import { createStitches, globalCss } from "@stitches/react";
import {
  violet, // accent
  violetA,
  cyan, // info
  cyanA,
  grass, // success
  grassA,
  tomato, // danger
  tomatoA,
  amber, // warning
  amberA,
  mauve, // grayscale
  mauveA, // overlay
} from "@radix-ui/colors";
// https://www.radix-ui.com/docs/colors
import type { CSS, PropertyValue, ScaleValue } from "@stitches/react";

export const globalStyles = globalCss({
  "@import": [
    "url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap')",
  ],
});

type TextStyle =
  | "text-1"
  | "text-2"
  | "text-3"
  | "text-4"
  | "text-5"
  | "text-6"
  | "text-7"
  | "text-8";

const textStyles: {
  [key in TextStyle]: { fontSize: string; lineHeight: string; fontWeight: string };
} = {
  "text-1": {
    fontSize: "$xs", // 12px
    lineHeight: "1.66",
    fontWeight: "$normal",
  },
  "text-2": {
    fontSize: "$sm", // 14px
    lineHeight: "1.71",
    fontWeight: "$normal",
  },
  "text-3": {
    fontSize: "$md", // 16px
    lineHeight: "1.5",
    fontWeight: "$normal",
  },
  "text-4": {
    fontSize: "$md", // 16px
    lineHeight: "1.5",
    fontWeight: "$semi_bold",
  },
  "text-5": {
    fontSize: "$lg", // 20px
    lineHeight: "1.4",
    fontWeight: "$semi_bold",
  },
  "text-6": {
    fontSize: "$xl", // 24px
    lineHeight: "1.4",
    fontWeight: "$semi_bold",
  },
  "text-7": {
    fontSize: "$heading", // 32px
    lineHeight: "1.25",
    fontWeight: "$bold",
  },
  "text-8": {
    fontSize: "$heading_lg", // 40px
    lineHeight: "1.4",
    fontWeight: "$bold",
  },
};

export const { styled, css, keyframes } = createStitches({
  utils: {
    textStyle: (style: TextStyle) => {
      return textStyles[style];
    },
    lineClamp: (value: number) => ({
      display: "-webkit-box",
      WebkitLineClamp: value,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    }),
    truncateText: (value: PropertyValue<"width"> | boolean) => ({
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: typeof value !== "boolean" ? value : "initial",
    }),
    hideOnPrint: () => ({
      "@media print": {
        display: "none",
      },
    }),
    onPrint: (CSSObject: CSS) => ({
      "@media print": {
        ...CSSObject,
      },
    }),
    nudge: ([x, y]: [number, number]) => ({
      transform: `translate(${x}px, ${y}px)`,
    }),
    nudgeX: (x: number) => ({
      transform: `translateX(${x}px)`,
    }),
    nudgeY: (y: number) => ({
      transform: `translateY(${y}px)`,
    }),
    pad: (value: PropertyValue<"padding">) => ({
      paddingTop: value,
      paddingLeft: value,
      paddingRight: value,
      paddingBottom: value,
    }),
    padx: (value: PropertyValue<"padding">) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    pady: (value: PropertyValue<"padding">) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    padt: (value: PropertyValue<"padding">) => ({
      paddingTop: value,
    }),
    padb: (value: PropertyValue<"padding">) => ({
      paddingBottom: value,
    }),
    padl: (value: PropertyValue<"padding">) => ({
      paddingLeft: value,
    }),
    padr: (value: PropertyValue<"padding">) => ({
      paddingRight: value,
    }),
    m: (value: PropertyValue<"margin">) => ({
      margin: value,
    }),
    mx: (value: PropertyValue<"margin">) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: PropertyValue<"margin">) => ({
      marginTop: value,
      marginBottom: value,
    }),
    ml: (value: PropertyValue<"margin">) => ({
      marginLeft: value,
    }),
    mr: (value: PropertyValue<"margin">) => ({
      marginRight: value,
    }),
    mt: (value: PropertyValue<"margin">) => ({
      marginTop: value,
    }),
    mb: (value: PropertyValue<"margin">) => ({
      marginBottom: value,
    }),
    br: (value: PropertyValue<"borderRadius">) => ({
      webkitBorderRadius: value,
      mozBorderRadius: value,
      borderRadius: value,
    }),
    brTopLeft: (value: PropertyValue<"borderRadius">) => ({
      borderTopLeftRadius: value,
    }),
    brTopRight: (value: PropertyValue<"borderRadius">) => ({
      borderTopRightRadius: value,
    }),
    brBotLeft: (value: PropertyValue<"borderRadius">) => ({
      borderBottomLeftRadius: value,
    }),
    brBotRight: (value: PropertyValue<"borderRadius">) => ({
      borderBottomRightRadius: value,
    }),
    size: (value: PropertyValue<"width" | "height">) => ({
      width: value,
      height: value,
    }),
    h: (value: PropertyValue<"height">) => ({
      height: value,
    }),
    w: (value: PropertyValue<"width">) => ({
      width: value,
    }),
    minH: (value: PropertyValue<"height">) => ({
      minHeight: value,
    }),
    minW: (value: PropertyValue<"width">) => ({
      minWidth: value,
    }),
    maxH: (value: PropertyValue<"height">) => ({
      maxHeight: value,
    }),
    maxW: (value: PropertyValue<"width">) => ({
      maxWidth: value,
    }),
    pos: (value: PropertyValue<"position">) => ({
      position: value,
    }),
    flexDir: (value: PropertyValue<"flexDirection">) => ({
      flexDirection: value,
    }),
    jc: (value: PropertyValue<"justifyContent">) => ({
      justifyContent: value,
    }),
    ai: (value: PropertyValue<"alignItems">) => ({
      alignItems: value,
    }),
    ff: (value: ScaleValue<"fonts">) => ({
      fontFamily: value,
    }),
    fw: (value: PropertyValue<"fontWeight">) => ({
      fontWeight: value,
    }),
    fs: (value: PropertyValue<"fontSize">) => ({
      fontSize: value,
    }),
    lh: (value: PropertyValue<"lineHeight">) => ({
      lineHeight: value,
    }),
  },
  media: {
    tablet: "(min-width: 576px)",
    desktop_sm: "(min-width: 768px)",
    desktop_md: "(min-width: 1024px)",
    desktop_lg: "(min-width: 1280px)",
    desktop_xl: "(min-width: 1440px)",
  },
  theme: {
    sizes: {
      maxContentWidth: "1320px",
      maxContentWidthMd: "1024px",
      maxContentWidthSm: "768px",
      maxContentWidthXs: "576px",
      navHeight: "calc(env(safe-area-inset-bottom) + 60px)",
      xs_btn: "24px",
      sm_btn: "32px",
      md_btn: "40px",
      lg_btn: "48px",
      full: "100%",
    },
    space: {
      xxs: "4px",
      xs: "8px",
      sm: "12px",
      md: "16px",
      lg: "24px",
      xl: "32px",
      xxl: "48px",
    },
    fontWeights: {
      normal: 400,
      semi_bold: 500, // this should be 600 or 550
      bold: 700,
    },
    fontSizes: {
      xs: "0.75rem",
      sm: "0.875rem",
      md: "1.0rem",
      lg: "1.25rem",
      xl: "1.5rem",
      heading: "2rem",
      heading_lg: "2.5rem",
    },
    fonts: {
      avenir: "Avenir, Avenir Next, apple-system, sans-serif",
      arboria: "Arboria, Helvetica Neue, Roboto, sans-serif",
      inter: "Inter, Helvetica Neue, Roboto, sans-serif",
    },
    lineHeights: {
      reset: 1,
      btn: 1.125,
      heading: 1.25,
      text: 1.5,
      article: 1.75,
    },
    letterSpacings: {},

    borderWidths: {},
    borderStyles: {},
    radii: {
      xs: "4px",
      sm: "6px",
      md: "8px",
      lg: "12px",
      xl: "16px",
      pill: "9999px",
    },
    shadows: {
      elevation5: "0px 8px 24px 2px rgba(35, 52, 52, 0.05)",
      elevation4: "0px 6px 16px 2px rgba(35, 52, 52, 0.1)",
      elevation3: "0px 4px 8px 1px rgba(35, 52, 52, 0.15)",
      elevation2: "0px 2px 4px 1px rgba(35, 52, 52, 0.12)",
      elevation1: "0px 1px 2px 0px rgba(35, 52, 52, 0.08)",
      focus: "0 0 0 2px $colors$accent9",
    },
    zIndices: {
      above: 100,
      baseline: 0,
      dropdown: "calc($baseline + $above)",
      sticky: "calc($dropdown + $above)",
      fixed: "calc($sticky + $above)",
      modal_backdrop: "calc($fixed + $above)",
      modal: "calc($modal_backdrop + $above)",
      popover: "calc($modal + $above)",
      toast: "calc($popover + $above)",
      tooltip: "calc($toast + $above)",
    },
    transitions: {},

    // ################
    // #### COLORS ####
    // ################

    colors: {
      ...cyan,
      ...cyanA,
      ...violet,
      ...violetA,
      ...grass,
      ...grassA,
      ...tomato,
      ...tomatoA,
      ...amber,
      ...amberA,
      ...mauve,
      ...mauveA,

      // SUCCESS, VALID - grass
      success1: "$grass1",
      success2: "$grass2",
      success3: "$grass3",
      success4: "$grass4",
      success5: "$grass5",
      success6: "$grass6",
      success7: "$grass7",
      success8: "$grass8",
      success9: "$grass9",
      success10: "$grass10",
      success11: "$grass11",
      success12: "$grass12",
      valid1: "$grass1",
      valid2: "$grass2",
      valid3: "$grass3",
      valid4: "$grass4",
      valid5: "$grass5",
      valid6: "$grass6",
      valid7: "$grass7",
      valid8: "$grass8",
      valid9: "$grass9",
      valid10: "$grass10",
      valid11: "$grass11",
      valid12: "$grass12",

      // WARNING, PENDING - amber
      warning1: "$amber1",
      warning2: "$amber2",
      warning3: "$amber3",
      warning4: "$amber4",
      warning5: "$amber5",
      warning6: "$amber6",
      warning7: "$amber7",
      warning8: "$amber8",
      warning9: "$amber9",
      warning10: "$amber10",
      warning11: "$amber11",
      warning12: "$amber12",
      pending1: "$amber1",
      pending2: "$amber2",
      pending3: "$amber3",
      pending4: "$amber4",
      pending5: "$amber5",
      pending6: "$amber6",
      pending7: "$amber7",
      pending8: "$amber8",
      pending9: "$amber9",
      pending10: "$amber10",
      pending11: "$amber11",
      pending12: "$amber12",

      // ERROR, DANGER - tomato
      danger1: "$tomato1",
      danger2: "$tomato2",
      danger3: "$tomato3",
      danger4: "$tomato4",
      danger5: "$tomato5",
      danger6: "$tomato6",
      danger7: "$tomato7",
      danger8: "$tomato8",
      danger9: "$tomato9",
      danger10: "$tomato10",
      danger11: "$tomato11",
      danger12: "$tomato12",
      error1: "$tomato1",
      error2: "$tomato2",
      error3: "$tomato3",
      error4: "$tomato4",
      error5: "$tomato5",
      error6: "$tomato6",
      error7: "$tomato7",
      error8: "$tomato8",
      error9: "$tomato9",
      error10: "$tomato10",
      error11: "$tomato11",
      error12: "$tomato12",

      // INFO - cyan
      info1: "$cyan1",
      info2: "$cyan2",
      info3: "$cyan3",
      info4: "$cyan4",
      info5: "$cyan5",
      info6: "$cyan6",
      info7: "$cyan7",
      info8: "$cyan8",
      info9: "$cyan9",
      info10: "$cyan10",
      info11: "$cyan11",
      info12: "$cyan12",

      // ACCENT - violet
      accent1: "$violet1",
      accent2: "$violet2",
      accent3: "$violet3",
      accent4: "$violet4",
      accent5: "$violet5",
      accent6: "$violet6",
      accent7: "$violet7",
      accent8: "$violet8",
      accent9: "$violet9",
      accent10: "$violet10",
      accent11: "$violet11",
      accent12: "$violet12",
      // hotplate brand color
      hotplate1: "hsl(0, 100%, 99%)",
      hotplate2: "hsl(0, 100%, 98%)",
      hotplate3: "hsl(0, 100%, 95%)",
      hotplate4: "hsl(0, 98%, 94%)",
      hotplate5: "hsl(0, 90%, 91%)",
      hotplate6: "hsl(0, 81%, 87%)",
      hotplate7: "hsl(0, 74%, 82%)",
      hotplate8: "hsl(0, 70%, 74%)",
      hotplate9: "hsl(0, 75%, 63%)",
      hotplate10: "hsl(0, 69%, 55%)",
      hotplate11: "hsl(0, 65%, 50%)",
      hotplate12: "hsl(0, 50%, 15%)",
      // hotplate brand colors w/ alpha
      hotplateA1: "hsla(0, 100%, 99%, 0.012)",
      hotplateA2: "hsla(0, 100%, 98%, 0.032)",
      hotplateA3: "hsla(0, 100%, 95%, 0.063)",
      hotplateA4: "hsla(0, 98%, 94%, 0.102)",
      hotplateA5: "hsla(0, 90%, 91%, 0.153)",
      hotplateA6: "hsla(0, 81%, 87%, 0.224)",
      hotplateA7: "hsla(0, 74%, 82%, 0.318)",
      hotplateA8: "hsla(0, 70%, 74%, 0.436)",
      hotplateA9: "hsla(0, 75%, 63%, 0.718)",
      hotplateA10: "hsla(0, 69%, 55%, 0.761)",
      hotplateA11: "hsla(0, 65%, 50%, 0.832)",
      hotplateA12: "hsla(0, 50%, 15%, 0.926)",

      // OVERLAY - MauveA (e.g. with alpha)
      overlay1: "$mauveA1",
      overlay2: "$mauveA2",
      overlay3: "$mauveA3",
      overlay4: "$mauveA4",
      overlay5: "$mauveA5",
      overlay6: "$mauveA6",
      overlay7: "$mauveA7",
      overlay8: "$mauveA8",
      overlay9: "$mauveA9",
      overlay10: "$mauveA10",
      overlay11: "$mauveA11",
      overlay12: "$mauveA12",

      // GRAYSCALE - mauve
      gray1: "$mauve1",
      gray2: "$mauve2",
      gray3: "$mauve3",
      gray4: "$mauve4",
      gray5: "$mauve5",
      gray6: "$mauve6",
      gray7: "$mauve7",
      gray8: "$mauve8",
      gray9: "$mauve9",
      gray10: "$mauve10",
      gray11: "$mauve11",
      gray12: "$mauve12",

      // Util colors
      white: "rgba(255, 255, 255, 1)",

      // UNDERSTANDING COLOR SCALES - basic guidelines (NOT hard rules)

      // ### App Backgrounds
      // 1 - whiteish for app or fullscreen components
      // 2 - subtle for component differentiation

      // ### Component backgrounds
      // 3 - normal states
      // 4 - hover states
      // 5 - active or selected states
      // NOTE: can be bumped up to 4,5,6 to draw strong attention

      // ### Borders
      // 6 - subtle for UI elements that are non-interactive
      // 7 - strong for interactive components, can also be used for focus rings
      // 8 - :hover for interactive components

      // ### Solid Backgrounds
      // 9 - the purest hue/highest chroma, has least amount of white or black
      // NOTE: ~VERSATILE~
      // designed for WHITE foreground text
      // background for: app, section, header, component, button
      // Graphics, logos
      // Overlays
      // colored shadows
      // accent borders
      // 10 - hover state of components using step 9

      // ### Text
      // 11 - low contrast text
      // 12 - high contrast text
    },
  },
});

export const colors = {
  ...cyan,
  ...violet,
  ...grass,
  ...tomato,
  ...amber,
  ...amberA,
  ...mauve,
  ...mauveA,

  // SUCCESS, VALID - grass
  success1: grass.grass1,
  success2: grass.grass2,
  success3: grass.grass3,
  success4: grass.grass4,
  success5: grass.grass5,
  success6: grass.grass6,
  success7: grass.grass7,
  success8: grass.grass8,
  success9: grass.grass9,
  success10: grass.grass10,
  success11: grass.grass11,
  success12: grass.grass12,
  valid1: grass.grass1,
  valid2: grass.grass2,
  valid3: grass.grass3,
  valid4: grass.grass4,
  valid5: grass.grass5,
  valid6: grass.grass6,
  valid7: grass.grass7,
  valid8: grass.grass8,
  valid9: grass.grass9,
  valid10: grass.grass10,
  valid11: grass.grass11,
  valid12: grass.grass12,

  // WARNING, PENDING - amber
  warning1: amber.amber1,
  warning2: amber.amber2,
  warning3: amber.amber3,
  warning4: amber.amber4,
  warning5: amber.amber5,
  warning6: amber.amber6,
  warning7: amber.amber7,
  warning8: amber.amber8,
  warning9: amber.amber9,
  warning10: amber.amber10,
  warning11: amber.amber11,
  warning12: amber.amber12,
  pending1: amber.amber1,
  pending2: amber.amber2,
  pending3: amber.amber3,
  pending4: amber.amber4,
  pending5: amber.amber5,
  pending6: amber.amber6,
  pending7: amber.amber7,
  pending8: amber.amber8,
  pending9: amber.amber9,
  pending10: amber.amber10,
  pending11: amber.amber11,
  pending12: amber.amber12,

  // ERROR, DANGER - tomato
  danger1: tomato.tomato1,
  danger2: tomato.tomato2,
  danger3: tomato.tomato3,
  danger4: tomato.tomato4,
  danger5: tomato.tomato5,
  danger6: tomato.tomato6,
  danger7: tomato.tomato7,
  danger8: tomato.tomato8,
  danger9: tomato.tomato9,
  danger10: tomato.tomato10,
  danger11: tomato.tomato11,
  danger12: tomato.tomato12,
  error1: tomato.tomato1,
  error2: tomato.tomato2,
  error3: tomato.tomato3,
  error4: tomato.tomato4,
  error5: tomato.tomato5,
  error6: tomato.tomato6,
  error7: tomato.tomato7,
  error8: tomato.tomato8,
  error9: tomato.tomato9,
  error10: tomato.tomato10,
  error11: tomato.tomato11,
  error12: tomato.tomato12,

  // INFO - cyan
  info1: cyan.cyan1,
  info2: cyan.cyan2,
  info3: cyan.cyan3,
  info4: cyan.cyan4,
  info5: cyan.cyan5,
  info6: cyan.cyan6,
  info7: cyan.cyan7,
  info8: cyan.cyan8,
  info9: cyan.cyan9,
  info10: cyan.cyan10,
  info11: cyan.cyan11,
  info12: cyan.cyan12,

  // ACCENT - violet
  accent1: violet.violet1,
  accent2: violet.violet2,
  accent3: violet.violet3,
  accent4: violet.violet4,
  accent5: violet.violet5,
  accent6: violet.violet6,
  accent7: violet.violet7,
  accent8: violet.violet8,
  accent9: violet.violet9,
  accent10: violet.violet10,
  accent11: violet.violet11,
  accent12: violet.violet12,
  // hotplate brand color
  hotplate1: "hsl(0, 100%, 99%)",
  hotplate2: "hsl(0, 100%, 98%)",
  hotplate3: "hsl(0, 100%, 95%)",
  hotplate4: "hsl(0, 98%, 94%)",
  hotplate5: "hsl(0, 90%, 91%)",
  hotplate6: "hsl(0, 81%, 87%)",
  hotplate7: "hsl(0, 74%, 82%)",
  hotplate8: "hsl(0, 70%, 74%)",
  hotplate9: "hsl(0, 75%, 63%)",
  hotplate10: "hsl(0, 69%, 55%)",
  hotplate11: "hsl(0, 65%, 50%)",
  hotplate12: "hsl(0, 50%, 15%)",
  // hotplate brand colors w/ alpha
  hotplateA1: "hsla(0, 100%, 99%, 0.012)",
  hotplateA2: "hsla(0, 100%, 98%, 0.032)",
  hotplateA3: "hsla(0, 100%, 95%, 0.063)",
  hotplateA4: "hsla(0, 98%, 94%, 0.102)",
  hotplateA5: "hsla(0, 90%, 91%, 0.153)",
  hotplateA6: "hsla(0, 81%, 87%, 0.224)",
  hotplateA7: "hsla(0, 74%, 82%, 0.318)",
  hotplateA8: "hsla(0, 70%, 74%, 0.436)",
  hotplateA9: "hsla(0, 75%, 63%, 0.718)",
  hotplateA10: "hsla(0, 69%, 55%, 0.761)",
  hotplateA11: "hsla(0, 65%, 50%, 0.832)",
  hotplateA12: "hsla(0, 50%, 15%, 0.926)",

  // OVERLAY - MauveA (e.g. with alpha)
  overlay1: mauveA.mauveA1,
  overlay2: mauveA.mauveA2,
  overlay3: mauveA.mauveA3,
  overlay4: mauveA.mauveA4,
  overlay5: mauveA.mauveA5,
  overlay6: mauveA.mauveA6,
  overlay7: mauveA.mauveA7,
  overlay8: mauveA.mauveA8,
  overlay9: mauveA.mauveA9,
  overlay10: mauveA.mauveA10,
  overlay11: mauveA.mauveA11,
  overlay12: mauveA.mauveA12,

  // GRAYSCALE - mauve
  gray1: mauve.mauve1,
  gray2: mauve.mauve2,
  gray3: mauve.mauve3,
  gray4: mauve.mauve4,
  gray5: mauve.mauve5,
  gray6: mauve.mauve6,
  gray7: mauve.mauve7,
  gray8: mauve.mauve8,
  gray9: mauve.mauve9,
  gray10: mauve.mauve10,
  gray11: mauve.mauve11,
  gray12: mauve.mauve12,

  // Util colors
  white: "rgba(255, 255, 255, 1)",
};
