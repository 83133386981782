/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import ToolTip from "../../../../hotplate-common/ToolTip";
import { EventPublish, EventLink } from "../../../../visly/Events";
import { getEventStatus } from "@hotplate/utils-ts/helperFunctions";
import QRCode from "qrcode.react";
import ReactLoading from "react-loading";

import { useBreakpoint } from "../../../../visly";

import { setToastPopupInfo } from "../../../../hotplate-storefront/actions";
import { backendUrl } from "../../../../hotplate-storefront/actions/types";
import { Referral } from "../../../Referral";
import { Flex, H3, P } from "../../../../hotplate-common/primitives/Containers";
import { trackEventLinkCopied, trackEventQRCodeDownloaded } from "../analytics";
import { usePortalUser } from "../../../../auth";
import { Dialog } from "../../../../hotplate-common/primitives/Dialog";
import { Input } from "../../../../hotplate-common/primitives/Input";
import { Label } from "../../../../hotplate-common/primitives/Label";
import { trpc } from "../../../../trpc";
import { Error as ErrorText } from "../../../../hotplate-common/primitives/Error";
import { TextArea } from "../../../../hotplate-common/primitives/TextArea";
import {
  trackOpenSendEmailRemindersModalClicked,
  trackSendEmailRemindersClicked,
} from "../../../analytics";

function SendEmail({ event }) {
  const [subject, setSubject] = useState(`${event.title} Dropping Soon`);
  const [title, setTitle] = useState(event.title);
  const [subtitle, setSubtitle] = useState("");
  const [body, setBody] = useState("");
  const [testEmailAddress, setTestEmailAddress] = useState("");
  const sendTestEmailEventReminder = trpc.portal.sendTestEmailEventReminder.useMutation();
  const sendEmailEventReminders = trpc.portal.sendEmailEventReminders.useMutation();
  const dispatch = useDispatch();

  const isValid = subject.length && title.length && body.length;

  return (
    <>
      <Label text="Subject*" css={{ marginBottom: "1em" }}>
        <Input
          placeholder="you@example.com"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
      </Label>
      <Label text="Title*" css={{ marginBottom: "1em" }}>
        <Input value={title} onChange={(e) => setTitle(e.target.value)} />
      </Label>
      <Label text="Subtitle" css={{ marginBottom: "1em" }}>
        <Input
          placeholder="Said to be so sharp you can cut yourself just by looking..."
          value={subtitle}
          onChange={(e) => setSubtitle(e.target.value)}
        />
      </Label>
      <Label text="Body*" css={{ marginBottom: "1em" }}>
        <TextArea
          placeholder="Your mind is like this water, my friend. When it is agitated, it becomes difficult to see. But if you allow it to settle, the answer becomes clear."
          value={body}
          onChange={(e) => setBody(e.target.value)}
        />
      </Label>
      <Label text="Provide your email address to send a test email to">
        <Input
          placeholder="you@example.com"
          value={testEmailAddress}
          onChange={(e) => setTestEmailAddress(e.target.value)}
        />
      </Label>
      {sendTestEmailEventReminder.isError && (
        <ErrorText>{sendTestEmailEventReminder.error.message}</ErrorText>
      )}
      <EventPublish.PublishButton
        style={{ marginTop: "1em" }}
        text={"Send Test Email"}
        disabled={testEmailAddress.length === 0 || !isValid || sendTestEmailEventReminder.isLoading}
        onClick={async () => {
          try {
            await sendTestEmailEventReminder.mutateAsync({
              email: testEmailAddress,
              eventId: event.id,
              model: {
                subject,
                title,
                subtitle,
                body,
              },
            });
            setToastPopupInfo({
              text: `Successfully sent test email to ${testEmailAddress}`,
              type: "success",
            })(dispatch);
          } catch (e) {
            setToastPopupInfo({
              text: `Error sending test email: ${e?.message}`,
              type: "error",
            })(dispatch);
          }
        }}
        loading={sendTestEmailEventReminder.isLoading}
        loadingAnimation={
          <ReactLoading type={"spin"} color={"#000000"} height={"20px"} width={"20px"} />
        }
      />
      <EventPublish.PublishButton
        style={{ marginTop: "1em" }}
        text={"Send Email Reminders"}
        disabled={!sendTestEmailEventReminder.isSuccess || sendEmailEventReminders.isLoading}
        onClick={async () => {
          trackSendEmailRemindersClicked(event.id);
          try {
            await sendEmailEventReminders.mutateAsync({
              eventId: event.id,
              model: {
                subject,
                title,
                subtitle,
                body,
              },
            });
            setToastPopupInfo({
              text: `Successfully sent emails`,
              type: "success",
            })(dispatch);
          } catch (e) {
            setToastPopupInfo({
              text: `Error sending email notifications: ${e?.message}`,
              type: "error",
            })(dispatch);
          }
        }}
        loading={sendEmailEventReminders.isLoading}
        loadingAnimation={
          <ReactLoading type={"spin"} color={"#000000"} height={"20px"} width={"20px"} />
        }
      />
    </>
  );
}

export default connect(
  (state) => {
    return {};
  },
  {
    setToastPopupInfo,
  }
)(function PublishMaster({
  setToastPopupInfo,
  event,
  publishDisabled,
  publishEvent,
  publishEventLoading,
  unpublishEvent,
  unpublishEventLoading,
}) {
  const { chefId } = usePortalUser();
  const [qrCodeLink, setQrCodeLink] = useState(undefined);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);

  useEffect(() => {
    if (qrCodeLink) {
      try {
        const canvas = document.getElementById("qr-gen");
        if (canvas === null) {
          throw new Error("#qr-gen element could not be found");
        }
        const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        const downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `Event-QRCode-${event.title}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setQrCodeLink(undefined);
      } catch (e) {
        setToastPopupInfo({
          text: `Error creating QR code: ${e.message}`,
          type: "error",
        });
      }
    }
  }, [qrCodeLink, event.title, setToastPopupInfo]);

  function getEventLink(event) {
    let baseUrl = "hotplate.com/";
    if (backendUrl === "https://hotplate-backend-test.uc.r.appspot.com/")
      baseUrl = "https://hotplate-frontend-test.uc.r.appspot.com/";
    else if (
      backendUrl === "https://hotplate.ngrok.io/" ||
      backendUrl === "https://localhost:8080/"
    )
      baseUrl = "localhost:3000/";
    return baseUrl + chefId + "?event=" + event.id.substring(0, 5);
  }

  function copyEventLink(event) {
    navigator.clipboard.writeText(getEventLink(event));
    setToastPopupInfo({
      text: "Copied to clipboard",
      type: "info",
    });
    trackEventLinkCopied({
      eventId: event.id,
      eventStatus: getEventStatus(event),
    });
  }

  function generateQRCode(event) {
    setQrCodeLink(getEventLink(event));
    trackEventQRCodeDownloaded({
      eventId: event.id,
      eventStatus: getEventStatus(event),
    });
  }

  return (
    <>
      <EventPublish
        size={size}
        Link={
          <EventPublish.Link
            tooltip={
              <ToolTip
                placement="right"
                text={
                  "This will be the direct link to your event once you publish it.\n\nIf your event is Private, this is the only way customers will be able to access it.\n\nIf your event is not Private, then this link will bring up your storefront with this event selected."
                }
              />
            }
            Input={<EventLink text={getEventLink(event)} />}
          />
        }
        QrCodeButton={<EventPublish.QrCodeButton onClick={() => generateQRCode(event)} />}
        CopyLinkButton={<EventPublish.CopyLinkButton onClick={() => copyEventLink(event)} />}
        PublishButton={
          getEventStatus(event) !== "complete" &&
          (event.draft ? (
            <EventPublish.PublishButton
              onClick={() => publishEvent()}
              disabled={publishDisabled || publishEventLoading}
              loading={publishEventLoading}
              loadingAnimation={
                <ReactLoading type={"spin"} color={"#FFFFFF"} height={"20px"} width={"20px"} />
              }
            />
          ) : (
            <EventPublish.PublishButton
              kind="warning"
              text="Unpublish Event"
              onClick={() => unpublishEvent()}
              loading={unpublishEventLoading}
              loadingAnimation={
                <ReactLoading type={"spin"} color={"#000000"} height={"20px"} width={"20px"} />
              }
            />
          ))
        }
      >
        <Flex css={{ flexDirection: "column", maxWidth: 480, width: "100%" }}>
          <EventPublish.PublishButton
            text={
              event.numEmailRemindersSent
                ? `${event.numEmailRemindersSent} email reminders sent`
                : "Send Email Reminders"
            }
            disabled={!!event.numEmailRemindersSent}
            onClick={() => {
              setIsEmailDialogOpen(true);
              trackOpenSendEmailRemindersModalClicked(event.id);
            }}
          />
          <H3
            css={{
              marginTop: 32,
              fontFamily: "$arboria",
              fontSize: "$lg",
              fontWeight: "$semi_bold",
              marginBottom: "$sm",
              lh: "$heading",
              color: "$gray12",
            }}
          >
            Enjoying Hotplate? Refer a chef!
          </H3>
          <P
            css={{
              fontFamily: "$avenir",
              fontSize: "$sm",
              fontWeight: "$semi_bold",
              lh: "$text",
              color: "$gray10",
            }}
          >
            Invite a chef to Hotplate and both of you will get $30 added to your account when they
            make their first sale.
          </P>
          <Referral />
        </Flex>
      </EventPublish>
      {qrCodeLink && (
        <QRCode
          style={{ zIndex: -10 }}
          bgColor={"transparent"}
          id="qr-gen"
          value={qrCodeLink}
          size={256}
          level="L"
          includeMargin={true}
        />
      )}
      <Dialog
        isOpen={isEmailDialogOpen && !event.numEmailRemindersSent}
        onOpenChange={() => setIsEmailDialogOpen(false)}
      >
        <SendEmail event={event} />
      </Dialog>
    </>
  );
});
