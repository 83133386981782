// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Closable.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, ContainerPrimitive } from "../_internal_primitives";
import * as icons from "../icons";
import {
  default as IconButton,
  IconButtonContext,
  default as largeClose,
  IconButtonContext as largeCloseContext,
} from "../Primitives/IconButton";
import { default as Backdrop } from "./Closable.Backdrop";

const styles = [
  {
    type: "default",
    layers: {
      SgMmdPRioy: {
        none: {
          htmlElement: "section",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "gone",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large850",
        layers: {},
      },
      {
        propValue: "med450",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
    ],
  },
  {
    type: "boolean",
    propName: "compact",
    layers: {},
  },
  {
    type: "boolean",
    propName: "cart",
    layers: {},
  },
  {
    type: "boolean",
    propName: "noClose",
    layers: {},
  },
  {
    type: "enum",
    propName: "collapse",
    values: [
      {
        propValue: "down",
        layers: {},
      },
      {
        propValue: "right",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      Qc9FqyMxcV: {
        none: {
          disabled: false,
          icon: icons.close,
          large: true,
        },
      },
      UeV8RoX9e9: {
        none: {
          icon: icons.close,
          large: true,
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "gone",
    layers: {
      Qc9FqyMxcV: {},
      UeV8RoX9e9: {},
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large850",
        layers: {
          Qc9FqyMxcV: {},
          UeV8RoX9e9: {},
        },
      },
      {
        propValue: "med450",
        layers: {
          Qc9FqyMxcV: {},
          UeV8RoX9e9: {},
        },
      },
      {
        propValue: "small",
        layers: {
          Qc9FqyMxcV: {},
          UeV8RoX9e9: {},
        },
      },
    ],
  },
  {
    type: "boolean",
    propName: "compact",
    layers: {
      Qc9FqyMxcV: {
        none: {
          large: false,
        },
      },
      UeV8RoX9e9: {},
    },
  },
  {
    type: "boolean",
    propName: "cart",
    layers: {
      Qc9FqyMxcV: {
        none: {
          icon: icons.chevronLeft,
          large: true,
        },
      },
      UeV8RoX9e9: {},
    },
  },
  {
    type: "boolean",
    propName: "noClose",
    layers: {
      Qc9FqyMxcV: {},
      UeV8RoX9e9: {},
    },
  },
  {
    type: "enum",
    propName: "collapse",
    values: [
      {
        propValue: "down",
        layers: {
          Qc9FqyMxcV: {},
          UeV8RoX9e9: {},
        },
      },
      {
        propValue: "right",
        layers: {
          Qc9FqyMxcV: {},
          UeV8RoX9e9: {},
        },
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "gone",
  },
  {
    type: "enum",
    propName: "size",
    propValues: ["large850", "med450", "small"],
  },
  {
    type: "boolean",
    propName: "compact",
  },
  {
    type: "boolean",
    propName: "cart",
  },
  {
    type: "boolean",
    propName: "noClose",
  },
  {
    type: "enum",
    propName: "collapse",
    propValues: ["down", "right"],
  },
];

export const ClosableContext = createContext(null);

function Closable(_props) {
  const defaults = useContext(ClosableContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="JzFdEzyiEv"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "JzFdEzyiEv",
        scope: "5EQYnAybQE",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"BackdropContainer_V1Nk5aDCL2"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_5EQYnAybQE_V1Nk5aDCL2"
            }
            key={"V1Nk5aDCL2"}
            addSpacing={false}
            element={getStyle("V1Nk5aDCL2", "htmlElement")}
          >
            {[
              props.Backdrop === undefined ? (
                <Backdrop key={"Qab8bggcLv"} />
              ) : (
                props.Backdrop
              ),
              <ContainerPrimitive
                id={"largeCloseContainer_MGJzCfznha"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_5EQYnAybQE_MGJzCfznha"
                }
                key={"MGJzCfznha"}
                addSpacing={false}
                element={getStyle("MGJzCfznha", "htmlElement")}
              >
                {props.largeClose === undefined ? (
                  <largeClose
                    key={"UeV8RoX9e9"}
                    {...getCompositeDefaultProps("UeV8RoX9e9")}
                    id="largeClose_UeV8RoX9e9"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_5EQYnAybQE_UeV8RoX9e9"
                  />
                ) : (
                  <largeCloseContext.Provider
                    key="UeV8RoX9e9-provider"
                    value={{
                      key: "UeV8RoX9e9",
                      id: "largeClose_UeV8RoX9e9",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_5EQYnAybQE_UeV8RoX9e9",
                      ...getCompositeDefaultProps("UeV8RoX9e9"),
                    }}
                  >
                    {props.largeClose}
                  </largeCloseContext.Provider>
                )}
              </ContainerPrimitive>,
            ]}
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"VislyOuterWrapper_SgMmdPRioy"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_5EQYnAybQE_SgMmdPRioy"
            }
            key={"SgMmdPRioy"}
            addSpacing={false}
            element={getStyle("SgMmdPRioy", "htmlElement")}
          >
            <ContainerPrimitive
              id={"ModalHeader_NBtCGuNje7"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_5EQYnAybQE_NBtCGuNje7"
              }
              key={"NBtCGuNje7"}
              addSpacing={false}
              element={getStyle("NBtCGuNje7", "htmlElement")}
            >
              {props.IconButton === undefined ? (
                <IconButton
                  key={"Qc9FqyMxcV"}
                  {...getCompositeDefaultProps("Qc9FqyMxcV")}
                  id="IconButton_Qc9FqyMxcV"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_5EQYnAybQE_Qc9FqyMxcV"
                />
              ) : (
                <IconButtonContext.Provider
                  key="Qc9FqyMxcV-provider"
                  value={{
                    key: "Qc9FqyMxcV",
                    id: "IconButton_Qc9FqyMxcV",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_5EQYnAybQE_Qc9FqyMxcV",
                    ...getCompositeDefaultProps("Qc9FqyMxcV"),
                  }}
                >
                  {props.IconButton}
                </IconButtonContext.Provider>
              )}
            </ContainerPrimitive>
            <ContainerPrimitive
              key={"6HnfG62ZnE"}
              id={"Slot_6HnfG62ZnE"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_5EQYnAybQE_6HnfG62ZnE"
              }
              addSpacing={false}
            >
              {props.children}
            </ContainerPrimitive>
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

Closable.IconButton = IconButton;
Closable.largeClose = largeClose;
Closable.__variants = [
  {
    name: "gone",
    type: "variant",
  },
  {
    name: "size",
    type: "group",
    variants: ["large850", "med450", "small"],
  },
  {
    name: "compact",
    type: "variant",
  },
  {
    name: "cart",
    type: "variant",
  },
  {
    name: "noClose",
    type: "variant",
  },
  {
    name: "collapse",
    type: "group",
    variants: ["down", "right"],
  },
];

export default Closable;
