import { styled } from "../../stitches.config";

export const StorefrontBody = styled("div", {
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  alignItems: "center",
});

export const MenuContainer = styled("article", {
  display: "flex",
  width: "100%",
  flexDirection: "column",
  alignItems: "center",
  paddingInline: "$md",
  paddingBlock: "$lg",
  "@desktop_sm": {
    maxWidth: "$maxContentWidth",
  },
  "@desktop_md": {
    paddingInline: "$lg",
  },
  "@desktop_xl ": {
    padding: "0px",
  },
});

export const MenuSection = styled("section", {
  display: "grid", // IF THIS PARENT DOES NOT HAVE A GRID, SAFARI COMMITS SEPUKU, FUCK SAFARI
  width: "100%",
  scrollMarginTop: "88px",
});

export const MenuItemGrid = styled("div", {
  display: "grid",
  alignContent: "flex-start",
  width: "100%",
  gap: "clamp(16px,2vw, 24px)",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridTemplateRows: "auto",
  "@tablet": {
    gridTemplateColumns: "repeat(auto-fill, minmax(263px, 1fr))",
  },
  "& ::after": {
    display: "none",
  },
});

export const DeliveryGrid = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "$xs",
  width: "100%",
  padx: "$xxs",
  maxWidth: "$maxContentWidth",
  mb: "$sm",
});
