import { chef_id } from "../analytics";

// ###### TransactionHistory.jsx ######

export function trackTransactionsExported({ filteredByDate, filteredBySearch }) {
  window.analytics.track("Transactions Exported", {
    chef_id,
    filtered_by_date: filteredByDate,
    filtered_by_search: filteredBySearch,
  });
}

export function trackTransactionsSortUpdated({ sort, direction }) {
  window.analytics.track("Transactions Sort Updated", {
    chef_id,
    sort,
    direction,
  });
}

export function trackTransactionsFilteredByDate({ start, end }) {
  window.analytics.track("Transactions Filtered By Date", {
    chef_id,
    start,
    end,
  });
}

export function trackTransactionsSearched({ search }) {
  window.analytics.track("Transactions Searched", {
    chef_id,
    search,
  });
}

export function trackTransactionsPageChanged({ from, to }) {
  window.analytics.track("Transactions Page Changed", {
    chef_id,
    from,
    to,
  });
}

// ###### Index.jsx ######

export function trackTransactionClicked() {
  window.analytics.track("Transaction Clicked", {
    chef_id,
  });
}

export function trackPayoutButtonClicked() {
  window.analytics.track("Payout Button Clicked", {
    chef_id,
  });
}

export function trackBankInfoUpdated() {
  window.analytics.track("Bank Info Updated", {
    chef_id,
  });
}

// withdrawal initiated track call is in the python backend
