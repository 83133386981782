import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import firebase from "firebase/app";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./App.css";
import { AuthUserProviderGate } from "./auth";
import FirebaseProvider from "./firebaseSocket";
import { PuffLoader } from "./hotplate-common/loaders/PuffLoader";
import { Center, H1 } from "./hotplate-common/primitives/Containers";
import { TooltipProvider } from "./hotplate-common/primitives/Tooltip";
import Toast from "./hotplate-portal/components/Toast";
import { backendTsUrl, IS_PROD } from "./hotplate-storefront/actions/types";
import ScreenshotWidget from "./hotplate-storefront/components/ScreenshotWidget";
import Router from "./Router";
import { globalStyles } from "./stitches.config";
import { persistor, store } from "./Store.jsx";
import { trpc } from "./trpc";

const queryClient = new QueryClient();
const trpcClient = trpc.createClient({
  links: [
    httpBatchLink({
      url: backendTsUrl + "trpc",
      async headers() {
        const currentUser = firebase.auth().currentUser;
        if (currentUser) {
          const token = await currentUser.getIdToken();
          return {
            Authorization: `Bearer ${token}`,
          };
        }
        return {};
      },
    }),
  ],
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    Sentry.captureException(error, {
      level: "fatal",
      extra: { errorInfo },
      tags: { kind: "whitescreen" },
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Center
          css={{
            height: "100%",
          }}
        >
          <H1
            css={{
              textStyle: "text-7",
              padding: "1em",
              fontFamily: "$inter",
              fontWeight: "$bold",
              lineHeight: "1.75",
              color: "$accent11",
            }}
          >
            Something went wrong. Please refresh the page. If that doesn&apos;t help, contact
            support at (214) 833-9248.
          </H1>
        </Center>
      );
    }

    return this.props.children;
  }
}

export default function App() {
  const loading = (
    <Center css={{ height: "100%" }}>
      <PuffLoader />
    </Center>
  );

  useEffect(() => {
    globalStyles();
  }, []);

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <TooltipProvider delayDuration={500} skipDelayDuration={300}>
          <PersistGate loading={loading} persistor={persistor}>
            <FirebaseProvider>
              <trpc.Provider client={trpcClient} queryClient={queryClient}>
                <QueryClientProvider client={queryClient}>
                  <AuthUserProviderGate loading={loading}>
                    {!IS_PROD && <ScreenshotWidget />}
                    <Router />
                    <Toast />
                  </AuthUserProviderGate>
                </QueryClientProvider>
              </trpc.Provider>
            </FirebaseProvider>
          </PersistGate>
        </TooltipProvider>
      </Provider>
    </ErrorBoundary>
  );
}
