// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./EditOptionCategory.OptionsContainer.Option.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, SpacerPrimitive } from "../_internal_primitives";
import * as icons from "../icons";
import {
  default as TitleInput,
  InputContext as TitleInputContext,
  default as PriceInput,
  InputContext as PriceInputContext,
  default as QuantityInput,
  InputContext as QuantityInputContext,
} from "../Primitives/Input";
import {
  default as DeleteButton,
  IconButtonContext as DeleteButtonContext,
} from "../Primitives/IconButton";

const styles = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "showQuantity",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      Kk52Zk1WS2: {
        none: {
          placeholder: "Untitled Option",
        },
      },
      JzxKDMgafw: {
        none: {
          placeholder: "+0.00",
          withUnit: "left",
        },
      },
      "8vmad1atZ7": {
        none: {
          placeholder: "0",
        },
      },
      UjsPtqNpzT: {
        none: {
          icon: icons.close,
          large: false,
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "showQuantity",
    layers: {
      Kk52Zk1WS2: {},
      JzxKDMgafw: {},
      "8vmad1atZ7": {},
      UjsPtqNpzT: {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "showQuantity",
  },
];

export const OptionContext = createContext(null);

function Option(_props) {
  const defaults = useContext(OptionContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="2yqccLtHpv"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "2yqccLtHpv",
        scope: "PDnPh1VYdv",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => [
        props.TitleInput === undefined ? (
          <TitleInput
            key={"Kk52Zk1WS2"}
            {...getCompositeDefaultProps("Kk52Zk1WS2")}
            id="TitleInput_Kk52Zk1WS2"
            className="__visly_reset_7X7HTLRqyD __visly_scope_PDnPh1VYdv_Kk52Zk1WS2"
          />
        ) : (
          <TitleInputContext.Provider
            key="Kk52Zk1WS2-provider"
            value={{
              key: "Kk52Zk1WS2",
              id: "TitleInput_Kk52Zk1WS2",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_PDnPh1VYdv_Kk52Zk1WS2",
              ...getCompositeDefaultProps("Kk52Zk1WS2"),
            }}
          >
            {props.TitleInput}
          </TitleInputContext.Provider>
        ),
        <SpacerPrimitive
          id={"Spacer_45FRULrBDR"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_PDnPh1VYdv_45FRULrBDR"
          }
          key={"45FRULrBDR"}
        />,
        props.PriceInput === undefined ? (
          <PriceInput
            key={"JzxKDMgafw"}
            {...getCompositeDefaultProps("JzxKDMgafw")}
            id="PriceInput_JzxKDMgafw"
            className="__visly_reset_7X7HTLRqyD __visly_scope_PDnPh1VYdv_JzxKDMgafw"
          />
        ) : (
          <PriceInputContext.Provider
            key="JzxKDMgafw-provider"
            value={{
              key: "JzxKDMgafw",
              id: "PriceInput_JzxKDMgafw",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_PDnPh1VYdv_JzxKDMgafw",
              ...getCompositeDefaultProps("JzxKDMgafw"),
            }}
          >
            {props.PriceInput}
          </PriceInputContext.Provider>
        ),
        <SpacerPrimitive
          id={"Spacer_ASuBSurZNm"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_PDnPh1VYdv_ASuBSurZNm"
          }
          key={"ASuBSurZNm"}
        />,
        props.QuantityInput === undefined ? (
          <QuantityInput
            key={"8vmad1atZ7"}
            {...getCompositeDefaultProps("8vmad1atZ7")}
            id="QuantityInput_8vmad1atZ7"
            className="__visly_reset_7X7HTLRqyD __visly_scope_PDnPh1VYdv_8vmad1atZ7"
          />
        ) : (
          <QuantityInputContext.Provider
            key="8vmad1atZ7-provider"
            value={{
              key: "8vmad1atZ7",
              id: "QuantityInput_8vmad1atZ7",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_PDnPh1VYdv_8vmad1atZ7",
              ...getCompositeDefaultProps("8vmad1atZ7"),
            }}
          >
            {props.QuantityInput}
          </QuantityInputContext.Provider>
        ),
        <SpacerPrimitive
          id={"Spacer_M1KdbhwWBq"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_PDnPh1VYdv_M1KdbhwWBq"
          }
          key={"M1KdbhwWBq"}
        />,
        props.DeleteButton === undefined ? (
          <DeleteButton
            key={"UjsPtqNpzT"}
            {...getCompositeDefaultProps("UjsPtqNpzT")}
            id="DeleteButton_UjsPtqNpzT"
            className="__visly_reset_7X7HTLRqyD __visly_scope_PDnPh1VYdv_UjsPtqNpzT"
          />
        ) : (
          <DeleteButtonContext.Provider
            key="UjsPtqNpzT-provider"
            value={{
              key: "UjsPtqNpzT",
              id: "DeleteButton_UjsPtqNpzT",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_PDnPh1VYdv_UjsPtqNpzT",
              ...getCompositeDefaultProps("UjsPtqNpzT"),
            }}
          >
            {props.DeleteButton}
          </DeleteButtonContext.Provider>
        ),
      ]}
    </RootPrimitive>
  );
}

Option.TitleInput = TitleInput;
Option.PriceInput = PriceInput;
Option.QuantityInput = QuantityInput;
Option.DeleteButton = DeleteButton;
Option.__variants = [
  {
    name: "showQuantity",
    type: "variant",
  },
];

export default Option;
