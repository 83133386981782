import React, { useEffect, useState } from "react";
import { Badge } from "../../../hotplate-common/primitives/Badge";
import { Column, Flex } from "../../../hotplate-common/primitives/Containers";
import { styled } from "../../../stitches.config";
import { LineItem } from "./OrderLineItem";
import { OrderInteraction } from "./OrderInteraction";
import { LabeledCustomerBadge } from "../../components/CustomerBadge";
import {
  getTimestampDayOfWeek,
  getReadableTimeSlot,
  getOrderType,
  getReadableOrderType,
} from "@hotplate/utils-ts/helperFunctions";
import { setToastPopupInfo } from "../../../hotplate-storefront/actions";
import { Button } from "../../../hotplate-common/primitives/Button";
import { useToggle } from "../../../hooks";
import { useSelector } from "react-redux";
import {
  trackOrderMarkedComplete,
  trackOrderMarkedIncomplete,
  trackOrderMarkedPacked,
} from "../analytics";

export const HeaderTextRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  ff: "$avenir",
  fw: "$semi_bold",
  lineHeight: 1.5,
  variants: {
    size: {
      small: {
        fs: 14,
        "@media print": {
          fs: 12,
        },
      },
      medium: {
        fs: 16,
        marginBottom: "$xxs",
        "@media print": {
          fs: 14,
        },
      },
    },
  },
});

export const CardHeaderPrimitive = styled("div", {
  $$backgroundColor: "$colors$gray4",
  $$color: "$colors$gray11",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "$$backgroundColor",
  position: "relative",
  color: "$$color",
  borderBottom: "1px solid $gray6",
  padding: 12,
  transition: "opacity 250ms ease-in-out",
  "@media print": {
    display: "block",
    width: "100%",
    backgroundColor: "transparent",
    color: "$gray12",
  },
  variants: {
    type: {
      pickup: { $$backgroundColor: "$colors$info9", $$color: "$colors$white" },
      walk_up: { $$backgroundColor: "$colors$hotplate9", $$color: "$colors$white" },
      delivery: { $$backgroundColor: "$colors$warning9", $$color: "$colors$white" },
      subscription: { $$backgroundColor: "$colors$accent9", $$color: "$colors$white" },
      wholesale: { $$backgroundColor: "$colors$success11", $$color: "$colors$white" },
      catering: { $$backgroundColor: "$colors$success11", $$color: "$colors$white" },
      shipping: { $$backgroundColor: "$colors$gray12", $$color: "$colors$white" },
    },
  },
});

export const CardContent = styled("div", {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  minHeight: 0,
  transition: "opacity 250ms ease-in-out",
  "@meida print": {
    display: "block",
    width: "100%",
    minHeight: "auto",
  },
});

const CardFooterPrimitive = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: 12,
  position: "absolute",
  bottom: 0,
  width: "100%",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  backdropFilter: "blur(1px)",
  borderTop: "1px solid $gray6",
  hideOnPrint: true,
});

const WalkupTimer = styled("div", {
  ff: "$avenir",
  fw: "$bold",
  fs: 24,
  position: "absolute",
  right: 16,
  // center in div
  top: "50%",
  transform: "translateY(-50%)",
});

const CustomFieldName = styled("div", {
  ff: "$avenir",
  fw: "$semi_bold",
  fs: 14,
  lineHeight: 1.5,
  color: "$hotplate11",
  textTransform: "uppercase",
  "@media print": {
    color: "$gray12",
  },
});

const CustomFieldValue = styled("div", {
  ff: "$avenir",
  fw: "$semi_bold",
  fs: 14,
  lineHeight: 1.5,
  color: "$gray12",
});

const CustomFields = ({ order }) => {
  return (
    <>
      {order &&
        Array.isArray(order.customFields) &&
        order.customFields.map((customField, index) => {
          <Flex key={index} css={{ flexWrap: "wrap", gap: 8, pady: 8 }}>
            <CustomFieldName>{`${customField.title}:`}</CustomFieldName>
            <CustomFieldValue>{customField.value}</CustomFieldValue>
          </Flex>;
        })}
      {order.chefNotes && (
        <Flex css={{ flexWrap: "wrap", gap: 8, padding: "8px 4px" }}>
          <CustomFieldName>{`Notes:`}</CustomFieldName>
          <CustomFieldValue>{order.chefNotes}</CustomFieldValue>
        </Flex>
      )}
    </>
  );
};

const CardPrimitive = styled("div", {
  display: "flex",
  position: "relative",
  flexDirection: "column",
  flexGrow: 1,

  borderRadius: "$md",
  overflow: "hidden",
  backgroundColor: "$white",
  border: "1px solid gray6",
  boxShadow: "$elevation4",
  outline: "0px solid transparent",
  transition: "all 250ms ease-in-out",
  variants: {
    complete: {
      true: {
        boxShadow: "0px 0px 0px 0px transparent",
        outline: "1px solid $success6",
        backgroundColor: "$success1",
        [`& ${CardHeaderPrimitive}`]: {
          opacity: 0.5,
        },
        [`& ${CardContent}`]: {
          opacity: 0.5,
        },
        [`& ${CardFooterPrimitive} > div :first-child`]: {
          opacity: 0.7,
        },
      },
    },
    pinned: {
      true: {
        boxShadow: "0px 0px 0px 0px transparent",
        outline: "2px solid $warning9",
      },
    },
  },
  "@media print": {
    display: "inline-block",
    width: "3cm",
    minHeight: "300px",
    backgroundColor: "transparent",
    borderRadius: 0,
    boxShadow: "none",
    margin: "0.25cm",
    border: "1px solid $gray11",
    pageBreakInside: "avoid",
    flexGrow: 0,
    minWidth: 300,
  },
});

export const PackingCard = ({ order, functions, view, ...props }) => {
  const {
    toggleShowCustomerModal,
    setEditOrderData,
    setSubscriptionModalData,
    toggleShowIrpReceipt,
    toggleOrderPinned,
    markLineItemDone,
    getItemQuantity,
    setOrderNotPacked,
    setOrderPacked,
    markAllItemsDone,
  } = functions;
  const {
    packed,
    packed_time,
    orderPlaced,
    pinned,
    cartItems,
    orderId,
    fullName,
    totalCustomerOrdersForChef,
    timeSlot,
    isSubscription,
    isSubscriber,
  } = order;
  let [packButtonLoading, setPackButtonLoading] = useToggle();
  const packingView = useSelector((state) => state.orderManagement.packingView);
  const completedOrdersDisplay = useSelector(
    (state) => state.orderManagement.completedOrdersDisplay
  );
  const primarySort = useSelector((state) => state.orderManagement.sort.primary);
  const trackMetaData = {
    packingView,
    primarySort,
    completedOrdersDisplay,
  };

  function isOrderReadyToBePacked(order) {
    if (!order.cartItems || !Array.isArray(order.cartItems)) return;
    if (order.packed) return false;
    for (let i = 0; i < order.cartItems.length; i++) {
      const item = order.cartItems[i];
      if (item.quantityDone === undefined) return false; // not defined until the first check/uncheck
      if (item.quantityDone < getItemQuantity(item)) return false;
    }

    return true;
  }

  const status = order.packed
    ? "complete"
    : isOrderReadyToBePacked(order)
    ? "ready_to_complete"
    : "incomplete";

  const type = getOrderType(order);

  let [currTime, setCurrTime] = useState(
    Array.isArray(order.cartItems) && order.packed ? order.packed_time : Date.now()
  );

  useEffect(() => {
    if (getOrderType(order) === "walk_up" && !order.packed) {
      const interval = setInterval(() => {
        setCurrTime(Date.now());
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
    if (packed) setCurrTime(packed_time);
  }, [order, packed, packed_time]);

  // this WAS currTime = order.packed_time; but that seemed wrong
  let delta = (currTime - orderPlaced) / 1000;
  const minutes = Math.floor(delta / 60);
  delta -= minutes * 60;
  let seconds = Math.floor(delta % 60);
  if (seconds.toString().length === 1) seconds = "0" + seconds.toString();

  const packButtonStyles =
    view === "grid"
      ? { marginRight: 8, flexShrink: 0, flexGrow: 1 }
      : { marginTop: 8, flexShrink: 0, flexGrow: 1 };
  const packButtonByStatus = {
    ready_to_complete: (
      <Button
        css={packButtonStyles}
        color="success"
        variant="tinted"
        loading={packButtonLoading}
        onClick={async () => {
          setPackButtonLoading(true);
          try {
            await setOrderPacked(order);
          } catch (e) {
            setToastPopupInfo({
              type: "error",
              text: `Failed to mark complete: ${e}`,
            });
            throw e;
          } finally {
            trackOrderMarkedComplete({
              ...trackMetaData,
              orderId: order.paymentIntentId,
            });
            setPackButtonLoading(false);
          }
        }}
      >
        Mark Complete
      </Button>
    ),
    complete: (
      <Button
        css={packButtonStyles}
        color="warning"
        variant="tinted"
        loading={packButtonLoading}
        onClick={async () => {
          setPackButtonLoading(true);
          try {
            await setOrderNotPacked(order);
          } catch (e) {
            setToastPopupInfo({
              type: "error",
              text: `Failed to mark incomplete: ${e}`,
            });
            throw e;
          } finally {
            trackOrderMarkedIncomplete({
              ...trackMetaData,
              orderId: order.paymentIntentId,
            });
            setPackButtonLoading(false);
          }
        }}
      >
        Mark Incomplete
      </Button>
    ),
    incomplete: (
      <Button
        css={packButtonStyles}
        color="info"
        variant="tinted"
        loading={packButtonLoading}
        onClick={async () => {
          setPackButtonLoading(true);
          try {
            await markAllItemsDone({
              chefId: order.chefId,
              paymentIntentId: order.paymentIntentId,
            });
          } catch (e) {
            setToastPopupInfo({
              type: "error",
              text: `Failed to mark all items as packed: ${e}`,
            });
            throw e;
          } finally {
            trackOrderMarkedPacked({
              ...trackMetaData,
              orderId: order.paymentIntentId,
            });
            setPackButtonLoading(false);
          }
        }}
      >
        Mark All as Packed
      </Button>
    ),
  };

  return (
    <CardPrimitive complete={packed} pinned={pinned} {...props}>
      {/* Header */}
      <CardHeaderPrimitive type={type}>
        {/* Customer Name && Fulfillment Time */}
        <HeaderTextRow size="medium">
          <LabeledCustomerBadge
            fullName={fullName}
            numOrders={totalCustomerOrdersForChef}
            onClick={() => toggleShowCustomerModal(view, order)}
          />
          <Flex css={{ textAlign: "right" }}>
            {type !== "walk_up" ? getReadableTimeSlot(timeSlot) : ""}
          </Flex>
        </HeaderTextRow>
        {/* Order # && Fulfillment Date */}
        <HeaderTextRow size="small">
          <Flex>
            {`#${orderId}`}
            {isSubscriber && (
              <Badge size="small" css={{ marginLeft: 6, hideOnPrint: true }}>
                SUB
              </Badge>
            )}
          </Flex>
          <Flex css={{ textAlign: "right" }}>
            {type !== "walk_up"
              ? `${getTimestampDayOfWeek(timeSlot.startTime, true)}. ${
                  new Date(timeSlot.startTime).getMonth() + 1
                }/${new Date(timeSlot.startTime).getDate()}`
              : ""}
          </Flex>
        </HeaderTextRow>
        {type === "walk_up" && <WalkupTimer>{`${minutes}:${seconds}`}</WalkupTimer>}
      </CardHeaderPrimitive>
      {/* Content: Items, custom fields, etc. */}
      <CardContent>
        <Column
          css={{
            padding: "$xs $xs 110px",
            overflow: "auto",
            "@media print": { paddingBottom: "$xs" },
          }}
        >
          <CustomFields order={order} />
          {cartItems
            .filter(
              (item) =>
                !Number.isInteger(item.restockQuantity) || item.restockQuantity < item.quantity
            )
            .map((item, index) => (
              <LineItem
                view="card"
                key={index}
                item={item}
                itemIndex={index}
                order={order}
                functions={{
                  markLineItemDone: markLineItemDone,
                  getItemQuantity: getItemQuantity,
                }}
              />
            ))}
        </Column>
      </CardContent>
      {/* Footer */}
      {view === "grid" ? (
        <Flex
          css={{
            padding: "0px $xs $sm $sm",
            width: "100%",
            alignItems: "center",
            position: "absolute",
            bottom: 0,
          }}
        >
          {packButtonByStatus[status]}
          <OrderInteraction
            order={order}
            view="grid"
            functions={{
              toggleShowCustomerModal: toggleShowCustomerModal,
              setEditOrderData: setEditOrderData,
              setSubscriptionModalData: setSubscriptionModalData,
              toggleShowIrpReceipt: toggleShowIrpReceipt,
              toggleOrderPinned: toggleOrderPinned,
            }}
          />
        </Flex>
      ) : (
        <CardFooterPrimitive>
          <Flex css={{ justifyContent: "space-between", alignItems: "center" }}>
            <Flex css={{ alignItems: "center", marginRight: 12 }}>
              <Badge type={type}>{getReadableOrderType(order)}</Badge>
              {isSubscription && (
                <Badge css={{ marginLeft: 6 }} type="subscription">
                  Subscription
                </Badge>
              )}
            </Flex>
            <OrderInteraction
              order={order}
              view="card"
              functions={{
                toggleShowCustomerModal: toggleShowCustomerModal,
                setEditOrderData: setEditOrderData,
                setSubscriptionModalData: setSubscriptionModalData,
                toggleShowIrpReceipt: toggleShowIrpReceipt,
                toggleOrderPinned: toggleOrderPinned,
              }}
            />
          </Flex>
          {packButtonByStatus[status]}
        </CardFooterPrimitive>
      )}
    </CardPrimitive>
  );
};
