// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./WithdrawInfo.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as Checkbox, CheckboxContext } from "../Primitives/Checkbox";
import {
  default as WithdrawButton,
  ButtonContext as WithdrawButtonContext,
} from "../Primitives/Button";
import { default as AccountInfo } from "./WithdrawInfo.AccountInfo";

const styles = [
  {
    type: "default",
    layers: {
      LAXXz9WmQp: {
        none: {
          text: "345.56",
        },
      },
      "8Ff64VBHEU": {
        none: {
          text: "Available to Withdraw",
        },
      },
      "2RK5BLxkfJ": {
        none: {
          text: "",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "editing",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      JXCAesCyAv: {},
      "5JFcqFAFeu": {
        none: {
          text: "Initiate Withdrawal",
          large: true,
          kind: "good",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "editing",
    layers: {
      JXCAesCyAv: {},
      "5JFcqFAFeu": {
        none: {
          disabled: true,
        },
      },
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "editing",
  },
];

export const WithdrawInfoContext = createContext(null);

function WithdrawInfo(_props) {
  const defaults = useContext(WithdrawInfoContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="JvEmCUE1Nq"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "JvEmCUE1Nq",
        scope: "XYAiDKjL6Q",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_CEhQczxiJJ"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_XYAiDKjL6Q_CEhQczxiJJ"
          }
          key={"CEhQczxiJJ"}
          addSpacing={false}
          element={getStyle("CEhQczxiJJ", "htmlElement")}
        >
          <ContainerPrimitive
            id={"balance_TkZTfhfj4N"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_XYAiDKjL6Q_TkZTfhfj4N"
            }
            key={"TkZTfhfj4N"}
            addSpacing={true}
            element={getStyle("TkZTfhfj4N", "htmlElement")}
          >
            <TextPrimitive
              id={"balance_LAXXz9WmQp"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_XYAiDKjL6Q_LAXXz9WmQp"
              }
              key={"LAXXz9WmQp"}
              text={
                exists(props.balance)
                  ? props.balance
                  : getStyle("LAXXz9WmQp", "text")
              }
              element={getStyle("LAXXz9WmQp", "htmlElement")}
            />
            <TextPrimitive
              id={"Text_8Ff64VBHEU"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_XYAiDKjL6Q_8Ff64VBHEU"
              }
              key={"8Ff64VBHEU"}
              text={getStyle("8Ff64VBHEU", "text")}
              element={getStyle("8Ff64VBHEU", "htmlElement")}
            />
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"Container_JWThFn9289"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_XYAiDKjL6Q_JWThFn9289"
            }
            key={"JWThFn9289"}
            addSpacing={false}
            element={getStyle("JWThFn9289", "htmlElement")}
          >
            {props.AccountInfo === undefined ? (
              <AccountInfo key={"GLuFbudvRr"} />
            ) : (
              props.AccountInfo
            )}
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"tos_YFqHDLTWPm"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_XYAiDKjL6Q_YFqHDLTWPm"
            }
            key={"YFqHDLTWPm"}
            addSpacing={false}
            element={getStyle("YFqHDLTWPm", "htmlElement")}
          >
            {[
              props.Checkbox === undefined ? (
                <Checkbox
                  key={"JXCAesCyAv"}
                  {...getCompositeDefaultProps("JXCAesCyAv")}
                  id="Checkbox_JXCAesCyAv"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_XYAiDKjL6Q_JXCAesCyAv"
                />
              ) : (
                <CheckboxContext.Provider
                  key="JXCAesCyAv-provider"
                  value={{
                    key: "JXCAesCyAv",
                    id: "Checkbox_JXCAesCyAv",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_XYAiDKjL6Q_JXCAesCyAv",
                    ...getCompositeDefaultProps("JXCAesCyAv"),
                  }}
                >
                  {props.Checkbox}
                </CheckboxContext.Provider>
              ),
              <SpacerPrimitive
                id={"Spacer_QqAgsmNYDX"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_XYAiDKjL6Q_QqAgsmNYDX"
                }
                key={"QqAgsmNYDX"}
              />,
              <ContainerPrimitive
                id={"Container_EbjNouDfJq"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_XYAiDKjL6Q_EbjNouDfJq"
                }
                key={"EbjNouDfJq"}
                addSpacing={false}
                element={getStyle("EbjNouDfJq", "htmlElement")}
              >
                {
                  <ContainerPrimitive
                    key={"VbdusXkts2"}
                    id={"Slot_VbdusXkts2"}
                    className={
                      "__visly_reset_7X7HTLRqyD __visly_scope_XYAiDKjL6Q_VbdusXkts2"
                    }
                    addSpacing={false}
                  >
                    {props.link}
                  </ContainerPrimitive>
                }
              </ContainerPrimitive>,
            ]}
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"widthdraw-button-container_AkwZ4yc2pH"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_XYAiDKjL6Q_AkwZ4yc2pH"
            }
            key={"AkwZ4yc2pH"}
            addSpacing={false}
            element={getStyle("AkwZ4yc2pH", "htmlElement")}
          >
            {[
              props.WithdrawButton === undefined ? (
                <WithdrawButton
                  key={"5JFcqFAFeu"}
                  {...getCompositeDefaultProps("5JFcqFAFeu")}
                  id="WithdrawButton_5JFcqFAFeu"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_XYAiDKjL6Q_5JFcqFAFeu"
                />
              ) : (
                <WithdrawButtonContext.Provider
                  key="5JFcqFAFeu-provider"
                  value={{
                    key: "5JFcqFAFeu",
                    id: "WithdrawButton_5JFcqFAFeu",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_XYAiDKjL6Q_5JFcqFAFeu",
                    ...getCompositeDefaultProps("5JFcqFAFeu"),
                  }}
                >
                  {props.WithdrawButton}
                </WithdrawButtonContext.Provider>
              ),
              <TextPrimitive
                id={"error_2RK5BLxkfJ"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_XYAiDKjL6Q_2RK5BLxkfJ"
                }
                key={"2RK5BLxkfJ"}
                text={
                  exists(props.error)
                    ? props.error
                    : getStyle("2RK5BLxkfJ", "text")
                }
                element={getStyle("2RK5BLxkfJ", "htmlElement")}
              />,
            ]}
          </ContainerPrimitive>
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

WithdrawInfo.Checkbox = Checkbox;
WithdrawInfo.WithdrawButton = WithdrawButton;
WithdrawInfo.__variants = [
  {
    name: "editing",
    type: "variant",
  },
];

export default WithdrawInfo;
