/* eslint-disable react/prop-types */
import React from "react";
import { Box } from "../../hotplate-common/primitives/Containers";

const ScreenshotWidget = () => (
  <Box
    id="ScreenshotWidget"
    css={{
      position: "fixed",
      top: 20,
      right: 20,
      height: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "75px",
      borderRadius: "16px",
      boxShadow: "0px 3px 6px rgba(0, 0, 0, .46)",
      backgroundColor: "#e85b5b",
      zIndex: 1000000,
      "@media print": {
        display: "none",
      },
    }}
  >
    <p style={{ color: "white" }}>TEST</p>
  </Box>
);
export default ScreenshotWidget;
