/* eslint-disable react/prop-types */
import React from "react";
import ReactLoading from "react-loading";
import { useBreakpoint } from "../../visly";
import { AddEditModal } from "../../visly/Events";

export default function AddEditModalMaster({
  children,
  title,
  noTitle,
  subtitle,
  saveDisabled,
  editing,
  cancelButtonText,
  cancelButtonOnClick,
  saveButtonText,
  saveButtonOnClick,
  saveButtonLoading,
  secondaryDeleteButton,
  deleteButtonText,
  deleteButtonOnClick,
  deleteButtonLoading,
  deleteMessage,
  setOptionSelectModalState,
  style,
}) {
  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);

  return (
    <>
      <AddEditModal
        style={style}
        noTitle={noTitle}
        size={size}
        Header={<AddEditModal.Header title={title} subtitle={subtitle ? subtitle : ""} />}
        editing={editing}
        DeleteButton={
          <AddEditModal.DeleteButton
            text={deleteButtonText ? deleteButtonText : "Delete"}
            loading={deleteButtonLoading}
            loadingAnimation={
              <ReactLoading type={"spin"} color={"#FFFFFF"} height={"24px"} width={"24px"} />
            }
            onClick={() =>
              setOptionSelectModalState(deleteMessage, [
                {
                  text: "Delete",
                  type: "warning",
                  onClick: deleteButtonOnClick,
                },
              ])
            }
          />
        }
        SecondaryDeleteButton={secondaryDeleteButton}
        CancelButton={
          <AddEditModal.CancelButton
            onClick={cancelButtonOnClick}
            text={cancelButtonText ? cancelButtonText : "Discard"}
          />
        }
        SaveButton={
          <AddEditModal.SaveButton
            text={saveButtonText ? saveButtonText : "Save"}
            disabled={saveDisabled}
            onClick={saveButtonOnClick}
            loading={saveButtonLoading}
            loadingAnimation={
              <ReactLoading type={"spin"} color={"#FFFFFF"} height={"24px"} width={"24px"} />
            }
          />
        }
      >
        {children}
      </AddEditModal>
    </>
  );
}
