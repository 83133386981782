// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./AdvancedSettings.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, SpacerPrimitive } from "../_internal_primitives";
import {
  default as EventConfigurationSetting,
  EventConfigurationSettingContext,
} from "../Events/EventConfigurationSetting";
import {
  default as LabeledInput,
  LabeledInputContext,
} from "../Events/LabeledInput";

const styles = [
  {
    type: "default",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      "3j7qamkyQ5": {},
      KLiwP4Sv96: {},
    },
  },
];

const variantPropTypes = [];

export const AdvancedSettingsContext = createContext(null);

function AdvancedSettings(_props) {
  const defaults = useContext(AdvancedSettingsContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="5garXD5dy2"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "5garXD5dy2",
        scope: "2XZntXfgdy",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => [
        <SpacerPrimitive
          id={"Spacer_4tMvvVBGif"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_2XZntXfgdy_4tMvvVBGif"
          }
          key={"4tMvvVBGif"}
        />,
        props.EventConfigurationSetting === undefined ? (
          <EventConfigurationSetting
            key={"3j7qamkyQ5"}
            {...getCompositeDefaultProps("3j7qamkyQ5")}
            id="EventConfigurationSetting_3j7qamkyQ5"
            className="__visly_reset_7X7HTLRqyD __visly_scope_2XZntXfgdy_3j7qamkyQ5"
          />
        ) : (
          <EventConfigurationSettingContext.Provider
            key="3j7qamkyQ5-provider"
            value={{
              key: "3j7qamkyQ5",
              id: "EventConfigurationSetting_3j7qamkyQ5",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_2XZntXfgdy_3j7qamkyQ5",
              ...getCompositeDefaultProps("3j7qamkyQ5"),
            }}
          >
            {props.EventConfigurationSetting}
          </EventConfigurationSettingContext.Provider>
        ),
        <SpacerPrimitive
          id={"Spacer_FnU449SR5u"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_2XZntXfgdy_FnU449SR5u"
          }
          key={"FnU449SR5u"}
        />,
        props.LabeledInput === undefined ? (
          <LabeledInput
            key={"KLiwP4Sv96"}
            {...getCompositeDefaultProps("KLiwP4Sv96")}
            id="LabeledInput_KLiwP4Sv96"
            className="__visly_reset_7X7HTLRqyD __visly_scope_2XZntXfgdy_KLiwP4Sv96"
          />
        ) : (
          <LabeledInputContext.Provider
            key="KLiwP4Sv96-provider"
            value={{
              key: "KLiwP4Sv96",
              id: "LabeledInput_KLiwP4Sv96",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_2XZntXfgdy_KLiwP4Sv96",
              ...getCompositeDefaultProps("KLiwP4Sv96"),
            }}
          >
            {props.LabeledInput}
          </LabeledInputContext.Provider>
        ),
      ]}
    </RootPrimitive>
  );
}

AdvancedSettings.EventConfigurationSetting = EventConfigurationSetting;
AdvancedSettings.LabeledInput = LabeledInput;
AdvancedSettings.__variants = [];

export default AdvancedSettings;
