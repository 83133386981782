import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import localForage from "localforage";
import LoginReducer from "./LoginReducer";
import StorefrontReducer from "./StorefrontReducer";
import SubscriptionReducer from "./SubscriptionReducer";
import CartReducer from "./CartReducer";
import ConfirmationReducer from "./ConfirmationReducer";
import HostPortalReducer from "./HostPortalReducer";
import PayoutReducer from "./PayoutReducer";
import OrderManagementReducer from "./OrderManagementReducer";
import TestingReducer from "./TestingReducer";
import EventsReducer from "./EventsReducer";
import packageJson from "../../../package.json";
const versionNumber = packageJson.version.split(".").splice(0, 2).join(".");

import { LOGOUT_USER, IS_PROD, OVERRIDE_STORE_STATE, IS_STAGING } from "../actions/types";

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    state = undefined;
    if (!action.payload) window.location.assign("/");
  }
  if (action.type === OVERRIDE_STORE_STATE) {
    state = action.payload;
  }
  // console.log('state before action', state);
  if (!IS_PROD && !IS_STAGING) {
    // WILL CAUSE MEMORY LEAKS
    console.log("new_action", action);
    console.log("state before action", state);
  } else if (action) {
    // console.log('new_action', action.type);
  }
  return appReducer(state, action);
};

const storefrontPersistConfig = {
  key: "storefront:" + versionNumber,
  storage: localForage,
  whitelist: ["customerAddressDict", "customerDeliveryInstructions", "deliveryFee"],
};

const cartPersistConfig = {
  key: "cart:" + versionNumber,
  storage: localForage,
  whitelist: ["cartId"],
};

const confirmationPersistConfig = {
  key: "confirmation:" + versionNumber,
  storage: localForage,
  whitelist: ["localOrder"],
};

const hostPortalPersistConfig = {
  key: "hostPortal:" + versionNumber,
  storage: localForage,
  whitelist: [],
};

const loginPersistConfig = {
  key: "login:" + versionNumber,
  storage: localForage,
  whitelist: ["userInfo"],
};

const orderManagementPersistConfig = {
  key: "orderManagement:" + versionNumber,
  storage: localForage,
  whitelist: [
    "orderManagementAction",
    "packingView",
    "packingFilter",
    "prevPackingFilter",
    "sort",
    "completedOrdersDisplay",
  ],
};

const payoutPersistConfig = {
  key: "payout:" + versionNumber,
  storage: localForage,
  whitelist: [],
};

const testingPersistConfig = {
  key: "testing:" + versionNumber,
  storage: localForage,
  whitelist: [
    "uploadComponentTime",
    "uploadComponentFailure",
    "urlName",
    "uploadGlobalComponentFailure",
  ], // Probably broken
};

const subscriptionPersistConfig = {
  key: "subscription:" + versionNumber,
  storage: localForage,
  whitelist: [],
};

const eventsPersistConfig = {
  key: "events:" + versionNumber,
  storage: localForage,
  whitelist: [],
};

const appReducer = combineReducers({
  login: persistReducer(loginPersistConfig, LoginReducer),
  storefront: persistReducer(storefrontPersistConfig, StorefrontReducer),
  cart: persistReducer(cartPersistConfig, CartReducer),
  confirmation: persistReducer(confirmationPersistConfig, ConfirmationReducer),
  hostPortal: persistReducer(hostPortalPersistConfig, HostPortalReducer),
  payout: persistReducer(payoutPersistConfig, PayoutReducer),
  orderManagement: persistReducer(orderManagementPersistConfig, OrderManagementReducer),
  testing: persistReducer(testingPersistConfig, TestingReducer),
  subscription: persistReducer(subscriptionPersistConfig, SubscriptionReducer),
  events: persistReducer(eventsPersistConfig, EventsReducer),
});

export default rootReducer;
