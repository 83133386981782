// Auth.js types
export const LOAD_USER_INFO_START = "load_user_info_start";
export const LOAD_USER_INFO_SUCCESS = "load_user_info_success";
export const LOAD_USER_INFO_FAILURE = "load_user_info_failure";

export const SIGNUP_USER_SUCCESS = "create_user_info_success";

export const LOGOUT_USER = "logout_user";

// Cart.js types;
export const SET_CART_ID = "set_cart_id";
export const SET_CART_COUNTDOWN = "set_cart_countdown";

// Storefront.js types

export const GET_SITE_SETTINGS_START = "get_site_setting_start";
export const GET_SITE_SETTINGS_SUCCESS = "get_site_setting_success";
export const GET_SITE_SETTINGS_FAILURE = "get_site_setting_failure";

export const SET_DELIVERY_FEE = "set_delivery_fee";
export const SET_CUSTOMER_DELIVERY_INSTRUCTIONS = "set_customer_delivery_instructions";
export const SET_CUSTOMER_ADDRESS_UNIT = "set_customer_address_unit";
export const SET_CUSTOMER_ADDRESS_DICT = "set_customer_address_dict";

// HostPortal.js types

export const SET_TOAST_POPUP_INFO = "set_toast_popup_info";

export const CONNECT_TO_CHEF_INFO_START = "connect_to_chef_info_start";
export const CONNECT_TO_CHEF_INFO_SUCCESS = "connect_to_chef_info_success";
export const CONNECT_TO_CHEF_INFO_FAILURE = "connect_to_chef_info_failure";
export const DISCONNECT_FROM_CHEF_INFO_START = "disconnect_to_chef_info_start";
export const DISCONNECT_FROM_CHEF_INFO_SUCCESS = "disconnect_to_chef_info_success";
export const DISCONNECT_FROM_CHEF_INFO_FAILURE = "disconnect_to_chef_info_failure";

export const GET_GOD_MODE_OPTIONS_START = "get_god_mode_options_start";
export const GET_GOD_MODE_OPTIONS_SUCCESS = "get_god_mode_options_success";
export const GET_GOD_MODE_OPTIONS_FAILURE = "get_god_mode_options_failure";

export const CONNECT_TO_CHEF_INFO_EVENTS_SUCCESS = "connect_to_chef_info_events_success";
export const CONNECT_TO_CHEF_INFO_MEMBERSHIPS_SUCCESS = "connect_to_chef_info_memberships_success";
export const CONNECT_TO_CHEF_INFO_LOCATIONS_SUCCESS = "connect_to_chef_info_locations_success";
export const CONNECT_TO_CHEF_INFO_MENU_ITEMS_SUCCESS = "connect_to_chef_info_menu_items_success";
export const CONNECT_TO_CHEF_INFO_HOST_PHONE_SUCCESS = "connect_to_chef_info_host_phone_success";
export const CONNECT_TO_CHEF_INFO_EMAIL_SUCCESS = "connect_to_chef_info_email_success";
export const CONNECT_TO_CHEF_INFO_INSTA_SUCCESS = "connect_to_chef_info_insta_success";
export const CONNECT_TO_CHEF_INFO_PERMISSIONS_START = "connect_to_chef_info_permissions_start";
export const CONNECT_TO_CHEF_INFO_PERMISSIONS_SUCCESS = "connect_to_chef_info_permissions_success";
export const CONNECT_TO_CHEF_INFO_PERMISSIONS_FAILURE = "connect_to_chef_info_permissions_failure";
export const CONNECT_TO_CHEF_INFO_SITE_SETTINGS_START = "connect_to_chef_info_site_settings_start";
export const CONNECT_TO_CHEF_INFO_SITE_SETTINGS_SUCCESS =
  "connect_to_chef_info_site_settings_success";
export const CONNECT_TO_CHEF_INFO_SITE_SETTINGS_FAILURE =
  "connect_to_chef_info_site_settings_failure";
export const CONNECT_TO_CHEF_INFO_ROUTABLE_INFO_START = "connect_to_chef_info_routable_info_start";
export const CONNECT_TO_CHEF_INFO_ROUTABLE_INFO_SUCCESS =
  "connect_to_chef_info_routable_info_success";
export const CONNECT_TO_CHEF_INFO_ROUTABLE_INFO_FAILURE =
  "connect_to_chef_info_routable_info_failure";
export const CONNECT_TO_CHEF_INFO_CUSTOMER_SUBSCRIPTION_ID_SUCCESS =
  "connect_to_chef_info_customer_subscription_id_success";
export const CONNECT_TO_CHEF_INFO_DISCOUNT_CODES_SUCCESS =
  "connect_to_chef_info_discount_codes_success";

export const DISCONNECT_FROM_CHEF_INFO_EVENTS_SUCCESS = "disconnect_from_chef_info_events_success";
export const DISCONNECT_FROM_CHEF_INFO_LOCATIONS_SUCCESS =
  "disconnect_from_chef_info_locations_success";
export const DISCONNECT_FROM_CHEF_INFO_MENU_ITEMS_SUCCESS =
  "disconnect_from_chef_info_menu_items_success";
export const DISCONNECT_FROM_CHEF_INFO_SITE_SETTINGS_SUCCESS =
  "disconnect_from_chef_info_site_settings_success";
export const DISCONNECT_FROM_CHEF_INFO_ROUTABLE_INFO_SUCCESS =
  "disconnect_from_chef_info_routable_info_success";
export const DISCONNECT_FROM_CHEF_INFO_CUSTOMER_SUBSCRIPTION_ID_SUCCESS =
  "disconnect_from_chef_info_customer_subscription_id_success";
export const DISCONNECT_FROM_CHEF_INFO_DISCOUNT_CODES_SUCCESS =
  "disconnect_from_chef_info_discount_codes_success";

export const DISCONNECT_FROM_CHEF_INFO_EMAIL_SUCCESS = "disconnect_from_chef_info_email_success";
export const DISCONNECT_FROM_CHEF_INFO_INSTA_HANDLE_SUCCESS =
  "disconnect_from_chef_info_insta_handle_success";
export const DISCONNECT_FROM_CHEF_INFO_HOST_PHONE_SUCCESS =
  "disconnect_from_chef_info_host_phone_success";
export const DISCONNECT_FROM_CHEF_INFO_MEMBERSHIPS_SUCCESS =
  "disconnect_from_chef_info_memberships_success";
export const DISCONNECT_FROM_CHEF_INFO_PERMISSIONS_SUCCESS =
  "disconnect_from_chef_info_permissions_success";

// AccountSettings.js types
export const SAVE_ACCOUNT_SETTINGS_START = "save_account_settings_start";
export const SAVE_ACCOUNT_SETTINGS_SUCCESS = "save_account_settings_success";
export const SAVE_ACCOUNT_SETTINGS_FAILURE = "save_account_settings_failure";

// Confirmation.js types
export const CONNECT_TO_CUSTOMER_ORDER_START = "connect_to_customer_order_start";
export const CONNECT_TO_CUSTOMER_ORDER_SUCCESS = "connect_to_customer_order_success";
export const CONNECT_TO_CUSTOMER_ORDER_FAILURE = "connect_to_customer_order_failure";
export const SET_CONFIRMATION_ORDER = "set_confirmation_order";

// OrderManagement types
export const CONNECT_TO_EVENT_ORDERS_START = "connect_to_event_orders_start";
export const CONNECT_TO_EVENT_ORDERS_SUCCESS = "connect_to_event_orders_success";
export const CONNECT_TO_EVENT_ORDERS_FAILURE = "connect_to_event_orders_failure";
export const DISCONNECT_FROM_EVENT_ORDERS_START = "disconnect_to_event_orders_start";
export const DISCONNECT_FROM_EVENT_ORDERS_SUCCESS = "disconnect_to_event_orders_success";
export const DISCONNECT_FROM_EVENT_ORDERS_FAILURE = "disconnect_to_event_orders_failure";
export const SET_ORDER_MANAGEMENT_ACTION = "set_order_management_action";
export const UPDATE_SECTIONS_IN_PROGRESS = "update_sections_in_progress";
export const SET_PACKING_FILTER = "set_packing_filter";
export const SET_ACTIVE_FILTERS = "set_active_filters";
export const SET_PRIMARY_ORDERS_SORT = "set_primary_orders_sort";
export const SET_COMPLETED_ORDERS_DISPLAY = "set_completed_orders_display";
export const UPLOAD_CSV_START = "upload_csv_start";
export const UPLOAD_CSV_SUCCESS = "upload_csv_success";
export const UPLOAD_CSV_FAILURE = "upload_csv_failure";
export const NOTIFY_CUSTOMER_START = "notify_customer_start";
export const NOTIFY_CUSTOMER_FAILURE = "notify_customer_failure";
export const NOTIFY_CUSTOMER_SUCCESS = "notify_customer_success";
export const SET_PACKING_VIEW = "set_packing_view";
export const EDIT_ORDER_START = "edit_order_start";
export const EDIT_ORDER_SUCCESS = "edit_order_success";
export const EDIT_ORDER_FAILURE = "edit_order_failure";
export const INITIATE_CUSTOMER_REFUND_START = "initiate_customer_refund_start";
export const INITIATE_CUSTOMER_REFUND_SUCCESS = "initiate_customer_refund_success";
export const INITIATE_CUSTOMER_REFUND_FAILURE = "initiate_customer_refund_failure";
export const CONNECT_TO_ORDERS_START = "connect_to_orders_start";
export const CONNECT_TO_ORDERS_SUCCESS = "connect_to_orders_success";
export const CONNECT_TO_ORDERS_FAILURE = "connect_to_orders_failure";
export const DISCONNECT_FROM_ORDERS_START = "disconnect_from_orders_start";
export const DISCONNECT_FROM_ORDERS_SUCCESS = "disconnect_from_orders_success";
export const DISCONNECT_FROM_ORDERS_FAILURE = "disconnect_from_orders_failure";
export const GET_TRANSACTION_ORDERS_START = "get_transaction_orders_start";
export const GET_TRANSACTION_ORDERS_SUCCESS = "get_transaction_orders_success";
export const GET_TRANSACTION_ORDERS_FAILURE = "get_transaction_orders_failure";

export const CONNECT_TO_CUSTOMER_CHEF_ORDERS_START = "connect_to_customer_chef_orders_start";
export const CONNECT_TO_CUSTOMER_CHEF_ORDERS_SUCCESS = "connect_to_customer_chef_orders_success";
export const CONNECT_TO_CUSTOMER_CHEF_ORDERS_FAILURE = "connect_to_customer_chef_orders_failure";
export const DISCONNECT_FROM_CUSTOMER_CHEF_ORDERS_START =
  "disconnect_from_customer_chef_orders_start";
export const DISCONNECT_FROM_CUSTOMER_CHEF_ORDERS_SUCCESS =
  "disconnect_from_customer_chef_orders_success";
export const DISCONNECT_FROM_CUSTOMER_CHEF_ORDERS_FAILURE =
  "disconnect_from_customer_chef_orders_failure";
export const CONNECT_TO_ITEMIZED_RECEIPT_ORDER_START = "connect_to_itemized_receipt_order_start";
export const CONNECT_TO_ITEMIZED_RECEIPT_ORDER_SUCCESS =
  "connect_to_itemized_receipt_order_success";
export const CONNECT_TO_ITEMIZED_RECEIPT_ORDER_FAILURE =
  "connect_to_itemized_receipt_order_failure";
export const DISCONNECT_FROM_ITEMIZED_RECEIPT_ORDER_START =
  "disconnect_from_itemized_receipt_order_start";
export const DISCONNECT_FROM_ITEMIZED_RECEIPT_ORDER_SUCCESS =
  "disconnect_from_itemized_receipt_order_success";
export const DISCONNECT_FROM_ITEMIZED_RECEIPT_ORDER_FAILURE =
  "disconnect_from_itemized_receipt_order_failure";

// Payout.js types
export const CREATE_PARTNERSHIP_START = "create_partnership_start";
export const CREATE_PARTNERSHIP_FAILURE = "create_partnership_failure";
export const CREATE_PARTNERSHIP_SUCCESS = "create_partnership_success";
export const ADD_FUNDING_ACCOUNT_START = "add_funding_start";
export const ADD_FUNDING_ACCOUNT_FAILURE = "add_funding_failure";
export const ADD_FUNDING_ACCOUNT_SUCCESS = "add_funding_success";
export const INITIATE_PAYMENT_START = "initiate_payment_start";
export const INITIATE_PAYMENT_FAILURE = "initiate_payment_failure";
export const INITIATE_PAYMENT_SUCCESS = "initiate_payment_success";

// Testing.js types
export const UPLOAD_COMPONENT_STATE_START = "upload_component_state_start";
export const UPLOAD_COMPONENT_STATE_SUCCESS = "upload_component_state_success";
export const UPLOAD_COMPONENT_STATE_FAILURE = "upload_component_state_failure";
export const OVERRIDE_STORE_STATE = "override_store_state";

// Subscription.js types

export const CONNECT_TO_MEMBERSHIP_START = "connect_to_membership_start";
export const CONNECT_TO_MEMBERSHIP_SUCCESS = "connect_to_membership_success";
export const CONNECT_TO_MEMBERSHIP_FAILURE = "connect_to_membership_failure";
export const DISCONNECT_FROM_MEMBERSHIP_START = "disconnect_from_membership_start";
export const DISCONNECT_FROM_MEMBERSHIP_SUCCESS = "disconnect_from_membership_success";
export const DISCONNECT_FROM_MEMBERSHIP_FAILURE = "disconnect_from_membership_failure";

export const EDIT_CUSTOMER_GLOBALS_START = "edit_customer_globals_start";
export const EDIT_CUSTOMER_GLOBALS_SUCCESS = "edit_customer_globals_success";
export const EDIT_CUSTOMER_GLOBALS_FAILURE = "edit_customer_globals_failure";

export const CONNECT_TO_CUSTOMER_GLOBALS_START = "connect_to_customer_globals_start";
export const CONNECT_TO_CUSTOMER_GLOBALS_FIRST_NAME_SUCCESS =
  "connect_to_customer_globals_first_name_success";
export const CONNECT_TO_CUSTOMER_GLOBALS_LAST_NAME_SUCCESS =
  "connect_to_customer_globals_last_name_success";
export const CONNECT_TO_CUSTOMER_GLOBALS_NAME_SUCCESS = "connect_to_customer_globals_name_success";
export const CONNECT_TO_CUSTOMER_GLOBALS_EMAIL_SUCCESS =
  "connect_to_customer_globals_email_success";
export const CONNECT_TO_CUSTOMER_GLOBALS_CUSTOMER_ADDRESS_DICT_SUCCESS =
  "connect_to_customer_globals_customer_adress_dict_success";
export const CONNECT_TO_CUSTOMER_GLOBALS_PAYMENT_METHOD_SUCCESS =
  "connect_to_customer_globals_payment_methode_success";
export const CONNECT_TO_CUSTOMER_GLOBALS_SUCCESS = "connect_to_customer_globals_success";
export const CONNECT_TO_CUSTOMER_GLOBALS_FAILURE = "connect_to_customer_globals_failure";

export const DISCONNECT_FROM_CUSTOMER_GLOBALS_START = "disconnect_from_customer_globals_start";
export const DISCONNECT_FROM_CUSTOMER_GLOBALS_SUCCESS = "disconnect_from_customer_globals_success";
export const DISCONNECT_FROM_CUSTOMER_GLOBALS_FAILURE = "disconnect_from_customer_globals_failure";
export const DISCONNECT_FROM_CUSTOMER_GLOBALS_FIRST_NAME_SUCCESS =
  "disconnect_from_customer_globals_first_name_success";
export const DISCONNECT_FROM_CUSTOMER_GLOBALS_LAST_NAME_SUCCESS =
  "disconnect_from_customer_globals_last_name_success";
export const DISCONNECT_FROM_CUSTOMER_GLOBALS_NAME_SUCCESS =
  "disconnect_from_customer_globals_name_success";
export const DISCONNECT_FROM_CUSTOMER_GLOBALS_EMAIL_SUCCESS =
  "disconnect_from_customer_globals_email_success";
export const DISCONNECT_FROM_CUSTOMER_GLOBALS_CUSTOMER_ADDRESS_DICT_SUCCESS =
  "disconnect_from_customer_globals_customer_adress_dict_success";
export const DISCONNECT_FROM_CUSTOMER_GLOBALS_PAYMENT_METHOD_SUCCESS =
  "disconnect_from_customer_globals_payment_methode_success";

export const GET_NEW_SUBSCRIPTION_SESSION_ID_START = "get_new_subscription_session_id_start";
export const GET_NEW_SUBSCRIPTION_SESSION_ID_SUCCESS = "get_new_subscription_session_id_success";
export const GET_NEW_SUBSCRIPTION_SESSION_ID_FAILURE = "get_new_subscription_session_id_failure";
export const GET_BILLING_PORTAL_URL_START = "get_billing_portal_url_start";
export const GET_BILLING_PORTAL_URL_SUCCESS = "get_billing_portal_url_success";
export const GET_BILLING_PORTAL_URL_FAILURE = "get_billing_portal_url_failure";
export const GET_SUBSCRIPTION_INFORMATION_START = "get_subscription_information_start";
export const GET_SUBSCRIPTION_INFORMATION_SUCCESS = "get_subscription_information_success";
export const GET_SUBSCRIPTION_INFORMATION_FAILURE = "get_subscription_information_failure";

export const ADD_CUSTOMER_SUBSCRIPTION_START = "add_customer_subscription_start";
export const ADD_CUSTOMER_SUBSCRIPTION_SUCCESS = "add_customer_subscription_success";
export const ADD_CUSTOMER_SUBSCRIPTION_FAILURE = "add_customer_subscription_failure";

export const EDIT_CUSTOMER_SUBSCRIPTION_START = "edit_customer_subscription_start";
export const EDIT_CUSTOMER_SUBSCRIPTION_SUCCESS = "edit_customer_subscription_success";
export const EDIT_CUSTOMER_SUBSCRIPTION_FAILURE = "edit_customer_subscription_failure";

export const EDIT_CUSTOMER_SUBSCRIPTION_ORDER_START = "edit_customer_subscription_order_start";
export const EDIT_CUSTOMER_SUBSCRIPTION_ORDER_SUCCESS = "edit_customer_subscription_order_success";
export const EDIT_CUSTOMER_SUBSCRIPTION_ORDER_FAILURE = "edit_customer_subscription_order_failure";

export const CANCEL_CUSTOMER_SUBSCRIPTION_START = "cancel_customer_subscription_start";
export const CANCEL_CUSTOMER_SUBSCRIPTION_SUCCESS = "cancel_customer_subscription_success";
export const CANCEL_CUSTOMER_SUBSCRIPTION_FAILURE = "cancel_customer_subscription_failure";

export const CONNECT_TO_CHEF_SUBSCRIPTIONS_START = "connect_to_chef_subscriptions_start";
export const CONNECT_TO_CHEF_SUBSCRIPTIONS_SUCCESS = "connect_to_chef_subscriptions_success";
export const CONNECT_TO_CHEF_SUBSCRIPTIONS_FAILURE = "connect_to_chef_subscriptions_failure";
export const DISCONNECT_FROM_CHEF_SUBSCRIPTIONS_START = "disconnect_from_chef_subscriptions_start";
export const DISCONNECT_FROM_CHEF_SUBSCRIPTIONS_SUCCESS =
  "disconnect_from_chef_subscriptions_success";
export const DISCONNECT_FROM_CHEF_SUBSCRIPTIONS_FAILURE =
  "disconnect_from_chef_subscriptions_failure";

export const CONNECT_TO_CUSTOMER_SUBSCRIPTIONS_START = "connect_to_customer_subscriptions_start";
export const CONNECT_TO_CUSTOMER_SUBSCRIPTIONS_SUCCESS =
  "connect_to_customer_subscriptions_success";
export const CONNECT_TO_CUSTOMER_SUBSCRIPTIONS_FAILURE =
  "connect_to_customer_subscriptions_failure";
export const DISCONNECT_FROM_CUSTOMER_SUBSCRIPTIONS_START =
  "disconnect_from_customer_subscriptions_start";
export const DISCONNECT_FROM_CUSTOMER_SUBSCRIPTIONS_SUCCESS =
  "disconnect_from_customer_subscriptions_success";
export const DISCONNECT_FROM_CUSTOMER_SUBSCRIPTIONS_FAILURE =
  "disconnect_from_customer_subscriptions_failure";

export const SAVE_PAYMENT_METHOD_START = "save_payment_method_start";
export const SAVE_PAYMENT_METHOD_SUCCESS = "save_payment_method_success";
export const SAVE_PAYMENT_METHOD_FAILURE = "save_payment_method_failure";
export const SET_PAYMENT_METHOD_ERROR = "set_payment_method_error";

export const CONNECT_TO_CHEF_SITE_SETTINGS_START = "connect_to_chef_site_settings_start";
export const CONNECT_TO_CHEF_SITE_SETTINGS_SUCCESS = "connect_to_chef_site_settings_success";
export const CONNECT_TO_CHEF_SITE_SETTINGS_FAILURE = "connect_to_chef_site_settings_failure";
export const DISCONNECT_FROM_CHEF_SITE_SETTINGS_START = "disconnect_from_chef_site_settings_start";
export const DISCONNECT_FROM_CHEF_SITE_SETTINGS_SUCCESS =
  "disconnect_from_chef_site_settings_success";
export const DISCONNECT_FROM_CHEF_SITE_SETTINGS_FAILURE =
  "disconnect_from_chef_site_settings_failure";

export const UPDATE_CUSTOMER_SUBSCRIPTION_START = "update_customer_subscription_start";
export const UPDATE_CUSTOMER_SUBSCRIPTION_SUCCESS = "update_customer_subscription_success";
export const UPDATE_CUSTOMER_SUBSCRIPTION_FAILURE = "update_customer_subscription_failure";

export const UPDATE_CUSTOMER_SUBSCRIPTION_ORDER_START = "update_customer_subscription_order_start";
export const UPDATE_CUSTOMER_SUBSCRIPTION_ORDER_SUCCESS =
  "update_customer_subscription_order_success";
export const UPDATE_CUSTOMER_SUBSCRIPTION_ORDER_FAILURE =
  "update_customer_subscription_order_failure";

export const CONNECT_TO_SUBSCRIPTION_ORDERS_START = "connect_to_subscription_orders_start";
export const CONNECT_TO_SUBSCRIPTION_ORDERS_SUCCESS = "connect_to_subscription_orders_success";
export const CONNECT_TO_SUBSCRIPTION_ORDERS_FAILURE = "connect_to_subscription_orders_failure";

export const DISCONNECT_FROM_SUBSCRIPTION_ORDERS_START =
  "disconnect_from_subscription_orders_start";
export const DISCONNECT_FROM_SUBSCRIPTION_ORDERS_SUCCESS =
  "disconnect_from_subscription_orders_success";
export const DISCONNECT_FROM_SUBSCRIPTION_ORDERS_FAILURE =
  "disconnect_from_subscription_orders_failure";

// Event.js types
export const PREPARE_QUEUE_EVENT_REMINDERS_START = "prepare_queue_event_reminders_start";
export const PREPARE_QUEUE_EVENT_REMINDERS_SUCCESS = "prepare_queue_event_reminders_success";
export const PREPARE_QUEUE_EVENT_REMINDERS_FAILURE = "prepare_queue_event_reminders_failure";

export const CONNECT_TO_PORTAL_EVENTS_START = "connect_to_portal_events_start";
export const CONNECT_TO_PORTAL_EVENTS_SUCCESS = "connect_to_portal_events_success";
export const CONNECT_TO_PORTAL_EVENTS_FAILURE = "connect_to_portal_events_failure";

export const DISCONNECT_FROM_PORTAL_EVENTS_START = "disconnect_from_portal_events_start";
export const DISCONNECT_FROM_PORTAL_EVENTS_SUCCESS = "disconnect_from_portal_events_success";
export const DISCONNECT_FROM_PORTAL_EVENTS_FAILURE = "disconnect_from_portal_events_failure";

export const backendUrl = import.meta.env.REACT_APP_BACKEND_URL;
export const backendTsUrl = import.meta.env.REACT_APP_BACKEND_TS_URL;
export const ephemeralChatUrl = import.meta.env.REACT_APP_EPHEMERAL_CHAT_URL;
export const stripeFrontendApiKey = import.meta.env.REACT_APP_STRIPE_FRONTEND_API_KEY;

export const IS_PROD = backendUrl === "https://hotplate.appspot.com/";
export const IS_STAGING = backendUrl === "https://hotplate-backend-test.uc.r.appspot.com/";

export const FIREBASE_CONFIG = IS_PROD
  ? {
      apiKey: "AIzaSyDSnZUKwtPCjrsz8T5OckGsokdSfTVAKgQ",
      authDomain: "hotplate-cb99e.firebaseapp.com",
      databaseURL: "https://hotplate-cb99e.firebaseio.com",
      projectId: "hotplate-cb99e",
      storageBucket: "hotplate-cb99e.appspot.com",
      messagingSenderId: "47881696522",
      appId: "1:47881696522:web:7f4cacacf1a7a75c0ff6f3",
      measurementId: "G-RXFZKEP6Z8",
    }
  : {
      apiKey: "AIzaSyAgSZ9SGq7wW9c2r-JQUwVsVe1twnyOfeM",
      authDomain: "hotplate-test-589a0.firebaseapp.com",
      databaseURL: "https://hotplate-test-589a0-default-rtdb.firebaseio.com/",
      projectId: "hotplate-test-589a0",
      storageBucket: "hotplate-test-589a0.appspot.com",
      messagingSenderId: "109641191668",
      appId: "1:109641191668:web:95340477a38f2ed9fd8ea1",
      measurementId: "G-YPT1LEGW7B",
    };
