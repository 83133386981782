import {
  SET_ORDER_MANAGEMENT_ACTION,
  UPDATE_SECTIONS_IN_PROGRESS,
  SET_PACKING_FILTER,
  SET_ACTIVE_FILTERS,
  SET_PRIMARY_ORDERS_SORT,
  SET_COMPLETED_ORDERS_DISPLAY,
  UPLOAD_CSV_START,
  UPLOAD_CSV_SUCCESS,
  UPLOAD_CSV_FAILURE,
  NOTIFY_CUSTOMER_START,
  NOTIFY_CUSTOMER_FAILURE,
  NOTIFY_CUSTOMER_SUCCESS,
  EDIT_ORDER_START,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_FAILURE,
  SET_PACKING_VIEW,
  INITIATE_CUSTOMER_REFUND_START,
  INITIATE_CUSTOMER_REFUND_SUCCESS,
  INITIATE_CUSTOMER_REFUND_FAILURE,
  CONNECT_TO_ORDERS_START,
  CONNECT_TO_ORDERS_SUCCESS,
  CONNECT_TO_ORDERS_FAILURE,
  DISCONNECT_FROM_ORDERS_START,
  DISCONNECT_FROM_ORDERS_SUCCESS,
  DISCONNECT_FROM_ORDERS_FAILURE,
  GET_TRANSACTION_ORDERS_START,
  GET_TRANSACTION_ORDERS_SUCCESS,
  GET_TRANSACTION_ORDERS_FAILURE,
  CONNECT_TO_EVENT_ORDERS_START,
  CONNECT_TO_EVENT_ORDERS_SUCCESS,
  CONNECT_TO_EVENT_ORDERS_FAILURE,
  DISCONNECT_FROM_EVENT_ORDERS_START,
  DISCONNECT_FROM_EVENT_ORDERS_SUCCESS,
  DISCONNECT_FROM_EVENT_ORDERS_FAILURE,
  CONNECT_TO_ITEMIZED_RECEIPT_ORDER_START,
  CONNECT_TO_ITEMIZED_RECEIPT_ORDER_SUCCESS,
  CONNECT_TO_ITEMIZED_RECEIPT_ORDER_FAILURE,
  DISCONNECT_FROM_ITEMIZED_RECEIPT_ORDER_START,
  DISCONNECT_FROM_ITEMIZED_RECEIPT_ORDER_SUCCESS,
  DISCONNECT_FROM_ITEMIZED_RECEIPT_ORDER_FAILURE,
  CONNECT_TO_CUSTOMER_CHEF_ORDERS_START,
  CONNECT_TO_CUSTOMER_CHEF_ORDERS_SUCCESS,
  CONNECT_TO_CUSTOMER_CHEF_ORDERS_FAILURE,
  DISCONNECT_FROM_CUSTOMER_CHEF_ORDERS_START,
  DISCONNECT_FROM_CUSTOMER_CHEF_ORDERS_SUCCESS,
  DISCONNECT_FROM_CUSTOMER_CHEF_ORDERS_FAILURE,
} from "../actions/types";

const INITIAL_STATE = {
  orderManagementAction: "PREP",
  activeFilters: [],

  packingFilter: "NOT_DONE",
  prevPackingFilter: "NOT_DONE",
  packingView: "CARD",
  sectionsInProgress: {},

  sort: {
    primary: {
      // named primary so we could add secondary sort later with a minor diff
      type: "timeSlot", // timeSlot, orderNumber, customerName
      direction: "asc", // asc, desc
    },
  },
  completedOrdersDisplay: "hidden", // hidden, end, none

  uploadCsvLoading: false,
  uploadCsvError: "",
  notifyCustomerLoading: false,
  notifyCustomerError: "",

  initiateCustomerRefundLoading: false,
  initiateCustomerRefundError: "",

  editOrderLoading: false,
  editOrderError: "",

  orders: {},
  connectToOrdersLoading: false,
  connectToOrdersError: "",
  disconnectFromOrdersLoading: false,
  disconnectFromOrdersError: "",

  transactionOrders: [],
  totalTransactionOrders: null,
  getTransactionOrdersLoading: false,
  getTransactionOrdersError: "",

  eventOrders: {},
  connectToEventOrdersLoading: false,
  connectToEventOrdersError: "",

  disconnectFromEventOrdersLoading: false,
  disconnectFromEventOrdersError: "",

  itemizedReceiptOrder: {},
  connectToItemizedReceiptOrderLoading: false,
  connectToItemizedReceiptOrderError: "",
  disconnectFromItemizedReceiptOrderLoading: false,
  disconnectFromItemizedReceiptOrderError: "",

  customerOrders: {},
  connectToCustomerChefOrdersLoading: false,
  connectToCustomerChefOrdersError: "",
  disconnectFromCustomerChefOrdersLoading: false,
  disconnectFromCustomerChefOrdersError: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONNECT_TO_CUSTOMER_CHEF_ORDERS_START:
      return {
        ...state,
        connectToCustomerChefOrdersLoading: true,
        connectToCustomerChefOrdersError: "",
      };
    case CONNECT_TO_CUSTOMER_CHEF_ORDERS_SUCCESS:
      return {
        ...state,
        connectToCustomerChefOrdersLoading: false,
        customerOrders: action.payload,
      };
    case CONNECT_TO_CUSTOMER_CHEF_ORDERS_FAILURE:
      return {
        ...state,
        connectToCustomerChefOrdersLoading: false,
        connectToCustomerChefOrdersError: action.payload,
      };
    case DISCONNECT_FROM_CUSTOMER_CHEF_ORDERS_START:
      return {
        ...state,
        disconnectFromCustomerChefOrdersLoading: true,
        disconnectFromCustomerChefOrdersError: "",
      };
    case DISCONNECT_FROM_CUSTOMER_CHEF_ORDERS_SUCCESS:
      return {
        ...state,
        disconnectFromCustomerChefOrdersLoading: false,
        customerOrders: {},
      };
    case DISCONNECT_FROM_CUSTOMER_CHEF_ORDERS_FAILURE:
      return {
        ...state,
        disconnectFromCustomerChefOrdersLoading: false,
        disconnectFromCustomerChefOrdersError: action.payload,
      };

    case CONNECT_TO_ITEMIZED_RECEIPT_ORDER_START:
      return {
        ...state,
        connectToItemizedReceiptOrderLoading: true,
        connectToItemizedReceiptOrderError: "",
        itemizedReceiptOrder: {},
      };
    case CONNECT_TO_ITEMIZED_RECEIPT_ORDER_SUCCESS:
      return {
        ...state,
        connectToItemizedReceiptOrderLoading: false,
        itemizedReceiptOrder: action.payload,
      };
    case CONNECT_TO_ITEMIZED_RECEIPT_ORDER_FAILURE:
      return {
        ...state,
        connectToItemizedReceiptOrderLoading: false,
        connectToItemizedReceiptOrderError: action.payload,
      };
    case DISCONNECT_FROM_ITEMIZED_RECEIPT_ORDER_START:
      return {
        ...state,
        disconnectFromItemizedReceiptOrderLoading: true,
        disconnectFromItemizedReceiptOrderError: "",
      };
    case DISCONNECT_FROM_ITEMIZED_RECEIPT_ORDER_SUCCESS:
      return { ...state, disconnectFromItemizedReceiptOrderLoading: false };
    case DISCONNECT_FROM_ITEMIZED_RECEIPT_ORDER_FAILURE:
      return {
        ...state,
        disconnectFromItemizedReceiptOrderLoading: false,
        disconnectFromItemizedReceiptOrderError: action.payload,
      };

    case CONNECT_TO_EVENT_ORDERS_START:
      return {
        ...state,
        connectToEventOrdersLoading: true,
        connectToEventOrdersError: "",
      };
    case CONNECT_TO_EVENT_ORDERS_SUCCESS:
      return {
        ...state,
        connectToEventOrdersLoading: false,
        eventOrders: action.payload,
      };
    case CONNECT_TO_EVENT_ORDERS_FAILURE:
      return {
        ...state,
        connectToEventOrdersLoading: false,
        connectToEventOrdersError: action.payload,
      };
    case DISCONNECT_FROM_EVENT_ORDERS_START:
      return {
        ...state,
        disconnectToEventOrdersLoading: true,
        disconnectToEventOrdersError: "",
      };
    case DISCONNECT_FROM_EVENT_ORDERS_SUCCESS:
      return { ...state, disconnectToEventOrdersLoading: false };
    case DISCONNECT_FROM_EVENT_ORDERS_FAILURE:
      return {
        ...state,
        disconnectToEventOrdersLoading: false,
        disconnectToEventOrdersError: action.payload,
      };
    case GET_TRANSACTION_ORDERS_START:
      return {
        ...state,
        getTransactionOrdersLoading: true,
        getTransactionOrdersError: "",
      };
    case GET_TRANSACTION_ORDERS_SUCCESS:
      return {
        ...state,
        getTransactionOrdersLoading: false,
        transactionOrders: action.payload.orders,
        totalTransactionOrders: action.payload.totalOrders,
      };
    case GET_TRANSACTION_ORDERS_FAILURE:
      return {
        ...state,
        getTransactionOrdersLoading: false,
        getTransactionOrdersError: action.payload,
      };
    case CONNECT_TO_ORDERS_START:
      return {
        ...state,
        connectToOrdersLoading: true,
        connectToOrdersError: "",
      };
    case CONNECT_TO_ORDERS_SUCCESS:
      return {
        ...state,
        connectToOrdersLoading: false,
        orders: action.payload,
      };
    case CONNECT_TO_ORDERS_FAILURE:
      return {
        ...state,
        connectToOrdersLoading: false,
        connectToOrdersError: action.payload,
      };
    case DISCONNECT_FROM_ORDERS_START:
      return {
        ...state,
        disconnectFromOrdersLoading: true,
        disconnectFromOrdersError: "",
      };
    case DISCONNECT_FROM_ORDERS_SUCCESS:
      return { ...state, disconnectFromOrdersLoading: false };
    case DISCONNECT_FROM_ORDERS_FAILURE:
      return {
        ...state,
        disconnectFromOrdersLoading: false,
        disconnectFromOrdersError: action.payload,
      };
    case EDIT_ORDER_START:
      return { ...state, editOrderLoading: true, editOrderError: "" };
    case EDIT_ORDER_SUCCESS:
      return { ...state, editOrderLoading: false };
    case EDIT_ORDER_FAILURE:
      return {
        ...state,
        editOrderLoading: false,
        editOrderError: action.payload,
      };
    case INITIATE_CUSTOMER_REFUND_START:
      return {
        ...state,
        initiateCustomerRefundLoading: true,
        initiateCustomerRefundError: "",
      };
    case INITIATE_CUSTOMER_REFUND_SUCCESS:
      return { ...state, initiateCustomerRefundLoading: false };
    case INITIATE_CUSTOMER_REFUND_FAILURE:
      return {
        ...state,
        initiateCustomerRefundLoading: false,
        initiateCustomerRefundError: action.payload,
      };
    case SET_PACKING_VIEW:
      return { ...state, packingView: action.payload };
    case SET_PACKING_FILTER: {
      let nPrevPackingFilter = state.prevPackingFilter;
      if (action.payload !== "ALL") {
        nPrevPackingFilter = action.payload;
      }
      return {
        ...state,
        packingFilter: action.payload,
        prevPackingFilter: nPrevPackingFilter,
      };
    }
    case SET_ACTIVE_FILTERS: {
      return { ...state, activeFilters: action.payload };
    }
    case SET_COMPLETED_ORDERS_DISPLAY: {
      return { ...state, completedOrdersDisplay: action.payload };
    }
    case SET_PRIMARY_ORDERS_SORT: {
      return { ...state, sort: { primary: action.payload } };
    }
    case SET_ORDER_MANAGEMENT_ACTION:
      return { ...state, orderManagementAction: action.payload };
    case UPDATE_SECTIONS_IN_PROGRESS:
      return { ...state, sectionsInProgress: action.payload };
    case UPLOAD_CSV_START:
      return { ...state, uploadCsvLoading: true, uploadCsvError: false };
    case UPLOAD_CSV_SUCCESS:
      return { ...state, uploadCsvLoading: false };
    case UPLOAD_CSV_FAILURE:
      return {
        ...state,
        uploadCsvLoading: false,
        uploadCsvError: action.payload,
      };

    case NOTIFY_CUSTOMER_START:
      return { ...state, notifyCustomerLoading: true, notifyCustomerError: "" };
    case NOTIFY_CUSTOMER_FAILURE:
      return { ...state, notifyCustomerLoading: false };
    case NOTIFY_CUSTOMER_SUCCESS:
      return {
        ...state,
        notifyCustomerLoading: false,
        notifyCustomerError: action.payload,
      };
    default:
      return state;
  }
};
