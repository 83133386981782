import React, { useState } from "react";
import ReactModal from "react-modal";
// accesibility friendly dialog library created by the a11y foundation

import "./css/NewModal.css";
import "./css/reset.css";
import classNames from "classnames";
import { styled } from "../stitches.config";
import { ScrollArea } from "./primitives/ScrollArea";
import { ArrowLeftIcon, Cross2Icon } from "@radix-ui/react-icons";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";

const CloseButton = styled("button", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  zIndex: "$fixed",
  top: "$md",
  right: "$md",
  width: 36,
  height: 36,
  borderRadius: "$pill",
  cursor: "pointer",
  transition: "background-color 100ms ease-in-out, color 100ms ease-in-out",
  "& svg": {
    width: 20,
    height: 20,
  },
  variants: {
    variant: {
      quiet: {
        backgroundColor: "transparent",
        border: "none",
        color: "$accent11",
        "&:hover": {
          color: "$accent12",
          backgroundColor: "$overlay4",
        },
        "&:active": {
          color: "$accent12",
          backgroundColor: "$overlay6",
        },
      },
      solid: {
        backgroundColor: "$accent1",
        border: "1px solid $accent3",
        color: "$accent11",
        boxShadow: "rgba(0, 0, 0, 0.14) 0px 2px 10px",
        "&:hover": {
          backgroundColor: "$accent3",
        },
        "&:active": {
          backgroundColor: "$accent4",
        },
      },
    },
    left: {
      true: {
        left: "calc(1.5em - 8px)",
        right: "auto",
      },
    },
  },
  defaultVariants: {
    variant: "solid",
    left: false,
  },
});

const HeaderPrimitve = styled("header", {
  display: "flex",
  flexDirection: "column",
  marginBottom: "$lg",
  width: "100%",
  paddingTop: "3.5rem",
  variants: {
    hidden: {
      true: {
        display: "none",
      },
    },
  },
});

const Title = styled("h3", {
  ff: "$arboria",
  fs: "$lg",
  fw: "$semi_bold",
  lh: "$heading",
  color: "$gray12",
  marginBottom: "$sm",
});

const Description = styled("p", {
  ff: "$avenir",
  fs: "$sm",
  fw: "$semi_bold",
  lh: "$text",
  color: "$gray10",
});

const Header = ({ title, description, hidden, ...props }) => {
  return (
    <>
      {hidden ? (
        <VisuallyHidden.Root>
          <HeaderPrimitve {...props}>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </HeaderPrimitve>
        </VisuallyHidden.Root>
      ) : (
        <HeaderPrimitve {...props}>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </HeaderPrimitve>
      )}
    </>
  );
};

export default function NewModal({
  isOpen = false,
  onRequestClose,
  id = "modal-content",
  onAfterOpen,
  onAfterClose,
  closeTimeoutMS = 350,
  title = "",
  fullscreen = false,
  headerless = false,
  children,
  desc = "",
  transparent,
  css,
  headerCss,
}: {
  isOpen: boolean;
  title: string;
  children: React.ReactNode;
  fullscreen?: boolean;
  headerless?: boolean;
  onRequestClose?: () => void;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
  id?: string;
  closeTimeoutMS?: number;
  desc?: string;
}) {
  const [open, setOpen] = useState();

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // class definitions for modal components

  const contentClasses = classNames({
    "modal-content": true,
    "--fullscreen": fullscreen,
    "--headerless": headerless,
    "--transparent": transparent,
  });

  const overlayClasses = classNames({
    "modal-overlay": true,
    "--hidden": fullscreen,
  });

  return (
    <ReactModal
      isOpen={isOpen}
      // triggers the if the modal should be shown & if its children should be rendered
      onRequestClose={onRequestClose || undefined}
      // function that is called when user tires to close the modal, either via the closeButoon, hitting ESC, or clicking the overlay
      onAfterOpen={onAfterOpen || undefined}
      // function that is called after the modal has been opened
      onAfterClose={onAfterClose || undefined}
      // function that is called after the modal has been opened
      closeTimeoutMS={closeTimeoutMS || undefined}
      // timeout of modal close to allow for fade transitions
      contentLabel={title || undefined}
      // screen reader label for the modal, will use the title you pass in
      className={{
        base: contentClasses,
        afterOpen: "--opened",
        beforeClose: "--closed",
      }}
      // className for the portal div, determined by the type prop
      // http://reactcommunity.org/react-modal/styles/classes/
      overlayClassName={{
        base: overlayClasses,
        afterOpen: "--opened",
        beforeClose: "--closed",
      }}
      //className for the overlay div
      id={id}
      // ID applied to the content div, default is "modal-content"
      testId={id}
      // String testId that renders a data-testid attribute in the DOM, may help with testing later
    >
      {/* CLOSE BUTTON */}
      <CloseButton
        onClick={onRequestClose}
        variant={headerless ? "solid" : "quiet"}
        left={fullscreen}
      >
        {fullscreen ? <ArrowLeftIcon /> : <Cross2Icon height={16} width={16} />}
      </CloseButton>
      <ScrollArea
        id="modal-scroll-area"
        css={
          !headerless
            ? {
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                padx: "1.5em",
                ...css,
              }
            : {
                display: "flex",
                flexDirection: "column",
                padx: 0,
                width: "100%",
                maxHeight: "100%",
                maxWidth: "100%",
                ...css,
              }
        }
        viewportCss={{
          "& > :last-child": {
            marginBottom: "2em",
          },
        }}
      >
        {/* HEADER */}
        <Header
          title={title}
          css={headerCss}
          description={desc}
          hidden={transparent || headerless}
        />
        {/* CONTENT */}
        {children}
      </ScrollArea>
    </ReactModal>
  );
}
