import { useCallback } from "react";
import { icons } from "../visly";

// TODO this regex will add commas to thousandth places: {some number string}.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
// I added this functionality in CustomerModal, but did not want to add it directly to toFixed since I am not sure how it will impact other places we use this func.

export function useConnectedFn(fn, dispatch) {
  return useCallback(
    (...args) => {
      return fn(...args)(dispatch);
    },
    [fn, dispatch]
  );
}

export function showItemWaitlistPortalFunctionality(
  menuItem,
  menuItemSoldCount,
  itemWaitlistCount
) {
  return (
    itemWaitlistCount &&
    itemWaitlistCount > 0 &&
    menuItem.inventory === 0 &&
    Math.max(
      (menuItem.inventoryReserved ? menuItem.inventoryReserved : 0) -
        (menuItemSoldCount ? menuItemSoldCount : 0),
      0
    ) === 0
  );
}

export function startPortalLoad() {
  if (!document) return;
  const portalLoader = document.querySelector(".portal-navigation");
  if (portalLoader && portalLoader.classList) {
    portalLoader.classList.add("loading");
  }
}

export function endPortalLoad() {
  if (!document) return;
  const portalLoader = document.querySelector(".portal-navigation");
  if (portalLoader && portalLoader.classList) {
    portalLoader.classList.add("loaded");
    setTimeout(() => {
      portalLoader.classList.remove("loading");
      portalLoader.classList.remove("loaded");
    }, 500);
  }
}

export function clearPortalLoad() {
  if (!document) return;
  const portalLoader = document.querySelector(".portal-navigation");
  if (portalLoader && portalLoader.classList) {
    portalLoader.classList.remove("loading");
    portalLoader.classList.remove("loaded");
  }
}

export function getLocationStyles() {
  return [
    {
      colorName: "blue",
      iconColor: "#006E6A",
      backgroundColor: "#E0F3F4",
      iconUrl: icons.bluePin,
    },
    {
      colorName: "purple",
      iconColor: "#790098",
      backgroundColor: "#E7BCE5",
      iconUrl: icons.purplePin,
    },
    {
      colorName: "orange",
      iconColor: "#FF9502",
      backgroundColor: "#FEEEB5",
      iconUrl: icons.orangePin,
    },
    {
      colorName: "red",
      iconColor: "#BF3635",
      backgroundColor: "#FBCFD5",
      iconUrl: icons.redPin,
    },
    {
      colorName: "green",
      iconColor: "#097638",
      backgroundColor: "#C1E4CB",
      iconUrl: icons.greenPin,
    },
  ];
}
