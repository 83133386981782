// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Avatar.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      XUV744u51e: {
        none: {
          text: "B",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "small",
    layers: {},
  },
  {
    type: "boolean",
    propName: "letter",
    layers: {
      XUV744u51e: {
        none: {
          text: "B",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "rectangle",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "small",
    layers: {},
  },
  {
    type: "boolean",
    propName: "letter",
    layers: {},
  },
  {
    type: "boolean",
    propName: "rectangle",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "small",
  },
  {
    type: "boolean",
    propName: "letter",
  },
  {
    type: "boolean",
    propName: "rectangle",
  },
];

export const AvatarContext = createContext(null);

function Avatar(_props) {
  const defaults = useContext(AvatarContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="F5bRqs4Lma"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "F5bRqs4Lma",
        scope: "NX3W3WrU94",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            key={"2CfTSktX9a"}
            id={"Slot_2CfTSktX9a"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_NX3W3WrU94_2CfTSktX9a"
            }
            addSpacing={false}
          >
            {props.imageSrc}
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"Container_5yRSkktFAb"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_NX3W3WrU94_5yRSkktFAb"
            }
            key={"5yRSkktFAb"}
            addSpacing={false}
            element={getStyle("5yRSkktFAb", "htmlElement")}
          >
            {
              <TextPrimitive
                id={"Text_XUV744u51e"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_NX3W3WrU94_XUV744u51e"
                }
                key={"XUV744u51e"}
                text={
                  exists(props.text)
                    ? props.text
                    : getStyle("XUV744u51e", "text")
                }
                element={getStyle("XUV744u51e", "htmlElement")}
              />
            }
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

Avatar.__variants = [
  {
    name: "small",
    type: "variant",
  },
  {
    name: "letter",
    type: "variant",
  },
  {
    name: "rectangle",
    type: "variant",
  },
];

export default Avatar;
