// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./EventConfig.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as NavButton, NavButtonContext } from "../Events/NavButton";
import {
  default as EventStatus,
  StatusContext as EventStatusContext,
} from "../Events/Status";
import { default as ConfigTabs, ConfigTabsContext } from "../Events/ConfigTabs";

const styles = [
  {
    type: "default",
    layers: {
      NmhRGcexJX: {
        none: {
          text: "Page name",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      VFWaZvbu35: {},
      UUgxkFSLTh: {},
      UQN7ohuRkp: {},
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {
          VFWaZvbu35: {},
          UUgxkFSLTh: {},
          UQN7ohuRkp: {
            none: {
              size: "xsmall",
            },
          },
        },
      },
      {
        propValue: "small",
        layers: {
          VFWaZvbu35: {},
          UUgxkFSLTh: {},
          UQN7ohuRkp: {
            none: {
              size: "small",
            },
          },
        },
      },
      {
        propValue: "med",
        layers: {
          VFWaZvbu35: {},
          UUgxkFSLTh: {},
          UQN7ohuRkp: {
            none: {
              size: "med",
            },
          },
        },
      },
      {
        propValue: "large",
        layers: {
          VFWaZvbu35: {},
          UUgxkFSLTh: {},
          UQN7ohuRkp: {
            none: {
              size: "large",
            },
          },
        },
      },
      {
        propValue: "xlarge",
        layers: {
          VFWaZvbu35: {},
          UUgxkFSLTh: {},
          UQN7ohuRkp: {},
        },
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export const EventConfigContext = createContext(null);

function EventConfig(_props) {
  const defaults = useContext(EventConfigContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="UUpPGf54LD"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "UUpPGf54LD",
        scope: "JQhAD1qGDA",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"content-container_HPiawH7h26"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_JQhAD1qGDA_HPiawH7h26"
          }
          key={"HPiawH7h26"}
          addSpacing={false}
          element={getStyle("HPiawH7h26", "htmlElement")}
        >
          <ContainerPrimitive
            id={"header-container_JxjHVXmz6a"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_JQhAD1qGDA_JxjHVXmz6a"
            }
            key={"JxjHVXmz6a"}
            addSpacing={false}
            element={getStyle("JxjHVXmz6a", "htmlElement")}
          >
            {[
              <ContainerPrimitive
                id={"nav-status-container_DMgrBWbwgA"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_JQhAD1qGDA_DMgrBWbwgA"
                }
                key={"DMgrBWbwgA"}
                addSpacing={false}
                element={getStyle("DMgrBWbwgA", "htmlElement")}
              >
                {[
                  props.NavButton === undefined ? (
                    <NavButton
                      key={"VFWaZvbu35"}
                      {...getCompositeDefaultProps("VFWaZvbu35")}
                      id="NavButton_VFWaZvbu35"
                      className="__visly_reset_7X7HTLRqyD __visly_scope_JQhAD1qGDA_VFWaZvbu35"
                    />
                  ) : (
                    <NavButtonContext.Provider
                      key="VFWaZvbu35-provider"
                      value={{
                        key: "VFWaZvbu35",
                        id: "NavButton_VFWaZvbu35",
                        className:
                          "__visly_reset_7X7HTLRqyD __visly_scope_JQhAD1qGDA_VFWaZvbu35",
                        ...getCompositeDefaultProps("VFWaZvbu35"),
                      }}
                    >
                      {props.NavButton}
                    </NavButtonContext.Provider>
                  ),
                  props.EventStatus === undefined ? (
                    <EventStatus
                      key={"UUgxkFSLTh"}
                      {...getCompositeDefaultProps("UUgxkFSLTh")}
                      id="EventStatus_UUgxkFSLTh"
                      className="__visly_reset_7X7HTLRqyD __visly_scope_JQhAD1qGDA_UUgxkFSLTh"
                    />
                  ) : (
                    <EventStatusContext.Provider
                      key="UUgxkFSLTh-provider"
                      value={{
                        key: "UUgxkFSLTh",
                        id: "EventStatus_UUgxkFSLTh",
                        className:
                          "__visly_reset_7X7HTLRqyD __visly_scope_JQhAD1qGDA_UUgxkFSLTh",
                        ...getCompositeDefaultProps("UUgxkFSLTh"),
                      }}
                    >
                      {props.EventStatus}
                    </EventStatusContext.Provider>
                  ),
                ]}
              </ContainerPrimitive>,
              <SpacerPrimitive
                id={"Spacer_YNzb4zyGhV"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_JQhAD1qGDA_YNzb4zyGhV"
                }
                key={"YNzb4zyGhV"}
              />,
              props.ConfigTabs === undefined ? (
                <ConfigTabs
                  key={"UQN7ohuRkp"}
                  {...getCompositeDefaultProps("UQN7ohuRkp")}
                  id="ConfigTabs_UQN7ohuRkp"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_JQhAD1qGDA_UQN7ohuRkp"
                />
              ) : (
                <ConfigTabsContext.Provider
                  key="UQN7ohuRkp-provider"
                  value={{
                    key: "UQN7ohuRkp",
                    id: "ConfigTabs_UQN7ohuRkp",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_JQhAD1qGDA_UQN7ohuRkp",
                    ...getCompositeDefaultProps("UQN7ohuRkp"),
                  }}
                >
                  {props.ConfigTabs}
                </ConfigTabsContext.Provider>
              ),
            ]}
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"scrollbox_6JJbfGPm3B"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_JQhAD1qGDA_6JJbfGPm3B"
            }
            key={"6JJbfGPm3B"}
            addSpacing={false}
            element={getStyle("6JJbfGPm3B", "htmlElement")}
          >
            <TextPrimitive
              id={"title_NmhRGcexJX"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_JQhAD1qGDA_NmhRGcexJX"
              }
              key={"NmhRGcexJX"}
              text={
                exists(props.title)
                  ? props.title
                  : getStyle("NmhRGcexJX", "text")
              }
              element={getStyle("NmhRGcexJX", "htmlElement")}
            />
            <ContainerPrimitive
              key={"NS37NNn92Y"}
              id={"Slot_NS37NNn92Y"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_JQhAD1qGDA_NS37NNn92Y"
              }
              addSpacing={false}
            >
              {props.children}
            </ContainerPrimitive>
          </ContainerPrimitive>
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

EventConfig.NavButton = NavButton;
EventConfig.EventStatus = EventStatus;
EventConfig.ConfigTabs = ConfigTabs;
EventConfig.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export default EventConfig;
