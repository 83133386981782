// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./InputDropdown.Options.Option.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      BRvH3tzoWc: {
        none: {
          text: "Text",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const OptionContext = createContext(null);

function Option(_props) {
  const defaults = useContext(OptionContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="WP2BfPuvhk"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "WP2BfPuvhk",
        scope: "2ZrFdDKKSX",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_BojNyUPTGf"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_2ZrFdDKKSX_BojNyUPTGf"
          }
          key={"BojNyUPTGf"}
          addSpacing={false}
          element={getStyle("BojNyUPTGf", "htmlElement")}
        >
          {
            <TextPrimitive
              id={"Text_BRvH3tzoWc"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_2ZrFdDKKSX_BRvH3tzoWc"
              }
              key={"BRvH3tzoWc"}
              text={
                exists(props.text) ? props.text : getStyle("BRvH3tzoWc", "text")
              }
              element={getStyle("BRvH3tzoWc", "htmlElement")}
            />
          }
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

Option.__variants = [];

export default Option;
