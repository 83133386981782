import { titleCase } from "@hotplate/utils-ts/helperFunctions";
import React from "react";
import { styled } from "../../stitches.config";

const BadgePrimitive = styled("div", {
  display: "flex",
  whiteSpace: "nowrap",
  alignItems: "center",
  height: 22,
  padx: 5,
  borderRadius: "$pill",
  backgroundColor: "$overlay3",
  ff: "$avenir",
  fw: "$bold",
  lineHeight: "20px",
  fs: 13,
  transition: "background-color 100ms ease-in-out, color 100ms ease-in-out",
  "&:hover": {
    backgroundColor: "$overlay5",
  },
  // "@media print": {
  //   display: "none",
  // },
  variants: {
    variant: {
      new: {
        color: "$success11",
        backgroundColor: "$success3",
        "&:hover": {
          backgroundColor: "$success5",
        },
      },
      default: {
        color: "$warning11",
        backgroundColor: "$warning3",
        "&:hover": {
          backgroundColor: "$warning5",
        },
      },
      regular: {
        color: "$hotplate11",
        backgroundColor: "$hotplate3",
        "&:hover": {
          backgroundColor: "$hotplate5",
        },
      },
      god: {
        color: "$white",
        backgroundColor: "$error9",
        boxShadow: "rgb(231 90 90 / 100%) 0px 0px 3px, rgb(231 90 90 / 20%) 0px 2px 5px",
        "&:hover": {
          backgroundColor: "$error10",
        },
      },
    },
  },
});

export function getCustomerOrderString(numOrders) {
  return numOrders === 1
    ? "NEW"
    : numOrders > 49
    ? `${numOrders} 🌟`
    : numOrders > 9
    ? `${numOrders} 🚀`
    : `${numOrders} 🔥`;
}

export const CustomerBadge = ({ numOrders, ...props }) => {
  return (
    <BadgePrimitive
      variant={
        numOrders === 1 ? "new" : numOrders < 10 ? "default" : numOrders < 50 ? "regular" : "god"
      }
      {...props}
    >
      {getCustomerOrderString(numOrders)}
    </BadgePrimitive>
  );
};

const CustomerNameFlex = styled("button", {
  display: "flex",
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
  columnGap: 8,
  rowGap: 4,
  flexFlow: "row wrap",
  alignItems: "center",
  ff: "$avenir",
  lh: "$header",
  fw: "$semi_bold",
  color: "inherit",
  cursor: "pointer",
  "&:focus-visible": {
    borderRadius: "$xs",
    outline: "2px solid $accent9",
    outlineOffset: 4,
  },
  variants: {
    size: {
      small: {
        fs: 14,
        "@media print": {
          color: "$gray12",
          fs: "12px !important",
        },
      },
      medium: {
        fs: 16,
        "@media print": {
          color: "$gray12",
          fs: "14px !important",
        },
      },
    },
  },
  defaultVariants: {
    size: "medium",
  },
});

export const LabeledCustomerBadge = ({ fullName, numOrders, onClick, ...props }) => {
  return (
    <CustomerNameFlex {...props} onClick={onClick} tabIndex={0}>
      <span>{titleCase(fullName)}</span>
      {numOrders && <CustomerBadge numOrders={numOrders} onClick={onClick} />}
    </CustomerNameFlex>
  );
};
