// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./LocationBadge.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as RemoveButton } from "./LocationBadge.RemoveButton";

const styles = [
  {
    type: "default",
    layers: {
      AAY92ZWPzm: {
        none: {
          text: "Location Name",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "showRemoveButton",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "showRemoveButton",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "showRemoveButton",
  },
];

export const LocationBadgeContext = createContext(null);

function LocationBadge(_props) {
  const defaults = useContext(LocationBadgeContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="JWzczFzG1Q"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "JWzczFzG1Q",
        scope: "TXgNwSLE6Q",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_R9kv3TvdCt"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_TXgNwSLE6Q_R9kv3TvdCt"
          }
          key={"R9kv3TvdCt"}
          addSpacing={false}
          element={getStyle("R9kv3TvdCt", "htmlElement")}
        >
          <ContainerPrimitive
            id={"location-container_AtzaM32rHQ"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_TXgNwSLE6Q_AtzaM32rHQ"
            }
            key={"AtzaM32rHQ"}
            addSpacing={false}
            element={getStyle("AtzaM32rHQ", "htmlElement")}
          >
            {
              <TextPrimitive
                id={"Text_AAY92ZWPzm"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_TXgNwSLE6Q_AAY92ZWPzm"
                }
                key={"AAY92ZWPzm"}
                text={
                  exists(props.title)
                    ? props.title
                    : getStyle("AAY92ZWPzm", "text")
                }
                element={getStyle("AAY92ZWPzm", "htmlElement")}
              />
            }
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"button-container_QykNzSCdTi"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_TXgNwSLE6Q_QykNzSCdTi"
            }
            key={"QykNzSCdTi"}
            addSpacing={false}
            element={getStyle("QykNzSCdTi", "htmlElement")}
          >
            {[
              <ContainerPrimitive
                id={"divider-wrapper_4KdVSwYNS7"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_TXgNwSLE6Q_4KdVSwYNS7"
                }
                key={"4KdVSwYNS7"}
                addSpacing={false}
                element={getStyle("4KdVSwYNS7", "htmlElement")}
              >
                {
                  <ContainerPrimitive
                    id={"divider_Roarj51uFu"}
                    className={
                      "__visly_reset_7X7HTLRqyD __visly_scope_TXgNwSLE6Q_Roarj51uFu"
                    }
                    key={"Roarj51uFu"}
                    addSpacing={false}
                    element={getStyle("Roarj51uFu", "htmlElement")}
                  >
                    {null}
                  </ContainerPrimitive>
                }
              </ContainerPrimitive>,
              props.RemoveButton === undefined ? (
                <RemoveButton key={"4cXzjfyTbV"} />
              ) : (
                props.RemoveButton
              ),
            ]}
          </ContainerPrimitive>
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

LocationBadge.__variants = [
  {
    name: "showRemoveButton",
    type: "variant",
  },
];

export default LocationBadge;
