import React from "react";
import { styled, keyframes } from "../../stitches.config";
import { Cross2Icon } from "@radix-ui/react-icons";
import * as DialogPrimitive from "@radix-ui/react-dialog";

const overlayShow = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const overlayHide = keyframes({
  "0%": { opacity: 1 },
  "100%": { opacity: 0 },
});

const contentShow = keyframes({
  "0%": { opacity: 0, transform: "translate(-50%, -48%) scale(.96)" },
  "100%": { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
});

const contentHide = keyframes({
  "0%": { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
  "100%": { opacity: 0, transform: "translate(-50%, -48%) scale(.96)" },
});

const StyledOverlay = styled(DialogPrimitive.Overlay, {
  backgroundColor: "$overlay9",
  zIndex: "$modal_backdrop",
  position: "fixed",
  inset: 0,
  backdropFilter: "blur(5px)",
  "@media (prefers-reduced-motion: no-preference)": {
    '&[data-state="open"]': {
      animation: `${overlayShow} 200ms ease-out`,
    },
    '&[data-state="closed"]': {
      animation: `${overlayHide} 200ms ease-in`,
    },
  },
});

const requiredContentCss = {
  position: "fixed",
  top: "50%",
  left: "50%",
  zIndex: "$modal",
  transform: "translate(-50%, -50%)",
  "@media (prefers-reduced-motion: no-preference)": {
    '&[data-state="open"]': {
      animation: `${contentShow} 200ms ease-out`,
    },
    '&[data-state="closed"]': {
      animation: `${contentHide} 200ms ease-in`,
    },
  },
  "&:focus": { outline: "none" },
};

const StyledContent = styled(DialogPrimitive.Content, {
  ...requiredContentCss,
  backgroundColor: "white",
  borderRadius: 6,
  boxShadow: "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  width: "90vw",
  maxWidth: "450px",
  maxHeight: "85vh",
  padding: 25,
  overflow: "scroll",
});

function Content({ children, ...props }) {
  return (
    <DialogPrimitive.Portal>
      <StyledOverlay />
      <StyledContent {...props}>{children}</StyledContent>
    </DialogPrimitive.Portal>
  );
}

const StyledTitle = styled(DialogPrimitive.Title, {
  fontFamily: "$arboria",
  fontWeight: "$semi_bold",
  lh: "$heading",
  color: "$gray12",
  fontSize: "$xl",
  maxWidth: "calc(100% - 40px)",
});

const StyledDescription = styled(DialogPrimitive.Description, {
  margin: "10px 0 20px",
  color: "$gray11",
  fontFamily: "$avenir",
  fontSize: 15,
  lh: "$text",
});

const StyledClose = styled(DialogPrimitive.Close, {
  all: "unset",
  fontFamily: "inherit",
  borderRadius: "100%",
  height: 32,
  width: 32,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  color: "$gray11",
  cursor: "pointer",
  position: "absolute",
  top: 16,
  right: 16,
  backgroundColor: "transparent",
  transition: "background-color 100ms ease-in-out",
  "&:hover": { backgroundColor: "$gray4" },
  "&:focus": { outline: "none" },
  "&:focus-visible": { outline: "2px solid $accent9", outlineOffset: 2 },
});

export const DialogRoot = DialogPrimitive.Root;
export const DialogTrigger = styled(DialogPrimitive.Trigger, {});

export const DialogTitle = StyledTitle;
export const DialogDescription = StyledDescription;

export const DialogClose = ({ ...props }) => {
  return (
    <StyledClose {...props}>
      <Cross2Icon height={16} width={16} />
    </StyledClose>
  );
};

const UnstyledContent = styled(DialogPrimitive.Content, {});

export const DialogContent = ({ children, css, ...props }) => {
  return (
    <DialogPrimitive.Portal>
      <StyledOverlay />
      <UnstyledContent
        onCloseAutoFocus={(e) => {
          e.preventDefault();
        }}
        css={{
          boxShadow:
            "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
          ...requiredContentCss,
          ...css,
        }}
        {...props}
      >
        {children}
      </UnstyledContent>
    </DialogPrimitive.Portal>
  );
};

export const Dialog = ({
  trigger,
  triggerCss,
  contentCss,
  children,
  title,
  description,
  defaultOpen,
  isOpen,
  onOpenChange,
  ...props
}) => {
  return (
    <DialogRoot
      {...props}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange ? () => onOpenChange(!isOpen) : null}
      open={isOpen}
    >
      {trigger && <DialogTrigger css={triggerCss}>{trigger}</DialogTrigger>}
      <Content
        onCloseAutoFocus={(e) => {
          e.preventDefault();
        }}
        css={contentCss}
      >
        {title && <DialogTitle>{title}</DialogTitle>}
        {description && <DialogDescription>{description}</DialogDescription>}
        {children}
        <StyledClose {...props}>
          <Cross2Icon height={16} width={16} />
        </StyledClose>
      </Content>
    </DialogRoot>
  );
};
