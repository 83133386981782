// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Closable.Backdrop.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, ContainerPrimitive } from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const BackdropContext = createContext(null);

function Backdrop(_props) {
  const defaults = useContext(BackdropContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="4HKBRHsV98"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "4HKBRHsV98",
        scope: "9goHzJYnv7",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_6EiBeNZrSp"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_9goHzJYnv7_6EiBeNZrSp"
          }
          key={"6EiBeNZrSp"}
          addSpacing={false}
          element={getStyle("6EiBeNZrSp", "htmlElement")}
        >
          {null}
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

Backdrop.__variants = [];

export default Backdrop;
