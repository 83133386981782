import React from "react";
import { PayoutHist } from "../../visly/Payout";
import { useBreakpoint } from "../../visly";

const PayoutHistory = ({ payouts }) => {
  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);

  return (
    payouts && (
      <PayoutHist size={size} Header={<PayoutHist.Header size={size} />}>
        {payouts.map((payout, index) => (
          <PayoutHist.Payout
            key={index}
            size={size}
            amount={payout.payoutAmount}
            bank={payout.bankName}
            lastFour={payout.lastFour}
            date={payout.dateString}
            time={payout.timeString}
          />
        ))}
      </PayoutHist>
    )
  );
};

export default PayoutHistory;
