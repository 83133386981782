// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./AccountPage.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";
import {
  default as IconButton,
  IconButtonContext,
} from "../Primitives/IconButton";

const styles = [
  {
    type: "default",
    layers: {
      LD5qQJe8bP: {
        none: {
          text: "Your Account",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      RYexQFEsde: {
        none: {
          icon: icons.chevronLeft,
        },
      },
    },
  },
];

const variantPropTypes = [];

export const AccountPageContext = createContext(null);

function AccountPage(_props) {
  const defaults = useContext(AccountPageContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="C5Ld47ZcuS"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "C5Ld47ZcuS",
        scope: "PTugrNhgoo",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => [
        props.IconButton === undefined ? (
          <IconButton
            key={"RYexQFEsde"}
            {...getCompositeDefaultProps("RYexQFEsde")}
            id="IconButton_RYexQFEsde"
            className="__visly_reset_7X7HTLRqyD __visly_scope_PTugrNhgoo_RYexQFEsde"
          />
        ) : (
          <IconButtonContext.Provider
            key="RYexQFEsde-provider"
            value={{
              key: "RYexQFEsde",
              id: "IconButton_RYexQFEsde",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_PTugrNhgoo_RYexQFEsde",
              ...getCompositeDefaultProps("RYexQFEsde"),
            }}
          >
            {props.IconButton}
          </IconButtonContext.Provider>
        ),
        <SpacerPrimitive
          id={"Spacer_K9GnJQmSCZ"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_PTugrNhgoo_K9GnJQmSCZ"
          }
          key={"K9GnJQmSCZ"}
        />,
        <TextPrimitive
          id={"Text_LD5qQJe8bP"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_PTugrNhgoo_LD5qQJe8bP"
          }
          key={"LD5qQJe8bP"}
          text={getStyle("LD5qQJe8bP", "text")}
          element={getStyle("LD5qQJe8bP", "htmlElement")}
        />,
        <SpacerPrimitive
          id={"Spacer_6MTn41wXoh"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_PTugrNhgoo_6MTn41wXoh"
          }
          key={"6MTn41wXoh"}
        />,
        <ContainerPrimitive
          key={"14ib7bSoS7"}
          id={"Slot_14ib7bSoS7"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_PTugrNhgoo_14ib7bSoS7"
          }
          addSpacing={false}
        >
          {props.children}
        </ContainerPrimitive>,
      ]}
    </RootPrimitive>
  );
}

AccountPage.IconButton = IconButton;
AccountPage.__variants = [];

export default AccountPage;
