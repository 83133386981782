// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./OptionSelectModal.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as CloseButton } from "./OptionSelectModal.CloseButton";

const styles = [
  {
    type: "default",
    layers: {
      "3dsVNUVnjd": {
        none: {
          text:
            "This will do something, are you sure you want to do something?",
          htmlElement: "title",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "isOpen",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "isOpen",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "isOpen",
  },
];

export const OptionSelectModalContext = createContext(null);

function OptionSelectModal(_props) {
  const defaults = useContext(OptionSelectModalContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="YZxBoAevRX"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "YZxBoAevRX",
        scope: "M4TBmdE25w",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"options-container_RESn3vZcUg"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_M4TBmdE25w_RESn3vZcUg"
          }
          key={"RESn3vZcUg"}
          addSpacing={false}
          element={getStyle("RESn3vZcUg", "htmlElement")}
        >
          {[
            <SpacerPrimitive
              id={"Spacer_VtKB5L4xDg"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_M4TBmdE25w_VtKB5L4xDg"
              }
              key={"VtKB5L4xDg"}
            />,
            <TextPrimitive
              id={"message_3dsVNUVnjd"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_M4TBmdE25w_3dsVNUVnjd"
              }
              key={"3dsVNUVnjd"}
              text={
                exists(props.message)
                  ? props.message
                  : getStyle("3dsVNUVnjd", "text")
              }
              element={getStyle("3dsVNUVnjd", "htmlElement")}
            />,
            <SpacerPrimitive
              id={"Spacer_SkKucJ2ZAs"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_M4TBmdE25w_SkKucJ2ZAs"
              }
              key={"SkKucJ2ZAs"}
            />,
            <ContainerPrimitive
              key={"9GRxzTuwQ7"}
              id={"Slot_9GRxzTuwQ7"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_M4TBmdE25w_9GRxzTuwQ7"
              }
              addSpacing={false}
            >
              {props.children}
            </ContainerPrimitive>,
            <SpacerPrimitive
              id={"Spacer_L9uBLzq5Re"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_M4TBmdE25w_L9uBLzq5Re"
              }
              key={"L9uBLzq5Re"}
            />,
            props.CloseButton === undefined ? (
              <CloseButton key={"CGW2LmNhhU"} />
            ) : (
              props.CloseButton
            ),
            <SpacerPrimitive
              id={"Spacer_KmXzsermEW"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_M4TBmdE25w_KmXzsermEW"
              }
              key={"KmXzsermEW"}
            />,
          ]}
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

OptionSelectModal.__variants = [
  {
    name: "isOpen",
    type: "variant",
  },
];

export default OptionSelectModal;
