import { persistor } from "../../Store";
import { SET_CART_ID, SET_CART_COUNTDOWN } from "./types";
export const setCountdown = (countdown) => {
  return (dispatch) => {
    dispatch({
      type: SET_CART_COUNTDOWN,
      payload: countdown,
    });
  };
};

export const setCartId = (cartId) => {
  return (dispatch) => {
    dispatch({
      type: SET_CART_ID,
      payload: cartId,
    });
    persistor.flush();
  };
};
