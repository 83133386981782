// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Status.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      GYrYaUXopa: {
        none: {
          icon: icons.cal,
          useMask: true,
        },
      },
      X2Lm5LA9im: {
        none: {
          text: "Text",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "withIcon",
    layers: {},
  },
  {
    type: "enum",
    propName: "type",
    values: [
      {
        propValue: "asap",
        layers: {
          GYrYaUXopa: {
            none: {
              useMask: true,
            },
          },
          X2Lm5LA9im: {
            none: {
              text: "Walk-up",
            },
          },
        },
      },
      {
        propValue: "delivery",
        layers: {
          GYrYaUXopa: {
            none: {
              useMask: true,
            },
          },
          X2Lm5LA9im: {
            none: {
              text: "Delivery",
            },
          },
        },
      },
      {
        propValue: "recurring",
        layers: {
          GYrYaUXopa: {
            none: {
              icon: icons.repeat,
              useMask: true,
            },
          },
          X2Lm5LA9im: {
            none: {
              text: "Repeating",
            },
          },
        },
      },
      {
        propValue: "private",
        layers: {
          GYrYaUXopa: {
            none: {
              icon: icons.private,
              useMask: true,
            },
          },
          X2Lm5LA9im: {
            none: {
              text: "Private",
            },
          },
        },
      },
      {
        propValue: "pickup",
        layers: {
          GYrYaUXopa: {
            none: {
              useMask: true,
            },
          },
          X2Lm5LA9im: {
            none: {
              text: "Pickup",
            },
          },
        },
      },
      {
        propValue: "draft",
        layers: {
          X2Lm5LA9im: {
            none: {
              text: "Draft",
            },
          },
        },
      },
      {
        propValue: "complete",
        layers: {
          X2Lm5LA9im: {
            none: {
              text: "Complete",
            },
          },
        },
      },
      {
        propValue: "live",
        layers: {
          X2Lm5LA9im: {
            none: {
              text: "Live",
            },
          },
        },
      },
      {
        propValue: "scheduled",
        layers: {
          GYrYaUXopa: {
            none: {
              useMask: true,
            },
          },
          X2Lm5LA9im: {
            none: {
              text: "Published",
            },
          },
        },
      },
      {
        propValue: "outOfStock",
        layers: {
          X2Lm5LA9im: {
            none: {
              text: "OUT OF STOCK",
            },
          },
        },
      },
      {
        propValue: "wholesale",
        layers: {
          X2Lm5LA9im: {
            none: {
              text: "Wholesale",
            },
          },
        },
      },
      {
        propValue: "shipping",
        layers: {
          X2Lm5LA9im: {
            none: {
              text: "Shipping",
            },
          },
        },
      },
      {
        propValue: "received",
        layers: {
          GYrYaUXopa: {
            none: {
              icon: icons.checkmark,
              useMask: true,
            },
          },
          X2Lm5LA9im: {
            none: {
              text: "Received",
            },
          },
        },
      },
      {
        propValue: "partialRefund",
        layers: {
          GYrYaUXopa: {
            none: {
              icon: icons.angleArrow,
              useMask: true,
            },
          },
          X2Lm5LA9im: {
            none: {
              text: "Partial Refund",
            },
          },
        },
      },
      {
        propValue: "fullRefund",
        layers: {
          GYrYaUXopa: {
            none: {
              icon: icons.refund,
              useMask: true,
            },
          },
          X2Lm5LA9im: {
            none: {
              text: "Refunded",
            },
          },
        },
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "withIcon",
    layers: {},
  },
  {
    type: "enum",
    propName: "type",
    values: [
      {
        propValue: "asap",
        layers: {},
      },
      {
        propValue: "delivery",
        layers: {},
      },
      {
        propValue: "recurring",
        layers: {},
      },
      {
        propValue: "private",
        layers: {},
      },
      {
        propValue: "pickup",
        layers: {},
      },
      {
        propValue: "draft",
        layers: {},
      },
      {
        propValue: "complete",
        layers: {},
      },
      {
        propValue: "live",
        layers: {},
      },
      {
        propValue: "scheduled",
        layers: {},
      },
      {
        propValue: "outOfStock",
        layers: {},
      },
      {
        propValue: "wholesale",
        layers: {},
      },
      {
        propValue: "shipping",
        layers: {},
      },
      {
        propValue: "received",
        layers: {},
      },
      {
        propValue: "partialRefund",
        layers: {},
      },
      {
        propValue: "fullRefund",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "withIcon",
  },
  {
    type: "enum",
    propName: "type",
    propValues: [
      "asap",
      "delivery",
      "recurring",
      "private",
      "pickup",
      "draft",
      "complete",
      "live",
      "scheduled",
      "outOfStock",
      "wholesale",
      "shipping",
      "received",
      "partialRefund",
      "fullRefund",
    ],
  },
];

export const StatusContext = createContext(null);

function Status(_props) {
  const defaults = useContext(StatusContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="YBYcBgpAUC"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "YBYcBgpAUC",
        scope: "MvwDSTzBWG",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            key={"CsS4zz4EaL"}
            id={"Slot_CsS4zz4EaL"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_MvwDSTzBWG_CsS4zz4EaL"
            }
            addSpacing={false}
          >
            {props.animation}
          </ContainerPrimitive>
          <IconPrimitive
            id={"Icon_GYrYaUXopa"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_MvwDSTzBWG_GYrYaUXopa"
            }
            key={"GYrYaUXopa"}
            useMask={getStyle("GYrYaUXopa", "useMask")}
            src={
              exists(props.icon) ? props.icon : getStyle("GYrYaUXopa", "icon")
            }
          />
          <SpacerPrimitive
            id={"Spacer_WKXYU4ukdj"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_MvwDSTzBWG_WKXYU4ukdj"
            }
            key={"WKXYU4ukdj"}
          />
          <TextPrimitive
            id={"Text_X2Lm5LA9im"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_MvwDSTzBWG_X2Lm5LA9im"
            }
            key={"X2Lm5LA9im"}
            text={
              exists(props.text) ? props.text : getStyle("X2Lm5LA9im", "text")
            }
            element={getStyle("X2Lm5LA9im", "htmlElement")}
          />
        </>
      )}
    </RootPrimitive>
  );
}

Status.__variants = [
  {
    name: "withIcon",
    type: "variant",
  },
  {
    name: "type",
    type: "group",
    variants: [
      "asap",
      "delivery",
      "recurring",
      "private",
      "pickup",
      "draft",
      "complete",
      "live",
      "scheduled",
      "outOfStock",
      "wholesale",
      "shipping",
      "received",
      "partialRefund",
      "fullRefund",
    ],
  },
];

export default Status;
