/* eslint-disable react/prop-types */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import ReactLoading from "react-loading";
import classNames from "classnames";
import { WarningBubble, Button } from "../../visly/Primitives";
import { LabeledInput } from "../../visly/Events";
import _ from "lodash";
import {
  getAddressString,
  capitalizeFirstLetter,
  getFormattedPhone,
  getUnFormattedPhone,
} from "@hotplate/utils-ts/helperFunctions";
import Modal from "../../hotplate-common/Modal";
import Tooltip from "../../hotplate-common/ToolTip";
import AddressInput from "../../hotplate-common/AddressInput";

import { editOrder } from "../../hotplate-storefront/actions";
import DayPicker from "../components/DayPicker";
import { TextSwitch } from "../../hotplate-common/primitives/TextSwitch";
import SmartTextArea from "../../hotplate-common/SmartTextArea";
import Spacer from "../../hotplate-common/Spacer";
import { OrderEditing } from "../../visly/Management/Packing";
import "./css/AddEditOrder.css";
import { textstyles, colors } from "../../visly";
import { Input } from "../../hotplate-common/primitives/Input";
import { trackOrderEdited } from "./analytics";
import { usePortalUser } from "../../auth";
import { trpc } from "../../trpc";
import { P } from "../../hotplate-common/primitives/Containers";

class AddEditOrder extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      order: _.cloneDeep(props.order),
      notifyCustomer: true,
      addItemToOrderOpen: false,
      menuItemToBeAddedQuantity: 1,
      menuItemToBeAdded: null,
      priceIncreased: false,
      timeEdited: false,
      addressEditingOpen: false,
      unsavedChanges: false,
      optionSelectModalState: null,
      locationSelected: null,
    };

    this.setStartTime = this.setStartTime.bind(this);
    this.setEndTime = this.setEndTime.bind(this);
    this.setDeliveryAddressDict = this.setDeliveryAddressDict.bind(this);
    this.setDeliveryAddressUnit = this.setDeliveryAddressUnit.bind(this);
    this.setDate = this.setDate.bind(this);
    this.setTimeEdited = this.setTimeEdited.bind(this);
    this.setPriceIncreased = this.setPriceIncreased.bind(this);
    this.setCartType = this.setCartType.bind(this);
    this.setAddressEditingOpen = this.setAddressEditingOpen.bind(this);
    this.setUnsavedChanges = this.setUnsavedChanges.bind(this);
    this.setOptionSelectModalState = this.setOptionSelectModalState.bind(this);
    this.closeOptionSelectModal = this.closeOptionSelectModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { order, editOrderLoading, editOrderError, manuallyAddOrder, setEditOrderData } =
      this.props;
    this.setUnsavedChanges();
    if (prevProps.order !== order) {
      this.setState({ order: _.cloneDeep(order) });
    }

    if (prevProps.editOrderLoading && !editOrderLoading && editOrderError === "") {
      setEditOrderData(null);
    }
    if (
      prevProps.manuallyAddOrder.isLoading &&
      !manuallyAddOrder.isLoading &&
      manuallyAddOrder.isSuccess
    ) {
      setEditOrderData(null);
    }
  }

  setOptionSelectModalState(message, optionSelectButtons) {
    this.setState({
      optionSelectModalState: {
        message: message,
        optionSelectButtons: optionSelectButtons,
        closeOptionSelectModal: this.closeOptionSelectModal,
      },
    });
  }

  closeOptionSelectModal() {
    this.setState({ optionSelectModalState: null });
  }

  setUnsavedChanges() {
    _.isEqual(this.props.order, this.state.order)
      ? this.setState({ unsavedChanges: false })
      : this.setState({ unsavedChanges: true });
  }

  orderIsRange() {
    const { order } = this.state;
    return order.timeSlot.startTime !== order.timeSlot.endTime;
  }

  setTimeEdited(bool) {
    this.setState({ timeEdited: bool });
  }

  setPriceIncreased(bool) {
    this.setState({ priceIncreased: bool });
  }

  setStartTime(timeAMPM) {
    const { order } = this.state;
    const newOrder = _.cloneDeep(order);

    const prevD = new Date(newOrder.timeSlot.startTime);
    const hours = parseInt(timeAMPM.split(":")[0]);
    const minutes = parseInt(timeAMPM.split(":")[1].substr(0, 2));
    const ampm = timeAMPM.split(":")[1].substr(2, 2);
    prevD.setHours((hours % 12) + (ampm === "PM" ? 12 : 0), minutes, 0, 0);
    newOrder.timeSlot.startTime = prevD.getTime();
    if (!this.orderIsRange()) newOrder.timeSlot.endTime = newOrder.timeSlot.startTime;
    this.setState({ order: newOrder });
    this.setTimeEdited(true);
    this.setUnsavedChanges();
  }

  setEndTime(timeAMPM) {
    const { order } = this.state;
    const newOrder = _.cloneDeep(order);

    const prevD = new Date(newOrder.timeSlot.endTime);
    const hours = parseInt(timeAMPM.split(":")[0]);
    const minutes = parseInt(timeAMPM.split(":")[1].substr(0, 2));
    const ampm = timeAMPM.split(":")[1].substr(2, 2);
    prevD.setHours((hours % 12) + (ampm === "PM" ? 12 : 0), minutes, 0, 0);
    newOrder.timeSlot.endTime = prevD.getTime();
    this.setState({ order: newOrder });
    this.setTimeEdited(true);
    this.setUnsavedChanges();
  }

  setChefNotes(notes) {
    const { order } = this.state;
    const newOrder = _.cloneDeep(order);
    newOrder.chefNotes = notes;
    this.setState({ order: newOrder });
  }

  setDate(timestamp) {
    const { order } = this.state;
    const newOrder = _.cloneDeep(order);

    const nextD = new Date(timestamp);

    const prevDStartEndTime = new Date(newOrder.timeSlot.startTime);
    prevDStartEndTime.setYear(nextD.getFullYear());
    prevDStartEndTime.setMonth(nextD.getMonth(), nextD.getDate());
    newOrder.timeSlot.startTime = prevDStartEndTime.getTime();

    const prevDEndTime = new Date(newOrder.timeSlot.endTime);
    prevDEndTime.setYear(nextD.getFullYear());
    prevDEndTime.setMonth(nextD.getMonth(), nextD.getDate());
    newOrder.timeSlot.endTime = prevDEndTime.getTime();

    this.setState({ order: newOrder });
    this.setTimeEdited(true);
    this.setUnsavedChanges();
  }

  setCartType() {
    const { order } = this.state;
    const newOrder = _.cloneDeep(order);
    newOrder.cartType = newOrder.cartType === "pickup" ? "delivery" : "pickup";
    this.setState({ order: newOrder });
    // if we set the new cartType to be pickup, priceIncreased is false, otherwise, it is true
    newOrder.cartType === "pickup" ? this.setPriceIncreased(false) : this.setPriceIncreased(true);
    newOrder.cartType === "pickup"
      ? this.setAddressEditingOpen(false)
      : this.setAddressEditingOpen(true);
  }

  setAddressEditingOpen(bool) {
    this.setState({ addressEditingOpen: bool });
  }

  setDeliveryAddressDict(addressDict) {
    const { order } = this.state;
    const newOrder = _.cloneDeep(order);
    newOrder.customerAddressDict = addressDict;
    this.setState({ order: newOrder });
  }

  setDeliveryAddressUnit(addressUnit) {
    const { order } = this.state;
    const newOrder = _.cloneDeep(order);
    if (!newOrder.customerAddressDict) newOrder.customerAddressDict = {};
    newOrder.customerAddressDict.unit = addressUnit;
    this.setState({ order: newOrder });
  }

  setCustomerDeliveryInstructions(customerDeliveryInstructions) {
    const { order } = this.state;
    const newOrder = _.cloneDeep(order);
    newOrder.customerDeliveryInstructions = customerDeliveryInstructions;
    this.setState({ order: newOrder });
  }

  setFirstName(firstName) {
    const { order } = this.state;
    const newOrder = _.cloneDeep(order);
    newOrder.firstName = firstName;
    newOrder.fullName = firstName + " " + newOrder.lastName;
    this.setState({ order: newOrder });
  }

  setLastName(lastName) {
    const { order } = this.state;
    const newOrder = _.cloneDeep(order);
    newOrder.lastName = lastName;
    newOrder.fullName = newOrder.firstName + " " + lastName;
    this.setState({ order: newOrder });
  }

  async setPhoneNumber(phone) {
    const { getUserDetailsFromPhone } = this.props;
    const { order } = this.state;
    const newOrder = _.cloneDeep(order);
    newOrder.phone = getFormattedPhone(phone, false);
    this.setState({ order: newOrder });
    if (newOrder.phone.length === 14) {
      try {
        const json = await getUserDetailsFromPhone.mutateAsync({
          phone: "+1" + getUnFormattedPhone(newOrder.phone),
        });
        for (const key in json) {
          if (json[key]) newOrder[key] = json[key];
        }
        if (!_.isEqual(order, newOrder)) this.setState({ order: newOrder });
      } catch (e) {
        console.log(e);
      }
    } else {
      this.setState({ order: newOrder });
    }
  }

  setEmail(email) {
    const { order } = this.state;
    const newOrder = _.cloneDeep(order);
    newOrder.email = email;
    this.setState({ order: newOrder });
  }

  setLocation(locationId) {
    const { locations } = this.props;
    const { order } = this.state;
    const newOrder = _.cloneDeep(order);
    newOrder.location = locations[locationId];
    this.setState({ order: newOrder });
  }

  setEventId(eventId) {
    const { order } = this.state;
    const newOrder = _.cloneDeep(order);
    newOrder.eventId = eventId;
    this.setState({ order: newOrder });
  }

  isOrderIncomplete() {
    const { order } = this.state;
    return (
      !order.firstName ||
      !order.lastName ||
      !order.phone ||
      (!order.location?.title && order.cartType === "pickup") ||
      !order.timeSlot?.startTime ||
      !order.cartItems ||
      order.cartItems.length === 0 ||
      (order.cartType === "delivery" && !order.customerAddressDict) ||
      order.phone.length !== 14
    );
  }

  addItemToOrder() {
    const { order, menuItemToBeAddedQuantity, menuItemToBeAdded } = this.state;
    const newOrder = _.cloneDeep(order);
    let noDuplicateFound = true;
    for (let i = 0; i < newOrder.cartItems.length; i++) {
      const cartItem = newOrder.cartItems[i];
      if (cartItem.title === menuItemToBeAdded.title && cartItem.manuallyAddedItem) {
        cartItem.quantity += menuItemToBeAddedQuantity;
        noDuplicateFound = false;
        break;
      }
    }
    if (noDuplicateFound) {
      newOrder.cartItems.push({
        id: menuItemToBeAdded.id,
        title: menuItemToBeAdded.title,
        price: menuItemToBeAdded.price,
        quantity: menuItemToBeAddedQuantity,
        manuallyAddedItem: true,
      });
    }
    this.setState({
      order: newOrder,
      menuItemToBeAddedQuantity: 1,
      menuItemToBeAdded: null,
      addItemToOrderOpen: false,
    });
    this.setPriceIncreased(true);
  }

  render() {
    const {
      getItemQuantity,
      editOrder,
      editOrderError,
      editOrderLoading,
      setEditOrderData,
      manuallyAddOrder,
      hostId,
      isChefCreatingNewOrder,
      menuItems,
      locations,
      getUserDetailsFromPhone,
      getEventDetails,
    } = this.props;
    const {
      order,
      notifyCustomer,
      addItemToOrderOpen,
      menuItemToBeAddedQuantity,
      menuItemToBeAdded,
      priceIncreased,
      timeEdited,
      addressEditingOpen,
      unsavedChanges,
      optionSelectModalState,
      selectedLocation,
    } = this.state;
    if (!order) return null;

    const warningBubbleClass = classNames({
      "warning-container": true,
      closed: !priceIncreased && !timeEdited,
    });

    const addressPreviewClass = classNames({
      "address-preview": true,
      closed: addressEditingOpen,
    });

    const addressEditingClass = classNames({
      "address-editing": true,
      closed: !addressEditingOpen,
    });

    const eventDetails = getEventDetails.data;

    return (
      <Modal
        hideClose={false}
        optionSelectModalState={optionSelectModalState}
        closeModal={() => setEditOrderData(null)}
      >
        {isChefCreatingNewOrder && (
          <div
            style={{
              position: "absolute",
              top: "24px",
              width: "100%",
              backgroundColor: "white",
              height: "24px",
              display: "flex",
              alignItems: "flex-end",
              paddingLeft: "24px",
              zIndex: 1,
            }}
          >
            <text
              style={{
                color: "#233434",
                textAlign: "start",
                whiteSpace: "normal",
                fontFamily: "Arboria, sans-serif",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "24px",
                flexShrink: 1,
              }}
            >
              Add Order
            </text>
          </div>
        )}
        <OrderEditing
          name={isChefCreatingNewOrder ? "" : order.fullName}
          orderNum={
            <div
              style={{
                marginLeft: isChefCreatingNewOrder ? "-8px" : "0px",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <text>{"#" + order.orderId}</text>
              {isChefCreatingNewOrder && (
                <>
                  <Input
                    placeholder={"Phone Number (required)"}
                    value={order.phone}
                    onChange={async (e) => {
                      this.setPhoneNumber(e.target.value);
                    }}
                    loading={getUserDetailsFromPhone.isLoading}
                    loadingAnimation={
                      <ReactLoading type="spin" color="#000000" height={20} width={20} />
                    }
                  />
                  {getUserDetailsFromPhone.error && (
                    <P css={{ color: "$warning10", fontWeight: "bold" }}>
                      Error - No customer found with this phone, enter their information and we will
                      save it for next time
                    </P>
                  )}
                  {getUserDetailsFromPhone.isSuccess && (
                    <P css={{ color: "$success10", fontWeight: "bold" }}>
                      Success - Customer found, information autofilled
                    </P>
                  )}
                  <div
                    style={{ display: "flex", flexDirection: "row", gap: "8px", flexFlow: "wrap" }}
                  >
                    <Input
                      css={{ flex: 1 }}
                      placeholder={"First Name"}
                      value={order.firstName}
                      onChange={(e) => {
                        this.setFirstName(e.target.value);
                      }}
                      loading={getUserDetailsFromPhone.isLoading}
                      loadingAnimation={
                        <ReactLoading type="spin" color="#000000" height={20} width={20} />
                      }
                    />
                    <Input
                      css={{ flex: 1 }}
                      placeholder={"Last Name"}
                      value={order.lastName}
                      onChange={(e) => {
                        this.setLastName(e.target.value);
                      }}
                      loading={getUserDetailsFromPhone.isLoading}
                      loadingAnimation={
                        <ReactLoading type="spin" color="#000000" height={20} width={20} />
                      }
                    />
                  </div>
                  <Input
                    placeholder={"Email"}
                    value={order.email}
                    onChange={(e) => {
                      this.setEmail(e.target.value);
                    }}
                    loading={getUserDetailsFromPhone.isLoading}
                    loadingAnimation={
                      <ReactLoading type="spin" color="#000000" height={20} width={20} />
                    }
                  />
                  <OrderEditing.AddItemToOrder.ItemSelect
                    style={{ width: "100%" }}
                    label={
                      order.eventId && !getEventDetails.isLoading ? (
                        eventDetails[order.eventId].title
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            // width: "100%",
                            gap: "12px",
                            justifyContent: "space-between",
                          }}
                        >
                          <p>(Optional) Select an event</p>
                          {getEventDetails.isLoading && (
                            <ReactLoading type="spin" color="#000000" height={20} width={20} />
                          )}
                        </div>
                      )
                    }
                    selectedKey={order.eventId ? order.eventId : undefined}
                    onSelectionChange={(eventId) => this.setEventId(eventId)}
                  >
                    {eventDetails &&
                      eventDetails.constructor == Object &&
                      Object.keys(eventDetails)
                        .sort((aId, bId) => {
                          const a = eventDetails[aId];
                          const b = eventDetails[bId];
                          return b.goLiveTime - a.goLiveTime;
                        })
                        .map((eventId) => {
                          return (
                            <OrderEditing.AddItemToOrder.ItemSelect.Option
                              key={eventId}
                              label={eventDetails[eventId].title}
                            />
                          );
                        })}
                  </OrderEditing.AddItemToOrder.ItemSelect>
                  <p>
                    This order will count towards inventory & timeslot restrictions of the selected
                    event.
                  </p>
                </>
              )}
            </div>
          }
          warningBubble={
            !isChefCreatingNewOrder && (
              <div className={warningBubbleClass}>
                <WarningBubble
                  text={
                    priceIncreased
                      ? "You must invoice the customer for any price increases to the order."
                      : timeEdited
                      ? "Editing the pickup time will ignore any per day or per time slot limits you have set on your items."
                      : ""
                  }
                />
              </div>
            )
          }
          EditCartType={
            <div style={{ marginBottom: "8px" }}>
              <TextSwitch
                defaultChecked={order.cartType === "delivery"}
                uncheckedContent="Pickup"
                checkedContent="Delivery"
                width={95}
                id="cartTypeSwitch"
                onCheckedChange={() =>
                  this.setCartType(order.cartType === "pickup" ? "delivery" : "pickup")
                }
              />
            </div>
          }
          EditLocation={
            order.cartType === "delivery" ? (
              <OrderEditing.EditLocation
                label={`${capitalizeFirstLetter(order.cartType)} Location`}
                style={{ paddingBottom: "24px" }}
                Input={
                  <div className="flex column width-100">
                    {order.cartType === "delivery" && (
                      <div>
                        <div className={addressPreviewClass}>
                          <span
                            style={{
                              ...textstyles.body,
                              color: colors.gray800,
                              lineHeight: "24px",
                            }}
                          >
                            {order.customerAddressDict && order.customerAddressDict.streetNumber}{" "}
                            {order.customerAddressDict && order.customerAddressDict.streetRoute}
                          </span>
                          {order.customerAddressDict && order.customerAddressDict.unit && (
                            <span
                              style={{
                                ...textstyles.body,
                                color: colors.gray700,
                              }}
                            >
                              {order.customerAddressDict && order.customerAddressDict.unit}
                            </span>
                          )}
                          <span
                            style={{
                              ...textstyles.body,
                              color: colors.gray700,
                            }}
                          >
                            {order.customerAddressDict && order.customerAddressDict.city},{" "}
                            {order.customerAddressDict && order.customerAddressDict.state}{" "}
                            {order.customerAddressDict && order.customerAddressDict.zip}
                          </span>
                          <span
                            style={{
                              ...textstyles.quote,
                              color: colors.gray700,
                              padding: "8px 0px",
                            }}
                          >
                            {!order.customerDeliveryInstructions
                              ? "No instructions provided."
                              : order.customerDeliveryInstructions}
                          </span>
                        </div>
                        <div className={addressEditingClass}>
                          <AddressInput
                            setAddressDict={this.setDeliveryAddressDict}
                            setAddressUnit={this.setDeliveryAddressUnit}
                            addressDict={order.customerAddressDict}
                            setThisToUpdateAddressInput={getAddressString({
                              addressDict: order.customerAddressDict,
                              displayFullAddress: true,
                            })}
                            initialAddress={getAddressString({
                              addressDict: order.customerAddressDict,
                              displayFullAddress: true,
                            })}
                          />
                          <Spacer size="Large" />
                          <SmartTextArea
                            style={{ height: "328px", width: "100%" }}
                            maxLength={1000}
                            placeholder="No instructions provided"
                            onChange={(value) => this.setCustomerDeliveryInstructions(value)}
                            text={order.customerDeliveryInstructions}
                          />
                        </div>
                        <Button
                          style={{ height: "36px", width: "100px" }}
                          onClick={() => this.setAddressEditingOpen(!addressEditingOpen)}
                          text={addressEditingOpen ? "Collapse" : "Edit"}
                        />
                      </div>
                    )}
                  </div>
                }
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  marginBottom: "12px",
                }}
              >
                <text
                  style={{
                    color: "#233434",
                    textAlign: "start",
                    whiteSpace: "nowrap",
                    fontFamily: "Avenir, sans-serif",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "19px",
                  }}
                >
                  Select your pickup location
                </text>
                <OrderEditing.AddItemToOrder.ItemSelect
                  label={order.location.title ? order.location.title : "Select a location"}
                  selectedKey={order.location.id ? order.location.id : undefined}
                  onSelectionChange={(locationId) => this.setLocation(locationId)}
                >
                  {locations &&
                    locations.constructor == Object &&
                    Object.keys(locations)
                      .sort((aId, bId) => {
                        const a = locations[aId];
                        const b = locations[bId];
                        return a.title.localeCompare(b.title);
                      })
                      .map((locationId) => {
                        return (
                          <OrderEditing.AddItemToOrder.ItemSelect.Option
                            key={locations[locationId].id}
                            label={locations[locationId].title}
                          />
                        );
                      })}
                </OrderEditing.AddItemToOrder.ItemSelect>
              </div>
            )
          }
          AddItemToOrder={
            isChefCreatingNewOrder && (
              <OrderEditing.AddItemToOrder
                open={addItemToOrderOpen}
                ItemQuantityInput={
                  <OrderEditing.AddItemToOrder.ItemQuantityInput
                    value={menuItemToBeAddedQuantity}
                    onChange={(value) => {
                      if (/^[0-9]+$/.test(value) || value === "") {
                        if (value !== "") value = parseInt(value);
                        this.setState({ menuItemToBeAddedQuantity: value });
                      }
                    }}
                  />
                }
                input={
                  <OrderEditing.AddItemToOrder.ItemSelect
                    label={menuItemToBeAdded ? menuItemToBeAdded.title : "Select an item"}
                    selectedKey={menuItemToBeAdded?.id}
                    onSelectionChange={(menuItemId) =>
                      this.setState({ menuItemToBeAdded: menuItems[menuItemId] })
                    }
                  >
                    {menuItems &&
                      menuItems.constructor == Object &&
                      Object.keys(menuItems)
                        .sort((aId, bId) => {
                          const a = menuItems[aId];
                          const b = menuItems[bId];
                          return a.title.localeCompare(b.title);
                        })
                        .map((menuItemId) => {
                          return (
                            <OrderEditing.AddItemToOrder.ItemSelect.Option
                              key={menuItemId}
                              label={menuItems[menuItemId].title}
                            />
                          );
                        })}
                  </OrderEditing.AddItemToOrder.ItemSelect>
                }
                AddItemButton={
                  <OrderEditing.AddItemToOrder.AddItemButton
                    disabled={
                      addItemToOrderOpen && (!menuItemToBeAdded || !menuItemToBeAddedQuantity)
                    }
                    onClick={() => {
                      if (addItemToOrderOpen) this.addItemToOrder();
                      else this.setState({ addItemToOrderOpen: true });
                    }}
                  />
                }
                CancelAddItemButton={
                  <OrderEditing.AddItemToOrder.CancelAddItemButton
                    onClick={() =>
                      this.setState({
                        addItemToOrderOpen: false,
                        menuItemToBeAdded: null,
                        menuItemToBeAddedQuantity: 1,
                      })
                    }
                  />
                }
              />
            )
          }
          NotifyCustomer={
            !isChefCreatingNewOrder && (
              <OrderEditing.NotifyCustomer
                onClick={() => this.setState({ notifyCustomer: !notifyCustomer })}
                Checkbox={<OrderEditing.NotifyCustomer.Checkbox checked={notifyCustomer} />}
              />
            )
          }
          EditTimeslot={
            <OrderEditing.EditTimeslot
              label={`${capitalizeFirstLetter(order.cartType)} Time`}
              Input={
                <DayPicker
                  recurring={false}
                  dateLabel=" "
                  startTimeLabel=" "
                  endTimeLabel=" "
                  startTimestamp={order.timeSlot.startTime}
                  endTimestamp={this.orderIsRange() ? order.timeSlot.endTime : null}
                  setStartTime={this.setStartTime}
                  setEndTime={this.orderIsRange() ? this.setEndTime : null}
                  setDate={this.setDate}
                />
              }
            />
          }
          CancelButton={
            <OrderEditing.CancelButton
              onClick={() =>
                unsavedChanges
                  ? this.setOptionSelectModalState(
                      "You have unsaved changes that will be lost, are you sure?",
                      [
                        {
                          text: "Discard Changes",
                          type: "warning",
                          onClick: () => {
                            setEditOrderData(null);
                          },
                        },
                      ]
                    )
                  : setEditOrderData(null)
              }
            />
          }
          notes={
            <LabeledInput
              style={{ marginBottom: "24px", width: "100%" }}
              label="Order Notes (not shared with customers)"
              tooltip={
                <Tooltip text="Notes are only visible to you and will not be seen by the customer" />
              }
              Input={
                <SmartTextArea
                  small
                  maxLength={500}
                  onChange={(val) => this.setChefNotes(val)}
                  text={order.chefNotes ? order.chefNotes : ""}
                  placeholder="Add notes to this order"
                />
              }
            />
          }
          error={
            <>
              {editOrderError && (
                <OrderEditing.Error text="There was an error. Contact support or try again later" />
              )}
              {manuallyAddOrder.error && (
                <OrderEditing.Error text={manuallyAddOrder.error.message} />
              )}
            </>
          }
          SaveButton={
            <OrderEditing.SaveButton
              disabled={!unsavedChanges || (isChefCreatingNewOrder && this.isOrderIncomplete())}
              loading={editOrderLoading || manuallyAddOrder.isLoading}
              text={isChefCreatingNewOrder ? "Create Order" : "Save Changes"}
              loadingAnimation={
                <ReactLoading type={"bubbles"} color={"#FFFFFF"} width={50} height={50} />
              }
              onClick={() => {
                const newOrder = _.cloneDeep(order);
                newOrder.phone = "+1" + getUnFormattedPhone(order.phone);
                if (isChefCreatingNewOrder) {
                  manuallyAddOrder.mutateAsync({
                    order: {
                      ...newOrder,
                      email: newOrder.email || undefined,
                    },
                  });
                } else {
                  editOrder({ hostId, order: newOrder, notifyCustomer });
                  trackOrderEdited({
                    orderId: newOrder.paymentIntentId,
                    isCustomerNotified: notifyCustomer,
                  });
                }
              }}
            />
          }
        >
          {Array.isArray(order.cartItems) &&
            order.cartItems.map((cartItem, index) => {
              if (cartItem.deleted || getItemQuantity(cartItem) <= 0) return null;
              return (
                <OrderEditing.OrderEditingLineItem
                  key={index}
                  item={cartItem.title}
                  RefundItemButton={null}
                  quantity={cartItem.quantity}
                />
              );
            })}
        </OrderEditing>
        {/* {optionSelectModalState && <OptionSelectModal
          message={optionSelectModalState.message}
          closeModal={() => this.setState({optionSelectModalState: null})}
          optionSelectButtons={optionSelectModalState.optionSelectButtons}
        />} */}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menuItems: state.hostPortal.hostInfo.menuItems,
    locations: state.hostPortal.hostInfo.locations,
    editOrderLoading: state.orderManagement.editOrderLoading,
    editOrderError: state.orderManagement.editOrderError,
  };
};

export default connect(mapStateToProps, {
  editOrder,
})(function (props) {
  const { chefId } = usePortalUser();
  const getUserDetailsFromPhone = trpc.portal.getUserDetailsFromPhone.useMutation({ retry: 1 });
  const getEventDetails = trpc.portal.getEventDetails.useQuery();
  const manuallyAddOrder = trpc.portal.manuallyAddOrder.useMutation({ retry: 1 });
  return (
    <AddEditOrder
      {...props}
      hostId={chefId}
      getUserDetailsFromPhone={getUserDetailsFromPhone}
      getEventDetails={getEventDetails}
      manuallyAddOrder={manuallyAddOrder}
    />
  );
});
