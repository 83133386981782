import { SET_CART_COUNTDOWN, SET_CART_ID } from "../actions/types";

const INITIAL_STATE = {
  cartId: null, // MUST BE PERSISTED!
  countdown: undefined,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CART_ID:
      return { ...state, cartId: action.payload };
    case SET_CART_COUNTDOWN:
      return { ...state, countdown: action.payload };
    default:
      return state;
  }
};
