import {
  getDateTimeString,
  getSubscriptionOrdersCSVData,
} from "@hotplate/utils-ts/helperFunctions";
import {
  DotsVerticalIcon,
  DownloadIcon,
  EnvelopeClosedIcon,
  FilePlusIcon,
  PaperPlaneIcon,
  ReaderIcon,
} from "@radix-ui/react-icons";
import React, { useCallback, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch } from "react-redux";
import { Button } from "../../../hotplate-common/primitives/Button";
import { Dialog } from "../../../hotplate-common/primitives/Dialog";
import { DropdownMenu } from "../../../hotplate-common/primitives/DropdownMenu";
import { IconButton } from "../../../hotplate-common/primitives/IconButton";
import { TextArea } from "../../../hotplate-common/primitives/TextArea";
import { setToastPopupInfo as setToastPopupInfoUnconnected } from "../../../hotplate-storefront/actions";
import { styled } from "../../../stitches.config";
import { trpc } from "../../../trpc";
import { OrderExportDialog } from "../../components/OrderExportDialog";

function useConnectedFn(fn: any, dispatch: any) {
  return useCallback(
    (...args: any[]) => {
      return fn(...args)(dispatch);
    },
    [fn, dispatch]
  );
}

const EmailStringBox = styled("div", {
  display: "flex",
  padding: 12,
  borderRadius: 8,
  backgroundColor: "var(--secondary050)",
  color: "var(--secondary800)",
  fontFamily: "var(--avenir)",
  fontSize: 13,
  fontWeight: 400,
  lineHeight: 1.5,
  wordBreak: "break-all",
  maxWidth: "100%",
  maxHeight: "200px",
  overflow: "auto",
  marginBottom: 24,
  "& ::-webkit-scrollbar": {
    display: "none",
    appearance: "none",
  },
});

const EmailDialog = ({
  emailString,
  numEmails,
  ...props
}: {
  emailString: string;
  numEmails: number;
  [key: string]: any;
}) => {
  const [buttonText, setButtonText] = useState("Copy to Clipboard");
  return (
    <Dialog
      title="Email customers"
      description={`${numEmails} unique emails from your filter`}
      {...props}
    >
      <EmailStringBox>{emailString}</EmailStringBox>
      <Button
        css={{ width: "100%" }}
        color={buttonText === "Copy to Clipboard" ? "info" : "success"}
        variant="tinted"
        onClick={() => {
          navigator.clipboard.writeText(emailString).then(
            function () {
              setButtonText("Copied!");
              setTimeout(() => {
                setButtonText("Copy to Clipboard");
              }, 3000);
            },
            function () {
              setButtonText("Failed, try again");
              setTimeout(() => {
                setButtonText("Copy to Clipboard");
              }, 3000);
            }
          );
        }}
      >
        {buttonText}
      </Button>
    </Dialog>
  );
};

const SMSDialog = ({ phones }: { phones: Set<string> }) => {
  const dispatch = useDispatch();
  const setToastPopupInfo = useConnectedFn(setToastPopupInfoUnconnected, dispatch);

  const [content, setContent] = useState("");

  const sendSmsToSelf = trpc.portal.sendSmsToSelf.useMutation();
  const sendSmsToCustomers = trpc.portal.sendSmsToCustomers.useMutation();

  const createErrorLine = (message: string) => (
    <p style={{ fontFamily: "var(--avenir)", fontSize: "12px", color: "red", marginTop: "5px" }}>
      Error: {message}
    </p>
  );

  return (
    <>
      <TextArea
        rows={5}
        maxLength={280}
        placeholder="Due to unforeseen circumstances, our Lego shop has run out of Legos, so we will be substituting with Mega Bloks during your pickup at 2 PM PT. If you would like a refund, please send us a DM on Instagram."
        value={content}
        onChange={(e: any) => {
          setContent(e.target.value);
        }}
        disabled={sendSmsToCustomers.isSuccess}
        css={sendSmsToCustomers.isSuccess ? { opacity: "60%" } : undefined}
      />
      <Button
        css={{ width: "100%", marginTop: "1em" }}
        color={content.length < 1 ? "gray" : sendSmsToSelf.isLoading ? "gray" : "info"}
        variant="tinted"
        loading={sendSmsToSelf.isLoading}
        disabled={content.length < 1 || sendSmsToSelf.isLoading}
        onClick={async () => {
          try {
            await sendSmsToSelf.mutateAsync({ message: content });
          } catch (e) {
            setToastPopupInfo({
              type: "error",
              text: `There was an error. Please try again, or contact support if this issue persists.`,
            });
          }
        }}
      >
        Text me
      </Button>
      {sendSmsToSelf.isError && createErrorLine(sendSmsToSelf.error.message)}
      <span style={{ fontFamily: "var(--avenir)", fontSize: "12px" }}>
        Use this to make sure your text message looks right before sending it to customers.
      </span>
      <Button
        css={{ width: "100%", marginTop: "1em" }}
        color={
          sendSmsToCustomers.isLoading
            ? "gray"
            : sendSmsToCustomers.isSuccess
            ? "success"
            : content.length < 10
            ? "gray"
            : "info"
        }
        variant="tinted"
        loading={sendSmsToCustomers.isLoading}
        disabled={
          content.length < 10 || sendSmsToCustomers.isLoading || sendSmsToCustomers.isSuccess
        }
        onClick={async () => {
          try {
            await sendSmsToCustomers.mutateAsync({
              message: content,
              phoneNumbers: Array.from(phones.values()),
            });
          } catch (e) {
            setToastPopupInfo({
              type: "error",
              text: `There was an error. Please try again, or contact support if this issue persists.`,
            });
          }
        }}
      >
        {sendSmsToCustomers.isSuccess
          ? `Text sent to ${phones.size} customers!`
          : content.length < 10
          ? "Minimum 10 characters"
          : `Text ${phones.size} customers`}
      </Button>
      {sendSmsToCustomers.isError && createErrorLine(sendSmsToCustomers.error.message)}
      {!(content.length < 10) && (
        <span style={{ fontFamily: "var(--avenir)", fontSize: "12px" }}>
          If any of these customers did not consent to SMS updates, they will not receive your
          message.
        </span>
      )}
    </>
  );
};

export const ManagementDropdown = ({
  filteredOrders,
  hostId,
  orderManagementAction,
  packingView,
  onPrintRequest,
  setIsChefCreatingNewOrder,
}: {
  filteredOrders: Record<string, any>;
  hostId: string;
  orderManagementAction: string;
  packingView: string;
  setIsChefCreatingNewOrder: () => void;
  onPrintRequest?: () => void;
}) => {
  const [showEmailDialog, setShowEmailDialog] = useState(false);
  const [showSMSDialog, setShowSMSDialog] = useState(false);
  const [showOrderExportDialog, setShowOrderExportDialog] = useState(false);

  let emailString = "";
  let emailCount = 0;
  const emailPattern =
    /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

  Object.keys(filteredOrders).forEach((key) => {
    const order = filteredOrders[key];

    let fullyRefunded = true;
    for (let i = 0; i < order.cartItems.length; i++) {
      const cartItem = order.cartItems[i];
      if (
        !Number.isInteger(cartItem.restockQuantity) ||
        cartItem.restockQuantity < cartItem.quantity
      )
        fullyRefunded = false;
    }

    if (!fullyRefunded && order.email && emailPattern.test(order.email)) {
      if (!emailString.includes(order.email)) {
        if (emailString.length > 0) emailString += ", ";
        emailString += `${order.fullName} <${order.email}>`;
        emailCount += 1;
      }
    }
  });

  const uniquePhones = new Set(Object.values(filteredOrders).map((order) => order.phone));

  return (
    <>
      <DropdownMenu
        trigger={<IconButton size="small" icon={<DotsVerticalIcon />} />}
        css={{ "@media print": { display: "none" } }}
      >
        {orderManagementAction === "PACKING" && (
          <DropdownMenu.Item
            text="Manually add order"
            hint={<FilePlusIcon />}
            onClick={() => setIsChefCreatingNewOrder(true)}
          />
        )}
        <DropdownMenu.Item
          text="Email customers"
          hint={<EnvelopeClosedIcon />}
          onClick={() => setShowEmailDialog(true)}
        />
        <DropdownMenu.Item
          text="Text customers"
          hint={<PaperPlaneIcon />}
          onClick={() => setShowSMSDialog(true)}
        />
        <DropdownMenu.Item
          text="Export orders"
          hint={<DownloadIcon />}
          onClick={() => {
            setShowOrderExportDialog(true);
          }}
        />
        {hostId === "dumplingclub" && (
          <CSVLink
            filename={"delivery-data_" + getDateTimeString() + ".csv"}
            style={{ textDecoration: "none", marginLeft: "auto" }}
            data={getSubscriptionOrdersCSVData(filteredOrders)}
          >
            <DropdownMenu.Item text="Export deliveries" hint={<DownloadIcon />} />
          </CSVLink>
        )}
        <DropdownMenu.Item
          text={`Print ${
            orderManagementAction === "PREP"
              ? "preplist"
              : packingView === "LIST"
              ? "checklist"
              : "tickets"
          }`}
          hint={<ReaderIcon />}
          onClick={() => {
            if (typeof onPrintRequest === "function") {
              onPrintRequest();
            }
          }}
        />
      </DropdownMenu>
      <OrderExportDialog
        orders={filteredOrders}
        trackSource={"order_management"}
        isOpen={showOrderExportDialog}
        onOpenChange={setShowOrderExportDialog}
      />
      <EmailDialog
        emailString={emailString}
        numEmails={emailCount}
        isOpen={showEmailDialog}
        onOpenChange={setShowEmailDialog}
      />
      <Dialog
        title="Text customers"
        description={`${uniquePhones.size} unique phone numbers from your filter`}
        isOpen={showSMSDialog}
        onOpenChange={setShowSMSDialog}
      >
        <SMSDialog phones={uniquePhones} />
      </Dialog>
    </>
  );
};
