import React from "react";
import { styled } from "../../../stitches.config";

const SettingsCardBox = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padx: 24,
  paddingBottom: 24,
  paddingTop: 16,
  borderRadius: "$lg",
  border: "1px solid $accent5",
  boxShadow: "$elevation4",
  backgroundColor: "$accent1",
  scrollMarginTop: 140, //dependent on height of the portal header && the height of the menu tabs
});

const SettingsCardTitle = styled("div", {
  ff: "$arboria",
  fs: "$md",
  fw: 500,
  color: "$accent12",
  marginBottom: "$lg",
});

export const SettingsCard = ({ title, children, ...props }) => {
  return (
    <SettingsCardBox {...props}>
      <SettingsCardTitle>{title}</SettingsCardTitle>
      {children}
    </SettingsCardBox>
  );
};
