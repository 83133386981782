// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./ItemizedReceipt.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as Status } from "../Events/Status";
import {
  default as DropdownMenu,
  DropdownMenuContext,
} from "../Primitives/DropdownMenu";
import {
  default as DatePlaced,
  ReceiptFieldContext as DatePlacedContext,
  default as PaymentId,
  ReceiptFieldContext as PaymentIdContext,
} from "../Management/ReceiptField";
import {
  default as ReceiptCard,
  default as ChefOrderSummary,
  ReceiptCardContext as ChefOrderSummaryContext,
} from "../Management/ReceiptCard";

const styles = [
  {
    type: "default",
    layers: {
      "8SjFeKAcnW": {
        none: {
          text: "No phone found",
        },
      },
      LZ6Yb6NPv1: {
        none: {
          text: "Customer Name",
        },
      },
      Dr8TZryd2x: {
        none: {
          text: "No email found",
        },
      },
      TqZtuMxqSL: {
        none: {
          text: "•",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      EdXK6F7Gsm: {
        none: {
          type: "received",
        },
      },
      L6GVjzHtkt: {},
      WyLtgrZHRE: {
        none: {
          label: "Date Placed",
          text: "None found",
          type: "first",
        },
      },
      AzfoWy5hwL: {
        none: {
          label: "Transaction ID",
          text: "None found",
          mono: true,
          type: "last",
        },
      },
      Q9aduefJZv: {},
      "5F24mSXFXH": {},
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {
          EdXK6F7Gsm: {},
          L6GVjzHtkt: {},
          WyLtgrZHRE: {},
          AzfoWy5hwL: {},
          Q9aduefJZv: {},
          "5F24mSXFXH": {},
        },
      },
      {
        propValue: "small",
        layers: {
          EdXK6F7Gsm: {},
          L6GVjzHtkt: {},
          WyLtgrZHRE: {},
          AzfoWy5hwL: {},
          Q9aduefJZv: {},
          "5F24mSXFXH": {},
        },
      },
      {
        propValue: "med",
        layers: {
          EdXK6F7Gsm: {},
          L6GVjzHtkt: {},
          WyLtgrZHRE: {},
          AzfoWy5hwL: {},
          Q9aduefJZv: {},
          "5F24mSXFXH": {},
        },
      },
      {
        propValue: "large",
        layers: {
          EdXK6F7Gsm: {},
          L6GVjzHtkt: {},
          WyLtgrZHRE: {},
          AzfoWy5hwL: {},
          Q9aduefJZv: {},
          "5F24mSXFXH": {},
        },
      },
      {
        propValue: "xlarge",
        layers: {
          EdXK6F7Gsm: {},
          L6GVjzHtkt: {},
          WyLtgrZHRE: {},
          AzfoWy5hwL: {},
          Q9aduefJZv: {},
          "5F24mSXFXH": {},
        },
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export const ItemizedReceiptContext = createContext(null);

function ItemizedReceipt(_props) {
  const defaults = useContext(ItemizedReceiptContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="Ddp1kR9e8Y"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "Ddp1kR9e8Y",
        scope: "egicbxzJTK",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => [
        <ContainerPrimitive
          id={"header-container_KGJsGfpZiD"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_KGJsGfpZiD"
          }
          key={"KGJsGfpZiD"}
          addSpacing={false}
          element={getStyle("KGJsGfpZiD", "htmlElement")}
        >
          <ContainerPrimitive
            id={"name-status-menu_8aygmQzR8y"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_8aygmQzR8y"
            }
            key={"8aygmQzR8y"}
            addSpacing={false}
            element={getStyle("8aygmQzR8y", "htmlElement")}
          >
            {[
              <ContainerPrimitive
                id={"name-status-container_KJH3oGQfs4"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_KJH3oGQfs4"
                }
                key={"KJH3oGQfs4"}
                addSpacing={false}
                element={getStyle("KJH3oGQfs4", "htmlElement")}
              >
                <TextPrimitive
                  id={"name_LZ6Yb6NPv1"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_LZ6Yb6NPv1"
                  }
                  key={"LZ6Yb6NPv1"}
                  text={
                    exists(props.name)
                      ? props.name
                      : getStyle("LZ6Yb6NPv1", "text")
                  }
                  element={getStyle("LZ6Yb6NPv1", "htmlElement")}
                />
                <SpacerPrimitive
                  id={"Spacer_KmgXBTEyLy"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_KmgXBTEyLy"
                  }
                  key={"KmgXBTEyLy"}
                />
                <ContainerPrimitive
                  key={"Af8v9rnUS9"}
                  id={"Slot_Af8v9rnUS9"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_Af8v9rnUS9"
                  }
                  addSpacing={false}
                >
                  {props.paymentStatus}
                </ContainerPrimitive>
              </ContainerPrimitive>,
              <SpacerPrimitive
                id={"Spacer_5Ko7XkJ38b"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_5Ko7XkJ38b"
                }
                key={"5Ko7XkJ38b"}
              />,
              props.DropdownMenu === undefined ? (
                <DropdownMenu
                  key={"L6GVjzHtkt"}
                  {...getCompositeDefaultProps("L6GVjzHtkt")}
                  id="DropdownMenu_L6GVjzHtkt"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_L6GVjzHtkt"
                />
              ) : (
                <DropdownMenuContext.Provider
                  key="L6GVjzHtkt-provider"
                  value={{
                    key: "L6GVjzHtkt",
                    id: "DropdownMenu_L6GVjzHtkt",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_L6GVjzHtkt",
                    ...getCompositeDefaultProps("L6GVjzHtkt"),
                  }}
                >
                  {props.DropdownMenu}
                </DropdownMenuContext.Provider>
              ),
            ]}
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"contact-container_FLTe5AGbwR"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_FLTe5AGbwR"
            }
            key={"FLTe5AGbwR"}
            addSpacing={false}
            element={getStyle("FLTe5AGbwR", "htmlElement")}
          >
            <TextPrimitive
              id={"email_Dr8TZryd2x"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_Dr8TZryd2x"
              }
              key={"Dr8TZryd2x"}
              text={
                exists(props.email)
                  ? props.email
                  : getStyle("Dr8TZryd2x", "text")
              }
              element={getStyle("Dr8TZryd2x", "htmlElement")}
            />
            <TextPrimitive
              id={"Text_TqZtuMxqSL"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_TqZtuMxqSL"
              }
              key={"TqZtuMxqSL"}
              text={getStyle("TqZtuMxqSL", "text")}
              element={getStyle("TqZtuMxqSL", "htmlElement")}
            />
            <TextPrimitive
              id={"phone_8SjFeKAcnW"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_8SjFeKAcnW"
              }
              key={"8SjFeKAcnW"}
              text={
                exists(props.phone)
                  ? props.phone
                  : getStyle("8SjFeKAcnW", "text")
              }
              element={getStyle("8SjFeKAcnW", "htmlElement")}
            />
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"date-id-container_T8Ym1LjDmZ"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_T8Ym1LjDmZ"
            }
            key={"T8Ym1LjDmZ"}
            addSpacing={false}
            element={getStyle("T8Ym1LjDmZ", "htmlElement")}
          >
            {[
              props.DatePlaced === undefined ? (
                <DatePlaced
                  key={"WyLtgrZHRE"}
                  {...getCompositeDefaultProps("WyLtgrZHRE")}
                  id="DatePlaced_WyLtgrZHRE"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_WyLtgrZHRE"
                />
              ) : (
                <DatePlacedContext.Provider
                  key="WyLtgrZHRE-provider"
                  value={{
                    key: "WyLtgrZHRE",
                    id: "DatePlaced_WyLtgrZHRE",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_WyLtgrZHRE",
                    ...getCompositeDefaultProps("WyLtgrZHRE"),
                  }}
                >
                  {props.DatePlaced}
                </DatePlacedContext.Provider>
              ),
              props.PaymentId === undefined ? (
                <PaymentId
                  key={"AzfoWy5hwL"}
                  {...getCompositeDefaultProps("AzfoWy5hwL")}
                  id="PaymentId_AzfoWy5hwL"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_AzfoWy5hwL"
                />
              ) : (
                <PaymentIdContext.Provider
                  key="AzfoWy5hwL-provider"
                  value={{
                    key: "AzfoWy5hwL",
                    id: "PaymentId_AzfoWy5hwL",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_AzfoWy5hwL",
                    ...getCompositeDefaultProps("AzfoWy5hwL"),
                  }}
                >
                  {props.PaymentId}
                </PaymentIdContext.Provider>
              ),
            ]}
          </ContainerPrimitive>
        </ContainerPrimitive>,
        <ContainerPrimitive
          key={"6sL2PqwkSN"}
          id={"Slot_6sL2PqwkSN"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_6sL2PqwkSN"
          }
          addSpacing={true}
        >
          {props.children}
        </ContainerPrimitive>,
        <SpacerPrimitive
          id={"Spacer_Y1P9BmZqBN"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_Y1P9BmZqBN"
          }
          key={"Y1P9BmZqBN"}
        />,
        props.ChefOrderSummary === undefined ? (
          <ChefOrderSummary
            key={"5F24mSXFXH"}
            {...getCompositeDefaultProps("5F24mSXFXH")}
            id="ChefOrderSummary_5F24mSXFXH"
            className="__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_5F24mSXFXH"
          />
        ) : (
          <ChefOrderSummaryContext.Provider
            key="5F24mSXFXH-provider"
            value={{
              key: "5F24mSXFXH",
              id: "ChefOrderSummary_5F24mSXFXH",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_egicbxzJTK_5F24mSXFXH",
              ...getCompositeDefaultProps("5F24mSXFXH"),
            }}
          >
            {props.ChefOrderSummary}
          </ChefOrderSummaryContext.Provider>
        ),
      ]}
    </RootPrimitive>
  );
}

ItemizedReceipt.Status = Status;
ItemizedReceipt.DropdownMenu = DropdownMenu;
ItemizedReceipt.DatePlaced = DatePlaced;
ItemizedReceipt.PaymentId = PaymentId;
ItemizedReceipt.ReceiptCard = ReceiptCard;
ItemizedReceipt.ChefOrderSummary = ChefOrderSummary;
ItemizedReceipt.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export default ItemizedReceipt;
