import { styled } from "../../stitches.config";
import ReactLoading from "react-loading";
import React from "react";
import type { CSS } from "@stitches/react";

const LoadingBox = styled("div", {
  display: "flex",
  justifyContent: "center",
  borderRadius: "inherit",
  alignItems: "center",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "inherit",
  color: "inherit",
});

export const Loading = (props: React.ComponentProps<typeof ReactLoading>) => {
  return (
    <LoadingBox className="btn-loading">
      <ReactLoading type={"bubbles"} color="inherit" height={35} width={35} {...props} />
    </LoadingBox>
  );
};

Loading.toString = () => ".btn-loading";

type ButtonColor = "gray" | "accent" | "success" | "danger" | "warning" | "info" | "hotplate";

type ButtonVariant = "filled" | "tinted" | "outlined" | "gray" | "plain";

interface ButtonStyles extends CSS {
  $$backgroundColorDefault: string;
  $$backgroundColorHover: string;
  $$backgroundColorActive: string;
  $$backgroundColorDisabled: string;
  $$colorDefault: string;
  $$colorHover: string;
  $$colorActive: string;
  $$colorDisabled: string;
  $$border: string;
}

interface CustomButtonStyles extends CSS {
  backgroundColor: string;
  backgroundColorHover: string;
  backgroundColorActive?: string;
  backgroundColorDisabled?: string;
  color: string;
  colorHover?: string;
  colorActive?: string;
  colorDisabled?: string;
  border?: string;
}

type ButtonStyleObject = { [key in ButtonVariant]: ButtonStyles };

type GetButtonStyle = (
  color: ButtonColor,
  variant: ButtonVariant,
  customStyles?: CustomButtonStyles
) => ButtonStyles;

const getButtonStyleTokens: GetButtonStyle = (color, variant, customStyles) => {
  if (customStyles) {
    const customStylesToButtonStyles: ButtonStyles = {
      $$backgroundColorDefault: customStyles.backgroundColor,
      $$backgroundColorHover: customStyles.backgroundColorHover,
      $$backgroundColorActive:
        customStyles.backgroundColorActive || customStyles.backgroundColorHover,
      $$backgroundColorDisabled:
        customStyles.backgroundColorDisabled || customStyles.backgroundColor,
      $$colorDefault: customStyles.color,
      $$colorHover: customStyles.colorHover || customStyles.color,
      $$colorActive: customStyles.colorActive || customStyles.colorHover || customStyles.color,
      $$colorDisabled: customStyles.colorDisabled || customStyles.color,
      $$border: customStyles.border || "none",
    };
    return customStylesToButtonStyles;
  }

  const styleObj: ButtonStyleObject = {
    filled: {
      $$backgroundColorDefault: `$colors$${color}9`,
      $$backgroundColorHover: `$colors$${color}10`,
      $$backgroundColorActive: `$colors$${color}10`,
      $$backgroundColorDisabled: `$colors$gray3`,
      $$colorDefault: color === "warning" ? "$colors$gray12" : "$white",
      $$colorHover: color === "warning" ? "$colors$gray12" : "$white",
      $$colorActive: color === "warning" ? "$colors$gray12" : "$white",
      $$colorDisabled: `$colors$gray10`,
      $$border: "none",
    },
    tinted: {
      $$backgroundColorDefault: `$colors$${color}4`,
      $$backgroundColorHover: `$colors$${color}5`,
      $$backgroundColorActive: `$colors$${color}6`,
      $$backgroundColorDisabled: `$colors$gray3`,
      $$colorDefault: `$colors$${color}${color === "gray" ? "12" : "11"}`,
      $$colorHover: `$colors$${color}${color === "gray" ? "12" : "11"}`,
      $$colorActive: `$colors$${color}${color === "gray" ? "12" : "11"}`,
      $$colorDisabled: `$colors$gray10`,
      $$border: "none",
    },
    outlined: {
      $$backgroundColorDefault: `transparent`,
      $$backgroundColorHover: `$colors$${color}3`,
      $$backgroundColorActive: `$colors$${color}4`,
      $$backgroundColorDisabled: `transparent`,
      $$colorDefault: `$colors$${color}${color === "gray" ? "12" : "11"}`,
      $$colorHover: `$colors$${color}${color === "gray" ? "12" : "11"}`,
      $$colorActive: `$colors$${color}${color === "gray" ? "12" : "11"}`,
      $$colorDisabled: `$colors$gray10`,
      $$border: "1px solid currentColor",
    },
    gray: {
      $$backgroundColorDefault: `$colors$gray4`,
      $$backgroundColorHover: `$colors$gray5`,
      $$backgroundColorActive: `$colors$gray6`,
      $$backgroundColorDisabled: `$colors$gray3`,
      $$colorDefault: `$colors$${color}${color === "gray" ? "12" : "11"}`,
      $$colorHover: `$colors$${color}${color === "gray" ? "12" : "11"}`,
      $$colorActive: `$colors$${color}${color === "gray" ? "12" : "11"}`,
      $$colorDisabled: `$colors$gray10`,
      $$border: "none",
    },
    plain: {
      $$backgroundColorDefault: `transparent`,
      $$backgroundColorHover: `transparent`,
      $$backgroundColorActive: `transparent`,
      $$backgroundColorDisabled: `transparent`,
      $$colorDefault: `$colors$${color}${color === "gray" ? "12" : "11"}`,
      $$colorHover: `$colors$${color}${color === "gray" ? "12" : "11"}`,
      $$colorActive: `$colors$${color}${color === "gray" ? "12" : "11"}`,
      $$colorDisabled: `$colors$gray10`,
      $$border: "none",
    },
  };

  return styleObj[variant];
};

const StyledButton = styled("button", {
  display: "flex",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "$avenir",
  fontWeight: "$bold",
  lh: "$reset",
  transition:
    "background-color 150ms ease-in-out, color 150ms ease-in-out, border-color 150ms ease-in-out",
  backgroundColor: "$$backgroundColorDefault",
  color: "$$colorDefault",
  whiteSpace: "nowrap",
  border: "$$border",
  borderRadius: "$$borderRadius",
  "&:hover": {
    backgroundColor: "$$backgroundColorHover",
    color: "$$colorHover",
  },
  "&:active": {
    backgroundColor: "$$backgroundColorActive",
    color: "$$colorActive",
  },
  "&:disabled": {
    pointerEvents: "none",
    cursor: "default",
    backgroundColor: "$$backgroundColorDisabled",
    color: "$$colorDisabled",
  },
  "&:focus": {
    outline: "none",
  },
  "&:focus-visible": {
    outline: "2px solid $info9",
    outlineOffset: 2,
  },
  [`& ${Loading}`]: {
    display: "none",
  },
  "& svg": {
    nudgeY: -1,
  },
  variants: {
    loading: {
      true: {
        pointerEvents: "none",
        cursor: "default",
        "& > svg": {
          display: "none",
        },
        [`& ${Loading}`]: {
          display: "flex",
          "& svg": {
            margin: 0,
            fill: "$$colorDefault",
          },
        },
      },
    },
    size: {
      xsmall: {
        height: "$xs_btn",
        padx: "$xs",
        gap: "$xxs",
        fs: 12,
        "& > svg": {
          size: 14,
        },
        $$borderRadius: "$radii$sm",
      },
      small: {
        $$borderRadius: "$radii$sm",
        height: "$sm_btn",
        padx: "$sm",
        gap: "$xs",
        fs: 14,
        "& > svg": {
          size: 15,
        },
      },
      medium: {
        $$borderRadius: "$radii$md",
        height: "$md_btn",
        fs: 14,
        gap: "$xs",
        padx: "$md",
        "& > svg": {
          size: 16,
        },
      },
      large: {
        $$borderRadius: "$radii$md",
        height: "$lg_btn",
        padx: "$lg",
        gap: "$sm",
        fs: 16,
        "& > svg": {
          size: 18,
        },
      },
    },
    shape: {
      square: {
        borderRadius: "$$borderRadius",
      },
      rounded: {
        borderRadius: "$pill",
      },
    },
    removePadding: {
      true: {
        padx: 0,
        height: "auto !important",
      },
    },
  },
  defaultVariants: {
    size: "medium",
    shape: "square",
    loading: false,
  },
});

interface ButtonProps extends React.ComponentProps<typeof StyledButton> {
  color: ButtonColor;
  variant: ButtonVariant;
  loading?: boolean;
  children: React.ReactNode;
  as?: string; // this actaully allows you to render the button as any html element, but I did not know how to define that type well
  href?: string; // if as is a link, this is the href
  target?: string; // if as is a link, this is the target
  customButtonColorStyle?: CustomButtonStyles;
}

export const Button = ({
  color, // sets base color
  variant, // sets overall style of button
  children,
  // optional props below
  css,
  loading,
  size,
  shape, // square or rounded
  removePadding, //used for "plain" variant buttons when you need bettwe alignment
  customButtonColorStyle, // used for custom button styles
  ...props
}: ButtonProps): JSX.Element => {
  return (
    <StyledButton
      size={size}
      shape={shape}
      removePadding={removePadding}
      loading={loading}
      css={{ ...getButtonStyleTokens(color, variant, customButtonColorStyle), ...css }}
      {...props}
    >
      {loading && <Loading />}
      {children}
    </StyledButton>
  );
};
