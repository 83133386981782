// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
export const black = "rgba(0,0,0,1)";
export const chefBadlarryburgerclub = "rgba(255,62,36,1)";
export const chefBakesum = "rgba(247,233,228,1)";
export const chefCalabama = "rgba(251,11,0,1)";
export const chefComfypupla = "rgba(69,91,224,1)";
export const chefDabaosingapore = "rgba(247,208,71,1)";
export const chefDoughdaddyla = "rgba(226,41,39,1)";
export const chefFrankgrizzly = "rgba(152,13,13,1)";
export const chefHappymediumsdeli = "rgba(0,158,245,1)";
export const chefLittlebluehousesj = "rgba(143,179,236,1)";
export const chefManeatingplant = "rgba(139,154,88,1)";
export const chefMisa = "rgba(249,190,50,1)";
export const chefSandoitchi = "rgba(216,44,43,1)";
export const chefTwojewishgirls = "rgba(255,219,87,1)";
export const chefUbeaddict = "rgba(140,0,186,1)";
export const danger = "rgba(213,0,0,1)";
export const dangerDark = "rgba(179,0,0,1)";
export const dangerLight = "rgba(255,128,128,0.75)";
export const dangerVeryDark = "rgba(128,0,0,1)";
export const dangerVeryLight = "rgba(255,204,204,1)";
export const gray050 = "rgba(248,250,250,1)";
export const gray100 = "rgba(236,240,240,1)";
export const gray200 = "rgba(220,227,227,1)";
export const gray300 = "rgba(204,211,211,1)";
export const gray400 = "rgba(167,178,178,1)";
export const gray500 = "rgba(130,137,137,1)";
export const gray600 = "rgba(92,100,101,1)";
export const gray700 = "rgba(68,80,80,1)";
export const gray800 = "rgba(35,52,52,1)";
export const gray900 = "rgba(20,25,25,1)";
export const green050 = "rgba(229,244,234,1)";
export const green100 = "rgba(193,228,203,1)";
export const green200 = "rgba(153,210,170,1)";
export const green300 = "rgba(111,193,136,1)";
export const green400 = "rgba(78,180,112,1)";
export const green500 = "rgba(39,168,88,1)";
export const green600 = "rgba(31,153,78,1)";
export const green700 = "rgba(20,135,66,1)";
export const green800 = "rgba(9,118,56,1)";
export const green900 = "rgba(0,87,37,1)";
export const iconButtonHover = "rgba(114,114,114,0.1)";
export const iconButtonOnPress = "rgba(114,114,114,0.25)";
export const lightModalBackground = "rgba(34,34,34,0.1)";
export const modalBackground = "rgba(34,34,34,0.6)";
export const orange050 = "rgba(255,249,226,1)";
export const orange100 = "rgba(254,238,181,1)";
export const orange200 = "rgba(254,227,132,1)";
export const orange300 = "rgba(254,217,82,1)";
export const orange400 = "rgba(254,207,44,1)";
export const orange500 = "rgba(255,199,17,1)";
export const orange600 = "rgba(255,186,7,1)";
export const orange700 = "rgba(255,166,3,1)";
export const orange800 = "rgba(255,149,0,1)";
export const orange900 = "rgba(216,101,1,1)";
export const primary050 = "rgba(253,236,239,1)";
export const primary100 = "rgba(251,207,213,1)";
export const primary200 = "rgba(233,157,159,1)";
export const primary300 = "rgba(222,120,123,1)";
export const primary400 = "rgba(232,91,91,1)";
export const primary500 = "rgba(237,77,68,1)";
export const primary600 = "rgba(222,68,66,1)";
export const primary700 = "rgba(204,59,60,1)";
export const primary800 = "rgba(191,54,53,1)";
export const primary900 = "rgba(176,43,42,1)";
export const purple050 = "rgba(245,228,244,1)";
export const purple100 = "rgba(231,188,229,1)";
export const purple200 = "rgba(215,143,213,1)";
export const purple300 = "rgba(198,97,196,1)";
export const purple400 = "rgba(185,58,183,1)";
export const purple500 = "rgba(171,0,171,1)";
export const purple600 = "rgba(157,0,166,1)";
export const purple700 = "rgba(138,0,159,1)";
export const purple800 = "rgba(121,0,152,1)";
export const purple900 = "rgba(90,0,140,1)";
export const secondary050 = "rgba(224,243,244,1)";
export const secondary100 = "rgba(178,225,226,1)";
export const secondary200 = "rgba(126,206,208,1)";
export const secondary300 = "rgba(68,186,187,1)";
export const secondary400 = "rgba(0,171,171,1)";
export const secondary500 = "rgba(0,156,154,1)";
export const secondary600 = "rgba(0,143,139,1)";
export const secondary700 = "rgba(0,126,122,1)";
export const secondary800 = "rgba(0,110,106,1)";
export const secondary900 = "rgba(0,82,76,1)";
export const textStandard = "rgba(69,69,69,1)";
export const white = "rgba(255,255,255,1)";
export const white25 = "rgba(255,255,255,0.25)";
export const white35 = "rgba(255,255,255,0.35)";
export const white50 = "rgba(255,255,255,0.5)";
export const white75 = "rgba(255,255,255,0.75)";
export const whiteOff = "rgba(250,250,250,1)";
