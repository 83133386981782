import React, { useCallback, useState } from "react";
import { styled } from "../../../stitches.config";
import { TableVirtuoso } from "react-virtuoso";
import { useSelector } from "react-redux";

// Functions
import {
  getReadableTimeSlot,
  titleCase,
  getTimestampDayOfWeek,
  getOrderType,
  getReadableOrderType,
  getAddressString,
} from "@hotplate/utils-ts/helperFunctions";

// Components/Assets
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { LineItem } from "./OrderLineItem";
import { TableRowLoader } from "../../../hotplate-common/loaders/PackingLoaders";
import { LabeledCustomerBadge } from "../../components/CustomerBadge";
import { Badge } from "../../../hotplate-common/primitives/Badge";
import { Checkbox } from "../../../hotplate-common/primitives/Checkbox";
import { Box, Table } from "../../../hotplate-common/primitives/Containers";
import { OrderInteraction } from "./OrderInteraction";
import { NothingHere } from "./NothingHere";
import { trackOrderMarkedComplete, trackOrderMarkedIncomplete } from "../analytics";

// #############################################################################
// ########                   STYLES                                    ########
// #############################################################################

const PackingTablePrimitive = styled("table", {
  maxWidth: "100%",
  minHeight: 0,
  mx: "auto",
  boxShadow: "$elevation5",
  "& thead tr th:first-child": {
    borderTopLeftRadius: 8,
  },
  "& thead tr th:last-child": {
    borderTopRightRadius: 8,
  },
  hideOnPrint: true,
});

const PackingTableBody = styled("tbody", {});

const PackingTableHead = styled("thead", {});

const FillerRow = styled("tr", {});

const PackingRowPrimitive = styled("tr", {
  textAlign: "left",
  width: "100%",
  alignItems: "center",
  borderBottom: "1px solid $gray6",
  transition: "background-color 125ms ease-in-out",
  "@media print": {
    display: "block",
    height: "auto !important",
    breakInside: "avoid-page",
    width: 100000,
  },
  variants: {
    type: {
      header: {
        height: 40,
        borderBottom: "2px solid $gray6",
      },
      row: {
        height: 52,
        backgroundColor: "$white",
        "&:nth-child(even)": {
          backgroundColor: "$accent1",
        },
        "&:hover": {
          backgroundColor: "$accent2",
        },
      },
    },
    state: {
      pinned: {
        backgroundColor: "$warning2",
      },
    },
  },
  defaultVariants: {
    type: "row",
  },
});

const CommonCellStyles = {
  ff: "$avenir",
  color: "$gray12",
  fs: 14,
  lineHeight: 1.5,
  verticalAlign: "middle",
  "@media print": {
    fs: 12,
    lineHeight: 1.25,
    verticalAlign: "baseline",
    borderLeft: "1px solid $gray6",
    borderRight: "1px solid $gray6",
    breakInside: "avoid-page",
  },
  variants: {
    pinned: {
      true: {
        backgroundColor: "$warning2",
      },
    },
  },
};

const PackingCellPrimitive = styled("td", {
  ...CommonCellStyles,
  fw: "$normal",
  wordBreak: "break-word",
  height: "100%",
});

const PackingHeaderCellPrimitive = styled("th", {
  ...CommonCellStyles,
  fw: "$bold",
  wordBreak: "break-word",
  backgroundColor: "$accent3",
});

const PackingCellContent = styled("div", {
  display: "flex",
  padx: 16,
  pady: 6,
  "@media print": {
    breakInside: "avoid-page",
  },
  variants: {
    type: {
      checkbox: {
        paddingRight: 0,
        "@media print": {
          padding: 8,
          width: "1cm",
        },
      },
      orderNumber: {
        whiteSpace: "nowrap",
        "@media print": {
          padding: 8,
          width: "2cm",
        },
      },
      customerName: {
        height: "100%",
        alignItems: "center",
        paddingRight: 0,
        minWidth: 200,
        "@media print": {
          height: "auto",
          minWidth: "auto",
          width: "4cm",
        },
      },
      customerEmail: {
        wordBreak: "break-word",
        minWidth: 100,
        color: "$gray11",
        "@media print": {
          height: "auto",
          width: "3cm",
        },
      },
      customerPhone: {
        whiteSpace: "nowrap",
        color: "$gray11",
        "@media print": {
          height: "auto",
          width: "3cm",
        },
      },
      orderItems: {
        flexDirection: "column",
        minWidth: "225px",
        "@media print": {
          height: "auto",
          width: "7cm",
        },
      },
      fulfillmentDate: {
        whiteSpace: "nowrap",
        "@media print": {
          height: "auto",
          width: "2cm",
        },
      },
      fulfillmentTime: {
        whiteSpace: "nowrap",
        "@media print": {
          height: "auto",
          width: "3.5cm",
        },
      },
      fulfillmentType: {
        whiteSpace: "nowrap",
        "@media print": {
          height: "auto",
          width: "2cm",
        },
      },
      dropdown: {
        paddingLeft: 0,
        paddingRight: 8,
      },
    },
  },
});

const NoOrdersPlaceholder = (
  <Box
    css={{
      position: "absolute",
      inset: 0,
      backgrouncColor: "$white",
      backdropFilter: "blur(4px)",
    }}
  >
    <NothingHere />
  </Box>
);

const PackingFillerRow = ({ columns }) => {
  const connectToOrdersLoading = useSelector(
    (state) => state.orderManagement.connectToOrdersLoading
  );

  // This component is  only used when the table is empty and is purely visual
  return (
    <>
      {connectToOrdersLoading ? (
        <PackingTableBody css={{ position: "relative" }}>
          {Array(20)
            .fill(0)
            .map((_, i) => (
              <TableRowLoader key={i} columns={columns} />
            ))}
        </PackingTableBody>
      ) : (
        <PackingTableBody css={{ position: "relative", height: 100 }}>
          {NoOrdersPlaceholder}
        </PackingTableBody>
      )}
    </>
  );
};

const DropdownPlaceholder = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 35,
  width: 35,
});

const PackingRowContent = ({ order, columns, functions, isScrolling, trackMetaData }) => {
  const {
    toggleShowCustomerModal,
    setEditOrderData,
    setSubscriptionModalData,
    toggleShowIrpReceipt,
    toggleOrderPinned,
    markLineItemDone,
    setOrderNotPacked,
    setOrderPacked,
    getItemQuantity,
  } = functions;

  let [orderPackedLoading, setOrderPackedLoading] = useState(false);

  return (
    <>
      <PackingCellPrimitive
        pinned={order.pinned}
        onClick={async () => {
          setOrderPackedLoading(true);
          try {
            if (order.packed) {
              await setOrderNotPacked(order);
              trackOrderMarkedIncomplete({
                ...trackMetaData,
                orderId: order.paymentIntentId,
              });
            } else {
              await setOrderPacked(order);
              trackOrderMarkedComplete({
                ...trackMetaData,
                orderId: order.paymentIntentId,
              });
            }
          } finally {
            setOrderPackedLoading(false);
          }
        }}
      >
        <PackingCellContent type="checkbox">
          <Checkbox size="medium" loading={orderPackedLoading} checked={order.packed} />
        </PackingCellContent>
      </PackingCellPrimitive>
      {columns.map(
        (column, idx) =>
          column.visible && (
            <PackingCellPrimitive pinned={order.pinned} key={idx}>
              <PackingCellContent type={column.id} key={idx}>
                {column.id === "orderNumber" && `#${order.orderId}`}
                {column.id === "customerName" && (
                  <LabeledCustomerBadge
                    css={{ color: "$gray12", pady: 12 }}
                    key={order.orderId}
                    size="small"
                    fullName={titleCase(order.fullName)}
                    numOrders={order.totalCustomerOrdersForChef}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleShowCustomerModal("list", order);
                    }}
                  />
                )}
                {column.id === "customerEmail" && `${order.email || "--"}`}
                {column.id === "customerPhone" && `${order.phone || "--"}`}
                {column.id === "orderItems" &&
                  order.cartItems
                    ?.sort((a, b) => {
                      return a.title
                        .toLowerCase()
                        .trim()
                        .localeCompare(b.title.toLowerCase().trim());
                    })
                    .map((item, index) => (
                      <LineItem
                        css={isScrolling ? { pointerEvents: "none" } : {}}
                        key={index}
                        view="list"
                        order={order}
                        item={item}
                        itemIndex={index}
                        functions={{
                          markLineItemDone: markLineItemDone,
                          getItemQuantity: getItemQuantity,
                        }}
                      />
                    ))}
                {column.id === "fulfillmentDate" &&
                  `${getTimestampDayOfWeek(order.timeSlot?.startTime, true)} ${
                    new Date(order.timeSlot?.startTime).getMonth() + 1
                  }/${new Date(order.timeSlot?.startTime).getDate()}`}
                {column.id === "fulfillmentTime" && `${getReadableTimeSlot(order.timeSlot)}`}
                {column.id === "fulfillmentType" && (
                  <Badge size="small" type={getOrderType(order)}>
                    {getReadableOrderType(order)}
                  </Badge>
                )}
                {column.id === "address" &&
                  (order.cartType === "delivery" || order.cartType === "wholesale"
                    ? getAddressString({
                        addressDict: order.customerAddressDict,
                        displayFullAddress: true,
                      }) +
                      " " +
                      (order.customerAddressDict ? order.customerAddressDict.zip : "No Zip")
                    : getAddressString({
                        addressDict: order.location.addressDict,
                        displayFullAddress: true,
                      }) +
                      " " +
                      (order.location && order.location.addressDict
                        ? order.location.addressDict.zip
                        : "No Zip"))}
              </PackingCellContent>
            </PackingCellPrimitive>
          )
      )}
      <PackingCellPrimitive pinned={order.pinned} css={{ hideOnPrint: true }}>
        <PackingCellContent type="dropdown">
          {!isScrolling || order.pinned ? (
            <OrderInteraction
              order={order}
              functions={{
                toggleShowCustomerModal: toggleShowCustomerModal,
                setEditOrderData: setEditOrderData,
                setSubscriptionModalData: setSubscriptionModalData,
                toggleShowIrpReceipt: toggleShowIrpReceipt,
                toggleOrderPinned: toggleOrderPinned,
              }}
              view="list"
            />
          ) : (
            <DropdownPlaceholder>
              <DotsVerticalIcon />
            </DropdownPlaceholder>
          )}
        </PackingCellContent>
      </PackingCellPrimitive>
    </>
  );
};

export const PackingTable = ({ orders, columns, functions, isPrinting }) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const packingView = useSelector((state) => state.orderManagement.packingView);
  const completedOrdersDisplay = useSelector(
    (state) => state.orderManagement.completedOrdersDisplay
  );
  const primarySort = useSelector((state) => state.orderManagement.sort.primary);
  const trackMetaData = {
    packingView,
    primarySort,
    completedOrdersDisplay,
  };
  const PackingFillerRowWrapped = useCallback(
    (props) => {
      return <PackingFillerRow {...props} columns={columns} />;
    },
    [columns]
  );
  const FillerRowWrapped = useCallback(
    (props) => {
      return <FillerRow {...props} columns={columns} />;
    },
    [columns]
  );

  return (
    <>
      {/* Virtualized */}
      <TableVirtuoso
        data={orders}
        isScrolling={setIsScrolling}
        overscan={200}
        increaseViewportBy={{ top: 500 }}
        components={{
          TableRow: PackingRowPrimitive,
          Table: PackingTablePrimitive,
          TableBody: PackingTableBody,
          EmptyPlaceholder: PackingFillerRowWrapped,
          FillerRow: FillerRowWrapped,
        }}
        fixedHeaderContent={() => (
          <PackingRowPrimitive type="header" css={{ borderRadius: 8 }}>
            <PackingHeaderCellPrimitive>
              {/* Checkbox Spacer */}
              <PackingCellContent type="checkbox" />
            </PackingHeaderCellPrimitive>
            {columns.map(
              (column) =>
                column.visible && (
                  <PackingHeaderCellPrimitive key={column.id}>
                    <PackingCellContent
                      type={column.id}
                      css={{ color: "$accent11", wordBreak: "keep-all", whiteSpace: "nowrap" }}
                    >
                      {column.displayName}
                    </PackingCellContent>
                  </PackingHeaderCellPrimitive>
                )
            )}
            <PackingHeaderCellPrimitive>
              {/* Dropdown Spacer */}
              <PackingCellContent type="dropdown" />
            </PackingHeaderCellPrimitive>
          </PackingRowPrimitive>
        )}
        itemContent={(idx, order) => (
          <PackingRowContent
            key={order.paymentIntentId}
            order={order}
            columns={columns}
            functions={functions}
            isScrolling={isScrolling}
            trackMetaData={trackMetaData}
          />
        )}
      />
      {/* Printing */}
      {isPrinting && (
        <Table css={{ display: "none", "@media print": { display: "table" } }}>
          <PackingTableHead>
            <PackingRowPrimitive type="header" css={{ borderRadius: 8 }}>
              <PackingHeaderCellPrimitive css={{ width: "1cm" }}>
                {/* Checkbox Spacer */}
              </PackingHeaderCellPrimitive>
              {columns.map(
                (column) =>
                  column.visible && (
                    <PackingHeaderCellPrimitive key={column.id} css={{ verticalAlign: "middle" }}>
                      <PackingCellContent
                        type={column.id}
                        css={{ color: "$accent11", wordBreak: "keep-all", whiteSpace: "nowrap" }}
                      >
                        {column.displayName}
                      </PackingCellContent>
                    </PackingHeaderCellPrimitive>
                  )
              )}
            </PackingRowPrimitive>
          </PackingTableHead>
          <PackingTableBody>
            {orders.map((order, index) => (
              <PackingRowPrimitive key={index}>
                <PackingRowContent order={order} columns={columns} functions={functions} />
              </PackingRowPrimitive>
            ))}
          </PackingTableBody>
        </Table>
      )}
    </>
  );
};
