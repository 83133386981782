// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./InputDropdown.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { TextPrimitive, ContainerPrimitive } from "../_internal_primitives";
import { Root, TextFieldPrimitive } from "../_internal_text_field";
import { default as Options } from "./InputDropdown.Options";

const styles = [
  {
    type: "default",
    layers: {
      input: {
        none: {
          "placeholder-text": "Placeholder",
        },
      },
      "5g7TB5hmXU": {
        none: {
          text: "Label",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "handleOnKeyDown",
    layers: {},
  },
  {
    type: "boolean",
    propName: "required",
    layers: {},
  },
  {
    type: "boolean",
    propName: "optionsVisible",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "handleOnKeyDown",
    layers: {},
  },
  {
    type: "boolean",
    propName: "required",
    layers: {},
  },
  {
    type: "boolean",
    propName: "optionsVisible",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "handleOnKeyDown",
  },
  {
    type: "boolean",
    propName: "required",
  },
  {
    type: "boolean",
    propName: "optionsVisible",
  },
];

export const InputDropdownContext = createContext(null);

function InputDropdown(_props) {
  const defaults = useContext(InputDropdownContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <Root
      {...props}
      key="root"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "root",
        scope: "RF9FUxxjuC",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Container_TaChU51szi"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_RF9FUxxjuC_TaChU51szi"
            }
            key={"TaChU51szi"}
            addSpacing={false}
            element={getStyle("TaChU51szi", "htmlElement")}
          >
            {props.Options === undefined ? (
              <Options key={"Se7Hk69QvN"} />
            ) : (
              props.Options
            )}
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"Container_N2g1KqYu1r"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_RF9FUxxjuC_N2g1KqYu1r"
            }
            key={"N2g1KqYu1r"}
            addSpacing={false}
            element={getStyle("N2g1KqYu1r", "htmlElement")}
          >
            <ContainerPrimitive
              id={"Container_Rxh7LBquzR"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_RF9FUxxjuC_Rxh7LBquzR"
              }
              key={"Rxh7LBquzR"}
              addSpacing={false}
              element={getStyle("Rxh7LBquzR", "htmlElement")}
            >
              {
                <TextPrimitive
                  id={"Label_5g7TB5hmXU"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_RF9FUxxjuC_5g7TB5hmXU"
                  }
                  key={"5g7TB5hmXU"}
                  text={
                    exists(props.label)
                      ? props.label
                      : getStyle("5g7TB5hmXU", "text")
                  }
                  element={getStyle("5g7TB5hmXU", "htmlElement")}
                />
              }
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"Container_5kzWNAcw2H"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_RF9FUxxjuC_5kzWNAcw2H"
              }
              key={"5kzWNAcw2H"}
              addSpacing={false}
              element={getStyle("5kzWNAcw2H", "htmlElement")}
            >
              {
                <TextFieldPrimitive
                  id={"input_input"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_RF9FUxxjuC_input"
                  }
                  key={"input"}
                  placeholder={
                    exists(props.placeholder)
                      ? props.placeholder
                      : getStyle("input", "placeholder-text")
                  }
                />
              }
            </ContainerPrimitive>
          </ContainerPrimitive>
          <ContainerPrimitive
            key={"34YnPCcYuc"}
            id={"Slot_34YnPCcYuc"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_RF9FUxxjuC_34YnPCcYuc"
            }
            addSpacing={false}
          >
            {props.loadingAnimation}
          </ContainerPrimitive>
        </>
      )}
    </Root>
  );
}

InputDropdown.__variants = [
  {
    name: "handleOnKeyDown",
    type: "variant",
  },
  {
    name: "required",
    type: "variant",
  },
  {
    name: "optionsVisible",
    type: "variant",
  },
];

export default InputDropdown;
