// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import { mergeProps, useLabel } from "@visly/core";
import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { usePrimitive } from "./_internal_usePrimitive";
import { combineRef, renderChildren, exists } from "./_internal_utils";
export const LabellingContext = createContext({});
export function useFormLabel() {
  const props = useContext(LabellingContext);
  return Object.keys(props).length > 0
    ? props
    : {
        registerLabelProps: () => {},
        label: null,
        fieldProps: {},
        labelProps: {},
      };
}
export function FormLabelRoot(props) {
  const ref = useRef();
  const { label } = props;
  const { style, testId, vislyProps, values } = usePrimitive({
    ref,
    props,
    noUserSelect: true,
  });
  const { labelProps, fieldProps } = useLabel({
    label,
  });
  const [componentLabelProps, setComponentLabelProps] = useState(null);
  const registerLabelProps = useCallback((props) => {
    setComponentLabelProps(props);
  }, []);
  return (
    <label
      {...mergeProps(
        exists(componentLabelProps) ? componentLabelProps : labelProps,
        vislyProps
      )}
      ref={combineRef(props.measureRef, ref)}
      data-testid={testId}
      style={style}
    >
      <LabellingContext.Provider
        value={{
          label,
          labelProps,
          fieldProps,
          registerLabelProps,
        }}
      >
        {renderChildren(props.children, values)}
      </LabellingContext.Provider>
    </label>
  );
}
