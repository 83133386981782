// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./CustomerMenuItem.Description.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      "8q17K2MwGW": {
        none: {
          text:
            "Stepping off a dirty greyhound bus was a single mother with 3 small children in tow. Searching for a new life and a roof for her family for the night with nothing in her wallet. This was my arrival into San Francisco. As a family, we remained homeless for about hello hello",
          htmlElement: "span",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "expanded",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "expanded",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "expanded",
  },
];

export const DescriptionContext = createContext(null);

function Description(_props) {
  const defaults = useContext(DescriptionContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="VUw2XthxSK"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "VUw2XthxSK",
        scope: "GAvfmQw65x",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Container_Jd41bWb9GX"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_GAvfmQw65x_Jd41bWb9GX"
            }
            key={"Jd41bWb9GX"}
            addSpacing={false}
            element={getStyle("Jd41bWb9GX", "htmlElement")}
          >
            {
              <TextPrimitive
                id={"desc_8q17K2MwGW"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_GAvfmQw65x_8q17K2MwGW"
                }
                key={"8q17K2MwGW"}
                text={
                  exists(props.desc)
                    ? props.desc
                    : getStyle("8q17K2MwGW", "text")
                }
                element={getStyle("8q17K2MwGW", "htmlElement")}
              />
            }
          </ContainerPrimitive>
          <ContainerPrimitive
            key={"3RPTvUfq96"}
            id={"Slot_3RPTvUfq96"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_GAvfmQw65x_3RPTvUfq96"
            }
            addSpacing={false}
          >
            {props.readMoreButton}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

Description.__variants = [
  {
    name: "expanded",
    type: "variant",
  },
];

export default Description;
