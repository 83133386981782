import React from "react";
import { colors } from "../visly";
import PropTypes from "prop-types";
import "./css/Icon.css";

function Icon({ icon, size, color, className }) {
  const style = {
    width: size,
    height: size,
    backgroundColor: color,
    display: "block",
  };
  return (
    <div
      className={className}
      style={{
        ...style,
        WebkitMask: `url(${icon}) 50% 50% / contain no-repeat`,
        mask: `url(${icon}) 50% 50% / contain no-repeat`,
      }}
    ></div>
  );
}

Icon.defaultProps = {
  size: "16px",
  color: colors.gray600,
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

export default Icon;
