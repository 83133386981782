// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./ConfigSection.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as SectionTitle } from "./ConfigSection.SectionTitle";

const styles = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "noTitle",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "noTitle",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "noTitle",
  },
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export const ConfigSectionContext = createContext(null);

function ConfigSection(_props) {
  const defaults = useContext(ConfigSectionContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="EDCabSn2Wj"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "EDCabSn2Wj",
        scope: "H2gpvEyvck",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Container_95wbajAN4h"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_H2gpvEyvck_95wbajAN4h"
            }
            key={"95wbajAN4h"}
            addSpacing={false}
            element={getStyle("95wbajAN4h", "htmlElement")}
          >
            {[
              props.SectionTitle === undefined ? (
                <SectionTitle key={"Er1FrjozJg"} />
              ) : (
                props.SectionTitle
              ),
              <SpacerPrimitive
                id={"Spacer_HNLYz52SAm"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_H2gpvEyvck_HNLYz52SAm"
                }
                key={"HNLYz52SAm"}
              />,
            ]}
          </ContainerPrimitive>
          <ContainerPrimitive
            key={"ViqNa2Bew6"}
            id={"Slot_ViqNa2Bew6"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_H2gpvEyvck_ViqNa2Bew6"
            }
            addSpacing={false}
          >
            {props.children}
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_KBDt28Jcjp"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_H2gpvEyvck_KBDt28Jcjp"
            }
            key={"KBDt28Jcjp"}
          />
        </>
      )}
    </RootPrimitive>
  );
}

ConfigSection.__variants = [
  {
    name: "noTitle",
    type: "variant",
  },
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export default ConfigSection;
