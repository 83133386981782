// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./ReceiptField.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      "78mUNoa1s7": {
        none: {
          text: "Field Label",
        },
      },
      SCXHxUyoD4: {
        none: {
          text: "Not found",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "mono",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
    ],
  },
  {
    type: "enum",
    propName: "type",
    values: [
      {
        propValue: "first",
        layers: {},
      },
      {
        propValue: "last",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "mono",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
    ],
  },
  {
    type: "enum",
    propName: "type",
    values: [
      {
        propValue: "first",
        layers: {},
      },
      {
        propValue: "last",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "mono",
  },
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall"],
  },
  {
    type: "enum",
    propName: "type",
    propValues: ["first", "last"],
  },
];

export const ReceiptFieldContext = createContext(null);

function ReceiptField(_props) {
  const defaults = useContext(ReceiptFieldContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="Whwd36vgga"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "Whwd36vgga",
        scope: "MyVYVu2Exp",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <SpacerPrimitive
            id={"Spacer_VksMCPX3yV"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_MyVYVu2Exp_VksMCPX3yV"
            }
            key={"VksMCPX3yV"}
          />
          <ContainerPrimitive
            id={"text-container_J5tTdeqA57"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_MyVYVu2Exp_J5tTdeqA57"
            }
            key={"J5tTdeqA57"}
            addSpacing={false}
            element={getStyle("J5tTdeqA57", "htmlElement")}
          >
            <TextPrimitive
              id={"label_78mUNoa1s7"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_MyVYVu2Exp_78mUNoa1s7"
              }
              key={"78mUNoa1s7"}
              text={
                exists(props.label)
                  ? props.label
                  : getStyle("78mUNoa1s7", "text")
              }
              element={getStyle("78mUNoa1s7", "htmlElement")}
            />
            <TextPrimitive
              id={"text_SCXHxUyoD4"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_MyVYVu2Exp_SCXHxUyoD4"
              }
              key={"SCXHxUyoD4"}
              text={
                exists(props.text) ? props.text : getStyle("SCXHxUyoD4", "text")
              }
              element={getStyle("SCXHxUyoD4", "htmlElement")}
            />
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_V3acuxjQ7F"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_MyVYVu2Exp_V3acuxjQ7F"
            }
            key={"V3acuxjQ7F"}
          />
        </>
      )}
    </RootPrimitive>
  );
}

ReceiptField.__variants = [
  {
    name: "mono",
    type: "variant",
  },
  {
    name: "size",
    type: "group",
    variants: ["xsmall"],
  },
  {
    name: "type",
    type: "group",
    variants: ["first", "last"],
  },
];

export default ReceiptField;
