// * GENERAL *

// Reminder Signup Completed
export function trackReminderSignupCompleted(source, obj) {
  window.analytics.track("Reminder Signup Completed", {
    chef_id: obj.chef_id,
    customer_id: obj.customer_id,
    source: source,
    ...obj,
  });
}

// * STOREFRONT *

export function trackNumCustomersAtDrop(chefId, numCustomers) {
  window.analytics.track("Num Customers at Drop", {
    chef_id: chefId,
    num_customers: numCustomers,
  });
}

export function trackChefProfileExpanded(chefId) {
  window.analytics.track("Chef Profile Expanded", {
    chef_id: chefId,
  });
}

export function trackSocialMediaLinkClicked(chefId, linkType) {
  window.analytics.track("Social Media Link Clicked", {
    chef_id: chefId,
    type: linkType,
  });
}

export function trackStorefrontLinkClicked(chefId, url) {
  window.analytics.track("Storefront Link Clicked", {
    chef_id: chefId,
    url: url,
  });
}

export function trackFaqExpanded(chefId, question) {
  window.analytics.track("FAQ Expanded", {
    chef_id: chefId,
    question: question,
  });
}

export function trackDropExplainerClicked(chefId) {
  window.analytics.track("Drop Explainer Clicked", {
    chef_id: chefId,
  });
}

export function trackDropCardClicked(chefId, eventId, eventStatus) {
  window.analytics.track("Drop Card Clicked", {
    chef_id: chefId,
    event_id: eventId,
    event_status: eventStatus,
  });
}

// * DROP *

export function trackDropInfoExpanded(chefId, infoType) {
  window.analytics.track("Drop Info Expanded", {
    chef_id: chefId,
    info_type: infoType, // locations | fulfilment_times
  });
}

export function trackChatMessageSent(chefId) {
  window.analytics.track("Chat Message Sent", {
    chef_id: chefId,
  });
}
export function trackChatMessageRejected(chefId, reason) {
  window.analytics.track("Chat Message Rejected", {
    chef_id: chefId,
    reason: reason,
  });
}

// Menu Item Restock Reminder Clicked
export function trackMenuItemRestockReminderSignupClicked(chef_id) {
  window.analytics.track("Menu Item Restock Reminder Signup Clicked", {
    chef_id: chef_id,
  });
}

// Products

// Product Clicked
export function trackProductClicked(item, chef_id) {
  window.analytics.track("Product Clicked", {
    product_id: item.id,
    product_name: item.title,
    price: parseInt(item.price),
    chef_id: chef_id,
  });
}

// Product Viewed
export function trackProductViewed(item, chef_id) {
  window.analytics.track("Product Viewed", {
    product_id: item.id,
    product_name: item.title,
    price: parseInt(item.price),
    chef_id: chef_id,
  });
}

// Product Added
export function trackProductAdded(item, chef_id) {
  window.analytics.track("Product Added", {
    product_id: item.id,
    product_name: item.title,
    price: parseInt(item.price),
    quantity: item.quantity,
    value: parseInt(item.price) * item.quantity,
    chef_id: chef_id,
  });
}

// Product Removed https://segment.com/docs/connections/spec/ecommerce/v2/#product-removed
export function trackProductRemoved(item, chef_id) {
  window.analytics.track("Product Removed", {
    product_id: item.id,
    product_name: item.title,
    price: parseInt(item.price),
    quantity: item.quantity,
    value: parseInt(item.price) * item.quantity,
    chef_id: chef_id,
  });
}

// Checkout Countdown Started
export function trackCheckoutCountdownStarted(millis, cart_id) {
  const seconds = millis / 1000;
  window.analytics.track("Checkout Countdown Started", {
    seconds: seconds,
    cart_id: cart_id,
  });
}

// Checkout Countdown Expired
export function trackCheckoutCountdownExpired(cart_id, event_id) {
  window.analytics.track("Checkout Countdown Expired", {
    cart_id: cart_id,
    event_id: event_id,
  });
}

//
// Cart
//

// Cart Viewed
export function trackCartViewed() {
  window.analytics.track("Cart Viewed");
}

//
// * CHECKOUT *
//

// Checkout Started

export function trackCheckoutStarted(obj) {
  window.analytics.track("Checkout Started", {
    cart_id: obj.cart_id,
    chef_id: obj.chef_id,
    event_id: obj.event_id,
    order_id: obj.order_id,
    ...obj,
  });
}
// Checkout Step Viewed && Checkout Step Completed

function getTrackCheckoutStepNumber(step) {
  const steps = ["phone-login", "time-slots", "user-details", "confirm-payment"];

  return steps.indexOf(step) + 1;
}

export function trackCheckoutStepViewed(step, cartId) {
  window.analytics.track("Checkout Step Viewed", {
    checkout_id: cartId,
    step: getTrackCheckoutStepNumber(step),
  });
}

export function trackCheckoutCompleted(obj) {
  window.analytics.track("Checkout Completed", {
    cart_id: obj.cart_id,
    chef_id: obj.chef_id,
    event_id: obj.event_id,
    order_id: obj.order_id,
    customer_id: obj.customerId,
    ...obj,
  });
}

// * Confirmation * //
export function trackAddToCalendarClicked(obj) {
  window.analytics.track("Add to Calendar Clicked", {
    event_id: obj.event_id,
    chef_id: obj.chef_id,
    order_id: obj.order_id,
    ...obj,
  });
}

export function trackLoveSent({ chefId, message }) {
  window.analytics.track("Love Sent", {
    chef_id: chefId,
    message: message,
  });
}

// Event viewed (used by Insights)
export function trackEventViewed(eventId, chefId) {
  window.analytics.track("Event Viewed", {
    event_id: eventId,
    chef_id: chefId,
  });
}

// * HOMEPAGE *

export function trackGetStartedClicked(location) {
  window.analytics.track("Get Started Clicked", {
    location: location,
  });
}

export function trackSignupCompleted(obj) {
  window.analytics.track("Signup Completed", obj);
}

export function trackDemoTimesViewed(obj) {
  window.analytics.track("Demo Times Viewed", obj);
}

export function trackDemoTimeSelected(obj) {
  window.analytics.track("Demo Time Selected", obj);
}

export function trackDemoScheduled(obj) {
  window.analytics.track("Demo Scheduled", obj);
}

export function trackNavClicked(obj) {
  window.analytics.track("Homepage Nav Clicked", obj);
}

// reminder clicked w/ source:
// header
// chefprofile
// dropcard
// dropInfo_scheduled
// dropInfo_complete
