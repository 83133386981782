// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./LocationCard.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive } from "../_internal_primitives";
import { default as EditButton } from "./LocationCard.EditButton";
import { default as SelectLocationButton } from "./LocationCard.SelectLocationButton";

const styles = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "selected",
  },
];

export const LocationCardContext = createContext(null);

function LocationCard(_props) {
  const defaults = useContext(LocationCardContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="Q8xspeWVXU"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "Q8xspeWVXU",
        scope: "2nhZa5sGjy",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => [
        props.SelectLocationButton === undefined ? (
          <SelectLocationButton key={"TRV5m6qPEw"} />
        ) : (
          props.SelectLocationButton
        ),
        props.EditButton === undefined ? (
          <EditButton key={"6adSFLFyeJ"} />
        ) : (
          props.EditButton
        ),
      ]}
    </RootPrimitive>
  );
}

LocationCard.__variants = [
  {
    name: "selected",
    type: "variant",
  },
];

export default LocationCard;
