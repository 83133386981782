// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import component_0 from "./Avatar";
import component_1 from "./Badge";
import component_2 from "./BubbleTabs";
import component_3 from "./BubbleTabs.Button";
import component_4 from "./Bullet";
import component_5 from "./Button";
import component_6 from "./Checkbox";
import component_7 from "./CustomTabs";
import component_8 from "./CustomTabs.Component";
import component_9 from "./DropdownMenu";
import component_10 from "./DropdownMenu.Item";
import component_11 from "./EmptyPagePlaceholder";
import component_12 from "./Error";
import component_13 from "./FormLabel";
import component_14 from "./IconButton";
import component_15 from "./Input";
import component_16 from "./Input2";
import component_17 from "./InputDropdown";
import component_18 from "./InputDropdown.Options";
import component_19 from "./InputDropdown.Options.Option";
import component_20 from "./InputPhone";
import component_21 from "./InputSelect";
import component_22 from "./InputSelect.Options";
import component_23 from "./InputSelect.Options.Option";
import component_24 from "./List";
import component_25 from "./List.Item";
import component_26 from "./Menu";
import component_27 from "./Menu.Item";
import component_28 from "./Menu.Submenu";
import component_29 from "./Menu.Section";
import component_30 from "./Menu.Selectable";
import component_31 from "./ProgressBar";
import component_32 from "./Radio";
import component_33 from "./Radio.Button";
import component_34 from "./SearchBar";
import component_35 from "./Segmented";
import component_36 from "./Segmented.Button";
import component_37 from "./Select";
import component_38 from "./Select.Option";
import component_39 from "./Slider";
import component_40 from "./Switch";
import component_41 from "./Tabs";
import component_42 from "./Tabs.Button";
import component_43 from "./TextArea";
import component_44 from "./TextArea.CharCount";
import component_45 from "./Toast";
import component_46 from "./Toast.CloseButton";
import component_47 from "./ToggleButton";
import component_48 from "./ToolTipContainer";
import component_49 from "./Tooltip";
import component_50 from "./WarningBubble";
export const Avatar = component_0;
export const Badge = component_1;
export const BubbleTabs = component_2;
export const Bullet = component_4;
export const Button = component_5;
export const Checkbox = component_6;
export const CustomTabs = component_7;
export const DropdownMenu = component_9;
export const EmptyPagePlaceholder = component_11;
export const Error = component_12;
export const FormLabel = component_13;
export const IconButton = component_14;
export const Input = component_15;
export const Input2 = component_16;
export const InputDropdown = component_17;
export const InputPhone = component_20;
export const InputSelect = component_21;
export const List = component_24;
export const Menu = component_26;
export const ProgressBar = component_31;
export const Radio = component_32;
export const SearchBar = component_34;
export const Segmented = component_35;
export const Select = component_37;
export const Slider = component_39;
export const Switch = component_40;
export const Tabs = component_41;
export const TextArea = component_43;
export const Toast = component_45;
export const ToggleButton = component_47;
export const ToolTipContainer = component_48;
export const Tooltip = component_49;
export const WarningBubble = component_50;
BubbleTabs.Button = component_3;
CustomTabs.Component = component_8;
DropdownMenu.Item = component_10;
InputDropdown.Options = component_18;
InputDropdown.Options.Option = component_19;
InputSelect.Options = component_22;
InputSelect.Options.Option = component_23;
List.Item = component_25;
Menu.Item = component_27;
Menu.Submenu = component_28;
Menu.Section = component_29;
Menu.Selectable = component_30;
Radio.Button = component_33;
Segmented.Button = component_36;
Select.Option = component_38;
Tabs.Button = component_42;
TextArea.CharCount = component_44;
Toast.CloseButton = component_46;
