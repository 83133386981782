import { styled } from "../../../stitches.config";
export const AccountSettingsPage = styled("div", {
  display: "flex",
  position: "relative",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  height: "100%",
  // minWidth:0,
  backgroundColor: "$accent1",
  padding: "$md",
  "@desktop_sm": {
    padding: "$lg",
  },
});

export const AccountSettingsContent = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "$maxContentWidthSm",
  gap: "$xl",
  paddingBottom: "calc($xxl + $sizes$navHeight)",
});

export const ButtonContainer = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  maxWidth: "$maxContentWidthSm",
  gap: "$lg",
});

export const TooltipSwitch = styled("div", {
  display: "flex",
  flex: 1,
  justifyContent: "space-between",
  alignItems: "center",
  gap: "$lg",
});

export const TeamMembers = styled("div", {
  display: "grid",
  gridAutoFlow: "row",
  width: "100%",
  maxWidth: "$maxContentWidthXs",
  gap: "$md",
  marginBottom: "$lg",
});

export const Permissions = styled("div", {
  display: "flex",
  flexFlow: "row wrap",
  gap: "$xs",
});

export const InputColorPreview = styled("div", {
  display: "flex",
  alignItems: "center",
  width: "100%",
  gap: "$md",
});

export const ColorPreview = styled("div", {
  size: "$sizes$lg_btn",
  borderRadius: "$md",
  border: "1px solid $gray6",
  boxShadow: "$elevation2",
  backgroundColor: "transparent",
  transition: "background-color 0.2s ease-in-out",
});

const brandingPreviewCommon = {
  display: "flex",
  gap: "$xl",
};

export const BrandingPreview = styled("div", {
  ...brandingPreviewCommon,
  flexDirection: "column",
});

export const PreviewRow = styled("div", {
  ...brandingPreviewCommon,
  flexFlow: "row wrap",
  width: "100%",
});
