import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import "./css/index.css";

const boards = [
  ["Request Features", "189b117a-463a-c269-8300-4409fe0f72f5"],
  // ["Report Bugs", "a8f54a2f-0de7-a67e-8575-5f9e122bff1c"], // HOT-1217
];

const Feedback = () => {
  const [loadingBoard, setLoadingBoard] = useState(false);
  const [board, setBoard] = useState(boards[0][0]);
  const userInfo = useSelector((state) => state.login.userInfo);

  useEffect(() => {
    const boardMap = {};
    for (let i = 0; i < boards.length; i++) {
      boardMap[boards[i][0]] = boards[i][1];
    }
    const renderBoard = (board) => {
      const sso = userInfo.cannySSO;
      setLoadingBoard(true);
      window.Canny("render", {
        boardToken: boardMap[board],
        basePath: "/portal/feedback", // See step 2
        ssoToken: sso, // See step 3
        onLoadCallback: () => setLoadingBoard(false),
      });
    };

    renderBoard(board);
  }, [board, userInfo.cannySSO]);

  return (
    <div className="feedback-container">
      <div className="feedback-nav__background">
        <div className="feedback-nav">
          <div className="feedback-nav__header">
            <h1>Feedback Forum</h1>
            <p>Request new features from Hotplate and interact with feedback from other Chefs</p>
          </div>
          <div className="feedback-nav__buttons">
            {boards.map((boardArr) => (
              <button
                data-selected={board === boardArr[0]}
                key={boardArr[0]}
                onClick={() => setBoard(boardArr[0])}
              >
                {boardArr[0]}
              </button>
            ))}
          </div>
        </div>
      </div>
      {loadingBoard && (
        <div className="feedback-loader">
          <ReactLoading width={50} height={50} color="#e85b5b" type="spin" />
        </div>
      )}
      {boards.map((boardArr) => {
        if (boardArr[0] == board) {
          return <div data-canny key={boardArr[0]} />;
        }
      })}
    </div>
  );
};

export default Feedback;
