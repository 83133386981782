import React from "react";
import { Center, Flex } from "../../../hotplate-common/primitives/Containers";

export const NothingHere = () => {
  return (
    <Center css={{ width: "100%", height: "100%" }}>
      <Flex
        css={{
          textAlign: "center",
          ff: "$avenir",
          fs: "$md",
          lh: "$text",
          fw: "$semi_bold",
          color: "$gray11",
        }}
      >
        Nothing to see here... yet!
      </Flex>
    </Center>
  );
};
