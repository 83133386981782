import _ from "lodash";

import {
  LOAD_USER_INFO_START,
  LOAD_USER_INFO_SUCCESS,
  LOAD_USER_INFO_FAILURE,
  CONNECT_TO_CUSTOMER_GLOBALS_START,
  CONNECT_TO_CUSTOMER_GLOBALS_SUCCESS,
  CONNECT_TO_CUSTOMER_GLOBALS_FAILURE,
  CONNECT_TO_CUSTOMER_GLOBALS_FIRST_NAME_SUCCESS,
  CONNECT_TO_CUSTOMER_GLOBALS_LAST_NAME_SUCCESS,
  CONNECT_TO_CUSTOMER_GLOBALS_NAME_SUCCESS,
  CONNECT_TO_CUSTOMER_GLOBALS_EMAIL_SUCCESS,
  CONNECT_TO_CUSTOMER_GLOBALS_CUSTOMER_ADDRESS_DICT_SUCCESS,
  CONNECT_TO_CUSTOMER_GLOBALS_PAYMENT_METHOD_SUCCESS,
  DISCONNECT_FROM_CUSTOMER_GLOBALS_START,
  DISCONNECT_FROM_CUSTOMER_GLOBALS_SUCCESS,
  DISCONNECT_FROM_CUSTOMER_GLOBALS_FAILURE,
} from "../actions/types";

const INITIAL_STATE = {
  userInfo: {
    paymentMethods: [],
  },
  userInfoLoading: false,
  userInfoError: "",

  connectToCustomerGlobalsLoading: false,
  connectToCustomerGlobalsError: "",

  disconnectFromCustomerGlobalsLoading: false,
  disconnectFromCustomerGlobalsError: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DISCONNECT_FROM_CUSTOMER_GLOBALS_START:
      return {
        ...state,
        disconnectFromCustomerGlobalsLoading: true,
        disconnectFromCustomerGlobalsError: "",
      };
    case DISCONNECT_FROM_CUSTOMER_GLOBALS_SUCCESS:
      return { ...state, disconnectFromCustomerGlobalsLoading: false };
    case DISCONNECT_FROM_CUSTOMER_GLOBALS_FAILURE:
      return {
        ...state,
        disconnectFromCustomerGlobalsLoading: false,
        disconnectFromCustomerGlobalsError: action.payload,
      };
    case CONNECT_TO_CUSTOMER_GLOBALS_START:
      return {
        ...state,
        connectToCustomerGlobalsLoading: true,
        connectToCustomerGlobalsError: "",
      };
    case CONNECT_TO_CUSTOMER_GLOBALS_FIRST_NAME_SUCCESS: {
      const newUserInfoF = _.cloneDeep(state.userInfo);
      newUserInfoF.firstName = action.payload ? action.payload : "";
      return { ...state, userInfo: newUserInfoF };
    }
    case CONNECT_TO_CUSTOMER_GLOBALS_LAST_NAME_SUCCESS: {
      const newUserInfoL = _.cloneDeep(state.userInfo);
      newUserInfoL.lastName = action.payload ? action.payload : "";
      return { ...state, userInfo: newUserInfoL };
    }
    case CONNECT_TO_CUSTOMER_GLOBALS_NAME_SUCCESS: {
      const newUserInfoN = _.cloneDeep(state.userInfo);
      newUserInfoN.name = action.payload ? action.payload : "";
      return { ...state, userInfo: newUserInfoN };
    }
    case CONNECT_TO_CUSTOMER_GLOBALS_EMAIL_SUCCESS: {
      const newUserInfoE = _.cloneDeep(state.userInfo);
      newUserInfoE.email = action.payload ? action.payload : "";
      return { ...state, userInfo: newUserInfoE };
    }
    case CONNECT_TO_CUSTOMER_GLOBALS_CUSTOMER_ADDRESS_DICT_SUCCESS: {
      const newUserInfoC = _.cloneDeep(state.userInfo);
      newUserInfoC.customerAddressDict = action.payload ? action.payload : null;
      return { ...state, userInfo: newUserInfoC };
    }
    case CONNECT_TO_CUSTOMER_GLOBALS_PAYMENT_METHOD_SUCCESS: {
      const newUserInfoP = _.cloneDeep(state.userInfo);
      newUserInfoP.paymentMethod = action.payload ? action.payload : null;
      return { ...state, userInfo: newUserInfoP };
    }
    case CONNECT_TO_CUSTOMER_GLOBALS_SUCCESS:
      return { ...state, connectToCustomerGlobalsLoading: false };
    case CONNECT_TO_CUSTOMER_GLOBALS_FAILURE:
      return {
        ...state,
        connectToCustomerGlobalsLoading: false,
        connectToCustomerGlobalsError: action.payload,
      };
    case LOAD_USER_INFO_START:
      return { ...state, userInfoLoading: true };
    case LOAD_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfoLoading: false,
        userInfoError: "",
        userInfo: action.payload,
      };
    case LOAD_USER_INFO_FAILURE:
      return {
        ...state,
        userInfoLoading: false,
        userInfoError: action.payload,
      };
    default:
      return state;
  }
};
