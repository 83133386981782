/* eslint-disable react/prop-types */
import React, { useState } from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import {
  getTimestampDayOfWeekMonthDate,
  getTimestampDayOfWeek,
} from "@hotplate/utils-ts/helperFunctions";
import { TimeInput } from "./TimeInput";
import { DatePicker } from "../../visly/Events";
import { Button, Select } from "../../visly/Primitives";
import { useBreakpoint } from "../../visly";
import "./css/DayPicker.css";
import { colors } from "../../stitches.config";

function DayPickerContainer({
  startTimestamp,
  recurring,
  setDate,
  dateLabel,
  setTimeWindowDayOfWeek,
  setStartTime,
  startTimeLabel,
  endTimestamp,
  setEndTime,
  endTimeLabel,
  invalid,
}) {
  const [expanded, toggleExpanded] = useState(false);
  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);
  const range = Number.isInteger(endTimestamp) || typeof setEndTime === "function";
  const inputValidationStyle = invalid
    ? {
        outline: `2px solid ${colors.tomato9}`,
        backgroundColor: colors.tomato3,
      }
    : {};

  return (
    <DatePicker
      size={size}
      range={range}
      DateButton={
        <DatePicker.DateButton
          label={dateLabel ? dateLabel : "Date"}
          Input={
            !recurring ? (
              <Button
                style={{
                  width: "142px",
                  padding: "12px",
                  height: "46px",
                  borderRadius: "4px",
                  backgroundColor: expanded ? colors.gray4 : colors.gray3,
                  ...inputValidationStyle,
                }}
                text={
                  Number.isInteger(startTimestamp)
                    ? getTimestampDayOfWeekMonthDate(startTimestamp, true)
                    : "Select Date"
                } // should show current date by default
                onClick={() => toggleExpanded(!expanded)}
              />
            ) : (
              <Select
                style={{ minWidth: "150px", height: "46px", ...inputValidationStyle }}
                label={
                  getTimestampDayOfWeek(startTimestamp) !== ""
                    ? getTimestampDayOfWeek(startTimestamp)
                    : "Select Day"
                }
                selectedKey={getTimestampDayOfWeek(startTimestamp)}
                onSelectionChange={(val) => setTimeWindowDayOfWeek(val)}
              >
                {Array.from(
                  { length: 7 },
                  (_, i) => new Date().getTime() + i * 1000 * 60 * 60 * 24
                ).map((timestamp) => (
                  <Select.Option
                    key={getTimestampDayOfWeek(timestamp)}
                    label={getTimestampDayOfWeek(timestamp)}
                  />
                ))}
              </Select>
            )
          }
        />
      }
      StartTime={
        <DatePicker.StartTime
          label={startTimeLabel ? startTimeLabel : "Time"}
          Input={
            <TimeInput
              style={{ width: "90px", ...inputValidationStyle }}
              timestamp={startTimestamp}
              validationType={range && startTimestamp > endTimestamp && "invalid"}
              onBlur={(val) => setStartTime(val)}
            />
          }
        />
      }
      EndTime={
        range && (
          <DatePicker.EndTime
            label={endTimeLabel ? endTimeLabel : "End Time"}
            Input={
              <TimeInput
                style={{ width: "90px", ...inputValidationStyle }}
                validationType={range && startTimestamp > endTimestamp && "invalid"}
                timestamp={endTimestamp}
                onBlur={(val) => setEndTime(val)}
              />
            }
          />
        )
      }
      DateDropdown={
        <DatePicker.DateDropdown expanded={expanded}>
          <DayPicker
            modifiers={{
              disabled: { before: new Date() },
            }}
            fromMonth={new Date()}
            selectedDays={Number.isInteger(startTimestamp) ? new Date(startTimestamp) : null}
            onDayClick={(date) => {
              setDate(date.getTime());
              toggleExpanded(!expanded);
            }}
          />
        </DatePicker.DateDropdown>
      }
    />
    //       {event.recurring && }
  );
}

export default DayPickerContainer;
