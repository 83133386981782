// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Segmented.Button.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { TextPrimitive, IconPrimitive } from "../_internal_primitives";
import { RadioGroupButtonRoot } from "../_internal_radio_group";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      "segmented-control-button-text": {
        none: {
          text: "Option",
        },
      },
      XEt3pa1DtU: {
        none: {
          icon: icons.vislyCheck,
          useMask: true,
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {
      XEt3pa1DtU: {
        none: {
          useMask: true,
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "Icon",
    layers: {
      XEt3pa1DtU: {
        none: {
          icon: icons.checkmark,
          useMask: true,
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large850",
        layers: {},
      },
      {
        propValue: "med450",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
  {
    type: "boolean",
    propName: "Icon",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large850",
        layers: {},
      },
      {
        propValue: "med450",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "selected",
  },
  {
    type: "boolean",
    propName: "Icon",
  },
  {
    type: "enum",
    propName: "size",
    propValues: ["large850", "med450", "small"],
  },
];

export const ButtonContext = createContext(null);

function Button(_props) {
  const defaults = useContext(ButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RadioGroupButtonRoot
      {...props}
      key="segmented-control-button-root"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "segmented-control-button-root",
        scope: "BgFNHfxTXa",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <TextPrimitive
            id={"text_segmented-control-button-text"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_BgFNHfxTXa_segmented-control-button-text"
            }
            key={"segmented-control-button-text"}
            text={
              exists(props.text)
                ? props.text
                : getStyle("segmented-control-button-text", "text")
            }
            element={getStyle("segmented-control-button-text", "htmlElement")}
          />
          <IconPrimitive
            id={"Icon_XEt3pa1DtU"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_BgFNHfxTXa_XEt3pa1DtU"
            }
            key={"XEt3pa1DtU"}
            useMask={getStyle("XEt3pa1DtU", "useMask")}
            src={
              exists(props.icon) ? props.icon : getStyle("XEt3pa1DtU", "icon")
            }
          />
        </>
      )}
    </RadioGroupButtonRoot>
  );
}

Button.__variants = [
  {
    name: "selected",
    type: "variant",
  },
  {
    name: "Icon",
    type: "variant",
  },
  {
    name: "size",
    type: "group",
    variants: ["large850", "med450", "small"],
  },
];

export default Button;
