// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./PickupTime.SplitOrder.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as LineItem } from "../Confirmation/ReceiptLineItem";

const styles = [
  {
    type: "default",
    layers: {
      "9VNAErCAno": {
        none: {
          text: "00999",
        },
      },
      BUfAdhcMm6: {
        none: {
          text: "Wed, 7/11",
        },
      },
      UiBzJiSmnG: {
        none: {
          text: "#",
        },
      },
      A4Z8iRH19k: {
        none: {
          text: "at",
        },
      },
      GoFq4Rs7fH: {
        none: {
          text: "12:00 AM",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "onlyDate",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      "7NujFTKNgA": {},
    },
  },
  {
    type: "boolean",
    propName: "onlyDate",
    layers: {
      "7NujFTKNgA": {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "onlyDate",
  },
];

export const SplitOrderContext = createContext(null);

function SplitOrder(_props) {
  const defaults = useContext(SplitOrderContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="9TheDdA1qa"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "9TheDdA1qa",
        scope: "CciBXt4Zhy",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Container_TeZsbibYcC"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_CciBXt4Zhy_TeZsbibYcC"
            }
            key={"TeZsbibYcC"}
            addSpacing={false}
            element={getStyle("TeZsbibYcC", "htmlElement")}
          >
            <TextPrimitive
              id={"#_UiBzJiSmnG"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_CciBXt4Zhy_UiBzJiSmnG"
              }
              key={"UiBzJiSmnG"}
              text={getStyle("UiBzJiSmnG", "text")}
              element={getStyle("UiBzJiSmnG", "htmlElement")}
            />
            <TextPrimitive
              id={"orderNum_9VNAErCAno"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_CciBXt4Zhy_9VNAErCAno"
              }
              key={"9VNAErCAno"}
              text={
                exists(props.orderNum)
                  ? props.orderNum
                  : getStyle("9VNAErCAno", "text")
              }
              element={getStyle("9VNAErCAno", "htmlElement")}
            />
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"pickupTime_QAtMW1tW1R"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_CciBXt4Zhy_QAtMW1tW1R"
            }
            key={"QAtMW1tW1R"}
            addSpacing={false}
            element={getStyle("QAtMW1tW1R", "htmlElement")}
          >
            <TextPrimitive
              id={"date_BUfAdhcMm6"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_CciBXt4Zhy_BUfAdhcMm6"
              }
              key={"BUfAdhcMm6"}
              text={
                exists(props.date) ? props.date : getStyle("BUfAdhcMm6", "text")
              }
              element={getStyle("BUfAdhcMm6", "htmlElement")}
            />
            <ContainerPrimitive
              id={"Container_TSXiT3osdA"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_CciBXt4Zhy_TSXiT3osdA"
              }
              key={"TSXiT3osdA"}
              addSpacing={false}
              element={getStyle("TSXiT3osdA", "htmlElement")}
            >
              <TextPrimitive
                id={"at_A4Z8iRH19k"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_CciBXt4Zhy_A4Z8iRH19k"
                }
                key={"A4Z8iRH19k"}
                text={getStyle("A4Z8iRH19k", "text")}
                element={getStyle("A4Z8iRH19k", "htmlElement")}
              />
              <TextPrimitive
                id={"time_GoFq4Rs7fH"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_CciBXt4Zhy_GoFq4Rs7fH"
                }
                key={"GoFq4Rs7fH"}
                text={
                  exists(props.time)
                    ? props.time
                    : getStyle("GoFq4Rs7fH", "text")
                }
                element={getStyle("GoFq4Rs7fH", "htmlElement")}
              />
            </ContainerPrimitive>
          </ContainerPrimitive>
          <ContainerPrimitive
            key={"KEu2MSBi84"}
            id={"Slot_KEu2MSBi84"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_CciBXt4Zhy_KEu2MSBi84"
            }
            addSpacing={false}
          >
            {props.children}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

SplitOrder.LineItem = LineItem;
SplitOrder.__variants = [
  {
    name: "onlyDate",
    type: "variant",
  },
];

export default SplitOrder;
