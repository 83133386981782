// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./CustomerMenuItem.Description.ReadMoreButton.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      MM6ecFkt1a: {
        none: {
          htmlElement: "section",
        },
      },
      NQCp7Muzts: {
        none: {
          text: "Read more",
          htmlElement: "span",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const ReadMoreButtonContext = createContext(null);

function ReadMoreButton(_props) {
  const defaults = useContext(ReadMoreButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="N3mhGx4vce"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "N3mhGx4vce",
        scope: "XUJzax4NJA",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_MM6ecFkt1a"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_XUJzax4NJA_MM6ecFkt1a"
          }
          key={"MM6ecFkt1a"}
          addSpacing={false}
          element={getStyle("MM6ecFkt1a", "htmlElement")}
        >
          {
            <TextPrimitive
              id={"Text_NQCp7Muzts"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_XUJzax4NJA_NQCp7Muzts"
              }
              key={"NQCp7Muzts"}
              text={
                exists(props.text) ? props.text : getStyle("NQCp7Muzts", "text")
              }
              element={getStyle("NQCp7Muzts", "htmlElement")}
            />
          }
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

ReadMoreButton.__variants = [];

export default ReadMoreButton;
