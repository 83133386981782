import React from "react";
import { Flex } from "../../../hotplate-common/primitives/Containers";
import { styled } from "../../../stitches.config";

const NavButtonPrimitive = styled("button", {
  all: "unset",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 48,
  cursor: "pointer",
  "&:hover > div": {
    color: "$hotplate11",
  },
  variants: {
    selected: {
      true: {
        "&::after": {
          content: "",
          position: "absolute",
          bottom: -1,
          width: "100%",
          height: 3,
          backgroundColor: "$hotplate9",
        },
        "& > div": {
          color: "$hotplate9",
        },
      },
    },
  },
});

const NavButtonContent = styled("div", {
  all: "unset",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 24,
  color: "$hotplate12",
  ff: "$avenir",
  fw: "$bold",
  fs: 14,
  padx: 10,
  textAlign: "center",
  transition: "color 150ms ease-in-out",
});

export const ManagementNav = ({ selectedTab, setTab }) => {
  return (
    <Flex css={{ marginRight: 8 }}>
      <NavButtonPrimitive
        onClick={() => setTab("PREP")}
        css={{ marginRight: 8 }}
        selected={selectedTab === "PREP"}
      >
        <NavButtonContent>Prep</NavButtonContent>
      </NavButtonPrimitive>
      <NavButtonPrimitive onClick={() => setTab("PACKING")} selected={selectedTab === "PACKING"}>
        <NavButtonContent>Packing</NavButtonContent>
      </NavButtonPrimitive>
    </Flex>
  );
};
