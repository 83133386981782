// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./LabeledInput.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      "3DKy8biEez": {
        none: {
          text: "Label",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const LabeledInputContext = createContext(null);

function LabeledInput(_props) {
  const defaults = useContext(LabeledInputContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="FZNtieVDv9"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "FZNtieVDv9",
        scope: "6kPk1REbRp",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Container_KJgFojU7bk"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_6kPk1REbRp_KJgFojU7bk"
            }
            key={"KJgFojU7bk"}
            addSpacing={false}
            element={getStyle("KJgFojU7bk", "htmlElement")}
          >
            <TextPrimitive
              id={"Text_3DKy8biEez"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_6kPk1REbRp_3DKy8biEez"
              }
              key={"3DKy8biEez"}
              text={
                exists(props.label)
                  ? props.label
                  : getStyle("3DKy8biEez", "text")
              }
              element={getStyle("3DKy8biEez", "htmlElement")}
            />
            <SpacerPrimitive
              id={"Spacer_QNZQRG8h6A"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_6kPk1REbRp_QNZQRG8h6A"
              }
              key={"QNZQRG8h6A"}
            />
            <ContainerPrimitive
              key={"Rh2BwkqNV7"}
              id={"Slot_Rh2BwkqNV7"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_6kPk1REbRp_Rh2BwkqNV7"
              }
              addSpacing={false}
            >
              {props.tooltip}
            </ContainerPrimitive>
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_WPGCrYMbPv"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_6kPk1REbRp_WPGCrYMbPv"
            }
            key={"WPGCrYMbPv"}
          />
          <ContainerPrimitive
            key={"Gd4fKGvXTU"}
            id={"Slot_Gd4fKGvXTU"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_6kPk1REbRp_Gd4fKGvXTU"
            }
            addSpacing={false}
          >
            {props.Input}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

LabeledInput.__variants = [];

export default LabeledInput;
