// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import component_0 from "./AvailablePickupTimes";
import component_1 from "./AvailablePickupTimes.TimesContainer";
import component_2 from "./AvailablePickupTimes.TimesContainer.PickupTime";
import component_3 from "./BannerCartButton";
import component_4 from "./CountdownTimer";
import component_5 from "./CustomerMenuItem";
import component_6 from "./CustomerMenuItem.NextAvailable";
import component_7 from "./CustomerMenuItem.ChangeQuantity";
import component_8 from "./CustomerMenuItem.ChangeQuantity.QuantityButton";
import component_9 from "./CustomerMenuItem.AvailableTimesContainer";
import component_10 from "./CustomerMenuItem.Description";
import component_11 from "./CustomerMenuItem.Description.ReadMoreButton";
import component_12 from "./TipSelect";
import component_13 from "./TipSelect.Button";
export const AvailablePickupTimes = component_0;
export const BannerCartButton = component_3;
export const CountdownTimer = component_4;
export const CustomerMenuItem = component_5;
export const TipSelect = component_12;
AvailablePickupTimes.TimesContainer = component_1;
AvailablePickupTimes.TimesContainer.PickupTime = component_2;
CustomerMenuItem.NextAvailable = component_6;
CustomerMenuItem.ChangeQuantity = component_7;
CustomerMenuItem.ChangeQuantity.QuantityButton = component_8;
CustomerMenuItem.AvailableTimesContainer = component_9;
CustomerMenuItem.Description = component_10;
CustomerMenuItem.Description.ReadMoreButton = component_11;
TipSelect.Button = component_13;
