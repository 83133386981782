import {
  SET_ORDER_MANAGEMENT_ACTION,
  SET_PACKING_FILTER,
  SET_ACTIVE_FILTERS,
  SET_PRIMARY_ORDERS_SORT,
  SET_COMPLETED_ORDERS_DISPLAY,
  UPLOAD_CSV_START,
  UPLOAD_CSV_SUCCESS,
  UPLOAD_CSV_FAILURE,
  NOTIFY_CUSTOMER_START,
  NOTIFY_CUSTOMER_FAILURE,
  NOTIFY_CUSTOMER_SUCCESS,
  EDIT_ORDER_START,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_FAILURE,
  SET_PACKING_VIEW,
  INITIATE_CUSTOMER_REFUND_START,
  INITIATE_CUSTOMER_REFUND_SUCCESS,
  INITIATE_CUSTOMER_REFUND_FAILURE,
  backendUrl,
} from "./types";
import fetchRetry from "./FetchRetry";

export const setPackingView = (packingView) => {
  return (dispatch) => {
    dispatch({
      type: SET_PACKING_VIEW,
      payload: packingView,
    });
  };
};

export const initiateCustomerRefund = (hostId, order, restockItems, shouldKillFutureReminders) => {
  return (dispatch) => {
    dispatch({ type: INITIATE_CUSTOMER_REFUND_START });
    return fetchRetry(backendUrl + "initiateCustomerRefund", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        hostId,
        order,
        restockItems,
        shouldKillFutureReminders,
      }),
    })
      .then((response) => {
        if (response.status >= 400 && response.status < 600)
          dispatch({
            type: INITIATE_CUSTOMER_REFUND_FAILURE,
            payload: "SERVER_ERROR",
          });
        else dispatch({ type: INITIATE_CUSTOMER_REFUND_SUCCESS });
      })
      .catch((exception) => {
        dispatch({
          type: INITIATE_CUSTOMER_REFUND_FAILURE,
          payload: exception,
        });
      });
  };
};

export const editOrder = ({ hostId, order, notifyCustomer }) => {
  return (dispatch) => {
    dispatch({ type: EDIT_ORDER_START });
    return fetchRetry(backendUrl + "editOrder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        hostId,
        order,
        notifyCustomer,
      }),
    })
      .then((response) => {
        if (response.status >= 400 && response.status < 600)
          dispatch({ type: EDIT_ORDER_FAILURE, payload: "SERVER_ERROR" });
        else dispatch({ type: EDIT_ORDER_SUCCESS });
      })
      .catch((exception) => {
        dispatch({ type: EDIT_ORDER_FAILURE, payload: exception });
      });
  };
};

export const uploadCsv = (hostId, data) => {
  return (dispatch) => {
    dispatch({ type: UPLOAD_CSV_START });
    return fetchRetry(backendUrl + "uploadCsv", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        hostId,
        data,
      }),
    })
      .then((resp) =>
        resp.json().then((data) => {
          if ((resp.status >= 400 && resp.status < 600) || data.error)
            dispatch({ type: UPLOAD_CSV_FAILURE, payload: data.error });
          else dispatch({ type: UPLOAD_CSV_SUCCESS });
        })
      )
      .catch((exception) => {
        console.log("EXCEPTION: ", exception);
        dispatch({ type: UPLOAD_CSV_FAILURE, payload: exception });
      });
  };
};

export const notifyCustomer = (customerPhone, message, chefId, order) => {
  return (dispatch) => {
    dispatch({ type: NOTIFY_CUSTOMER_START });
    return fetchRetry(backendUrl + "notifyCustomer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customerPhone,
        message,
        chefId,
        order,
      }),
    })
      .then((response) => {
        if (response.status >= 400 && response.status < 600)
          dispatch({ type: NOTIFY_CUSTOMER_FAILURE, payload: "SERVER_ERROR" });
        else dispatch({ type: NOTIFY_CUSTOMER_SUCCESS });
      })
      .catch((exception) => {
        dispatch({ type: NOTIFY_CUSTOMER_FAILURE, payload: exception });
      });
  };
};

export const setPackingFilter = (packingFilter) => {
  return (dispatch) => {
    dispatch({
      type: SET_PACKING_FILTER,
      payload: packingFilter,
    });
  };
};

export const setActiveFilters = (activeFiltersArr) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_FILTERS,
      payload: activeFiltersArr,
    });
  };
};

export const setPrimaryOrdersSort = (ordersSortObj) => {
  return (dispatch) => {
    dispatch({
      type: SET_PRIMARY_ORDERS_SORT,
      payload: ordersSortObj,
    });
  };
};

export const setCompletedOrdersDisplay = (displayEnum) => {
  return (dispatch) => {
    dispatch({
      type: SET_COMPLETED_ORDERS_DISPLAY,
      payload: displayEnum,
    });
  };
};

export const setOrderManagementAction = (action) => {
  return (dispatch) => {
    dispatch({
      type: SET_ORDER_MANAGEMENT_ACTION,
      payload: action,
    });
  };
};
