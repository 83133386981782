// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./ReceiptLineItem.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      NPfbVWCXUk: {
        none: {
          text: "2",
        },
      },
      "7kuddeoWzF": {
        none: {
          text: "???? ????",
        },
      },
      E7Xn4z1HtP: {
        none: {
          text: "????",
        },
      },
      Sk2PqfYyGV: {
        none: {
          text: " ",
        },
      },
      HFMN25si9o: {
        none: {
          text: "x",
        },
      },
      LLDkmntqSC: {
        none: {
          text: "",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "fee",
    layers: {},
  },
  {
    type: "boolean",
    propName: "bold",
    layers: {
      "7kuddeoWzF": {
        none: {
          text: "BOLD",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "withTooltip",
    layers: {},
  },
  {
    type: "enum",
    propName: "color",
    values: [
      {
        propValue: "good",
        layers: {},
      },
      {
        propValue: "warning",
        layers: {},
      },
      {
        propValue: "secondary",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "fee",
    layers: {},
  },
  {
    type: "boolean",
    propName: "bold",
    layers: {},
  },
  {
    type: "boolean",
    propName: "withTooltip",
    layers: {},
  },
  {
    type: "enum",
    propName: "color",
    values: [
      {
        propValue: "good",
        layers: {},
      },
      {
        propValue: "warning",
        layers: {},
      },
      {
        propValue: "secondary",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "fee",
  },
  {
    type: "boolean",
    propName: "bold",
  },
  {
    type: "boolean",
    propName: "withTooltip",
  },
  {
    type: "enum",
    propName: "color",
    propValues: ["good", "warning", "secondary"],
  },
];

export const ReceiptLineItemContext = createContext(null);

function ReceiptLineItem(_props) {
  const defaults = useContext(ReceiptLineItemContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="MiByoQ6Z9a"
      addSpacing={true}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "MiByoQ6Z9a",
        scope: "VQ2WPWsNpv",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"quantity-name-container_NftG6smzH8"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_VQ2WPWsNpv_NftG6smzH8"
            }
            key={"NftG6smzH8"}
            addSpacing={false}
            element={getStyle("NftG6smzH8", "htmlElement")}
          >
            <ContainerPrimitive
              id={"quantity-container_Q7j96QyALu"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_VQ2WPWsNpv_Q7j96QyALu"
              }
              key={"Q7j96QyALu"}
              addSpacing={false}
              element={getStyle("Q7j96QyALu", "htmlElement")}
            >
              <TextPrimitive
                id={"quantity_NPfbVWCXUk"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_VQ2WPWsNpv_NPfbVWCXUk"
                }
                key={"NPfbVWCXUk"}
                text={
                  exists(props.quantity)
                    ? props.quantity
                    : getStyle("NPfbVWCXUk", "text")
                }
                element={getStyle("NPfbVWCXUk", "htmlElement")}
              />
              <TextPrimitive
                id={"x_HFMN25si9o"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_VQ2WPWsNpv_HFMN25si9o"
                }
                key={"HFMN25si9o"}
                text={getStyle("HFMN25si9o", "text")}
                element={getStyle("HFMN25si9o", "htmlElement")}
              />
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"name-options-container_HveJnwtwZs"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_VQ2WPWsNpv_HveJnwtwZs"
              }
              key={"HveJnwtwZs"}
              addSpacing={false}
              element={getStyle("HveJnwtwZs", "htmlElement")}
            >
              <ContainerPrimitive
                id={"Container_97BPukWFve"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_VQ2WPWsNpv_97BPukWFve"
                }
                key={"97BPukWFve"}
                addSpacing={false}
                element={getStyle("97BPukWFve", "htmlElement")}
              >
                <TextPrimitive
                  id={"item_7kuddeoWzF"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_VQ2WPWsNpv_7kuddeoWzF"
                  }
                  key={"7kuddeoWzF"}
                  text={
                    exists(props.item)
                      ? props.item
                      : getStyle("7kuddeoWzF", "text")
                  }
                  element={getStyle("7kuddeoWzF", "htmlElement")}
                />
                <SpacerPrimitive
                  id={"Spacer_2C1nbB2Qj2"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_VQ2WPWsNpv_2C1nbB2Qj2"
                  }
                  key={"2C1nbB2Qj2"}
                />
                <TextPrimitive
                  id={"refund_LLDkmntqSC"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_VQ2WPWsNpv_LLDkmntqSC"
                  }
                  key={"LLDkmntqSC"}
                  text={
                    exists(props.refund)
                      ? props.refund
                      : getStyle("LLDkmntqSC", "text")
                  }
                  element={getStyle("LLDkmntqSC", "htmlElement")}
                />
              </ContainerPrimitive>
              <TextPrimitive
                id={"options_Sk2PqfYyGV"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_VQ2WPWsNpv_Sk2PqfYyGV"
                }
                key={"Sk2PqfYyGV"}
                text={
                  exists(props.options)
                    ? props.options
                    : getStyle("Sk2PqfYyGV", "text")
                }
                element={getStyle("Sk2PqfYyGV", "htmlElement")}
              />
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"tooltip-container_H6doDYjkCQ"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_VQ2WPWsNpv_H6doDYjkCQ"
              }
              key={"H6doDYjkCQ"}
              addSpacing={false}
              element={getStyle("H6doDYjkCQ", "htmlElement")}
            >
              {
                <ContainerPrimitive
                  key={"B5oDqPCg65"}
                  id={"Slot_B5oDqPCg65"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_VQ2WPWsNpv_B5oDqPCg65"
                  }
                  addSpacing={false}
                >
                  {props.tooltip}
                </ContainerPrimitive>
              }
            </ContainerPrimitive>
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"price-container_TKdregjEPm"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_VQ2WPWsNpv_TKdregjEPm"
            }
            key={"TKdregjEPm"}
            addSpacing={false}
            element={getStyle("TKdregjEPm", "htmlElement")}
          >
            {
              <TextPrimitive
                id={"total_E7Xn4z1HtP"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_VQ2WPWsNpv_E7Xn4z1HtP"
                }
                key={"E7Xn4z1HtP"}
                text={
                  exists(props.total)
                    ? props.total
                    : getStyle("E7Xn4z1HtP", "text")
                }
                element={getStyle("E7Xn4z1HtP", "htmlElement")}
              />
            }
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

ReceiptLineItem.__variants = [
  {
    name: "fee",
    type: "variant",
  },
  {
    name: "bold",
    type: "variant",
  },
  {
    name: "withTooltip",
    type: "variant",
  },
  {
    name: "color",
    type: "group",
    variants: ["good", "warning", "secondary"],
  },
];

export default ReceiptLineItem;
