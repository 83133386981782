// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./AccountPage.MenuButton.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      T1KvJTUfuo: {
        none: {
          text: "Account Section",
        },
      },
      BnpG7RNQfv: {
        none: {
          icon: icons.chevronRight,
          useMask: true,
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const MenuButtonContext = createContext(null);

function MenuButton(_props) {
  const defaults = useContext(MenuButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="YLK7T12Y1P"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "YLK7T12Y1P",
        scope: "949auYUhuD",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <TextPrimitive
            id={"pageName_T1KvJTUfuo"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_949auYUhuD_T1KvJTUfuo"
            }
            key={"T1KvJTUfuo"}
            text={
              exists(props.pageName)
                ? props.pageName
                : getStyle("T1KvJTUfuo", "text")
            }
            element={getStyle("T1KvJTUfuo", "htmlElement")}
          />
          <IconPrimitive
            id={"Icon_BnpG7RNQfv"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_949auYUhuD_BnpG7RNQfv"
            }
            key={"BnpG7RNQfv"}
            useMask={getStyle("BnpG7RNQfv", "useMask")}
            src={getStyle("BnpG7RNQfv", "icon")}
          />
        </>
      )}
    </RootPrimitive>
  );
}

MenuButton.__variants = [];

export default MenuButton;
