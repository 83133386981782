import {
  capitalizeFirstLetter,
  getCountdownTimeArray,
  getDetailedEventStatus,
  getEventStatus,
  getSortedEventTimeSlotOptions,
  getSortedLocationsEvent,
  getTimeUntilGoLive,
  numberWithCommas,
} from "@hotplate/utils-ts/helperFunctions";
import { ArrowRightIcon, BellIcon, CalendarIcon, SewingPinFilledIcon } from "@radix-ui/react-icons";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AspectRatioImg } from "../../hotplate-common/primitives/AspectRatioImg";
import { Button } from "../../hotplate-common/primitives/Button";
import { Column, Flex, H2, P, Row } from "../../hotplate-common/primitives/Containers";
import { styled } from "../../stitches.config";
import type { CSS } from "@stitches/react";
import { Countdown } from "../components/EventCardCountdown";
import { getTimeTitle } from "./DropInfo";
import { ReminderDialog } from "./ReminderDialog";
import {
  DetailedEventStatus,
  EventStatus,
  EventType,
  FulfillmentType,
  Location,
  TimeWindow,
} from "./types";
import { getChefStyles } from "../../hotplate-common/chefStyleFunctions";
import { useSelector } from "react-redux";
import { ChefStyles, ShopReduxState } from "./types";
import { trackDropCardClicked } from "../analytics";

const DropCardHype = ({
  event,
  eventStatus,
  detailedEventStatus,
}: {
  event: EventType;
  eventStatus: EventStatus;
  detailedEventStatus: DetailedEventStatus;
}) => {
  const { siteSettings } = useSelector((state: ShopReduxState) => state.storefront);
  const dropName = siteSettings?.customDropName || "drop";
  const timerId = useRef<NodeJS.Timer | null>(null);
  const [timeUntilGoLive, setTimeUntilGoLive] = useState(getTimeUntilGoLive(event.goLiveTime));

  useEffect(() => {
    if (detailedEventStatus === "scheduled" || detailedEventStatus === "opening") {
      timerId.current = setInterval(() => {
        setTimeUntilGoLive(getTimeUntilGoLive(event.goLiveTime));
      }, 1000);
      return () => {
        if (timerId.current) {
          clearInterval(timerId.current);
          timerId.current = null;
        }
      };
    }
  }, [event.goLiveTime, detailedEventStatus]);
  const countdownTimeArray = getCountdownTimeArray(timeUntilGoLive);

  const eventIsLive = eventStatus === "live";
  const eventIsOpening = detailedEventStatus === "opening";
  const eventIsScheduledButNotOpening = detailedEventStatus === "scheduled";
  const eventIsScheduled = eventStatus === "scheduled";

  const containerCSS: CSS = eventIsScheduledButNotOpening
    ? {
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "$md",
        jc: "center",
        ai: "center",
        pad: "$xs",
        backgroundColor: "$gray2",
        br: "$lg",
        m: "$xs",
      }
    : eventIsOpening
    ? {
        flexDirection: "column",
        gap: "$md",
        pad: "$xs",
        backgroundColor: "$gray2",
        br: "$lg",
        m: "$xs",
      }
    : {
        flexDirection: "column",
        gap: "$md",
      };

  // function getHypeStat() {
  //   if (eventIsOpening || eventIsLive) {
  //     return `🔥 ${numberWithCommas(0 + 1023)} here`; // ! use actual presence
  //   }

  //   // if opening, some copy like "X people waiting for orders to open"
  //   // if live,
  //   // if sold out "SOLD OUT"
  //   // Math.max("X buying right now","X orders placed")
  // }
  return (
    <Flex css={containerCSS}>
      {eventIsScheduled && (
        <Countdown
          goLiveTime={event.goLiveTime}
          hideCountdown={event.hideOrdersOpenTime}
          type={eventIsOpening ? "large" : "normal"}
          days={countdownTimeArray[0]}
          hours={countdownTimeArray[1]}
          minutes={countdownTimeArray[2]}
          seconds={countdownTimeArray[3]}
          labeled={eventIsOpening} // label only if the event is opening
          chefStyles={{}}
          css={eventIsOpening ? { flexGrow: 1, jc: "center" } : {}}
        />
      )}

      {eventIsScheduledButNotOpening && (
        //   the wrapper around ReminderDialog is needed so that clicking on the trigger will not trigger navigation
        <Row css={{ flexGrow: 1 }} onClick={(e) => e.stopPropagation()}>
          <ReminderDialog
            title={`You can't miss this.`}
            description="Give us your phone number and we will send you a text a few minutes before sales start."
            trackSource="drop_card"
            triggerCSS={{ flexGrow: 1 }}
            trigger={
              <Button as="div" variant="tinted" color="danger" css={{ width: "$full" }}>
                <BellIcon />
                {`Remind me`}
              </Button>
            }
          />
        </Row>
      )}
      {(eventIsOpening || eventIsLive) && (
        <Button
          as="div"
          variant="tinted"
          color="danger"
          css={eventIsLive ? { mx: "$xs", mb: "$xs" } : {}}
        >
          {`Go to ${capitalizeFirstLetter(dropName)}`}
          <ArrowRightIcon />
        </Button>
      )}
      {/* // ? removed for time, need to be able to get presence counter values when not directly connected */}
      {/* {(eventIsOpening || eventIsLive) && (
        <Row
          css={{
            ai: "center",
            jc: "center",
            backgroundColor: "$danger4",
            color: "$danger11",
            ff: "$inter",
            fw: "$semi_bold",
            w: "$full",
            h: "$md_btn",
          }}
        >
          {getHypeStat()}
        </Row>
      )} */}
    </Flex>
  );
};

const IconBox = styled("div", {
  display: "flex",
  ai: "center",
  jc: "center",
  flexShrink: 0,
  size: "$xs_btn",
  br: "$sm",
  backgroundColor: "$accent4",
  color: "$accent11",
});

const DropCardItem = ({ text, icon, css }: { text: string; icon: JSX.Element; css?: CSS }) => {
  const { siteSettings } = useSelector((state: ShopReduxState) => state.storefront);
  const chefStyles: ChefStyles = getChefStyles(siteSettings);

  return (
    <Row
      css={{
        display: "flex",
        ai: "flex-start",
        pady: "$xs",
        gap: "$sm",
        textStyle: "text-2",
        fw: "$semi_bold",
        lh: 1.375,
        ...css,
      }}
    >
      <IconBox
        css={{
          backgroundColor: chefStyles.primaryColorLight?.toString(),
          color: chefStyles.primaryColorDark?.toString(),
        }}
      >
        {icon}
      </IconBox>
      <Row css={{ ai: "center", minH: "$xs_btn" }}>
        <P>{text}</P>
      </Row>
    </Row>
  );
};

const Description = styled("p", {
  textStyle: "text-2",
  lineClamp: 2,
  opacity: 0.8,
  fontStyle: "italic",
  padx: "$sm",
  mb: "$sm",
  "@tablet": {
    padx: 0,
    mb: 0,
    mt: "$xs",
    whiteSpace: "pre-line",
    lineClamp: 3,
  },
});

export const DropCard = ({ event }: { event: EventType }) => {
  const { title, image, description, pickupEnabled, goLiveTime, deliveryEnabled } = event;
  const detailedEventStatus: DetailedEventStatus =
    getDetailedEventStatus(event) === "draft" ? "complete" : getDetailedEventStatus(event); // force partial events from getPastChefEventsCloud to be draft
  const eventStatus: EventStatus =
    getEventStatus(event) === "draft" ? "complete" : getEventStatus(event); // force partial events from getPastChefEventsCloud to be draft
  const eventIsComplete = eventStatus === "complete";
  const eventIsOpening = detailedEventStatus === "opening";

  const { siteSettings } = useSelector((state: ShopReduxState) => state.storefront);
  const chefId = siteSettings?.chefId;

  const fulfillmentType: FulfillmentType | "both" =
    pickupEnabled && deliveryEnabled ? "both" : deliveryEnabled ? "delivery" : "pickup";

  const sortedTimeWindows: TimeWindow[] = getSortedEventTimeSlotOptions({
    event: { ...event, recurringCount: 1 },
    includePickup: fulfillmentType !== "delivery",
    includeDelivery: fulfillmentType !== "pickup",
    customerZip: "all",
    menuItems: null,
    locationId: null,
    customerCartId: null,
  });

  const sortedLocations: Location[] = getSortedLocationsEvent({
    event,
    includePickup: true,
    includeDelivery: true,
  });

  function getLocationTitle(sortedLocations: Location[]): string {
    if (sortedLocations.length === 0) return "No locations";
    if (sortedLocations.length > 1)
      return `${sortedLocations[0].title} + ${sortedLocations.length - 1} more`;
    return sortedLocations[0].title;
  }

  function OneLineTimeTitle(
    event: EventType,
    fulfillmentType: FulfillmentType | "both",
    sortedTimeWindows: TimeWindow[]
  ): string {
    const fragmentTitle = getTimeTitle(event, fulfillmentType, sortedTimeWindows);
    const extraLocations = sortedLocations.length > 3 ? ` +${sortedLocations.length - 3} more` : "";
    return `${fragmentTitle}${extraLocations}`;
  }

  const isEventSoldOut = () => {
    if (!event || !event.menuItems) return false;
    const { menuItems } = event;
    let numMenuItemsSoldOut = 0;

    Object.keys(menuItems).forEach((key) => {
      const menuItem = menuItems[key];
      // ! DUPLICATE CODE FROM getMenuItemStatus() in Drop.tsx
      if (typeof menuItem.inventory === "number" && menuItem.inventory <= 0) {
        if (
          (!menuItem.inventorySold && menuItem.inventoryReserved) || // prevents "Sold Out" from showing if first reservations reserves all the inventory, since inventorySold is not defined until a purchase is made
          (typeof menuItem.inventorySold === "number" &&
            menuItem.inventorySold < menuItem.inventoryReserved)
        ) {
          return;
        }
        numMenuItemsSoldOut++;
      }
    });
    return numMenuItemsSoldOut === Object.keys(menuItems).length;
  };

  return (
    <Link
      aria-label={`Click to navigate to ${title}`}
      to={event.id}
      style={{ textDecoration: "unset" }}
      onClick={() => {
        trackDropCardClicked(chefId, event.id, eventStatus);
      }}
    >
      <Column
        css={{
          $$shadow: eventIsOpening
            ? "0px 6px 16px 2px hsla(0, 75%, 63%, 0.3)"
            : eventIsComplete
            ? "0px 0px 0px 0px hsla(0, 0%, 0%, 0)"
            : "$shadows$elevation4",
          backgroundColor: eventIsComplete ? "$gray2" : "$white",
          br: "$xl",
          color: "$gray12",
          position: "relative",
          overflow: "hidden",
          border: eventIsOpening ? "1px solid $hotplate5" : "1px solid $gray5",
          w: "$full",
          transition:
            "box-shadow 150ms cubic-bezier(0.4, 0.3, 0.8, 0.6), border 150ms cubic-bezier(0.4, 0.3, 0.8, 0.6)",
          boxShadow: "$$shadow",
          "&:hover": {
            boxShadow:
              "$$shadow,inset 0 0 0 0 rgba(255,255,255,0), inset 0 0 0 4px $colors$white, inset 0 0 0 9999px $colors$gray2",
          },
          "&:active": {
            boxShadow:
              "$$shadow, inset 0 0 0 0 rgba(255,255,255,0), inset 0 0 0 4px $colors$white, inset 0 0 0 9999px $colors$gray2",
          },
          "&:focus-visible": {
            outline: "2px solid $accent9",
            outlineOffset: "4px",
          },
        }}
      >
        {isEventSoldOut() && (
          <Column
            css={{
              position: "absolute",
              zIndex: "$fixed",
              ai: "center",
              jc: "center",
              ff: "$inter",
              textStyle: "text-5",
              color: "$white",
              inset: 0,
              backgroundColor: "$overlay10",
            }}
          >
            Sold Out
          </Column>
        )}
        <Row css={{ ai: "flex-start", pad: "$sm" }}>
          {image && (
            <AspectRatioImg
              id="drop-card-image"
              ratio={1}
              src={image}
              alt={"Photo for " + title}
              css={{
                br: "$lg",
                boxShadow: eventIsComplete
                  ? "none"
                  : "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
                transform: "perspective(1000px)",
                w: "40%",
                flexShrink: 0,
                height: "fit-content",
              }}
            />
          )}

          <Column css={{ padl: "$sm", minW: 0 }}>
            <H2
              css={{
                textStyle: "text-5",
                fw: "$bold",
                padb: "$xxs",
                wordBreak: "break-word",
                lineClamp: 2,
              }}
            >
              {title}
            </H2>
            {eventIsComplete ? (
              // format goLiveTime to to display as "Dec 31st, 2021" using Intl.DateTimeFormat
              <>
                <Description>
                  {`Dropped on ${new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }).format(new Date(goLiveTime))}`}
                </Description>
              </>
            ) : (
              <>
                <DropCardItem
                  icon={<CalendarIcon />}
                  text={OneLineTimeTitle(event, fulfillmentType, sortedTimeWindows)}
                />

                <DropCardItem
                  icon={<SewingPinFilledIcon />}
                  text={getLocationTitle(sortedLocations)}
                />
                {description && (
                  <Description
                    css={{
                      display: "none",
                      "@tablet": {
                        display: "-webkit-box",
                      },
                    }}
                  >
                    {description}
                  </Description>
                )}
              </>
            )}
          </Column>
        </Row>
        {description && !eventIsComplete && (
          <Description
            css={{
              "@tablet": {
                display: "none",
              },
            }}
          >
            {description}
          </Description>
        )}
        <DropCardHype
          event={event}
          eventStatus={eventStatus}
          detailedEventStatus={detailedEventStatus}
        />
      </Column>
    </Link>
  );
};

// alternate hover css styles for dropcard
// * Focus  Effect
// css={{
//   backgroundColor: "$white",
//   br: "$lg",
//   color: "$gray12",
//   position: "relative",
//   overflow: "hidden",
//   cursor: "pointer",
//   border: "1px solid $gray5",
//   w: "$full",
//   pady: "$lg",
//   zIndex: 1,
//   transition: "box-shadow 0.15s ease-out, border 0.15s ease-out",
//   "&:hover": {
//     zIndex: 999,
//     boxShadow: "0px 0px 999px 999px rgba(255, 255, 255, 0.5)",
//     border: "1px solid $accent9",
//   },
// }}
// * Colored Corner Effect
// css={{
//   backgroundColor: "$info2",
//   br: "$lg",
//   color: "$gray12",
//   position: "relative",
//   overflow: "hidden",
//   cursor: "pointer",
//   w: "$full",
//   pady: "$lg",
//   zIndex: 0,
//   transition: "color 0.25s ease-out",
//   "&::before": {
//     content: "",
//     position: "absolute",
//     zIndex: -1,
//     top: -64,
//     right: -64,
//     background: "#00838d",
//     height: 128,
//     width: 128,
//     br: "$pill",
//     transform: "scale(1)",
//     transformOrigin: "50% 50%",
//     transition: "transform 0.25s ease-out",
//   },
//   "&:hover::before": {
//     transform: "scale(10)",
//   },
//   "&:hover": {
//     color: "$white !important",
//   },
// }}
