/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { useBreakpoint, icons } from "../visly";

import { EventCard, Field } from "../visly/Events";
import ReactLoading from "react-loading";
import { Countdown } from "../hotplate-storefront/components/EventCardCountdown";

import { FieldIcon } from "./FieldIcon";
import { shouldRetry, trpc } from "../trpc";
import {
  CalendarIcon,
  StopwatchIcon,
  SewingPinFilledIcon,
  DotsVerticalIcon,
  Cross2Icon,
} from "@radix-ui/react-icons";

import {
  getEventStatus,
  getAddressString,
  getTimestampHoursMinutesAMPM,
  getTimestampDayOfWeekMonthDate,
  getTimestampDayOfWeek,
  getSortedLocationsEvent,
  getTimestampMonthDate,
  getSortedEventTimeSlotOptions,
  isEventOnDemandActive,
  getCountdownTimeArray,
  getTimeUntilGoLive,
  // isCollabEvent,
} from "@hotplate/utils-ts/helperFunctions";

import AnimationContainer from "./AnimationContainer";
import "./css/EventCard.css";
import classNames from "classnames";
import { Button } from "./primitives/Button";
import { DropdownMenu } from "./primitives/DropdownMenu";
import { setToastPopupInfo as setToastPopupInfoUnconnected } from "../hotplate-storefront/actions";
import { useDispatch } from "react-redux";
import { useConnectedFn } from "./HelperFunctions";
import { trackEventDeleted } from "../hotplate-portal/events/eventOverview/analytics";

function getEventCardTimeWindowTitle(event, cartType, compact = false) {
  let keyWord = "";
  if (!compact && cartType) {
    keyWord = cartType === "pickup" ? "Pickup " : "Delivered ";
  } else {
    keyWord = "";
  }

  if (event.ticketed) keyWord = "";
  if (isEventOnDemandActive(event)) return keyWord + "ASAP";
  const sortedTimeWindows = getSortedEventTimeSlotOptions({
    event: { ...event, recurringCount: 1 },
    includePickup: cartType !== "delivery",
    includeDelivery: cartType !== "pickup",
    customerZip: "all",
  });
  if (sortedTimeWindows.length === 0) return null;

  if (sortedTimeWindows.length === 1) {
    if (event.recurring)
      return (
        keyWord +
        "every " +
        getTimestampDayOfWeek(sortedTimeWindows[0].timeSlots[0].startTime, false)
      );
    return (
      keyWord + getTimestampDayOfWeekMonthDate(sortedTimeWindows[0].timeSlots[0].startTime, true)
    );
  }
  return (
    (event.recurring ? keyWord + "every " : keyWord) +
    sortedTimeWindows
      .map((timeWindow) => {
        if (event.recurring) return getTimestampDayOfWeek(timeWindow.timeSlots[0].startTime, true);
        return getTimestampMonthDate(timeWindow.timeSlots[0].startTime, true);
      })
      .join(", ")
  );
}

function EventCardFunctional({
  event,
  type,
  selected,
  onClick,
  moreDetailsOnClick,
  dark,
  cartType,
  chefStyles,
  reminderButton,
}) {
  const dispatch = useDispatch();
  const setToastPopupInfo = useConnectedFn(setToastPopupInfoUnconnected, dispatch);
  const archiveEvent = trpc.portal.archiveEvent.useMutation({ retry: shouldRetry(1) });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);
  const eventStatus = getEventStatus(event);
  const sortedTimeWindows = getSortedEventTimeSlotOptions({
    event: { ...event, recurringCount: 1 },
    includePickup: cartType !== "delivery",
    includeDelivery: cartType !== "pickup",
    customerZip: "all",
  });

  const sortedLocations = getSortedLocationsEvent({
    event,
    includePickup: cartType !== "delivery",
    includeDelivery: cartType !== "pickup",
  });

  const eventCardLocationSubtitle =
    sortedLocations.length === 0
      ? ""
      : sortedLocations.length === 1
      ? getAddressString(sortedLocations[0])
      : " +" + (sortedLocations.length - 1) + " more locations";

  const timerId = useRef(null);
  const [timeUntilGoLive, setTimeUntilGoLive] = useState(getTimeUntilGoLive(event.goLiveTime));

  useEffect(() => {
    if (eventStatus === "scheduled" && type === "storefront") {
      timerId.current = setInterval(() => {
        setTimeUntilGoLive(getTimeUntilGoLive(event.goLiveTime));
      }, 1000);
      return () => {
        clearInterval(timerId.current);
        timerId.current = null;
      };
    }
  }, [event.goLiveTime, eventStatus, type]);

  const countdownTimeArray = getCountdownTimeArray(timeUntilGoLive);
  const goLivePadding = { paddingBottom: "16px" };
  goLivePadding.paddingBottom = size === "large" || size === "xlarge" ? "16px" : "12px";

  function getEventTags(event) {
    const tags = [];
    if (event.sales) {
      let sales = event.sales;
      if (sales.length > 6) {
        sales = [sales.slice(0, sales.length - 6), ",", sales.slice(-6)].join("");
      }
      tags.push(<EventCard.TagContainer.Tag key="complete" text={"$" + sales} type="complete" />);
    }
    if (event.ticketed) {
      tags.push(
        <EventCard.TagContainer.Tag
          key="ticketed"
          text="Tickets"
          withIcon="left"
          icon={icons.receipt}
          type="recurring"
        />
      );
    }
    if (event.pickupEnabled && !event.ticketed) {
      tags.push(<EventCard.TagContainer.Tag type="pickup" key="pickup" />);
    }
    if (event.onDemandEnabled) {
      tags.push(<EventCard.TagContainer.Tag type="asap" key="asap" />);
    }
    if (event.deliveryEnabled) {
      tags.push(<EventCard.TagContainer.Tag type="delivery" key="delivery" />);
    }
    if (event.recurring && (type === "overview" || type === "duplicate")) {
      tags.push(<EventCard.TagContainer.Tag type="recurring" key="recurring" />);
    }
    if (event.private && (type === "overview" || type === "duplicate")) {
      tags.push(<EventCard.TagContainer.Tag type="private" key="private" />);
    }
    // if (isCollabEvent(event)) {
    //   tags.push(<EventCard.TagContainer.Tag
    //     withIcon
    //     style={{ background: '#833ab4', background: 'linear-gradient(to right, #fcb045, #fd1d1d, #833ab4)', color: 'white'}}
    //     icon={icons.collab}
    //     type='pickup'
    //     text="Collab"
    //   />);
    // }
    return tags;
  }

  const eventCardClassName = classNames({
    eventCard: true,
    noPhoto: !event.image,
  });
  const days = countdownTimeArray[0];
  const hours = countdownTimeArray[1];
  const minutes = countdownTimeArray[2];
  const seconds = countdownTimeArray[3];

  return (
    <EventCard
      dark={dark}
      className={eventCardClassName}
      withImage={
        event.image &&
        (type === "storefront" || type === "eventList" || type === "overview" || type === "compact")
      } // TODO: change compact to carrousel
      image={
        <div className="bannerImgContainer">
          {/* <video className="bannerImg" width="100%" autoPlay muted loop>
          <source src={event.image} type="video/mp4"/>
        </video> */}
          <img className="bannerImg" src={event.image} />
        </div>
      }
      size={size}
      type={type} // storefront, eventList, overview, duplicate, compact
      selected={selected}
      onClick={onClick}
      title={event.title}
      countdown={
        eventStatus === "scheduled" && type === "storefront" && !event.hideOrdersOpenTime ? (
          <Countdown
            closed={timeUntilGoLive}
            days={days}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
            chefStyles={chefStyles}
          />
        ) : eventStatus === "complete" && type === "storefront" ? (
          <Button css={{ pointerEvents: "none" }} color="success" variant="tinted">
            Event Complete
          </Button> // this is so troll but it works... change later
        ) : null
      }
      timeWindow={
        sortedTimeWindows.length === 1 ? (
          <Field
            className="date-field"
            dark={dark}
            FieldIcon={
              <FieldIcon chefStyles={type === "storefront" && chefStyles} icon={<CalendarIcon />} />
            }
            containsSubtitle={true}
            title={getEventCardTimeWindowTitle(event, cartType)}
            subtitle={new Intl.DateTimeFormat("en-US", {
              hour12: true,
              hour: "numeric",
              minute: "numeric",
              timeZone: undefined, // timeSlot.timeZone,
              timeZoneName: "short",
            }).formatRange(
              new Date(sortedTimeWindows[0].timeSlots[0].startTime),
              new Date(
                sortedTimeWindows[0].timeSlots[sortedTimeWindows[0].timeSlots.length - 1].endTime
              )
            )}
          />
        ) : (
          <Field
            className="date-field"
            dark={dark}
            FieldIcon={<FieldIcon chefStyles={chefStyles} icon={<CalendarIcon />} />}
            containsSubtitle={false}
            title={getEventCardTimeWindowTitle(event, cartType)}
          />
        )
      }
      location={
        <Field
          dark={dark}
          style={{ flexShrink: 1 }}
          FieldIcon={<FieldIcon chefStyles={chefStyles} icon={<SewingPinFilledIcon />} />}
          containsSubtitle={true}
          title={sortedLocations.length > 0 ? sortedLocations[0].title : "No locations"}
          subtitle={eventCardLocationSubtitle}
        />
      }
      compactDate={getEventCardTimeWindowTitle(event, cartType, true)}
      compactLocation={
        (sortedLocations.length === 0 && "No location") ||
        (sortedLocations.length > 1
          ? sortedLocations.length + " locations"
          : sortedLocations[0].title)
      }
      status={
        <>
          {(type === "overview" || type === "duplicate") && (
            <EventCard.EventStatus
              type={eventStatus}
              animation={<AnimationContainer animation="blob" />}
            />
          )}
          {type === "overview" && eventStatus === "draft" && (
            <div
              style={{
                // translate down 8px
                transform: "translateY(5px)",
                marginLeft: "8px",
              }}
            >
              <DropdownMenu
                onClick={(e) => {
                  e.stopPropagation();
                }}
                open={isDropdownOpen}
                trigger={
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsDropdownOpen(!isDropdownOpen);
                    }}
                    className="test"
                    color="accent"
                    variant="tinted"
                    size="small"
                    css={{ padx: "$xs" }}
                  >
                    {isDropdownOpen ? <Cross2Icon /> : <DotsVerticalIcon />}
                  </Button>
                }
              >
                <DropdownMenu.Item
                  onClick={async () => {
                    try {
                      trackEventDeleted();
                      await archiveEvent.mutateAsync({ eventId: event.id });
                      setToastPopupInfo({
                        text: "Event successfully deleted.",
                        type: "success",
                      });
                      setIsDropdownOpen(false);
                    } catch (e) {
                      setToastPopupInfo({
                        text: "Deletion failed. Please try again later.",
                        type: "error",
                      });
                      setIsDropdownOpen(false);
                      throw e;
                    }
                  }}
                  hint={
                    archiveEvent.isLoading ? (
                      <ReactLoading type="spin" height={20} width={20} color="#333333" />
                    ) : null
                  }
                  text="Delete Event"
                />
              </DropdownMenu>
            </div>
          )}
        </>
      }
      goLiveTime={
        eventStatus === "scheduled" && (
          <Field
            className={timeUntilGoLive !== 0 ? "collapsable-field" : "collapsable-field collapsed"}
            dark={dark}
            style={goLivePadding}
            FieldIcon={<FieldIcon chefStyles={chefStyles} icon={<StopwatchIcon />} />}
            title={"Pre-orders open " + getTimestampDayOfWeekMonthDate(event.goLiveTime, true)}
            subtitle={!event.hideOrdersOpenTime && getTimestampHoursMinutesAMPM(event.goLiveTime)}
            containsSubtitle={true}
          />
        )
      }
      tagContainer={
        <EventCard.TagContainer className="footer-tag-container">
          {getEventTags(event).map((tag) => tag)}
        </EventCard.TagContainer>
      }
      moreDetails={
        type === "storefront" && eventStatus === "scheduled" ? (
          reminderButton
        ) : (
          <EventCard.MoreDetails
            dark={dark}
            className="accountStyleMoreDetails"
            onClick={moreDetailsOnClick}
          />
        )
      }
    />
  );
}

export default EventCardFunctional;
