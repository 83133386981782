import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";

export const ConfirmContext = React.createContext();

export const ConfirmLink = React.forwardRef((props, ref) => {
  const confirm = useContext(ConfirmContext);
  return (
    <Link
      onClick={(e) => {
        if (confirm && !window.confirm("Leave? You have unsaved changes that will not be saved.")) {
          e.preventDefault();
        }
      }}
      ref={ref}
      {...props}
    />
  );
});
ConfirmLink.displayName = "ConfirmLink";

export const ConfirmNavLink = React.forwardRef((props, ref) => {
  const confirm = useContext(ConfirmContext);
  return (
    <NavLink
      onClick={(e) => {
        if (confirm && !window.confirm("Leave? You have unsaved changes that will not be saved.")) {
          e.preventDefault();
        }
      }}
      ref={ref}
      {...props}
    />
  );
});
ConfirmNavLink.displayName = "ConfirmNavLink";
