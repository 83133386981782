import React from "react";
import { Column, H1, P } from "../../hotplate-common/primitives/Containers";

export const InsightMetric = ({ value, label }: { value: number | string; label: string }) => {
  return (
    <Column css={{ pad: "$md", br: "$md", backgroundColor: "$gray3" }}>
      <H1
        css={{
          ff: "$arboria",
          fs: 24,
          fw: "$bold",
          lineHeight: "$text",
          color: "$gray12",
        }}
      >
        {value}
      </H1>
      <P
        css={{
          ff: "$avenir",
          fs: 14,
          fw: "$normal",
          lineHeight: "$text",
          color: "$gray11",
        }}
      >
        {label}
      </P>
    </Column>
  );
};
