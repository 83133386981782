/* eslint-disable react/prop-types */
import {
  capitalizeFirstLetter,
  chargeHotplateFee,
  getAddressString,
  getCartItemPrice,
  getCustomerPaymentProcessingFee,
  getMenuItemOptionsString,
  getOrderDiscount,
  getOrderHotplateFees,
  getOrderTaxes,
  getOrderTotal,
  getReadableTimeSlot,
  getSubtotal,
  getTimestampDayOfWeekMonthDate,
  toFixed,
} from "@hotplate/utils-ts/helperFunctions";
import {
  CalendarIcon,
  ExternalLinkIcon,
  HeartFilledIcon,
  QuestionMarkCircledIcon,
} from "@radix-ui/react-icons";
import { useMutation } from "@tanstack/react-query";
import { atcb_action } from "add-to-calendar-button";
import "add-to-calendar-button/assets/css/atcb.css";
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import classNames from "classnames";
import _ from "lodash";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFirebaseConnection, usePrevious } from "../../hooks";
import { getChefStyles } from "../../hotplate-common/chefStyleFunctions";
import MapContainer from "../../hotplate-common/MapContainer";
import { Button } from "../../hotplate-common/primitives/Button";
import { Callout } from "../../hotplate-common/primitives/Callout";
import { Flex, P, Row } from "../../hotplate-common/primitives/Containers";
import { TextArea } from "../../hotplate-common/primitives/TextArea";
import { Tooltip } from "../../hotplate-common/primitives/Tooltip";
import { ensureOk, postOnWallOfLoveMutation } from "../../mutations";
import { ConfirmationHeader, OrderSummary } from "../../visly/Confirmation";
import { NavButton } from "../../visly/Events";
import { getConfirmationPageData } from "../actions";
import { trackAddToCalendarClicked, trackLoveSent } from "../analytics";
import "../css/Confirmation.css";

const PhotoModal = ({ url, onClose, photoModalStyle }) => {
  return (
    <div className="directionModal" style={photoModalStyle} id="directionModalMobile">
      <img
        className="closeDirectionModalImage"
        src={"https://ucarecdn.com/8e448d5c-7077-4fb2-b1c7-658a6179fb8f/ExitButton.svg"}
        onClick={onClose}
      />
      <img src={url} />
    </div>
  );
};

const AddToCalButton = ({ order, location }) => {
  const [startDate, startTime] = new Date(order.timeSlot.startTime).toISOString().split("T");
  const [endDate, endTime] = new Date(order.timeSlot.endTime).toISOString().split("T");

  const description =
    order.cartType === "delivery"
      ? ""
      : order.location.arrivalInstructions && order.location.arrivalInstructions.length > 0
      ? order.location.arrivalInstructions
      : "";

  return (
    <Button
      size="large"
      color="accent"
      variant="tinted"
      css={{ flexGrow: 1 }}
      onClick={(e) => {
        e.preventDefault();
        atcb_action({
          name: `${capitalizeFirstLetter(order.cartType)} from ${
            order.siteSettings.restaurantName
          }`,
          description: description,
          startDate: startDate,
          startTime: startTime.slice(0, startTime.lastIndexOf(":")),
          endDate: endDate,
          endTime: endTime.slice(0, endTime.lastIndexOf(":")),
          location: location,
          options: ["Google", "Apple", "iCal", "Microsoft365", "Outlook.com", "Yahoo"],
          timeZone: "UTC", // because startDate/startTime/endDate/endTime are in UTC
          iCalFileName: `${order.cartType}-reminder`,
        });
        trackAddToCalendarClicked({
          event_id: order.eventId,
          chef_id: order.chefId,
          order_id: order.paymentIntentId,
        });
      }}
    >
      <CalendarIcon />
      Add to Calendar
    </Button>
  );
};

const Confirmation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { localOrder, liveOrder } = useSelector((state) => ({
    localOrder: state.confirmation.localOrder,
    liveOrder: state.confirmation.liveOrder,
  }));
  const [photoModalState, setPhotoModalState] = useState({
    opacity: 0,
    zIndex: -1,
    url: null,
  });
  const [order, setOrder] = useState(null);
  const [loveText, setLoveText] = useState("");
  const [searchParams] = useSearchParams();
  const chefId =
    localOrder?.orderId?.slice(-6) === searchParams.get("orderId")
      ? localOrder.chefId
      : liveOrder?.chefId;

  const { data: liveSiteSettings } = useFirebaseConnection(
    { ref: `/hosts/${chefId}/siteSettings` },
    { isEnabled: !!chefId }
  );

  const postOnWallOfLove = useMutation(postOnWallOfLoveMutation, {
    retry: 2,
  });

  const pollCustomerOrderInterval = useRef(null);
  const _isMounted = useRef(false);
  const photoModalTarget = useRef(null);

  function linkToGoogleMaps(address) {
    address = address.replace(/ /, "+");
    address = address.replace(/#/g, "Unit ");
    window.open("https://www.google.com/maps/place/" + address, "_blank");
  }

  function getQueryVariable(variable) {
    const urlParams = queryString.parse(window.location.search);
    if (variable in urlParams) return urlParams[variable];
    return null;
  }

  function pollCustomerOrder(phone, orderId) {
    if (!_isMounted.current) return;
    getConfirmationPageData(phone, orderId)(dispatch);
  }

  function getOrderId() {
    return getQueryVariable("orderId");
  }

  function getOrderPhone() {
    return getQueryVariable("phone");
  }

  function openPhotoModal(url) {
    photoModalTarget.current = document.querySelector(".confirmationMain");
    disableBodyScroll(photoModalTarget);
    setPhotoModalState({
      opacity: 1,
      zIndex: 100,
      url: url,
    });
  }

  function closePhotoModal() {
    enableBodyScroll(photoModalTarget);
    setPhotoModalState({
      opacity: 0,
      zIndex: -1,
      url: null,
    });
  }

  function getLocationString(order) {
    let streetAddress = "";
    let cityStateZip = "";
    order.cartType === "delivery"
      ? (streetAddress = getAddressString(
          {
            addressDict: order.customerAddressDict,
            displayFullAddress: true,
          },
          true
        ))
      : (streetAddress = getAddressString(
          {
            ...order.location,
            displayFullAddress: true,
          },
          true
        ));
    order.cartType === "delivery"
      ? (cityStateZip = getAddressString(
          {
            addressDict: order.customerAddressDict,
            displayFullAddress: true,
          },
          false,
          true
        ))
      : (cityStateZip = getAddressString(
          {
            ...order.location,
            displayFullAddress: true,
          },
          false,
          true
        ));
    return { streetAddress: streetAddress, cityStateZip: cityStateZip };
  }

  useEffect(() => {
    _isMounted.current = true;
    window.scrollTo(0, 0);
    const orderId = getOrderId();
    let phone = getOrderPhone();

    if (orderId === null || phone === null) {
      navigate("/");
      return;
    }
    phone = phone.replace(" ", "+");
    if (
      localOrder &&
      typeof localOrder.paymentIntentId === "string" &&
      localOrder.paymentIntentId.substr(localOrder.paymentIntentId.length - 6) === orderId
    ) {
      setOrder(localOrder);
    } else {
      setOrder(liveOrder);
    }

    pollCustomerOrder(phone, orderId);
    setTimeout(() => pollCustomerOrder(phone, orderId), 2000);
    setTimeout(() => pollCustomerOrder(phone, orderId), 4000);
    setTimeout(() => pollCustomerOrder(phone, orderId), 8000);
    pollCustomerOrderInterval.current = setInterval(() => pollCustomerOrder(phone, orderId), 32000);
    return () => {
      clearAllBodyScrollLocks();
      _isMounted.current = false;
      if (pollCustomerOrderInterval.current) clearInterval(pollCustomerOrderInterval.current);
    };
  }, []);

  const prevProps = usePrevious({
    liveOrder,
    localOrder,
  });
  useEffect(() => {
    if (!prevProps) return;
    if (
      (!_.isEqual(prevProps.liveOrder, liveOrder) || _.isEqual(order, localOrder)) &&
      liveOrder &&
      liveOrder.constructor == Object &&
      Object.keys(liveOrder).length > 0 &&
      typeof liveOrder.paymentIntentId === "string" &&
      liveOrder.paymentIntentId.substr(liveOrder.paymentIntentId.length - 6) === getOrderId()
    ) {
      setOrder(_.cloneDeep(liveOrder));
    }
  });

  if (!order || order.constructor != Object || Object.keys(order).length === 0) {
    return (
      <div className="loadingPageMobile">
        <ReactLoading type={"spinningBubbles"} color={"#E85B5B"} height={100} width={100} />
        <p className="waitForLoading">loading...</p>
      </div>
    );
  }

  const {
    cartType,
    customerDeliveryInstructions,
    location,
    deliveryFee,
    timeSlot,
    cartItems,
    siteSettings,
    orderId,
    tip,
    orderPlaced,
    ticketed,
    fullName,
  } = order;

  if (!timeSlot || !location) {
    return (
      <p>
        An error occurred. If refreshing the page does not help, please text or call us at +1 (415)
        840-1210.
      </p>
    );
  }

  const timeSlotTimestamp = timeSlot.startTime;

  const dateOrderPlaced = new Date(orderPlaced);

  const orderPlacedDateTimeString = orderPlaced
    ? `${
        dateOrderPlaced.getMonth() + 1
      }/${dateOrderPlaced.getDate()}/${dateOrderPlaced.getFullYear()} ${
        dateOrderPlaced.getHours() % 12 || 12
      }:${
        dateOrderPlaced.getMinutes() < 10
          ? "0" + dateOrderPlaced.getMinutes()
          : dateOrderPlaced.getMinutes()
      } ${dateOrderPlaced.getHours() >= 12 ? "PM" : "AM"}`
    : "loading...";

  const contentWrapperClassNames = classNames({
    "content-wrapper": true,
    "--in-modal": undefined,
  });

  const confrimationBackgroundClassNames = classNames({
    "confirmation-background": true,
    "--in-modal": undefined,
  });

  return (
    <div className={confrimationBackgroundClassNames}>
      <div className="confirmationMain">
        <div
          className="directionModalMobileMain"
          style={{ opacity: photoModalState.opacity, zIndex: photoModalState.zIndex }}
        >
          <div className="greyBackground" onClick={() => closePhotoModal()} />
          <PhotoModal
            url={photoModalState.url}
            onClose={() => closePhotoModal()}
            photoModalStyle={{ opacity: photoModalState.opacity, zIndex: photoModalState.zIndex }}
          />
        </div>

        <div className={contentWrapperClassNames}>
          {/* Chef Banner */}
          <div
            className="chef-banner"
            onClick={() => window.open(`${window.location.origin}/${siteSettings.chefId}`)}
            style={{
              backgroundColor: getChefStyles(siteSettings).headerBackgroundColor,
              color: getChefStyles(siteSettings).headerTextColor,
              fontFamily: "Arboria, sans-serif",
              fontSize: 24,
              fontWeight: 600,
            }}
          >
            {getChefStyles(siteSettings).headerImage ? (
              <img
                src={getChefStyles(siteSettings).headerImage}
                alt={`${siteSettings.restaurantName} header photo`}
                style={{ height: "38px" }}
              ></img>
            ) : (
              siteSettings.restaurantName
            )}
          </div>
          <ConfirmationHeader
            orderType={ticketed ? "Reservation" : capitalizeFirstLetter(cartType)}
            orderPlaced={orderPlacedDateTimeString}
          >
            <Row
              css={{ textStyle: "text-4", ff: "$avenir", color: "$gray12", alignItems: "center" }}
            >
              {typeof orderId === "string" ? `#${orderId}` : "# loading..."}
              {typeof orderId !== "string" && (
                <Tooltip
                  clickable
                  content={`Don't worry, your order is secured! \n\nUnder high load it can take a bit for us to give you an order number, check back in a few minutes.`}
                >
                  <Button as="span" color="warning" variant="plain" shape="rounded" size="small">
                    <QuestionMarkCircledIcon />
                  </Button>
                </Tooltip>
              )}
            </Row>
          </ConfirmationHeader>
          <div className="pickup-time">
            <h2>
              {ticketed
                ? "Event time"
                : cartType === "pickup"
                ? "pickup time"
                : "delivery estimate"}
            </h2>
            <p>{timeSlot.onDemand ? "ASAP" : getTimestampDayOfWeekMonthDate(timeSlotTimestamp)}</p>
            <p>{getReadableTimeSlot(timeSlot)}</p>
          </div>
          {Object.values(liveSiteSettings?.notifications || {}).some(
            (notification) => notification.enabled
          ) && (
            <Callout type="info" css={{ marginBottom: 48 }}>
              Keep your phone close! You will receive updates on your order via text message.
            </Callout>
          )}
          <div className="location-info confirmation-card">
            {cartType !== "delivery" ? (
              <div
                className="confirmationMapContainer"
                onDoubleClick={() =>
                  linkToGoogleMaps(
                    getAddressString({
                      ...location,
                      displayFullAddress: true,
                    })
                  )
                }
              >
                <MapContainer // TODO: ADJUST PROPS
                  borderRadius={5}
                  locations={[{ ...location, displayFullAddress: true }]}
                />
              </div>
            ) : (
              <h1>Delivery Summary</h1>
            )}
            <h2>
              {ticketed
                ? "Event location"
                : cartType === "pickup"
                ? "pickup location"
                : "delivery address"}
            </h2>
            <p>{cartType === "delivery" ? fullName : location.title}</p>
            <p>{getLocationString(order).streetAddress}</p>
            <p>{getLocationString(order).cityStateZip}</p>
            <h2>
              {ticketed
                ? "Arrival Instructions"
                : cartType === "pickup"
                ? "Pickup Instructions"
                : "Delivery instructions"}
            </h2>
            <p className="__instructions">
              {cartType === "delivery"
                ? customerDeliveryInstructions
                : typeof location.arrivalInstructions === "string" &&
                  location.arrivalInstructions.length > 0
                ? location.arrivalInstructions
                : "None"}
            </p>
            {cartType !== "delivery" &&
              location &&
              location.image &&
              location.image !==
                "https://ucarecdn.com/b4c76d91-9145-4b7c-8258-3b6dc641a0e0/linesquarepngpngimage466144.png" && (
                <div className="photo-container">
                  <img
                    className="photo"
                    src={location.image}
                    onClick={() => openPhotoModal(location.image)}
                  />
                </div>
              )}
            <Flex
              css={{
                flexDirection: "column",
                marginTop: "24px",
                width: "100%",
                gap: 16,
                "@tablet": {
                  flexDirection: "row",
                },
              }}
            >
              <AddToCalButton
                order={order}
                location={`${getLocationString(order).streetAddress}, ${
                  getLocationString(order).cityStateZip
                }`}
              />
              {cartType === "pickup" && (
                <Button
                  css={{ flexGrow: 1 }}
                  size="large"
                  onClick={() =>
                    linkToGoogleMaps(
                      getAddressString({
                        ...location,
                        displayFullAddress: true,
                      })
                    )
                  }
                >
                  Directions
                  <ExternalLinkIcon />
                </Button>
              )}
            </Flex>
          </div>

          <div className="order-summary">
            <OrderSummary
              DeliverySummary={null}
              Subtotal={<OrderSummary.Subtotal total={`$${getSubtotal(cartItems)}`} />}
              Tax={
                <OrderSummary.Tax
                  item={chargeHotplateFee(order) ? "Taxes & Fees" : "Tax"}
                  total={`$${toFixed(
                    parseFloat(getOrderTaxes(order)) +
                      (chargeHotplateFee(order) ? parseFloat(getOrderHotplateFees(order)) : 0.0),
                    2
                  )}`}
                />
              }
              Total={
                <OrderSummary.Total
                  style={{ paddingBottom: "0px" }}
                  item="Total"
                  total={`$${toFixed(
                    parseFloat(getOrderTotal(order)) -
                      (order.refundTotal ? parseFloat(order.refundTotal) : 0),
                    2
                  )}`}
                />
              }
              Refund={
                order.refundTotal ? <OrderSummary.Refund total={`-$${order.refundTotal}`} /> : null
              }
              fees={
                <>
                  {(order.discountCode || order.giftCard) && (
                    <OrderSummary.Fee fee item="Discount" total={`-$${getOrderDiscount(order)}`} />
                  )}
                  <OrderSummary.Fee fee item="Chef Tip" total={`$${tip}`} />
                  {typeof order.deliveryFee === "string" &&
                    !isNaN(order.deliveryFee) &&
                    parseFloat(order.deliveryFee) > 0 && (
                      <OrderSummary.Fee fee item="Delivery Fee" total={`$${deliveryFee}`} />
                    )}
                  {siteSettings.chargeCustomerPaymentProcessing && (
                    <OrderSummary.Fee
                      fee
                      item="Payment Processing Fee"
                      total={`$${getCustomerPaymentProcessingFee(order)}`}
                    />
                  )}
                </>
              }
            >
              {cartItems
                .filter((cartItem) => !cartItem.isSynthetic) // won't do anything for localOrder, but will strip out synthetic items from liveOrder
                .map((cartItem, index) => (
                  <OrderSummary.ReceiptLineItem
                    key={index}
                    item={
                      cartItem.title +
                      (Number.isInteger(cartItem.refundQuantity) && cartItem.refundQuantity > 0
                        ? ` (${cartItem.refundQuantity} refunded)`
                        : "")
                    }
                    total={`$${getCartItemPrice(cartItem)}`}
                    quantity={cartItem.quantity}
                    options={getMenuItemOptionsString(cartItem)}
                  />
                ))}
            </OrderSummary>
          </div>
          <div className="order-details">
            <h1>order info</h1>
            {/* PICKUP/DELIVERY TIME */}
            <section aria-label="Fulfillment Time for your Order">
              <h2>
                {ticketed
                  ? "Event time"
                  : cartType === "pickup"
                  ? "pickup time"
                  : "delivery estimate"}
              </h2>
              <p>
                {timeSlot.onDemand ? "ASAP" : getTimestampDayOfWeekMonthDate(timeSlotTimestamp)}
              </p>
              <p>{getReadableTimeSlot(timeSlot)}</p>
            </section>
            {/* PICKUP/DELIVERY LOCATION */}
            <section aria-label="Location">
              <h2>
                {ticketed
                  ? "Event location"
                  : cartType === "pickup"
                  ? "pickup location"
                  : "delivery address"}
              </h2>
              <p>{cartType === "delivery" ? fullName : location.title}</p>
              <p>{getLocationString(order).streetAddress}</p>
              <p>{getLocationString(order).cityStateZip}</p>
            </section>
            {/* DATE PLACED */}
            <section aria-label="Date order was placed">
              <h2>Order date</h2>
              <p>{orderPlacedDateTimeString}</p>
            </section>
            {/* ORDER NUMBER */}
            <section aria-label="Order number">
              <h2>Order number</h2>
              <p>{typeof orderId === "string" ? "#" + orderId : "loading..."}</p>
            </section>
            {/* CUSTOMER INFO */}
            <section aria-label="The contact information you provided">
              <h2>Your information</h2>
              <p>{order.email}</p>
              <p>{order.phone}</p>
            </section>
            <section aria-label="Wall of love">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "12px",
                  backgroundColor: "#fadcf1",
                  padding: "12px",
                  marginTop: "24px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <HeartFilledIcon
                    style={{
                      marginLeft: "12px",
                      marginTop: "8px",
                      // add gloss to heart icon
                      filter: "drop-shadow(0px 0px 2px #eb1aa5)",
                    }}
                    color="#eb1aa5"
                    height="32px"
                    transform="scale(1.5)"
                  />
                  <text
                    style={{
                      fontFamily: "Avenir, Avenir Next, apple-system, sans-serif",
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginTop: "8px",
                    }}
                  >
                    Send some love to {siteSettings.restaurantName}
                  </text>
                  <HeartFilledIcon
                    style={{
                      marginRight: "12px",
                      marginTop: "8px",
                      filter: "drop-shadow(0px 0px 2px #eb1aa5)",
                    }}
                    color="#eb1aa5"
                    transform="scale(1.5)"
                    height={"32px"}
                  />
                </div>
                <TextArea
                  placeholder={"Send some love..."}
                  value={loveText}
                  onChange={(e) => {
                    setLoveText(e.target.value);
                  }}
                  resize="none"
                  style={{ marginTop: "24px", marginBottom: "8px" }}
                  css={{ outlineColor: "red", borderColor: "green" }}
                />
                <Button
                  onClick={async () => {
                    ensureOk(
                      await postOnWallOfLove.mutateAsync({
                        chefId: order.chefId,
                        paymentIntentId: order.paymentIntentId,
                        message: loveText,
                        firstName: order.firstName,
                        lastName: order.lastName,
                        phone: order.phone,
                      })
                    );
                    trackLoveSent({ chefId: order.chefId, message: loveText });
                    setLoveText("");
                  }}
                  style={{
                    width: "100%",
                    border: "1px solid #4287f5",
                    backgroundColor: "#b2cdf7",
                    marginTop: "8px",
                  }}
                  disabled={loveText === ""}
                  loading={postOnWallOfLove.isLoading}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {postOnWallOfLove.isSuccess ? (
                      <>Thank you!</>
                    ) : (
                      <>
                        Send{" "}
                        {!postOnWallOfLove.isLoading && (
                          <HeartFilledIcon
                            color="blue"
                            style={{ marginLeft: "8px", marginRight: "8px" }}
                          />
                        )}
                      </>
                    )}
                  </div>
                </Button>
              </div>
            </section>
          </div>
        </div>
        <NavButton
          text="Back to Store"
          style={{ margin: "0px 24px 24px" }}
          onClick={() => window.open(`${window.location.origin}/${siteSettings.chefId}`)}
        />
      </div>
    </div>
  );
};

export default Confirmation;
