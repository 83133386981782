import React, { useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import ReactLoading from "react-loading";
import ReactMarkdown from "react-markdown";

import rehypeRaw from "rehype-raw";
import { FirebaseContext } from "../firebaseSocket";
import { styled } from "@stitches/react";
import { Separator } from "../hotplate-common/primitives/Separator";
import { trackChangelogNavigationClicked, trackChangelogViewed } from "./analytics";
import { shouldRetry, trpc } from "../trpc";

const Box = styled("div", {
  "& img": {
    width: "100%",
  },
});

// Headers
const H1 = styled("h1", {
  fontFamily: "$arboria",
  fontWeight: 600,
  margin: "1.414rem 0 .5rem",
  lh: "$btn",
  marginTop: "0",
  fontSize: "2.5rem",
  color: "$gray12",
});
const H2 = styled("h2", {
  fontFamily: "$arboria",
  margin: "3rem 0 1.5rem 0",
  lh: "$heading",
  fontSize: "1.5rem",
  fontWeight: 500,
  color: "$gray12",
});
const H3 = styled("h3", {
  fontFamily: "$arboria",
  color: "$accent11",
  margin: "1.414rem 0 .5rem",
  lh: "$text",
  fontWeight: 500,
  fontSize: "1.25rem",
});

// Body Styles
const P = styled("p", {
  fontSize: 16,
  fontWeight: 400,
  marginBottom: "1.2rem",
  color: "$gray11",
  height: "auto",
  lh: "$article",
});
const A = styled("a", {
  color: "#3498db",
  textDecoration: "underline",
  "&:hover": {
    color: "#2980b9",
  },
  "&:focus": {
    color: "#2980b9",
  },
  "&:active": {
    color: "#2980b9",
  },
});
const Code = styled("code", { fontFamily: 'Menlo, Monaco, "Courier New", monospace' });
const Strong = styled("strong", {
  fontWeight: "bold",
  color: "#444",
  background: "none",
});
const Em = styled("em", { fontStyle: "italic" });
const Pre = styled("pre", {
  fontFamily: 'Menlo, Monaco, "Courier New", monospace',
  backgroundColor: "#fafafa",
  fontSize: ".8rem",
  overflowX: "scroll",
  padding: "1.125em",
});
const Blockquote = styled("blockquote", {
  fontSize: "1.5rem",
  fontStyle: "italic",
  margin: "1rem auto 1rem",
  maxWidth: "48rem",
  "& p": {
    fontSize: "1.5rem",
    fontStyle: "italic",
    margin: "1rem auto 1rem",
    maxWidth: "48rem",
  },
});

// Lists
const OL = styled("ol", {
  display: "block",
  listStyleType: "decimal",
  marginBlockStart: "1em",
  marginBlockEnd: "1em",
  marginInlineStart: "0px",
  marginInlineEnd: "0px",
});
const UL = styled("ul", {
  display: "block",
  listStyleType: "disc",
  marginBlockStart: "1em",
  marginBlockEnd: "1em",
  marginInlineStart: "0px",
  marginInlineEnd: "0px",
});
const LI = styled("li", { marginLeft: "2rem", display: "list-item" });

// Other
const Img = styled("img", {
  maxWidth: "100%",
  borderRadius: "$xs",
});
const Br = styled("br", {});

const ContentBox = styled("div", {
  display: "flex",
  flexDirection: "column",

  justifyContent: "center",
  backgroundColor: "$accent1",
  fontSize: 16,
  fontFamily: "$avenir",
  lh: "$text",
  minWidth: 0,
  margin: 0,
  padding: 24,
});

const Button = styled("button", {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  paddingInline: "$md",
  height: 35,
  fontFamily: "$avenir",
  fontSize: 14,
  fontWeight: 600,
  borderRadius: "$xs",
  backgroundColor: "$accent4",
  color: "$accent11",
  transition: "all 200ms ease-in-out",
  "&:hover": {
    backgroundColor: "$accent5",
  },
  "&:active": {
    backgroundColor: "$accent6",
    color: "$accent12",
  },
});

const PageNumber = styled("span", {
  fontFamily: "$avenir",
  fontSize: 16,
  display: "inline-flex",
  alignItems: "center",
  fontWeight: "$semi_bold",
  color: "$accent11",
});

export default function Changelog() {
  const firebaseContext = useContext(FirebaseContext);
  const getChangelogs = useQuery(["getChangelogs"], firebaseContext.api.getChangelogs);
  const markSeenChangelog = trpc.portal.markSeenChangelog.useMutation({ retry: shouldRetry(3) });
  const [selectedChangelogIdx, setSelectedChangelogIdx] = useState(0);

  useEffect(() => {
    markSeenChangelog.mutate.call(undefined, {});
  }, [markSeenChangelog.mutate]);

  const sortedChangelogs = useMemo(() => {
    if (getChangelogs.data instanceof Object) {
      return [...Object.values(getChangelogs.data)].sort((a, b) => b.timestamp - a.timestamp);
    }
    return [];
  }, [getChangelogs.data]);

  useEffect(() => {
    if (selectedChangelogIdx >= sortedChangelogs.length) {
      setSelectedChangelogIdx(0);
    }
  }, [sortedChangelogs, selectedChangelogIdx]);

  const selectedChangelogId = sortedChangelogs.length && sortedChangelogs[selectedChangelogIdx].id;
  useEffect(() => {
    if (selectedChangelogId) {
      trackChangelogViewed(selectedChangelogId);
    }
  }, [selectedChangelogId]);

  return (
    <ContentBox id="changelog-reader">
      {getChangelogs.isLoading ? (
        <ReactLoading type={"spin"} color={"#2500b6"} />
      ) : sortedChangelogs.length === 0 ? (
        <div>No changelogs found.</div>
      ) : (
        <>
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            components={{
              a: A,
              blockquote: Blockquote,
              br: Br,
              code: Code,
              em: Em,
              h1: H1,
              h2: H2,
              h3: H3,
              h4: H3,
              h5: H3,
              h6: H3,
              hr: Separator,
              img: Img,
              li: LI,
              ol: OL,
              p: P,
              pre: Pre,
              strong: Strong,
              ul: UL,
              div: Box,
            }}
          >
            {/* {`
# hey its keane
---

and here's a list
- a one
- a two
  - *a one, two three four*

**woopsie**

\`\`\`
daisy
\`\`\`


<p>this is a p tag</p>


\`leetcoder\`

[my bio](https://www.keane.dev)

> yea
> boi
`} */}
            {sortedChangelogs[selectedChangelogIdx].markdown}
          </ReactMarkdown>
          <div style={{ display: "flex", padding: "1em" }} id="bottom-nav">
            <div style={{ display: "flex", flex: "1", justifyContent: "center" }}>
              <span style={{ marginRight: "auto" }}>
                {selectedChangelogIdx + 1 < sortedChangelogs.length && (
                  <Button
                    onClick={() => {
                      setSelectedChangelogIdx(selectedChangelogIdx + 1);
                      trackChangelogNavigationClicked("previous");
                    }}
                  >
                    Previous
                  </Button>
                )}
              </span>
            </div>
            <div style={{ display: "flex", flex: "1", justifyContent: "center" }}>
              <PageNumber>#{sortedChangelogs.length - selectedChangelogIdx}</PageNumber>
            </div>
            <div style={{ display: "flex", flex: "1", justifyContent: "center" }}>
              <span style={{ marginLeft: "auto" }}>
                {0 < selectedChangelogIdx && (
                  <Button
                    onClick={() => {
                      setSelectedChangelogIdx(selectedChangelogIdx - 1);
                      trackChangelogNavigationClicked("next");
                    }}
                  >
                    Next
                  </Button>
                )}
              </span>
            </div>
          </div>
        </>
      )}
    </ContentBox>
  );
}
