import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import * as Sentry from "@sentry/react";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import localForage from "localforage";
import reducers from "./hotplate-storefront/reducers";
import ReduxThunk from "redux-thunk";
import packageJson from "../package.json";
const versionNumber = packageJson.version.split(".").splice(0, 2).join(".");

const persistConfig = {
  key: "version:" + versionNumber,
  storage: localForage,
  stateReconciler: autoMergeLevel2,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    return action;
  },
  stateTransformer: (state) => {
    return state;
  },
});

const userTimingMiddleware = () => (next) => (action) => {
  if (performance.mark === undefined) return next(action);
  performance.mark(`${action.type}_start`);
  const result = next(action);
  performance.mark(`${action.type}_end`);
  performance.measure(`${action.type}`, `${action.type}_start`, `${action.type}_end`);
  return result;
};

export const store = createStore(
  persistedReducer,
  {},
  compose(applyMiddleware(ReduxThunk, userTimingMiddleware), sentryReduxEnhancer)
);
export const persistor = persistStore(store);
