import { chef_id } from "../../analytics";

export function trackEventCreated() {
  window.analytics.track("Create Event Clicked", {
    chef_id,
  });
}

export function trackEventDeleted() {
  window.analytics.track("Event Deleted", {
    chef_id,
  });
}

export function trackEventDuplicated({ eventId }) {
  window.analytics.track("Duplicate Event Clicked", {
    chef_id,
    event_id: eventId,
  });
}

export function trackEventGroupExpanded({ group, expanded }) {
  window.analytics.track("Event Group Expanded", {
    chef_id,
    group,
    expanded: expanded,
  });
}
