import React from "react";
import { P } from "./Containers";

export const Error = ({ show = true, children, ...props }) => {
  return (
    <>
      {show && (
        <P
          {...props}
          css={{
            ff: "$inter",
            textStyle: "text-1",
            color: "$error11",
            ...props.css,
          }}
        >
          {children}
        </P>
      )}
    </>
  );
};
