import React from "react";
import { Select } from "../../../hotplate-common/primitives/Select";
import { CardStackIcon, DashboardIcon, TableIcon } from "@radix-ui/react-icons";
import { setPackingView } from "../../../hotplate-storefront/actions";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "../../../hotplate-common/primitives/Containers";
import { trackPackingViewChanged } from "../analytics";

export const PackingSelectView = () => {
  const packingView = useSelector((state) => state.orderManagement.packingView);
  const dispatch = useDispatch();

  const optionIcons = {
    LIST: <TableIcon />,
    CARD: (
      <Box css={{ transform: "rotate(-90deg)" }}>
        <CardStackIcon />
      </Box>
    ),
    GRID: <DashboardIcon />,
  };

  return (
    <Select.Root
      value={packingView}
      onValueChange={(value) => {
        trackPackingViewChanged({ packingView: value });
        setPackingView(value)(dispatch);
      }}
    >
      <Select.Trigger
        icon={optionIcons[packingView]}
        css={{
          padx: 6,
          height: 32,
          borderRadius: 8,
          marginLeft: 8,
          backgroundColor: "$accent1",
          gap: 0,
          "& > span": { padx: 6 },
          "& > span:first-child": {
            borderRight: "1px solid $accent6",
          },
        }}
      />
      <Select.Content>
        <Select.Option textValue="Table view" value="LIST" hint={optionIcons["LIST"]}>
          Table
        </Select.Option>
        <Select.Option textValue="Card view" value="CARD" hint={optionIcons["CARD"]}>
          Ticket
        </Select.Option>
        <Select.Option textValue="Grid view" value="GRID" hint={optionIcons["GRID"]}>
          Grid
        </Select.Option>
      </Select.Content>
    </Select.Root>
  );
};
