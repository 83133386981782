import {
  SET_CONFIRMATION_ORDER,
  CONNECT_TO_CUSTOMER_ORDER_START,
  CONNECT_TO_CUSTOMER_ORDER_SUCCESS,
  CONNECT_TO_CUSTOMER_ORDER_FAILURE,
} from "../actions/types";

const INITIAL_STATE = {
  localOrder: {},
  liveOrder: {},

  connectToCustomerOrderLoading: false,
  connectToCustomerOrderError: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONNECT_TO_CUSTOMER_ORDER_START:
      return {
        ...state,
        connectToCustomerOrderLoading: true,
        connectToCustomerOrderError: "",
      };
    case CONNECT_TO_CUSTOMER_ORDER_SUCCESS:
      return {
        ...state,
        connectToCustomerOrderLoading: false,
        liveOrder: action.payload,
      };
    case CONNECT_TO_CUSTOMER_ORDER_FAILURE:
      return {
        ...state,
        connectToCustomerOrderLoading: false,
        connectToCustomerOrderError: action.payload,
      };
    case SET_CONFIRMATION_ORDER:
      return { ...state, localOrder: action.payload };
    default:
      return state;
  }
};
