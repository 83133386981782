/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from "react";
import { Portal } from "react-portal";
import { colors } from "../visly";
import { ReactComponent as NoWifi } from "../static/images/noWifi.svg";
import "./css/ConnectionStatusMonitor.css";
import classNames from "classnames";
import { useFirebaseConnection } from "../hooks";
import { FirebaseContext } from "../firebaseSocket";
import { trackFirebaseConnectionLost } from "./analytics";

export default function ConnectionStatusMonitor() {
  const firebaseContext = useContext(FirebaseContext);

  const [timeCreated] = useState(() => Date.now());

  const { data: connected } = useFirebaseConnection({ ref: ".info/connected" });
  const { data: timestamp } = useFirebaseConnection({ ref: "heartbeat" });

  useEffect(() => {
    if (timestamp) {
      const t = setTimeout(() => {
        firebaseContext.reset();
        trackFirebaseConnectionLost();
      }, 20_000);
      return () => {
        clearTimeout(t);
      };
    }
  }, [firebaseContext, timestamp]);

  const isOnline = Date.now() < timeCreated + 8_000 || connected === true;

  const monitorClassNames = classNames({
    "monitor-container": true,
    "--show": !isOnline,
  });

  return (
    <Portal>
      <div className={monitorClassNames}>
        <NoWifi height="18px" width="18px" fill={colors.white} />
        You are currently offline.
      </div>
    </Portal>
  );
}
