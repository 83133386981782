import React from "react";
import { styled } from "../../stitches.config";
import * as SeparatorPrimitive from "@radix-ui/react-separator";

const StyledSeparator = styled(SeparatorPrimitive.Root, {});

// Exports
export const Separator = ({
  thickness = 1,
  length = "100%",
  space = "$md",
  orientation = "horizontal",
  decorative = "false",
  css,
  ...props
}) => {
  return (
    <StyledSeparator
      orientation={orientation}
      decorative={decorative}
      css={{
        backgroundColor: "$gray8",
        "&[data-orientation=horizontal]": { height: thickness, width: length, marginBlock: space },
        "&[data-orientation=vertical]": { height: length, width: thickness, marginInline: space },
        ...css,
      }}
      {...props}
    />
  );
};
