import React from "react";
import { styled } from "../../stitches.config";
import CountdownTimer from "./CountdownTimer";
import ReactLoading from "react-loading";
import {
  getSubtotal,
  getCartItemPrice,
  getMenuItemOptionsString,
  numberWithCommas,
} from "@hotplate/utils-ts/helperFunctions";
import { TrashIcon } from "@radix-ui/react-icons";
import * as AspectRatioPrimitive from "@radix-ui/react-aspect-ratio";
import { Button } from "../../hotplate-common/primitives/Button";
import { Link } from "react-router-dom";
import { Dialog } from "../../hotplate-common/primitives/Dialog";
import { H1, Row } from "../../hotplate-common/primitives/Containers";

const AspectRatio = AspectRatioPrimitive;

const Box = styled("div", { display: "flex" });

const CartItemBox = styled("div", {
  display: "flex",
  position: "relative",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBlock: "$md",
  "&::after": {
    // divider
    content: "",
    position: "absolute",
    bottom: 0,
    display: "block",
    width: "calc(100% - 36px)",
    height: "1px",
    backgroundColor: "$gray3",
  },
});

const ImgBox = styled("div", {
  width: "60px",
  overflow: "hidden",
  borderRadius: "$sm",
  backgroundColor: "$accent3",
  marginRight: "$md",
  display: "none",
  "@tablet": {
    display: "flex",
    flexShrink: 0,
  },
});

const Img = styled("img", {
  objectFit: "cover",
  width: "100%",
  height: "100%",
});

const TextBox = styled("div", {
  display: "flex",
  flexDirection: "column",
  marginRight: "$xs",
  minWidth: "0px",
  flex: 1,
});

const StrongText = styled("span", {
  display: "block",
  fontFamily: "$inter",
  fontWeight: "$semi_bold",
  fontSize: "$sm",
  color: "$gray12",
  maxWidth: "35ch",
  lh: "$text",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "pre-line",
});

const QuietText = styled("p", {
  fontFamily: "$inter",
  fontWeight: "$normal",
  fontSize: "$sm",
  lh: "$heading",
  color: "$gray11",
  marginTop: "$xs",
});

const CartItem = ({ cartItem, onRemoveItemFromCart, loading }) => {
  const options = getMenuItemOptionsString(cartItem);
  // TODO remove this src reassignment when we get rid of a default menu item img
  let imgSrc = "";
  cartItem.image === "https://ucarecdn.com/c7478fd7-b935-44e7-b406-7889e94f9b3d/MenuPlaceholder.png"
    ? (imgSrc = false)
    : (imgSrc = cartItem.image);
  return (
    <CartItemBox>
      <ImgBox>
        <AspectRatio.Root ratio={1}>
          {imgSrc && <Img src={cartItem.image} alt={`Amazing photo of ${cartItem.title}`} />}
        </AspectRatio.Root>
      </ImgBox>
      <TextBox>
        {/* top row */}
        <Box css={{ justifyContent: "space-between" }}>
          <StrongText css={{ marginRight: "$lg" }}>{cartItem.title}</StrongText>
          <StrongText css={{ minWidth: "fit-content" }}>
            ${numberWithCommas(getCartItemPrice(cartItem))}
          </StrongText>
        </Box>
        {/* bottom row */}
        <Box css={{ justifyContent: "space-between" }}>
          <QuietText css={{ maxWidth: "75%" }}>{options}</QuietText>
          <QuietText css={{ whiteSpace: "nowrap" }}>Qty {cartItem.quantity}</QuietText>
        </Box>
      </TextBox>
      {loading ? (
        <ReactLoading type={"spin"} color={"#000000"} height={26} width={26} />
      ) : (
        <Button
          tabIndex={-1}
          size="small"
          color="hotplate"
          variant="tinted"
          onClick={() => onRemoveItemFromCart(cartItem)}
          css={{ padx: "$xs", ml: "$xs" }}
        >
          <TrashIcon />
        </Button>
      )}
    </CartItemBox>
  );
};

const CartModalContent = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});

const CartItemsBox = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "flex-start",
  minHeight: "30vh",
  maxHeight: "50vh",
  overflowY: "auto",
});

const NoItemsInCart = styled("div", {
  display: "flex",
  alignSelf: "center",
  fontFamily: "$inter",
  fontWeight: "$semi_bold",
  fontSize: "$md",
  color: "$gray10",
  marginBlock: "$xl",
});

const Divider = styled("div", {
  width: "100%",
  marginBottom: "$lg",
  height: "2px",
  backgroundColor: "$gray9",
  opacity: 0.2,
});

const Subtotal = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "$lg",
  width: "100%",
  fontFamily: "$inter",
  fontWeight: "$semi_bold",
  fontSize: "$md",
  color: "$gray12",
});

export const CartModal = ({
  isOpen,
  onRequestClose,
  cartItems,
  cartEvent,
  onRemoveItemFromCart,
  cartType,
  chefStyles,
  unreserveCartItemsLoading,
}) => {
  // TODO this validation should also be done in the backend and in the Checkout.jsx component
  const minimumDeliverySubtotalError =
    cartType === "delivery" &&
    cartEvent.deliverySettings &&
    typeof cartEvent.deliverySettings.minimumSubtotal === "string" &&
    cartEvent.deliverySettings.minimumSubtotal !== "" &&
    parseFloat(getSubtotal(cartItems)) < parseFloat(cartEvent.deliverySettings.minimumSubtotal);

  const minimumSubtotalError = parseFloat(getSubtotal(cartItems)) < 0.5;

  let minimumSubtotal = minimumSubtotalError
    ? "$0.50"
    : minimumDeliverySubtotalError
    ? cartEvent.deliverySettings.minimumSubtotal
    : "";

  const hasItemsInCart = Array.isArray(cartItems) && cartItems.length > 0;

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={onRequestClose}
      contentCss={{ width: "100%", maxW: "650px", maxHeight: "calc(100% - 32px)", br: "$lg" }}
    >
      <CartModalContent css={{ ff: "$inter" }}>
        <Row
          css={{ w: "$full", jc: "space-between", ai: "center", mt: "$xxl", mb: "$lg", mr: "$xl" }}
        >
          <H1 css={{ textStyle: "text-5", color: "$gray12" }}>Your Cart</H1>
          {hasItemsInCart && <CountdownTimer size="medium" withTooltip />}
        </Row>

        <CartItemsBox>
          {hasItemsInCart ? (
            <>
              {cartItems.map((cartItem, cartItemIndex) => (
                <CartItem
                  key={cartItemIndex}
                  cartItem={cartItem}
                  onRemoveItemFromCart={onRemoveItemFromCart}
                  loading={unreserveCartItemsLoading}
                />
              ))}
            </>
          ) : (
            <NoItemsInCart>{`Your cart is empty.`}</NoItemsInCart>
          )}
        </CartItemsBox>
        <Divider />
        <Subtotal>
          <span>Subtotal</span>
          <span>${numberWithCommas(getSubtotal(cartItems))}</span>
        </Subtotal>
        {minimumDeliverySubtotalError || minimumSubtotalError ? (
          <Button
            as={Link}
            to="/Checkout"
            size="large"
            css={{
              width: "100%",
              border: chefStyles.primaryColorHover,
              textDecoration: "none",
              pointerEvents: "none",
            }}
          >
            Minimum of {minimumSubtotal} to checkout
          </Button>
        ) : (
          <Button
            as={Link}
            to="/Checkout"
            size="large"
            css={{
              width: "100%",
              backgroundColor: chefStyles.primaryColor,
              color: chefStyles.textColor + " !important",
              border: chefStyles.primaryColorHover,
              "&:hover": {
                backgroundColor: chefStyles.primaryColorHover + " !important",
              },
              "&:active": {
                backgroundColor: chefStyles.primaryColorActive + " !important",
              },
              "&:disabled": {
                backgroundColor: chefStyles.primaryColorLight + " !important",
              },
              "& *": {
                color: chefStyles.textColor + " !important",
              },
              textDecoration: "none",
            }}
          >
            Choose Time & Checkout
          </Button>
        )}
      </CartModalContent>
    </Dialog>
  );
};
