// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import {
  useReducer,
  useLayoutEffect,
  useEffect,
  useMemo,
  useState,
} from "react";
let breakRule = "min-width";
let breakpoints = ["480px", "768px", "1024px", "1200px"];
export function setBreakpoints(rule, breaks) {
  console.assert(breaks.length > 0, "Expected at least 1 break point");
  console.assert(
    rule === "min-width" || rule === "max-width",
    "Unexpected break rule"
  );
  let last;

  for (const bp of breaks) {
    console.assert(bp.match(/px|em|rem$/), "Breakpoint must have a valid unit");
    console.assert(parseFloat(bp), "Expected valid dimension value");

    if (last && rule === "min-width") {
      console.assert(
        parseFloat(last) < parseFloat(bp),
        "Expected breakpoint in order small -> large"
      );
    }

    if (last && rule === "max-width") {
      console.assert(
        parseFloat(last) > parseFloat(bp),
        "Expected breakpoint in order large -> small"
      );
    }

    last = bp;
  }

  breakRule = rule;
  breakpoints = breaks;
}

function _useBreakpoint(def, variants) {
  console.assert(variants.length > 0, "Expected at least 1 variant");
  console.assert(
    variants.length <= breakpoints.length,
    "Expected fewer or equal number of variants than break points"
  );
  const [update, forceUpdate] = useReducer((i) => i + 1, 0);
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", forceUpdate);
      return () => window.removeEventListener("resize", forceUpdate);
    }
  }, []);
  const variant = useMemo(() => {
    for (let i = variants.length - 1; i >= 0; i--) {
      if (
        typeof window !== "undefined" &&
        window.matchMedia(`(${breakRule}: ${breakpoints[i]})`).matches
      ) {
        return variants[i];
      }
    }

    return def;
  }, [update, ...variants]);
  return variant;
}

const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;
export function useBreakpoint(def, variants) {
  const [bp, setBp] = useState(def);

  const result = _useBreakpoint(def, variants);

  useIsomorphicLayoutEffect(() => {
    setBp(result);
  }, [result]);
  return bp;
}
