// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./CheckoutTimeCard.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      Coix8Vkcz3: {
        none: {
          text: "12:00",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
  {
    type: "boolean",
    propName: "loading",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
  {
    type: "boolean",
    propName: "loading",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "selected",
  },
  {
    type: "boolean",
    propName: "loading",
  },
];

export const CheckoutTimeCardContext = createContext(null);

function CheckoutTimeCard(_props) {
  const defaults = useContext(CheckoutTimeCardContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="Px8xpSztae"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "Px8xpSztae",
        scope: "JexBkUyqka",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_JeXqEUndhp"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_JexBkUyqka_JeXqEUndhp"
          }
          key={"JeXqEUndhp"}
          addSpacing={false}
          element={getStyle("JeXqEUndhp", "htmlElement")}
        >
          <TextPrimitive
            id={"title_Coix8Vkcz3"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_JexBkUyqka_Coix8Vkcz3"
            }
            key={"Coix8Vkcz3"}
            text={
              exists(props.title) ? props.title : getStyle("Coix8Vkcz3", "text")
            }
            element={getStyle("Coix8Vkcz3", "htmlElement")}
          />
          <ContainerPrimitive
            key={"VHit97njHX"}
            id={"Slot_VHit97njHX"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_JexBkUyqka_VHit97njHX"
            }
            addSpacing={false}
          >
            {props.loadingAnimation}
          </ContainerPrimitive>
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

CheckoutTimeCard.__variants = [
  {
    name: "selected",
    type: "variant",
  },
  {
    name: "loading",
    type: "variant",
  },
];

export default CheckoutTimeCard;
