// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./LocationCard.EditButton.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, IconPrimitive } from "../_internal_primitives";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      Y2aFJWUst9: {
        none: {
          icon: icons.edit,
          useMask: true,
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const EditButtonContext = createContext(null);

function EditButton(_props) {
  const defaults = useContext(EditButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="S6YdycbFSk"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "S6YdycbFSk",
        scope: "85ZFk7fv4G",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <IconPrimitive
          id={"Icon_Y2aFJWUst9"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_85ZFk7fv4G_Y2aFJWUst9"
          }
          key={"Y2aFJWUst9"}
          useMask={getStyle("Y2aFJWUst9", "useMask")}
          src={exists(props.icon) ? props.icon : getStyle("Y2aFJWUst9", "icon")}
        />
      )}
    </RootPrimitive>
  );
}

EditButton.__variants = [];

export default EditButton;
