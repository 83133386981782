import React from "react";
import { styled } from "../../stitches.config";
import { Box } from "./Containers";

const FilterButtonPrimitive = styled("div", {
  display: "flex",
  alignItems: "center",
  position: "relative",
  cursor: "pointer",
  height: 32,
  width: "min-content",
  paddingInline: 6,
  borderRadius: 8,
  boxShadow: `0 2px 3px $colors$overlay7`,
  backgroundColor: "$$background",
  fontFamily: "$avenir",
  fontSize: "13px",
  fontWeight: "500",
  whiteSpace: "nowrap",
  color: "$$color",
  border: "1px solid $$borderColor",
  transition: "background-color 150ms ease-in-out",
  "&:hover": {
    backgroundColor: "$$hover_background",
  },
  "&:active": {
    backgroundColor: "$$active_background",
  },
  "&:disabled": {
    cursor: "default",
  },

  "& svg": {
    height: 13,
    width: 13,
  },
  "& > svg ~ span": {
    marginLeft: "6px",
  },
  "& > .filter-alert": {
    position: "absolute",
    top: -2,
    right: -2,
    borderRadius: "$pill",
    height: 8,
    width: 8,
    backgroundColor: "$info9",
    opacity: 0,
    transition: "opacity 150ms ease-in-out",
  },
  variants: {
    alert: {
      true: {
        "& > .filter-alert": {
          opacity: 1,
        },
      },
    },
    color: {
      normal: {
        $$background: "var(--white)",
        $$hover_background: "var(--gray100)",
        $$active_background: "var(--gray200)",
        $$borderColor: "var(--gray200)",
        $$color: "var(--gray700)",
      },
      danger: {
        $$background: "var(--primary050)",
        $$hover_background: "var(--primary100)",
        $$active_background: "var(--primary200)",
        $$borderColor: "var(--primary200)",
        $$color: "var(--primary800)",
      },
      warning: {
        $$background: "var(--orange050)",
        $$hover_background: "var(--orange100)",
        $$active_background: "var(--orange200)",
        $$borderColor: "var(--orange500)",
        $$color: "var(--orange800)",
      },
      success: {
        $$background: "var(--green050)",
        $$hover_background: "var(--green100)",
        $$active_background: "var(--green200)",
        $$borderColor: "var(--green200)",
        $$color: "var(--green800)",
      },
    },
    outline: {
      true: {
        $$background: "transparent",
        border: "1px dashed $$borderColor",
        height: 32,
        boxShadow: "none",
      },
    },
  },
  compoundVariants: [
    {
      color: "normal",
      outline: true,
      css: {
        $$background: "transparent",
      },
    },
    {
      color: "success",
      outline: true,
      css: {
        $$background: "transparent",
      },
    },
    {
      color: "warning",
      outline: true,
      css: {
        $$background: "transparent",
      },
    },
    {
      color: "danger",
      outline: true,
      css: {
        $$background: "transparent",
      },
    },
  ],
  defaultVariants: {
    alert: false,
    color: "normal",
    outline: false,
  },
});

export const FilterButton = ({ text, onClick, outline, color, alert, children, ...props }) => {
  return (
    <FilterButtonPrimitive
      onClick={onClick}
      outline={outline}
      color={color}
      alert={alert}
      {...props}
    >
      <Box className="filter-alert" />
      {children}
      {text && <span>{text}</span>}
    </FilterButtonPrimitive>
  );
};
