// EXPORT ITEMS AS COLUMNS

// Use Dialog component to create a new OrderExport component w/ orders as a prop

// Every time orders are exported, modal appears with "Export Options"

// Toggle: "Export items as columns instead of rows"

// Export

// Functional component

// Use Dialog component to create a new OrderExport component w/ orders as a prop

import React, { useState } from "react";
import { Dialog } from "../../hotplate-common/primitives/Dialog";
import { Button } from "../../hotplate-common/primitives/Button";
import { Label } from "../../hotplate-common/primitives/Label";
import { Switch } from "../../hotplate-common/primitives/Switch";
import { CSVLink } from "react-csv";
import { getDateTimeString, getEventOrdersCsvData } from "@hotplate/utils-ts/helperFunctions";
import { TypeformButton } from "./TypeformButton";
import { DownloadIcon } from "@radix-ui/react-icons";
import { trackOrdersExported } from "../analytics";
import { Column, P } from "../../hotplate-common/primitives/Containers";

export const OrderExportDialog = ({
  trigger,
  isOpen,
  trackSource,
  onOpenChange,
  orders,
}: {
  trigger: any;
  isOpen: boolean | undefined;
  trackSource: string;
  onOpenChange: (isOpen: boolean) => void | undefined;
  orders: Array<Record<string, any>>;
}) => {
  const [exportItemsAsColumns, setExportItemsAsColumns] = useState(false);
  const [localIsOpen, setLocalIsOpen] = useState(isOpen);

  return (
    <Dialog
      isOpen={isOpen === undefined ? undefined : isOpen}
      onOpenChange={(isOpenProp: boolean) => {
        if (onOpenChange) onOpenChange(isOpenProp);
        setLocalIsOpen(isOpenProp);
      }}
      title={`Export Options`}
      trigger={trigger}
      description={`Exporting ${orders.length} order${orders.length > 1 ? "s" : ""} to a CSV file.`}
    >
      <Label
        text="Export items as columns instead of rows"
        side="right"
        css={{ mb: "$md", mt: "$xl" }}
        tooltipText=""
      >
        <Switch
          checked={exportItemsAsColumns}
          onCheckedChange={() => {
            setExportItemsAsColumns(!exportItemsAsColumns);
          }}
        />
      </Label>
      <Column css={{ justifyContent: "space-between" }}>
        <Column css={{ pady: "$xl" }}>
          <P
            css={{
              textStyle: "text-2",
              ff: "$inter",
              color: "$gray11",
              textAlign: "center",
              mb: "$md",
            }}
          >
            Want to see more options to configure how orders are exported? Let us know!
          </P>
          <TypeformButton
            featureName="Order Exports"
            text="Suggest additional options"
            css={{
              minWidth: 250,
              width: "50%",
              color: "$accent11",
              mx: "auto",
              backgroundColor: "$accent3",
              "&:hover": {
                backgroundColor: "$accent4",
              },
              "&:active": {
                backgroundColor: "$accent5",
              },
            }}
          />
        </Column>
        {isOpen || localIsOpen ? (
          <CSVLink
            filename={`hotplate-orders_exported-${getDateTimeString()}.csv`}
            style={{ textDecoration: "none" }}
            data={getEventOrdersCsvData(orders, false, exportItemsAsColumns)}
          >
            <Button
              onClick={() => {
                trackOrdersExported({
                  isItemsAsColumns: exportItemsAsColumns,
                  trackSource: trackSource,
                });
              }}
              color="accent"
              variant="filled"
              css={{ width: "$full" }}
            >
              <DownloadIcon />
              Export
            </Button>
          </CSVLink>
        ) : (
          <></>
        )}
      </Column>
    </Dialog>
  );
};
