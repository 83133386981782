/* eslint-disable react/prop-types */
import React from "react";
import ReactLoading from "react-loading";

import { icons, useBreakpoint } from "../../../../visly";

import {
  getTimestampDayOfWeekMonthDate,
  getTimestampHoursMinutesAMPM,
  getEventStatus,
  getSortedTimeWindows,
} from "@hotplate/utils-ts/helperFunctions";
import { Input, Button, Radio } from "../../../../visly/Primitives";
import { EventBasicInfo, LabeledSwitch } from "../../../../visly/Events";
import ToolTip from "../../../../hotplate-common/ToolTip";
import DayPicker from "../../../components/DayPicker";
import ImageUploader from "../../../components/ImageUploader";

import "./css/index.css";
import { Column } from "../../../../hotplate-common/primitives/Containers";
import { TextArea } from "../../../../hotplate-common/primitives/TextArea";
import { colors, styled } from "../../../../stitches.config";
import {
  trackDisableRemindersToggled,
  trackEventImageUpdated,
  trackEventOpenTimeUpdated,
  trackEventWalkupOrderingToggled,
  trackHideOpenTimeToggled,
  trackWalkupOrderingTypeUpdated,
  trackAdvancedSettingsClicked,
  trackEventDescriptionUpdated,
} from "../analytics";
import _ from "lodash";

const Error = styled("p", {
  ff: "$avenir",
  fs: 14,
  fw: "$normal",
  color: "$error10",
  lineHeight: 1.5,
  pady: "$md",
});

export default function InfoMaster({
  mergedEvent,
  eventDiffDispatch,
  saveEventLoading,
  publishEventLoading,
  saveAndContinue,
  setShowEditOrderCutoffTypeModal,
  setShowAdvancedSettingsModal,
}) {
  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);
  const [debounce] = React.useState(() => {
    return _.debounce(
      (func) => {
        func();
      },
      2000,
      { leading: false, trailing: true }
    );
  });
  const sortedTimeWindows = getSortedTimeWindows(mergedEvent.timeWindows);

  function getOrdersCloseButtonText() {
    if (mergedEvent.orderCutoffType === "Never") return "End of pickup window";
    if (mergedEvent.orderCutoffType === "Hours Before")
      return `${
        Number.isInteger(mergedEvent.orderCutoffHoursBefore)
          ? mergedEvent.orderCutoffHoursBefore
          : 1
      } hours before each pickup window`;
    if (mergedEvent.orderCutoffType === "Specific Time") {
      if (mergedEvent.recurring) return mergedEvent.orderCutoffDayOfWeek;
      return `${getTimestampDayOfWeekMonthDate(
        mergedEvent.orderCutoffTime
      )} at ${getTimestampHoursMinutesAMPM(mergedEvent.orderCutoffTime)}`;
    }
    return "Choose option";
  }

  function onFileLoaded(fileInfo) {
    if (!fileInfo) {
      console.error("IMG UPLOAD ERR");
      return;
    }
    setEventBannerImage(fileInfo.cdnUrl);
  }

  function setEventTitle(value) {
    // max of 50 characters as of now
    eventDiffDispatch({ type: "setEventTitle", payload: value.substr(0, 50) });
  }

  function setEventBannerImage(image) {
    eventDiffDispatch({ type: "setEventBannerImage", payload: image });
    trackEventImageUpdated({
      eventId: mergedEvent.id,
      eventStatus: getEventStatus(mergedEvent),
      image: image,
    });
  }

  function setGoLiveDate(timestamp) {
    const prevD = new Date(mergedEvent.goLiveTime);
    const nextD = new Date(timestamp);
    prevD.setYear(nextD.getFullYear());
    prevD.setMonth(nextD.getMonth(), nextD.getDate());
    eventDiffDispatch({ type: "setGoLiveDate", payload: prevD.getTime() });
    trackEventOpenTimeUpdated({
      eventId: mergedEvent.id,
      eventStatus: getEventStatus(mergedEvent),
      openTime: prevD.getTime(),
    });
  }

  function setGoLiveHoursMinutes(timeAMPM) {
    const prevD = new Date(mergedEvent.goLiveTime);
    const hours = parseInt(timeAMPM.split(":")[0]);
    const minutes = parseInt(timeAMPM.split(":")[1].substr(0, 2));
    const ampm = timeAMPM.split(":")[1].substr(2, 2);
    prevD.setHours((hours % 12) + (ampm === "PM" ? 12 : 0), minutes, 0, 0);
    eventDiffDispatch({
      type: "setGoLiveHoursMinutes",
      payload: prevD.getTime(),
    });
    trackEventOpenTimeUpdated({
      eventId: mergedEvent.id,
      eventStatus: getEventStatus(mergedEvent),
      openTime: prevD.getTime(),
    });
  }

  function toggleHideOrdersOpenTime() {
    eventDiffDispatch({
      type: "setHideOrdersOpenTime",
      payload: !mergedEvent.hideOrdersOpenTime,
    });
    trackHideOpenTimeToggled({
      eventId: mergedEvent.id,
      eventStatus: getEventStatus(mergedEvent),
      enabled: !mergedEvent.hideOrdersOpenTime,
    });
  }

  function toggleDisableEventReminders() {
    if (mergedEvent.private) {
      return;
    }
    eventDiffDispatch({
      type: "setIsEventRemindersDisabled",
      payload: !mergedEvent.isEventRemindersDisabled,
    });
    trackDisableRemindersToggled({
      eventId: mergedEvent.id,
      eventStatus: getEventStatus(mergedEvent),
      enabled: !mergedEvent.isEventRemindersDisabled,
    });
  }

  function toggleHypeMode() {
    eventDiffDispatch({
      type: "setIsHypeModeEnabled",
      payload: !mergedEvent.isHypeModeEnabled,
    });
  }

  function toggleOnDemandEnabled() {
    eventDiffDispatch({
      type: "setOnDemandEnabled",
      payload: !mergedEvent.onDemandEnabled,
    });
    trackEventWalkupOrderingToggled({
      eventId: mergedEvent.id,
      eventStatus: getEventStatus(mergedEvent),
      enabled: !mergedEvent.onDemandEnabled,
    });
  }

  function setOnDemandPickupWindows(value) {
    eventDiffDispatch({ type: "setOnDemandPickupWindows", payload: value });
    trackWalkupOrderingTypeUpdated({
      eventId: mergedEvent.id,
      eventStatus: getEventStatus(mergedEvent),
      type: value ? "pickup-windows" : "asap",
    });
  }

  return (
    <EventBasicInfo
      size={size}
      EventName={
        <EventBasicInfo.EventName
          Input={
            <Input
              style={{ width: "100%" }}
              value={mergedEvent.title}
              onChange={(val) => setEventTitle(val)}
              placeholder="Untitled Event"
            />
          }
        />
      }
      EventPhoto={
        <EventBasicInfo.EventPhoto
          Input={
            <ImageUploader
              image={mergedEvent.image}
              crop="800x800 minimum"
              onFileLoaded={onFileLoaded}
            />
          }
        />
      }
      EventDescription={
        <EventBasicInfo.EventDescription
          Input={
            <TextArea
              maxLength={500}
              placeholder="Describe your event"
              value={mergedEvent.description}
              onChange={(e) => {
                eventDiffDispatch({ type: "setEventDescription", payload: e.target.value });
                debounce(() => {
                  trackEventDescriptionUpdated({
                    eventId: mergedEvent.id,
                    eventStatus: getEventStatus(mergedEvent),
                    description: e.target.value,
                  });
                });
              }}
            />
          }
        />
      }
      OrdersOpen={
        <EventBasicInfo.OrdersOpen
          label={`Pre-orders Open (${
            new Date().toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2]
          })`}
          Input={
            <Column css={{ flexDirection: "column" }}>
              <DayPicker
                recurring={false}
                setDate={setGoLiveDate}
                dateLabel=" "
                startTimestamp={mergedEvent.goLiveTime}
                setStartTime={setGoLiveHoursMinutes}
                startTimeLabel=" "
                invalid={
                  sortedTimeWindows.length &&
                  mergedEvent.goLiveTime > sortedTimeWindows[0].startTime
                }
              />
              {sortedTimeWindows.length > 0 &&
                mergedEvent.goLiveTime > sortedTimeWindows[0].startTime && (
                  <Error>This is after your first pickup window opens, are you sure?</Error>
                )}
            </Column>
          }
        />
      }
      OrdersClose={
        <EventBasicInfo.OrdersClose
          label={
            "Pre-orders Close" +
            (mergedEvent.orderCutoffType === "Specific Time"
              ? ` (${
                  new Date().toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2]
                })`
              : "")
          }
          Input={
            <Column>
              <Button
                withIcon="right"
                icon={icons.chevronRight}
                style={
                  mergedEvent.orderCutoffType === "Specific Time" &&
                  mergedEvent.orderCutoffTime < mergedEvent.goLiveTime
                    ? { outline: `2px solid ${colors.tomato9}`, backgroundColor: colors.tomato3 }
                    : {}
                }
                placeholder="Choose option"
                onClick={() => setShowEditOrderCutoffTypeModal(true)}
                text={getOrdersCloseButtonText()}
              />
              {mergedEvent.orderCutoffType === "Specific Time" &&
                mergedEvent.orderCutoffTime < mergedEvent.goLiveTime && (
                  <Error>Orders are set to close before they begin.</Error>
                )}
            </Column>
          }
        />
      }
      EnableOnDemand={
        <LabeledSwitch
          Switch={
            <LabeledSwitch.Switch
              checked={mergedEvent.onDemandEnabled}
              onChange={toggleOnDemandEnabled}
            />
          }
          title="Enable walk-up ordering"
          tooltip={
            <ToolTip
              text={`Allows you to take contactless walk-up orders via QR code with an accelerated checkout. \n\n'ASAP' order tickets have a timer so you can keep track of your ticket times.`}
            />
          }
        />
      }
      onDemandType={
        <Column>
          <div
            style={{
              overflow: "hidden",
              maxHeight: mergedEvent.onDemandEnabled ? "125px" : "0px",
              transition: "max-height ease-in-out 500ms",
            }}
          >
            <Radio
              style={{ padding: "16px 0px 16px 24px" }}
              value={mergedEvent.onDemandWindowsEnabled ? "windows" : "asap"}
            >
              <Radio.Button
                style={{ cursor: "pointer" }}
                value="asap"
                text="Walk-ups order 'ASAP' and ignore Pickup Windows"
                onClick={() => setOnDemandPickupWindows(false)}
              />
              <Radio.Button
                style={{ cursor: "pointer" }}
                value="windows"
                text="Walk-ups choose a pickup time from the Pickup Windows I set"
                onClick={() => setOnDemandPickupWindows(true)}
              />
            </Radio>
          </div>
          <LabeledSwitch
            style={{ marginTop: "16px" }}
            Switch={
              <LabeledSwitch.Switch
                checked={mergedEvent.hideOrdersOpenTime}
                onChange={toggleHideOrdersOpenTime}
              />
            }
            title="Hide open time"
            tooltip={
              <ToolTip
                text={`Want to keep people guessing? Turn this on and customers will see the day the event will drop, but not the exact time.`}
              />
            }
          />
          <LabeledSwitch
            style={{ marginTop: "16px" }}
            Switch={
              <LabeledSwitch.Switch
                disabled={mergedEvent.private}
                checked={mergedEvent.private || mergedEvent.isEventRemindersDisabled}
                onChange={toggleDisableEventReminders}
              />
            }
            title="Disable event reminders"
            tooltip={
              <ToolTip
                text={`Prevent signed up customers from getting the standard SMS notification when this event drops`}
              />
            }
          />
          <LabeledSwitch
            style={{ marginTop: "16px" }}
            Switch={
              <LabeledSwitch.Switch
                disabled={true}
                checked={false}
                // checked={mergedEvent.isHypeModeEnabled}
                // onChange={toggleHypeMode}
              />
            }
            title="Hype mode"
            tooltip={
              <ToolTip
                text={`Until the event is live, hide the menu (overriding the "Hide menu until orders open" setting), replacing it with dark styling and a live public chat. Hype mode has no effect once the event is already live.`}
              />
            }
          />
        </Column>
      }
      SaveButton={
        <EventBasicInfo.SaveButton
          loading={saveEventLoading || publishEventLoading}
          disabled={mergedEvent.title === "" || saveEventLoading || publishEventLoading}
          loadingAnimation={
            <ReactLoading type={"bubbles"} color={"#FFFFFF"} height={50} width={50} />
          }
          kind={getEventStatus(mergedEvent) === "draft" ? "primary" : "good"}
          text={getEventStatus(mergedEvent) === "draft" ? "Save & Continue" : "Publish All Changes"}
          onClick={() => saveAndContinue()}
        />
      }
      AdvancedOptionsButton={
        <EventBasicInfo.AdvancedOptionsButton
          onClick={() => {
            setShowAdvancedSettingsModal(true);
            trackAdvancedSettingsClicked({
              eventId: mergedEvent.id,
              eventStatus: getEventStatus(mergedEvent),
            });
          }}
        />
      }
    />
  );
}
