// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./EditOptionCategory.OptionsContainer.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      "7jsLpYQGbV": {
        none: {
          text: "Option Name",
        },
      },
      "2tbdB792tp": {
        none: {
          text: "Price (+/-)",
        },
      },
      H6oit8jUdG: {
        none: {
          text: "Qty",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "showQuantity",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "showQuantity",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "showQuantity",
  },
];

export const OptionsContainerContext = createContext(null);

function OptionsContainer(_props) {
  const defaults = useContext(OptionsContainerContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="3VuDVF95mk"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "3VuDVF95mk",
        scope: "AyN3dnpzGA",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Container_MbvD6DDzk9"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_AyN3dnpzGA_MbvD6DDzk9"
            }
            key={"MbvD6DDzk9"}
            addSpacing={false}
            element={getStyle("MbvD6DDzk9", "htmlElement")}
          >
            <TextPrimitive
              id={"Text_7jsLpYQGbV"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_AyN3dnpzGA_7jsLpYQGbV"
              }
              key={"7jsLpYQGbV"}
              text={getStyle("7jsLpYQGbV", "text")}
              element={getStyle("7jsLpYQGbV", "htmlElement")}
            />
            <SpacerPrimitive
              id={"Spacer_CyKcydJvKf"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_AyN3dnpzGA_CyKcydJvKf"
              }
              key={"CyKcydJvKf"}
            />
            <TextPrimitive
              id={"Text_2tbdB792tp"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_AyN3dnpzGA_2tbdB792tp"
              }
              key={"2tbdB792tp"}
              text={getStyle("2tbdB792tp", "text")}
              element={getStyle("2tbdB792tp", "htmlElement")}
            />
            <SpacerPrimitive
              id={"Spacer_RQLFRSjyYz"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_AyN3dnpzGA_RQLFRSjyYz"
              }
              key={"RQLFRSjyYz"}
            />
            <TextPrimitive
              id={"Text_H6oit8jUdG"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_AyN3dnpzGA_H6oit8jUdG"
              }
              key={"H6oit8jUdG"}
              text={getStyle("H6oit8jUdG", "text")}
              element={getStyle("H6oit8jUdG", "htmlElement")}
            />
            <SpacerPrimitive
              id={"Spacer_WzkoTcHxJG"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_AyN3dnpzGA_WzkoTcHxJG"
              }
              key={"WzkoTcHxJG"}
            />
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_TQwuYhEbKN"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_AyN3dnpzGA_TQwuYhEbKN"
            }
            key={"TQwuYhEbKN"}
          />
          <ContainerPrimitive
            key={"7PeFBxNsUJ"}
            id={"Slot_7PeFBxNsUJ"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_AyN3dnpzGA_7PeFBxNsUJ"
            }
            addSpacing={true}
          >
            {props.children}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

OptionsContainer.__variants = [
  {
    name: "showQuantity",
    type: "variant",
  },
];

export default OptionsContainer;
