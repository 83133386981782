// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./AddEditModal.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import {
  default as CancelButton,
  ButtonContext as CancelButtonContext,
  default as SaveButton,
  ButtonContext as SaveButtonContext,
  default as DeleteButton,
  ButtonContext as DeleteButtonContext,
} from "../Primitives/Button";
import { default as Header } from "./AddEditModal.Header";

const styles = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "editing",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
  {
    type: "boolean",
    propName: "noTitle",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      FMMJiPNrGU: {
        none: {
          text: "Discard",
          large: false,
          kind: "quiet",
        },
      },
      KNNyQX1koy: {
        none: {
          text: "Save",
          withText: false,
          large: false,
          kind: "primary",
        },
      },
      GQaJ4SPhwU: {
        none: {
          text: "Delete",
          kind: "warning",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "editing",
    layers: {
      FMMJiPNrGU: {},
      KNNyQX1koy: {},
      GQaJ4SPhwU: {},
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {
          FMMJiPNrGU: {},
          KNNyQX1koy: {},
          GQaJ4SPhwU: {},
        },
      },
      {
        propValue: "small",
        layers: {
          FMMJiPNrGU: {},
          KNNyQX1koy: {},
          GQaJ4SPhwU: {},
        },
      },
      {
        propValue: "med",
        layers: {
          FMMJiPNrGU: {},
          KNNyQX1koy: {},
          GQaJ4SPhwU: {},
        },
      },
      {
        propValue: "large",
        layers: {
          FMMJiPNrGU: {},
          KNNyQX1koy: {},
          GQaJ4SPhwU: {},
        },
      },
      {
        propValue: "xlarge",
        layers: {
          FMMJiPNrGU: {},
          KNNyQX1koy: {},
          GQaJ4SPhwU: {},
        },
      },
    ],
  },
  {
    type: "boolean",
    propName: "noTitle",
    layers: {
      FMMJiPNrGU: {},
      KNNyQX1koy: {},
      GQaJ4SPhwU: {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "editing",
  },
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
  {
    type: "boolean",
    propName: "noTitle",
  },
];

export const AddEditModalContext = createContext(null);

function AddEditModal(_props) {
  const defaults = useContext(AddEditModalContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="JrXum9riXs"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "JrXum9riXs",
        scope: "VB3RchczUr",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"title-container_NwUnwmzwyM"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_VB3RchczUr_NwUnwmzwyM"
            }
            key={"NwUnwmzwyM"}
            addSpacing={false}
            element={getStyle("NwUnwmzwyM", "htmlElement")}
          >
            {props.Header === undefined ? (
              <Header key={"VquyyNv6g1"} />
            ) : (
              props.Header
            )}
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"scrollbox_9jwMDFXZFA"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_VB3RchczUr_9jwMDFXZFA"
            }
            key={"9jwMDFXZFA"}
            addSpacing={true}
            element={getStyle("9jwMDFXZFA", "htmlElement")}
          >
            <ContainerPrimitive
              key={"tstNZPvv5G"}
              id={"Slot_tstNZPvv5G"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_VB3RchczUr_tstNZPvv5G"
              }
              addSpacing={false}
            >
              {props.children}
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"delete-container_MP677KzQFj"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_VB3RchczUr_MP677KzQFj"
              }
              key={"MP677KzQFj"}
              addSpacing={true}
              element={getStyle("MP677KzQFj", "htmlElement")}
            >
              {[
                props.DeleteButton === undefined ? (
                  <DeleteButton
                    key={"GQaJ4SPhwU"}
                    {...getCompositeDefaultProps("GQaJ4SPhwU")}
                    id="DeleteButton_GQaJ4SPhwU"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_VB3RchczUr_GQaJ4SPhwU"
                  />
                ) : (
                  <DeleteButtonContext.Provider
                    key="GQaJ4SPhwU-provider"
                    value={{
                      key: "GQaJ4SPhwU",
                      id: "DeleteButton_GQaJ4SPhwU",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_VB3RchczUr_GQaJ4SPhwU",
                      ...getCompositeDefaultProps("GQaJ4SPhwU"),
                    }}
                  >
                    {props.DeleteButton}
                  </DeleteButtonContext.Provider>
                ),
                <ContainerPrimitive
                  key={"DMmTdsJdke"}
                  id={"Slot_DMmTdsJdke"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_VB3RchczUr_DMmTdsJdke"
                  }
                  addSpacing={false}
                >
                  {props.SecondaryDeleteButton}
                </ContainerPrimitive>,
              ]}
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"save-cancel-container_BQMkMQAv6N"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_VB3RchczUr_BQMkMQAv6N"
              }
              key={"BQMkMQAv6N"}
              addSpacing={false}
              element={getStyle("BQMkMQAv6N", "htmlElement")}
            >
              {[
                props.CancelButton === undefined ? (
                  <CancelButton
                    key={"FMMJiPNrGU"}
                    {...getCompositeDefaultProps("FMMJiPNrGU")}
                    id="CancelButton_FMMJiPNrGU"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_VB3RchczUr_FMMJiPNrGU"
                  />
                ) : (
                  <CancelButtonContext.Provider
                    key="FMMJiPNrGU-provider"
                    value={{
                      key: "FMMJiPNrGU",
                      id: "CancelButton_FMMJiPNrGU",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_VB3RchczUr_FMMJiPNrGU",
                      ...getCompositeDefaultProps("FMMJiPNrGU"),
                    }}
                  >
                    {props.CancelButton}
                  </CancelButtonContext.Provider>
                ),
                <SpacerPrimitive
                  id={"Spacer_SmJ1L2C5Cx"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_VB3RchczUr_SmJ1L2C5Cx"
                  }
                  key={"SmJ1L2C5Cx"}
                />,
                props.SaveButton === undefined ? (
                  <SaveButton
                    key={"KNNyQX1koy"}
                    {...getCompositeDefaultProps("KNNyQX1koy")}
                    id="SaveButton_KNNyQX1koy"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_VB3RchczUr_KNNyQX1koy"
                  />
                ) : (
                  <SaveButtonContext.Provider
                    key="KNNyQX1koy-provider"
                    value={{
                      key: "KNNyQX1koy",
                      id: "SaveButton_KNNyQX1koy",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_VB3RchczUr_KNNyQX1koy",
                      ...getCompositeDefaultProps("KNNyQX1koy"),
                    }}
                  >
                    {props.SaveButton}
                  </SaveButtonContext.Provider>
                ),
              ]}
            </ContainerPrimitive>
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

AddEditModal.CancelButton = CancelButton;
AddEditModal.SaveButton = SaveButton;
AddEditModal.DeleteButton = DeleteButton;
AddEditModal.__variants = [
  {
    name: "editing",
    type: "variant",
  },
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
  {
    name: "noTitle",
    type: "variant",
  },
];

export default AddEditModal;
