// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./CustomerMenuItem.NextAvailable.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      C4C3oY2oJ6: {
        none: {
          htmlElement: "section",
        },
      },
      UT4Gnvm5Kg: {
        none: {
          text: "Next Available Pickup: Fri, Mar 28th • 3:00pm",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const NextAvailableContext = createContext(null);

function NextAvailable(_props) {
  const defaults = useContext(NextAvailableContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="9fxZfKwgfg"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "9fxZfKwgfg",
        scope: "PggF92o1Qk",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_C4C3oY2oJ6"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_PggF92o1Qk_C4C3oY2oJ6"
          }
          key={"C4C3oY2oJ6"}
          addSpacing={false}
          element={getStyle("C4C3oY2oJ6", "htmlElement")}
        >
          {
            <TextPrimitive
              id={"Text_UT4Gnvm5Kg"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_PggF92o1Qk_UT4Gnvm5Kg"
              }
              key={"UT4Gnvm5Kg"}
              text={
                exists(props.text) ? props.text : getStyle("UT4Gnvm5Kg", "text")
              }
              element={getStyle("UT4Gnvm5Kg", "htmlElement")}
            />
          }
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

NextAvailable.__variants = [];

export default NextAvailable;
