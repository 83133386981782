import { chef_id } from "../../analytics";

/**
 * General
 */
// TODO VISUALIZE
export function trackEventConfigNavClicked({ eventId, tab }, ...obj) {
  window.analytics.track("Event Config Nav Clicked", {
    chef_id,
    event_id: eventId,
    tab: tab,
    ...obj,
  });
}

/**
 * Basic Info Logs
 */

export function trackEventImageUpdated({ eventId, eventStatus, image }) {
  window.analytics.track("Event Image Added", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    image,
  });
}

// ! not used, event image not currently removable
export function trackEventImageRemoved({ eventStatus, eventId }) {
  window.analytics.track("Event Image Removed", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
  });
}

export function trackEventDescriptionUpdated({ eventId, eventStatus, description }) {
  window.analytics.track("Event Description Updated", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    description,
  });
}

export function trackEventOpenTimeUpdated({ eventId, eventStatus, openTime }) {
  window.analytics.track("Event Open Time Updated", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    open_time: openTime,
  });
}

export function trackEventCloseTimeUpdated({ eventId, eventStatus, type }) {
  window.analytics.track("Event Close Time Updated", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    type: type,
  });
}

export function trackEventWalkupOrderingToggled({ eventId, eventStatus, enabled }) {
  window.analytics.track("Walkup Ordering Toggled", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    enabled: enabled,
  });
}

export function trackWalkupOrderingTypeUpdated({ eventId, eventStatus, type }) {
  window.analytics.track("Walkup Ordering Type Updated", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    type: type,
  });
}

export function trackHideOpenTimeToggled({ eventId, eventStatus, enabled }) {
  window.analytics.track("Hide Open Time Toggled", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    enabled: enabled,
  });
}

export function trackDisableRemindersToggled({ eventId, eventStatus, enabled }) {
  window.analytics.track("Disable Reminders Toggled", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,

    enabled: enabled,
  });
}

export function trackAdvancedSettingsClicked({ eventId, eventStatus }) {
  window.analytics.track("Event Advanced Settings Clicked", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
  });
}

export function trackEventPrivateToggled({ eventId, eventStatus, enabled }) {
  window.analytics.track("Event Privacy Toggled", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,

    enabled: enabled,
  });
}

export function trackCheckoutTimeUpdated({ eventId, eventStatus, checkoutTime }) {
  window.analytics.track("Checkout Time Updated", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    checkout_mins: checkoutTime,
  });
}

/**
 * Pickup Windows
 */

export function trackPickupWindowCadenceUpdated({ eventId, eventStatus, cadence }) {
  window.analytics.track("Pickup Window Cadence Updated", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    cadence: cadence,
  });
}

export function trackAddTimeWindowClicked({ eventId, eventStatus }) {
  window.analytics.track("Event Add Time Window Clicked", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
  });
}

export function trackLocationCreated({ id }) {
  window.analytics.track("Location Created", {
    chef_id,
    id: id,
  });
}

export function trackLocationUpdated({ locationDiff }) {
  window.analytics.track("Location Edited", {
    chef_id,
    location_diff: locationDiff,
  });
}
// TODO visualize
export function trackTimeWindowCreated({ eventId, eventStatus }) {
  window.analytics.track("Time Window Created", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
  });
}
// TODO visualize
export function trackTimeWindowUpdated({ eventId, eventStatus }) {
  window.analytics.track("Time Window Updated", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
  });
}

/**
 * Menu
 */

export function trackMenuSectionAdded({ eventId, eventStatus }) {
  window.analytics.track("Menu Section Added", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
  });
}

export function trackMaxCustomersPerPickupTimeUpdated({ eventId, eventStatus, value }) {
  window.analytics.track("Max Customers Per Pickup Time Updated", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    max_customers_per_pickup_time: value,
  });
}

export function trackShowInventoryToggled({ eventId, eventStatus, enabled }) {
  window.analytics.track("Show Inventory Toggled", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,

    enabled: enabled,
  });
}

export function trackHideMenuToggled({ eventId, eventStatus, enabled }) {
  window.analytics.track("Hide Menu Toggled", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    enabled: enabled,
  });
}

/**
 * Menu Item
 */

export function trackItemAddedToEvent({ eventId, eventStatus, numItems }) {
  window.analytics.track("Items Added to Event", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    num_items: numItems,
  });
}

export function trackAdditionalMenuItemImageAdded() {
  window.analytics.track("Additional Menu Item Image Added", {
    chef_id,
  });
}

// TODO ADD TO CODE
export function trackItemCreated({ item }) {
  window.analytics.track("Item Created", {
    chef_id,
    item,
  });
}

export function trackItemInventoryUpdated({ eventId, eventStatus, itemId, inventory }) {
  window.analytics.track("Item Inventory Updated", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    item_id: itemId,
    inventory: inventory,
  });
}

// TODO ADD LOGS FOR LIVE INVENTORY UPDATE

export function trackItemMaxPerOrderUpdated({ eventId, eventStatus, itemId, maxPerOrder }) {
  window.analytics.track("Item Max Per Order Updated", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    item_id: itemId,
    max_per_order: maxPerOrder,
  });
}

export function trackItemMaxPerPickupUpdated({ eventId, eventStatus, itemId, maxPerPickup, type }) {
  window.analytics.track("Item Max Per Pickup Updated", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    item_id: itemId,
    max_per_pickup: maxPerPickup,
    type: type,
  });
}

export function trackItemRemovedFromEvent({ eventId, eventStatus, itemId }) {
  window.analytics.track("Item Removed From Event", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    item_id: itemId,
  });
}

export function trackItemDeleted({ eventId, eventStatus, itemId }) {
  window.analytics.track("Item Deleted", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    item_id: itemId,
  });
}

export function trackOptionGroupCreated({ eventId, eventStatus, itemId, optionGroup }) {
  window.analytics.track("Option Group Created", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    item_id: itemId,
    option_group: optionGroup,
  });
}

export function trackOptionGroupUpdated({ eventId, eventStatus, itemId, optionGroup }) {
  window.analytics.track("Option Group Updated", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    item_id: itemId,
    option_group: optionGroup,
  });
}

export function trackOptionInventoryUpdated({ eventId, eventStatus, inventory }) {
  window.analytics.track("Option Inventory Updated", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    inventory: inventory,
  });
}

export function trackItemNotesToggled({ eventId, eventStatus, itemId, enabled }) {
  window.analytics.track("Item Notes Toggled", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    item_id: itemId,
    enabled: enabled,
  });
}

export function trackItemNotesRequiredToggled({ eventId, eventStatus, itemId, enabled }) {
  window.analytics.track("Require Item Notes Toggled", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    item_id: itemId,
    enabled: enabled,
  });
}

/**
 * Share
 */

export function trackEventQRCodeDownloaded({ eventId, eventStatus }) {
  window.analytics.track("Event QR Code Downloaded", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
  });
}

export function trackEventLinkCopied({ eventId, eventStatus }) {
  window.analytics.track("Event Link Copied", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
  });
}

export function trackEventPublished({ eventId, eventStatus }) {
  window.analytics.track("Event Published", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
  });
}

export function trackEventUnpublished({ eventId, eventStatus }) {
  window.analytics.track("Event Unpublished", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
  });
}

/**
 * Stats
 */

export function trackEventStatsBreakdownUpdated({ eventId, eventStatus, breakdown }) {
  window.analytics.track("Event Stats Breakdown Updated", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
    breakdown: breakdown,
  });
}

export function trackEventOrdersExported({ eventId, eventStatus }) {
  window.analytics.track("Event Orders Exported", {
    chef_id,
    event_id: eventId,
    event_status: eventStatus,
  });
}
