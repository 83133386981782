// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./DropdownMenu.Item.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { TextPrimitive } from "../_internal_primitives";
import { SelectOptionRoot } from "../_internal_select";

const styles = [
  {
    type: "default",
    layers: {
      Ss78z8ijiH: {
        none: {
          text: "Option",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "selected",
  },
];

export const ItemContext = createContext(null);

function Item(_props) {
  const defaults = useContext(ItemContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <SelectOptionRoot
      {...props}
      key="select-option-root"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "select-option-root",
        scope: "KP6DVtP5xC",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <TextPrimitive
          id={"text_Ss78z8ijiH"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_KP6DVtP5xC_Ss78z8ijiH"
          }
          key={"Ss78z8ijiH"}
          text={
            exists(props.label) ? props.label : getStyle("Ss78z8ijiH", "text")
          }
          element={getStyle("Ss78z8ijiH", "htmlElement")}
        />
      )}
    </SelectOptionRoot>
  );
}

Item.__variants = [
  {
    name: "selected",
    type: "variant",
  },
];

export default Item;
