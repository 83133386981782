import { EventStatus, MenuItemStatus } from "./types";

export function getMenuItemStatusDescription(
  menuItemStatus: MenuItemStatus,
  eventStatus: EventStatus
): string | null {
  if (eventStatus === "scheduled" || eventStatus === "draft") {
    return "This item is not available yet because sales have not begun";
  }
  if (eventStatus === "complete") {
    return "This item is not available because sales have ended";
  }
  switch (menuItemStatus) {
    case "no_time_available":
      return "There is inventory remaining, but all available time slots are taken";
    case "reserved":
      return "All the available inventory is in carts, but more may soon become available!";
    case "need_delivery_address":
      return "You need to add a delivery address to purchase this item";
    case "delivery_out_of_range":
      return "Your address is out of range for delivery";
    case "inactive_event":
      return "This item is not available because this sale is not accepting orders.";
    case "sold_out":
      return null;
    default:
      return null;
  }
}
