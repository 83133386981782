// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./WithdrawButton.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import { ButtonRoot } from "../_internal_button";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      "4Wr6D18jHL": {
        none: {
          text: "Initiate Withrawl",
        },
      },
      Qqx8yypqoZ: {
        none: {
          icon: icons.padlock,
          useMask: true,
        },
      },
    },
  },
  {
    type: "enum",
    propName: "kind",
    values: [
      {
        propValue: "secondary",
        layers: {
          "4Wr6D18jHL": {
            none: {
              text: "Cancel",
            },
          },
        },
      },
    ],
  },
  {
    type: "boolean",
    propName: "loading",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "kind",
    values: [
      {
        propValue: "secondary",
        layers: {},
      },
    ],
  },
  {
    type: "boolean",
    propName: "loading",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "kind",
    propValues: ["secondary"],
  },
  {
    type: "boolean",
    propName: "loading",
  },
];

export const WithdrawButtonContext = createContext(null);

function WithdrawButton(_props) {
  const defaults = useContext(WithdrawButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <ButtonRoot
      {...props}
      key="root"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "root",
        scope: "TuE9ux8Du4",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Container_UA81hMbA7a"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_TuE9ux8Du4_UA81hMbA7a"
            }
            key={"UA81hMbA7a"}
            addSpacing={false}
            element={getStyle("UA81hMbA7a", "htmlElement")}
          >
            {
              <ContainerPrimitive
                key={"P8iY8Q8hPW"}
                id={"Slot_P8iY8Q8hPW"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_TuE9ux8Du4_P8iY8Q8hPW"
                }
                addSpacing={false}
              >
                {props.loadingAnimation}
              </ContainerPrimitive>
            }
          </ContainerPrimitive>
          <TextPrimitive
            id={"text_4Wr6D18jHL"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_TuE9ux8Du4_4Wr6D18jHL"
            }
            key={"4Wr6D18jHL"}
            text={
              exists(props.text) ? props.text : getStyle("4Wr6D18jHL", "text")
            }
            element={getStyle("4Wr6D18jHL", "htmlElement")}
          />
          <ContainerPrimitive
            id={"Container_BHaqrLG4wm"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_TuE9ux8Du4_BHaqrLG4wm"
            }
            key={"BHaqrLG4wm"}
            addSpacing={false}
            element={getStyle("BHaqrLG4wm", "htmlElement")}
          >
            {
              <IconPrimitive
                id={"Icon_Qqx8yypqoZ"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_TuE9ux8Du4_Qqx8yypqoZ"
                }
                key={"Qqx8yypqoZ"}
                useMask={getStyle("Qqx8yypqoZ", "useMask")}
                src={
                  exists(props.icon)
                    ? props.icon
                    : getStyle("Qqx8yypqoZ", "icon")
                }
              />
            }
          </ContainerPrimitive>
        </>
      )}
    </ButtonRoot>
  );
}

WithdrawButton.__variants = [
  {
    name: "kind",
    type: "group",
    variants: ["secondary"],
  },
  {
    name: "loading",
    type: "variant",
  },
];

export default WithdrawButton;
