/* eslint-disable react/prop-types */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { FirebaseContext } from "../../firebaseSocket";
import { scHOC } from "../../hotplate-common/scHOC";
import ReactLoading from "react-loading";
import PrepSection from "./PrepSection";
import PackingSection from "./PackingSection";

import { ComplexFilter } from "../../hotplate-common/ComplexFilter";
import { styled } from "../../stitches.config";
import { Button } from "../../hotplate-common/primitives/Button";

import {
  startPortalLoad,
  endPortalLoad,
  clearPortalLoad,
} from "../../hotplate-common/HelperFunctions";

import { setOrderManagementAction, uploadCsv } from "../../hotplate-storefront/actions";
import { ManagementDropdown } from "./components/ManagementDropdown";
import { ManagementSearchbar } from "./components/ManagementSearchbar";
import { setPackingFilter, setPackingView } from "../../hotplate-storefront/actions";
import { Flex, Span } from "../../hotplate-common/primitives/Containers";
import { LineHeightIcon } from "@radix-ui/react-icons";
import { SortPopover } from "./components/SortPopover";
import { Popover } from "../../hotplate-common/primitives/Popover";
import { FilterButton } from "../../hotplate-common/primitives/FilterButton";
import { PackingSelectView } from "./components/PackingSelectView";
import { ManagementNav } from "./components/ManagementNav";
import { CompletedOrdersFilter } from "./components/CompletedOrdersFilter";
import { Label } from "../../hotplate-common/primitives/Label";
import { shouldRetry, trpc } from "../../trpc";
import { trackOrdersSearched } from "./analytics";
import { usePortalUser } from "../../auth";

const OrderManagementContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  maxWidth: "100%",
  paddingTop: "$sizes$lg_btn",
});

const ManagementHeader = styled("div", {
  display: "flex",
  justifyContent: "center",
  width: "100%",
  position: "fixed",
  top: 0,
  zIndex: "$fixed",
  backgroundColor: "$white",
  "@desktop_sm": { top: "$sizes$navHeight" },
  borderBottom: "1px solid $accent6",
  alignItems: "center",
  hideOnPrint: true,
});

const ManagementHeaderContent = styled("div", {
  display: "flex",
  width: "100%",
  maxWidth: 1440,
  padx: 16,
  alignItems: "center",
  "@desktop_sm": {
    padx: 32,
  },
});

const HeaderSection = styled("div", {
  display: "flex",
  justifyContent: "center",
  flexGrow: 1,
  minWidth: "min-content",
  "&:first-child": {
    justifyContent: "flex-start",
  },
  "&:last-child": {
    justifyContent: "flex-end",
  },
  "@desktop_sm": {
    flexBasis: 0,
  },
});

const ControlsBox = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  pady: 12,
  width: "100%",
  minWidth: 0,
  hideOnPrint: true,
});

const ControlsContent = styled("div", {
  display: "flex",
  alignItems: "flex-start",
  width: "100%",
  minWidth: 0,
  maxWidth: 1440,
  padx: 8,
  "@desktop_sm": {
    padx: 32,
    marginLeft: "auto",
    marginRight: "auto",
  },
});

class OrderManagement extends PureComponent {
  static contextType = FirebaseContext;
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      filteredOrders: {},
      filteredOrders_packing: [],
      filteredOrdersInProgressCount: 0,
      filteredOrdersPackedCount: 0,

      packingTableColumns: [
        {
          id: "orderNumber",
          displayName: "Order #",
          visible: true,
        },
        {
          id: "customerName",
          displayName: "Name",
          visible: true,
        },
        { id: "customerEmail", displayName: "Email", visible: false },
        {
          id: "customerPhone",
          displayName: "Phone",
          visible: false,
        },
        {
          id: "orderItems",
          displayName: "Items",
          visible: true,
        },
        {
          id: "fulfillmentDate",
          displayName: "Date",
          visible: true,
        },
        {
          id: "fulfillmentTime",
          displayName: "Time",
          visible: true,
        },
        {
          id: "fulfillmentType",
          displayName: "Type",
          visible: false,
        },
        {
          id: "address",
          displayName: "Address",
          visible: false,
        },
      ],

      isPrinting: false,
      isChefCreatingNewOrder: false,
    };

    this.setSearchText = this.setSearchText.bind(this);
    this.setFilteredOrders = this.setFilteredOrders.bind(this);
    this.setIsChefCreatingNewOrder = this.setIsChefCreatingNewOrder.bind(this);

    this.connectToOrdersHandler = null;
  }

  componentDidMount() {
    // Used for checking if the Firebase app was reset
    this.prevDb = this.context.database;
    this.connectToOrders();
  }

  connectToOrders() {
    const { hostId } = this.props;
    if (this.connectToOrdersHandler)
      this.context.api.disconnectFromOrders(hostId, this.connectToOrdersHandler);
    this.connectToOrdersHandler = this.context.api.connectToOrders(
      hostId,
      this.getOrderFilterProps()
    );
  }

  getOrderFilterProps(propsOverride) {
    const { activeFilters } = propsOverride || this.props;

    let min = Number.MAX_SAFE_INTEGER;
    let max = Number.MIN_SAFE_INTEGER;
    for (const filter of activeFilters) {
      if (filter.isDateFilter === "fulfillment") {
        min = Math.min(
          min,
          filter.selectedConjuction === "is between"
            ? filter.selectedDays[0]
            : filter.selectedDate.getTime()
        );
        max = Math.max(
          max,
          filter.selectedConjuction === "is between"
            ? filter.selectedDays[1]
            : filter.selectedConjuction === "is after"
            ? Number.MAX_SAFE_INTEGER
            : filter.selectedDate.getTime() + 1000 * 60 * 60 * 24
        );
      }
    }
    if (min === Number.MAX_SAFE_INTEGER) {
      min = undefined;
    }
    if (max === Number.MIN_SAFE_INTEGER) {
      max = undefined;
    }
    return { min, max };
  }

  componentWillUnmount() {
    const { hostId } = this.props;
    this.context.api.disconnectFromOrders(hostId, this.connectToOrdersHandler);
    clearPortalLoad();
  }

  componentDidUpdate(prevProps, prevState) {
    const { connectToOrdersLoading, packingFilter, setPackingFilter, prevPackingFilter } =
      this.props;
    const { searchText, isPrinting } = this.state;

    if (this.prevDb !== this.context.database) {
      this.prevDb = this.context.database;
      this.connectToOrders();
    }

    if (!_.isEqual(this.getOrderFilterProps(prevProps), this.getOrderFilterProps())) {
      this.connectToOrders();
    }

    if (prevProps.activeFilters)
      if (!prevProps.connectToOrdersLoading && connectToOrdersLoading) {
        startPortalLoad();
      }

    if (prevProps.connectToOrdersLoading && !connectToOrdersLoading) {
      endPortalLoad();
    }

    if (prevProps.orders !== this.props.orders) {
      this.setFilteredOrders();
    }

    if (prevState.searchText !== searchText) {
      this.setFilteredOrders();
      if (searchText === "") {
        setPackingFilter(prevPackingFilter);
      } else if (packingFilter !== "ALL") {
        setPackingFilter("ALL");
      }
    }

    if (prevProps.sort !== this.props.sort) {
      this.setFilteredOrdersForPacking();
    }

    if (prevProps.completedOrdersDisplay !== this.props.completedOrdersDisplay) {
      this.setFilteredOrdersForPacking();
    }

    if (prevProps.packingView !== this.props.packingView) {
      this.setFilteredOrdersForPacking();
    }

    if (!prevState.isPrinting && isPrinting) {
      window.print();
      this.setIsPrinting(false);
    }
  }

  uploadCsvStart(data) {
    let reader = new FileReader();
    reader.readAsText(data);
    reader.onload = (e) => {
      const csv = e.target.result;
      this.props.uploadCsv(this.props.hostId, csv);
    };
    reader.onerror = (e) => {
      console.log("error", e);
    };
  }

  getOrderTypes() {
    const { orders } = this.props;
    const orderTypesSet = new Set();
    Object.keys(orders).forEach((key) => {
      orderTypesSet.add(orders[key].cartType);
      if (orders[key].isSubscription) orderTypesSet.add("subscription");
    });
    const availableOrderTypes = [
      "delivery",
      "catering",
      "wholesale",
      "shipping",
      "pickup",
      "subscription",
    ];
    return Array.from(orderTypesSet)
      .filter((type) => {
        return availableOrderTypes.includes(type);
      })
      .sort();
  }

  getLocationOptions() {
    const { orders } = this.props;
    const locationOptionsSet = new Set();
    Object.keys(orders).forEach((key) => {
      const order = orders[key];
      if (order && order.location) {
        locationOptionsSet.add(orders[key].location.title);
      }
    });
    return Array.from(locationOptionsSet).sort();
  }

  setIsChefCreatingNewOrder(val) {
    this.setState({ isChefCreatingNewOrder: val });
  }

  setFilteredOrdersForPacking() {
    let { packingFilter, hostId, memberships, completedOrdersDisplay } = this.props;
    const { filteredOrders } = this.state;

    let filteredOrders_packing = [];

    for (const orderKey of Object.keys(filteredOrders)) {
      const order = filteredOrders[orderKey];

      if (completedOrdersDisplay === "hidden") {
        // if completed orders are hidden, change visible orders based upon which filter Chef has selected
        if (packingFilter === "DONE" && !order.packed) continue;
        if (packingFilter === "NOT_DONE" && order.packed) continue;
      }

      if (hostId === "dumplingclub" && order.phone in memberships) {
        const member = memberships[order.phone];
        if (member.subscriptionInfo && member.subscriptionInfo.subscriptions)
          order.isSubscriber = true;
      }

      filteredOrders_packing.push(order);
    }

    this.sortOrders(filteredOrders_packing);

    this.setState({
      filteredOrders_packing: filteredOrders_packing,
    });
  }

  setFilteredOrders(ordersProp) {
    const { searchText } = this.state;
    const { hostId } = this.props;
    const orders = ordersProp || this.props.orders;
    const filteredOrders = {};
    let filteredOrdersPackedCount = 0;
    let filteredOrdersInProgressCount = 0;

    for (const orderKey of Object.keys(orders)) {
      const order = orders[orderKey];
      // if the order has no siteSettings, the chefId doesn't match the hostId, or has been fully restocked, remove it.
      if (order.isHidden) continue;
      if (!order.siteSettings || order.siteSettings.chefId !== hostId) continue;
      if (!Array.isArray(order.cartItems)) continue;
      let fullyRestocked = true;
      for (let i = 0; i < order.cartItems.length; i++) {
        const cartItem = order.cartItems[i];
        if (
          !Number.isInteger(cartItem.restockQuantity) ||
          cartItem.restockQuantity < cartItem.quantity
        )
          fullyRestocked = false;
      }
      if (fullyRestocked) continue;
      // if there is a search being done and nothing in the order matches it, remove it.
      if (searchText !== "") {
        const date = new Date(order.timeSlot.startTime);
        const fields = [
          order.orderId,
          order.fullName,
          order.email,
          order.phone,
          ...order.cartItems.map((cartItem) => cartItem.title),
          date.toLocaleString("en-US", { dateStyle: "full", timeStyle: "long" }),
          date.toLocaleString("en-US", { dateStyle: "short", timeStyle: "short" }),
          order.cartType,
          order.isSubscription ? "subscription" : "",
        ];
        const match = fields
          .map((s) => (s || "").toLowerCase().replace(/\s/g, ""))
          .join("\n")
          .includes(searchText.toLowerCase().replace(/\s/g, ""));
        if (!match) {
          continue;
        }
      }

      if (order.packed) {
        filteredOrdersPackedCount += 1;
      } else {
        filteredOrdersInProgressCount += 1;
      }
      filteredOrders[orderKey] = order;
    }
    this.setState(
      {
        filteredOrders: filteredOrders,
        filteredOrdersPackedCount,
        filteredOrdersInProgressCount,
      },
      () => this.setFilteredOrdersForPacking()
    );
  }

  sortOrders(filteredOrders) {
    const { sort, completedOrdersDisplay } = this.props;

    filteredOrders.sort((a, b) => {
      if (a.pinned && !b.pinned) return -1; // pinned orders always come first
      if (!a.pinned && b.pinned) return 1;
      if (completedOrdersDisplay === "end") {
        // if completed orders are to be sorted to the end, then do that first
        if (a.packed && !b.packed) return 1;
        if (!a.packed && b.packed) return -1;
      }
      if (sort.primary.type === "timeSlot") {
        if (sort.primary.direction === "asc") {
          if (!a.timeSlot || !b.timeSlot) return;
          if (a.timeSlot.startTime === b.timeSlot.startTime) {
            return a.orderId - b.orderId;
          }
          return a.timeSlot.startTime - b.timeSlot.startTime;
        } else {
          if (!a.timeSlot || !b.timeSlot) return;
          if (a.timeSlot.startTime === b.timeSlot.startTime) {
            return a.orderId - b.orderId;
          }
          return b.timeSlot.startTime - a.timeSlot.startTime;
        }
      }
      if (sort.primary.type === "orderNumber") {
        if (sort.primary.direction === "asc") {
          return a.orderId - b.orderId;
        } else {
          return b.orderId - a.orderId;
        }
      }
      if (sort.primary.type === "customerName") {
        if (sort.primary.direction === "asc") {
          return a.fullName.toLowerCase().trim().localeCompare(b.fullName.toLowerCase().trim());
        } else {
          return b.fullName.toLowerCase().trim().localeCompare(a.fullName.toLowerCase().trim());
        }
      }
    });
  }

  setSearchText(searchText) {
    this.setState({ searchText: searchText }, () => this.setFilteredOrders());
    trackOrdersSearched({ searchQuery: searchText });
  }

  setPackingTableColumns(columnId, bool) {
    // clone deep current list of visible columns
    const packingTableColumns_mutable = _.cloneDeep(this.state.packingTableColumns);
    // find correct object in array based on id
    const columnIndex = packingTableColumns_mutable.findIndex((column) => column.id === columnId);
    // set value to new value
    packingTableColumns_mutable[columnIndex].visible = bool;
    // set state
    this.setState({ packingTableColumns: packingTableColumns_mutable });
  }

  setIsPrinting(bool) {
    this.setState({ isPrinting: bool });
  }

  render() {
    const {
      filteredOrders,
      filteredOrders_packing,
      filteredOrdersInProgressCount,
      filteredOrdersPackedCount,

      packingTableColumns,

      isPrinting,
      isChefCreatingNewOrder,
    } = this.state;
    const {
      setOrderManagementAction,
      orderManagementAction,
      hostId,
      uploadCsvLoading,
      uploadCsvError,
      packingView,
      completedOrdersDisplay,
      orders,
      deleteAllOrders,
    } = this.props;

    const complexFilterProps = {
      data: orders,
      orderSearchText: this.state.searchText,
      setFilteredData: this.setFilteredOrders,
      filters: [
        {
          title: "Date Placed",
          isDateFilter: "placed",
          disabledConjuctions: ["is before", "is an exact time slot"],
          getObjectTimestamp: (order) => {
            return order.orderPlaced;
          },
        },
        {
          title: "Fulfillment Date",
          isDateFilter: "fulfillment",
          disabledConjuctions: ["is before", "is an exact time slot"],
          getObjectTimestamp: (order) => {
            return order.timeSlot.startTime;
          },
        },
        {
          title: "Order Type",
          disabledConjuctions: ["is both"],
          getObjectDataForFilter: (order) => {
            // function that returrns array
            const data = [];
            if (order.isSubscription) data.push("Subscription");
            else if (typeof order.eventId === "string" && order.eventId !== "") data.push("Event");
            return data;
          },
        },
        {
          title: "Fufillment Type",
          disabledConjuctions: ["is both"],
          getObjectDataForFilter: (order) => {
            const data = [];
            if (order.cartType === "pickup") data.push("Pickup"); // ! TODO VALIDATE
            if (order.cartType === "delivery") data.push("Delivery");
            if (order.cartType === "wholesale") data.push("Wholesale");
            if (order.cartType === "shipping") data.push("Shipping");
            return data;
          },
        },
        {
          title: "Item",
          conjuctionTextOverrides: {
            "is either": "contains either",
            "is both": "contains both",
            "is not": "does not contain",
            "is neither": "containers neither",
            is: "contains",
          },
          getObjectDataForFilter: (order) => {
            const data = [];
            for (let i = 0; i < order.cartItems.length; i++) {
              data.push(order.cartItems[i].title);
            }
            return data;
          },
        },
        {
          title: "Category",
          conjuctionTextOverrides: {
            "is either": "contains either",
            "is both": "contains both",
            "is not": "does not contain",
            "is neither": "containers neither",
            is: "contains",
          },
          getObjectDataForFilter: (order) => {
            const data = [];
            for (let i = 0; i < order.cartItems.length; i++) {
              data.push(...(order.cartItems[i].prepTags || []));
            }
            return data;
          },
        },
        {
          title: "Location",
          disabledConjuctions: ["is both"],
          getObjectDataForFilter: (order) => {
            // CHECK W DELIVERY
            return [order.location ? order.location.title : "no location"];
          },
        },
      ],
    };

    return (
      <OrderManagementContainer>
        <ManagementHeader>
          <ManagementHeaderContent>
            <HeaderSection>
              <ManagementNav
                selectedTab={orderManagementAction}
                setTab={setOrderManagementAction}
              />
            </HeaderSection>
            <HeaderSection>
              <ManagementSearchbar css={{ mx: "auto" }} setSearch={this.setSearchText} />
            </HeaderSection>
            <HeaderSection>
              <ManagementDropdown
                filteredOrders={
                  orderManagementAction === "PACKING"
                    ? filteredOrders_packing
                    : Object.values(filteredOrders)
                }
                hostId={hostId}
                orderManagementAction={orderManagementAction}
                setIsChefCreatingNewOrder={this.setIsChefCreatingNewOrder}
                packingView={packingView}
                onPrintRequest={() => {
                  this.setIsPrinting(true);
                }}
              />
            </HeaderSection>
          </ManagementHeaderContent>
        </ManagementHeader>
        <ControlsBox>
          <ControlsContent>
            <Flex css={{ flexWrap: "wrap", gap: "$xs" }}>
              {completedOrdersDisplay === "hidden" && orderManagementAction === "PACKING" && (
                <CompletedOrdersFilter
                  incompleteOrdersCount={filteredOrdersInProgressCount}
                  completeOrdersCount={filteredOrdersPackedCount}
                  setFilteredOrders={this.setFilteredOrders}
                />
              )}
              <ComplexFilter part="create" {...complexFilterProps} />
            </Flex>
            {orderManagementAction === "PACKING" && (
              <Flex
                css={{
                  marginLeft: "auto",
                  justifyContent: "flex-end",
                  flexWrap: "wrap",
                  gap: "$xs",
                }}
              >
                <SortPopover />
                {packingView === "LIST" && (
                  <Popover
                    trigger={
                      <FilterButton text="Edit Columns">
                        <LineHeightIcon />
                      </FilterButton>
                    }
                  >
                    {packingTableColumns.map((column) => (
                      <Popover.LineItem
                        key={column.id}
                        showCheckbox
                        selected={
                          packingTableColumns.find((listColumn) => listColumn.id === column.id)
                            .visible
                        }
                        title={column.displayName}
                        onClick={() => {
                          this.setPackingTableColumns(column.id, !column.visible);
                        }}
                      />
                    ))}
                  </Popover>
                )}
                <PackingSelectView />
              </Flex>
            )}
          </ControlsContent>
          <ControlsContent>
            <ComplexFilter part="active" {...complexFilterProps} />
          </ControlsContent>
        </ControlsBox>
        {/* RIZE CSV UPLOAD */}
        {orderManagementAction === "PREP" && hostId == "ryzeupsourdough" && (
          <Flex
            css={{
              flexDirection: "row",
              alignItems: "center",
              gap: "$md",
              width: "100%",
              maxWidth: 1504,
              padx: 16,
              hideOnPrint: true,
              "@desktop_sm": {
                padx: 32,
              },
            }}
          >
            {/* CSVReader here */}
            <Label side="left" text="Upload CSV:" htmlFor="csv-upload">
              {uploadCsvLoading && (
                <ReactLoading type={"spin"} color={"#000000"} height={"20px"} width={"20px"} />
              )}
              <input
                id="csv-upload"
                type="file"
                onChange={(e) => {
                  this.uploadCsvStart(e.target.files[0]);
                }}
                multiple={false}
              />
              {uploadCsvError && (
                <Span css={{ ff: "$avenir", fs: 13, fw: "$semi_bold", color: "$error11" }}>
                  {typeof uploadCsvError === "string" &&
                  uploadCsvError.includes("SPLIT TIME---") &&
                  uploadCsvError.split("SPLIT TIME---").length > 1
                    ? uploadCsvError.split("SPLIT TIME---")[1] +
                      " needs notes because she has items on different days"
                    : "There was an error. Message Andy @(972)-977-7432 for help."}
                </Span>
              )}
            </Label>

            <Button
              onClick={async () => {
                await deleteAllOrders.mutateAsync();
              }}
              color="danger"
              variant="filled"
              size="small"
            >
              Delete all Orders
            </Button>
          </Flex>
        )}

        {orderManagementAction === "PREP" && (
          <PrepSection orders={filteredOrders} isPrinting={isPrinting} />
        )}
        {orderManagementAction === "PACKING" && (
          <PackingSection
            orders={filteredOrders_packing}
            packingTableColumns={packingTableColumns}
            setFilteredOrders={this.setFilteredOrders}
            isPrinting={isPrinting}
            isChefCreatingNewOrder={isChefCreatingNewOrder}
            setIsChefCreatingNewOrder={this.setIsChefCreatingNewOrder}
          />
        )}
      </OrderManagementContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    memberships: state.hostPortal.hostInfo.memberships,
    orders: state.orderManagement.orders,
    orderManagementAction: state.orderManagement.orderManagementAction,
    uploadCsvLoading: state.orderManagement.uploadCsvLoading,
    uploadCsvError: state.orderManagement.uploadCsvError,
    connectToOrdersLoading: state.orderManagement.connectToOrdersLoading,
    packingView: state.orderManagement.packingView,
    packingFilter: state.orderManagement.packingFilter,
    sort: state.orderManagement.sort,
    completedOrdersDisplay: state.orderManagement.completedOrdersDisplay,
    prevPackingFilter: state.orderManagement.prevPackingFilter,
    activeFilters: state.orderManagement.activeFilters,
  };
};

export default connect(mapStateToProps, {
  setPackingView,
  setPackingFilter,
  setOrderManagementAction,
  uploadCsv,
})(function OrderManagementQueryWrapper(props) {
  const { chefId } = usePortalUser();
  const deleteAllOrders = trpc.portal.deleteAllOrders.useMutation({ retry: shouldRetry(1) });
  return <OrderManagement deleteAllOrders={deleteAllOrders} {...props} hostId={chefId} />;
});
