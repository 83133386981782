// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./OrderEditing.NotifyCustomer.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
} from "../../_internal_primitives";
import {
  default as Checkbox,
  CheckboxContext,
} from "../../Primitives/Checkbox";

const styles = [
  {
    type: "default",
    layers: {
      "7rciDuHg6S": {
        none: {
          text: "Notify customer about changes",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      W7qBZXSphK: {},
    },
  },
];

const variantPropTypes = [];

export const NotifyCustomerContext = createContext(null);

function NotifyCustomer(_props) {
  const defaults = useContext(NotifyCustomerContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="4gc172dm8q"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "4gc172dm8q",
        scope: "59MqRniG58",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => [
        props.Checkbox === undefined ? (
          <Checkbox
            key={"W7qBZXSphK"}
            {...getCompositeDefaultProps("W7qBZXSphK")}
            id="Checkbox_W7qBZXSphK"
            className="__visly_reset_7X7HTLRqyD __visly_scope_59MqRniG58_W7qBZXSphK"
          />
        ) : (
          <CheckboxContext.Provider
            key="W7qBZXSphK-provider"
            value={{
              key: "W7qBZXSphK",
              id: "Checkbox_W7qBZXSphK",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_59MqRniG58_W7qBZXSphK",
              ...getCompositeDefaultProps("W7qBZXSphK"),
            }}
          >
            {props.Checkbox}
          </CheckboxContext.Provider>
        ),
        <SpacerPrimitive
          id={"Spacer_U7mdnJk5No"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_59MqRniG58_U7mdnJk5No"
          }
          key={"U7mdnJk5No"}
        />,
        <TextPrimitive
          id={"Text_7rciDuHg6S"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_59MqRniG58_7rciDuHg6S"
          }
          key={"7rciDuHg6S"}
          text={getStyle("7rciDuHg6S", "text")}
          element={getStyle("7rciDuHg6S", "htmlElement")}
        />,
      ]}
    </RootPrimitive>
  );
}

NotifyCustomer.Checkbox = Checkbox;
NotifyCustomer.__variants = [];

export default NotifyCustomer;
