// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./CountdownTimer.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      AuU4vQThV4: {
        none: {
          text: "00",
        },
      },
      MkV2pNDgMf: {
        none: {
          text: ":",
          htmlElement: "p",
        },
      },
      BrCZ1CcNgT: {
        none: {
          text: "00",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "light",
    layers: {},
  },
  {
    type: "boolean",
    propName: "dark",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "light",
    layers: {},
  },
  {
    type: "boolean",
    propName: "dark",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "light",
  },
  {
    type: "boolean",
    propName: "dark",
  },
];

export const CountdownTimerContext = createContext(null);

function CountdownTimer(_props) {
  const defaults = useContext(CountdownTimerContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="6jKZAspNH4"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "6jKZAspNH4",
        scope: "V2xhkryW4Q",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <TextPrimitive
            id={"minsCountdown_AuU4vQThV4"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_V2xhkryW4Q_AuU4vQThV4"
            }
            key={"AuU4vQThV4"}
            text={
              exists(props.minsCountdown)
                ? props.minsCountdown
                : getStyle("AuU4vQThV4", "text")
            }
            element={getStyle("AuU4vQThV4", "htmlElement")}
          />
          <TextPrimitive
            id={":_MkV2pNDgMf"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_V2xhkryW4Q_MkV2pNDgMf"
            }
            key={"MkV2pNDgMf"}
            text={getStyle("MkV2pNDgMf", "text")}
            element={getStyle("MkV2pNDgMf", "htmlElement")}
          />
          <TextPrimitive
            id={"secsCountdown _BrCZ1CcNgT"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_V2xhkryW4Q_BrCZ1CcNgT"
            }
            key={"BrCZ1CcNgT"}
            text={
              exists(props.secsCountdown)
                ? props.secsCountdown
                : getStyle("BrCZ1CcNgT", "text")
            }
            element={getStyle("BrCZ1CcNgT", "htmlElement")}
          />
          <SpacerPrimitive
            id={"Spacer_4AvGJyZYL5"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_V2xhkryW4Q_4AvGJyZYL5"
            }
            key={"4AvGJyZYL5"}
          />
          <ContainerPrimitive
            key={"CF9bfZAiFd"}
            id={"Slot_CF9bfZAiFd"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_V2xhkryW4Q_CF9bfZAiFd"
            }
            addSpacing={false}
          >
            {props.tooltip}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

CountdownTimer.__variants = [
  {
    name: "light",
    type: "variant",
  },
  {
    name: "dark",
    type: "variant",
  },
];

export default CountdownTimer;
