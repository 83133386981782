// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./AddEditLocation.AddressContainer.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      UkEbUpMVis: {
        none: {
          text: "Location Address",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const AddressContainerContext = createContext(null);

function AddressContainer(_props) {
  const defaults = useContext(AddressContainerContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="YPoVdceUeG"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "YPoVdceUeG",
        scope: "G5nSTsdWwU",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <TextPrimitive
            id={"label_UkEbUpMVis"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_G5nSTsdWwU_UkEbUpMVis"
            }
            key={"UkEbUpMVis"}
            text={getStyle("UkEbUpMVis", "text")}
            element={getStyle("UkEbUpMVis", "htmlElement")}
          />
          <SpacerPrimitive
            id={"Spacer_9dhYULimTK"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_G5nSTsdWwU_9dhYULimTK"
            }
            key={"9dhYULimTK"}
          />
          <ContainerPrimitive
            key={"Xxzrp1NH4G"}
            id={"Slot_Xxzrp1NH4G"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_G5nSTsdWwU_Xxzrp1NH4G"
            }
            addSpacing={true}
          >
            {props.children}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

AddressContainer.__variants = [];

export default AddressContainer;
