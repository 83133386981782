import {
  UPLOAD_COMPONENT_STATE_START,
  UPLOAD_COMPONENT_STATE_SUCCESS,
  UPLOAD_COMPONENT_STATE_FAILURE,
} from "../actions/types";

const INITIAL_STATE = {
  uploadComponentCount: 0,
  uploadComponentTime: 0,
  uploadComponentFailure: false,
  urlName: null,
  uploadGlobalComponentFailure: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPLOAD_COMPONENT_STATE_START:
      return {
        ...state,
        uploadComponentTime: new Date().getTime(),
        uploadComponentCount: state.uploadComponentCount + 1,
      };
    case UPLOAD_COMPONENT_STATE_SUCCESS:
      return { ...state, uploadComponentCount: state.uploadComponentCount - 1 };
    case UPLOAD_COMPONENT_STATE_FAILURE:
      return { ...state, uploadComponentFailure: true };
    default:
      return state;
  }
};
