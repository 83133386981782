// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./ItemsCard.Item.Badge.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      RbuR9sKae4: {
        none: {
          text: "10",
        },
      },
      DRLD4UdVpr: {
        none: {
          text: "STATUS",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "kind",
    values: [
      {
        propValue: "someDone",
        layers: {
          DRLD4UdVpr: {
            none: {
              text: "made",
            },
          },
        },
      },
      {
        propValue: "allDone",
        layers: {
          DRLD4UdVpr: {
            none: {
              text: "DONE",
            },
          },
        },
      },
    ],
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
      {
        propValue: "xsmall",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "kind",
    values: [
      {
        propValue: "someDone",
        layers: {},
      },
      {
        propValue: "allDone",
        layers: {},
      },
    ],
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
      {
        propValue: "xsmall",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "kind",
    propValues: ["someDone", "allDone"],
  },
  {
    type: "enum",
    propName: "size",
    propValues: ["large", "med", "small", "xlarge", "xsmall"],
  },
];

export const BadgeContext = createContext(null);

function Badge(_props) {
  const defaults = useContext(BadgeContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="FuiuSPYtaV"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "FuiuSPYtaV",
        scope: "7QXqsDRNbY",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Background_58gG3fNe9D"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_7QXqsDRNbY_58gG3fNe9D"
          }
          key={"58gG3fNe9D"}
          addSpacing={false}
          element={getStyle("58gG3fNe9D", "htmlElement")}
        >
          <TextPrimitive
            id={"quantity_RbuR9sKae4"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_7QXqsDRNbY_RbuR9sKae4"
            }
            key={"RbuR9sKae4"}
            text={
              exists(props.quantity)
                ? props.quantity
                : getStyle("RbuR9sKae4", "text")
            }
            element={getStyle("RbuR9sKae4", "htmlElement")}
          />
          <TextPrimitive
            id={"status_DRLD4UdVpr"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_7QXqsDRNbY_DRLD4UdVpr"
            }
            key={"DRLD4UdVpr"}
            text={getStyle("DRLD4UdVpr", "text")}
            element={getStyle("DRLD4UdVpr", "htmlElement")}
          />
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

Badge.__variants = [
  {
    name: "kind",
    type: "group",
    variants: ["someDone", "allDone"],
  },
  {
    name: "size",
    type: "group",
    variants: ["large", "med", "small", "xlarge", "xsmall"],
  },
];

export default Badge;
