/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import QRCode from "qrcode.react";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import { Formik, Field as FormikField, Form, ErrorMessage } from "formik";
import {
  Button as VislyButton,
  Input as VislyInput,
  WarningBubble,
  Badge,
} from "../../visly/Primitives";
import { textstyles, icons, colors, useBreakpoint } from "../../visly";
import { backendUrl } from "../../hotplate-storefront/actions/types";
import {
  AccountSettingsPage,
  AccountSettingsContent,
  ButtonContainer,
  TooltipSwitch,
  TeamMembers,
  Permissions,
  InputColorPreview,
  ColorPreview,
  BrandingPreview,
  PreviewRow,
} from "./components/Containers";
import {
  priceInputIsValid,
  toFixed,
  getFormattedPhone,
  getUnFormattedPhone,
  capitalizeFirstLetter,
} from "@hotplate/utils-ts/helperFunctions";
import Tooltip from "../../hotplate-common/ToolTip";
import SmartTextArea from "../../hotplate-common/SmartTextArea";
import AnimationContainer from "../../hotplate-common/AnimationContainer";
import { StorefrontHeader } from "../../hotplate-storefront/components/StorefrontHeader";
import { setToastPopupInfo as setToastPopupInfoUnconnected } from "../../hotplate-storefront/actions";
import { LabeledInput, Field, ConfigTabs } from "../../visly/Events";
import { AddPhone, SettingsHeader, UserPhoneCard } from "../../visly/AccountSettings";
import "./css/index.css";
import { getChefStyles } from "../../hotplate-common/chefStyleFunctions";
import { CartButton } from "../../hotplate-storefront/components/StorefrontCartButton";
import { ReactComponent as SquareLogo } from "../../static/images/logos/Square_LogoLockup_Black.svg";
import { FieldIcon } from "../../hotplate-common/FieldIcon";
import {
  ChevronRightIcon,
  CopyIcon,
  DotsVerticalIcon,
  SewingPinFilledIcon,
  TrashIcon,
} from "@radix-ui/react-icons";
import { styled } from "../../stitches.config";
import { TextSwitch } from "../../hotplate-common/primitives/TextSwitch";
import { Button } from "../../hotplate-common/primitives/Button";
import { Switch } from "../../hotplate-common/primitives/Switch";
import { SettingsCard } from "./components/SettingsCard";

const DangerouslyStyledVislyButton = styled(VislyButton, {});
import ImageUploader from "../components/ImageUploader";
import NewModal from "../../hotplate-common/NewModal";

import { DropdownMenu } from "../../hotplate-common/primitives/DropdownMenu";
import ItemizedReceipt from "../payment/ItemizedReceipt";
import "./css/index.css";
import { IconButton } from "../../hotplate-common/primitives/IconButton";
import { Input } from "../../hotplate-common/primitives/Input";
import { Label } from "../../hotplate-common/primitives/Label";
import { Separator } from "../../hotplate-common/primitives/Separator";
import { v4 as uuid } from "uuid";
import {
  trackStorefrontUrlCopied,
  trackStorefrontQrCodeDownloaded,
  trackBusinessNameUpdated,
  trackTaxRateUpdated,
  trackUserAddedToAccount,
  trackUserRemovedFromAccount,
  trackAccountPrimaryColorUpdated,
  trackAccountStorefrontHeaderColorUpdated,
  trackAccountStorefrontHeaderTextColorUpdated,
  trackAccountLogoUpdated,
  trackAccountLogoRemoved,
  trackAccountZeroTipDefaultToggled,
  trackAccountTipSuggestionsUpdated,
  trackAccountSmsNotificationToggled,
  trackAccountSmsNotificationUpdated,
  trackDiscountCodeArchivedUnarchived,
  trackCreateDiscountCodeClicked,
  trackDiscountCodeCreated,
  trackDiscountCodeToggled,
  trackConnectToSquareClicked,
  trackAboutMeUpdated,
  trackSocialMediaLinkUpdated,
  trackStorefrontLinkAdded,
  trackStorefrontLinkRemoved,
  trackFAQAdded,
  trackFAQRemoved,
  trackIsBusinessNameHiddenToggled,
  trackHideDropChatToggled,
  trackVisibleItemsInCartsCapToggled,
  trackHidePresenceToggled,
  trackIsGiftCardsEnabledOnStorefrontToggled,
  trackCustomDropNameUpdated,
} from "./analytics";
import { group } from "../../hotplate-common/analytics";
import { mergeDiff, removeNulls, useFirebaseConnection } from "../../hooks";
import { Center, H2, Row, P, Column } from "../../hotplate-common/primitives/Containers";
import { PuffLoader } from "../../hotplate-common/loaders/PuffLoader";
import { shouldRetry, trpc } from "../../trpc";
import { Select } from "../../hotplate-common/primitives/Select";
import { Checkbox } from "../../hotplate-common/primitives/Checkbox";
import { TextArea } from "../../hotplate-common/primitives/TextArea";
import { usePortalUser } from "../../auth";

function useConnectedFn(fn, dispatch) {
  return useCallback(
    (...args) => {
      return fn(...args)(dispatch);
    },
    [fn, dispatch]
  );
}

const DiscountCodeDropdownMenu = function ({ discountCode }) {
  const dispatch = useDispatch();
  const setToastPopupInfo = useConnectedFn(setToastPopupInfoUnconnected, dispatch);

  const updateDiscountCode = trpc.portal.updateDiscountCode.useMutation({ retry: shouldRetry(1) });

  return (
    <DropdownMenu
      trigger={<IconButton icon={<DotsVerticalIcon />} onClick={(e) => e.stopPropagation()} />} // stopPropagation so that clicking on the dropdown trigger doesn't bring up the modal
      onClick={(e) => e.stopPropagation()} // stopPropagation so that clicking on the content of the dropdown menu doesn't bring up the modal
      style={{ zIndex: 999999999 }} // this is to go over the .modal-overlay style
    >
      <DropdownMenu.Item
        onClick={() => {
          navigator.clipboard.writeText(discountCode.code).then(
            () => {
              setToastPopupInfo({
                text: "Copied to clipboard",
                type: "info",
              });
            },
            (err) => {
              setToastPopupInfo({
                text: `Failed to copy, try again: ${err}`,
                type: "error",
              });
            }
          );
        }}
        text={`Copy code`}
      />
      {!discountCode.archived && (
        <DropdownMenu.Item
          onClick={async () => {
            try {
              await updateDiscountCode.mutateAsync({
                discountCodeId: discountCode.id,
                discountCodeDiff: {
                  disabled: !discountCode.disabled,
                },
              });
            } catch (e) {
              setToastPopupInfo({
                text: `Failed to ${
                  discountCode.disabled ? "enable" : "disable"
                } discount code, try again: ${e}`,
                type: "error",
              });
              throw e;
            }
            trackDiscountCodeToggled({
              discountCodeId: discountCode.id,
              enabled: !discountCode.disabled,
            });
          }}
          text={`${discountCode.disabled ? "Enable" : "Disable"} code`}
        />
      )}
      <DropdownMenu.Item
        onClick={async () => {
          try {
            await updateDiscountCode.mutateAsync({
              discountCodeId: discountCode.id,
              discountCodeDiff: {
                archived: !discountCode.archived,
              },
            });
          } catch (e) {
            setToastPopupInfo({
              text: `Failed to ${
                discountCode.archived ? "unarchive" : "archive"
              } discount code, try again: ${e}`,
              type: "error",
            });
            throw e;
          }
          trackDiscountCodeArchivedUnarchived(discountCode.id, !discountCode.archived);
        }}
        variant="danger"
        text={`${discountCode.archived ? "Unarchive" : "Delete"} code`}
      />
    </DropdownMenu>
  );
};

const Flex = styled("div", {
  display: "flex",
});

const Text = styled("div", {});

const DiscountCodeButton = ({ onClick, discountCode, totalTimesUsed }) => {
  return (
    <Flex
      css={{
        justifyContent: "space-between",
        padding: 12,
        borderRadius: 8,
        backgroundColor: "$white",
        border: "1px solid $gray6",
        boxShadow: "$elevation3",
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          backgroundColor: "$gray2",
        },
      }}
      onClick={onClick}
    >
      <Flex css={{ flexDirection: "column" }}>
        <Text css={{ ff: "$avenir", fw: "$semi_bold", fs: 16, color: "$gray12" }}>
          {discountCode.name}
        </Text>
        <Flex css={{ marginTop: 8 }}>
          <Text css={{ ff: "$avenir", fw: "$normal", fs: 14, color: "$gray12" }}>{`${
            discountCode.type === "flat"
              ? `$${discountCode.amount}`
              : discountCode.type === "percentage"
              ? `${discountCode.amount * 100}%`
              : null
          } • Used ${totalTimesUsed} times`}</Text>
        </Flex>
      </Flex>
      <Flex>
        {discountCode.disabled && (
          <Badge text="Disabled" light="warning" style={{ marginRight: 8 }} />
        )}
        <DiscountCodeDropdownMenu discountCode={discountCode} />
      </Flex>
    </Flex>
  );
};

const Fieldset = styled("fieldset", {
  display: "flex",
  flexDirection: "column",
});

const Legend = styled("legend", {
  ff: "$arboria",
  fs: 16,
  fw: "$semi_bold",
  color: "$gray12",
  marginBottom: "$md",
});

const ErrorText = styled("span", {
  marginTop: 8,
  ff: "$avenir",
  fw: "$normal",
  fs: 13,
  color: "$danger11",
});

const CreateDiscountCodeModal = function ({
  activeCodes,
  onRequestClose,
  chefStyles,
  ...restProps
}) {
  const dispatch = useDispatch();
  const setToastPopupInfo = useConnectedFn(setToastPopupInfoUnconnected, dispatch);

  const addDiscountCode = trpc.portal.addDiscountCode.useMutation({ retry: shouldRetry(1) });

  return (
    <NewModal title="Create Discount" onRequestClose={onRequestClose} {...restProps}>
      <Flex css={{ flexDirection: "column" }}>
        <Formik
          initialValues={{
            name: "",
            code: "",
            amount: "",
            type: "flat",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name.length) {
              errors.name = "Required";
            }

            if (!values.code.length) {
              errors.code = "Required";
            } else if (values.code.length < 3 || 10 < values.code.length) {
              errors.code = "Must be 3 to 10 characters";
            } else if (!values.code.match(/^[\da-zA-Z-]+$/gm)) {
              errors.code = "Must contain only numbers, letters, and dashes";
            } else if (activeCodes.has(values.code.toUpperCase())) {
              errors.code = "Code is already in use by another discount";
            }

            if (!["flat", "percentage"].includes(values.type)) {
              errors.type = "Type must be one of [flat, percentage]";
            }

            const parsedAmount = parseFloat(values.amount);
            if (isNaN(parsedAmount)) {
              errors.amount = "Required";
            } else if (parsedAmount <= 0) {
              errors.amount = "Must be > 0";
            } else if (values.type === "percentage" && parsedAmount > 100) {
              errors.amount = "Must be <= 100";
            }

            return errors;
          }}
          onSubmit={async (values) => {
            const parsedAmount = parseFloat(values.amount);
            const newDiscountCode = {
              id: uuid(),
              name: values.name,
              code: values.code.toUpperCase(),
              amount: values.type === "percentage" ? parsedAmount / 100 : parsedAmount,
              type: values.type,
              disabled: false,
              archived: false,
              totalValueDiscounted: "0.00",
              totalTimesUsed: 0,
            };
            try {
              await addDiscountCode.mutateAsync({ newDiscountCode });
            } catch (e) {
              setToastPopupInfo({
                text: `Failed to add discount code, try again: ${e}`,
                type: "error",
              });
              throw e;
            }
            trackDiscountCodeCreated(newDiscountCode);
            onRequestClose();
          }}
        >
          {({ values, setFieldValue }) => (
            <Form style={{ display: "flex", flexDirection: "column", paddingInline: 2 }}>
              <Fieldset style={{ display: "flex", flexDirection: "column" }}>
                <Legend>Basic Information</Legend>
                <Label text="Discount name" tooltipText="This is only visible to you.">
                  <FormikField name="name">
                    {({ field, form }) => {
                      return (
                        <Input
                          {...field}
                          type="text"
                          placeholder="Internal name"
                          status={form.errors.name && "warning"}
                        />
                      );
                    }}
                  </FormikField>
                  <ErrorText>
                    <ErrorMessage name="name" />
                  </ErrorText>
                </Label>
                <Label
                  css={{ marginTop: 16 }}
                  text="Discount code"
                  tooltipText="The code customers will enter at checkout."
                >
                  <FormikField name="code">
                    {({ field, form }) => {
                      return (
                        <Input
                          {...field}
                          status={form.errors.code && "warning"}
                          type="text"
                          value={values.code.toUpperCase()}
                          placeholder="CODE20"
                        />
                      );
                    }}
                  </FormikField>
                  <ErrorText>
                    <ErrorMessage name="code" />
                  </ErrorText>
                </Label>
              </Fieldset>
              <Separator space="$space$lg" />
              <Fieldset sttyle={{ display: "flex", flexDirection: "column" }}>
                <Legend>Details</Legend>
                <Flex css={{ alignItems: "flex-end" }}>
                  <Label
                    css={{ flex: 1 }}
                    text="Discount amount"
                    tooltipText="Choose between a flat discount or a percentage"
                  >
                    <FormikField name="amount">
                      {({ field, form }) => {
                        return (
                          <Flex css={{ alignItems: "center" }}>
                            <Input
                              {...field}
                              css={{ flex: "0 1 125px", minWidth: "0px" }}
                              contentBefore={values.type === "flat" ? "$" : null}
                              contentAfter={values.type === "percentage" ? "%" : null}
                              inputMode="decimal"
                              placeholder={
                                values.type === "flat"
                                  ? "0.00"
                                  : values.type === "percentage"
                                  ? "0"
                                  : null
                              }
                              status={form.errors.amount && "warning"}
                              onChange={(e) => {
                                const incoming = e.target.value;
                                const match = incoming.match(/^-?\d*(?:\.\d{0,2})?/);
                                if (match === null) {
                                  return;
                                }
                                const truncated = match[0];
                                const dotIdx = truncated.indexOf(".");
                                const prefix = dotIdx === 0 ? "0" : "";
                                const value = prefix + truncated;
                                setFieldValue("amount", value);
                              }}
                            />
                            <TextSwitch
                              css={{ marginLeft: 16 }}
                              checkedContent="$"
                              uncheckedContent="%"
                              name="type"
                              chefStyles={chefStyles}
                              defaultChecked={values.type === "flat"}
                              width={50}
                              onCheckedChange={(checked) =>
                                setFieldValue("type", checked ? "flat" : "percentage")
                              }
                            />
                          </Flex>
                        );
                      }}
                    </FormikField>
                    <ErrorText>
                      <ErrorMessage name="amount" />
                    </ErrorText>
                  </Label>
                </Flex>
              </Fieldset>
              <Separator space="$space$lg" />
              <Flex>
                <Button color="gray" variant="tinted" onClick={onRequestClose}>
                  Discard
                </Button>
                <Button
                  css={{ marginLeft: 12 }}
                  type="submit"
                  color="success"
                  variant="tinted"
                  loading={addDiscountCode.isLoading}
                >
                  Save {"&"} Publish Code
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
    </NewModal>
  );
};

const CodeLineItemThing = ({ onClick, title, code }) => {
  return (
    <Flex css={{ alignItems: "center", justifyContent: "space-between", pady: 12 }}>
      <Flex css={{ flexDirection: "column" }}>
        <Text
          css={{ marginBottom: 12, ff: "$arboria", color: "$gray12", fw: "$semi_bold", fs: 15 }}
        >
          {title}
        </Text>
        <Text css={{ ff: "$avenir", fs: 15, fw: "$semi_bold", color: "$gray11" }}>{code}</Text>
      </Flex>
      {onClick && <IconButton icon={<CopyIcon />} onClick={onClick} />}
    </Flex>
  );
};

const Table = styled("table", {
  ff: "$avenir",
  fs: 14,
  width: "100%",
});

const TableRow = styled("tr", {
  height: 40,
  lh: "$reset",
  backgroundColor: "$gray2",

  paddingInline: 12,
  fw: "$normal",
  color: "$gray11",
  transition: "background-color 0.2s ease-in-out",
  borderBottom: "1px solid $gray6",
  "&:hover": {
    backgroundColor: "$gray3",
    color: "$gray12",
  },
  "& td:first-child, th:first-child": {
    paddingLeft: 12,
  },
  cursor: "pointer",
});

const Head = styled("th", {
  height: 20,
  verticalAlign: "middle",
  textAlign: "start",
  fw: "$semi_bold",
  color: "$gray12",
  backgroundColor: "transparent",
  borderBottom: "1px solid gray10",
});

const Data = styled("td", {
  height: 20,
  verticalAlign: "middle",
  textAlign: "start",
  color: "inherit",
});

const DiscountCodeDetailsModal = function ({ hostId, discountCode, onRequestClose, ...restProps }) {
  const dispatch = useDispatch();
  const setToastPopupInfo = useConnectedFn(setToastPopupInfoUnconnected, dispatch);

  const [idOfOrderBeingViewed, setIdOfOrderBeingViewed] = useState(null);

  const { data: discountedOrdersDb, isLoading: connectionLoading } = useFirebaseConnection(
    { ref: `/hosts/${hostId}/orders`, orderByChild: "/discountCode/id", equalTo: discountCode.id },
    {
      throttleArgs: {
        wait: 1000,
        options: {
          leading: true,
          trailing: true,
        },
      },
    }
  );

  const discountedOrders = discountedOrdersDb || {};

  return (
    <>
      <NewModal title="Discount Details & Usage" onRequestClose={onRequestClose} {...restProps}>
        <Flex css={{ justifyContent: "space-between", alignItems: "center" }}>
          <Text css={{ ff: "$arboria", fw: "$semi_bold", fs: 18 }}>{discountCode.name}</Text>
          <DiscountCodeDropdownMenu discountCode={discountCode} />
        </Flex>
        <Flex css={{ my: 8, padx: 0, pady: 0 }}>
          {discountCode.disabled && (
            <Badge text="Disabled" light="warning" style={{ paddingInline: 0 }} />
          )}
          {discountCode.archived && (
            <Badge text="Archived" light="black" style={{ paddingInline: 0 }} />
          )}
        </Flex>
        <CodeLineItemThing
          title="Code"
          code={discountCode.code}
          onClick={() => {
            navigator.clipboard.writeText(discountCode.code).then(
              () => {
                setToastPopupInfo({
                  text: "Copied to clipboard",
                  type: "info",
                });
              },
              (err) => {
                setToastPopupInfo({
                  text: `Failed to copy, try again: ${err}`,
                  type: "error",
                });
              }
            );
          }}
        />

        <CodeLineItemThing
          title="Details"
          code={
            discountCode.type === "flat"
              ? `$${discountCode.amount}`
              : discountCode.type === "percentage"
              ? `${discountCode.amount * 100}%`
              : null + "off"
          }
        />

        <Separator />
        <Flex css={{ flexDirection: "column", paddingBottom: 48 }}>
          <Text css={{ ff: "$arboria", fw: "$semi_bold", fs: 18, marginTop: 12, marginBottom: 16 }}>
            Usage
          </Text>
          <Text
            css={{ ff: "$avenir", fs: 15, fw: "$semi_bold", color: "$gray11", marginBottom: 32 }}
          >
            {connectionLoading && (
              <ReactLoading type="spin" width="20px" height="20px" color="#000000" />
            )}
            {"Used " + Object.keys(discountedOrders).length + " times"}
            {Object.keys(discountedOrders).length > 0
              ? ", $" + discountCode.totalValueDiscounted + " in discounts overall"
              : null}
          </Text>

          <Table css={{}}>
            <TableRow
              css={{
                backgroundColor: "transparent",
                "&:hover": { backgroundColor: "transparent" },
              }}
            >
              <Head>CUSTOMER</Head>
              <Head>ORDER</Head>
              <Head>DATE</Head>
              <Head></Head>
            </TableRow>

            {Object.entries(discountedOrders).map(([id, order]) => {
              const date = new Date(order.orderPlaced);
              return (
                <TableRow
                  key={id}
                  onClick={() => {
                    setIdOfOrderBeingViewed(order.paymentIntentId);
                  }}
                >
                  <Data> {" " + order.fullName}</Data>
                  <Data>{order.orderId}</Data>
                  <Data>
                    {date.getMonth() + 1}/{date.getDate()}/{date.getFullYear()}
                  </Data>
                  <Data>
                    <ChevronRightIcon />
                  </Data>
                </TableRow>
              );
            })}
          </Table>
        </Flex>
      </NewModal>
      <ItemizedReceipt
        isOpen={idOfOrderBeingViewed !== null}
        paymentIntentId={idOfOrderBeingViewed}
        onRequestClose={() => {
          setIdOfOrderBeingViewed(null);
        }}
      />
    </>
  );
};

function getFrontendUrl() {
  let frontendUrl = "https://hotplate.com/";
  if (backendUrl === "https://hotplate.appspot.com/") {
    frontendUrl = "https://hotplate.com/";
  } else if (backendUrl === "https://hotplate-backend-test.uc.r.appspot.com/") {
    frontendUrl = "https://hotplate-frontend-test.uc.r.appspot.com/";
  } else if (
    backendUrl === "https://hotplate.ngrok.io/" ||
    backendUrl === "https://localhost:8080/"
  ) {
    frontendUrl = "https://hotplate-frontend.ngrok.io/";
  }
  return frontendUrl;
}
const confirmationLink = getFrontendUrl() + "confirmation?orderId=XXXXXX&phone=+11111111111";
const reminderString =
  "\n\nView your order time, location, and additional details here:\n" + confirmationLink;

function getAllPermissions() {
  const permissions = ["accountSettings", "billing", "events", "orders", "transactions"];
  permissions.sort();
  return permissions;
}

function getDefaultPermissions() {
  const allPermissions = getAllPermissions();
  const defaultPermissions = {};
  for (let i = 0; i < allPermissions.length; i++) {
    defaultPermissions[allPermissions[i]] = false;
  }
  return defaultPermissions;
}

function validateTaxRateInput(val) {
  if (!val) return null;
  if (/^\d+(\.\d+)*$/.test(val)) return "valid";
  return "invalid";
}

function validateHexInput(val) {
  if (!val) return null;
  if (typeof val !== "string") return "invalid";
  if (
    /^#([0-9A-F]{3})$/i.test(val) ||
    /^#([0-9A-F]{6})$/i.test(val) ||
    /^#([0-9A-F]{8})$/i.test(val)
  )
    return "valid";
  return "invalid";
}

function addSiteSettingsDefaults(siteSettings) {
  const ret = _.cloneDeep(siteSettings);

  if (!ret.restaurantName) ret.restaurantName = "";

  if (!ret.taxRate) ret.taxRate = "0.00";

  if (!ret.styles) ret.styles = {};

  if (!ret.styles.primaryColor) ret.styles.primaryColor = "";

  if (!ret.styles.bannerColor) ret.styles.bannerColor = "";

  if (!ret.styles.bannerTextColor) ret.styles.bannerTextColor = "";

  if (!ret.styles.bannerImage) ret.styles.bannerImage = "";

  if (!ret.notifications) ret.notifications = {};

  if (!ret.notifications.messageOnOrder) {
    ret.notifications.messageOnOrder = {
      enabled: true,
      text: "",
    };
  }

  if (!ret.notifications.message24HoursBefore) {
    ret.notifications.message24HoursBefore = {
      enabled: true,
      text: "",
    };
  }

  if (!ret.notifications.message2HoursBefore) {
    ret.notifications.message2HoursBefore = {
      enabled: true,
      text: "",
    };
  }

  if (!ret.notifications.messageAtPickupTime) {
    ret.notifications.messageAtPickupTime = {
      enabled: true,
      text: "",
    };
  }

  if (!ret.notifications.onCompleteNotification) {
    ret.notifications.onCompleteNotification = {
      enabled: false,
      text: "",
    };
  }

  ret.taxRate = toFixed(parseFloat(ret.taxRate) * 100, 2);

  return ret;
}

const tipOptionArrays = [
  [".05", ".1", ".15"],
  [".1", ".15", ".2"],
  [".15", ".2", ".25"], // if you change this line, search the codebase for TIP_OPTION_DEFAULT_523894 and keep in sync (this array must be at index 2)
  [".2", ".25", ".3"],
];

export default function AccountSettings({ setConfirmModuleUnmount }) {
  const { chefId } = usePortalUser();
  const {
    hostPhone,
    email: hostEmail,
    instaHandle: hostInsta,
    permissions,
    siteSettings,
    discountCodes,
  } = useSelector((state) => state.hostPortal.hostInfo);
  const { connectToChefInfoPermissionsLoading, connectToChefInfoSiteSettingsLoading } = useSelector(
    (state) => state.hostPortal
  );
  const [debounce] = useState(() => {
    return _.debounce(
      (func) => {
        func();
      },
      2000,
      { leading: false, trailing: true }
    );
  });

  const [siteSettingsDiff, setSiteSettingsDiff] = useState({});
  const [permissionsDiff, setPermissionsDiff] = useState({});
  const [editPermissionState, setEditPermissionState] = useState({
    phone: "",
    name: "",
    userPermissions: getDefaultPermissions(),
  });
  const [isAddingUserPhone, setIsAddingUserPhone] = useState(false);
  const [qrCodeLink, setQrCodeLink] = useState("");
  const [showNewDiscountCodeModal, setShowNewDiscountCodeModal] = useState(false);
  const [idOfDiscountBeingViewed, setIdOfDiscountBeingViewed] = useState(null);

  const getSquareMerchant = trpc.portal.getSquareMerchant.useQuery();
  const getSquareAuthURL = trpc.portal.getSquareAuthURL.useMutation();
  const saveAccountSettingsDiff = trpc.portal.saveAccountSettingsDiff.useMutation({
    retry: shouldRetry(1),
  });

  const dispatch = useDispatch();
  const setToastPopupInfo = useConnectedFn(setToastPopupInfoUnconnected, dispatch);

  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);

  const siteSettingsWithDefaults = addSiteSettingsDefaults(siteSettings);
  const mergedSiteSettings = removeNulls(mergeDiff(siteSettingsWithDefaults, siteSettingsDiff));
  const mergedPermissions = removeNulls(mergeDiff(permissions, permissionsDiff));

  const isModified =
    !_.isEqual(siteSettingsWithDefaults, mergedSiteSettings) ||
    !_.isEqual(permissions, mergedPermissions);
  useEffect(() => {
    if (isModified) {
      setConfirmModuleUnmount(true);
      window.onbeforeunload = function () {
        return "Changes you made may not be saved.";
      };
    }
    return () => {
      setConfirmModuleUnmount(false);
      window.onbeforeunload = null;
    };
  }, [isModified]);

  function cancelEditPermissionState() {
    setIsAddingUserPhone(false);
    setEditPermissionState({
      phone: "",
      name: "",
      userPermissions: getDefaultPermissions(),
    });
  }

  function getSelectedPermissions(permissionsOverride) {
    const permissions = permissionsOverride
      ? permissionsOverride
      : editPermissionState.userPermissions;
    const selectedPermissions = [];
    const allPermissions = getAllPermissions();
    Object.keys(permissions).forEach((key) => {
      if (permissions[key] && allPermissions.includes(key))
        selectedPermissions.push(capitalizeFirstLetter(key));
    });
    selectedPermissions.sort();
    return selectedPermissions;
  }

  function togglePermissionRole(role) {
    const newEditPermissionState = _.cloneDeep(editPermissionState);
    newEditPermissionState.userPermissions[role] = !newEditPermissionState.userPermissions[role];
    setEditPermissionState(newEditPermissionState);
  }

  function setPermissionToEdit(permissionToEdit) {
    const newEditPermissionState = _.cloneDeep(editPermissionState);
    newEditPermissionState.phone = getFormattedPhone(
      getUnFormattedPhone(permissionToEdit.phone),
      false
    );
    newEditPermissionState.name = permissionToEdit.name;

    const allPermissions = getAllPermissions();
    for (let i = 0; i < allPermissions.length; i++) {
      const role = allPermissions[i];
      newEditPermissionState.userPermissions[role] = permissionToEdit[role];
    }
    setEditPermissionState(newEditPermissionState);
  }

  function setPermissionPhone(value) {
    const newEditPermissionState = _.cloneDeep(editPermissionState);

    const formattedNum = getFormattedPhone(value, false);
    if (formattedNum.length > 14) return;

    newEditPermissionState.phone = formattedNum;
    setEditPermissionState(newEditPermissionState);
  }

  function setPermissionName(val) {
    const newEditPermissionState = _.cloneDeep(editPermissionState);
    newEditPermissionState.name = val;
    setEditPermissionState(newEditPermissionState);
  }

  function savePermission() {
    setIsAddingUserPhone(false);
    setPermissionsDiff((prevPermissionsDiff) => {
      return {
        ...prevPermissionsDiff,
        ["+1" + getUnFormattedPhone(editPermissionState.phone)]: {
          name: editPermissionState.name,
          ...editPermissionState.userPermissions,
        },
      };
    });
    trackUserAddedToAccount({
      userName: editPermissionState.name,
      phone: "+1" + getUnFormattedPhone(editPermissionState.phone),
      permissions: editPermissionState.userPermissions,
    });
    setEditPermissionState({
      phone: "",
      name: "",
      userPermissions: getDefaultPermissions(),
    });
  }

  function removeUserPermissions(phone) {
    setPermissionsDiff((prevPermissionsDiff) => {
      return {
        ...prevPermissionsDiff,
        [phone]: null,
      };
    });
    trackUserRemovedFromAccount({ phone });
  }

  function setRestaurantName(value) {
    setSiteSettingsDiff((prevSiteSettingsDiff) => {
      return {
        ...prevSiteSettingsDiff,
        restaurantName: value,
      };
    });
    debounce(() => {
      trackBusinessNameUpdated({
        name: value,
      });
    });
  }

  function toggleNotificationEnabled(notification) {
    setSiteSettingsDiff((prevSiteSettingsDiff) => {
      return {
        ...prevSiteSettingsDiff,
        notifications: {
          ...prevSiteSettingsDiff.notifications,
          [notification]: {
            ...prevSiteSettingsDiff.notifications?.[notification],
            enabled: !mergedSiteSettings.notifications?.[notification]?.enabled,
          },
        },
      };
    });
    trackAccountSmsNotificationToggled({
      notificationType: notification,
      enabled: !mergedSiteSettings.notifications?.[notification]?.enabled,
    });
  }

  function setNotificationText(text, notification) {
    setSiteSettingsDiff((prevSiteSettingsDiff) => {
      return {
        ...prevSiteSettingsDiff,
        notifications: {
          ...prevSiteSettingsDiff.notifications,
          [notification]: {
            ...prevSiteSettingsDiff.notifications?.[notification],
            text: text,
          },
        },
      };
    });
    debounce(() => {
      trackAccountSmsNotificationUpdated({
        notificationType: notification,
        message: text,
      });
    });
  }

  function setTaxRate(value) {
    if (!priceInputIsValid(value)) return;
    setSiteSettingsDiff((prevSiteSettingsDiff) => {
      return {
        ...prevSiteSettingsDiff,
        taxRate: value,
      };
    });
    debounce(() => {
      trackTaxRateUpdated({
        taxRate: value,
      });
    });
  }

  function setPrimaryColor(value) {
    // TODO: validate
    setSiteSettingsDiff((prevSiteSettingsDiff) => {
      return {
        ...prevSiteSettingsDiff,
        styles: {
          ...prevSiteSettingsDiff.styles,
          primaryColor: value,
        },
      };
    });
    debounce(() => {
      trackAccountPrimaryColorUpdated({
        color: value,
      });
    });
  }

  function setBannerColor(value) {
    // TODO: validate
    setSiteSettingsDiff((prevSiteSettingsDiff) => {
      return {
        ...prevSiteSettingsDiff,
        styles: {
          ...prevSiteSettingsDiff.styles,
          bannerColor: value,
        },
      };
    });
    debounce(() => {
      trackAccountStorefrontHeaderColorUpdated({
        color: value,
      });
    });
  }

  function setBannerTextColor(value) {
    // TODO: validate
    setSiteSettingsDiff((prevSiteSettingsDiff) => {
      return {
        ...prevSiteSettingsDiff,
        styles: {
          ...prevSiteSettingsDiff.styles,
          bannerTextColor: value,
        },
      };
    });
    debounce(() => {
      trackAccountStorefrontHeaderTextColorUpdated({
        color: value,
      });
    });
  }

  function setBannerImage(fileInfo) {
    // TODO: validate
    if (!fileInfo) {
      throw new Error("IMG UPLOAD ERR");
    }
    setSiteSettingsDiff((prevSiteSettingsDiff) => {
      return {
        ...prevSiteSettingsDiff,
        styles: {
          ...prevSiteSettingsDiff.styles,
          bannerImage: fileInfo.cdnUrl,
        },
      };
    });
    if (!fileInfo.cdnUrl) {
      trackAccountLogoRemoved();
    } else {
      trackAccountLogoUpdated({
        logo: fileInfo.cdnUrl,
      });
    }
  }

  function setBigLogo(fileInfo) {
    // TODO: validate
    if (!fileInfo) {
      throw new Error("IMG UPLOAD ERR");
    }
    setSiteSettingsDiff((prevSiteSettingsDiff) => {
      return {
        ...prevSiteSettingsDiff,
        styles: {
          ...prevSiteSettingsDiff.styles,
          bigLogo: fileInfo.cdnUrl,
        },
      };
    });
  }

  function setTipOptions(tipOptionsString) {
    setSiteSettingsDiff((prevSiteSettingsDiff) => {
      return {
        ...prevSiteSettingsDiff,
        tipOptions: JSON.parse(tipOptionsString),
      };
    });
    trackAccountTipSuggestionsUpdated({
      tipSuggestions: tipOptionsString,
    });
  }

  function setIsTipDefaultZero(bool) {
    setSiteSettingsDiff((prevSiteSettingsDiff) => {
      return {
        ...prevSiteSettingsDiff,
        isTipDefaultZero: bool,
      };
    });
    trackAccountZeroTipDefaultToggled({
      enabled: bool,
    });
  }

  async function saveAccountSettingsWrapped() {
    const siteSettingsDiffToSave = _.cloneDeep(siteSettingsDiff);
    if (siteSettingsDiffToSave.taxRate) {
      siteSettingsDiffToSave.taxRate = toFixed(parseFloat(siteSettingsDiffToSave.taxRate) / 100, 4);
    }
    if (siteSettingsDiffToSave.restaurantName) {
      siteSettingsDiffToSave.restaurantName = siteSettingsDiffToSave.restaurantName.trim();
    }

    try {
      await saveAccountSettingsDiff.mutateAsync({
        siteSettingsDiff: siteSettingsDiffToSave,
        permissionsDiff: permissionsDiff,
      });
    } catch (e) {
      setToastPopupInfo({
        type: "error",
        text: `Failed to save account settings: ${e}`,
      });
      throw e;
    }
    setSiteSettingsDiff({});
    setPermissionsDiff({});
    group(chefId, {
      restaurantName: mergedSiteSettings.restaurantName,
      bannerImage: mergedSiteSettings.styles.bannerImage,
      chefId: chefId,
      phone: hostPhone,
      email: hostEmail,
      hotplateFee: siteSettings.hotplateFee,
      instaHandle: hostInsta,
    });
    setToastPopupInfo({ type: "success", text: "Account settings saved successfully" });
  }

  function getStorefrontLink() {
    const hostId = chefId;
    return getFrontendUrl() + hostId;
  }

  function generateQRCode() {
    setQrCodeLink(getStorefrontLink());
    trackStorefrontQrCodeDownloaded();
  }

  useEffect(() => {
    if (qrCodeLink) {
      const hostId = chefId;
      const canvas = document.getElementById("qr-gen");
      const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `Event-QRCode-${hostId}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      setQrCodeLink("");
    }
  }, [chefId, qrCodeLink]);

  function copyStorefrontLink() {
    navigator.clipboard.writeText(getStorefrontLink()).then(
      () => {
        setToastPopupInfo({
          text: "Copied to clipboard",
          type: "info",
        });
        trackStorefrontUrlCopied();
      },
      (err) => {
        setToastPopupInfo({
          text: `Failed to copy, try again: ${err}`,
          type: "error",
        });
      }
    );
  }

  function enableSaveAccountSettingsButton() {
    if (validateHexInput(mergedSiteSettings.styles.bannerColor) === "invalid") return false;
    if (validateHexInput(mergedSiteSettings.styles.primaryColor) === "invalid") return false;
    if (validateHexInput(mergedSiteSettings.styles.bannerTextColor) === "invalid") return false;
    if (validateTaxRateInput(mergedSiteSettings.taxRate) === "invalid") return false;
    if (!mergedSiteSettings.restaurantName.trim()) return false;
    return true;
  }

  const messageDefaults = {
    messageOnOrder:
      `${mergedSiteSettings.restaurantName} has received your order!` + reminderString,
    message24HoursBefore:
      `Don't forget about your order from ${mergedSiteSettings.restaurantName} tomorrow!` +
      reminderString,
    message2HoursBefore:
      `You pickup from ${mergedSiteSettings.restaurantName} in two hours!` + reminderString,
    messageAtPickupTime:
      `Your pickup order from ${mergedSiteSettings.restaurantName} will be ready in 15 minutes!` +
      reminderString,
    onCompleteNotification: "",
  };

  const activeCodes = new Set(
    Object.values(discountCodes)
      .filter((discountCode) => !discountCode.archived)
      .map((discountCode) => discountCode.code)
  );
  const chefStyles = getChefStyles(mergedSiteSettings);
  const settingCardIds = [
    "settings-general",
    "settings-team",
    "settings-content",
    "settings-branding",
    "settings-drop",
    "settings-checkout",
    "settings-notifications",
    "settings-discounts",
  ];
  const {
    primaryColor,
    primaryColorActive,
    primaryColorHover,
    primaryColorLight,
    textColor,
    cartButtonTheme,
  } = chefStyles;

  if (connectToChefInfoSiteSettingsLoading || connectToChefInfoPermissionsLoading) {
    return (
      <Center css={{ height: "100%", width: "100%", paddingTop: 256 }}>
        <PuffLoader />
      </Center>
    );
  }

  return (
    <AccountSettingsPage>
      <AccountSettingsContent>
        <SettingsHeader
          restaurantName={mergedSiteSettings.restaurantName}
          EventLink={<SettingsHeader.EventLink text={getStorefrontLink()} />}
          CopyLinkButton={
            <SettingsHeader.CopyLinkButton
              text="Copy Your URL"
              onClick={() => copyStorefrontLink()}
            />
          }
          QrCodeButton={
            <SettingsHeader.QrCodeButton text="Download QR Code" onClick={() => generateQRCode()} />
          }
        />
        <Flex
          css={{
            width: "95vw",
            justifyContent: "center",
            alignSelf: "center",
            position: "sticky",
            top: 0,
            backgroundColor: "$accent1",
            zIndex: 100,
            "@desktop_sm": {
              top: 60,
            },
          }}
        >
          <ConfigTabs
            style={{
              maxWidth: 768,
            }}
          >
            {settingCardIds.map((id) => (
              <ConfigTabs.Button
                onClick={() => {
                  document.getElementById(id).scrollIntoView({ behavior: "smooth" });
                }}
                className={`settings-tab__${id}`}
                value={id}
                key={id}
                text={capitalizeFirstLetter(id.replace("settings-", ""))} // this is a little janky, but it works
              />
            ))}
          </ConfigTabs>
        </Flex>

        {/* General Settings */}
        <SettingsCard title="General" id="settings-general">
          <LabeledInput
            style={{ marginBottom: 24 }}
            label="Business Name"
            Input={
              <VislyInput
                style={{ width: "25%", minWidth: "225px" }}
                placeholder="Business name"
                value={mergedSiteSettings.restaurantName}
                onChange={(val) => setRestaurantName(val)}
                validationType={!mergedSiteSettings.restaurantName.trim() ? "required" : "valid"}
              />
            }
          />
          {/* <TypeformButton text="Request Change" formId='mCXKU4l9' /> */}
          <LabeledInput
            label="Tax Rate"
            Input={
              <VislyInput
                style={{ width: "25%", minWidth: "225px" }}
                placeholder="0.00"
                withUnit="right"
                unit="%"
                value={mergedSiteSettings.taxRate}
                validationType={validateTaxRateInput(mergedSiteSettings.taxRate)}
                onChange={(val) => setTaxRate(val)}
              />
            }
          />
        </SettingsCard>

        {/* Add Users to Account */}
        <SettingsCard title="Your Team" id="settings-team">
          <TeamMembers>
            <UserPhoneCard
              key={hostPhone}
              owner
              name="Account Admin"
              phone={hostPhone}
              userRole={
                <Badge style={{ height: "auto", padding: "4px" }} small text="Owner" light="good" />
              }
              icon={icons.close}
            />
            {Object.keys(mergedPermissions)
              .sort()
              .map((phone) => (
                <UserPhoneCard
                  key={phone}
                  name={mergedPermissions[phone].name ? mergedPermissions[phone].name : "No Name"}
                  phone={phone}
                  userRole={
                    <Permissions>
                      {getSelectedPermissions(mergedPermissions[phone]).map((permission, index) => (
                        <Badge
                          style={{ height: "auto", padding: "0px" }}
                          small
                          light="black"
                          text={permission}
                          key={index}
                        ></Badge>
                      ))}
                    </Permissions>
                  }
                  buttons={
                    <>
                      <UserPhoneCard.EditUserButton
                        onClick={() => {
                          setIsAddingUserPhone(true);
                          setPermissionToEdit({
                            ...mergedPermissions[phone],
                            phone,
                          });
                        }}
                        icon={icons.edit}
                      />
                      <UserPhoneCard.RemoveUserButton
                        onClick={() => removeUserPermissions(phone)}
                        icon={icons.close}
                      />
                    </>
                  }
                />
              ))}
          </TeamMembers>
          <AddPhone
            size={size}
            open={isAddingUserPhone}
            PhoneInput={
              <AddPhone.PhoneInput
                value={editPermissionState.phone}
                handleOnKeyDown={editPermissionState.phone.length > 0}
                onChange={(val) => setPermissionPhone(val)}
                // TODO add formatting to this field + validation
                validationType={editPermissionState.phone.length === 14 ? "valid" : "invalid"}
                validAnimation={
                  editPermissionState.phone.length === 14 && (
                    <AnimationContainer animation="check" width="24px" height="24px" />
                  )
                }
              />
            }
            NameInput={
              <AddPhone.NameInput
                value={editPermissionState.name}
                handleOnKeyDown={editPermissionState.name.length > 0}
                onChange={(val) => setPermissionName(val)}
                // TODO add formatting to this field + validation
                validationType={editPermissionState.name.length > 0 ? "valid" : "invalid"}
                validAnimation={
                  editPermissionState.name.length > 0 && (
                    <AnimationContainer animation="check" width="24px" height="24px" />
                  )
                }
              />
            }
            PermissionsSelect={
              <AddPhone.PermissionsSelect
                style={{ flexGrow: 1, minWidth: "175px" }}
                onSelectionChange={(key) => togglePermissionRole(key)}
                label={
                  getSelectedPermissions().length > 0
                    ? getSelectedPermissions().length === getAllPermissions().length
                      ? "Full Permissions"
                      : getSelectedPermissions().join(", ")
                    : "Set Permissions"
                }
              >
                {getAllPermissions().map((item) => {
                  return (
                    <AddPhone.PermissionsSelect.Option
                      key={item}
                      label={capitalizeFirstLetter(item)}
                      selected={!!editPermissionState.userPermissions[item]}
                    />
                  );
                })}
              </AddPhone.PermissionsSelect>
            }
            AddPhoneButton={
              <AddPhone.AddPhoneButton
                disabled={
                  isAddingUserPhone &&
                  (editPermissionState.phone.length < 14 ||
                    editPermissionState.name.length === 0 ||
                    getSelectedPermissions().length === 0)
                }
                onClick={
                  !isAddingUserPhone ? () => setIsAddingUserPhone(true) : () => savePermission()
                }
              />
            }
            CancelAddPhoneButton={
              <AddPhone.CancelAddPhoneButton onClick={() => cancelEditPermissionState()} />
            }
          />
        </SettingsCard>

        {/* Storefront Content */}
        <SettingsCard title="Storefront Content" id="settings-content">
          <Label
            text="Do not display my business name above the about section in my profile"
            side="right"
            css={{ mb: "$md" }}
          >
            <Switch
              checked={mergedSiteSettings?.isBusinessNameHiddenOnProfile}
              onCheckedChange={() => {
                setSiteSettingsDiff({
                  ...siteSettingsDiff,
                  isBusinessNameHiddenOnProfile: !mergedSiteSettings?.isBusinessNameHiddenOnProfile,
                });
                trackIsBusinessNameHiddenToggled({
                  enabled: !mergedSiteSettings?.isBusinessNameHiddenOnProfile,
                });
              }}
            />
          </Label>
          <Label
            text="Allow customers to buy gift cards on my storefront"
            side="right"
            css={{ mb: "$xl" }}
          >
            <Switch
              checked={mergedSiteSettings?.isGiftCardsEnabledOnStorefront !== false}
              onCheckedChange={() => {
                const newVal =
                  mergedSiteSettings?.isGiftCardsEnabledOnStorefront === undefined
                    ? false
                    : !mergedSiteSettings?.isGiftCardsEnabledOnStorefront;
                setSiteSettingsDiff({
                  ...siteSettingsDiff,
                  isGiftCardsEnabledOnStorefront: newVal,
                });
                trackIsGiftCardsEnabledOnStorefrontToggled({
                  chefId: chefId,
                  enabled: newVal,
                });
              }}
            />
          </Label>
          <Label
            text="Custom Name for Drops"
            tooltipText={`By default, your storefront will use the word 'drop' to describe your sales. This input will override this with whatever you like (e.g. 'sale', 'bake', 'pop-up')`}
            css={{ mb: "$xl" }}
          >
            <Input
              value={mergedSiteSettings?.customDropName || ""}
              onChange={(e) => {
                setSiteSettingsDiff({
                  ...siteSettingsDiff,
                  customDropName: e.target.value || null,
                });
                debounce(() => {
                  trackCustomDropNameUpdated({
                    customName: e.target.value,
                  });
                });
              }}
              maxLength={15}
              placeholder="Custom drop name"
            />
          </Label>
          <Label text="About Section">
            <TextArea
              placeholder={"I make the best food."}
              value={mergedSiteSettings?.aboutSection}
              onChange={(e) => {
                setSiteSettingsDiff({
                  ...siteSettingsDiff,
                  aboutSection: e.target.value,
                });
                debounce(() => {
                  trackAboutMeUpdated({
                    aboutMe: e.target.value,
                  });
                });
              }}
            />
          </Label>
          <H2 css={{ mt: "$lg", ff: "$arboria", textStyle: "text-4", color: "$gray12" }}>
            Social Media Links
          </H2>
          <P css={{ ff: "$inter", textStyle: "text-2", color: "$gray11", mb: "$sm" }}>
            Small buttons on your storefront for customers to easily access your socials
          </P>
          <Label text="Instagram Link">
            <Input
              value={mergedSiteSettings?.socialMediaLinks?.instagram || ""}
              onChange={(e) => {
                setSiteSettingsDiff({
                  ...siteSettingsDiff,
                  socialMediaLinks: {
                    ...siteSettingsDiff.socialMediaLinks,
                    instagram: e.target.value || null,
                  },
                });
                debounce(() => {
                  trackSocialMediaLinkUpdated({
                    socialMediaLink: "instagram",
                  });
                });
              }}
              css={{ marginBottom: "1em" }}
              status={
                mergedSiteSettings?.socialMediaLinks?.instagram?.includes("instagram.com/") ===
                false
                  ? "error"
                  : "default" // if undefined or true
              }
            />
          </Label>
          <Label text="Facebook Link">
            <Input
              value={mergedSiteSettings?.socialMediaLinks?.facebook || ""}
              onChange={(e) => {
                setSiteSettingsDiff({
                  ...siteSettingsDiff,
                  socialMediaLinks: {
                    ...siteSettingsDiff.socialMediaLinks,
                    facebook: e.target.value || null,
                  },
                });
                debounce(() => {
                  trackSocialMediaLinkUpdated({
                    socialMediaLink: "facebook",
                  });
                });
              }}
              css={{ marginBottom: "1em" }}
              status={
                mergedSiteSettings?.socialMediaLinks?.facebook?.includes("facebook.com/") === false
                  ? "error"
                  : "default" // if undefined or true
              }
            />
          </Label>
          <Label text="Twitter Link">
            <Input
              value={mergedSiteSettings?.socialMediaLinks?.twitter || ""}
              onChange={(e) => {
                setSiteSettingsDiff({
                  ...siteSettingsDiff,
                  socialMediaLinks: {
                    ...siteSettingsDiff.socialMediaLinks,
                    twitter: e.target.value || null,
                  },
                });
                debounce(() => {
                  trackSocialMediaLinkUpdated({
                    socialMediaLink: "twitter",
                  });
                });
              }}
              css={{ marginBottom: "1em" }}
              status={
                mergedSiteSettings?.socialMediaLinks?.twitter?.includes("twitter.com/") === false
                  ? "error"
                  : "default" // if undefined or true
              }
            />
          </Label>
          <Label text="TikTok Link">
            <Input
              value={mergedSiteSettings?.socialMediaLinks?.tiktok || ""}
              onChange={(e) => {
                setSiteSettingsDiff({
                  ...siteSettingsDiff,
                  socialMediaLinks: {
                    ...siteSettingsDiff.socialMediaLinks,
                    tiktok: e.target.value || null,
                  },
                });
                debounce(() => {
                  trackSocialMediaLinkUpdated({
                    socialMediaLink: "tiktok",
                  });
                });
              }}
              css={{ marginBottom: "1em" }}
              status={
                mergedSiteSettings?.socialMediaLinks?.tiktok?.includes("tiktok.com/") === false
                  ? "error"
                  : "default" // if undefined or true
              }
            />
          </Label>
          <H2 css={{ mt: "$lg", ff: "$arboria", textStyle: "text-4", color: "$gray12" }}>
            {`General Links `}
          </H2>
          <P css={{ ff: "$inter", textStyle: "text-2", color: "$gray11", mb: "$sm" }}>
            These appear as buttons on your storefront, similar to linktree
          </P>
          {mergedSiteSettings?.storefrontLinks?.map((link, i) => (
            <Row key={i} css={{ ai: "baseline", gap: "$xs", flexWrap: "wrap", my: "$xs" }}>
              <Label text="Name">
                <Input
                  value={link.name}
                  onChange={(e) =>
                    setSiteSettingsDiff({
                      ...siteSettingsDiff,
                      storefrontLinks: [
                        ...mergedSiteSettings.storefrontLinks.slice(0, i),
                        { ...mergedSiteSettings.storefrontLinks[i], name: e.target.value },
                        ...mergedSiteSettings.storefrontLinks.slice(i + 1),
                      ],
                    })
                  }
                />
              </Label>

              <Label text="URL">
                <Input
                  value={link.url}
                  onChange={(e) =>
                    setSiteSettingsDiff({
                      ...siteSettingsDiff,
                      storefrontLinks: [
                        ...mergedSiteSettings.storefrontLinks.slice(0, i),
                        { ...mergedSiteSettings.storefrontLinks[i], url: e.target.value },
                        ...mergedSiteSettings.storefrontLinks.slice(i + 1),
                      ],
                    })
                  }
                />
              </Label>
              <IconButton
                icon={<TrashIcon />}
                css={{ mt: "auto", mb: "$xs" }}
                onClick={() => {
                  setSiteSettingsDiff({
                    ...siteSettingsDiff,
                    storefrontLinks:
                      mergedSiteSettings.storefrontLinks?.length === 1
                        ? null
                        : [
                            ...mergedSiteSettings.storefrontLinks.slice(0, i),
                            ...mergedSiteSettings.storefrontLinks.slice(i + 1),
                          ],
                  });
                  trackStorefrontLinkRemoved();
                }}
              />
            </Row>
          ))}
          <Button
            color="gray"
            variant="gray"
            onClick={() => {
              setSiteSettingsDiff({
                ...siteSettingsDiff,
                storefrontLinks: mergedSiteSettings.storefrontLinks
                  ? [
                      ...mergedSiteSettings.storefrontLinks,
                      {
                        name: "",
                        url: "",
                      },
                    ]
                  : [{ name: "", url: "" }],
              });
              trackStorefrontLinkAdded();
            }}
          >
            Add Link
          </Button>
          <H2 css={{ mt: "$lg", ff: "$arboria", textStyle: "text-4", color: "$gray12" }}>FAQs</H2>
          <P css={{ ff: "$inter", textStyle: "text-2", color: "$gray11", mb: "$sm" }}>
            Add in some common customer questions and their answers. These will appear at the bottom
            of your storefront.
          </P>
          {mergedSiteSettings?.faqs?.map((faq, i) => (
            <Column key={i} css={{ my: "$xs", gap: "$xs" }}>
              <Row css={{ ai: "baseline", gap: "$xs", flexWrap: "wrap" }}>
                <Label text="Question">
                  <Input
                    value={faq.question}
                    onChange={(e) =>
                      setSiteSettingsDiff({
                        ...siteSettingsDiff,
                        faqs: [
                          ...mergedSiteSettings.faqs.slice(0, i),
                          { ...mergedSiteSettings.faqs[i], question: e.target.value },
                          ...mergedSiteSettings.faqs.slice(i + 1),
                        ],
                      })
                    }
                  />
                </Label>
                <IconButton
                  icon={<TrashIcon />}
                  css={{ mt: "auto", mb: "$xs" }}
                  onClick={() => {
                    setSiteSettingsDiff({
                      ...siteSettingsDiff,
                      faqs:
                        mergedSiteSettings.faqs?.length === 1
                          ? null
                          : [
                              ...mergedSiteSettings.faqs.slice(0, i),
                              ...mergedSiteSettings.faqs.slice(i + 1),
                            ],
                    });
                    trackFAQRemoved();
                  }}
                />
              </Row>
              <Label text="Answer">
                <TextArea
                  value={faq.answer}
                  onChange={(e) =>
                    setSiteSettingsDiff({
                      ...siteSettingsDiff,
                      faqs: [
                        ...mergedSiteSettings.faqs.slice(0, i),
                        { ...mergedSiteSettings.faqs[i], answer: e.target.value },
                        ...mergedSiteSettings.faqs.slice(i + 1),
                      ],
                    })
                  }
                />
              </Label>
            </Column>
          ))}
          <Button
            color="gray"
            variant="gray"
            onClick={() => {
              setSiteSettingsDiff({
                ...siteSettingsDiff,
                faqs: mergedSiteSettings.faqs
                  ? [
                      ...mergedSiteSettings.faqs,
                      {
                        name: "",
                        url: "",
                      },
                    ]
                  : [{ name: "", url: "" }],
              });
              trackFAQAdded();
            }}
          >
            Add FAQ
          </Button>
        </SettingsCard>

        {/* Change Account Styles */}
        <SettingsCard title="Storefront Branding" id="settings-branding">
          {/* Primary Color Input */}
          <LabeledInput
            label="Primary Color"
            style={{ width: "100%", marginBottom: 24 }}
            tooltip={
              <Tooltip
                text="The main UI color. This affects the color of buttons, icons, and other interactable elements on your storefront."
                placement="right"
              />
            }
            Input={
              <InputColorPreview>
                <VislyInput
                  style={{ width: "min(250px, calc(100% - 62px))" }}
                  value={mergedSiteSettings.styles.primaryColor}
                  onChange={(val) => setPrimaryColor(val.trim())}
                  placeholder="#233434"
                  validationType={validateHexInput(mergedSiteSettings.styles.primaryColor)}
                  validAnimation={
                    validateHexInput(mergedSiteSettings.styles.primaryColor) === "valid" && (
                      <AnimationContainer animation="check" width="24px" height="24px" />
                    )
                  }
                />
                <ColorPreview
                  css={{
                    backgroundColor: mergedSiteSettings.styles.primaryColor,
                  }}
                />
              </InputColorPreview>
            }
          />
          {/* Header Color Input */}
          <LabeledInput
            label="Storefront Header Color"
            style={{ width: "100%", marginBottom: 24 }}
            tooltip={<Tooltip text="The color of your storefront header." placement="right" />}
            Input={
              <InputColorPreview>
                <VislyInput
                  style={{ width: "min(250px, calc(100% - 62px))" }}
                  value={mergedSiteSettings.styles.bannerColor}
                  onChange={(val) => setBannerColor(val.trim())}
                  placeholder="#233434"
                  validationType={validateHexInput(mergedSiteSettings.styles.bannerColor)}
                  validAnimation={
                    validateHexInput(mergedSiteSettings.styles.bannerColor) === "valid" && (
                      <AnimationContainer animation="check" width="24px" height="24px" />
                    )
                  }
                />
                <ColorPreview
                  css={{
                    backgroundColor: mergedSiteSettings.styles.bannerColor,
                  }}
                />
              </InputColorPreview>
            }
          />
          {/* HeaderText Color Input */}
          <LabeledInput
            label="Storefront Header Text Color"
            style={{ width: "100%", marginBottom: 24 }}
            tooltip={
              <Tooltip
                text="The color of your Business Name on your Storefront. This text only shows if you do not have a logo uploaded."
                placement="right"
              />
            }
            Input={
              <InputColorPreview>
                <VislyInput
                  style={{ width: "min(250px, calc(100% - 62px))" }}
                  value={mergedSiteSettings.styles.bannerTextColor}
                  onChange={(val) => setBannerTextColor(val.trim())}
                  placeholder="#FFFFFF"
                  validationType={validateHexInput(mergedSiteSettings.styles.bannerTextColor)}
                  validAnimation={
                    validateHexInput(mergedSiteSettings.styles.bannerTextColor) === "valid" && (
                      <AnimationContainer animation="check" width="24px" height="24px" />
                    )
                  }
                />
                <ColorPreview
                  css={{
                    backgroundColor: mergedSiteSettings.styles.bannerTextColor,
                  }}
                />
              </InputColorPreview>
            }
          />
          {/* Logo Uploadcare Widget & Preview */}
          <LabeledInput
            label="Storefront Small Logo"
            style={{ width: "min(100%, 480px)", marginBottom: 24 }}
            Input={
              <ImageUploader
                onFileLoaded={setBannerImage}
                image={mergedSiteSettings.styles.bannerImage}
                crop={true}
                removeImage={() => setBannerImage({ cdnUrl: "" })}
              />
            }
          />
          <LabeledInput
            label="Storefront Big Logo"
            style={{ width: "min(100%, 480px)", marginBottom: 24 }}
            Input={
              <ImageUploader
                onFileLoaded={setBigLogo}
                image={mergedSiteSettings.styles.bigLogo}
                crop={"574x246 minimum"}
                removeImage={() => setBigLogo({ cdnUrl: "" })}
              />
            }
          />
          <a
            href="https://imagecolorpicker.com/en"
            style={{ fontFamily: "var(--avenir)", fontSize: "12px", marginBottom: 48 }}
          >
            Having trouble with hex color codes? Try uploading your logo here and copy-pasting into
            hotplate
          </a>
          {/* spacer */}
          <div />
          {/* Component Preview */}
          <span style={{ ...textstyles.h4, color: colors.gray800, marginBottom: 24 }}>
            Preview your Branding
          </span>
          <BrandingPreview>
            <StorefrontHeader settings_preview={true} siteSettings={mergedSiteSettings} />
            <PreviewRow>
              <DangerouslyStyledVislyButton
                css={{
                  width: "100%",
                  height: 46,
                  maxWidth: 275,
                  minWidth: 0,
                  backgroundColor: primaryColor,
                  color: textColor + " !important",
                  border: primaryColorHover,
                  "&:hover": {
                    backgroundColor: primaryColorHover + " !important",
                  },
                  "&:active": {
                    backgroundColor: primaryColorActive + " !important",
                  },
                  "&:disabled": {
                    backgroundColor: primaryColorLight + " !important",
                  },
                  "& *": {
                    color: textColor + " !important",
                  },
                }}
                rounded
                withText
                text={"Add to Cart"}
                rightText={`$4.20`}
              />
              <CartButton
                chefStyles={chefStyles}
                cartItems={[{ quantity: 2 }]}
                css={{
                  display: "flex !important",
                  width: "275px",
                  zIndex: 1,
                  height: "46px",
                  "& > *": {
                    margin: 0,
                  },
                }}
              />
            </PreviewRow>
            <PreviewRow css={{ alignItems: "center" }}>
              <Field
                style={{ flexShrink: 1, marginRight: 24 }}
                FieldIcon={<FieldIcon chefStyles={chefStyles} icon={<SewingPinFilledIcon />} />}
                containsSubtitle={true}
                title={mergedSiteSettings.restaurantName}
                subtitle={"1234 Example Address"}
              />
              <TextSwitch
                uncheckedContent="Pickup"
                checkedContent="Delivery"
                width={95}
                id="cartTypeSwitch"
                chefStyles={chefStyles}
              />
            </PreviewRow>
          </BrandingPreview>
        </SettingsCard>
        {/* Drop Settings */}
        <SettingsCard title="Drop Controls" id="settings-drop">
          <P css={{ ff: "$inter", textStyle: "text-2", color: "$gray11", mb: "$md" }}>
            We recommend keeping these features enabled to boost your sales and offer a unique
            shopping experience to your customers
          </P>
          <Label
            text="Hide the customer chat that is displayed the 30 mins leading up to a sale opening"
            side="right"
            css={{ mb: "$xl" }}
          >
            <Switch
              checked={mergedSiteSettings?.isDropChatHidden}
              onCheckedChange={() => {
                setSiteSettingsDiff({
                  ...siteSettingsDiff,
                  isDropChatHidden: !mergedSiteSettings?.isDropChatHidden,
                });
                trackHideDropChatToggled({
                  enabled: !mergedSiteSettings?.isDropChatHidden,
                });
              }}
            />
          </Label>
          <Label
            text="Do not display the number of customers currently viewing my sale"
            side="right"
            css={{ mb: "$xl" }}
          >
            <Switch
              checked={mergedSiteSettings?.isPresenceOnDropHidden}
              onCheckedChange={() => {
                setSiteSettingsDiff({
                  ...siteSettingsDiff,
                  isPresenceOnDropHidden: !mergedSiteSettings?.isPresenceOnDropHidden,
                });
                trackHidePresenceToggled({
                  enabled: !mergedSiteSettings?.isPresenceOnDropHidden,
                });
              }}
            />
          </Label>
          <Label
            text="Cap the amount of an item 'in cart' displayed to customers at 500"
            side="right"
            css={{ mb: "$xl" }}
          >
            <Switch
              checked={mergedSiteSettings?.isVisibleNumItemsInCartCapped}
              onCheckedChange={() => {
                setSiteSettingsDiff({
                  ...siteSettingsDiff,
                  isVisibleNumItemsInCartCapped: !mergedSiteSettings?.isVisibleNumItemsInCartCapped,
                });
                trackVisibleItemsInCartsCapToggled({
                  enabled: !mergedSiteSettings?.isVisibleNumItemsInCartCapped,
                });
              }}
            />
          </Label>
        </SettingsCard>
        <SettingsCard title="Checkout" id="settings-checkout">
          <LabeledInput
            label="Tip Options"
            style={{ width: "100%", marginBottom: 24 }}
            tooltip={
              <Tooltip
                text="Customers always have the option to choose a custom tip value. Percentages are out of the order subtotal."
                placement="right"
              />
            }
            Input={
              <div>
                <Select
                  onValueChange={(value) => {
                    setTipOptions(value);
                  }}
                  value={JSON.stringify(mergedSiteSettings.tipOptions || tipOptionArrays[2])} // if you change this line, search the codebase for TIP_OPTION_DEFAULT_523894 and keep in sync
                >
                  <Select.Option value={JSON.stringify(tipOptionArrays[0])}>
                    5% / 10% / 15%
                  </Select.Option>
                  <Select.Option value={JSON.stringify(tipOptionArrays[1])}>
                    10% / 15% / 20%
                  </Select.Option>
                  <Select.Option value={JSON.stringify(tipOptionArrays[2])}>
                    15% / 20% / 25%
                  </Select.Option>
                  <Select.Option value={JSON.stringify(tipOptionArrays[3])}>
                    20% / 25% / 30%
                  </Select.Option>
                </Select>
                <div style={{ marginTop: "10px" }}>
                  <Checkbox
                    checked={!!mergedSiteSettings.isTipDefaultZero}
                    style={{ display: "inline-flex", verticalAlign: "bottom" }}
                    onChange={(bool) => {
                      setIsTipDefaultZero(bool);
                    }}
                  />{" "}
                  <Text
                    css={{
                      ff: "$avenir",
                      fw: "$normal",
                      fs: 14,
                      color: "$gray11",
                      lineHeight: 1.4,
                      marginBottom: "$md",
                      display: "inline",
                    }}
                  >
                    Make $0.00 the default.
                  </Text>
                </div>
              </div>
            }
          />
        </SettingsCard>

        {/* Notifications Settings */}
        <SettingsCard title="SMS Notifications" id="settings-notifications">
          {/* Default message texts should be the starting value in every host object, there should also be an option to reset to default.  */}
          <LabeledInput
            className="vmargin-8px mw-480px"
            label="Confirmation Message"
            style={{ marginBottom: 24 }}
            tooltip={
              <Tooltip text="This is the message your customers will get after they place their order." />
            }
            Input={
              <SmartTextArea
                maxLength={250}
                style={
                  !mergedSiteSettings.notifications.messageOnOrder.enabled
                    ? { display: "none" }
                    : {}
                }
                placeholder={messageDefaults.messageOnOrder}
                text={mergedSiteSettings.notifications.messageOnOrder.text} // TODO: need to make this consistent with backend
                onChange={(val) => setNotificationText(val, "messageOnOrder")}
              />
            }
          />

          <LabeledInput
            className="vmargin-8px mw-480px"
            label="24hr Reminder"
            disabled={!mergedSiteSettings.notifications.message24HoursBefore.enabled}
            tooltip={
              <TooltipSwitch>
                <Tooltip text="A reminder sent 24 hours before a customer's pickup time. Only applies to pickup pre-orders." />
                <Switch
                  checked={mergedSiteSettings.notifications.message24HoursBefore.enabled}
                  onCheckedChange={() => toggleNotificationEnabled("message24HoursBefore")}
                />
              </TooltipSwitch>
            }
            Input={
              <SmartTextArea
                maxLength={250}
                style={
                  !mergedSiteSettings.notifications.message24HoursBefore.enabled
                    ? { display: "none" }
                    : {}
                }
                disabled={!mergedSiteSettings.notifications.message24HoursBefore.enabled}
                placeholder={messageDefaults.message24HoursBefore}
                text={mergedSiteSettings.notifications.message24HoursBefore.text}
                onChange={(val) => setNotificationText(val, "message24HoursBefore")}
              />
            }
          />

          <LabeledInput
            className="vmargin-8px mw-480px"
            label="2hr Reminder"
            disabled={!mergedSiteSettings.notifications.message2HoursBefore.enabled}
            tooltip={
              <TooltipSwitch>
                <Tooltip text="A reminder sent 2 hours before a customer's pickup time. Only applies to pickup pre-orders." />
                <Switch
                  checked={mergedSiteSettings.notifications.message2HoursBefore.enabled}
                  onCheckedChange={() => toggleNotificationEnabled("message2HoursBefore")}
                />
              </TooltipSwitch>
            }
            Input={
              <SmartTextArea
                style={
                  !mergedSiteSettings.notifications.message2HoursBefore.enabled
                    ? { display: "none" }
                    : {}
                }
                disabled={!mergedSiteSettings.notifications.message2HoursBefore.enabled}
                maxLength={250}
                placeholder={messageDefaults.message2HoursBefore}
                text={mergedSiteSettings.notifications.message2HoursBefore.text}
                onChange={(val) => setNotificationText(val, "message2HoursBefore")}
              />
            }
          />

          <LabeledInput
            className="vmargin-8px mw-480px"
            label="15min Reminder"
            disabled={!mergedSiteSettings.notifications.messageAtPickupTime.enabled}
            tooltip={
              <TooltipSwitch>
                <Tooltip text="A reminder sent 15 minutes before a customer's pickup time. Only applies to pickup pre-orders." />
                <Switch
                  checked={mergedSiteSettings.notifications.messageAtPickupTime.enabled}
                  onCheckedChange={() => toggleNotificationEnabled("messageAtPickupTime")}
                />
              </TooltipSwitch>
            }
            Input={
              <SmartTextArea
                style={
                  !mergedSiteSettings.notifications.messageAtPickupTime.enabled
                    ? { display: "none" }
                    : {}
                }
                disabled={!mergedSiteSettings.notifications.messageAtPickupTime.enabled}
                maxLength={250}
                placeholder={messageDefaults.messageAtPickupTime}
                text={mergedSiteSettings.notifications.messageAtPickupTime.text}
                onChange={(val) => setNotificationText(val, "messageAtPickupTime")}
              />
            }
          />

          <LabeledInput
            className="vmargin-8px mw-480px"
            label="Order Completed"
            disabled={!mergedSiteSettings.notifications.onCompleteNotification.enabled}
            tooltip={
              <TooltipSwitch>
                <Tooltip text="A text sent to customers when you mark their order as 'Complete' on their order ticket." />
                <Switch
                  checked={mergedSiteSettings.notifications.onCompleteNotification.enabled}
                  onCheckedChange={() => toggleNotificationEnabled("onCompleteNotification")}
                />
              </TooltipSwitch>
            }
            Input={
              <SmartTextArea
                style={
                  !mergedSiteSettings.notifications.onCompleteNotification.enabled
                    ? { display: "none" }
                    : {}
                }
                disabled={!mergedSiteSettings.notifications.onCompleteNotification.enabled}
                maxLength={250}
                placeholder={messageDefaults.onCompleteNotification}
                text={mergedSiteSettings.notifications.onCompleteNotification.text}
                onChange={(val) => setNotificationText(val, "onCompleteNotification")}
              />
            }
          />
          <WarningBubble text="All SMS notifications have the customer's confirmation link appended to the end of the text. Additionally, updates to notifications will be automatically applied to all messages that have not yet been sent." />
        </SettingsCard>

        {/* Discount Codes */}
        <SettingsCard title="Discounts" id="settings-discounts">
          <Text
            css={{
              ff: "$avenir",
              fw: "$normal",
              fs: 14,
              color: "$gray11",
              lh: "$text",
              marginBottom: "$md",
            }}
          >
            Codes that will apply a discount to a customer&apos;s subtotal at checkout
          </Text>
          {_.isEmpty(discountCodes) ? (
            <Flex
              css={{
                display: "flex",
                flexDirection: "column",
                jc: "center",
                ai: "center",
                padding: "$lg",
                borderRadius: "$md",
                backgroundColor: "$accent2",
                alignSelf: "center",
                width: "75%",
                minWidth: 275,
                my: "$md",
              }}
            >
              <Text
                css={{
                  textAlign: "center",
                  ff: "$avenir",
                  fw: "$normal",
                  fs: 15,
                  lh: "$text",
                  color: "$gray12",
                }}
              >
                You haven&apos;t created any discounts yet. When you do, they will appear here.
              </Text>
              <Button
                color="success"
                variant="tinted"
                css={{
                  marginTop: "$md",
                }}
                onClick={() => {
                  trackCreateDiscountCodeClicked();
                  setShowNewDiscountCodeModal(true);
                }}
              >
                Create your first code
              </Button>
            </Flex>
          ) : (
            <Flex css={{ flexDirection: "column" }}>
              <Flex css={{ display: "grid", gap: 12, gridAutoFlow: "row" }}>
                {Object.entries(discountCodes)
                  .filter(([id, discountCode]) => !discountCode.archived) // eslint-disable-line @typescript-eslint/no-unused-vars
                  .map(([id, discountCode]) => (
                    <DiscountCodeButton
                      key={id}
                      onClick={() => {
                        setIdOfDiscountBeingViewed(id);
                      }}
                      discountCode={discountCode}
                      totalTimesUsed={
                        Number.isInteger(discountCode.totalTimesUsed)
                          ? discountCode.totalTimesUsed
                          : 0
                      }
                    />
                  ))}
              </Flex>
              <Button
                color="success"
                variant="tinted"
                size="medium"
                css={{ width: "100%", maxWidth: "300px", marginTop: 24 }}
                onClick={() => {
                  trackCreateDiscountCodeClicked();
                  setShowNewDiscountCodeModal(true);
                }}
              >
                Create new code
              </Button>
            </Flex>
          )}
        </SettingsCard>

        <SettingsCard title="Square" id="settings-square">
          {getSquareMerchant.isLoading ? (
            <ReactLoading type={"spin"} color={"#000000"} height={"20px"} width={"20px"} />
          ) : getSquareMerchant.data ? (
            <div>Connected to {getSquareMerchant.data.businessName}</div>
          ) : (
            <Button
              color="success"
              variant="tinted"
              size="medium"
              css={{ width: "100%", maxWidth: "300px", marginTop: 24 }}
              onClick={async () => {
                trackConnectToSquareClicked();
                if (!["testtests", "bellabakes", "bubandgrandmas"].includes(chefId)) {
                  return;
                }
                const url = await getSquareAuthURL.mutateAsync();
                window.location.href = url;
              }}
            >
              Connect to <SquareLogo style={{ width: "25%" }} />
            </Button>
          )}
        </SettingsCard>

        <ButtonContainer>
          <VislyButton
            text="Save Changes"
            kind="primary"
            loading={saveAccountSettingsDiff.isLoading}
            disabled={!isModified || !enableSaveAccountSettingsButton()}
            loadingAnimation={
              <ReactLoading type={"bubbles"} color={"#FFFFFF"} height={50} width={50} />
            }
            style={{ flexGrow: 1, maxWidth: "200px" }}
            onClick={saveAccountSettingsWrapped}
          />
        </ButtonContainer>
        {saveAccountSettingsDiff.error && (
          <span style={{ ...textstyles.body, color: colors.primary800 }}>
            There was an error saving your settings: {saveAccountSettingsDiff.error + ""}
          </span>
        )}
      </AccountSettingsContent>

      {qrCodeLink !== "" && qrCodeLink !== null && (
        <QRCode
          style={{ zIndex: -10 }}
          bgColor={"transparent"}
          id="qr-gen"
          value={qrCodeLink}
          size={256}
          level="L"
          includeMargin={true}
        />
      )}

      <CreateDiscountCodeModal
        chefStyles={chefStyles}
        onRequestClose={() => {
          setShowNewDiscountCodeModal(false);
        }}
        isOpen={showNewDiscountCodeModal}
        activeCodes={activeCodes}
      />

      {idOfDiscountBeingViewed !== null && (
        <DiscountCodeDetailsModal
          onRequestClose={() => {
            setIdOfDiscountBeingViewed(null);
          }}
          isOpen={true}
          hostId={chefId}
          discountCode={discountCodes[idOfDiscountBeingViewed]}
        />
      )}
    </AccountSettingsPage>
  );
}
