// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./PayoutHist.Header.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      DXZnG99ivE: {
        none: {
          text: "INITIATED",
        },
      },
      WBMyyPLCis: {
        none: {
          text: "ACCOUNT",
        },
      },
      MaFkViAcMb: {
        none: {
          text: "AMOUNT",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xlarge",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "xsmall",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xlarge",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "xsmall",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["xlarge", "large", "med", "small", "xsmall"],
  },
];

export const HeaderContext = createContext(null);

function Header(_props) {
  const defaults = useContext(HeaderContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="VEBFGurDz2"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "VEBFGurDz2",
        scope: "25EgXfeo3Y",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_P1ngPxQuBd"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_25EgXfeo3Y_P1ngPxQuBd"
          }
          key={"P1ngPxQuBd"}
          addSpacing={false}
          element={getStyle("P1ngPxQuBd", "htmlElement")}
        >
          <ContainerPrimitive
            id={"amount_Jii5x9vjnN"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_25EgXfeo3Y_Jii5x9vjnN"
            }
            key={"Jii5x9vjnN"}
            addSpacing={false}
            element={getStyle("Jii5x9vjnN", "htmlElement")}
          >
            {
              <TextPrimitive
                id={"amount_MaFkViAcMb"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_25EgXfeo3Y_MaFkViAcMb"
                }
                key={"MaFkViAcMb"}
                text={getStyle("MaFkViAcMb", "text")}
                element={getStyle("MaFkViAcMb", "htmlElement")}
              />
            }
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"account_C79TLfuNcY"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_25EgXfeo3Y_C79TLfuNcY"
            }
            key={"C79TLfuNcY"}
            addSpacing={false}
            element={getStyle("C79TLfuNcY", "htmlElement")}
          >
            {
              <TextPrimitive
                id={"bank_WBMyyPLCis"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_25EgXfeo3Y_WBMyyPLCis"
                }
                key={"WBMyyPLCis"}
                text={getStyle("WBMyyPLCis", "text")}
                element={getStyle("WBMyyPLCis", "htmlElement")}
              />
            }
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"date_Mvdfw4no1r"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_25EgXfeo3Y_Mvdfw4no1r"
            }
            key={"Mvdfw4no1r"}
            addSpacing={false}
            element={getStyle("Mvdfw4no1r", "htmlElement")}
          >
            {
              <TextPrimitive
                id={"date_DXZnG99ivE"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_25EgXfeo3Y_DXZnG99ivE"
                }
                key={"DXZnG99ivE"}
                text={getStyle("DXZnG99ivE", "text")}
                element={getStyle("DXZnG99ivE", "htmlElement")}
              />
            }
          </ContainerPrimitive>
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

Header.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["xlarge", "large", "med", "small", "xsmall"],
  },
];

export default Header;
