// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./EventStats.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";
import {
  default as OrdersPlaced,
  StatisticsBlockContext as OrdersPlacedContext,
  default as ItemsSold,
  StatisticsBlockContext as ItemsSoldContext,
  default as NetSales,
  StatisticsBlockContext as NetSalesContext,
  default as TotalTips,
  StatisticsBlockContext as TotalTipsContext,
  default as TaxCollected,
  StatisticsBlockContext as TaxCollectedContext,
  default as ProcessingFees,
  StatisticsBlockContext as ProcessingFeesContext,
} from "../Events/StatisticsBlock";
import {
  default as ExportCsvButton,
  ButtonContext as ExportCsvButtonContext,
} from "../Primitives/Button";
import {
  default as ConfigHeader,
  ConfigHeaderContext,
} from "../Events/ConfigHeader";

const styles = [
  {
    type: "default",
    layers: {
      "9ZghEJJ7xc": {
        none: {
          text: "Basic",
        },
      },
      "2oA4FZfWTe": {
        none: {
          text: "Financials",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "showTimeSlotSummary",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      Hei9Lv4MQm: {
        none: {
          title: "X",
          subtitle: "Orders Placed",
          color: "lightBlue",
        },
      },
      VJoJxZQ7vm: {
        none: {
          title: "X",
          subtitle: "Items Sold",
          color: "darkBlue",
        },
      },
      "5EQsTqXwkn": {
        none: {
          title: "X,XXX.XX",
          subtitle: "Net Sales",
          showDollarSign: true,
          color: "darkGreen",
        },
      },
      Uw3aHMYLX7: {
        none: {
          title: "XXX.XX",
          subtitle: "Total Tips",
          showDollarSign: true,
          color: "lightGreen",
        },
      },
      DDXhKqfLu9: {
        none: {
          title: "XXX.XX",
          subtitle: "Tax Collected",
          showDollarSign: true,
          type: "small",
          color: "lightPurple",
        },
      },
      EcMhekdgPG: {
        none: {
          title: "XXX.XX",
          subtitle: "Processing Fees",
          showDollarSign: true,
          type: "small",
          color: "lightOrange",
        },
      },
      RPSyiLq2gS: {
        none: {
          text: "Export to CSV",
          icon: icons.download,
          rounded: true,
          withIcon: "left",
        },
      },
      "6aCDLPMpWG": {
        none: {
          title: "Time Slot Summary",
          subtitle:
            "An overview of this event's time slot availability, updated live",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "showTimeSlotSummary",
    layers: {
      Hei9Lv4MQm: {},
      VJoJxZQ7vm: {},
      "5EQsTqXwkn": {},
      Uw3aHMYLX7: {},
      DDXhKqfLu9: {},
      EcMhekdgPG: {},
      RPSyiLq2gS: {},
      "6aCDLPMpWG": {},
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {
          Hei9Lv4MQm: {},
          VJoJxZQ7vm: {},
          "5EQsTqXwkn": {},
          Uw3aHMYLX7: {},
          DDXhKqfLu9: {
            none: {
              type: "small",
            },
          },
          EcMhekdgPG: {
            none: {
              type: "small",
            },
          },
          RPSyiLq2gS: {},
          "6aCDLPMpWG": {},
        },
      },
      {
        propValue: "small",
        layers: {
          Hei9Lv4MQm: {},
          VJoJxZQ7vm: {},
          "5EQsTqXwkn": {},
          Uw3aHMYLX7: {},
          DDXhKqfLu9: {},
          EcMhekdgPG: {},
          RPSyiLq2gS: {},
          "6aCDLPMpWG": {},
        },
      },
      {
        propValue: "med",
        layers: {
          Hei9Lv4MQm: {},
          VJoJxZQ7vm: {},
          "5EQsTqXwkn": {},
          Uw3aHMYLX7: {},
          DDXhKqfLu9: {
            none: {
              showDollarSign: true,
              type: "normal",
            },
          },
          EcMhekdgPG: {
            none: {
              type: "normal",
            },
          },
          RPSyiLq2gS: {},
          "6aCDLPMpWG": {},
        },
      },
      {
        propValue: "large",
        layers: {
          Hei9Lv4MQm: {},
          VJoJxZQ7vm: {},
          "5EQsTqXwkn": {},
          Uw3aHMYLX7: {},
          DDXhKqfLu9: {
            none: {
              type: "normal",
            },
          },
          EcMhekdgPG: {
            none: {
              type: "normal",
            },
          },
          RPSyiLq2gS: {},
          "6aCDLPMpWG": {},
        },
      },
      {
        propValue: "xlarge",
        layers: {
          Hei9Lv4MQm: {},
          VJoJxZQ7vm: {},
          "5EQsTqXwkn": {},
          Uw3aHMYLX7: {},
          DDXhKqfLu9: {
            none: {
              type: "normal",
            },
          },
          EcMhekdgPG: {
            none: {
              type: "normal",
            },
          },
          RPSyiLq2gS: {},
          "6aCDLPMpWG": {},
        },
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "showTimeSlotSummary",
  },
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export const EventStatsContext = createContext(null);

function EventStats(_props) {
  const defaults = useContext(EventStatsContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="7AcTcPNHBF"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "7AcTcPNHBF",
        scope: "DbyVV82tf4",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"stats-container_7n47sAzG7r"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_7n47sAzG7r"
            }
            key={"7n47sAzG7r"}
            addSpacing={true}
            element={getStyle("7n47sAzG7r", "htmlElement")}
          >
            <ContainerPrimitive
              id={"basic-stats-container_BWffCS9eDc"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_BWffCS9eDc"
              }
              key={"BWffCS9eDc"}
              addSpacing={false}
              element={getStyle("BWffCS9eDc", "htmlElement")}
            >
              <TextPrimitive
                id={"Text_9ZghEJJ7xc"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_9ZghEJJ7xc"
                }
                key={"9ZghEJJ7xc"}
                text={getStyle("9ZghEJJ7xc", "text")}
                element={getStyle("9ZghEJJ7xc", "htmlElement")}
              />
              <SpacerPrimitive
                id={"Spacer_YHEVKzjLXM"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_YHEVKzjLXM"
                }
                key={"YHEVKzjLXM"}
              />
              <ContainerPrimitive
                id={"basic-statblocks-container_J1FQpKp4ds"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_J1FQpKp4ds"
                }
                key={"J1FQpKp4ds"}
                addSpacing={false}
                element={getStyle("J1FQpKp4ds", "htmlElement")}
              >
                {[
                  props.OrdersPlaced === undefined ? (
                    <OrdersPlaced
                      key={"Hei9Lv4MQm"}
                      {...getCompositeDefaultProps("Hei9Lv4MQm")}
                      id="OrdersPlaced_Hei9Lv4MQm"
                      className="__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_Hei9Lv4MQm"
                    />
                  ) : (
                    <OrdersPlacedContext.Provider
                      key="Hei9Lv4MQm-provider"
                      value={{
                        key: "Hei9Lv4MQm",
                        id: "OrdersPlaced_Hei9Lv4MQm",
                        className:
                          "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_Hei9Lv4MQm",
                        ...getCompositeDefaultProps("Hei9Lv4MQm"),
                      }}
                    >
                      {props.OrdersPlaced}
                    </OrdersPlacedContext.Provider>
                  ),
                  <SpacerPrimitive
                    id={"Spacer_MceyWMxDRk"}
                    className={
                      "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_MceyWMxDRk"
                    }
                    key={"MceyWMxDRk"}
                  />,
                  props.ItemsSold === undefined ? (
                    <ItemsSold
                      key={"VJoJxZQ7vm"}
                      {...getCompositeDefaultProps("VJoJxZQ7vm")}
                      id="ItemsSold_VJoJxZQ7vm"
                      className="__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_VJoJxZQ7vm"
                    />
                  ) : (
                    <ItemsSoldContext.Provider
                      key="VJoJxZQ7vm-provider"
                      value={{
                        key: "VJoJxZQ7vm",
                        id: "ItemsSold_VJoJxZQ7vm",
                        className:
                          "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_VJoJxZQ7vm",
                        ...getCompositeDefaultProps("VJoJxZQ7vm"),
                      }}
                    >
                      {props.ItemsSold}
                    </ItemsSoldContext.Provider>
                  ),
                ]}
              </ContainerPrimitive>
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"financials-stats-container_j49LxQBpvn"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_j49LxQBpvn"
              }
              key={"j49LxQBpvn"}
              addSpacing={false}
              element={getStyle("j49LxQBpvn", "htmlElement")}
            >
              <TextPrimitive
                id={"Text_2oA4FZfWTe"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_2oA4FZfWTe"
                }
                key={"2oA4FZfWTe"}
                text={getStyle("2oA4FZfWTe", "text")}
                element={getStyle("2oA4FZfWTe", "htmlElement")}
              />
              <SpacerPrimitive
                id={"Spacer_6VbXzacycb"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_6VbXzacycb"
                }
                key={"6VbXzacycb"}
              />
              <ContainerPrimitive
                id={"financials-statblocks-container_Q6DqhQsqEG"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_Q6DqhQsqEG"
                }
                key={"Q6DqhQsqEG"}
                addSpacing={false}
                element={getStyle("Q6DqhQsqEG", "htmlElement")}
              >
                <ContainerPrimitive
                  id={"sales-tips_9q6kAnCMFG"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_9q6kAnCMFG"
                  }
                  key={"9q6kAnCMFG"}
                  addSpacing={false}
                  element={getStyle("9q6kAnCMFG", "htmlElement")}
                >
                  {[
                    props.NetSales === undefined ? (
                      <NetSales
                        key={"5EQsTqXwkn"}
                        {...getCompositeDefaultProps("5EQsTqXwkn")}
                        id="NetSales_5EQsTqXwkn"
                        className="__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_5EQsTqXwkn"
                      />
                    ) : (
                      <NetSalesContext.Provider
                        key="5EQsTqXwkn-provider"
                        value={{
                          key: "5EQsTqXwkn",
                          id: "NetSales_5EQsTqXwkn",
                          className:
                            "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_5EQsTqXwkn",
                          ...getCompositeDefaultProps("5EQsTqXwkn"),
                        }}
                      >
                        {props.NetSales}
                      </NetSalesContext.Provider>
                    ),
                    <SpacerPrimitive
                      id={"Spacer_DNL5MVVng8"}
                      className={
                        "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_DNL5MVVng8"
                      }
                      key={"DNL5MVVng8"}
                    />,
                    props.TotalTips === undefined ? (
                      <TotalTips
                        key={"Uw3aHMYLX7"}
                        {...getCompositeDefaultProps("Uw3aHMYLX7")}
                        id="TotalTips_Uw3aHMYLX7"
                        className="__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_Uw3aHMYLX7"
                      />
                    ) : (
                      <TotalTipsContext.Provider
                        key="Uw3aHMYLX7-provider"
                        value={{
                          key: "Uw3aHMYLX7",
                          id: "TotalTips_Uw3aHMYLX7",
                          className:
                            "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_Uw3aHMYLX7",
                          ...getCompositeDefaultProps("Uw3aHMYLX7"),
                        }}
                      >
                        {props.TotalTips}
                      </TotalTipsContext.Provider>
                    ),
                  ]}
                </ContainerPrimitive>
                <SpacerPrimitive
                  id={"Spacer_CupkD8piwU"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_CupkD8piwU"
                  }
                  key={"CupkD8piwU"}
                />
                <ContainerPrimitive
                  id={"tax-fees-container_RTpwRLEceR"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_RTpwRLEceR"
                  }
                  key={"RTpwRLEceR"}
                  addSpacing={false}
                  element={getStyle("RTpwRLEceR", "htmlElement")}
                >
                  {[
                    props.TaxCollected === undefined ? (
                      <TaxCollected
                        key={"DDXhKqfLu9"}
                        {...getCompositeDefaultProps("DDXhKqfLu9")}
                        id="TaxCollected_DDXhKqfLu9"
                        className="__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_DDXhKqfLu9"
                      />
                    ) : (
                      <TaxCollectedContext.Provider
                        key="DDXhKqfLu9-provider"
                        value={{
                          key: "DDXhKqfLu9",
                          id: "TaxCollected_DDXhKqfLu9",
                          className:
                            "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_DDXhKqfLu9",
                          ...getCompositeDefaultProps("DDXhKqfLu9"),
                        }}
                      >
                        {props.TaxCollected}
                      </TaxCollectedContext.Provider>
                    ),
                    <SpacerPrimitive
                      id={"Spacer_YNHvLJKqEF"}
                      className={
                        "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_YNHvLJKqEF"
                      }
                      key={"YNHvLJKqEF"}
                    />,
                    props.ProcessingFees === undefined ? (
                      <ProcessingFees
                        key={"EcMhekdgPG"}
                        {...getCompositeDefaultProps("EcMhekdgPG")}
                        id="ProcessingFees_EcMhekdgPG"
                        className="__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_EcMhekdgPG"
                      />
                    ) : (
                      <ProcessingFeesContext.Provider
                        key="EcMhekdgPG-provider"
                        value={{
                          key: "EcMhekdgPG",
                          id: "ProcessingFees_EcMhekdgPG",
                          className:
                            "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_EcMhekdgPG",
                          ...getCompositeDefaultProps("EcMhekdgPG"),
                        }}
                      >
                        {props.ProcessingFees}
                      </ProcessingFeesContext.Provider>
                    ),
                  ]}
                </ContainerPrimitive>
              </ContainerPrimitive>
            </ContainerPrimitive>
            <ContainerPrimitive
              key={"qBt9Bz1Z85"}
              id={"Slot_qBt9Bz1Z85"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_qBt9Bz1Z85"
              }
              addSpacing={false}
            >
              {props.children}
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"export-to-csv-container_Fck2GZ5iKv"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_Fck2GZ5iKv"
              }
              key={"Fck2GZ5iKv"}
              addSpacing={false}
              element={getStyle("Fck2GZ5iKv", "htmlElement")}
            >
              {props.ExportCsvButton === undefined ? (
                <ExportCsvButton
                  key={"RPSyiLq2gS"}
                  {...getCompositeDefaultProps("RPSyiLq2gS")}
                  id="ExportCsvButton_RPSyiLq2gS"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_RPSyiLq2gS"
                />
              ) : (
                <ExportCsvButtonContext.Provider
                  key="RPSyiLq2gS-provider"
                  value={{
                    key: "RPSyiLq2gS",
                    id: "ExportCsvButton_RPSyiLq2gS",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_RPSyiLq2gS",
                    ...getCompositeDefaultProps("RPSyiLq2gS"),
                  }}
                >
                  {props.ExportCsvButton}
                </ExportCsvButtonContext.Provider>
              )}
            </ContainerPrimitive>
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"timeslot-summary-container_9MY7cxV5qh"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_9MY7cxV5qh"
            }
            key={"9MY7cxV5qh"}
            addSpacing={true}
            element={getStyle("9MY7cxV5qh", "htmlElement")}
          >
            <ContainerPrimitive
              id={"divider_DFXZYPJous"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_DFXZYPJous"
              }
              key={"DFXZYPJous"}
              addSpacing={false}
              element={getStyle("DFXZYPJous", "htmlElement")}
            >
              {null}
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"Container_LfhkNQKNAX"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_LfhkNQKNAX"
              }
              key={"LfhkNQKNAX"}
              addSpacing={false}
              element={getStyle("LfhkNQKNAX", "htmlElement")}
            >
              {props.ConfigHeader === undefined ? (
                <ConfigHeader
                  key={"6aCDLPMpWG"}
                  {...getCompositeDefaultProps("6aCDLPMpWG")}
                  id="ConfigHeader_6aCDLPMpWG"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_6aCDLPMpWG"
                />
              ) : (
                <ConfigHeaderContext.Provider
                  key="6aCDLPMpWG-provider"
                  value={{
                    key: "6aCDLPMpWG",
                    id: "ConfigHeader_6aCDLPMpWG",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_6aCDLPMpWG",
                    ...getCompositeDefaultProps("6aCDLPMpWG"),
                  }}
                >
                  {props.ConfigHeader}
                </ConfigHeaderContext.Provider>
              )}
            </ContainerPrimitive>
            <ContainerPrimitive
              key={"5q1oc9KqZM"}
              id={"Slot_5q1oc9KqZM"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_DbyVV82tf4_5q1oc9KqZM"
              }
              addSpacing={false}
            >
              {props.timeSlots}
            </ContainerPrimitive>
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

EventStats.OrdersPlaced = OrdersPlaced;
EventStats.ItemsSold = ItemsSold;
EventStats.NetSales = NetSales;
EventStats.TotalTips = TotalTips;
EventStats.TaxCollected = TaxCollected;
EventStats.ProcessingFees = ProcessingFees;
EventStats.ExportCsvButton = ExportCsvButton;
EventStats.ConfigHeader = ConfigHeader;
EventStats.__variants = [
  {
    name: "showTimeSlotSummary",
    type: "variant",
  },
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export default EventStats;
