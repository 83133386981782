import { Widget } from "@uploadcare/react-widget";
import React from "react";
import { Cross2Icon, ImageIcon } from "@radix-ui/react-icons";
import { Button } from "../../hotplate-common/primitives/Button";
import { colors, icons, textstyles } from "../../visly";
import "./css/ImageUploader.css";

const ImageUploader = ({
  image,
  defaultImage,
  noImageText,
  onFileLoaded,
  crop,
  removeImage,
  maxHeight,
  containerStyle,
}) => {
  if (image) {
    return (
      <div style={{ ...containerStyle, display: "flex", flexDirection: "column" }}>
        <div className="imageEditContainer">
          <img
            className="imageEditImg"
            src={image}
            style={{
              maxWidth: "100%",
              borderRadius: "6px",
              maxHeight: maxHeight ? maxHeight : "100%",
            }}
          />
          <div className="imageEditIconContainer">
            <img className="imageEditIcon" src={icons.edit} />
          </div>
          <Widget
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
            publicKey="de7f2933f7047c1b669a"
            imagesOnly={true} // for Gifs, set to false
            previewStep={true}
            value={image === defaultImage ? null : image}
            onChange={onFileLoaded}
            crop={crop}
            imageShrink="2400x2400"
          />
        </div>
        {removeImage && (
          <Button
            color="danger"
            variant="tinted"
            onClick={removeImage}
            css={{ width: "200px", marginTop: "24px" }}
          >
            <Cross2Icon />
            Remove
          </Button>
        )}
      </div>
    );
  } else {
    return (
      <div style={{ display: "flex", flexFlow: "column" }}>
        {noImageText && (
          <p
            style={{
              color: colors.gray600,
              marginBottom: "24px",
              marginTop: "16px",
              ...textstyles.body,
            }}
          >
            {noImageText}
          </p>
        )}
        <div className="imageEditContainer">
          <Button css={{ mTop: "$xs" }} color="gray" variant="tinted">
            <ImageIcon />
            Upload
          </Button>
          <Widget
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
            publicKey="de7f2933f7047c1b669a"
            imagesOnly={true}
            previewStep={true}
            value={image === defaultImage ? null : image}
            onChange={onFileLoaded.bind(this)}
            crop={crop}
            imageShrink="2400x2400"
          />
        </div>
      </div>
    );
  }
};

export default ImageUploader;
