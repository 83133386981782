import React from "react";

const html =
  // eslint-disable-next-line quotes
  '<a href="https://www.iubenda.com/terms-and-conditions/45202832" class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe " title="Terms and Conditions ">Terms and Conditions</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>';

const TermsOfService = () => {
  return <div className="content" dangerouslySetInnerHTML={{ __html: html }}></div>;
};

export default TermsOfService;
