import React, { useState } from "react";

import { toFixed } from "@hotplate/utils-ts/helperFunctions";
import { List } from "../../visly/Primitives";
import { Separator } from "../../hotplate-common/primitives/Separator";
import { ExclamationTriangleIcon, CheckIcon, MinusIcon, PlusIcon } from "@radix-ui/react-icons";
import { styled, keyframes } from "../../stitches.config";
import { Badge } from "../../hotplate-common/primitives/Badge";
import { Column, Row } from "../../hotplate-common/primitives/Containers";

// Insecure hash function https://stackoverflow.com/a/52171480
const cyrb53 = (str, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }

  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

// badge in top right of option category
const CategoryRequirementBadge = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 24,
  paddingInline: "$xs",
  borderRadius: "$sm",
  fontFamily: "$avenir",
  fontSize: 12,
  lh: "$btn",
  fontWeight: "$semi_bold",
  "& svg": {
    display: "inline",
    height: 10,
    width: 10,
    marginRight: "$xs",
  },
  //status - optional, required, complete, incomplete
  variants: {
    status: {
      optional: {
        backgroundColor: "$gray4",
        color: "$gray12",
        "& svg": {
          display: "none",
        },
      },
      required: {
        backgroundColor: "$warning4",
        color: "$warning11",
      },
      incomplete: {
        // only true when its required and addToCart was attempted before completing it
        backgroundColor: "$error4",
        color: "$error11",
      },
      complete: {
        backgroundColor: "$success4",
        color: "$success11",
      },
    },
  },
  defaultVariants: {
    status: "optional",
  },
});

//category title
const H3 = styled("h3", {
  fontFamily: "$arboria",
  fontSize: 18,
  fontWeight: "$semi_bold",
  color: "$gray12",
  marginBottom: "$xs",
});

const P = styled("p", {
  fontFamily: "$avenir",
  fontSize: 13,
  fontWeight: "$bold",
  color: "$gray10",
});

const OptionCategoryPrimitive = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

function getOptionPriceString(option) {
  if (option.price === "") {
    return "";
  }
  const price = parseFloat(option.price);
  // if price is positive, add a + sign
  if (price > 0) {
    return `+$${toFixed(price, 2)}`;
  } else {
    return `-$${toFixed(price, 2).replace("-", "")}`;
  }
}

export const OptionCategorySelect = ({
  optionCategory,
  getOptionInventoryRemaining,
  setMenuItemModalSelectedOptions,
  optionCategoryIndex,
  menuItemQuantityCustomerWants,
  setOptionQuantity,
  incomplete,
  ...props
}) => {
  const { title, minimumSelections, maximumSelections, options, isOptionSelectionNumerical } =
    optionCategory;
  const [maxQuantityReachedString, setMaxQuantityReachedString] = useState("");

  function getNumberOfOptionsSelected() {
    if (optionCategory.isOptionSelectionNumerical) {
      return options.reduce(
        (sum, option) =>
          sum + (Number.isInteger(option.quantitySelected) ? option.quantitySelected : 0),
        0
      );
    } else {
      return options.filter((option) => option.selected).map((option) => option.title).length;
    }
  }

  const numSelected = getNumberOfOptionsSelected();

  function getOptionCategoryStatus() {
    if (minimumSelections === 0) {
      return "optional";
    }
    if (numSelected >= minimumSelections) {
      return "complete";
    }
    if (incomplete) {
      return "incomplete";
    } else {
      return "required";
    }
  }

  function getSelectionInstructionsColor() {
    if (numSelected === 0 && status !== "incomplete") {
      return "$gray11";
    }
    if (numSelected < minimumSelections) {
      return "$error10";
    }
    if (numSelected === maximumSelections) {
      return "$success10";
    }
    if (numSelected >= minimumSelections && minimumSelections > 0) {
      // only show success color if there is a minimum
      return "$success10";
    }
    return "$gray11";
  }

  function getSelectionInstructions() {
    // required & must select X
    if (minimumSelections === maximumSelections) {
      if (numSelected === 0) {
        return `Select ${minimumSelections}`;
      } else if (numSelected < minimumSelections) {
        return `Select ${minimumSelections - numSelected} more`;
      } else if (numSelected === minimumSelections) {
        return `${numSelected} selected`;
      }
    }

    // required & must select between X and Y
    if (minimumSelections > 0 && maximumSelections !== minimumSelections) {
      if (numSelected === 0) {
        return `Select between ${minimumSelections} and ${maximumSelections}`;
      } else if (numSelected < maximumSelections) {
        return `Select between ${Math.max(minimumSelections - numSelected, 0)} and ${
          maximumSelections - numSelected
        } more`;
      } else if (numSelected === maximumSelections) {
        return `Max ${numSelected} selected`;
      }
    }

    // not required
    if (numSelected < maximumSelections) {
      return `Select up to ${maximumSelections}`;
    }
    if (numSelected === maximumSelections) {
      return `Max ${numSelected} selected`;
    }
  }

  const status = getOptionCategoryStatus();
  const selectionInstructionsColor = getSelectionInstructionsColor();
  const selectionInstructions = getSelectionInstructions();

  // Map between option.title and a (hopefully) unique key.
  // Option titles must be unique.
  // We used to use the option title as the key itself, but
  // certain characters cause the Visly List to throw and
  // crash the page. See HOT-1625.
  const optionTitleToKey = {};
  const optionKeyToTitle = {};
  for (const option of options) {
    const key = Math.abs(cyrb53(option.title)).toString();
    optionTitleToKey[option.title] = key;
    optionKeyToTitle[key] = option.title;
  }

  return (
    <OptionCategoryPrimitive {...props}>
      <Row css={{ justifyContent: "space-between", marginBottom: "$md" }}>
        <Column css={{ flexDirection: "column" }}>
          <H3>{title}</H3>
          <P css={{ color: selectionInstructionsColor }}>{selectionInstructions}</P>
        </Column>
        <CategoryRequirementBadge status={status}>
          {(status === "required" || status === "incomplete") && <ExclamationTriangleIcon />}
          {status === "complete" && <CheckIcon />}
          {status === "optional" ? "Optional" : status === "incomplete" ? "Incomplete" : "Required"}
        </CategoryRequirementBadge>
      </Row>

      {/* Had to recreate the visly component fml */}
      {isOptionSelectionNumerical && (
        <ul
          style={{
            width: "100%",
            minWidth: "200px",
            borderColor: "#ecf0f0",
            borderStyle: "solid",
            backgroundColor: "#fff",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            borderRadius: "8px",
            borderWidth: "1px",
            padding: "8px",
            boxShadow: status === "incomplete" ? "0 0 0 2px #e85b5b" : "none",
          }}
        >
          {options.map((option, optionIndex) => {
            return (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    alignItems: "center",
                    alignContent: "flex-start",
                    justifyContent: "flex-start",
                    cursor: "pointer",
                    borderRadius: "4px",
                    padding: "12px 12px 12px 8px",
                  }}
                >
                  <Row
                    // ticker container
                    css={{
                      flexWrap: "nowrap",
                      alignItems: "center",
                      gap: "8px",
                      marginRight: "12px",
                      color: "$gray11",
                    }}
                  >
                    <Row
                      css={{
                        jc: "center",
                        ai: "center",
                        h: "$xs_btn",
                        w: "$xs_btn",
                        br: "$sm",
                        backgroundColor: "$gray3",
                        color: "$gray11",
                        transition: "background-color 0.15s ease-in-out, color 0.15s ease-in-out",
                        "&:hover": {
                          backgroundColor: "$gray4",
                          color: "$gray12",
                        },
                      }}
                      onClick={() => {
                        setOptionQuantity(
                          optionCategoryIndex,
                          optionIndex,
                          Number.isInteger(option.quantitySelected)
                            ? Math.max(0, option.quantitySelected - 1)
                            : 0
                        );
                      }}
                    >
                      <MinusIcon />
                    </Row>
                    <Row
                      css={{
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "$gray2",
                        height: "$xs_btn",
                        width: "3ch",
                        borderRadius: "4px",
                        textAlign: "center",
                        ff: "$inter",
                        textStyle: "text-2",
                        color: "$gray12",
                        userSelect: "none",
                      }}
                    >
                      {Number.isInteger(option.quantitySelected) ? option.quantitySelected : 0}
                    </Row>
                    <Row
                      css={{
                        jc: "center",
                        ai: "center",
                        h: "$xs_btn",
                        w: "$xs_btn",
                        br: "$sm",
                        backgroundColor: "$gray3",
                        color: "$gray11",
                        transition: "background-color 0.15s ease-in-out, color 0.15s ease-in-out",
                        "&:hover": {
                          backgroundColor: "$gray4",
                          color: "$gray12",
                        },
                      }}
                      onClick={() => {
                        const quantityAttempt = Number.isInteger(option.quantitySelected)
                          ? option.quantitySelected + 1
                          : 1;
                        const maxQuantity =
                          optionCategory.showOptionInventory && Number.isInteger(option.inventory)
                            ? option.inventory
                            : Number.POSITIVE_INFINITY;

                        if (quantityAttempt > maxQuantity) {
                          setMaxQuantityReachedString(
                            `Only ${option.inventory} left of ${option.title}`
                          );
                        }
                        setOptionQuantity(
                          optionCategoryIndex,
                          optionIndex,
                          Math.min(quantityAttempt, maxQuantity)
                        );
                      }}
                    >
                      <PlusIcon />
                    </Row>
                  </Row>
                  <div
                    //title container
                    style={{
                      color: "#233434",
                      fontFamily: "Avenir, sans-serif",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "22px",
                      flexShrink: "1",
                      alignSelf: "auto",
                      marginRight: "auto",
                      userSelect: "none",
                    }}
                  >
                    {option.title}
                  </div>
                  <div
                    // price container
                    style={{
                      color: "#454545",
                      textAlign: "start",
                      whiteSpace: "normal",
                      fontFamily: "Avenir, sans-serif",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "22px",
                      flexShrink: "1",
                      display: "block",
                      userSelect: "none",
                    }}
                  >
                    {getOptionPriceString(option)}
                  </div>
                </div>
                {optionIndex !== options.length - 1 && (
                  <div // divider
                    style={{
                      content: "",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "1px",
                      backgroundColor: "#f8fafa",
                    }}
                  ></div>
                )}
              </>
            );
          })}
        </ul>
      )}
      {!isOptionSelectionNumerical && (
        <List
          selectionMode={"multiple"}
          style={
            status === "incomplete" && {
              boxShadow: `0 0 0 2px #e85b5b`,
            }
          }
          selectedKeys={options
            .filter((option) => option.selected)
            .map((option) => optionTitleToKey[option.title])}
          onSelectionChange={(vals) => {
            setMenuItemModalSelectedOptions(
              vals.map((key) => optionKeyToTitle[key]),
              optionCategoryIndex
            );
          }}
        >
          {options.map((option) => (
            <List.Item
              style={
                getOptionInventoryRemaining(optionCategory, option) < menuItemQuantityCustomerWants
                  ? {
                      cursor: "default",
                      pointerEvents: "none",
                    }
                  : {
                      cursor: "pointer",
                      pointerEvents: "all",
                    }
              }
              disabled={
                getOptionInventoryRemaining(optionCategory, option) < menuItemQuantityCustomerWants
              }
              key={optionTitleToKey[option.title]}
              option={option.title}
              price={getOptionPriceString(option)}
              badge={
                <Badge
                  size="small"
                  color={
                    getOptionInventoryRemaining(optionCategory, option) === 0
                      ? "light_danger"
                      : "light_warning"
                  }
                >
                  {getOptionInventoryRemaining(optionCategory, option) === 0
                    ? "Sold Out"
                    : "Only " + getOptionInventoryRemaining(optionCategory, option) + " left"}
                </Badge>
              }
            />
          ))}
        </List>
      )}
      <Separator space="$space$lg" />
    </OptionCategoryPrimitive>
  );
};
