// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./ConfigMenu.EmptyMenuCta.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";
import {
  default as AddFirstItemButton,
  ButtonContext as AddFirstItemButtonContext,
} from "../Primitives/Button";

const styles = [
  {
    type: "default",
    layers: {
      C53jKWndQG: {
        none: {
          icon: icons.empty,
        },
      },
      Gt9EGmWvHh: {
        none: {
          text: "Your menu is empty, lets change that",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      QBZYLL3NX7: {
        none: {
          text: "Add items to your menu",
          rounded: false,
          large: true,
          kind: "primary",
        },
      },
    },
  },
];

const variantPropTypes = [];

export const EmptyMenuCtaContext = createContext(null);

function EmptyMenuCta(_props) {
  const defaults = useContext(EmptyMenuCtaContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="EmKKUXYJkW"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "EmKKUXYJkW",
        scope: "TZJqAj8NDr",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => [
        <ContainerPrimitive
          id={"Container_2CeTdatjpt"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_TZJqAj8NDr_2CeTdatjpt"
          }
          key={"2CeTdatjpt"}
          addSpacing={false}
          element={getStyle("2CeTdatjpt", "htmlElement")}
        >
          {
            <IconPrimitive
              id={"Icon_C53jKWndQG"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_TZJqAj8NDr_C53jKWndQG"
              }
              key={"C53jKWndQG"}
              useMask={getStyle("C53jKWndQG", "useMask")}
              src={getStyle("C53jKWndQG", "icon")}
            />
          }
        </ContainerPrimitive>,
        <TextPrimitive
          id={"Text_Gt9EGmWvHh"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_TZJqAj8NDr_Gt9EGmWvHh"
          }
          key={"Gt9EGmWvHh"}
          text={getStyle("Gt9EGmWvHh", "text")}
          element={getStyle("Gt9EGmWvHh", "htmlElement")}
        />,
        <SpacerPrimitive
          id={"Spacer_NJnwz4FmeC"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_TZJqAj8NDr_NJnwz4FmeC"
          }
          key={"NJnwz4FmeC"}
        />,
        props.AddFirstItemButton === undefined ? (
          <AddFirstItemButton
            key={"QBZYLL3NX7"}
            {...getCompositeDefaultProps("QBZYLL3NX7")}
            id="AddFirstItemButton_QBZYLL3NX7"
            className="__visly_reset_7X7HTLRqyD __visly_scope_TZJqAj8NDr_QBZYLL3NX7"
          />
        ) : (
          <AddFirstItemButtonContext.Provider
            key="QBZYLL3NX7-provider"
            value={{
              key: "QBZYLL3NX7",
              id: "AddFirstItemButton_QBZYLL3NX7",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_TZJqAj8NDr_QBZYLL3NX7",
              ...getCompositeDefaultProps("QBZYLL3NX7"),
            }}
          >
            {props.AddFirstItemButton}
          </AddFirstItemButtonContext.Provider>
        ),
      ]}
    </RootPrimitive>
  );
}

EmptyMenuCta.AddFirstItemButton = AddFirstItemButton;
EmptyMenuCta.__variants = [];

export default EmptyMenuCta;
