// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./ItemsCard.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../../_internal_primitives";
import * as icons from "../../icons";
import { default as Expand } from "./ItemsCard.Expand";

const styles = [
  {
    type: "default",
    layers: {
      JLZ9NYouhg: {
        none: {
          text: "Wednesday",
        },
      },
      "2knr1NkyFa": {
        none: {
          htmlElement: "nav",
        },
      },
      S9DFaycvaT: {
        none: {
          text: "mar 24",
          htmlElement: "code",
        },
      },
      XYDcSzwhyG: {
        none: {
          text: "82",
        },
      },
      UwFLhomNx9: {
        none: {
          text: "Orders,",
        },
      },
      "5wtfQ8BXar": {
        none: {
          text: "125",
        },
      },
      UhLp6hvQhG: {
        none: {
          text: "Items",
        },
      },
      Fdu1pX1qaj: {
        none: {
          text: "10",
        },
      },
      HPR6Et8K5m: {
        none: {
          text: "10",
        },
      },
      V7zBxxzM7V: {
        none: {
          text: "ORDERS DONE",
        },
      },
      MsdHpq3Mux: {
        none: {
          text: "LEFT",
        },
      },
      Qjhnsv9tUv: {
        none: {
          text: "10",
        },
      },
      LzzDR3BNLS: {
        none: {
          text: "ITEMS DONE",
        },
      },
      Q3ctRA3G1D: {
        none: {
          text: "10",
        },
      },
      VBYX3uGZoM: {
        none: {
          text: "LEFT",
        },
      },
      A7cZSut8EJ: {
        none: {
          htmlElement: "main",
        },
      },
      Xpeux2EgrV: {
        none: {
          icon: icons.vislyCheck,
          useMask: true,
        },
      },
      NMhAfGUDdg: {
        none: {
          htmlElement: "article",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "med",
        layers: {
          W3R6E83jRu: {
            none: {
              htmlElement: "footer",
            },
          },
        },
      },
      {
        propValue: "xsmall",
        layers: {
          W3R6E83jRu: {
            none: {
              htmlElement: "footer",
            },
          },
          UwFLhomNx9: {
            none: {
              text: "Orders",
            },
          },
          V7zBxxzM7V: {
            none: {
              text: "DONE",
            },
          },
          LzzDR3BNLS: {
            none: {
              text: "DONE",
            },
          },
          VBYX3uGZoM: {
            none: {
              text: "LEFT",
            },
          },
        },
      },
      {
        propValue: "small",
        layers: {
          W3R6E83jRu: {
            none: {
              htmlElement: "footer",
            },
          },
        },
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
  {
    type: "enum",
    propName: "orderType",
    values: [
      {
        propValue: "shipping",
        layers: {
          JLZ9NYouhg: {
            none: {
              text: "Shipping",
            },
          },
          Xpeux2EgrV: {
            none: {
              icon: icons.paperPlane,
            },
          },
        },
      },
      {
        propValue: "delivery",
        layers: {
          JLZ9NYouhg: {
            none: {
              text: "Delivery",
            },
          },
          Xpeux2EgrV: {
            none: {
              icon: icons.delivery,
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "pickup",
        layers: {
          JLZ9NYouhg: {
            none: {
              text: "Pickup",
            },
          },
          Xpeux2EgrV: {
            none: {
              icon: icons.home,
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "wholesale",
        layers: {
          JLZ9NYouhg: {
            none: {
              text: "Wholesale",
            },
          },
          Xpeux2EgrV: {
            none: {
              icon: icons.wholesale,
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "catering",
        layers: {
          JLZ9NYouhg: {
            none: {
              text: "Catering",
            },
          },
          Xpeux2EgrV: {
            none: {
              icon: icons.archive,
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "asap",
        layers: {
          JLZ9NYouhg: {
            none: {
              text: "ASAP",
            },
          },
        },
      },
    ],
  },
  {
    type: "boolean",
    propName: "sub",
    layers: {},
  },
  {
    type: "boolean",
    propName: "expanded",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
  {
    type: "enum",
    propName: "orderType",
    values: [
      {
        propValue: "shipping",
        layers: {},
      },
      {
        propValue: "delivery",
        layers: {},
      },
      {
        propValue: "pickup",
        layers: {},
      },
      {
        propValue: "wholesale",
        layers: {},
      },
      {
        propValue: "catering",
        layers: {},
      },
      {
        propValue: "asap",
        layers: {},
      },
    ],
  },
  {
    type: "boolean",
    propName: "sub",
    layers: {},
  },
  {
    type: "boolean",
    propName: "expanded",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["large", "med", "xsmall", "small", "xlarge"],
  },
  {
    type: "enum",
    propName: "orderType",
    propValues: [
      "shipping",
      "delivery",
      "pickup",
      "wholesale",
      "catering",
      "asap",
    ],
  },
  {
    type: "boolean",
    propName: "sub",
  },
  {
    type: "boolean",
    propName: "expanded",
  },
];

export const ItemsCardContext = createContext(null);

function ItemsCard(_props) {
  const defaults = useContext(ItemsCardContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="54UjWDhrsJ"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "54UjWDhrsJ",
        scope: "PPgnx6615D",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Container_W3R6E83jRu"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_W3R6E83jRu"
            }
            key={"W3R6E83jRu"}
            addSpacing={false}
            element={getStyle("W3R6E83jRu", "htmlElement")}
          >
            {
              <TextPrimitive
                id={"date_S9DFaycvaT"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_S9DFaycvaT"
                }
                key={"S9DFaycvaT"}
                text={
                  exists(props.date)
                    ? props.date
                    : getStyle("S9DFaycvaT", "text")
                }
                element={getStyle("S9DFaycvaT", "htmlElement")}
              />
            }
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"Container_Ajj8kx5jK3"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_Ajj8kx5jK3"
            }
            key={"Ajj8kx5jK3"}
            addSpacing={false}
            element={getStyle("Ajj8kx5jK3", "htmlElement")}
          >
            <ContainerPrimitive
              id={"Container_3eT3aicBsu"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_3eT3aicBsu"
              }
              key={"3eT3aicBsu"}
              addSpacing={false}
              element={getStyle("3eT3aicBsu", "htmlElement")}
            >
              {
                <ContainerPrimitive
                  id={"Container_6z29qzY6LZ"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_6z29qzY6LZ"
                  }
                  key={"6z29qzY6LZ"}
                  addSpacing={false}
                  element={getStyle("6z29qzY6LZ", "htmlElement")}
                >
                  {null}
                </ContainerPrimitive>
              }
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"Content_NiqZPVVwT1"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_NiqZPVVwT1"
              }
              key={"NiqZPVVwT1"}
              addSpacing={false}
              element={getStyle("NiqZPVVwT1", "htmlElement")}
            >
              <ContainerPrimitive
                id={"Container_A7cZSut8EJ"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_A7cZSut8EJ"
                }
                key={"A7cZSut8EJ"}
                addSpacing={false}
                element={getStyle("A7cZSut8EJ", "htmlElement")}
              >
                <ContainerPrimitive
                  id={"Header_NM2tRBu1hn"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_NM2tRBu1hn"
                  }
                  key={"NM2tRBu1hn"}
                  addSpacing={false}
                  element={getStyle("NM2tRBu1hn", "htmlElement")}
                >
                  <ContainerPrimitive
                    id={"Container_21KLeKxNCr"}
                    className={
                      "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_21KLeKxNCr"
                    }
                    key={"21KLeKxNCr"}
                    addSpacing={false}
                    element={getStyle("21KLeKxNCr", "htmlElement")}
                  >
                    <TextPrimitive
                      id={"timeBlock_JLZ9NYouhg"}
                      className={
                        "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_JLZ9NYouhg"
                      }
                      key={"JLZ9NYouhg"}
                      text={
                        exists(props.timeBlock)
                          ? props.timeBlock
                          : getStyle("JLZ9NYouhg", "text")
                      }
                      element={getStyle("JLZ9NYouhg", "htmlElement")}
                    />
                    <ContainerPrimitive
                      id={"Container_2Ws6TFdX6w"}
                      className={
                        "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_2Ws6TFdX6w"
                      }
                      key={"2Ws6TFdX6w"}
                      addSpacing={false}
                      element={getStyle("2Ws6TFdX6w", "htmlElement")}
                    >
                      <IconPrimitive
                        id={"Icon_Xpeux2EgrV"}
                        className={
                          "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_Xpeux2EgrV"
                        }
                        key={"Xpeux2EgrV"}
                        useMask={getStyle("Xpeux2EgrV", "useMask")}
                        src={getStyle("Xpeux2EgrV", "icon")}
                      />
                      <ContainerPrimitive
                        key={"FP4b7GTGFc"}
                        id={"Slot_FP4b7GTGFc"}
                        className={
                          "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_FP4b7GTGFc"
                        }
                        addSpacing={false}
                      >
                        {props.animation}
                      </ContainerPrimitive>
                    </ContainerPrimitive>
                    <ContainerPrimitive
                      id={"Container_NrGHCRuzCR"}
                      className={
                        "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_NrGHCRuzCR"
                      }
                      key={"NrGHCRuzCR"}
                      addSpacing={false}
                      element={getStyle("NrGHCRuzCR", "htmlElement")}
                    >
                      <ContainerPrimitive
                        id={"Container_J3JAaXrs9A"}
                        className={
                          "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_J3JAaXrs9A"
                        }
                        key={"J3JAaXrs9A"}
                        addSpacing={false}
                        element={getStyle("J3JAaXrs9A", "htmlElement")}
                      >
                        <TextPrimitive
                          id={"numOrders_XYDcSzwhyG"}
                          className={
                            "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_XYDcSzwhyG"
                          }
                          key={"XYDcSzwhyG"}
                          text={
                            exists(props.numOrders)
                              ? props.numOrders
                              : getStyle("XYDcSzwhyG", "text")
                          }
                          element={getStyle("XYDcSzwhyG", "htmlElement")}
                        />
                        <SpacerPrimitive
                          id={"Spacer_KEhdnoYTHG"}
                          className={
                            "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_KEhdnoYTHG"
                          }
                          key={"KEhdnoYTHG"}
                        />
                        <TextPrimitive
                          id={"Orders_UwFLhomNx9"}
                          className={
                            "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_UwFLhomNx9"
                          }
                          key={"UwFLhomNx9"}
                          text={getStyle("UwFLhomNx9", "text")}
                          element={getStyle("UwFLhomNx9", "htmlElement")}
                        />
                        <SpacerPrimitive
                          id={"Spacer_Ay7ok5f3Ww"}
                          className={
                            "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_Ay7ok5f3Ww"
                          }
                          key={"Ay7ok5f3Ww"}
                        />
                      </ContainerPrimitive>
                      <ContainerPrimitive
                        id={"Container_GYYrK5dweG"}
                        className={
                          "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_GYYrK5dweG"
                        }
                        key={"GYYrK5dweG"}
                        addSpacing={false}
                        element={getStyle("GYYrK5dweG", "htmlElement")}
                      >
                        <TextPrimitive
                          id={"numItems_5wtfQ8BXar"}
                          className={
                            "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_5wtfQ8BXar"
                          }
                          key={"5wtfQ8BXar"}
                          text={
                            exists(props.numItems)
                              ? props.numItems
                              : getStyle("5wtfQ8BXar", "text")
                          }
                          element={getStyle("5wtfQ8BXar", "htmlElement")}
                        />
                        <SpacerPrimitive
                          id={"Spacer_CiCTHDq9et"}
                          className={
                            "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_CiCTHDq9et"
                          }
                          key={"CiCTHDq9et"}
                        />
                        <TextPrimitive
                          id={"Items_UhLp6hvQhG"}
                          className={
                            "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_UhLp6hvQhG"
                          }
                          key={"UhLp6hvQhG"}
                          text={getStyle("UhLp6hvQhG", "text")}
                          element={getStyle("UhLp6hvQhG", "htmlElement")}
                        />
                      </ContainerPrimitive>
                    </ContainerPrimitive>
                  </ContainerPrimitive>
                  <ContainerPrimitive
                    id={"Expand_2knr1NkyFa"}
                    className={
                      "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_2knr1NkyFa"
                    }
                    key={"2knr1NkyFa"}
                    addSpacing={false}
                    element={getStyle("2knr1NkyFa", "htmlElement")}
                  >
                    {props.Expand === undefined ? (
                      <Expand key={"YBD94Qw69V"} />
                    ) : (
                      props.Expand
                    )}
                  </ContainerPrimitive>
                </ContainerPrimitive>
                <ContainerPrimitive
                  id={"Stats_2EjTLSzfgr"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_2EjTLSzfgr"
                  }
                  key={"2EjTLSzfgr"}
                  addSpacing={false}
                  element={getStyle("2EjTLSzfgr", "htmlElement")}
                >
                  {
                    <ContainerPrimitive
                      id={"Container_CBwZjh7WX2"}
                      className={
                        "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_CBwZjh7WX2"
                      }
                      key={"CBwZjh7WX2"}
                      addSpacing={true}
                      element={getStyle("CBwZjh7WX2", "htmlElement")}
                    >
                      <ContainerPrimitive
                        id={"Current Orders_YDkLvZyic9"}
                        className={
                          "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_YDkLvZyic9"
                        }
                        key={"YDkLvZyic9"}
                        addSpacing={false}
                        element={getStyle("YDkLvZyic9", "htmlElement")}
                      >
                        <ContainerPrimitive
                          id={"Container_LtpM4sPx2b"}
                          className={
                            "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_LtpM4sPx2b"
                          }
                          key={"LtpM4sPx2b"}
                          addSpacing={false}
                          element={getStyle("LtpM4sPx2b", "htmlElement")}
                        >
                          <TextPrimitive
                            id={"ordersComplete_Fdu1pX1qaj"}
                            className={
                              "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_Fdu1pX1qaj"
                            }
                            key={"Fdu1pX1qaj"}
                            text={
                              exists(props.ordersDone)
                                ? props.ordersDone
                                : getStyle("Fdu1pX1qaj", "text")
                            }
                            element={getStyle("Fdu1pX1qaj", "htmlElement")}
                          />
                          <SpacerPrimitive
                            id={"Spacer_KQTPNQMpsv"}
                            className={
                              "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_KQTPNQMpsv"
                            }
                            key={"KQTPNQMpsv"}
                          />
                          <TextPrimitive
                            id={"Text_V7zBxxzM7V"}
                            className={
                              "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_V7zBxxzM7V"
                            }
                            key={"V7zBxxzM7V"}
                            text={getStyle("V7zBxxzM7V", "text")}
                            element={getStyle("V7zBxxzM7V", "htmlElement")}
                          />
                          <SpacerPrimitive
                            id={"Spacer_MRiEUu9vpy"}
                            className={
                              "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_MRiEUu9vpy"
                            }
                            key={"MRiEUu9vpy"}
                          />
                        </ContainerPrimitive>
                        <ContainerPrimitive
                          id={"Container_Un8KZiTKkz"}
                          className={
                            "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_Un8KZiTKkz"
                          }
                          key={"Un8KZiTKkz"}
                          addSpacing={false}
                          element={getStyle("Un8KZiTKkz", "htmlElement")}
                        >
                          <TextPrimitive
                            id={"ordersLeft_HPR6Et8K5m"}
                            className={
                              "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_HPR6Et8K5m"
                            }
                            key={"HPR6Et8K5m"}
                            text={
                              exists(props.ordersLeft)
                                ? props.ordersLeft
                                : getStyle("HPR6Et8K5m", "text")
                            }
                            element={getStyle("HPR6Et8K5m", "htmlElement")}
                          />
                          <SpacerPrimitive
                            id={"Spacer_C69K5wiWAa"}
                            className={
                              "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_C69K5wiWAa"
                            }
                            key={"C69K5wiWAa"}
                          />
                          <TextPrimitive
                            id={"Text_MsdHpq3Mux"}
                            className={
                              "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_MsdHpq3Mux"
                            }
                            key={"MsdHpq3Mux"}
                            text={getStyle("MsdHpq3Mux", "text")}
                            element={getStyle("MsdHpq3Mux", "htmlElement")}
                          />
                        </ContainerPrimitive>
                      </ContainerPrimitive>
                      <ContainerPrimitive
                        id={"dividerSpace_KB7TwhH9MU"}
                        className={
                          "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_KB7TwhH9MU"
                        }
                        key={"KB7TwhH9MU"}
                        addSpacing={false}
                        element={getStyle("KB7TwhH9MU", "htmlElement")}
                      >
                        {
                          <ContainerPrimitive
                            id={"divider_QyTsxbgkHD"}
                            className={
                              "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_QyTsxbgkHD"
                            }
                            key={"QyTsxbgkHD"}
                            addSpacing={false}
                            element={getStyle("QyTsxbgkHD", "htmlElement")}
                          >
                            {null}
                          </ContainerPrimitive>
                        }
                      </ContainerPrimitive>
                      <ContainerPrimitive
                        id={"Current Items_HaG3U3Q4no"}
                        className={
                          "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_HaG3U3Q4no"
                        }
                        key={"HaG3U3Q4no"}
                        addSpacing={false}
                        element={getStyle("HaG3U3Q4no", "htmlElement")}
                      >
                        <ContainerPrimitive
                          id={"Container_FTzCExboVf"}
                          className={
                            "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_FTzCExboVf"
                          }
                          key={"FTzCExboVf"}
                          addSpacing={false}
                          element={getStyle("FTzCExboVf", "htmlElement")}
                        >
                          <TextPrimitive
                            id={"ordersComplete_Qjhnsv9tUv"}
                            className={
                              "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_Qjhnsv9tUv"
                            }
                            key={"Qjhnsv9tUv"}
                            text={
                              exists(props.itemsDone)
                                ? props.itemsDone
                                : getStyle("Qjhnsv9tUv", "text")
                            }
                            element={getStyle("Qjhnsv9tUv", "htmlElement")}
                          />
                          <SpacerPrimitive
                            id={"Spacer_6FZbyQZhR7"}
                            className={
                              "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_6FZbyQZhR7"
                            }
                            key={"6FZbyQZhR7"}
                          />
                          <TextPrimitive
                            id={"items done_LzzDR3BNLS"}
                            className={
                              "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_LzzDR3BNLS"
                            }
                            key={"LzzDR3BNLS"}
                            text={getStyle("LzzDR3BNLS", "text")}
                            element={getStyle("LzzDR3BNLS", "htmlElement")}
                          />
                          <SpacerPrimitive
                            id={"Spacer_HwdvPsAGvH"}
                            className={
                              "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_HwdvPsAGvH"
                            }
                            key={"HwdvPsAGvH"}
                          />
                        </ContainerPrimitive>
                        <ContainerPrimitive
                          id={"Container_REMP97QmsM"}
                          className={
                            "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_REMP97QmsM"
                          }
                          key={"REMP97QmsM"}
                          addSpacing={false}
                          element={getStyle("REMP97QmsM", "htmlElement")}
                        >
                          <TextPrimitive
                            id={"ordersLeft_Q3ctRA3G1D"}
                            className={
                              "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_Q3ctRA3G1D"
                            }
                            key={"Q3ctRA3G1D"}
                            text={
                              exists(props.itemsLeft)
                                ? props.itemsLeft
                                : getStyle("Q3ctRA3G1D", "text")
                            }
                            element={getStyle("Q3ctRA3G1D", "htmlElement")}
                          />
                          <SpacerPrimitive
                            id={"Spacer_P9PyvcTjzm"}
                            className={
                              "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_P9PyvcTjzm"
                            }
                            key={"P9PyvcTjzm"}
                          />
                          <TextPrimitive
                            id={"Text_VBYX3uGZoM"}
                            className={
                              "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_VBYX3uGZoM"
                            }
                            key={"VBYX3uGZoM"}
                            text={getStyle("VBYX3uGZoM", "text")}
                            element={getStyle("VBYX3uGZoM", "htmlElement")}
                          />
                        </ContainerPrimitive>
                      </ContainerPrimitive>
                    </ContainerPrimitive>
                  }
                </ContainerPrimitive>
              </ContainerPrimitive>
              <ContainerPrimitive
                key={"4CZzXK84AC"}
                id={"Slot_4CZzXK84AC"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_4CZzXK84AC"
                }
                addSpacing={false}
              >
                {props.subCards}
              </ContainerPrimitive>
              <ContainerPrimitive
                key={"XBcemEKtxR"}
                id={"Slot_XBcemEKtxR"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_XBcemEKtxR"
                }
                addSpacing={false}
              >
                {props.children}
              </ContainerPrimitive>
              <ContainerPrimitive
                id={"spacer_div_NMhAfGUDdg"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_PPgnx6615D_NMhAfGUDdg"
                }
                key={"NMhAfGUDdg"}
                addSpacing={false}
                element={getStyle("NMhAfGUDdg", "htmlElement")}
              >
                {null}
              </ContainerPrimitive>
            </ContainerPrimitive>
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

ItemsCard.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["large", "med", "xsmall", "small", "xlarge"],
  },
  {
    name: "orderType",
    type: "group",
    variants: [
      "shipping",
      "delivery",
      "pickup",
      "wholesale",
      "catering",
      "asap",
    ],
  },
  {
    name: "sub",
    type: "variant",
  },
  {
    name: "expanded",
    type: "variant",
  },
];

export default ItemsCard;
