import React, { useMemo } from "react";
import { styled, keyframes } from "../../stitches.config";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";

// Keyframes
export const loading = keyframes({
  "0%": {
    backgroundColor: "$gray7",
  },
  "100%": {
    backgroundColor: "$gray3",
  },
});

const RatioBoxLoaderPrimitive = styled("div", {
  display: "flex",
  backgroundColor: "$gray2",
  animation: `${loading} 1.25s linear infinite alternate`,
});

export const RatioBoxLoader = ({ width = "100%", css, ratio = 1, ...props }) => {
  return (
    <RatioBoxLoaderPrimitive css={{ width: width, ...css }} {...props}>
      <AspectRatio.Root ratio={ratio} />
    </RatioBoxLoaderPrimitive>
  );
};

export const TitleLoader = styled("div", {
  display: "flex",
  borderRadius: "$sm",
  height: "$space$lg",
  marginBottom: "$sm",
  animation: `${loading} 1.25s linear infinite alternate`,
});

export const DescriptionLoader = styled("div", {
  display: "flex",
  borderRadius: "$sm",
  marginBlock: "$xs",
  height: "$space$md",
  animation: `${loading} 1.25s linear infinite alternate`,
});

export const TextLoader = styled("div", {
  display: "flex",
  borderRadius: "$sm",
  height: "$space$md",
  animation: `${loading} 1.25s linear infinite alternate`,
});

export const VariableWidthLoader = ({ min, max, unit = "px", css, ...props }) => {
  const randomWidth = useMemo(
    () => `${Math.floor(Math.random() * (max - min + 1) + min)}${unit}`,
    [min, max, unit]
  );

  return <TextLoader css={{ width: randomWidth, ...css }} {...props} />;
};
