// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./BannerCartButton.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../../_internal_primitives";
import * as icons from "../../icons";

const styles = [
  {
    type: "default",
    layers: {
      RdeayNNRLL: {
        none: {
          text: "98:59",
        },
      },
      "6WakVDdymT": {
        none: {
          icon: icons.cart,
          useMask: true,
        },
      },
    },
  },
  {
    type: "enum",
    propName: "isEmpty",
    values: [
      {
        propValue: "light",
        layers: {
          "6WakVDdymT": {
            none: {
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "dark",
        layers: {},
      },
      {
        propValue: "none",
        layers: {},
      },
    ],
  },
  {
    type: "enum",
    propName: "hasItemsInCart",
    values: [
      {
        propValue: "light",
        layers: {},
      },
      {
        propValue: "dark",
        layers: {
          "6WakVDdymT": {
            none: {
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "none",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "isEmpty",
    values: [
      {
        propValue: "light",
        layers: {},
      },
      {
        propValue: "dark",
        layers: {},
      },
      {
        propValue: "none",
        layers: {},
      },
    ],
  },
  {
    type: "enum",
    propName: "hasItemsInCart",
    values: [
      {
        propValue: "light",
        layers: {},
      },
      {
        propValue: "dark",
        layers: {},
      },
      {
        propValue: "none",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "isEmpty",
    propValues: ["light", "dark", "none"],
  },
  {
    type: "enum",
    propName: "hasItemsInCart",
    propValues: ["light", "dark", "none"],
  },
];

export const BannerCartButtonContext = createContext(null);

function BannerCartButton(_props) {
  const defaults = useContext(BannerCartButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="AAcjMqeusT"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "AAcjMqeusT",
        scope: "FVLPDm1rbc",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"icon-container_Lk4nSuyJ9t"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_FVLPDm1rbc_Lk4nSuyJ9t"
            }
            key={"Lk4nSuyJ9t"}
            addSpacing={false}
            element={getStyle("Lk4nSuyJ9t", "htmlElement")}
          >
            <IconPrimitive
              id={"Icon_6WakVDdymT"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_FVLPDm1rbc_6WakVDdymT"
              }
              key={"6WakVDdymT"}
              useMask={getStyle("6WakVDdymT", "useMask")}
              src={getStyle("6WakVDdymT", "icon")}
            />
            <ContainerPrimitive
              id={"hasitems-bubble_AuRRqMFrrJ"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_FVLPDm1rbc_AuRRqMFrrJ"
              }
              key={"AuRRqMFrrJ"}
              addSpacing={false}
              element={getStyle("AuRRqMFrrJ", "htmlElement")}
            >
              {null}
            </ContainerPrimitive>
          </ContainerPrimitive>
          <TextPrimitive
            id={"countdownTimer_RdeayNNRLL"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_FVLPDm1rbc_RdeayNNRLL"
            }
            key={"RdeayNNRLL"}
            text={
              exists(props.countdownTimer)
                ? props.countdownTimer
                : getStyle("RdeayNNRLL", "text")
            }
            element={getStyle("RdeayNNRLL", "htmlElement")}
          />
        </>
      )}
    </RootPrimitive>
  );
}

BannerCartButton.__variants = [
  {
    name: "isEmpty",
    type: "group",
    variants: ["light", "dark", "none"],
  },
  {
    name: "hasItemsInCart",
    type: "group",
    variants: ["light", "dark", "none"],
  },
];

export default BannerCartButton;
