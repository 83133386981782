// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./FilterDropdown.Button.Dialog.Option.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      LzEW4H4tjz: {
        none: {
          icon: icons.vislyCheck,
          useMask: true,
        },
      },
      G4ReMjHi4Y: {
        none: {
          text: "Label",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
  {
    type: "enum",
    propName: "orderType",
    values: [
      {
        propValue: "delivery",
        layers: {
          G4ReMjHi4Y: {
            none: {
              text: "Delivery",
            },
          },
        },
      },
      {
        propValue: "shipping",
        layers: {
          G4ReMjHi4Y: {
            none: {
              text: "Shipping",
            },
          },
        },
      },
      {
        propValue: "wholesale",
        layers: {
          G4ReMjHi4Y: {
            none: {
              text: "Wholesale",
            },
          },
        },
      },
      {
        propValue: "pickup",
        layers: {
          G4ReMjHi4Y: {
            none: {
              text: "Pickup",
            },
          },
        },
      },
      {
        propValue: "catering",
        layers: {
          G4ReMjHi4Y: {
            none: {
              text: "Catering",
            },
          },
        },
      },
      {
        propValue: "subscription",
        layers: {
          G4ReMjHi4Y: {
            none: {
              text: "Subscription",
            },
          },
        },
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
  {
    type: "enum",
    propName: "orderType",
    values: [
      {
        propValue: "delivery",
        layers: {},
      },
      {
        propValue: "shipping",
        layers: {},
      },
      {
        propValue: "wholesale",
        layers: {},
      },
      {
        propValue: "pickup",
        layers: {},
      },
      {
        propValue: "catering",
        layers: {},
      },
      {
        propValue: "subscription",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "selected",
  },
  {
    type: "enum",
    propName: "orderType",
    propValues: [
      "delivery",
      "shipping",
      "wholesale",
      "pickup",
      "catering",
      "subscription",
    ],
  },
];

export const OptionContext = createContext(null);

function Option(_props) {
  const defaults = useContext(OptionContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="GYsFfFD9nt"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "GYsFfFD9nt",
        scope: "BKzuR8QsrF",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_EQ8wpP9U7Q"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_BKzuR8QsrF_EQ8wpP9U7Q"
          }
          key={"EQ8wpP9U7Q"}
          addSpacing={false}
          element={getStyle("EQ8wpP9U7Q", "htmlElement")}
        >
          <ContainerPrimitive
            id={"Container_RjWXgFmu5x"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_BKzuR8QsrF_RjWXgFmu5x"
            }
            key={"RjWXgFmu5x"}
            addSpacing={false}
            element={getStyle("RjWXgFmu5x", "htmlElement")}
          >
            {
              <ContainerPrimitive
                id={"Container_CPthZ8WHBh"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_BKzuR8QsrF_CPthZ8WHBh"
                }
                key={"CPthZ8WHBh"}
                addSpacing={false}
                element={getStyle("CPthZ8WHBh", "htmlElement")}
              >
                {
                  <IconPrimitive
                    id={"Icon_LzEW4H4tjz"}
                    className={
                      "__visly_reset_7X7HTLRqyD __visly_scope_BKzuR8QsrF_LzEW4H4tjz"
                    }
                    key={"LzEW4H4tjz"}
                    useMask={getStyle("LzEW4H4tjz", "useMask")}
                    src={getStyle("LzEW4H4tjz", "icon")}
                  />
                }
              </ContainerPrimitive>
            }
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_T11iRZgs7N"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_BKzuR8QsrF_T11iRZgs7N"
            }
            key={"T11iRZgs7N"}
          />
          <TextPrimitive
            id={"label_G4ReMjHi4Y"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_BKzuR8QsrF_G4ReMjHi4Y"
            }
            key={"G4ReMjHi4Y"}
            text={
              exists(props.label) ? props.label : getStyle("G4ReMjHi4Y", "text")
            }
            element={getStyle("G4ReMjHi4Y", "htmlElement")}
          />
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

Option.__variants = [
  {
    name: "selected",
    type: "variant",
  },
  {
    name: "orderType",
    type: "group",
    variants: [
      "delivery",
      "shipping",
      "wholesale",
      "pickup",
      "catering",
      "subscription",
    ],
  },
];

export default Option;
