/* eslint-disable react/prop-types */
import { getFormattedPhone, getUnFormattedPhone } from "@hotplate/utils-ts/helperFunctions";
import { useMutation } from "@tanstack/react-query";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import { useAuth } from "../auth";
import { Input } from "../hotplate-common/primitives/Input";
import { Button } from "./primitives/Button";
import { IS_PROD } from "../hotplate-storefront/actions/types";
import { sendOTPMutation } from "../mutations";
import { Button as VislyButton } from "../visly/Primitives";
import { AuthCodeInput, AuthCodeRef } from "./primitives/AuthCodeInput";
import { Box, Column, H3, Row, Span } from "./primitives/Containers";
import { Error as ErrorMessage } from "./primitives/Error";

export default function PhoneLogin({
  setGuestLoginEnabled,
  onLogin,
  isPortal,
}: {
  setGuestLoginEnabled?: (enabled: boolean) => void;
  onLogin: () => void;
  isPortal: boolean;
}) {
  const { userInfoError } = useSelector((state: any) => state.login);
  const auth = useAuth();
  const AuthInputRef = useRef<AuthCodeRef>(null);
  const PhoneInputRef = useRef<HTMLInputElement>(null);
  const [stage, setStage] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [signInWithPhoneLoading, setSignInWithPhoneLoading] = useState(false);
  const [errorText, setErrorText] = useState<string>();

  const sendOTP = useMutation(sendOTPMutation);

  const promptGuestLogin =
    setGuestLoginEnabled && !signInWithPhoneLoading && (stage === 1 || sendOTP.isError);

  function getRandomQueryPhone() {
    const queryPhone = queryString.parse(window.location.search).phone;
    if (queryPhone == "random") {
      let randomPhone = "+1";
      for (let i = 0; i < 10; i++) {
        randomPhone = randomPhone + Math.floor(Math.random() * 10);
      }
      return randomPhone;
    } else if (queryPhone) {
      return queryPhone.replace(" ", "+");
    }
    return phoneNumber.length === 14 ? "+1" + getUnFormattedPhone(phoneNumber) : "+19729777432";
  }

  async function wrappedLogin(
    isPortal: boolean,
    loginAttempt?: { otp: string; methodId: string },
    unverifiedPhone?: string
  ) {
    setSignInWithPhoneLoading(true);
    try {
      await auth.login(isPortal, loginAttempt, unverifiedPhone);
      if (setGuestLoginEnabled) {
        if (loginAttempt) {
          setGuestLoginEnabled(false);
        } else {
          setGuestLoginEnabled(true);
        }
      }
      onLogin();
    } catch (e) {
      if (e instanceof Error) {
        setErrorText(e.message);
        if (e.message === "No user found.") {
          setPhoneNumber("");
          setStage(0);
        }
      }
    } finally {
      setSignInWithPhoneLoading(false);
    }
  }

  useEffect(() => {
    PhoneInputRef.current?.focus();
  }, []);

  return (
    <Column css={{ w: "$full" }}>
      <Column css={{ ff: "$inter", color: "$gray12", gap: "$xs", mb: "$md", ai: "center" }}>
        <H3 css={{ textStyle: "text-4" }}>
          {stage === 0 && `Enter your phone number`}
          {stage === 1 && `Check your phone`}
        </H3>
        {stage === 1 && (
          <Box css={{ textStyle: "text-2", gap: ".75ch", color: "$accent12", textAlign: "center" }}>
            <Span>Enter the code we sent to </Span>
            <Span css={{ fw: "$bold" }}>{`+1 ${phoneNumber}`}</Span>
          </Box>
        )}
      </Column>

      {stage === 0 && (
        <Input
          css={{ minW: 0 }}
          size="large"
          contentBefore={"+1 🇺🇸"}
          loading={sendOTP.isLoading || signInWithPhoneLoading || auth.isLoading}
          placeholder="Mobile phone number"
          type={"tel"}
          inputMode={"tel"}
          ref={PhoneInputRef}
          onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
            const formattedNum = getFormattedPhone(e.target.value, false);
            setPhoneNumber(formattedNum);
            if (formattedNum.length === 14) {
              await sendOTP.mutateAsync({
                phoneNumber: "+1" + getUnFormattedPhone(formattedNum),
              });
              setStage(1);
            }
          }}
          aria-label="Phone Number"
          value={phoneNumber}
          showClear={undefined}
          maxLength={undefined}
          showCount={undefined}
          defaultValue={undefined}
          status={undefined}
        />
      )}
      {stage === 1 && (
        <Box css={{ textAlign: "center" }}>
          {auth.isLoading ? (
            <Column
              css={{
                jc: "center",
                ai: "center",
              }}
            >
              <ReactLoading type="spin" height={42} width={42} color="#E85B5B" />
            </Column>
          ) : (
            <AuthCodeInput
              ref={AuthInputRef}
              aria-label="SMS code"
              allowedCharacters="numeric"
              placeholder="0"
              onChange={async (value: string) => {
                if (value.length > 6 || (value && isNaN(parseInt(value)))) {
                  return;
                }
                if (value.length === 6) {
                  await wrappedLogin(
                    isPortal,
                    {
                      methodId: sendOTP.data.json.phoneId,
                      otp: value,
                    },
                    undefined
                  );
                }
              }}
            />
          )}
        </Box>
      )}
      <ErrorMessage
        show={userInfoError || errorText || sendOTP.isError}
        css={{ w: "$full", textAlign: "center", mt: "$sm" }}
      >
        {userInfoError || errorText}
        {sendOTP.isError && "We had trouble sending a code to this phone number"}
      </ErrorMessage>
      {stage === 1 && (
        <Box
          css={{
            ff: "$inter",
            textStyle: "text-1",
            color: "$gray11",
            mt: "$sm",
            textAlign: "center",
            w: "$full",
          }}
        >
          <Span>Didn&apos;t receive a code or typed the wrong number? </Span>
          <Span
            css={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => {
              setPhoneNumber("");
              setStage(0);
            }}
          >
            Click to resend
          </Span>
        </Box>
      )}

      {promptGuestLogin && phoneNumber.length === 14 && (
        <Row
          css={{
            flexWrap: "wrap",
            jc: "center",
            mt: "$lg",
            "&::before": {
              content: "",
              display: "block",
              width: "100%",
              height: "1px",
              backgroundColor: "$gray6",
            },
          }}
        >
          <Span
            css={{
              ff: "$inter",
              textStyle: "text-1",
              color: "$gray11",
              mt: "$lg",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={async () => {
              if (auth.isLoading) {
                return;
              }
              await wrappedLogin(isPortal, undefined, "+1" + getUnFormattedPhone(phoneNumber));
            }}
          >
            Skip login and use guest checkout
          </Span>
        </Row>
      )}
      {!IS_PROD && (
        <Button
          variant="tinted"
          color="accent"
          loading={auth.isLoading}
          onClick={async () => {
            await wrappedLogin(isPortal, undefined, getRandomQueryPhone());
          }}
          size="small"
          css={{ mt: "$sm" }}
        >
          Bypass Login
        </Button>
      )}
    </Column>
  );
}
