// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Refund.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as RefundSummary } from "../Refund/Summary";
import { default as RefundLineItem } from "../Refund/RefundLineItem";
import {
  default as RestockItems,
  RestockItemsContext,
} from "../Refund/RestockItems";
import { default as FeeLineItem } from "../Refund/FeeLineItem";

const styles = [
  {
    type: "default",
    layers: {
      "8YYqSWp31K": {
        none: {
          text: "Initiate Refund",
        },
      },
      "2rgEV1n5Wq": {
        none: {
          text: "Order #01011",
        },
      },
      WHUsXRNif6: {
        none: {
          text: "•",
        },
      },
      "2LZnYjjYfp": {
        none: {
          text: "Customer Name",
        },
      },
      ExprEodYEX: {
        none: {
          text: "Summary",
        },
      },
      DcZCjmowQF: {
        none: {
          text: "Refund Fees",
        },
      },
      FJZxYFoJ3y: {
        none: {
          text: "Refund Items",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      "2uX2Y9KGJW": {},
      "4gjxSFJ8m7": {},
      TAM6EjxWpj: {
        none: {
          text: "Restock {#} item(s)",
        },
      },
      TepKchd2Bk: {},
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {
          "2uX2Y9KGJW": {},
          "4gjxSFJ8m7": {
            none: {
              size: "xsmall",
            },
          },
          TAM6EjxWpj: {},
          TepKchd2Bk: {},
        },
      },
      {
        propValue: "small",
        layers: {
          "2uX2Y9KGJW": {},
          "4gjxSFJ8m7": {},
          TAM6EjxWpj: {},
          TepKchd2Bk: {},
        },
      },
      {
        propValue: "med",
        layers: {
          "2uX2Y9KGJW": {},
          "4gjxSFJ8m7": {},
          TAM6EjxWpj: {},
          TepKchd2Bk: {},
        },
      },
      {
        propValue: "large",
        layers: {
          "2uX2Y9KGJW": {},
          "4gjxSFJ8m7": {},
          TAM6EjxWpj: {},
          TepKchd2Bk: {},
        },
      },
      {
        propValue: "xlarge",
        layers: {
          "2uX2Y9KGJW": {},
          "4gjxSFJ8m7": {},
          TAM6EjxWpj: {},
          TepKchd2Bk: {},
        },
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export const RefundContext = createContext(null);

function Refund(_props) {
  const defaults = useContext(RefundContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="W1BypF7caj"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "W1BypF7caj",
        scope: "PGe592xTty",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"header-container_GPdfeUrBt4"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PGe592xTty_GPdfeUrBt4"
            }
            key={"GPdfeUrBt4"}
            addSpacing={false}
            element={getStyle("GPdfeUrBt4", "htmlElement")}
          >
            <TextPrimitive
              id={"Initiate Refund_8YYqSWp31K"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_PGe592xTty_8YYqSWp31K"
              }
              key={"8YYqSWp31K"}
              text={getStyle("8YYqSWp31K", "text")}
              element={getStyle("8YYqSWp31K", "htmlElement")}
            />
            <ContainerPrimitive
              id={"orderinfo-container_GNhjLBXxa6"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_PGe592xTty_GNhjLBXxa6"
              }
              key={"GNhjLBXxa6"}
              addSpacing={false}
              element={getStyle("GNhjLBXxa6", "htmlElement")}
            >
              <TextPrimitive
                id={"orderNum_2rgEV1n5Wq"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_PGe592xTty_2rgEV1n5Wq"
                }
                key={"2rgEV1n5Wq"}
                text={
                  exists(props.orderNum)
                    ? props.orderNum
                    : getStyle("2rgEV1n5Wq", "text")
                }
                element={getStyle("2rgEV1n5Wq", "htmlElement")}
              />
              <TextPrimitive
                id={"\u2022_WHUsXRNif6"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_PGe592xTty_WHUsXRNif6"
                }
                key={"WHUsXRNif6"}
                text={getStyle("WHUsXRNif6", "text")}
                element={getStyle("WHUsXRNif6", "htmlElement")}
              />
              <TextPrimitive
                id={"customerName_2LZnYjjYfp"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_PGe592xTty_2LZnYjjYfp"
                }
                key={"2LZnYjjYfp"}
                text={
                  exists(props.customerName)
                    ? props.customerName
                    : getStyle("2LZnYjjYfp", "text")
                }
                element={getStyle("2LZnYjjYfp", "htmlElement")}
              />
            </ContainerPrimitive>
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"24px_SXkP73gKDQ"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PGe592xTty_SXkP73gKDQ"
            }
            key={"SXkP73gKDQ"}
          />
          <ContainerPrimitive
            id={"refund-items-container_TLqvLJqoja"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PGe592xTty_TLqvLJqoja"
            }
            key={"TLqvLJqoja"}
            addSpacing={false}
            element={getStyle("TLqvLJqoja", "htmlElement")}
          >
            {[
              <TextPrimitive
                id={"title_FJZxYFoJ3y"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_PGe592xTty_FJZxYFoJ3y"
                }
                key={"FJZxYFoJ3y"}
                text={getStyle("FJZxYFoJ3y", "text")}
                element={getStyle("FJZxYFoJ3y", "htmlElement")}
              />,
              <ContainerPrimitive
                key={"Az3kuLmakW"}
                id={"Slot_Az3kuLmakW"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_PGe592xTty_Az3kuLmakW"
                }
                addSpacing={false}
              >
                {props.children}
              </ContainerPrimitive>,
              props.RestockItems === undefined ? (
                <RestockItems
                  key={"TAM6EjxWpj"}
                  {...getCompositeDefaultProps("TAM6EjxWpj")}
                  id="RestockItems_TAM6EjxWpj"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_PGe592xTty_TAM6EjxWpj"
                />
              ) : (
                <RestockItemsContext.Provider
                  key="TAM6EjxWpj-provider"
                  value={{
                    key: "TAM6EjxWpj",
                    id: "RestockItems_TAM6EjxWpj",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_PGe592xTty_TAM6EjxWpj",
                    ...getCompositeDefaultProps("TAM6EjxWpj"),
                  }}
                >
                  {props.RestockItems}
                </RestockItemsContext.Provider>
              ),
            ]}
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"32px_D9qwktKUXH"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PGe592xTty_D9qwktKUXH"
            }
            key={"D9qwktKUXH"}
          />
          <ContainerPrimitive
            id={"refund-fees-container_UAwSuzdoxv"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PGe592xTty_UAwSuzdoxv"
            }
            key={"UAwSuzdoxv"}
            addSpacing={false}
            element={getStyle("UAwSuzdoxv", "htmlElement")}
          >
            <TextPrimitive
              id={"title_DcZCjmowQF"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_PGe592xTty_DcZCjmowQF"
              }
              key={"DcZCjmowQF"}
              text={getStyle("DcZCjmowQF", "text")}
              element={getStyle("DcZCjmowQF", "htmlElement")}
            />
            <ContainerPrimitive
              key={"XbzRjaFavv"}
              id={"Slot_XbzRjaFavv"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_PGe592xTty_XbzRjaFavv"
              }
              addSpacing={false}
            >
              {props.fees}
            </ContainerPrimitive>
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"32px_LSpmJbnfhU"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PGe592xTty_LSpmJbnfhU"
            }
            key={"LSpmJbnfhU"}
          />
          <ContainerPrimitive
            id={"summary-container_8U5n1abwWR"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PGe592xTty_8U5n1abwWR"
            }
            key={"8U5n1abwWR"}
            addSpacing={false}
            element={getStyle("8U5n1abwWR", "htmlElement")}
          >
            <TextPrimitive
              id={"title_ExprEodYEX"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_PGe592xTty_ExprEodYEX"
              }
              key={"ExprEodYEX"}
              text={getStyle("ExprEodYEX", "text")}
              element={getStyle("ExprEodYEX", "htmlElement")}
            />
            <ContainerPrimitive
              key={"HHJ1Yq2P2a"}
              id={"Slot_HHJ1Yq2P2a"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_PGe592xTty_HHJ1Yq2P2a"
              }
              addSpacing={false}
            >
              {props.summary}
            </ContainerPrimitive>
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

Refund.RefundSummary = RefundSummary;
Refund.RefundLineItem = RefundLineItem;
Refund.RestockItems = RestockItems;
Refund.FeeLineItem = FeeLineItem;
Refund.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export default Refund;
