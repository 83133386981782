import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import _ from "lodash";
import Geocode from "react-geocode";
import { getLocationStyles } from "./HelperFunctions";
import { getAddressString } from "@hotplate/utils-ts/helperFunctions";
import "./css/MapContainer.css";
import { Center } from "./primitives/Containers";
import { PuffLoader } from "./loaders/PuffLoader";
import { usePrevious } from "../hooks";

Geocode.setApiKey("AIzaSyABSMJXmVvS1fOPmYo0zwG7YehwxxNZFQA");
Geocode.setLanguage("en");

export default function MapContainer({ locations: locationsProp, borderRadius }) {
  const [locations, setLocations] = useState(locationsProp);
  const [loading, setLoading] = useState(
    locationsProp.filter((location) => {
      return !location.lat && !location.lng;
    }).length > 0
  );

  useEffect(() => {
    setLocationLatLng();
  }, []);

  async function setLocationLatLng() {
    const locations = _.cloneDeep(locationsProp);
    for (let i = 0; i < locations.length; i++) {
      const location = locations[i];
      if (!location.lat || !location.lng) {
        const addressString = getAddressString(location);
        await Geocode.fromAddress(addressString).then(
          (response) => {
            const { lat, lng } = response.results[0].geometry.location;
            location.lat = lat;
            location.lng = lng;
          },
          (error) => {
            console.log("ERROR GETTING LAT & LNG", error);
          }
        );
      }
    }
    setLocations(locations);
    setLoading(false);
  }

  const prevProps = usePrevious({ locations: locationsProp });
  useState(() => {
    if (!prevProps) {
      return;
    }

    if (!_.isEqual(prevProps.locations, locationsProp)) {
      setLoading(true);
      setTimeout(setLocationLatLng, 100);
    }
  });

  function getCenterOfLocationsLatLng() {
    const coords = locations.map((location) => {
      return {
        lat: location.lat,
        lng: location.lng,
      };
    });
    if (coords.length === 1) {
      return coords[0];
    }

    let x = 0.0;
    let y = 0.0;
    let z = 0.0;

    for (const coord of coords) {
      const lat = (coord.lat * Math.PI) / 180;
      const lng = (coord.lng * Math.PI) / 180;

      x += Math.cos(lat) * Math.cos(lng);
      y += Math.cos(lat) * Math.sin(lng);
      z += Math.sin(lat);
    }

    const total = coords.length;

    x = x / total;
    y = y / total;
    z = z / total;

    const centralLng = Math.atan2(y, x);
    const centralSquareRoot = Math.sqrt(x * x + y * y);
    const centralLat = Math.atan2(z, centralSquareRoot);
    return {
      lat: (centralLat * 180) / Math.PI,
      lng: (centralLng * 180) / Math.PI,
    };
  }

  if (loading) {
    return (
      <Center
        css={{
          position: "absolute",
          inset: 0,
          width: "100%",
          height: "100%",
          borderRadius: "$md",
          border: "1px solid $gray3",
        }}
      >
        <PuffLoader />
      </Center>
    );
  }
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyABSMJXmVvS1fOPmYo0zwG7YehwxxNZFQA" }}
      defaultCenter={{
        lat: getCenterOfLocationsLatLng().lat,
        lng: getCenterOfLocationsLatLng().lng,
      }}
      defaultZoom={locations.length === 1 ? 14 : locations.length === 2 ? 12 : 10}
      options={{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: true,
        styles: [
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#f5f5f2",
              },
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "administrative",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "administrative.neighborhood",
            stylers: [
              {
                visibility: "simplified",
              },
            ],
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.attraction",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff",
              },
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "poi.business",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.medical",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.place_of_worship",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.school",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.sports_complex",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff",
              },
              {
                visibility: "simplified",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [
              {
                visibility: "simplified",
              },
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "on",
              },
              {
                color: "#333333",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "water",
            stylers: [
              {
                color: "#71c8d4",
              },
            ],
          },
          {
            featureType: "landscape",
            stylers: [
              {
                color: "#e5e8e7",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#91b65d",
              },
            ],
          },
          {
            featureType: "poi.park",
            stylers: [
              {
                gamma: 1.51,
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#888888",
              },
              {
                weight: 1.5,
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#FFFFFF",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#FFFFFF",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#666666",
              },
            ],
          },
          {
            featureType: "poi.sports_complex",
            elementType: "geometry",
            stylers: [
              {
                color: "#c7c7c7",
              },
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "water",
            stylers: [
              {
                color: "#a0d3d3",
              },
            ],
          },
          {
            featureType: "road.local",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "poi.government",
            elementType: "geometry",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "landscape",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [
              {
                visibility: "simplified",
              },
            ],
          },
          {
            featureType: "road.local",
            stylers: [
              {
                visibility: "simplified",
              },
            ],
          },
          {
            featureType: "road",
          },
          {
            featureType: "road",
          },
          {},
          {
            featureType: "road.highway",
          },
        ],
      }}
      yesIWantToUseGoogleMapApiInternals={true}
      onGoogleApiLoaded={({ map, maps }) => {
        locations.map((location, locationIndex) => {
          const locationStyles = getLocationStyles();
          const locationStyle = locationStyles[locationIndex % locationStyles.length];
          const iconColor = locationStyle.iconColor;
          const backgroundColor = locationStyle.backgroundColor;
          const iconUrl = locationStyle.iconUrl;
          if (location.displayFullAddress) {
            new maps.Marker({
              position: { lat: location.lat, lng: location.lng },
              map,
              icon: {
                url: iconUrl,
                anchor: new maps.Point(16, 32),
                scaledSize: new maps.Size(32, 32),
              },
            });
          } else {
            // TODO: ADJUST RADIUS and RANDOM CENTER DEPENDING ON MAP DISPLAY
            new maps.Circle({
              strokeColor: iconColor,
              strokeOpacity: 0.75,
              strokeWeight: 1.5,
              fillColor: backgroundColor,
              fillOpacity: 0.5,
              map,
              center: {
                lat: location.lat + (Math.random() - 0.5) / 1000,
                lng: location.lng + (Math.random() - 0.5) / 1000,
              },
              radius: 400,
            });
          }
        });
      }}
      draggable={true}
      style={{
        borderRadius: borderRadius,
        width: "100%",
        height: "100%",
      }}
    />
  );
}
