// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./FilterDropdown.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, ContainerPrimitive } from "../_internal_primitives";
import { default as Button } from "./FilterDropdown.Button";

const styles = [
  {
    type: "default",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const FilterDropdownContext = createContext(null);

function FilterDropdown(_props) {
  const defaults = useContext(FilterDropdownContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="5rSHcRNNhF"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "5rSHcRNNhF",
        scope: "TRJYTkxKrG",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_8YikqbY2Lj"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_TRJYTkxKrG_8YikqbY2Lj"
          }
          key={"8YikqbY2Lj"}
          addSpacing={false}
          element={getStyle("8YikqbY2Lj", "htmlElement")}
        >
          {[
            <ContainerPrimitive
              key={"WDtHEKomzR"}
              id={"Slot_WDtHEKomzR"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_TRJYTkxKrG_WDtHEKomzR"
              }
              addSpacing={false}
            >
              {props.children}
            </ContainerPrimitive>,
            props.Button === undefined ? (
              <Button key={"9ixN1a89vt"} />
            ) : (
              props.Button
            ),
          ]}
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

FilterDropdown.__variants = [];

export default FilterDropdown;
