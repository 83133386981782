// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./EventCard.TagContainer.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, ContainerPrimitive } from "../_internal_primitives";
import { default as Tag } from "../Events/Status";

const styles = [
  {
    type: "default",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      WxjS7nBZhF: {},
    },
  },
];

const variantPropTypes = [];

export const TagContainerContext = createContext(null);

function TagContainer(_props) {
  const defaults = useContext(TagContainerContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="J4hTEn8mKS"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "J4hTEn8mKS",
        scope: "S8xH3kMFEp",
        activeVariants: activeVariants,
      }}
    >
      <ContainerPrimitive
        key={"PxHT5jv8fg"}
        id={"Slot_PxHT5jv8fg"}
        className={
          "__visly_reset_7X7HTLRqyD __visly_scope_S8xH3kMFEp_PxHT5jv8fg"
        }
        addSpacing={true}
      >
        {props.children}
      </ContainerPrimitive>
    </RootPrimitive>
  );
}

TagContainer.Tag = Tag;
TagContainer.__variants = [];

export default TagContainer;
