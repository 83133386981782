// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./SearchBar.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  SpacerPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import { Root, TextFieldPrimitive } from "../_internal_text_field";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      input: {
        none: {
          "placeholder-text": "Search",
        },
      },
      NqHVca9wc6: {
        none: {
          icon: icons.search,
          useMask: true,
        },
      },
      A8R4qr5mWt: {
        none: {
          htmlElement: "nav",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const SearchBarContext = createContext(null);

function SearchBar(_props) {
  const defaults = useContext(SearchBarContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <Root
      {...props}
      key="root"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "root",
        scope: "8qKBQh6XDW",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <IconPrimitive
            id={"Icon_NqHVca9wc6"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_8qKBQh6XDW_NqHVca9wc6"
            }
            key={"NqHVca9wc6"}
            useMask={getStyle("NqHVca9wc6", "useMask")}
            src={getStyle("NqHVca9wc6", "icon")}
          />
          <SpacerPrimitive
            id={"Spacer_Xe57Hecyww"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_8qKBQh6XDW_Xe57Hecyww"
            }
            key={"Xe57Hecyww"}
          />
          <TextFieldPrimitive
            id={"search_input"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_8qKBQh6XDW_input"
            }
            key={"input"}
            placeholder={
              exists(props.placeholder)
                ? props.placeholder
                : getStyle("input", "placeholder-text")
            }
          />
          <ContainerPrimitive
            id={"Container_A8R4qr5mWt"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_8qKBQh6XDW_A8R4qr5mWt"
            }
            key={"A8R4qr5mWt"}
            addSpacing={false}
            element={getStyle("A8R4qr5mWt", "htmlElement")}
          >
            {
              <ContainerPrimitive
                key={"SHh21c9xPA"}
                id={"Slot_SHh21c9xPA"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_8qKBQh6XDW_SHh21c9xPA"
                }
                addSpacing={false}
              >
                {props.button}
              </ContainerPrimitive>
            }
          </ContainerPrimitive>
        </>
      )}
    </Root>
  );
}

SearchBar.__variants = [];

export default SearchBar;
