import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "../hooks";
import { styled } from "../stitches.config";
import { setActiveFilters } from "../hotplate-storefront/actions";
import { getTimestampDayOfWeekMonthDate } from "@hotplate/utils-ts/helperFunctions";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import { Popover } from "../hotplate-common/primitives/Popover";
import { FilterButton } from "./primitives/FilterButton";
import { Flex } from "./primitives/Containers";
import { Cross2Icon, MixerHorizontalIcon, PlusIcon } from "@radix-ui/react-icons";
import DayPicker, { DateUtils } from "react-day-picker";
import { trackOrdersFiltered } from "../hotplate-portal/orderManagement/analytics";

const FilterSearchbar = styled("input", {
  padding: 20,
  height: 62,
  fontSize: 18,
  display: "flex",
  position: "sticky",
  zIndex: "500",
  top: "0px",
  flexDirection: "row",
  alignItems: "center",
  border: "none",
  borderBottom: "2px solid var(--gray200)",
  borderRadius: "4px 4px 0px 0px",
  color: "var(--gray700)",
  fontFamily: "var(--avenir)",
  fontWeight: "500",
  "&:focus": {
    outline: "none",
  },
  "&::placeholder": {
    color: "var(--gray500)",
  },
  "@tablet": {
    padding: "10px 14px 8px",
    height: "36px",
    fontSize: "14px",
    borderBottom: "1px solid var(--gray200)",
  },
});

const FilterContentBox = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const PopoverWrapper = ({ wrapperProps, triggerId, children }) => {
  const { popupState, closeFilterEdit, saveFilter, selectedFilter, daysWithOrders, setSearchText } =
    wrapperProps;
  const earliestDate = new Date(daysWithOrders[0]);
  const initialMonth = earliestDate > new Date() ? earliestDate : new Date();
  return (
    <Popover
      open={!!popupState && triggerId === popupState.triggerId}
      close={closeFilterEdit}
      trigger={children}
      closeButton={
        popupState &&
        popupState.isLastStage && (
          <Popover.SaveButton
            onClick={() => {
              saveFilter(_.cloneDeep(selectedFilter));
              trackOrdersFiltered({ filter: selectedFilter });
            }}
            disabled={
              selectedFilter.isDateFilter
                ? popupState.dateFilterType === "RANGE"
                  ? selectedFilter.selectedDays.includes(undefined)
                  : !selectedFilter.selectedDate
                : selectedFilter.selectedValueOptions.length === 0
            }
          >
            Apply
          </Popover.SaveButton>
        )
      }
    >
      {popupState && (
        <FilterContentBox>
          <FilterSearchbar
            placeholder={`${popupState.topText}...`}
            type="text"
            value={popupState.searchText}
            onChange={(e) => setSearchText(e.target.value)}
            disabled={!popupState.isSearchable}
          />
          {popupState.dateFilterType === "SINGLE" && (
            <Flex data-mode="single" css={{ justifyContent: "center" }}>
              <DayPicker
                mode="single"
                fromMonth={selectedFilter.isDateFilter === "fulfillment" ? undefined : earliestDate}
                initialMonth={initialMonth}
                modifiers={{
                  disabled:
                    selectedFilter.isDateFilter === "fulfillment"
                      ? undefined
                      : { before: earliestDate },
                  highlight: daysWithOrders,
                }}
                selectedDays={selectedFilter.selectedDate}
                onDayClick={(date) => popupState.setSelectedDate(date)}
              />
            </Flex>
          )}
          {popupState.dateFilterType === "RANGE" && (
            <Flex data-mode="range" css={{ justifyContent: "center" }}>
              <DayPicker
                mode="range"
                fromMonth={selectedFilter.isDateFilter === "fulfillment" ? undefined : earliestDate}
                initialMonth={initialMonth}
                modifiers={{
                  disabled:
                    selectedFilter.isDateFilter === "fulfillment"
                      ? undefined
                      : { before: earliestDate },
                  start:
                    selectedFilter.selectedDays[0] === undefined
                      ? undefined
                      : new Date(selectedFilter.selectedDays[0]),
                  end:
                    selectedFilter.selectedDays[1] === undefined
                      ? undefined
                      : new Date(selectedFilter.selectedDays[1]),
                  highlight: daysWithOrders,
                }}
                selectedDays={[
                  selectedFilter.selectedDays[0] === undefined
                    ? undefined
                    : new Date(selectedFilter.selectedDays[0]),
                  {
                    from:
                      selectedFilter.selectedDays[0] === undefined
                        ? undefined
                        : new Date(selectedFilter.selectedDays[0]),
                    to:
                      selectedFilter.selectedDays[1] === undefined
                        ? undefined
                        : new Date(selectedFilter.selectedDays[1]),
                  },
                ]}
                onDayClick={(day) => popupState.setSelectedDays(day)}
              />
            </Flex>
          )}
          {popupState.options.map((option, optionIndex) => {
            return (
              <Popover.LineItem
                key={optionIndex}
                title={option.title}
                showCheckbox={option.showCheckbox}
                subtitle={option.subtitle}
                selected={
                  selectedFilter && selectedFilter.selectedValueOptions.includes(option.title)
                }
                onClick={option.onClick}
              />
            );
          })}
        </FilterContentBox>
      )}
    </Popover>
  );
};

const ActiveFilterButtonPrimitive = styled("div", {
  $$border: "1px solid var(--gray200)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "white",
  height: "32px",
  whiteSpace: "nowrap",
  paddingInline: "8px",
  borderLeft: "$$border",
  borderTop: "$$border",
  borderBottom: "$$border",
  transition: "background-color 100ms ease-in-out",

  "&:hover": {
    backgroundColor: "var(--gray050)",
  },
  "&:disabled": {
    cursor: "default",
    backgroundColor: "white",
  },
  "& span": {
    color: "var(--gray700)",
    fontFamily: "var(--avenir)",
    fontSize: "12px",
    fontWeight: "500",
  },
  "& svg": {
    height: 12,
    width: 12,
  },
  variants: {
    order: {
      first: {
        borderRadius: "8px 0px 0px 8px",
      },
      middle: {
        borderRadius: "0px",
      },
      last: {
        borderRight: "$$border",
        borderRadius: "0px 8px 8px 0px",
      },
    },
  },
});

const ActiveFilterButton = ({ onClick, text, order, disabled, children, ...props }) => {
  // limit the length of the inner text to 30 characters
  // if you want to avoid this behavior, pass in a <span/> as a child
  const innerText = text ? (text.length <= 30 ? text : `${text.split(",").length} selected`) : "";
  return (
    <ActiveFilterButtonPrimitive onClick={onClick} order={order} disabled={disabled} {...props}>
      {text && <span>{innerText}</span>}
      {children}
    </ActiveFilterButtonPrimitive>
  );
};

const ActiveFilter = ({ index, filter, clearFilter, setFilterEdit, wrapperProps }) => {
  return (
    <Flex key={index} css={{ alignItems: "center" }}>
      <PopoverWrapper wrapperProps={wrapperProps}>
        <ActiveFilterButton order="first" disabled text={filter.title} />
      </PopoverWrapper>
      <PopoverWrapper wrapperProps={wrapperProps} triggerId={filter.id + ">1"}>
        <ActiveFilterButton
          onClick={() => setFilterEdit(filter, 1, filter.id + ">1")}
          order="middle"
          text={
            filter.conjuctionTextOverrides &&
            typeof filter.conjuctionTextOverrides[filter.selectedConjuction] === "string"
              ? filter.conjuctionTextOverrides[filter.selectedConjuction]
              : filter.selectedConjuction
          }
        />
      </PopoverWrapper>
      <PopoverWrapper wrapperProps={wrapperProps} triggerId={filter.id + ">2"}>
        <ActiveFilterButton
          onClick={() => setFilterEdit(filter, 2, filter.id + ">2")}
          order="middle"
          text={
            filter.isDateFilter
              ? filter.selectedConjuction === "is between"
                ? getTimestampDayOfWeekMonthDate(filter.selectedDays[0]) +
                  " and " +
                  getTimestampDayOfWeekMonthDate(filter.selectedDays[1])
                : getTimestampDayOfWeekMonthDate(filter.selectedDate.getTime())
              : filter.selectedValueOptions.join(", ")
          }
        />
      </PopoverWrapper>
      <ActiveFilterButton order="last" onClick={clearFilter}>
        <Cross2Icon />
      </ActiveFilterButton>
    </Flex>
  );
};

const ActiveFiltersGrid = styled("div", {
  display: "flex",
  flexFlow: "row wrap",
  alignItems: "center",
  gap: 8,
  paddingTop: 12,
  "@desktop_sm": {
    backgroundColor: "transparent",
    gap: 12,
  },
});

export const ComplexFilter = ({ part, orderSearchText, setFilteredData, data, filters }) => {
  const [constructedFilters, setConstructedFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedFilterStage, setSelectedFilterStage] = useState(0);
  const [triggerId, setTriggerId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const activeFilters = useSelector((state) => state.orderManagement.activeFilters);
  const dispatch = useDispatch();

  const constructFilters = useCallback((filters, data) => {
    const constructedFilters = [];
    if (!filters) return;
    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];

      filter.selectedValueOptions = [];

      if (filter.isDateFilter) {
        filter.conjuctions = [
          "is an exact time slot", // ! TODO SHOULD BE ENUM
          "is on",
          "is between",
          "is after",
          "is before",
        ];
      } else {
        filter.conjuctions = ["is", "is either", "is not", "is neither", "is both"];
      }

      filter.conjuctions = filter.conjuctions.filter((conjuction) => {
        if (Array.isArray(filter.disabledConjuctions)) {
          return !filter.disabledConjuctions.includes(conjuction);
        }
        return true;
      });

      if (filter.isDateFilter) {
        constructedFilters.push(filter);
      } else {
        const valueOptions = new Set();
        Object.keys(data).forEach((key) => {
          const object = data[key];
          const objectDataFilters = filter.getObjectDataForFilter(object);
          for (let j = 0; j < objectDataFilters.length; j++) {
            valueOptions.add(objectDataFilters[j]);
          }
        });

        const valueOptionsArr = Array.from(valueOptions).sort(); // ! TODO CHANGE SORT FOR TIME SLOTS
        if (valueOptionsArr.length > 1) {
          filter.valueOptions = valueOptionsArr;
          constructedFilters.push(filter);
        }
      }

      if (filter.isDateFilter) {
        filter.selectedDays = [undefined, undefined];
      }
    }
    setConstructedFilters(constructedFilters);
  }, []);

  const filterData = useCallback(
    (activeFilters, data) => {
      if (activeFilters.length === 0) {
        setFilteredData(data);
        return;
      }
      const filteredData = {};
      Object.keys(data).forEach((key) => {
        const obj = data[key];
        let filtersPassed = false;
        for (let i = 0; i < activeFilters.length; i++) {
          const filter = activeFilters[i];

          if (filter.isDateFilter) {
            const objTimestamp = filter.getObjectTimestamp(obj);
            if (filter.selectedConjuction === "is an exact time slot") {
              // do nothing
            } else if (filter.selectedConjuction === "is on") {
              if (
                getTimestampDayOfWeekMonthDate(filter.selectedDate.getTime()) !==
                getTimestampDayOfWeekMonthDate(objTimestamp)
              ) {
                break;
              }
            } else if (filter.selectedConjuction === "is between") {
              if (objTimestamp < filter.selectedDays[0] || objTimestamp > filter.selectedDays[1])
                break;
            } else if (filter.selectedConjuction === "is after") {
              if (objTimestamp < filter.selectedDate.getTime()) {
                break;
              }
            } else if (filter.selectedConjuction === "is before") {
              if (objTimestamp >= filter.selectedDate.getTime()) {
                break;
              }
            }
          } else {
            if (filter.selectedConjuction === "is") {
              if (!filter.getObjectDataForFilter(obj).includes(filter.selectedValueOptions[0]))
                break;
            } else if (filter.selectedConjuction === "is either") {
              let lbreak = true;
              for (let j = 0; j < filter.selectedValueOptions.length; j++) {
                if (filter.getObjectDataForFilter(obj).includes(filter.selectedValueOptions[j]))
                  lbreak = false;
              }
              if (lbreak) break;
            } else if (filter.selectedConjuction === "is not") {
              if (filter.getObjectDataForFilter(obj).includes(filter.selectedValueOptions[0]))
                break;
            } else if (filter.selectedConjuction === "is neither") {
              let lbreak = false;
              for (let j = 0; j < filter.selectedValueOptions.length; j++) {
                if (filter.getObjectDataForFilter(obj).includes(filter.selectedValueOptions[j]))
                  lbreak = true;
              }
              if (lbreak) break;
            } else if (filter.selectedConjuction === "is both") {
              let lbreak = false;
              for (let j = 0; j < filter.selectedValueOptions.length; j++) {
                if (!filter.getObjectDataForFilter(obj).includes(filter.selectedValueOptions[j]))
                  lbreak = true;
              }
              if (lbreak) break;
            }
          }
          if (i === activeFilters.length - 1) filtersPassed = true;
        }
        if (filtersPassed) filteredData[key] = obj;
      });

      setFilteredData(filteredData);
    },
    [setFilteredData]
  );

  function setFilterEdit(filter, stage, triggerId) {
    // ! DELICATE AF, should probably combine together or sync state
    setSelectedFilterStage(stage);
    setSelectedFilter(filter);
    triggerId && setTriggerId(triggerId);
  }

  function getPopupState() {
    let popupState = null;
    if (selectedFilterStage === -1) return null;
    if (selectedFilterStage === 0) {
      popupState = {
        topText: "Filter",
        isSearchable: true,
        triggerId,
        searchText: searchText,
        unfilteredOptions: constructedFilters.map((filter) => {
          return {
            title: filter.title,
            subtitle: !filter.isDateFilter && filter.valueOptions.join(", ").substring(0, 40),
            onClick: () => {
              if (filter.isDateFilter) setFilterEdit(filter, 1);
              else {
                filter.selectedConjuction = "is either";
                setFilterEdit(filter, 2);
              }
            },
          };
        }),
      };
    } else if (selectedFilterStage === 1) {
      popupState = {
        topText: selectedFilter.title,
        isSearchable: true,
        triggerId,
        searchText: searchText,
        unfilteredOptions: selectedFilter.conjuctions
          .map((conjuction) => {
            return {
              title:
                selectedFilter.conjuctionTextOverrides &&
                typeof selectedFilter.conjuctionTextOverrides[conjuction] === "string"
                  ? selectedFilter.conjuctionTextOverrides[conjuction]
                  : conjuction,
              baseConjuction: conjuction,
              onClick: () => {
                const nSf = _.cloneDeep(selectedFilter);
                nSf.selectedConjuction = conjuction;
                if (selectedFilter.id && !selectedFilter.isDateFilter) {
                  saveFilter(nSf);
                } else {
                  setSelectedFilter(nSf);
                  setFilterEdit(nSf, 2);
                }
              },
            };
          })
          .filter((option) => {
            if (selectedFilter.isDateFilter) return true;

            if (selectedFilter.selectedConjuction === "is neither") {
              if (
                option.baseConjuction === "is both" ||
                option.baseConjuction === "is either" ||
                option.baseConjuction === "is neither"
              )
                return true;
            }

            if (selectedFilter.selectedConjuction === "is either") {
              if (
                option.baseConjuction === "is both" ||
                option.baseConjuction === "is either" ||
                option.baseConjuction === "is neither"
              )
                return true;
            }
            if (selectedFilter.selectedConjuction === "is both") {
              if (
                option.baseConjuction === "is either" ||
                option.baseConjuction === "is both" ||
                option.baseConjuction === "is neither"
              )
                return true;
            }

            if (selectedFilter.selectedConjuction === "is not") {
              if (option.baseConjuction === "is" || option.baseConjuction === "is not") return true;
            }

            if (selectedFilter.selectedConjuction === "is") {
              if (option.baseConjuction === "is not" || option.baseConjuction === "is") return true;
            }
            return false;
          }),
      };
    } else if (selectedFilterStage === 2) {
      const isDateFilter = selectedFilter.isDateFilter;

      let dateFilterType = null;
      let topText = selectedFilter.title;
      if (isDateFilter) {
        const selectedConjuction = selectedFilter.selectedConjuction;
        if (selectedConjuction === "is an exact time slot") {
          topText = "Exact time slot from...";
        } else {
          topText += " " + selectedConjuction;
        }

        if (
          selectedConjuction === "is on" ||
          selectedConjuction == "is after" ||
          selectedConjuction == "is before"
        ) {
          dateFilterType = "SINGLE";
        } else {
          dateFilterType = "RANGE";
        }
      }

      popupState = {
        topText: topText,
        isSearchable: !dateFilterType,
        triggerId,
        isLastStage: true,
        dateFilterType: dateFilterType,
        searchText: searchText,
        setSelectedDate: (date) => {
          const nSf = _.cloneDeep(selectedFilter);
          date.setHours(0, 0, 0, 0);
          nSf.selectedDate = date;
          setSelectedFilter(nSf);
        },
        setSelectedDays: (day) => {
          const nSf = _.cloneDeep(selectedFilter);
          const orderGroupingStart = selectedFilter.selectedDays[0];
          const orderGroupingEnd = selectedFilter.selectedDays[1];
          const dayRangeStart =
            orderGroupingStart === undefined ? orderGroupingStart : new Date(orderGroupingStart);
          const dayRangeEnd =
            orderGroupingEnd === undefined ? orderGroupingEnd : new Date(orderGroupingEnd);
          const range = DateUtils.addDayToRange(day, {
            from: dayRangeStart,
            to: dayRangeEnd,
          });
          if (range.from) range.from.setHours(0, 0, 0, 0);
          if (range.to) range.to.setHours(23, 59, 59, 59, 59);
          const fromValue =
            range.from !== undefined && range.from !== null ? range.from.getTime() : undefined;
          const toValue =
            range.to !== undefined && range.from !== null ? range.to.getTime() : undefined;
          nSf.selectedDays = [fromValue, toValue];
          setSelectedFilter(nSf);
        },

        unfilteredOptions: isDateFilter
          ? []
          : selectedFilter.valueOptions.map((value) => {
              return {
                title: value,
                showCheckbox: true,
                onClick: () => {
                  const nSf = _.cloneDeep(selectedFilter);
                  const index = nSf.selectedValueOptions.indexOf(value);
                  if (index === -1) {
                    nSf.selectedValueOptions.push(value);
                  } else {
                    nSf.selectedValueOptions.splice(index, 1);
                  }
                  setSelectedFilter(nSf);
                },
              };
            }),
      };
    }

    popupState.options = popupState.unfilteredOptions.filter((option) => {
      return option.title
        .toLowerCase()
        .replace(" ", "")
        .includes(searchText.toLowerCase().replace(" ", ""));
    });

    return popupState;
  }

  function addFilter(triggerId) {
    setFilterEdit(null, 0, triggerId);
  }

  function closeFilterEdit() {
    setFilterEdit(null, -1, null);
  }

  function saveFilter(filterToSave) {
    if (!filterToSave.id) filterToSave.id = uuid();

    const newActiveFilters = [];

    let filterSeen = false;
    for (let i = 0; i < activeFilters.length; i++) {
      const activeFilter = activeFilters[i];
      if (activeFilter.id === filterToSave.id) {
        newActiveFilters.push(filterToSave);
        filterSeen = true;
      } else {
        newActiveFilters.push(activeFilter);
      }
    }
    if (!filterSeen) {
      newActiveFilters.push(filterToSave);
    }

    if (
      !filterToSave.isDateFilter &&
      filterToSave.selectedConjuction === "is either" &&
      filterToSave.selectedValueOptions.length == 1
    ) {
      filterToSave.selectedConjuction = "is";
    }

    if (
      !filterToSave.isDateFilter &&
      filterToSave.selectedConjuction === "is" &&
      filterToSave.selectedValueOptions.length > 1
    ) {
      filterToSave.selectedConjuction = "is either";
    }

    if (
      !filterToSave.isDateFilter &&
      filterToSave.selectedConjuction === "is neither" &&
      filterToSave.selectedValueOptions.length == 1
    ) {
      filterToSave.selectedConjuction = "is not";
    }

    if (
      !filterToSave.isDateFilter &&
      filterToSave.selectedConjuction === "is not" &&
      filterToSave.selectedValueOptions.length > 1
    ) {
      filterToSave.selectedConjuction = "is neither";
    }
    setActiveFilters(newActiveFilters)(dispatch);
    closeFilterEdit();
  }

  function getDaysWithOrders(type) {
    const daysWithOrders = new Set();
    Object.keys(data).forEach((pI) => {
      const order = data[pI];
      const pt = type === "fulfillment" ? order.timeSlot.startTime : order.orderPlaced;
      const filteredDate = new Date(pt);
      filteredDate.setHours(0, 0, 0, 0);
      daysWithOrders.add(filteredDate.getTime());
    });
    return Array.from(daysWithOrders)
      .sort((a, b) => a - b)
      .map((timestamp) => new Date(timestamp));
  }

  function getWrapperProps() {
    const daysWithOrders = selectedFilter?.isDateFilter
      ? selectedFilter.isDateFilter === "fulfillment"
        ? getDaysWithOrders("fulfillment")
        : getDaysWithOrders("placed")
      : [];
    return {
      selectedFilter,
      closeFilterEdit,
      saveFilter,
      daysWithOrders,
      setSearchText,
      popupState: getPopupState(),
    };
  }

  const prevDataProp = usePrevious(data);
  const prevFiltersProp = usePrevious(filters);
  const prevOrderSearchTextProp = usePrevious(orderSearchText);
  const prevActiveFiltersProp = usePrevious(activeFilters);

  useEffect(() => {
    if (!prevDataProp) return;
    if (!_.isEqual(prevFiltersProp, filters)) constructFilters(filters, data);
    if (
      !_.isEqual(prevDataProp, data) ||
      !_.isEqual(prevActiveFiltersProp, activeFilters) ||
      !_.isEqual(prevOrderSearchTextProp, orderSearchText)
    )
      filterData(activeFilters, data);
  }, [
    data,
    filters,
    activeFilters,
    orderSearchText,
    filterData,
    constructFilters,
    prevDataProp,
    prevFiltersProp,
    prevActiveFiltersProp,
    prevOrderSearchTextProp,
  ]);

  const wrapperProps = getWrapperProps();

  return (
    <>
      {part === "create" && (
        <Flex css={{ alignItems: "center" }}>
          <PopoverWrapper wrapperProps={wrapperProps} triggerId="filterButton">
            <FilterButton
              onClick={() => addFilter("filterButton")}
              text={`${activeFilters.length > 0 ? "Add " : ""}Filter`}
            >
              {activeFilters.length > 0 ? <PlusIcon /> : <MixerHorizontalIcon />}
            </FilterButton>
          </PopoverWrapper>
          {activeFilters.length > 0 && (
            <FilterButton
              css={{ marginLeft: "$xs" }}
              text="Clear"
              outline
              color="warning"
              onClick={() => setActiveFilters([])(dispatch)}
            >
              <Cross2Icon />
            </FilterButton>
          )}
        </Flex>
      )}
      {part === "active" && activeFilters.length > 0 && (
        <ActiveFiltersGrid>
          {activeFilters.map((filter, index) => {
            return (
              <ActiveFilter
                filter={filter}
                key={index}
                setFilterEdit={setFilterEdit}
                wrapperProps={wrapperProps}
                clearFilter={() =>
                  setActiveFilters(
                    activeFilters.filter((_, fIndex) => {
                      return index !== fIndex;
                    })
                  )(dispatch)
                }
              />
            );
          })}
        </ActiveFiltersGrid>
      )}
    </>
  );
};
