/* eslint-disable react/prop-types */
import React from "react";
import "./css/ErrorLine.css";
import { textstyles } from "../../visly";

interface ErrorLineProps {
  errorText: string;
  showError: boolean;
  style?: React.CSSProperties;
}

const ErrorLine = ({ errorText, showError, style }: ErrorLineProps) => {
  if (showError && errorText.length !== 0) {
    return (
      <div className="errorLine" style={{ ...style }}>
        <img
          className="errorLineImage"
          src={"https://ucarecdn.com/fa00460e-b13d-4bc4-a2e2-2f789974aa9b/errorCode.png"}
        />
        <p style={{ ...textstyles.button, color: "hsl(10, 82.0%, 43.5%)", marginTop: 2 }}>
          {errorText}
        </p>
      </div>
    );
  }

  return <div />;
};

export default ErrorLine;
