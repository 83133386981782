// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./PayoutHist.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as Header } from "./PayoutHist.Header";

const styles = [
  {
    type: "default",
    layers: {
      "2MNnerJGdq": {
        none: {
          text: "Payout History",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xlarge",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "xsmall",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xlarge",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "xsmall",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["xlarge", "large", "med", "small", "xsmall"],
  },
];

export const PayoutHistContext = createContext(null);

function PayoutHist(_props) {
  const defaults = useContext(PayoutHistContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="YYk9dxhuxx"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "YYk9dxhuxx",
        scope: "9s2P4Zzcb2",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_X1zmgJBTET"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_9s2P4Zzcb2_X1zmgJBTET"
          }
          key={"X1zmgJBTET"}
          addSpacing={false}
          element={getStyle("X1zmgJBTET", "htmlElement")}
        >
          <TextPrimitive
            id={"Text_2MNnerJGdq"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_9s2P4Zzcb2_2MNnerJGdq"
            }
            key={"2MNnerJGdq"}
            text={getStyle("2MNnerJGdq", "text")}
            element={getStyle("2MNnerJGdq", "htmlElement")}
          />
          <ContainerPrimitive
            id={"Container_T2wLmyKgi1"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_9s2P4Zzcb2_T2wLmyKgi1"
            }
            key={"T2wLmyKgi1"}
            addSpacing={false}
            element={getStyle("T2wLmyKgi1", "htmlElement")}
          >
            {[
              props.Header === undefined ? (
                <Header key={"VSzzKngA4f"} />
              ) : (
                props.Header
              ),
              <ContainerPrimitive
                key={"DNwZRrgQzo"}
                id={"Slot_DNwZRrgQzo"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_9s2P4Zzcb2_DNwZRrgQzo"
                }
                addSpacing={false}
              >
                {props.children}
              </ContainerPrimitive>,
            ]}
          </ContainerPrimitive>
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

PayoutHist.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["xlarge", "large", "med", "small", "xsmall"],
  },
];

export default PayoutHist;
