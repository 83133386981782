import { styled } from "../../stitches.config";
import React from "react";

const IconButtonPrimitive = styled("div", {
  all: "unset",
  fontFamily: "inherit",
  borderRadius: "$xs",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  flexShrink: 0,
  cursor: "pointer",
  transition: "background-color 150ms ease-in-out",
  "&:hover": { backgroundColor: "$gray5" },
  "&:focus": { outline: "0px solid transparent" },
  "&:focus-visible": { outline: "2px solid $accent6", outlineOffset: 4 },
  variants: {
    rounded: {
      true: {
        borderRadius: "$pill",
      },
    },
    filled: {
      true: {
        backgroundColor: "$gray4",
        color: "$accent12",
      },
    },
    selected: {
      true: {
        backgroundColor: "$accent4",
      },
    },
    size: {
      xsmall: {
        height: 24,
        width: 24,
        "& svg": {
          height: 12,
          width: 12,
        },
      },
      small: {
        height: 24,
        width: 24,
        "& svg": {
          height: 16,
          width: 16,
        },
      },
      medium: {
        height: 32,
        width: 32,
        "& svg": {
          height: 18,
          width: 18,
        },
      },
      large: {
        height: 40,
        width: 40,
        "& svg": {
          height: 24,
          width: 24,
        },
      },
    },
    color: {
      default: {
        color: "$accent11",
        backgroundColor: "$transparent",
      },
      transparent_success: {
        color: "$success11",
        backgroundColor: "$transparent",
      },
      filled_success: {
        color: "$success11",
        backgroundColor: "$success3",
      },
    },
  },
  defaultVariants: {
    color: "default",
    size: "medium",
    rounded: false,
    filled: false,
  },
});

/**
 * @param {Node} icon - Will be colored according to the "color" css property on the button itself, use icons from radix icons: https://icons.radix-ui.com/
 * @param {string} [size="medium"] - optional, "xsmall" | "small" | "medium" | "large" to control size
 * @param {boolean} [rounded=false] - Whether the button should have rounded corners
 * @param {boolean} [filled=false] - Whether the button should have a filled background
 * @returns {Node} IconButton
 */
export const IconButton = ({ icon, rounded, filled, size, ...props }) => {
  return (
    <IconButtonPrimitive rounded={rounded} filled={filled} size={size} {...props}>
      {icon}
    </IconButtonPrimitive>
  );
};
