// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./EventOverview.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as CreateEventButton } from "../Events/DropdownButton";

const styles = [
  {
    type: "default",
    layers: {
      GYD8oL32GC: {
        none: {
          text: "Your Events",
        },
      },
      "8o5urXLgJx": {
        none: {
          htmlElement: "header",
        },
      },
      "58otNipvAv": {
        none: {
          htmlElement: "main",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
  {
    type: "boolean",
    propName: "isEmpty",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      CJ65y74gEw: {},
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {
          CJ65y74gEw: {},
        },
      },
      {
        propValue: "small",
        layers: {
          CJ65y74gEw: {},
        },
      },
      {
        propValue: "med",
        layers: {
          CJ65y74gEw: {},
        },
      },
      {
        propValue: "large",
        layers: {
          CJ65y74gEw: {},
        },
      },
      {
        propValue: "xlarge",
        layers: {
          CJ65y74gEw: {},
        },
      },
    ],
  },
  {
    type: "boolean",
    propName: "isEmpty",
    layers: {
      CJ65y74gEw: {},
    },
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
  {
    type: "boolean",
    propName: "isEmpty",
  },
];

export const EventOverviewContext = createContext(null);

function EventOverview(_props) {
  const defaults = useContext(EventOverviewContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="C1xTYGAgbq"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "C1xTYGAgbq",
        scope: "Pz5T4LuNQc",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"headerWrapper_8o5urXLgJx"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Pz5T4LuNQc_8o5urXLgJx"
            }
            key={"8o5urXLgJx"}
            addSpacing={false}
            element={getStyle("8o5urXLgJx", "htmlElement")}
          >
            <TextPrimitive
              id={"title_GYD8oL32GC"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Pz5T4LuNQc_GYD8oL32GC"
              }
              key={"GYD8oL32GC"}
              text={
                exists(props.title)
                  ? props.title
                  : getStyle("GYD8oL32GC", "text")
              }
              element={getStyle("GYD8oL32GC", "htmlElement")}
            />
            <SpacerPrimitive
              id={"Spacer_DBbLwotcnK"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Pz5T4LuNQc_DBbLwotcnK"
              }
              key={"DBbLwotcnK"}
            />
            <ContainerPrimitive
              key={"Q5KWyE1zer"}
              id={"Slot_Q5KWyE1zer"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Pz5T4LuNQc_Q5KWyE1zer"
              }
              addSpacing={false}
            >
              {props.createEventButton}
            </ContainerPrimitive>
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"scrollbox_58otNipvAv"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Pz5T4LuNQc_58otNipvAv"
            }
            key={"58otNipvAv"}
            addSpacing={false}
            element={getStyle("58otNipvAv", "htmlElement")}
          >
            {
              <ContainerPrimitive
                key={"BqgDBLPLKZ"}
                id={"Slot_BqgDBLPLKZ"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_Pz5T4LuNQc_BqgDBLPLKZ"
                }
                addSpacing={false}
              >
                {props.children}
              </ContainerPrimitive>
            }
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

EventOverview.CreateEventButton = CreateEventButton;
EventOverview.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
  {
    name: "isEmpty",
    type: "variant",
  },
];

export default EventOverview;
