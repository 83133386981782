// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./AddEditLocation.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive } from "../_internal_primitives";
import {
  default as LocationTitle,
  LabeledInputContext as LocationTitleContext,
  default as Instructions,
  LabeledInputContext as InstructionsContext,
  default as Photo,
  LabeledInputContext as PhotoContext,
} from "../Events/LabeledInput";
import {
  default as HideAddress,
  LabeledSwitchContext as HideAddressContext,
} from "../Events/LabeledSwitch";
import { default as AddressContainer } from "./AddEditLocation.AddressContainer";

const styles = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      KAckZiuss4: {
        none: {
          label: "Location Name",
        },
      },
      Y1ouzgaX8n: {
        none: {
          label: "Pickup Instructions",
        },
      },
      ConNE6YtMd: {
        none: {
          title: "Hide Address",
        },
      },
      CQ4eG3mXH1: {
        none: {
          label: "Pickup Photo",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {
          KAckZiuss4: {},
          Y1ouzgaX8n: {},
          ConNE6YtMd: {},
          CQ4eG3mXH1: {},
        },
      },
      {
        propValue: "small",
        layers: {
          KAckZiuss4: {},
          Y1ouzgaX8n: {},
          ConNE6YtMd: {},
          CQ4eG3mXH1: {},
        },
      },
      {
        propValue: "med",
        layers: {
          KAckZiuss4: {},
          Y1ouzgaX8n: {},
          ConNE6YtMd: {},
          CQ4eG3mXH1: {},
        },
      },
      {
        propValue: "large",
        layers: {
          KAckZiuss4: {},
          Y1ouzgaX8n: {},
          ConNE6YtMd: {},
          CQ4eG3mXH1: {},
        },
      },
      {
        propValue: "xlarge",
        layers: {
          KAckZiuss4: {},
          Y1ouzgaX8n: {},
          ConNE6YtMd: {},
          CQ4eG3mXH1: {},
        },
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export const AddEditLocationContext = createContext(null);

function AddEditLocation(_props) {
  const defaults = useContext(AddEditLocationContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="N6aWRzoHjv"
      addSpacing={true}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "N6aWRzoHjv",
        scope: "HTSHEpZoxS",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => [
        props.LocationTitle === undefined ? (
          <LocationTitle
            key={"KAckZiuss4"}
            {...getCompositeDefaultProps("KAckZiuss4")}
            id="LocationTitle_KAckZiuss4"
            className="__visly_reset_7X7HTLRqyD __visly_scope_HTSHEpZoxS_KAckZiuss4"
          />
        ) : (
          <LocationTitleContext.Provider
            key="KAckZiuss4-provider"
            value={{
              key: "KAckZiuss4",
              id: "LocationTitle_KAckZiuss4",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_HTSHEpZoxS_KAckZiuss4",
              ...getCompositeDefaultProps("KAckZiuss4"),
            }}
          >
            {props.LocationTitle}
          </LocationTitleContext.Provider>
        ),
        props.AddressContainer === undefined ? (
          <AddressContainer key={"21jhKPJHkB"} />
        ) : (
          props.AddressContainer
        ),
        props.Instructions === undefined ? (
          <Instructions
            key={"Y1ouzgaX8n"}
            {...getCompositeDefaultProps("Y1ouzgaX8n")}
            id="Instructions_Y1ouzgaX8n"
            className="__visly_reset_7X7HTLRqyD __visly_scope_HTSHEpZoxS_Y1ouzgaX8n"
          />
        ) : (
          <InstructionsContext.Provider
            key="Y1ouzgaX8n-provider"
            value={{
              key: "Y1ouzgaX8n",
              id: "Instructions_Y1ouzgaX8n",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_HTSHEpZoxS_Y1ouzgaX8n",
              ...getCompositeDefaultProps("Y1ouzgaX8n"),
            }}
          >
            {props.Instructions}
          </InstructionsContext.Provider>
        ),
        props.Photo === undefined ? (
          <Photo
            key={"CQ4eG3mXH1"}
            {...getCompositeDefaultProps("CQ4eG3mXH1")}
            id="Photo_CQ4eG3mXH1"
            className="__visly_reset_7X7HTLRqyD __visly_scope_HTSHEpZoxS_CQ4eG3mXH1"
          />
        ) : (
          <PhotoContext.Provider
            key="CQ4eG3mXH1-provider"
            value={{
              key: "CQ4eG3mXH1",
              id: "Photo_CQ4eG3mXH1",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_HTSHEpZoxS_CQ4eG3mXH1",
              ...getCompositeDefaultProps("CQ4eG3mXH1"),
            }}
          >
            {props.Photo}
          </PhotoContext.Provider>
        ),
        props.HideAddress === undefined ? (
          <HideAddress
            key={"ConNE6YtMd"}
            {...getCompositeDefaultProps("ConNE6YtMd")}
            id="HideAddress_ConNE6YtMd"
            className="__visly_reset_7X7HTLRqyD __visly_scope_HTSHEpZoxS_ConNE6YtMd"
          />
        ) : (
          <HideAddressContext.Provider
            key="ConNE6YtMd-provider"
            value={{
              key: "ConNE6YtMd",
              id: "HideAddress_ConNE6YtMd",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_HTSHEpZoxS_ConNE6YtMd",
              ...getCompositeDefaultProps("ConNE6YtMd"),
            }}
          >
            {props.HideAddress}
          </HideAddressContext.Provider>
        ),
      ]}
    </RootPrimitive>
  );
}

AddEditLocation.LocationTitle = LocationTitle;
AddEditLocation.Instructions = Instructions;
AddEditLocation.HideAddress = HideAddress;
AddEditLocation.Photo = Photo;
AddEditLocation.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export default AddEditLocation;
