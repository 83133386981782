/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useRef, useState } from "react";
import fetchRetry from "../../hotplate-storefront/actions/FetchRetry";
import { CSVLink } from "react-csv";
import DayPicker, { DateUtils } from "react-day-picker";
import { useSelector } from "react-redux";
import { FirebaseContext } from "../../firebaseSocket";
import {
  getSubtotal,
  getOrderRevenue,
  getTimestampMonth,
  getTimestampHoursMinutesAMPM,
  getTimestampDayOfWeekMonthDate,
  getAddressString,
  getDateTimeString,
  getTransactionOrderStatus,
  toFixed,
} from "@hotplate/utils-ts/helperFunctions";
import AnimationContainer from "../../hotplate-common/AnimationContainer";
import ReactLoading from "react-loading";
import { SortButton } from "../../visly/Payout";
import { backendUrl } from "../../hotplate-storefront/actions/types";
import { Status } from "../../visly/Events";
import { FilterDropdown } from "../../visly/Management";

import { textstyles, icons, colors, useBreakpoint } from "../../visly";

import { SearchBar, Button } from "../../visly/Primitives";
import {
  trackTransactionsExported,
  trackTransactionsFilteredByDate,
  trackTransactionsPageChanged,
  trackTransactionsSearched,
  trackTransactionsSortUpdated,
} from "./analytics";
import { usePortalUser } from "../../auth";
import { usePrevious } from "../../hooks";

export default function TransactionHistory({ rowOnClick }: { rowOnClick: (order: any) => void }) {
  const { chefId: hostId } = usePortalUser();

  const {
    transactionOrders,
    getTransactionOrdersLoading,
    getTransactionOrdersError,
    totalTransactionOrders,
    initiateCustomerRefundLoading,
    initiateCustomerRefundError,
  } = useSelector((state) => state.orderManagement);

  const [dateOpen, setDateOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0);
  const [sortSelected, setSortSelected] = useState("DATE:ASCENDING");
  const [orderGroupingStart, setOrderGroupingStart] = useState(undefined);
  const [orderGroupingEnd, setOrderGroupingEnd] = useState(undefined);
  const [csvDownloadURL, setCsvDownloadURL] = useState(undefined);
  const [exportSubscriberData, setExportSubscriberData] = useState([]);
  const [getCsvDataLoading, setGetCsvDataLoading] = useState(false);
  const [getCsvDataSubscribersLoading, setGetCsvDataSubscribersLoading] = useState(false);

  const firebaseContext = useContext(FirebaseContext);
  const csvLinkRef = useRef<HTMLAnchorElement | null>(null);
  const csvLinkSubscribersRef = useRef();
  const _isMounted = useRef(false);

  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);

  useEffect(() => {
    _isMounted.current = true;
    getTransactionOrders();
    window.addEventListener("keydown", handleEnter);

    return () => {
      _isMounted.current = false;
      window.removeEventListener("keydown", handleEnter);
    };
  }, []);

  const prevState = usePrevious({
    dateOpen,
    searchText,
    page,
    sortSelected,
    orderGroupingStart,
    orderGroupingEnd,
    csvDownloadURL,
    exportSubscriberData,
    getCsvDataLoading,
    getCsvDataSubscribersLoading,
  });
  const prevProps = usePrevious({
    hostId,
    initiateCustomerRefundLoading,
  });

  useEffect(() => {
    if (!prevProps || !prevState) {
      return;
    }

    if (prevState.sortSelected !== sortSelected) {
      trackTransactionsSortUpdated({
        sort: sortSelected.split(":")[0],
        direction: sortSelected.split(":")[1],
      });
    }
    if (
      (prevState.orderGroupingStart !== orderGroupingStart ||
        prevState.orderGroupingEnd !== orderGroupingEnd) &&
      ((orderGroupingStart !== undefined && orderGroupingEnd !== undefined) ||
        (orderGroupingStart === undefined && orderGroupingEnd === undefined))
    ) {
      trackTransactionsFilteredByDate({
        start: orderGroupingStart,
        end: orderGroupingEnd,
      });
    }
    if (prevState.page !== page) {
      trackTransactionsPageChanged({
        from: prevState.page,
        to: page,
      });
    }
    if (
      ((prevState.orderGroupingStart !== orderGroupingStart ||
        prevState.orderGroupingEnd !== orderGroupingEnd) &&
        ((orderGroupingStart !== undefined && orderGroupingEnd !== undefined) ||
          (orderGroupingStart === undefined && orderGroupingEnd === undefined))) ||
      prevState.sortSelected !== sortSelected ||
      prevState.page !== page
    ) {
      // actual logic
      if (prevState.page === page) {
        setPage(0);
        getTransactionOrders(0);
      } else {
        getTransactionOrders();
      }
    }

    if (
      prevProps.initiateCustomerRefundLoading &&
      !initiateCustomerRefundLoading &&
      initiateCustomerRefundError === ""
    ) {
      getTransactionOrders();
    }

    if (!prevState.csvDownloadURL && csvDownloadURL) {
      csvLinkRef.current.click();
      setGetCsvDataLoading(false);
      setCsvDownloadURL(undefined);
    }
  });

  function handleEnter(event) {
    if (event.code === "Enter") {
      if (document.activeElement === document.getElementById("search_input") && searchText !== "") {
        setPage(0);
        getTransactionOrders(0);
      } else {
        if (document.getElementById("search_input"))
          document.getElementById("search_input").focus();
      }
    }
  }

  function clearSearch() {
    if (searchText !== "" || !_isMounted.current) return;
    setPage(0);
    getTransactionOrders(0);
  }

  function setSearchTextWrapper(val) {
    setSearchText(val);
    if (val === "") setTimeout(clearSearch, 500);
  }

  function getTransactionOrders(pageProp?: number) {
    if (searchText) {
      trackTransactionsSearched({
        search: searchText,
      });
    }
    firebaseContext.cloudFunctions.getTransactionOrders({
      sortBy: sortSelected.split(":")[0],
      page: pageProp ?? page,
      ascending: sortSelected.split(":")[1] !== "ASCENDING", // the Visly component renders in reverse
      searchText: searchText,
      startAtTimestamp: orderGroupingStart,
      endAtTimestamp: orderGroupingEnd,
    });
  }

  function sortByTaxToggle() {
    const ss = sortSelected;
    let nextss = ss;
    if (ss === "taxTop") nextss = "taxBot";
    else nextss = "taxTop";
    setSortSelected(nextss);
  }

  function sortByCustomerNameToggle() {
    const ss = sortSelected;
    let nextss = ss;
    if (ss === "NAME:ASCENDING") nextss = "NAME:DESCENDING";
    else nextss = "NAME:ASCENDING";
    setSortSelected(nextss);
  }

  function sortByTimeToggle() {
    const ss = sortSelected;
    let nextss = ss;
    if (ss === "DATE:ASCENDING") nextss = "DATE:DESCENDING";
    else nextss = "DATE:ASCENDING";
    setSortSelected(nextss);
  }

  function sortByRevenueToggle() {
    const ss = sortSelected;
    let nextss = ss;
    if (ss === "REVENUE:ASCENDING") nextss = "REVENUE:DESCENDING";
    else nextss = "REVENUE:ASCENDING";
    setSortSelected(nextss);
  }

  function sortByTipToggle() {
    const ss = sortSelected;
    let nextss = ss;
    if (ss === "TIP:ASCENDING") nextss = "TIP:DESCENDING";
    else nextss = "TIP:ASCENDING";
    setSortSelected(nextss);
  }

  function resetOrderGrouping() {
    setOrderGroupingStart(undefined);
    setOrderGroupingEnd(undefined);
  }

  function setOrderGrouping(day) {
    const dayRangeStart =
      orderGroupingStart === undefined ? orderGroupingStart : new Date(orderGroupingStart);
    const dayRangeEnd =
      orderGroupingEnd === undefined ? orderGroupingEnd : new Date(orderGroupingEnd);
    const range = DateUtils.addDayToRange(day, {
      from: dayRangeStart,
      to: dayRangeEnd,
    });
    if (range.from) range.from.setHours(0, 0, 0, 0);
    if (range.to) range.to.setHours(23, 59, 59, 999);
    const fromValue =
      range.from !== undefined && range.from !== null ? range.from.getTime() : undefined;
    const toValue = range.to !== undefined && range.from !== null ? range.to.getTime() : undefined;
    setOrderGroupingStart(fromValue);
    setOrderGroupingEnd(toValue);
  }

  function getCSVFileName(dataType) {
    if (orderGroupingEnd && orderGroupingStart) {
      return `hotplate_${dataType}_${new Intl.DateTimeFormat("default").format(
        orderGroupingStart
      )}-${new Intl.DateTimeFormat("default").format(orderGroupingEnd)}.csv`;
    } else {
      const d = new Date();
      const monthDayYear = new Intl.DateTimeFormat("default", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }).format(d);
      const timeZone = new Intl.DateTimeFormat("default", {
        timeZoneName: "short",
      })
        .format(d)
        .split(" ")[1];
      const time = new Intl.DateTimeFormat("default", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      }).format(d);
      const currTimeString = `${monthDayYear}_${time}${timeZone}`;
      return `hotplate_transactions_all-before-${currTimeString}.csv`;
    }
  }

  async function getCSVDataSubscribers() {
    setGetCsvDataSubscribersLoading(true);
    await fetchRetry(backendUrl + "getSubscriberInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chefId: hostId,
      }),
    })
      .then((response) =>
        response.json().then((json) => {
          if (_isMounted.current) {
            const subscriberInfo = json.subscriberInfo;
            for (let i = 1; i < subscriberInfo.length; i++) {
              subscriberInfo[i][0] = getTimestampDayOfWeekMonthDate(subscriberInfo[i][0]); // DATE
              subscriberInfo[i][5] = getAddressString({
                addressDict: subscriberInfo[i][5],
                displayFullAddress: true,
              }); // FUFILLMENT ADDRESS
            }
            setExportSubscriberData(subscriberInfo);
          }
        })
      )
      .catch((exception) => {
        console.log("export data exception", exception);
      });

    if (_isMounted.current) {
      csvLinkSubscribersRef.current.link.click();
      setGetCsvDataSubscribersLoading(false);
      setExportSubscriberData([]);
    }
  }

  async function getCSVData() {
    setGetCsvDataLoading(true);
    trackTransactionsExported({
      filteredByDate: orderGroupingStart !== undefined && orderGroupingEnd !== undefined,
      // ! the bug mentioned on line 345 about search entry having an unintended impact on export filtering also applies here, if we add state for "searchApplied", that should be the value used here
      filteredBySearch: searchText !== undefined && searchText !== "",
    });
    await firebaseContext.cloudFunctions
      .getTransactionOrdersForExport({
        sortBy: sortSelected.split(":")[0],
        page: -1,
        ascending: sortSelected.split(":")[1] === "ASCENDING",
        // ! because we do not have a seperate piece of state for "searchApplied", a search that has been entered but not confirmed (by hitting the search button) will filter the resulting export. This would be unintended by the user
        searchText: searchText,
        startAtTimestamp: orderGroupingStart,
        endAtTimestamp: orderGroupingEnd,
        getDownloadURL: true,
        promptSaveAs: getCSVFileName("transactions"),
      })
      .then((result) => {
        if (_isMounted.current) {
          setCsvDownloadURL(result.data.url);
        }
      })
      .catch((exception) => {
        console.error("export data exception", exception);
        throw exception;
      });
  }

  const sortType = sortSelected.split(":")[0];
  const sortDirection = sortSelected.split(":")[1];

  const dateRange = {
    from: orderGroupingStart === undefined ? orderGroupingStart : new Date(orderGroupingStart),
    to: orderGroupingEnd === undefined ? orderGroupingEnd : new Date(orderGroupingEnd),
  };

  return (
    <div className="transactions-master-container">
      <span style={{ ...textstyles.h3, color: colors.gray800 }}>Transaction History</span>
      <div className="transactions-searchbar">
        <SearchBar
          type="search"
          inputMode="search"
          placeholder={
            size !== "xsmall" && size !== "small"
              ? "Press   ↵ Enter   to search"
              : "Search orders..."
          }
          value={searchText}
          onChange={(val) => setSearchTextWrapper(val)}
          button={
            <Button
              className="searchbar-button"
              style={searchText && searchText.length > 0 ? {} : { backgroundColor: "#ecf0f0" }}
              text="Search"
              onClick={() => getTransactionOrders()}
              kind={searchText && searchText.length > 0 ? "secondary" : undefined}
              disabled={!searchText || searchText.length === 0 || getTransactionOrdersLoading}
            />
          }
        />
      </div>
      <div className="transactions-filter">
        <FilterDropdown
          Button={
            <FilterDropdown.Button
              key="daypicker"
              style={dateOpen ? { zIndex: "1" } : {}}
              onMouseEnter={() => setDateOpen(true)}
              onMouseLeave={() => setDateOpen(false)}
              open={dateOpen}
              label="Date Filter"
              Dialog={
                <FilterDropdown.Button.Dialog
                  style={
                    dateOpen
                      ? { zIndex: "1", minWidth: "fit-content" }
                      : { display: "none", minWidth: "fit-content" }
                  }
                >
                  <DayPicker
                    modifiers={{
                      start: dateRange.from, // dateRange.from // controls styling
                      end: dateRange.to, // dateRange.to
                    }}
                    selectedDays={[dateRange.from, { from: dateRange.from, to: dateRange.to }]}
                    onDayClick={setOrderGrouping} // needs onClick
                  />
                </FilterDropdown.Button.Dialog>
              }
            />
          }
        >
          {dateRange.from !== undefined && dateRange.to !== undefined && (
            <FilterDropdown.Tag
              onClick={resetOrderGrouping} // resets the range
              date // boolean variant
              tagText={`${dateRange.from.toLocaleDateString().split("/")[0]}/${
                dateRange.from.toLocaleDateString().split("/")[1]
              } - ${dateRange.to.toLocaleDateString().split("/")[0]}/${
                dateRange.to.toLocaleDateString().split("/")[1]
              }`}
            />
          )}
        </FilterDropdown>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {hostId === "dumplingclub" && (
            <div className="export-button">
              <Button
                style={{ width: "170px", marginRight: "8px" }}
                onClick={getCSVDataSubscribers}
                loading={getCsvDataSubscribersLoading}
                loadingAnimation={
                  <ReactLoading type={"bubbles"} color={"#445050"} height={26} width={26} />
                }
                icon={icons.download}
                disabled={getCsvDataSubscribersLoading}
                withIcon={getCsvDataSubscribersLoading ? undefined : "right"}
                kind="quiet"
                text="Export Subscribers"
              />
              <CSVLink
                data={exportSubscriberData}
                filename={"hotplate-subscribers-" + getDateTimeString() + ".csv"}
                ref={csvLinkSubscribersRef}
                target="_blank"
                style={{ textDecoration: "none" }}
              ></CSVLink>
            </div>
          )}
          <div className="export-button">
            <Button
              style={{ width: "105px" }}
              onClick={getCSVData}
              loading={getCsvDataLoading}
              loadingAnimation={
                <ReactLoading type={"bubbles"} color={"#445050"} height={26} width={26} />
              }
              icon={icons.download}
              disabled={getCsvDataLoading}
              withIcon={getCsvDataLoading ? undefined : "right"}
              kind="quiet"
              text="Export"
            />
            {csvDownloadURL && <a ref={csvLinkRef} href={csvDownloadURL}></a>}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          flexGrow: 1,
          justifyContent: "space-between",
        }}
      >
        {getTransactionOrdersLoading ? (
          <div className="table-loading-container">
            <AnimationContainer animation="loader --7" />
          </div>
        ) : getTransactionOrdersError ? (
          <p style={{ color: "red" }}>{getTransactionOrdersError}</p>
        ) : (
          <table className="transaction-table">
            <thead className="header">
              <tr>
                <th className="cell name">
                  <div className="content-container clickable" onClick={sortByCustomerNameToggle}>
                    <span>Name</span>
                    <SortButton
                      key="nameSort"
                      sort={sortType === "NAME" ? sortDirection.toLowerCase() : "false"}
                    />
                  </div>
                </th>
                <th className="cell date">
                  <div className="content-container clickable" onClick={sortByTimeToggle}>
                    <span>Date</span>
                    <SortButton
                      key="dateSort"
                      sort={sortType === "DATE" ? sortDirection.toLowerCase() : "false"}
                    />
                  </div>
                </th>
                <th className="cell order-num">
                  <div className="content-container">
                    <span>Order #</span>
                  </div>
                </th>
                <th className="cell payment-intent">
                  <div className="content-container">
                    <span>ID</span>
                  </div>
                </th>
                <th className="cell subtotal">
                  <div className="content-container">
                    <span>Subtotal</span>
                  </div>
                </th>
                <th className="cell tip">
                  <div className="content-container clickable" onClick={sortByTipToggle}>
                    <span>Tip</span>
                    <SortButton
                      key="tipSort"
                      sort={sortType === "TIP" ? sortDirection.toLowerCase() : "false"}
                    />
                  </div>
                </th>
                <th className="cell revenue">
                  <div className="content-container clickable" onClick={sortByRevenueToggle}>
                    <span>{size === "small" || size === "xsmall" ? "Rev" : "Revenue"}</span>
                    <SortButton
                      key="revenueSort"
                      sort={sortType === "REVENUE" ? sortDirection.toLowerCase() : "false"}
                    />
                  </div>
                </th>
                <th className="cell">
                  <div className="content-container"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {transactionOrders.map((order, index) => (
                <tr
                  className={
                    getTransactionOrderStatus(order) === "partialRefund" ||
                    getTransactionOrderStatus(order) === "fullRefund"
                      ? "row refunded"
                      : "row"
                  }
                  key={index}
                  onClick={() => rowOnClick(order)}
                >
                  <td className="cell name">
                    <div className="content-container" style={{ ...textstyles.tableText }}>
                      {order.firstName} {order.lastName}
                    </div>
                  </td>
                  <td className="cell date">
                    <div className="content-container date" style={{ ...textstyles.tableText }}>
                      <span style={{ ...textstyles.tableText }}>
                        {getTimestampMonth(order.orderPlaced, true) +
                          " " +
                          new Date(order.orderPlaced).getDate()}
                      </span>
                      <span className="date-time hide" style={{ ...textstyles.tableText }}>
                        ,&nbsp;
                      </span>
                      <span className="date-time hide" style={{ ...textstyles.tableText }}>
                        {getTimestampHoursMinutesAMPM(order.orderPlaced)}
                      </span>
                    </div>
                  </td>
                  <td className="cell order-num">
                    <div className="content-container" style={{ ...textstyles.tableMono }}>
                      #{order.orderId}
                    </div>
                  </td>
                  <td className="cell payment-intent">
                    <div className="content-container" style={{ ...textstyles.tableMono }}>
                      {order.paymentIntentId.slice(-6)}
                    </div>
                  </td>
                  <td className="cell subtotal">
                    <div className="content-container" style={{ ...textstyles.tableText }}>
                      ${getSubtotal(order.cartItems)}
                    </div>
                  </td>
                  <td className="cell tip">
                    <div className="content-container" style={{ ...textstyles.tableText }}>
                      ${toFixed(parseFloat(order.tip), 2)}
                    </div>
                  </td>
                  <td className="cell revenue">
                    <div className="content-container" style={{ ...textstyles.tableText }}>
                      ${getOrderRevenue(order)}
                    </div>
                  </td>
                  <td className="cell status-arrow">
                    <div className="content-container" style={{ ...textstyles.tableText }}>
                      <Status
                        className="transaction-status-tag"
                        type={getTransactionOrderStatus(order)}
                      />
                      <img
                        className="icon"
                        src={icons.chevronRight}
                        style={{ height: "12px", width: "16px" }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div className="table-footer">
          <div style={{ display: "flex", gap: "4px" }}>
            {getTransactionOrdersLoading ? (
              <ReactLoading type={"spin"} color={"#445050"} height={14} width={14} />
            ) : (
              totalTransactionOrders !== null && (
                <div>
                  <span
                    style={{
                      ...textstyles.body,
                      fontWeight: 500,
                      color: colors.gray800,
                    }}
                  >
                    {totalTransactionOrders}
                  </span>
                  <span style={{ ...textstyles.body, color: colors.gray600 }}> results</span>
                </div>
              )
            )}
          </div>
          <div className="table-nav-buttons">
            <Button text="Previous" disabled={page === 0} onClick={() => setPage(page - 1)} />
            <Button
              text="Next"
              disabled={
                totalTransactionOrders === null
                  ? !transactionOrders?.length
                  : (page + 1) * 10 >= totalTransactionOrders
              }
              onClick={() => setPage(page + 1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
