// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./OrderEditingLineItem.EditModsButton.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      LJBAs2H8V1: {
        none: {
          text: "",
        },
      },
      UfXe7GZKxM: {
        none: {
          text: "",
        },
      },
      UZAmcDSFC6: {
        none: {
          text: "",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const EditModsButtonContext = createContext(null);

function EditModsButton(_props) {
  const defaults = useContext(EditModsButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="NxjGuw8Mw8"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "NxjGuw8Mw8",
        scope: "2MeEAX7MAz",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"var-mods-container_WEGNY4SGyF"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_2MeEAX7MAz_WEGNY4SGyF"
          }
          key={"WEGNY4SGyF"}
          addSpacing={false}
          element={getStyle("WEGNY4SGyF", "htmlElement")}
        >
          <TextPrimitive
            id={"variations_LJBAs2H8V1"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_2MeEAX7MAz_LJBAs2H8V1"
            }
            key={"LJBAs2H8V1"}
            text={
              exists(props.variations)
                ? props.variations
                : getStyle("LJBAs2H8V1", "text")
            }
            element={getStyle("LJBAs2H8V1", "htmlElement")}
          />
          <TextPrimitive
            id={"mods_UfXe7GZKxM"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_2MeEAX7MAz_UfXe7GZKxM"
            }
            key={"UfXe7GZKxM"}
            text={
              exists(props.mods) ? props.mods : getStyle("UfXe7GZKxM", "text")
            }
            element={getStyle("UfXe7GZKxM", "htmlElement")}
          />
          <TextPrimitive
            id={"notes_UZAmcDSFC6"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_2MeEAX7MAz_UZAmcDSFC6"
            }
            key={"UZAmcDSFC6"}
            text={
              exists(props.notes) ? props.notes : getStyle("UZAmcDSFC6", "text")
            }
            element={getStyle("UZAmcDSFC6", "htmlElement")}
          />
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

EditModsButton.__variants = [];

export default EditModsButton;
