// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./EventBasicInfo.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";
import {
  default as EventName,
  LabeledInputContext as EventNameContext,
  default as OrdersOpen,
  LabeledInputContext as OrdersOpenContext,
  default as OrdersClose,
  LabeledInputContext as OrdersCloseContext,
  default as EventPhoto,
  LabeledInputContext as EventPhotoContext,
  default as EnableOnDemand,
  LabeledInputContext as EnableOnDemandContext,
  default as EventDescription,
  LabeledInputContext as EventDescriptionContext,
} from "../Events/LabeledInput";
import {
  default as AdvancedOptionsButton,
  ButtonContext as AdvancedOptionsButtonContext,
  default as SaveButton,
  ButtonContext as SaveButtonContext,
} from "../Primitives/Button";

const styles = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      GyRrWzRDiM: {
        none: {
          label: "Event Name",
        },
      },
      "8nak386BQP": {
        none: {
          label: "Pre-orders Open",
        },
      },
      FHor1U5GkQ: {
        none: {
          label: "Pre-orders Close",
        },
      },
      HhJmVJTg4g: {
        none: {
          text: "Advanced Options",
          icon: icons.settings,
          rounded: true,
          withIcon: "left",
        },
      },
      "8ozbzkrS7S": {
        none: {
          text: "Save & Continue",
          large: true,
          kind: "primary",
        },
      },
      GjJQLWtiRa: {
        none: {
          label: "Event Image (optional)",
        },
      },
      QkeFHpdT31: {
        none: {
          label: "Enable walk-up ordering",
        },
      },
      "8115RKy4vP": {
        none: {
          label: "Event Description (optional)",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {
          GyRrWzRDiM: {},
          "8nak386BQP": {},
          FHor1U5GkQ: {},
          HhJmVJTg4g: {},
          "8ozbzkrS7S": {},
          GjJQLWtiRa: {},
          QkeFHpdT31: {},
          "8115RKy4vP": {},
        },
      },
      {
        propValue: "small",
        layers: {
          GyRrWzRDiM: {},
          "8nak386BQP": {},
          FHor1U5GkQ: {},
          HhJmVJTg4g: {},
          "8ozbzkrS7S": {},
          GjJQLWtiRa: {},
          QkeFHpdT31: {},
          "8115RKy4vP": {},
        },
      },
      {
        propValue: "med",
        layers: {
          GyRrWzRDiM: {},
          "8nak386BQP": {},
          FHor1U5GkQ: {},
          HhJmVJTg4g: {},
          "8ozbzkrS7S": {},
          GjJQLWtiRa: {},
          QkeFHpdT31: {},
          "8115RKy4vP": {},
        },
      },
      {
        propValue: "large",
        layers: {
          GyRrWzRDiM: {},
          "8nak386BQP": {},
          FHor1U5GkQ: {},
          HhJmVJTg4g: {},
          "8ozbzkrS7S": {},
          GjJQLWtiRa: {},
          QkeFHpdT31: {},
          "8115RKy4vP": {},
        },
      },
      {
        propValue: "xlarge",
        layers: {
          GyRrWzRDiM: {},
          "8nak386BQP": {},
          FHor1U5GkQ: {},
          HhJmVJTg4g: {},
          "8ozbzkrS7S": {},
          GjJQLWtiRa: {},
          QkeFHpdT31: {},
          "8115RKy4vP": {},
        },
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export const EventBasicInfoContext = createContext(null);

function EventBasicInfo(_props) {
  const defaults = useContext(EventBasicInfoContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="XDhDr9NTxz"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "XDhDr9NTxz",
        scope: "9HrPNy3U5e",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Container_6rdXxRi2hn"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_6rdXxRi2hn"
            }
            key={"6rdXxRi2hn"}
            addSpacing={true}
            element={getStyle("6rdXxRi2hn", "htmlElement")}
          >
            {[
              props.EventName === undefined ? (
                <EventName
                  key={"GyRrWzRDiM"}
                  {...getCompositeDefaultProps("GyRrWzRDiM")}
                  id="EventName_GyRrWzRDiM"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_GyRrWzRDiM"
                />
              ) : (
                <EventNameContext.Provider
                  key="GyRrWzRDiM-provider"
                  value={{
                    key: "GyRrWzRDiM",
                    id: "EventName_GyRrWzRDiM",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_GyRrWzRDiM",
                    ...getCompositeDefaultProps("GyRrWzRDiM"),
                  }}
                >
                  {props.EventName}
                </EventNameContext.Provider>
              ),
              props.EventPhoto === undefined ? (
                <EventPhoto
                  key={"GjJQLWtiRa"}
                  {...getCompositeDefaultProps("GjJQLWtiRa")}
                  id="EventPhoto_GjJQLWtiRa"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_GjJQLWtiRa"
                />
              ) : (
                <EventPhotoContext.Provider
                  key="GjJQLWtiRa-provider"
                  value={{
                    key: "GjJQLWtiRa",
                    id: "EventPhoto_GjJQLWtiRa",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_GjJQLWtiRa",
                    ...getCompositeDefaultProps("GjJQLWtiRa"),
                  }}
                >
                  {props.EventPhoto}
                </EventPhotoContext.Provider>
              ),
              props.EventDescription === undefined ? (
                <EventDescription
                  key={"8115RKy4vP"}
                  {...getCompositeDefaultProps("8115RKy4vP")}
                  id="EventDescription_8115RKy4vP"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_8115RKy4vP"
                />
              ) : (
                <EventDescriptionContext.Provider
                  key="8115RKy4vP-provider"
                  value={{
                    key: "8115RKy4vP",
                    id: "EventDescription_8115RKy4vP",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_8115RKy4vP",
                    ...getCompositeDefaultProps("8115RKy4vP"),
                  }}
                >
                  {props.EventDescription}
                </EventDescriptionContext.Provider>
              ),
            ]}
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_AJLB4F8zao"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_AJLB4F8zao"
            }
            key={"AJLB4F8zao"}
          />
          <ContainerPrimitive
            id={"Container_85fiRSJmYp"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_85fiRSJmYp"
            }
            key={"85fiRSJmYp"}
            addSpacing={false}
            element={getStyle("85fiRSJmYp", "htmlElement")}
          >
            {[
              props.OrdersOpen === undefined ? (
                <OrdersOpen
                  key={"8nak386BQP"}
                  {...getCompositeDefaultProps("8nak386BQP")}
                  id="OrdersOpen_8nak386BQP"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_8nak386BQP"
                />
              ) : (
                <OrdersOpenContext.Provider
                  key="8nak386BQP-provider"
                  value={{
                    key: "8nak386BQP",
                    id: "OrdersOpen_8nak386BQP",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_8nak386BQP",
                    ...getCompositeDefaultProps("8nak386BQP"),
                  }}
                >
                  {props.OrdersOpen}
                </OrdersOpenContext.Provider>
              ),
              <SpacerPrimitive
                id={"Spacer_XU7oLftEYt"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_XU7oLftEYt"
                }
                key={"XU7oLftEYt"}
              />,
              props.OrdersClose === undefined ? (
                <OrdersClose
                  key={"FHor1U5GkQ"}
                  {...getCompositeDefaultProps("FHor1U5GkQ")}
                  id="OrdersClose_FHor1U5GkQ"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_FHor1U5GkQ"
                />
              ) : (
                <OrdersCloseContext.Provider
                  key="FHor1U5GkQ-provider"
                  value={{
                    key: "FHor1U5GkQ",
                    id: "OrdersClose_FHor1U5GkQ",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_FHor1U5GkQ",
                    ...getCompositeDefaultProps("FHor1U5GkQ"),
                  }}
                >
                  {props.OrdersClose}
                </OrdersCloseContext.Provider>
              ),
              <SpacerPrimitive
                id={"Spacer_NmHjYijwmF"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_NmHjYijwmF"
                }
                key={"NmHjYijwmF"}
              />,
              props.EnableOnDemand === undefined ? (
                <EnableOnDemand
                  key={"QkeFHpdT31"}
                  {...getCompositeDefaultProps("QkeFHpdT31")}
                  id="EnableOnDemand_QkeFHpdT31"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_QkeFHpdT31"
                />
              ) : (
                <EnableOnDemandContext.Provider
                  key="QkeFHpdT31-provider"
                  value={{
                    key: "QkeFHpdT31",
                    id: "EnableOnDemand_QkeFHpdT31",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_QkeFHpdT31",
                    ...getCompositeDefaultProps("QkeFHpdT31"),
                  }}
                >
                  {props.EnableOnDemand}
                </EnableOnDemandContext.Provider>
              ),
              <ContainerPrimitive
                key={"Sk8F89rGFj"}
                id={"Slot_Sk8F89rGFj"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_Sk8F89rGFj"
                }
                addSpacing={false}
              >
                {props.onDemandType}
              </ContainerPrimitive>,
            ]}
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_pddKvAKA3t"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_pddKvAKA3t"
            }
            key={"pddKvAKA3t"}
          />
          <ContainerPrimitive
            id={"Container_VxAvwe8dHZ"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_VxAvwe8dHZ"
            }
            key={"VxAvwe8dHZ"}
            addSpacing={false}
            element={getStyle("VxAvwe8dHZ", "htmlElement")}
          >
            {[
              props.AdvancedOptionsButton === undefined ? (
                <AdvancedOptionsButton
                  key={"HhJmVJTg4g"}
                  {...getCompositeDefaultProps("HhJmVJTg4g")}
                  id="AdvancedOptionsButton_HhJmVJTg4g"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_HhJmVJTg4g"
                />
              ) : (
                <AdvancedOptionsButtonContext.Provider
                  key="HhJmVJTg4g-provider"
                  value={{
                    key: "HhJmVJTg4g",
                    id: "AdvancedOptionsButton_HhJmVJTg4g",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_HhJmVJTg4g",
                    ...getCompositeDefaultProps("HhJmVJTg4g"),
                  }}
                >
                  {props.AdvancedOptionsButton}
                </AdvancedOptionsButtonContext.Provider>
              ),
              <SpacerPrimitive
                id={"Spacer_HEdJsTk17k"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_HEdJsTk17k"
                }
                key={"HEdJsTk17k"}
              />,
              props.SaveButton === undefined ? (
                <SaveButton
                  key={"8ozbzkrS7S"}
                  {...getCompositeDefaultProps("8ozbzkrS7S")}
                  id="SaveButton_8ozbzkrS7S"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_8ozbzkrS7S"
                />
              ) : (
                <SaveButtonContext.Provider
                  key="8ozbzkrS7S-provider"
                  value={{
                    key: "8ozbzkrS7S",
                    id: "SaveButton_8ozbzkrS7S",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_9HrPNy3U5e_8ozbzkrS7S",
                    ...getCompositeDefaultProps("8ozbzkrS7S"),
                  }}
                >
                  {props.SaveButton}
                </SaveButtonContext.Provider>
              ),
            ]}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

EventBasicInfo.EventName = EventName;
EventBasicInfo.OrdersOpen = OrdersOpen;
EventBasicInfo.OrdersClose = OrdersClose;
EventBasicInfo.AdvancedOptionsButton = AdvancedOptionsButton;
EventBasicInfo.SaveButton = SaveButton;
EventBasicInfo.EventPhoto = EventPhoto;
EventBasicInfo.EnableOnDemand = EnableOnDemand;
EventBasicInfo.EventDescription = EventDescription;
EventBasicInfo.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export default EventBasicInfo;
