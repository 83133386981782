// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./AddEditMenuItem.GlobalDetails.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, ContainerPrimitive } from "../_internal_primitives";
import {
  default as Title,
  LabeledInputContext as TitleContext,
  default as Description,
  LabeledInputContext as DescriptionContext,
  default as Price,
  LabeledInputContext as PriceContext,
  default as ImageWidget,
  LabeledInputContext as ImageWidgetContext,
} from "../Events/LabeledInput";
import {
  default as ModificationsHeader,
  ConfigHeaderContext as ModificationsHeaderContext,
} from "../Events/ConfigHeader";
import {
  default as AddModificationButton,
  ButtonContext as AddModificationButtonContext,
} from "../Primitives/Button";
import {
  default as NotesEnabledSwitch,
  LabeledSwitchContext as NotesEnabledSwitchContext,
} from "../Events/LabeledSwitch";
import { default as Modifications } from "./AddEditMenuItem.GlobalDetails.Modifications";

const styles = [
  {
    type: "default",
    layers: {
      GtMuh2Yw6U: {
        none: {
          htmlElement: "aside",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "editing",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      SbywDZ3TV8: {
        none: {
          label: "Item Name",
        },
      },
      AssPxwGt59: {
        none: {
          label: "Description",
        },
      },
      Y2mVDLJUuw: {
        none: {
          label: "Base Price",
        },
      },
      PF6PDwFXVo: {
        none: {
          label: "Photo",
        },
      },
      GTsFA2rPbG: {
        none: {
          title: "Item Options",
          subtitle: " ",
        },
      },
      KqTyVEy8AE: {
        none: {
          text: "Add Option Group",
        },
      },
      FwHUzNJN61: {
        none: {
          title: "Special instructions",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "editing",
    layers: {
      SbywDZ3TV8: {},
      AssPxwGt59: {},
      Y2mVDLJUuw: {},
      PF6PDwFXVo: {},
      GTsFA2rPbG: {},
      KqTyVEy8AE: {},
      FwHUzNJN61: {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "editing",
  },
];

export const GlobalDetailsContext = createContext(null);

function GlobalDetails(_props) {
  const defaults = useContext(GlobalDetailsContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="DoNrqFMrjU"
      addSpacing={true}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "DoNrqFMrjU",
        scope: "7JFpBsT1d6",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"alldetails-container_8mF3kEgrZz"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_7JFpBsT1d6_8mF3kEgrZz"
            }
            key={"8mF3kEgrZz"}
            addSpacing={true}
            element={getStyle("8mF3kEgrZz", "htmlElement")}
          >
            <ContainerPrimitive
              id={"name-desc-price-container_8LdKiD9pLP"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_7JFpBsT1d6_8LdKiD9pLP"
              }
              key={"8LdKiD9pLP"}
              addSpacing={true}
              element={getStyle("8LdKiD9pLP", "htmlElement")}
            >
              {[
                props.Title === undefined ? (
                  <Title
                    key={"SbywDZ3TV8"}
                    {...getCompositeDefaultProps("SbywDZ3TV8")}
                    id="Title_SbywDZ3TV8"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_7JFpBsT1d6_SbywDZ3TV8"
                  />
                ) : (
                  <TitleContext.Provider
                    key="SbywDZ3TV8-provider"
                    value={{
                      key: "SbywDZ3TV8",
                      id: "Title_SbywDZ3TV8",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_7JFpBsT1d6_SbywDZ3TV8",
                      ...getCompositeDefaultProps("SbywDZ3TV8"),
                    }}
                  >
                    {props.Title}
                  </TitleContext.Provider>
                ),
                props.Description === undefined ? (
                  <Description
                    key={"AssPxwGt59"}
                    {...getCompositeDefaultProps("AssPxwGt59")}
                    id="Description_AssPxwGt59"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_7JFpBsT1d6_AssPxwGt59"
                  />
                ) : (
                  <DescriptionContext.Provider
                    key="AssPxwGt59-provider"
                    value={{
                      key: "AssPxwGt59",
                      id: "Description_AssPxwGt59",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_7JFpBsT1d6_AssPxwGt59",
                      ...getCompositeDefaultProps("AssPxwGt59"),
                    }}
                  >
                    {props.Description}
                  </DescriptionContext.Provider>
                ),
                props.Price === undefined ? (
                  <Price
                    key={"Y2mVDLJUuw"}
                    {...getCompositeDefaultProps("Y2mVDLJUuw")}
                    id="Price_Y2mVDLJUuw"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_7JFpBsT1d6_Y2mVDLJUuw"
                  />
                ) : (
                  <PriceContext.Provider
                    key="Y2mVDLJUuw-provider"
                    value={{
                      key: "Y2mVDLJUuw",
                      id: "Price_Y2mVDLJUuw",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_7JFpBsT1d6_Y2mVDLJUuw",
                      ...getCompositeDefaultProps("Y2mVDLJUuw"),
                    }}
                  >
                    {props.Price}
                  </PriceContext.Provider>
                ),
              ]}
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"photo-container_GtMuh2Yw6U"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_7JFpBsT1d6_GtMuh2Yw6U"
              }
              key={"GtMuh2Yw6U"}
              addSpacing={false}
              element={getStyle("GtMuh2Yw6U", "htmlElement")}
            >
              {props.ImageWidget === undefined ? (
                <ImageWidget
                  key={"PF6PDwFXVo"}
                  {...getCompositeDefaultProps("PF6PDwFXVo")}
                  id="ImageWidget_PF6PDwFXVo"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_7JFpBsT1d6_PF6PDwFXVo"
                />
              ) : (
                <ImageWidgetContext.Provider
                  key="PF6PDwFXVo-provider"
                  value={{
                    key: "PF6PDwFXVo",
                    id: "ImageWidget_PF6PDwFXVo",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_7JFpBsT1d6_PF6PDwFXVo",
                    ...getCompositeDefaultProps("PF6PDwFXVo"),
                  }}
                >
                  {props.ImageWidget}
                </ImageWidgetContext.Provider>
              )}
            </ContainerPrimitive>
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"mod-container_RTh972EE1p"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_7JFpBsT1d6_RTh972EE1p"
            }
            key={"RTh972EE1p"}
            addSpacing={true}
            element={getStyle("RTh972EE1p", "htmlElement")}
          >
            {[
              props.ModificationsHeader === undefined ? (
                <ModificationsHeader
                  key={"GTsFA2rPbG"}
                  {...getCompositeDefaultProps("GTsFA2rPbG")}
                  id="ModificationsHeader_GTsFA2rPbG"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_7JFpBsT1d6_GTsFA2rPbG"
                />
              ) : (
                <ModificationsHeaderContext.Provider
                  key="GTsFA2rPbG-provider"
                  value={{
                    key: "GTsFA2rPbG",
                    id: "ModificationsHeader_GTsFA2rPbG",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_7JFpBsT1d6_GTsFA2rPbG",
                    ...getCompositeDefaultProps("GTsFA2rPbG"),
                  }}
                >
                  {props.ModificationsHeader}
                </ModificationsHeaderContext.Provider>
              ),
              props.Modifications === undefined ? (
                <Modifications key={"CigxUBrfw8"} />
              ) : (
                props.Modifications
              ),
              props.AddModificationButton === undefined ? (
                <AddModificationButton
                  key={"KqTyVEy8AE"}
                  {...getCompositeDefaultProps("KqTyVEy8AE")}
                  id="AddModificationButton_KqTyVEy8AE"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_7JFpBsT1d6_KqTyVEy8AE"
                />
              ) : (
                <AddModificationButtonContext.Provider
                  key="KqTyVEy8AE-provider"
                  value={{
                    key: "KqTyVEy8AE",
                    id: "AddModificationButton_KqTyVEy8AE",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_7JFpBsT1d6_KqTyVEy8AE",
                    ...getCompositeDefaultProps("KqTyVEy8AE"),
                  }}
                >
                  {props.AddModificationButton}
                </AddModificationButtonContext.Provider>
              ),
              props.NotesEnabledSwitch === undefined ? (
                <NotesEnabledSwitch
                  key={"FwHUzNJN61"}
                  {...getCompositeDefaultProps("FwHUzNJN61")}
                  id="NotesEnabledSwitch_FwHUzNJN61"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_7JFpBsT1d6_FwHUzNJN61"
                />
              ) : (
                <NotesEnabledSwitchContext.Provider
                  key="FwHUzNJN61-provider"
                  value={{
                    key: "FwHUzNJN61",
                    id: "NotesEnabledSwitch_FwHUzNJN61",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_7JFpBsT1d6_FwHUzNJN61",
                    ...getCompositeDefaultProps("FwHUzNJN61"),
                  }}
                >
                  {props.NotesEnabledSwitch}
                </NotesEnabledSwitchContext.Provider>
              ),
            ]}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

GlobalDetails.Title = Title;
GlobalDetails.Description = Description;
GlobalDetails.Price = Price;
GlobalDetails.ImageWidget = ImageWidget;
GlobalDetails.ModificationsHeader = ModificationsHeader;
GlobalDetails.AddModificationButton = AddModificationButton;
GlobalDetails.NotesEnabledSwitch = NotesEnabledSwitch;
GlobalDetails.__variants = [
  {
    name: "editing",
    type: "variant",
  },
];

export default GlobalDetails;
