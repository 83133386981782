import React from "react";
import { Box } from "../../../hotplate-common/primitives/Containers";
import { PackingCard } from "./PackingCard";

export const PackingCardPrint = ({ orders, functions }) => {
  return (
    <Box
      css={{
        display: "none",
        width: "100%",
        "@media print": {
          display: "block",
        },
      }}
    >
      {orders.map((order, index) => (
        <PackingCard key={index} functions={functions} order={order} />
      ))}
    </Box>
  );
};
