import React from "react";
import { styled } from "../../stitches.config";
import { Tooltip } from "../../hotplate-common/primitives/Tooltip";

import { AspectRatioImg } from "../../hotplate-common/primitives/AspectRatioImg";
import { Column, Row, Span } from "../../hotplate-common/primitives/Containers";
import { formatToCurrency } from "@hotplate/utils-ts/helperFunctions";
import { CSS } from "@stitches/react";
import { getMenuItemStatusDescription } from "../shop/commonFunctions";
import type { EventStatus, MenuItemStatus, ShopReduxState } from "../shop/types";
import { useSelector } from "react-redux";

const Content = styled("div", {
  display: "flex",
  flexFlow: "column nowrap",
  width: "100%",
  padding: "$sm",
  textAlign: "start",
  justifyContent: "space-between",
  flexGrow: 1,
});

const Title = styled("h3", {
  ff: "$inter",
  textStyle: "text-2",
  fw: "$semi_bold",
  lh: 1.25,
  color: "$gray12",
  minHeight: "fit-content",
  display: "-webkit-box",
  "-webkit-line-clamp": "2",
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  transition: "opacity 0.2s ease-in-out",
  "@desktop_md": {
    textStyle: "text-4",
  },
  "@desktop_lg": {
    "-webkit-line-clamp": "1",
  },
});

const Description = styled("p", {
  fontFamily: "$inter",
  textStyle: "text-1",
  color: "$gray10",
  fontStyle: "italic",
  display: "-webkit-box",
  whiteSpace: "pre-line",
  "-webkit-line-clamp": "2",
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  transition: "opacity 0.2s ease-in-out",
  "@desktop_md": {
    textStyle: "text-2",
  },
  variants: {
    extended: {
      true: {
        "-webkit-line-clamp": "5",
      },
    },
  },
});

const Price = styled("p", {
  fontFamily: "$inter",
  textStyle: "text-1",
  // fontWeight: "$semi_bold",
  color: "$gray12",
  transition: "opacity 0.2s ease-in-out",
  "@desktop_md": {
    textStyle: "text-2",
  },
});

const MenuItemBox = styled("div", {
  backgroundColor: "$white",
  border: "none",
  borderRadius: "$md",
  display: "flex",
  width: "100%",
  maxWidth: "100%",
  minWidth: "0px",
  height: "100%",
  minHeight: "fit-content",
  flexDirection: "column",
  overflow: "hidden",
  cursor: "pointer",
  transition:
    "background-color 0.15s ease-in-out, box-shadow 150ms cubic-bezier(0.4, 0.3, 0.8, 0.6) 0s",
  outline: "1px solid $gray2",
  "&:disabled": {
    cursor: "default",
    pointerEvents: "none",
  },
  '&[data-status="sold_out"]': {
    backgroundColor: "$overlay5",
    outline: "1px solid transparent",
    [`& ${AspectRatioImg}, & ${Title}, & ${Description}`]: {
      opacity: 0.5,
    },
  },
  '&[data-status="available"]': {
    boxShadow: "$elevation5",
    "&:hover": {
      boxShadow:
        "$elevation5,inset 0 0 0 0 rgba(255,255,255,0), inset 0 0 0 4px $colors$white, inset 0 0 0 9999px $colors$gray2",
    },
    "&:active": {
      boxShadow:
        "$elevation5, inset 0 0 0 0 rgba(255,255,255,0), inset 0 0 0 4px $colors$white, inset 0 0 0 9999px $colors$gray2",
    },
    "&:focus-visible": {
      outline: "2px solid $accent9",
      outlineOffset: "4px",
    },
  },
});

export const MenuInventory = ({
  value,
  text,
  css,
  ...props
}: {
  value: number | string;
  text: string;
  css?: CSS;
}) => {
  const sharedTextCss: CSS = {
    ff: "$inter",
    textStyle: "text-1",
    color: "inherit",
    truncateText: true,
    "@desktop_md": {
      textStyle: "text-2",
    },
  };

  return (
    <Row
      css={{
        backgroundColor: "$gray3",
        color: "$gray11",
        h: "$xs_btn",
        borderRadius: "$sm",
        alignItems: "center",
        padx: "$xs",
        ...css,
      }}
      {...props}
    >
      <Span css={{ ...sharedTextCss, fw: "$semi_bold" }}>{value}</Span>
      <Span css={{ ...sharedTextCss, ml: "0.5ch" }}>{text}</Span>
    </Row>
  );
};

/**
 * @component
 * @param {string} src The image of the menu item
 * @param {string} title The title of the menu item
 * @param {string} description - The description of the menu item
 * @param {string} status ["sold_out" | "available" | "no_time_available" | "inactive_event" | "reserved"]
 * @param {(string|number)} inventory The inventory of the menu item, if unlimited is an empty string
 * @param {(string|float)} price The price of the menu item
 * @param {bool} showInventory Shows remaining inventory if item is available
 * @param {function} onClick The function to call when the menu item is clicked
 *
 */
export const MenuItem = ({
  src,
  title,
  description,
  inventoryAvailable,
  inventoryInCustomerCarts,
  showInventory,
  price,
  status,
  onClick,
  eventStatus,
  ...props
}: {
  src: string;
  title: string;
  description: string;
  inventoryAvailable: number;
  inventoryInCustomerCarts: number;
  showInventory: boolean;
  price: string;
  status: MenuItemStatus;
  eventStatus: EventStatus;
  onClick: () => void;
}) => {
  // TODO remove this src reassignment when we get rid of a default menu item img
  let imgSrc: false | string = "";
  src === "https://ucarecdn.com/c7478fd7-b935-44e7-b406-7889e94f9b3d/MenuPlaceholder.png"
    ? (imgSrc = false)
    : (imgSrc = src);

  const { siteSettings } = useSelector((state: ShopReduxState) => state.storefront);

  const tooltipText = getMenuItemStatusDescription(status, eventStatus);
  const itemIsSoldOut = status === "sold_out" || status === "no_time_available";
  const eventIsLive = eventStatus === "live";
  const eventIsComplete = eventStatus === "complete";

  const inner = (
    <MenuItemBox
      onClick={onClick}
      data-status={itemIsSoldOut ? "sold_out" : "available"}
      css={!imgSrc ? { alignSelf: "flex-end", height: "fit-content" } : {}}
      {...props}
    >
      {imgSrc && (
        <AspectRatioImg
          src={imgSrc}
          alt={`Amazing photo of ${title}`}
          ratio={1}
          css={{
            filter: itemIsSoldOut ? "brightness(0.5)" : "brightness(1)",
            borderTopLeftRadius: "$md", //without explicit borderRadius, Safari just doesnt give a border radius at all.
            borderTopRightRadius: "$md",
            transition: "filter 0.15s ease-in-out",
            "& img": {
              borderTopLeftRadius: "$md",
              borderTopRightRadius: "$md",
            },
          }}
        />
      )}
      <Content>
        <Column css={{ gap: "$xs" }}>
          <Title>{title}</Title>
          <Price>{formatToCurrency(price, "en-US", "USD")}</Price>
          <Description extended={!imgSrc}>{description}</Description>
        </Column>
        <Row css={{ marginTop: "$xs", gap: "$xs" }}>
          {/* have to also include the !itemIsSoldOut flag so that we do not have items with no timeslots displaying something like "98 available, Sold Out" */}
          {inventoryAvailable >= 0 &&
            showInventory &&
            !itemIsSoldOut &&
            eventStatus !== "complete" && (
              <MenuInventory
                css={{
                  backgroundColor: `$${eventIsLive ? "danger" : "gray"}4`,
                  color: `$${eventIsLive ? "danger" : "gray"}11`,
                }}
                value={inventoryAvailable}
                text={eventIsLive ? "left" : "available"}
              />
            )}
          {eventIsLive && !itemIsSoldOut && inventoryInCustomerCarts !== 0 && (
            <MenuInventory
              css={{ backgroundColor: "$accent4", color: "$accent11" }}
              value={
                !siteSettings?.isVisibleNumItemsInCartCapped || inventoryInCustomerCarts < 500
                  ? inventoryInCustomerCarts
                  : "500+"
              }
              text="in carts"
            />
          )}
          {itemIsSoldOut && (
            <Row
              css={{
                backgroundColor: "$danger4",
                color: "$danger11",
                h: "$xs_btn",
                borderRadius: "$sm",
                alignItems: "center",
                padx: "$xs",
                ff: "$inter",
                textStyle: "text-1",
                fw: "$semi_bold",
                truncateText: true,
                "@desktop_md": {
                  textStyle: "text-2",
                },
              }}
            >
              Sold Out
            </Row>
          )}
        </Row>
      </Content>
    </MenuItemBox>
  );

  return (
    <>
      {tooltipText ? (
        <Tooltip
          content={tooltipText}
          css={!imgSrc ? { alignSelf: "flex-end", height: "fit-content" } : {}}
        >
          {inner}
        </Tooltip>
      ) : (
        inner
      )}
    </>
  );
};
