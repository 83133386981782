import {
  GET_NEW_SUBSCRIPTION_SESSION_ID_START,
  GET_NEW_SUBSCRIPTION_SESSION_ID_SUCCESS,
  GET_NEW_SUBSCRIPTION_SESSION_ID_FAILURE,
  GET_BILLING_PORTAL_URL_START,
  GET_BILLING_PORTAL_URL_SUCCESS,
  GET_BILLING_PORTAL_URL_FAILURE,
  GET_SUBSCRIPTION_INFORMATION_START,
  GET_SUBSCRIPTION_INFORMATION_SUCCESS,
  GET_SUBSCRIPTION_INFORMATION_FAILURE,
  CONNECT_TO_CHEF_SUBSCRIPTIONS_START,
  CONNECT_TO_CHEF_SUBSCRIPTIONS_SUCCESS,
  CONNECT_TO_CHEF_SUBSCRIPTIONS_FAILURE,
  DISCONNECT_FROM_CHEF_SUBSCRIPTIONS_START,
  DISCONNECT_FROM_CHEF_SUBSCRIPTIONS_SUCCESS,
  DISCONNECT_FROM_CHEF_SUBSCRIPTIONS_FAILURE,
  CONNECT_TO_CUSTOMER_SUBSCRIPTIONS_START,
  CONNECT_TO_CUSTOMER_SUBSCRIPTIONS_SUCCESS,
  CONNECT_TO_CUSTOMER_SUBSCRIPTIONS_FAILURE,
  DISCONNECT_FROM_CUSTOMER_SUBSCRIPTIONS_START,
  DISCONNECT_FROM_CUSTOMER_SUBSCRIPTIONS_SUCCESS,
  DISCONNECT_FROM_CUSTOMER_SUBSCRIPTIONS_FAILURE,
  SAVE_PAYMENT_METHOD_START,
  SAVE_PAYMENT_METHOD_SUCCESS,
  SAVE_PAYMENT_METHOD_FAILURE,
  SET_PAYMENT_METHOD_ERROR,
  CONNECT_TO_CHEF_SITE_SETTINGS_START,
  CONNECT_TO_CHEF_SITE_SETTINGS_SUCCESS,
  CONNECT_TO_CHEF_SITE_SETTINGS_FAILURE,
  DISCONNECT_FROM_CHEF_SITE_SETTINGS_START,
  DISCONNECT_FROM_CHEF_SITE_SETTINGS_SUCCESS,
  DISCONNECT_FROM_CHEF_SITE_SETTINGS_FAILURE,
  UPDATE_CUSTOMER_SUBSCRIPTION_START,
  UPDATE_CUSTOMER_SUBSCRIPTION_SUCCESS,
  UPDATE_CUSTOMER_SUBSCRIPTION_FAILURE,
  UPDATE_CUSTOMER_SUBSCRIPTION_ORDER_START,
  UPDATE_CUSTOMER_SUBSCRIPTION_ORDER_SUCCESS,
  UPDATE_CUSTOMER_SUBSCRIPTION_ORDER_FAILURE,
  CONNECT_TO_SUBSCRIPTION_ORDERS_START,
  CONNECT_TO_SUBSCRIPTION_ORDERS_SUCCESS,
  CONNECT_TO_SUBSCRIPTION_ORDERS_FAILURE,
  ADD_CUSTOMER_SUBSCRIPTION_START,
  ADD_CUSTOMER_SUBSCRIPTION_SUCCESS,
  ADD_CUSTOMER_SUBSCRIPTION_FAILURE,
  EDIT_CUSTOMER_SUBSCRIPTION_START,
  EDIT_CUSTOMER_SUBSCRIPTION_SUCCESS,
  EDIT_CUSTOMER_SUBSCRIPTION_FAILURE,
  EDIT_CUSTOMER_SUBSCRIPTION_ORDER_START,
  EDIT_CUSTOMER_SUBSCRIPTION_ORDER_SUCCESS,
  EDIT_CUSTOMER_SUBSCRIPTION_ORDER_FAILURE,
  CANCEL_CUSTOMER_SUBSCRIPTION_START,
  CANCEL_CUSTOMER_SUBSCRIPTION_SUCCESS,
  CANCEL_CUSTOMER_SUBSCRIPTION_FAILURE,
  EDIT_CUSTOMER_GLOBALS_START,
  EDIT_CUSTOMER_GLOBALS_SUCCESS,
  EDIT_CUSTOMER_GLOBALS_FAILURE,
  CONNECT_TO_MEMBERSHIP_START,
  CONNECT_TO_MEMBERSHIP_SUCCESS,
  CONNECT_TO_MEMBERSHIP_FAILURE,
  DISCONNECT_FROM_MEMBERSHIP_START,
  DISCONNECT_FROM_MEMBERSHIP_SUCCESS,
  DISCONNECT_FROM_MEMBERSHIP_FAILURE,
} from "../actions/types";

const INITIAL_STATE = {
  // Chef Subscriptions to Hotplate
  getBillingPortalUrlResult: "",
  getBillingPortalUrlLoading: false,
  getBillingPortalUrlError: "",

  getNewSubscriptionSessionIdResult: "",
  getNewSubscriptionSessionIdLoading: false,
  getNewSubscriptionSessionIdError: "",

  getSubscriptionInformationResult: {},
  getSubscriptionInformationLoading: false,
  getSubscriptionInformationError: "",

  // Customer Subscriptions to Chef
  customerSubscriptions: {},
  connectToCustomerSubscriptionsLoading: false,
  connectToCustomerSubscriptionsError: "",
  disconnectFromCustomerSubscriptionsLoading: false,
  disconnectFromCustomerSubscriptionsError: "",

  chefSubscriptions: {},
  connectToChefSubscriptionsLoading: false,
  connectToChefSubscriptionsError: "",
  disconnectFromChefSubscriptionsLoading: false,
  disconnectFromChefSubscriptionsError: "",

  savePaymentMethodLoading: false,
  savePaymentMethodError: "",

  siteSettings: {},
  connectToChefSiteSettingsLoading: false,
  connectToChefSiteSettingsError: "",
  disconnectFromChefSiteSettingsLoading: false,
  disconnectFromChefSiteSettingsError: "",

  updateCustomerSubscriptionLoading: false,
  updateCustomerSubscriptionError: "",

  updateCustomerSubscriptionOrderLoading: false,
  updateCustomerSubscriptionOrderError: "",

  subscriptionOrders: {},
  connectToSubscriptionOrdersLoading: false,
  connectToSubscriptionOrdersError: "",

  addCustomerSubscriptionLoading: false,
  addCustomerSubscriptionError: "",

  editCustomerSubscriptionOrderLoading: false,
  editCustomerSubscriptionOrderError: "",

  editCustomerSubscriptionLoading: false,
  editCustomerSubscriptionError: "",

  cancelCustomerSubscriptionLoading: false,
  cancelCustomerSubscriptionError: "",

  editCustomerGlobalsLoading: false,
  editCustomerGlobalsError: "",

  membership: {},
  connectToMembershipLoading: false,
  connectToMembershipError: "",
  disconnectFromMembershipLoading: false,
  disconnectFromMembershipError: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONNECT_TO_MEMBERSHIP_START:
      return {
        ...state,
        connectToMembershipLoading: true,
        connectToMembershipError: "",
      };
    case CONNECT_TO_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        connectToMembershipLoading: false,
        membership: action.payload,
      };
    case CONNECT_TO_MEMBERSHIP_FAILURE:
      return {
        ...state,
        connectToMembershipLoading: false,
        connectToMembershipError: action.payload,
      };
    case DISCONNECT_FROM_MEMBERSHIP_START:
      return {
        ...state,
        disconnectFromMembershipLoading: true,
        disconnectFromMembershipError: "",
      };
    case DISCONNECT_FROM_MEMBERSHIP_SUCCESS:
      return { ...state, disconnectFromMembershipLoading: false };
    case DISCONNECT_FROM_MEMBERSHIP_FAILURE:
      return {
        ...state,
        disconnectFromMembershipLoading: false,
        disconnectFromMembershipError: action.payload,
      };
    case EDIT_CUSTOMER_GLOBALS_START:
      return {
        ...state,
        editCustomerGlobalsLoading: true,
        editCustomerGlobalsError: "",
      };
    case EDIT_CUSTOMER_GLOBALS_SUCCESS:
      return { ...state, editCustomerGlobalsLoading: false };
    case EDIT_CUSTOMER_GLOBALS_FAILURE:
      return {
        ...state,
        editCustomerGlobalsLoading: false,
        editCustomerGlobalsError: action.payload,
      };

    case EDIT_CUSTOMER_SUBSCRIPTION_ORDER_START:
      return {
        ...state,
        editCustomerSubscriptionOrderLoading: true,
        editCustomerSubscriptionOrderError: "",
      };
    case EDIT_CUSTOMER_SUBSCRIPTION_ORDER_SUCCESS:
      return { ...state, editCustomerSubscriptionOrderLoading: false };
    case EDIT_CUSTOMER_SUBSCRIPTION_ORDER_FAILURE:
      return {
        ...state,
        editCustomerSubscriptionOrderLoading: false,
        editCustomerSubscriptionOrderError: action.payload,
      };
    case CANCEL_CUSTOMER_SUBSCRIPTION_START:
      return {
        ...state,
        cancelCustomerSubscriptionLoading: true,
        cancelCustomerSubscriptionError: "",
      };
    case CANCEL_CUSTOMER_SUBSCRIPTION_SUCCESS:
      return { ...state, cancelCustomerSubscriptionLoading: false };
    case CANCEL_CUSTOMER_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        cancelCustomerSubscriptionLoading: false,
        cancelCustomerSubscriptionError: action.payload,
      };
    case EDIT_CUSTOMER_SUBSCRIPTION_START:
      return {
        ...state,
        editCustomerSubscriptionLoading: true,
        editCustomerSubscriptionError: "",
      };
    case EDIT_CUSTOMER_SUBSCRIPTION_SUCCESS:
      return { ...state, editCustomerSubscriptionLoading: false };
    case EDIT_CUSTOMER_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        editCustomerSubscriptionLoading: false,
        editCustomerSubscriptionError: action.payload,
      };
    case ADD_CUSTOMER_SUBSCRIPTION_START:
      return {
        ...state,
        addCustomerSubscriptionLoading: true,
        addCustomerSubscriptionError: "",
      };
    case ADD_CUSTOMER_SUBSCRIPTION_SUCCESS:
      return { ...state, addCustomerSubscriptionLoading: false };
    case ADD_CUSTOMER_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        addCustomerSubscriptionLoading: false,
        addCustomerSubscriptionError: action.payload,
      };

    case UPDATE_CUSTOMER_SUBSCRIPTION_ORDER_START:
      return {
        ...state,
        updateCustomerSubscriptionOrderLoading: true,
        updateCustomerSubscriptionOrderError: "",
      };
    case UPDATE_CUSTOMER_SUBSCRIPTION_ORDER_SUCCESS:
      return { ...state, updateCustomerSubscriptionOrderLoading: false };
    case UPDATE_CUSTOMER_SUBSCRIPTION_ORDER_FAILURE:
      return {
        ...state,
        updateCustomerSubscriptionOrderLoading: false,
        updateCustomerSubscriptionOrderError: action.payload,
      };
    case UPDATE_CUSTOMER_SUBSCRIPTION_START:
      return {
        ...state,
        updateCustomerSubscriptionLoading: true,
        updateCustomerSubscriptionError: "",
      };
    case UPDATE_CUSTOMER_SUBSCRIPTION_SUCCESS:
      return { ...state, updateCustomerSubscriptionLoading: false };
    case UPDATE_CUSTOMER_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        updateCustomerSubscriptionLoading: false,
        updateCustomerSubscriptionError: action.payload,
      };

    case SAVE_PAYMENT_METHOD_START:
      return {
        ...state,
        savePaymentMethodLoading: true,
        savePaymentMethodError: "",
      };
    case SAVE_PAYMENT_METHOD_SUCCESS:
      return { ...state, savePaymentMethodLoading: false };
    case SAVE_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        savePaymentMethodLoading: false,
        savePaymentMethodError: action.payload,
      };
    case SET_PAYMENT_METHOD_ERROR:
      return { ...state, savePaymentMethodError: action.payload };

    case CONNECT_TO_CHEF_SUBSCRIPTIONS_START:
      return {
        ...state,
        connectToChefSubscriptionsLoading: true,
        connectToChefSubscriptionsError: "",
      };
    case CONNECT_TO_CHEF_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        connectToChefSubscriptionsLoading: false,
        chefSubscriptions: action.payload,
      };
    case CONNECT_TO_CHEF_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        connectToChefSubscriptionsLoading: false,
        connectToChefSubscriptionsError: action.payload,
      };
    case DISCONNECT_FROM_CHEF_SUBSCRIPTIONS_START:
      return {
        ...state,
        disconnectToChefSubscriptionsLoading: true,
        disconnectFromChefSubscriptionsError: "",
      };
    case DISCONNECT_FROM_CHEF_SUBSCRIPTIONS_SUCCESS:
      return { ...state, disconnectToChefSubscriptionsLoading: false };
    case DISCONNECT_FROM_CHEF_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        disconnectToChefSubscriptionsLoading: false,
        disconnectFromChefSubscriptionsError: action.payload,
      };

    case CONNECT_TO_CUSTOMER_SUBSCRIPTIONS_START:
      return {
        ...state,
        connectToCustomerSubscriptionsLoading: true,
        connectToCustomerSubscriptionsError: "",
        customerSubscriptions: {},
      };
    case CONNECT_TO_CUSTOMER_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        connectToCustomerSubscriptionsLoading: false,
        customerSubscriptions: action.payload,
      };
    case CONNECT_TO_CUSTOMER_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        connectToCustomerSubscriptionsLoading: false,
        connectToCustomerSubscriptionsError: action.payload,
      };
    case DISCONNECT_FROM_CUSTOMER_SUBSCRIPTIONS_START:
      return {
        ...state,
        disconnectFromCustomerSubscriptionsLoading: true,
        disconnectFromCustomerSubscriptionsError: "",
      };
    case DISCONNECT_FROM_CUSTOMER_SUBSCRIPTIONS_SUCCESS:
      return { ...state, disconnectFromCustomerSubscriptionsLoading: false };
    case DISCONNECT_FROM_CUSTOMER_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        disconnectFromCustomerSubscriptionsLoading: false,
        disconnectFromCustomerSubscriptionsError: action.payload,
      };

    case CONNECT_TO_CHEF_SITE_SETTINGS_START:
      return {
        ...state,
        connectToChefSiteSettingsLoading: true,
        connectToChefSiteSettingsError: "",
        siteSettings: {},
      };
    case CONNECT_TO_CHEF_SITE_SETTINGS_SUCCESS:
      return {
        ...state,
        connectToChefSiteSettingsLoading: false,
        siteSettings: action.payload,
      };
    case CONNECT_TO_CHEF_SITE_SETTINGS_FAILURE:
      return {
        ...state,
        connectToChefSiteSettingsLoading: false,
        connectToChefSiteSettingsError: action.payload,
      };
    case DISCONNECT_FROM_CHEF_SITE_SETTINGS_START:
      return {
        ...state,
        disconnectFromChefSiteSettingsLoading: true,
        disconnectFromChefSiteSettingsError: "",
      };
    case DISCONNECT_FROM_CHEF_SITE_SETTINGS_SUCCESS:
      return { ...state, disconnectFromChefSiteSettingsLoading: false };
    case DISCONNECT_FROM_CHEF_SITE_SETTINGS_FAILURE:
      return {
        ...state,
        disconnectFromChefSiteSettingsLoading: false,
        disconnectFromChefSiteSettingsError: action.payload,
      };

    case GET_SUBSCRIPTION_INFORMATION_START:
      return {
        ...state,
        getSubscriptionInformationLoading: true,
        getSubscriptionInformationError: "",
      };
    case GET_SUBSCRIPTION_INFORMATION_SUCCESS:
      return {
        ...state,
        getSubscriptionInformationLoading: false,
        getSubscriptionInformationResult: action.payload,
      };
    case GET_SUBSCRIPTION_INFORMATION_FAILURE:
      return {
        ...state,
        getSubscriptionInformationLoading: false,
        getSubscriptionInformationError: action.payload,
      };
    case GET_BILLING_PORTAL_URL_START:
      return {
        ...state,
        getBillingPortalUrlLoading: true,
        getBillingPortalUrlError: "",
        getBillingPortalUrlResult: "",
      };
    case GET_BILLING_PORTAL_URL_SUCCESS:
      return {
        ...state,
        getBillingPortalUrlLoading: false,
        getBillingPortalUrlResult: action.payload,
      };
    case GET_BILLING_PORTAL_URL_FAILURE:
      return {
        ...state,
        getBillingPortalUrlLoading: false,
        getBillingPortalUrlError: action.payload,
      };
    case GET_NEW_SUBSCRIPTION_SESSION_ID_START:
      return {
        ...state,
        getNewSubscriptionSessionIdLoading: true,
        getNewSubscriptionSessionIdResult: "",
        getNewSubscriptionSessionIdError: "",
      };
    case GET_NEW_SUBSCRIPTION_SESSION_ID_SUCCESS:
      return {
        ...state,
        getNewSubscriptionSessionIdLoading: false,
        getNewSubscriptionSessionIdResult: action.payload,
      };
    case GET_NEW_SUBSCRIPTION_SESSION_ID_FAILURE:
      return {
        ...state,
        getNewSubscriptionSessionIdLoading: false,
        getNewSubscriptionSessionIdError: action.payload,
      };
    case CONNECT_TO_SUBSCRIPTION_ORDERS_START:
      return {
        ...state,
        connectToSubscriptionOrdersLoading: true,
        connectToSubscriptionOrdersError: "",
      };
    case CONNECT_TO_SUBSCRIPTION_ORDERS_SUCCESS:
      return {
        ...state,
        connectToSubscriptionOrdersLoading: false,
        subscriptionOrders: action.payload,
      };
    case CONNECT_TO_SUBSCRIPTION_ORDERS_FAILURE:
      return {
        ...state,
        connectToSubscriptionOrdersLoading: false,
        connectToSubscriptionOrdersError: action.payload,
      };
    default:
      return state;
  }
};
