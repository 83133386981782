import React from "react";
import { styled } from "../../stitches.config";

const Box = styled("div", { display: "flex" });

const Footer = styled("footer", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  paddingBlock: "$xxl",
  marginBottom: "env(safe-area-inset-bottom, 0px)",
  gap: "$md",
  width: "100%",
  maxWidth: "$maxContentWidth",
  marginInline: "auto",
  paddingInline: "$lg",
  "@tablet": {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
});

const A = styled("a", {
  textDecoration: "none",
  cursor: "pointer",
  fontWeight: "$normal",
  fontSize: "$sm",
  variants: {
    type: {
      policy: {
        fontFamily: "$arboria",
        color: "$gray10",
        transition: "opacity 0.2s ease-in-out",
        opacity: 0.8,
        "&:hover": {
          opacity: 1,
        },
      },
      hotplate: {
        fontFamily: "$avenir",
        fontWeight: "$normal",
        color: "$hotplate9",
      },
    },
  },
});

export const StorefrontFooter = ({ restaurantName }) => {
  return (
    <Footer aria-label="Storefront Footer">
      <Box
        css={{
          alignItems: "baseline",
          color: "$gray10",
          fontFamily: "$avenir",
          fontWeight: "$normal",
          fontSize: "$xs",
        }}
      >
        <A
          href="https://www.hotplate.com/"
          target="_blank"
          rel="noreferrer"
          css={{ marginRight: "$sm" }}
          type="hotplate"
          onClick={() => {
            if (!!window && !!window.analytics) window.analytics.track("Hotplate Footer Clicked");
          }}
          aria-label="Sign up for Hotplate"
        >
          Powered by Hotplate
        </A>
        © {restaurantName} {new Date().getFullYear()}
      </Box>
      <Box css={{ display: "grid", gridAutoFlow: "column", gap: "$md" }}>
        <A type="policy" href="mailto:support@hotplate.com" aria-label="Support Email">
          Support
        </A>
        <A
          type="policy"
          href="https://www.iubenda.com/privacy-policy/45202832"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Privacy Policy"
        >
          Privacy
        </A>
        <A
          type="policy"
          href="https://www.iubenda.com/terms-and-conditions/45202832"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Terms and Conditions"
        >
          Terms
        </A>
      </Box>
    </Footer>
  );
};
