// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./WithdrawPreview.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";
import { default as Button, ButtonContext } from "../Primitives/Button";

const styles = [
  {
    type: "default",
    layers: {
      "7wmPdCswVE": {
        none: {
          text: "Available to Withdraw",
        },
      },
      VzZPxxJkJP: {
        none: {
          text: "254.86",
        },
      },
      Tk14jcUX47: {
        none: {
          text: "254.86",
        },
      },
      Unbdoj1wTp: {
        none: {
          text: "All-Time Income",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xlarge",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      "3PPTbM2B2x": {
        none: {
          text: "Payout",
          icon: icons.chevronRight,
          withIcon: true,
          large: true,
          kind: "good",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xlarge",
        layers: {
          "3PPTbM2B2x": {},
        },
      },
      {
        propValue: "large",
        layers: {
          "3PPTbM2B2x": {},
        },
      },
      {
        propValue: "med",
        layers: {
          "3PPTbM2B2x": {},
        },
      },
      {
        propValue: "xsmall",
        layers: {
          "3PPTbM2B2x": {
            none: {
              withIcon: false,
            },
          },
        },
      },
      {
        propValue: "small",
        layers: {
          "3PPTbM2B2x": {},
        },
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["xlarge", "large", "med", "xsmall", "small"],
  },
];

export const WithdrawPreviewContext = createContext(null);

function WithdrawPreview(_props) {
  const defaults = useContext(WithdrawPreviewContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="QRURXNipyx"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "QRURXNipyx",
        scope: "SfgLzpBKMB",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Container_77obPc5W8t"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_SfgLzpBKMB_77obPc5W8t"
            }
            key={"77obPc5W8t"}
            addSpacing={false}
            element={getStyle("77obPc5W8t", "htmlElement")}
          >
            <ContainerPrimitive
              id={"Container_KybUdN5AgS"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_SfgLzpBKMB_KybUdN5AgS"
              }
              key={"KybUdN5AgS"}
              addSpacing={false}
              element={getStyle("KybUdN5AgS", "htmlElement")}
            >
              <ContainerPrimitive
                id={"hasWithdrawn_DPtdU4uzkC"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_SfgLzpBKMB_DPtdU4uzkC"
                }
                key={"DPtdU4uzkC"}
                addSpacing={false}
                element={getStyle("DPtdU4uzkC", "htmlElement")}
              >
                <ContainerPrimitive
                  id={"Container_U958NF3rfa"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_SfgLzpBKMB_U958NF3rfa"
                  }
                  key={"U958NF3rfa"}
                  addSpacing={false}
                  element={getStyle("U958NF3rfa", "htmlElement")}
                >
                  {
                    <TextPrimitive
                      id={"grossIncome_Tk14jcUX47"}
                      className={
                        "__visly_reset_7X7HTLRqyD __visly_scope_SfgLzpBKMB_Tk14jcUX47"
                      }
                      key={"Tk14jcUX47"}
                      text={
                        exists(props.grossIncome)
                          ? props.grossIncome
                          : getStyle("Tk14jcUX47", "text")
                      }
                      element={getStyle("Tk14jcUX47", "htmlElement")}
                    />
                  }
                </ContainerPrimitive>
                <TextPrimitive
                  id={"Text_Unbdoj1wTp"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_SfgLzpBKMB_Unbdoj1wTp"
                  }
                  key={"Unbdoj1wTp"}
                  text={getStyle("Unbdoj1wTp", "text")}
                  element={getStyle("Unbdoj1wTp", "htmlElement")}
                />
              </ContainerPrimitive>
              <SpacerPrimitive
                id={"Spacer_9tpXmhSePJ"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_SfgLzpBKMB_9tpXmhSePJ"
                }
                key={"9tpXmhSePJ"}
              />
              <ContainerPrimitive
                id={"toWithdraw_NBMA7ooXMJ"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_SfgLzpBKMB_NBMA7ooXMJ"
                }
                key={"NBMA7ooXMJ"}
                addSpacing={false}
                element={getStyle("NBMA7ooXMJ", "htmlElement")}
              >
                <ContainerPrimitive
                  id={"Container_AuQrMvy1Pq"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_SfgLzpBKMB_AuQrMvy1Pq"
                  }
                  key={"AuQrMvy1Pq"}
                  addSpacing={false}
                  element={getStyle("AuQrMvy1Pq", "htmlElement")}
                >
                  {
                    <TextPrimitive
                      id={"toWithdraw_VzZPxxJkJP"}
                      className={
                        "__visly_reset_7X7HTLRqyD __visly_scope_SfgLzpBKMB_VzZPxxJkJP"
                      }
                      key={"VzZPxxJkJP"}
                      text={
                        exists(props.toWithdraw)
                          ? props.toWithdraw
                          : getStyle("VzZPxxJkJP", "text")
                      }
                      element={getStyle("VzZPxxJkJP", "htmlElement")}
                    />
                  }
                </ContainerPrimitive>
                <TextPrimitive
                  id={"Text_7wmPdCswVE"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_SfgLzpBKMB_7wmPdCswVE"
                  }
                  key={"7wmPdCswVE"}
                  text={getStyle("7wmPdCswVE", "text")}
                  element={getStyle("7wmPdCswVE", "htmlElement")}
                />
              </ContainerPrimitive>
              <SpacerPrimitive
                id={"Spacer_Enkp1WePpL"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_SfgLzpBKMB_Enkp1WePpL"
                }
                key={"Enkp1WePpL"}
              />
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"Container_2ymaqNVvMe"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_SfgLzpBKMB_2ymaqNVvMe"
              }
              key={"2ymaqNVvMe"}
              addSpacing={false}
              element={getStyle("2ymaqNVvMe", "htmlElement")}
            >
              {props.Button === undefined ? (
                <Button
                  key={"3PPTbM2B2x"}
                  {...getCompositeDefaultProps("3PPTbM2B2x")}
                  id="Button_3PPTbM2B2x"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_SfgLzpBKMB_3PPTbM2B2x"
                />
              ) : (
                <ButtonContext.Provider
                  key="3PPTbM2B2x-provider"
                  value={{
                    key: "3PPTbM2B2x",
                    id: "Button_3PPTbM2B2x",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_SfgLzpBKMB_3PPTbM2B2x",
                    ...getCompositeDefaultProps("3PPTbM2B2x"),
                  }}
                >
                  {props.Button}
                </ButtonContext.Provider>
              )}
            </ContainerPrimitive>
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_KDjLec5Lzx"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_SfgLzpBKMB_KDjLec5Lzx"
            }
            key={"KDjLec5Lzx"}
          />
        </>
      )}
    </RootPrimitive>
  );
}

WithdrawPreview.Button = Button;
WithdrawPreview.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["xlarge", "large", "med", "xsmall", "small"],
  },
];

export default WithdrawPreview;
