// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./DirectionsButton.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  SpacerPrimitive,
  TextPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import { ButtonRoot } from "../_internal_button";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      Reu2obA4Ks: {
        none: {
          text: "Open Maps",
        },
      },
      PRfsVgW8X9: {
        none: {
          icon: icons.external,
          useMask: true,
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xlarge",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "xsmall",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xlarge",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "xsmall",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["xlarge", "large", "med", "small", "xsmall"],
  },
];

export const DirectionsButtonContext = createContext(null);

function DirectionsButton(_props) {
  const defaults = useContext(DirectionsButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <ButtonRoot
      {...props}
      key="root"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "root",
        scope: "6fksfKrBRg",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <TextPrimitive
            id={"text_Reu2obA4Ks"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_6fksfKrBRg_Reu2obA4Ks"
            }
            key={"Reu2obA4Ks"}
            text={
              exists(props.text) ? props.text : getStyle("Reu2obA4Ks", "text")
            }
            element={getStyle("Reu2obA4Ks", "htmlElement")}
          />
          <SpacerPrimitive
            id={"Spacer_UiG5ShkmZJ"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_6fksfKrBRg_UiG5ShkmZJ"
            }
            key={"UiG5ShkmZJ"}
          />
          <IconPrimitive
            id={"Icon_PRfsVgW8X9"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_6fksfKrBRg_PRfsVgW8X9"
            }
            key={"PRfsVgW8X9"}
            useMask={getStyle("PRfsVgW8X9", "useMask")}
            src={
              exists(props.icon) ? props.icon : getStyle("PRfsVgW8X9", "icon")
            }
          />
        </>
      )}
    </ButtonRoot>
  );
}

DirectionsButton.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["xlarge", "large", "med", "small", "xsmall"],
  },
];

export default DirectionsButton;
