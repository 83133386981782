// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./EventLink.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, TextPrimitive } from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      "9BqvkHQTQc": {
        none: {
          text: "https://link.com",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const EventLinkContext = createContext(null);

function EventLink(_props) {
  const defaults = useContext(EventLinkContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="3SjVAgkc1h"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "3SjVAgkc1h",
        scope: "3ed8unZL2T",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <TextPrimitive
          id={"Text_9BqvkHQTQc"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_3ed8unZL2T_9BqvkHQTQc"
          }
          key={"9BqvkHQTQc"}
          text={
            exists(props.text) ? props.text : getStyle("9BqvkHQTQc", "text")
          }
          element={getStyle("9BqvkHQTQc", "htmlElement")}
        />
      )}
    </RootPrimitive>
  );
}

EventLink.__variants = [];

export default EventLink;
