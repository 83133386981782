// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./InputDropdown.Options.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, ContainerPrimitive } from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const OptionsContext = createContext(null);

function Options(_props) {
  const defaults = useContext(OptionsContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="FjsWyDFcMa"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "FjsWyDFcMa",
        scope: "2YJHUesDC6",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"option-container_V8WHdHC9aH"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_2YJHUesDC6_V8WHdHC9aH"
          }
          key={"V8WHdHC9aH"}
          addSpacing={false}
          element={getStyle("V8WHdHC9aH", "htmlElement")}
        >
          {
            <ContainerPrimitive
              key={"4GjynEHBTK"}
              id={"Slot_4GjynEHBTK"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_2YJHUesDC6_4GjynEHBTK"
              }
              addSpacing={false}
            >
              {props.children}
            </ContainerPrimitive>
          }
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

Options.__variants = [];

export default Options;
