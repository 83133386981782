import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import { H } from "highlight.run";
import { BrowserTracing } from "@sentry/tracing";
import {
  IS_PROD,
  backendUrl,
  IS_STAGING,
  FIREBASE_CONFIG,
} from "./hotplate-storefront/actions/types";
// import CohereSentry from 'cohere-sentry';
import Cohere from "cohere-js";
import { init as initLogging } from "./logger";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";

// setBreakpoints('min-width', ['450px', '850px']);
// console.log(autoprefixer().info()) Will print debug info on PostCSS Autoprefixer to show which which browsers are selected and which properties will be prefixed

// https://formatjs.io/docs/polyfills/intl-datetimeformat/#default-timezone
// We use polyfill.io in index.html. The polyfill (if loaded) uses UTC as
// the default time zone because it has no way of knowing the browser's
// local time. Here we set the polyfill's default to Pacific time to reduce
// confusion, but this shouldn't be a major problem as long as we always
// show timezones everywhere we show times.
if ("__setDefaultTimeZone" in Intl.DateTimeFormat) {
  Intl.DateTimeFormat.__setDefaultTimeZone("America/Los_Angeles");
}

if (!backendUrl.includes("hotplate.ngrok.io")) {
  H.init("w4d761go", {
    environment: IS_PROD ? "production" : "staging",
    enableStrictPrivacy: false,
    backendUrl: "https://highlight.hotplate.com",
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
    },
  });
  Cohere.init("HoT-MPu1PYIs53UGNcU70gJf");
  Sentry.init({
    dsn: "https://0de6fb3f98eb4ae494b99507c8f200ee@o507277.ingest.sentry.io/5598146",
    autoSessionTracking: true,
    attachStacktrace: true,
    normalizeDepth: 5,
    maxBreadcrumbs: 15,
    debug: !IS_PROD && !IS_STAGING,
    environment: IS_PROD ? "production" : IS_STAGING ? "staging" : "development",
    release: import.meta.env.REACT_APP_SENTRY_VERSION,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      // new SentryRRWeb({}),
      // new CohereSentry(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: IS_PROD ? 0.7 : 1,
    beforeSend(event, hint) {
      if (hint.originalException === "Timeout") return null;
      return event;
    },
  });
  // change Segment/analytics writeKey based on prod/staging
  if (IS_PROD) {
    window.analytics._writeKey = "Ro84a5F5l8BOgnnOZhQcU7pokr3xBC8T";
    window.analytics.load("Ro84a5F5l8BOgnnOZhQcU7pokr3xBC8T");
  } else {
    window.analytics._writeKey = "QYQxuT6cFhgE5CCwo3uudHAoGgtmI4Ue";
    window.analytics.load("QYQxuT6cFhgE5CCwo3uudHAoGgtmI4Ue");
  }
}

initLogging();

firebase.initializeApp(FIREBASE_CONFIG);
// if (backendUrl === "https://hotplate.ngrok.io/")
//   firebase.functions().useEmulator("localhost", 5001);

if (!IS_PROD && !IS_STAGING) {
  firebase.database.enableLogging(true);
}

ReactDOM.render(<App />, document.getElementById("hotplate-root"));
