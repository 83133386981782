/* eslint-disable react/prop-types */
import { useEffect, useRef } from "react";
import packageJson from "../../package.json";
window.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

const VersionChecker = () => {
  const metaInteral = useRef(null);

  function checkVersion() {
    fetch(`/meta.json?${new Date().getTime()}`, { cache: "no-cache" })
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = window.appVersion;
        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          window.location.reload(); // reload to get new version, which means new persist reducer local storage keys
        }
      });
  }
  useEffect(() => {
    checkVersion();
    metaInteral.current = setInterval(() => checkVersion(), 10000);
    return () => {
      if (metaInteral.current) {
        clearInterval(metaInteral.current);
      }
    };
  }, []);

  return null;
};

export default VersionChecker;
