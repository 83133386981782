// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./ToggleButton.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import { ToggleButtonRoot } from "../_internal_togglebutton";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      Qv4PXEzSwy: {
        none: {
          text: "orders open",
        },
      },
      B4Zrqx74Tj: {
        none: {
          icon: icons.openPadlock,
          useMask: true,
        },
        disabled: {
          useMask: true,
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "checked",
    layers: {
      Qv4PXEzSwy: {
        none: {
          text: "orders closed",
        },
      },
      B4Zrqx74Tj: {
        none: {
          icon: icons.padlock,
          useMask: true,
        },
        disabled: {
          useMask: true,
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "checked",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "checked",
  },
];

export const ToggleButtonContext = createContext(null);

function ToggleButton(_props) {
  const defaults = useContext(ToggleButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <ToggleButtonRoot
      {...props}
      key="root"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "root",
        scope: "Bw1sSJHFHP",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Container_Av4g4kYgyr"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Bw1sSJHFHP_Av4g4kYgyr"
            }
            key={"Av4g4kYgyr"}
            addSpacing={false}
            element={getStyle("Av4g4kYgyr", "htmlElement")}
          >
            <IconPrimitive
              id={"Icon_B4Zrqx74Tj"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Bw1sSJHFHP_B4Zrqx74Tj"
              }
              key={"B4Zrqx74Tj"}
              useMask={getStyle("B4Zrqx74Tj", "useMask")}
              src={getStyle("B4Zrqx74Tj", "icon")}
            />
            <SpacerPrimitive
              id={"Spacer_PQ4GxRt8UK"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Bw1sSJHFHP_PQ4GxRt8UK"
              }
              key={"PQ4GxRt8UK"}
            />
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_DMEpdfzzJZ"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Bw1sSJHFHP_DMEpdfzzJZ"
            }
            key={"DMEpdfzzJZ"}
          />
          <TextPrimitive
            id={"text_Qv4PXEzSwy"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Bw1sSJHFHP_Qv4PXEzSwy"
            }
            key={"Qv4PXEzSwy"}
            text={
              exists(props.text) ? props.text : getStyle("Qv4PXEzSwy", "text")
            }
            element={getStyle("Qv4PXEzSwy", "htmlElement")}
          />
        </>
      )}
    </ToggleButtonRoot>
  );
}

ToggleButton.__variants = [
  {
    name: "checked",
    type: "variant",
  },
];

export default ToggleButton;
