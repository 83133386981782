import React from "react";
import { styled } from "../../stitches.config";
import { Flex } from "./Containers";
import { Count } from "./Input";
import { inputStyles } from "./Input";

const TextAreaPrimitive = styled("textarea", {
  all: "unset",
  display: "block",
  ...inputStyles,
  borderRadius: "$sm",
  padding: "$xs",
  lineHeight: 1.5,
  width: "100%",
});

const TextAreaCount = styled(Count, {
  position: "absolute",
  right: "10px",
  bottom: "10px",
});

export const TextArea = ({
  rows = 4,
  cols = 50,
  placeholder,
  disabled,
  value,
  onChange,
  maxLength,
  resize = "both", // none, both, horizontal, vertical
  css,
  ...props
}) => {
  return (
    <Flex css={{ position: "relative", ...css }}>
      <TextAreaPrimitive
        css={{ resize: resize }}
        rows={rows}
        cols={cols}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        {...props}
      />
      {maxLength && (
        <TextAreaCount>
          {value ? value.length : 0}/{maxLength}
        </TextAreaCount>
      )}
    </Flex>
  );
};
