// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./EventConfigurationSetting.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as Switch } from "../Primitives/Switch";
import { default as Input } from "../Primitives/Input";

const styles = [
  {
    type: "default",
    layers: {
      TLWeouSdqa: {
        none: {
          text: "Setting Title",
        },
      },
      Y9vAzf3VHK: {
        none: {
          text: "Subtitle text goes here",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      Vs1tThnMwD: {},
      FGSQoUtwo8: {
        none: {
          placeholder: "Unlimited",
        },
      },
    },
  },
];

const variantPropTypes = [];

export const EventConfigurationSettingContext = createContext(null);

function EventConfigurationSetting(_props) {
  const defaults = useContext(EventConfigurationSettingContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="GUe1wgNxVV"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "GUe1wgNxVV",
        scope: "14f33tZPJL",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            key={"XLgwdcMe8Y"}
            id={"Slot_XLgwdcMe8Y"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_14f33tZPJL_XLgwdcMe8Y"
            }
            addSpacing={false}
          >
            {props.settingControl}
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_7BBVbpTS6K"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_14f33tZPJL_7BBVbpTS6K"
            }
            key={"7BBVbpTS6K"}
          />
          <ContainerPrimitive
            id={"Container_A8VCa3ahfk"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_14f33tZPJL_A8VCa3ahfk"
            }
            key={"A8VCa3ahfk"}
            addSpacing={false}
            element={getStyle("A8VCa3ahfk", "htmlElement")}
          >
            <ContainerPrimitive
              id={"Container_SpED3e3F7g"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_14f33tZPJL_SpED3e3F7g"
              }
              key={"SpED3e3F7g"}
              addSpacing={false}
              element={getStyle("SpED3e3F7g", "htmlElement")}
            >
              <TextPrimitive
                id={"title_TLWeouSdqa"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_14f33tZPJL_TLWeouSdqa"
                }
                key={"TLWeouSdqa"}
                text={
                  exists(props.title)
                    ? props.title
                    : getStyle("TLWeouSdqa", "text")
                }
                element={getStyle("TLWeouSdqa", "htmlElement")}
              />
              <SpacerPrimitive
                id={"Spacer_4Pf88pNNY4"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_14f33tZPJL_4Pf88pNNY4"
                }
                key={"4Pf88pNNY4"}
              />
              <ContainerPrimitive
                key={"B8G4Mg76dS"}
                id={"Slot_B8G4Mg76dS"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_14f33tZPJL_B8G4Mg76dS"
                }
                addSpacing={false}
              >
                {props.tooltip}
              </ContainerPrimitive>
            </ContainerPrimitive>
            <TextPrimitive
              id={"subtitle_Y9vAzf3VHK"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_14f33tZPJL_Y9vAzf3VHK"
              }
              key={"Y9vAzf3VHK"}
              text={
                exists(props.subtitle)
                  ? props.subtitle
                  : getStyle("Y9vAzf3VHK", "text")
              }
              element={getStyle("Y9vAzf3VHK", "htmlElement")}
            />
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

EventConfigurationSetting.Switch = Switch;
EventConfigurationSetting.Input = Input;
EventConfigurationSetting.__variants = [];

export default EventConfigurationSetting;
