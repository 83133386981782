/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCustomerAddressUnit as setCustomerAddressUnitUnconnected,
  setCustomerDeliveryInstructions as setCustomerDeliveryInstructionsUnconnected,
  setCustomerAddressDict as setCustomerAddressDictUnconnected,
} from "../hotplate-storefront/actions";
import {
  getAddressString,
  getDeliveryFee,
  getDeliveryAddressError,
} from "@hotplate/utils-ts/helperFunctions";
import AddressInput from "./AddressInput";
import { Input, Button } from "../visly/Primitives";
import NewModal from "./NewModal";
import { styled } from "../stitches.config";
import { Callout } from "./primitives/Callout";

function useConnectedFn(fn, dispatch) {
  return useCallback(
    (...args) => {
      return fn(...args)(dispatch);
    },
    [fn, dispatch]
  );
}

const DangerouslyStyledVislyButton = styled(Button, {});

const Flex = styled("div", {
  display: "flex",
});

const Label = styled("label", {
  fontFamily: "$avenir",
  fontWeight: "$normal",
  color: "$gray11",
  fontSize: "$sm",
  marginTop: "$lg",
  marginBottom: "$sm",
  width: "100%",
});

const InfoItem = styled("p", {
  display: "flex",
  justifyContent: "space-between",
  fontSize: "inherit",
  color: "inherit",
  flex: 1,
});

const P = styled("p", {
  fontWeight: "bold",
});

export default function CustomerDeliveryAddressModal({
  deliverySettings,
  onRequestClose,
  isOpen,
  chefStyles,
  onAddressConfirmed, // DOES NOT EXIST
}) {
  const { customerAddressDict, customerDeliveryInstructions } = useSelector(
    (state) => state.storefront
  );

  const dispatch = useDispatch();
  const setCustomerAddressUnit = useConnectedFn(setCustomerAddressUnitUnconnected, dispatch);
  const setCustomerAddressDict = useConnectedFn(setCustomerAddressDictUnconnected, dispatch);
  const setCustomerDeliveryInstructions = useConnectedFn(
    setCustomerDeliveryInstructionsUnconnected,
    dispatch
  );

  const { primaryColor, primaryColorActive, primaryColorHover, primaryColorLight, textColor } =
    chefStyles;
  const validationStatus =
    !customerAddressDict || customerAddressDict.constructor != Object
      ? undefined
      : getDeliveryAddressError(customerAddressDict, deliverySettings) === ""
      ? "valid"
      : "invalid";
  const showMinimumSubtotal =
    deliverySettings &&
    deliverySettings.constructor == Object &&
    typeof deliverySettings.minimumSubtotal === "string" &&
    deliverySettings.minimumSubtotal !== "";

  const showFreeDelivery =
    deliverySettings &&
    deliverySettings.constructor == Object &&
    typeof deliverySettings.freeDelivery === "string" &&
    deliverySettings.freeDelivery !== "";

  return (
    <NewModal
      compact
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title="Enter Delivery Address"
    >
      <Flex css={{ flexDirection: "column" }}>
        <Label css={{ marginTop: 0 }}>Address</Label>
        <AddressInput
          setAddressDict={setCustomerAddressDict}
          setAddressUnit={(val) => setCustomerAddressUnit(val)}
          addressDict={customerAddressDict}
          initialAddress={getAddressString({
            addressDict: customerAddressDict,
            displayFullAddress: true,
          })}
        />
        <Label>Delivery Instructions</Label>
        <Input
          handleOnKeyDown={
            customerDeliveryInstructions && customerDeliveryInstructions.length !== 0
          }
          value={customerDeliveryInstructions}
          onChange={(val) => setCustomerDeliveryInstructions(val)}
          placeholder="Delivery instructions (optional)"
          label="Delivery instructions"
          style={{ width: "100%" }}
        />
        <Flex css={{ marginBlock: "$lg", width: "100%", justifyContent: "center" }}>
          {validationStatus && (
            <Callout
              type={validationStatus === "valid" ? "success" : "warning"}
              css={{ width: "100%", maxWidth: "400px" }}
            >
              {validationStatus === "invalid" && (
                <InfoItem>
                  {getDeliveryAddressError(customerAddressDict, deliverySettings)}
                </InfoItem>
              )}
              {validationStatus === "valid" && (
                <Flex css={{ flexDirection: "column", width: "100%" }}>
                  <InfoItem css={{ marginBottom: "$sm" }}>
                    {"You're in our delivery radius!"}
                  </InfoItem>
                  <InfoItem>
                    <P>Delivery fee</P>
                    <P>{`$${getDeliveryFee(customerAddressDict, deliverySettings)}`}</P>
                  </InfoItem>
                  {showFreeDelivery && (
                    <InfoItem>
                      <P>Free delivery starts at</P>
                      <P>{`$${deliverySettings.freeDelivery}`}</P>
                    </InfoItem>
                  )}
                  {showMinimumSubtotal && (
                    <InfoItem>
                      <P>Minimum order size</P>
                      <P>{`$${deliverySettings.minimumSubtotal}`}</P>
                    </InfoItem>
                  )}
                </Flex>
              )}
            </Callout>
          )}
        </Flex>

        {/* //TODO when this is replaced with a real primitive, define styles for hover and active inline */}
        <DangerouslyStyledVislyButton
          css={{
            width: "100%",
            backgroundColor: primaryColor,
            color: textColor + " !important",
            border: primaryColorHover,
            "&:hover": {
              backgroundColor: primaryColorHover + " !important",
            },
            "&:active": {
              backgroundColor: primaryColorActive + " !important",
            },
            "&:disabled": {
              backgroundColor: primaryColorLight + " !important",
            },
            "& *": {
              color: textColor + " !important",
            },
          }}
          disabled={getDeliveryAddressError(customerAddressDict, deliverySettings) !== ""}
          text="Confirm Address"
          onClick={() => {
            if (getDeliveryAddressError(customerAddressDict, deliverySettings) === "") {
              onRequestClose();
              if (onAddressConfirmed) {
                onAddressConfirmed(customerAddressDict, customerDeliveryInstructions);
              }
            }
          }}
        />
      </Flex>
    </NewModal>
  );
}
