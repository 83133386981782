/* eslint-disable react/prop-types */
import React from "react";
import { PopupButton as TypeformPopupButton } from "@typeform/embed-react";
import { IS_PROD } from "../../hotplate-storefront/actions/types";
import {
  trackFeatureFeedbackOpened,
  trackFeatureFeedbackSubmitted,
} from "../../hotplate-portal/analytics";
import { styled } from "../../stitches.config";
import { usePortalUser } from "../../auth";

const PopupButton = styled(TypeformPopupButton, {
  display: "inline-flex",
  position: "relative",
  backgroundColor: "$accent9",
  color: "$accent1",
  alignItems: "center",
  justifyContent: "center",
  paddingInline: "1rem",
  height: "$md_btn",
  fontSize: 14,
  fontFamily: "$avenir",
  fontWeight: "$bold",
  borderRadius: ".3rem",
  transition: "background-color 150ms ease-in-out",
  "&:hover": {
    backgroundColor: "$accent10",
  },
  "&:active": {
    backgroundColor: "$accent11",
  },
  "&:focus": {
    outline: "none",
  },
  "&:focus-visible": {
    outline: "2px solid $info9",
    outlineOffset: 2,
  },
});

export const TypeformButton = ({
  formId = "lRbA3rzF",
  testFormId = "PNZ4SE3i",
  featureName,
  text,
  ...props
}) => {
  // formId and testFormId defaults are pointing to in product feedback survey
  const { chefId } = usePortalUser();
  return (
    <>
      <PopupButton
        id={IS_PROD ? formId : testFormId}
        onReady={() =>
          IS_PROD
            ? trackFeatureFeedbackOpened(featureName)
            : console.log("Ben: feature feedback opened!", featureName, chefId, testFormId, formId)
        }
        hidden={{
          feature_name: featureName,
          chef_id: chefId,
        }}
        onSubmit={() =>
          IS_PROD
            ? trackFeatureFeedbackSubmitted(featureName)
            : console.log(
                "Ben: feature feedback submitted!",
                featureName,
                chefId,
                testFormId,
                formId
              )
        }
        {...props}
      >
        {text}
      </PopupButton>
    </>
  );
};
