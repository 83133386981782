import {
  getAddressString,
  getDeliveryAddressError,
  getDeliveryFee,
  getEventStatus,
  getDetailedEventStatus,
  getReadableTimeSlot,
  getSortedEventTimeSlotOptions,
  getSubtotal,
  isEventOnDemandActive,
  isMenuEmpty,
  getTimeUntilGoLive,
} from "@hotplate/utils-ts/helperFunctions";
import { v4 as uuid } from "uuid";
import { MenuItemModal } from "../components/StorefrontMenuItemModal";
import CountdownTimer from "../components/CountdownTimer";
import { useMutation } from "@tanstack/react-query";
import React, { Dispatch, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useFirebaseConnection, usePresenceCounter, useToggle } from "../../hooks";
import { getChefStyles } from "../../hotplate-common/chefStyleFunctions";
import { NotFound } from "../../hotplate-common/NotFound";
import { Box, Column, Grid, H2 } from "../../hotplate-common/primitives/Containers";
import {
  addItemToCartMutation,
  ensureOk,
  expireCartMutation,
  removeItemFromCartMutation,
} from "../../mutations";
import {
  trackCartViewed,
  trackEventViewed,
  trackProductAdded,
  trackProductClicked,
  trackProductRemoved,
} from "../analytics";
import { MenuItem } from "../components/MenuItem";
import { CartModal } from "../components/StorefrontCartModal";
import { DeliveryGrid } from "../components/StorefrontContainers";
import { MenuNav } from "../components/StorefrontTabs";
import {
  setCartId as setCartIdUnconnected,
  setDeliveryFee as setDeliveryFeeUnconnected,
  setToastPopupInfo as setToastPopupInfoUnconnected,
} from "../actions";
import { StorefrontFooter } from "../components/StorefrontFooter";
import { DeliveryAddressButton } from "../components/StorefrontDeliveryAddressButton";
import { TextSwitch } from "../../hotplate-common/primitives/TextSwitch";
import CustomerDeliveryAddressModal from "../../hotplate-common/CustomerDeliveryAddressModal";
import { CartButton } from "../components/StorefrontCartButton";
import { DropInfo } from "./DropInfo";
import { Button } from "../../hotplate-common/primitives/Button";
import { ChevronLeftIcon } from "@radix-ui/react-icons";
import {
  CartEventType,
  CartItemType,
  CartType,
  ChefStyles,
  DetailedEventStatus,
  DropPathParams,
  EventStatus,
  EventType,
  FulfillmentType,
  MenuItemStatus,
  MenuItemType,
  MenuSection,
  ShopReduxState,
} from "./types";
import _ from "lodash";
import { StorefrontHeader } from "../components/StorefrontHeader";
import { Callout } from "../../hotplate-common/primitives/Callout";
import { DropReveal } from "./DropReveal";
import { StorefrontLoader } from "../../hotplate-common/loaders/StorefrontLoaders";

const DummyMenu = () => {
  return (
    <Box css={{ display: "grid", width: "$full", position: "relative" }}>
      <Callout
        type="warning"
        css={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          mb: "$md",
          zIndex: "$fixed",
          boxShadow: "$elevation4",
        }}
        hideIcon={false}
      >
        {`The menu is hidden! You will have to wait until orders open to see it.`}
      </Callout>
      {/* // ? copy-pasted from the menu JSX */}
      <Grid
        css={{
          width: "$full",
          alignContent: "flex-start",
          gap: "$md",
          gridTemplateColumns: "repeat(2, 1fr)",
          "@tablet": {
            gridTemplateColumns: "repeat(3, 1fr)",
          },
          "@desktop_lg": {
            gridTemplateColumns: "repeat(4, 1fr)",
          },
          // added CSS
          filter: "blur(12px)",
          pointerEvents: "none",
        }}
      >
        <MenuItem
          title={"Not a real item!"}
          description={"Thought you were sneaky, didn't ya?"}
          price={"4.20"}
          inventoryAvailable={100}
          inventoryInCustomerCarts={0}
          status={"available"}
          showInventory={true}
          eventStatus={"live"}
          src={
            "https://ucarecdn.com/384575a8-cdfc-4b7c-9a5a-d903807f7cf2/-/crop/2334x2336/534,0/-/resize/750x750/"
          }
          onClick={() => {
            console.log("nice try, buddy!");
          }}
        />
        <MenuItem
          title={"This one is also fake!"}
          description={"Thought you were sneaky, didn't ya?"}
          price={"6.90"}
          inventoryAvailable={100}
          inventoryInCustomerCarts={0}
          status={"available"}
          showInventory={true}
          eventStatus={"live"}
          src={
            "https://ucarecdn.com/1dce0413-4d04-4952-adeb-62408118b934/-/crop/1280x719/0,64/-/resize/800x450/"
          }
          onClick={() => {
            console.log("nice try, buddy!");
          }}
        />
        <MenuItem
          title={"This one is also fake!"}
          description={"Thought you were sneaky, didn't ya?"}
          price={"6.90"}
          inventoryAvailable={100}
          inventoryInCustomerCarts={0}
          status={"available"}
          showInventory={true}
          eventStatus={"live"}
          src={
            "https://ucarecdn.com/0ba7d5b3-2eb4-4fa2-916d-e65f60c1e2c0/-/crop/3504x1971/0,150/-/resize/800x450/"
          }
          onClick={() => {
            console.log("nice try, buddy!");
          }}
        />
        <MenuItem
          title={"Not a real item!"}
          description={"Thought you were sneaky, didn't ya?"}
          price={"4.20"}
          inventoryAvailable={100}
          inventoryInCustomerCarts={0}
          status={"available"}
          showInventory={true}
          eventStatus={"live"}
          src={
            "https://ucarecdn.com/132a056b-15ad-47d5-bb83-b03f7c0f0bc5/-/crop/3504x1972/0,183/-/resize/800x450/"
          }
          onClick={() => {
            console.log("nice try, buddy!");
          }}
        />
        <MenuItem
          title={"Not a real item!"}
          description={"Thought you were sneaky, didn't ya?"}
          price={"4.20"}
          inventoryAvailable={100}
          inventoryInCustomerCarts={0}
          status={"available"}
          showInventory={true}
          eventStatus={"live"}
          src={
            "https://ucarecdn.com/35036e16-6cab-4bc1-a829-ec8d2f507f76/-/crop/3181x1787/22,0/-/resize/800x450/"
          }
          onClick={() => {
            console.log("nice try, buddy!");
          }}
        />
      </Grid>
    </Box>
  );
};

function useConnectedFn(fn: (...args: any[]) => any, dispatch: Dispatch<any>) {
  return useCallback(
    (...args: any[]) => {
      return fn(...args)(dispatch);
    },
    [fn, dispatch]
  );
}

function DropInner({
  chefId,
  event,
  cart,
  cartEvent,
  isCartStuffLoading,
}: {
  chefId: string;
  event: EventType;
  cart: CartType;
  cartEvent: CartEventType;
  isCartStuffLoading: boolean;
}) {
  const {
    siteSettings,
    getSiteSettingsLoading,
    getSiteSettingsError,
    customerAddressDict,
    deliveryFee,
  } = useSelector((state: ShopReduxState) => state.storefront);
  const { cartId } = useSelector((state: ShopReduxState) => state.cart);

  const dispatch = useDispatch();
  const setDeliveryFee = useConnectedFn(setDeliveryFeeUnconnected, dispatch);
  const setCartId = useConnectedFn(setCartIdUnconnected, dispatch);
  const setToastPopupInfo = useConnectedFn(setToastPopupInfoUnconnected, dispatch);

  const eventStatus: EventStatus = getEventStatus(event);
  const detailedEventStatus: DetailedEventStatus = getDetailedEventStatus(event);

  const chefStyles: ChefStyles = getChefStyles(siteSettings);

  const { count: dropPresenceCount } = usePresenceCounter(event.id);

  const [idOfMenuItemBeingViewed, setIdOfMenuItemBeingViewed] = useState<string | null>();

  const [showCartModal, setShowCartModal] = useState(false);
  const [showEnterDeliveryAddressModal, setShowEnterDeliveryAddressModal] = useState(false);
  const [showMenuItemModal, toggleShowMenuItemModal, setShowMenuItemModal] = useToggle(false);

  const addItemToCart = useMutation(addItemToCartMutation);
  const removeItemFromCart = useMutation(removeItemFromCartMutation);
  const expireCart = useMutation(expireCartMutation);

  const [fulfillmentType, setFulfillmentType] = useState<FulfillmentType>("pickup");

  function switchToDelivery() {
    setFulfillmentType("delivery");
    if (
      !showEnterDeliveryAddressModal &&
      getDeliveryAddressError(customerAddressDict, event.deliverySettings) !== "" &&
      eventStatus !== "complete"
    ) {
      setShowEnterDeliveryAddressModal(true);
    }
  }
  if (event) {
    if (fulfillmentType === "pickup" && !event.pickupEnabled) {
      if (event.deliveryEnabled) {
        switchToDelivery();
      } else {
        throw new Error(`Neither pickupEnabled nor deliveryEnabled are true for event ${event.id}`);
      }
    } else if (fulfillmentType === "delivery" && !event.deliveryEnabled) {
      if (event.pickupEnabled) {
        setFulfillmentType("pickup");
      } else {
        throw new Error(`Neither pickupEnabled nor deliveryEnabled are true for event ${event.id}`);
      }
    }
  }

  // Update and persist deliveryFee, which will then be read during Checkout
  if (cart?.fulfillmentType === "delivery" && cartEvent?.deliverySettings) {
    // cartEvent could be stale
    const maybeNewDeliveryFee =
      cartEvent.deliverySettings.freeDelivery &&
      parseFloat(getSubtotal(cart?.cartItems)) >=
        parseFloat(cartEvent.deliverySettings.freeDelivery)
        ? "0.00"
        : getDeliveryFee(customerAddressDict, cartEvent.deliverySettings);

    if (!isCartStuffLoading && deliveryFee !== maybeNewDeliveryFee) {
      setDeliveryFee(maybeNewDeliveryFee);
    }
  }

  const [focusedSectionIndex, setFocusedSectionIndex] = useState<string>("1");

  function handleSectionTabOnClick(sectionIndex: typeof focusedSectionIndex) {
    setFocusedSectionIndex(sectionIndex);
    const section = document.querySelector("#sectionContainer_" + sectionIndex);
    if (section)
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  }

  function getMenuItemDisplayQuantityRemaining(event: EventType, menuItem: MenuItemType) {
    if (
      !event ||
      event.constructor != Object ||
      !event.menu ||
      event.menu.constructor != Object ||
      !menuItem ||
      menuItem.constructor != Object
    )
      return false;
    if (!event.menu.displayQuantityRemaining) return false;
    if (!Number.isInteger(menuItem.inventory)) return false;
    if (menuItem.inventory > 999) return false;
    return true;
  }

  function getNextAvailablePickupSlot(menuItem: MenuItemType) {
    const timeSlotOptions = getSortedEventTimeSlotOptions({
      event: event,
      menuItems: [menuItem, ...(cart?.cartItems || [])],
      includeDelivery: fulfillmentType === "delivery",
      includePickup: fulfillmentType === "pickup",
      customerZip: customerAddressDict ? customerAddressDict.zip : null,
      locationId: null,
      customerCartId: null,
    });
    for (let i = 0; i < timeSlotOptions.length; i++) {
      const timeSlotOption = timeSlotOptions[i];
      const timeSlots = timeSlotOption.timeSlots;
      for (let j = 0; j < timeSlots.length; j++) {
        const timeSlot = timeSlots[j];
        if (!timeSlot.disabled) {
          return timeSlotOption.readableDate + " • " + getReadableTimeSlot(timeSlot);
        }
      }
    }
    return null;
  }

  // MENU ITEM MODAL FUNCTIONS
  function getMenuItemStatus(menuItem: MenuItemType, event: EventType): MenuItemStatus {
    // no_time_available || inactive_event || reserved || sold_out || available
    if (typeof menuItem.inventory === "number" && menuItem.inventory <= 0) {
      if (
        (!menuItem.inventorySold && menuItem.inventoryReserved) || // prevents "Sold Out" from showing if first reservations reserves all the inventory, since inventorySold is not defined until a purchase is made
        (typeof menuItem.inventorySold === "number" &&
          menuItem.inventorySold < menuItem.inventoryReserved)
      ) {
        return "reserved";
      }
      return "sold_out";
    }
    if (getEventStatus(event) !== "live") return "inactive_event"; // - event is not live
    if (fulfillmentType === "delivery" && !customerAddressDict) {
      return "need_delivery_address"; // without an address, getNextAvailablePickupSlot will find nothing
    }
    if (
      fulfillmentType === "delivery" &&
      customerAddressDict &&
      (!event.deliverySettings ||
        !event.deliverySettings.servicableZipcodes ||
        !(customerAddressDict.zip in event.deliverySettings.servicableZipcodes)) // TODO this logic is duplicated with helperFunctions getDeliveryAddressError()
    ) {
      return "delivery_out_of_range";
    }
    if (getNextAvailablePickupSlot(menuItem) === null && !isEventOnDemandActive(event))
      return "no_time_available"; // NOT soldout && no pickup times (& not on-demand)
    return "available";
  }

  function getMenuItemInventoryInCart(menuItem: MenuItemType) {
    let count = 0;
    if (Array.isArray(cart?.cartItems)) {
      for (let i = 0; i < cart.cartItems.length; i++) {
        const cartItem = cart.cartItems[i];
        if (
          cartItem.id === menuItem.id ||
          (typeof cartItem.volumeRestrictionId === "string" &&
            cartItem.volumeRestrictionId !== "" &&
            cartItem.volumeRestrictionId === menuItem.volumeRestrictionId)
        ) {
          count += cartItem.quantity;
        }
      }
    }
    return count;
  }

  function getMenuItemInventoryRemaining(menuItem: MenuItemType): number {
    if (!menuItem) return 0;
    const quantityRemaining = Number.isInteger(menuItem.inventory) ? menuItem.inventory : 9999999;

    // change quantity remaing to be 0 depending on volume restriction id per day

    return quantityRemaining;
  }

  function getMenuItemInventoryInAllCarts(menuItem: MenuItemType): number {
    if (!menuItem) return 0;
    const inventorySold = Number.isInteger(menuItem.inventorySold) ? menuItem.inventorySold : 0;
    const inventoryReserved = Number.isInteger(menuItem.inventoryReserved)
      ? menuItem.inventoryReserved
      : 0;
    // using Math.max() ensures a negative number is never shown to the customer
    return Math.max(inventoryReserved - inventorySold, 0);
  }

  function getMenuItemInventorySold(menuItem: MenuItemType): number {
    if (!menuItem) return 0;
    const inventorySold = Number.isInteger(menuItem.inventorySold) ? menuItem.inventorySold : 0;
    return inventorySold;
  }

  function getMenuItemMaxPerCustomer(menuItem: MenuItemType) {
    let maxPerCustomer = 999999;
    if (Number.isInteger(menuItem.maxPerCustomer))
      maxPerCustomer = Math.min(menuItem.maxPerCustomer, maxPerCustomer);
    if (menuItem.maxPerRangeType === "timeSlot" && Number.isInteger(menuItem.maxPerTimeSlot))
      maxPerCustomer = Math.min(menuItem.maxPerTimeSlot, maxPerCustomer);
    if (menuItem.maxPerRangeType === "date" && Number.isInteger(menuItem.maxPerDay))
      maxPerCustomer = Math.min(menuItem.maxPerDay, maxPerCustomer);
    return maxPerCustomer;
  }

  function cartButtonOnClick() {
    if (
      cart?.fulfillmentType === "delivery" &&
      getDeliveryAddressError(customerAddressDict, event.deliverySettings) !== ""
    ) {
      setShowEnterDeliveryAddressModal(true);
    } else {
      setShowCartModal(true);
      trackCartViewed();
    }
  }

  // TODO remove this after react-router ScrollRestoration is added
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  // If current cart was created for a different chef than the chef whose Storefront is being displayed
  useEffect(() => {
    if (cart && chefId && cart.chefId !== chefId) {
      setCartId(uuid());
    }
  }, [cart, chefId, setCartId]);

  const timerId = useRef<NodeJS.Timer | null>(null);
  const [timeUntilGoLive, setTimeUntilGoLive] = useState(getTimeUntilGoLive(event.goLiveTime));
  useEffect(() => {
    if (detailedEventStatus === "scheduled" || detailedEventStatus === "opening") {
      setTimeUntilGoLive(getTimeUntilGoLive(event.goLiveTime));
      timerId.current = setInterval(() => {
        setTimeUntilGoLive(getTimeUntilGoLive(event.goLiveTime));
        // WARNING regarding getTimeUntilGolive:
        // If this is Math.floor (or Math.round), then it's possible for the React
        // component using this to setState(0) when the real time until live is
        // fractional. In that case, the value from that render for eventStatus
        // will still be "scheduled". One second later, when setState(0) is called
        // again, React will ignore the redundant update and won't rerender. Thus
        // the eventStatus would be stuck "scheduled" until something else causes
        // a rerender.
      }, 1000);
      return () => {
        // WARNING if the latest getTimeUntilGoLive returned 1, but the real time
        // until live is 0, and a render occurs before the next setInterval call
        // can update timeUntilGoLive, then after that render, eventStatus and
        // detailedEventStatus would be "live", but timeUntilGoLive would be stuck
        // at 1 because the setInterval is cleared here. To avoid that, we force
        // timeUntilGoLive to be 0 during cleanup.
        setTimeUntilGoLive(0);
        if (timerId.current) {
          clearInterval(timerId.current);
          timerId.current = null;
        }
      };
    }
  }, [event.goLiveTime, detailedEventStatus]);

  const shouldMenuBeHidden = event.menu.hideUntilLive && timeUntilGoLive > 9; // swap menu in when countdown is at 9 seconds

  return (
    <>
      {event && (
        <Column css={{ w: "$full", pos: "relative" }}>
          {/* <DropReveal presenceCount={dropPresenceCount} timeUntilGoLive={timeUntilGoLive} /> */}
          <StorefrontHeader
            type="drop"
            siteSettings={siteSettings}
            navOnClickTo=".."
            css={{ "& > div": { maxWidth: "none" } }} // removing the max-width from the header content since the menu is full width
          />
          <Column
            css={{
              w: "$full",
              backgroundColor: "$accent2",
            }}
          >
            <Column
              className="drop-info_and_menu"
              css={{
                gap: "$lg",
                jc: "center",
                mb: "auto",
                "@desktop_md": {
                  flexDir: "row",
                  pad: "$lg",
                  flexGrow: 1,
                },
              }}
            >
              {/*  */}
              {/*  */}
              {/* DROP INFO COLUMN */}
              {/*  */}
              {/*  */}
              <Column
                className="drop-info_back-btn"
                css={{
                  ai: "center",
                  jc: "center",
                  "@desktop_md": {
                    h: "calc(100vh - 72px - 48px)", //!bad
                    pos: "sticky",
                    top: 72 + 24, // header height + padding
                  },
                }}
              >
                {/* Back Button */}
                <Box
                  css={{
                    marginRight: "auto",
                    "@desktop_md": { position: "absolute", top: 0, left: 0 },
                  }}
                >
                  <Link
                    to=".."
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <Button
                      variant="plain"
                      color="gray"
                      size="small"
                      css={{ color: "$gray11", "@desktop_md": { padl: 0 } }}
                    >
                      <ChevronLeftIcon />
                      Back
                    </Button>
                  </Link>
                </Box>
                {/* Back Button */}
                {/* Drop Info */}
                <DropInfo
                  event={event}
                  fulfillmentType={fulfillmentType}
                  presenceCount={dropPresenceCount || 0}
                  timeUntilGoLive={timeUntilGoLive}
                />
                {/* Drop Info */}
              </Column>

              {/*  */}
              {/*  */}
              {/* MENU & OTHER CONTENT */}
              {/*  */}
              {/*  */}
              <Column
                css={{
                  jc: "center",
                  minW: 0,
                  "@desktop_md": {
                    flexGrow: 3,
                  },
                }}
              >
                {/*  */}
                {/*  */}
                {/* DELIVERY, COUNTDOWN, NUM PEOPLE, CHAT */}
                {/*  */}
                {/*  */}
                {/* DELIVERY */}
                {event.deliveryEnabled && (
                  <DeliveryGrid>
                    {fulfillmentType === "delivery" && (
                      <DeliveryAddressButton
                        disabled={fulfillmentType !== "delivery"}
                        onClick={() => setShowEnterDeliveryAddressModal(true)}
                        error={
                          getDeliveryAddressError(customerAddressDict, event.deliverySettings) !==
                          ""
                        }
                        text={
                          customerAddressDict
                            ? "Delivery to " +
                              getAddressString({
                                addressDict: customerAddressDict,
                                displayFullAddress: true,
                              })
                            : "No address"
                        }
                      />
                    )}
                    {[event.pickupEnabled, event.deliveryEnabled].filter((eventType) => {
                      return eventType !== undefined && eventType !== null && eventType;
                    }).length > 1 && (
                      <TextSwitch
                        defaultChecked={false}
                        css={{}}
                        checked={fulfillmentType === "delivery"}
                        uncheckedContent="Pickup"
                        checkedContent="Delivery"
                        size={{
                          "@initial": "small",
                          "@desktop_md": "medium",
                        }}
                        width={75}
                        id="cartTypeSwitch"
                        chefStyles={chefStyles}
                        onCheckedChange={() =>
                          fulfillmentType === "pickup"
                            ? switchToDelivery()
                            : setFulfillmentType("pickup")
                        }
                      />
                    )}
                  </DeliveryGrid>
                )}

                <Column
                  css={{
                    pos: "relative",
                    minW: 0,
                    flexGrow: 1,
                  }}
                >
                  {/* Section Tabs */}
                  {!isMenuEmpty(event) && event.menu.sections.length > 1 && (
                    <MenuNav
                      css={{
                        position: "sticky",
                        top: 0,
                        zIndex: "$sticky",
                        height: "$lg_btn",
                        "@desktop_md": {
                          top: 72, // header height
                        },
                      }}
                      value={focusedSectionIndex}
                      onValueChange={(focusedSectionIndex) =>
                        handleSectionTabOnClick(focusedSectionIndex)
                      }
                    >
                      {event.menu.sections
                        .slice(1, event.menu.sections.length)
                        .map((section: MenuSection, sectionIndex: number) => (
                          <MenuNav.Item value={sectionIndex + 1} key={sectionIndex + 1}>
                            {section.title}
                          </MenuNav.Item>
                        ))}
                    </MenuNav>
                  )}
                  <Column
                    css={{
                      pos: "relative",
                      br: "$lg",
                      boxShadow: "$elevation4",
                      flexGrow: 1,
                    }}
                  >
                    <Column
                      css={{ pos: "relative", overflow: "hidden", br: "inherit", flexGrow: 1 }}
                    >
                      {/* EVENT IMAGE BACKGROUND BLURRED */}
                      <Box
                        css={{
                          backgroundColor: "white",
                          position: "absolute",
                          inset: 0,
                          backgroundPosition: "left 50% bottom 50%",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundImage: event.image
                            ? `linear-gradient(90deg, rgba(255,255,255,0.75) 0%, rgba(255,255,255,0.75) 100%), url(${event.image})`
                            : "",
                          filter: "blur(40px) saturate(2)",
                        }}
                      />
                      {/* MENU */}
                      <Column
                        css={{
                          padx: "$xs",
                          pady: "$md",
                          zIndex: "$above",
                          "@desktop_md": {
                            padx: "$md",
                          },
                        }}
                      >
                        {eventStatus === "scheduled" && (
                          <Callout
                            type="info"
                            css={{ mb: "$md" }}
                            hideIcon={false}
                          >{`You will be able to place an order when the countdown hits zero. ${
                            detailedEventStatus === "scheduled"
                              ? "Signup to be reminded so you don't miss it!"
                              : "Chat with everyone else here while you wait!"
                          }`}</Callout>
                        )}
                        {eventStatus === "complete" && (
                          <Callout
                            type="warning"
                            css={{ mb: "$md" }}
                            hideIcon={false}
                          >{`Sales have ended for this drop. Make sure to signup for reminders so you don't miss the next one!`}</Callout>
                        )}
                        {shouldMenuBeHidden && <DummyMenu />}
                        {/* // ! is this isMenuEmpty check needed?? */}
                        {!isMenuEmpty(event) &&
                          !shouldMenuBeHidden &&
                          event.menu.sections.map((section: MenuSection, sectionIndex: number) => (
                            <Box
                              css={{ display: "grid", width: "$full", scrollMarginTop: 88 }}
                              id={`sectionContainer_${sectionIndex}`}
                              key={sectionIndex}
                            >
                              {sectionIndex !== 0 && (
                                <H2
                                  css={{
                                    textStyle: "text-5",
                                    color: "$gray12",
                                    ff: "$inter",
                                    my: "$lg",
                                  }}
                                >
                                  {section.title}
                                </H2>
                              )}
                              <Grid
                                css={{
                                  width: "$full",
                                  alignContent: "flex-start",
                                  gap: "$md",
                                  gridTemplateColumns: "repeat(2, 1fr)",
                                  "@tablet": {
                                    gridTemplateColumns: "repeat(3, 1fr)",
                                  },
                                  "@desktop_lg": {
                                    gridTemplateColumns: "repeat(4, 1fr)",
                                  },
                                }}
                              >
                                {Array.isArray(section.menuItems) &&
                                  section.menuItems.map((menuItemId: string) => {
                                    const menuItem = event.menuItems[menuItemId];
                                    if (!menuItem || menuItem.constructor != Object) return null;
                                    return (
                                      <MenuItem
                                        key={menuItemId}
                                        title={menuItem.title}
                                        description={menuItem.description}
                                        price={menuItem.price}
                                        inventoryAvailable={getMenuItemInventoryRemaining(menuItem)}
                                        inventoryInCustomerCarts={getMenuItemInventoryInAllCarts(
                                          menuItem
                                        )}
                                        status={getMenuItemStatus(menuItem, event)}
                                        showInventory={getMenuItemDisplayQuantityRemaining(
                                          event,
                                          menuItem
                                        )}
                                        eventStatus={eventStatus}
                                        src={menuItem.image}
                                        onClick={() => {
                                          trackProductClicked(menuItem, siteSettings.chefId);
                                          setIdOfMenuItemBeingViewed(menuItemId);
                                          toggleShowMenuItemModal();
                                        }}
                                      />
                                    );
                                  })}
                              </Grid>
                            </Box>
                          ))}
                      </Column>
                    </Column>
                  </Column>
                </Column>
              </Column>
            </Column>
            <StorefrontFooter restaurantName={siteSettings.restaurantName} />
            <CartButton
              cartItems={cart?.cartItems}
              chefStyles={chefStyles}
              onClick={cartButtonOnClick}
              countdownTimer={<CountdownTimer size="small" />}
            />
          </Column>
          {/*  */}
          {/*  */}
          {/* MODALS */}
          {/*  */}
          {/*  */}
          <CartModal
            isOpen={showCartModal}
            onRequestClose={() => setShowCartModal(!showCartModal)}
            cartItems={cart?.cartItems}
            cartType={cart?.fulfillmentType}
            unreserveCartItemsLoading={removeItemFromCart.isLoading}
            cartEvent={cartEvent || event} // There may be no cart yet, but you can still open the modal with fallback
            onRemoveItemFromCart={(cartItem: CartItemType) => {
              if (isCartStuffLoading || removeItemFromCart.isLoading) {
                return;
              }
              trackProductRemoved(cartItem, cartId);
              removeItemFromCart.mutateAsync({ cartId, cartItem }).then(ensureOk);
            }}
            chefStyles={chefStyles}
          />
          <MenuItemModal
            onOpenChange={() => toggleShowMenuItemModal()}
            isOpen={showMenuItemModal}
            key={idOfMenuItemBeingViewed} // when this changes, clobber all state inside MenuItemModal
            chefStyles={chefStyles}
            siteSettings={siteSettings}
            event={event}
            fulfillmentType={fulfillmentType}
            menuItem={
              (idOfMenuItemBeingViewed && event.menuItems?.[idOfMenuItemBeingViewed]) || {
                // placeholder for animation
                title: "",
                price: "",
                description: "",
                image: "",
                inventory: "",
                id: "",
                notesEnabled: "",
                notes: "",
              }
            }
            cart={cart}
            menuItemModalFuncs={{
              getMenuItemInventoryInCart: getMenuItemInventoryInCart,
              getMenuItemInventoryRemaining: getMenuItemInventoryRemaining,
              getMenuItemDisplayQuantityRemaining: getMenuItemDisplayQuantityRemaining,
              getMenuItemMaxPerCustomer: getMenuItemMaxPerCustomer,
              getNextAvailablePickupSlot: getNextAvailablePickupSlot,
              getMenuItemStatus: getMenuItemStatus,
              getMenuItemInventoryInAllCarts: getMenuItemInventoryInAllCarts,
              getMenuItemInventorySold: getMenuItemInventorySold,
            }}
            onClearCart={async () => {
              ensureOk(
                await expireCart.mutateAsync({
                  cartId,
                  creationId: cart.creationId,
                })
              );
            }}
            onAddToCart={async (cartItem) => {
              if (isCartStuffLoading || addItemToCart.isLoading) {
                return;
              }
              const { response, json } = await addItemToCart.mutateAsync({
                cartId,
                chefId,
                eventId: event.id,
                menuItemId: cartItem.id,
                fulfillmentType,
                quantity: cartItem.quantity,
                notes: cartItem.notes,
                optionCategories: cartItem.optionCategories,
              });
              if (json.error === "Out of stock.") {
                setToastPopupInfo({
                  text: "Someone else beat you to the last item just now.",
                  type: "error",
                });
              } else if (!json.quantityAdded) {
                setToastPopupInfo({
                  text: "We couldn't add this item to your cart.",
                  type: "error",
                });
              } else if (json.quantityAdded < cartItem.quantity) {
                setToastPopupInfo({
                  text: `We could only add ${json.quantityAdded} of ${cartItem.quantity} of this item to your cart.`,
                  type: "warning",
                });
              } else if (!response.ok) {
                throw new Error("Response not ok.");
              } else {
                setShowMenuItemModal(false);
              }
              trackProductAdded(cartItem, siteSettings.chefId);
            }}
            isAddToCartLoading={isCartStuffLoading || addItemToCart.isLoading}
          />
          <CustomerDeliveryAddressModal
            isOpen={showEnterDeliveryAddressModal}
            onRequestClose={() => setShowEnterDeliveryAddressModal(false)}
            deliverySettings={event.deliverySettings}
            chefStyles={chefStyles}
            onAddressConfirmed={null}
          />
        </Column>
      )}
    </>
  );
}

export function Drop({
  cart,
  cartEvent,
  isCartStuffLoading,
}: {
  cart: CartType;
  cartEvent: CartEventType;
  isCartStuffLoading: boolean;
}) {
  const pathParams = useParams();
  const [searchParams] = useSearchParams();

  const selectedEventId = pathParams.eventId;
  const chefId =
    searchParams.get("chef") || // For Reflect.run tests
    pathParams.chefId?.toLowerCase();

  const selectedEventConnection = useFirebaseConnection(
    {
      ref: `/hosts/${chefId}/events/${selectedEventId}`,
    },
    {
      isEnabled: !!(chefId && selectedEventId),
      throttleArgs: {
        wait: 1000,
        options: {
          leading: true,
          trailing: true,
        },
      },
    }
  );

  useEffect(() => {
    if (pathParams.eventId) {
      trackEventViewed(pathParams.eventId, chefId);
    }
  }, [pathParams.eventId, chefId]);

  return chefId && selectedEventId && selectedEventConnection.isLoading ? (
    <StorefrontLoader />
  ) : chefId && selectedEventId && selectedEventConnection.data ? (
    <DropInner
      chefId={chefId}
      event={selectedEventConnection.data}
      cart={cart}
      cartEvent={cartEvent}
      isCartStuffLoading={isCartStuffLoading}
    />
  ) : (
    <NotFound />
  );
}
