import React from "react";
import { styled } from "../stitches.config";

const FieldIconPrimitive = styled("div", {
  backgroundColor: "$cyan3",
  color: "$cyan10",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  variants: {
    size: {
      small: {
        width: 20,
        height: 20,
        borderRadius: "$sm",
        "& svg": {
          width: 12,
          height: 12,
        },
      },
      medium: {
        width: 28,
        height: 28,
        borderRadius: "$md",
        "& svg": {
          width: 15,
          height: 15,
        },
      },
      large: {
        width: 36,
        height: 36,
        borderRadius: "$lg",
        "& svg": {
          width: 18,
          height: 18,
        },
      },
    },
  },
  defaultVariants: {
    size: "medium",
  },
});

export const FieldIcon = ({ icon, size, chefStyles, ...props }) => {
  return (
    <FieldIconPrimitive
      size={size}
      css={
        chefStyles && {
          backgroundColor: chefStyles.primaryColorLight,
          color: chefStyles.primaryColorDark,
        }
      }
      {...props}
    >
      {icon}
    </FieldIconPrimitive>
  );
};
