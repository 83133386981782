// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Select.Option.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import { SelectOptionRoot } from "../_internal_select";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      DJFgdVooE8: {
        none: {
          text: "List item",
        },
      },
      N9pNVC4oP3: {
        none: {
          icon: icons.plus,
          useMask: true,
        },
      },
      Ujtf1kwQ2Q: {
        none: {
          text: "2206 Delancey Pl, 19103",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {
      DJFgdVooE8: {
        none: {
          text: "Selected item",
        },
      },
      N9pNVC4oP3: {
        none: {
          icon: icons.checkmark,
          useMask: true,
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "multiLine",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
  {
    type: "boolean",
    propName: "multiLine",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "selected",
  },
  {
    type: "boolean",
    propName: "multiLine",
  },
];

export const OptionContext = createContext(null);

function Option(_props) {
  const defaults = useContext(OptionContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <SelectOptionRoot
      {...props}
      key="select-option-root"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "select-option-root",
        scope: "YCaC21ACBT",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"label-container_9CLv8VyLcU"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_YCaC21ACBT_9CLv8VyLcU"
            }
            key={"9CLv8VyLcU"}
            addSpacing={false}
            element={getStyle("9CLv8VyLcU", "htmlElement")}
          >
            <TextPrimitive
              id={"label_DJFgdVooE8"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_YCaC21ACBT_DJFgdVooE8"
              }
              key={"DJFgdVooE8"}
              text={
                exists(props.label)
                  ? props.label
                  : getStyle("DJFgdVooE8", "text")
              }
              element={getStyle("DJFgdVooE8", "htmlElement")}
            />
            <SpacerPrimitive
              id={"Spacer_WMz5uAifqa"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_YCaC21ACBT_WMz5uAifqa"
              }
              key={"WMz5uAifqa"}
            />
            <TextPrimitive
              id={"subtitle_Ujtf1kwQ2Q"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_YCaC21ACBT_Ujtf1kwQ2Q"
              }
              key={"Ujtf1kwQ2Q"}
              text={
                exists(props.subtitle)
                  ? props.subtitle
                  : getStyle("Ujtf1kwQ2Q", "text")
              }
              element={getStyle("Ujtf1kwQ2Q", "htmlElement")}
            />
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_719E6YjLqx"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_YCaC21ACBT_719E6YjLqx"
            }
            key={"719E6YjLqx"}
          />
          <IconPrimitive
            id={"Icon_N9pNVC4oP3"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_YCaC21ACBT_N9pNVC4oP3"
            }
            key={"N9pNVC4oP3"}
            useMask={getStyle("N9pNVC4oP3", "useMask")}
            src={
              exists(props.icon) ? props.icon : getStyle("N9pNVC4oP3", "icon")
            }
          />
        </>
      )}
    </SelectOptionRoot>
  );
}

Option.__variants = [
  {
    name: "selected",
    type: "variant",
  },
  {
    name: "multiLine",
    type: "variant",
  },
];

export default Option;
