// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./AddEditMenuItem.EventDetails.ExpandGlobalDetails.HeaderButton.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      HZ1FG4zBVN: {
        none: {
          icon: icons.chevronRight,
          useMask: true,
        },
      },
      TvwSc8MVPy: {
        none: {
          text: "Edit Item Details",
        },
      },
      "4JU9m15oey": {
        none: {
          text: "Photo, price, title, modifications, etc.",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "expanded",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "expanded",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "expanded",
  },
];

export const HeaderButtonContext = createContext(null);

function HeaderButton(_props) {
  const defaults = useContext(HeaderButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="RGmut9Zzyi"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "RGmut9Zzyi",
        scope: "FwZm8LB6Uj",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Container_CCQjC21okS"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_FwZm8LB6Uj_CCQjC21okS"
            }
            key={"CCQjC21okS"}
            addSpacing={false}
            element={getStyle("CCQjC21okS", "htmlElement")}
          >
            <TextPrimitive
              id={"Text_TvwSc8MVPy"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_FwZm8LB6Uj_TvwSc8MVPy"
              }
              key={"TvwSc8MVPy"}
              text={getStyle("TvwSc8MVPy", "text")}
              element={getStyle("TvwSc8MVPy", "htmlElement")}
            />
            <SpacerPrimitive
              id={"Spacer_AAvEi774q9"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_FwZm8LB6Uj_AAvEi774q9"
              }
              key={"AAvEi774q9"}
            />
            <TextPrimitive
              id={"Text_4JU9m15oey"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_FwZm8LB6Uj_4JU9m15oey"
              }
              key={"4JU9m15oey"}
              text={getStyle("4JU9m15oey", "text")}
              element={getStyle("4JU9m15oey", "htmlElement")}
            />
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_BLCjggoJyh"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_FwZm8LB6Uj_BLCjggoJyh"
            }
            key={"BLCjggoJyh"}
          />
          <IconPrimitive
            id={"Icon_HZ1FG4zBVN"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_FwZm8LB6Uj_HZ1FG4zBVN"
            }
            key={"HZ1FG4zBVN"}
            useMask={getStyle("HZ1FG4zBVN", "useMask")}
            src={getStyle("HZ1FG4zBVN", "icon")}
          />
        </>
      )}
    </RootPrimitive>
  );
}

HeaderButton.__variants = [
  {
    name: "expanded",
    type: "variant",
  },
];

export default HeaderButton;
