// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Instructions.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import {
  default as Close,
  IconButtonContext as CloseContext,
} from "../Primitives/IconButton";

const styles = [
  {
    type: "default",
    layers: {
      XaiDQrRacP: {
        none: {
          htmlElement: "header",
        },
      },
      RTmshi5SY7: {
        none: {
          text: "Directions",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      Jj3MNKqiEQ: {},
    },
  },
];

const variantPropTypes = [];

export const InstructionsContext = createContext(null);

function Instructions(_props) {
  const defaults = useContext(InstructionsContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="7yDFZenNb7"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "7yDFZenNb7",
        scope: "4BAoGvTYJT",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_Ffd9CG6BC7"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_4BAoGvTYJT_Ffd9CG6BC7"
          }
          key={"Ffd9CG6BC7"}
          addSpacing={false}
          element={getStyle("Ffd9CG6BC7", "htmlElement")}
        >
          <ContainerPrimitive
            id={"header_XaiDQrRacP"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_4BAoGvTYJT_XaiDQrRacP"
            }
            key={"XaiDQrRacP"}
            addSpacing={false}
            element={getStyle("XaiDQrRacP", "htmlElement")}
          >
            {
              <ContainerPrimitive
                id={"closeButoon_VfPrhSxuaa"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_4BAoGvTYJT_VfPrhSxuaa"
                }
                key={"VfPrhSxuaa"}
                addSpacing={false}
                element={getStyle("VfPrhSxuaa", "htmlElement")}
              >
                {props.Close === undefined ? (
                  <Close
                    key={"Jj3MNKqiEQ"}
                    {...getCompositeDefaultProps("Jj3MNKqiEQ")}
                    id="Close_Jj3MNKqiEQ"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_4BAoGvTYJT_Jj3MNKqiEQ"
                  />
                ) : (
                  <CloseContext.Provider
                    key="Jj3MNKqiEQ-provider"
                    value={{
                      key: "Jj3MNKqiEQ",
                      id: "Close_Jj3MNKqiEQ",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_4BAoGvTYJT_Jj3MNKqiEQ",
                      ...getCompositeDefaultProps("Jj3MNKqiEQ"),
                    }}
                  >
                    {props.Close}
                  </CloseContext.Provider>
                )}
              </ContainerPrimitive>
            }
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"content_4dDhTy85N9"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_4BAoGvTYJT_4dDhTy85N9"
            }
            key={"4dDhTy85N9"}
            addSpacing={false}
            element={getStyle("4dDhTy85N9", "htmlElement")}
          >
            {
              <TextPrimitive
                id={"title_RTmshi5SY7"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_4BAoGvTYJT_RTmshi5SY7"
                }
                key={"RTmshi5SY7"}
                text={
                  exists(props.title)
                    ? props.title
                    : getStyle("RTmshi5SY7", "text")
                }
                element={getStyle("RTmshi5SY7", "htmlElement")}
              />
            }
          </ContainerPrimitive>
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

Instructions.Close = Close;
Instructions.__variants = [];

export default Instructions;
