// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./List.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { ListboxPrimitive } from "../_internal_listbox";

const styles = [
  {
    type: "default",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const ListContext = createContext(null);

function List(_props) {
  const defaults = useContext(ListContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <ListboxPrimitive
      {...props}
      key="listbox-root"
      internal={{
        styles,
        projectId: "7X7HTLRqyD",
        layerId: "listbox-root",
        scope: "UhNf4CKKvw",
        activeVariants,
      }}
      addSpacing={true}
      vislyChildren={(getStyle) => props.children}
    >
      {props.children}
    </ListboxPrimitive>
  );
}

List.__variants = [];

export default List;
