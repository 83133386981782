/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import ReactLoading from "react-loading";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Spacer from "../../../../hotplate-common/Spacer";
import _ from "lodash";
import { showItemWaitlistPortalFunctionality } from "../../../../hotplate-common/HelperFunctions";
import {
  MenuConfigItemCard,
  ConfigMenu,
  ConfigSection,
  EditMenuSettings,
  DraggableSection,
} from "../../../../visly/Events";

import ToolTip from "../../../../hotplate-common/ToolTip";
import { Input, Badge } from "../../../../visly/Primitives";

import { useBreakpoint } from "../../../../visly";

import { mergeDiff, usePrevious } from "../../../../hooks";
import { setToastPopupInfo } from "../../../../hotplate-storefront/actions";

import AddEditMenuItemModal from "./AddEditMenuItemModal";
import EditMenuSectionModal from "./EditMenuSectionModal";
import AddMenuItemsModal from "./AddMenuItemsModal";

import "./css/index.css";
import { shouldRetry, trpc } from "../../../../trpc";
import {
  trackHideMenuToggled,
  trackItemAddedToEvent,
  trackMaxCustomersPerPickupTimeUpdated,
  trackMenuSectionAdded,
  trackShowInventoryToggled,
} from "../analytics";
import { getEventStatus } from "@hotplate/utils-ts/helperFunctions";
import { usePortalUser } from "../../../../auth";

const INIT_SECTIONS_LIST = [
  {
    title: "",
  },
];

// These are event-specific fields of menu items, that are NOT to be stored
// to the host menu items catalog.
const VOLUME_RESTRICTION_DEFAULTS = {
  inventory: "", //
  inventoryReserved: 0,
  inventorySold: 0,
  maxPerCustomer: "", //
  maxPerRangeType: "timeSlot", // timeSlot, date
  maxPerTimeSlot: "",
  maxPerDay: "",
};

function getItemCountString(section) {
  if (!section || section.constructor != Object) return "";
  if (!Array.isArray(section.menuItems)) return "0 items";
  return section.menuItems.length + " item" + (section.menuItems.length === 1 ? "" : "s");
}

const DraggableSectionMaster = ({ id, index, keyVal, section }) => {
  if (section === null || section === undefined) return null;
  return (
    <Draggable draggableId={id} index={index} key={keyVal}>
      {(provided) => (
        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <DraggableSection
            key={keyVal}
            title={section.title === "" ? "Untitled Section" : section.title}
            itemCountString={getItemCountString(section)}
          />
          <Spacer size="Medium" />
          <Spacer size="Small" />
        </div>
      )}
    </Draggable>
  );
};

const DraggableMenuItem = ({
  id,
  index,
  keyVal,
  menuItem,
  itemWaitlistCount,
  menuItemSoldCount,
  showLiveCounts,
  onMouseUp,
}) => {
  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);
  return (
    <Draggable draggableId={id} index={index} key={keyVal}>
      {(provided) => (
        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <MenuConfigItemCard
            className="menuConfigCard"
            type="menuConfig"
            onMouseUp={onMouseUp}
            key={keyVal}
            size={"xsmall"}
            image={<img src={menuItem.image} style={{ maxWidth: "100%", objectFit: "contain" }} />}
            title={menuItem.title}
            badges={
              showLiveCounts ? (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <Badge
                    text={`${
                      Number.isInteger(menuItem.inventory) ? menuItem.inventory : "∞"
                    } available`}
                    light="black"
                    style={{ display: "inline-block" }}
                  />
                  {Number.isInteger(menuItem.inventoryReserved) && (
                    <Badge
                      text={`${Math.max(
                        menuItem.inventoryReserved - menuItemSoldCount,
                        0
                      )} in carts`}
                      light="warning"
                    />
                  )}
                  <Badge text={`${menuItemSoldCount} sold`} light="good" />
                  {showItemWaitlistPortalFunctionality(
                    menuItem,
                    menuItemSoldCount,
                    itemWaitlistCount
                  ) && <Badge text={itemWaitlistCount + " want more"} solid="black" />}
                </div>
              ) : (
                <Badge
                  text={
                    "Inventory: " +
                    (Number.isInteger(menuItem.inventory) ? menuItem.inventory : "∞")
                  }
                  light={
                    menuItem.inventory === 0
                      ? "primary"
                      : Number.isInteger(menuItem.inventory)
                      ? "black"
                      : "good"
                  }
                  style={{ display: "inline-block" }}
                />
              )
            }
          />
          <Spacer size={size === "large" || size === "xlarge" ? "XLarge" : "Large"} />
        </div>
      )}
    </Draggable>
  );
};

const mapStateToProps = (state) => {
  return {
    hostMenuItems: state.hostPortal.hostInfo.menuItems,
  };
};

export default connect(mapStateToProps, {
  setToastPopupInfo,
})(function MenuMaster({
  // from connect
  hostMenuItems,
  setToastPopupInfo,

  // from parent
  saveEventLoading,
  publishEventLoading,
  eventStatus,
  eventTimeSlotCadence,
  eventTimeSlotCount,
  // menu,
  // menuItems,
  menuDiffDispatch,
  // menuItemDiffDispatch,

  event,
  eventDiff,
  mergedEvent,
  orders,
  setMenuDiff,
  setMenuItemsDiff,
  saveAndContinue,
}) {
  const { chefId } = usePortalUser();
  const menu = event.menu || {};
  const menuItems = event.menuItems || {};
  const menuDiff = eventDiff.menu || {};
  // const menuItemsDiff = eventDiff.menuItems || {};
  const mergedMenu = mergedEvent.menu || {};
  const mergedMenuItems = mergedEvent.menuItems || {};

  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);
  const [debounce] = useState(() => {
    return _.debounce(
      (func) => {
        func();
      },
      2000,
      { leading: false, trailing: true }
    );
  });

  const [showAddMenuItemsModal, setShowAddMenuItemsModal] = useState(false);
  const [showAddMenuItemsModalOptionSelectModal, setShowAddMenuItemsModalOptionSelectModal] =
    useState(false);
  const [selectedMenuItemIds, setSelectedMenuItemIds] = useState(null);
  const [idxOfMenuSectionBeingEdited, setIdxOfMenuSectionBeingEdited] = useState(null);
  const [idOfMenuItemBeingEdited, setIdOfMenuItemBeingEdited] = useState(null);
  const saveMenuItemDiff = trpc.portal.saveMenuItemDiff.useMutation({
    retry: shouldRetry(1),
  });
  const deleteMenuItem = trpc.portal.deleteMenuItem.useMutation({
    retry: shouldRetry(1),
  });
  const getItemWaitlistCounts = trpc.portal.getItemWaitlistCounts.useQuery(
    { eventId: event.id },
    {
      refetchInterval: 60_000, // ms
    }
  );

  const isMenuEmpty =
    !Array.isArray(mergedMenu.sections) ||
    mergedMenu.sections.length === 0 ||
    (mergedMenu.sections.length === 1 &&
      (!Array.isArray(mergedMenu.sections[0].menuItems) ||
        mergedMenu.sections[0].menuItems.length === 0));

  const addMenuItemsModalOptionSelectModalState = showAddMenuItemsModalOptionSelectModal
    ? {
        message: "Which section would you like to add these items to?",
        optionSelectButtons: mergedMenu.sections.slice(1).map((section, sectionIndex) => {
          return {
            text: section.title === "" ? "Untitled Section" : section.title,
            type: "",
            onClick: () => addMenuItems(sectionIndex + 1, selectedMenuItemIds),
          };
        }),
        closeOptionSelectModal: () => {
          setShowAddMenuItemsModalOptionSelectModal(false);
          setSelectedMenuItemIds(null);
        },
        modal: undefined,
      }
    : null;

  const menuItemSoldCounts = {};
  if (orders instanceof Object) {
    for (const orderId in orders) {
      const order = orders[orderId];
      if (Array.isArray(order.cartItems)) {
        for (const cartItem of order.cartItems) {
          if (menuItemSoldCounts[cartItem.id] === undefined) {
            menuItemSoldCounts[cartItem.id] = 0;
          }
          menuItemSoldCounts[cartItem.id] += cartItem.quantity || 0;
        }
      }
    }
  }

  function addMenuSection() {
    menuDiffDispatch({ type: "addMenuSection" });
  }

  function handleMenuSettingsSectionsOnDragEnd(result) {
    // !WARNING! This function reorders the menu sections, but does
    // not adjust idxOfMenuSectionBeingEdited. This is because at
    // the time of writing, if idxOfMenuSectionBeingEdited is non-null,
    // then the menu section edit modal will be open and menu sections
    // cannot be rearranged, and so menuDiff.sections will be stable.
    const { destination, source } = result;

    if (!destination || !source) return;
    if (destination.index === source.index) return;

    menuDiffDispatch({
      type: "handleMenuSettingsSectionsOnDragEnd",
      payload: result,
    });
  }

  function setMaxOrdersPerTimeSlot(value) {
    if (!/^[0-9]+$/.test(value) && value !== "") return;
    menuDiffDispatch({ type: "setMaxOrdersPerTimeSlot", payload: value });
    debounce(() => {
      trackMaxCustomersPerPickupTimeUpdated({
        eventId: event.id,
        eventStatus: getEventStatus(event),
        value: value,
      });
    });
  }

  function toggleHideMenuUntilLive() {
    menuDiffDispatch({
      type: "setHideUntilLive",
      payload: !mergedMenu.hideUntilLive,
    });
    trackHideMenuToggled({
      eventId: event.id,
      eventStatus: getEventStatus(event),
      enabled: !mergedMenu.hideUntilLive,
    });
  }

  function toggleDisplayQuantityRemaining() {
    menuDiffDispatch({
      type: "setDisplayQuantityRemaining",
      payload: !mergedMenu.displayQuantityRemaining,
    });
    trackShowInventoryToggled({
      eventId: event.id,
      eventStatus: getEventStatus(event),
      enabled: !mergedMenu.displayQuantityRemaining,
    });
  }

  function handleMenuOnDragEnd(result) {
    // MIGHT FAIL WITH MULTIPLE ITEMS
    const { destination, source /* , draggableId */ } = result;

    if (!destination || !source) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    menuDiffDispatch({ type: "handleMenuOnDragEnd", payload: result });
  }

  function addMenuItems(sectionIndex, selectedMenuItemIds) {
    setMenuItemsDiff((menuItemsDiff) => {
      const newMenuItemsDiff = { ...menuItemsDiff };

      for (const selectedMenuItemId of selectedMenuItemIds) {
        if (!(selectedMenuItemId in mergedMenuItems)) {
          if (selectedMenuItemId in menuItems) {
            // restoring/undoing local delete
            delete newMenuItemsDiff[selectedMenuItemId];
          } else {
            // adding
            // merge host menu item with event-specific fields before adding to event
            newMenuItemsDiff[selectedMenuItemId] = {
              ...hostMenuItems[selectedMenuItemId],
              ...VOLUME_RESTRICTION_DEFAULTS,
            };
          }
        }
      }

      return newMenuItemsDiff;
    });

    setMenuDiff((menuDiff) => {
      const newSections = _.cloneDeep(
        menuDiff.sections && menuDiff.sections.length
          ? menuDiff.sections
          : menu.sections || INIT_SECTIONS_LIST // snapshot menu.sections
      );

      const sectionHasMenuItem = (menuItemId, sectionIndex) => {
        if (
          !Array.isArray(newSections) ||
          sectionIndex > newSections.length - 1 ||
          !Array.isArray(newSections[sectionIndex].menuItems)
        )
          return false;
        return (
          newSections[sectionIndex].menuItems.findIndex((itemId) => {
            return menuItemId === itemId;
          }) > -1
        );
      };

      for (const selectedMenuItemId of selectedMenuItemIds) {
        if (sectionHasMenuItem(selectedMenuItemId, sectionIndex)) {
          continue;
        }

        while (sectionIndex >= newSections.length) {
          // should only happen first time
          newSections.push({
            title: "",
          });
        }

        if (!Array.isArray(newSections[sectionIndex].menuItems)) {
          newSections[sectionIndex].menuItems = [];
        }
        newSections[sectionIndex].menuItems.push(selectedMenuItemId);
      }

      return {
        ...menuDiff,
        sections: newSections,
      };
    });

    setShowAddMenuItemsModal(false);
    setShowAddMenuItemsModalOptionSelectModal(false);
    setSelectedMenuItemIds(null);
    trackItemAddedToEvent({
      eventId: event.id,
      eventStatus: getEventStatus(event),
      numItems: selectedMenuItemIds.length,
    });
  }

  const removeMenuItemFromDiffs = useCallback(
    (menuItemId, snapshot = true) => {
      if (snapshot) {
        // snapshot menu.sections into the diff
        setMenuDiff((prevMenuDiff) => {
          if (!prevMenuDiff.sections || prevMenuDiff.sections.length === 0) {
            return {
              ...prevMenuDiff,
              sections: [...(menu.sections || [])],
            };
          }
          return prevMenuDiff; // no change
        });
      }

      // Remove menu item from menu sections
      setMenuDiff((prevMenuDiff) => {
        const newSections = _.cloneDeep(prevMenuDiff.sections);

        const sectionHasMenuItem = (menuItemId, sectionIndex) => {
          if (
            !Array.isArray(newSections) ||
            sectionIndex > newSections.length - 1 ||
            !Array.isArray(newSections[sectionIndex].menuItems)
          )
            return false;
          return (
            newSections[sectionIndex].menuItems.findIndex((itemId) => {
              return menuItemId === itemId;
            }) > -1
          );
        };

        if (Array.isArray(newSections)) {
          for (let i = 0; i < newSections.length; i++) {
            if (!sectionHasMenuItem(menuItemId, i)) continue;
            const indexToRemove = newSections[i].menuItems.findIndex((itemId) => {
              return menuItemId === itemId;
            });
            newSections[i].menuItems.splice(indexToRemove, 1);
          }
        }

        return {
          ...prevMenuDiff,
          sections: newSections,
        };
      });

      // Remove menu item from menuItems object
      setMenuItemsDiff((prevMenuItemsDiff) => {
        const newMenuItemsDiff = { ...prevMenuItemsDiff };
        delete newMenuItemsDiff[menuItemId];
        return newMenuItemsDiff;
      });
    },
    [menu.sections, setMenuDiff, setMenuItemsDiff]
  );

  // Handle concurrent menu item deletions
  const prevEvent = usePrevious(event);
  useEffect(() => {
    if (prevEvent !== event) {
      // look for deleted items and remove them from the diff objects
      // is that sufficient? can sections be ignored?
      const prevMenuItems = (prevEvent && prevEvent.menuItems) || {};
      const currMenuItems = event.menuItems || {};
      for (const menuItemId in prevMenuItems) {
        if (!(menuItemId in currMenuItems)) {
          // this menuItemId was deleted and needs to be removed from the diff
          removeMenuItemFromDiffs(menuItemId, false);
          if (menuItemId === idOfMenuItemBeingEdited) {
            setIdOfMenuItemBeingEdited(null);
          }
        }
      }
    }
  }, [prevEvent, event, removeMenuItemFromDiffs, idOfMenuItemBeingEdited]);

  return (
    <>
      <ConfigMenu
        AddSectionButton={
          <ConfigMenu.AddSectionButton
            text="Add Section"
            onClick={() => {
              addMenuSection();
              trackMenuSectionAdded({
                eventId: event.id,
                eventStatus: getEventStatus(event),
              });
            }}
          />
        }
        AddItemButton={<ConfigMenu.AddItemButton onClick={() => setShowAddMenuItemsModal(true)} />}
        size={size}
        isEmpty={isMenuEmpty}
        SaveAndContinueButton={
          <ConfigMenu.SaveAndContinueButton
            loading={saveEventLoading || publishEventLoading}
            disabled={isMenuEmpty || saveEventLoading || publishEventLoading}
            loadingAnimation={
              <ReactLoading type={"bubbles"} color={"#FFFFFF"} height={50} width={50} />
            }
            kind={eventStatus === "draft" ? "primary" : "good"}
            text={eventStatus === "draft" ? "Save & Continue" : "Publish All Changes"}
            onClick={() => saveAndContinue()}
          />
        }
        menuSettings={
          <EditMenuSettings
            size={size}
            noSections={!Array.isArray(mergedMenu.sections) || mergedMenu.sections.length < 3}
            MaxOrdersPerPickupTime={
              <EditMenuSettings.MaxOrdersPerPickupTime
                Input={
                  <Input
                    value={mergedMenu.maxOrdersPerTimeSlot}
                    disabled={chefId === "atozcreamery"}
                    onChange={(val) => setMaxOrdersPerTimeSlot(val)}
                    darkPlaceholder
                    placeholder="Unlimited"
                  />
                }
                tooltip={
                  <ToolTip
                    text={`Set a limit to the number of orders that can be placed for each Pickup Time of this event.\n
                  ${
                    eventTimeSlotCadence !== "Anytime between"
                      ? `Pickup Times currently occur every ${eventTimeSlotCadence}.`
                      : ""
                  }
                  \nYou have ${eventTimeSlotCount} Pickup Times.`}
                  />
                }
              />
            }
            ShowInventoryOnStorefront={
              <EditMenuSettings.ShowInventoryOnStorefront
                onClick={toggleDisplayQuantityRemaining}
                Switch={
                  <EditMenuSettings.ShowInventoryOnStorefront.Switch
                    checked={mergedMenu.displayQuantityRemaining}
                  />
                }
                tooltip={
                  <ToolTip
                    text={`Shows the real-time inventory of menu items to your customers once the event goes live.\n\nAny menu item that has an inventory of “Unlimited” will not display this value.`}
                  />
                }
              />
            }
            HideMenuUntilLive={
              <EditMenuSettings.HideMenuUntilLive
                onClick={toggleHideMenuUntilLive}
                Switch={
                  <EditMenuSettings.HideMenuUntilLive.Switch checked={mergedMenu.hideUntilLive} />
                }
              />
            }
            rearrangeSections={
              <EditMenuSettings.RearrangeSections>
                <DragDropContext onDragEnd={handleMenuSettingsSectionsOnDragEnd}>
                  <Droppable droppableId={"-1"}>
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {Array.isArray(mergedMenu.sections) &&
                          mergedMenu.sections.map((section, sectionIndex) => {
                            if (sectionIndex === 0) return null;
                            return (
                              <DraggableSectionMaster
                                key={sectionIndex}
                                keyVal={sectionIndex}
                                index={sectionIndex}
                                id={sectionIndex.toString()}
                                section={section}
                              />
                            );
                          })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </EditMenuSettings.RearrangeSections>
            }
          />
        }
      >
        {isMenuEmpty && (
          <ConfigMenu.EmptyMenuCta
            AddFirstItemButton={
              <ConfigMenu.EmptyMenuCta.AddFirstItemButton
                onClick={() => setShowAddMenuItemsModal(true)}
              />
            }
          />
        )}
        {!isMenuEmpty && (
          <DragDropContext onDragEnd={handleMenuOnDragEnd}>
            {mergedMenu.sections.map(
              (section, sectionIndex) =>
                (mergedMenu.sections.length <= 1 || sectionIndex !== 0) && (
                  <Droppable droppableId={sectionIndex.toString()} key={sectionIndex}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <ConfigSection
                          key={"sectionContainer:" + sectionIndex}
                          noTitle={sectionIndex === 0}
                          size={size}
                          SectionTitle={
                            <ConfigSection.SectionTitle
                              title={section.title === "" ? "Untitled Section" : section.title}
                              onClick={() => {
                                // Snapshot the mergedMenu into the diff.
                                // We cannot deal with concurrent updates
                                // to the menu sections list because menu
                                // section objects do not have unique IDs
                                // and so we need to make sure the
                                // association between a section's index
                                // and a section remains stable.

                                // Snapshot the sections list into the diff
                                // if the diff hasn't been created yet to
                                // ensure the indices of the list won't
                                // change up from underneath us while
                                // a particular section is being edited.
                                setMenuDiff((prevMenuDiff) => {
                                  if (
                                    !prevMenuDiff.sections ||
                                    prevMenuDiff.sections.length === 0
                                  ) {
                                    return {
                                      ...prevMenuDiff,
                                      sections: [...(menu.sections || [])],
                                    };
                                  }
                                  return prevMenuDiff; // no change
                                });
                                setIdxOfMenuSectionBeingEdited(sectionIndex);
                              }}
                            />
                          }
                        >
                          {(!Array.isArray(section.menuItems) || section.menuItems.length === 0) &&
                            sectionIndex !== 0 && <ConfigSection.EmptyContainer size={size} />}

                          {Array.isArray(section.menuItems) &&
                            section.menuItems.map((menuItemId, menuItemIndex) => (
                              <DraggableMenuItem
                                key={menuItemId}
                                keyVal={menuItemId}
                                id={menuItemId + ":::::" + sectionIndex}
                                menuItem={mergedMenuItems[menuItemId]}
                                itemWaitlistCount={getItemWaitlistCounts.data?.[menuItemId]}
                                menuItemSoldCount={menuItemSoldCounts[menuItemId] || 0}
                                index={menuItemIndex}
                                onMouseUp={() => {
                                  setIdOfMenuItemBeingEdited(menuItemId);
                                }}
                                showLiveCounts={
                                  ["live", "complete"].includes(eventStatus) &&
                                  menuItems[menuItemId] // check that the menuItem is saved/published: it will be in the menuItems prop
                                }
                              />
                            ))}
                          {provided.placeholder}
                        </ConfigSection>
                      </div>
                    )}
                  </Droppable>
                )
            )}
          </DragDropContext>
        )}
      </ConfigMenu>

      {showAddMenuItemsModal && (
        <AddMenuItemsModal
          eventId={event.id}
          onComplete={(selectedMenuItemIds) => {
            if (!Array.isArray(mergedMenu.sections) || mergedMenu.sections.length === 0) {
              addMenuItems(0, selectedMenuItemIds);
            } else if (mergedMenu.sections.length < 3) {
              addMenuItems(mergedMenu.sections.length - 1, selectedMenuItemIds);
            } else {
              setShowAddMenuItemsModalOptionSelectModal(true);
              setSelectedMenuItemIds(selectedMenuItemIds);
            }
          }}
          onRequestClose={() => {
            setShowAddMenuItemsModal(false);
            setShowAddMenuItemsModalOptionSelectModal(false);
            setSelectedMenuItemIds(null);
          }}
          optionSelectModalState={addMenuItemsModalOptionSelectModalState}
        />
      )}

      {idxOfMenuSectionBeingEdited !== null && (
        <EditMenuSectionModal
          menuSection={mergedMenu.sections[idxOfMenuSectionBeingEdited]}
          onRequestSave={(menuSectionDiffIncoming) => {
            setIdxOfMenuSectionBeingEdited(null);
            setMenuDiff((prevMenuDiff) => {
              const prevSections = prevMenuDiff.sections || [];
              const newSectionsDiff = [...prevSections];

              if (menuSectionDiffIncoming === null) {
                newSectionsDiff.splice(idxOfMenuSectionBeingEdited, 1);
              } else {
                newSectionsDiff[idxOfMenuSectionBeingEdited] = mergeDiff(
                  prevSections[idxOfMenuSectionBeingEdited],
                  menuSectionDiffIncoming
                );
              }

              return {
                ...prevMenuDiff,
                sections: newSectionsDiff,
              };
            });
            if (menuSectionDiffIncoming === null) {
              setMenuItemsDiff((prevMenuItemsDiff) => {
                const sections = menuDiff.sections || [];
                const section = sections[idxOfMenuSectionBeingEdited] || {};
                const ids = section.menuItems || [];
                const newMenuItemsDiff = { ...prevMenuItemsDiff };
                for (const id of ids) {
                  delete newMenuItemsDiff[id];
                }
                return newMenuItemsDiff;
              });
            }
          }}
          onRequestClose={() => {
            // !WARNING! no clean-up because array was snapshotted
            setIdxOfMenuSectionBeingEdited(null);
          }}
        />
      )}

      {idOfMenuItemBeingEdited !== null && (
        <AddEditMenuItemModal
          showLiveCounts={
            ["live", "complete"].includes(eventStatus) && menuItems[idOfMenuItemBeingEdited] // check that the menuItem is saved/published: it will be in the menuItems prop
          }
          eventId={event.id}
          eventStatus={eventStatus}
          isNewMenuItem={false} // new menu items are not created in this component
          menuItem={mergedMenuItems[idOfMenuItemBeingEdited]}
          menuItemSoldCount={menuItemSoldCounts[idOfMenuItemBeingEdited] || 0}
          itemWaitlistCount={getItemWaitlistCounts.data?.[idOfMenuItemBeingEdited]}
          saveMenuItemLoading={saveMenuItemDiff.isLoading}
          deleteMenuItemLoading={deleteMenuItem.isLoading}
          onRequestSave={async (menuItemDiffIncoming) => {
            // menuItemDiffIncoming may be null, to request removal from event, NOT deletion from host catalog
            if (menuItemDiffIncoming === null) {
              // remove from menu sections diff, snapshotting if necessary
              removeMenuItemFromDiffs(idOfMenuItemBeingEdited);
            } else {
              // save host menu item
              const menuItemDiffToSave = { ...menuItemDiffIncoming };
              for (const key in VOLUME_RESTRICTION_DEFAULTS) {
                delete menuItemDiffToSave[key];
              }
              try {
                await saveMenuItemDiff.mutateAsync({
                  menuItemId: idOfMenuItemBeingEdited,
                  menuItemDiff: menuItemDiffToSave,
                });
              } catch (e) {
                setToastPopupInfo({
                  type: "error",
                  text: "Menu item failed to save",
                });
                throw e;
              }
            }
            setIdOfMenuItemBeingEdited(null);
            setMenuItemsDiff((prevMenuItemsDiff) => {
              return {
                ...prevMenuItemsDiff,
                [idOfMenuItemBeingEdited]: mergeDiff(
                  prevMenuItemsDiff[idOfMenuItemBeingEdited],
                  menuItemDiffIncoming
                ),
              };
            });
          }}
          onRequestDelete={async () => {
            // delete from host catalog
            try {
              await deleteMenuItem.mutateAsync({ menuItemId: idOfMenuItemBeingEdited });
            } catch (e) {
              setToastPopupInfo({
                type: "error",
                text: "Menu item failed to delete",
              });
              throw e;
            }
            setIdOfMenuItemBeingEdited(null);

            // remove from menu sections diff, snapshotting if necessary
            // this needs to happen before the setMenuItemsDiff because
            // removeMenuItemFromDiffs will set the menu item to undefined
            removeMenuItemFromDiffs(idOfMenuItemBeingEdited);

            setMenuItemsDiff((prevMenuItemsDiff) => {
              return {
                ...prevMenuItemsDiff,
                [idOfMenuItemBeingEdited]: null,
              };
            });
          }}
          onRequestClose={() => {
            setIdOfMenuItemBeingEdited(null);
          }}
        />
      )}
    </>
  );
});
