// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./RestockItems.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as Checkbox, CheckboxContext } from "../Primitives/Checkbox";

const styles = [
  {
    type: "default",
    layers: {
      P6ENa5mBsn: {
        none: {
          text: "Restock 1 item",
        },
        disabled: {
          text: "Restock 0 items",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "noItemsSelected",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      "5QEcfmSzWc": {},
    },
  },
  {
    type: "boolean",
    propName: "noItemsSelected",
    layers: {
      "5QEcfmSzWc": {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "noItemsSelected",
  },
];

export const RestockItemsContext = createContext(null);

function RestockItems(_props) {
  const defaults = useContext(RestockItemsContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="Ef5Wkm3XGf"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "Ef5Wkm3XGf",
        scope: "SG9UvRV6iv",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <SpacerPrimitive
            id={"Spacer_NThhnWmVNF"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_SG9UvRV6iv_NThhnWmVNF"
            }
            key={"NThhnWmVNF"}
          />
          <ContainerPrimitive
            id={"divider_7Zaioh977Q"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_SG9UvRV6iv_7Zaioh977Q"
            }
            key={"7Zaioh977Q"}
            addSpacing={false}
            element={getStyle("7Zaioh977Q", "htmlElement")}
          >
            {null}
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_Q3oApbUibE"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_SG9UvRV6iv_Q3oApbUibE"
            }
            key={"Q3oApbUibE"}
          />
          <ContainerPrimitive
            id={"content_FC4dU6gm9S"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_SG9UvRV6iv_FC4dU6gm9S"
            }
            key={"FC4dU6gm9S"}
            addSpacing={false}
            element={getStyle("FC4dU6gm9S", "htmlElement")}
          >
            {[
              props.Checkbox === undefined ? (
                <Checkbox
                  key={"5QEcfmSzWc"}
                  {...getCompositeDefaultProps("5QEcfmSzWc")}
                  id="Checkbox_5QEcfmSzWc"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_SG9UvRV6iv_5QEcfmSzWc"
                />
              ) : (
                <CheckboxContext.Provider
                  key="5QEcfmSzWc-provider"
                  value={{
                    key: "5QEcfmSzWc",
                    id: "Checkbox_5QEcfmSzWc",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_SG9UvRV6iv_5QEcfmSzWc",
                    ...getCompositeDefaultProps("5QEcfmSzWc"),
                  }}
                >
                  {props.Checkbox}
                </CheckboxContext.Provider>
              ),
              <SpacerPrimitive
                id={"Spacer_4bFozH5TYL"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_SG9UvRV6iv_4bFozH5TYL"
                }
                key={"4bFozH5TYL"}
              />,
              <TextPrimitive
                id={"Text_P6ENa5mBsn"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_SG9UvRV6iv_P6ENa5mBsn"
                }
                key={"P6ENa5mBsn"}
                text={
                  exists(props.text)
                    ? props.text
                    : getStyle("P6ENa5mBsn", "text")
                }
                element={getStyle("P6ENa5mBsn", "htmlElement")}
              />,
            ]}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

RestockItems.Checkbox = Checkbox;
RestockItems.__variants = [
  {
    name: "noItemsSelected",
    type: "variant",
  },
];

export default RestockItems;
