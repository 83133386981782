import React from "react";
import { styled } from "../stitches.config";
import { ReactComponent as HotplateLogo } from "../static/images/logos/outline_logo.svg";

const ContainerFlex = styled("div", {
  display: "flex",
  position: "relative",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  backgroundColor: "$accent2",
  padding: "$xl",
});

const Title = styled("h1", {
  ff: "$arboria",
  fs: "$heading_lg",
  fw: "bold",
  color: "$accent12",
  marginBottom: "$xl",
  textAlign: "center",
});

const Description = styled("p", {
  ff: "$avenir",
  fs: "$md",
  lh: "$article",
  color: "$accent11",
  textAlign: "center",
  marginBottom: "$lg",
  maxWidth: "50ch",
});

const NumberBox = styled("div", {
  display: "flex",
  padx: "$md",
  ff: "$avenir",
  fs: "$md",
  fw: "$bold",
  lh: "$article",
  color: "$hotplate11",
  backgroundColor: "$hotplate4",
  br: "$sm",
  mb: "$lg",
});

const LogoBox = styled("div", {
  display: "flex",
  height: "40px",
  position: "absolute",
  top: 24,
  left: 24,
});

export const NotFound = ({ title, description }: { title?: string; description?: string }) => {
  return (
    <ContainerFlex>
      <LogoBox>
        <HotplateLogo height="100%" />
      </LogoBox>
      <NumberBox>404</NumberBox>
      <Title>{title || "Dude, where's my page?"}</Title>
      <Description>
        {description ||
          "We get it, you're lost. It happens to us all! Why don't you try looking over the URL you typed in?"}
      </Description>
    </ContainerFlex>
  );
};
