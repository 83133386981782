// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./ItemsCard.Expand.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../../_internal_primitives";
import * as icons from "../../icons";

const styles = [
  {
    type: "default",
    layers: {
      Py2s3cCaE9: {
        none: {
          icon: icons.chevronRight,
          useMask: true,
        },
        pressed: {
          useMask: true,
        },
      },
      NAPG4aTCi7: {
        none: {
          text: "Expand",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "pressed",
    layers: {
      Py2s3cCaE9: {
        none: {
          useMask: true,
        },
      },
      NAPG4aTCi7: {
        none: {
          text: "Collapse",
        },
        hover: {
          text: "Collapse",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "pressed",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "pressed",
  },
  {
    type: "enum",
    propName: "size",
    propValues: ["large", "med", "small", "xsmall", "xlarge"],
  },
];

export const ExpandContext = createContext(null);

function Expand(_props) {
  const defaults = useContext(ExpandContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="VkNnZ5Vf4G"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "VkNnZ5Vf4G",
        scope: "UbCx7KGrC7",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_BwR3pj5VTt"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_UbCx7KGrC7_BwR3pj5VTt"
          }
          key={"BwR3pj5VTt"}
          addSpacing={true}
          element={getStyle("BwR3pj5VTt", "htmlElement")}
        >
          <TextPrimitive
            id={"Text_NAPG4aTCi7"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_UbCx7KGrC7_NAPG4aTCi7"
            }
            key={"NAPG4aTCi7"}
            text={getStyle("NAPG4aTCi7", "text")}
            element={getStyle("NAPG4aTCi7", "htmlElement")}
          />
          <IconPrimitive
            id={"Icon_Py2s3cCaE9"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_UbCx7KGrC7_Py2s3cCaE9"
            }
            key={"Py2s3cCaE9"}
            useMask={getStyle("Py2s3cCaE9", "useMask")}
            src={getStyle("Py2s3cCaE9", "icon")}
          />
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

Expand.__variants = [
  {
    name: "pressed",
    type: "variant",
  },
  {
    name: "size",
    type: "group",
    variants: ["large", "med", "small", "xsmall", "xlarge"],
  },
];

export default Expand;
