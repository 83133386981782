// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./AddEditModal.Header.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, TextPrimitive } from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      LtGDP63TcU: {
        none: {
          text: "Text",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const HeaderContext = createContext(null);

function Header(_props) {
  const defaults = useContext(HeaderContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="5WXBZhtsB5"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "5WXBZhtsB5",
        scope: "6xZz1rMM69",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <TextPrimitive
          id={"title_LtGDP63TcU"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_6xZz1rMM69_LtGDP63TcU"
          }
          key={"LtGDP63TcU"}
          text={
            exists(props.title) ? props.title : getStyle("LtGDP63TcU", "text")
          }
          element={getStyle("LtGDP63TcU", "htmlElement")}
        />
      )}
    </RootPrimitive>
  );
}

Header.__variants = [];

export default Header;
