// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./ToolTipContainer.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      FXvDdDTnhJ: {
        none: {
          htmlElement: "section",
        },
      },
      "5gQ2csBSir": {
        none: {
          text:
            "Lorem Ipsum sin dolor amet. requiet dolor sin pualor dolor sin amet. Lorem Ipsum sin dolor amet. requiet dolor sin pualor dolor sin amet",
          htmlElement: "span",
        },
      },
      "6CMg5DhD6D": {
        none: {
          icon: icons.info,
          useMask: true,
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "showToolTip",
    layers: {},
  },
  {
    type: "enum",
    propName: "placement",
    values: [
      {
        propValue: "left",
        layers: {},
      },
      {
        propValue: "right",
        layers: {},
      },
      {
        propValue: "center",
        layers: {},
      },
    ],
  },
  {
    type: "boolean",
    propName: "white",
    layers: {
      "6CMg5DhD6D": {
        none: {
          useMask: true,
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "showToolTip",
    layers: {},
  },
  {
    type: "enum",
    propName: "placement",
    values: [
      {
        propValue: "left",
        layers: {},
      },
      {
        propValue: "right",
        layers: {},
      },
      {
        propValue: "center",
        layers: {},
      },
    ],
  },
  {
    type: "boolean",
    propName: "white",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "showToolTip",
  },
  {
    type: "enum",
    propName: "placement",
    propValues: ["left", "right", "center"],
  },
  {
    type: "boolean",
    propName: "white",
  },
];

export const ToolTipContainerContext = createContext(null);

function ToolTipContainer(_props) {
  const defaults = useContext(ToolTipContainerContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="GdLchwHRSD"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "GdLchwHRSD",
        scope: "GHpFYY3PTm",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <IconPrimitive
            id={"Icon_6CMg5DhD6D"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_GHpFYY3PTm_6CMg5DhD6D"
            }
            key={"6CMg5DhD6D"}
            useMask={getStyle("6CMg5DhD6D", "useMask")}
            src={getStyle("6CMg5DhD6D", "icon")}
          />
          <ContainerPrimitive
            id={"Container_FXvDdDTnhJ"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_GHpFYY3PTm_FXvDdDTnhJ"
            }
            key={"FXvDdDTnhJ"}
            addSpacing={false}
            element={getStyle("FXvDdDTnhJ", "htmlElement")}
          >
            {
              <TextPrimitive
                id={"Text_5gQ2csBSir"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_GHpFYY3PTm_5gQ2csBSir"
                }
                key={"5gQ2csBSir"}
                text={
                  exists(props.text)
                    ? props.text
                    : getStyle("5gQ2csBSir", "text")
                }
                element={getStyle("5gQ2csBSir", "htmlElement")}
              />
            }
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

ToolTipContainer.__variants = [
  {
    name: "showToolTip",
    type: "variant",
  },
  {
    name: "placement",
    type: "group",
    variants: ["left", "right", "center"],
  },
  {
    name: "white",
    type: "variant",
  },
];

export default ToolTipContainer;
