// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./OrderEditingLineItem.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../../_internal_primitives";
import { default as RefundItemButton } from "./OrderEditingLineItem.RefundItemButton";

const styles = [
  {
    type: "default",
    layers: {
      NPfbVWCXUk: {
        none: {
          text: "2",
        },
      },
      "7kuddeoWzF": {
        none: {
          text: "???? ????",
        },
      },
      HFMN25si9o: {
        none: {
          text: "x",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const OrderEditingLineItemContext = createContext(null);

function OrderEditingLineItem(_props) {
  const defaults = useContext(OrderEditingLineItemContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="MiByoQ6Z9a"
      addSpacing={true}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "MiByoQ6Z9a",
        scope: "XmsTFUeWFV",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"quantity-name-container_NftG6smzH8"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_XmsTFUeWFV_NftG6smzH8"
            }
            key={"NftG6smzH8"}
            addSpacing={false}
            element={getStyle("NftG6smzH8", "htmlElement")}
          >
            <ContainerPrimitive
              id={"quantity-container_Q7j96QyALu"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_XmsTFUeWFV_Q7j96QyALu"
              }
              key={"Q7j96QyALu"}
              addSpacing={false}
              element={getStyle("Q7j96QyALu", "htmlElement")}
            >
              <TextPrimitive
                id={"quantity_NPfbVWCXUk"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_XmsTFUeWFV_NPfbVWCXUk"
                }
                key={"NPfbVWCXUk"}
                text={
                  exists(props.quantity)
                    ? props.quantity
                    : getStyle("NPfbVWCXUk", "text")
                }
                element={getStyle("NPfbVWCXUk", "htmlElement")}
              />
              <TextPrimitive
                id={"x_HFMN25si9o"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_XmsTFUeWFV_HFMN25si9o"
                }
                key={"HFMN25si9o"}
                text={getStyle("HFMN25si9o", "text")}
                element={getStyle("HFMN25si9o", "htmlElement")}
              />
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"name-options-container_HveJnwtwZs"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_XmsTFUeWFV_HveJnwtwZs"
              }
              key={"HveJnwtwZs"}
              addSpacing={false}
              element={getStyle("HveJnwtwZs", "htmlElement")}
            >
              <TextPrimitive
                id={"item_7kuddeoWzF"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_XmsTFUeWFV_7kuddeoWzF"
                }
                key={"7kuddeoWzF"}
                text={
                  exists(props.item)
                    ? props.item
                    : getStyle("7kuddeoWzF", "text")
                }
                element={getStyle("7kuddeoWzF", "htmlElement")}
              />
              <ContainerPrimitive
                key={"SDKTg4u26K"}
                id={"Slot_SDKTg4u26K"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_XmsTFUeWFV_SDKTg4u26K"
                }
                addSpacing={false}
              >
                {props.editModsButton}
              </ContainerPrimitive>
            </ContainerPrimitive>
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"removeandrefund-container_TKdregjEPm"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_XmsTFUeWFV_TKdregjEPm"
            }
            key={"TKdregjEPm"}
            addSpacing={false}
            element={getStyle("TKdregjEPm", "htmlElement")}
          >
            {props.RefundItemButton === undefined ? (
              <RefundItemButton key={"9vrtsFCdoL"} />
            ) : (
              props.RefundItemButton
            )}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

OrderEditingLineItem.__variants = [];

export default OrderEditingLineItem;
