// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./AddEditMenuItem.GlobalDetails.Modifications.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as OptionCategoryCard } from "../Events/OptionCategoryCard";

const styles = [
  {
    type: "default",
    layers: {
      "39aZEZMMrK": {
        none: {
          text:
            "Add options if this Item has add-ons, sizes,  or types (e.g. a half or full pint of chocolate or vanilla ice cream, and the option to add fudge)",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "empty",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      QjDbaUktFe: {},
    },
  },
  {
    type: "boolean",
    propName: "empty",
    layers: {
      QjDbaUktFe: {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "empty",
  },
];

export const ModificationsContext = createContext(null);

function Modifications(_props) {
  const defaults = useContext(ModificationsContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="Nugj43LEZ1"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "Nugj43LEZ1",
        scope: "Wr5aLyTejf",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <TextPrimitive
            id={"Text_39aZEZMMrK"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Wr5aLyTejf_39aZEZMMrK"
            }
            key={"39aZEZMMrK"}
            text={getStyle("39aZEZMMrK", "text")}
            element={getStyle("39aZEZMMrK", "htmlElement")}
          />
          <ContainerPrimitive
            key={"Zp39vxo5Rq"}
            id={"Slot_Zp39vxo5Rq"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Wr5aLyTejf_Zp39vxo5Rq"
            }
            addSpacing={true}
          >
            {props.children}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

Modifications.OptionCategoryCard = OptionCategoryCard;
Modifications.__variants = [
  {
    name: "empty",
    type: "variant",
  },
];

export default Modifications;
