import React from "react";
import { CSSProperties, DetailedHTMLProps, HTMLAttributes } from "react";
import { keyframes } from "../../stitches.config";
// https://github.com/davidhu2000/react-spinners

interface LoaderSizeProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  color?: string;
  loading?: boolean;
  cssOverride?: CSSProperties;
  speedMultiplier?: number;
  size?: number | string;
}

const puff1 = keyframes({
  "0%": { transform: "scale(0)" },
  "100%": { transform: "scale(1.0)" },
});

const puff2 = keyframes({
  "0%": { opacity: 1 },
  "100%": { opacity: 0 },
});

export function PuffLoader({
  loading = true,
  color = "hsl(250, 43%, 48%)",
  speedMultiplier = 1,
  cssOverride = {},
  size = 60,
  ...additionalprops
}: LoaderSizeProps): JSX.Element | null {
  const wrapper: React.CSSProperties = {
    display: "inherit",
    position: "relative",
    width: size,
    height: size,
    ...cssOverride,
  };

  const style = (i: number): React.CSSProperties => {
    return {
      position: "absolute",
      height: size,
      width: size,
      border: `thick solid ${color}`,
      borderRadius: "50%",
      opacity: "1",
      top: "0",
      left: "0",
      animationFillMode: "both",
      animation: `${puff1}, ${puff2}`,
      animationDuration: `${1.5 / speedMultiplier}s`,
      animationIterationCount: "infinite",
      animationTimingFunction:
        "cubic-bezier(0.165, 0.84, 0.44, 1), cubic-bezier(0.3, 0.61, 0.355, 1)",
      animationDelay: i === 1 ? "-1s" : "0s",
    };
  };

  if (!loading) {
    return null;
  }

  return (
    <span style={wrapper} {...additionalprops}>
      <span style={style(1)} />
      <span style={style(2)} />
    </span>
  );
}
