// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Menu.Item.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { MenuItemRoot } from "../_internal_menu";

const styles = [
  {
    type: "default",
    layers: {
      "919h6wuWJr": {
        none: {
          text: "List item",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const ItemContext = createContext(null);

function Item(_props) {
  const defaults = useContext(ItemContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <MenuItemRoot
      {...props}
      key="menu-item"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "menu-item",
        scope: "SUKPhmzsGh",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Row_3yHi7R9mUr"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_SUKPhmzsGh_3yHi7R9mUr"
          }
          key={"3yHi7R9mUr"}
          addSpacing={false}
          element={getStyle("3yHi7R9mUr", "htmlElement")}
        >
          <SpacerPrimitive
            id={"Spacer_7af6buYrHG"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_SUKPhmzsGh_7af6buYrHG"
            }
            key={"7af6buYrHG"}
          />
          <TextPrimitive
            id={"text_919h6wuWJr"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_SUKPhmzsGh_919h6wuWJr"
            }
            key={"919h6wuWJr"}
            text={
              exists(props.text) ? props.text : getStyle("919h6wuWJr", "text")
            }
            element={getStyle("919h6wuWJr", "htmlElement")}
          />
        </ContainerPrimitive>
      )}
    </MenuItemRoot>
  );
}

Item.__variants = [];

export default Item;
