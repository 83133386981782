// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./PayoutHist.Payout.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      S34Cc4s7Hh: {
        none: {
          text: "11:00 AM",
        },
      },
      "2AwPagLMgR": {
        none: {
          text: "Apr 4",
        },
      },
      YZGaV4FWYC: {
        none: {
          text: " ",
        },
      },
      A7FQ6Vo9gj: {
        none: {
          text: "N/a",
        },
      },
      AzfrhTeCW5: {
        none: {
          text: "0.00",
        },
      },
      "5F3Ggf1ZPX": {
        none: {
          text: ",",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xlarge",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "xsmall",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xlarge",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "xsmall",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["xlarge", "large", "med", "small", "xsmall"],
  },
];

export const PayoutContext = createContext(null);

function Payout(_props) {
  const defaults = useContext(PayoutContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="PPhEBZe7Tr"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "PPhEBZe7Tr",
        scope: "Sz2cnUqrqv",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_6JrJfrgx5V"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_Sz2cnUqrqv_6JrJfrgx5V"
          }
          key={"6JrJfrgx5V"}
          addSpacing={false}
          element={getStyle("6JrJfrgx5V", "htmlElement")}
        >
          <ContainerPrimitive
            id={"amount_Mz9HtLQXU5"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Sz2cnUqrqv_Mz9HtLQXU5"
            }
            key={"Mz9HtLQXU5"}
            addSpacing={false}
            element={getStyle("Mz9HtLQXU5", "htmlElement")}
          >
            {
              <TextPrimitive
                id={"amount_AzfrhTeCW5"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_Sz2cnUqrqv_AzfrhTeCW5"
                }
                key={"AzfrhTeCW5"}
                text={
                  exists(props.amount)
                    ? props.amount
                    : getStyle("AzfrhTeCW5", "text")
                }
                element={getStyle("AzfrhTeCW5", "htmlElement")}
              />
            }
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"account_SVTCj6W7mD"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Sz2cnUqrqv_SVTCj6W7mD"
            }
            key={"SVTCj6W7mD"}
            addSpacing={false}
            element={getStyle("SVTCj6W7mD", "htmlElement")}
          >
            <TextPrimitive
              id={"bank_A7FQ6Vo9gj"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Sz2cnUqrqv_A7FQ6Vo9gj"
              }
              key={"A7FQ6Vo9gj"}
              text={
                exists(props.bank) ? props.bank : getStyle("A7FQ6Vo9gj", "text")
              }
              element={getStyle("A7FQ6Vo9gj", "htmlElement")}
            />
            <TextPrimitive
              id={"lastFour_YZGaV4FWYC"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Sz2cnUqrqv_YZGaV4FWYC"
              }
              key={"YZGaV4FWYC"}
              text={
                exists(props.lastFour)
                  ? props.lastFour
                  : getStyle("YZGaV4FWYC", "text")
              }
              element={getStyle("YZGaV4FWYC", "htmlElement")}
            />
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"date_2TV6oh6bRt"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Sz2cnUqrqv_2TV6oh6bRt"
            }
            key={"2TV6oh6bRt"}
            addSpacing={false}
            element={getStyle("2TV6oh6bRt", "htmlElement")}
          >
            <ContainerPrimitive
              id={"Container_BaqjTkdYGb"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Sz2cnUqrqv_BaqjTkdYGb"
              }
              key={"BaqjTkdYGb"}
              addSpacing={false}
              element={getStyle("BaqjTkdYGb", "htmlElement")}
            >
              <TextPrimitive
                id={"date_2AwPagLMgR"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_Sz2cnUqrqv_2AwPagLMgR"
                }
                key={"2AwPagLMgR"}
                text={
                  exists(props.date)
                    ? props.date
                    : getStyle("2AwPagLMgR", "text")
                }
                element={getStyle("2AwPagLMgR", "htmlElement")}
              />
              <TextPrimitive
                id={",_5F3Ggf1ZPX"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_Sz2cnUqrqv_5F3Ggf1ZPX"
                }
                key={"5F3Ggf1ZPX"}
                text={getStyle("5F3Ggf1ZPX", "text")}
                element={getStyle("5F3Ggf1ZPX", "htmlElement")}
              />
            </ContainerPrimitive>
            <TextPrimitive
              id={"time_S34Cc4s7Hh"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Sz2cnUqrqv_S34Cc4s7Hh"
              }
              key={"S34Cc4s7Hh"}
              text={
                exists(props.time) ? props.time : getStyle("S34Cc4s7Hh", "text")
              }
              element={getStyle("S34Cc4s7Hh", "htmlElement")}
            />
          </ContainerPrimitive>
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

Payout.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["xlarge", "large", "med", "small", "xsmall"],
  },
];

export default Payout;
