import { styled } from "../../stitches.config";

export const Badge = styled("div", {
  $$backgroundColor: "$colors$gray4",
  $$color: "$colors$gray11",
  display: "flex",

  alignItems: "center",
  justifyContent: "center",
  padding: "4px 8px 3px",
  borderRadius: 4,
  backgroundColor: "$$backgroundColor",
  color: "$$color",
  width: "fit-content",
  ff: "$avenir",

  fw: "$normal",
  wordBreak: "keep-all",
  lh: "$reset",
  variants: {
    color: {
      solid_black: {
        $$backgroundColor: "$colors$gray12",
        $$color: "$colors$white",
      },
      solid_gray: {
        $$backgroundColor: "$colors$gray9",
        $$color: "$colors$white",
      },
      solid_info: {
        $$backgroundColor: "$colors$info9",
        $$color: "$colors$white",
      },
      solid_warning: {
        $$backgroundColor: "$colors$warning9",
        $$color: "$colors$gray12",
      },
      solid_success: {
        $$backgroundColor: "$colors$success9",
        $$color: "$colors$white",
      },
      solid_accent: {
        $$backgroundColor: "$colors$accent9",
        $$color: "$colors$white",
      },
      solid_danger: {
        $$backgroundColor: "$colors$danger9",
        $$color: "$colors$white",
      },
      solid_hotplate: {
        $$backgroundColor: "$colors$hotplate9",
        $$color: "$colors$white",
      },
      light_gray: {
        $$backgroundColor: "$colors$gray5",
        $$color: "$colors$gray12",
      },
      light_info: {
        $$backgroundColor: "$colors$info4",
        $$color: "$colors$info11",
      },
      light_warning: {
        $$backgroundColor: "$colors$warning4",
        $$color: "$colors$warning11",
      },
      light_success: {
        $$backgroundColor: "$colors$success4",
        $$color: "$colors$success11",
      },
      light_accent: {
        $$backgroundColor: "$colors$accent4",
        $$color: "$colors$accent11",
      },
      light_danger: {
        $$backgroundColor: "$colors$danger4",
        $$color: "$colors$danger11",
      },
      light_hotplate: {
        $$backgroundColor: "$colors$hotplate4",
        $$color: "$colors$hotplate11",
      },
    },
    type: {
      pickup: { $$backgroundColor: "$colors$info9", $$color: "$colors$white" },
      walk_up: { $$backgroundColor: "$colors$hotplate9", $$color: "$colors$white" },
      delivery: { $$backgroundColor: "$colors$warning9", $$color: "$colors$white" },
      subscription: { $$backgroundColor: "$colors$accent9", $$color: "$colors$white" },
      wholesale: { $$backgroundColor: "$colors$success11", $$color: "$colors$white" },
      catering: { $$backgroundColor: "$colors$success11", $$color: "$colors$white" },
      shipping: { $$backgroundColor: "$colors$gray12", $$color: "$colors$white" },
    },
    size: {
      small: { fs: 12, height: 20 },
      medium: { fs: 14, height: 24 },
    },
  },
  defaultVariants: {
    color: "light_gray",
    size: "medium",
  },
});
