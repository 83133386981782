/* eslint-disable react/prop-types */
import React, { createContext, useState } from "react";
import * as Sentry from "@sentry/react";
import firebase from "firebase/app";
import { useDispatch } from "react-redux";
import _ from "lodash";
import {
  CONNECT_TO_CHEF_INFO_START,
  CONNECT_TO_CHEF_INFO_SUCCESS,
  CONNECT_TO_CHEF_INFO_FAILURE,
  DISCONNECT_FROM_CHEF_INFO_START,
  DISCONNECT_FROM_CHEF_INFO_SUCCESS,
  DISCONNECT_FROM_CHEF_INFO_FAILURE,
  GET_GOD_MODE_OPTIONS_START,
  GET_GOD_MODE_OPTIONS_SUCCESS,
  GET_GOD_MODE_OPTIONS_FAILURE,
  UPLOAD_COMPONENT_STATE_START,
  UPLOAD_COMPONENT_STATE_SUCCESS,
  UPLOAD_COMPONENT_STATE_FAILURE,
  CONNECT_TO_EVENT_ORDERS_START,
  CONNECT_TO_EVENT_ORDERS_SUCCESS,
  CONNECT_TO_EVENT_ORDERS_FAILURE,
  DISCONNECT_FROM_EVENT_ORDERS_START,
  DISCONNECT_FROM_EVENT_ORDERS_SUCCESS,
  DISCONNECT_FROM_EVENT_ORDERS_FAILURE,
  GET_SITE_SETTINGS_START,
  GET_SITE_SETTINGS_SUCCESS,
  GET_SITE_SETTINGS_FAILURE,
  CONNECT_TO_ORDERS_START,
  CONNECT_TO_ORDERS_SUCCESS,
  CONNECT_TO_ORDERS_FAILURE,
  DISCONNECT_FROM_ORDERS_START,
  DISCONNECT_FROM_ORDERS_SUCCESS,
  DISCONNECT_FROM_ORDERS_FAILURE,
  CONNECT_TO_CHEF_INFO_MEMBERSHIPS_SUCCESS,
  CONNECT_TO_CHEF_INFO_LOCATIONS_SUCCESS,
  CONNECT_TO_CHEF_INFO_MENU_ITEMS_SUCCESS,
  CONNECT_TO_CHEF_INFO_HOST_PHONE_SUCCESS,
  CONNECT_TO_CHEF_INFO_EMAIL_SUCCESS,
  CONNECT_TO_CHEF_INFO_INSTA_SUCCESS,
  CONNECT_TO_CHEF_INFO_PERMISSIONS_START,
  CONNECT_TO_CHEF_INFO_PERMISSIONS_SUCCESS,
  CONNECT_TO_CHEF_INFO_PERMISSIONS_FAILURE,
  CONNECT_TO_CHEF_INFO_SITE_SETTINGS_START,
  CONNECT_TO_CHEF_INFO_SITE_SETTINGS_SUCCESS,
  CONNECT_TO_CHEF_INFO_SITE_SETTINGS_FAILURE,
  CONNECT_TO_CHEF_INFO_ROUTABLE_INFO_START,
  CONNECT_TO_CHEF_INFO_ROUTABLE_INFO_SUCCESS,
  CONNECT_TO_CHEF_INFO_ROUTABLE_INFO_FAILURE,
  CONNECT_TO_CHEF_INFO_CUSTOMER_SUBSCRIPTION_ID_SUCCESS,
  CONNECT_TO_CHEF_INFO_DISCOUNT_CODES_SUCCESS,
  DISCONNECT_FROM_CHEF_INFO_LOCATIONS_SUCCESS,
  DISCONNECT_FROM_CHEF_INFO_MENU_ITEMS_SUCCESS,
  DISCONNECT_FROM_CHEF_INFO_SITE_SETTINGS_SUCCESS,
  DISCONNECT_FROM_CHEF_INFO_ROUTABLE_INFO_SUCCESS,
  DISCONNECT_FROM_CHEF_INFO_CUSTOMER_SUBSCRIPTION_ID_SUCCESS,
  DISCONNECT_FROM_CHEF_INFO_DISCOUNT_CODES_SUCCESS,
  DISCONNECT_FROM_CHEF_INFO_EMAIL_SUCCESS,
  DISCONNECT_FROM_CHEF_INFO_INSTA_HANDLE_SUCCESS,
  DISCONNECT_FROM_CHEF_INFO_PERMISSIONS_SUCCESS,
  DISCONNECT_FROM_CHEF_INFO_HOST_PHONE_SUCCESS,
  DISCONNECT_FROM_CHEF_INFO_MEMBERSHIPS_SUCCESS,
  CONNECT_TO_CHEF_SUBSCRIPTIONS_START,
  CONNECT_TO_CHEF_SUBSCRIPTIONS_SUCCESS,
  CONNECT_TO_CHEF_SUBSCRIPTIONS_FAILURE,
  DISCONNECT_FROM_CHEF_SUBSCRIPTIONS_START,
  DISCONNECT_FROM_CHEF_SUBSCRIPTIONS_SUCCESS,
  DISCONNECT_FROM_CHEF_SUBSCRIPTIONS_FAILURE,
  CONNECT_TO_CUSTOMER_SUBSCRIPTIONS_START,
  CONNECT_TO_CUSTOMER_SUBSCRIPTIONS_SUCCESS,
  CONNECT_TO_CUSTOMER_SUBSCRIPTIONS_FAILURE,
  DISCONNECT_FROM_CUSTOMER_SUBSCRIPTIONS_START,
  DISCONNECT_FROM_CUSTOMER_SUBSCRIPTIONS_SUCCESS,
  DISCONNECT_FROM_CUSTOMER_SUBSCRIPTIONS_FAILURE,
  CONNECT_TO_CHEF_SITE_SETTINGS_START,
  CONNECT_TO_CHEF_SITE_SETTINGS_SUCCESS,
  CONNECT_TO_CHEF_SITE_SETTINGS_FAILURE,
  DISCONNECT_FROM_CHEF_SITE_SETTINGS_START,
  DISCONNECT_FROM_CHEF_SITE_SETTINGS_SUCCESS,
  DISCONNECT_FROM_CHEF_SITE_SETTINGS_FAILURE,
  CONNECT_TO_CUSTOMER_CHEF_ORDERS_START,
  CONNECT_TO_CUSTOMER_CHEF_ORDERS_SUCCESS,
  CONNECT_TO_CUSTOMER_CHEF_ORDERS_FAILURE,
  DISCONNECT_FROM_CUSTOMER_CHEF_ORDERS_START,
  DISCONNECT_FROM_CUSTOMER_CHEF_ORDERS_SUCCESS,
  DISCONNECT_FROM_CUSTOMER_CHEF_ORDERS_FAILURE,
  CONNECT_TO_ITEMIZED_RECEIPT_ORDER_START,
  CONNECT_TO_ITEMIZED_RECEIPT_ORDER_SUCCESS,
  CONNECT_TO_ITEMIZED_RECEIPT_ORDER_FAILURE,
  DISCONNECT_FROM_ITEMIZED_RECEIPT_ORDER_START,
  DISCONNECT_FROM_ITEMIZED_RECEIPT_ORDER_SUCCESS,
  DISCONNECT_FROM_ITEMIZED_RECEIPT_ORDER_FAILURE,
  CONNECT_TO_SUBSCRIPTION_ORDERS_START,
  CONNECT_TO_SUBSCRIPTION_ORDERS_SUCCESS,
  CONNECT_TO_SUBSCRIPTION_ORDERS_FAILURE,
  DISCONNECT_FROM_SUBSCRIPTION_ORDERS_START,
  DISCONNECT_FROM_SUBSCRIPTION_ORDERS_SUCCESS,
  DISCONNECT_FROM_SUBSCRIPTION_ORDERS_FAILURE,
  CONNECT_TO_CUSTOMER_GLOBALS_START,
  CONNECT_TO_CUSTOMER_GLOBALS_FIRST_NAME_SUCCESS,
  CONNECT_TO_CUSTOMER_GLOBALS_LAST_NAME_SUCCESS,
  CONNECT_TO_CUSTOMER_GLOBALS_NAME_SUCCESS,
  CONNECT_TO_CUSTOMER_GLOBALS_EMAIL_SUCCESS,
  CONNECT_TO_CUSTOMER_GLOBALS_CUSTOMER_ADDRESS_DICT_SUCCESS,
  CONNECT_TO_CUSTOMER_GLOBALS_PAYMENT_METHOD_SUCCESS,
  CONNECT_TO_CUSTOMER_GLOBALS_SUCCESS,
  CONNECT_TO_CUSTOMER_GLOBALS_FAILURE,
  DISCONNECT_FROM_CUSTOMER_GLOBALS_START,
  DISCONNECT_FROM_CUSTOMER_GLOBALS_SUCCESS,
  DISCONNECT_FROM_CUSTOMER_GLOBALS_FAILURE,
  DISCONNECT_FROM_CUSTOMER_GLOBALS_FIRST_NAME_SUCCESS,
  DISCONNECT_FROM_CUSTOMER_GLOBALS_LAST_NAME_SUCCESS,
  DISCONNECT_FROM_CUSTOMER_GLOBALS_NAME_SUCCESS,
  DISCONNECT_FROM_CUSTOMER_GLOBALS_EMAIL_SUCCESS,
  DISCONNECT_FROM_CUSTOMER_GLOBALS_CUSTOMER_ADDRESS_DICT_SUCCESS,
  DISCONNECT_FROM_CUSTOMER_GLOBALS_PAYMENT_METHOD_SUCCESS,
  CONNECT_TO_MEMBERSHIP_START,
  CONNECT_TO_MEMBERSHIP_SUCCESS,
  CONNECT_TO_MEMBERSHIP_FAILURE,
  DISCONNECT_FROM_MEMBERSHIP_START,
  DISCONNECT_FROM_MEMBERSHIP_SUCCESS,
  DISCONNECT_FROM_MEMBERSHIP_FAILURE,
  GET_TRANSACTION_ORDERS_START,
  GET_TRANSACTION_ORDERS_SUCCESS,
  GET_TRANSACTION_ORDERS_FAILURE,
  CONNECT_TO_PORTAL_EVENTS_START,
  CONNECT_TO_PORTAL_EVENTS_SUCCESS,
  CONNECT_TO_PORTAL_EVENTS_FAILURE,
  DISCONNECT_FROM_PORTAL_EVENTS_START,
  DISCONNECT_FROM_PORTAL_EVENTS_SUCCESS,
  DISCONNECT_FROM_PORTAL_EVENTS_FAILURE,
  FIREBASE_CONFIG,
} from "./hotplate-storefront/actions/types";
import { sleep } from "@hotplate/utils-ts/helperFunctions";
import { logger } from "./logger";
/*
  To use in a component you need to:
  import FirebaseContext
  static contextType = FirebaseContext;
  and then access hotSockets via this.context
  for example to use connectToChefInfo you would write 'this.context.api.connectToChefInfo()'
 */

const FirebaseContext = createContext(null);
export { FirebaseContext };

const FirebaseProvider = ({ children }) => {
  const [hotSockets, setHotsockets] = useState(() => {
    const app = firebase.app();

    return {
      app: app,
      database: app.database(),

      reset: async () => {
        logger.debug("Resetting Firebase app");
        await firebase.app().delete();
        const app = firebase.initializeApp(FIREBASE_CONFIG);
        hotSockets.app = app; // BAD: MUTATE initial version of hotSockets
        hotSockets.database = app.database(); // BAD: MUTATE initial version of hotSockets
        setHotsockets({
          // these changes will cause useEffects throughout the app to run again
          ...hotSockets,
          api: { ...hotSockets.api },
          cloudFunctions: { ...hotSockets.cloudFunctions },
        });
      },

      api: {
        connectToChefInfo,
        getSiteSettings,
        getGodModeOptions,
        uploadComponentState,
        getSavedComponentState,
        getSavedGlobalState,
        disconnectFromChefInfo,
        connectToOrders,
        disconnectFromOrders,
        connectToEventOrders,
        disconnectFromEventOrders,
        connectToChefSubscriptions,
        disconnectFromChefSubscriptions,
        connectToCustomerSubscriptions,
        disconnectFromCustomerSubscriptions,
        connectToChefSiteSettings,
        disconnectFromChefSiteSettings,
        connectToCustomerChefOrders,
        disconnectFromCustomerChefOrders,
        connectToItemizedReceiptOrder,
        disconnectFromItemizedReceiptOrder,
        connectToSubscriptionOrders,
        disconnectFromSubscriptionOrders,
        getChangelogs,
        connectToCustomerGlobals,
        disconnectFromCustomerGlobals,
        connectToMembership,
        disconnectFromMembership,
        connectToPortalEvents,
        disconnectFromPortalEvents,
      },

      cloudFunctions: {
        getChefEvents: getChefEvents,
        getTransactionOrders: getTransactionOrders,
        getTransactionOrdersForExport: getTransactionOrdersForExport,
        updateInsightsCache: updateInsightsCache,
        getWithdrawalAmount: getWithdrawalAmount,
      },
    };
  });

  const dispatch = useDispatch();

  // -----------------------CLOUD FUNCTIONS------------------------- //
  // ---------------------------------------------------------------- //

  async function getChefEvents(chefId) {
    const getChefEventsCloud = firebase.functions().httpsCallable("getChefEventsCloud");

    let result;
    for (let i = 0; i < 3; i++) {
      try {
        result = await getChefEventsCloud({ chefId: chefId });
        break;
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        if (i === 2) {
          throw e;
        }
        await sleep(1.5 ** i * 1000);
      }
    }
    if (!result.data) {
      throw new Error("No data returned from getChefEventsCloud");
    }
    return result.data;
  }

  function getTransactionOrders(payload) {
    const getTransactionOrdersCloud = firebase
      .functions()
      .httpsCallable("getTransactionOrdersCloud", { timeout: 300_000 });
    dispatch({ type: GET_TRANSACTION_ORDERS_START });
    try {
      return getTransactionOrdersCloud(payload)
        .then((result) => {
          if (result.error || result.data.error) {
            console.log(result.error || result.data.error);
            dispatch({
              type: GET_TRANSACTION_ORDERS_FAILURE,
              payload: result.error || result.data.error,
            });
            return;
          }
          dispatch({
            type: GET_TRANSACTION_ORDERS_SUCCESS,
            payload: result.data,
          });
        })
        .catch((exception) => {
          console.log(exception);
          dispatch({
            type: GET_TRANSACTION_ORDERS_FAILURE,
            payload: GET_TRANSACTION_ORDERS_FAILURE,
          });
        });
    } catch (exception) {
      console.log(exception);
      dispatch({ type: GET_TRANSACTION_ORDERS_FAILURE, payload: exception });
    }
  }

  function getTransactionOrdersForExport(payload) {
    const getTransactionOrdersCloud = firebase
      .functions()
      .httpsCallable("getTransactionOrdersCloud", { timeout: 300_000 });
    return getTransactionOrdersCloud(payload);
  }

  function updateInsightsCache(payload) {
    const updateInsightsCacheCloud = firebase
      .functions()
      .httpsCallable("updateInsightsCacheCloud", { timeout: 300_000 });
    return updateInsightsCacheCloud(payload);
  }

  function getWithdrawalAmount(payload) {
    const getWithdrawalAmount = firebase
      .functions()
      .httpsCallable("getWithdrawalAmount", { timeout: 300_000 });
    return getWithdrawalAmount(payload);
  }
  // ---------------------------------------------------------------- //
  // ---------------------------------------------------------------- //

  function getGodModeOptions() {
    dispatch({ type: GET_GOD_MODE_OPTIONS_START });
    try {
      hotSockets.database.ref("/hostIds").once("value", (snapshot) => {
        const hostsDict = snapshot.val();
        const godModeOptions = [];
        Object.keys(hostsDict).forEach((key) => {
          godModeOptions.push(key);
        });
        dispatch({
          type: GET_GOD_MODE_OPTIONS_SUCCESS,
          payload: godModeOptions,
        });
      });
    } catch (error) {
      dispatch({
        type: GET_GOD_MODE_OPTIONS_FAILURE,
        payload: GET_GOD_MODE_OPTIONS_FAILURE,
      });
    }
  }

  function connectToCustomerChefOrders(chefId, phone) {
    if (!chefId || !phone) {
      console.log("WRONG PARAMS", chefId, phone);
      return;
    }
    try {
      dispatch({ type: CONNECT_TO_CUSTOMER_CHEF_ORDERS_START });
      const handler = (snapshot) => {
        const filteredOrders = {};
        const orders = snapshot.val() || {};
        for (const orderId in orders) {
          if (orderId.startsWith("pi_")) {
            // * filtering out subscription orders (e.g. orders that have not beenb placed yet)
            filteredOrders[orderId] = orders[orderId];
          }
        }
        dispatch({
          type: CONNECT_TO_CUSTOMER_CHEF_ORDERS_SUCCESS,
          payload: filteredOrders,
        });
      };
      hotSockets.database
        .ref(`/customersByPhone/${phone}/orders`)
        .orderByChild("chefId")
        .equalTo(chefId)
        .on("value", handler);
      return handler;
    } catch (error) {
      dispatch({
        type: CONNECT_TO_CUSTOMER_CHEF_ORDERS_FAILURE,
        payload: CONNECT_TO_CUSTOMER_CHEF_ORDERS_FAILURE,
      });
    }
  }

  function disconnectFromCustomerChefOrders(phone, handler) {
    if (!phone || !handler) {
      console.log("WRONG PARAMS", phone, handler);
      return;
    }
    dispatch({ type: DISCONNECT_FROM_CUSTOMER_CHEF_ORDERS_START });
    try {
      hotSockets.database.ref(`/customersByPhone/${phone}/orders`).off("value", handler);
      dispatch({
        type: DISCONNECT_FROM_CUSTOMER_CHEF_ORDERS_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: DISCONNECT_FROM_CUSTOMER_CHEF_ORDERS_FAILURE,
        payload: DISCONNECT_FROM_CUSTOMER_CHEF_ORDERS_FAILURE,
      });
    }
  }

  function connectToItemizedReceiptOrder(chefId, paymentIntentId) {
    if (!chefId || !paymentIntentId) {
      console.log("WRONG PARAMS", chefId, paymentIntentId);
      return;
    }
    dispatch({ type: CONNECT_TO_ITEMIZED_RECEIPT_ORDER_START });
    try {
      hotSockets.database
        .ref(`/hosts/${chefId}/orders/${paymentIntentId}`)
        .on("value", (snapshot) => {
          let order = {};
          if (snapshot && snapshot.val()) order = snapshot.val();
          dispatch({
            type: CONNECT_TO_ITEMIZED_RECEIPT_ORDER_SUCCESS,
            payload: order,
          });
        });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONNECT_TO_ITEMIZED_RECEIPT_ORDER_FAILURE,
        payload: CONNECT_TO_ITEMIZED_RECEIPT_ORDER_FAILURE,
      });
    }
  }

  function disconnectFromItemizedReceiptOrder(chefId, paymentIntentId) {
    if (!chefId || !paymentIntentId) {
      console.log("WRONG PARAMS", chefId, paymentIntentId);
      return;
    }
    dispatch({ type: DISCONNECT_FROM_ITEMIZED_RECEIPT_ORDER_START });
    try {
      hotSockets.database.ref(`/hosts/${chefId}/orders/${paymentIntentId}`).off("value");
      dispatch({ type: DISCONNECT_FROM_ITEMIZED_RECEIPT_ORDER_SUCCESS });
    } catch (error) {
      console.log(error);
      dispatch({
        type: DISCONNECT_FROM_ITEMIZED_RECEIPT_ORDER_FAILURE,
        payload: DISCONNECT_FROM_ITEMIZED_RECEIPT_ORDER_FAILURE,
      });
    }
  }

  function connectToOrders(chefId, range) {
    dispatch({ type: CONNECT_TO_ORDERS_START });
    try {
      const handler = _.throttle(
        (snapshot) => {
          let orders = {};
          if (snapshot && snapshot.val()) orders = snapshot.val();
          dispatch({
            type: CONNECT_TO_ORDERS_SUCCESS,
            payload: orders,
          });
        },
        1000,
        { leading: true, trailing: true }
      );
      hotSockets.database
        .ref(`/hosts/${chefId}/orders`)
        .orderByChild("timeSlot/startTime")
        .startAt(range?.min || new Date().getTime() - 1000 * 60 * 60 * 24 * 5)
        .endAt(range?.max || Number.MAX_SAFE_INTEGER)
        .on("value", handler);
      return handler;
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONNECT_TO_ORDERS_FAILURE,
        payload: CONNECT_TO_ORDERS_FAILURE,
      });
    }
  }

  function disconnectFromOrders(chefId, handler) {
    dispatch({ type: DISCONNECT_FROM_ORDERS_START });
    try {
      hotSockets.database.ref(`/hosts/${chefId}/orders`).off("value", handler);
      dispatch({ type: DISCONNECT_FROM_ORDERS_SUCCESS });
    } catch (error) {
      console.log(error);
      dispatch({
        type: DISCONNECT_FROM_ORDERS_FAILURE,
        payload: DISCONNECT_FROM_ORDERS_FAILURE,
      });
    }
  }

  function connectToEventOrders(chefId, eventId) {
    dispatch({ type: CONNECT_TO_EVENT_ORDERS_START });
    try {
      const onHandler = _.throttle(
        (snapshot) => {
          let orders = {};
          if (snapshot && snapshot.val()) orders = snapshot.val();
          dispatch({
            type: CONNECT_TO_EVENT_ORDERS_SUCCESS,
            payload: orders,
          });
        },
        1000,
        { leading: true, trailing: true }
      );
      hotSockets.database
        .ref(`/hosts/${chefId}/orders`)
        .orderByChild("eventId")
        .equalTo(eventId)
        .on("value", onHandler);
      return onHandler;
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONNECT_TO_EVENT_ORDERS_FAILURE,
        payload: CONNECT_TO_EVENT_ORDERS_FAILURE,
      });
    }
  }

  function disconnectFromEventOrders(chefId, listener) {
    dispatch({ type: DISCONNECT_FROM_EVENT_ORDERS_START });
    try {
      hotSockets.database.ref(`/hosts/${chefId}/orders`).off("value", listener);
      dispatch({ type: DISCONNECT_FROM_EVENT_ORDERS_SUCCESS });
    } catch (error) {
      console.log(error);
      dispatch({
        type: DISCONNECT_FROM_EVENT_ORDERS_FAILURE,
        payload: DISCONNECT_FROM_EVENT_ORDERS_FAILURE,
      });
    }
  }

  function connectToCustomerGlobals(phone) {
    dispatch({ type: CONNECT_TO_CUSTOMER_GLOBALS_START });
    try {
      hotSockets.database.ref(`/customersByPhone/${phone}/firstName`).on("value", (snapshot) => {
        if (snapshot && snapshot.val()) {
          dispatch({
            type: CONNECT_TO_CUSTOMER_GLOBALS_FIRST_NAME_SUCCESS,
            payload: snapshot.val(),
          });
        }
      });
      hotSockets.database.ref(`/customersByPhone/${phone}/lastName`).on("value", (snapshot) => {
        if (snapshot && snapshot.val()) {
          dispatch({
            type: CONNECT_TO_CUSTOMER_GLOBALS_LAST_NAME_SUCCESS,
            payload: snapshot.val(),
          });
        }
      });
      hotSockets.database.ref(`/customersByPhone/${phone}/name`).on("value", (snapshot) => {
        if (snapshot && snapshot.val()) {
          dispatch({
            type: CONNECT_TO_CUSTOMER_GLOBALS_NAME_SUCCESS,
            payload: snapshot.val(),
          });
        }
      });
      hotSockets.database.ref(`/customersByPhone/${phone}/email`).on("value", (snapshot) => {
        if (snapshot && snapshot.val()) {
          dispatch({
            type: CONNECT_TO_CUSTOMER_GLOBALS_EMAIL_SUCCESS,
            payload: snapshot.val(),
          });
        }
      });
      hotSockets.database
        .ref(`/customersByPhone/${phone}/customerAddressDict`)
        .on("value", (snapshot) => {
          if (snapshot && snapshot.val()) {
            dispatch({
              type: CONNECT_TO_CUSTOMER_GLOBALS_CUSTOMER_ADDRESS_DICT_SUCCESS,
              payload: snapshot.val(),
            });
          }
        });
      hotSockets.database
        .ref(`/customersByPhone/${phone}/paymentMethod`)
        .on("value", (snapshot) => {
          if (snapshot && snapshot.val()) {
            dispatch({
              type: CONNECT_TO_CUSTOMER_GLOBALS_PAYMENT_METHOD_SUCCESS,
              payload: snapshot.val(),
            });
          }
        });
      dispatch({ type: CONNECT_TO_CUSTOMER_GLOBALS_SUCCESS });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONNECT_TO_CUSTOMER_GLOBALS_FAILURE,
        payload: CONNECT_TO_CUSTOMER_GLOBALS_FAILURE,
      });
    }
  }

  function disconnectFromCustomerGlobals(phone) {
    dispatch({ type: DISCONNECT_FROM_CUSTOMER_GLOBALS_START });
    try {
      hotSockets.database.ref(`/customersByPhone/${phone}/firstName`).off("value");
      dispatch({ type: DISCONNECT_FROM_CUSTOMER_GLOBALS_FIRST_NAME_SUCCESS });
      hotSockets.database.ref(`/customersByPhone/${phone}/lastName`).off("value");
      dispatch({ type: DISCONNECT_FROM_CUSTOMER_GLOBALS_LAST_NAME_SUCCESS });
      hotSockets.database.ref(`/customersByPhone/${phone}/name`).off("value");
      dispatch({ type: DISCONNECT_FROM_CUSTOMER_GLOBALS_NAME_SUCCESS });
      hotSockets.database.ref(`/customersByPhone/${phone}/email`).off("value");
      dispatch({ type: DISCONNECT_FROM_CUSTOMER_GLOBALS_EMAIL_SUCCESS });
      hotSockets.database.ref(`/customersByPhone/${phone}/customerAddressDict`).off("value");
      dispatch({
        type: DISCONNECT_FROM_CUSTOMER_GLOBALS_CUSTOMER_ADDRESS_DICT_SUCCESS,
      });
      hotSockets.database.ref(`/customersByPhone/${phone}/paymentMethod`).off("value");
      dispatch({
        type: DISCONNECT_FROM_CUSTOMER_GLOBALS_PAYMENT_METHOD_SUCCESS,
      });
      dispatch({ type: DISCONNECT_FROM_CUSTOMER_GLOBALS_SUCCESS });
    } catch (error) {
      console.log(error);
      dispatch({
        type: DISCONNECT_FROM_CUSTOMER_GLOBALS_FAILURE,
        payload: DISCONNECT_FROM_CUSTOMER_GLOBALS_FAILURE,
      });
    }
  }

  function connectToChefSiteSettings(chefId) {
    dispatch({ type: CONNECT_TO_CHEF_SITE_SETTINGS_START });
    try {
      hotSockets.database.ref(`/hosts/${chefId}/siteSettings`).on("value", (snapshot) => {
        let siteSettings = {};
        if (snapshot && snapshot.val()) siteSettings = snapshot.val();
        dispatch({
          type: CONNECT_TO_CHEF_SITE_SETTINGS_SUCCESS,
          payload: siteSettings,
        });
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONNECT_TO_CHEF_SITE_SETTINGS_FAILURE,
        payload: CONNECT_TO_CHEF_SITE_SETTINGS_FAILURE,
      });
    }
  }

  function disconnectFromChefSiteSettings(chefId) {
    dispatch({ type: DISCONNECT_FROM_CHEF_SITE_SETTINGS_START });
    try {
      hotSockets.database.ref(`/hosts/${chefId}/siteSettings`).off("value");
      dispatch({ type: DISCONNECT_FROM_CHEF_SITE_SETTINGS_SUCCESS });
    } catch (error) {
      console.log(error);
      dispatch({
        type: DISCONNECT_FROM_CHEF_SITE_SETTINGS_FAILURE,
        payload: DISCONNECT_FROM_CHEF_SITE_SETTINGS_FAILURE,
      });
    }
  }

  function connectToMembership(chefId, phone) {
    dispatch({ type: CONNECT_TO_MEMBERSHIP_START });
    try {
      hotSockets.database.ref(`/hosts/${chefId}/memberships/${phone}`).on("value", (snapshot) => {
        if (snapshot && snapshot.val()) {
          dispatch({
            type: CONNECT_TO_MEMBERSHIP_SUCCESS,
            payload: snapshot.val(),
          });
        }
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONNECT_TO_MEMBERSHIP_FAILURE,
        payload: CONNECT_TO_MEMBERSHIP_FAILURE,
      });
    }
  }

  function disconnectFromMembership(chefId, phone) {
    dispatch({ type: DISCONNECT_FROM_MEMBERSHIP_START });
    try {
      hotSockets.database.ref(`/hosts/${chefId}/memberships/${phone}`).off("value");
      dispatch({ type: DISCONNECT_FROM_MEMBERSHIP_SUCCESS });
    } catch (error) {
      console.log(error);
      dispatch({
        type: DISCONNECT_FROM_MEMBERSHIP_FAILURE,
        payload: DISCONNECT_FROM_MEMBERSHIP_FAILURE,
      });
    }
  }

  function connectToChefSubscriptions(chefId) {
    dispatch({ type: CONNECT_TO_CHEF_SUBSCRIPTIONS_START });
    try {
      hotSockets.database.ref(`/hosts/${chefId}/subscriptions`).on("value", (snapshot) => {
        let subscriptions = {};
        if (snapshot && snapshot.val()) subscriptions = snapshot.val();
        dispatch({
          type: CONNECT_TO_CHEF_SUBSCRIPTIONS_SUCCESS,
          payload: subscriptions,
        });
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONNECT_TO_CHEF_SUBSCRIPTIONS_FAILURE,
        payload: CONNECT_TO_CHEF_SUBSCRIPTIONS_FAILURE,
      });
    }
  }

  function disconnectFromChefSubscriptions(chefId) {
    dispatch({ type: DISCONNECT_FROM_CHEF_SUBSCRIPTIONS_START });
    try {
      hotSockets.database.ref(`/hosts/${chefId}/subscriptions`).off("value");
      dispatch({ type: DISCONNECT_FROM_CHEF_SUBSCRIPTIONS_SUCCESS });
    } catch (error) {
      console.log(error);
      dispatch({
        type: DISCONNECT_FROM_CHEF_SUBSCRIPTIONS_FAILURE,
        payload: DISCONNECT_FROM_CHEF_SUBSCRIPTIONS_FAILURE,
      });
    }
  }

  function connectToCustomerSubscriptions(phone) {
    dispatch({ type: CONNECT_TO_CUSTOMER_SUBSCRIPTIONS_START });
    try {
      hotSockets.database
        .ref(`/customersByPhone/${phone}/subscriptions`)
        .on("value", (snapshot) => {
          let subscriptions = {};
          if (snapshot && snapshot.val()) subscriptions = snapshot.val();
          dispatch({
            type: CONNECT_TO_CUSTOMER_SUBSCRIPTIONS_SUCCESS,
            payload: subscriptions,
          });
        });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONNECT_TO_CUSTOMER_SUBSCRIPTIONS_FAILURE,
        payload: CONNECT_TO_CUSTOMER_SUBSCRIPTIONS_FAILURE,
      });
    }
  }

  function disconnectFromCustomerSubscriptions(phone) {
    dispatch({ type: DISCONNECT_FROM_CUSTOMER_SUBSCRIPTIONS_START });
    try {
      hotSockets.database.ref(`/customersByPhone/${phone}/subscriptions`).off("value");
      dispatch({ type: DISCONNECT_FROM_CUSTOMER_SUBSCRIPTIONS_SUCCESS });
    } catch (error) {
      console.log(error);
      dispatch({
        type: DISCONNECT_FROM_CUSTOMER_SUBSCRIPTIONS_FAILURE,
        payload: DISCONNECT_FROM_CUSTOMER_SUBSCRIPTIONS_FAILURE,
      });
    }
  }

  function connectToSubscriptionOrders(phone, chefId) {
    dispatch({ type: CONNECT_TO_SUBSCRIPTION_ORDERS_START });
    try {
      hotSockets.database
        .ref(`/customersByPhone/${phone}/orders`)
        .orderByChild("isSubscription")
        .equalTo(true)
        .on("value", (snapshot) => {
          let orders = {};
          if (snapshot && snapshot.val()) {
            const data = snapshot.val();
            Object.keys(data).forEach((key) => {
              const order = data[key];
              if (order.chefId === chefId) orders[key] = order;
            });
            orders = snapshot.val();
          }

          dispatch({
            type: CONNECT_TO_SUBSCRIPTION_ORDERS_SUCCESS,
            payload: orders,
          });
        });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONNECT_TO_SUBSCRIPTION_ORDERS_FAILURE,
        payload: error,
      });
    }
  }

  function disconnectFromSubscriptionOrders(phone) {
    dispatch({ type: DISCONNECT_FROM_SUBSCRIPTION_ORDERS_START });
    try {
      hotSockets.database.ref(`/customersByPhone/${phone}/orders`).off("value");
      dispatch({ type: DISCONNECT_FROM_SUBSCRIPTION_ORDERS_SUCCESS });
    } catch (error) {
      console.log(error);
      dispatch({
        type: DISCONNECT_FROM_SUBSCRIPTION_ORDERS_FAILURE,
        payload: DISCONNECT_FROM_SUBSCRIPTION_ORDERS_FAILURE,
      });
    }
  }

  function connectToPortalEvents(hostId) {
    dispatch({ type: CONNECT_TO_PORTAL_EVENTS_START });
    try {
      hotSockets.database.ref(`/hosts/${hostId}/events`).on(
        "value",
        _.throttle(
          (snapshot) => {
            if (snapshot) {
              dispatch({
                type: CONNECT_TO_PORTAL_EVENTS_SUCCESS,
                payload: snapshot.val(),
              });
            }
          },
          1000,
          { leading: true, trailing: true }
        )
      );
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONNECT_TO_PORTAL_EVENTS_FAILURE,
        payload: CONNECT_TO_PORTAL_EVENTS_FAILURE,
      });
    }
  }

  function disconnectFromPortalEvents(hostId) {
    dispatch({ type: DISCONNECT_FROM_PORTAL_EVENTS_START });
    try {
      hotSockets.database.ref(`/hosts/${hostId}/events`).off("value");
      dispatch({ type: DISCONNECT_FROM_PORTAL_EVENTS_SUCCESS });
    } catch (error) {
      console.log(error);
      dispatch({
        type: DISCONNECT_FROM_PORTAL_EVENTS_FAILURE,
        payload: DISCONNECT_FROM_PORTAL_EVENTS_FAILURE,
      });
    }
  }

  function connectToChefInfo(hostId) {
    dispatch({ type: CONNECT_TO_CHEF_INFO_START });
    try {
      if (hostId === "dumplingclub") {
        hotSockets.database.ref(`/hosts/${hostId}/memberships`).on("value", (snapshot) => {
          if (snapshot) {
            dispatch({
              type: CONNECT_TO_CHEF_INFO_MEMBERSHIPS_SUCCESS,
              payload: snapshot.val(),
            });
          }
        });
      }
      hotSockets.database.ref(`/hosts/${hostId}/locations`).on("value", (snapshot) => {
        if (snapshot) {
          dispatch({
            type: CONNECT_TO_CHEF_INFO_LOCATIONS_SUCCESS,
            payload: snapshot.val(),
          });
        }
      });
      hotSockets.database.ref(`/hosts/${hostId}/menuItems`).on("value", (snapshot) => {
        if (snapshot) {
          dispatch({
            type: CONNECT_TO_CHEF_INFO_MENU_ITEMS_SUCCESS,
            payload: snapshot.val(),
          });
        }
      });
      hotSockets.database.ref(`/hosts/${hostId}/hostPhone`).on("value", (snapshot) => {
        if (snapshot) {
          dispatch({
            type: CONNECT_TO_CHEF_INFO_HOST_PHONE_SUCCESS,
            payload: snapshot.val(),
          });
        }
      });
      hotSockets.database.ref(`/hosts/${hostId}/email`).on("value", (snapshot) => {
        if (snapshot) {
          dispatch({
            type: CONNECT_TO_CHEF_INFO_EMAIL_SUCCESS,
            payload: snapshot.val(),
          });
        }
      });
      hotSockets.database.ref(`/hosts/${hostId}/instaHandle`).on("value", (snapshot) => {
        if (snapshot) {
          dispatch({
            type: CONNECT_TO_CHEF_INFO_INSTA_SUCCESS,
            payload: snapshot.val(),
          });
        }
      });
      dispatch({ type: CONNECT_TO_CHEF_INFO_PERMISSIONS_START });
      hotSockets.database.ref(`/hosts/${hostId}/permissions`).on(
        "value",
        (snapshot) => {
          if (snapshot) {
            dispatch({
              type: CONNECT_TO_CHEF_INFO_PERMISSIONS_SUCCESS,
              payload: snapshot.val(),
            });
          }
        },
        (error) => {
          dispatch({ type: CONNECT_TO_CHEF_INFO_PERMISSIONS_FAILURE, payload: error });
        }
      );
      dispatch({ type: CONNECT_TO_CHEF_INFO_SITE_SETTINGS_START });
      hotSockets.database.ref(`/hosts/${hostId}/siteSettings`).on(
        "value",
        (snapshot) => {
          if (snapshot) {
            dispatch({
              type: CONNECT_TO_CHEF_INFO_SITE_SETTINGS_SUCCESS,
              payload: snapshot.val(),
            });
          }
        },
        (error) => {
          dispatch({ type: CONNECT_TO_CHEF_INFO_SITE_SETTINGS_FAILURE, payload: error });
        }
      );
      dispatch({ type: CONNECT_TO_CHEF_INFO_ROUTABLE_INFO_START });
      hotSockets.database.ref(`/hosts/${hostId}/routableInfo`).on(
        "value",
        (snapshot) => {
          dispatch({
            type: CONNECT_TO_CHEF_INFO_ROUTABLE_INFO_SUCCESS,
            payload: snapshot.val(),
          });
        },
        (error) => {
          dispatch({ type: CONNECT_TO_CHEF_INFO_ROUTABLE_INFO_FAILURE, payload: error });
        }
      );
      hotSockets.database.ref(`/hosts/${hostId}/customerSubscriptionId`).on("value", (snapshot) => {
        if (snapshot) {
          dispatch({
            type: CONNECT_TO_CHEF_INFO_CUSTOMER_SUBSCRIPTION_ID_SUCCESS,
            payload: snapshot.val(),
          });
        }
      });
      hotSockets.database.ref(`/hosts/${hostId}/discountCodes`).on("value", (snapshot) => {
        if (snapshot) {
          dispatch({
            type: CONNECT_TO_CHEF_INFO_DISCOUNT_CODES_SUCCESS,
            payload: snapshot.val(),
          });
        }
      });

      dispatch({ type: CONNECT_TO_CHEF_INFO_SUCCESS });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONNECT_TO_CHEF_INFO_FAILURE,
        payload: CONNECT_TO_CHEF_INFO_FAILURE,
      });
    }
  }

  function disconnectFromChefInfo(hostId) {
    dispatch({ type: DISCONNECT_FROM_CHEF_INFO_START });
    try {
      if (hostId === "dumplingclub") {
        hotSockets.database.ref(`/hosts/${hostId}/memberships`).off("value");
        dispatch({ type: DISCONNECT_FROM_CHEF_INFO_MEMBERSHIPS_SUCCESS });
      }
      hotSockets.database.ref(`/hosts/${hostId}/locations`).off("value");
      dispatch({ type: DISCONNECT_FROM_CHEF_INFO_LOCATIONS_SUCCESS });

      hotSockets.database.ref(`/hosts/${hostId}/menuItems`).off("value");
      dispatch({ type: DISCONNECT_FROM_CHEF_INFO_MENU_ITEMS_SUCCESS });

      hotSockets.database.ref(`/hosts/${hostId}/hostPhone`).off("value");
      dispatch({ type: DISCONNECT_FROM_CHEF_INFO_HOST_PHONE_SUCCESS });

      hotSockets.database.ref(`/hosts/${hostId}/email`).off("value");
      dispatch({ type: DISCONNECT_FROM_CHEF_INFO_EMAIL_SUCCESS });

      hotSockets.database.ref(`/hosts/${hostId}/instaHandle`).off("value");
      dispatch({ type: DISCONNECT_FROM_CHEF_INFO_INSTA_HANDLE_SUCCESS });

      hotSockets.database.ref(`/hosts/${hostId}/permissions`).off("value");
      dispatch({ type: DISCONNECT_FROM_CHEF_INFO_PERMISSIONS_SUCCESS });

      hotSockets.database.ref(`/hosts/${hostId}/siteSettings`).off("value");
      dispatch({ type: DISCONNECT_FROM_CHEF_INFO_SITE_SETTINGS_SUCCESS });

      hotSockets.database.ref(`/hosts/${hostId}/routableInfo`).off("value");
      dispatch({ type: DISCONNECT_FROM_CHEF_INFO_ROUTABLE_INFO_SUCCESS });

      hotSockets.database.ref(`/hosts/${hostId}/customerSubscriptionId`).off("value");
      dispatch({
        type: DISCONNECT_FROM_CHEF_INFO_CUSTOMER_SUBSCRIPTION_ID_SUCCESS,
      });

      hotSockets.database.ref(`/hosts/${hostId}/discountCodes`).off("value");
      dispatch({
        type: DISCONNECT_FROM_CHEF_INFO_DISCOUNT_CODES_SUCCESS,
      });
      dispatch({ type: DISCONNECT_FROM_CHEF_INFO_SUCCESS });
    } catch (error) {
      console.log(error);
      dispatch({
        type: DISCONNECT_FROM_CHEF_INFO_FAILURE,
        payload: DISCONNECT_FROM_CHEF_INFO_FAILURE,
      });
    }
  }

  async function getSiteSettings(chefId) {
    dispatch({ type: GET_SITE_SETTINGS_START });
    let siteSettings;
    for (let i = 0; i < 3; i++) {
      try {
        siteSettings = (await hotSockets.database.ref(`/hosts/${chefId}/siteSettings`).get()).val();
        break;
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);

        if (i === 2) {
          dispatch({
            type: GET_SITE_SETTINGS_FAILURE,
            payload: GET_SITE_SETTINGS_FAILURE,
          });
        }
      }
    }
    dispatch({
      type: GET_SITE_SETTINGS_SUCCESS,
      payload: siteSettings,
    });
    return siteSettings;
  }

  function encodeData(data) {
    const d = _.cloneDeep(data);
    if (Array.isArray(d)) {
      for (let i = 0; i < d.length; i++) {
        if (d[i] === null) {
          d[i] = "39jr3nf9034fvdda";
        } else if (d[i] === undefined) {
          d[i] = "349rnbdfnf9134j94";
        } else if (Array.isArray(d[i]) && d[i].length === 0) {
          d[i] = "598jg8tng2-03i4-23";
        } else if (d[i].constructor === Object || Array.isArray(d[i])) d[i] = encodeData(d[i]);
      }
    } else if (d.constructor === Object) {
      if (Object.keys(d).length === 0) return "23490vjnj0v8adfvan";
      Object.keys(d).forEach((key) => {
        if (d[key] === null) {
          d[key] = "39jr3nf9034fvdda";
        } else if (d[key] === undefined) {
          d[key] = "349rnbdfnf9134j94";
        } else if (Array.isArray(d[key]) && d[key].length === 0) {
          d[key] = "598jg8tng2-03i4-23";
        } else if (d[key].constructor === Object || Array.isArray(d[key]))
          d[key] = encodeData(d[key]);
      });
    }
    return d;
  }

  function decodeData(data) {
    let d = _.cloneDeep(data);

    // convert to array if needed cuz firebase is dumb af
    if (d && d.constructor === Object) {
      const potentialArr = [];
      for (let i = 0; i < Object.keys(d).length; i++) {
        if (!(i in d)) break;
        potentialArr.push(d[i]);
        if (i === Object.keys(d).length - 1) {
          // ACTUALLY A FUCKING ARRAY
          d = potentialArr;
        }
      }
    }

    if (d === "23490vjnj0v8adfvan") return {};

    if (Array.isArray(d)) {
      for (let i = 0; i < d.length; i++) {
        if (d[i] === "39jr3nf9034fvdda") {
          d[i] = null;
        } else if (d[i] === "349rnbdfnf9134j94") {
          d[i] = undefined;
        } else if (d[i] === "598jg8tng2-03i4-23") {
          d[i] = [];
        } else if (d[i].constructor === Object || Array.isArray(d[i])) d[i] = decodeData(d[i]);
      }
    } else if (d && d.constructor === Object) {
      Object.keys(d).forEach((key) => {
        if (d[key] === "39jr3nf9034fvdda") {
          d[key] = null;
        } else if (d[key] === "349rnbdfnf9134j94") {
          d[key] = undefined;
        } else if (d[key] === "598jg8tng2-03i4-23") {
          d[key] = [];
        } else if (d[key].constructor === Object || Array.isArray(d[key]))
          d[key] = decodeData(d[key]);
      });
    }
    return d;
  }

  function uploadComponentState(urlName, componentId, state) {
    if (!urlName || !componentId) return;
    dispatch({ type: UPLOAD_COMPONENT_STATE_START });
    try {
      const saveStateDict = {};
      saveStateDict[componentId] = state;
      hotSockets.database.ref(`/savedStates/${urlName}`).update(encodeData(saveStateDict));
      dispatch({ type: UPLOAD_COMPONENT_STATE_SUCCESS });
    } catch (error) {
      console.log(error);
      dispatch({ type: UPLOAD_COMPONENT_STATE_FAILURE });
    }
  }

  function getSavedComponentState(urlName, componentId) {
    if (!urlName || !componentId) return {};
    try {
      return hotSockets.database
        .ref(`/savedStates/${urlName}/${componentId}`)
        .once("value")
        .then((snapshot) => {
          return decodeData(snapshot.val());
        });
    } catch (error) {
      console.log(error);
      return {};
    }
  }

  function getSavedGlobalState(urlName) {
    if (!urlName) return {};
    try {
      return hotSockets.database
        .ref(`/savedStates/${urlName}/global`)
        .once("value")
        .then((snapshot) => {
          return decodeData(snapshot.val());
        });
    } catch (error) {
      console.log(error);
      return {};
    }
  }

  async function getChangelogs() {
    try {
      return (await hotSockets.database.ref(`/changelogs/data`).get()).val();
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  return <FirebaseContext.Provider value={hotSockets}>{children}</FirebaseContext.Provider>;
};

export default FirebaseProvider;
