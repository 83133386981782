// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./NavButton.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      "66ZCaTZyEH": {
        none: {
          text: "Events",
        },
      },
      RHjjaJ3sT1: {
        none: {
          icon: icons.arrowLeft,
          useMask: true,
        },
        hover: {
          useMask: true,
        },
        pressed: {
          useMask: true,
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const NavButtonContext = createContext(null);

function NavButton(_props) {
  const defaults = useContext(NavButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="KzaEj9caNL"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "KzaEj9caNL",
        scope: "PdP55NZbnq",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <IconPrimitive
            id={"Icon_RHjjaJ3sT1"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PdP55NZbnq_RHjjaJ3sT1"
            }
            key={"RHjjaJ3sT1"}
            useMask={getStyle("RHjjaJ3sT1", "useMask")}
            src={
              exists(props.icon) ? props.icon : getStyle("RHjjaJ3sT1", "icon")
            }
          />
          <SpacerPrimitive
            id={"Spacer_4NMauMTSkm"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PdP55NZbnq_4NMauMTSkm"
            }
            key={"4NMauMTSkm"}
          />
          <TextPrimitive
            id={"Text_66ZCaTZyEH"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PdP55NZbnq_66ZCaTZyEH"
            }
            key={"66ZCaTZyEH"}
            text={
              exists(props.text) ? props.text : getStyle("66ZCaTZyEH", "text")
            }
            element={getStyle("66ZCaTZyEH", "htmlElement")}
          />
        </>
      )}
    </RootPrimitive>
  );
}

NavButton.__variants = [];

export default NavButton;
