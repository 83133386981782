import { styled } from "../../stitches.config";

export const Flex = styled("div", {
  display: "flex",
});

export const Grid = styled("div", {
  display: "grid",
});

export const CenterX = styled("div", {
  display: "flex",
  justifyContent: "center",
  width: "100%",
});

export const CenterY = styled("div", {
  my: "auto",
  height: "100%",
});

export const Center = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const Column = styled("div", {
  display: "flex",
  flexDirection: "column",
});

export const Row = styled("div", {
  display: "flex",
  flexDirection: "row",
});

export const Box = styled("div", {});

export const P = styled("p", {});

export const Span = styled("span", {});

export const A = styled("a", {});

export const Section = styled("section", {});

export const H1 = styled("h1", {});

export const H2 = styled("h2", {});

export const H3 = styled("h3", {});

export const Img = styled("img", {});

// Tables

export const Table = styled("table", {});

export const TableHeader = styled("thead", {});

export const TableBody = styled("tbody", {});

export const TableRow = styled("tr", {});

export const TableCell = styled("td", {});

export const TableHeaderCell = styled("th", {});
