import React from "react";
import { styled } from "../../stitches.config";
import ReactLoading from "react-loading";
import { Cross2Icon } from "@radix-ui/react-icons";

const LoadingBox = styled("div", {
  display: "flex",
  justifyContent: "center",
  borderRadius: "inherit",
  alignItems: "center",
  position: "absolute",
  top: 0,
  right: 12,
  bottom: 0,
  backgroundColor: "inherit",
  color: "inherit",
});

export const Loading = () => {
  return (
    <LoadingBox className="btn-loading">
      <ReactLoading type={"spin"} color="inherit" height={20} width={20} />
    </LoadingBox>
  );
};

Loading.toString = () => ".btn-loading";

const ContentFlex = styled("div", {
  display: "flex",
  height: "100%",
  jc: "center",
  ai: "center",
  mx: "$space$xs",
  flexShrink: 0,
});

export const Count = styled("span", {
  display: "inline-flex",
  jc: "center",
  ai: "center",
  marginLeft: "5px",
  color: "$gray11",
  fs: "0.9em",
  fw: "$semi_bold",
  transition: "opacity 150ms ease-in-out",
});

export const inputStyles = {
  position: "relative",
  $$outlineColor: "$colors$accent9",
  $$color: "$colors$gray12",
  $$borderColor: "$colors$gray8",
  ff: "$avenir",
  fw: "$normal",
  color: "$$color",
  border: `1px solid $$borderColor`,
  br: "inherit",
  transition: "box-shadow 100ms ease-in-out, border 100ms ease-in-out",
  "&::placeholder": {
    color: "$gray9",
  },
  "&:focus-within": { boxShadow: `0 0 0 2px $$outlineColor`, border: "1px solid $$outlineColor" },
};

const InputFlex = styled("div", {
  all: "unset",

  alignItems: "center",
  minWidth: 0,
  display: "flex",
  padx: "10px",
  ...inputStyles,
  lineHeight: "$btn",
  [`& ${Count}`]: { opacity: 0 },
  "&:focus-within": {
    boxShadow: `0 0 0 1px $$outlineColor`,
    border: "1px solid $$outlineColor",
    [`& ${Count}`]: { opacity: 1 },
  },
  variants: {
    status: {
      default: {
        $$color: "$colors$gray12",
        backgroundColor: "transparent",
        $$borderColor: `$colors$gray8`,
        $$outlineColor: "$colors$accent9",
      },
      error: {
        $$color: "$colors$error11",
        $$borderColor: `$colors$error10`,
        backgroundColor: "$error1",
        $$outlineColor: "$colors$error9",
      },
      warning: {
        $$color: "$colors$warning11",
        $$borderColor: `$colors$warning10`,
        backgroundColor: "$warning1",
        $$outlineColor: "$colors$warning9",
      },
    },
    loading: {
      true: {
        [`& ${Loading}`]: {
          display: "flex",
        },
      },
    },
    size: {
      small: {
        borderRadius: 4,
      },
      medium: {
        minWidth: 225,
        borderRadius: 6,
      },
      large: {
        borderRadius: 8,
      },
    },
  },
  defaultVariants: {
    status: "default",
    size: "medium",
    loading: false,
  },
});

const InputPrimitive = styled("input", {
  all: "unset",
  height: "$$height",
  display: "flex",
  position: "relative",
  minWidth: 0,
  overflow: "hidden",
  textOverflow: "ellipsis",
  flex: 1,
  cursor: "text",
  variants: {
    showClear: {
      true: {
        "&[type=search]": {
          "&::-webkit-search-cancel-button": {
            //   webkitAppearance: "searchfield-cancel-button",
            color: "inherit",
            backgroundImage: `url(${Cross2Icon.toString()})`,
            cursor: "pointer",
          },
        },
      },
    },
    size: {
      small: {
        $$height: "25px",
        fs: 13,
        borderRadius: 4,
      },
      medium: {
        $$height: "42px",
        fs: 15,
        borderRadius: 4,
      },
      large: {
        $$height: "55px",
        fs: 16,
        borderRadius: 4,
      },
    },
  },
  defaultVariants: {
    showClear: false,
    size: "medium",
  },
});

function getCountColor(maxLength, currLength) {
  if (!maxLength || !currLength) return "$gray11";

  if (currLength === maxLength) return "$error11";
  if (maxLength - currLength <= 10) return "$warning11";
  return "$gray11";
}

export const Input = React.forwardRef(
  (
    {
      showClear,
      contentAfter,
      contentBefore,
      maxLength,
      showCount,
      placeholder,
      value,
      defaultValue,
      onChange,
      type,
      inputMode,
      loading,
      loadingAnimation,
      disabled,
      status,
      size,
      css,
      ...props
    },
    ref
  ) => {
    return (
      <InputFlex status={status} css={css} size={size}>
        {contentBefore && <ContentFlex css={{ marginLeft: 0 }}>{contentBefore}</ContentFlex>}
        <InputPrimitive
          ref={ref}
          loading={loading}
          disabled={disabled}
          size={size}
          value={value}
          placeholder={placeholder}
          maxLength={maxLength}
          results="0"
          onChange={onChange}
          inputMode={inputMode}
          showClear={showClear}
          type={type}
          defaultValue={defaultValue}
          {...props}
        />
        {showCount && (
          <Count css={maxLength && { color: getCountColor(maxLength || 999, value.length) }}>
            {maxLength ? maxLength - value.length || 0 : value.length || 0}
          </Count>
        )}
        {loading && (loadingAnimation ? loadingAnimation : <Loading />)}
        {contentAfter && <ContentFlex css={{ marginRight: 0 }}>{contentAfter}</ContentFlex>}
      </InputFlex>
    );
  }
);

Input.displayName = "Input";
