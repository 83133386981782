// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Summary.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import {
  default as RefundLineItem,
  default as RefundTotal,
  SummaryLineItemContext as RefundTotalContext,
} from "../Refund/SummaryLineItem";
import {
  default as ConfirmRefundButton,
  ButtonContext as ConfirmRefundButtonContext,
} from "../Primitives/Button";
import {
  default as RefundInput,
  InputContext as RefundInputContext,
} from "../Primitives/Input";
import { default as WarningBubble } from "../Primitives/WarningBubble";
import {
  default as KillReminders,
  RestockItemsContext as KillRemindersContext,
} from "../Refund/RestockItems";

const styles = [
  {
    type: "default",
    layers: {
      UAxsvJYnAf: {
        none: {
          text: "Max possible refund:",
        },
      },
      "7vqTPrYt5N": {
        none: {
          text: "$42.69",
        },
      },
      A5BxookVgg: {
        none: {
          text: "No items selected.",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "noItemsSelected",
    layers: {},
  },
  {
    type: "boolean",
    propName: "manualInputMismatch",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      Y2X9w8vew7: {},
      "5SNKFq5GqV": {
        none: {
          bold: true,
        },
      },
      WCrT41upoJ: {
        none: {
          text: "Refund $0.00",
          kind: "secondary",
          shadow: true,
        },
      },
      MYvujimhxS: {
        none: {
          placeholder: "0.00",
          label: "Refund Amount",
          unit: "USD",
          handleOnKeyDown: false,
          withUnit: "right",
        },
      },
      PRdZBpJtTx: {},
      YHF3FCVYg6: {
        none: {
          text: "Don't send any more reminders for this order",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "noItemsSelected",
    layers: {
      Y2X9w8vew7: {},
      "5SNKFq5GqV": {},
      WCrT41upoJ: {},
      MYvujimhxS: {},
      PRdZBpJtTx: {},
      YHF3FCVYg6: {},
    },
  },
  {
    type: "boolean",
    propName: "manualInputMismatch",
    layers: {
      Y2X9w8vew7: {},
      "5SNKFq5GqV": {},
      WCrT41upoJ: {},
      MYvujimhxS: {},
      PRdZBpJtTx: {},
      YHF3FCVYg6: {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "noItemsSelected",
  },
  {
    type: "boolean",
    propName: "manualInputMismatch",
  },
];

export const SummaryContext = createContext(null);

function Summary(_props) {
  const defaults = useContext(SummaryContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="TvLdTF9sdS"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "TvLdTF9sdS",
        scope: "Vdm4oe6xQg",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => [
        <TextPrimitive
          id={"Text_A5BxookVgg"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_A5BxookVgg"
          }
          key={"A5BxookVgg"}
          text={getStyle("A5BxookVgg", "text")}
          element={getStyle("A5BxookVgg", "htmlElement")}
        />,
        <ContainerPrimitive
          key={"XWjBregpVe"}
          id={"Slot_XWjBregpVe"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_XWjBregpVe"
          }
          addSpacing={false}
        >
          {props.children}
        </ContainerPrimitive>,
        props.RefundTotal === undefined ? (
          <RefundTotal
            key={"5SNKFq5GqV"}
            {...getCompositeDefaultProps("5SNKFq5GqV")}
            id="RefundTotal_5SNKFq5GqV"
            className="__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_5SNKFq5GqV"
          />
        ) : (
          <RefundTotalContext.Provider
            key="5SNKFq5GqV-provider"
            value={{
              key: "5SNKFq5GqV",
              id: "RefundTotal_5SNKFq5GqV",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_5SNKFq5GqV",
              ...getCompositeDefaultProps("5SNKFq5GqV"),
            }}
          >
            {props.RefundTotal}
          </RefundTotalContext.Provider>
        ),
        <ContainerPrimitive
          id={"refundamount-container_4vLkZbFtmb"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_4vLkZbFtmb"
          }
          key={"4vLkZbFtmb"}
          addSpacing={false}
          element={getStyle("4vLkZbFtmb", "htmlElement")}
        >
          {[
            <SpacerPrimitive
              id={"Spacer_PryekiDnZ9"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_PryekiDnZ9"
              }
              key={"PryekiDnZ9"}
            />,
            <ContainerPrimitive
              id={"divider_HZGoBMWxTV"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_HZGoBMWxTV"
              }
              key={"HZGoBMWxTV"}
              addSpacing={false}
              element={getStyle("HZGoBMWxTV", "htmlElement")}
            >
              {null}
            </ContainerPrimitive>,
            <SpacerPrimitive
              id={"Spacer_TBqtQAUp3D"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_TBqtQAUp3D"
              }
              key={"TBqtQAUp3D"}
            />,
            props.RefundInput === undefined ? (
              <RefundInput
                key={"MYvujimhxS"}
                {...getCompositeDefaultProps("MYvujimhxS")}
                id="RefundInput_MYvujimhxS"
                className="__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_MYvujimhxS"
              />
            ) : (
              <RefundInputContext.Provider
                key="MYvujimhxS-provider"
                value={{
                  key: "MYvujimhxS",
                  id: "RefundInput_MYvujimhxS",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_MYvujimhxS",
                  ...getCompositeDefaultProps("MYvujimhxS"),
                }}
              >
                {props.RefundInput}
              </RefundInputContext.Provider>
            ),
            <ContainerPrimitive
              id={"maxrefund-container_9BZwEqQWmA"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_9BZwEqQWmA"
              }
              key={"9BZwEqQWmA"}
              addSpacing={false}
              element={getStyle("9BZwEqQWmA", "htmlElement")}
            >
              <TextPrimitive
                id={"Text_UAxsvJYnAf"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_UAxsvJYnAf"
                }
                key={"UAxsvJYnAf"}
                text={getStyle("UAxsvJYnAf", "text")}
                element={getStyle("UAxsvJYnAf", "htmlElement")}
              />
              <SpacerPrimitive
                id={"Spacer_8LBiszzeNE"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_8LBiszzeNE"
                }
                key={"8LBiszzeNE"}
              />
              <TextPrimitive
                id={"maxRefundPossible_7vqTPrYt5N"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_7vqTPrYt5N"
                }
                key={"7vqTPrYt5N"}
                text={
                  exists(props.maxPossibleRefund)
                    ? props.maxPossibleRefund
                    : getStyle("7vqTPrYt5N", "text")
                }
                element={getStyle("7vqTPrYt5N", "htmlElement")}
              />
            </ContainerPrimitive>,
            <SpacerPrimitive
              id={"Spacer_UiGA3LwMoQ"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_UiGA3LwMoQ"
              }
              key={"UiGA3LwMoQ"}
            />,
            <ContainerPrimitive
              id={"mismatch-alert-container_B12Nmzt9VB"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_B12Nmzt9VB"
              }
              key={"B12Nmzt9VB"}
              addSpacing={false}
              element={getStyle("B12Nmzt9VB", "htmlElement")}
            >
              {
                <ContainerPrimitive
                  key={"6UEGZ5RsDQ"}
                  id={"Slot_6UEGZ5RsDQ"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_6UEGZ5RsDQ"
                  }
                  addSpacing={false}
                >
                  {props.warningBubble}
                </ContainerPrimitive>
              }
            </ContainerPrimitive>,
            props.KillReminders === undefined ? (
              <KillReminders
                key={"YHF3FCVYg6"}
                {...getCompositeDefaultProps("YHF3FCVYg6")}
                id="KillReminders_YHF3FCVYg6"
                className="__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_YHF3FCVYg6"
              />
            ) : (
              <KillRemindersContext.Provider
                key="YHF3FCVYg6-provider"
                value={{
                  key: "YHF3FCVYg6",
                  id: "KillReminders_YHF3FCVYg6",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_YHF3FCVYg6",
                  ...getCompositeDefaultProps("YHF3FCVYg6"),
                }}
              >
                {props.KillReminders}
              </KillRemindersContext.Provider>
            ),
            <SpacerPrimitive
              id={"Spacer_Sw1QWpQ3xT"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_Sw1QWpQ3xT"
              }
              key={"Sw1QWpQ3xT"}
            />,
            props.ConfirmRefundButton === undefined ? (
              <ConfirmRefundButton
                key={"WCrT41upoJ"}
                {...getCompositeDefaultProps("WCrT41upoJ")}
                id="ConfirmRefundButton_WCrT41upoJ"
                className="__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_WCrT41upoJ"
              />
            ) : (
              <ConfirmRefundButtonContext.Provider
                key="WCrT41upoJ-provider"
                value={{
                  key: "WCrT41upoJ",
                  id: "ConfirmRefundButton_WCrT41upoJ",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_Vdm4oe6xQg_WCrT41upoJ",
                  ...getCompositeDefaultProps("WCrT41upoJ"),
                }}
              >
                {props.ConfirmRefundButton}
              </ConfirmRefundButtonContext.Provider>
            ),
          ]}
        </ContainerPrimitive>,
      ]}
    </RootPrimitive>
  );
}

Summary.RefundLineItem = RefundLineItem;
Summary.RefundTotal = RefundTotal;
Summary.ConfirmRefundButton = ConfirmRefundButton;
Summary.RefundInput = RefundInput;
Summary.WarningBubble = WarningBubble;
Summary.KillReminders = KillReminders;
Summary.__variants = [
  {
    name: "noItemsSelected",
    type: "variant",
  },
  {
    name: "manualInputMismatch",
    type: "variant",
  },
];

export default Summary;
