// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./CustomerMenuItem.AvailableTimesContainer.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import { RootPrimitive, ContainerPrimitive } from "../../_internal_primitives";
import {
  default as ConfigHeader,
  ConfigHeaderContext,
} from "../../Events/ConfigHeader";

const styles = [
  {
    type: "default",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      VfU1Wbzkb4: {
        none: {
          title: "Availability of this item",
          subtitle:
            "Updated live, select your preferred pickup time at checkout.",
        },
      },
    },
  },
];

const variantPropTypes = [];

export const AvailableTimesContainerContext = createContext(null);

function AvailableTimesContainer(_props) {
  const defaults = useContext(AvailableTimesContainerContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="Da7DsHFE46"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "Da7DsHFE46",
        scope: "E31MNhjRYq",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"header-padding-container_6k8JjynEqG"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_E31MNhjRYq_6k8JjynEqG"
            }
            key={"6k8JjynEqG"}
            addSpacing={false}
            element={getStyle("6k8JjynEqG", "htmlElement")}
          >
            {props.ConfigHeader === undefined ? (
              <ConfigHeader
                key={"VfU1Wbzkb4"}
                {...getCompositeDefaultProps("VfU1Wbzkb4")}
                id="ConfigHeader_VfU1Wbzkb4"
                className="__visly_reset_7X7HTLRqyD __visly_scope_E31MNhjRYq_VfU1Wbzkb4"
              />
            ) : (
              <ConfigHeaderContext.Provider
                key="VfU1Wbzkb4-provider"
                value={{
                  key: "VfU1Wbzkb4",
                  id: "ConfigHeader_VfU1Wbzkb4",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_E31MNhjRYq_VfU1Wbzkb4",
                  ...getCompositeDefaultProps("VfU1Wbzkb4"),
                }}
              >
                {props.ConfigHeader}
              </ConfigHeaderContext.Provider>
            )}
          </ContainerPrimitive>
          <ContainerPrimitive
            key={"5HaGH3fBz2"}
            id={"Slot_5HaGH3fBz2"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_E31MNhjRYq_5HaGH3fBz2"
            }
            addSpacing={true}
          >
            {props.children}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

AvailableTimesContainer.ConfigHeader = ConfigHeader;
AvailableTimesContainer.__variants = [];

export default AvailableTimesContainer;
