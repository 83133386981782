/* eslint-disable react/prop-types */
import React, { useState, useReducer, useContext, useEffect } from "react";
import { v4 as uuid } from "uuid";
import { connect } from "react-redux";
import _ from "lodash";
import { Status } from "../../../visly/Events";

import {
  getEventStatus,
  isMenuEmpty,
  getSortedTimeWindows, // !which one?
  getSortedTimeSlots, // !which one?
} from "@hotplate/utils-ts/helperFunctions";

import { mergeDiff, removeNulls, useDetectDeletion, usePrevious } from "../../../hooks";
import AnimationContainer from "../../../hotplate-common/AnimationContainer";
import OptionSelectModal from "../../../hotplate-common/OptionSelectModal";

import {
  setToastPopupInfo,
  prepareQueueEventReminders,
} from "../../../hotplate-storefront/actions";

import AdvancedSettingsModal from "./info/AdvancedSettingsModal";
import EditOrderCutoffTypeModal from "./info/EditOrderCutoffTypeModal";
import AddEditTimeWindowModal from "./locationsAndTimes/AddEditTimeWindowModal";
import { Statistics } from "./statistics";
import InfoMaster from "./info";
import MenuMaster from "./menu";
import PublishMaster from "./publish";
import LocationsAndTimesMaster from "./locationsAndTimes";
import { FirebaseContext } from "../../../firebaseSocket";
import { styled } from "../../../stitches.config";
import { CenterX, Row } from "../../../hotplate-common/primitives/Containers";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { Tabs } from "../../../hotplate-common/primitives/Tabs";
import { IconButton } from "../../../hotplate-common/primitives/IconButton";
import { shouldRetry, trpc } from "../../../trpc";
import {
  trackEventConfigNavClicked,
  trackEventPublished,
  trackEventUnpublished,
  trackTimeWindowCreated,
  trackTimeWindowUpdated,
} from "./analytics";
import { usePortalUser } from "../../../auth";

function createNewTimeWindow() {
  return {
    id: uuid(),
    startTime: null,
    endTime: null,
    locations: null,
  };
}

const mapStateToProps = (state) => {
  return {
    orders: state.orderManagement.eventOrders,
    connectToEventOrdersLoading: state.orderManagement.connectToEventOrdersLoading,
    connectToEventOrdersError: state.orderManagement.connectToEventOrdersError,
    disconnectFromEventOrdersLoading: state.orderManagement.disconnectFromEventOrdersLoading,
    disconnectFromEventOrdersError: state.orderManagement.disconnectFromEventOrdersError,

    locations: state.hostPortal.hostInfo.locations,
    menuItems: state.hostPortal.hostInfo.menuItems,
    prepareQueueEventRemindersLoading: state.events.prepareQueueEventRemindersLoading,
    prepareQueueEventRemindersError: state.events.prepareQueueEventRemindersError,

    siteSettings: state.hostPortal.hostInfo.siteSettings,
  };
};

const ConfigContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  width: "100%",
  maxWidth: "$maxContentWidthSm",
});

const ConfigHeader = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  pady: "$md",
  padx: "$md",
  gap: "$md",
  ff: "$avenir",
  fs: "$lg",
  fw: "$bold",
  lineHeight: "$text",
  color: "$gray12",
});

export default connect(mapStateToProps, {
  setToastPopupInfo,
  prepareQueueEventReminders,
})(function EventConfigurationMaster({
  // from connect
  orders,
  connectToEventOrdersLoading,
  prepareQueueEventRemindersLoading,
  prepareQueueEventRemindersError,
  siteSettings,
  setToastPopupInfo,
  prepareQueueEventReminders,

  // from parent
  initialEventDiff,
  event,
  isNewEvent,
  setConfirmModuleUnmount,
  onRequestClose,
}) {
  const { chefId } = usePortalUser();
  const [eventDiff, eventDiffDispatch] = useReducer(eventDiffReducer, initialEventDiff || {});
  const [selectedTab, setSelectedTab] = useState("Info");
  const [optionSelectModalState, setOptionSelectModalState] = useState(null);
  const [isAdvancedSettingsModalVisible, setIsAdvancedSettingsModalVisible] = useState(false);
  const [isEditOrderCutoffTypeModalVisible, setIsEditOrderCutoffTypeModalVisible] = useState(false);
  const [idOfTimeWindowBeingEdited, setIdOfTimeWindowBeingEdited] = useState(null);
  const [newTimeWindow, setNewTimeWindow] = useState(null);
  const publishEventDiff = trpc.portal.publishEventDiff.useMutation({
    retry: shouldRetry(2),
  });
  const firebaseContext = useContext(FirebaseContext);

  const timeWindowBeingEditedWasDeleted = useDetectDeletion(
    event.timeWindows,
    idOfTimeWindowBeingEdited
  );
  if (timeWindowBeingEditedWasDeleted) {
    eventDiffDispatch({
      type: "removeTimeWindow",
      id: idOfTimeWindowBeingEdited,
    });
    setIdOfTimeWindowBeingEdited(null);
  }

  // MERGE database event with eventDiff to get the event object that will be
  // used for rendering
  const mergedEvent = removeNulls(mergeDiff(event, eventDiff));

  const eventStatus = _.isEmpty(event) ? "draft" : getEventStatus(event); // note, diff does not affect this
  const publishEventLoading = prepareQueueEventRemindersLoading || publishEventDiff.isLoading;

  function eventDiffReducer(state, action) {
    switch (action.type) {
      case "reset":
        return {};
      case "setEventTitle":
        return {
          ...state,
          title: action.payload,
        };
      case "setEventBannerImage":
        return {
          ...state,
          image: action.payload,
        };
      case "setEventDescription":
        return {
          ...state,
          description: action.payload,
        };
      case "setOnDemandEnabled":
        return {
          ...state,
          onDemandEnabled: action.payload,
        };
      case "setHideOrdersOpenTime":
        return {
          ...state,
          hideOrdersOpenTime: action.payload,
        };
      case "setIsEventRemindersDisabled":
        return {
          ...state,
          isEventRemindersDisabled: action.payload,
        };
      case "setIsHypeModeEnabled":
        return {
          ...state,
          isHypeModeEnabled: action.payload,
        };
      case "setOnDemandPickupWindows":
        return {
          ...state,
          onDemandWindowsEnabled: action.payload,
        };
      case "setTimeSlotCadence":
        return {
          ...state,
          timeSlotCadence: action.payload,
        };
      case "setGoLiveDate":
        return {
          ...state,
          goLiveTime: action.payload,
        };
      case "setGoLiveHoursMinutes":
        return {
          ...state,
          goLiveTime: action.payload,
        };
      case "setMenu":
        return {
          ...state,
          menu: menuDiffReducer(state.menu || {}, action.payload),
        };
      case "setMenuItems":
        return {
          ...state,
          menuItems: menuItemsDiffReducer(state.menuItems || {}, action.payload),
        };
      case "mergeTimeWindowDiff":
        return {
          ...state,
          timeWindows: {
            ...state.timeWindows,
            [action.id]: mergeDiff((state.timeWindows || {})[action.id], action.payload),
          },
        };
      case "mergeEventDiff":
        return mergeDiff(state, action.payload);
      case "addTimeWindow": {
        const newTimeWindow = action.payload;
        return {
          ...state,
          timeWindows: {
            ...state.timeWindows,
            [newTimeWindow.id]: newTimeWindow,
          },
        };
      }
      case "removeTimeWindow": {
        const timeWindowId = action.id;
        const newTimeWindows = { ...state.timeWindows };
        delete newTimeWindows[timeWindowId];
        return {
          ...state,
          timeWindows: newTimeWindows,
        };
      }
      default:
        throw new Error(`Invalid action type for eventDiffReducer: ${action.type}`);
    }
  }

  function menuDiffReducer(state, action) {
    const INIT_SECTIONS_LIST = [
      {
        title: "",
      },
    ];

    const menu = event.menu || {};
    const menuDiff = state;

    const newSections = _.cloneDeep(
      menuDiff.sections || menu.sections || INIT_SECTIONS_LIST // snapshot menu.sections
    ); // cloneDeep shouldn't be necessary but is for good measure

    switch (action.type) {
      case "setState": {
        if (typeof action.payload === "function") {
          return action.payload(state);
        }
        return action.payload;
      }
      case "addMenuSection": {
        let menuItemsToCopy = [];
        if (
          newSections.length === 1 &&
          Array.isArray(newSections[0].menuItems) &&
          newSections[0].menuItems.length > 0
        ) {
          menuItemsToCopy = newSections[0].menuItems;
          newSections[0].menuItems = [];
        }
        newSections.push({
          title: "",
          menuItems: menuItemsToCopy,
        });

        return {
          ...menuDiff,
          sections: newSections,
        };
      }
      case "handleMenuSettingsSectionsOnDragEnd": {
        const { destination, source } = action.payload;

        const sectionToMove = newSections.splice(source.index, 1)[0];
        newSections.splice(destination.index, 0, sectionToMove);
        return {
          ...menuDiff,
          sections: newSections,
        };
      }
      case "handleMenuOnDragEnd": {
        const { destination, source, draggableId } = action.payload;
        const menuItemId = draggableId.split(":::::")[0];

        if (!Array.isArray(newSections[destination.droppableId].menuItems))
          newSections[destination.droppableId].menuItems = [];

        if (
          destination.droppableId !== source.droppableId &&
          newSections[destination.droppableId].menuItems.includes(menuItemId)
        )
          return {}; // no change

        newSections[parseInt(source.droppableId)].menuItems.splice(source.index, 1);
        newSections[destination.droppableId].menuItems.splice(destination.index, 0, menuItemId);
        return {
          ...menuDiff,
          sections: newSections,
        };
      }
      case "setMaxOrdersPerTimeSlot": {
        const value = action.payload;
        return {
          ...state,
          maxOrdersPerTimeSlot: value !== "" ? parseInt(value) : "",
        };
      }
      case "setHideUntilLive": {
        return {
          ...state,
          hideUntilLive: action.payload,
        };
      }
      case "setDisplayQuantityRemaining": {
        return {
          ...state,
          displayQuantityRemaining: action.payload,
        };
      }
      default:
        throw new Error(`Invalid action type for menuDiffReducer: ${action.type}`);
    }
  }

  function menuDiffDispatch(action) {
    eventDiffDispatch({ type: "setMenu", payload: action });
  }

  function setMenuDiff(arg) {
    menuDiffDispatch({ type: "setState", payload: arg });
  }

  function menuItemsDiffReducer(state, action) {
    switch (action.type) {
      case "setState": {
        if (typeof action.payload === "function") {
          return action.payload(state);
        }
        return action.payload;
      }
      default:
        throw new Error(`Invalid action type for menuItemsDiffReducer: ${action.type}`);
    }
  }

  function menuItemsDiffDispatch(action) {
    eventDiffDispatch({ type: "setMenuItems", payload: action });
  }

  function setMenuItemsDiff(arg) {
    menuItemsDiffDispatch({ type: "setState", payload: arg });
  }

  const isEventModified = !_.isEqual(event, mergedEvent); // alternative approach: _.isEmpty(eventDiff);

  useEffect(() => {
    if (isEventModified) {
      window.onbeforeunload = function () {
        return "Changes you made may not be saved.";
      };
      setConfirmModuleUnmount(true);
    }
    return () => {
      window.onbeforeunload = null;
      setConfirmModuleUnmount(false);
    };
  }, [isEventModified, setConfirmModuleUnmount]);

  useEffect(() => {
    if (!isNewEvent) {
      const handler = firebaseContext.api.connectToEventOrders(chefId, event.id);
      return () => {
        firebaseContext.api.disconnectFromEventOrders(chefId, handler);
      };
    }
  }, [chefId, event.id, isNewEvent, firebaseContext.api]);

  // INFO FUNCTIONS
  function saveOrderCutoffTypeModal(eventDiffIncoming) {
    eventDiffDispatch({ type: "mergeEventDiff", payload: eventDiffIncoming });
    setIsEditOrderCutoffTypeModalVisible(false);
  }

  function saveAdvancedSettings(eventDiffIncoming) {
    eventDiffDispatch({ type: "mergeEventDiff", payload: eventDiffIncoming });
    setIsAdvancedSettingsModalVisible(false);
  }

  // LOCATIONS & TIMES FUNCTIONS
  function createAndSwitchToNewTimeWindow() {
    const newTimeWindow = createNewTimeWindow();
    setNewTimeWindow(newTimeWindow);
    setIdOfTimeWindowBeingEdited(newTimeWindow.id);
  }

  function setTimeSlotCadence(val) {
    const validInputs = ["5 minutes", "15 minutes", "30 minutes", "60 minutes", "Anytime between"];
    if (!validInputs.includes(val)) return null;
    eventDiffDispatch({ type: "setTimeSlotCadence", payload: val });
  }

  // HEADER FUNCTIONS
  const saveEventDiff = trpc.portal.saveEventDiff.useMutation({ retry: 1 });
  const saveEventLoading = saveEventDiff.isLoading;
  const saveEventError = saveEventDiff.isError;

  function saveAndContinue() {
    if (eventStatus === "draft") {
      saveEventDiff.mutate({ eventId: mergedEvent.id, eventDiff });
    } else {
      publishEvent(false, false);
    }
  }

  const prevSaveEventLoading = usePrevious(saveEventLoading);
  useEffect(() => {
    if (prevSaveEventLoading && !saveEventLoading) {
      if (saveEventError) {
        setToastPopupInfo({
          type: "error",
          text: "Event failed to save",
        });
      } else {
        const actionFlow = {
          Info: "Pickup Times",
          "Pickup Times": "Menu",
          Menu: "Publish",
        };
        if (selectedTab in actionFlow) {
          setSelectedTab(actionFlow[selectedTab]);
        }
        setToastPopupInfo({
          type: "success",
          text: "Event successfully saved",
        });
        eventDiffDispatch({ type: "reset" });
      }
    }
  }, [prevSaveEventLoading, saveEventLoading, saveEventError, selectedTab, setToastPopupInfo]);

  function publishEvent(overrideWarning = false, bypassCall = false) {
    if (!overrideWarning && eventStatus === "draft") {
      setOptionSelectModalState({
        message:
          "This will make your event publicly visible on your storefront, are you sure? \n\n You can always unpublish if you find any issues.",
        optionSelectButtons: [
          {
            text: "Confirm",
            onClick: () => {
              publishEvent(true, bypassCall);
              trackEventPublished({
                eventId: mergedEvent.id,
                eventStatus: eventStatus,
              });
            },
            type: "confirm",
          },
        ],
        closeModal: () => {
          setOptionSelectModalState(null);
        },
      });
      return;
    }
    prepareQueueEventReminders(
      chefId,
      mergedEvent.id,
      mergedEvent.goLiveTime,
      siteSettings.restaurantName,
      bypassCall
    );
  }

  const prevPrepareQueueEventRemindersLoading = usePrevious(prepareQueueEventRemindersLoading);
  useEffect(() => {
    if (prevPrepareQueueEventRemindersLoading && !prepareQueueEventRemindersLoading) {
      if (prepareQueueEventRemindersError) {
        setToastPopupInfo({
          type: "error",
          text: "Event failed to publish",
        });
      } else {
        publishEventDiff
          .mutateAsync({
            eventId: mergedEvent.id,
            eventDiff: {
              ...eventDiff,
              draft: false,
            },
          })
          .then(() => {
            setToastPopupInfo({
              type: "success",
              text: "Event successfully published",
            });
            if (eventStatus === "draft") {
              onRequestClose();
            } else {
              eventDiffDispatch({ type: "reset" });
            }
          })
          .catch((e) => {
            setToastPopupInfo({
              type: "error",
              text: `Event failed to publish: ${e}`,
            });
            throw e;
          });
      }
    }
  }, [prevPrepareQueueEventRemindersLoading, prepareQueueEventRemindersLoading, prepareQueueEventRemindersError, setToastPopupInfo, eventStatus, publishEventDiff, mergedEvent.id, eventDiff, onRequestClose, chefId]);

  const unpublishEventRQ = trpc.portal.unpublishEvent.useMutation({
    retry: 1,
  });
  async function unpublishEvent(overrideWarning = false) {
    if (!overrideWarning) {
      setOptionSelectModalState({
        message: "This will remove this event from your storefront immediately, continue?",
        optionSelectButtons: [
          {
            text: "Confirm",
            onClick: () => {
              unpublishEvent(true);
              trackEventUnpublished({
                eventId: mergedEvent.id,
                eventStatus: eventStatus,
              });
            },
            type: "warning",
          },
        ],
        closeModal: () => {
          setOptionSelectModalState(null);
        },
      });
      return;
    }
    try {
      await unpublishEventRQ.mutateAsync({ eventId: mergedEvent.id });
      setToastPopupInfo({
        type: "success",
        text: "Event successfully unpublished",
      });
    } catch (e) {
      setToastPopupInfo({
        type: "error",
        text: `Event failed to unpublish: ${e}`,
      });
      throw e;
    }
  }

  return (
    <CenterX css={{ paddingBottom: "$sizes$navHeight", backgroundColor: "$accent1" }}>
      <ConfigContainer>
        <ConfigHeader>
          <Row css={{ width: "100%", justifyContent: "space-between", alignItems: "center" }}>
            <IconButton
              icon={<ArrowLeftIcon />}
              onClick={() => {
                if (isEventModified) {
                  const r = window.confirm(
                    "Leave? You have unsaved changes that will not be saved."
                  );
                  if (r == true) {
                    onRequestClose();
                  }
                } else {
                  onRequestClose();
                }
              }}
            />
            {mergedEvent.title?.length > 0 ? mergedEvent.title : "Untitled Event"}
            <Status type={eventStatus} animation={<AnimationContainer animation="blob" />} />
          </Row>
        </ConfigHeader>
        <Tabs
          value={selectedTab}
          onValueChange={(value) => {
            setSelectedTab(value);
            trackEventConfigNavClicked({ eventId: event.id, tab: value });
          }}
          css={{ boxShadow: "rgb(0 0 0 / 7%) 0px 2px 10px" }}
        >
          <Tabs.List>
            <Tabs.Tab value="Info">Info</Tabs.Tab>
            <Tabs.Tab value="Pickup Times">Pickup Windows</Tabs.Tab>
            <Tabs.Tab value="Menu">Menu</Tabs.Tab>
            <Tabs.Tab value="Publish">{mergedEvent.draft ? "Publish" : "Share"}</Tabs.Tab>
            {!mergedEvent.draft && <Tabs.Tab value="Stats">Stats</Tabs.Tab>}
          </Tabs.List>
          <Tabs.Page value="Info">
            <InfoMaster
              mergedEvent={mergedEvent}
              eventDiffDispatch={eventDiffDispatch}
              saveEventLoading={saveEventLoading}
              publishEventLoading={publishEventLoading}
              saveAndContinue={saveAndContinue}
              setShowEditOrderCutoffTypeModal={setIsEditOrderCutoffTypeModalVisible}
              setShowAdvancedSettingsModal={setIsAdvancedSettingsModalVisible}
            />
            {isEditOrderCutoffTypeModalVisible && (
              <EditOrderCutoffTypeModal
                event={mergedEvent}
                eventDiffDispatch={eventDiffDispatch}
                setShowEditOrderCutoffTypeModal={setIsEditOrderCutoffTypeModalVisible}
                onRequestSave={saveOrderCutoffTypeModal}
              />
            )}
            {isAdvancedSettingsModalVisible && (
              <AdvancedSettingsModal
                event={mergedEvent}
                eventDiffDispatch={eventDiffDispatch}
                setShowAdvancedSettingsModal={setIsAdvancedSettingsModalVisible}
                onRequestSave={saveAdvancedSettings}
              />
            )}
          </Tabs.Page>
          <Tabs.Page value="Pickup Times">
            <LocationsAndTimesMaster
              mergedEvent={mergedEvent}
              saveEventLoading={saveEventLoading}
              publishEventLoading={publishEventLoading}
              saveAndContinue={saveAndContinue}
              createAndSwitchToNewTimeWindow={createAndSwitchToNewTimeWindow}
              setIdOfTimeWindowBeingEdited={setIdOfTimeWindowBeingEdited}
              setTimeSlotCadence={setTimeSlotCadence}
            />
          </Tabs.Page>
          <Tabs.Page value="Menu">
            <MenuMaster
              saveAndContinue={saveAndContinue}
              saveEventLoading={saveEventLoading}
              publishEventLoading={publishEventLoading}
              eventStatus={eventStatus}
              eventTimeSlotCadence={mergedEvent.timeSlotCadence}
              eventTimeSlotCount={
                getSortedTimeSlots({
                  event: mergedEvent,
                  includePickup: true,
                  includeDelivery: true,
                  customerZip: "all",
                }).length
              }
              menuDiffDispatch={menuDiffDispatch}
              menuItemsDiffDispatch={menuItemsDiffDispatch}
              setMenuDiff={setMenuDiff}
              setMenuItemsDiff={setMenuItemsDiff}
              event={event}
              eventDiff={eventDiff}
              mergedEvent={mergedEvent}
              orders={orders}
            />
          </Tabs.Page>
          <Tabs.Page value="Publish">
            <PublishMaster
              event={mergedEvent}
              publishEvent={publishEvent}
              unpublishEvent={unpublishEvent}
              unpublishEventLoading={unpublishEventRQ.isLoading}
              publishEventLoading={publishEventLoading}
              publishDisabled={
                isMenuEmpty(mergedEvent) ||
                getSortedTimeWindows(mergedEvent.timeWindows || {}).length === 0 ||
                mergedEvent.title === ""
              }
            />
          </Tabs.Page>
          <Tabs.Page value="Stats">
            <Statistics event={mergedEvent} orders={orders} />
          </Tabs.Page>
        </Tabs>
        {(newTimeWindow || idOfTimeWindowBeingEdited) && (
          <AddEditTimeWindowModal
            isEventRecurring={mergedEvent.recurring}
            eventId={mergedEvent.id}
            isNewTimeWindow={newTimeWindow !== null}
            initialTimeWindowDiff={newTimeWindow}
            timeWindow={newTimeWindow ? {} : mergedEvent.timeWindows?.[idOfTimeWindowBeingEdited]}
            goLiveTime={mergedEvent.goLiveTime}
            onRequestSave={(timeWindowDiffIncoming) => {
              if (newTimeWindow) {
                eventDiffDispatch({
                  type: "addTimeWindow",
                  payload: timeWindowDiffIncoming,
                });
                trackTimeWindowCreated({
                  eventId: event.id,
                  eventStatus: eventStatus,
                });
              } else {
                eventDiffDispatch({
                  type: "mergeTimeWindowDiff",
                  payload: timeWindowDiffIncoming,
                  id: idOfTimeWindowBeingEdited,
                });
                trackTimeWindowUpdated({
                  eventId: event.id,
                  eventStatus: eventStatus,
                });
              }
              setNewTimeWindow(null);
              setIdOfTimeWindowBeingEdited(null);
            }}
            onRequestClose={() => {
              setNewTimeWindow(null);
              setIdOfTimeWindowBeingEdited(null);
            }}
          />
        )}
        {optionSelectModalState && <OptionSelectModal {...optionSelectModalState} />}
      </ConfigContainer>
    </CenterX>
  );
});
