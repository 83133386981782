import React from "react";
import { styled } from "../../stitches.config";
import { IconButton } from "./IconButton";
import { Tooltip } from "./Tooltip";
import * as LabelPrimitive from "@radix-ui/react-label";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { Flex } from "./Containers";

const StyledLabel = styled(LabelPrimitive.Root, {
  display: "flex",
  alignItems: "center",
  fontSize: 14,
  fontWeight: "$normal",
  color: "$gray11",
  userSelect: "none",
});

const LabelFlex = styled("div", {
  display: "flex",
  fontFamily: "$avenir",
  variants: {
    side: {
      top: {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        [`& ${StyledLabel}`]: {
          marginBottom: "$xs",
        },
      },
      bottom: {
        flexDirection: "column-reverse",
        alignItems: "flex-start",
        [`& ${StyledLabel}`]: {
          marginTop: "$xs",
        },
      },
      left: {
        flexDirection: "row",
        [`& ${StyledLabel}`]: {
          marginRight: "$xs",
        },
      },
      right: {
        flexDirection: "row-reverse",
        justifyContent: "flex-end",
        [`& ${StyledLabel}`]: {
          marginLeft: "$xs",
        },
      },
    },
  },
  defaultVariants: {
    side: "top",
  },
});

export const Label = ({ text, side, children, tooltipText, css, ...props }) => {
  return (
    <LabelFlex side={side} css={css}>
      <StyledLabel {...props}>
        {text}
        {tooltipText && (
          <Tooltip content={tooltipText} clickable>
            <IconButton size="small" rounded icon={<InfoCircledIcon />} css={{ marginLeft: 4 }} />
          </Tooltip>
        )}
      </StyledLabel>
      {children}
    </LabelFlex>
  );
};
