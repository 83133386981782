/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { loadStripe } from "@stripe/stripe-js";
import * as Sentry from "@sentry/react";
import ReactLoading from "react-loading";
import {
  Elements,
  CardElement,
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  getCustomerPaymentProcessingFee,
  getSubtotal,
  getOrderTotal,
  chargeHotplateFee,
  getOrderTaxesAndHotplateFee,
  getUnFormattedPhone,
  getOrderDiscount,
  createOrder,
  getSortedLocationsEvent,
} from "@hotplate/utils-ts/helperFunctions";
import { Button, Checkbox } from "../../visly/Primitives";
import { icons, textstyles, colors } from "../../visly";
import "./css/CheckoutForm.css";
import { trackCheckoutCompleted, trackReminderSignupCompleted } from "../analytics";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { stripeFrontendApiKey } from "../actions/types";
import {
  setCartId as setCartIdUnconnected,
  setConfirmationOrder as setConfirmationOrderUnconnected,
} from "../actions";
import { ensureOk, prepareToConfirmPaymentMutation, saveUserInfoMutation } from "../../mutations";
import { logger } from "../../logger";

function useConnectedFn(fn, dispatch) {
  return useCallback(
    (...args) => {
      return fn(...args)(dispatch);
    },
    [fn, dispatch]
  );
}

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#E85B5B",
      color: "#333333",
      fontWeight: 500,
      fontFamily: "arboria, sans-serif",
      fontSize: "18px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#333333",
      },
      "::placeholder": {
        color: "#C6CACC",
      },
    },
    invalid: {
      iconColor: "red",
      color: "red",
    },
  },
};

const ErrorMessage = ({ children, ...restProps }) => (
  <div className="ErrorMessage" role="alert" {...restProps}>
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#3E4345"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#fff"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

function CheckoutForm({
  // Shop info
  isPaymentProcessing,
  setIsPaymentProcessing,
  cart,
  cartEvent,

  // Checkout context
  showCheckout,
  isOnDemand,
  guestLoginEnabled,
  paymentIntentClientSecret,
  customFieldsSnapshot,

  // Checkout form info
  firstName,
  lastName,
  phoneOverride,
  email,
  tip,
  customerAddressDict,
  customerDeliveryInstructions,
  deliveryFee,
  customFieldValues,
}) {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const userInfo = useSelector((state) => state.login.userInfo);
  const siteSettings = useSelector((state) => state.storefront.siteSettings);

  const dispatch = useDispatch();
  const setCartId = useConnectedFn(setCartIdUnconnected, dispatch);
  const setConfirmationOrder = useConnectedFn(setConfirmationOrderUnconnected, dispatch);

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [isPayWithCardReady, setIsPayWithCardReady] = useState(false);
  const [disablePay, setDisablePay] = useState(false);
  const [eventRemindersEnabled, setEventRemindersEnabled] = useState(true);
  const [useSavedCard, setUseSavedCard] = useState(
    Array.isArray(userInfo.paymentMethods) && userInfo.paymentMethods.length !== 0 ? true : false
  );
  const useSavedCardRef = useRef(useSavedCard);
  useSavedCardRef.current = useSavedCard; // this is used in a setTimeout to get the latest useSavedCard state
  const [cardFieldError, setCardFieldError] = useState();
  const [checkoutError, setCheckoutError] = useState();

  const prepareToConfirmPayment = useMutation(prepareToConfirmPaymentMutation, { retry: 3 });
  const saveUserInfo = useMutation(saveUserInfoMutation, { retry: 3 });

  // This order is used for calculating costs via getOrderTotal and as the local order for the confirmation page.
  const now = Date.now();
  const order = createOrder(
    {
      ...cart,
      timeSlot:
        isOnDemand && !cartEvent.onDemandWindowsEnabled
          ? {
              range: false,
              endTime: now,
              startTime: now,
              onDemand: true,
            }
          : cart.timeSlot,
      location: isOnDemand
        ? getSortedLocationsEvent({
            event: cartEvent,
            includePickup: cart.fulfillmentType === "pickup",
            includeDelivery: cart.fulfillmentType === "delivery",
          })[0]
        : cart.location,
    },
    {
      ...cartEvent,
      customFields: customFieldsSnapshot, // use the snapshotted customFields so they'll match customFieldValues
    },
    siteSettings,
    {
      firstName,
      lastName,
      phone: getCustomerPhone(),
      email,
      tip,
      customerAddressDict,
      customerDeliveryInstructions,
      deliveryFee,
      orderPlaced: undefined,
      customFieldValues,
      eventRemindersEnabled,
    }
  );
  const totalApprovedByCustomer = getOrderTotal(order);

  function getCustomerPhone() {
    if (typeof phoneOverride === "string") return "+1" + getUnFormattedPhone(phoneOverride);
    else if (typeof userInfo.phone === "string") return userInfo.phone;
    return "";
  }

  function onDemandDisabled() {
    if (
      isOnDemand &&
      (getCustomerPhone().length !== 12 ||
        typeof firstName !== "string" ||
        firstName.length === 0 ||
        !cart.paymentIntentId ||
        !order.timeSlot ||
        !order.location)
    )
      return true;
    return false;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    return checkout();
  }

  const checkoutRef = useRef(checkout);
  checkoutRef.current = checkout;
  async function checkout(ev) {
    if (!stripe || !elements || !paymentIntentClientSecret) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (ev) {
      logger.debug("Beginning payment via payment request.");
    } else {
      logger.debug("Beginning payment via card.");
    }

    setIsPaymentProcessing(true);

    try {
      logger.debug("Calling prepareToConfirmPayment");
      ensureOk(
        await prepareToConfirmPayment.mutateAsync({
          cartId: cart.id,
          checkoutInfo: {
            firstName,
            lastName,
            fullName: firstName + " " + lastName,
            phone: getCustomerPhone(),
            email,
            tip,
            customerAddressDict, // TODO KEANE should be in cart
            customerDeliveryInstructions, // TODO KEANE should be in cart
            deliveryFee, // TODO KEANE should be in cart
            customFieldValues,
            eventRemindersEnabled,
          },
          totalApprovedByCustomer,
        })
      );

      logger.debug("Calling stripe.confirmCardPayment");
      if (ev) {
        // Payment Request
        const { paymentIntent, error } = await stripe.confirmCardPayment(
          paymentIntentClientSecret,
          {
            payment_method: ev.paymentMethod.id,
          },
          {
            handleActions: false,
          }
        );

        if (error) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete("fail");
          throw error;
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete("success");
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent.status === "requires_action") {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(paymentIntentClientSecret);
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
              throw error;
            } else {
              // The payment has succeeded.
            }
          } else {
            // The payment has succeeded.
          }
        }
      } else {
        // Card
        const { error } = await stripe.confirmCardPayment(paymentIntentClientSecret, {
          setup_future_usage: "on_session",
          payment_method:
            useSavedCard && Array.isArray(userInfo.paymentMethods) && userInfo.paymentMethods.length
              ? userInfo.paymentMethods[0].id
              : {
                  card: elements.getElement(CardElement),
                  billing_details: {
                    phone: getCustomerPhone(),
                    name: firstName + " " + lastName,
                    email: email ? email : null,
                  },
                },
        });

        if (error) {
          throw error;
        }
      }

      if (!guestLoginEnabled) {
        // We don't await this because we want to continue even if it fails
        logger.debug("Calling saveUserInfo");
        saveUserInfo
          .mutateAsync({
            userInfo: {
              firstName,
              lastName,
              deliveryInstructions: customerDeliveryInstructions,
              email,
              name: firstName + " " + lastName,
              fullName: firstName + " " + lastName,
              phone: getCustomerPhone(),
            },
          })
          .then(ensureOk);
      }

      let orderType = "";
      if (isOnDemand) {
        orderType = "walk_up";
      } else if (order.ticketed) {
        orderType = "ticket";
      } else {
        orderType = "pre-order";
      }
      trackCheckoutCompleted({
        order_id: cart.paymentIntentId,
        event_id: cart.eventId,
        cart_id: cart.id,
        chef_id: cart.chefId,
        customer_id: userInfo.phone,
        fulfillment_type: cart.fulfillmentType,
        order_type: orderType,
      });
      if (eventRemindersEnabled) {
        trackReminderSignupCompleted("checkout", {
          chef_id: cart.chefId,
          customer_id: userInfo.phone,
          order_id: cart.paymentIntentId,
          event_id: cart.eventId,
          cart_id: cart.id,
          fulfillment_type: cart.fulfillmentType,
          order_type: orderType,
        });
      }

      if (!order.timeSlot || !order.location) {
        logger.error("Read may be late. Local order is missing timeSlot or location.");
        Sentry.captureException(
          new Error("Read may be late. Local order is missing timeSlot or location.")
        );
      } else {
        logger.debug("Setting local order.");
        setConfirmationOrder(order);
      }

      const confirmationPageUrl =
        "/confirmation" +
        "?orderId=" +
        cart.paymentIntentId.substring(
          cart.paymentIntentId.length - 6,
          cart.paymentIntentId.length
        ) +
        "&phone=" +
        getCustomerPhone();
      logger.debug(`Navigating to confirmation page at ${confirmationPageUrl}`);
      navigate(confirmationPageUrl);

      // Abandon the cart now that it's complete
      logger.debug("Abandoning cart now that it's complete.");
      setCartId(uuid());
    } catch (e) {
      setCheckoutError(e);
      throw e; // for Sentry and console
    } finally {
      setIsPaymentProcessing(false);
    }
  }

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Total",
          amount: 50, // will be updated in the onClick for opening the payment request menu
        },
        requestPayerName: true, // TODO possibly remove, though apparently it's recommended to keep at least one of these
        requestPayerEmail: true, // TODO possibly remove
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          pr.on("paymentmethod", (ev) => {
            checkoutRef.current(ev); // TODO come up with a better pattern for this, this is probably not safe for concurrent mode
          });
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  useEffect(() => {
    setUseSavedCard(
      Array.isArray(userInfo.paymentMethods) && userInfo.paymentMethods.length !== 0 ? true : false
    );
  }, [userInfo.paymentMethods]);

  useEffect(() => {
    logger.debug(`useSavedCard: ${useSavedCard}`);
  }, [useSavedCard]);

  return (
    <form className="Form" onSubmit={handleSubmit}>
      <div className="cartTotalInformation">
        <div className="cartTotalSubItem">
          <p className="cartSubtotalLeft">Subtotal</p>
          <p className="cartSubtotalPrice">${getSubtotal(cart.cartItems)}</p>
        </div>
        {(order.discountCode || order.giftCard) && (
          <div className="cartTotalSubItem">
            <p className="cartSubtotalLeft">Discount</p>
            <p className="cartSubtotalPrice">−${getOrderDiscount(order)}</p>
          </div>
        )}
        {cart.fulfillmentType === "delivery" && (
          <div className="cartTotalSubItem">
            <p className="cartSubtotalLeft">Delivery</p>
            <p className="cartSubtotalPrice">${deliveryFee}</p>
          </div>
        )}
        {showCheckout && (
          <div className="cartTotalSubItem">
            <p className="cartSubtotalLeft">Tip</p>
            <p className="cartSubtotalPrice">${tip}</p>
          </div>
        )}
        {siteSettings.chargeCustomerPaymentProcessing && (
          <div className="cartTotalSubItem">
            <p className="cartSubtotalLeft">Payment Processing Fee</p>
            <p className="cartSubtotalPrice">${getCustomerPaymentProcessingFee(order)}</p>
          </div>
        )}
        <div className="cartTotalSubItem">
          {<p className="cartSubtotalLeft">{chargeHotplateFee(order) ? "Taxes & Fees" : "Tax"}</p>}
          <p className="cartSubtotalPrice">${getOrderTaxesAndHotplateFee(order)}</p>
        </div>
        <div className="cartTotalSubItem">
          <p className="cartSubtotalLeftTotal">Total</p>
          <p className="cartSubtotalPriceTotal">${totalApprovedByCustomer}</p>
        </div>
      </div>
      {showCheckout && (
        <>
          {paymentRequest && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "52px",
                width: "100%",
                borderRadius: "8px",
                overflow: "hidden",
                marginTop: 24,
                opacity: onDemandDisabled() || !paymentIntentClientSecret ? 0.5 : 1,
                pointerEvents: onDemandDisabled() || !paymentIntentClientSecret ? "none" : "auto",
              }}
            >
              <PaymentRequestButtonElement
                onClick={() => {
                  const amount = Math.round(parseFloat(totalApprovedByCustomer) * 100);
                  logger.debug(`Updating payment request amount: ${amount}`);
                  paymentRequest.update({
                    total: {
                      label: "Total",
                      amount: amount,
                    },
                  });
                }}
                options={{
                  paymentRequest,
                  style: {
                    paymentRequestButton: {
                      type: "default",
                      // One of 'default', 'book', 'buy', or 'donate'
                      // Defaults to 'default'

                      theme: "dark",
                      // One of 'dark', 'light', or 'light-outline'
                      // Defaults to 'dark'

                      height: "52px",
                      // Defaults to '40px'. The width is always '100%'.
                    },
                  },
                }}
                style={{ borderRadius: 8, height: 52 }}
              />
            </div>
          )}
          <fieldset
            className="FormGroup"
            style={{
              maxHeight: isPayWithCardReady ? 50 : 0,
              transition: "max-height .5s ease-in-out, margin-top .25s ease-in-out",
              overflow: isPayWithCardReady ? "visible" : "hidden",
              marginBottom: 0,
              marginTop: isPayWithCardReady ? 32 : 0,
            }}
          >
            {Array.isArray(userInfo.paymentMethods) &&
            userInfo.paymentMethods.length !== 0 &&
            useSavedCard ? (
              <div className="FormRow">
                <div className="savedCardHolder">
                  <p
                    className="savedCardTextChange"
                    onClick={() => {
                      setDisablePay(true);
                      setUseSavedCard(false);
                    }}
                  >
                    Change Card
                  </p>
                  <p className="savedCardText">
                    {userInfo.paymentMethods[0]["card"]["brand"] +
                      " ••••" +
                      userInfo.paymentMethods[0]["card"]["last4"]}
                  </p>
                </div>
              </div>
            ) : (
              <CardField
                onChange={(e) => {
                  setDisablePay(!e.complete);
                  if (e.error) {
                    setCardFieldError(e.error);
                  } else if (e.complete) {
                    setCardFieldError(undefined);
                  }
                }}
              />
            )}
          </fieldset>
          {cardFieldError && <ErrorMessage>{cardFieldError.message}</ErrorMessage>}
          {isPayWithCardReady ? (
            <Button
              size="large"
              style={{ marginTop: "16px", height: "52px" }}
              large
              kind="primary"
              disabled={
                !stripe ||
                !elements ||
                !paymentIntentClientSecret ||
                disablePay ||
                onDemandDisabled() ||
                isPaymentProcessing
              }
              loading={!paymentIntentClientSecret}
              loadingAnimation={
                <ReactLoading type={"spin"} color={"#FFFFFF"} height={24} width={24} />
              }
              text={"Pay $" + totalApprovedByCustomer}
              type="submit"
            />
          ) : (
            <Button
              size="large"
              style={{ marginTop: "16px", height: "52px" }}
              large
              kind="primary"
              text="Pay with Card"
              type="button"
              disabled={onDemandDisabled()}
              onClick={() => {
                setIsPayWithCardReady(true);
                setDisablePay(true);
                if (useSavedCard) {
                  setTimeout(() => {
                    if (useSavedCardRef.current) {
                      setDisablePay(false);
                    }
                  }, 1000);
                }
              }}
            />
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              cursor: "pointer",
              marginTop: "24px",
            }}
            onClick={() => {
              setEventRemindersEnabled(!eventRemindersEnabled);
            }}
          >
            <Checkbox checked={eventRemindersEnabled} icon={icons.checkmark} />
            <span style={{ ...textstyles.body, color: colors.gray700 }}>
              Notify me when {siteSettings.restaurantName} opens their sales again
            </span>
          </div>
          {checkoutError && (
            <ErrorMessage style={{ color: "red" }}>{checkoutError.message}</ErrorMessage>
          )}
        </>
      )}
    </form>
  );
}

const stripePromise = loadStripe(stripeFrontendApiKey);

/**
 * <Elements> needs to wrap <CheckoutForm> because the latter uses useStripe()
 * and the former provides the context for that hook.
 */
export default function InjectedCheckoutForm(props) {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm {...props} />
    </Elements>
  );
}
