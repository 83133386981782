// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./AvailablePickupTimes.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../../_internal_primitives";
import {
  default as ToggleExpandButton,
  ButtonContext as ToggleExpandButtonContext,
} from "../../Primitives/Button";
import { default as TimesContainer } from "./AvailablePickupTimes.TimesContainer";

const styles = [
  {
    type: "default",
    layers: {
      HqXMSfuesA: {
        none: {
          text: "Wed, 3/14",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      MkjJ4BEbdh: {
        none: {
          text: "View more",
          rounded: true,
        },
      },
    },
  },
];

const variantPropTypes = [];

export const AvailablePickupTimesContext = createContext(null);

function AvailablePickupTimes(_props) {
  const defaults = useContext(AvailablePickupTimesContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="QwFRQmMQys"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "QwFRQmMQys",
        scope: "BQtqWetr17",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => [
        <SpacerPrimitive
          id={"Spacer_AP99gENXir"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_BQtqWetr17_AP99gENXir"
          }
          key={"AP99gENXir"}
        />,
        <TextPrimitive
          id={"date_HqXMSfuesA"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_BQtqWetr17_HqXMSfuesA"
          }
          key={"HqXMSfuesA"}
          text={
            exists(props.date) ? props.date : getStyle("HqXMSfuesA", "text")
          }
          element={getStyle("HqXMSfuesA", "htmlElement")}
        />,
        props.TimesContainer === undefined ? (
          <TimesContainer key={"9dd5iCQdUT"} />
        ) : (
          props.TimesContainer
        ),
        <ContainerPrimitive
          id={"button-container_WaAdHiMAwd"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_BQtqWetr17_WaAdHiMAwd"
          }
          key={"WaAdHiMAwd"}
          addSpacing={false}
          element={getStyle("WaAdHiMAwd", "htmlElement")}
        >
          {props.ToggleExpandButton === undefined ? (
            <ToggleExpandButton
              key={"MkjJ4BEbdh"}
              {...getCompositeDefaultProps("MkjJ4BEbdh")}
              id="ToggleExpandButton_MkjJ4BEbdh"
              className="__visly_reset_7X7HTLRqyD __visly_scope_BQtqWetr17_MkjJ4BEbdh"
            />
          ) : (
            <ToggleExpandButtonContext.Provider
              key="MkjJ4BEbdh-provider"
              value={{
                key: "MkjJ4BEbdh",
                id: "ToggleExpandButton_MkjJ4BEbdh",
                className:
                  "__visly_reset_7X7HTLRqyD __visly_scope_BQtqWetr17_MkjJ4BEbdh",
                ...getCompositeDefaultProps("MkjJ4BEbdh"),
              }}
            >
              {props.ToggleExpandButton}
            </ToggleExpandButtonContext.Provider>
          )}
        </ContainerPrimitive>,
        <SpacerPrimitive
          id={"Spacer_PjDw1GNy1J"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_BQtqWetr17_PjDw1GNy1J"
          }
          key={"PjDw1GNy1J"}
        />,
      ]}
    </RootPrimitive>
  );
}

AvailablePickupTimes.ToggleExpandButton = ToggleExpandButton;
AvailablePickupTimes.__variants = [];

export default AvailablePickupTimes;
