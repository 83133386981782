// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./OrderEditingLineItem.RefundItemButton.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import { RootPrimitive, TextPrimitive } from "../../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      "9hSxDq6qd8": {
        none: {
          text: "Remove & Refund",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const RefundItemButtonContext = createContext(null);

function RefundItemButton(_props) {
  const defaults = useContext(RefundItemButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="GJz6ScoA4k"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "GJz6ScoA4k",
        scope: "UJs52fA2ou",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <TextPrimitive
          id={"Text_9hSxDq6qd8"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_UJs52fA2ou_9hSxDq6qd8"
          }
          key={"9hSxDq6qd8"}
          text={getStyle("9hSxDq6qd8", "text")}
          element={getStyle("9hSxDq6qd8", "htmlElement")}
        />
      )}
    </RootPrimitive>
  );
}

RefundItemButton.__variants = [];

export default RefundItemButton;
