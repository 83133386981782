import React, { useEffect, useState } from "react";
import { P } from "../../hotplate-common/primitives/Containers";
import { styled, keyframes } from "../../stitches.config";
import { ChefStyles } from "../shop/types";

const slideIn = keyframes({
  from: { transform: "translateY(150px)" },
  to: { transform: "translateY(0px)" },
});

const CartButtonPrimitive = styled("button", {
  display: "grid",
  gridAutoFlow: "column",
  gridAutoColumns: "1fr",
  alignContent: "center",
  height: "$lg_btn",
  width: "100%",
  maxW: 700,
  marginInline: "$md",
  paddingInline: "$lg",
  backgroundColor: "$hotplate9",
  border: "1px solid $colors$hotplate7",
  borderRadius: "$pill",
  boxShadow: "$elevation4",
  color: "$white",
  textStyle: "text-5",
  fontFamily: "$inter",
  fontSize: "$sm",
  transition:
    "background-color 150ms ease-in-out, border-color 150ms ease-in-out, color 150ms ease-in-out",

  "&:disabled": {
    backgroundColor: "$hotplate7",
    border: "1px solid $colors$hotplate6",
    color: "$gray600",
    boxShadow: "none",
    cursor: "default",
  },
  "&:hover": {
    backgroundColor: "$hotplate10",
  },
  "&:active": {
    backgroundColor: "$hotplate11",
  },
  "&:focus-visible": {
    outline: "2px solid $accent9",
    outlineOffset: "4px",
  },
  "@desktop_md": {
    height: 56,
    padx: "$xl",
  },
});

const CartButtonBox = styled("div", {
  position: "sticky",
  zIndex: "$sticky",
  bottom: "max(env(safe-area-inset-bottom), 24px)",
  display: "flex",
  justifyContent: "center",
  width: "100%",
  '&[data-show="true"]': { animation: `${slideIn} 250ms cubic-bezier(.45,.41,.42,1.32)` },
  '&[data-show="false"]': { display: "none" },
});

type CartItem = {
  quantity: number;
};

export const CartButton = ({
  onClick,
  cartItems,
  countdownTimer,
  chefStyles,
  disabled,
  ...props
}: {
  onClick: () => void;
  cartItems: CartItem[];
  countdownTimer: React.ReactNode;
  chefStyles: ChefStyles;
  disabled?: boolean;
}) => {
  const hasItemsInCart = Array.isArray(cartItems) && cartItems.length > 0;
  const {
    primaryColor,
    primaryColorActive,
    primaryColorHover,
    primaryColorLight,
    primaryColorDark,
    textColor,
  } = chefStyles;

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (hasItemsInCart) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [hasItemsInCart]);

  const totalQuantity = hasItemsInCart
    ? cartItems.reduce((sum, cartItem) => (sum += cartItem.quantity), 0)
    : 0;

  return (
    <CartButtonBox {...props} data-show={show}>
      <CartButtonPrimitive
        onClick={onClick}
        css={{
          backgroundColor: primaryColor || "transparent",
          borderColor: primaryColorHover || "transparent",
          color: textColor,
          "&:hover": { backgroundColor: primaryColorHover },
          "&:active": { backgroundColor: primaryColorActive },
          "&:disabled": { backgroundColor: primaryColorLight, color: primaryColorDark },
        }}
        disabled={disabled}
      >
        <P css={{ justifySelf: "flex-start", alignSelf: "center" }}>Cart</P>
        {hasItemsInCart && countdownTimer}
        <P css={{ justifySelf: "flex-end", alignSelf: "center" }}>{`${totalQuantity} items`}</P>
      </CartButtonPrimitive>
    </CartButtonBox>
  );
};
