/* eslint-disable react/prop-types */
import React, { useState } from "react";
import ToolTip from "../../../../hotplate-common/ToolTip";
import AddEditModal from "../../../components/AddEditModal";
import Spacer from "../../../../hotplate-common/Spacer";
import Modal from "../../../../hotplate-common/Modal";
import { Input } from "../../../../visly/Primitives";
import { LabeledInput, LabeledSwitch } from "../../../../visly/Events";
import "./css/AdvancedSettingsModal.css";
import { mergeDiff, removeNulls } from "../../../../hooks";
import { getEventStatus } from "@hotplate/utils-ts/helperFunctions";
import { trackCheckoutTimeUpdated, trackEventPrivateToggled } from "../analytics";
import _ from "lodash";

export default function AdvancedSettingsModal({
  event,
  setShowAdvancedSettingsModal,
  onRequestSave,
}) {
  const [eventDiff, setEventDiff] = useState({});
  const mergedEvent = removeNulls(mergeDiff(event, eventDiff));
  const [debounce] = useState(() => {
    return _.debounce(
      (func) => {
        func();
      },
      2000,
      { leading: false, trailing: true }
    );
  });

  function toggleEventPrivate() {
    setEventDiff((prevEventDiff) => {
      return {
        ...prevEventDiff,
        private: !mergedEvent.private,
      };
    });
    trackEventPrivateToggled({
      eventId: event.id,
      eventStatus: getEventStatus(event),
      enabled: !mergedEvent.private,
    });
  }

  function setCheckoutTime(value) {
    if ((!/^[0-9]+$/.test(value) && value !== "") || value.length > 2) return;
    setEventDiff((prevEventDiff) => {
      return {
        ...prevEventDiff,
        checkoutTime: value !== "" ? parseInt(value) * 60 * 1000 : "",
      };
    });
    debounce(() => {
      trackCheckoutTimeUpdated({
        eventId: event.id,
        eventStatus: getEventStatus(event),
        checkoutTime: parseInt(value),
      });
    });
  }

  return (
    <Modal closeModal={() => setShowAdvancedSettingsModal(false)} optionSelectModalState={null}>
      <AddEditModal
        cancelButtonOnClick={() => setShowAdvancedSettingsModal(false)}
        saveButtonOnClick={() => {
          onRequestSave(eventDiff);
        }}
        saveDisabled={!Number.isInteger(mergedEvent.checkoutTime)}
        setOptionSelectModalState={() => {
          // do nothing
        }}
        title={"Advanced Options"}
        editing={false}
      >
        <div className="advancedSettings-container">
          <LabeledSwitch
            Switch={
              <LabeledSwitch.Switch checked={mergedEvent.private} onChange={toggleEventPrivate} />
            }
            title="Make this event Private"
            tooltip={
              <ToolTip text="A private event will only be accessible via link, and is hidden on your storefront otherwise." />
            }
          />
          <Spacer size="XLarge" />
          <LabeledInput
            label="Checkout Time"
            Input={
              <Input
                value={
                  Number.isInteger(mergedEvent.checkoutTime)
                    ? mergedEvent.checkoutTime / (60 * 1000)
                    : ""
                }
                onChange={(val) => setCheckoutTime(val)}
                withUnit="right"
                unit="minutes"
                style={{ width: "150px" }}
              />
            }
            tooltip={
              <ToolTip
                placement="center"
                text="This value determines how much time customers have to checkout before their cart expires."
              />
            }
          />
        </div>
      </AddEditModal>
    </Modal>
  );
}
