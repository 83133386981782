// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./FilterDropdown.Button.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";
import { default as Dialog } from "./FilterDropdown.Button.Dialog";

const styles = [
  {
    type: "default",
    layers: {
      La6PfPpgss: {
        none: {
          htmlElement: "nav",
        },
      },
      TfHEcZ6suV: {
        none: {
          text: "Label",
        },
      },
      VzDAp53EPC: {
        none: {
          icon: icons.chevronDown,
          useMask: true,
        },
      },
      J3QeTrBjKV: {
        none: {
          htmlElement: "article",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "open",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "open",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "open",
  },
];

export const ButtonContext = createContext(null);

function Button(_props) {
  const defaults = useContext(ButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="HzXse2Y1DC"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "HzXse2Y1DC",
        scope: "WjPqZf8ktE",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Container_La6PfPpgss"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_WjPqZf8ktE_La6PfPpgss"
            }
            key={"La6PfPpgss"}
            addSpacing={true}
            element={getStyle("La6PfPpgss", "htmlElement")}
          >
            <TextPrimitive
              id={"label_TfHEcZ6suV"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_WjPqZf8ktE_TfHEcZ6suV"
              }
              key={"TfHEcZ6suV"}
              text={
                exists(props.label)
                  ? props.label
                  : getStyle("TfHEcZ6suV", "text")
              }
              element={getStyle("TfHEcZ6suV", "htmlElement")}
            />
            <SpacerPrimitive
              id={"Spacer_XMMnHzqgBL"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_WjPqZf8ktE_XMMnHzqgBL"
              }
              key={"XMMnHzqgBL"}
            />
            <IconPrimitive
              id={"Icon_VzDAp53EPC"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_WjPqZf8ktE_VzDAp53EPC"
              }
              key={"VzDAp53EPC"}
              useMask={getStyle("VzDAp53EPC", "useMask")}
              src={
                exists(props.icon) ? props.icon : getStyle("VzDAp53EPC", "icon")
              }
            />
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"dialog_J3QeTrBjKV"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_WjPqZf8ktE_J3QeTrBjKV"
            }
            key={"J3QeTrBjKV"}
            addSpacing={false}
            element={getStyle("J3QeTrBjKV", "htmlElement")}
          >
            {props.Dialog === undefined ? (
              <Dialog key={"CzHgdf9h1c"} />
            ) : (
              props.Dialog
            )}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

Button.__variants = [
  {
    name: "open",
    type: "variant",
  },
];

export default Button;
