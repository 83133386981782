import fetchRetry from "./FetchRetry";
import {
  GET_NEW_SUBSCRIPTION_SESSION_ID_START,
  GET_NEW_SUBSCRIPTION_SESSION_ID_SUCCESS,
  GET_NEW_SUBSCRIPTION_SESSION_ID_FAILURE,
  GET_BILLING_PORTAL_URL_START,
  GET_BILLING_PORTAL_URL_SUCCESS,
  GET_BILLING_PORTAL_URL_FAILURE,
  GET_SUBSCRIPTION_INFORMATION_START,
  GET_SUBSCRIPTION_INFORMATION_SUCCESS,
  GET_SUBSCRIPTION_INFORMATION_FAILURE,
  SAVE_PAYMENT_METHOD_START,
  SAVE_PAYMENT_METHOD_SUCCESS,
  SAVE_PAYMENT_METHOD_FAILURE,
  SET_PAYMENT_METHOD_ERROR,
  ADD_CUSTOMER_SUBSCRIPTION_START,
  ADD_CUSTOMER_SUBSCRIPTION_SUCCESS,
  ADD_CUSTOMER_SUBSCRIPTION_FAILURE,
  EDIT_CUSTOMER_SUBSCRIPTION_START,
  EDIT_CUSTOMER_SUBSCRIPTION_SUCCESS,
  EDIT_CUSTOMER_SUBSCRIPTION_FAILURE,
  EDIT_CUSTOMER_SUBSCRIPTION_ORDER_START,
  EDIT_CUSTOMER_SUBSCRIPTION_ORDER_SUCCESS,
  EDIT_CUSTOMER_SUBSCRIPTION_ORDER_FAILURE,
  CANCEL_CUSTOMER_SUBSCRIPTION_START,
  CANCEL_CUSTOMER_SUBSCRIPTION_SUCCESS,
  CANCEL_CUSTOMER_SUBSCRIPTION_FAILURE,
  EDIT_CUSTOMER_GLOBALS_START,
  EDIT_CUSTOMER_GLOBALS_SUCCESS,
  EDIT_CUSTOMER_GLOBALS_FAILURE,
  backendUrl,
} from "./types";

export const cancelCustomerSubscription = (phone, chefId, subscriptionId) => {
  return (dispatch) => {
    dispatch({ type: CANCEL_CUSTOMER_SUBSCRIPTION_START });
    try {
      return fetchRetry(backendUrl + "cancelCustomerSubscription", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chefId: chefId,
          subscriptionId: subscriptionId,
          phone: phone,
        }),
      })
        .then((response) => {
          if (response.error) {
            console.log(response.error);
            dispatch({
              type: CANCEL_CUSTOMER_SUBSCRIPTION_FAILURE,
              payload: response.error,
            });
          } else {
            dispatch({ type: CANCEL_CUSTOMER_SUBSCRIPTION_SUCCESS });
          }
        })
        .catch((exception) => {
          console.log(exception);
          dispatch({
            type: CANCEL_CUSTOMER_SUBSCRIPTION_FAILURE,
            payload: CANCEL_CUSTOMER_SUBSCRIPTION_FAILURE,
          });
        });
    } catch (exception) {
      console.log(exception);
      dispatch({
        type: CANCEL_CUSTOMER_SUBSCRIPTION_FAILURE,
        payload: CANCEL_CUSTOMER_SUBSCRIPTION_FAILURE,
      });
    }
  };
};

export const addCustomerSubscription = (chefId, subscriptionId, phone, cartType) => {
  return (dispatch) => {
    dispatch({ type: ADD_CUSTOMER_SUBSCRIPTION_START });
    try {
      return fetchRetry(backendUrl + "addCustomerSubscription", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chefId: chefId,
          subscriptionId: subscriptionId,
          phone: phone,
          cartType: cartType,
        }),
      })
        .then((response) => {
          if (response.error) {
            console.log(response.error);
            dispatch({
              type: ADD_CUSTOMER_SUBSCRIPTION_FAILURE,
              payload: response.error,
            });
          } else {
            dispatch({ type: ADD_CUSTOMER_SUBSCRIPTION_SUCCESS });
          }
        })
        .catch((exception) => {
          console.log(exception);
          dispatch({
            type: ADD_CUSTOMER_SUBSCRIPTION_FAILURE,
            payload: ADD_CUSTOMER_SUBSCRIPTION_FAILURE,
          });
        });
    } catch (exception) {
      console.log(exception);
      dispatch({
        type: ADD_CUSTOMER_SUBSCRIPTION_FAILURE,
        payload: ADD_CUSTOMER_SUBSCRIPTION_FAILURE,
      });
    }
  };
};

export const editCustomerSubscription = (chefId, subscriptionId, phone, updateDict) => {
  return (dispatch) => {
    dispatch({ type: EDIT_CUSTOMER_SUBSCRIPTION_START });
    try {
      return fetchRetry(backendUrl + "editCustomerSubscription", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chefId: chefId,
          subscriptionId: subscriptionId,
          phone: phone,
          updateDict: updateDict,
        }),
      })
        .then((response) => {
          if (response.error) {
            console.log(response.error);
            dispatch({
              type: EDIT_CUSTOMER_SUBSCRIPTION_FAILURE,
              payload: response.error,
            });
          } else {
            dispatch({ type: EDIT_CUSTOMER_SUBSCRIPTION_SUCCESS });
          }
        })
        .catch((exception) => {
          console.log(exception);
          dispatch({
            type: EDIT_CUSTOMER_SUBSCRIPTION_FAILURE,
            payload: EDIT_CUSTOMER_SUBSCRIPTION_FAILURE,
          });
        });
    } catch (exception) {
      console.log(exception);
      dispatch({
        type: EDIT_CUSTOMER_SUBSCRIPTION_FAILURE,
        payload: EDIT_CUSTOMER_SUBSCRIPTION_FAILURE,
      });
    }
  };
};

export const editCustomerSubscriptionOrder = (chefId, orderId, phone, updateDict) => {
  return (dispatch) => {
    dispatch({ type: EDIT_CUSTOMER_SUBSCRIPTION_ORDER_START });
    try {
      return fetchRetry(backendUrl + "editCustomerSubscriptionOrder", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chefId: chefId,
          orderId: orderId,
          phone: phone,
          updateDict: updateDict,
        }),
      })
        .then((response) => {
          if (response.error) {
            console.log(response.error);
            dispatch({
              type: EDIT_CUSTOMER_SUBSCRIPTION_ORDER_FAILURE,
              payload: response.error,
            });
          } else {
            dispatch({ type: EDIT_CUSTOMER_SUBSCRIPTION_ORDER_SUCCESS });
          }
        })
        .catch((exception) => {
          console.log(exception);
          dispatch({
            type: EDIT_CUSTOMER_SUBSCRIPTION_ORDER_FAILURE,
            payload: EDIT_CUSTOMER_SUBSCRIPTION_ORDER_FAILURE,
          });
        });
    } catch (exception) {
      console.log(exception);
      dispatch({
        type: EDIT_CUSTOMER_SUBSCRIPTION_ORDER_FAILURE,
        payload: EDIT_CUSTOMER_SUBSCRIPTION_ORDER_FAILURE,
      });
    }
  };
};

export const getSubscriptionInformation = (customerSubscriptionId) => {
  return (dispatch) => {
    dispatch({ type: GET_SUBSCRIPTION_INFORMATION_START });
    return fetchRetry(backendUrl + "getSubscriptionInformation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customerSubscriptionId: customerSubscriptionId,
      }),
    })
      .then((response) =>
        response.json().then((json) => {
          if (response.status >= 400) {
            dispatch({
              type: GET_SUBSCRIPTION_INFORMATION_FAILURE,
              payload: "SERVER_ERROR",
            });
          } else {
            dispatch({
              type: GET_SUBSCRIPTION_INFORMATION_SUCCESS,
              payload: json,
            });
          }
        })
      )
      .catch((exception) => {
        console.log(exception);
        dispatch({
          type: GET_SUBSCRIPTION_INFORMATION_FAILURE,
          payload: exception,
        });
      });
  };
};

export const getNewSubscriptionSessionId = (hostId) => {
  return (dispatch) => {
    dispatch({ type: GET_NEW_SUBSCRIPTION_SESSION_ID_START });
    return fetchRetry(backendUrl + "getNewSubscriptionSessionId", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        hostId: hostId,
      }),
    })
      .then((response) =>
        response.json().then((json) => {
          dispatch({
            type: GET_NEW_SUBSCRIPTION_SESSION_ID_SUCCESS,
            payload: json.sessionId,
          });
        })
      )
      .catch((exception) => {
        console.log(exception);
        dispatch({
          type: GET_NEW_SUBSCRIPTION_SESSION_ID_FAILURE,
          payload: exception,
        });
      });
  };
};

export const getBillingPortalUrl = (customerSubscriptionId) => {
  return (dispatch) => {
    dispatch({ type: GET_BILLING_PORTAL_URL_START });
    return fetchRetry(backendUrl + "getBillingPortalUrl", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customerSubscriptionId: customerSubscriptionId,
      }),
    })
      .then((response) =>
        response.json().then((json) => {
          dispatch({
            type: GET_BILLING_PORTAL_URL_SUCCESS,
            payload: json.url,
          });
        })
      )
      .catch((exception) => {
        console.log(exception);
        dispatch({
          type: GET_BILLING_PORTAL_URL_FAILURE,
          payload: "exception",
        });
      });
  };
};

export const savePaymentMethod = ({ phone, card, stripe, name }) => {
  return (dispatch) => {
    dispatch({ type: SAVE_PAYMENT_METHOD_START });
    return stripe
      .createPaymentMethod({
        type: "card",
        card: card,
        billing_details: {
          name: name,
        },
      })
      .then((result) => {
        if (result.error) {
          console.log("ERROR creating payment method");
          dispatch({
            type: SAVE_PAYMENT_METHOD_FAILURE,
            payload: result.error.message,
          });
        } else {
          fetchRetry(backendUrl + "savePaymentMethod", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              phone,
              paymentMethodId: result.paymentMethod.id,
            }),
          })
            .then((response) =>
              response.json().then(() => {
                if (response.status >= 400 && response.status < 600) {
                  dispatch({
                    type: SAVE_PAYMENT_METHOD_FAILURE,
                    payload: "There was an error. Try again or with another card.",
                  });
                  return;
                }
                dispatch({ type: SAVE_PAYMENT_METHOD_SUCCESS });
              })
            )
            .catch((exception) => {
              console.log(exception);
              dispatch({
                type: SAVE_PAYMENT_METHOD_FAILURE,
                payload: "There was an error. Try again or with another card.",
              });
            });
        }
      });
  };
};

export const setPaymentMethodError = (message) => {
  return (dispatch) => {
    dispatch({ type: SET_PAYMENT_METHOD_ERROR, payload: message });
  };
};

export const editCustomerGlobals = (phone, updateDict) => {
  return (dispatch) => {
    dispatch({ type: EDIT_CUSTOMER_GLOBALS_START });
    try {
      return fetchRetry(backendUrl + "editCustomerGlobals", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone: phone,
          updateDict: updateDict,
        }),
      })
        .then((response) => {
          if (response.error) {
            console.log(response.error);
            dispatch({
              type: EDIT_CUSTOMER_GLOBALS_FAILURE,
              payload: response.error,
            });
          } else {
            dispatch({ type: EDIT_CUSTOMER_GLOBALS_SUCCESS });
          }
        })
        .catch((exception) => {
          console.log(exception);
          dispatch({
            type: EDIT_CUSTOMER_GLOBALS_FAILURE,
            payload: EDIT_CUSTOMER_GLOBALS_FAILURE,
          });
        });
    } catch (exception) {
      console.log(exception);
      dispatch({
        type: EDIT_CUSTOMER_GLOBALS_FAILURE,
        payload: EDIT_CUSTOMER_GLOBALS_FAILURE,
      });
    }
  };
};
