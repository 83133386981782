// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./EmptyPagePlaceholder.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      WoJp9ByNE6: {
        none: {
          icon: icons.empty,
        },
      },
      RgTffNXZYV: {
        none: {
          text: "text",
          htmlElement: "span",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const EmptyPagePlaceholderContext = createContext(null);

function EmptyPagePlaceholder(_props) {
  const defaults = useContext(EmptyPagePlaceholderContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="NBn7whxVmH"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "NBn7whxVmH",
        scope: "8FMyXfr3Xf",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"icon-container_QeDYvUMBER"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_8FMyXfr3Xf_QeDYvUMBER"
            }
            key={"QeDYvUMBER"}
            addSpacing={false}
            element={getStyle("QeDYvUMBER", "htmlElement")}
          >
            {
              <IconPrimitive
                id={"Icon_WoJp9ByNE6"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_8FMyXfr3Xf_WoJp9ByNE6"
                }
                key={"WoJp9ByNE6"}
                useMask={getStyle("WoJp9ByNE6", "useMask")}
                src={getStyle("WoJp9ByNE6", "icon")}
              />
            }
          </ContainerPrimitive>
          <TextPrimitive
            id={"Text_RgTffNXZYV"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_8FMyXfr3Xf_RgTffNXZYV"
            }
            key={"RgTffNXZYV"}
            text={
              exists(props.text) ? props.text : getStyle("RgTffNXZYV", "text")
            }
            element={getStyle("RgTffNXZYV", "htmlElement")}
          />
          <SpacerPrimitive
            id={"Spacer_8Y3oN1bRL1"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_8FMyXfr3Xf_8Y3oN1bRL1"
            }
            key={"8Y3oN1bRL1"}
          />
          <ContainerPrimitive
            key={"B9wFYjjHkH"}
            id={"Slot_B9wFYjjHkH"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_8FMyXfr3Xf_B9wFYjjHkH"
            }
            addSpacing={false}
          >
            {props.children}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

EmptyPagePlaceholder.__variants = [];

export default EmptyPagePlaceholder;
