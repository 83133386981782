// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./DatePicker.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import {
  default as StartTime,
  LabeledInputContext as StartTimeContext,
  default as EndTime,
  LabeledInputContext as EndTimeContext,
  default as DateButton,
  LabeledInputContext as DateButtonContext,
} from "../Events/LabeledInput";
import { default as DateDropdown } from "./DatePicker.DateDropdown";

const styles = [
  {
    type: "default",
    layers: {
      A6YhTBA4jK: {
        none: {
          text: "to",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "range",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      S9SgHuFGCw: {
        none: {
          label: "Time",
        },
      },
      R48SHnxyeq: {
        none: {
          label: "End Time",
        },
      },
      "3S9Ce16hpu": {
        none: {
          label: "Date",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "range",
    layers: {
      S9SgHuFGCw: {},
      R48SHnxyeq: {},
      "3S9Ce16hpu": {},
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {
          S9SgHuFGCw: {},
          R48SHnxyeq: {},
          "3S9Ce16hpu": {},
        },
      },
      {
        propValue: "small",
        layers: {
          S9SgHuFGCw: {},
          R48SHnxyeq: {},
          "3S9Ce16hpu": {},
        },
      },
      {
        propValue: "med",
        layers: {
          S9SgHuFGCw: {},
          R48SHnxyeq: {},
          "3S9Ce16hpu": {},
        },
      },
      {
        propValue: "large",
        layers: {
          S9SgHuFGCw: {},
          R48SHnxyeq: {},
          "3S9Ce16hpu": {},
        },
      },
      {
        propValue: "xlarge",
        layers: {
          S9SgHuFGCw: {},
          R48SHnxyeq: {},
          "3S9Ce16hpu": {},
        },
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "range",
  },
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export const DatePickerContext = createContext(null);

function DatePicker(_props) {
  const defaults = useContext(DatePickerContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="Nt4BGDY74L"
      addSpacing={true}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "Nt4BGDY74L",
        scope: "T5ErURMxe6",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => [
        <ContainerPrimitive
          id={"inputs-container_FfWaRBCwAv"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_T5ErURMxe6_FfWaRBCwAv"
          }
          key={"FfWaRBCwAv"}
          addSpacing={true}
          element={getStyle("FfWaRBCwAv", "htmlElement")}
        >
          {[
            props.DateButton === undefined ? (
              <DateButton
                key={"3S9Ce16hpu"}
                {...getCompositeDefaultProps("3S9Ce16hpu")}
                id="DateButton_3S9Ce16hpu"
                className="__visly_reset_7X7HTLRqyD __visly_scope_T5ErURMxe6_3S9Ce16hpu"
              />
            ) : (
              <DateButtonContext.Provider
                key="3S9Ce16hpu-provider"
                value={{
                  key: "3S9Ce16hpu",
                  id: "DateButton_3S9Ce16hpu",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_T5ErURMxe6_3S9Ce16hpu",
                  ...getCompositeDefaultProps("3S9Ce16hpu"),
                }}
              >
                {props.DateButton}
              </DateButtonContext.Provider>
            ),
            <ContainerPrimitive
              id={"time-container_GNNwYHk3nK"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_T5ErURMxe6_GNNwYHk3nK"
              }
              key={"GNNwYHk3nK"}
              addSpacing={true}
              element={getStyle("GNNwYHk3nK", "htmlElement")}
            >
              {[
                props.StartTime === undefined ? (
                  <StartTime
                    key={"S9SgHuFGCw"}
                    {...getCompositeDefaultProps("S9SgHuFGCw")}
                    id="StartTime_S9SgHuFGCw"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_T5ErURMxe6_S9SgHuFGCw"
                  />
                ) : (
                  <StartTimeContext.Provider
                    key="S9SgHuFGCw-provider"
                    value={{
                      key: "S9SgHuFGCw",
                      id: "StartTime_S9SgHuFGCw",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_T5ErURMxe6_S9SgHuFGCw",
                      ...getCompositeDefaultProps("S9SgHuFGCw"),
                    }}
                  >
                    {props.StartTime}
                  </StartTimeContext.Provider>
                ),
                <TextPrimitive
                  id={"Text_A6YhTBA4jK"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_T5ErURMxe6_A6YhTBA4jK"
                  }
                  key={"A6YhTBA4jK"}
                  text={getStyle("A6YhTBA4jK", "text")}
                  element={getStyle("A6YhTBA4jK", "htmlElement")}
                />,
                props.EndTime === undefined ? (
                  <EndTime
                    key={"R48SHnxyeq"}
                    {...getCompositeDefaultProps("R48SHnxyeq")}
                    id="EndTime_R48SHnxyeq"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_T5ErURMxe6_R48SHnxyeq"
                  />
                ) : (
                  <EndTimeContext.Provider
                    key="R48SHnxyeq-provider"
                    value={{
                      key: "R48SHnxyeq",
                      id: "EndTime_R48SHnxyeq",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_T5ErURMxe6_R48SHnxyeq",
                      ...getCompositeDefaultProps("R48SHnxyeq"),
                    }}
                  >
                    {props.EndTime}
                  </EndTimeContext.Provider>
                ),
              ]}
            </ContainerPrimitive>,
          ]}
        </ContainerPrimitive>,
        props.DateDropdown === undefined ? (
          <DateDropdown key={"8nVueryCAj"} />
        ) : (
          props.DateDropdown
        ),
      ]}
    </RootPrimitive>
  );
}

DatePicker.StartTime = StartTime;
DatePicker.EndTime = EndTime;
DatePicker.DateButton = DateButton;
DatePicker.__variants = [
  {
    name: "range",
    type: "variant",
  },
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export default DatePicker;
