// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
export const dimensions = {
  sizeLarge: "32px",
  sizeMedium: "16px",
  sizeSmall: "8px",
  sizeXlarge: "40px",
};
export const radius = {
  radiusLarge: "16px",
  radiusMedium: "8px",
  radiusPill: "9999999px",
  radiusSmall: "4px",
  radiusStandard: "12px",
};
export const spacing = {
  paddingLarge: "16px",
  paddingMedium: "8px",
  paddingSmall: "4px",
  paddingXLarge: "24px",
  spaceLarge: "16px",
  spaceMedium: "8px",
  spaceNone: "0px",
  spaceSmall: "4px",
  spaceXLarge: "24px",
};
