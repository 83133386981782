// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Bullet.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive } from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "small",
    layers: {},
  },
  {
    type: "enum",
    propName: "kind",
    values: [
      {
        propValue: "danger",
        layers: {},
      },
      {
        propValue: "success",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "small",
    layers: {},
  },
  {
    type: "enum",
    propName: "kind",
    values: [
      {
        propValue: "danger",
        layers: {},
      },
      {
        propValue: "success",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "small",
  },
  {
    type: "enum",
    propName: "kind",
    propValues: ["danger", "success"],
  },
];

export const BulletContext = createContext(null);

function Bullet(_props) {
  const defaults = useContext(BulletContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="5NbUV11msS"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "5NbUV11msS",
        scope: "NBFhYJkZA3",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => null}
    </RootPrimitive>
  );
}

Bullet.__variants = [
  {
    name: "small",
    type: "variant",
  },
  {
    name: "kind",
    type: "group",
    variants: ["danger", "success"],
  },
];

export default Bullet;
