import React from "react";
import { styled } from "../../stitches.config";
import { ResponsiveContainer } from "recharts";
import { Column, H1, P, Row } from "../../hotplate-common/primitives/Containers";
import { Tooltip } from "../../hotplate-common/primitives/Tooltip";
import { IconButton } from "../../hotplate-common/primitives/IconButton";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";

const Responsive = styled(ResponsiveContainer, {
  ff: "$avenir",
  fw: "$semi_bold",
  color: "$gray11",
  fs: 12,
});

interface XAxisLabelProps {
  labels: string[];
}

const XAxisLabel: React.FC<XAxisLabelProps> = ({ labels }: XAxisLabelProps) => {
  return (
    <Row
      css={{
        width: "100%",
        justifyContent: "space-between",
        ff: "$avenir",
        fs: 12,
        fw: "$semi_bold",
        color: "$gray11",
        borderTop: "1px solid $gray10",
        paddingTop: "$xs",
        textAlign: "center",
      }}
    >
      {labels.map((label, i) => (
        <P key={i}>{label}</P>
      ))}
    </Row>
  );
};

interface ChartWrapperProps extends React.HTMLProps<HTMLDivElement> {
  title: string;
  tooltipText?: string;
  labels: string[];
  children: React.ReactNode;
}

export const ChartWrapper: React.FC<ChartWrapperProps> = ({
  title,
  tooltipText,
  labels,
  children,
  ...props
}: ChartWrapperProps) => {
  return (
    <Column
      css={{
        width: "100%",
        height: "100%",
        br: "$md",
        backgroundColor: "$white",
        boxShadow: "$elevation4",
        pad: "$md",
      }}
      {...props}
    >
      <Row css={{ gap: "$xxs", alignContent: "center", paddingBottom: "$sm", minHeight: 36 }}>
        <H1
          css={{
            ff: "$arboria",
            fs: "$lg",
            fw: "$bold",
            fill: "$violet12",
          }}
        >
          {title}
        </H1>
        {tooltipText && (
          <Tooltip content={tooltipText} clickable={true} css={null}>
            <IconButton size="xsmall" icon={<QuestionMarkCircledIcon />} rounded />
          </Tooltip>
        )}
      </Row>

      <Responsive aspect={16 / 9}>{children}</Responsive>
      <XAxisLabel labels={labels} />
    </Column>
  );
};
