// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Select.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import {
  SelectRoot,
  SelectOptionContainer,
  SelectButton,
} from "../_internal_select";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      "select-button-text": {
        none: {
          text: "Selected option",
        },
      },
      "2pmEsZq6Nc": {
        none: {
          icon: icons.chevronDown,
          useMask: true,
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "noButton",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "noButton",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "noButton",
  },
];

export const SelectContext = createContext(null);

function Select(_props) {
  const defaults = useContext(SelectContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <SelectRoot
      {...props}
      key="select-root"
      internal={{
        styles,
        projectId: "7X7HTLRqyD",
        layerId: "select-root",
        scope: "9cCvR1MveE",
        activeVariants,
      }}
      vislyChildren={(getStyle) => (
        <>
          <SelectButton
            key="select-button"
            id={"Button_select-button"}
            className="__visly_reset_7X7HTLRqyD __visly_scope_9cCvR1MveE_select-button"
          >
            {
              <ContainerPrimitive
                id={"Container_AVCjGJDaeq"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_9cCvR1MveE_AVCjGJDaeq"
                }
                key={"AVCjGJDaeq"}
                addSpacing={false}
                element={getStyle("AVCjGJDaeq", "htmlElement")}
              >
                <TextPrimitive
                  id={"text_select-button-text"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_9cCvR1MveE_select-button-text"
                  }
                  key={"select-button-text"}
                  text={
                    exists(props.label)
                      ? props.label
                      : getStyle("select-button-text", "text")
                  }
                  element={getStyle("select-button-text", "htmlElement")}
                />
                <SpacerPrimitive
                  id={"Spacer_Bo5pqdBErn"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_9cCvR1MveE_Bo5pqdBErn"
                  }
                  key={"Bo5pqdBErn"}
                />
                <IconPrimitive
                  id={"icon_2pmEsZq6Nc"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_9cCvR1MveE_2pmEsZq6Nc"
                  }
                  key={"2pmEsZq6Nc"}
                  useMask={getStyle("2pmEsZq6Nc", "useMask")}
                  src={
                    exists(props.icon)
                      ? props.icon
                      : getStyle("2pmEsZq6Nc", "icon")
                  }
                />
              </ContainerPrimitive>
            }
          </SelectButton>
          <SelectOptionContainer
            key="select-menu"
            id={"Menu_select-menu"}
            className="__visly_reset_7X7HTLRqyD __visly_scope_9cCvR1MveE_select-menu"
            innerKey="select-menu"
            addSpacing={true}
            useButtonWidth={true}
          >
            {props.children}
          </SelectOptionContainer>
        </>
      )}
    >
      {props.children}
    </SelectRoot>
  );
}

Select.__variants = [
  {
    name: "noButton",
    type: "variant",
  },
];

export default Select;
