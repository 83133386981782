// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./SetLocationTime.DateContainer.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as DateCard } from "../Checkout/CheckoutTimeCard";

const styles = [
  {
    type: "default",
    layers: {
      "5wmTJUgEG5": {
        none: {
          text: "Choose Date",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      R3mJyC7gZK: {},
    },
  },
];

const variantPropTypes = [];

export const DateContainerContext = createContext(null);

function DateContainer(_props) {
  const defaults = useContext(DateContainerContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="7tYYgC5iNV"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "7tYYgC5iNV",
        scope: "T7qxMRVrLc",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <TextPrimitive
            id={"Text_5wmTJUgEG5"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_T7qxMRVrLc_5wmTJUgEG5"
            }
            key={"5wmTJUgEG5"}
            text={getStyle("5wmTJUgEG5", "text")}
            element={getStyle("5wmTJUgEG5", "htmlElement")}
          />
          <SpacerPrimitive
            id={"Spacer_P9Nw9T4MrS"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_T7qxMRVrLc_P9Nw9T4MrS"
            }
            key={"P9Nw9T4MrS"}
          />
          <ContainerPrimitive
            key={"R58X4Eg6mx"}
            id={"Slot_R58X4Eg6mx"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_T7qxMRVrLc_R58X4Eg6mx"
            }
            addSpacing={true}
          >
            {props.children}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

DateContainer.DateCard = DateCard;
DateContainer.__variants = [];

export default DateContainer;
