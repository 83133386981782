/* eslint-disable react/prop-types */
import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import { FirebaseContext } from "../../firebaseSocket";
import ReactLoading from "react-loading";
import CheckoutForm from "../components/CheckoutForm";
import CheckoutCard from "../components/CheckoutCard";
import UserInfoDetail from "../components/UserInfoDetail";
import ProcessingPayment from "../components/ProcessingPayment";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import "../css/Checkout.css";
import PhoneLogin from "../../hotplate-common/PhoneLogin";
import CountdownTimer from "../components/CountdownTimer";
import { Input as VislyInput, Segmented, Checkbox } from "../../visly/Primitives";
import { textstyles, colors, icons } from "../../visly";
import { LabeledInput } from "../../visly/Events";

import { TipSelect } from "../../visly/Menu/Customer";
import { SetLocationTime } from "../../visly/Checkout";
import { styled } from "../../stitches.config";

import { Button, Loading as ButtonLoading } from "../../hotplate-common/primitives/Button";
import { Input } from "../../hotplate-common/primitives/Input";

import {
  getSubtotal,
  toFixed,
  getCartItemPrice,
  getSortedLocationsEvent,
  getAddressString,
  getReadableTimeSlot,
  getMenuItemOptionsString,
  priceInputIsValid,
  getFormattedPhone,
  getUnFormattedPhone,
  getSortedEventTimeSlotOptions,
  capitalizeFirstLetter,
  isEventOnDemandActive,
  getTimestampDayOfWeekMonthDate,
} from "@hotplate/utils-ts/helperFunctions";

import {
  setCartId as setCartIdUnconnected,
  setToastPopupInfo as setToastPopupInfoUnconnected,
} from "../actions";
import { trackCheckoutStarted, trackCheckoutStepViewed } from "../analytics";
import { identify } from "../../hotplate-common/analytics";
import { Separator } from "../../hotplate-common/primitives/Separator";
import { useMutation } from "@tanstack/react-query";
import {
  applyGiftOrDiscountCodeMutation,
  ensureOk,
  initializeCartPaymentIntentMutation,
  reserveTimeSlotMutation,
} from "../../mutations";
import { Navigate, useNavigate } from "react-router-dom";
import { usePrevious } from "../../hooks";
import { PuffLoader } from "../../hotplate-common/loaders/PuffLoader";
import { H as Highlight } from "highlight.run";
import Cohere from "cohere-js";
import * as Sentry from "@sentry/react";
import { logger, popLoggerContext, pushLoggerContext } from "../../logger";

function useConnectedFn(fn, dispatch) {
  return useCallback(
    (...args) => {
      return fn(...args)(dispatch);
    },
    [fn, dispatch]
  );
}

const Flex = styled("div", {
  display: "flex",
});
const Text = styled("div", {});

function DiscountCodeInput({ cartId, chefId }) {
  const dispatch = useDispatch();
  const setToastPopupInfo = useConnectedFn(setToastPopupInfoUnconnected, dispatch);

  const applyGiftOrDiscountCode = useMutation(applyGiftOrDiscountCodeMutation);

  const [inputValue, setInputValue] = useState("");

  return (
    <Flex css={{ flexDirection: "column", alignItems: "flex-end", my: 24 }}>
      <Flex css={{ alignItems: "center" }}>
        <Text css={{ ff: "$avenir", fw: "$semi_bold", fs: 14, color: "$gray11", marginRight: 12 }}>
          Gift / Discount Code:
        </Text>
        <Input
          css={{ width: "10ch", textAlign: "center" }}
          maxLength={10}
          status={
            inputValue.length < 3 && inputValue.length > 0
              ? "warning"
              : applyGiftOrDiscountCode.isSuccess &&
                applyGiftOrDiscountCode.data?.json.status === "code_not_found"
              ? "error"
              : "default"
          }
          type="text"
          size="small"
          placeholder="MYCODE1"
          value={inputValue}
          onChange={(event) => {
            setInputValue(event.target.value.toUpperCase());
          }}
        />
      </Flex>
      {inputValue.length > 0 && applyGiftOrDiscountCode.data?.json.status !== "ok" && (
        <Button
          css={{ marginTop: 8 }}
          size="small"
          color="success"
          variant="tinted"
          onClick={async () => {
            let data;
            try {
              const { json } = ensureOk(
                await applyGiftOrDiscountCode.mutateAsync({
                  cartId,
                  chefId,
                  code: inputValue,
                })
              );
              data = json;
            } catch (e) {
              setToastPopupInfo({
                text: `Please try again. A problem occurred while trying to apply the code: ${e}`,
                type: "error",
              });
              throw e;
            }
          }}
          loading={applyGiftOrDiscountCode.isLoading}
        >
          Apply
          <ButtonLoading color="#FFFFFF" />
        </Button>
      )}
      {applyGiftOrDiscountCode.isSuccess &&
        (applyGiftOrDiscountCode.data?.json.discountCode ||
        applyGiftOrDiscountCode.data?.json.giftCard ? (
          <Text css={{ ff: "$avenir", fw: "$bold", fs: 15, color: "$success11", marginTop: 8 }}>
            Discount applied!
          </Text>
        ) : (
          <Text css={{ ff: "$avenir", fw: "$bold", fs: 15, color: "$error11", marginTop: 8 }}>
            Not a valid code.
          </Text>
        ))}
    </Flex>
  );
}

/**
 * This component guarantees that the Checkout component only ever receives
 * cart and cartEvent props that are truthy and matching.
 */
export default function CheckoutGuard(props) {
  const { cart, cartEvent, isCartStuffLoading } = props;
  const firebaseContext = useContext(FirebaseContext);
  const { siteSettings, getSiteSettingsLoading, getSiteSettingsError } = useSelector(
    (state) => state.storefront
  );

  // Connect to site settings based on the cart's chefId.
  useEffect(() => {
    if (cart?.chefId && !getSiteSettingsLoading && siteSettings?.chefId !== cart.chefId) {
      firebaseContext.api.getSiteSettings(cart.chefId);
    }
  }, [firebaseContext, cart?.chefId, getSiteSettingsLoading, siteSettings?.chefId]);

  return isCartStuffLoading || getSiteSettingsLoading ? (
    <PuffLoader />
  ) : getSiteSettingsError ? (
    <p>
      An error occurred: {getSiteSettingsError}
      Please try refreshing the page.
    </p>
  ) : !cart || !cartEvent ? (
    siteSettings?.chefId ? (
      <Navigate to={`/${siteSettings.chefId}`} />
    ) : (
      <Navigate to="/" />
    )
  ) : !Array.isArray(cart.cartItems) ||
    cart.cartItems.length === 0 ||
    // TODO should use getNextAvailablePickupSlot
    getSortedLocationsEvent({
      event: cartEvent,
      includePickup: cart.fulfillmentType === "pickup",
      includeDelivery: cart.fulfillmentType === "delivery",
    }).length === 0 ||
    // TODO these next two subtotal requirement checks are duplicated with StorefrontCartModal
    (cart.fulfillmentType === "delivery" &&
      cartEvent.deliverySettings &&
      typeof cartEvent.deliverySettings.minimumSubtotal === "string" &&
      cartEvent.deliverySettings.minimumSubtotal !== "" &&
      parseFloat(getSubtotal(cart.cartItems)) <
        parseFloat(cartEvent.deliverySettings.minimumSubtotal)) ||
    parseFloat(getSubtotal(cart.cartItems)) < 0.5 ? (
    <Navigate to={`/${cart.chefId}?event=${cart.eventId.substring(0, 5)}`} />
  ) : (
    <Checkout {...props} />
  );
}

function Checkout({ cart, cartEvent, isPaymentProcessing, setIsPaymentProcessing }) {
  const prevIsPaymentProcessing = usePrevious(isPaymentProcessing);

  const navigate = useNavigate();

  const { cartId } = useSelector((state) => state.cart);

  const {
    siteSettings,
    getSiteSettingsLoading,
    customerAddressDict,
    customerDeliveryInstructions,
    deliveryFee,
  } = useSelector((state) => state.storefront);
  const userInfo = useSelector((state) => state.login.userInfo);

  const dispatch = useDispatch();
  const setToastPopupInfo = useConnectedFn(setToastPopupInfoUnconnected, dispatch);
  const setCartId = useConnectedFn(setCartIdUnconnected, dispatch);

  const isOnDemandInitial = isEventOnDemandActive(cartEvent);

  const sortedLocations = getSortedLocationsEvent({
    event: cartEvent,
    includePickup: cart.fulfillmentType === "pickup",
    includeDelivery: cart.fulfillmentType === "delivery",
  });

  const tipOptions = useMemo(
    () => [...(siteSettings.tipOptions || [".15", ".2", ".25"]), "other"], // if you change this line, search the codebase for TIP_OPTION_DEFAULT_523894 and keep in sync
    [siteSettings.tipOptions]
  );

  const [action, setAction] = useState(userInfo.phone ? "time-slots" : "phone-login");
  const [tipOption, setTipOption] = useState(
    siteSettings.isTipDefaultZero ? "other" : tipOptions[1]
  );
  const [manualTipInput, setManualTipInput] = useState(
    siteSettings.isTipDefaultZero
      ? "0.00"
      : toFixed(parseFloat(getSubtotal(cart.cartItems)) * parseFloat(".15"), 2)
  );
  const [email, setEmail] = useState(userInfo.email);
  const [wasEmailTouched, setWasEmailTouched] = useState(false);
  const [firstName, setFirstName] = useState(userInfo.firstName);
  const [wasFirstNameTouched, setWasFirstNameTouched] = useState(false);
  const [lastName, setLastName] = useState(userInfo.lastName);
  const [wasLastNameTouched, setWasLastNameTouched] = useState(false);
  const [fullName, setFullName] = useState(
    typeof userInfo.firstName === "string"
      ? userInfo.firstName + (userInfo.lastName ? " " + userInfo.lastName : "")
      : ""
  );
  const [phone, setPhone] = useState(getFormattedPhone(userInfo.phone).replace("+1", ""));
  const [isOnDemand] = useState(isOnDemandInitial); // snapshot isEventOnDemandActive at start of checkout
  const [customFields] = useState(cartEvent.customFields); // WARN UNSAFE snapshot cartEvent.customFields, if cartEvent changes this will be STALE
  const [customFieldValues, setCustomFieldValues] = useState(
    Array.isArray(customFields) ? customFields.map((customField) => customField.defaultValue) : []
  );
  const [guestLoginEnabled, setGuestLoginEnabled] = useState(false);

  // TODO: initialize selectedLocation, selectedtimeSlotOption, and selectedTimeSlot
  // based on the selection that may already be in the cart
  const [selectedLocationIndex, setSelectedLocationIndex] = useState(
    isOnDemandInitial || sortedLocations.length == 1 ? 0 : -1
  );

  const selectedLocation =
    selectedLocationIndex === -1 ? null : sortedLocations[selectedLocationIndex];

  const timeSlotOptions =
    cart.fulfillmentType === "delivery"
      ? getSortedEventTimeSlotOptions({
          event: cartEvent,
          menuItems: cart.cartItems,
          customerCartId: cartId,
          includeDelivery: true,
          customerZip: customerAddressDict ? customerAddressDict.zip : "",
        })
      : cart.fulfillmentType === "pickup" && selectedLocation
      ? getSortedEventTimeSlotOptions({
          event: cartEvent,
          menuItems: cart.cartItems,
          customerCartId: cartId,
          includePickup: true,
          locationId: selectedLocation.id,
        })
      : undefined;

  // a day
  const [selectedTimeSlotOptionIndex, setSelectedTimeSlotOptionIndex] = useState(
    isOnDemandInitial || (Array.isArray(timeSlotOptions) && timeSlotOptions.length == 1) ? 0 : -1
  );

  // e.g., 2 PM - 3 PM, 2:15 PM
  const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState(
    !isOnDemandInitial && // force selection for on demand so that if onDemandWindowsEnabled, reserveTimeSlot endpoint will be called
      Array.isArray(timeSlotOptions) &&
      timeSlotOptions.length == 1 &&
      Array.isArray(timeSlotOptions[0].timeSlots) &&
      timeSlotOptions[0].timeSlots.filter((timeSlot) => {
        return !timeSlot.disabled;
      }).length == 1
      ? 0 // automatically select the only available time slot for the user
      : -1
  );

  const selectedTimeSlot =
    selectedTimeSlotOptionIndex !== -1 &&
    selectedTimeSlotIndex !== -1 &&
    timeSlotOptions[selectedTimeSlotOptionIndex] &&
    timeSlotOptions[selectedTimeSlotOptionIndex].timeSlots
      ? timeSlotOptions[selectedTimeSlotOptionIndex].timeSlots[selectedTimeSlotIndex]
      : null;

  const initializeCartPaymentIntent = useMutation(initializeCartPaymentIntentMutation, {
    retry: 2,
  });
  const reserveTimeSlot = useMutation(reserveTimeSlotMutation, { retry: 2 });

  // TODO convert these error functions to simple values
  const phoneError = useCallback(() => {
    if (getUnFormattedPhone(phone).length === 0) return "required";
    if (getUnFormattedPhone(phone).length === 10) return "valid";
    return "invalid";
  }, [phone]);

  function fullNameError() {
    if (!fullName?.length) return "You must enter your first name.";
    return "";
  }

  function firstNameError() {
    if (!firstName?.length) return "You must enter your first name.";
    return "";
  }

  function lastNameError() {
    if (!lastName?.length) return "You must enter your last name.";
    return "";
  }

  function emailError() {
    const emailPattern =
      /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    if (!emailPattern.test(email)) return "This is an invalid email, change it and try again.";
    return "";
  }

  function confirmUserDetails() {
    if (firstNameError() || lastNameError() || emailError()) {
      return;
    }
    setAction("confirm-payment");
  }

  function getTipPrice() {
    // Before the user reaches the confirm-payment section, the tip should be 0
    if (!isOnDemand && action !== "confirm-payment") {
      return "0.00";
    }
    if (tipOption === "other") {
      if (manualTipInput === "") return "0.00";
      return toFixed(parseFloat(manualTipInput), 2);
    }
    return toFixed(parseFloat(getSubtotal(cart.cartItems)) * parseFloat(tipOption), 2);
  }

  function setTip(price) {
    if (!priceInputIsValid(price)) return;
    setManualTipInput(price);
  }

  function getCustomFieldInput(customField, index) {
    if (customField.type === "segmented") {
      return (
        <Segmented
          value={customFieldValues[index]}
          onChange={(value) => {
            const nextCustomFieldValues = _.cloneDeep(customFieldValues);
            nextCustomFieldValues[index] = value;
            setCustomFieldValues(nextCustomFieldValues);
          }}
        >
          {customField.values.map((value) => (
            <Segmented.Button text={value} value={value} key={value} />
          ))}
        </Segmented>
      );
    }
    if (customField.type === "checkbox") {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            cursor: "pointer",
          }}
          onClick={() => {
            const nextCustomFieldValues = _.cloneDeep(customFieldValues);
            nextCustomFieldValues[index] = !customFieldValues[index];
            setCustomFieldValues(nextCustomFieldValues);
          }}
        >
          <Checkbox checked={customFieldValues[index]} icon={icons.checkmark} />
          <span style={{ ...textstyles.body, color: colors.gray700 }}>{customField.text}</span>
        </div>
      );
    }
    return null;
  }

  // TODO remove this after react-router ScrollRestoration is added
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  // Following Stripe documentation suggestions,
  // ensure the cart object gets a valid paymentIntentId
  // in it as soon as Checkout mounts.
  // Until the response comes in, the "confirm-payment"
  // section should be loading.
  useEffect(() => {
    if (isOnDemand || userInfo.phone) {
      initializeCartPaymentIntent.mutateAsync
        .call(undefined, { cartId, isOnDemand, phone: userInfo.phone })
        .then(({ json }) => {
          if (json.error) {
            // The payment intent could not be created or the cart
            // is already associated with a settled payment intent.
            setCartId(uuid());
          }
        })
        .catch((e) => {
          setToastPopupInfo({
            text: `Please refresh the page. An error occurred: ${e}`,
            type: "error",
          });
          throw e;
        });
    }
  }, [
    setToastPopupInfo,
    setCartId,
    cartId,
    initializeCartPaymentIntent.mutateAsync,
    isOnDemand,
    userInfo.phone,
  ]);

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  useEffect(() => {
    trackCheckoutStarted({
      event_id: cart.eventId,
      cart_id: cartId,
      fulfillment_type: cart.fulfillmentType,
      order_type: isOnDemand ? "walk_up" : cartEvent.ticketed ? "ticket" : "pre-order",
    });
    logger.debug(
      `Beginning checkout.
eventId: ${cart.eventId}
ticketed: ${cartEvent.ticketed}
isOnDemand: ${isOnDemand}`
    );
  }, [cart.fulfillmentType, cart.eventId, cartEvent.ticketed, cartId, isOnDemand]); // none of these should ever change lol

  useEffect(() => {
    logger.debug(`guestLoginEnabled: ${guestLoginEnabled}`);
  }, [guestLoginEnabled]);

  const targetScrollDisable = useRef();
  useEffect(() => {
    if (!prevIsPaymentProcessing && isPaymentProcessing) {
      targetScrollDisable.current = document.querySelector("#checkoutOverlay");
      disableBodyScroll(targetScrollDisable.current);
    }
    if (prevIsPaymentProcessing && !isPaymentProcessing) {
      enableBodyScroll(targetScrollDisable.current);
    }
  });

  // Track checkout actions for funnel
  useEffect(() => {
    trackCheckoutStepViewed(action, cartId);
    logger.debug(`Checkout action: ${action}`);
  }, [cartId, action]);

  // If userInfo comes in from a login, overwrite user details fields
  useEffect(() => {
    if (userInfo) {
      setFirstName(userInfo.firstName);
      setLastName(userInfo.lastName);
      setPhone(getFormattedPhone(userInfo.phone).replace("+1", ""));
      setEmail(userInfo.email);

      logger.debug("userInfo came in from a login (may have been guest login).");
    }
  }, [userInfo]);

  // Note that we identify by phone number even if the number
  // didn't come into userInfo from PhoneLogin; on demand orders
  // can put in a phone number that they don't own since they
  // don't have to login.
  useEffect(() => {
    if (phoneError() === "valid") {
      const id = userInfo?.phone || getUnFormattedPhone(phone);
      identify(id, userInfo || {});
      Highlight.identify(id);
      Cohere.identify(id);
      Sentry.setUser({ id: id });

      pushLoggerContext({ phone: id });
      return () => {
        popLoggerContext();
      };
    }
  }, [phone, phoneError, userInfo]);

  // If siteSettings changes causing the current tipOption to be invalid, fix it
  useEffect(() => {
    if (!tipOptions.includes(tipOption)) {
      setTipOption(tipOptions[1]);
    }
  }, [tipOptions, tipOption]);

  let step = "";
  if (selectedLocationIndex === -1 && cart.fulfillmentType === "pickup") {
    step = "location";
  } else if (
    (selectedLocationIndex !== -1 || cart.fulfillmentType === "delivery") &&
    selectedTimeSlotOptionIndex === -1
  ) {
    step = "date";
  } else {
    step = "time";
  }

  if (initializeCartPaymentIntent.isError) {
    return (
      <div>
        <p>An error occurred while setting up checkout. Please refresh the page.</p>
        <p style={{ color: "red" }}>{"" + initializeCartPaymentIntent.error}</p>
      </div>
    );
  }

  if (isOnDemand) {
    return (
      <div className="checkoutMain">
        <div
          id="checkoutOverlay"
          className={isPaymentProcessing ? "checkoutOverlayShowing" : "checkoutOverlayNotShowing"}
        >
          {isPaymentProcessing && <ProcessingPayment />}
        </div>
        <p className="checkoutRestaurantName">{siteSettings.restaurantName}</p>
        <CountdownTimer css={{ mb: "$md" }} size="large" withTooltip />
        <div className="checkoutInputContainer">
          <p className="checkoutInputContainerLeft" style={{ marginBottom: 24 }}>
            Order Summary
          </p>
          <UserInfoDetail
            showError={false}
            value={phone}
            onChange={(val) => setPhone(getFormattedPhone("+1" + val).replace("+1", ""))}
            validationType={phoneError()}
            type="tel"
            placeholder="Phone"
            label="+1 🇺🇸"
            width={208}
            marginRight={0}
          />
          <UserInfoDetail
            showError={false}
            value={fullName}
            onChange={(val) => setFullName(val)}
            validationType={fullNameError() ? "required" : "valid"}
            placeholder="Full Name"
            width={208}
            marginRight={0}
          />
          {cartEvent.onDemandWindowsEnabled && (
            <SetLocationTime
              style={{ marginTop: 24 }}
              step={step}
              LocationContainer={null}
              DateContainer={null}
              TimeContainer={
                step === "time" && (
                  <SetLocationTime.TimeContainer
                    text={`Choose time (${
                      new Date()
                        .toLocaleTimeString("en-us", { timeZoneName: "short" })
                        .split(" ")[2]
                    })`}
                  >
                    {selectedTimeSlotOptionIndex !== -1 &&
                      timeSlotOptions[selectedTimeSlotOptionIndex].timeSlots.map(
                        (timeSlot, timeSlotIndex) => {
                          if (timeSlot.hidden) return null;
                          return (
                            <SetLocationTime.TimeContainer.TimeCard
                              style={timeSlot.range ? { flex: "50% 0 1" } : { flex: "33% 0 1" }}
                              key={timeSlotIndex}
                              disabled={timeSlot.disabled || reserveTimeSlot.isLoading}
                              loading={
                                reserveTimeSlot.isLoading && timeSlotIndex === selectedTimeSlotIndex
                              }
                              loadingAnimation={
                                <ReactLoading
                                  type={"bubbles"}
                                  color={"#FFFFFF"}
                                  height={42}
                                  width={42}
                                />
                              }
                              title={getReadableTimeSlot(timeSlot)}
                              onClick={async () => {
                                setSelectedTimeSlotIndex(timeSlotIndex);
                                // TODO replace this hacky copy pasted code
                                const newSelectedTimeSlot =
                                  selectedTimeSlotOptionIndex !== -1 &&
                                  timeSlotIndex !== -1 &&
                                  timeSlotOptions[selectedTimeSlotOptionIndex] &&
                                  timeSlotOptions[selectedTimeSlotOptionIndex].timeSlots
                                    ? timeSlotOptions[selectedTimeSlotOptionIndex].timeSlots[
                                        timeSlotIndex
                                      ]
                                    : null;
                                try {
                                  ensureOk(
                                    await reserveTimeSlot.mutateAsync({
                                      cartId,
                                      chefId: cart.chefId,
                                      eventId: cart.eventId,
                                      timeSlot: newSelectedTimeSlot,
                                      location: selectedLocation,
                                    })
                                  );
                                } catch (e) {
                                  setSelectedTimeSlotIndex(-1); // unset so it can be selected again
                                  setToastPopupInfo({
                                    text: `Please try again. An error occurred while reserving the time slot: ${e}`,
                                    type: "error",
                                  });
                                  throw e;
                                }
                              }}
                              selected={timeSlotIndex === selectedTimeSlotIndex}
                            />
                          );
                        }
                      )}
                  </SetLocationTime.TimeContainer>
                )
              }
            />
          )}
          {cartEvent.onDemandWindowsEnabled && reserveTimeSlot.error && (
            <p style={{ ...textstyles.button, color: "red", paddingTop: 12 }}>
              {"" + reserveTimeSlot.error}
            </p>
          )}
          {Array.isArray(customFields) &&
            customFields.map((customField, index) => (
              <LabeledInput
                key={index}
                style={{ margin: "24px 0px" }}
                label={customField.type === "checkbox" ? "" : customField.title}
                Input={getCustomFieldInput(customField, index)}
              />
            ))}
          {typeof cartEvent.additionalTimeSlotInfoText === "string" && (
            <p style={{ ...textstyles.body, color: colors.gray700 }}>
              {cartEvent.additionalTimeSlotInfoText}
            </p>
          )}
          {cartEvent.onDemandWindowsEnabled &&
            Array.isArray(timeSlotOptions) &&
            timeSlotOptions.filter((timeSlotOption) => {
              return (
                timeSlotOption.timeSlots.filter((timeSlot) => {
                  return !timeSlot.disabled;
                }).length > 0
              );
            }).length === 0 && (
              <p style={{ ...textstyles.button, color: "red", paddingTop: 12 }}>
                Sorry, there are no order times left for these items.
              </p>
            )}
          <div className="cartScrollContainer">
            <div className="cartItemsContainer">
              {cart.cartItems.map((cartItem, cartItemIndex) => (
                <div className="cartItemContainer" key={cartItemIndex}>
                  <div className="cartItemTop">
                    <p className="cartItemName">
                      {cartItem.quantity} {cartItem.title}
                    </p>
                    <p className="cartItemPrice">${getCartItemPrice(cartItem)}</p>
                  </div>
                  <div
                    className="cartItemTags"
                    style={{
                      borderBottom:
                        cartItemIndex !== cart.cartItems.length - 1 ? "1px solid #EDEDED" : "none",
                    }}
                  >
                    <p className="cartItemTag">{getMenuItemOptionsString(cartItem)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div style={{ display: "flex", flexFlow: "column", marginBottom: 32 }}>
            <div className="deliveryDriverTipContainer">
              <TipSelect
                value={tipOption}
                text="Tip"
                aria-label="Tip"
                style={{ alignSelf: "flex-start", alignItems: "center" }}
                onChange={(value) => setTipOption(value)}
              >
                {tipOptions.map((value, index) => (
                  <TipSelect.Button
                    style={{
                      minWidth: "25%",
                    }}
                    value={value}
                    aria-label={value}
                    text={
                      value === "other"
                        ? "other"
                        : "$" +
                          toFixed(parseFloat(getSubtotal(cart.cartItems)) * parseFloat(value), 2)
                    }
                    key={index}
                  />
                ))}
              </TipSelect>
            </div>
            {tipOption === "other" && (
              <div className="deliveryOtherInputContainer">
                <VislyInput
                  style={{ width: "100px" }}
                  onChange={(value) => setTip(value)}
                  onBlur={() => setTip(toFixed(parseFloat(manualTipInput), 2))}
                  value={manualTipInput}
                  placeholder="0.00"
                  withUnit="left"
                  unit="$"
                />
              </div>
            )}
          </div>
          <DiscountCodeInput cartId={cartId} chefId={cart.chefId} />
          <div className="cartCheckoutInformation">
            <CheckoutForm
              // Shop info
              isPaymentProcessing={isPaymentProcessing}
              setIsPaymentProcessing={setIsPaymentProcessing}
              cart={cart}
              cartEvent={cartEvent}
              // Checkout context
              showCheckout={true}
              isOnDemand={true}
              guestLoginEnabled={undefined}
              paymentIntentClientSecret={
                initializeCartPaymentIntent.isLoading
                  ? undefined
                  : initializeCartPaymentIntent.data?.json.client_secret
              }
              customFieldsSnapshot={customFields}
              // Checkout form info
              firstName={fullName.split(" ")[0]}
              lastName={fullName.split(" ").length > 1 ? fullName.split(" ")[1] : ""}
              phoneOverride={phone}
              email={""}
              tip={getTipPrice()}
              customerAddressDict={customerAddressDict}
              customerDeliveryInstructions={customerDeliveryInstructions}
              deliveryFee={cart.fulfillmentType === "delivery" ? deliveryFee : "0.00"}
              customFieldValues={customFieldValues}
            />
          </div>
        </div>
        <p className="checkoutBack" onClick={() => navigate(`/${cart.chefId}/${cart.eventId}`)}>
          Go Back
        </p>
      </div>
    );
  }

  return (
    <div className="checkoutMain">
      <div
        id="checkoutOverlay"
        className={isPaymentProcessing ? "checkoutOverlayShowing" : "checkoutOverlayNotShowing"}
      >
        {isPaymentProcessing && <ProcessingPayment />}
      </div>
      <p className="checkoutRestaurantName">{siteSettings.restaurantName}</p>
      <CountdownTimer css={{ mb: "$md" }} size="large" withTooltip />
      <CheckoutCard
        currentAction={action}
        setAction={setAction}
        focusedAction={"phone-login"}
        headerText={action !== "phone-login" ? "Phone" : ""}
        savedMiddleTexts={[getFormattedPhone(userInfo.phone)]}
      >
        <div style={{ marginTop: 8 }} />
        <PhoneLogin
          onLogin={() => setAction("time-slots")}
          setGuestLoginEnabled={(val) => setGuestLoginEnabled(val)}
        />
      </CheckoutCard>
      {action !== "phone-login" && (
        <CheckoutCard
          focusedAction="time-slots"
          currentAction={action}
          setAction={setAction}
          headerText={
            cartEvent.ticketed
              ? "Reserve Your Time"
              : ` Choose ${capitalizeFirstLetter(cart.fulfillmentType)} Time`
          }
          buttonText={
            cartEvent.ticketed
              ? "Confirm Reservation Time"
              : `Confirm ${capitalizeFirstLetter(cart.fulfillmentType)} Time`
          }
          buttonDisabled={selectedTimeSlot === null}
          buttonStyle={{ marginTop: 24 }}
          confirmSection={async () => {
            try {
              ensureOk(
                await reserveTimeSlot.mutateAsync({
                  cartId,
                  chefId: cart.chefId,
                  eventId: cart.eventId,
                  timeSlot: selectedTimeSlot,
                  location: selectedLocation,
                })
              );
            } catch (e) {
              setToastPopupInfo({
                text: `Please try again. An error occurred while reserving the time slot: ${e}`,
                type: "error",
              });
              throw e;
            }
            setAction("user-details");
          }}
          buttonLoading={reserveTimeSlot.isLoading}
          confirmationError={reserveTimeSlot.error}
          savedMiddleTexts={[
            cart.fulfillmentType === "delivery"
              ? getAddressString({
                  addressDict: customerAddressDict,
                  displayFullAddress: true,
                })
              : null,
            cart.location ? cart.location.title : "",
            cart.timeSlot ? getTimestampDayOfWeekMonthDate(cart.timeSlot.startTime, true) : "",
            getReadableTimeSlot(cart.timeSlot),
          ]}
        >
          <SetLocationTime
            step={step}
            style={{ gap: 24, marginTop: 24 }}
            LocationContainer={
              <SetLocationTime.LocationContainer
                delivery={cart.fulfillmentType === "delivery"}
                deliveryAddress={
                  cart.fulfillmentType === "delivery" &&
                  getAddressString({
                    addressDict: customerAddressDict,
                    displayFullAddress: true,
                  })
                }
              >
                {sortedLocations.map((location, locationIndex) => (
                  <SetLocationTime.LocationContainer.LocationCard
                    key={locationIndex}
                    title={location.title}
                    selected={locationIndex === selectedLocationIndex}
                    onClick={() => {
                      setSelectedLocationIndex(locationIndex);
                      setSelectedTimeSlotOptionIndex(-1); // time slots are for a given location, so when the location changes, reset selected timeslot indices.
                      setSelectedTimeSlotIndex(-1); // TODO: ideally we should re-run the auto-select logic
                    }}
                    subtitle={getAddressString(location)}
                  />
                ))}
              </SetLocationTime.LocationContainer>
            }
            DateContainer={
              (step === "date" || step === "time") && (
                <SetLocationTime.DateContainer>
                  {timeSlotOptions.map((timeSlotOption, timeSlotOptionIndex) => (
                    <SetLocationTime.DateContainer.DateCard
                      style={{ flex: "50% 0 1" }}
                      key={timeSlotOptionIndex}
                      title={timeSlotOption.readableDate}
                      disabled={
                        timeSlotOption.timeSlots.filter((timeSlot) => {
                          return !timeSlot.disabled;
                        }).length === 0
                      }
                      onClick={() => {
                        setSelectedTimeSlotOptionIndex(timeSlotOptionIndex);
                        setSelectedTimeSlotIndex(-1);
                      }}
                      selected={timeSlotOptionIndex === selectedTimeSlotOptionIndex}
                    />
                  ))}
                </SetLocationTime.DateContainer>
              )
            }
            TimeContainer={
              step === "time" && (
                <SetLocationTime.TimeContainer text={`Choose Time`}>
                  {selectedTimeSlotOptionIndex !== -1 &&
                    timeSlotOptions[selectedTimeSlotOptionIndex] &&
                    timeSlotOptions[selectedTimeSlotOptionIndex].timeSlots.map(
                      (timeSlot, timeSlotIndex) => {
                        if (timeSlot.hidden) return null;
                        return (
                          <SetLocationTime.TimeContainer.TimeCard
                            style={timeSlot.range ? { flex: "50% 0 1" } : { flex: "33% 0 1" }}
                            key={timeSlotIndex}
                            disabled={timeSlot.disabled}
                            title={getReadableTimeSlot(timeSlot)}
                            onClick={() => {
                              setSelectedTimeSlotIndex(timeSlotIndex);
                            }}
                            selected={timeSlotIndex === selectedTimeSlotIndex}
                          />
                        );
                      }
                    )}
                </SetLocationTime.TimeContainer>
              )
            }
          />
          {typeof cartEvent.additionalTimeSlotInfoText === "string" && (
            <p
              style={{
                ...textstyles.body,
                color: colors.gray700,
                whiteSpace: "pre-line",
              }}
            >
              {cartEvent.additionalTimeSlotInfoText}
            </p>
          )}
          {Array.isArray(timeSlotOptions) &&
            timeSlotOptions.filter((timeSlotOption) => {
              return (
                timeSlotOption.timeSlots.filter((timeSlot) => {
                  return !timeSlot.disabled;
                }).length > 0
              );
            }).length === 0 && (
              <p style={{ ...textstyles.button, color: "red", paddingTop: 12 }}>
                Sorry, there are no order times left for these items.
              </p>
            )}
        </CheckoutCard>
      )}
      {(action === "user-details" || action === "confirm-payment") && (
        <CheckoutCard
          focusedAction="user-details"
          currentAction={action}
          setAction={setAction}
          headerText="Your Info"
          buttonText="Continue to payment"
          savedMiddleTexts={[firstName + " " + lastName, email].concat(
            Array.isArray(customFields)
              ? customFields.map((customField, index) => {
                  return customField.title + ": " + customFieldValues[index];
                })
              : []
          )}
          buttonLoading={false}
          buttonDisabled={firstNameError() || lastNameError() || emailError()}
          confirmationError={""}
          confirmSection={confirmUserDetails}
        >
          <p className="checkoutInputContainerText" style={{ marginBottom: 40 }}>
            Enter your information below to stay updated with your order.
          </p>
          <UserInfoDetail
            showError={true}
            value={firstName}
            onChange={(val) => {
              setFirstName(val);
            }}
            onBlur={() => {
              setWasFirstNameTouched(true);
            }}
            errorText={wasFirstNameTouched ? firstNameError() : undefined}
            placeholder="First name"
            width={96}
            marginRight={16}
          />
          <UserInfoDetail
            showError={true}
            value={lastName}
            onChange={(val) => {
              setLastName(val);
            }}
            onBlur={() => {
              setWasLastNameTouched(true);
            }}
            errorText={wasLastNameTouched ? lastNameError() : undefined}
            placeholder="Last name"
            width={96}
            marginRight={16}
          />
          <UserInfoDetail
            showError={true}
            value={email}
            onChange={(val) => {
              setEmail(val.trim());
            }}
            onBlur={() => {
              setWasEmailTouched(true);
            }}
            errorText={wasEmailTouched ? emailError() : undefined}
            placeholder="Email address"
            width={208}
            marginRight={0}
          />
          {Array.isArray(customFields) &&
            customFields.map((customField, index) => (
              <LabeledInput
                label={customField.type === "checkbox" ? "" : customField.title}
                key={index}
                Input={getCustomFieldInput(customField, index)}
              />
            ))}
        </CheckoutCard>
      )}
      <div className="checkoutInputContainer">
        <p className="checkoutInputContainerLeft" style={{ marginBottom: 24 }}>
          Order Summary
        </p>
        <div className="cartScrollContainer">
          <div className="cartItemsContainer">
            {cart.cartItems.map((cartItem, cartItemIndex) => (
              <div className="cartItemContainer" key={cartItemIndex}>
                <div className="cartItemTop">
                  <p className="cartItemName">
                    {cartItem.quantity} {cartItem.title}
                  </p>
                  <p className="cartItemPrice">${getCartItemPrice(cartItem)}</p>
                </div>
                <div
                  className="cartItemTags"
                  style={{
                    borderBottom:
                      cartItemIndex !== cart.cartItems.length - 1 ? "1px solid #EDEDED" : "none",
                  }}
                >
                  <p className="cartItemTag">{getMenuItemOptionsString(cartItem)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {action === "confirm-payment" && (
          <div style={{ display: "flex", flexFlow: "column" }}>
            <Separator />

            <div className="deliveryDriverTipContainer">
              <TipSelect
                value={tipOption}
                text="Tip"
                aria-label="Tip"
                style={{ alignSelf: "flex-start" }}
                onChange={(value) => setTipOption(value)}
              >
                {tipOptions.map((value, index) => (
                  <TipSelect.Button
                    style={{
                      minWidth: "25%",
                    }}
                    value={value}
                    aria-label={value}
                    text={
                      value === "other"
                        ? "other"
                        : "$" +
                          toFixed(parseFloat(getSubtotal(cart.cartItems)) * parseFloat(value), 2)
                    }
                    key={index}
                  />
                ))}
              </TipSelect>
            </div>
            {tipOption === "other" && (
              <div className="deliveryOtherInputContainer">
                <VislyInput
                  style={{ width: "100px" }}
                  onChange={(value) => setTip(value)}
                  onBlur={() => setTip(toFixed(parseFloat(manualTipInput), 2))}
                  value={manualTipInput}
                  placeholder="0.00"
                  withUnit="left"
                  unit="$"
                />
              </div>
            )}
            <DiscountCodeInput cartId={cartId} chefId={cart.chefId} />
          </div>
        )}
        <div className="cartCheckoutInformation">
          <CheckoutForm
            // Shop info
            isPaymentProcessing={isPaymentProcessing}
            setIsPaymentProcessing={setIsPaymentProcessing}
            cart={cart}
            cartEvent={cartEvent}
            // Checkout context
            showCheckout={action === "confirm-payment"}
            isOnDemand={false}
            guestLoginEnabled={guestLoginEnabled}
            paymentIntentClientSecret={
              initializeCartPaymentIntent.isLoading
                ? undefined
                : initializeCartPaymentIntent.data?.json.client_secret
            }
            customFieldsSnapshot={customFields}
            // Checkout form info
            firstName={firstName}
            lastName={lastName}
            phoneOverride={undefined}
            email={email}
            tip={getTipPrice()}
            customerAddressDict={customerAddressDict}
            customerDeliveryInstructions={customerDeliveryInstructions}
            deliveryFee={cart.fulfillmentType === "delivery" ? deliveryFee : "0.00"}
            customFieldValues={customFieldValues}
          />
        </div>
      </div>
      <p className="checkoutBack" onClick={() => navigate(`/${cart.chefId}/${cart.eventId}`)}>
        Go Back
      </p>
    </div>
  );
}

// CheckoutForm passed props
// ==== ON DEMAND ====
// checkoutProcessing={checkoutProcessing}
// showCheckout={true}
// props={props}
// tip={getTipPrice()}
// firstName={fullName.split(" ")[0]}
// lastName={fullName.split(" ").length > 1 ? fullName.split(" ")[1] : ""}
// email=""
// timeSlot={
//   cartEvent.onDemandWindowsEnabled
//     ? reserveTimeSlotError !== "" || reserveTimeSlotLoading
//       ? null
//       : cartTimeSlot
//     : {
//         range: false,
//         endTime: onDemandTimeSlotTime,
//         startTime: onDemandTimeSlotTime,
//         onDemand: true,
//       }
// }
// location={sortedLocations[0]}
// isOnDemand={true}
// setCartProcessing={setCartProcessingWrapped}
// setCartPaymentReceived={setCartPaymentReceivedWrapped}
// customFields={
//   Array.isArray(cartEvent.customFields) &&
//   cartEvent.customFields.map((customField, index) => ({
//     title: customField.title,
//     value: customFieldValues[index],
//   }))
// }
// ticketed={cartEvent.ticketed}
// setCartProcessingLoading={setCartProcessing.isLoading}
// discountCode={discountCode}

// phone={phone}
// userInfo={userInfo}

// ==== NORMAL ====
// checkoutProcessing={checkoutProcessing}
// showCheckout={action === "confirm-payment"}
// props={props}
// tip={getTipPrice()}
// firstName={firstName}
// lastName={lastName}
// email={email}
// timeSlot={cartTimeSlot}
// location={cartLocation}
// isOnDemand={false}
// setCartProcessing={setCartProcessingWrapped}
// setCartPaymentReceived={setCartPaymentReceivedWrapped}
// customFields={
//   Array.isArray(cartEvent.customFields) &&
//   cartEvent.customFields.map((customField, index) => ({
//     title: customField.title,
//     value: customFieldValues[index],
//   }))
// }
// ticketed={cartEvent.ticketed}
// setCartProcessingLoading={setCartProcessing.isLoading}
// discountCode={discountCode}

// guestLoginEnabled={guestLoginEnabled}
