// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Tooltip.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { TextPrimitive, ContainerPrimitive } from "../_internal_primitives";
import { TooltipArrow, TooltipRoot } from "../_internal_tooltip";

const styles = [
  {
    type: "default",
    layers: {
      "tooltip-root": {
        none: {
          arrowColor: "rgba(255,255,255,1)",
        },
      },
      "tooltip-text": {
        none: {
          text: "Hey, kid. Want some tips?",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "dark",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "dark",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "dark",
  },
];

export const TooltipContext = createContext(null);

function Tooltip(_props) {
  const defaults = useContext(TooltipContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <TooltipRoot
      {...props}
      key="tooltip-root"
      internal={{
        styles,
        projectId: "7X7HTLRqyD",
        layerId: "tooltip-root",
        scope: "4DM3A5kqKR",
        activeVariants,
      }}
      internalChildren={(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Content_L5hMsqpeLs"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_4DM3A5kqKR_L5hMsqpeLs"
            }
            key={"L5hMsqpeLs"}
            addSpacing={false}
            element={getStyle("L5hMsqpeLs", "htmlElement")}
          >
            {
              <TextPrimitive
                id={"Text_tooltip-text"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_4DM3A5kqKR_tooltip-text"
                }
                key={"tooltip-text"}
                text={
                  exists(props.text)
                    ? props.text
                    : getStyle("tooltip-text", "text")
                }
                element={getStyle("tooltip-text", "htmlElement")}
              />
            }
          </ContainerPrimitive>
          <TooltipArrow
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_4DM3A5kqKR_tooltip-arrow"
            }
            key={"tooltip-arrow"}
          />
        </>
      )}
    >
      {props.children}
    </TooltipRoot>
  );
}

Tooltip.__variants = [
  {
    name: "dark",
    type: "variant",
  },
];

export default Tooltip;
