/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from "react";
import PhoneLogin from "./PhoneLogin";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import "./css/Login.css";
import { icons, colors, textstyles } from "../visly";
import Icon from "./Icon";

export default function Login({
  callback,
  close,
  style,
  title,
  subtitle,
  onLogin,
  showCloseIcon,
  errorMessage,
  isPortal,
}) {
  const [animationStyle, setAnimationStyle] = useState({});
  const [backgroundStyle, setBackgroundStyle] = useState({});
  const target = useRef(null);

  useEffect(() => {
    target.current = document.querySelector(".signinBackdrop");
    target.current && disableBodyScroll(target.current);
    setTimeout(() => {
      setAnimationStyle({
        transform: "translateY(0%)",
      });
      setBackgroundStyle({
        backgroundColor: "rgba(0, 0, 0, .4)",
      });
    }, 10);
    return () => {
      enableBodyScroll(target.current);
      target.current = null;
    };
  }, []);

  function onLoginInternal() {
    callback();
    close();
  }

  function getMorningAfternoonEvening() {
    const hour = new Date().getHours();
    if (hour >= 0 && hour < 12) {
      return "morning";
    } else if (hour >= 12 && hour < 18) {
      return "afternoon";
    } else {
      return "evening";
    }
  }

  return (
    <div className="signinBackdrop" style={{ ...style, ...backgroundStyle }}>
      <div className="login-form" style={animationStyle} id="loginForm">
        {showCloseIcon && (
          <button className="login-form__close-btn" onClick={() => close()}>
            <Icon icon={icons.close} size={"20px"} color={colors.gray600} />
          </button>
        )}
        <span className="login-form__title">
          {title ? title : `Good ${getMorningAfternoonEvening()}, Chef!`}
        </span>
        {subtitle && <span className="login-form__subtitle">{subtitle}</span>}
        <PhoneLogin onLogin={onLogin ? onLogin : onLoginInternal} isPortal={isPortal} />
        {errorMessage && <span style={{ ...textstyles.button, color: "red" }}>{errorMessage}</span>}
        {/* <img className="login-form__logo" src={'https://ucarecdn.com/85af6a80-3ebb-4a84-8521-29bfb89f81ea/greyTextLogo.svg'}/> */}
      </div>
    </div>
  );
}
