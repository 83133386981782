// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./EventCard.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as EventStatus } from "../Events/Status";
import { default as TagContainer } from "./EventCard.TagContainer";

const styles = [
  {
    type: "default",
    layers: {
      "7TXNHjfDrU": {
        none: {
          text: "Untitled Event",
        },
      },
      SSXWowfy64: {
        none: {
          text: "Text",
        },
      },
      FiKgFss6CY: {
        none: {
          text: "Text",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
  {
    type: "boolean",
    propName: "withImage",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
  {
    type: "enum",
    propName: "type",
    values: [
      {
        propValue: "overview",
        layers: {},
      },
      {
        propValue: "duplicate",
        layers: {},
      },
      {
        propValue: "storefront",
        layers: {},
      },
      {
        propValue: "eventList",
        layers: {},
      },
      {
        propValue: "compact",
        layers: {
          SSXWowfy64: {
            none: {
              text: "Sun, June 18th",
            },
          },
          FiKgFss6CY: {
            none: {
              text: "Little Brother Bar",
            },
          },
        },
      },
    ],
  },
  {
    type: "boolean",
    propName: "dark",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      GTYZf87nSr: {
        none: {
          type: "complete",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {
      GTYZf87nSr: {},
    },
  },
  {
    type: "boolean",
    propName: "withImage",
    layers: {
      GTYZf87nSr: {},
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {
          GTYZf87nSr: {},
        },
      },
      {
        propValue: "small",
        layers: {
          GTYZf87nSr: {},
        },
      },
      {
        propValue: "med",
        layers: {
          GTYZf87nSr: {},
        },
      },
      {
        propValue: "large",
        layers: {
          GTYZf87nSr: {},
        },
      },
      {
        propValue: "xlarge",
        layers: {
          GTYZf87nSr: {},
        },
      },
    ],
  },
  {
    type: "enum",
    propName: "type",
    values: [
      {
        propValue: "overview",
        layers: {
          GTYZf87nSr: {},
        },
      },
      {
        propValue: "duplicate",
        layers: {
          GTYZf87nSr: {},
        },
      },
      {
        propValue: "storefront",
        layers: {
          GTYZf87nSr: {},
        },
      },
      {
        propValue: "eventList",
        layers: {
          GTYZf87nSr: {},
        },
      },
      {
        propValue: "compact",
        layers: {
          GTYZf87nSr: {},
        },
      },
    ],
  },
  {
    type: "boolean",
    propName: "dark",
    layers: {
      GTYZf87nSr: {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "selected",
  },
  {
    type: "boolean",
    propName: "withImage",
  },
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
  {
    type: "enum",
    propName: "type",
    propValues: ["overview", "duplicate", "storefront", "eventList", "compact"],
  },
  {
    type: "boolean",
    propName: "dark",
  },
];

export const EventCardContext = createContext(null);

function EventCard(_props) {
  const defaults = useContext(EventCardContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="39EQRCrdVF"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "39EQRCrdVF",
        scope: "2qnHSRM2mL",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            key={"McY6cQcQKT"}
            id={"Slot_McY6cQcQKT"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_2qnHSRM2mL_McY6cQcQKT"
            }
            addSpacing={false}
          >
            {props.image}
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"event-content-container_3nqHrHqw3B"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_2qnHSRM2mL_3nqHrHqw3B"
            }
            key={"3nqHrHqw3B"}
            addSpacing={false}
            element={getStyle("3nqHrHqw3B", "htmlElement")}
          >
            <ContainerPrimitive
              id={"Container_2BPMegQMxk"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_2qnHSRM2mL_2BPMegQMxk"
              }
              key={"2BPMegQMxk"}
              addSpacing={false}
              element={getStyle("2BPMegQMxk", "htmlElement")}
            >
              <ContainerPrimitive
                id={"title-container_BCC4DZgS8v"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_2qnHSRM2mL_BCC4DZgS8v"
                }
                key={"BCC4DZgS8v"}
                addSpacing={true}
                element={getStyle("BCC4DZgS8v", "htmlElement")}
              >
                <TextPrimitive
                  id={"Text_7TXNHjfDrU"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_2qnHSRM2mL_7TXNHjfDrU"
                  }
                  key={"7TXNHjfDrU"}
                  text={
                    exists(props.title)
                      ? props.title
                      : getStyle("7TXNHjfDrU", "text")
                  }
                  element={getStyle("7TXNHjfDrU", "htmlElement")}
                />
                <ContainerPrimitive
                  key={"66KBptsFYU"}
                  id={"Slot_66KBptsFYU"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_2qnHSRM2mL_66KBptsFYU"
                  }
                  addSpacing={false}
                >
                  {props.status}
                </ContainerPrimitive>
              </ContainerPrimitive>
              <ContainerPrimitive
                id={"compact-container_5A1uLZJ5Cs"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_2qnHSRM2mL_5A1uLZJ5Cs"
                }
                key={"5A1uLZJ5Cs"}
                addSpacing={true}
                element={getStyle("5A1uLZJ5Cs", "htmlElement")}
              >
                <TextPrimitive
                  id={"compactDate_SSXWowfy64"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_2qnHSRM2mL_SSXWowfy64"
                  }
                  key={"SSXWowfy64"}
                  text={
                    exists(props.compactDate)
                      ? props.compactDate
                      : getStyle("SSXWowfy64", "text")
                  }
                  element={getStyle("SSXWowfy64", "htmlElement")}
                />
                <TextPrimitive
                  id={"compactLocation_FiKgFss6CY"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_2qnHSRM2mL_FiKgFss6CY"
                  }
                  key={"FiKgFss6CY"}
                  text={
                    exists(props.compactLocation)
                      ? props.compactLocation
                      : getStyle("FiKgFss6CY", "text")
                  }
                  element={getStyle("FiKgFss6CY", "htmlElement")}
                />
              </ContainerPrimitive>
              <ContainerPrimitive
                key={"MK9ZShDKPB"}
                id={"Slot_MK9ZShDKPB"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_2qnHSRM2mL_MK9ZShDKPB"
                }
                addSpacing={false}
              >
                {props.goLiveTime}
              </ContainerPrimitive>
              <ContainerPrimitive
                key={"QVVR7GM9Js"}
                id={"Slot_QVVR7GM9Js"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_2qnHSRM2mL_QVVR7GM9Js"
                }
                addSpacing={false}
              >
                {props.timeWindow}
              </ContainerPrimitive>
              <ContainerPrimitive
                id={"location-and-details_3rerjhGw2E"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_2qnHSRM2mL_3rerjhGw2E"
                }
                key={"3rerjhGw2E"}
                addSpacing={false}
                element={getStyle("3rerjhGw2E", "htmlElement")}
              >
                <ContainerPrimitive
                  key={"AgF32tor8z"}
                  id={"Slot_AgF32tor8z"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_2qnHSRM2mL_AgF32tor8z"
                  }
                  addSpacing={false}
                >
                  {props.location}
                </ContainerPrimitive>
                <ContainerPrimitive
                  id={"Container_8kERuogrtM"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_2qnHSRM2mL_8kERuogrtM"
                  }
                  key={"8kERuogrtM"}
                  addSpacing={false}
                  element={getStyle("8kERuogrtM", "htmlElement")}
                >
                  <ContainerPrimitive
                    key={"M9pp72eG2Q"}
                    id={"Slot_M9pp72eG2Q"}
                    className={
                      "__visly_reset_7X7HTLRqyD __visly_scope_2qnHSRM2mL_M9pp72eG2Q"
                    }
                    addSpacing={false}
                  >
                    {props.countdown}
                  </ContainerPrimitive>
                  <ContainerPrimitive
                    key={"H1ghWSpKvE"}
                    id={"Slot_H1ghWSpKvE"}
                    className={
                      "__visly_reset_7X7HTLRqyD __visly_scope_2qnHSRM2mL_H1ghWSpKvE"
                    }
                    addSpacing={false}
                  >
                    {props.moreDetails}
                  </ContainerPrimitive>
                </ContainerPrimitive>
              </ContainerPrimitive>
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"tagContainer-container_51PnTBPKd2"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_2qnHSRM2mL_51PnTBPKd2"
              }
              key={"51PnTBPKd2"}
              addSpacing={false}
              element={getStyle("51PnTBPKd2", "htmlElement")}
            >
              {props.tagContainer === undefined ? (
                <TagContainer key={"Roc4ugaSVr"} />
              ) : (
                props.tagContainer
              )}
            </ContainerPrimitive>
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

EventCard.EventStatus = EventStatus;
EventCard.__variants = [
  {
    name: "selected",
    type: "variant",
  },
  {
    name: "withImage",
    type: "variant",
  },
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
  {
    name: "type",
    type: "group",
    variants: ["overview", "duplicate", "storefront", "eventList", "compact"],
  },
  {
    name: "dark",
    type: "variant",
  },
];

export default EventCard;
