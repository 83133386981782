import React, { useMemo } from "react";
import {
  CardContent,
  CardHeaderPrimitive,
  HeaderTextRow,
} from "../../hotplate-portal/orderManagement/components/PackingCard";
import { styled } from "../../stitches.config";
import { Flex } from "../primitives/Containers";

import { RatioBoxLoader, TextLoader, VariableWidthLoader } from "./CommonLoaders";

// ##############################
// BATCH VIEW LOADERS
// ##############################
const TableRowLoaderContainer = styled("tr", {
  height: 52,
  width: "100%",
  backgroundColor: "$white",
  borderBottom: "1px solid $accent4",
});

const CellPrimtitive = styled("td", {
  height: "100%",
  verticalAlign: "top",
});

const CellContentPrimitive = styled("div", {
  display: "flex",
  padx: "$md",
  pady: "$sm",
});

const CustomerNameLoader = (
  <Flex css={{ alignItems: "center" }}>
    <VariableWidthLoader min={40} max={100} />
    <TextLoader css={{ width: 35, marginLeft: "$xs", borderRadius: "$pill" }} />
  </Flex>
);

const OrderItemLoader = ({ view = "batch" }) => {
  const hasMods = useMemo(() => Math.random() > 0.5, []);
  return (
    <Flex
      css={
        view !== "batch"
          ? { width: "100%", alignItems: "flex-start" }
          : { width: "100%", alignItems: "center" }
      }
    >
      <RatioBoxLoader
        width={view !== "batch" ? 24 : 20}
        ratio={1}
        css={
          view !== "batch"
            ? { marginRight: "$sm", borderRadius: "$sm" }
            : { marginRight: "$xs", borderRadius: "$sm" }
        }
      />
      <Flex css={{ flexDirection: "column" }}>
        <VariableWidthLoader
          min={view !== "batch" ? 125 : 75}
          max={view !== "batch" ? 200 : 150}
          css={view !== "batch" ? { height: 20, marginTop: 3 } : {}}
        />
        {view !== "batch" && hasMods && (
          <VariableWidthLoader min={45} max={100} css={{ height: 14, marginTop: "$sm" }} />
        )}
      </Flex>
    </Flex>
  );
};

const OrderItemsLoader = ({ min = 1, max = 2, view = "batch" }) => {
  const randNumItems = useMemo(() => Math.floor(Math.random() * (max - min + 1) + min), [min, max]);
  return (
    <Flex
      css={
        view !== "batch"
          ? { flexDirection: "column", gap: "$md" }
          : { flexDirection: "column", gap: "$sm" }
      }
    >
      {Array.from({ length: randNumItems }, (_, i) => (
        <OrderItemLoader key={i} view={view} />
      ))}
    </Flex>
  );
};

const CellLoader = ({ id }) => {
  return (
    <CellPrimtitive>
      <CellContentPrimitive>
        {id === "checkbox" && <RatioBoxLoader width={20} css={{ borderRadius: "$xs" }} />}
        {id === "orderNumber" && <TextLoader css={{ width: "6ch" }} />}
        {id === "customerName" && CustomerNameLoader}
        {id === "customerEmail" && <VariableWidthLoader min={50} max={100} />}
        {id === "customerPhone" && <TextLoader css={{ width: "12ch" }} />}
        {id === "orderItems" && <OrderItemsLoader />}
        {id === "fulfillmentDate" && <TextLoader css={{ width: "8ch" }} />}
        {id === "fulfillmentTime" && <TextLoader css={{ width: "6ch" }} />}
        {id === "fulfillmentType" && <TextLoader css={{ width: "6ch" }} />}
      </CellContentPrimitive>
    </CellPrimtitive>
  );
};

export const TableRowLoader = ({ columns, ...props }) => {
  const visibleColumns = columns.filter((column) => column.visible);
  return (
    <TableRowLoaderContainer {...props}>
      <CellLoader id="checkbox" />
      {visibleColumns.map((column) => (
        <CellLoader id={column.id} key={column.id} />
      ))}
      <CellLoader id="dropdown" />
    </TableRowLoaderContainer>
  );
};

// ##############################
// CARD/GRID VIEW LOADERS
// ##############################

const CardLoaderContainer = styled("div", {
  display: "flex",
  position: "relative",
  flexDirection: "column",
  flexShrink: 0,
  flexGrow: 1,
  borderRadius: "$md",
  overflow: "hidden",
  backgroundColor: "$white",
  border: "1px solid gray6",
  boxShadow: "$elevation4",
});

const CardFooterContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$xs",
  padding: "$sm",
  width: "100%",
});

const CardHeaderLoader = () => {
  return (
    <CardHeaderPrimitive css={{ backgroundColor: "transparent" }}>
      <HeaderTextRow size="medium" css={{ width: "100%", marginBottom: "$sm" }}>
        <TextLoader css={{ height: 20, width: "45%" }} />
        <TextLoader css={{ height: 20, width: "6ch" }} />
      </HeaderTextRow>
      <HeaderTextRow size="small" css={{ width: "100%" }}>
        <TextLoader css={{ width: "6ch" }} />
        <TextLoader css={{ width: "4ch" }} />
      </HeaderTextRow>
    </CardHeaderPrimitive>
  );
};

const CardItemsLoader = ({ view }) => {
  return (
    <CardContent css={{ padding: "$sm $sm 110px" }}>
      <OrderItemsLoader min={1} max={view === "card" ? 5 : 3} view={view} />
    </CardContent>
  );
};

const CardFooterLoader = ({ view }) => {
  return (
    <CardFooterContainer css={view === "card" ? { borderTop: "1px solid $gray6" } : {}}>
      {view === "card" && (
        <Flex css={{ justifyContent: "space-between", alignItems: "center" }}>
          <TextLoader css={{ width: "6ch" }} />
          <RatioBoxLoader width={20} ratio={1} css={{ borderRadius: "$xs" }} />
        </Flex>
      )}
      <TextLoader css={{ width: "100%", height: "$md_btn" }} />
    </CardFooterContainer>
  );
};

export const PackingCardLoader = ({ view, ...props }) => {
  return (
    <CardLoaderContainer {...props}>
      <CardHeaderLoader />
      <CardItemsLoader view={view} />
      <CardFooterLoader view={view} />
    </CardLoaderContainer>
  );
};

export const PackingCardListLoader = ({ ...props }) => {
  return (
    <Flex
      css={{
        gap: "$lg",
        height: "100%",
        overflow: "auto",
        paddingLeft: "$md",
        paddingBottom: "$xs",
      }}
    >
      {Array(10)
        .fill(0)
        .map((_, i) => (
          <PackingCardLoader {...props} view="card" key={i} />
        ))}
    </Flex>
  );
};
