import React from "react";
import { styled, keyframes } from "../../stitches.config";
import { Button } from "./Button";
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";

const overlayShow = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const contentShow = keyframes({
  "0%": { opacity: 0, transform: "translate(-50%, -48%) scale(.96)" },
  "100%": { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
});

const Flex = styled("div", {
  display: "flex",
});

const StyledOverlay = styled(AlertDialogPrimitive.Overlay, {
  backgroundColor: "var(--overlay)",
  zIndex: "999999999999999",
  position: "fixed",
  inset: 0,
  "@media (prefers-reduced-motion: no-preference)": {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
});

const StyledContent = styled(AlertDialogPrimitive.Content, {
  zIndex: "999999999999999",
  backgroundColor: "white",
  borderRadius: 6,
  boxShadow: "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  maxWidth: "650px",
  minWidth: "min-content",
  maxHeight: "85vh",
  padding: 25,
  "@media (prefers-reduced-motion: no-preference)": {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
  "&:focus": { outline: "none" },
});

function Content({ children, ...props }) {
  return (
    <AlertDialogPrimitive.Portal>
      <StyledOverlay />
      <StyledContent {...props}>{children}</StyledContent>
    </AlertDialogPrimitive.Portal>
  );
}

const StyledTitle = styled(AlertDialogPrimitive.Title, {
  m: 0,
  color: "$gray12",
  ff: "$arboria",
  fs: "$md",
  fw: "$semi_bold",
  lh: "$heading",
  mb: "$md",
});

const StyledDescription = styled(AlertDialogPrimitive.Description, {
  mb: "$lg",
  ff: "$avenir",
  color: "$gray11",
  fw: "$normal",
  fs: "$sm",
  lh: "$text",
});

const SpicyDescription = styled("h2", {
  mb: "$lg",
  ff: "$avenir",
  color: "$gray11",
  fw: "$semi_bold",
  fs: "$lg",
  lh: "$text",
});

const AlertDialogRoot = AlertDialogPrimitive.Root;
const AlertDialogTrigger = AlertDialogPrimitive.Trigger;
const AlertDialogContent = Content;
const AlertDialogTitle = StyledTitle;
const AlertDialogDescription = StyledDescription;
const AlertDialogAction = AlertDialogPrimitive.Action;
const AlertDialogCancel = AlertDialogPrimitive.Cancel;

export const AlertDialog = ({
  trigger,
  title,
  actionText,
  cancelText,
  description,
  spicyDescription,
  unclosable,
  children,
  actionButtonsArr, //overrides the default action button
  onClick,
  isOpen,
  onOpenChange,
  defaultOpen,
  ...props
}) => {
  return (
    <AlertDialogRoot open={isOpen} onOpenChange={onOpenChange} defaultOpen={defaultOpen}>
      <AlertDialogTrigger asChild>{trigger}</AlertDialogTrigger>
      <AlertDialogContent {...props}>
        {title && <AlertDialogTitle>{title}</AlertDialogTitle>}
        {description && <AlertDialogDescription>{description}</AlertDialogDescription>}
        {spicyDescription && <SpicyDescription>{spicyDescription}</SpicyDescription>}
        {children}
        {!actionButtonsArr && (
          <Flex css={{ justifyContent: "flex-end" }}>
            {!unclosable && (
              <AlertDialogCancel>
                <Button color="gray" variant="tinted" css={{ marginRight: 20 }}>
                  {cancelText || "Cancel"}
                </Button>
              </AlertDialogCancel>
            )}
            <AlertDialogAction asChild>
              <Button color="hotplate" variant="tinted" onClick={onClick}>
                {actionText || "Confirm"}
              </Button>
            </AlertDialogAction>
          </Flex>
        )}
        {Array.isArray(actionButtonsArr) && (
          <Flex
            css={{
              justifyContent: "center",
              gap: 16,
              flexDirection: "column",
              "@tablet": { flexDirection: "row" },
            }}
          >
            {actionButtonsArr.map((actionButton, key) => (
              <AlertDialogAction asChild key={key}>
                {actionButton}
              </AlertDialogAction>
            ))}
          </Flex>
        )}
      </AlertDialogContent>
    </AlertDialogRoot>
  );
};
