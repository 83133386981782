import { createTRPCReact, TRPCClientErrorBase } from "@trpc/react-query";
import type { DefaultErrorShape } from "@trpc/server";
import type { TRPCRouter } from "../../back-end-ts/src/routers/trpc";

export const trpc = createTRPCReact<TRPCRouter>();

/**
 * Creates a function to pass to react-query as the `retry` option.
 * If the error is a 4XX, only retry the first time, because
 * occasionally a 4XX is caused by a transient error. Otherwise,
 * retry up to num times.
 */
export function shouldRetry(num: number) {
  return (failureCount: number, err: TRPCClientErrorBase<DefaultErrorShape>) => {
    return err.data && 400 <= err.data.httpStatus && err.data.httpStatus < 500
      ? failureCount === 1
      : failureCount <= num;
  };
}
