import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPackingFilter } from "../../../hotplate-storefront/actions";
import { styled } from "../../../stitches.config";
import { ToggleGroup } from "../../../hotplate-common/primitives/ToggleGroup";

const NumberBadge = styled("div", {
  display: "flex",
  height: 16,
  alignItems: "center",
  color: "$white",
  backgroundColor: "$accent7",
  padx: "$xxs",
  borderRadius: "$pill",
  marginLeft: "$xxs",
  variants: {
    selected: {
      true: {
        color: "$accent11",
        backgroundColor: "$white",
      },
    },
  },
});

export const CompletedOrdersFilter = ({
  incompleteOrdersCount,
  completeOrdersCount,
  setFilteredOrders,
}) => {
  const packingFilter = useSelector((state) => state.orderManagement.packingFilter);
  const dispatch = useDispatch();
  return (
    <ToggleGroup
      size="small"
      value={packingFilter}
      onValueChange={(value) => {
        setPackingFilter(value)(dispatch);
        setFilteredOrders();
      }}
    >
      <ToggleGroup.Item value="NOT_DONE">
        <span>Open</span>
        <NumberBadge selected={packingFilter === "NOT_DONE"}>{incompleteOrdersCount}</NumberBadge>
      </ToggleGroup.Item>
      <ToggleGroup.Item value="DONE">
        <span>Done</span>
        <NumberBadge selected={packingFilter === "DONE"}>{completeOrdersCount}</NumberBadge>
      </ToggleGroup.Item>
    </ToggleGroup>
  );
};
