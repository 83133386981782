/* eslint-disable react/prop-types */
import React, { PureComponent } from "react";
import {
  getOrderRevenue,
  getOrderTotal,
  getTimestampMonthDateYear,
  toFixed,
} from "@hotplate/utils-ts/helperFunctions";
import { connect } from "react-redux";
import { FirebaseContext } from "../../firebaseSocket";
import NewModal from "../../hotplate-common/NewModal";
import ItemizedReceipt from "../payment/ItemizedReceipt";
import ReactLoading from "react-loading";
import { styled } from "../../stitches.config";
import { IconButton } from "../../hotplate-common/primitives/IconButton";
import { ReaderIcon } from "@radix-ui/react-icons";
import { Separator } from "../../hotplate-common/primitives/Separator";
import { getCustomerOrderString } from "./CustomerBadge";
import { usePortalUser } from "../../auth";

const Flex = styled("div", {
  display: "flex",
});

const Grid = styled("div", {
  display: "grid",
});

const StatValue = styled("h2", {
  fs: "$lg",
  fw: "bold",
  ff: "$arboria",
  color: "$gray12",
  wordWrap: "break-word",
  lh: "$btn",
});

const StatLabel = styled("p", {
  fs: "$sm",
  fw: "$normal",
  ff: "$avenir",
  color: "$gray11",
  marginTop: "$sm",
});

const StatCard = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "$sm",
  backgroundColor: "$white",
  borderRadius: "$md",
  border: "1px solid $gray6",
  boxShadow: "$elevation3",
  minWidth: 0,
});

const CustomerStat = ({ value, name }) => {
  // containers & styles

  return (
    <StatCard>
      <StatValue>{value}</StatValue>
      <StatLabel>{name}</StatLabel>
    </StatCard>
  );
};

const Table = styled("table", {
  ff: "$avenir",
  fs: 14,
});

const Head = styled("th", {
  height: 20,
  verticalAlign: "middle",
  textAlign: "start",
  fw: "$semi_bold",
  color: "$gray12",
  backgroundColor: "transparent",
  borderBottom: "1px solid gray10",
});

const Row = styled("tr", {
  height: 40,
  lh: "$reset",
  backgroundColor: "$gray3",
  fw: "$normal",
  color: "$gray12",
  transition: "background-color 0.1s ease-in-out",
  borderBottom: "1px solid $gray6",
  "&:nth-child(2n+0)": {
    backgroundColor: "$gray2",
  },
  "&:hover": {
    backgroundColor: "$accent4",
    color: "$accent12",
  },
  "& td:first-child, th:first-child": {
    paddingLeft: 24,
  },
  cursor: "pointer",
});

const OrderRow = ({ order, onClick, ...props }) => {
  const Data = styled("td", {
    height: 20,
    verticalAlign: "middle",
    textAlign: "start",
    color: "inherit",
  });
  return (
    <Row onClick={onClick} {...props}>
      <Data>#{order.orderId}</Data>
      <Data>{getTimestampMonthDateYear(order.orderPlaced)}</Data>
      <Data>${getOrderTotal(order).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Data>
      <Data>
        <IconButton icon={<ReaderIcon />} />
      </Data>
    </Row>
  );
};

class CustomerModalMaster extends PureComponent {
  static contextType = FirebaseContext;
  constructor(props) {
    super(props);
    this.state = {
      irpPaymentIntentId: null,
    };

    this.handler = null;
    this.handlerPhone = null;
  }

  componentDidMount() {
    // Used for checking if the Firebase app was reset
    this.prevDb = this.context.database;
  }

  componentDidUpdate(prevProps) {
    const { hostId, phone, isOpen } = this.props;
    if (phone && hostId && isOpen && (!prevProps.isOpen || this.prevDb !== this.context.database)) {
      this.prevDb = this.context.database;
      this.handler = this.context.api.connectToCustomerChefOrders(hostId, phone);
      this.handlerPhone = phone;
    }

    if (prevProps.isOpen && !isOpen) {
      this.unmount();
    }
  }

  componentWillUnmount() {
    this.unmount();
  }

  unmount() {
    if (this.handler && this.handlerPhone) {
      this.context.api.disconnectFromCustomerChefOrders(this.handlerPhone, this.handler);
    }
  }

  getTotalRevenue() {
    const { customerOrders } = this.props;
    return Object.keys(customerOrders).reduce((sum, pI) => {
      const order = customerOrders[pI];
      return toFixed(parseFloat(sum) + parseFloat(getOrderRevenue(order)), 2);
    }, "0.00");
  }

  getTotalSpent() {
    const { customerOrders } = this.props;
    return Object.keys(customerOrders).reduce((sum, pI) => {
      const order = customerOrders[pI];
      return toFixed(parseFloat(sum) + parseFloat(getOrderTotal(order)), 2);
    }, "0.00");
  }

  getFirstOrder() {
    const { customerOrders } = this.props;
    let firstOrder = {};
    Object.keys(customerOrders).forEach((pI) => {
      const order = customerOrders[pI];
      if (!firstOrder.orderPlaced || order.orderPlaced < firstOrder.orderPlaced) {
        firstOrder = order;
      }
    });
    return firstOrder;
  }

  getLastOrder() {
    const { customerOrders } = this.props;
    let lastOrder = {};
    Object.keys(customerOrders).forEach((pI) => {
      const order = customerOrders[pI];
      if (!lastOrder.orderPlaced || order.orderPlaced > lastOrder.orderPlaced) {
        lastOrder = order;
      }
    });
    return lastOrder;
  }

  render() {
    const {
      onRequestClose,
      isOpen,
      connectToCustomerChefOrdersLoading,
      connectToCustomerChefOrdersLoadingError,
      customerOrders,
    } = this.props;
    const { irpPaymentIntentId } = this.state;
    return (
      <NewModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        title={this.getLastOrder().fullName}
        desc={"Customer Details"}
        css={{ paddingInline: 0 }}
        headerCss={{ paddingInline: "1.5em" }}
      >
        <Grid css={{ gridAutoFlow: "row", maxWidth: "100%", mx: "1.5em", alignContent: "center" }}>
          {connectToCustomerChefOrdersLoading && (
            <ReactLoading type="spin" height="20px" width="20px" color="#000000" />
          )}
          {connectToCustomerChefOrdersLoadingError && <div>Error connecting to customer</div>}
          <Grid
            css={{
              gridTemplateColumns: "repeat(auto-fill, minmax(125px, 1fr))",
              gap: 24,
              maxWidth: "100%",
            }}
          >
            <CustomerStat
              name="Orders placed"
              value={
                getCustomerOrderString(Object.keys(customerOrders).length) === "NEW"
                  ? "1"
                  : getCustomerOrderString(Object.keys(customerOrders).length)
              }
            />
            <CustomerStat
              name="Total spent"
              value={"$" + this.getTotalSpent().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            />
            <CustomerStat
              name="Revenue generated"
              value={"$" + this.getTotalRevenue().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            />
            <CustomerStat
              name="Customer since"
              value={getTimestampMonthDateYear(this.getFirstOrder().orderPlaced)}
            />
          </Grid>
          <Separator space="$space$xl" />
          <Flex
            css={{
              marginBottom: 24,
              ff: "$arboria",
              fw: "$semi_bold",
              fs: "$lg",
              color: "$gray12",
            }}
          >
            Order history
          </Flex>
          <Table css={{ mx: "-1.5em" }}>
            <Row
              css={{
                backgroundColor: "transparent",
                borderBottom: "2px solid $gray7",
                "&:hover": { backgroundColor: "transparent" },
              }}
            >
              <Head>Order ID</Head>
              <Head>Date</Head>
              <Head>Total</Head>
              <Head></Head>
            </Row>
            {Object.keys(customerOrders)
              .sort((a, b) => {
                const orderA = customerOrders[a];
                const orderB = customerOrders[b];
                return orderB.orderPlaced - orderA.orderPlaced;
              })
              .map((pI, i) => {
                return (
                  <OrderRow
                    order={customerOrders[pI]}
                    key={i}
                    onClick={() => this.setState({ irpPaymentIntentId: pI })}
                  />
                );
              })}
          </Table>
          <ItemizedReceipt
            isOpen={!!irpPaymentIntentId}
            paymentIntentId={irpPaymentIntentId}
            onRequestClose={() => this.setState({ irpPaymentIntentId: null })}
          />
        </Grid>
      </NewModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customerOrders: state.orderManagement.customerOrders,
    connectToCustomerChefOrdersLoading: state.orderManagement.connectToCustomerChefOrdersLoading,
    connectToCustomerChefOrdersError: state.orderManagement.connectToCustomerChefOrdersError,
  };
};

export default connect(
  mapStateToProps,
  {}
)(function (props) {
  const user = usePortalUser();
  return <CustomerModalMaster {...props} hostId={user.chefId} />;
});
