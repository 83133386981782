/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { usePortalUser } from "../../auth";
import Login from "../../hotplate-common/Login";

const Reauthenticate = ({
  onReauthenticate,
  close,
}: {
  onReauthenticate: () => void;
  close: () => void;
}) => {
  const { chefId } = usePortalUser();
  const [originalHostId] = useState(chefId);

  function onLogin() {
    if (chefId === originalHostId) {
      onReauthenticate();
    }
    close();
  }

  return (
    <Login
      close={close}
      showCloseIcon={true}
      callback={onLogin}
      title="Confirm it's you"
      subtitle="Login again to confirm your identity"
      isPortal={true}
    />
  );
};

export default Reauthenticate;
