// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./CheckoutLocationCard.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      K5rnd7UDHA: {
        none: {
          text: "Location Name",
        },
      },
      W81RtY3AQF: {
        none: {
          text: "1337 Gamer Ave, Austin, TX 78705",
        },
        disabled: {
          text: "Unavailable :(",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "selected",
  },
];

export const CheckoutLocationCardContext = createContext(null);

function CheckoutLocationCard(_props) {
  const defaults = useContext(CheckoutLocationCardContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="HLQCskHeX9"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "HLQCskHeX9",
        scope: "PBPwaULA4G",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <TextPrimitive
            id={"title_K5rnd7UDHA"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PBPwaULA4G_K5rnd7UDHA"
            }
            key={"K5rnd7UDHA"}
            text={
              exists(props.title) ? props.title : getStyle("K5rnd7UDHA", "text")
            }
            element={getStyle("K5rnd7UDHA", "htmlElement")}
          />
          <SpacerPrimitive
            id={"Spacer_ToLZK6SLoA"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PBPwaULA4G_ToLZK6SLoA"
            }
            key={"ToLZK6SLoA"}
          />
          <TextPrimitive
            id={"subtitle_W81RtY3AQF"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PBPwaULA4G_W81RtY3AQF"
            }
            key={"W81RtY3AQF"}
            text={
              exists(props.subtitle)
                ? props.subtitle
                : getStyle("W81RtY3AQF", "text")
            }
            element={getStyle("W81RtY3AQF", "htmlElement")}
          />
        </>
      )}
    </RootPrimitive>
  );
}

CheckoutLocationCard.__variants = [
  {
    name: "selected",
    type: "variant",
  },
];

export default CheckoutLocationCard;
