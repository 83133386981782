import {
  SET_CUSTOMER_ADDRESS_DICT,
  SET_CUSTOMER_ADDRESS_UNIT,
  SET_CUSTOMER_DELIVERY_INSTRUCTIONS,
  SET_DELIVERY_FEE,
} from "./types";
import { persistor } from "../../Store";

export const setDeliveryFee = (deliveryFee) => {
  return (dispatch) => {
    dispatch({
      type: SET_DELIVERY_FEE,
      payload: deliveryFee,
    });
    persistor.flush();
  };
};

export const setCustomerDeliveryInstructions = (deliveryInstructions) => {
  return (dispatch) => {
    dispatch({
      type: SET_CUSTOMER_DELIVERY_INSTRUCTIONS,
      payload: deliveryInstructions,
    });
  };
};

export const setCustomerAddressDict = (addressDict) => {
  return (dispatch) => {
    dispatch({
      type: SET_CUSTOMER_ADDRESS_DICT,
      payload: addressDict,
    });
  };
};

export const setCustomerAddressUnit = (addressUnit) => {
  return (dispatch) => {
    dispatch({
      type: SET_CUSTOMER_ADDRESS_UNIT,
      payload: addressUnit,
    });
  };
};
