// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./ConfirmationHeader.OrderNum.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, TextPrimitive } from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      "4Cgvxqhohr": {
        none: {
          text: "#",
        },
      },
      "2f196V3KNv": {
        none: {
          text: "00999",
        },
      },
      Q12EyQyKNc: {
        none: {
          text: "•",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "last",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "last",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "last",
  },
];

export const OrderNumContext = createContext(null);

function OrderNum(_props) {
  const defaults = useContext(OrderNumContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="VTJNYDQ47F"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "VTJNYDQ47F",
        scope: "JMozvhXopt",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <TextPrimitive
            id={"Order #_4Cgvxqhohr"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_JMozvhXopt_4Cgvxqhohr"
            }
            key={"4Cgvxqhohr"}
            text={getStyle("4Cgvxqhohr", "text")}
            element={getStyle("4Cgvxqhohr", "htmlElement")}
          />
          <TextPrimitive
            id={"orderNum_2f196V3KNv"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_JMozvhXopt_2f196V3KNv"
            }
            key={"2f196V3KNv"}
            text={
              exists(props.orderNum)
                ? props.orderNum
                : getStyle("2f196V3KNv", "text")
            }
            element={getStyle("2f196V3KNv", "htmlElement")}
          />
          <TextPrimitive
            id={"Order #_Q12EyQyKNc"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_JMozvhXopt_Q12EyQyKNc"
            }
            key={"Q12EyQyKNc"}
            text={getStyle("Q12EyQyKNc", "text")}
            element={getStyle("Q12EyQyKNc", "htmlElement")}
          />
        </>
      )}
    </RootPrimitive>
  );
}

OrderNum.__variants = [
  {
    name: "last",
    type: "variant",
  },
];

export default OrderNum;
