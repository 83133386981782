import React from "react";
import { styled } from "../../stitches.config";
import { HomeIcon } from "@radix-ui/react-icons";

const DeliveryAddressButtonPrimitive = styled("button", {
  display: "flex",
  alignItems: "center",
  flexShrink: 0,
  padx: "$sm",
  height: "$sm_btn",
  width: "100%",
  minWidth: 0,
  opacity: 1,
  backgroundColor: "$gray4",
  br: "$sm",
  transition:
    "opacity 200ms ease-in-out, minHeight 200ms ease-in-out, height 200ms ease-in-out, background-color 150ms ease-in-out",
  flex: 1,
  cursor: "pointer",

  textStyle: "text-2",
  color: "$gray12",
  fontFamily: "$inter",
  "&:hover": {
    backgroundColor: "$gray5",
  },
  "&:active": {
    backgroundColor: "$gray6",
  },
  "&:disabled": {
    opacity: 0,
    minHeight: 0,
    height: 0,
    pointerEvents: "none",
    cursor: "default",
  },
  "& svg": {
    marginRight: "$sm",
    height: 16,
    width: 16,
  },
  "@desktop_md": {
    textStyle: "text-3",
    padx: "$md",
    h: "$md_btn",
    br: "$md",
    "& svg": {
      marginRight: "$md",
      height: 18,
      width: 18,
    },
  },
  variants: {
    error: {
      true: {
        backgroundColor: "$error3",
        color: "$error11",
        "&:hover": {
          backgroundColor: "$error5",
        },
        "&:active": {
          backgroundColor: "$error6",
        },
      },
    },
  },
});

const Span = styled("span", {
  display: "inline",
  maxWidth: "80%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

export const DeliveryAddressButton = ({ onClick, text, error, ...props }) => {
  return (
    <DeliveryAddressButtonPrimitive error={error} {...props} onClick={onClick}>
      <HomeIcon />
      <Span>{text}</Span>
    </DeliveryAddressButtonPrimitive>
  );
};
