import React from "react";
import { getChefStyles } from "../../hotplate-common/chefStyleFunctions";
import { Button } from "../../hotplate-common/primitives/Button";
import { styled } from "../../stitches.config";
import { ReminderDialog } from "../shop/ReminderDialog";
import { BellIcon } from "@radix-ui/react-icons";
import type { CSS } from "@stitches/react";
import { Link } from "react-router-dom";
import { getCustomButtonColorsFromChef } from "@hotplate/utils-ts/helperFunctions";
import { SiteSettings } from "../shop/types";
import { BuyGiftCard } from "../shop/BuyGiftCard";
import { Row } from "../../hotplate-common/primitives/Containers";
const Header = styled("header", {
  display: "flex",
  width: "100%",
  height: "72px",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "$elevation5",
  "@desktop_md": {
    position: "sticky",
    top: 0,
    zIndex: "$sticky",
  },
  variants: {
    settings_preview: {
      true: {
        position: "relative",
        zIndex: "$baseline",
        borderRadius: "$md",
        overflow: "hidden",
      },
    },
  },
});

const Text = styled("h1", {
  fontFamily: "$arboria",
  fontWeight: "$semi_bold",
  fontSize: "$xl",
  lh: "$text",
  overflowX: "hidden",
  textOverflow: "ellipsis",
  color: "white",
});

const Img = styled("img", {
  height: "40px",
  objectFit: "contain",
  maxWidth: "100%",
  minWidth: 0,
});

const Content = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "$sm",
  padx: "$sm",
  mx: "auto",
  width: "100%",
  maxWidth: "$maxContentWidth",
  "@desktop_md": {
    padx: "$lg",
  },
});

export const StorefrontHeader = ({
  siteSettings,
  navOnClickTo,
  settings_preview,
  css,
  type,
  ...props
}: {
  siteSettings: SiteSettings;
  type: "storefront" | "drop";
  navOnClickTo?: string;
  settings_preview?: boolean;
  css?: CSS;
}) => {
  const chefStyles = getChefStyles(siteSettings);

  const headerLogoContent = !chefStyles.headerImage ? (
    <Text css={{ color: chefStyles.headerTextColor }}>{siteSettings.restaurantName}</Text>
  ) : (
    <Img src={chefStyles.headerImage} alt={`${siteSettings.restaurantName}'s Logo`} />
  );
  return (
    <Header
      settings_preview={settings_preview}
      css={{
        backgroundColor: chefStyles.headerBackgroundColor,
        ...css,
      }}
      {...props}
    >
      <Content>
        {!navOnClickTo ? (
          headerLogoContent
        ) : (
          <Link
            to={navOnClickTo}
            style={{
              textDecoration: "none",
            }}
          >
            {headerLogoContent}
          </Link>
        )}
        <Row>
          {siteSettings.isGiftCardsEnabledOnStorefront !== false && type === "storefront" && (
            <BuyGiftCard />
          )}
          <ReminderDialog
            title="Don't miss out."
            description="Give us your phone number and we will send you a text a few minutes before a new sale starts."
            trackSource="header"
            trigger={
              <Button
                color="info"
                variant="tinted"
                as="div"
                customButtonColorStyle={getCustomButtonColorsFromChef(chefStyles)}
                css={{
                  ml: "$md",
                  display:
                    siteSettings.isGiftCardsEnabledOnStorefront !== false && type === "storefront"
                      ? "none"
                      : "flex",
                  "@tablet": {
                    display: "flex",
                  },
                }}
              >
                <BellIcon />
                Join
              </Button>
            }
          />
        </Row>
      </Content>
    </Header>
  );
};
