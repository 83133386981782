/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import ReactLoading from "react-loading";
import { ManageBilling, PlanCard } from "../../visly/Billing";
import { Button } from "../../visly/Primitives";
import { textstyles, colors } from "../../visly";
import { IS_PROD } from "../../hotplate-storefront/actions/types";

import {
  getBillingPortalUrl as getBillingPortalUrlUnconnected,
  getNewSubscriptionSessionId as getNewSubscriptionSessionIdUnconnected,
  getSubscriptionInformation as getSubscriptionInformationUnconnected,
} from "../../hotplate-storefront/actions";
import { usePortalUser } from "../../auth";
import { usePrevious } from "../../hooks";
import { useConnectedFn } from "../../hotplate-common/HelperFunctions";

let stripePromise = "";
if (IS_PROD) stripePromise = loadStripe("pk_live_qtxQChcCyNxgExt5lqELz8ce00em0ekyot");
else stripePromise = loadStripe("pk_test_yVk7wVIoE6vAuoy8RvIe14zP00aiAEmLAd");

const PlanCardButton = ({
  plan,
  onClick,
  selected,
  active,
  getBillingPortalUrlLoading,
  getNewSubscriptionSessionIdLoading,
  redirectingToStripe,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        minWidth: "265px",
        flex: 1,
      }}
    >
      <PlanCard
        title={plan.plan}
        priceToChef={plan.priceString}
        hotplatePercentFee={plan.hotplateFeeString}
        selected={selected}
      />
      <Button
        style={{ width: "100%", marginTop: "16px" }}
        // text={selected ? 'Current Plan' : 'Switch to this plan'}
        // disabled={selected}
        text={
          plan.plan === "Standard"
            ? active
              ? "Manage Subscription"
              : "Activate your subscription"
            : selected
            ? "Current Plan"
            : "Switch to this plan"
        }
        disabled={plan.plan !== "Standard"}
        loading={
          getBillingPortalUrlLoading || getNewSubscriptionSessionIdLoading || redirectingToStripe
        }
        loadingAnimation={<ReactLoading type={"spin"} color={"#FFFFFF"} width={24} height={24} />}
        kind={selected ? "good" : "secondary"}
        onClick={onClick}
      />
    </div>
  );
};

export default function Billing() {
  const user = usePortalUser();

  const { customerSubscriptionId, siteSettings } = useSelector(
    (state) => state.hostPortal.hostInfo
  );
  const {
    getBillingPortalUrlResult,
    getBillingPortalUrlLoading,
    getBillingPortalUrlError,
    getNewSubscriptionSessionIdLoading,
    getNewSubscriptionSessionIdResult,
    getNewSubscriptionSessionIdError,
    getSubscriptionInformationLoading,
    getSubscriptionInformationResult,
    getSubscriptionInformationError,
  } = useSelector((state) => state.subscription);

  const dispatch = useDispatch();
  const getBillingPortalUrl = useConnectedFn(getBillingPortalUrlUnconnected, dispatch);
  const getNewSubscriptionSessionId = useConnectedFn(
    getNewSubscriptionSessionIdUnconnected,
    dispatch
  );
  const getSubscriptionInformation = useConnectedFn(
    getSubscriptionInformationUnconnected,
    dispatch
  );

  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [redirectingToStripe, setRedirectingToStripe] = useState(false);

  function activateSubscription() {
    getNewSubscriptionSessionId(user.chefId);
  }

  useEffect(() => {
    if (customerSubscriptionId !== "" && typeof customerSubscriptionId === "string") {
      getSubscriptionInformation(customerSubscriptionId);
    }
  }, [customerSubscriptionId]);

  const prevProps = usePrevious({
    getBillingPortalUrlLoading,
    getBillingPortalUrlResult,
    getBillingPortalUrlError,
    getNewSubscriptionSessionIdLoading,
    getNewSubscriptionSessionIdResult,
    getNewSubscriptionSessionIdError,
    getSubscriptionInformationLoading,
    getSubscriptionInformationError,
    getSubscriptionInformationResult,
  });

  useEffect(() => {
    if (!prevProps) {
      return;
    }

    if (
      prevProps.getBillingPortalUrlLoading &&
      !getBillingPortalUrlLoading &&
      getBillingPortalUrlResult !== "" &&
      getBillingPortalUrlError === ""
    ) {
      window.location.href = getBillingPortalUrlResult;
    }
    if (
      prevProps.getNewSubscriptionSessionIdLoading &&
      !getNewSubscriptionSessionIdLoading &&
      getNewSubscriptionSessionIdResult !== "" &&
      getNewSubscriptionSessionIdError === ""
    ) {
      setRedirectingToStripe(true);
      stripePromise.then((stripe) => {
        stripe
          .redirectToCheckout({
            sessionId: getNewSubscriptionSessionIdResult,
          })
          .then(() => setRedirectingToStripe(false));
      }); // TODO handle error
    }
    if (
      prevProps.getSubscriptionInformationLoading &&
      !getSubscriptionInformationLoading &&
      getSubscriptionInformationError === ""
    ) {
      const subscriptionInformation = getSubscriptionInformationResult;
      if (
        !subscriptionInformation ||
        !Array.isArray(subscriptionInformation.paymentHistory) ||
        subscriptionInformation.paymentHistory.length === 0
      ) {
        // pass
      } else if (
        subscriptionInformation.paymentHistory[subscriptionInformation.paymentHistory.length - 1]
          .periodEnd < new Date().getTime()
      ) {
        setSubscriptionActive(false);
      } else {
        setSubscriptionActive(true);
      }
    }
  });

  function getHotplatePlan() {
    if (siteSettings.hotplateFee) {
      if (parseFloat(siteSettings.hotplateFee) === 0.05) {
        return {
          plan: "Free",
          hotplateFee: 0.05,
          price: 0,
          hotplateFeeString: "5%",
          priceString: "$0",
        };
      } else if (parseFloat(siteSettings.hotplateFee) === 0.02) {
        return {
          plan: "Standard",
          hotplateFee: 0.02,
          price: 29,
          hotplateFeeString: "2%",
          priceString: "$29",
          active: subscriptionActive,
        };
      }
    }
    return {
      plan: "Hotplate OG",
      hotplateFee: 0.0,
      price: 0,
      hotplateFeeString: "0%",
      priceString: "$0",
    };
  }

  if (getSubscriptionInformationLoading) {
    return (
      <div
        style={{
          display: "flex",
          backgroundColor: "white",
          flex: 1,
          paddingTop: "30px",
          paddingLeft: "30px",
          boxSizing: "border-box",
        }}
      >
        <ReactLoading type={"spin"} color={"#000000"} height={50} width={50} />
      </div>
    );
  }

  // const hotplatePlans = [
  //   {
  //     plan: "Free",
  //     hotplateFee: 0.05,
  //     price: 0,
  //     hotplateFeeString: "5%",
  //     priceString: "$0",
  //   },
  //   {
  //     plan: "Standard",
  //     hotplateFee: 0.02,
  //     price: 29,
  //     hotplateFeeString: "2%",
  //     priceString: "$29",
  //   },
  // ];

  const subscriptionPlan = getHotplatePlan();
  console.log(subscriptionPlan);

  // TODO - if "manage account in the stripe portal is only used to cancel a subscription, then just cancel the subscription when switching bsack to the free plan"
  // TODO - add Toast to show that the subscription was switched successfully
  // TODO - add proper onClicks to buttons depending on the plan

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        backgroundColor: "white",
        paddingTop: "24px",
        width: "100%",
        boxSizing: "border-box",
        justifyContent: "center",
      }}
    >
      <ManageBilling title="Manage your Hotplate plan" style={{ maxWidth: "768px" }}>
        <div
          style={{
            display: "flex",
            flexFlow: "column",
            width: "100%",
            justifyContent: "center",
            padding: "32px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexFlow: "row wrap",
              width: "100%",
              justifyContent: "center",
              gap: "48px",
            }}
          >
            <PlanCardButton
              plan={subscriptionPlan}
              getBillingPortalUrlLoading={getBillingPortalUrlLoading}
              getNewSubscriptionSessionIdLoading={getNewSubscriptionSessionIdLoading}
              redirectingToStripe={redirectingToStripe}
              onClick={() => {
                if (subscriptionPlan.plan === "Standard") {
                  if (subscriptionPlan.active) getBillingPortalUrl(customerSubscriptionId);
                  else activateSubscription();
                }
              }}
              selected={true}
              active={subscriptionPlan.active}
            />
          </div>
          <span
            style={{
              ...textstyles.body,
              color: colors.gray600,
              textAlign: "center",
              marginTop: "48px",
            }}
          >
            Your plan includes a payment processing fee of 2.9% + 30¢ per transaction, paid by you.
          </span>
        </div>
      </ManageBilling>
    </div>
  );
}
