import fetchRetry from "./FetchRetry";
import {
  SET_CONFIRMATION_ORDER,
  CONNECT_TO_CUSTOMER_ORDER_START,
  CONNECT_TO_CUSTOMER_ORDER_SUCCESS,
  CONNECT_TO_CUSTOMER_ORDER_FAILURE,
  backendUrl,
} from "./types";

export const setConfirmationOrder = (order) => {
  return (dispatch) => {
    dispatch({
      type: SET_CONFIRMATION_ORDER,
      payload: order,
    });
  };
};

export const getConfirmationPageData = (phone, shortPaymentIntentId) => {
  return async (dispatch) => {
    dispatch({ type: CONNECT_TO_CUSTOMER_ORDER_START });
    fetchRetry(backendUrl + "getConfirmationPageData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone,
        shortPaymentIntentId,
      }),
    })
      .then((response) =>
        response.json().then((json) => {
          if ((response.status >= 400 && response.status < 600) || json.error) {
            dispatch({
              type: CONNECT_TO_CUSTOMER_ORDER_FAILURE,
              payload: "SERVER_ERROR",
            });
            return;
          }
          dispatch({
            type: CONNECT_TO_CUSTOMER_ORDER_SUCCESS,
            payload: json["data"],
          });
        })
      )
      .catch((exception) => {
        console.log(exception);
        dispatch({
          type: CONNECT_TO_CUSTOMER_ORDER_FAILURE,
          payload: "SERVER_ERROR",
        });
      });
  };
};
