import React, { useCallback, useEffect, useRef, useState } from "react";
import { PackingCard } from "./PackingCard";
import { Flex } from "../../../hotplate-common/primitives/Containers";
import _ from "lodash";
import { PackingCardPrint } from "./PackingCardPrint";
import { useSelector } from "react-redux";
import { PackingCardListLoader } from "../../../hotplate-common/loaders/PackingLoaders";
import { NothingHere } from "./NothingHere";

const NUM_BUFFER_CARDS = 10;

export const PackingCardList = ({ functions, orders, isPrinting }) => {
  const connectToOrdersLoading = useSelector(
    (state) => state.orderManagement.connectToOrdersLoading
  );
  const [throttle] = useState(() => {
    return _.throttle(
      (func) => {
        func();
      },
      500,
      { leading: false, trailing: true }
    );
  });

  const [cardWidth, setCardWidth] = useState(Math.min(375, window.innerWidth * 0.95));
  const [numVisibleCards, setNumVisibleCards] = useState(Math.ceil(window.innerWidth / cardWidth));
  const [idxOfFirstVisibleCard, setIdxOfFirstVisibleCard] = useState(0);

  const leftBuffer = Math.min(NUM_BUFFER_CARDS, idxOfFirstVisibleCard);
  const rightBuffer = Math.max(
    0,
    Math.min(NUM_BUFFER_CARDS, orders.length - (idxOfFirstVisibleCard + numVisibleCards))
  );
  const numRenderedCards = Math.min(orders.length, leftBuffer + numVisibleCards + rightBuffer);
  const idxOfFirstRenderedCard = idxOfFirstVisibleCard - leftBuffer;
  const numUnrenderedCardsLeft = idxOfFirstRenderedCard;
  const numUnrenderedCardsRight = orders.length - (idxOfFirstRenderedCard + numRenderedCards);

  const scrollNode = useRef();

  const registerScrollEventRef = useCallback(
    (node) => {
      scrollNode.current = node;
      if (node !== null) {
        const handleScroll = () => {
          throttle(() => {
            const scrollLeft = Math.max(0, node.scrollLeft);
            setIdxOfFirstVisibleCard(Math.floor(scrollLeft / cardWidth));
          });
        };

        node.addEventListener("scroll", handleScroll);
      }
    },
    [throttle, cardWidth]
  );

  // If the total width of all cards becomes less than the current scroll position,
  // then force scroll. Otherwise, the current scroll position would remain even
  // after the margin decreased. This probably has something to do with the specific
  // interaction between changing margins and scroll position.
  const totalWidth =
    (numUnrenderedCardsLeft + numRenderedCards + numUnrenderedCardsRight) * cardWidth;
  useEffect(() => {
    if (scrollNode.current && scrollNode.current.scrollLeft > totalWidth) {
      scrollNode.current.scrollLeft = Math.max(0, totalWidth - window.innerWidth);
    }
  }, [totalWidth]);

  useEffect(() => {
    const handleResize = () => {
      throttle(() => {
        const newCardWidth = Math.min(375, window.innerWidth * 0.95);
        setNumVisibleCards(Math.ceil(window.innerWidth / newCardWidth));
        setCardWidth(newCardWidth);
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [throttle]);

  return (
    <>
      {/* Print Box */}
      {isPrinting && <PackingCardPrint orders={orders} functions={functions} />}
      {/* Virtualized List */}
      {orders.length === 0 ? (
        connectToOrdersLoading ? (
          <PackingCardListLoader css={{ width: cardWidth }} />
        ) : (
          <NothingHere />
        )
      ) : (
        <Flex
          ref={registerScrollEventRef}
          css={{
            height: "100%",
            maxHeight: "-webkit-fill-available",
            overflow: "auto",
            hideOnPrint: true,
          }}
        >
          <Flex
            css={{
              display: "flex",
              marginLeft: numUnrenderedCardsLeft * cardWidth + "px",
              marginRight: numUnrenderedCardsRight * cardWidth + "px",
            }}
          >
            {orders
              .slice(idxOfFirstRenderedCard, idxOfFirstRenderedCard + numRenderedCards)
              .map((order) => (
                <Flex
                  key={order.paymentIntentId}
                  css={{
                    padx: "$xs",
                    paddingBottom: "$xs",
                    paddingTop: "$xxs",
                    width: cardWidth,
                  }}
                >
                  <PackingCard view="ticket" functions={functions} order={order} />
                </Flex>
              ))}
          </Flex>
        </Flex>
      )}
    </>
  );
};
