import React, { useState } from "react";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";
import _ from "lodash";

import { MenuConfigItemCard, ObjectSelectionModal } from "../../../../visly/Events";
import { SearchBar } from "../../../../visly/Primitives";
import { useBreakpoint } from "../../../../visly";

import { getSortedMenuItems } from "@hotplate/utils-ts/helperFunctions";
import { setToastPopupInfo } from "../../../../hotplate-storefront/actions";

import Modal from "../../../../hotplate-common/Modal";
import Spacer from "../../../../hotplate-common/Spacer";
import AddEditMenuItemModal from "./AddEditMenuItemModal";
import { shouldRetry, trpc } from "../../../../trpc";

/**
 * Create a new menu item, optionally providing an existing menu item object to
 * copy from.
 * @param {object} [menuItemToDuplicate]
 */
function createNewMenuItem(menuItemToDuplicate) {
  const newMenuItemId = uuid();

  if (menuItemToDuplicate) {
    return {
      ..._.cloneDeep(menuItemToDuplicate), // cloneDeep shouldn't be necessary but is for good measure
      id: newMenuItemId,
      creationTime: new Date().getTime(),
      title: menuItemToDuplicate.title + " (COPY)",
      sectionIndex: null,
    };
  }

  return {
    id: newMenuItemId,
    title: "",
    description: "",
    price: "",
    image: "https://ucarecdn.com/c7478fd7-b935-44e7-b406-7889e94f9b3d/MenuPlaceholder.png",
    notesEnabled: false,
    optionCategories: [],
    creationTime: new Date().getTime(),
  };
}

const mapStateToProps = (state) => {
  return {
    hostMenuItems: state.hostPortal.hostInfo.menuItems,
  };
};

export default connect(mapStateToProps, {
  setToastPopupInfo,
})(function AddMenuItemsModal({
  // from connect
  hostMenuItems,
  setToastPopupInfo,

  // from parent
  onComplete,
  onRequestClose,
  optionSelectModalState,
  eventId,
}) {
  const [selectedMenuItemIds, setSelectedMenuItemIds] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [idOfMenuItemBeingEdited, setIdOfMenuItemBeingEdited] = useState(null);
  const [newMenuItem, setNewMenuItem] = useState(null);
  const createMenuItem = trpc.portal.createMenuItem.useMutation({
    retry: shouldRetry(1),
  });

  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);

  const sortedMenuItems = getSortedMenuItems(hostMenuItems);

  function createAndSwitchToNewMenuItem(menuItemToDuplicate) {
    const newMenuItem = createNewMenuItem(menuItemToDuplicate);
    setNewMenuItem(newMenuItem);
    setIdOfMenuItemBeingEdited(newMenuItem.id);
  }

  function toggleMenuItemSelected(menuItemId) {
    const prevIndex = selectedMenuItemIds.indexOf(menuItemId);
    if (prevIndex !== -1) {
      setSelectedMenuItemIds([
        ...selectedMenuItemIds.slice(0, prevIndex),
        ...selectedMenuItemIds.slice(prevIndex + 1),
      ]);
    } else {
      setSelectedMenuItemIds([...selectedMenuItemIds, menuItemId]);
    }
  }

  return (
    <>
      <Modal closeModal={onRequestClose} optionSelectModalState={optionSelectModalState}>
        <ObjectSelectionModal
          className="objectSelection"
          isEmpty={!Array.isArray(sortedMenuItems) || sortedMenuItems.length === 0}
          header="Add Menu Items"
          subHeader={
            <>
              <Spacer size="Large" />
              <SearchBar
                value={searchText}
                onChange={setSearchText}
                placeholder="Search your items..."
              />
            </>
          }
          CreateButton={
            <ObjectSelectionModal.CreateButton
              onClick={() => {
                createAndSwitchToNewMenuItem();
              }}
            />
          }
          CancelButton={
            <ObjectSelectionModal.CancelButton text="Cancel" onClick={onRequestClose} />
          }
          AddButton={
            <ObjectSelectionModal.AddButton
              disabled={selectedMenuItemIds.length === 0}
              onClick={() => {
                onComplete(selectedMenuItemIds);
              }}
              text={
                "Add " +
                selectedMenuItemIds.length +
                " item" +
                (selectedMenuItemIds.length > 1 ? "s" : "")
              }
            />
          }
        >
          {Array.isArray(sortedMenuItems) &&
            sortedMenuItems
              .filter((menuItem) => {
                return menuItem.title
                  .toLowerCase()
                  .replace(" ", "")
                  .includes(searchText.toLowerCase().replace(" ", ""));
              })
              .sort((a, b) => {
                if (!Number.isInteger(a.creationTime)) return 1;
                if (!Number.isInteger(b.creationTime)) return -1;
                return b.creationTime - a.creationTime;
              })
              .map((menuItem) => (
                <MenuConfigItemCard
                  type="addEditModal"
                  size={size}
                  key={menuItem.id}
                  image={
                    <img style={{ maxWidth: "100%", objectFit: "cover" }} src={menuItem.image} />
                  }
                  title={menuItem.title}
                  DuplicateButton={
                    <MenuConfigItemCard.DuplicateButton
                      onClick={() => {
                        createAndSwitchToNewMenuItem(menuItem);
                      }}
                    />
                  }
                  onClick={() => toggleMenuItemSelected(menuItem.id)}
                  selected={selectedMenuItemIds.includes(menuItem.id)}
                />
              ))}
          {(!Array.isArray(sortedMenuItems) || sortedMenuItems.length === 0) && (
            <ObjectSelectionModal.EmptyContainerCta text="You have no menu items, create your first by pressing the button above" />
          )}
        </ObjectSelectionModal>
      </Modal>
      {(newMenuItem || idOfMenuItemBeingEdited) && (
        <AddEditMenuItemModal
          eventId={eventId}
          isNewMenuItem={hostMenuItems[idOfMenuItemBeingEdited] === undefined} // should always be true
          initialMenuItemDiff={newMenuItem}
          menuItem={newMenuItem ? {} : hostMenuItems[idOfMenuItemBeingEdited]}
          menuItemSoldCounts={{}}
          saveMenuItemLoading={createMenuItem.isLoading}
          onRequestSave={async (menuItemDiffIncoming) => {
            // menuItemDiffIncoming won't be null; no remove button will be shown; menu item should always be new
            try {
              await createMenuItem.mutateAsync({
                menuItemId: idOfMenuItemBeingEdited,
                menuItem: menuItemDiffIncoming,
              });
            } catch (e) {
              setToastPopupInfo({
                type: "error",
                text: "Menu item failed to save",
              });
              throw e;
            }
            setIdOfMenuItemBeingEdited(null);
            setNewMenuItem(null);
          }}
          onRequestDelete={() => {
            // No delete button will be shown; menu item should always be new
          }}
          onRequestClose={() => {
            setIdOfMenuItemBeingEdited(null);
            setNewMenuItem(null);
          }}
        />
      )}
    </>
  );
});
