// https://github.com/PeerBoard/integration-examples/blob/master/React/PeerBoard.jsx
import type { ForumAPI } from "@peerboard/core";
import { createForum } from "@peerboard/core";
import "firebase/auth";
import React, { useEffect, useRef, useState } from "react";
import { trpc } from "../../trpc";

// Settings -> Hosting -> Board ID
const boardID = 1562947242;
// Settings -> Hosting -> Path Prefix
const pathPrefix = "/portal/community";

// Somewhere in your routing configuration. Don't use exact attribute - you should allow all nested routes
// <Route path="<PATH PREFIX>" component={PageWithPeerboard} />

export function PeerBoard() {
  const containerRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const getPeerBoardToken = trpc.portal.getPeerBoardToken.useMutation();

  useEffect(() => {
    let forum: ForumAPI | undefined;
    (async () => {
      const options = {
        // Optional. Do this only if you want to implement seamless login https://community.peerboard.com/post/183197627?category=2097967386
        // Add auth token so the user will be authenticated when community is loaded
        jwtToken: await getPeerBoardToken.mutateAsync.call(undefined),

        prefix: pathPrefix,

        // Recommended setting so that the forum container
        // will always occupy all available space
        minHeight: window.innerHeight,

        // Update your page title according to the forum state
        //   onTitleChanged: (title) => (window.document.title = "Your title " + title),

        // You can remove the loaded and forum will be showed to the user
        onReady: () => {
          setIsLoading(false);
        },
        onFail: () => {
          setIsLoading(false);
          setError("Failed to load forum...");
        },

        baseURL: "https://peerboard.hotplate.com/",
      };

      if (containerRef.current) {
        forum = await createForum(boardID, containerRef.current, options);
      }
    })().catch((err) => setError(err.message));
    return () => {
      forum?.destroy();
    };
  }, [getPeerBoardToken.mutateAsync]);

  return (
    <div>
      {error}
      {isLoading && "Loading..."}
      <div ref={containerRef}></div>
    </div>
  );
}
