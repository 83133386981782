// Identify on login

export function identify(userId, userObj) {
  const u = userObj;
  window.analytics.identify(userId, {
    type: u.hostId ? "chef" : "eater",
    chef_id: u.hostId && u.hostId,
    email: u.email && u.email,
    instagram: u.instaHandle && u.instaHandle,
    first_name: u.firstName && u.firstName,
    last_name: u.lastName && u.lastName,
    phone: u.phone && u.phone,
  });
}

function getChefPlan(hotplateFee) {
  if (hotplateFee === "0.00") {
    return "Free + 0%";
  } else if (hotplateFee === "0.05") {
    return "Free + 5%";
  } else if (hotplateFee === "0.02") {
    return "$30 + 2%";
  }
  return "Free + 0%";
}

export function group(chef_id, groupObj) {
  const g = groupObj;
  window.analytics.group(chef_id, {
    chef_id: g.chefId,
    $name: g.restaurantName,
    $avatar: g.bannerImage,
    $website: "hotplate.com/" + g.chefId,
    $phone: g.phone,
    instagram: "instagram.com/" + g.instaHandle,
    $email: g.email,
    $plan: getChefPlan(g.hotplateFee),
  });
}

export function trackFirebaseConnectionLost() {
  window.analytics.track("Frontend Firebase Connection Lost", {});
}

// Reset on logout

export function trackLogout() {
  window.analytics.reset();
}
