import React from "react";
import { styled } from "../../stitches.config";
import { ExclamationTriangleIcon, CheckCircledIcon, InfoCircledIcon } from "@radix-ui/react-icons";

const CalloutIcon = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "$xs",
  borderRadius: "$pill",
});

const CalloutPrimitive = styled("div", {
  display: "flex",
  flexDirection: "row",
  padding: "$xs",
  borderRadius: "$lg",
  alignItems: "center",
  fontFamily: "$inter",
  textStyle: "text-1",
  [`& ${CalloutIcon}`]: {
    marginRight: "$xs",
  },
  "@tablet": {
    textStyle: "text-2",
    "& svg": {
      height: 20,
      width: 20,
    },
    [`& ${CalloutIcon}`]: {
      marginRight: "$sm",
    },
  },
  variants: {
    type: {
      info: {
        color: "$info11",
        backgroundColor: "$info3",
      },
      success: {
        color: "$success11",
        backgroundColor: "$success4",
      },
      warning: {
        color: "$warning11",
        backgroundColor: "$warning4",
      },
      accent: {
        color: "$accent11",
        backgroundColor: "$accent4",
      },
    },
  },
  defaultVariants: {
    type: "info",
  },
});

export const Callout = ({ type, children, hideIcon, ...props }) => {
  return (
    <CalloutPrimitive {...props} type={type}>
      {!hideIcon && (
        <CalloutIcon>
          {(type === "info" || !type) && <InfoCircledIcon />}
          {type === "success" && <CheckCircledIcon />}
          {type === "warning" && <ExclamationTriangleIcon />}
        </CalloutIcon>
      )}
      {children}
    </CalloutPrimitive>
  );
};
