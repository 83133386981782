import React from "react";
import { styled } from "../../stitches.config";
import * as SwitchPrimitive from "@radix-ui/react-switch";

const SwitchContentItem = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 9,
  fontFamily: "$avenir",
  fontWeight: "semi_bold",
  transition: "color 200ms ease-in-out",
});

const StyledSwitch = styled(SwitchPrimitive.Root, {
  all: "unset",
  display: "flex",
  alignItems: "center",
  backgroundColor: "$gray5",
  position: "relative",
  cursor: "pointer",
  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
  '&[data-state="checked"]': {
    [`& ${SwitchContentItem}`]: { color: "$white" },
    [`& ${SwitchContentItem}:first-child`]: { color: "$acccent11" },
  },
  '&[data-state="unchecked"]': {
    [`& ${SwitchContentItem}`]: { color: "$acccent11" },
    [`& ${SwitchContentItem}:first-child`]: { color: "$white" },
  },
  variants: {
    size: {
      small: {
        height: 32,
        borderRadius: "$sm",
        fontSize: 13,
      },
      medium: {
        height: 42,
        borderRadius: "$md",
        fontSize: 15,
      },
    },
  },
  defaultVariants: {
    size: "medium",
  },
});

const StyledThumb = styled(SwitchPrimitive.Thumb, {
  display: "block",
  position: "absolute",
  left: 0,
  height: "100%",
  backgroundColor: "$accent9",
  borderRadius: "inherit",
  boxShadow: `0 2px 2px $overlay10`,
  transition: "transform 200ms",
  transform: "translateX(0px)",
  willChange: "transform",
  '&[data-state="checked"]': {
    transform: "translateX(100%)",
  },
});

// Exports
const Switch = StyledSwitch;
const SwitchThumb = StyledThumb;
// TODO not very reusable right now, when required, make this a more friendly API/component
export const TextSwitch = ({
  chefStyles,
  checked,
  width = 85, // the width of each option button and the thumb itself, the overall component will be 2.25x this value
  uncheckedContent,
  checkedContent,
  onCheckedChange,
  defaultChecked,
  css,
  ...props
}) => {
  return (
    <Switch
      {...props}
      checked={checked}
      onCheckedChange={onCheckedChange}
      defaultChecked={defaultChecked}
      css={
        chefStyles
          ? {
              width: `calc(${width} * 2.25)`,
              backgroundColor: chefStyles.primaryColorLight,
              '&[data-state="checked"]': {
                [`& ${SwitchContentItem}`]: { color: chefStyles.textColor },
                [`& ${SwitchContentItem}:first-child`]: { color: "$gray10" },
              },
              '&[data-state="unchecked"]': {
                [`& ${SwitchContentItem}`]: { color: "$gray10" },
                [`& ${SwitchContentItem}:first-child`]: { color: chefStyles.textColor },
              },
              ...css,
            }
          : { css }
      }
    >
      <SwitchContentItem css={{ width: width }}>{uncheckedContent}</SwitchContentItem>
      <SwitchContentItem css={{ width: width }}>{checkedContent}</SwitchContentItem>
      <SwitchThumb
        css={
          chefStyles ? { backgroundColor: chefStyles.primaryColor, width: width } : { width: width }
        }
      />
    </Switch>
  );
};
