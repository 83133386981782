import {
  PREPARE_QUEUE_EVENT_REMINDERS_START,
  PREPARE_QUEUE_EVENT_REMINDERS_SUCCESS,
  PREPARE_QUEUE_EVENT_REMINDERS_FAILURE,
  CONNECT_TO_PORTAL_EVENTS_START,
  CONNECT_TO_PORTAL_EVENTS_SUCCESS,
  CONNECT_TO_PORTAL_EVENTS_FAILURE,
  DISCONNECT_FROM_PORTAL_EVENTS_START,
  DISCONNECT_FROM_PORTAL_EVENTS_SUCCESS,
  DISCONNECT_FROM_PORTAL_EVENTS_FAILURE,
} from "../actions/types";

const INITIAL_STATE = {
  events: {},
  connectToPortalEventsLoading: false,
  connectToPortalEventsError: null,
  disconnectFromPortalEventsLoading: false,
  disconnectFromPortalEventsError: null,

  saveEventLoading: false,
  saveEventError: "",

  prepareQueueEventRemindersLoading: false,
  prepareQueueEventRemindersError: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DISCONNECT_FROM_PORTAL_EVENTS_START:
      return {
        ...state,
        disconnectFromPortalEventsLoading: true,
        disconnectFromPortalEventsError: null,
      };
    case DISCONNECT_FROM_PORTAL_EVENTS_SUCCESS:
      return { ...state, disconnectFromPortalEventsLoading: false };
    case DISCONNECT_FROM_PORTAL_EVENTS_FAILURE:
      return {
        ...state,
        disconnectFromPortalEventsLoading: false,
        disconnectFromPortalEventsError: action.payload,
      };
    case CONNECT_TO_PORTAL_EVENTS_START:
      return {
        ...state,
        connectToPortalEventsLoading: true,
        connectToPortalEventsError: null,
      };
    case CONNECT_TO_PORTAL_EVENTS_SUCCESS:
      return { ...state, connectToPortalEventsLoading: false, events: action.payload };
    case CONNECT_TO_PORTAL_EVENTS_FAILURE:
      return {
        ...state,
        connectToPortalEventsLoading: false,
        connectToPortalEventsError: action.payload,
      };
    case PREPARE_QUEUE_EVENT_REMINDERS_START:
      return {
        ...state,
        prepareQueueEventRemindersLoading: true,
        prepareQueueEventRemindersError: "",
      };
    case PREPARE_QUEUE_EVENT_REMINDERS_SUCCESS:
      return { ...state, prepareQueueEventRemindersLoading: false };
    case PREPARE_QUEUE_EVENT_REMINDERS_FAILURE:
      return {
        ...state,
        prepareQueueEventRemindersLoading: false,
        prepareQueueEventRemindersError: action.payload,
      };

    default:
      return state;
  }
};
