// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./SortButton.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      PwDqXGHh8p: {
        none: {
          icon: icons.chevronUp,
          useMask: true,
        },
      },
      BXyonNKW9y: {
        none: {
          icon: icons.chevronDown,
          useMask: true,
        },
      },
    },
  },
  {
    type: "enum",
    propName: "sort",
    values: [
      {
        propValue: "ascending",
        layers: {
          BXyonNKW9y: {
            none: {
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "descending",
        layers: {
          PwDqXGHh8p: {
            none: {
              useMask: true,
            },
          },
        },
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "sort",
    values: [
      {
        propValue: "ascending",
        layers: {},
      },
      {
        propValue: "descending",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "sort",
    propValues: ["ascending", "descending"],
  },
];

export const SortButtonContext = createContext(null);

function SortButton(_props) {
  const defaults = useContext(SortButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="GPw1ZEBBCS"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "GPw1ZEBBCS",
        scope: "T2xEfch6Ea",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Container_BHbyYEGs4c"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_T2xEfch6Ea_BHbyYEGs4c"
            }
            key={"BHbyYEGs4c"}
            addSpacing={false}
            element={getStyle("BHbyYEGs4c", "htmlElement")}
          >
            {
              <IconPrimitive
                id={"up_PwDqXGHh8p"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_T2xEfch6Ea_PwDqXGHh8p"
                }
                key={"PwDqXGHh8p"}
                useMask={getStyle("PwDqXGHh8p", "useMask")}
                src={getStyle("PwDqXGHh8p", "icon")}
              />
            }
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"Container_Eum565skn3"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_T2xEfch6Ea_Eum565skn3"
            }
            key={"Eum565skn3"}
            addSpacing={false}
            element={getStyle("Eum565skn3", "htmlElement")}
          >
            {
              <IconPrimitive
                id={"down_BXyonNKW9y"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_T2xEfch6Ea_BXyonNKW9y"
                }
                key={"BXyonNKW9y"}
                useMask={getStyle("BXyonNKW9y", "useMask")}
                src={getStyle("BXyonNKW9y", "icon")}
              />
            }
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

SortButton.__variants = [
  {
    name: "sort",
    type: "group",
    variants: ["ascending", "descending"],
  },
];

export default SortButton;
