// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./TypeFilter.Button.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import {
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../../_internal_primitives";
import { RadioGroupButtonRoot } from "../../_internal_radio_group";
import * as icons from "../../icons";

const styles = [
  {
    type: "default",
    layers: {
      "segmented-control-button-text": {
        none: {
          text: "Week",
        },
      },
      UdQPj7uj8B: {
        none: {
          icon: icons.paperPlane,
          useMask: true,
        },
        disabled: {
          useMask: true,
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large850",
        layers: {},
      },
      {
        propValue: "med450",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
    ],
  },
  {
    type: "enum",
    propName: "type",
    values: [
      {
        propValue: "shipping",
        layers: {
          "segmented-control-button-text": {
            none: {
              text: "shipping",
            },
          },
          UdQPj7uj8B: {
            none: {
              useMask: true,
            },
            hover: {
              useMask: true,
            },
            disabled: {
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "pickup",
        layers: {
          "segmented-control-button-text": {
            none: {
              text: "pickup",
            },
          },
          UdQPj7uj8B: {
            none: {
              icon: icons.home,
              useMask: true,
            },
            hover: {
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "delivery",
        layers: {
          "segmented-control-button-text": {
            none: {
              text: "delivery",
            },
          },
          UdQPj7uj8B: {
            none: {
              icon: icons.delivery,
              useMask: true,
            },
            hover: {
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "all",
        layers: {
          "segmented-control-button-text": {
            none: {
              text: "all orders",
            },
          },
        },
      },
      {
        propValue: "done",
        layers: {
          "segmented-control-button-text": {
            none: {
              text: "done",
            },
          },
          UdQPj7uj8B: {
            none: {
              icon: icons.vislyCheck,
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "incomplete",
        layers: {
          "segmented-control-button-text": {
            none: {
              text: "incomplete",
            },
          },
        },
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large850",
        layers: {},
      },
      {
        propValue: "med450",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
    ],
  },
  {
    type: "enum",
    propName: "type",
    values: [
      {
        propValue: "shipping",
        layers: {},
      },
      {
        propValue: "pickup",
        layers: {},
      },
      {
        propValue: "delivery",
        layers: {},
      },
      {
        propValue: "all",
        layers: {},
      },
      {
        propValue: "done",
        layers: {},
      },
      {
        propValue: "incomplete",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "selected",
  },
  {
    type: "enum",
    propName: "size",
    propValues: ["large850", "med450", "small"],
  },
  {
    type: "enum",
    propName: "type",
    propValues: ["shipping", "pickup", "delivery", "all", "done", "incomplete"],
  },
];

export const ButtonContext = createContext(null);

function Button(_props) {
  const defaults = useContext(ButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RadioGroupButtonRoot
      {...props}
      key="segmented-control-button-root"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "segmented-control-button-root",
        scope: "KkXddrHQ9h",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_TSpeCx7u1s"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_KkXddrHQ9h_TSpeCx7u1s"
          }
          key={"TSpeCx7u1s"}
          addSpacing={false}
          element={getStyle("TSpeCx7u1s", "htmlElement")}
        >
          <ContainerPrimitive
            id={"iconWrapper_Nntzz1bQTS"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_KkXddrHQ9h_Nntzz1bQTS"
            }
            key={"Nntzz1bQTS"}
            addSpacing={false}
            element={getStyle("Nntzz1bQTS", "htmlElement")}
          >
            {
              <IconPrimitive
                id={"Icon_UdQPj7uj8B"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_KkXddrHQ9h_UdQPj7uj8B"
                }
                key={"UdQPj7uj8B"}
                useMask={getStyle("UdQPj7uj8B", "useMask")}
                src={getStyle("UdQPj7uj8B", "icon")}
              />
            }
          </ContainerPrimitive>
          <TextPrimitive
            id={"Time_segmented-control-button-text"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_KkXddrHQ9h_segmented-control-button-text"
            }
            key={"segmented-control-button-text"}
            text={
              exists(props.text)
                ? props.text
                : getStyle("segmented-control-button-text", "text")
            }
            element={getStyle("segmented-control-button-text", "htmlElement")}
          />
        </ContainerPrimitive>
      )}
    </RadioGroupButtonRoot>
  );
}

Button.__variants = [
  {
    name: "selected",
    type: "variant",
  },
  {
    name: "size",
    type: "group",
    variants: ["large850", "med450", "small"],
  },
  {
    name: "type",
    type: "group",
    variants: ["shipping", "pickup", "delivery", "all", "done", "incomplete"],
  },
];

export default Button;
