// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Compact.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";
import {
  default as IconButton,
  IconButtonContext,
} from "../Primitives/IconButton";

const styles = [
  {
    type: "default",
    layers: {
      pFLcUZs6hn: {
        none: {
          text: "Sign In",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large850",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      Qc9FqyMxcV: {
        none: {
          disabled: false,
          icon: icons.close,
          large: false,
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large850",
        layers: {
          Qc9FqyMxcV: {},
        },
      },
      {
        propValue: "med",
        layers: {
          Qc9FqyMxcV: {},
        },
      },
      {
        propValue: "xsmall",
        layers: {
          Qc9FqyMxcV: {},
        },
      },
      {
        propValue: "xlarge",
        layers: {
          Qc9FqyMxcV: {},
        },
      },
      {
        propValue: "small",
        layers: {
          Qc9FqyMxcV: {},
        },
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["large850", "med", "xsmall", "xlarge", "small"],
  },
];

export const CompactContext = createContext(null);

function Compact(_props) {
  const defaults = useContext(CompactContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="JzFdEzyiEv"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "JzFdEzyiEv",
        scope: "G2hpWkBBkq",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"ModalContainer_SgMmdPRioy"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_G2hpWkBBkq_SgMmdPRioy"
          }
          key={"SgMmdPRioy"}
          addSpacing={false}
          element={getStyle("SgMmdPRioy", "htmlElement")}
        >
          <ContainerPrimitive
            id={"Container_NBtCGuNje7"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_G2hpWkBBkq_NBtCGuNje7"
            }
            key={"NBtCGuNje7"}
            addSpacing={false}
            element={getStyle("NBtCGuNje7", "htmlElement")}
          >
            <ContainerPrimitive
              id={"Container_Qx7Ze9DJEC"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_G2hpWkBBkq_Qx7Ze9DJEC"
              }
              key={"Qx7Ze9DJEC"}
              addSpacing={false}
              element={getStyle("Qx7Ze9DJEC", "htmlElement")}
            >
              {
                <TextPrimitive
                  id={"Title_pFLcUZs6hn"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_G2hpWkBBkq_pFLcUZs6hn"
                  }
                  key={"pFLcUZs6hn"}
                  text={
                    exists(props.Title)
                      ? props.Title
                      : getStyle("pFLcUZs6hn", "text")
                  }
                  element={getStyle("pFLcUZs6hn", "htmlElement")}
                />
              }
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"Icon_QYDxuBkohW"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_G2hpWkBBkq_QYDxuBkohW"
              }
              key={"QYDxuBkohW"}
              addSpacing={false}
              element={getStyle("QYDxuBkohW", "htmlElement")}
            >
              {props.IconButton === undefined ? (
                <IconButton
                  key={"Qc9FqyMxcV"}
                  {...getCompositeDefaultProps("Qc9FqyMxcV")}
                  id="IconButton_Qc9FqyMxcV"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_G2hpWkBBkq_Qc9FqyMxcV"
                />
              ) : (
                <IconButtonContext.Provider
                  key="Qc9FqyMxcV-provider"
                  value={{
                    key: "Qc9FqyMxcV",
                    id: "IconButton_Qc9FqyMxcV",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_G2hpWkBBkq_Qc9FqyMxcV",
                    ...getCompositeDefaultProps("Qc9FqyMxcV"),
                  }}
                >
                  {props.IconButton}
                </IconButtonContext.Provider>
              )}
            </ContainerPrimitive>
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"Container_AeMDF6nCyA"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_G2hpWkBBkq_AeMDF6nCyA"
            }
            key={"AeMDF6nCyA"}
            addSpacing={false}
            element={getStyle("AeMDF6nCyA", "htmlElement")}
          >
            {
              <ContainerPrimitive
                key={"6HnfG62ZnE"}
                id={"Slot_6HnfG62ZnE"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_G2hpWkBBkq_6HnfG62ZnE"
                }
                addSpacing={false}
              >
                {props.content}
              </ContainerPrimitive>
            }
          </ContainerPrimitive>
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

Compact.IconButton = IconButton;
Compact.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["large850", "med", "xsmall", "xlarge", "small"],
  },
];

export default Compact;
