// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./TimeWindowCard.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";
import { default as Field, FieldContext } from "../Events/Field";

const styles = [
  {
    type: "default",
    layers: {
      "87sAAGFmFW": {
        none: {
          text: "mon",
        },
      },
      Xkg2ACY7j8: {
        none: {
          text: "00",
        },
      },
      Y2bb41EJ94: {
        none: {
          text: "Mon, 5:00pm - 8:00pm",
        },
      },
      "8e8rD1hNQD": {
        none: {
          icon: icons.edit,
          useMask: true,
        },
      },
      "39p8pKwSGC": {
        none: {
          text: "Every ",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "hideEditButton",
    layers: {},
  },
  {
    type: "boolean",
    propName: "recurring",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      JYDMTdiNqy: {
        none: {
          small: true,
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "hideEditButton",
    layers: {
      JYDMTdiNqy: {},
    },
  },
  {
    type: "boolean",
    propName: "recurring",
    layers: {
      JYDMTdiNqy: {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "hideEditButton",
  },
  {
    type: "boolean",
    propName: "recurring",
  },
];

export const TimeWindowCardContext = createContext(null);

function TimeWindowCard(_props) {
  const defaults = useContext(TimeWindowCardContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="Q8xspeWVXU"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "Q8xspeWVXU",
        scope: "XJ3c8MSK8Y",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"date-time-location-container_HEytHifYxg"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_XJ3c8MSK8Y_HEytHifYxg"
            }
            key={"HEytHifYxg"}
            addSpacing={false}
            element={getStyle("HEytHifYxg", "htmlElement")}
          >
            <ContainerPrimitive
              id={"date-container_PNy8Rhk2WS"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_XJ3c8MSK8Y_PNy8Rhk2WS"
              }
              key={"PNy8Rhk2WS"}
              addSpacing={false}
              element={getStyle("PNy8Rhk2WS", "htmlElement")}
            >
              <TextPrimitive
                id={"month_87sAAGFmFW"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_XJ3c8MSK8Y_87sAAGFmFW"
                }
                key={"87sAAGFmFW"}
                text={
                  exists(props.month)
                    ? props.month
                    : getStyle("87sAAGFmFW", "text")
                }
                element={getStyle("87sAAGFmFW", "htmlElement")}
              />
              <TextPrimitive
                id={"date_Xkg2ACY7j8"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_XJ3c8MSK8Y_Xkg2ACY7j8"
                }
                key={"Xkg2ACY7j8"}
                text={
                  exists(props.date)
                    ? props.date
                    : getStyle("Xkg2ACY7j8", "text")
                }
                element={getStyle("Xkg2ACY7j8", "htmlElement")}
              />
            </ContainerPrimitive>
            <SpacerPrimitive
              id={"Spacer_Tt1b3KNiQT"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_XJ3c8MSK8Y_Tt1b3KNiQT"
              }
              key={"Tt1b3KNiQT"}
            />
            <ContainerPrimitive
              id={"time-location-container_ATsFrMprRv"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_XJ3c8MSK8Y_ATsFrMprRv"
              }
              key={"ATsFrMprRv"}
              addSpacing={false}
              element={getStyle("ATsFrMprRv", "htmlElement")}
            >
              {[
                <ContainerPrimitive
                  id={"Container_UxgpsZ5dRh"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_XJ3c8MSK8Y_UxgpsZ5dRh"
                  }
                  key={"UxgpsZ5dRh"}
                  addSpacing={false}
                  element={getStyle("UxgpsZ5dRh", "htmlElement")}
                >
                  <TextPrimitive
                    id={"Every_39p8pKwSGC"}
                    className={
                      "__visly_reset_7X7HTLRqyD __visly_scope_XJ3c8MSK8Y_39p8pKwSGC"
                    }
                    key={"39p8pKwSGC"}
                    text={getStyle("39p8pKwSGC", "text")}
                    element={getStyle("39p8pKwSGC", "htmlElement")}
                  />
                  <SpacerPrimitive
                    id={"Spacer_Q1gMstD5YQ"}
                    className={
                      "__visly_reset_7X7HTLRqyD __visly_scope_XJ3c8MSK8Y_Q1gMstD5YQ"
                    }
                    key={"Q1gMstD5YQ"}
                  />
                  <TextPrimitive
                    id={"timeWindowRange_Y2bb41EJ94"}
                    className={
                      "__visly_reset_7X7HTLRqyD __visly_scope_XJ3c8MSK8Y_Y2bb41EJ94"
                    }
                    key={"Y2bb41EJ94"}
                    text={
                      exists(props.timeWindowRange)
                        ? props.timeWindowRange
                        : getStyle("Y2bb41EJ94", "text")
                    }
                    element={getStyle("Y2bb41EJ94", "htmlElement")}
                  />
                </ContainerPrimitive>,
                <SpacerPrimitive
                  id={"Spacer_RFGseU8pQt"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_XJ3c8MSK8Y_RFGseU8pQt"
                  }
                  key={"RFGseU8pQt"}
                />,
                props.Field === undefined ? (
                  <Field
                    key={"JYDMTdiNqy"}
                    {...getCompositeDefaultProps("JYDMTdiNqy")}
                    id="Field_JYDMTdiNqy"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_XJ3c8MSK8Y_JYDMTdiNqy"
                  />
                ) : (
                  <FieldContext.Provider
                    key="JYDMTdiNqy-provider"
                    value={{
                      key: "JYDMTdiNqy",
                      id: "Field_JYDMTdiNqy",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_XJ3c8MSK8Y_JYDMTdiNqy",
                      ...getCompositeDefaultProps("JYDMTdiNqy"),
                    }}
                  >
                    {props.Field}
                  </FieldContext.Provider>
                ),
              ]}
            </ContainerPrimitive>
            <SpacerPrimitive
              id={"Spacer_QNY17Yc8tE"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_XJ3c8MSK8Y_QNY17Yc8tE"
              }
              key={"QNY17Yc8tE"}
            />
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"edit-container_T7tk7sgj9J"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_XJ3c8MSK8Y_T7tk7sgj9J"
            }
            key={"T7tk7sgj9J"}
            addSpacing={false}
            element={getStyle("T7tk7sgj9J", "htmlElement")}
          >
            {
              <IconPrimitive
                id={"Icon_8e8rD1hNQD"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_XJ3c8MSK8Y_8e8rD1hNQD"
                }
                key={"8e8rD1hNQD"}
                useMask={getStyle("8e8rD1hNQD", "useMask")}
                src={
                  exists(props.icon)
                    ? props.icon
                    : getStyle("8e8rD1hNQD", "icon")
                }
              />
            }
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

TimeWindowCard.Field = Field;
TimeWindowCard.__variants = [
  {
    name: "hideEditButton",
    type: "variant",
  },
  {
    name: "recurring",
    type: "variant",
  },
];

export default TimeWindowCard;
