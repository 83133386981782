// !wtf fix this

function fetchRetry(url, fetchOptions = {}, attempt = 0, maxAttempts = 5, maxBackOff = 15000) {
  // https://aws.amazon.com/blogs/architecture/exponential-backoff-and-jitter/
  const sleep = Math.random() * 2 + Math.min(maxBackOff, Math.pow(2, attempt) * 1000) + 1;
  return fetch(url, fetchOptions).then((res) => {
    if (res.status >= 500 && res.status < 600) {
      if (attempt < maxAttempts) {
        return new Promise((resolve) => {
          setTimeout(resolve, sleep);
        }).then(() => {
          return fetchRetry(url, fetchOptions, attempt + 1);
        });
      } else {
        return Promise.reject(res);
      }
    } else if (res.status == 400) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

export default fetchRetry;
