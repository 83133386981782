import React from "react";
import { styled } from "../../stitches.config";
import * as RadixSelect from "@radix-ui/react-select";
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";

// Documentation: https://www.radix-ui.com/docs/primitives/components/select/1.0.0

// Separator, usually used to separate groups
const SelectSeparator = styled(RadixSelect.Separator, {
  height: 1,
  backgroundColor: "$accent6",
  margin: 5,
});

// #############################################################################
// ## Select Label ##
// semantically used to label options in a group, but can also be used inline
// #############################################################################

const SelectLabel = styled(RadixSelect.Label, {
  padding: "0 25px",
  fontSize: 12,
  lh: "$article",
  mb: "$md",
  color: "$gray11",
});

// #############################################################################
// ## Select Trigger ##
// #############################################################################

// ## Displays Text Value of select in the trigger
const SelectValue = RadixSelect.SelectValue;

const TriggerIcon = styled(RadixSelect.Icon, {
  color: "$accent11",
});

// Button that opens the Select
const SelectTriggerPrimitive = styled(RadixSelect.Trigger, {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: 6,
  padding: "0 15px",
  ff: "$avenir",
  fs: 14,
  fw: "$normal",
  lh: "$reset",
  height: 36,
  gap: 5,
  backgroundColor: "white",
  border: "1px solid $gray6",
  color: "$accent12",
  boxShadow: `0 2px 3px $colors$overlay7`,
  outline: "0px solid transparent",
  outlineOffset: "-1px",
  transition: "all 120ms ease-in-out",
  "&:hover": { backgroundColor: "$gray3" },
  "&:focus": { outline: "none" },
  "&:focus-visible": { outline: `2px solid $accent9` },
  "&[data-placeholder]": { color: "$accent9" },
});

const SelectTrigger = ({ placeholder, icon, value, valueText, ...props }) => {
  return (
    <SelectTriggerPrimitive {...props}>
      <SelectValue placeholder={placeholder} value={value}>
        {valueText}
        {/* Use when you want the value of the Select to be different from the value of the option */}
        {/* E.g. if you select from a list of countries, you may want to just display the flag instead of the country name by setting valueText to be flagObj[country] */}
      </SelectValue>
      <TriggerIcon>{icon || <ChevronDownIcon />}</TriggerIcon>
    </SelectTriggerPrimitive>
  );
};

// #############################################################################
// ## Select Content ##
// #############################################################################

// ## Portals content into body to avoid scroll/z-index issues
const ContentPortal = RadixSelect.Portal;

// Buttons that appear when list is longer than viewport
const scrollButtonStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 25,
  backgroundColor: "white",
  color: "$accent11",
  cursor: "default",
};
const ScrollUpButtonPrimitive = styled(RadixSelect.ScrollUpButton, scrollButtonStyles);
const ScrollDownButtonPrimitive = styled(RadixSelect.ScrollDownButton, scrollButtonStyles);

const ScrollUpButton = ({ ...props }) => {
  return (
    <ScrollUpButtonPrimitive {...props}>
      <ChevronUpIcon />
    </ScrollUpButtonPrimitive>
  );
};

const ScrollDownButton = ({ ...props }) => {
  return (
    <ScrollDownButtonPrimitive {...props}>
      <ChevronDownIcon />
    </ScrollDownButtonPrimitive>
  );
};

// Viewport that holds the options
const ContentViewport = styled(RadixSelect.Viewport, {
  padding: 5,
});

// Content Container that holds the viewport, scroll up button, and scroll down button
const SelectContentPrimitive = styled(RadixSelect.Content, {
  overflow: "hidden",
  backgroundColor: "white",
  borderRadius: 6,
  zIndex: "$tooltip",
  boxShadow:
    "0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)",
});

const SelectContent = ({
  children,
  portalParentContainer,
  onCloseAutoFocus,
  onEscapeKeyDown,
  onPointerDownOutside,
  ...props
}) => {
  return (
    <ContentPortal container={portalParentContainer}>
      <SelectContentPrimitive
        onCloseAutoFocus={onCloseAutoFocus}
        onEscapeKeyDown={onEscapeKeyDown}
        onPointerDownOutside={onPointerDownOutside}
        {...props}
      >
        <ScrollUpButton />
        <ContentViewport>{children}</ContentViewport>
        <ScrollDownButton />
      </SelectContentPrimitive>
    </ContentPortal>
  );
};

// #############################################################################
// ## Select Option ##
// #############################################################################

// ## The text value that will appear in the Trigger when the option is selected
// ## should be unstyled to avoid positioning issues
const OptionText = RadixSelect.ItemText;

// The Option itself
const OptionPrimitive = styled(RadixSelect.Item, {
  ff: "$avenir",
  fs: 14,
  fw: "$normal",
  color: "$accent11",
  lh: "$reset",
  borderRadius: "$xs",
  display: "flex",
  alignItems: "center",
  height: 36,
  padding: "0 35px 0 25px",
  position: "relative",
  cursor: "pointer",
  userSelect: "none",
  "&:focus": { outline: "none" },
  "&:focus-visible": { outline: `none` },
  "&[data-disabled]": {
    color: "$gray8",
    cursor: "default",
    pointerEvents: "none",
  },

  "&[data-highlighted]": {
    backgroundColor: "$accent3",
    color: "$accent11",
  },
});

// Indicator to show the option is selected
const OptionIndicator = styled(RadixSelect.ItemIndicator, {
  position: "absolute",
  left: 0,
  width: 25,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
});

const OptionHint = styled("div", {
  position: "absolute",
  right: 0,
  width: 25,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
});

const SelectOption = ({ value, textValue, disabled, children, hint, ...props }) => {
  return (
    <OptionPrimitive value={value} disabled={disabled} textValue={textValue} {...props}>
      <OptionText>{children}</OptionText>
      {hint && <OptionHint>{hint}</OptionHint>}
      <OptionIndicator>
        <CheckIcon />
      </OptionIndicator>
    </OptionPrimitive>
  );
};

// #############################################################################
// ## Select Group ##
// #############################################################################

// ## Groups options together for accessibility
const SelectGroupPrimitive = RadixSelect.Group;

const SelectGroup = ({ separator, label, children, ...props }) => {
  return (
    <SelectGroupPrimitive {...props}>
      {separator.inclues("top") && <SelectSeparator />}
      {label && <SelectLabel>{label}</SelectLabel>}
      {children}
      {separator.inclues("bottom") && <SelectSeparator />}
    </SelectGroupPrimitive>
  );
};

// #############################################################################
// ## Select Wrapper ##
// #############################################################################
const SelectRoot = RadixSelect.Root;

// #############################################################################
// ## Select ##
// #############################################################################

export const Select = ({
  placeholder,
  children,
  name,
  open,
  onOpenChange,
  triggerCss,
  portalParentContainer,
  ...props
}) => {
  return (
    <SelectRoot name={name} open={open} onOpenChange={onOpenChange} {...props}>
      <SelectTrigger placeholder={placeholder} css={triggerCss} />
      <SelectContent portalParentContainer={portalParentContainer}>{children}</SelectContent>
    </SelectRoot>
  );
};

Select.Option = SelectOption;
Select.Group = SelectGroup;
Select.Separator = SelectSeparator;
Select.Label = SelectLabel;
// if you want to construct this component yourself to pass styles/props to parts specifically, you can use these parts individually
Select.Root = SelectRoot;
Select.Content = SelectContent;
Select.Trigger = SelectTrigger;
