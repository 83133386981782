// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Input2.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { TextPrimitive, ContainerPrimitive } from "../_internal_primitives";
import { Root, TextFieldPrimitive } from "../_internal_text_field";

const styles = [
  {
    type: "default",
    layers: {
      input: {
        none: {
          "placeholder-text": "Placeholder",
        },
      },
      "5g7TB5hmXU": {
        none: {
          text: "Label",
        },
      },
      RpvVtLAE7b: {
        none: {
          text: "Text",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "alignRight",
    layers: {
      input: {
        none: {
          "placeholder-text": "0.00",
        },
      },
      RpvVtLAE7b: {
        none: {
          text: "$",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "small",
    layers: {},
  },
  {
    type: "boolean",
    propName: "handleOnKeyDown",
    layers: {},
  },
  {
    type: "boolean",
    propName: "search",
    layers: {},
  },
  {
    type: "enum",
    propName: "editMenu",
    values: [
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "medium",
        layers: {},
      },
      {
        propValue: "alignRight",
        layers: {
          input: {
            none: {
              "placeholder-text": "0.00",
            },
          },
          RpvVtLAE7b: {
            none: {
              text: "$",
            },
          },
        },
      },
      {
        propValue: "large",
        layers: {},
      },
    ],
  },
  {
    type: "boolean",
    propName: "invisible",
    layers: {},
  },
  {
    type: "boolean",
    propName: "required",
    layers: {},
  },
  {
    type: "boolean",
    propName: "large",
    layers: {},
  },
  {
    type: "enum",
    propName: "tip",
    values: [
      {
        propValue: "small",
        layers: {
          input: {
            none: {
              "placeholder-text": "other",
            },
          },
        },
      },
      {
        propValue: "med450",
        layers: {},
      },
      {
        propValue: "large850",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "alignRight",
    layers: {},
  },
  {
    type: "boolean",
    propName: "small",
    layers: {},
  },
  {
    type: "boolean",
    propName: "handleOnKeyDown",
    layers: {},
  },
  {
    type: "boolean",
    propName: "search",
    layers: {},
  },
  {
    type: "enum",
    propName: "editMenu",
    values: [
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "medium",
        layers: {},
      },
      {
        propValue: "alignRight",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
    ],
  },
  {
    type: "boolean",
    propName: "invisible",
    layers: {},
  },
  {
    type: "boolean",
    propName: "required",
    layers: {},
  },
  {
    type: "boolean",
    propName: "large",
    layers: {},
  },
  {
    type: "enum",
    propName: "tip",
    values: [
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med450",
        layers: {},
      },
      {
        propValue: "large850",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "alignRight",
  },
  {
    type: "boolean",
    propName: "small",
  },
  {
    type: "boolean",
    propName: "handleOnKeyDown",
  },
  {
    type: "boolean",
    propName: "search",
  },
  {
    type: "enum",
    propName: "editMenu",
    propValues: ["small", "medium", "alignRight", "large"],
  },
  {
    type: "boolean",
    propName: "invisible",
  },
  {
    type: "boolean",
    propName: "required",
  },
  {
    type: "boolean",
    propName: "large",
  },
  {
    type: "enum",
    propName: "tip",
    propValues: ["small", "med450", "large850"],
  },
];

export const Input2Context = createContext(null);

function Input2(_props) {
  const defaults = useContext(Input2Context);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <Root
      {...props}
      key="root"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "root",
        scope: "AzHv2NByua",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Container_AvHLb62B2C"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_AzHv2NByua_AvHLb62B2C"
            }
            key={"AvHLb62B2C"}
            addSpacing={false}
            element={getStyle("AvHLb62B2C", "htmlElement")}
          >
            {
              <TextPrimitive
                id={"rightText_RpvVtLAE7b"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_AzHv2NByua_RpvVtLAE7b"
                }
                key={"RpvVtLAE7b"}
                text={
                  exists(props.rightText)
                    ? props.rightText
                    : getStyle("RpvVtLAE7b", "text")
                }
                element={getStyle("RpvVtLAE7b", "htmlElement")}
              />
            }
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"Container_N2g1KqYu1r"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_AzHv2NByua_N2g1KqYu1r"
            }
            key={"N2g1KqYu1r"}
            addSpacing={false}
            element={getStyle("N2g1KqYu1r", "htmlElement")}
          >
            <ContainerPrimitive
              id={"Container_Rxh7LBquzR"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_AzHv2NByua_Rxh7LBquzR"
              }
              key={"Rxh7LBquzR"}
              addSpacing={false}
              element={getStyle("Rxh7LBquzR", "htmlElement")}
            >
              {
                <TextPrimitive
                  id={"Label_5g7TB5hmXU"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_AzHv2NByua_5g7TB5hmXU"
                  }
                  key={"5g7TB5hmXU"}
                  text={
                    exists(props.label)
                      ? props.label
                      : getStyle("5g7TB5hmXU", "text")
                  }
                  element={getStyle("5g7TB5hmXU", "htmlElement")}
                />
              }
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"Container_5kzWNAcw2H"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_AzHv2NByua_5kzWNAcw2H"
              }
              key={"5kzWNAcw2H"}
              addSpacing={false}
              element={getStyle("5kzWNAcw2H", "htmlElement")}
            >
              {
                <TextFieldPrimitive
                  id={"input_input"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_AzHv2NByua_input"
                  }
                  key={"input"}
                  placeholder={
                    exists(props.placeholder)
                      ? props.placeholder
                      : getStyle("input", "placeholder-text")
                  }
                />
              }
            </ContainerPrimitive>
          </ContainerPrimitive>
        </>
      )}
    </Root>
  );
}

Input2.__variants = [
  {
    name: "alignRight",
    type: "variant",
  },
  {
    name: "small",
    type: "variant",
  },
  {
    name: "handleOnKeyDown",
    type: "variant",
  },
  {
    name: "search",
    type: "variant",
  },
  {
    name: "editMenu",
    type: "group",
    variants: ["small", "medium", "alignRight", "large"],
  },
  {
    name: "invisible",
    type: "variant",
  },
  {
    name: "required",
    type: "variant",
  },
  {
    name: "large",
    type: "variant",
  },
  {
    name: "tip",
    type: "group",
    variants: ["small", "med450", "large850"],
  },
];

export default Input2;
