// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Button.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import { ButtonRoot } from "../_internal_button";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      HxPKbCp77g: {
        none: {
          text: "Button",
          htmlElement: "span",
        },
      },
      MvKJxShAHh: {
        none: {
          icon: icons.plus,
          useMask: true,
        },
        disabled: {
          useMask: true,
        },
      },
      "37NNhhHQcu": {
        none: {
          text: "Text",
          htmlElement: "span",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "rounded",
    layers: {},
  },
  {
    type: "enum",
    propName: "withIcon",
    values: [
      {
        propValue: "right",
        layers: {},
      },
      {
        propValue: "left",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
    ],
  },
  {
    type: "boolean",
    propName: "withText",
    layers: {
      "37NNhhHQcu": {
        none: {
          text: "$5.00",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "loading",
    layers: {},
  },
  {
    type: "boolean",
    propName: "large",
    layers: {},
  },
  {
    type: "enum",
    propName: "kind",
    values: [
      {
        propValue: "primary",
        layers: {
          MvKJxShAHh: {
            none: {
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "secondary",
        layers: {
          MvKJxShAHh: {
            none: {
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "warning",
        layers: {
          HxPKbCp77g: {
            none: {
              text: "Delete",
            },
          },
        },
      },
      {
        propValue: "quiet",
        layers: {},
      },
      {
        propValue: "good",
        layers: {
          HxPKbCp77g: {
            none: {
              text: "Publish",
            },
          },
          MvKJxShAHh: {
            none: {
              useMask: true,
            },
          },
        },
      },
    ],
  },
  {
    type: "boolean",
    propName: "shadow",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "rounded",
    layers: {},
  },
  {
    type: "enum",
    propName: "withIcon",
    values: [
      {
        propValue: "right",
        layers: {},
      },
      {
        propValue: "left",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
    ],
  },
  {
    type: "boolean",
    propName: "withText",
    layers: {},
  },
  {
    type: "boolean",
    propName: "loading",
    layers: {},
  },
  {
    type: "boolean",
    propName: "large",
    layers: {},
  },
  {
    type: "enum",
    propName: "kind",
    values: [
      {
        propValue: "primary",
        layers: {},
      },
      {
        propValue: "secondary",
        layers: {},
      },
      {
        propValue: "warning",
        layers: {},
      },
      {
        propValue: "quiet",
        layers: {},
      },
      {
        propValue: "good",
        layers: {},
      },
    ],
  },
  {
    type: "boolean",
    propName: "shadow",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "rounded",
  },
  {
    type: "enum",
    propName: "withIcon",
    propValues: ["right", "left", "large"],
  },
  {
    type: "boolean",
    propName: "withText",
  },
  {
    type: "boolean",
    propName: "loading",
  },
  {
    type: "boolean",
    propName: "large",
  },
  {
    type: "enum",
    propName: "kind",
    propValues: ["primary", "secondary", "warning", "quiet", "good"],
  },
  {
    type: "boolean",
    propName: "shadow",
  },
];

export const ButtonContext = createContext(null);

function Button(_props) {
  const defaults = useContext(ButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <ButtonRoot
      {...props}
      key="root"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "root",
        scope: "57m21SveAo",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"maintext-container_EvDWUXHMg4"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_57m21SveAo_EvDWUXHMg4"
            }
            key={"EvDWUXHMg4"}
            addSpacing={false}
            element={getStyle("EvDWUXHMg4", "htmlElement")}
          >
            <TextPrimitive
              id={"text_HxPKbCp77g"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_57m21SveAo_HxPKbCp77g"
              }
              key={"HxPKbCp77g"}
              text={
                exists(props.text) ? props.text : getStyle("HxPKbCp77g", "text")
              }
              element={getStyle("HxPKbCp77g", "htmlElement")}
            />
            <SpacerPrimitive
              id={"Spacer_A5DZiLXTPM"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_57m21SveAo_A5DZiLXTPM"
              }
              key={"A5DZiLXTPM"}
            />
            <IconPrimitive
              id={"Icon_MvKJxShAHh"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_57m21SveAo_MvKJxShAHh"
              }
              key={"MvKJxShAHh"}
              useMask={getStyle("MvKJxShAHh", "useMask")}
              src={
                exists(props.icon) ? props.icon : getStyle("MvKJxShAHh", "icon")
              }
            />
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"sidetext-container_DMaqPkbrS8"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_57m21SveAo_DMaqPkbrS8"
            }
            key={"DMaqPkbrS8"}
            addSpacing={false}
            element={getStyle("DMaqPkbrS8", "htmlElement")}
          >
            <SpacerPrimitive
              id={"Spacer_SBFQw2sBVB"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_57m21SveAo_SBFQw2sBVB"
              }
              key={"SBFQw2sBVB"}
            />
            <TextPrimitive
              id={"rightText_37NNhhHQcu"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_57m21SveAo_37NNhhHQcu"
              }
              key={"37NNhhHQcu"}
              text={
                exists(props.rightText)
                  ? props.rightText
                  : getStyle("37NNhhHQcu", "text")
              }
              element={getStyle("37NNhhHQcu", "htmlElement")}
            />
          </ContainerPrimitive>
          <ContainerPrimitive
            key={"67PrZ9FdmA"}
            id={"Slot_67PrZ9FdmA"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_57m21SveAo_67PrZ9FdmA"
            }
            addSpacing={false}
          >
            {props.loadingAnimation}
          </ContainerPrimitive>
        </>
      )}
    </ButtonRoot>
  );
}

Button.__variants = [
  {
    name: "rounded",
    type: "variant",
  },
  {
    name: "withIcon",
    type: "group",
    variants: ["right", "left", "large"],
  },
  {
    name: "withText",
    type: "variant",
  },
  {
    name: "loading",
    type: "variant",
  },
  {
    name: "large",
    type: "variant",
  },
  {
    name: "kind",
    type: "group",
    variants: ["primary", "secondary", "warning", "quiet", "good"],
  },
  {
    name: "shadow",
    type: "variant",
  },
];

export default Button;
