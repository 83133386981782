import React from "react";
import { styled } from "../../stitches.config";
import * as RadixToggleGroup from "@radix-ui/react-toggle-group";
import { CSS } from "@stitches/react";

const NavContainerPrimitive = styled(RadixToggleGroup.Root, {
  display: "flex",
  flexDir: "row",
  ai: "center",
  flexShrink: 0,
  backdropFilter: "blur(10px) saturate(50%)",
  boxShadow: "inset 0 0 2000px hsla(252, 100%, 99%, .9)", // $violet2 at 90% opacity
  overflow: "auto",
  // hide scrollbar
  "&::-webkit-scrollbar": { display: "none" },
});

const NavItemPrimitve = styled(RadixToggleGroup.Item, {
  all: "unset",
  backgroundColor: "transparent",
  textStyle: "text-1",
  h: "$xs_btn",
  lh: "$reset",
  ff: "$inter",
  color: "$gray10",

  display: "flex",
  flexShrink: 0,
  ai: "center",
  padx: "$xs",
  br: "$sm",
  mx: "$xxs",
  my: "$xxs",
  cursor: "pointer",
  transition: "color 100ms ease-in-out, background-color 100ms ease-in-out",
  truncateText: "30ch",
  "&:last-child": { mr: "$xs" },
  "&:hover": { backgroundColor: "$gray2" },
  "&[data-state=on]": { color: "$gray12", backgroundColor: "$white" },
  "&:focus-visible": { position: "relative", outline: `2px solid $violet9` },
  "@tablet": { h: "$sm_btn", textStyle: "text-2" },
});

const NavItem = ({
  value,
  disabled,
  children,
  css,
  ...props
}: {
  value: string | number;
  children: React.ReactNode;
  css?: CSS;
  disabled?: boolean;
}) => {
  return (
    <NavItemPrimitve value={value.toString()} disabled={disabled} css={css} {...props}>
      {children}
    </NavItemPrimitve>
  );
};

export const MenuNav = ({
  value,
  onValueChange,
  children,
  css,
  ...props
}: {
  value: string;
  onValueChange: (value: string) => void;
  children: React.ReactNode;
  css?: CSS;
}) => {
  return (
    <NavContainerPrimitive
      type="single"
      value={value.toString()}
      onValueChange={onValueChange}
      css={css}
      {...props}
    >
      {children}
    </NavContainerPrimitive>
  );
};

MenuNav.Item = NavItem;
