// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import React, { useRef, useLayoutEffect } from "react";
import {
  OverlayContainer,
  useOverlayTrigger,
  useOverlayPosition,
  useOverlay,
  useModal,
  useDialog,
  mergeProps,
  FocusScope,
} from "@visly/core";
export const DefaultPopoverPositions = {
  under: {
    placement: "bottom start",
    offset: 0,
    crossOffset: 0,
  },
};
export const PopoverInner = ({
  onClose,
  isOpen,
  innerRef,
  triggerProps,
  positionProps,
  children,
  autoFocusFirst = false,
  containFocus = false,
}) => {
  const { overlayProps } = useOverlay(
    {
      onClose,
      isOpen,
      isDismissable: true,
      shouldCloseOnBlur: false,
    },
    innerRef
  );
  useModal();
  const { dialogProps } = useDialog({}, innerRef);
  const combinedProps = mergeProps(
    overlayProps,
    dialogProps,
    positionProps,
    triggerProps
  );
  return (
    <FocusScope restoreFocus contain={containFocus} autoFocus={autoFocusFirst}>
      <div
        {...combinedProps}
        style={{ ...combinedProps.style, outline: "none" }}
        ref={innerRef}
      >
        {children}
      </div>
    </FocusScope>
  );
};
export function Popover(props) {
  const {
    triggerRef,
    placement = "top",
    offset = 0,
    crossOffset = 0,
    isOpen,
    onShouldClose,
    children,
    autoFocusFirst = false,
    containFocus = false,
    type = "dialog",
    scrollRef,
  } = props;
  const ref = useRef(null);
  const state = {
    isOpen,
    open: () => {},
    close: onShouldClose,
    toggle: () => {},
  };
  const overlayRef = useRef();
  const { overlayProps: triggerProps } = useOverlayTrigger(
    {
      type,
    },
    state,
    triggerRef
  );
  const { overlayProps: positionProps, updatePosition } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef,
    placement,
    offset,
    crossOffset,
    isOpen: state.isOpen,
    shouldFlip: true,
    scrollRef,
  });
  useLayoutEffect(() => {
    if (state.isOpen) {
      requestAnimationFrame(updatePosition);
    }
  }, [state.isOpen, updatePosition]);
  return (
    state.isOpen && (
      <OverlayContainer>
        <PopoverInner
          positionProps={positionProps}
          triggerProps={triggerProps}
          innerRef={overlayRef}
          vislyRef={ref}
          isOpen={state.isOpen}
          onClose={state.close}
          autoFocusFirst={autoFocusFirst}
          containFocus={containFocus}
        >
          {children}
        </PopoverInner>
      </OverlayContainer>
    )
  );
}
