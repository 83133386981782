/* eslint-disable react/prop-types */
import React from "react";
import { sizes } from "../visly";

function Spacer({ size }) {
  const padding = sizes.spacing["padding" + size];
  return <div style={{ display: "flex", height: padding, width: padding }}></div>;
}

export default Spacer;
