import React from "react";
import * as RadixAccordion from "@radix-ui/react-accordion";
import { styled, keyframes } from "../../stitches.config";
import { ChevronDownIcon } from "@radix-ui/react-icons";

interface AccordianProps extends React.ComponentProps<typeof RadixAccordion.Root> {
  type: "single" | "multiple";
  children: React.ReactNode;
  defaultValue?: string | undefined;
  collapsible?: boolean;
}

const AccordionRootPrimitive = styled(RadixAccordion.Root, {
  br: "$xs",
  width: "$full",
  backgroundColor: "$violet6",
});

const AccordionItemPrimitive = styled(RadixAccordion.Item, {
  overflow: "hidden",
  marginTop: 1,

  "&:first-child": {
    marginTop: 0,
  },

  "&:focus-within": {
    position: "relative",
    zIndex: "$dropdown",
  },
});

const ItemTrigger = React.forwardRef(({ children, ...props }, forwardedRef) => (
  <StyledHeader>
    <StyledTrigger {...props} ref={forwardedRef}>
      {children}
      <StyledChevron aria-hidden />
    </StyledTrigger>
  </StyledHeader>
));

const ItemContent = React.forwardRef(({ children, ...props }, forwardedRef) => (
  <StyledContent {...props} ref={forwardedRef}>
    <StyledContentText>{children}</StyledContentText>
  </StyledContent>
));

interface ItemProps extends React.ComponentProps<typeof AccordionItemPrimitive> {
  value: string;
  title: string;
  content: string;
}

const StyledHeader = styled(RadixAccordion.Header, {
  all: "unset",
  display: "flex",
});

const StyledTrigger = styled(RadixAccordion.Trigger, {
  all: "unset",
  fontFamily: "inherit",
  padding: "$sm 20px",
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: 15,
  lineHeight: "$text",
  color: "$violet11",
  backgroundColor: "$white",
  "&:hover": { backgroundColor: "$violet2" },
});

const StyledChevron = styled(ChevronDownIcon, {
  color: "$violet10",
  transition: "transform 300ms cubic-bezier(0.87, 0, 0.13, 1)",
  "[data-state=open] &": { transform: "rotate(180deg)" },
});

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: "var(--radix-accordion-content-height)" },
});

const slideUp = keyframes({
  from: { height: "var(--radix-accordion-content-height)" },
  to: { height: 0 },
});

const StyledContent = styled(RadixAccordion.Content, {
  overflow: "hidden",
  fontSize: 15,
  color: "$gray11",
  backgroundColor: "$violet2",

  '&[data-state="open"]': {
    animation: `${slideDown} 150ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 150ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
});

const StyledContentText = styled("div", {
  padding: "15px 20px",
  textStyle: "text-2",
  whiteSpace: "pre-line",
});

const Item = ({ value, title, content, ...props }: ItemProps) => {
  return (
    <AccordionItemPrimitive value={value} {...props}>
      <ItemTrigger>{title}</ItemTrigger>
      <ItemContent>{content}</ItemContent>
    </AccordionItemPrimitive>
  );
};

export const Accordion = ({
  type,
  children,
  defaultValue,
  collapsible,
  ...props
}: AccordianProps): JSX.Element => (
  <AccordionRootPrimitive
    type={type}
    defaultValue={defaultValue}
    collapsible={collapsible}
    {...props}
  >
    {children}
  </AccordionRootPrimitive>
);

Accordion.Item = Item;
