// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./ItemsCard.Total.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import { RootPrimitive, TextPrimitive } from "../../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      RGtf1nWUEY: {
        none: {
          text: "32",
        },
      },
      FzFeD7KggU: {
        none: {
          text: "total",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const TotalContext = createContext(null);

function Total(_props) {
  const defaults = useContext(TotalContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="QTkgXPTcKv"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "QTkgXPTcKv",
        scope: "6v8EFdCoy6",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <TextPrimitive
            id={"count_RGtf1nWUEY"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_6v8EFdCoy6_RGtf1nWUEY"
            }
            key={"RGtf1nWUEY"}
            text={
              exists(props.count) ? props.count : getStyle("RGtf1nWUEY", "text")
            }
            element={getStyle("RGtf1nWUEY", "htmlElement")}
          />
          <TextPrimitive
            id={"total_FzFeD7KggU"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_6v8EFdCoy6_FzFeD7KggU"
            }
            key={"FzFeD7KggU"}
            text={getStyle("FzFeD7KggU", "text")}
            element={getStyle("FzFeD7KggU", "htmlElement")}
          />
        </>
      )}
    </RootPrimitive>
  );
}

Total.__variants = [];

export default Total;
