// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./ConfigSection.SectionTitle.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      "7tUFEcEXtK": {
        none: {
          text: "Untitled Section",
        },
      },
      "3yohdCV44h": {
        none: {
          icon: icons.edit,
          useMask: true,
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const SectionTitleContext = createContext(null);

function SectionTitle(_props) {
  const defaults = useContext(SectionTitleContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="XG367wCAmj"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "XG367wCAmj",
        scope: "47VtDo718p",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <TextPrimitive
            id={"title_7tUFEcEXtK"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_47VtDo718p_7tUFEcEXtK"
            }
            key={"7tUFEcEXtK"}
            text={
              exists(props.title) ? props.title : getStyle("7tUFEcEXtK", "text")
            }
            element={getStyle("7tUFEcEXtK", "htmlElement")}
          />
          <SpacerPrimitive
            id={"Spacer_EZWow7TTP9"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_47VtDo718p_EZWow7TTP9"
            }
            key={"EZWow7TTP9"}
          />
          <IconPrimitive
            id={"Icon_3yohdCV44h"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_47VtDo718p_3yohdCV44h"
            }
            key={"3yohdCV44h"}
            useMask={getStyle("3yohdCV44h", "useMask")}
            src={getStyle("3yohdCV44h", "icon")}
          />
        </>
      )}
    </RootPrimitive>
  );
}

SectionTitle.__variants = [];

export default SectionTitle;
