// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./LabeledSwitch.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as Switch, SwitchContext } from "../Primitives/Switch";

const styles = [
  {
    type: "default",
    layers: {
      MuXKsxPYMV: {
        none: {
          text: "Text",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      "8nXc9xRDUc": {},
    },
  },
];

const variantPropTypes = [];

export const LabeledSwitchContext = createContext(null);

function LabeledSwitch(_props) {
  const defaults = useContext(LabeledSwitchContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="XohiRqhA8B"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "XohiRqhA8B",
        scope: "BKcyt3adfN",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => [
        props.Switch === undefined ? (
          <Switch
            key={"8nXc9xRDUc"}
            {...getCompositeDefaultProps("8nXc9xRDUc")}
            id="Switch_8nXc9xRDUc"
            className="__visly_reset_7X7HTLRqyD __visly_scope_BKcyt3adfN_8nXc9xRDUc"
          />
        ) : (
          <SwitchContext.Provider
            key="8nXc9xRDUc-provider"
            value={{
              key: "8nXc9xRDUc",
              id: "Switch_8nXc9xRDUc",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_BKcyt3adfN_8nXc9xRDUc",
              ...getCompositeDefaultProps("8nXc9xRDUc"),
            }}
          >
            {props.Switch}
          </SwitchContext.Provider>
        ),
        <SpacerPrimitive
          id={"Spacer_MFjxnn1Xxc"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_BKcyt3adfN_MFjxnn1Xxc"
          }
          key={"MFjxnn1Xxc"}
        />,
        <ContainerPrimitive
          id={"text-tooltip-wrap-container_BGmsvuZ3xo"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_BKcyt3adfN_BGmsvuZ3xo"
          }
          key={"BGmsvuZ3xo"}
          addSpacing={false}
          element={getStyle("BGmsvuZ3xo", "htmlElement")}
        >
          <TextPrimitive
            id={"title_MuXKsxPYMV"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_BKcyt3adfN_MuXKsxPYMV"
            }
            key={"MuXKsxPYMV"}
            text={
              exists(props.title) ? props.title : getStyle("MuXKsxPYMV", "text")
            }
            element={getStyle("MuXKsxPYMV", "htmlElement")}
          />
          <SpacerPrimitive
            id={"Spacer_MPfYNNp17i"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_BKcyt3adfN_MPfYNNp17i"
            }
            key={"MPfYNNp17i"}
          />
          <ContainerPrimitive
            key={"682CT9e6BA"}
            id={"Slot_682CT9e6BA"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_BKcyt3adfN_682CT9e6BA"
            }
            addSpacing={false}
          >
            {props.tooltip}
          </ContainerPrimitive>
        </ContainerPrimitive>,
      ]}
    </RootPrimitive>
  );
}

LabeledSwitch.Switch = Switch;
LabeledSwitch.__variants = [];

export default LabeledSwitch;
