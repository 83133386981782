/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import Geocode from "react-geocode";
import { getAddressString } from "@hotplate/utils-ts/helperFunctions";
import querystring from "query-string";
import { Input, InputDropdown } from "../visly/Primitives";
import { backendUrl } from "../hotplate-storefront/actions/types";
import Spacer from "./Spacer";

export default function AddressInput({
  initialAddress,
  setThisToUpdateAddressInput,
  setAddressDict,
  setLatLng,
  setTimeZone,
  setAddressUnit,
  addressDict,
}) {
  const [userTypingTime, setUserTypingTime] = useState(0);
  const [addressEditing, setAddressEditing] = useState(false);
  const [autocompletePredictions, setAutocompletePredictions] = useState([]);
  const [autocompleteProcessing, setAutocompleteProcessing] = useState(false);
  const [addressInput, setAddressInput] = useState("");
  const [addressDetailsProcessing, setAddressDetailsProcessing] = useState();

  useEffect(() => {
    if (initialAddress !== "" && typeof initialAddress === "string") {
      setAddressInput(initialAddress);
    }
  }, []);

  useEffect(() => {
    if (
      setThisToUpdateAddressInput &&
      typeof setThisToUpdateAddressInput === "string" &&
      setThisToUpdateAddressInput !== ""
    ) {
      setAddressInput(setThisToUpdateAddressInput);
    }
  }, [setThisToUpdateAddressInput]);

  async function getAddressPredictions() {
    if (addressInput === "") return;
    setAutocompleteProcessing(true);
    if (new Date().getTime() - userTypingTime >= 300 && addressInput !== "") {
      const endUrl =
        "https://maps.googleapis.com/maps/api/place/autocomplete/json?key=AIzaSyD9TdIZyIKeBdNDrn9ajcGDPGbcZMdApMs&type=address&input=" +
        addressInput;
      return fetch(backendUrl + "callPlacesApi", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: querystring.stringify({
          url: endUrl,
        }),
      })
        .then((response) =>
          response.json().then((json) => {
            setAutocompletePredictions(json.predictions);
            setAutocompleteProcessing(false);
          })
        )
        .catch((exception) => {
          setAutocompleteProcessing(false);
          console.log(exception);
        });
    }
  }

  async function getAddressDetails(placeId) {
    setAddressDetailsProcessing(true);
    const endUrl =
      "https://maps.googleapis.com/maps/api/place/details/json?key=AIzaSyD9TdIZyIKeBdNDrn9ajcGDPGbcZMdApMs&type=address&place_id=" +
      placeId;
    return fetch(backendUrl + "callPlacesApi", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: querystring.stringify({
        url: endUrl,
      }),
    })
      .then((response) =>
        response.json().then((json) => {
          processGetAddressDetailsResponse(json.result.address_components);
        })
      )
      .catch((exception) => {
        setAddressDetailsProcessing(false);
        setAddressEditing(false);
        console.log(exception);
      });
  }

  async function getLatLngFromAddress(address) {
    Geocode.setApiKey("AIzaSyABSMJXmVvS1fOPmYo0zwG7YehwxxNZFQA");
    Geocode.setLanguage("en");
    return Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        return { lat: lat, lng: lng };
      },
      (error) => {
        console.log("ERROR GETTING LAT & LNG", error);
        return { lat: null, lng: null };
      }
    );
  }

  async function processGetAddressDetailsResponse(payload) {
    // console.log("RESPONSE DATA: ", payload);
    const addressDict = {
      streetNumber: "",
      streetRoute: "",
      city: "",
      state: "",
      zip: "",
    };
    for (let i = 0; i < payload.length; i++) {
      const component = payload[i];
      for (let j = 0; j < component.types.length; j++) {
        if (component.types[j] === "street_number") addressDict.streetNumber = component.long_name;
        else if (component.types[j] === "route") addressDict.streetRoute = component.long_name;
        else if (component.types[j] === "locality" || component.types[j] === "sublocality")
          addressDict.city = component.long_name;
        else if (component.types[j] === "administrative_area_level_1")
          addressDict.state = component.long_name;
        else if (component.types[j] === "postal_code") addressDict.zip = component.long_name;
      }
    }

    const addressString = getAddressString({
      displayFullAddress: true,
      addressDict: addressDict,
    });
    if (typeof setLatLng === "function") {
      const addressLatLng = await getLatLngFromAddress(addressString);
      setLatLng(addressLatLng);
      if (typeof setTimeZone === "function") {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/timezone/json?language=en&location=${encodeURIComponent(
            `${addressLatLng.lat},${addressLatLng.lng}`
          )}&timestamp=${Math.round(
            Date.now() / 1000
          )}&key=${"AIzaSyABSMJXmVvS1fOPmYo0zwG7YehwxxNZFQA"}`
        );
        const data = await response.json();
        setTimeZone(data.timeZoneId);
      }
    }
    setAddressDict(addressDict);
    setAddressDetailsProcessing(false);
    setAddressEditing(false);
    setAddressInput(addressString);
  }

  function userTyping() {
    setUserTypingTime(new Date().getTime());
    setAddressEditing(true);
    setTimeout(() => getAddressPredictions(), 400); // TODO debounce and fix for async rendering
  }

  const showSuggestions = addressEditing && autocompletePredictions.length !== 0;
  return (
    <div style={{ display: "flex", flexFlow: "column", width: "100%" }}>
      <InputDropdown
        loadingAnimation={
          (autocompleteProcessing || addressDetailsProcessing) && (
            <ReactLoading type={"spin"} color={"#000000"} height={"20px"} width={"20px"} />
          )
        }
        value={addressInput}
        style={{ width: "100%", zIndex: "1" }}
        inputProps={{ autoComplete: "new-password" }}
        onChange={(value) => {
          userTyping();
          setAddressInput(value);
        }}
        label="Address"
        handleOnKeyDown={showSuggestions}
        placeholder="Street Address"
        onFocus={() => setAddressEditing(true)}
        Options={
          <InputDropdown.Options>
            {autocompletePredictions.slice(0, 4).map((item, index) => (
              <InputDropdown.Options.Option
                key={index}
                onClick={() => {
                  setAddressEditing(true);
                  getAddressDetails(item.place_id);
                }}
                text={item.description.substr(0, 50)}
              />
            ))}
          </InputDropdown.Options>
        }
      />
      <Spacer size="Large" />
      <Input
        label="Unit, apt, etc."
        placeholder="Apt, unit, suite, etc. (optional)"
        disabled={!addressDict}
        handleOnKeyDown={addressDict && addressDict.unit && addressDict.unit.length > 0}
        value={
          !addressDict || addressDict.constructor != Object || typeof addressDict.unit !== "string"
            ? ""
            : addressDict.unit
        }
        onChange={(val) => setAddressUnit(val)}
      />
    </div>
  );
}
