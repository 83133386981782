/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { Modal } from "../visly/Modals";
import OptionSelectModal from "./OptionSelectModal";
import { v4 as uuid } from "uuid";
import "./css/Modal.css";
import { useBreakpoint } from "../visly";
import { usePrevious } from "../hooks";

export default function ModalMaster({
  forceClose,
  closeModal,
  children,
  fixedContent,
  compact,
  noHeader,
  confirmation,
  showConfirmation,
  hideClose,
  optionSelectModalState,
  gray,
  title,
  style,
}) {
  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);

  const [isOpen, setIsOpen] = useState(false);
  const [removeTransformState, setRemoveTransform] = useState(false);

  const id = useRef(uuid());
  const scrollLockRef = useRef(null);

  // onModalOpen() {
  //   document.querySelector('.commonModalBackdrop').addEventListener('click', this.closeModal.bind(this));
  //   this.target = document.querySelector('.commonModalBackdrop');
  //   disableBodyScroll(this.target);
  // }

  // closeModal(event) {
  //   const {setGone, hideClose} = this.props;
  //   if (!(event.target.closest('#commonModalWrapper')) && !(event.target.closest('#ScreenshotWidget'))) {
  //     if (!hideClose) setGone(true);
  //   }
  // }

  // onModalGone() {
  //   if (!!this.target) enableBodyScroll(this.target);
  //   document.body.removeEventListener('click', this.closeModal);
  //   this.laggingChildren = null;
  // }

  useEffect(() => {
    const modalContainer = document.querySelector("#modalContainer" + id.current);
    if (modalContainer) {
      scrollLockRef.current = modalContainer.querySelector("div[id^=scrollbox]");
      if (scrollLockRef.current) disableBodyScroll(scrollLockRef.current);
    }
    setTimeout(() => {
      setIsOpen(true);
      setTimeout(() => setRemoveTransform(true), 450);
    }, 300);

    return () => {
      if (scrollLockRef.current) clearAllBodyScrollLocks();
    };
  }, []);

  const prevProps = usePrevious({ forceClose });
  useEffect(() => {
    if (!prevProps) {
      return;
    }
    if (!prevProps.forceClose && forceClose) {
      unmount();
    }
  });

  function unmount() {
    if (scrollLockRef.current) clearAllBodyScrollLocks();
    setIsOpen(false);
    setTimeout(closeModal, 600);
  }

  const removeTransform = isOpen && removeTransformState;
  const h = Math.min(window.innerHeight, window.screen.height);

  return (
    <div
      id={"modalContainer" + id.current}
      className={optionSelectModalState ? "modal-master showOptionSelect" : "modal-master"}
      style={{
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        height: h,
        zIndex: 999,
      }}
    >
      <Modal
        className={(compact ? "compactModal" : "") + (removeTransform ? " removeTransform" : "")}
        compact={compact}
        title={title}
        style={style}
        confirmation={confirmation}
        showConfirmation={showConfirmation}
        noHeader={noHeader}
        size={size}
        optionSelect={
          optionSelectModalState && (
            <OptionSelectModal
              absolute={true}
              message={optionSelectModalState.message}
              closeModal={optionSelectModalState.closeOptionSelectModal}
              optionSelectButtons={optionSelectModalState.optionSelectButtons}
            />
          )
        }
        gray={gray}
        isOpen={isOpen} // reveals modal
        fixedContent={fixedContent && fixedContent}
        CloseButton={<Modal.CloseButton disabled={hideClose} onClick={unmount} />}
        hideClose={hideClose}
      >
        {children}
      </Modal>
    </div>
  );
}
