// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import React, {
  createContext,
  useContext,
  useRef,
  useState,
  useLayoutEffect,
  useMemo,
  useEffect,
} from "react";
import {
  ListLayout,
  mergeProps,
  HiddenSelect,
  useSelectState,
  useSelect,
  useButton,
  useListBox,
  useOption,
  useFocusRing,
  Virtualizer,
  VirtualizerItem,
  DismissButton,
  useCollator,
} from "@visly/core";
import { combineRef, exists, renderChildren } from "./_internal_utils";
import { usePrimitive } from "./_internal_usePrimitive";
import { Popover } from "./builtins/Popover";
import { ItemContext, renderCollection } from "./_internal_collection";
import { useFormLabel } from "./_internal_formlabel";
import { injectSpacing } from "./_internal_component_utils";
export const SelectContext = createContext({
  triggerProps: {},
  triggerRef: null,
  buttonStyles: {},
  testId: "",
  vislyProps: {},
  menuProps: {},
  state: null,
  layout: null,
  renderInline: false,
  rootClassName: "",
  triggerMeasureRef: null,
});
export function SelectButton(props) {
  const {
    triggerRef,
    triggerMeasureRef,
    vislyProps,
    testId,
    buttonStyles,
    triggerProps,
  } = useContext(SelectContext);

  const _triggerProps = mergeProps(triggerProps, {
    onKeyDown: (e) => {
      if (e.key === "Escape") {
        e.continuePropagation();
      }
    },
  });

  const { buttonProps } = useButton(_triggerProps, triggerRef);
  return (
    <button
      {...mergeProps(buttonProps, vislyProps)}
      data-testid={testId}
      ref={combineRef(
        combineRef(props.measureRef, triggerMeasureRef),
        triggerRef
      )}
      className={props.className}
      style={buttonStyles}
    >
      {props.children}
    </button>
  );
}
export function SelectOptionContainer(props) {
  const { state, renderInline } = useContext(SelectContext);
  return state.isOpen || renderInline ? (
    <_SelectOptionContainer {...props} />
  ) : null;
}

function useListBoxLayout(state, minimumItemHeight) {
  const collator = useCollator({
    usage: "search",
    sensitivity: "base",
  });
  const layout = useMemo(
    () =>
      new ListLayout({
        estimatedRowHeight: exists(minimumItemHeight) ? minimumItemHeight : 20,
        padding: 0,
        collator,
      }),
    [minimumItemHeight, collator]
  );
  layout.collection = state.collection;
  layout.disabledKeys = state.disabledKeys;
  return layout;
}

function _SelectOptionContainer(props) {
  const {
    menuProps,
    state,
    layout,
    renderInline,
    triggerRef,
    rootClassName,
  } = useContext(SelectContext);
  const listboxRef = useRef(null);
  const { listBoxProps } = useListBox(
    {
      ...menuProps,
      autoFocus: true,
      disallowEmptySelection: true,
      "aria-label": exists(state.selectedKey) ? state.selectedKey : "none",
      id: menuProps.id,
      isVirtualized: true,
      keyboardDelegate: layout,
    },
    state,
    listboxRef
  );
  const [buttonWidth, setButtonWidth] = useState(null);
  useLayoutEffect(() => {
    if (exists(triggerRef)) {
      const width = triggerRef.current.offsetWidth;
      setButtonWidth(width);
    }
  }, [triggerRef, state.selectedKey]);

  const renderWrapper = (parent, reusableView) => {
    return (
      <VirtualizerItem
        key={reusableView.key}
        reusableView={reusableView}
        parent={parent}
        className="virtualizer-item"
      />
    );
  };

  if (renderInline) {
    const selectOptions = Array.isArray(props.children)
      ? props.children.find((p) => p.type === React.Fragment)
      : props.children;
    return (
      <ul
        ref={combineRef(listboxRef, props.measureRef)}
        className={props.className}
        style={
          props.useButtonWidth
            ? {
                width: buttonWidth,
              }
            : undefined
        }
      >
        <ItemContext.Provider
          value={{
            isSelected: false,
            isFocused: false,
            key: null,
          }}
        >
          {injectSpacing(props.addSpacing, selectOptions)}
        </ItemContext.Provider>
      </ul>
    );
  }

  const firstKey = state.collection.firstKey;
  return (
    <Popover
      scrollRef={listboxRef}
      triggerRef={triggerRef}
      isOpen={state.isOpen}
      containFocus
      onShouldClose={state.close}
      placement="bottom start"
    >
      <style
        style={{
          display: "none",
        }}
      >{`
                .virtualizer-item, .virtualizer-wrapper > * {
                    width: 100% !important;
                }
            `}</style>
      <DismissButton onDismiss={() => state.close()} />
      <div key={props.innerKey} className={rootClassName}>
        {exists(props.cssStyles) ? (
          <style
            style={{
              display: "none",
            }}
          >
            {props.cssStyles}
          </style>
        ) : null}

        <Virtualizer
          {...mergeProps(listBoxProps, menuProps)}
          ref={listboxRef}
          layout={layout}
          collection={state.collection}
          sizeToFit="height"
          scrollDirection="vertical"
          renderWrapper={renderWrapper}
          focusedKey={state.selectionManager.focusedKey}
          className={`${props.className} virtualizer-wrapper`}
          style={{
            overflow: "auto",
            ...(props.useButtonWidth
              ? {
                  width: buttonWidth,
                }
              : {}),
            padding: undefined,
          }}
        >
          {(_type, item) => {
            const showDivider = props.addSpacing && item.key !== firstKey;
            return (
              <>
                {showDivider && (
                  <div
                    className="__visly_spacing"
                    key={`__visly_spacing-${item.key}`}
                  />
                )}
                <Option key={item.key} item={item} />
              </>
            );
          }}
        </Virtualizer>
      </div>
      <DismissButton onDismiss={() => state.close()} />
    </Popover>
  );
}

function Option({ item }) {
  const { state } = useContext(SelectContext);
  const ref = useRef();
  const isDisabled = state.disabledKeys.has(item.key);
  const isSelected = state.selectionManager.isSelected(item.key);
  const { optionProps } = useOption(
    {
      key: item.key,
      isDisabled,
      isSelected,
      shouldSelectOnPressUp: true,
      shouldFocusOnHover: true,
      "aria-label": item.key,
      isVirtualized: true,
    },
    state,
    ref
  );
  const { focusProps, isFocusVisible } = useFocusRing();
  return (
    <li
      {...mergeProps(optionProps, focusProps)}
      ref={ref}
      style={{
        outline: "none",
      }}
    >
      <ItemContext.Provider
        value={{
          isSelected,
          isFocused: isFocusVisible,
          key: item.key,
        }}
      >
        {item.rendered}
      </ItemContext.Provider>
    </li>
  );
}

export function SelectRoot(props) {
  const ref = useRef(null);
  const {
    selectedKey,
    onSelectionChange,
    renderInline,
    children,
    minimumItemHeight,
    disabledKeys,
  } = props;
  const { style, testId, values, vislyProps, isDisabled } = usePrimitive({
    ref,
    props,
  });
  const { className, ...other } = vislyProps;
  const { label, registerLabelProps } = useFormLabel();
  const state = useSelectState({
    children: renderCollection(children),
    selectedKey,
    onSelectionChange,
    disallowEmptySelection: true,
    shouldFlip: true,
    label,
    isDisabled,
    disabledKeys,
  });
  const layout = useListBoxLayout(state, minimumItemHeight);
  const { triggerProps, menuProps, labelProps } = useSelect(
    {
      children: renderCollection(children),
      selectedKey,
      onSelectionChange,
      shouldFlip: true,
      keyboardDelegate: layout,
      label,
      disabledKeys,
      "aria-describedby": props["aria-describedby"],
      "aria-details": props["aria-details"],
      "aria-label": props["aria-label"],
      "aria-labelledby": props["aria-labelledby"],
    },
    state,
    ref
  );
  useEffect(() => {
    registerLabelProps(labelProps);
  }, []);
  triggerProps.isDisabled = isDisabled;
  return (
    <div
      className={className}
      style={{
        ...style,
        ...(renderInline
          ? {
              display: "flex",
              alignItems: "flex-start",
            }
          : {
              display: "contents",
            }),
      }}
    >
      <HiddenSelect
        state={state}
        triggerRef={ref}
        label={label || "Select"}
        name="Select"
      />
      <SelectContext.Provider
        value={{
          buttonStyles: style,
          renderInline,
          triggerProps,
          triggerRef: ref,
          triggerMeasureRef: props.measureRef,
          vislyProps: other,
          testId,
          state,
          layout,
          menuProps,
          rootClassName: className,
        }}
      >
        {renderChildren(props.vislyChildren, values)}
      </SelectContext.Provider>
    </div>
  );
}
export function SelectOptionRoot(props) {
  const ref = useRef();
  const { isSelected, isFocused } = useContext(ItemContext) || {};
  const { style, testId, innerRef, values, vislyProps } = usePrimitive({
    ignoreFocusHandling: true,
    isFocusVisible: isFocused,
    ref,
    props,
    variants: isSelected
      ? [
          {
            propName: "selected",
          },
        ]
      : [],
  });
  return (
    <div
      key={props.value}
      ref={combineRef(props.measureRef, combineRef(innerRef, ref))}
      data-testid={testId}
      {...vislyProps}
      style={style}
    >
      {renderChildren(props.children, values)}
    </div>
  );
}
