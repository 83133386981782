/* eslint-disable react/prop-types */
import React from "react";
import { TextArea } from "../visly/Primitives";

const SmartTextArea = ({ placeholder, text, maxLength, onChange, small, disabled, className }) => {
  return (
    <TextArea
      disabled={disabled}
      className={className}
      value={text}
      style
      small={small}
      onChange={(val) => onChange(val)}
      placeholder={placeholder}
      maxLength={maxLength}
      resize={small ? "none" : "vertical"}
      CharCount={<TextArea.CharCount current={text ? text.length : 0} max={maxLength} />}
    />
  );
};

SmartTextArea.defaultProps = {
  maxLength: "500",
  placeholder: "write here",
};

export default SmartTextArea;
