import {
  isEventOnDemandActive,
  getTimestampDayOfWeek,
  getSortedLocationsEvent,
  getTimestampDayOfWeekMonthDate,
  getAddressString,
  getSortedTimeWindows,
  getTimeWindowCardLocationTitle,
  getSortedEventTimeSlotOptions,
  getEventStatus,
  getCountdownTimeArray,
  getDetailedEventStatus,
  numberWithCommas,
  getReadableTimeWindow,
} from "@hotplate/utils-ts/helperFunctions";
import type {
  DropInfoEvent,
  TimeWindow,
  Location,
  EventStatus,
  DetailedEventStatus,
  ShopReduxState,
  FulfillmentType,
  ChefStyles,
} from "./types";
import {
  BellIcon,
  CalendarIcon,
  ChevronRightIcon,
  ClockIcon,
  ExternalLinkIcon,
  SewingPinFilledIcon,
} from "@radix-ui/react-icons";
import { CSS } from "@stitches/react";
import React, { useState, useEffect, useRef } from "react";
import { useToggle } from "../../hooks";
import { AspectRatioImg } from "../../hotplate-common/primitives/AspectRatioImg";
import { Button } from "../../hotplate-common/primitives/Button";
import { Box, Column, Flex, H1, P, Row, Span } from "../../hotplate-common/primitives/Containers";
import { Countdown } from "../components/EventCardCountdown";
import { ReminderDialog } from "./ReminderDialog";
import { useSelector } from "react-redux";
import { DropChat } from "../components/DropChat";
import { trackDropInfoExpanded } from "../analytics";
import { getChefStyles } from "../../hotplate-common/chefStyleFunctions";
import { Callout } from "../../hotplate-common/primitives/Callout";

const DropInfoHype = ({
  event,
  presenceCount: dropPresenceCount,
  timeUntilGoLive,
}: {
  event: DropInfoEvent;
  presenceCount: number;
  timeUntilGoLive: number;
}) => {
  const siteSettings = useSelector((state: ShopReduxState) => state.storefront.siteSettings);

  const eventStatus: EventStatus = getEventStatus(event);
  const detailedEventStatus: DetailedEventStatus = getDetailedEventStatus(event);

  const eventIsOpening = detailedEventStatus === "opening";
  const eventIsScheduledButNotOpening = detailedEventStatus === "scheduled";
  const eventIsLive = eventStatus === "live";
  const eventIsComplete = eventStatus === "complete";
  const eventIsScheduled = eventStatus === "scheduled";

  const countdownTimeArray = getCountdownTimeArray(timeUntilGoLive);

  const hypeCSS: CSS = eventIsOpening
    ? {
        cursor: "pointer",
        pad: "$md",
        br: "$lg",
        transition: "box-shadow 200ms cubic-bezier(0.4, 0.3, 0.8, 0.6) 0s",
        backgroundColor: "$white",
        "&:hover": {
          boxShadow:
            "inset 0 0 0 0 rgba(255,255,255,0), inset 0 0 0 4px $colors$white, inset 0 0 0 9999px $colors$gray3",
        },
        "&:active": {
          boxShadow:
            "inset 0 0 0 0 rgba(255,255,255,0), inset 0 0 0 4px $colors$white, inset 0 0 0 9999px $colors$gray3",
        },
      }
    : { mt: "$xs" };

  const presenceCSS: CSS = eventIsOpening
    ? {
        padx: "$xs",
        h: "$sm_btn",
        br: "$xs",
        textStyle: "text-2",
        ml: "auto",
      }
    : {
        w: "$full",
        h: "$md_btn",
      };

  const inner = (
    <Row css={{ flexWrap: "wrap", gap: "$xxs", ...hypeCSS }}>
      {eventIsScheduled && (
        <Countdown
          type={eventIsOpening ? "large" : "normal"}
          goLiveTime={event.goLiveTime}
          hideCountdown={event.hideOrdersOpenTime}
          days={countdownTimeArray[0]}
          hours={countdownTimeArray[1]}
          minutes={countdownTimeArray[2]}
          seconds={countdownTimeArray[3]}
          labeled={eventIsScheduledButNotOpening} // label only if the event is not opening
          chefStyles={{}} //!add these
          css={{ flexGrow: 1, jc: "center" }}
        />
      )}
      {/* Presence counter */}
      {eventIsLive && dropPresenceCount > 10 && !siteSettings.isPresenceOnDropHidden && (
        <Row
          css={{
            cursor: "default",
            ai: "center",
            jc: "center",
            backgroundColor: "$danger4",
            color: "$danger11",
            ff: "$inter",
            ...presenceCSS,
            fw: "$semi_bold",
          }}
        >
          {`🔥 ${numberWithCommas(dropPresenceCount || 0)} ${eventIsLive ? "others" : ""} here now`}
        </Row>
      )}

      {/* Chat Feed // ? keeping this here in case we decide to put chat in a modal later so I do not have to redo my work */}
      {/* each chat should be animated to odometer in */}
      {/* // TODO add actual chat feed & update onChat */}
      {/* {eventIsOpening && (
        <Row
          css={{
            w: "$full",
            mt: "$sm",
            jc: "space-between",
            ai: "center",
            ff: "$inter",
            textStyle: "text-2",
          }}
        >
          <P css={{ fw: "$bold", padr: "$sm", color: "$accent11" }}>Ben</P>
          <P css={{ lineClamp: 2, color: "$gray11", lh: "$text" }}>
            This is so sick!! lets goo baby I love ice cream I wanna GET SOME BOI CMON
          </P>
        </Row>
      )} */}
      {/* Chat Feed // ? keeping this here in case we decide to put chat in a modal later so I do not have to redo my work */}

      {(eventIsScheduledButNotOpening || eventIsComplete) && (
        <ReminderDialog
          title={eventIsComplete ? "Make sure you don't miss the next drop" : "You can't miss this"}
          description={
            eventIsComplete
              ? `Put in your phone number and we will text you a few minutes before ${siteSettings.restaurantName}'s next sale goes live`
              : "Put in your phone number and we will text you a few minutes before this sale goes live"
          }
          trackSource={eventIsComplete ? "drop_info_complete" : "drop_info_scheduled"}
          trigger={
            <Button
              as="div"
              variant="tinted"
              color={eventIsComplete ? "info" : "danger"}
              css={{ flexGrow: 1, m: "$xxs" }}
            >
              <BellIcon />
              {/* // TODO make this copy better */}
              {eventIsComplete ? `Don't miss the next one` : `Remind me`}
            </Button>
          }
          triggerCSS={{ width: "$full" }}
        />
      )}
    </Row>
  );

  return eventIsOpening && !siteSettings.isDropChatHidden ? (
    <DropChat channelId={event.id} event={event} timeUntilGoLive={timeUntilGoLive} />
  ) : (
    inner
  );
};

const DropInfoSubItem = ({
  icon,
  title,
  subtitle,
  sideContent,
  onClick,
  ...props
}: {
  icon: JSX.Element;
  title: string;
  subtitle: string;
  sideContent: string | JSX.Element;
  onClick?: () => void;
}) => {
  return (
    <Row
      css={{ ff: "$inter", pady: "$sm", padr: "$md", ai: "center" }}
      onClick={onClick}
      {...props}
    >
      <Flex
        css={{
          ai: "center",
          jc: "center",
          flexShrink: 0,
          size: "$xs_btn",
          br: "$md",
          backgroundColor: "$gray4",
          color: "gray11",
          mr: "$md",
          nudgeY: -3,
          "& svg": {
            size: 14,
          },
          "@tablet": {
            size: "$sm_btn",
            br: "$lg",
            "& svg": {
              size: 16,
            },
          },
        }}
      >
        {icon}
      </Flex>
      <Column css={{ padr: "$xs" }}>
        <Span css={{ textStyle: "text-2", fw: "$semi_bold", color: "$gray12", lh: "$reset" }}>
          {title}
        </Span>
        <Span
          css={{
            textStyle: "text-1",
            color: "$gray10",
            lh: "$article",
            whiteSpace: "nowrap",
            truncateText: "30ch",
          }}
        >
          {subtitle}
        </Span>
      </Column>
      <Span
        css={{
          textStyle: "text-1",
          color: "$gray10",
          lh: "$reset",
          ml: "auto",
          truncateText: true,
        }}
      >
        {sideContent}
      </Span>
    </Row>
  );
};

const DropInfoItem = ({
  icon,
  title,
  subtitle,
  trackValue,
  children,
  css,
  ...props
}: {
  icon: JSX.Element;
  title?: string;
  subtitle?: string;
  trackValue?: "locations" | "fulfillment_times";
  children: React.ReactNode;
  css?: CSS;
}) => {
  const { siteSettings } = useSelector((state: ShopReduxState) => state.storefront);
  const chefId = siteSettings?.chefId;
  const chefStyles: ChefStyles = getChefStyles(siteSettings);
  const [expanded, toggleExpanded] = useToggle(false);

  return (
    <Column css={{ ff: "$inter", cursor: "pointer", ...css }} {...props}>
      <Row
        css={{
          ai: "center",
          pad: "$md",
          br: "$lg",
          transition: "box-shadow 200ms cubic-bezier(0.4, 0.3, 0.8, 0.6) 0s",
          backgroundColor: "$white",
          "&:hover": {
            boxShadow:
              "inset 0 0 0 0 rgba(255,255,255,0), inset 0 0 0 4px $colors$white, inset 0 0 0 9999px $colors$gray3",
          },
          "&:active": {
            boxShadow:
              "inset 0 0 0 0 rgba(255,255,255,0), inset 0 0 0 4px $colors$white, inset 0 0 0 9999px $colors$gray3",
          },
        }}
        onClick={() => {
          toggleExpanded();
          trackDropInfoExpanded(chefId, trackValue);
        }}
      >
        {/* Icon wrapper */}
        <Flex
          css={{
            ai: "center",
            jc: "center",
            flexShrink: 0,
            br: "$pill",
            backgroundColor: chefStyles.primaryColorLight || "$violet4",
            color: chefStyles.primaryColorDark || "$violet9",
            mr: "$sm",
            size: 32,
            "@tablet": {
              size: 40,
              mr: "$md",
              "& svg": {
                height: 20,
                width: 20,
              },
            },
          }}
        >
          {icon}
        </Flex>
        <Column css={{ padr: "$xs", minW: 0 }}>
          <P
            css={{
              textStyle: "text-2",
              fw: "$semi_bold",
              color: "$gray12",
              lh: 1.375,
              "@tablet": { textStyle: "text-4" },
            }}
          >
            {title}
          </P>
          {subtitle && (
            <P css={{ textStyle: "text-2", color: "$gray10", lh: 1.375, truncateText: "30ch" }}>
              {subtitle}
            </P>
          )}
        </Column>
        <Box
          css={{
            ml: "auto",
            transform: expanded ? "rotate(90deg)" : "rotate(0deg)",
            transition: "transform 150ms ease-in-out",
            color: "$gray10",
          }}
        >
          <ChevronRightIcon height="20" width="20" />
        </Box>
      </Row>
      {expanded && <Column css={{ padl: "$md", "@tablet": { padl: "$lg" } }}>{children}</Column>}
    </Column>
  );
};

function linkToGoogleMaps(address: string) {
  address = address.replace(/ /, "+");
  address = address.replace(/#/g, "Unit ");
  window.open("https://www.google.com/maps/search/" + address, "_blank");
}

export function getTimeTitle(
  event: DropInfoEvent,
  fulfillmentType: FulfillmentType | "both",
  sortedTimeWindows: TimeWindow[],
  compact?: boolean
): string | undefined {
  let keyWord = "";
  if (!compact && fulfillmentType) {
    keyWord =
      fulfillmentType === "pickup"
        ? "Pickup "
        : fulfillmentType === "delivery"
        ? "Delivered "
        : "Pickup or Delivery "; // both
  } else {
    keyWord = "";
  }

  if (event.ticketed) keyWord = "";
  if (isEventOnDemandActive(event)) return keyWord + "ASAP";
  if (sortedTimeWindows.length === 0) return undefined;

  if (sortedTimeWindows.length === 1) {
    if (event.recurring)
      return (
        keyWord +
        "every " +
        getTimestampDayOfWeek(sortedTimeWindows[0].timeSlots[0].startTime, false)
      );
    return (
      keyWord + getTimestampDayOfWeekMonthDate(sortedTimeWindows[0].timeSlots[0].startTime, true)
    );
  }
  return (
    (event.recurring ? keyWord + "every " : keyWord) +
    sortedTimeWindows
      .slice(0, 3)
      .map((timeWindow: TimeWindow) => {
        if (event.recurring) return getTimestampDayOfWeek(timeWindow.timeSlots[0].startTime, true);
        // return getTimestampMonthDate(timeWindow.timeSlots[0].startTime, true);
        return new Date(timeWindow.timeSlots[0].startTime).toLocaleDateString("en-US", {
          month: "numeric",
          day: "numeric",
        });
      })
      .join(", ")
  );
}

const EventTitle = ({ css, children }: { css: CSS; children: React.ReactNode }) => {
  return (
    <H1 css={{ textStyle: "text-7", padx: "$md", padt: "$md", padb: "$xs", fs: "$xl", ...css }}>
      {children}
    </H1>
  );
};

export const DropInfo = ({
  event,
  fulfillmentType,
  presenceCount: dropPresenceCount,
  timeUntilGoLive,
}: {
  event: DropInfoEvent;
  fulfillmentType: FulfillmentType;
  presenceCount: number;
  timeUntilGoLive: number;
}) => {
  const [descriptionExpanded, toggleDescriptionExpanded] = useToggle(false);
  const textContainerRef = useRef<HTMLDivElement>(null);
  const [textContainerHeight, updateTextContainerHeight] = useState(0);

  const sortedTimeWindows = getSortedEventTimeSlotOptions({
    event: { ...event, recurringCount: 1 },
    includePickup: fulfillmentType !== "delivery",
    includeDelivery: fulfillmentType !== "pickup",
    customerZip: "all",
    menuItems: null,
    locationId: null,
    customerCartId: null,
  });

  const sortedLocations = getSortedLocationsEvent({
    event,
    includePickup: fulfillmentType !== "delivery",
    includeDelivery: fulfillmentType !== "pickup",
  });

  function getTimeSubtitle(): string | undefined {
    if (sortedTimeWindows.length < 4) return undefined;
    return `+${sortedTimeWindows.length - 3} more dates`;
  }

  function getLocationTitle(sortedLocations: Location[]): string {
    if (sortedLocations.length === 0) return "No locations";
    return sortedLocations[0].title;
  }

  function getLocationSubtitle(sortedLocations: Location[]): string {
    const subtitle =
      sortedLocations.length === 0
        ? ""
        : sortedLocations.length === 1
        ? getAddressString(sortedLocations[0])
        : " +" + (sortedLocations.length - 1) + " more locations";
    return subtitle;
  }

  useEffect(() => {
    const textContainerHeight = textContainerRef.current
      ? textContainerRef.current.clientHeight
      : 0;
    updateTextContainerHeight(textContainerHeight);
    // ? I am doing this so that the button will render on mount, but I do not htink this is the correct way to do it
    // ? I also worry with this method there could be situations where the button does not render if page loads on desktop (making the dscription short enough to not need the button) then is resized to mobile
  }, []);
  const showMoreButton =
    textContainerHeight > 45 ? (
      <Button
        variant="plain"
        color="accent"
        size="xsmall"
        css={{ padx: 0, height: "auto" }}
        onClick={() => toggleDescriptionExpanded()}
      >
        {descriptionExpanded ? "See less" : "Read more"}
      </Button>
    ) : null;

  return (
    <Column
      css={{
        backgroundColor: "$white",
        border: "1px solid $gray3",
        ff: "$inter",
        w: "$full", // for some reason adding a margin didn't work, this is a little dirty but whatever
        br: "$lg",
        boxShadow: "$elevation3",

        maxH: "calc(100% - 32px)",
        overflowY: "auto",
        overscrollBehavior: "contain",
        maxW: "min(400px, calc(100% - 32px))",
        "@desktop_md": {
          w: "$full",
          mt: "$xl",
          minW: 350,
        },
      }}
    >
      {event.image && (
        <Column css={{ pos: "relative" }}>
          <AspectRatioImg
            ratio={4 / 3}
            src={event.image}
            alt={`Image for ${event.title}`}
            css={{
              brTopLeft: "$lg",
              brTopRight: "$lg",
              position: "relative",
              "& ::after": {
                position: "absolute",
                content: "",
                height: "100%",
                width: "100%",
                top: 0,
                left: 0,
                background: "linear-gradient(to bottom, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0.4) 100%)",
              },
            }}
          />
          <EventTitle
            css={{
              pos: "absolute",
              bottom: 0,
              color: "$white",
              padb: "$md",
            }}
          >
            {event.title}
          </EventTitle>
        </Column>
      )}
      {!event.image && <EventTitle css={{ color: "$gray12" }}>{event.title}</EventTitle>}
      {event.description && (
        <Column
          css={{
            alignItems: "flex-start",
            padx: "$md",
            gap: "$xxs",
            padt: event.image ? "$sm" : 0,
          }}
        >
          <P
            css={{
              textStyle: "text-2",
              color: "$gray11",
              lineClamp: descriptionExpanded ? 999 : 2,
              whiteSpace: "pre-line",
            }}
            ref={textContainerRef}
          >
            {event.description}
          </P>
          {showMoreButton}
        </Column>
      )}

      {/* TIME WINDOWS */}
      <DropInfoItem
        icon={<CalendarIcon />}
        title={getTimeTitle(event, fulfillmentType, sortedTimeWindows)}
        trackValue="fulfillment_times"
        subtitle={getTimeSubtitle()}
      >
        {getSortedTimeWindows(event.timeWindows)
          .filter((timeWindow: TimeWindow) => {
            return (
              (timeWindow.isDelivery && fulfillmentType === "delivery") ||
              (!timeWindow.isDelivery && fulfillmentType === "pickup")
            );
          })
          .map((timeWindow, timeWindowIndex: number) => {
            const startDate = new Date(timeWindow.startTime);
            const dayMonthDateString = startDate.toLocaleDateString("en-US", {
              weekday: "short",
              month: "numeric",
              day: "numeric",
              timeZone: timeWindow.locations[Object.keys(timeWindow.locations)[0]].timeZone,
            });
            return (
              <DropInfoSubItem
                key={timeWindowIndex}
                icon={<ClockIcon />}
                subtitle={getReadableTimeWindow(timeWindow)}
                sideContent={getTimeWindowCardLocationTitle(timeWindow)}
                title={dayMonthDateString}
              />
            );
          })}
        <Callout type={"info"} hideIcon={false} css={{ mr: "$sm", textStyle: "text-1" }}>
          You will be able to select a time when you checkout.
        </Callout>
      </DropInfoItem>

      {/* LOCATIONS */}
      <DropInfoItem
        icon={<SewingPinFilledIcon />}
        trackValue="locations"
        title={getLocationTitle(sortedLocations)}
        subtitle={getLocationSubtitle(sortedLocations)}
      >
        {sortedLocations.map((location: Location, locationIndex: number) => {
          return (
            <DropInfoSubItem
              key={locationIndex}
              icon={<SewingPinFilledIcon />}
              title={location.title}
              subtitle={getAddressString(location)}
              sideContent={<ExternalLinkIcon />}
              onClick={() => linkToGoogleMaps(getAddressString(location))}
            />
            // TODO if location is delivery, the subItem should not be displaying the same info. Right now, you cannot know if the location is delivery or pickup because that is on the timewindow itself
          );
        })}
      </DropInfoItem>
      <DropInfoHype
        event={event}
        presenceCount={dropPresenceCount}
        timeUntilGoLive={timeUntilGoLive}
      />
    </Column>
  );
};
