/* eslint-disable react/prop-types */
import React from "react";
import ReactLoading from "react-loading";
import ToolTip from "../../../../hotplate-common/ToolTip";

import { TimeWindowCard, EventPickupWindows, Field } from "../../../../visly/Events";
import { FieldIcon } from "../../../../hotplate-common/FieldIcon";
import { Select } from "../../../../visly/Primitives";
import { useBreakpoint } from "../../../../visly";

import {
  getTimestampDayOfWeek,
  getTimeWindowCardLocationTitle,
  getTimestampHoursMinutesAMPM,
  getSortedTimeWindows,
  getEventStatus,
  getTimestampMonth,
} from "@hotplate/utils-ts/helperFunctions";
import { SewingPinFilledIcon } from "@radix-ui/react-icons";
import { colors } from "../../../../stitches.config";
import { trackAddTimeWindowClicked, trackPickupWindowCadenceUpdated } from "../analytics";

export default function LocationsAndTimesMaster({
  mergedEvent,
  saveEventLoading,
  saveAndContinue,
  createAndSwitchToNewTimeWindow,
  setIdOfTimeWindowBeingEdited,
  setTimeSlotCadence,
  publishEventLoading,
}) {
  const pickupTimeDict = {
    "5 minutes": "every 5 minutes",
    "15 minutes": "every 15 minutes",
    "30 minutes": "every 30 minutes",
    "60 minutes": "every 60 minutes",
    "Anytime between": "Anytime between window start and end",
  };
  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);
  const sortedTimeWindows = getSortedTimeWindows(mergedEvent.timeWindows);
  return (
    <EventPickupWindows
      size={size}
      AddPickupWindowButton={
        <EventPickupWindows.AddPickupWindowButton
          onClick={() => {
            createAndSwitchToNewTimeWindow();
            trackAddTimeWindowClicked({
              eventId: mergedEvent.id,
              eventStatus: getEventStatus(mergedEvent),
            });
          }}
        />
      }
      TimeCadence={
        <EventPickupWindows.TimeCadence
          tooltip={
            <ToolTip
              text={
                "This determines what pickup times customers can choose in your Pickup Windows.\n\n(e.g. if this is set to 15 mins, and you made a Pickup Window from 6:00PM - 7:00PM, customers could select a pickup time at 6:00PM, 6:15PM, etc.)"
              }
            />
          }
          Input={
            <Select
              style={{ width: "100%" }}
              label={pickupTimeDict[mergedEvent.timeSlotCadence]}
              selectedKey={mergedEvent.timeSlotCadence}
              onSelectionChange={(val) => {
                setTimeSlotCadence(val);
                trackPickupWindowCadenceUpdated({
                  eventId: mergedEvent.id,
                  eventStatus: getEventStatus(mergedEvent),
                  cadence: val,
                });
              }}
            >
              {Object.keys(pickupTimeDict).map((key) => (
                <Select.Option key={key} label={pickupTimeDict[key]} />
              ))}
            </Select>
          }
        />
      }
      SaveButton={
        <EventPickupWindows.SaveButton
          loading={saveEventLoading || publishEventLoading}
          loadingAnimation={
            <ReactLoading type={"bubbles"} color={"#FFFFFF"} height={50} width={50} />
          }
          disabled={sortedTimeWindows.length === 0 || saveEventLoading || publishEventLoading}
          kind={getEventStatus(mergedEvent) === "draft" ? "primary" : "good"}
          text={getEventStatus(mergedEvent) === "draft" ? "Save & Continue" : "Publish All Changes"}
          onClick={() => saveAndContinue()}
        />
      }
      PickupWindowContainer={
        <EventPickupWindows.PickupWindowContainer isEmpty={sortedTimeWindows.length === 0}>
          {sortedTimeWindows.map((timeWindow, timeWindowIndex) => (
            <TimeWindowCard
              key={timeWindowIndex}
              style={
                timeWindow.startTime < mergedEvent.goLiveTime
                  ? { outline: `2px solid ${colors.tomato9}`, backgroundColor: `${colors.tomato1}` }
                  : {}
              }
              showEditButton={true}
              recurring={mergedEvent.recurring}
              month={getTimestampMonth(timeWindow.startTime, true)}
              date={new Date(timeWindow.startTime).getDate()}
              onClick={() => setIdOfTimeWindowBeingEdited(timeWindow.id)}
              Field={
                <Field
                  style={{ fontSize: 14 }}
                  small
                  FieldIcon={<FieldIcon size="small" icon={<SewingPinFilledIcon />} />}
                  containsSubtitle={false}
                  title={getTimeWindowCardLocationTitle(timeWindow)}
                />
              }
              timeWindowRange={
                getTimestampDayOfWeek(timeWindow.startTime, true) +
                ", " +
                getTimestampHoursMinutesAMPM(timeWindow.startTime) +
                " - " +
                getTimestampHoursMinutesAMPM(timeWindow.endTime) +
                " " +
                new Date().toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2]
              }
            />
          ))}
        </EventPickupWindows.PickupWindowContainer>
      }
    />
  );
}
