// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./ConfirmationHeader.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      MPvdr9excX: {
        none: {
          text: "Order",
        },
      },
      Mkxjz8mFHR: {
        none: {
          text: "Confirmed",
        },
      },
      "4jrcLFLWwE": {
        none: {
          text: "",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const ConfirmationHeaderContext = createContext(null);

function ConfirmationHeader(_props) {
  const defaults = useContext(ConfirmationHeaderContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="NwCRTjrhTo"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "NwCRTjrhTo",
        scope: "JhqJ7pwXdR",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Container_QGDzA32yqU"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_JhqJ7pwXdR_QGDzA32yqU"
            }
            key={"QGDzA32yqU"}
            addSpacing={false}
            element={getStyle("QGDzA32yqU", "htmlElement")}
          >
            <TextPrimitive
              id={"orderType_MPvdr9excX"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_JhqJ7pwXdR_MPvdr9excX"
              }
              key={"MPvdr9excX"}
              text={
                exists(props.orderType)
                  ? props.orderType
                  : getStyle("MPvdr9excX", "text")
              }
              element={getStyle("MPvdr9excX", "htmlElement")}
            />
            <TextPrimitive
              id={"confirmed_Mkxjz8mFHR"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_JhqJ7pwXdR_Mkxjz8mFHR"
              }
              key={"Mkxjz8mFHR"}
              text={getStyle("Mkxjz8mFHR", "text")}
              element={getStyle("Mkxjz8mFHR", "htmlElement")}
            />
            <SpacerPrimitive
              id={"Spacer_MBJwki8Wjm"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_JhqJ7pwXdR_MBJwki8Wjm"
              }
              key={"MBJwki8Wjm"}
            />
            <TextPrimitive
              id={"orderPlaced_4jrcLFLWwE"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_JhqJ7pwXdR_4jrcLFLWwE"
              }
              key={"4jrcLFLWwE"}
              text={
                exists(props.orderPlaced)
                  ? props.orderPlaced
                  : getStyle("4jrcLFLWwE", "text")
              }
              element={getStyle("4jrcLFLWwE", "htmlElement")}
            />
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_WyLup18dB7"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_JhqJ7pwXdR_WyLup18dB7"
            }
            key={"WyLup18dB7"}
          />
          <ContainerPrimitive
            key={"5fv3DvG14c"}
            id={"Slot_5fv3DvG14c"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_JhqJ7pwXdR_5fv3DvG14c"
            }
            addSpacing={false}
          >
            {props.children}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

ConfirmationHeader.__variants = [];

export default ConfirmationHeader;
