// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./CheckoutForm.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";
import {
  default as IconButton,
  IconButtonContext,
} from "../Primitives/IconButton";

const styles = [
  {
    type: "default",
    layers: {
      "4eXbwpW5eK": {
        none: {
          text: "Form",
        },
      },
      NNcnYStfpQ: {
        none: {
          text: "description of form",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "collapsed",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      "6xkpL8GhE4": {
        none: {
          icon: icons.chevronDown,
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "collapsed",
    layers: {
      "6xkpL8GhE4": {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "collapsed",
  },
];

export const CheckoutFormContext = createContext(null);

function CheckoutForm(_props) {
  const defaults = useContext(CheckoutFormContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="TaVJvsBrFC"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "TaVJvsBrFC",
        scope: "F7jCCW92eH",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"formWrapper_UrhfUjuYCA"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_F7jCCW92eH_UrhfUjuYCA"
          }
          key={"UrhfUjuYCA"}
          addSpacing={false}
          element={getStyle("UrhfUjuYCA", "htmlElement")}
        >
          <ContainerPrimitive
            id={"Container_LQ7itHqSHc"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_F7jCCW92eH_LQ7itHqSHc"
            }
            key={"LQ7itHqSHc"}
            addSpacing={false}
            element={getStyle("LQ7itHqSHc", "htmlElement")}
          >
            <TextPrimitive
              id={"label_4eXbwpW5eK"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_F7jCCW92eH_4eXbwpW5eK"
              }
              key={"4eXbwpW5eK"}
              text={
                exists(props.label)
                  ? props.label
                  : getStyle("4eXbwpW5eK", "text")
              }
              element={getStyle("4eXbwpW5eK", "htmlElement")}
            />
            <ContainerPrimitive
              id={"withText_UaR2B9hZK8"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_F7jCCW92eH_UaR2B9hZK8"
              }
              key={"UaR2B9hZK8"}
              addSpacing={false}
              element={getStyle("UaR2B9hZK8", "htmlElement")}
            >
              {
                <TextPrimitive
                  id={"subtext_NNcnYStfpQ"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_F7jCCW92eH_NNcnYStfpQ"
                  }
                  key={"NNcnYStfpQ"}
                  text={
                    exists(props.subtext)
                      ? props.subtext
                      : getStyle("NNcnYStfpQ", "text")
                  }
                  element={getStyle("NNcnYStfpQ", "htmlElement")}
                />
              }
            </ContainerPrimitive>
            <ContainerPrimitive
              key={"Cf8vCVAUXj"}
              id={"Slot_Cf8vCVAUXj"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_F7jCCW92eH_Cf8vCVAUXj"
              }
              addSpacing={false}
            >
              {props.children}
            </ContainerPrimitive>
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"Container_YSxfSWDxdM"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_F7jCCW92eH_YSxfSWDxdM"
            }
            key={"YSxfSWDxdM"}
            addSpacing={false}
            element={getStyle("YSxfSWDxdM", "htmlElement")}
          >
            {props.IconButton === undefined ? (
              <IconButton
                key={"6xkpL8GhE4"}
                {...getCompositeDefaultProps("6xkpL8GhE4")}
                id="IconButton_6xkpL8GhE4"
                className="__visly_reset_7X7HTLRqyD __visly_scope_F7jCCW92eH_6xkpL8GhE4"
              />
            ) : (
              <IconButtonContext.Provider
                key="6xkpL8GhE4-provider"
                value={{
                  key: "6xkpL8GhE4",
                  id: "IconButton_6xkpL8GhE4",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_F7jCCW92eH_6xkpL8GhE4",
                  ...getCompositeDefaultProps("6xkpL8GhE4"),
                }}
              >
                {props.IconButton}
              </IconButtonContext.Provider>
            )}
          </ContainerPrimitive>
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

CheckoutForm.IconButton = IconButton;
CheckoutForm.__variants = [
  {
    name: "collapsed",
    type: "variant",
  },
];

export default CheckoutForm;
