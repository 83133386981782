import { getDateTimeString, getFormattedPhone } from "@hotplate/utils-ts/helperFunctions";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useContext, useState } from "react";
import { CSVLink } from "react-csv";
import { TableVirtuoso } from "react-virtuoso";
import { usePortalUser } from "../../auth";
import { FirebaseContext } from "../../firebaseSocket";
import { PuffLoader } from "../../hotplate-common/loaders/PuffLoader";
import { Center, Column, Flex, H1, P, Row } from "../../hotplate-common/primitives/Containers";
import { Error } from "../../hotplate-common/primitives/Error";
import { styled } from "../../stitches.config";
import CustomerModal from "../components/CustomerModal";
import { NothingHere } from "../orderManagement/components/NothingHere";

const RefreshDataButton = styled("button", {
  all: "unset",
  cursor: "pointer",
  color: "$gray9",
  fontFamily: "$avenir",
  fontSize: 12,
  fontWeight: "$semi_bold",
  padding: "$sm",
  paddingBottom: 0,
  "&:hover": {
    textDecoration: "underline",
  },
  "&:disabled": {
    pointerEvents: "none",
  },
});

function StyledTable(props: any) {
  return <table {...props} style={{ borderSpacing: "1em", borderCollapse: "separate" }} />;
}

export default function Customers() {
  const firebaseContext = useContext<any>(FirebaseContext);
  const { chefId } = usePortalUser();

  const queryClient = useQueryClient();
  const getInsights = useQuery(
    ["getInsights", chefId],
    async () => {
      return (await firebaseContext.database.ref(`/hosts/${chefId}/insightsCache`).get()).val();
    },
    { staleTime: Number.POSITIVE_INFINITY }
  );
  const refreshInsights = useMutation(
    () => {
      return firebaseContext.cloudFunctions.updateInsightsCache();
    },
    {
      onSuccess: (json: any) => {
        queryClient.setQueryData(["getInsights", chefId], json.data);
      },
    }
  );

  const [selectedCustomerPhoneNumber, setSelectedCustomerPhoneNumber] = useState<string>();
  const [customerSort, setCustomerSort] = useState({
    field: "totalSpend",
    ascending: false,
  });
  const [customerSearch, setCustomerSearch] = useState("");

  function getTrProps(customer: any) {
    return {
      onClick: () => {
        setSelectedCustomerPhoneNumber(customer.phone);
      },
      style: { cursor: "pointer" },
    };
  }

  const loadedSuccessfully = !getInsights.isLoading && !getInsights.isError && getInsights.data;

  return (
    <Flex
      css={{
        padding: "$md",
        width: "100%",
        justifyContent: "center",
        "@tablet": {
          padding: "$lg",
        },
        fontFamily: "$avenir",
      }}
    >
      <Column css={{ width: "100%", maxWidth: "$maxContentWidth" }}>
        <Row css={{ flexWrap: "wrap", gap: "$sm", alignItems: "baseline" }}>
          <H1
            css={{
              fontFamily: "$arboria",
              fontSize: "$heading",
              fontWeight: "$semi_bold",
              lineHeight: "$text",
              color: "$violet12",
            }}
          >
            Customers
          </H1>
          <Row css={{ alignItems: "flex-end" }}>
            <P
              css={{
                fontFamily: "$avenir",
                fontSize: 12,
                fontWeight: "$normal",
                color: "$gray9",
              }}
            >
              {loadedSuccessfully
                ? `Last updated ${new Date(getInsights.data.lastUpdatedAt).toLocaleString(
                    undefined,
                    {
                      dateStyle: "short",
                      timeStyle: "short",
                    }
                  )}`
                : ""}
            </P>
            {!getInsights.isLoading && (
              <RefreshDataButton
                disabled={refreshInsights.isLoading}
                onClick={() => {
                  refreshInsights.mutateAsync();
                }}
              >
                {refreshInsights.isLoading
                  ? "Refreshing... (this may take several minutes)"
                  : "Refresh"}
              </RefreshDataButton>
            )}
          </Row>
        </Row>
        <Error show={refreshInsights.isError}>{"" + refreshInsights.error}</Error>
        {getInsights.isLoading ? (
          <Center css={{ height: "100%", width: "100%", paddingTop: 256 }}>
            <PuffLoader />
          </Center>
        ) : getInsights.isError ? (
          <Error>{"" + getInsights.error}</Error>
        ) : !getInsights.data ? (
          <NothingHere />
        ) : (
          <>
            <input
              type="text"
              size={20}
              placeholder="Filter"
              value={customerSearch}
              onChange={(e) => {
                setCustomerSearch(e.target.value);
              }}
            />
            {getInsights.data.customers ? (
              (() => {
                const allCustomers = Object.values(getInsights.data.customers);
                const data = allCustomers
                  .filter(
                    (customer: any) =>
                      !customerSearch ||
                      [customer.fullName, customer.phone]
                        .join("\n")
                        .toLowerCase()
                        .includes(customerSearch.toLowerCase().replace(/\s/g, ""))
                  )
                  .sort((a: any, b: any) => {
                    let ret;
                    switch (customerSort.field) {
                      case "fullName":
                      case "phone": {
                        ret = a[customerSort.field].localeCompare(b[customerSort.field], "en", {
                          sensitivity: "accent",
                        });
                        break;
                      }
                      default:
                        ret = a[customerSort.field] - b[customerSort.field];
                    }
                    return customerSort.ascending ? ret : ret * -1;
                  });

                return (
                  <>
                    <TableVirtuoso
                      style={{ height: "500px" }}
                      components={{
                        Table: StyledTable,
                      }}
                      data={data}
                      fixedHeaderContent={() => (
                        <tr>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setCustomerSort({
                                field: "fullName",
                                ascending:
                                  customerSort.field !== "fullName"
                                    ? true
                                    : !customerSort.ascending,
                              });
                            }}
                          >
                            Name
                            {customerSort.field !== "fullName"
                              ? ""
                              : customerSort.ascending
                              ? "▲"
                              : "▼"}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setCustomerSort({
                                field: "phone",
                                ascending:
                                  customerSort.field !== "phone" ? true : !customerSort.ascending,
                              });
                            }}
                          >
                            Phone
                            {customerSort.field !== "phone"
                              ? ""
                              : customerSort.ascending
                              ? "▲"
                              : "▼"}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setCustomerSort({
                                field: "totalSpend",
                                ascending:
                                  customerSort.field !== "totalSpend"
                                    ? false
                                    : !customerSort.ascending,
                              });
                            }}
                          >
                            Total Spend
                            {customerSort.field !== "totalSpend"
                              ? ""
                              : customerSort.ascending
                              ? "▲"
                              : "▼"}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setCustomerSort({
                                field: "ordersPlaced",
                                ascending:
                                  customerSort.field !== "ordersPlaced"
                                    ? false
                                    : !customerSort.ascending,
                              });
                            }}
                          >
                            # of Orders
                            {customerSort.field !== "ordersPlaced"
                              ? ""
                              : customerSort.ascending
                              ? "▲"
                              : "▼"}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setCustomerSort({
                                field: "isSignedUpForReminders",
                                ascending:
                                  customerSort.field !== "isSignedUpForReminders"
                                    ? true
                                    : !customerSort.ascending,
                              });
                            }}
                          >
                            Reminders?
                            {customerSort.field !== "isSignedUpForReminders"
                              ? ""
                              : customerSort.ascending
                              ? "▲"
                              : "▼"}
                          </th>
                        </tr>
                      )}
                      itemContent={(index, customer: any) => (
                        <>
                          <td {...getTrProps(customer)}>{customer.fullName}</td>
                          <td {...getTrProps(customer)}>{getFormattedPhone(customer.phone)}</td>
                          <td {...getTrProps(customer)}>${customer.totalSpend}</td>
                          <td {...getTrProps(customer)}>{customer.ordersPlaced}</td>
                          <td {...getTrProps(customer)}>
                            {customer.isSignedUpForReminders ? "Yes" : "No"}
                          </td>
                        </>
                      )}
                      computeItemKey={(index, customer) => customer.phone}
                    />
                    <p>
                      Viewing {data.length} / {allCustomers.length} customers.
                    </p>
                    <CSVLink
                      data={data}
                      filename={"hotplate-customers-" + getDateTimeString() + ".csv"}
                      target="_blank"
                    >
                      Export
                    </CSVLink>
                  </>
                );
              })()
            ) : (
              <p>No customers yet.</p>
            )}
          </>
        )}
        <CustomerModal
          isOpen={!!selectedCustomerPhoneNumber}
          phone={selectedCustomerPhoneNumber}
          onRequestClose={() => {
            setSelectedCustomerPhoneNumber(undefined);
          }}
        />
      </Column>
    </Flex>
  );
}
