/* eslint-disable react/prop-types */
import React, { useState } from "react";
import DayPicker from "../../../components/DayPicker";
import AddEditModal from "../../../components/AddEditModal";
import { Input } from "../../../../visly/Primitives";
import { CardSegmented, LabeledInput } from "../../../../visly/Events";
import { textstyles, colors } from "../../../../visly";
import Modal from "../../../../hotplate-common/Modal";
import Divider from "../../../../hotplate-common/Divider";
import Spacer from "../../../../hotplate-common/Spacer";

import "./css/EditOrderCutoffTypeModal.css";
import { mergeDiff, removeNulls } from "../../../../hooks";
import { trackEventCloseTimeUpdated } from "../analytics";
import { getEventStatus } from "@hotplate/utils-ts/helperFunctions";

export default function EditOrderCutoffTypeModal({
  event,
  setShowEditOrderCutoffTypeModal,
  onRequestSave,
  setOrderCutoffDayOfWeek,
}) {
  const [eventDiff, setEventDiff] = useState({});
  const mergedEvent = removeNulls(mergeDiff(event, eventDiff));

  function setOrderCutoffType(value) {
    if (value !== "Never" && value !== "Specific Time" && value !== "Hours Before") return;
    setEventDiff((prevEventDiff) => {
      return {
        ...prevEventDiff,
        orderCutoffType: value,
      };
    });
    trackEventCloseTimeUpdated({
      eventId: event.id,
      eventStatus: getEventStatus(event),
      type: value,
    });
  }

  function setOrderCutoffDate(timestamp) {
    const prevD = new Date(mergedEvent.orderCutoffTime);
    const nextD = new Date(timestamp);
    prevD.setYear(nextD.getFullYear());
    prevD.setMonth(nextD.getMonth(), nextD.getDate());
    const newOrderCutoffTime = prevD.getTime();
    setEventDiff((prevEventDiff) => {
      return {
        ...prevEventDiff,
        orderCutoffTime: newOrderCutoffTime,
      };
    });
  }

  function setOrderCutoffHoursMinutes(timeAMPM) {
    const prevD = new Date(mergedEvent.orderCutoffTime);
    const hours = parseInt(timeAMPM.split(":")[0]);
    const minutes = parseInt(timeAMPM.split(":")[1].substr(0, 2));
    const ampm = timeAMPM.split(":")[1].substr(2, 2);
    prevD.setHours((hours % 12) + (ampm === "PM" ? 12 : 0), minutes, 0, 0);
    const newOrderCutoffTime = prevD.getTime();
    setEventDiff((prevEventDiff) => {
      return {
        ...prevEventDiff,
        orderCutoffTime: newOrderCutoffTime,
      };
    });
  }

  function setOrderCutoffHoursBefore(value) {
    if (!/^[0-9]+$/.test(value) && value !== "") return;
    setEventDiff((prevEventDiff) => {
      return {
        ...prevEventDiff,
        orderCutoffHoursBefore: value !== "" ? parseInt(value) : value,
      };
    });
  }

  return (
    <Modal closeModal={() => setShowEditOrderCutoffTypeModal(false)} optionSelectModalState={null}>
      <AddEditModal
        cancelButtonOnClick={() => setShowEditOrderCutoffTypeModal(false)}
        saveButtonOnClick={() => {
          onRequestSave(eventDiff);
        }}
        saveDisabled={false}
        title="Choose when pre-orders close"
        editing={false}
        setOptionSelectModalState={() => {
          // do nothing
        }}
      >
        <div className="orderCutoffModal">
          <Spacer size="Large" />
          <CardSegmented
            value={mergedEvent.orderCutoffType}
            onChange={(val) => setOrderCutoffType(val)}
          >
            <CardSegmented.Button
              title="When event ends"
              subtitle="Pre-orders don’t close until the last pickup window ends."
              value="Never"
            />
            <CardSegmented.Button
              title="Hours before pickups begin"
              subtitle="Set the numbers of hours you want pre-orders to close before each pickup window."
              value="Hours Before"
            />
            {!mergedEvent.recurring && (
              <CardSegmented.Button
                title="At a specific time"
                subtitle="Set a day and time for all orders to close for this event."
                value="Specific Time"
              />
            )}
          </CardSegmented>
          <Divider spacing="XLarge" />
          <LabeledInput
            label={
              "Pre-orders Close" +
              (mergedEvent.orderCutoffType === "Specific Time"
                ? ` (${
                    new Date().toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2]
                  })`
                : "")
            }
            Input={
              <div className="inputs-container">
                {mergedEvent.orderCutoffType === "Never" && (
                  <p
                    style={{
                      padding: "24px 0px",
                      color: colors.gray600,
                      ...textstyles.bodyLarge,
                      lineHeight: "20px",
                    }}
                  >
                    Pre-orders don’t close until the last pickup window is finished.
                  </p>
                )}

                {mergedEvent.orderCutoffType === "Hours Before" && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Input
                      style={{ width: "100px" }}
                      value={mergedEvent.orderCutoffHoursBefore}
                      onChange={(val) => setOrderCutoffHoursBefore(val)}
                      withUnit="right"
                      unit="hours"
                    />
                    <Spacer size="Medium" />
                    <p
                      style={{
                        color: colors.gray800,
                        ...textstyles.bodyLarge,
                      }}
                    >
                      before each pickup window
                    </p>
                  </div>
                )}

                {mergedEvent.orderCutoffType === "Specific Time" && (
                  <div className="specificTimeInput-container">
                    <DayPicker
                      recurring={mergedEvent.recurring}
                      setTimeWindowDayOfWeek={setOrderCutoffDayOfWeek}
                      dateLabel=" "
                      setDate={setOrderCutoffDate}
                      startTimeLabel=" "
                      startTimestamp={mergedEvent.orderCutoffTime}
                      setStartTime={setOrderCutoffHoursMinutes}
                    />
                  </div>
                )}
                <Spacer size="XLarge" />
                <Spacer size="XLarge" />
              </div>
            }
          />
        </div>
      </AddEditModal>
    </Modal>
  );
}
