import React from "react";
import "./css/AnimationContainer.css";

function AnimationContainer({ animation, height, width }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: height,
        width: width,
      }}
    >
      {animation === "blob" && <div className={animation}></div>}
      {animation === "blob-green" && <div className={animation}></div>}
      {animation === "check" && (
        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
          <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
      )}
      {animation === "bigRing" && (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      {animation === "loader --2" && <div className="loader --2"></div>}
      {animation === "loader --7" && <div className="loader --7"></div>}
    </div>
  );
}

export default AnimationContainer;
