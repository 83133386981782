/* eslint-disable react/prop-types */
import React from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "../../hotplate-common/primitives/Tooltip";
import "./css/CountdownTimer.css";
import { P } from "../../hotplate-common/primitives/Containers";
import { ShopReduxState } from "../shop/types";
import type { CSS } from "@stitches/react";
import { styled } from "../../stitches.config";

const TimerContainer = styled("div", {
  display: "flex",
  ai: "center",
  jc: "center",
  justifySelf: "center",
  transition: "box-shadow 125ms ease-in-out, color 125ms ease-in-out",
  variants: {
    size: {
      small: {
        w: 60,
        h: 28,
        br: "$sm",
        ff: "$inter",
        "& p": {
          fs: 14,
          fw: "$semi_bold",
          lh: "$normal",
        },
      },
      medium: {
        w: 68,
        h: 32,
        br: "$sm",
        ff: "$inter",
        "& p": {
          fs: 16,
          fw: "$semi_bold",
          lh: "$normal",
        },
      },
      large: {
        w: 80,
        h: 40,
        br: "$md",
        ff: "$inter",
        "& p": {
          fs: 22,
          fw: "$bold",
          lh: "$normal",
        },
      },
    },
    type: {
      normal: {
        boxShadow: "$colors$overlay10 0 0 2000px inset",
        color: "$white",
      },
      warning: {
        boxShadow: "$colors$warning4 0 0 2000px inset",
        color: "$warning11",
      },
      danger: {
        boxShadow: "$colors$danger9 0px 0 3px 2px, $colors$danger4 0 0 2000px inset",
        color: "$danger11",
      },
    },
  },
});

export default function CountdownTimerMaster({
  css,
  size,
  withTooltip,
}: {
  size: "small" | "medium" | "large";
  withTooltip?: boolean;
  css?: CSS;
}) {
  const { countdown } = useSelector((state: ShopReduxState) => state.cart);

  if (!Number.isInteger(countdown)) {
    return null;
  }

  const minsCountdown = Math.floor(countdown / 60);
  const secsCountdown = (countdown % 60 < 10 ? "0" : "") + (countdown % 60);

  const inner = (
    <TimerContainer
      size={size}
      type={minsCountdown > 4 ? "normal" : minsCountdown > 0 ? "warning" : "danger"}
      css={css}
    >
      <P>{`${minsCountdown}:${secsCountdown}`}</P>
    </TimerContainer>
  );

  return (
    <>
      {withTooltip ? (
        <Tooltip
          css={{}}
          clickable
          content={`This timer shows how long we'll reserve your food during checkout. \n\nIf time runs out, your cart will expire and be made available to other customers.`}
        >
          {inner}
        </Tooltip>
      ) : (
        inner
      )}
    </>
  );
}
