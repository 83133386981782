// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./List.Item.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import { ListboxItemPrimitive } from "../_internal_listbox";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      "3VJGRE8vAZ": {
        none: {
          text: "List item",
        },
      },
      Gs3JxzGhUj: {
        none: {
          text: "$0.00",
        },
      },
      sfyEfNk5at: {
        none: {
          icon: icons.vislyCheck,
          useMask: true,
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
  {
    type: "boolean",
    propName: "small",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
  {
    type: "boolean",
    propName: "small",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "selected",
  },
  {
    type: "boolean",
    propName: "small",
  },
];

export const ItemContext = createContext(null);

function Item(_props) {
  const defaults = useContext(ItemContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <ListboxItemPrimitive
      {...props}
      key="listbox-item-root"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "listbox-item-root",
        scope: "BPKn9HiVM5",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Container_8eHBFagjC4"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_BPKn9HiVM5_8eHBFagjC4"
            }
            key={"8eHBFagjC4"}
            addSpacing={false}
            element={getStyle("8eHBFagjC4", "htmlElement")}
          >
            {
              <IconPrimitive
                id={"Icon_sfyEfNk5at"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_BPKn9HiVM5_sfyEfNk5at"
                }
                key={"sfyEfNk5at"}
                useMask={getStyle("sfyEfNk5at", "useMask")}
                src={getStyle("sfyEfNk5at", "icon")}
              />
            }
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_FhP7D7WBdX"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_BPKn9HiVM5_FhP7D7WBdX"
            }
            key={"FhP7D7WBdX"}
          />
          <ContainerPrimitive
            id={"Container_Qmka6DNn8F"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_BPKn9HiVM5_Qmka6DNn8F"
            }
            key={"Qmka6DNn8F"}
            addSpacing={false}
            element={getStyle("Qmka6DNn8F", "htmlElement")}
          >
            <ContainerPrimitive
              id={"Container_T9qfsm4phb"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_BPKn9HiVM5_T9qfsm4phb"
              }
              key={"T9qfsm4phb"}
              addSpacing={false}
              element={getStyle("T9qfsm4phb", "htmlElement")}
            >
              {
                <TextPrimitive
                  id={"Option_3VJGRE8vAZ"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_BPKn9HiVM5_3VJGRE8vAZ"
                  }
                  key={"3VJGRE8vAZ"}
                  text={
                    exists(props.option)
                      ? props.option
                      : getStyle("3VJGRE8vAZ", "text")
                  }
                  element={getStyle("3VJGRE8vAZ", "htmlElement")}
                />
              }
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"Container_4QwvWqe6t3"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_BPKn9HiVM5_4QwvWqe6t3"
              }
              key={"4QwvWqe6t3"}
              addSpacing={false}
              element={getStyle("4QwvWqe6t3", "htmlElement")}
            >
              {
                <TextPrimitive
                  id={"Price_Gs3JxzGhUj"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_BPKn9HiVM5_Gs3JxzGhUj"
                  }
                  key={"Gs3JxzGhUj"}
                  text={
                    exists(props.price)
                      ? props.price
                      : getStyle("Gs3JxzGhUj", "text")
                  }
                  element={getStyle("Gs3JxzGhUj", "htmlElement")}
                />
              }
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"Container_6eFfTSHAR7"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_BPKn9HiVM5_6eFfTSHAR7"
              }
              key={"6eFfTSHAR7"}
              addSpacing={false}
              element={getStyle("6eFfTSHAR7", "htmlElement")}
            >
              {
                <ContainerPrimitive
                  key={"V7CwEbmktu"}
                  id={"Slot_V7CwEbmktu"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_BPKn9HiVM5_V7CwEbmktu"
                  }
                  addSpacing={false}
                >
                  {props.badge}
                </ContainerPrimitive>
              }
            </ContainerPrimitive>
          </ContainerPrimitive>
        </>
      )}
    </ListboxItemPrimitive>
  );
}

Item.__variants = [
  {
    name: "selected",
    type: "variant",
  },
  {
    name: "small",
    type: "variant",
  },
];

export default Item;
