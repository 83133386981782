// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./BillingBanner.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      FExGcEvDKJ: {
        none: {
          text: "Payment missing! Account pausing in ",
        },
      },
      RPAoDV66qf: {
        none: {
          text: "",
        },
      },
      YTS9eoR4n8: {
        none: {
          text: "hours",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const BillingBannerContext = createContext(null);

function BillingBanner(_props) {
  const defaults = useContext(BillingBannerContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="DuLCc3Gnnj"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "DuLCc3Gnnj",
        scope: "NRFC2rRTRJ",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <TextPrimitive
            id={"Text_FExGcEvDKJ"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_NRFC2rRTRJ_FExGcEvDKJ"
            }
            key={"FExGcEvDKJ"}
            text={getStyle("FExGcEvDKJ", "text")}
            element={getStyle("FExGcEvDKJ", "htmlElement")}
          />
          <SpacerPrimitive
            id={"Spacer_WPBrnHSiND"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_NRFC2rRTRJ_WPBrnHSiND"
            }
            key={"WPBrnHSiND"}
          />
          <TextPrimitive
            id={"hours_RPAoDV66qf"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_NRFC2rRTRJ_RPAoDV66qf"
            }
            key={"RPAoDV66qf"}
            text={
              exists(props.hours) ? props.hours : getStyle("RPAoDV66qf", "text")
            }
            element={getStyle("RPAoDV66qf", "htmlElement")}
          />
          <SpacerPrimitive
            id={"Spacer_FdTbehQvQi"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_NRFC2rRTRJ_FdTbehQvQi"
            }
            key={"FdTbehQvQi"}
          />
          <TextPrimitive
            id={"hours_YTS9eoR4n8"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_NRFC2rRTRJ_YTS9eoR4n8"
            }
            key={"YTS9eoR4n8"}
            text={getStyle("YTS9eoR4n8", "text")}
            element={getStyle("YTS9eoR4n8", "htmlElement")}
          />
        </>
      )}
    </RootPrimitive>
  );
}

BillingBanner.__variants = [];

export default BillingBanner;
