import fetchRetry from "./FetchRetry";
import * as Sentry from "@sentry/react";
import firebase from "firebase/app";

import {
  LOAD_USER_INFO_START,
  LOAD_USER_INFO_SUCCESS,
  LOAD_USER_INFO_FAILURE,
  LOGOUT_USER,
  backendTsUrl,
} from "./types";
import { getAuthHeaders } from "../../mutations";

export const getUserInfo = () => {
  return (dispatch) => {
    dispatch({ type: LOAD_USER_INFO_START });

    return getAuthHeaders()
      .then((headers) =>
        fetchRetry(backendTsUrl + "portal/userInfo", {
          method: "GET",
          headers,
        })
      )
      .then((response) =>
        response.json().then((json) => {
          if (response.status >= 400) {
            Sentry.captureMessage("server_responded_with_error");
            loadUserInfoFailure(dispatch, "SERVER_ERROR");
            return;
          }
          dispatch({
            type: LOAD_USER_INFO_SUCCESS,
            payload: json,
          });
        })
      )
      .catch((exception) => {
        Sentry.captureException(exception);
        loadUserInfoFailure(dispatch, exception + "");
      });
  };
};

export const logoutUser = (hostPortal = false) => {
  return (dispatch) => {
    dispatch({
      type: LOGOUT_USER,
      payload: hostPortal,
    });
  };
};

export const loadUserInfoSuccess = (dispatch, infoDict) => {
  dispatch({
    type: LOAD_USER_INFO_SUCCESS,
    payload: infoDict,
  });
};

const loadUserInfoFailure = (dispatch, exception) => {
  console.log(exception);
  dispatch({
    type: LOAD_USER_INFO_FAILURE,
    payload: exception,
  });
};
