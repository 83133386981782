// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./EventOverview.EmptyEventsCta.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";
import {
  default as CreateFirstEventButton,
  ButtonContext as CreateFirstEventButtonContext,
} from "../Primitives/Button";

const styles = [
  {
    type: "default",
    layers: {
      Je3L6gxMjm: {
        none: {
          icon: icons.empty,
        },
      },
      VxLHKPdcmA: {
        none: {
          text: "You haven't made any events yet, lets get started!",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      Go4EkBsGJN: {
        none: {
          text: " Create your first event",
          large: true,
          kind: "primary",
        },
      },
    },
  },
];

const variantPropTypes = [];

export const EmptyEventsCtaContext = createContext(null);

function EmptyEventsCta(_props) {
  const defaults = useContext(EmptyEventsCtaContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="RGUoW1rdZF"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "RGUoW1rdZF",
        scope: "LSoYaJf8tW",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"cta-container_JDvGZHyanz"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_LSoYaJf8tW_JDvGZHyanz"
          }
          key={"JDvGZHyanz"}
          addSpacing={false}
          element={getStyle("JDvGZHyanz", "htmlElement")}
        >
          {[
            <ContainerPrimitive
              id={"icon-container_KYSKCZ4YGV"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_LSoYaJf8tW_KYSKCZ4YGV"
              }
              key={"KYSKCZ4YGV"}
              addSpacing={false}
              element={getStyle("KYSKCZ4YGV", "htmlElement")}
            >
              {
                <IconPrimitive
                  id={"Icon_Je3L6gxMjm"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_LSoYaJf8tW_Je3L6gxMjm"
                  }
                  key={"Je3L6gxMjm"}
                  useMask={getStyle("Je3L6gxMjm", "useMask")}
                  src={getStyle("Je3L6gxMjm", "icon")}
                />
              }
            </ContainerPrimitive>,
            <TextPrimitive
              id={"Text_VxLHKPdcmA"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_LSoYaJf8tW_VxLHKPdcmA"
              }
              key={"VxLHKPdcmA"}
              text={getStyle("VxLHKPdcmA", "text")}
              element={getStyle("VxLHKPdcmA", "htmlElement")}
            />,
            <SpacerPrimitive
              id={"Spacer_4ifp3Nix9B"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_LSoYaJf8tW_4ifp3Nix9B"
              }
              key={"4ifp3Nix9B"}
            />,
            props.CreateFirstEventButton === undefined ? (
              <CreateFirstEventButton
                key={"Go4EkBsGJN"}
                {...getCompositeDefaultProps("Go4EkBsGJN")}
                id="CreateFirstEventButton_Go4EkBsGJN"
                className="__visly_reset_7X7HTLRqyD __visly_scope_LSoYaJf8tW_Go4EkBsGJN"
              />
            ) : (
              <CreateFirstEventButtonContext.Provider
                key="Go4EkBsGJN-provider"
                value={{
                  key: "Go4EkBsGJN",
                  id: "CreateFirstEventButton_Go4EkBsGJN",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_LSoYaJf8tW_Go4EkBsGJN",
                  ...getCompositeDefaultProps("Go4EkBsGJN"),
                }}
              >
                {props.CreateFirstEventButton}
              </CreateFirstEventButtonContext.Provider>
            ),
          ]}
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

EmptyEventsCta.CreateFirstEventButton = CreateFirstEventButton;
EmptyEventsCta.__variants = [];

export default EmptyEventsCta;
