/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect, useCallback } from "react";
import { FirebaseContext } from "../firebaseSocket";
import firebase from "firebase/app";
import "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import Cohere from "cohere-js";
import VersionChecker from "../hotplate-common/VersionChecker";
import { H as Highlight } from "highlight.run";
import Login from "../hotplate-common/Login";
import { identify } from "../hotplate-common/analytics";

import { loadUserInfoSuccess } from "../hotplate-storefront/actions";
import NewModal from "../hotplate-common/NewModal";
import { styled } from "../stitches.config";
import { Referral } from "./Referral";
import { usePrevious } from "../hooks";
import { PortalNav } from "./components/PortalNav";
import { Navigate, Route, Routes } from "react-router-dom";
import Insights from "./insights";
import Customers from "./customers";
import OrderManagement from "./orderManagement";
import Events from "./events";
import Payment from "./payment";
import GodMode from "./godmode";
import AccountSettings from "./accountSettings";
import Billing from "./billing";
import Feedback from "./feedback";
import { NotFound } from "../hotplate-common/NotFound";
import * as Sentry from "@sentry/react";
import { ConfirmContext } from "./Confirm";
import { trpc } from "../trpc";
import { logger, popLoggerContext, pushLoggerContext } from "../logger";
import { PeerBoard } from "./components/PeerBoard";
import { useAuth, useMaybeUser } from "../auth";
import { setPortalAnalyticsChefId } from "./analytics";
import ConnectionStatusMonitor from "../hotplate-common/ConnectionStatusMonitor";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const PortalRoot = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  minHeight: "100%",
  width: "100%",
  overscrollBehavior: "none",
});

const PortalBody = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  flexGrow: 1 /* so it will fill the screen, even if there is not much content*/,
  overscrollBehavior: "none" /* to avoid accidental page navigation */,
  backgroundColor: "$accent1",
  paddingTop: 0,
  paddingBottom:
    "calc(60px + env(safe-area-inset-bottom))" /* To account for the height of the Portal Navigator on Mobile*/,
  "@desktop_sm": {
    paddingTop: "$sizes$navHeight",
    paddingBottom: 0,
  },
  "@media print": {
    backgroundColor: "transparent",
    paddingTop: 0,
  },
});

export default function HostPortal() {
  const firebaseContext = useContext(FirebaseContext);

  const permissions = useSelector((state) => state.hostPortal.hostInfo.permissions);
  const { userInfo } = useSelector((state) => state.login);
  const hostInfo = useSelector((state) => state.hostPortal.hostInfo);

  const dispatch = useDispatch();

  const [showReferral, setShowReferral] = useState(false);
  const [confirmModuleUnmount, setConfirmModuleUnmount] = useState(false);

  const getGodModeToken = trpc.portal.getGodModeToken.useMutation();

  const auth = useAuth();
  const user = useMaybeUser();

  const getPermission = useCallback(
    (permissionKey) => {
      if (permissionKey === "admin") {
        return user?.isAdmin;
      }
      if (
        user &&
        user.phoneNumber &&
        permissions &&
        user.phoneNumber in permissions &&
        permissionKey in permissions[user.phoneNumber]
      ) {
        return permissions[user.phoneNumber][permissionKey];
      }
      return true;
    },
    [permissions, user]
  );

  const godModeSwitchAccounts = useCallback(
    async (hostId) => {
      const data = await getGodModeToken.mutateAsync.call(undefined, { hostId });
      firebaseContext.api.disconnectFromChefInfo(user?.chefId);
      await auth.logout();

      return firebase
        .auth()
        .signInWithCustomToken(data.firebaseToken)
        .then((userCredential) => {
          if (userCredential.user) {
            loadUserInfoSuccess(dispatch, data.userInfo);
          }
        });
    },
    [auth, dispatch, firebaseContext.api, getGodModeToken.mutateAsync, user?.chefId]
  );

  const prevUser = usePrevious(user);

  // Identify when we have a new phone number
  useEffect(() => {
    if (prevUser?.phoneNumber !== user?.phoneNumber && user?.phoneNumber && user?.chefId) {
      pushLoggerContext({ phone: user.phoneNumber, chefId: user.chefId });
      logger.debug("Identifying with phone number.");

      identify(user.phoneNumber, {
        hostId: user.chefId,
        email: userInfo.email || hostInfo.email,
        instagram: userInfo.instaHandle || hostInfo.instaHandle,
        firstName: userInfo.firstName || "",
        lastName: userInfo.lastName || "",
        name: userInfo.name || hostInfo.hostName,
        phone: user.phoneNumber,
      });
      Highlight.identify(user.phoneNumber, { id: user.chefId });
      Cohere.identify(user.phoneNumber, { displayName: user.chefId });
      Sentry.setUser({ id: user.phoneNumber, hostId: user.chefId });

      return () => {
        popLoggerContext();
      };
    }
  }, [userInfo, hostInfo, user?.chefId, prevUser?.phoneNumber, user?.phoneNumber]);

  useEffect(() => {
    if (user?.chefId) {
      logger.debug("Connecting to chef info.");
      const hostId = user.chefId;
      setPortalAnalyticsChefId(hostId);
      firebaseContext.api.connectToChefInfo(hostId);

      return () => {
        logger.debug("Disconnecting from chef info.");
        setPortalAnalyticsChefId(undefined);
        firebaseContext.api.disconnectFromChefInfo(user.chefId);
      };
    }
  }, [firebaseContext, user?.chefId]);

  const permissionNavigate = getPermission("events") ? (
    <Navigate to="/portal/events" replace />
  ) : getPermission("orders") ? (
    <Navigate to="/portal/orders" replace />
  ) : getPermission("transactions") ? (
    <Navigate to="/portal/transactions" replace />
  ) : getPermission("accountSettings") ? (
    <Navigate to="/portal/settings" replace />
  ) : getPermission("billing") ? (
    <Navigate to="/portal/billing" replace />
  ) : (
    <Navigate to="/portal/feedback" replace />
  );

  return (
    <ConfirmContext.Provider value={confirmModuleUnmount}>
      <PortalRoot id="portal-root">
        <VersionChecker />
        <ConnectionStatusMonitor />
        {user?.chefId ? (
          <>
            <PortalNav getPermission={getPermission} showReferral={() => setShowReferral(true)} />

            <PortalBody>
              <SentryRoutes>
                <Route index element={permissionNavigate} />
                <Route
                  path="insights"
                  element={getPermission("transactions") ? <Insights /> : permissionNavigate}
                />
                <Route
                  path="customers"
                  element={getPermission("transactions") ? <Customers /> : permissionNavigate}
                />
                <Route
                  path="orders"
                  element={getPermission("orders") ? <OrderManagement /> : permissionNavigate}
                />
                <Route
                  path="events"
                  element={
                    getPermission("events") ? (
                      <Events setConfirmModuleUnmount={setConfirmModuleUnmount} />
                    ) : (
                      permissionNavigate
                    )
                  }
                />
                <Route
                  path="transactions"
                  element={getPermission("transactions") ? <Payment /> : permissionNavigate}
                />
                <Route
                  path="admin"
                  element={
                    getPermission("admin") ? (
                      <GodMode godModeSwitchAccounts={godModeSwitchAccounts} />
                    ) : (
                      <NotFound />
                    )
                  }
                />
                <Route
                  path="settings"
                  element={
                    getPermission("accountSettings") ? (
                      <AccountSettings setConfirmModuleUnmount={setConfirmModuleUnmount} />
                    ) : (
                      permissionNavigate
                    )
                  }
                />
                <Route
                  path="billing"
                  element={getPermission("billing") ? <Billing /> : permissionNavigate}
                />
                <Route path="feedback/*" element={<Feedback />} />
                <Route path="community/*" element={<PeerBoard />} />
                <Route path="*" element={<NotFound />} />
              </SentryRoutes>
            </PortalBody>
          </>
        ) : (
          <Login
            callback={() => {
              // unused because we rely on PhoneLogin to update Redux, which will cause the above useEffects to run
            }}
            headerBlurb="Sign in to view your Chef Portal!"
            close={() => {
              // do nothing
            }}
            errorMessage={""}
            isPortal={true}
          />
        )}

        {/* Referral Modal */}
        <NewModal
          isOpen={showReferral}
          onRequestClose={() => setShowReferral(false)}
          title="Get $30 for every chef you refer"
          desc="Invite a chef to Hotplate and both of you will get $30 added to your account when they make their first sale."
          condensed
        >
          <Referral />
        </NewModal>
      </PortalRoot>
    </ConfirmContext.Provider>
  );
}
