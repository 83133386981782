// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./LocationBadge.RemoveButton.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, IconPrimitive } from "../_internal_primitives";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      CLjibXhBs8: {
        none: {
          icon: icons.close,
          useMask: true,
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const RemoveButtonContext = createContext(null);

function RemoveButton(_props) {
  const defaults = useContext(RemoveButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="XVcxrJpbri"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "XVcxrJpbri",
        scope: "MhBmEhPVST",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <IconPrimitive
          id={"Icon_CLjibXhBs8"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_MhBmEhPVST_CLjibXhBs8"
          }
          key={"CLjibXhBs8"}
          useMask={getStyle("CLjibXhBs8", "useMask")}
          src={getStyle("CLjibXhBs8", "icon")}
        />
      )}
    </RootPrimitive>
  );
}

RemoveButton.__variants = [];

export default RemoveButton;
