/* eslint-disable react/prop-types */
import React, { useState, useReducer } from "react";
import { toFixed, priceInputIsValid } from "@hotplate/utils-ts/helperFunctions";
import ToolTip from "../../../../hotplate-common/ToolTip";
import AddEditModal from "../../../components/AddEditModal";

import { useBreakpoint } from "../../../../visly";
import { Input, Select } from "../../../../visly/Primitives";
import { EditOptionCategory } from "../../../../visly/Events";
import { mergeDiff, removeNulls } from "../../../../hooks";
import Modal from "../../../../hotplate-common/Modal";
import {
  trackOptionGroupCreated,
  trackOptionGroupUpdated,
  trackOptionInventoryUpdated,
} from "../analytics";
import _ from "lodash";
import { trackQuantityOnOptionsChanged } from "../../../analytics";

export default function AddEditOptionCategoryModal({
  menuItemTitle,
  isNewOptionCategory,
  initialOptionCategoryDiff,
  optionCategory,
  onRequestClose,
  onRequestSave,
  eventId,
  eventStatus,
}) {
  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);
  const [optionSelectModalState, setOptionSelectModalState] = useState(null);
  const [optionCategoryDiff, dispatchOptionCategoryDiff] = useReducer(
    optionCategoryDiffReducer,
    initialOptionCategoryDiff || {}
  );
  const [debounce] = useState(() => {
    return _.debounce(
      (func) => {
        func();
      },
      2000,
      { leading: false, trailing: true }
    );
  });

  // Merge prop with diff state
  const mergedOptionCategory = removeNulls(mergeDiff(optionCategory, optionCategoryDiff));

  function optionCategoryDiffReducer(state, action) {
    const mergedOptionCategory = removeNulls(mergeDiff(optionCategory, state));

    const newOptions = [
      ...(Array.isArray(state.options) /* && state.options.length */
        ? state.options
        : optionCategory.options || []),
    ]; // snapshot

    if (action.type === "setTitle") {
      return {
        ...state,
        title: action.payload,
      };
    } else if (action.type === "toggleIsOptionSelectionNumerical") {
      return {
        ...state,
        isOptionSelectionNumerical: !state.isOptionSelectionNumerical,
      };
    } else if (action.type === "setCategoryType") {
      return {
        ...state,
        categoryType: action.payload,
      };
    } else if (action.type === "setMinimumSelections") {
      if (
        action.consistent &&
        action.payload &&
        action.payload > mergedOptionCategory.maximumSelections
      ) {
        return {
          ...state,
          minimumSelections: action.payload,
          maximumSelections: action.payload,
        };
      }
      return {
        ...state,
        minimumSelections: action.payload,
      };
    } else if (action.type === "setMaximumSelections") {
      if (
        action.consistent &&
        action.payload &&
        action.payload < mergedOptionCategory.minimumSelections
      ) {
        return {
          ...state,
          minimumSelections: action.payload,
          maximumSelections: action.payload,
        };
      }
      return {
        ...state,
        maximumSelections: action.payload,
      };
    } else if (action.type === "toggleShowOptionInventory") {
      return {
        ...state,
        showOptionInventory: !state.showOptionInventory,
      };
    } else if (action.type === "pushOption") {
      return {
        ...state,
        options: [
          ...newOptions, // snapshot
          action.payload,
        ],
      };
    } else if (action.type === "setOptionTitle") {
      newOptions[action.index] = {
        ...newOptions[action.index],
        title: action.payload,
      };
      return {
        ...state,
        options: newOptions, // snapshot
      };
    } else if (action.type === "setOptionPrice") {
      newOptions[action.index] = {
        ...newOptions[action.index],
        price: action.payload,
      };
      return {
        ...state,
        options: newOptions, // snapshot
      };
    } else if (action.type === "setOptionInventory") {
      newOptions[action.index] = {
        ...newOptions[action.index],
        inventory: action.payload,
      };
      return {
        ...state,
        options: newOptions, // snapshot
      };
    } else if (action.type === "removeOption") {
      newOptions.splice(action.index, 1);
      return {
        ...state,
        options: newOptions, // snapshot
      };
    } else {
      throw new Error(`Invalid optionCategoryDiffReducer action type: ${action.type}`);
    }
  }

  function setOptionCategoryTitle(value) {
    dispatchOptionCategoryDiff({ type: "setTitle", payload: value });
  }

  function setOptionCategoryType(value) {
    dispatchOptionCategoryDiff({
      type: "setCategoryType",
      payload: value,
    });
  }

  function setOptionCategoryMinimumSelections(value, consistent = true) {
    dispatchOptionCategoryDiff({
      type: "setMinimumSelections",
      payload: value === "" || value === undefined ? null : Math.max(0, parseInt(value)),
      consistent: consistent,
    });
  }

  function setOptionCategoryMaximumSelections(value, consistent = true) {
    dispatchOptionCategoryDiff({
      type: "setMaximumSelections",
      payload: value === "" || value === undefined ? null : Math.max(1, parseInt(value)),
      consistent: consistent,
    });
  }

  function toggleOptionCategoryShowOptionInventory() {
    dispatchOptionCategoryDiff({
      type: "toggleShowOptionInventory",
    });
  }

  function toggleIsOptionSelectionNumerical() {
    trackQuantityOnOptionsChanged();
    dispatchOptionCategoryDiff({
      type: "toggleIsOptionSelectionNumerical",
    });
  }

  function setOptionTitle(value, optionIndex) {
    dispatchOptionCategoryDiff({
      type: "setOptionTitle",
      payload: value,
      index: optionIndex,
    });
  }

  function setOptionPrice(priceProp, optionIndex) {
    const isNegative = (priceProp.match(/-/g) || []).length % 2 == 1;
    const price = priceProp.replace(/-/g, "");
    if (!priceInputIsValid(price)) return;
    dispatchOptionCategoryDiff({
      type: "setOptionPrice",
      payload: (isNegative ? "-" : "") + price,
      index: optionIndex,
    });
  }

  function setOptionInventory(inventory, optionIndex) {
    if (!/^[0-9]+$/.test(inventory) && inventory !== "") return;
    dispatchOptionCategoryDiff({
      type: "setOptionInventory",
      payload: inventory !== "" ? parseInt(inventory) : inventory,
      index: optionIndex,
    });
    debounce(() => {
      trackOptionInventoryUpdated({
        eventId,
        eventStatus,
        inventory,
      });
    });
  }

  function addNewOption() {
    dispatchOptionCategoryDiff({
      type: "pushOption",
      payload: {
        title: "",
        price: "",
        inventory: "",
      },
    });
  }

  function removeOption(optionIndex) {
    dispatchOptionCategoryDiff({
      type: "removeOption",
      index: optionIndex,
    });
  }

  return (
    <Modal
      closeModal={onRequestClose}
      navText={"Menu Item"}
      optionSelectModalState={optionSelectModalState}
    >
      <AddEditModal
        cancelButtonOnClick={onRequestClose}
        saveButtonOnClick={() => {
          onRequestSave(optionCategoryDiff);
          isNewOptionCategory
            ? trackOptionGroupCreated({
                eventId: eventId,
                eventStatus: eventStatus,
                optionGroup: optionCategoryDiff,
              })
            : trackOptionGroupUpdated({
                eventId: eventId,
                eventStatus: eventStatus,
                optionGroup: optionCategoryDiff,
              });
        }}
        deleteButtonOnClick={() => {
          onRequestSave(null);
        }}
        deleteMessage={`This will permanently remove this Option Group from ${menuItemTitle}. Are you sure?`}
        deleteButtonLoading={false}
        saveDisabled={
          mergedOptionCategory.title === "" ||
          !Array.isArray(mergedOptionCategory.options) ||
          mergedOptionCategory.options.length === 0 ||
          mergedOptionCategory.options.filter((option) => {
            return option.title === "";
          }).length > 0 ||
          mergedOptionCategory.minimumSelections === undefined ||
          mergedOptionCategory.maximumSelections === undefined
        }
        title={isNewOptionCategory ? "Create an Option Group" : mergedOptionCategory.title}
        navText={`${menuItemTitle}`}
        editing={!isNewOptionCategory}
        setOptionSelectModalState={(message, optionSelectButtons) => {
          setOptionSelectModalState({
            message,
            optionSelectButtons,
            closeOptionSelectModal: () => setOptionSelectModalState(null),
          });
        }}
      >
        <EditOptionCategory
          size={size}
          Title={
            <EditOptionCategory.Title
              Input={
                <Input
                  style={{ width: "100%" }}
                  value={mergedOptionCategory.title}
                  inputProps={{ required: true }}
                  onChange={(val) => setOptionCategoryTitle(val)}
                  placeholder="Choose protein, add toppings, etc."
                />
              }
            />
          }
          AddOptionButton={
            <>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  marginBottom: "24px",
                }}
              >
                <EditOptionCategory.SetInventoryCheckbox
                  checked={mergedOptionCategory.isOptionSelectionNumerical}
                  onChange={toggleIsOptionSelectionNumerical}
                />
                <text
                  style={{
                    color: "#233434",
                    textAlign: "start",
                    whiteSpace: "normal",
                    fontFamily: "Avenir, sans-serif",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "19px",
                    marginLeft: "8px",
                  }}
                >
                  Can customers increment quantity of each option?
                </text>
                <ToolTip
                  placement="center"
                  // TODO: add image to tooltip
                  text={
                    "A plus and minus will be shown next to each option so that customers can select multiple of each one."
                  }
                />
              </div>
              <EditOptionCategory.AddOptionButton onClick={() => addNewOption()} />
            </>
          }
          RequiredOptions={
            <EditOptionCategory.RequiredOptions
              Input={
                mergedOptionCategory.isOptionSelectionNumerical ? (
                  <Input
                    style={{ width: "100%" }}
                    value={mergedOptionCategory.minimumSelections}
                    inputProps={{ required: true }}
                    onChange={(val) => {
                      if (val === "" || !isNaN(parseInt(val))) {
                        setOptionCategoryMinimumSelections(val, false);
                      }
                    }}
                    onBlur={() => {
                      setOptionCategoryMinimumSelections(
                        mergedOptionCategory.minimumSelections,
                        true
                      );
                    }}
                    placeholder="0"
                  />
                ) : (
                  <Select
                    style={{ width: "100%" }}
                    label={mergedOptionCategory.minimumSelections}
                    selectedKey={mergedOptionCategory.minimumSelections}
                    onSelectionChange={(val) => setOptionCategoryMinimumSelections(val)}
                  >
                    {Array.isArray(mergedOptionCategory.options) &&
                      Array.from(
                        {
                          length: mergedOptionCategory.options.length + 1,
                        },
                        (_, id) => id
                      ).map((o) => <Select.Option key={parseInt(o)} label={o} />)}
                  </Select>
                )
              }
              tooltip={
                <ToolTip
                  placement="center"
                  text={"The number of above options customers are required to select"}
                />
              }
            />
          }
          MaximumOptions={
            <EditOptionCategory.MaximumOptions
              Input={
                mergedOptionCategory.isOptionSelectionNumerical ? (
                  <Input
                    style={{ width: "100%" }}
                    value={mergedOptionCategory.maximumSelections}
                    inputProps={{ required: true }}
                    onChange={(val) => {
                      if (val === "" || !isNaN(parseInt(val))) {
                        setOptionCategoryMaximumSelections(val, false);
                      }
                    }}
                    onBlur={() => {
                      setOptionCategoryMaximumSelections(
                        mergedOptionCategory.maximumSelections,
                        true
                      );
                    }}
                    placeholder="999"
                  />
                ) : (
                  <Select
                    style={{ width: "100%" }}
                    label={mergedOptionCategory.maximumSelections}
                    selectedKey={mergedOptionCategory.maximumSelections}
                    onSelectionChange={(val) => setOptionCategoryMaximumSelections(val)}
                  >
                    {Array.isArray(mergedOptionCategory.options) &&
                      Array.from(
                        { length: mergedOptionCategory.options.length },
                        (_, id) => id + 1
                      ).map((o) => <Select.Option key={parseInt(o)} label={o} />)}
                  </Select>
                )
              }
              tooltip={<ToolTip text={"The maximum number of options customers can select"} />}
            />
          }
          SetVariationCheckbox={
            <EditOptionCategory.SetVariationCheckbox
              checked={mergedOptionCategory.categoryType === "variation"}
              onChange={() =>
                setOptionCategoryType(
                  mergedOptionCategory.categoryType === "variation" ? "modification" : "variation"
                )
              }
            />
          }
          setVariationText={`Show ${mergedOptionCategory.title} on my Prep screen`}
          setVariationTooltip={
            <ToolTip
              text={
                "Breaks out this Option Group into different counts on your prep screen.\n\nThis is best when you have required options that change the item substantially (e.g. Chocolate, Blueberry, or Banana muffin)"
              }
            />
          }
          SetInventoryCheckbox={
            <EditOptionCategory.SetInventoryCheckbox
              checked={mergedOptionCategory.showOptionInventory}
              onChange={toggleOptionCategoryShowOptionInventory}
            />
          }
          setInventoryTooltip={
            <ToolTip
              text={`Set quantity for the options you defined above. These options can sell out in the same way items sell out. \n\n If this Option Group is required (set in Required Options, below this), the item will sell out when these options sell out`}
            />
          }
          OptionsContainer={
            <EditOptionCategory.OptionsContainer
              showQuantity={mergedOptionCategory.showOptionInventory}
            >
              {Array.isArray(mergedOptionCategory.options) &&
                mergedOptionCategory.options.map((option, optionIndex) => (
                  <EditOptionCategory.OptionsContainer.Option
                    key={optionIndex}
                    TitleInput={
                      <EditOptionCategory.OptionsContainer.Option.TitleInput
                        value={option.title}
                        inputProps={{ required: true }}
                        onChange={(val) => setOptionTitle(val, optionIndex)}
                      />
                    }
                    PriceInput={
                      <EditOptionCategory.OptionsContainer.Option.PriceInput
                        value={option.price}
                        inputProps={{ step: "0.01", required: true }}
                        onChange={(val) => setOptionPrice(val, optionIndex)}
                        onBlur={() =>
                          setOptionPrice(toFixed(parseFloat(option.price), 2), optionIndex)
                        }
                      />
                    }
                    QuantityInput={
                      <EditOptionCategory.OptionsContainer.Option.QuantityInput
                        value={option.inventory}
                        onChange={(val) => setOptionInventory(val, optionIndex)}
                        placeholder="∞"
                      />
                    }
                    DeleteButton={
                      <EditOptionCategory.OptionsContainer.Option.DeleteButton
                        onClick={() => {
                          removeOption(optionIndex);
                        }}
                      />
                    }
                    showQuantity={mergedOptionCategory.showOptionInventory}
                  />
                ))}
            </EditOptionCategory.OptionsContainer>
          }
        />
      </AddEditModal>
    </Modal>
  );
}
