// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import component_0 from "./ConfirmationHeader";
import component_1 from "./ConfirmationHeader.OrderNum";
import component_2 from "./DeliverySummary";
import component_3 from "./DirectionsButton";
import component_4 from "./Instructions";
import component_5 from "./OrderSummary";
import component_6 from "./PickupTime";
import component_7 from "./PickupTime.SplitOrder";
import component_8 from "./ReceiptLineItem";
export const ConfirmationHeader = component_0;
export const DeliverySummary = component_2;
export const DirectionsButton = component_3;
export const Instructions = component_4;
export const OrderSummary = component_5;
export const PickupTime = component_6;
export const ReceiptLineItem = component_8;
ConfirmationHeader.OrderNum = component_1;
PickupTime.SplitOrder = component_7;
