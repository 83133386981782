import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useMatch, useNavigate } from "react-router-dom";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import * as Sentry from "@sentry/react";
import {
  setCountdown as setCountdownUnconnected,
  setToastPopupInfo as setToastPopupInfoUnconnected,
  setCartId as setCartIdUnconnected,
} from "../actions";
import Checkout from "./Checkout";
import CustomerSubscriptions from "../CustomerSubscriptions";
import { trackCheckoutCountdownExpired } from "../analytics";
import { getEventStatus } from "@hotplate/utils-ts/helperFunctions";
import { useFirebaseConnection, usePrevious } from "../../hooks";
import { NotFound } from "../../hotplate-common/NotFound";
import { logger, popLoggerContext, pushLoggerContext } from "../../logger";
import { NewStorefront } from "./NewStorefront";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function useConnectedFn(fn, dispatch) {
  return useCallback(
    (...args) => {
      return fn(...args)(dispatch);
    },
    [fn, dispatch]
  );
}

/**
 * Primarily responsible for managing cart state, which is shared between the
 * Storefront and Checkout components.
 */
export default function Shop() {
  const { cartId, countdown } = useSelector((state) => state.cart);
  const siteSettings = useSelector((state) => state.storefront.siteSettings);
  const dispatch = useDispatch();
  const setCountdown = useConnectedFn(setCountdownUnconnected, dispatch);
  const setToastPopupInfo = useConnectedFn(setToastPopupInfoUnconnected, dispatch);
  const setCartId = useConnectedFn(setCartIdUnconnected, dispatch);

  const navigate = useNavigate();
  const checkoutPathMatch = useMatch("checkout");

  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);

  const { data: cart, isLoading: isCartConnectionLoading } = useFirebaseConnection(
    { ref: `/checkoutV2/carts/${cartId}` },
    { isEnabled: !!cartId }
  );

  // Connect to cartEvent once we have a cart
  const { data: cartEventDb, isLoading: isCartEventLoading } = useFirebaseConnection(
    {
      ref: `/hosts/${cart?.chefId}/events/${cart?.eventId}`,
    },
    {
      isEnabled: !!(cart?.chefId && cart?.eventId),
      throttleArgs: {
        wait: 1000,
        options: {
          leading: true,
          trailing: true,
        },
      },
    }
  );
  const cartEvent = cart?.chefId && cart?.eventId && !isCartEventLoading ? cartEventDb : undefined;

  const prevCartId = usePrevious(cartId);
  // TODO ideally isCartStuffLoading should be true when addItemToCart has received a successful response but the change in the database
  // has not been seen yet. That case is currently not covered and is a flaw.
  const isCartStuffLoading =
    !cartId ||
    prevCartId !== cartId || // cartId has just changed, but current cart state has not been cleaned up to become undefined yet
    isCartConnectionLoading ||
    (cart !== null && cartEvent === undefined) ||
    cart?.eventId !== cartEvent?.id; // The cartEvent may be stale for one render before the useEffect cleanup sets it to undefined.

  useEffect(() => {
    pushLoggerContext({ cartId });
    return () => {
      popLoggerContext();
    };
  }, [cartId]);

  useEffect(() => {
    Sentry.setContext("shop", { cartId, cart });
  }, [cartId, cart]);

  // Update cart countdown timer
  // WARNING: Every time cartItems changes, the interval will be reset.
  useEffect(() => {
    if (
      Array.isArray(cart?.cartItems) && // this also verifies that cart is not falsey
      cart.cartItems.length !== 0 &&
      cart.checkoutTime !== undefined
    ) {
      setCountdown(
        Math.floor(
          Math.max(0, (cart.checkoutTime - new Date().getTime() + cart.countdownStart) / 1000)
        )
      );
      const timerId = setInterval(() => {
        setCountdown(
          Math.floor(
            Math.max(0, (cart.checkoutTime - new Date().getTime() + cart.countdownStart) / 1000)
          )
        );
      }, 1000);
      return () => {
        clearInterval(timerId);
        setCountdown(undefined);
      };
    }
  }, [cart?.cartItems, cart?.countdownStart, cart?.checkoutTime, setCountdown]);

  // Check if the cart was cleared
  const prevCart = usePrevious(cart);
  useEffect(() => {
    if (prevCart && cart === null) {
      logger.debug("Cart became null.");

      if (countdown < 5) {
        // The cart was probably cleared due to expiration
        setToastPopupInfo({
          text: "Your cart has expired!",
          type: "error",
        });
        trackCheckoutCountdownExpired(cartId, prevCart.eventId); // TODO move this into backend
      } else {
        // The cart was probably deleted for a different reason, likely frontend-initiated
      }

      setCartId(uuid());

      if (checkoutPathMatch) {
        logger.debug("Kicking user from checkout.");
        navigate(`/${siteSettings.chefId}?event=${prevCart.eventId.substring(0, 5)}`);
      }
    }
  });

  // If the cart's event becomes no longer live, abandon the cart
  const prevCartEvent = usePrevious(cartEvent);
  useEffect(() => {
    if (
      !isCartStuffLoading &&
      prevCartEvent &&
      getEventStatus(prevCartEvent) === "live" &&
      cartEvent &&
      getEventStatus(cartEvent) !== "live" &&
      cart?.cartItems.length > 0 &&
      !isPaymentProcessing
    ) {
      logger.debug("cartEvent status is no longer live.");

      setToastPopupInfo({
        text: "Your cart's event has closed!",
        type: "error",
      });
      setCartId(uuid());

      if (checkoutPathMatch) {
        logger.debug("Kicking user from checkout.");
        navigate("/" + siteSettings.chefId);
      }
    }
  });

  if (!cartId) {
    setCartId(uuid());
  }

  return (
    <SentryRoutes>
      <Route
        path=":chefId/*"
        element={
          <NewStorefront
            cart={cart}
            cartEvent={cartEvent}
            isCartStuffLoading={isCartStuffLoading}
          />
        }
      />
      <Route
        path="checkout"
        element={
          <Checkout
            cart={cart}
            cartEvent={cartEvent}
            isCartStuffLoading={isCartStuffLoading}
            isPaymentProcessing={isPaymentProcessing}
            setIsPaymentProcessing={setIsPaymentProcessing}
          />
        }
      />
      <Route path="*" element={<NotFound />} />
    </SentryRoutes>
  );
}
