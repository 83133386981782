import _ from "lodash";
import {
  GET_GOD_MODE_OPTIONS_START,
  GET_GOD_MODE_OPTIONS_SUCCESS,
  GET_GOD_MODE_OPTIONS_FAILURE,
  CONNECT_TO_CHEF_INFO_START,
  CONNECT_TO_CHEF_INFO_SUCCESS,
  CONNECT_TO_CHEF_INFO_FAILURE,
  DISCONNECT_FROM_CHEF_INFO_START,
  DISCONNECT_FROM_CHEF_INFO_SUCCESS,
  DISCONNECT_FROM_CHEF_INFO_FAILURE,
  SET_TOAST_POPUP_INFO,
  CONNECT_TO_CHEF_INFO_MEMBERSHIPS_SUCCESS,
  CONNECT_TO_CHEF_INFO_LOCATIONS_SUCCESS,
  CONNECT_TO_CHEF_INFO_MENU_ITEMS_SUCCESS,
  CONNECT_TO_CHEF_INFO_HOST_PHONE_SUCCESS,
  CONNECT_TO_CHEF_INFO_EMAIL_SUCCESS,
  CONNECT_TO_CHEF_INFO_INSTA_SUCCESS,
  CONNECT_TO_CHEF_INFO_PERMISSIONS_START,
  CONNECT_TO_CHEF_INFO_PERMISSIONS_SUCCESS,
  CONNECT_TO_CHEF_INFO_PERMISSIONS_FAILURE,
  CONNECT_TO_CHEF_INFO_SITE_SETTINGS_START,
  CONNECT_TO_CHEF_INFO_SITE_SETTINGS_SUCCESS,
  CONNECT_TO_CHEF_INFO_SITE_SETTINGS_FAILURE,
  CONNECT_TO_CHEF_INFO_ROUTABLE_INFO_START,
  CONNECT_TO_CHEF_INFO_ROUTABLE_INFO_SUCCESS,
  CONNECT_TO_CHEF_INFO_ROUTABLE_INFO_FAILURE,
  CONNECT_TO_CHEF_INFO_CUSTOMER_SUBSCRIPTION_ID_SUCCESS,
  CONNECT_TO_CHEF_INFO_DISCOUNT_CODES_SUCCESS,
} from "../actions/types";

const INITIAL_STATE = {
  hostInfo: {
    customerSubscriptionId: null,
    events: {},
    email: "",
    instaHandle: "",
    locations: {},
    menuItems: {},
    siteSettings: {},
    routableInfo: {},
    permissions: {},
    discountCodes: {},
    memberships: {},
  },

  isOnline: true,
  toastPopupInfo: null,

  connectToChefInfoLoading: false,
  connectToChefInfoError: "",
  connectToChefInfoPermissionsLoading: false,
  connectToChefInfoPermissionsError: "",
  connectToChefInfoSiteSettingsLoading: false,
  connectToChefInfoSiteSettingsError: "",
  connectToChefInfoRoutableInfoLoading: true, // starts at true for componentDidMount logic in Payment component
  connectToChefInfoRoutableInfoError: "",

  disconnectFromChefInfoLoading: false,
  disconnectFromChefInfoError: "",

  godModeOptions: [],
  godModeOptionsLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_TOAST_POPUP_INFO:
      return { ...state, toastPopupInfo: action.payload };

    case GET_GOD_MODE_OPTIONS_START:
      return { ...state, godModeOptionsLoading: true };
    case GET_GOD_MODE_OPTIONS_SUCCESS:
      return {
        ...state,
        godModeOptionsLoading: false,
        godModeOptions: action.payload,
      };
    case GET_GOD_MODE_OPTIONS_FAILURE:
      return { ...state, godModeOptionsLoading: false };

    case CONNECT_TO_CHEF_INFO_START:
      return {
        ...state,
        connectToChefInfoLoading: true,
        connectToChefInfoError: "",
      };
    case CONNECT_TO_CHEF_INFO_SUCCESS:
      return { ...state, connectToChefInfoLoading: false };
    case CONNECT_TO_CHEF_INFO_FAILURE:
      return {
        ...state,
        connectToChefInfoLoading: false,
        connectToChefInfoError: action.payload,
      };
    case DISCONNECT_FROM_CHEF_INFO_START:
      return {
        ...state,
        disconnectToChefInfoLoading: true,
        disconnectToChefInfoError: "",
      };
    case DISCONNECT_FROM_CHEF_INFO_SUCCESS:
      return { ...state, disconnectToChefInfoLoading: false };
    case DISCONNECT_FROM_CHEF_INFO_FAILURE:
      return {
        ...state,
        disconnectToChefInfoLoading: false,
        disconnectToChefInfoError: action.payload,
      };
    default:
      return state;
    case CONNECT_TO_CHEF_INFO_MEMBERSHIPS_SUCCESS: {
      const newHostInfoMemberships = _.cloneDeep(state.hostInfo);
      newHostInfoMemberships.memberships = action.payload ? action.payload : {};
      return { ...state, hostInfo: newHostInfoMemberships };
    }
    case CONNECT_TO_CHEF_INFO_LOCATIONS_SUCCESS: {
      const newHostInfoLocations = _.cloneDeep(state.hostInfo);
      newHostInfoLocations.locations = action.payload ? action.payload : {};
      return { ...state, hostInfo: newHostInfoLocations };
    }
    case CONNECT_TO_CHEF_INFO_HOST_PHONE_SUCCESS: {
      const newHostInfoHostPhone = _.cloneDeep(state.hostInfo);
      newHostInfoHostPhone.hostPhone = action.payload ? action.payload : {};
      return { ...state, hostInfo: newHostInfoHostPhone };
    }
    case CONNECT_TO_CHEF_INFO_EMAIL_SUCCESS: {
      const newHostInfoEmail = _.cloneDeep(state.hostInfo);
      newHostInfoEmail.email = action.payload ? action.payload : {};
      return { ...state, hostInfo: newHostInfoEmail };
    }
    case CONNECT_TO_CHEF_INFO_INSTA_SUCCESS: {
      const newHostInfoInsta = _.cloneDeep(state.hostInfo);
      newHostInfoInsta.instaHandle = action.payload ? action.payload : {};
      return { ...state, hostInfo: newHostInfoInsta };
    }
    case CONNECT_TO_CHEF_INFO_MENU_ITEMS_SUCCESS: {
      const newHostInfoMenuItems = _.cloneDeep(state.hostInfo);
      newHostInfoMenuItems.menuItems = action.payload ? action.payload : {};
      return { ...state, hostInfo: newHostInfoMenuItems };
    }
    case CONNECT_TO_CHEF_INFO_PERMISSIONS_START: {
      return {
        ...state,
        connectToChefInfoPermissionsLoading: true,
        connectToChefInfoPermissionsError: "",
      };
    }
    case CONNECT_TO_CHEF_INFO_PERMISSIONS_SUCCESS: {
      const newHostInfoPermissions = _.cloneDeep(state.hostInfo);
      newHostInfoPermissions.permissions = action.payload ? action.payload : {};
      return {
        ...state,
        hostInfo: newHostInfoPermissions,
        connectToChefInfoPermissionsLoading: false,
      };
    }
    case CONNECT_TO_CHEF_INFO_PERMISSIONS_FAILURE: {
      return {
        ...state,
        connectToChefInfoPermissionsLoading: false,
        connectToChefInfoPermissionsError: action.payload,
      };
    }
    case CONNECT_TO_CHEF_INFO_SITE_SETTINGS_START: {
      return {
        ...state,
        connectToChefInfoSiteSettingsLoading: true,
        connectToChefInfoSiteSettingsError: "",
      };
    }
    case CONNECT_TO_CHEF_INFO_SITE_SETTINGS_SUCCESS: {
      const newHostInfoSiteSettings = _.cloneDeep(state.hostInfo);
      newHostInfoSiteSettings.siteSettings = action.payload ? action.payload : {};
      return {
        ...state,
        hostInfo: newHostInfoSiteSettings,
        connectToChefInfoSiteSettingsLoading: false,
      };
    }
    case CONNECT_TO_CHEF_INFO_SITE_SETTINGS_FAILURE: {
      return {
        ...state,
        connectToChefInfoSiteSettingsLoading: false,
        connectToChefInfoSiteSettingsError: action.payload,
      };
    }
    case CONNECT_TO_CHEF_INFO_ROUTABLE_INFO_START: {
      return {
        ...state,
        connectToChefInfoRoutableInfoLoading: true,
        connectToChefInfoRoutableInfoError: "",
      };
    }
    case CONNECT_TO_CHEF_INFO_ROUTABLE_INFO_SUCCESS: {
      const newHostInfoRoutableInfo = _.cloneDeep(state.hostInfo);
      newHostInfoRoutableInfo.routableInfo = action.payload ? action.payload : {};
      return {
        ...state,
        hostInfo: newHostInfoRoutableInfo,
        connectToChefInfoRoutableInfoLoading: false,
      };
    }
    case CONNECT_TO_CHEF_INFO_ROUTABLE_INFO_FAILURE: {
      return {
        ...state,
        connectToChefInfoRoutableInfoLoading: false,
        connectToChefInfoRoutableInfoError: action.payload,
      };
    }
    case CONNECT_TO_CHEF_INFO_CUSTOMER_SUBSCRIPTION_ID_SUCCESS: {
      const newHostInfoSubscriptionId = _.cloneDeep(state.hostInfo);
      newHostInfoSubscriptionId.customerSubscriptionId = action.payload ? action.payload : null;
      return { ...state, hostInfo: newHostInfoSubscriptionId };
    }
    case CONNECT_TO_CHEF_INFO_DISCOUNT_CODES_SUCCESS: {
      return {
        ...state,
        hostInfo: {
          ...state.hostInfo,
          discountCodes: action.payload || {},
        },
      };
    }
  }
};
