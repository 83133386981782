// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./ChefOrderSummary.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import {
  default as ReceiptLineItem,
  default as Subtotal,
  ReceiptLineItemContext as SubtotalContext,
  default as Fee,
  default as CustomerTotal,
  ReceiptLineItemContext as CustomerTotalContext,
  default as HotplateFee,
  ReceiptLineItemContext as HotplateFeeContext,
  default as PpFee,
  ReceiptLineItemContext as PpFeeContext,
  default as Revenue,
  ReceiptLineItemContext as RevenueContext,
  default as ChefHotplateFee,
  ReceiptLineItemContext as ChefHotplateFeeContext,
  default as Refund,
  ReceiptLineItemContext as RefundContext,
} from "../Confirmation/ReceiptLineItem";
import { default as Status } from "../Events/Status";
import {
  default as FulfillmentDate,
  ReceiptFieldContext as FulfillmentDateContext,
  default as FulfillmentAddress,
  ReceiptFieldContext as FulfillmentAddressContext,
} from "../Management/ReceiptField";

const styles = [
  {
    type: "default",
    layers: {
      JE6EMQxSKs: {
        none: {
          text: "Order Summary",
        },
      },
      VF5yBc43Q8: {
        none: {
          text: "#?????",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "isCollab",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      LpskU6sKJR: {},
      NJsTKT7TAw: {
        none: {
          item: "Subtotal",
          fee: true,
        },
      },
      H9mYmjUG4d: {
        none: {
          item: "Tip",
          fee: true,
        },
      },
      RoaNMaZmZ2: {
        none: {
          item: "Customer Total",
          bold: true,
        },
      },
      Kpy6cdJGfG: {
        none: {
          item: "Hotplate Fee",
          fee: true,
        },
      },
      bkL6MqPGXb: {
        none: {
          item: "Payment Processing",
          fee: true,
        },
      },
      QnBe5GCSWW: {
        none: {
          item: "Your Revenue",
          bold: true,
          color: "good",
        },
      },
      TdMQcNczE9: {
        none: {
          type: "pickup",
        },
      },
      sfmbsLnjzu: {
        none: {
          item: "Hotplate Fee",
          fee: true,
        },
      },
      JPNGkemTyW: {
        none: {
          label: "Pickup Date",
          text: "No Date Found",
          type: "first",
        },
      },
      V4Ry1djqgL: {
        none: {
          label: "Pickup Address",
          text: "No Address Found",
          type: "last",
        },
      },
      JjzkDjAT9E: {
        none: {
          item: "Refund Amount",
          bold: true,
          color: "secondary",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "isCollab",
    layers: {
      LpskU6sKJR: {},
      NJsTKT7TAw: {},
      H9mYmjUG4d: {},
      RoaNMaZmZ2: {},
      Kpy6cdJGfG: {},
      bkL6MqPGXb: {},
      QnBe5GCSWW: {
        none: {
          item: "Collab Revenue",
        },
      },
      TdMQcNczE9: {
        none: {
          text: "Collab",
          type: "complete",
        },
      },
      sfmbsLnjzu: {},
      JPNGkemTyW: {},
      V4Ry1djqgL: {},
      JjzkDjAT9E: {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "isCollab",
  },
];

export const ChefOrderSummaryContext = createContext(null);

function ChefOrderSummary(_props) {
  const defaults = useContext(ChefOrderSummaryContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="7HdTXYTxxv"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "7HdTXYTxxv",
        scope: "78WTYEaKAp",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"header-container_RyPaPazQ7u"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_RyPaPazQ7u"
            }
            key={"RyPaPazQ7u"}
            addSpacing={false}
            element={getStyle("RyPaPazQ7u", "htmlElement")}
          >
            <ContainerPrimitive
              id={"header-type-container_FNdoApUTUP"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_FNdoApUTUP"
              }
              key={"FNdoApUTUP"}
              addSpacing={false}
              element={getStyle("FNdoApUTUP", "htmlElement")}
            >
              <ContainerPrimitive
                id={"Container_AcDBx7cpBQ"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_AcDBx7cpBQ"
                }
                key={"AcDBx7cpBQ"}
                addSpacing={false}
                element={getStyle("AcDBx7cpBQ", "htmlElement")}
              >
                <TextPrimitive
                  id={"header_JE6EMQxSKs"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_JE6EMQxSKs"
                  }
                  key={"JE6EMQxSKs"}
                  text={getStyle("JE6EMQxSKs", "text")}
                  element={getStyle("JE6EMQxSKs", "htmlElement")}
                />
                <TextPrimitive
                  id={"orderNum_VF5yBc43Q8"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_VF5yBc43Q8"
                  }
                  key={"VF5yBc43Q8"}
                  text={
                    exists(props.orderNum)
                      ? props.orderNum
                      : getStyle("VF5yBc43Q8", "text")
                  }
                  element={getStyle("VF5yBc43Q8", "htmlElement")}
                />
              </ContainerPrimitive>
              <SpacerPrimitive
                id={"Spacer_Te8N5jQosV"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_Te8N5jQosV"
                }
                key={"Te8N5jQosV"}
              />
              <ContainerPrimitive
                key={"C74YPgru5V"}
                id={"Slot_C74YPgru5V"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_C74YPgru5V"
                }
                addSpacing={false}
              >
                {props.orderType}
              </ContainerPrimitive>
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"order-info-container_7qA2Rw71UM"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_7qA2Rw71UM"
              }
              key={"7qA2Rw71UM"}
              addSpacing={false}
              element={getStyle("7qA2Rw71UM", "htmlElement")}
            >
              {[
                props.FulfillmentDate === undefined ? (
                  <FulfillmentDate
                    key={"JPNGkemTyW"}
                    {...getCompositeDefaultProps("JPNGkemTyW")}
                    id="FulfillmentDate_JPNGkemTyW"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_JPNGkemTyW"
                  />
                ) : (
                  <FulfillmentDateContext.Provider
                    key="JPNGkemTyW-provider"
                    value={{
                      key: "JPNGkemTyW",
                      id: "FulfillmentDate_JPNGkemTyW",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_JPNGkemTyW",
                      ...getCompositeDefaultProps("JPNGkemTyW"),
                    }}
                  >
                    {props.FulfillmentDate}
                  </FulfillmentDateContext.Provider>
                ),
                props.FulfillmentAddress === undefined ? (
                  <FulfillmentAddress
                    key={"V4Ry1djqgL"}
                    {...getCompositeDefaultProps("V4Ry1djqgL")}
                    id="FulfillmentAddress_V4Ry1djqgL"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_V4Ry1djqgL"
                  />
                ) : (
                  <FulfillmentAddressContext.Provider
                    key="V4Ry1djqgL-provider"
                    value={{
                      key: "V4Ry1djqgL",
                      id: "FulfillmentAddress_V4Ry1djqgL",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_V4Ry1djqgL",
                      ...getCompositeDefaultProps("V4Ry1djqgL"),
                    }}
                  >
                    {props.FulfillmentAddress}
                  </FulfillmentAddressContext.Provider>
                ),
              ]}
            </ContainerPrimitive>
          </ContainerPrimitive>
          <ContainerPrimitive
            key={"TcME5noo8F"}
            id={"Slot_TcME5noo8F"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_TcME5noo8F"
            }
            addSpacing={false}
          >
            {props.children}
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"customer-totals-container_WfSEdoYb1A"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_WfSEdoYb1A"
            }
            key={"WfSEdoYb1A"}
            addSpacing={false}
            element={getStyle("WfSEdoYb1A", "htmlElement")}
          >
            {[
              <SpacerPrimitive
                id={"Spacer_K1SbGVGi1w"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_K1SbGVGi1w"
                }
                key={"K1SbGVGi1w"}
              />,
              props.Subtotal === undefined ? (
                <Subtotal
                  key={"NJsTKT7TAw"}
                  {...getCompositeDefaultProps("NJsTKT7TAw")}
                  id="Subtotal_NJsTKT7TAw"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_NJsTKT7TAw"
                />
              ) : (
                <SubtotalContext.Provider
                  key="NJsTKT7TAw-provider"
                  value={{
                    key: "NJsTKT7TAw",
                    id: "Subtotal_NJsTKT7TAw",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_NJsTKT7TAw",
                    ...getCompositeDefaultProps("NJsTKT7TAw"),
                  }}
                >
                  {props.Subtotal}
                </SubtotalContext.Provider>
              ),
              <ContainerPrimitive
                key={"EUYUpSyfbu"}
                id={"Slot_EUYUpSyfbu"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_EUYUpSyfbu"
                }
                addSpacing={false}
              >
                {props.fees}
              </ContainerPrimitive>,
              props.HotplateFee === undefined ? (
                <HotplateFee
                  key={"Kpy6cdJGfG"}
                  {...getCompositeDefaultProps("Kpy6cdJGfG")}
                  id="HotplateFee_Kpy6cdJGfG"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_Kpy6cdJGfG"
                />
              ) : (
                <HotplateFeeContext.Provider
                  key="Kpy6cdJGfG-provider"
                  value={{
                    key: "Kpy6cdJGfG",
                    id: "HotplateFee_Kpy6cdJGfG",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_Kpy6cdJGfG",
                    ...getCompositeDefaultProps("Kpy6cdJGfG"),
                  }}
                >
                  {props.HotplateFee}
                </HotplateFeeContext.Provider>
              ),
              props.Refund === undefined ? (
                <Refund
                  key={"JjzkDjAT9E"}
                  {...getCompositeDefaultProps("JjzkDjAT9E")}
                  id="Refund_JjzkDjAT9E"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_JjzkDjAT9E"
                />
              ) : (
                <RefundContext.Provider
                  key="JjzkDjAT9E-provider"
                  value={{
                    key: "JjzkDjAT9E",
                    id: "Refund_JjzkDjAT9E",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_JjzkDjAT9E",
                    ...getCompositeDefaultProps("JjzkDjAT9E"),
                  }}
                >
                  {props.Refund}
                </RefundContext.Provider>
              ),
              props.CustomerTotal === undefined ? (
                <CustomerTotal
                  key={"RoaNMaZmZ2"}
                  {...getCompositeDefaultProps("RoaNMaZmZ2")}
                  id="CustomerTotal_RoaNMaZmZ2"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_RoaNMaZmZ2"
                />
              ) : (
                <CustomerTotalContext.Provider
                  key="RoaNMaZmZ2-provider"
                  value={{
                    key: "RoaNMaZmZ2",
                    id: "CustomerTotal_RoaNMaZmZ2",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_RoaNMaZmZ2",
                    ...getCompositeDefaultProps("RoaNMaZmZ2"),
                  }}
                >
                  {props.CustomerTotal}
                </CustomerTotalContext.Provider>
              ),
            ]}
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"divider_PCgwNXHksU"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_PCgwNXHksU"
            }
            key={"PCgwNXHksU"}
            addSpacing={false}
            element={getStyle("PCgwNXHksU", "htmlElement")}
          >
            <SpacerPrimitive
              id={"Spacer_U6rMzvBA9E"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_U6rMzvBA9E"
              }
              key={"U6rMzvBA9E"}
            />
            <ContainerPrimitive
              id={"divider_CmvPjqQGVa"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_CmvPjqQGVa"
              }
              key={"CmvPjqQGVa"}
              addSpacing={false}
              element={getStyle("CmvPjqQGVa", "htmlElement")}
            >
              {null}
            </ContainerPrimitive>
            <SpacerPrimitive
              id={"Spacer_78nPyBvzhx"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_78nPyBvzhx"
              }
              key={"78nPyBvzhx"}
            />
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"chef-totals-container_DLqAHZKdFt"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_DLqAHZKdFt"
            }
            key={"DLqAHZKdFt"}
            addSpacing={false}
            element={getStyle("DLqAHZKdFt", "htmlElement")}
          >
            {[
              props.PpFee === undefined ? (
                <PpFee
                  key={"bkL6MqPGXb"}
                  {...getCompositeDefaultProps("bkL6MqPGXb")}
                  id="PpFee_bkL6MqPGXb"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_bkL6MqPGXb"
                />
              ) : (
                <PpFeeContext.Provider
                  key="bkL6MqPGXb-provider"
                  value={{
                    key: "bkL6MqPGXb",
                    id: "PpFee_bkL6MqPGXb",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_bkL6MqPGXb",
                    ...getCompositeDefaultProps("bkL6MqPGXb"),
                  }}
                >
                  {props.PpFee}
                </PpFeeContext.Provider>
              ),
              props.ChefHotplateFee === undefined ? (
                <ChefHotplateFee
                  key={"sfmbsLnjzu"}
                  {...getCompositeDefaultProps("sfmbsLnjzu")}
                  id="ChefHotplateFee_sfmbsLnjzu"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_sfmbsLnjzu"
                />
              ) : (
                <ChefHotplateFeeContext.Provider
                  key="sfmbsLnjzu-provider"
                  value={{
                    key: "sfmbsLnjzu",
                    id: "ChefHotplateFee_sfmbsLnjzu",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_sfmbsLnjzu",
                    ...getCompositeDefaultProps("sfmbsLnjzu"),
                  }}
                >
                  {props.ChefHotplateFee}
                </ChefHotplateFeeContext.Provider>
              ),
              props.Revenue === undefined ? (
                <Revenue
                  key={"QnBe5GCSWW"}
                  {...getCompositeDefaultProps("QnBe5GCSWW")}
                  id="Revenue_QnBe5GCSWW"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_QnBe5GCSWW"
                />
              ) : (
                <RevenueContext.Provider
                  key="QnBe5GCSWW-provider"
                  value={{
                    key: "QnBe5GCSWW",
                    id: "Revenue_QnBe5GCSWW",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_78WTYEaKAp_QnBe5GCSWW",
                    ...getCompositeDefaultProps("QnBe5GCSWW"),
                  }}
                >
                  {props.Revenue}
                </RevenueContext.Provider>
              ),
            ]}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

ChefOrderSummary.ReceiptLineItem = ReceiptLineItem;
ChefOrderSummary.Subtotal = Subtotal;
ChefOrderSummary.Fee = Fee;
ChefOrderSummary.CustomerTotal = CustomerTotal;
ChefOrderSummary.HotplateFee = HotplateFee;
ChefOrderSummary.PpFee = PpFee;
ChefOrderSummary.Revenue = Revenue;
ChefOrderSummary.Status = Status;
ChefOrderSummary.ChefHotplateFee = ChefHotplateFee;
ChefOrderSummary.FulfillmentDate = FulfillmentDate;
ChefOrderSummary.FulfillmentAddress = FulfillmentAddress;
ChefOrderSummary.Refund = Refund;
ChefOrderSummary.__variants = [
  {
    name: "isCollab",
    type: "variant",
  },
];

export default ChefOrderSummary;
