// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./CollabOrderReceipt.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import {
  default as ReceiptLineItem,
  default as Subtotal,
  ReceiptLineItemContext as SubtotalContext,
  default as Fee,
  default as HotplateFee,
  ReceiptLineItemContext as HotplateFeeContext,
  default as Refund,
  ReceiptLineItemContext as RefundContext,
  default as CustomerTotal,
  ReceiptLineItemContext as CustomerTotalContext,
  default as PpFee,
  ReceiptLineItemContext as PpFeeContext,
  default as ChefHotplateFee,
  ReceiptLineItemContext as ChefHotplateFeeContext,
  default as Revenue,
  ReceiptLineItemContext as RevenueContext,
} from "../Confirmation/ReceiptLineItem";

const styles = [
  {
    type: "default",
    layers: {
      "5SPYRkoEkR": {
        none: {
          text: "Your Sale",
        },
      },
      VLyRengfsY: {
        none: {
          text: "#?????",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      "8Spra8CkVH": {},
      NfaKpGjYuV: {
        none: {
          item: "Subtotal",
          fee: true,
        },
      },
      MaJvmwpYZC: {
        none: {
          item: "Tip",
          fee: true,
        },
      },
      Qe135E4p8Q: {
        none: {
          item: "Hotplate Fee",
          fee: true,
        },
      },
      Ee8aJysehF: {
        none: {
          item: "Refund Total",
          fee: true,
          bold: true,
          color: "secondary",
        },
      },
      ALY5i8MfhX: {
        none: {
          item: "Customer Total",
          fee: true,
          bold: true,
        },
      },
      "7JLbxyJz8S": {
        none: {
          item: "Payment Processing",
          fee: true,
        },
      },
      U4kufiDQoy: {
        none: {
          item: "Hotplate Fee",
          fee: true,
        },
      },
      F7rzkhFijD: {
        none: {
          item: "Your Revenue",
          fee: true,
          bold: true,
          color: "good",
        },
      },
    },
  },
];

const variantPropTypes = [];

export const CollabOrderReceiptContext = createContext(null);

function CollabOrderReceipt(_props) {
  const defaults = useContext(CollabOrderReceiptContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="HBy54sLKax"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "HBy54sLKax",
        scope: "PrxeveR4YV",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => [
        <ContainerPrimitive
          id={"Container_A2bqQybPp1"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_A2bqQybPp1"
          }
          key={"A2bqQybPp1"}
          addSpacing={false}
          element={getStyle("A2bqQybPp1", "htmlElement")}
        >
          <TextPrimitive
            id={"Text_5SPYRkoEkR"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_5SPYRkoEkR"
            }
            key={"5SPYRkoEkR"}
            text={getStyle("5SPYRkoEkR", "text")}
            element={getStyle("5SPYRkoEkR", "htmlElement")}
          />
          <TextPrimitive
            id={"orderNum_VLyRengfsY"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_VLyRengfsY"
            }
            key={"VLyRengfsY"}
            text={
              exists(props.orderNum)
                ? props.orderNum
                : getStyle("VLyRengfsY", "text")
            }
            element={getStyle("VLyRengfsY", "htmlElement")}
          />
        </ContainerPrimitive>,
        <ContainerPrimitive
          key={"HVFJRTThQc"}
          id={"Slot_HVFJRTThQc"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_HVFJRTThQc"
          }
          addSpacing={false}
        >
          {props.children}
        </ContainerPrimitive>,
        props.Subtotal === undefined ? (
          <Subtotal
            key={"NfaKpGjYuV"}
            {...getCompositeDefaultProps("NfaKpGjYuV")}
            id="Subtotal_NfaKpGjYuV"
            className="__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_NfaKpGjYuV"
          />
        ) : (
          <SubtotalContext.Provider
            key="NfaKpGjYuV-provider"
            value={{
              key: "NfaKpGjYuV",
              id: "Subtotal_NfaKpGjYuV",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_NfaKpGjYuV",
              ...getCompositeDefaultProps("NfaKpGjYuV"),
            }}
          >
            {props.Subtotal}
          </SubtotalContext.Provider>
        ),
        <ContainerPrimitive
          key={"RwjAu5WADM"}
          id={"Slot_RwjAu5WADM"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_RwjAu5WADM"
          }
          addSpacing={false}
        >
          {props.fees}
        </ContainerPrimitive>,
        props.HotplateFee === undefined ? (
          <HotplateFee
            key={"Qe135E4p8Q"}
            {...getCompositeDefaultProps("Qe135E4p8Q")}
            id="HotplateFee_Qe135E4p8Q"
            className="__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_Qe135E4p8Q"
          />
        ) : (
          <HotplateFeeContext.Provider
            key="Qe135E4p8Q-provider"
            value={{
              key: "Qe135E4p8Q",
              id: "HotplateFee_Qe135E4p8Q",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_Qe135E4p8Q",
              ...getCompositeDefaultProps("Qe135E4p8Q"),
            }}
          >
            {props.HotplateFee}
          </HotplateFeeContext.Provider>
        ),
        props.Refund === undefined ? (
          <Refund
            key={"Ee8aJysehF"}
            {...getCompositeDefaultProps("Ee8aJysehF")}
            id="Refund_Ee8aJysehF"
            className="__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_Ee8aJysehF"
          />
        ) : (
          <RefundContext.Provider
            key="Ee8aJysehF-provider"
            value={{
              key: "Ee8aJysehF",
              id: "Refund_Ee8aJysehF",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_Ee8aJysehF",
              ...getCompositeDefaultProps("Ee8aJysehF"),
            }}
          >
            {props.Refund}
          </RefundContext.Provider>
        ),
        props.CustomerTotal === undefined ? (
          <CustomerTotal
            key={"ALY5i8MfhX"}
            {...getCompositeDefaultProps("ALY5i8MfhX")}
            id="CustomerTotal_ALY5i8MfhX"
            className="__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_ALY5i8MfhX"
          />
        ) : (
          <CustomerTotalContext.Provider
            key="ALY5i8MfhX-provider"
            value={{
              key: "ALY5i8MfhX",
              id: "CustomerTotal_ALY5i8MfhX",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_ALY5i8MfhX",
              ...getCompositeDefaultProps("ALY5i8MfhX"),
            }}
          >
            {props.CustomerTotal}
          </CustomerTotalContext.Provider>
        ),
        <ContainerPrimitive
          id={"divider_B6WuAYLndX"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_B6WuAYLndX"
          }
          key={"B6WuAYLndX"}
          addSpacing={false}
          element={getStyle("B6WuAYLndX", "htmlElement")}
        >
          <SpacerPrimitive
            id={"Spacer_97DYJ3tPT3"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_97DYJ3tPT3"
            }
            key={"97DYJ3tPT3"}
          />
          <ContainerPrimitive
            id={"divider-line_XVuTPDUefa"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_XVuTPDUefa"
            }
            key={"XVuTPDUefa"}
            addSpacing={false}
            element={getStyle("XVuTPDUefa", "htmlElement")}
          >
            {null}
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_HG9zhDzc9t"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_HG9zhDzc9t"
            }
            key={"HG9zhDzc9t"}
          />
        </ContainerPrimitive>,
        props.PpFee === undefined ? (
          <PpFee
            key={"7JLbxyJz8S"}
            {...getCompositeDefaultProps("7JLbxyJz8S")}
            id="PpFee_7JLbxyJz8S"
            className="__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_7JLbxyJz8S"
          />
        ) : (
          <PpFeeContext.Provider
            key="7JLbxyJz8S-provider"
            value={{
              key: "7JLbxyJz8S",
              id: "PpFee_7JLbxyJz8S",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_7JLbxyJz8S",
              ...getCompositeDefaultProps("7JLbxyJz8S"),
            }}
          >
            {props.PpFee}
          </PpFeeContext.Provider>
        ),
        props.ChefHotplateFee === undefined ? (
          <ChefHotplateFee
            key={"U4kufiDQoy"}
            {...getCompositeDefaultProps("U4kufiDQoy")}
            id="ChefHotplateFee_U4kufiDQoy"
            className="__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_U4kufiDQoy"
          />
        ) : (
          <ChefHotplateFeeContext.Provider
            key="U4kufiDQoy-provider"
            value={{
              key: "U4kufiDQoy",
              id: "ChefHotplateFee_U4kufiDQoy",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_U4kufiDQoy",
              ...getCompositeDefaultProps("U4kufiDQoy"),
            }}
          >
            {props.ChefHotplateFee}
          </ChefHotplateFeeContext.Provider>
        ),
        props.Revenue === undefined ? (
          <Revenue
            key={"F7rzkhFijD"}
            {...getCompositeDefaultProps("F7rzkhFijD")}
            id="Revenue_F7rzkhFijD"
            className="__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_F7rzkhFijD"
          />
        ) : (
          <RevenueContext.Provider
            key="F7rzkhFijD-provider"
            value={{
              key: "F7rzkhFijD",
              id: "Revenue_F7rzkhFijD",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_PrxeveR4YV_F7rzkhFijD",
              ...getCompositeDefaultProps("F7rzkhFijD"),
            }}
          >
            {props.Revenue}
          </RevenueContext.Provider>
        ),
      ]}
    </RootPrimitive>
  );
}

CollabOrderReceipt.ReceiptLineItem = ReceiptLineItem;
CollabOrderReceipt.Subtotal = Subtotal;
CollabOrderReceipt.Fee = Fee;
CollabOrderReceipt.HotplateFee = HotplateFee;
CollabOrderReceipt.Refund = Refund;
CollabOrderReceipt.CustomerTotal = CustomerTotal;
CollabOrderReceipt.PpFee = PpFee;
CollabOrderReceipt.ChefHotplateFee = ChefHotplateFee;
CollabOrderReceipt.Revenue = Revenue;
CollabOrderReceipt.__variants = [];

export default CollabOrderReceipt;
