// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./AddItemToOrder.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  ContainerPrimitive,
} from "../../_internal_primitives";
import {
  default as AddItemButton,
  ButtonContext as AddItemButtonContext,
  default as CancelAddItemButton,
  ButtonContext as CancelAddItemButtonContext,
} from "../../Primitives/Button";
import {
  default as ItemQuantityInput,
  InputContext as ItemQuantityInputContext,
  default as CustomItem,
} from "../../Primitives/Input";
import { default as ItemSelect } from "../../Primitives/Select";

const styles = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "open",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      "2DTddgpvgd": {
        none: {
          text: "Add Item",
        },
      },
      VzD6ghR1sH: {
        none: {
          placeholder: "#",
        },
      },
      "9NYn7Ku6xY": {},
      UoNDJnrtnn: {
        none: {
          placeholder: "Enter custom item",
        },
      },
      EG8LmqeG7U: {
        none: {
          text: "Cancel",
          kind: "quiet",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "open",
    layers: {
      "2DTddgpvgd": {
        none: {
          text: "Save",
          kind: "secondary",
        },
      },
      VzD6ghR1sH: {},
      "9NYn7Ku6xY": {},
      UoNDJnrtnn: {},
      EG8LmqeG7U: {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "open",
  },
];

export const AddItemToOrderContext = createContext(null);

function AddItemToOrder(_props) {
  const defaults = useContext(AddItemToOrderContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="M2f6kyJfur"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "M2f6kyJfur",
        scope: "PUZf3MEXXM",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"dropdown-container_8HDqCTmYrW"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PUZf3MEXXM_8HDqCTmYrW"
            }
            key={"8HDqCTmYrW"}
            addSpacing={false}
            element={getStyle("8HDqCTmYrW", "htmlElement")}
          >
            {[
              <SpacerPrimitive
                id={"Spacer_D3S32h3nfP"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_PUZf3MEXXM_D3S32h3nfP"
                }
                key={"D3S32h3nfP"}
              />,
              props.ItemQuantityInput === undefined ? (
                <ItemQuantityInput
                  key={"VzD6ghR1sH"}
                  {...getCompositeDefaultProps("VzD6ghR1sH")}
                  id="ItemQuantityInput_VzD6ghR1sH"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_PUZf3MEXXM_VzD6ghR1sH"
                />
              ) : (
                <ItemQuantityInputContext.Provider
                  key="VzD6ghR1sH-provider"
                  value={{
                    key: "VzD6ghR1sH",
                    id: "ItemQuantityInput_VzD6ghR1sH",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_PUZf3MEXXM_VzD6ghR1sH",
                    ...getCompositeDefaultProps("VzD6ghR1sH"),
                  }}
                >
                  {props.ItemQuantityInput}
                </ItemQuantityInputContext.Provider>
              ),
              <SpacerPrimitive
                id={"Spacer_UYk1tsex9E"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_PUZf3MEXXM_UYk1tsex9E"
                }
                key={"UYk1tsex9E"}
              />,
              <ContainerPrimitive
                key={"F3kRz6cyK3"}
                id={"Slot_F3kRz6cyK3"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_PUZf3MEXXM_F3kRz6cyK3"
                }
                addSpacing={false}
              >
                {props.input}
              </ContainerPrimitive>,
              <SpacerPrimitive
                id={"Spacer_LeWGeB2KhX"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_PUZf3MEXXM_LeWGeB2KhX"
                }
                key={"LeWGeB2KhX"}
              />,
            ]}
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"Container_2jbvJNM5NU"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_PUZf3MEXXM_2jbvJNM5NU"
            }
            key={"2jbvJNM5NU"}
            addSpacing={false}
            element={getStyle("2jbvJNM5NU", "htmlElement")}
          >
            {[
              props.AddItemButton === undefined ? (
                <AddItemButton
                  key={"2DTddgpvgd"}
                  {...getCompositeDefaultProps("2DTddgpvgd")}
                  id="AddItemButton_2DTddgpvgd"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_PUZf3MEXXM_2DTddgpvgd"
                />
              ) : (
                <AddItemButtonContext.Provider
                  key="2DTddgpvgd-provider"
                  value={{
                    key: "2DTddgpvgd",
                    id: "AddItemButton_2DTddgpvgd",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_PUZf3MEXXM_2DTddgpvgd",
                    ...getCompositeDefaultProps("2DTddgpvgd"),
                  }}
                >
                  {props.AddItemButton}
                </AddItemButtonContext.Provider>
              ),
              <SpacerPrimitive
                id={"Spacer_XvAFtWUeLA"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_PUZf3MEXXM_XvAFtWUeLA"
                }
                key={"XvAFtWUeLA"}
              />,
              props.CancelAddItemButton === undefined ? (
                <CancelAddItemButton
                  key={"EG8LmqeG7U"}
                  {...getCompositeDefaultProps("EG8LmqeG7U")}
                  id="CancelAddItemButton_EG8LmqeG7U"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_PUZf3MEXXM_EG8LmqeG7U"
                />
              ) : (
                <CancelAddItemButtonContext.Provider
                  key="EG8LmqeG7U-provider"
                  value={{
                    key: "EG8LmqeG7U",
                    id: "CancelAddItemButton_EG8LmqeG7U",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_PUZf3MEXXM_EG8LmqeG7U",
                    ...getCompositeDefaultProps("EG8LmqeG7U"),
                  }}
                >
                  {props.CancelAddItemButton}
                </CancelAddItemButtonContext.Provider>
              ),
            ]}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

AddItemToOrder.AddItemButton = AddItemButton;
AddItemToOrder.ItemQuantityInput = ItemQuantityInput;
AddItemToOrder.ItemSelect = ItemSelect;
AddItemToOrder.CustomItem = CustomItem;
AddItemToOrder.CancelAddItemButton = CancelAddItemButton;
AddItemToOrder.__variants = [
  {
    name: "open",
    type: "variant",
  },
];

export default AddItemToOrder;
