import React, { useState } from "react";
import { connect } from "react-redux";
import ReactLoading from "react-loading";
import { textstyles, colors, icons } from "../../../../visly";
import { Badge, Button, IconButton, Input } from "../../../../visly/Primitives";

import Modal from "../../../../hotplate-common/Modal";
import { setToastPopupInfo } from "../../../../hotplate-storefront/actions";
import OptionSelectModal from "../../../../hotplate-common/OptionSelectModal";
import { showItemWaitlistPortalFunctionality } from "../../../../hotplate-common/HelperFunctions";
import { trpc } from "../../../../trpc";

export default connect(undefined, { setToastPopupInfo })(
  function ChangeLiveEventMenuItemInventoryModal({
    eventId,
    setToastPopupInfo,

    menuItem,
    menuItemSoldCount,
    itemWaitlistCount,
    onRequestClose,
  }) {
    const [menuItemInventoryDeltaValue, setMenuItemInventoryDeltaValue] = useState("");
    const [forceMenuItemInventoryValue, setForceMenuItemInventoryValue] = useState("");
    const [showOptionSelectModal, setShowOptionSelectModal] = useState(false);
    const setLiveEventMenuItemInventory = trpc.portal.setLiveEventMenuItemInventory.useMutation({
      retry: 1,
    });
    const deltaLiveEventMenuItemInventory = trpc.portal.deltaLiveEventMenuItemInventory.useMutation(
      {
        retry: 0,
      }
    );

    const menuItemInventoryDeltaValueNumeric = (() => {
      const text = menuItemInventoryDeltaValue;
      if (text === "") {
        return 0;
      } else if (text.startsWith("+")) {
        if (text.length === 1) {
          return 0;
        }
        return parseInt(text.slice(1));
      } else if (text.startsWith("-") || text.startsWith("−")) {
        if (text.length === 1) {
          return 0;
        }
        return -parseInt(text.slice(1));
      } else {
        return parseInt(text);
      }
    })();

    function handleMenuItemInventoryDeltaValueChange(text) {
      if (text.endsWith("-") || text.endsWith("−") || text.endsWith("+")) {
        if (text.startsWith("-") || text.startsWith("−") || text.startsWith("+")) {
          text = text.slice(-1) + text.slice(1, -1);
        } else {
          text = text.slice(-1) + text.slice(0, -1);
        }
      } else if (
        !(text.startsWith("-") || text.startsWith("−") || text.startsWith("+") || text === "")
      ) {
        text = "+" + text;
      }

      if (text === "" || /^[+\-−]?[0-9]*$/.test(text)) {
        setMenuItemInventoryDeltaValue(text);
      }
    }

    function addToMenuItemInventoryDeltaValue(delta) {
      const text = menuItemInventoryDeltaValue;
      const newNum = menuItemInventoryDeltaValueNumeric + delta;
      let newText;
      if (newNum < 0) {
        if (text.startsWith("−")) {
          newText = "−" + newNum.toString().slice(1);
        } else {
          newText = newNum.toString();
        }
      } else {
        newText = "+" + newNum.toString();
      }
      setMenuItemInventoryDeltaValue(newText);
    }

    function handleForceMenuItemInventoryValueChange(val) {
      if (/^[0-9]+$/.test(val) || val === "") {
        setForceMenuItemInventoryValue(val);
      }
    }

    async function applyMenuItemInventoryDeltaValue() {
      let resp;
      try {
        resp = await deltaLiveEventMenuItemInventory.mutateAsync({
          eventId: eventId,
          menuItemId: menuItem.id,
          delta: menuItemInventoryDeltaValueNumeric,
          notifyItemWaitlist: showItemWaitlistPortalFunctionality(
            menuItem,
            menuItemSoldCount,
            itemWaitlistCount
          ),
        });
      } catch (e) {
        setToastPopupInfo({
          text: `Failed to change inventory: ${e}`,
          type: "error",
        });
        throw e;
      }
      if (resp.deltaApplied === menuItemInventoryDeltaValueNumeric) {
        setToastPopupInfo({
          text: "Successfully changed menu item inventory",
          type: "success",
        });
        onRequestClose();
      } else {
        setToastPopupInfo({
          text: `Changed inventory by ${resp.deltaApplied} instead of ${menuItemInventoryDeltaValueNumeric}`,
          type: "error",
        });
      }
    }

    async function saveForceMenuItemInventoryValue() {
      const value = parseInt(forceMenuItemInventoryValue);
      if (isNaN(value)) {
        throw new Error("forceMenuItemInventoryValue could not be parsed into a number");
      }
      try {
        await setLiveEventMenuItemInventory.mutateAsync({
          eventId: eventId,
          menuItemId: menuItem.id,
          value: value,
        });
      } catch (e) {
        setToastPopupInfo({
          text: `Failed to set inventory: ${e}`,
          type: "error",
        });
        throw e;
      }
      setToastPopupInfo({
        text: "Successfully set menu item inventory",
        type: "success",
      });
      onRequestClose();
    }

    const inventoryOriginalText =
      menuItem["inventoryOriginal"] === "" ? "unlimited" : menuItem["inventoryOriginal"];

    const inventoryModifiedText =
      menuItem["inventoryModified"] === "" ? "unlimited" : menuItem["inventoryModified"];

    const pStyle = {
      ...textstyles.footnote,
      color: colors.gray600,
      lineHeight: "1.75",
    };

    const headerStyle = {
      fontFamily: "var(--arboria)",
      fontSize: "24px",
      fontWeight: "500",
      color: "var(--gray800)",
      marginBottom: "0.5em",
    };

    const subHeaderStyle = {
      fontFamily: "var(--avenir)",
      fontSize: "16px",
      fontWeight: "500",
      color: "var(--gray600)",
      marginBottom: "1em",
    };

    const boxStyle = {
      display: "flex",
      flexFlow: "column",
      width: "100%",
      paddingInline: "clamp(20px, 3vw + 8px, 28px)",
      paddingBottom: "32px",
    };

    return (
      <Modal
        compact
        closeModal={onRequestClose}
        confirmation={
          showOptionSelectModal && (
            <OptionSelectModal
              absolute
              message={`You are about to ADD ${menuItemInventoryDeltaValueNumeric} inventory to the current value, are you sure?`}
              optionSelectButtons={[
                {
                  text: "Yes, I'm sure",
                  type: "warning",
                  onClick: applyMenuItemInventoryDeltaValue,
                },
              ]}
              closeModal={() => setShowOptionSelectModal(false)}
            />
          )
        }
        showConfirmation={showOptionSelectModal}
      >
        <div style={boxStyle}>
          <h2 style={headerStyle}>{`Change inventory`}</h2>
          <h3 style={subHeaderStyle}>{menuItem.title}</h3>
          <div
            style={{
              display: "flex",
              marginBottom: "1em",
            }}
          >
            <Badge
              text={`${Number.isInteger(menuItem.inventory) ? menuItem.inventory : "∞"} available`}
              light="black"
              style={{ display: "inline-block" }}
            />
            {Number.isInteger(menuItem.inventoryReserved) && (
              <Badge
                text={`${Math.max(menuItem.inventoryReserved - menuItemSoldCount, 0)} in carts`}
                light="warning"
              />
            )}
            <Badge text={`${menuItemSoldCount} sold`} light="good" />
            {showItemWaitlistPortalFunctionality(
              menuItem,
              menuItemSoldCount,
              itemWaitlistCount
            ) && <Badge text={itemWaitlistCount + " want more"} solid="black" />}
          </div>
          <div
            style={{
              height: "1px",
              backgroundColor: "var(--gray100)",

              width: "100%",
            }}
          />
          <div style={{ marginBlock: "2em" }}>
            {menuItem.inventory === "" ? (
              <Input
                darkPlaceholder
                style={{ width: "100%", marginBottom: "1em" }}
                value={forceMenuItemInventoryValue}
                onChange={handleForceMenuItemInventoryValueChange}
                placeholder="Set inventory"
              />
            ) : (
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  marginBottom: "1em",
                }}
              >
                <Input
                  type="text"
                  placeholder="+0"
                  inputProps={{ style: { textAlign: "center" } }}
                  style={{ width: "100%" }}
                  value={menuItemInventoryDeltaValue}
                  onChange={handleMenuItemInventoryDeltaValueChange}
                />
                <IconButton
                  icon={icons.minus}
                  style={{
                    position: "absolute",
                    left: "12px",
                    backgroundColor: "white",
                    border: "1px solid var(--gray200)",
                    borderRadius: "4px",
                    boxShadow: "var(--elevation1-shadow)",
                  }}
                  large
                  onClick={() => addToMenuItemInventoryDeltaValue(-1)}
                />
                <IconButton
                  icon={icons.plus}
                  style={{
                    position: "absolute",
                    right: "12px",
                    backgroundColor: "white",
                    border: "1px solid var(--gray200)",
                    borderRadius: "4px",
                    boxShadow: "var(--elevation1-shadow)",
                  }}
                  large
                  onClick={() => addToMenuItemInventoryDeltaValue(1)}
                />
              </div>
            )}
            {Number.isInteger(menuItem.inventory) && (
              <p style={pStyle}>
                available + in carts + sold ={" "}
                {menuItem.inventory +
                  Math.max(menuItem.inventoryReserved - menuItemSoldCount, 0) +
                  menuItemSoldCount}
              </p>
            )}
            <p style={pStyle}>
              {menuItem["inventoryOriginal"] !== undefined
                ? `Original inventory: ${inventoryOriginalText}`
                : null}
            </p>
            <p style={pStyle}>
              {menuItem["inventoryModified"] !== undefined
                ? `Modified inventory: ${inventoryModifiedText}`
                : null}
            </p>
          </div>
          {menuItem.inventory === "" ? (
            <Button
              text={`Set inventory to ${parseInt(forceMenuItemInventoryValue) || ""}`}
              disabled={forceMenuItemInventoryValue === ""}
              kind="secondary"
              style={{ width: "100%", marginBlock: "2em 1em" }}
              onClick={saveForceMenuItemInventoryValue}
            />
          ) : (
            <Button
              text={`${
                menuItemInventoryDeltaValue.startsWith("-") ||
                menuItemInventoryDeltaValue.startsWith("−")
                  ? "Remove"
                  : "Add"
              } ${Math.abs(menuItemInventoryDeltaValueNumeric)} inventory ${
                showItemWaitlistPortalFunctionality(menuItem, menuItemSoldCount, itemWaitlistCount)
                  ? ` and notify ${
                      itemWaitlistCount - (itemWaitlistCount === 1 ? 0 : 1)
                    }+ customers`
                  : ""
              }`}
              disabled={menuItemInventoryDeltaValueNumeric === 0}
              kind="secondary"
              style={{ width: "100%", marginBlock: "2em 1em" }}
              loading={
                setLiveEventMenuItemInventory.isLoading || deltaLiveEventMenuItemInventory.isLoading
              }
              loadingAnimation={
                <ReactLoading type={"bubbles"} color={"#FFFFFF"} height={26} width={26} />
              }
              onClick={() => {
                // IF inventory delta >= 50% of original inventory, throw up a confirmation modal
                if (menuItemInventoryDeltaValueNumeric >= menuItem["inventoryOriginal"] / 2) {
                  setShowOptionSelectModal(true);
                } else {
                  applyMenuItemInventoryDeltaValue();
                }
              }}
            />
          )}
          <Button
            text="Cancel"
            style={{ width: "100%", marginBottom: "1em" }}
            onClick={onRequestClose}
          />
        </div>
      </Modal>
    );
  }
);
