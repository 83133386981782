// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./ObjectSelectionModal.EmptyContainerCta.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      "4sNdBS15qo": {
        none: {
          icon: icons.empty,
        },
      },
      SGZ6CHD4Qg: {
        none: {
          text: "empty placeholder",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const EmptyContainerCtaContext = createContext(null);

function EmptyContainerCta(_props) {
  const defaults = useContext(EmptyContainerCtaContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="6yvVpxSRqz"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "6yvVpxSRqz",
        scope: "MFaWzKN3oX",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"icon-container_QyRmfA3uMD"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_MFaWzKN3oX_QyRmfA3uMD"
            }
            key={"QyRmfA3uMD"}
            addSpacing={false}
            element={getStyle("QyRmfA3uMD", "htmlElement")}
          >
            {
              <IconPrimitive
                id={"Icon_4sNdBS15qo"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_MFaWzKN3oX_4sNdBS15qo"
                }
                key={"4sNdBS15qo"}
                useMask={getStyle("4sNdBS15qo", "useMask")}
                src={getStyle("4sNdBS15qo", "icon")}
              />
            }
          </ContainerPrimitive>
          <TextPrimitive
            id={"Text_SGZ6CHD4Qg"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_MFaWzKN3oX_SGZ6CHD4Qg"
            }
            key={"SGZ6CHD4Qg"}
            text={
              exists(props.text) ? props.text : getStyle("SGZ6CHD4Qg", "text")
            }
            element={getStyle("SGZ6CHD4Qg", "htmlElement")}
          />
        </>
      )}
    </RootPrimitive>
  );
}

EmptyContainerCta.__variants = [];

export default EmptyContainerCta;
