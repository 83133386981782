// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./CustomTabs.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, ContainerPrimitive } from "../_internal_primitives";
import { default as Component } from "./CustomTabs.Component";

const styles = [
  {
    type: "default",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const CustomTabsContext = createContext(null);

function CustomTabs(_props) {
  const defaults = useContext(CustomTabsContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="YRiqfFL3mh"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "YRiqfFL3mh",
        scope: "JgjVfpnyMx",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"buttonContainer_5XKmscwwQy"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_JgjVfpnyMx_5XKmscwwQy"
          }
          key={"5XKmscwwQy"}
          addSpacing={false}
          element={getStyle("5XKmscwwQy", "htmlElement")}
        >
          {props.Component === undefined ? (
            <Component key={"4MbXVaqk4G"} />
          ) : (
            props.Component
          )}
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

CustomTabs.__variants = [];

export default CustomTabs;
