import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import {
  formatToCurrency,
  getCartItemPrice,
  getEventStatus,
  getFormattedPhone,
  getUnFormattedPhone,
  isEventOnDemandActive,
} from "@hotplate/utils-ts/helperFunctions";
import OptionSelectModal from "../../hotplate-common/OptionSelectModal";
import SmartTextArea from "../../hotplate-common/SmartTextArea";
import { CustomerMenuItem } from "../../visly/Menu/Customer";
import { icons } from "../../visly";
import { styled, keyframes } from "../../stitches.config";
import { OptionCategorySelect } from "./StorefrontOptionCategorySelect";
import { trackMenuItemRestockReminderSignupClicked, trackProductViewed } from "../analytics";
import { usePresenceCounter, usePrevious } from "../../hooks";
import { Error } from "../../hotplate-common/primitives/Error";
import { getMenuItemStatusDescription } from "../shop/commonFunctions";
import { Callout } from "../../hotplate-common/primitives/Callout";
import {
  CartItemType,
  CartType,
  ChefStyles,
  EventStatus,
  EventType,
  FulfillmentType,
  MenuItemStatus,
  MenuItemType,
  Option,
  OptionCategory,
  SiteSettings,
} from "../shop/types";
import { DialogClose, DialogContent, DialogRoot } from "../../hotplate-common/primitives/Dialog";
import { Box, Column, H2, Img, P, Row, Span } from "../../hotplate-common/primitives/Containers";
import { AspectRatioImg } from "../../hotplate-common/primitives/AspectRatioImg";
import { Button } from "../../hotplate-common/primitives/Button";
import { CSS } from "@stitches/react";
import { ScrollArea } from "../../hotplate-common/primitives/ScrollArea";
import { CheckCircledIcon } from "@radix-ui/react-icons";
import { Input } from "../../hotplate-common/primitives/Input";
import { ensureOk, setMenuItemNotificationPhoneMutation } from "../../mutations";
import { setToastPopupInfo as setToastPopupInfoUnconnected } from "../actions";
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";

function useConnectedFn(fn, dispatch) {
  return useCallback(
    (...args) => {
      return fn(...args)(dispatch);
    },
    [fn, dispatch]
  );
}

const Flex = styled("div", { display: "flex" });

const slideIn = keyframes({
  from: { transform: "translateY(150px)" },
  to: { transform: "translateY(0px)" },
});

const ContentBox = styled("div", {
  display: "flex",
  flexDirection: "column",
  flexShrink: 0,
  padx: "$sm",
  width: "100%",
  gap: "$lg",
  "@tablet": {
    padx: "$md",
  },
  "@desktop_md": {
    padx: "$lg",
  },
});

const Title = styled("h3", {
  fontFamily: "$inter",
  textStyle: "text-4",
  color: "$gray12",
  marginBlock: "$md",
  "@desktop_md": {
    textStyle: "text-5",
  },
});

const Description = styled("p", {
  fontFamily: "$inter",
  textStyle: "text-2",
  color: "$gray11",
  display: "flex",
  overflow: "hidden",
  width: "100%",
  whiteSpace: "pre-line",
  textOverflow: "ellipsis",
  marginBottom: "$md",
  "@desktop_md": {
    textStyle: "text-3",
  },
});

const CartButtonBox = styled("div", {
  position: "sticky",
  zIndex: "$sticky",
  bottom: "max(env(safe-area-inset-bottom), 24px)",
  display: "flex",
  justifyContent: "center",
  width: "100%",
  '&[data-show="true"]': { animation: `${slideIn} 250ms cubic-bezier(.45,.41,.42,1.32)` },
  '&[data-show="false"]': { display: "none" },
});

export const MenuInventory = ({
  value,
  text,
  css,
  ...props
}: {
  value: number | string;
  text: string;
  css?: CSS;
}) => {
  const sharedTextCss: CSS = {
    ff: "$inter",
    textStyle: "text-2",
    color: "inherit",
    truncateText: true,
  };

  return (
    <Row
      css={{
        backgroundColor: "$gray3",
        color: "$gray11",
        h: "$xs_btn",
        borderRadius: "$sm",
        alignItems: "center",
        padx: "$xs",
        ...css,
      }}
      {...props}
    >
      <Span css={{ ...sharedTextCss, fw: "$semi_bold" }}>{value}</Span>
      <Span css={{ ...sharedTextCss, ml: "0.5ch" }}>{text}</Span>
    </Row>
  );
};

type ModalFunctions = {
  getMenuItemInventoryInCart: (menuItem: MenuItemType) => number;
  getMenuItemInventoryRemaining: (menuItem: MenuItemType) => number;
  getMenuItemDisplayQuantityRemaining: (event: EventType, menuItem: MenuItemType) => boolean;
  getMenuItemMaxPerCustomer: (menuItem: MenuItemType) => number;
  getNextAvailablePickupSlot: (menuItem: MenuItemType) => string | null;
  getMenuItemStatus: (menuItem: MenuItemType, event: EventType) => MenuItemStatus;
  getMenuItemInventoryInAllCarts: (menuItem: MenuItemType) => number;
  getMenuItemInventorySold: (menuItem: MenuItemType) => number;
};

export const MenuItemModal = ({
  siteSettings,
  event,
  fulfillmentType,
  menuItem,
  cart,
  menuItemModalFuncs,
  chefStyles,
  onClearCart,
  onAddToCart,
  isAddToCartLoading,
  isOpen,
  onOpenChange,
}: {
  siteSettings: SiteSettings;
  event: EventType;
  menuItem: MenuItemType;
  cart: CartType;
  menuItemModalFuncs: ModalFunctions;
  fulfillmentType: FulfillmentType;
  chefStyles: ChefStyles;
  onAddToCart: (cartItem: CartItemType) => void;
  onClearCart: () => void;
  isAddToCartLoading: boolean;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}) => {
  const {
    getMenuItemInventoryInCart,
    getMenuItemInventoryRemaining,
    getMenuItemDisplayQuantityRemaining,
    getMenuItemMaxPerCustomer,
    getNextAvailablePickupSlot,
    getMenuItemStatus,
    getMenuItemInventoryInAllCarts,
    getMenuItemInventorySold,
  } = menuItemModalFuncs;
  const maxPerCustomer = getMenuItemMaxPerCustomer(menuItem);
  const status = getMenuItemStatus(menuItem, event);
  const showInventory = getMenuItemDisplayQuantityRemaining(event, menuItem);
  const inventoryAvailable = getMenuItemInventoryRemaining(menuItem);
  const inventoryInCustomerCarts = getMenuItemInventoryInAllCarts(menuItem);
  const itemIsSoldOut = status === "sold_out" || status === "no_time_available";
  const eventStatus = getEventStatus(event);
  const eventIsLive = eventStatus === "live";

  const [notifyMePhoneNumber, setNotifyMePhoneNumber] = useState("");
  const dispatch = useDispatch();
  const setToastPopupInfo = useConnectedFn(setToastPopupInfoUnconnected, dispatch);

  const setMenuItemNotificationPhone = useMutation(setMenuItemNotificationPhoneMutation);

  const [showOptionSelectModal, setShowOptionSelectModal] = useState(false);
  const [optionSelectModalMessage, setOptionSelectModalMessage] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedOptionQuantities, setSelectedOptionQuantities] = useState({});
  const [quantity, setQuantity] = useState(
    Math.max(
      1,
      Math.min(1, inventoryAvailable, maxPerCustomer - getMenuItemInventoryInCart(menuItem))
    )
  );
  const [imgSrc, setImgSrc] = useState(
    menuItem.image ===
      "https://ucarecdn.com/c7478fd7-b935-44e7-b406-7889e94f9b3d/MenuPlaceholder.png"
      ? false
      : menuItem.image
  );
  const [notes, setNotes] = useState<string>();
  const [idxOfIncompleteOptionCategory, setIdxOfIncompleteOptionCategory] = useState(-1);
  const { count: numCustomersPresentOnItem } = usePresenceCounter(
    event.id + "/" + (menuItem.id || "undefined") // use the string undefined as a hack to prevent connecting to the same presenceKey as something else (and causing presence count to be incremented) when menuItem.id === "" (which is passed by the parent for animation??)
  );

  // Derive a cart item from a menu item.
  // inject selected option quantity
  const cartItem = _.cloneDeep(menuItem);
  if (Array.isArray(cartItem.optionCategories)) {
    for (
      let optionCategoryIndex = 0;
      optionCategoryIndex < cartItem.optionCategories.length;
      optionCategoryIndex++
    ) {
      const optionCategory = cartItem.optionCategories[optionCategoryIndex];
      const options = optionCategory.options;
      if (Array.isArray(options)) {
        for (let optionIndex = 0; optionIndex < options.length; optionIndex++) {
          if (
            optionCategoryIndex in selectedOptionQuantities &&
            optionIndex in selectedOptionQuantities[optionCategoryIndex]
          ) {
            options[optionIndex].quantitySelected =
              selectedOptionQuantities[optionCategoryIndex][optionIndex];
          }
        }
      }
    }

    for (const [optionCategoryIndex, selectedOptionTitles] of Object.entries<string[]>(
      selectedOptions
    )) {
      const optionCategory = cartItem.optionCategories[optionCategoryIndex];
      const options = optionCategory.options;
      const maximumSelections = optionCategory.maximumSelections;

      if (Array.isArray(options)) {
        const validOptionTitles = new Set(options.map((option) => option.title));
        let validSelectedOptionTitles = selectedOptionTitles.filter((title: string) =>
          validOptionTitles.has(title)
        );

        if (validSelectedOptionTitles.length > maximumSelections) {
          validSelectedOptionTitles = validSelectedOptionTitles.slice(
            validSelectedOptionTitles.length - maximumSelections
          );
        }

        for (const option of options) {
          if (isOptionOutOfStock(optionCategory, option, quantity)) continue;
          if (validSelectedOptionTitles.indexOf(option.title) > -1) option.selected = true;
          else option.selected = false;
        }
      }
    }
  }
  cartItem.quantity = quantity;
  cartItem.notes = notes;

  const nextAvailablePickupSlot = getNextAvailablePickupSlot(cartItem);

  const { selectedOptionWithLeastInventory, categoryOfSelectedOptionWithLeastInventory } =
    getSelectedOptionWithLeastInventory();

  const isAnySelectedOptionAtInventoryLimit =
    selectedOptionWithLeastInventory &&
    isOptionOutOfStock(
      categoryOfSelectedOptionWithLeastInventory,
      selectedOptionWithLeastInventory,
      quantity + 1 // we show the text when the option's inventory === current item quantity
    );

  function incrementMenuItemModalQuantity() {
    setQuantity(
      Math.max(
        1,
        Math.min(
          quantity + 1,
          inventoryAvailable,
          maxPerCustomer - getMenuItemInventoryInCart(menuItem),
          selectedOptionWithLeastInventory ? selectedOptionWithLeastInventory.inventory : Infinity
        )
      )
    );
  }

  function decrementMenuItemModalQuantity() {
    if (quantity > 0) {
      setQuantity(Math.max(1, quantity - 1));
    }
  }

  function isOptionOutOfStock(
    optionCategory: OptionCategory,
    option: Option,
    selectedQuantity: number
  ) {
    return (
      optionCategory.showOptionInventory &&
      Number.isInteger(option.inventory) &&
      option.inventory < selectedQuantity
    );
  }

  function getOptionInventoryRemaining(optionCategory: OptionCategory, option: Option) {
    if (optionCategory.showOptionInventory && Number.isInteger(option.inventory)) {
      return option.inventory;
    } else {
      return 99999;
    }
  }

  function setMenuItemModalSelectedOptions(
    selectedOptionTitles: string[],
    optionCategoryIndex: number
  ) {
    setSelectedOptions({
      ...selectedOptions,
      [optionCategoryIndex]: selectedOptionTitles,
    });
  }

  function setOptionQuantity(optionCategoryIndex, optionIndex, quantitySelected) {
    const maxSelections = cartItem.optionCategories[optionCategoryIndex].maximumSelections;

    const newSelectedOptionQuantities = _.cloneDeep(selectedOptionQuantities);
    newSelectedOptionQuantities[optionCategoryIndex] =
      newSelectedOptionQuantities[optionCategoryIndex] || {};
    newSelectedOptionQuantities[optionCategoryIndex][optionIndex] = quantitySelected;

    const quantityOptionsSelected = Object.values(
      newSelectedOptionQuantities[optionCategoryIndex]
    ).reduce((sum, v) => sum + v, 0);

    if (quantityOptionsSelected > maxSelections) return;

    setSelectedOptionQuantities(newSelectedOptionQuantities);
  }

  /**
   * @returns Of all selected menu item options, the option (and category of that option)
   * that has the least inventory which is NOT infinite and whose category has inventory
   * enabled.
   */
  function getSelectedOptionWithLeastInventory() {
    let selectedOptionWithLeastInventory = null;
    let categoryOfSelectedOptionWithLeastInventory = null;
    if (Array.isArray(cartItem.optionCategories)) {
      for (const optionCategory of cartItem.optionCategories) {
        if (optionCategory.showOptionInventory) {
          for (const option of optionCategory.options) {
            if (
              option.selected &&
              optionCategory.showOptionInventory &&
              Number.isInteger(option.inventory) &&
              (!selectedOptionWithLeastInventory ||
                option.inventory < selectedOptionWithLeastInventory.inventory)
            ) {
              selectedOptionWithLeastInventory = option;
              categoryOfSelectedOptionWithLeastInventory = optionCategory;
            }
          }
        }
      }
    }
    return {
      selectedOptionWithLeastInventory,
      categoryOfSelectedOptionWithLeastInventory,
    };
  }

  async function attemptAddToCart() {
    // Validation
    if (Array.isArray(cartItem.optionCategories)) {
      for (let i = 0; i < cartItem.optionCategories.length; i++) {
        const optionCategory = cartItem.optionCategories[i];
        if (!Array.isArray(optionCategory.options)) continue;

        let optionCategoryIncomplete = false;
        if (optionCategory.isOptionSelectionNumerical) {
          optionCategoryIncomplete =
            optionCategory.options.reduce(
              (sum, option) =>
                sum + (Number.isInteger(option.quantitySelected) ? option.quantitySelected : 0),
              0
            ) < optionCategory.minimumSelections;
        } else {
          const selectedOptions = optionCategory.options.filter((option: Option) => {
            return option.selected;
          });
          if (selectedOptions.length < optionCategory.minimumSelections)
            optionCategoryIncomplete = true;
        }

        // * If the option category is required and no selections are made, return
        if (optionCategoryIncomplete) {
          const optionCategoryNotComplete = document.querySelector("#option-category_" + i);
          setIdxOfIncompleteOptionCategory(i);
          if (optionCategoryNotComplete) {
            optionCategoryNotComplete.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
          return;
        }
      }
    }

    setIdxOfIncompleteOptionCategory(-1);
    await onAddToCart(cartItem);
  }

  const prevMenuItem = usePrevious(menuItem);
  useEffect(() => {
    if (prevMenuItem?.id !== menuItem?.id && menuItem) {
      trackProductViewed(menuItem, siteSettings.chefId);
    }
  }, [prevMenuItem, menuItem, siteSettings]);

  function getAddToCartButtonText(status: MenuItemStatus, eventStatus: EventStatus): string {
    switch (status) {
      case "delivery_out_of_range":
        return "Too far";
      case "need_delivery_address":
        return "Enter your address";
      case "no_time_available":
      case "sold_out":
        return "Sold Out";
      case "reserved":
        return "Reserved";
      case "inactive_event":
        return eventStatus === "complete" ? "Sale Ended" : "Available Soon";
      case "available":
        return "Add to Cart";
      default:
        return "Add to Cart";
    }
  }

  function getCalloutVariant(status: MenuItemStatus) {
    switch (status) {
      case "delivery_out_of_range":
        return "warning";
      case "need_delivery_address":
        return "warning";
      case "no_time_available":
        return "warning";
      case "inactive_event":
        return "info";
      case "sold_out":
        return "warning";
      case "reserved":
        return "info";
      default:
        return "info";
    }
  }

  const isAddToCartButtonDisabled: boolean =
    isAddToCartLoading ||
    inventoryAvailable <= 0 ||
    (nextAvailablePickupSlot === null && !isEventOnDemandActive(event)) ||
    showOptionSelectModal ||
    quantity === 0 ||
    parseFloat(getCartItemPrice(cartItem)) < 0 ||
    status !== "available" ||
    (cartItem.notesEnabled && cartItem.notesRequired && !notes?.trim().length) ||
    maxPerCustomer - getMenuItemInventoryInCart(menuItem) <= 0;

  return (
    <DialogRoot onOpenChange={onOpenChange} open={isOpen}>
      <DialogContent
        css={{
          width: "100%",
          maxW: "650px",
          height: "100%",
          br: "$lg",
          "@desktop_md": {
            maxH: "calc(100% - 32px)",
          },
        }}
      >
        <ScrollArea
          horizontal={undefined}
          id="menu-item_scroll-area"
          vertical={true}
          css={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            height: "100%",
            width: "100%",
            backgroundColor: "$white",
            br: "inherit",
            jc: "space-between",
          }}
          viewportCss={{
            "& > div": {
              display: "flex !important",
              flexDirection: "column",
              minH: "100%",
              justifyContent: "space-between",
            },
          }}
        >
          <Flex css={{ flexDirection: "column" }}>
            {showOptionSelectModal && (
              <OptionSelectModal
                style={{ borderRadius: "12px" }}
                closeModal={() => setShowOptionSelectModal(false)}
                message={optionSelectModalMessage}
                optionSelectButtons={[
                  {
                    text: "Clear my Cart",
                    type: "warning",
                    onClick: onClearCart,
                  },
                ]}
              />
            )}
            <Box css={{ position: "relative" }}>
              {imgSrc && (
                <AspectRatioImg
                  ratio={1}
                  src={imgSrc}
                  alt={`Amazing photo of ${menuItem.title}`}
                  css={{
                    // maxHeight: "60vh", this was here before, but i don't think the issue it was trying to solve is still relevant, I would rather show the entire image to the customer instead of cropping it
                    brTopLeft: "$lg",
                    brTopRight: "$lg",
                    overflow: "hidden",
                  }}
                />
              )}
              {imgSrc &&
                Array.isArray(menuItem.additionalImages) &&
                menuItem.additionalImages.length > 0 && (
                  <Flex
                    css={{
                      justifyContent: "center",
                      overflowX: "auto",
                      pady: "$sm",
                      gap: "$xs",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      background: "linear-gradient(180deg, $overlay1 0%, $overlay12 100%)",
                      "@desktop_md": {
                        pady: "$md",
                      },
                    }}
                  >
                    {[menuItem.image].concat(menuItem.additionalImages).map((img, i) => (
                      <Img
                        key={i}
                        src={img}
                        alt={`Amazing photo of ${menuItem.title}`}
                        css={{
                          size: 48,
                          borderRadius: "$xs",
                          cursor: "pointer",
                          outline: imgSrc === img ? "1px solid $white" : "1px solid transparent",
                          outlineOffset: -1,
                          transition: "outline 150ms ease-in-out",
                          "@desktop_sm": {
                            size: 64,
                          },
                          "@desktop_lg": {
                            size: 72,
                          },
                        }}
                        onClick={() => setImgSrc(img)}
                      />
                    ))}
                  </Flex>
                )}
            </Box>

            <ContentBox>
              <Column css={{ mt: "$lg", w: "$full" }}>
                {getMenuItemStatusDescription(status, eventStatus) && (
                  <Callout type={getCalloutVariant(status)} css={{ mb: "$sm" }} hideIcon={false}>
                    {getMenuItemStatusDescription(status, eventStatus)}
                  </Callout>
                )}
                {status === "sold_out" && (
                  <Callout
                    css={{ mb: "$lg", boxShadow: "$elevation3", pad: "$md" }}
                    type={"accent"}
                    hideIcon
                  >
                    <Column css={{ w: "$full" }}>
                      <H2 css={{ textStyle: "text-4" }}>This item is currently sold out</H2>
                      <P>
                        Enter your phone to let {siteSettings.restaurantName} know you want more! We
                        will send you a text if it comes back in stock.
                      </P>
                      <Column css={{ ai: "center", mt: "$sm" }}>
                        <Input
                          css={{
                            minW: 0,
                            w: "calc(100% - 24px)", // i have no idea why the input doesnt obey the width of the parent, but this is a hack since I do not have much time
                            backgroundColor: "$white",
                            "@tablet": { w: "calc(100% - 32px)" },
                          }}
                          contentBefore={"+1 🇺🇸"}
                          disabled={setMenuItemNotificationPhone.isLoading}
                          placeholder="Phone number"
                          type={"tel"}
                          inputMode={"tel"}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setNotifyMePhoneNumber(getFormattedPhone(e.target.value, false))
                          }
                          value={notifyMePhoneNumber}
                          showClear={undefined}
                          contentAfter={undefined}
                          maxLength={undefined}
                          showCount={undefined}
                          defaultValue={undefined}
                          loading={undefined}
                          status={undefined}
                          size={undefined}
                        />
                        <Button
                          onClick={async () => {
                            if (
                              notifyMePhoneNumber.length !== 14 ||
                              setMenuItemNotificationPhone.isSuccess
                            ) {
                              return;
                            }
                            trackMenuItemRestockReminderSignupClicked(siteSettings.chefId);
                            try {
                              ensureOk(
                                await setMenuItemNotificationPhone.mutateAsync({
                                  chefId: siteSettings.chefId,
                                  menuItemId: menuItem.id,
                                  phone: "+1" + getUnFormattedPhone(notifyMePhoneNumber),
                                  eventId: event.id,
                                })
                              );
                            } catch (e) {
                              setToastPopupInfo({
                                text: "Failed to sign up for reminders, please try again.",
                                type: "error",
                              });
                            }
                          }}
                          loading={setMenuItemNotificationPhone.isLoading}
                          color={setMenuItemNotificationPhone.isSuccess ? "success" : "accent"}
                          variant={"filled"}
                          css={{ mt: "$xs", width: "100%" }}
                        >
                          {setMenuItemNotificationPhone.isSuccess ? (
                            <CheckCircledIcon color="white" />
                          ) : (
                            "Notify Me"
                          )}
                        </Button>
                      </Column>
                    </Column>
                  </Callout>
                )}
                <Row css={{ gap: "$xs" }}>
                  {inventoryAvailable >= 0 && showInventory && eventStatus !== "complete" && (
                    <MenuInventory
                      css={{
                        backgroundColor: `$${eventIsLive ? "danger" : "gray"}4`,
                        color: `$${eventIsLive ? "danger" : "gray"}11`,
                      }}
                      value={inventoryAvailable}
                      text={eventIsLive ? "left" : "available"}
                    />
                  )}
                  {eventIsLive && !itemIsSoldOut && inventoryInCustomerCarts !== 0 && (
                    <MenuInventory
                      css={{ backgroundColor: "$accent4", color: "$accent11" }}
                      value={
                        !siteSettings?.isVisibleNumItemsInCartCapped ||
                        inventoryInCustomerCarts < 500
                          ? inventoryInCustomerCarts
                          : "500+"
                      }
                      text="in carts"
                    />
                  )}
                  {itemIsSoldOut && (
                    <Row
                      css={{
                        backgroundColor: "$danger4",
                        color: "$danger11",
                        h: "$xs_btn",
                        borderRadius: "$sm",
                        alignItems: "center",
                        padx: "$xs",
                        ff: "$inter",
                        textStyle: "text-1",
                        fw: "$semi_bold",
                        truncateText: true,
                        "@desktop_md": {
                          textStyle: "text-2",
                        },
                      }}
                    >
                      Sold Out
                    </Row>
                  )}
                  {!!numCustomersPresentOnItem && numCustomersPresentOnItem > 1 && (
                    <MenuInventory
                      css={{ backgroundColor: "$danger4", color: "$danger11", ml: "auto" }}
                      value={numCustomersPresentOnItem}
                      text={"people here"}
                    />
                  )}
                </Row>
                <Title>{menuItem.title}</Title>
                <Description>{menuItem.description}</Description>
              </Column>
              {/* Option Categories */}
              {Array.isArray(cartItem.optionCategories) &&
                cartItem.optionCategories.map(
                  (optionCategory: OptionCategory, optionCategoryIndex: number) => {
                    if (
                      !Array.isArray(optionCategory.options) ||
                      optionCategory.options.length === 0
                    )
                      return null;
                    return (
                      <OptionCategorySelect
                        key={optionCategoryIndex}
                        id={`option-category_${optionCategoryIndex}`} // used for to auto scroll to it if its not completed
                        optionCategoryIndex={optionCategoryIndex} // to be passed into functions below
                        menuItemQuantityCustomerWants={quantity} // to be passed into functions below
                        optionCategory={optionCategory}
                        incomplete={idxOfIncompleteOptionCategory === optionCategoryIndex}
                        setMenuItemModalSelectedOptions={setMenuItemModalSelectedOptions}
                        setOptionQuantity={setOptionQuantity}
                        getOptionInventoryRemaining={getOptionInventoryRemaining}
                      />
                    );
                  }
                )}
              {menuItem.notesEnabled && (
                <>
                  <Flex css={{ marginTop: "$lg" }}>
                    <SmartTextArea
                      className="item-notes"
                      disabled={false}
                      placeholder="Add Special Instructions..."
                      small
                      maxLength="250"
                      text={typeof notes === "string" ? notes : ""}
                      onChange={(text: string) => setNotes(text)}
                    />
                  </Flex>
                  {menuItem.notesRequired && (
                    <Error show css={{ fontSize: "$md" }}>
                      Required
                    </Error>
                  )}
                </>
              )}
              <CustomerMenuItem.ChangeQuantity
                showMaxPerCustomer={
                  isAnySelectedOptionAtInventoryLimit ||
                  (maxPerCustomer <= 999 &&
                    getMenuItemInventoryInCart(menuItem) + quantity >= maxPerCustomer)
                }
                maxPerCustomer={
                  isAnySelectedOptionAtInventoryLimit
                    ? `Your choice "${selectedOptionWithLeastInventory.title}" in "${categoryOfSelectedOptionWithLeastInventory.title}" only has ${selectedOptionWithLeastInventory.inventory} available`
                    : `Maximum of ${maxPerCustomer} per customer`
                }
                quantity={`${quantity}`}
                decrement={
                  <CustomerMenuItem.ChangeQuantity.QuantityButton
                    onClick={decrementMenuItemModalQuantity}
                    icon={icons.minus}
                  />
                }
                increment={
                  <CustomerMenuItem.ChangeQuantity.QuantityButton
                    onClick={incrementMenuItemModalQuantity}
                    icon={icons.plus}
                  />
                }
              />
            </ContentBox>
          </Flex>

          {/* Add to Cart Button */}
          <CartButtonBox>
            <Button
              variant="filled"
              color="accent"
              shape={"rounded"}
              loading={isAddToCartLoading}
              disabled={isAddToCartButtonDisabled}
              size="large"
              css={{
                width: "90%",
                boxShadow: "$elevation3",
                backgroundColor: chefStyles.primaryColor + " !important",
                color: chefStyles.textColor + " !important",
                "&:hover": {
                  backgroundColor: chefStyles.primaryColorHover + " !important",
                },
                "&:active": {
                  backgroundColor: chefStyles.primaryColorActive + " !important",
                },
                "&:disabled": {
                  backgroundColor: chefStyles.primaryColorLight + " !important",
                },
                "& p": {
                  color: chefStyles.textColor + " !important",
                },
              }}
              onClick={async () => {
                // a change in chefId, eventId, fulfillmentType results in cart being reset
                if (cart?.cartItems.length > 0) {
                  if (cart?.eventId && cart.eventId !== event.id) {
                    setOptionSelectModalMessage(
                      "You have an item from another event in your Cart. Would you like to clear your cart?"
                    );
                    setShowOptionSelectModal(true);
                    return;
                  } else if (cart.fulfillmentType !== fulfillmentType) {
                    setOptionSelectModalMessage(
                      `Your cart is for ${cart.fulfillmentType}, but you are adding an item for ${fulfillmentType}. Would you like to clear your cart?`
                    );
                    setShowOptionSelectModal(true);
                    return;
                  }
                }
                attemptAddToCart();
              }}
            >
              <Row css={{ w: "$full", jc: "space-between" }}>
                <P>{getAddToCartButtonText(status, getEventStatus(event))}</P>
                <P>{formatToCurrency(getCartItemPrice(cartItem), "en-US", "USD")}</P>
              </Row>
            </Button>
          </CartButtonBox>
        </ScrollArea>
        <DialogClose
          css={{
            backgroundColor: imgSrc ? "$overlay8" : "transparent",
            color: imgSrc ? "$gray1" : "$gray11",
            "&:hover": {
              backgroundColor: imgSrc ? "$overlay9" : "$overlay5",
            },
          }}
        />
      </DialogContent>
    </DialogRoot>
  );
};
