// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import component_0 from "./AccountPage";
import component_1 from "./AccountPage.MenuButton";
import component_2 from "./BillingBanner";
import component_3 from "./ManageBilling";
import component_4 from "./PlanCard";
export const AccountPage = component_0;
export const BillingBanner = component_2;
export const ManageBilling = component_3;
export const PlanCard = component_4;
AccountPage.MenuButton = component_1;
