// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./WarningBubble.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      VpiXafWgmN: {
        none: {
          icon: icons.info,
          useMask: true,
        },
      },
      "57LkUyFyeo": {
        none: {
          text:
            "The amount being refunded is different from the value of the items you selected.",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "kind",
    values: [
      {
        propValue: "info",
        layers: {},
      },
      {
        propValue: "caution",
        layers: {
          VpiXafWgmN: {
            none: {
              icon: icons.info,
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "warning",
        layers: {
          VpiXafWgmN: {
            none: {
              icon: icons.alertTriangle,
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "success",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "kind",
    values: [
      {
        propValue: "info",
        layers: {},
      },
      {
        propValue: "caution",
        layers: {},
      },
      {
        propValue: "warning",
        layers: {},
      },
      {
        propValue: "success",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "kind",
    propValues: ["info", "caution", "warning", "success"],
  },
];

export const WarningBubbleContext = createContext(null);

function WarningBubble(_props) {
  const defaults = useContext(WarningBubbleContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="BymWtApc7p"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "BymWtApc7p",
        scope: "5Yb9c27ESn",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            key={"Wz7eWdNMRQ"}
            id={"Slot_Wz7eWdNMRQ"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_5Yb9c27ESn_Wz7eWdNMRQ"
            }
            addSpacing={false}
          >
            {props.animation}
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"icon-shadow_KYJ3uXvn82"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_5Yb9c27ESn_KYJ3uXvn82"
            }
            key={"KYJ3uXvn82"}
            addSpacing={false}
            element={getStyle("KYJ3uXvn82", "htmlElement")}
          >
            {
              <IconPrimitive
                id={"Icon_VpiXafWgmN"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_5Yb9c27ESn_VpiXafWgmN"
                }
                key={"VpiXafWgmN"}
                useMask={getStyle("VpiXafWgmN", "useMask")}
                src={getStyle("VpiXafWgmN", "icon")}
              />
            }
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_Ki4dTvq48P"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_5Yb9c27ESn_Ki4dTvq48P"
            }
            key={"Ki4dTvq48P"}
          />
          <TextPrimitive
            id={"Text_57LkUyFyeo"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_5Yb9c27ESn_57LkUyFyeo"
            }
            key={"57LkUyFyeo"}
            text={
              exists(props.text) ? props.text : getStyle("57LkUyFyeo", "text")
            }
            element={getStyle("57LkUyFyeo", "htmlElement")}
          />
          <ContainerPrimitive
            key={"8eVn1GbaWw"}
            id={"Slot_8eVn1GbaWw"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_5Yb9c27ESn_8eVn1GbaWw"
            }
            addSpacing={false}
          >
            {props.children}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

WarningBubble.__variants = [
  {
    name: "kind",
    type: "group",
    variants: ["info", "caution", "warning", "success"],
  },
];

export default WarningBubble;
