// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Badge.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      "98kaKZvitd": {
        none: {
          text: "Text",
        },
      },
      EKpG3Kwcjq: {
        none: {
          icon: icons.refreshing,
          useMask: true,
        },
      },
      "8j3LjeLNj3": {
        none: {
          icon: icons.close,
          useMask: true,
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "withIcon",
    layers: {
      EKpG3Kwcjq: {
        none: {
          useMask: true,
        },
      },
      "8j3LjeLNj3": {
        none: {
          icon: icons.close,
          useMask: true,
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "small",
    layers: {},
  },
  {
    type: "boolean",
    propName: "xsmall",
    layers: {},
  },
  {
    type: "boolean",
    propName: "noIntWithIcon",
    layers: {},
  },
  {
    type: "boolean",
    propName: "noInt",
    layers: {},
  },
  {
    type: "enum",
    propName: "solid",
    values: [
      {
        propValue: "primary",
        layers: {},
      },
      {
        propValue: "secondary",
        layers: {},
      },
      {
        propValue: "warning",
        layers: {},
      },
      {
        propValue: "black",
        layers: {
          "98kaKZvitd": {
            none: {
              text: "Text",
            },
          },
        },
      },
      {
        propValue: "catering",
        layers: {},
      },
      {
        propValue: "good",
        layers: {},
      },
      {
        propValue: "subscription",
        layers: {},
      },
    ],
  },
  {
    type: "enum",
    propName: "light",
    values: [
      {
        propValue: "primary",
        layers: {
          EKpG3Kwcjq: {
            none: {
              useMask: true,
            },
          },
          "8j3LjeLNj3": {
            none: {
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "secondary",
        layers: {
          EKpG3Kwcjq: {
            none: {
              useMask: true,
            },
          },
          "8j3LjeLNj3": {
            none: {
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "warning",
        layers: {
          EKpG3Kwcjq: {
            none: {
              useMask: true,
            },
          },
          "8j3LjeLNj3": {
            none: {
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "black",
        layers: {
          EKpG3Kwcjq: {
            none: {
              useMask: true,
            },
          },
          "8j3LjeLNj3": {
            none: {
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "catering",
        layers: {
          "8j3LjeLNj3": {
            none: {
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "good",
        layers: {
          EKpG3Kwcjq: {
            none: {
              useMask: true,
            },
          },
          "8j3LjeLNj3": {
            none: {
              useMask: true,
            },
          },
        },
      },
    ],
  },
  {
    type: "boolean",
    propName: "round",
    layers: {
      "98kaKZvitd": {
        none: {
          text: "23",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "withIcon",
    layers: {},
  },
  {
    type: "boolean",
    propName: "small",
    layers: {},
  },
  {
    type: "boolean",
    propName: "xsmall",
    layers: {},
  },
  {
    type: "boolean",
    propName: "noIntWithIcon",
    layers: {},
  },
  {
    type: "boolean",
    propName: "noInt",
    layers: {},
  },
  {
    type: "enum",
    propName: "solid",
    values: [
      {
        propValue: "primary",
        layers: {},
      },
      {
        propValue: "secondary",
        layers: {},
      },
      {
        propValue: "warning",
        layers: {},
      },
      {
        propValue: "black",
        layers: {},
      },
      {
        propValue: "catering",
        layers: {},
      },
      {
        propValue: "good",
        layers: {},
      },
      {
        propValue: "subscription",
        layers: {},
      },
    ],
  },
  {
    type: "enum",
    propName: "light",
    values: [
      {
        propValue: "primary",
        layers: {},
      },
      {
        propValue: "secondary",
        layers: {},
      },
      {
        propValue: "warning",
        layers: {},
      },
      {
        propValue: "black",
        layers: {},
      },
      {
        propValue: "catering",
        layers: {},
      },
      {
        propValue: "good",
        layers: {},
      },
    ],
  },
  {
    type: "boolean",
    propName: "round",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "withIcon",
  },
  {
    type: "boolean",
    propName: "small",
  },
  {
    type: "boolean",
    propName: "xsmall",
  },
  {
    type: "boolean",
    propName: "noIntWithIcon",
  },
  {
    type: "boolean",
    propName: "noInt",
  },
  {
    type: "enum",
    propName: "solid",
    propValues: [
      "primary",
      "secondary",
      "warning",
      "black",
      "catering",
      "good",
      "subscription",
    ],
  },
  {
    type: "enum",
    propName: "light",
    propValues: [
      "primary",
      "secondary",
      "warning",
      "black",
      "catering",
      "good",
    ],
  },
  {
    type: "boolean",
    propName: "round",
  },
];

export const BadgeContext = createContext(null);

function Badge(_props) {
  const defaults = useContext(BadgeContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="HCb91eiUZn"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "HCb91eiUZn",
        scope: "3sBeXgUGmK",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"THicc_ThNZ4T2cjf"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_3sBeXgUGmK_ThNZ4T2cjf"
          }
          key={"ThNZ4T2cjf"}
          addSpacing={false}
          element={getStyle("ThNZ4T2cjf", "htmlElement")}
        >
          {
            <ContainerPrimitive
              id={"Container_M6MAcstm2G"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_3sBeXgUGmK_M6MAcstm2G"
              }
              key={"M6MAcstm2G"}
              addSpacing={false}
              element={getStyle("M6MAcstm2G", "htmlElement")}
            >
              <ContainerPrimitive
                id={"Container_3UKZ5fHv3X"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_3sBeXgUGmK_3UKZ5fHv3X"
                }
                key={"3UKZ5fHv3X"}
                addSpacing={false}
                element={getStyle("3UKZ5fHv3X", "htmlElement")}
              >
                {
                  <IconPrimitive
                    id={"Icon_8j3LjeLNj3"}
                    className={
                      "__visly_reset_7X7HTLRqyD __visly_scope_3sBeXgUGmK_8j3LjeLNj3"
                    }
                    key={"8j3LjeLNj3"}
                    useMask={getStyle("8j3LjeLNj3", "useMask")}
                    src={getStyle("8j3LjeLNj3", "icon")}
                  />
                }
              </ContainerPrimitive>
              <ContainerPrimitive
                id={"Container_MALG5pjXZq"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_3sBeXgUGmK_MALG5pjXZq"
                }
                key={"MALG5pjXZq"}
                addSpacing={false}
                element={getStyle("MALG5pjXZq", "htmlElement")}
              >
                <ContainerPrimitive
                  id={"Container_4enDW9hPWL"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_3sBeXgUGmK_4enDW9hPWL"
                  }
                  key={"4enDW9hPWL"}
                  addSpacing={false}
                  element={getStyle("4enDW9hPWL", "htmlElement")}
                >
                  <IconPrimitive
                    id={"Icon_EKpG3Kwcjq"}
                    className={
                      "__visly_reset_7X7HTLRqyD __visly_scope_3sBeXgUGmK_EKpG3Kwcjq"
                    }
                    key={"EKpG3Kwcjq"}
                    useMask={getStyle("EKpG3Kwcjq", "useMask")}
                    src={
                      exists(props.icon)
                        ? props.icon
                        : getStyle("EKpG3Kwcjq", "icon")
                    }
                  />
                  <SpacerPrimitive
                    id={"Spacer_TUhNbFqz3F"}
                    className={
                      "__visly_reset_7X7HTLRqyD __visly_scope_3sBeXgUGmK_TUhNbFqz3F"
                    }
                    key={"TUhNbFqz3F"}
                  />
                </ContainerPrimitive>
                <SpacerPrimitive
                  id={"Spacer_XT7i38pma3"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_3sBeXgUGmK_XT7i38pma3"
                  }
                  key={"XT7i38pma3"}
                />
              </ContainerPrimitive>
              <ContainerPrimitive
                id={"Container_Vtg35HTR5y"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_3sBeXgUGmK_Vtg35HTR5y"
                }
                key={"Vtg35HTR5y"}
                addSpacing={false}
                element={getStyle("Vtg35HTR5y", "htmlElement")}
              >
                {
                  <TextPrimitive
                    id={"Text_98kaKZvitd"}
                    className={
                      "__visly_reset_7X7HTLRqyD __visly_scope_3sBeXgUGmK_98kaKZvitd"
                    }
                    key={"98kaKZvitd"}
                    text={
                      exists(props.text)
                        ? props.text
                        : getStyle("98kaKZvitd", "text")
                    }
                    element={getStyle("98kaKZvitd", "htmlElement")}
                  />
                }
              </ContainerPrimitive>
            </ContainerPrimitive>
          }
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

Badge.__variants = [
  {
    name: "withIcon",
    type: "variant",
  },
  {
    name: "small",
    type: "variant",
  },
  {
    name: "xsmall",
    type: "variant",
  },
  {
    name: "noIntWithIcon",
    type: "variant",
  },
  {
    name: "noInt",
    type: "variant",
  },
  {
    name: "solid",
    type: "group",
    variants: [
      "primary",
      "secondary",
      "warning",
      "black",
      "catering",
      "good",
      "subscription",
    ],
  },
  {
    name: "light",
    type: "group",
    variants: ["primary", "secondary", "warning", "black", "catering", "good"],
  },
  {
    name: "round",
    type: "variant",
  },
];

export default Badge;
