/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Portal } from "react-portal";
import { useDispatch, useSelector } from "react-redux";
import { setToastPopupInfo } from "../../hotplate-storefront/actions";
import { useBreakpoint } from "../../visly";
import { Toast } from "../../visly/Primitives";
import "./css/Toast.css";

const ToastMaster = () => {
  const dispatch = useDispatch();
  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);
  const [isOpen, setIsOpen] = useState(false);
  const toastPopupInfo = useSelector((state) => state.hostPortal.toastPopupInfo);

  function closeToast() {
    setIsOpen(false);
    setTimeout(() => setToastPopupInfo(null)(dispatch), 775);
  }
  useEffect(() => {
    setToastPopupInfo(null)(dispatch);
    return () => {
      setToastPopupInfo(null)(dispatch);
    };
  }, []);
  useEffect(() => {
    if (toastPopupInfo !== null) {
      setTimeout(() => setIsOpen(true), 250);
      setTimeout(
        () => closeToast(),
        Math.min(Math.max(toastPopupInfo.text.length * 50, 3000), 7000)
      );
    }
  }, [toastPopupInfo]);

  return (
    <Portal>
      <div className={toastPopupInfo !== null ? "toast-container" : "toast-container closed"}>
        {toastPopupInfo && (
          <Toast
            size={size}
            isOpen={isOpen} // animate on this variable
            type={toastPopupInfo.type}
            text={toastPopupInfo.text}
            onClick={toastPopupInfo.onClick ? toastPopupInfo.onClick : closeToast}
            CloseButton={
              <Toast.CloseButton
                light={
                  toastPopupInfo.type === "success" ||
                  toastPopupInfo.type === "warning" ||
                  toastPopupInfo.type === "error"
                }
                onClick={closeToast}
              />
            }
          />
        )}
      </div>
    </Portal>
  );
};

export default ToastMaster;
