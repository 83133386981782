// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./ConfigMenu.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import {
  default as AddSectionButton,
  ButtonContext as AddSectionButtonContext,
  default as AddItemButton,
  ButtonContext as AddItemButtonContext,
  default as SaveAndContinueButton,
  ButtonContext as SaveAndContinueButtonContext,
} from "../Primitives/Button";
import { default as EditMenuSettings } from "../Events/EditMenuSettings";

const styles = [
  {
    type: "default",
    layers: {
      QEskUnRt56: {
        none: {
          text: "Menu Controls",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "isEmpty",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      FeQmU4fg7i: {
        none: {
          text: "Add Section",
          rounded: true,
        },
      },
      THRYjDwL15: {
        none: {
          text: "Add Item",
          rounded: true,
          kind: "primary",
        },
      },
      L3V1xmUR9p: {
        none: {
          text: "Save & Continue",
          large: true,
          kind: "primary",
        },
      },
      TKVxBjtLHV: {},
    },
  },
  {
    type: "boolean",
    propName: "isEmpty",
    layers: {
      FeQmU4fg7i: {},
      THRYjDwL15: {},
      L3V1xmUR9p: {},
      TKVxBjtLHV: {},
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {
          FeQmU4fg7i: {},
          THRYjDwL15: {},
          L3V1xmUR9p: {},
          TKVxBjtLHV: {},
        },
      },
      {
        propValue: "small",
        layers: {
          FeQmU4fg7i: {},
          THRYjDwL15: {},
          L3V1xmUR9p: {},
          TKVxBjtLHV: {},
        },
      },
      {
        propValue: "med",
        layers: {
          FeQmU4fg7i: {},
          THRYjDwL15: {},
          L3V1xmUR9p: {},
          TKVxBjtLHV: {},
        },
      },
      {
        propValue: "large",
        layers: {
          FeQmU4fg7i: {},
          THRYjDwL15: {},
          L3V1xmUR9p: {},
          TKVxBjtLHV: {},
        },
      },
      {
        propValue: "xlarge",
        layers: {
          FeQmU4fg7i: {},
          THRYjDwL15: {},
          L3V1xmUR9p: {},
          TKVxBjtLHV: {},
        },
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "isEmpty",
  },
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export const ConfigMenuContext = createContext(null);

function ConfigMenu(_props) {
  const defaults = useContext(ConfigMenuContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="XQZnffvA1z"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "XQZnffvA1z",
        scope: "567qHZp2Ak",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"menu-container_7NfuLxYN7s"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_567qHZp2Ak_7NfuLxYN7s"
            }
            key={"7NfuLxYN7s"}
            addSpacing={false}
            element={getStyle("7NfuLxYN7s", "htmlElement")}
          >
            <ContainerPrimitive
              id={"button-container_GzccESoaVZ"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_567qHZp2Ak_GzccESoaVZ"
              }
              key={"GzccESoaVZ"}
              addSpacing={false}
              element={getStyle("GzccESoaVZ", "htmlElement")}
            >
              {[
                props.AddSectionButton === undefined ? (
                  <AddSectionButton
                    key={"FeQmU4fg7i"}
                    {...getCompositeDefaultProps("FeQmU4fg7i")}
                    id="AddSectionButton_FeQmU4fg7i"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_567qHZp2Ak_FeQmU4fg7i"
                  />
                ) : (
                  <AddSectionButtonContext.Provider
                    key="FeQmU4fg7i-provider"
                    value={{
                      key: "FeQmU4fg7i",
                      id: "AddSectionButton_FeQmU4fg7i",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_567qHZp2Ak_FeQmU4fg7i",
                      ...getCompositeDefaultProps("FeQmU4fg7i"),
                    }}
                  >
                    {props.AddSectionButton}
                  </AddSectionButtonContext.Provider>
                ),
                <SpacerPrimitive
                  id={"Spacer_QTwTvZH7ch"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_567qHZp2Ak_QTwTvZH7ch"
                  }
                  key={"QTwTvZH7ch"}
                />,
                props.AddItemButton === undefined ? (
                  <AddItemButton
                    key={"THRYjDwL15"}
                    {...getCompositeDefaultProps("THRYjDwL15")}
                    id="AddItemButton_THRYjDwL15"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_567qHZp2Ak_THRYjDwL15"
                  />
                ) : (
                  <AddItemButtonContext.Provider
                    key="THRYjDwL15-provider"
                    value={{
                      key: "THRYjDwL15",
                      id: "AddItemButton_THRYjDwL15",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_567qHZp2Ak_THRYjDwL15",
                      ...getCompositeDefaultProps("THRYjDwL15"),
                    }}
                  >
                    {props.AddItemButton}
                  </AddItemButtonContext.Provider>
                ),
              ]}
            </ContainerPrimitive>
            <SpacerPrimitive
              id={"Spacer_HvewmYRBrJ"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_567qHZp2Ak_HvewmYRBrJ"
              }
              key={"HvewmYRBrJ"}
            />
            <ContainerPrimitive
              key={"3r3qRsiEXA"}
              id={"Slot_3r3qRsiEXA"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_567qHZp2Ak_3r3qRsiEXA"
              }
              addSpacing={true}
            >
              {props.children}
            </ContainerPrimitive>
            <SpacerPrimitive
              id={"Spacer_WdijECSmD6"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_567qHZp2Ak_WdijECSmD6"
              }
              key={"WdijECSmD6"}
            />
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_QN6LLNPYvn"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_567qHZp2Ak_QN6LLNPYvn"
            }
            key={"QN6LLNPYvn"}
          />
          <TextPrimitive
            id={"Menu Controls_QEskUnRt56"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_567qHZp2Ak_QEskUnRt56"
            }
            key={"QEskUnRt56"}
            text={getStyle("QEskUnRt56", "text")}
            element={getStyle("QEskUnRt56", "htmlElement")}
          />
          <SpacerPrimitive
            id={"Spacer_8DBa5Xaij9"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_567qHZp2Ak_8DBa5Xaij9"
            }
            key={"8DBa5Xaij9"}
          />
          <ContainerPrimitive
            id={"menu-options-container_XtzczuwyrL"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_567qHZp2Ak_XtzczuwyrL"
            }
            key={"XtzczuwyrL"}
            addSpacing={false}
            element={getStyle("XtzczuwyrL", "htmlElement")}
          >
            {
              <ContainerPrimitive
                key={"XPEPep8C9t"}
                id={"Slot_XPEPep8C9t"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_567qHZp2Ak_XPEPep8C9t"
                }
                addSpacing={false}
              >
                {props.menuSettings}
              </ContainerPrimitive>
            }
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_CizwG4uRVh"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_567qHZp2Ak_CizwG4uRVh"
            }
            key={"CizwG4uRVh"}
          />
          <ContainerPrimitive
            id={"Container_QxAvGF42iT"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_567qHZp2Ak_QxAvGF42iT"
            }
            key={"QxAvGF42iT"}
            addSpacing={false}
            element={getStyle("QxAvGF42iT", "htmlElement")}
          >
            {props.SaveAndContinueButton === undefined ? (
              <SaveAndContinueButton
                key={"L3V1xmUR9p"}
                {...getCompositeDefaultProps("L3V1xmUR9p")}
                id="SaveAndContinueButton_L3V1xmUR9p"
                className="__visly_reset_7X7HTLRqyD __visly_scope_567qHZp2Ak_L3V1xmUR9p"
              />
            ) : (
              <SaveAndContinueButtonContext.Provider
                key="L3V1xmUR9p-provider"
                value={{
                  key: "L3V1xmUR9p",
                  id: "SaveAndContinueButton_L3V1xmUR9p",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_567qHZp2Ak_L3V1xmUR9p",
                  ...getCompositeDefaultProps("L3V1xmUR9p"),
                }}
              >
                {props.SaveAndContinueButton}
              </SaveAndContinueButtonContext.Provider>
            )}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

ConfigMenu.AddSectionButton = AddSectionButton;
ConfigMenu.AddItemButton = AddItemButton;
ConfigMenu.SaveAndContinueButton = SaveAndContinueButton;
ConfigMenu.EditMenuSettings = EditMenuSettings;
ConfigMenu.__variants = [
  {
    name: "isEmpty",
    type: "variant",
  },
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export default ConfigMenu;
