// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./PickupTime.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      NNfvysB2bD: {
        none: {
          text: "Your order will be ready",
        },
      },
      Atkcn55gqF: {
        none: {
          text: "Wan, 13/7",
        },
      },
      "2S7QxbNwXN": {
        none: {
          text: "at",
        },
      },
      HZQkpF7GZF: {
        none: {
          text: "11:00 PM",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "multiple",
    layers: {},
  },
  {
    type: "boolean",
    propName: "onlyDate",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "multiple",
    layers: {},
  },
  {
    type: "boolean",
    propName: "onlyDate",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "multiple",
  },
  {
    type: "boolean",
    propName: "onlyDate",
  },
];

export const PickupTimeContext = createContext(null);

function PickupTime(_props) {
  const defaults = useContext(PickupTimeContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="F7tozQumGH"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "F7tozQumGH",
        scope: "G6tVDXBh5d",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_HsdaD5kMhw"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_G6tVDXBh5d_HsdaD5kMhw"
          }
          key={"HsdaD5kMhw"}
          addSpacing={false}
          element={getStyle("HsdaD5kMhw", "htmlElement")}
        >
          <TextPrimitive
            id={"readyText_NNfvysB2bD"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_G6tVDXBh5d_NNfvysB2bD"
            }
            key={"NNfvysB2bD"}
            text={
              exists(props.readyText)
                ? props.readyText
                : getStyle("NNfvysB2bD", "text")
            }
            element={getStyle("NNfvysB2bD", "htmlElement")}
          />
          <SpacerPrimitive
            id={"Spacer_NTcSboaEUS"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_G6tVDXBh5d_NTcSboaEUS"
            }
            key={"NTcSboaEUS"}
          />
          <ContainerPrimitive
            id={"Container_3NK58WXkug"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_G6tVDXBh5d_3NK58WXkug"
            }
            key={"3NK58WXkug"}
            addSpacing={false}
            element={getStyle("3NK58WXkug", "htmlElement")}
          >
            <TextPrimitive
              id={"date_Atkcn55gqF"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_G6tVDXBh5d_Atkcn55gqF"
              }
              key={"Atkcn55gqF"}
              text={
                exists(props.date) ? props.date : getStyle("Atkcn55gqF", "text")
              }
              element={getStyle("Atkcn55gqF", "htmlElement")}
            />
            <ContainerPrimitive
              id={"Container_CMYF474YwS"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_G6tVDXBh5d_CMYF474YwS"
              }
              key={"CMYF474YwS"}
              addSpacing={false}
              element={getStyle("CMYF474YwS", "htmlElement")}
            >
              <TextPrimitive
                id={"at_2S7QxbNwXN"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_G6tVDXBh5d_2S7QxbNwXN"
                }
                key={"2S7QxbNwXN"}
                text={getStyle("2S7QxbNwXN", "text")}
                element={getStyle("2S7QxbNwXN", "htmlElement")}
              />
              <TextPrimitive
                id={"time_HZQkpF7GZF"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_G6tVDXBh5d_HZQkpF7GZF"
                }
                key={"HZQkpF7GZF"}
                text={
                  exists(props.time)
                    ? props.time
                    : getStyle("HZQkpF7GZF", "text")
                }
                element={getStyle("HZQkpF7GZF", "htmlElement")}
              />
            </ContainerPrimitive>
          </ContainerPrimitive>
          <ContainerPrimitive
            key={"Azg8VeVED5"}
            id={"Slot_Azg8VeVED5"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_G6tVDXBh5d_Azg8VeVED5"
            }
            addSpacing={false}
          >
            {props.children}
          </ContainerPrimitive>
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

PickupTime.__variants = [
  {
    name: "multiple",
    type: "variant",
  },
  {
    name: "onlyDate",
    type: "variant",
  },
];

export default PickupTime;
