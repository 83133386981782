import _ from "lodash";

import {
  SET_DELIVERY_FEE,
  SET_CUSTOMER_DELIVERY_INSTRUCTIONS,
  SET_CUSTOMER_ADDRESS_DICT,
  SET_CUSTOMER_ADDRESS_UNIT,
  GET_SITE_SETTINGS_START,
  GET_SITE_SETTINGS_SUCCESS,
  GET_SITE_SETTINGS_FAILURE,
} from "../actions/types";

const INITIAL_STATE = {
  siteSettings: {},
  getSiteSettingsLoading: false,
  getSiteSettingsError: "",

  customerAddressDict: null,
  customerDeliveryInstructions: "",
  deliveryFee: "0.00",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SITE_SETTINGS_START:
      return {
        ...state,
        getSiteSettingsLoading: true,
        getSiteSettingsError: "",
      };
    case GET_SITE_SETTINGS_SUCCESS:
      return {
        ...state,
        getSiteSettingsLoading: false,
        siteSettings: action.payload,
      };
    case GET_SITE_SETTINGS_FAILURE:
      return {
        ...state,
        getSiteSettingsLoading: false,
        getSiteSettingsError: action.payload,
      };
    case SET_DELIVERY_FEE:
      return { ...state, deliveryFee: action.payload };
    case SET_CUSTOMER_DELIVERY_INSTRUCTIONS:
      return { ...state, customerDeliveryInstructions: action.payload };
    case SET_CUSTOMER_ADDRESS_DICT:
      return { ...state, customerAddressDict: action.payload };
    case SET_CUSTOMER_ADDRESS_UNIT: {
      let addressDict = _.cloneDeep(state.customerAddressDict);
      if (!addressDict || addressDict.constructor != Object) addressDict = {};
      addressDict.unit = action.payload;
      return { ...state, customerAddressDict: addressDict };
    }
    default:
      return state;
  }
};
