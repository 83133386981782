// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Toast.CloseButton.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, IconPrimitive } from "../_internal_primitives";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      "3qsqTseqey": {
        none: {
          icon: icons.close,
          useMask: true,
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "light",
    layers: {
      "3qsqTseqey": {
        none: {
          useMask: true,
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "light",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "light",
  },
];

export const CloseButtonContext = createContext(null);

function CloseButton(_props) {
  const defaults = useContext(CloseButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="U6rtzvJLbs"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "U6rtzvJLbs",
        scope: "6YYtKtH2j8",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <IconPrimitive
          id={"Icon_3qsqTseqey"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_6YYtKtH2j8_3qsqTseqey"
          }
          key={"3qsqTseqey"}
          useMask={getStyle("3qsqTseqey", "useMask")}
          src={getStyle("3qsqTseqey", "icon")}
        />
      )}
    </RootPrimitive>
  );
}

CloseButton.__variants = [
  {
    name: "light",
    type: "variant",
  },
];

export default CloseButton;
