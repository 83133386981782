// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./ItemsCard.Item.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../../_internal_primitives";
import * as icons from "../../icons";
import { default as Select, SelectContext } from "../../Primitives/Select";

const styles = [
  {
    type: "default",
    layers: {
      D6Mk9Y3NHE: {
        none: {
          text: "",
        },
      },
      "6E2h2U3MSB": {
        none: {
          text: "Chicken under a brick",
          htmlElement: "h6",
        },
      },
      "3khbTazJtq": {
        none: {
          text: "43",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
      {
        propValue: "xsmall",
        layers: {
          D6Mk9Y3NHE: {
            none: {
              htmlElement: "h6",
            },
          },
          "6E2h2U3MSB": {
            none: {
              htmlElement: "h6",
            },
          },
        },
      },
    ],
  },
  {
    type: "boolean",
    propName: "hasVariations",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      HhFMrWBD8v: {
        none: {
          icon: icons.moreVertical,
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large",
        layers: {
          HhFMrWBD8v: {},
        },
      },
      {
        propValue: "med",
        layers: {
          HhFMrWBD8v: {},
        },
      },
      {
        propValue: "small",
        layers: {
          HhFMrWBD8v: {},
        },
      },
      {
        propValue: "xlarge",
        layers: {
          HhFMrWBD8v: {},
        },
      },
      {
        propValue: "xsmall",
        layers: {
          HhFMrWBD8v: {},
        },
      },
    ],
  },
  {
    type: "boolean",
    propName: "hasVariations",
    layers: {
      HhFMrWBD8v: {},
    },
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["large", "med", "small", "xlarge", "xsmall"],
  },
  {
    type: "boolean",
    propName: "hasVariations",
  },
];

export const ItemContext = createContext(null);

function Item(_props) {
  const defaults = useContext(ItemContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="5FXLWfXh8J"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "5FXLWfXh8J",
        scope: "HkQ6DeZYBu",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_L3ModTpTbo"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_HkQ6DeZYBu_L3ModTpTbo"
          }
          key={"L3ModTpTbo"}
          addSpacing={false}
          element={getStyle("L3ModTpTbo", "htmlElement")}
        >
          <ContainerPrimitive
            id={"default_WvCuDJugBP"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_HkQ6DeZYBu_WvCuDJugBP"
            }
            key={"WvCuDJugBP"}
            addSpacing={false}
            element={getStyle("WvCuDJugBP", "htmlElement")}
          >
            <TextPrimitive
              id={"quantity_3khbTazJtq"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_HkQ6DeZYBu_3khbTazJtq"
              }
              key={"3khbTazJtq"}
              text={
                exists(props.quantity)
                  ? props.quantity
                  : getStyle("3khbTazJtq", "text")
              }
              element={getStyle("3khbTazJtq", "htmlElement")}
            />
            <ContainerPrimitive
              id={"Container_4i2hRNYiNm"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_HkQ6DeZYBu_4i2hRNYiNm"
              }
              key={"4i2hRNYiNm"}
              addSpacing={false}
              element={getStyle("4i2hRNYiNm", "htmlElement")}
            >
              {
                <TextPrimitive
                  id={"name_6E2h2U3MSB"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_HkQ6DeZYBu_6E2h2U3MSB"
                  }
                  key={"6E2h2U3MSB"}
                  text={
                    exists(props.name)
                      ? props.name
                      : getStyle("6E2h2U3MSB", "text")
                  }
                  element={getStyle("6E2h2U3MSB", "htmlElement")}
                />
              }
            </ContainerPrimitive>
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"Container_Qu6Avm6SWW"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_HkQ6DeZYBu_Qu6Avm6SWW"
            }
            key={"Qu6Avm6SWW"}
            addSpacing={false}
            element={getStyle("Qu6Avm6SWW", "htmlElement")}
          >
            {
              <TextPrimitive
                id={"variation_D6Mk9Y3NHE"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_HkQ6DeZYBu_D6Mk9Y3NHE"
                }
                key={"D6Mk9Y3NHE"}
                text={
                  exists(props.variation)
                    ? props.variation
                    : getStyle("D6Mk9Y3NHE", "text")
                }
                element={getStyle("D6Mk9Y3NHE", "htmlElement")}
              />
            }
          </ContainerPrimitive>
          <ContainerPrimitive
            key={"JPSb24yzur"}
            id={"Slot_JPSb24yzur"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_HkQ6DeZYBu_JPSb24yzur"
            }
            addSpacing={false}
          >
            {props.children}
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"Container_AqfkGy78kL"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_HkQ6DeZYBu_AqfkGy78kL"
            }
            key={"AqfkGy78kL"}
            addSpacing={false}
            element={getStyle("AqfkGy78kL", "htmlElement")}
          >
            {props.Select === undefined ? (
              <Select
                key={"HhFMrWBD8v"}
                {...getCompositeDefaultProps("HhFMrWBD8v")}
                id="Select_HhFMrWBD8v"
                className="__visly_reset_7X7HTLRqyD __visly_scope_HkQ6DeZYBu_HhFMrWBD8v"
              />
            ) : (
              <SelectContext.Provider
                key="HhFMrWBD8v-provider"
                value={{
                  key: "HhFMrWBD8v",
                  id: "Select_HhFMrWBD8v",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_HkQ6DeZYBu_HhFMrWBD8v",
                  ...getCompositeDefaultProps("HhFMrWBD8v"),
                }}
              >
                {props.Select}
              </SelectContext.Provider>
            )}
          </ContainerPrimitive>
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

Item.Select = Select;
Item.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["large", "med", "small", "xlarge", "xsmall"],
  },
  {
    name: "hasVariations",
    type: "variant",
  },
];

export default Item;
