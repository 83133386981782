import React from "react";
import * as RadixSwitch from "@radix-ui/react-switch";
import { styled } from "../../stitches.config";
import type { CSS } from "@stitches/react";

const SwitchThumb = styled(RadixSwitch.Thumb, {
  display: "block",
  width: 21,
  height: 21,
  backgroundColor: "$white",
  borderRadius: "$pill",
  boxShadow: `0 2px 2px $overlay7`,
  transition: "transform 100ms",
  transform: "translateX(2px)",
  willChange: "transform",
  '&[data-state="checked"]': { transform: "translateX(19px)" },
  "&[data-disabled]": { opacity: 0.75 },
});

const SwitchRoot = styled(RadixSwitch.Root, {
  all: "unset",
  display: "flex",
  ai: "center",
  flexShrink: 0,
  width: 42,
  height: 25,
  backgroundColor: "$gray9",
  borderRadius: "$pill",
  position: "relative",
  WebkitTapHighlightColor: "$accent9",
  "&:focus": { boxShadow: `0 0 0 2px $accent9` },
  '&[data-state="checked"]': { backgroundColor: "$hotplate9" },
  "&[data-disabled]": { backgroundColor: "$gray5" },
});

interface SwitchProps extends RadixSwitch.SwitchProps {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  defaultChecked?: boolean;
  disabled?: boolean;
  required?: boolean;
  name?: string;
  value?: string;
  css?: CSS;
}

export const Switch = ({ checked, onCheckedChange, css, ...props }: SwitchProps) => {
  return (
    <SwitchRoot css={css} checked={checked} onCheckedChange={onCheckedChange} {...props}>
      <SwitchThumb />
    </SwitchRoot>
  );
};
