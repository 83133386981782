// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import React, { createContext, useContext, useRef, forwardRef } from "react";
import { createPortal } from "react-dom";
import {
  useTooltipTrigger,
  useTooltip,
  useTooltipTriggerState,
  mergeProps,
  useOverlayPosition,
} from "@visly/core";
import { exists, renderChildren } from "./_internal_utils";
import { usePrimitive } from "./_internal_usePrimitive";
export let Gravity;

(function (Gravity) {
  Gravity["Top"] = "top";
  Gravity["Bottom"] = "bottom";
  Gravity["Left"] = "left";
  Gravity["Right"] = "right";
  Gravity["Center"] = "center";
})(Gravity || (Gravity = {}));

const TooltipContext = createContext(null);

function vislyPropsToReactAria(props) {
  return {
    ...props,
    isDisabled: props.disabled,
    delay: exists(props.delayMs) ? props.delayMs : 1000,
    offset: 7,
    crossOffset: 0,
  };
}

const _Tooltip = forwardRef((props, ref) => {
  const { tooltipProps } = useTooltip(props);
  const { style = {}, ...rest } = props;
  const { left, top } = style;
  const position =
    !exists(left) && !exists(top)
      ? {
          left: -100,
          top: -100,
        }
      : {
          left,
          top,
        };
  return (
    <div
      ref={ref}
      {...mergeProps(rest, tooltipProps)}
      style={{
        overflow: "visible",
        position: "relative",
        ...style,
        ...position,
      }}
    >
      {props.children}
    </div>
  );
});

export function TooltipRoot(props) {
  const { values, vislyProps } = usePrimitive({
    ref: null,
    props,
    isInteractive: false,
  });
  const renderInline = props.renderInline || false;
  const newRef = useRef(null);
  const tooltipTriggerRef = exists(props.triggerRef)
    ? props.triggerRef
    : newRef;
  const overlayRef = useRef(null);
  const reactAriaProps = vislyPropsToReactAria(props);
  const state = useTooltipTriggerState(reactAriaProps);
  const { triggerProps, tooltipProps } = useTooltipTrigger(
    reactAriaProps,
    state,
    tooltipTriggerRef
  );
  const { overlayProps, placement } = useOverlayPosition({
    ...reactAriaProps,
    placement: reactAriaProps.placement || "bottom",
    targetRef: tooltipTriggerRef,
    overlayRef,
    isOpen: state.isOpen,
    containerPadding: 0,
  });

  if (renderInline) {
    return (
      <TooltipContext.Provider
        value={{
          color: values[props.internal.layerId].arrowColor,
          placement: "top",
        }}
      >
        <_Tooltip
          ref={props.measureRef}
          style={{
            left: 0,
            top: 0,
          }}
          {...mergeProps(vislyProps, tooltipProps)}
        >
          {renderChildren(props.internalChildren, values)}
        </_Tooltip>
      </TooltipContext.Provider>
    );
  }

  const element =
    typeof props.children === "function"
      ? props.children(tooltipTriggerRef)
      : props.children;
  const trigger = React.isValidElement(element)
    ? React.cloneElement(element, mergeProps(element.props, triggerProps))
    : element;
  return (
    <>
      {trigger}
      <TooltipContext.Provider
        value={{
          color: values[props.internal.layerId].arrowColor,
          placement,
        }}
      >
        {state.isOpen &&
          createPortal(
            <_Tooltip
              ref={overlayRef}
              {...mergeProps(vislyProps, tooltipProps, overlayProps)}
            >
              {renderChildren(props.internalChildren, values)}
            </_Tooltip>,
            document.body
          )}
      </TooltipContext.Provider>
    </>
  );
}
export function TooltipArrow(props) {
  const { color, placement } = useContext(TooltipContext) || {};
  const arrowStyles = {
    position: "absolute",
    content: "",
    backgroundColor: color,
    alignSelf: "center",
    ...arrow(placement),
  };
  return (
    <div
      style={arrowStyles}
      id="arrow"
      className={props.className}
      ref={props.measureRef}
    />
  );
}

const arrow = (placement) => {
  switch (placement) {
    case "top":
      return {
        bottom: "1px",
        marginLeft: 0,
        marginRight: 0,
        transform: "translateY(50%) rotate(45deg)",
      };

    case "bottom":
      return {
        top: "1px",
        marginLeft: 0,
        marginRight: 0,
        transform: "translateY(-50%) rotate(45deg)",
      };

    case "left":
      return {
        right: "1px",
        marginTop: 0,
        marginBottom: 0,
        transform: "translateX(50%) rotate(45deg)",
      };

    case "right":
      return {
        left: "1px",
        marginTop: 0,
        marginBottom: 0,
        transform: "translateX(-50%) rotate(45deg)",
      };
  }
};
