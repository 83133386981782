/* eslint-disable react/prop-types */
import React, { useState } from "react";
import EventCard from "../../../hotplate-common/EventCard";
import { ObjectSelectionModal } from "../../../visly/Events";
import Modal from "../../../hotplate-common/Modal";
import ModalSubpage from "../../components/ModalSubpage";
import { trackEventDuplicated } from "./analytics";

export default function DuplicateEventModal({ events, onClose, onSelect }) {
  const [selectedEventIdx, setSelectedEventIdx] = useState(-1);

  return (
    <Modal closeModal={onClose}>
      <ModalSubpage isFirstPage={true}>
        <ObjectSelectionModal
          className="objectSelection"
          header="Duplicate Event"
          CreateButton={null}
          CancelButton={<ObjectSelectionModal.CancelButton text="Cancel" onClick={onClose} />}
          AddButton={
            <ObjectSelectionModal.AddButton
              disabled={selectedEventIdx === -1}
              onClick={() => {
                onSelect(selectedEventIdx);
                trackEventDuplicated({
                  eventId: events[selectedEventIdx].id,
                });
              }}
              text="Duplicate"
            />
          }
        >
          {Array.isArray(events) &&
            events.map((event, eventIndex) => (
              <EventCard
                type="duplicate"
                event={event}
                key={eventIndex}
                selected={eventIndex === selectedEventIdx}
                onClick={() => {
                  if (selectedEventIdx === eventIndex) {
                    setSelectedEventIdx(-1);
                  } else {
                    setSelectedEventIdx(eventIndex);
                  }
                }}
              />
            ))}
        </ObjectSelectionModal>
      </ModalSubpage>
    </Modal>
  );
}
