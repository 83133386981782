// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./RefundLineItem.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import {
  default as RefundInput,
  RefundQuantityInputContext as RefundInputContext,
} from "../Refund/RefundQuantityInput";

const styles = [
  {
    type: "default",
    layers: {
      C6YkjNi9zu: {
        none: {
          text: "Item Title",
        },
      },
      BQpMAvjHfZ: {
        none: {
          text: "$23.00",
        },
      },
      xpuz5ZSxvc: {
        none: {
          text: "$0.00",
        },
      },
      NBsQzVTRxb: {
        none: {
          text: "",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      UFKr91jQLk: {
        none: {
          disabled: false,
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {
          UFKr91jQLk: {},
        },
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall"],
  },
];

export const RefundLineItemContext = createContext(null);

function RefundLineItem(_props) {
  const defaults = useContext(RefundLineItemContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="Sf1XcqoVQ6"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "Sf1XcqoVQ6",
        scope: "UtDxKVqdXb",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"title-price-container_EJhejowDu5"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_UtDxKVqdXb_EJhejowDu5"
            }
            key={"EJhejowDu5"}
            addSpacing={false}
            element={getStyle("EJhejowDu5", "htmlElement")}
          >
            <TextPrimitive
              id={"title_C6YkjNi9zu"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_UtDxKVqdXb_C6YkjNi9zu"
              }
              key={"C6YkjNi9zu"}
              text={
                exists(props.title)
                  ? props.title
                  : getStyle("C6YkjNi9zu", "text")
              }
              element={getStyle("C6YkjNi9zu", "htmlElement")}
            />
            <TextPrimitive
              id={"options_NBsQzVTRxb"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_UtDxKVqdXb_NBsQzVTRxb"
              }
              key={"NBsQzVTRxb"}
              text={
                exists(props.options)
                  ? props.options
                  : getStyle("NBsQzVTRxb", "text")
              }
              element={getStyle("NBsQzVTRxb", "htmlElement")}
            />
            <TextPrimitive
              id={"price_BQpMAvjHfZ"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_UtDxKVqdXb_BQpMAvjHfZ"
              }
              key={"BQpMAvjHfZ"}
              text={
                exists(props.price)
                  ? props.price
                  : getStyle("BQpMAvjHfZ", "text")
              }
              element={getStyle("BQpMAvjHfZ", "htmlElement")}
            />
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_T7DL5KCDbk"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_UtDxKVqdXb_T7DL5KCDbk"
            }
            key={"T7DL5KCDbk"}
          />
          <ContainerPrimitive
            id={"input-total-container_21ewFiycPN"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_UtDxKVqdXb_21ewFiycPN"
            }
            key={"21ewFiycPN"}
            addSpacing={false}
            element={getStyle("21ewFiycPN", "htmlElement")}
          >
            {[
              props.RefundInput === undefined ? (
                <RefundInput
                  key={"UFKr91jQLk"}
                  {...getCompositeDefaultProps("UFKr91jQLk")}
                  id="RefundInput_UFKr91jQLk"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_UtDxKVqdXb_UFKr91jQLk"
                />
              ) : (
                <RefundInputContext.Provider
                  key="UFKr91jQLk-provider"
                  value={{
                    key: "UFKr91jQLk",
                    id: "RefundInput_UFKr91jQLk",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_UtDxKVqdXb_UFKr91jQLk",
                    ...getCompositeDefaultProps("UFKr91jQLk"),
                  }}
                >
                  {props.RefundInput}
                </RefundInputContext.Provider>
              ),
              <SpacerPrimitive
                id={"Spacer_D8ZebKvY6c"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_UtDxKVqdXb_D8ZebKvY6c"
                }
                key={"D8ZebKvY6c"}
              />,
              <TextPrimitive
                id={"refundAmount_xpuz5ZSxvc"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_UtDxKVqdXb_xpuz5ZSxvc"
                }
                key={"xpuz5ZSxvc"}
                text={
                  exists(props.refundAmount)
                    ? props.refundAmount
                    : getStyle("xpuz5ZSxvc", "text")
                }
                element={getStyle("xpuz5ZSxvc", "htmlElement")}
              />,
            ]}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

RefundLineItem.RefundInput = RefundInput;
RefundLineItem.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["xsmall"],
  },
];

export default RefundLineItem;
