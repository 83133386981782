// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./DeliverySummary.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      "89Db5qrA4A": {
        none: {
          text: "ADDRESS",
        },
      },
      wVYGDAWPSk: {
        none: {
          text: "1337 Gamer Avenue",
        },
      },
      YJTZwsbjFy: {
        none: {
          text: "Suite 5A",
        },
      },
      BCnKu4zn6L: {
        none: {
          text: "San Francisco, CA 94136",
        },
      },
      "2kRYd5tTyA": {
        none: {
          text: "INSTRUCTIONS",
        },
      },
      Pt6dfzXWa5: {
        none: {
          text: "Bring it up to my desk, im streaming rn",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "onlyAddress",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "onlyAddress",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "onlyAddress",
  },
];

export const DeliverySummaryContext = createContext(null);

function DeliverySummary(_props) {
  const defaults = useContext(DeliverySummaryContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="ESc81u7RD8"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "ESc81u7RD8",
        scope: "3BxxyR9HZR",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"ContentBlock_AfSxmqdjCu"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_3BxxyR9HZR_AfSxmqdjCu"
          }
          key={"AfSxmqdjCu"}
          addSpacing={false}
          element={getStyle("AfSxmqdjCu", "htmlElement")}
        >
          <ContainerPrimitive
            id={"Address_DQFA6megbd"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_3BxxyR9HZR_DQFA6megbd"
            }
            key={"DQFA6megbd"}
            addSpacing={false}
            element={getStyle("DQFA6megbd", "htmlElement")}
          >
            <TextPrimitive
              id={"ADDRESS_89Db5qrA4A"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_3BxxyR9HZR_89Db5qrA4A"
              }
              key={"89Db5qrA4A"}
              text={getStyle("89Db5qrA4A", "text")}
              element={getStyle("89Db5qrA4A", "htmlElement")}
            />
            <ContainerPrimitive
              id={"Container_55c3THvHdC"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_3BxxyR9HZR_55c3THvHdC"
              }
              key={"55c3THvHdC"}
              addSpacing={false}
              element={getStyle("55c3THvHdC", "htmlElement")}
            >
              <TextPrimitive
                id={"addressLineOne_wVYGDAWPSk"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_3BxxyR9HZR_wVYGDAWPSk"
                }
                key={"wVYGDAWPSk"}
                text={
                  exists(props.addressLineOne)
                    ? props.addressLineOne
                    : getStyle("wVYGDAWPSk", "text")
                }
                element={getStyle("wVYGDAWPSk", "htmlElement")}
              />
              <TextPrimitive
                id={"addressLineTwo_YJTZwsbjFy"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_3BxxyR9HZR_YJTZwsbjFy"
                }
                key={"YJTZwsbjFy"}
                text={
                  exists(props.addressLineTwo)
                    ? props.addressLineTwo
                    : getStyle("YJTZwsbjFy", "text")
                }
                element={getStyle("YJTZwsbjFy", "htmlElement")}
              />
              <TextPrimitive
                id={"cityStateZip_BCnKu4zn6L"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_3BxxyR9HZR_BCnKu4zn6L"
                }
                key={"BCnKu4zn6L"}
                text={
                  exists(props.cityStateZip)
                    ? props.cityStateZip
                    : getStyle("BCnKu4zn6L", "text")
                }
                element={getStyle("BCnKu4zn6L", "htmlElement")}
              />
            </ContainerPrimitive>
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"Instructions_P8fWnikq3P"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_3BxxyR9HZR_P8fWnikq3P"
            }
            key={"P8fWnikq3P"}
            addSpacing={false}
            element={getStyle("P8fWnikq3P", "htmlElement")}
          >
            <TextPrimitive
              id={"INSTRUCTIONS_2kRYd5tTyA"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_3BxxyR9HZR_2kRYd5tTyA"
              }
              key={"2kRYd5tTyA"}
              text={getStyle("2kRYd5tTyA", "text")}
              element={getStyle("2kRYd5tTyA", "htmlElement")}
            />
            <ContainerPrimitive
              id={"Container_JDb8HrJEX7"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_3BxxyR9HZR_JDb8HrJEX7"
              }
              key={"JDb8HrJEX7"}
              addSpacing={false}
              element={getStyle("JDb8HrJEX7", "htmlElement")}
            >
              {
                <TextPrimitive
                  id={"deliveryInstructions_Pt6dfzXWa5"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_3BxxyR9HZR_Pt6dfzXWa5"
                  }
                  key={"Pt6dfzXWa5"}
                  text={
                    exists(props.deliveryInstructions)
                      ? props.deliveryInstructions
                      : getStyle("Pt6dfzXWa5", "text")
                  }
                  element={getStyle("Pt6dfzXWa5", "htmlElement")}
                />
              }
            </ContainerPrimitive>
          </ContainerPrimitive>
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

DeliverySummary.__variants = [
  {
    name: "onlyAddress",
    type: "variant",
  },
];

export default DeliverySummary;
