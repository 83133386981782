// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./TipSelect.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import { TextPrimitive, ContainerPrimitive } from "../../_internal_primitives";
import { RadioGroupRoot } from "../../_internal_radio_group";

const styles = [
  {
    type: "default",
    layers: {
      NA2ni6Nybd: {
        none: {
          htmlElement: "nav",
        },
      },
      W8rYNNd4PN: {
        none: {
          text: "Tip",
          htmlElement: "span",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large850",
        layers: {},
      },
      {
        propValue: "med450",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large850",
        layers: {},
      },
      {
        propValue: "med450",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["large850", "med450", "small"],
  },
];

export const TipSelectContext = createContext(null);

function TipSelect(_props) {
  const defaults = useContext(TipSelectContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RadioGroupRoot
      {...props}
      key="segmented-control-root"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "segmented-control-root",
        scope: "SEMyhbhqgo",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <TextPrimitive
            id={"Text_W8rYNNd4PN"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_SEMyhbhqgo_W8rYNNd4PN"
            }
            key={"W8rYNNd4PN"}
            text={
              exists(props.text) ? props.text : getStyle("W8rYNNd4PN", "text")
            }
            element={getStyle("W8rYNNd4PN", "htmlElement")}
          />
          <ContainerPrimitive
            id={"Container_NA2ni6Nybd"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_SEMyhbhqgo_NA2ni6Nybd"
            }
            key={"NA2ni6Nybd"}
            addSpacing={false}
            element={getStyle("NA2ni6Nybd", "htmlElement")}
          >
            {props.children}
          </ContainerPrimitive>
        </>
      )}
    </RadioGroupRoot>
  );
}

TipSelect.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["large850", "med450", "small"],
  },
];

export default TipSelect;
