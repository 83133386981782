import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  useLayoutEffect,
  SetStateAction,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import useAnimateNumber from "use-animate-number"; //https://github.com/baristna/use-animate-number/tree/master
import _ from "lodash";
import { FirebaseContext } from "../../firebaseSocket";
import {
  trackChefProfileExpanded,
  trackSocialMediaLinkClicked,
  trackStorefrontLinkClicked,
  trackFaqExpanded,
  trackDropExplainerClicked,
} from "../analytics";
import {
  getSubtotal,
  isMenuEmpty,
  getEventStatus,
  getAddressString,
  getSortedTimeWindows,
  getSortedEventTimeSlotOptions,
  getReadableTimeSlot,
  getDeliveryFee,
  getDeliveryAddressError,
  getFormattedPhone,
  getUnFormattedPhone,
  isEventOnDemandActive,
  getCountdownTimeArray,
  getDetailedEventStatus,
  numberWithCommas,
  getCustomButtonColorsFromChef,
  capitalizeFirstLetter,
} from "@hotplate/utils-ts/helperFunctions";

import {
  setCartId as setCartIdUnconnected,
  setDeliveryFee as setDeliveryFeeUnconnected,
  setToastPopupInfo as setToastPopupInfoUnconnected,
} from "../actions";
import "../../static/fonts/Inter-VariableFont_slnt,wght.ttf";
import { Link, Route, Routes, useNavigate, useParams, useSearchParams } from "react-router-dom";

import {
  addItemToCartMutation,
  ensureOk,
  expireCartMutation,
  removeItemFromCartMutation,
  subscribeToChefRemindersMutation,
} from "../../mutations";
import { useMutation, useQuery } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import { Box, Column, H1, H2, P, Row } from "../../hotplate-common/primitives/Containers";
import { getChefStyles } from "../../hotplate-common/chefStyleFunctions";
import { AspectRatioImg } from "../../hotplate-common/primitives/AspectRatioImg";
import { Button } from "../../hotplate-common/primitives/Button";
import { Dialog } from "../../hotplate-common/primitives/Dialog";
import { Accordion } from "../../hotplate-common/primitives/Accordian";
import { Drop } from "./Drop";
import {
  BellIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  InstagramLogoIcon,
  TwitterLogoIcon,
  FramerLogoIcon,
} from "@radix-ui/react-icons";
import { useIntersectionObserver, useToggle } from "../../hooks";

import {
  ChefStyles,
  EventType,
  ShopReduxState,
  SiteSettings,
  SocialMediaLinkOptions,
} from "./types";
import { ReminderDialog } from "./ReminderDialog";
import { StorefrontFooter } from "../components/StorefrontFooter";
import { DropCard } from "./DropCard";
import { StorefrontHeader } from "../components/StorefrontHeader";
import { NotFound } from "../../hotplate-common/NotFound";
import { DropCardLoader, StorefrontLoader } from "../../hotplate-common/loaders/StorefrontLoaders";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const NoEventsPlaceholder = ({ siteSettings }: { siteSettings: SiteSettings }) => {
  const chefStyles: ChefStyles = getChefStyles(siteSettings);
  const dropName = siteSettings?.customDropName || "drop";

  return (
    <Column css={{ backgroundColor: "$gray3", br: "$lg", pady: "$xxl", padx: "$md" }}>
      <H2
        css={{
          ff: "$inter",
          textStyle: "text-3",
          textAlign: "center",
          padt: "$lg",
          padb: "$xxl",
          color: "$gray11",
        }}
      >{`${siteSettings.restaurantName} hasn't scheduled their next ${dropName} yet. Signup to be reminded when they do!`}</H2>
      <ReminderDialog
        title={`Make sure you don't miss the next ${dropName}`}
        description={`Put in your phone number and we will text you a few minutes before ${siteSettings.restaurantName}'s next sale goes live`}
        trackSource="no_events_placeholder"
        trigger={
          <Button
            as="div"
            variant="tinted"
            color="accent"
            customButtonColorStyle={getCustomButtonColorsFromChef(chefStyles)}
          >
            <BellIcon />
            {`Don't miss the next one`}
          </Button>
        }
      />
    </Column>
  );
};

const ChefProfile = React.forwardRef<HTMLDivElement | null>(({ ...props }, ref) => {
  const [profileExpanded, toggleProfileExpanded] = useToggle(false);
  const { siteSettings } = useSelector((state: ShopReduxState) => state.storefront);
  const chefId = siteSettings.chefId;
  const chefStyles: ChefStyles = getChefStyles(siteSettings);

  return (
    <Column
      ref={ref}
      css={{
        w: "$full",
        boxShadow: "$elevation4",
        pos: "relative",
        backgroundColor: "$white",
        transition: "border-radius 0.2s ease-in-out, margin 0.2s ease-in-out",
        "@tablet": {
          border: "1px solid $gray4",
          mt: "$xxl",
          br: "$lg",
        },
      }}
      {...props}
    >
      {/* LOGO */}
      {chefStyles.bigLogo && (
        <Box
          css={{
            w: "$full",
            brTopLeft: "inherit",
            brTopRight: "inherit",
            overflow: "hidden",
          }}
        >
          <AspectRatioImg
            css={{}}
            ratio={21 / 9}
            src={chefStyles.bigLogo}
            alt={`${siteSettings.restaurantName} logo`}
          />
        </Box>
      )}
      <Column css={{ pad: "$md", padb: "$lg" }}>
        <Row
          css={{
            flexWrap: "wrap",
            justifyContent: "space-between",
            ai: "baseline",
            gap: "$sm",
            mb: "$sm",
          }}
        >
          {/* BUSINESS NAME */}
          {!siteSettings.isBusinessNameHiddenOnProfile && (
            <H1
              css={{
                textStyle: "text-5",
                fw: "$bold !important",
                color: "$gray12",
                "@tablet": {
                  textStyle: "text-6",
                },
                "@desktop_md": {
                  textStyle: "text-7",
                },
              }}
            >
              {siteSettings.restaurantName}
            </H1>
          )}
          {/* SOCIAL LINKS */}
          {siteSettings.socialMediaLinks && (
            <Row css={{ gap: "$sm" }}>
              {/* map over the socialmedialinks object and map */}
              <>
                {(Object.keys(siteSettings.socialMediaLinks) as Array<SocialMediaLinkOptions>).map(
                  (key) => {
                    const rawUrl = siteSettings.socialMediaLinks[key];
                    const href = rawUrl.startsWith("http") ? rawUrl : `http://${rawUrl}`;
                    return (
                      <Button
                        key={key}
                        as="a"
                        size="small"
                        color="accent"
                        variant="gray"
                        shape={"rounded"}
                        href={href}
                        target="_blank"
                        onClick={() => trackSocialMediaLinkClicked(chefId, key)}
                        css={{ padx: "$xs" }}
                      >
                        {key === "instagram" && <InstagramLogoIcon />}
                        {key === "twitter" && <TwitterLogoIcon />}
                        {key === "facebook" && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-facebook"
                          >
                            <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                          </svg>
                        )}
                        {key === "tiktok" && <FramerLogoIcon />}
                      </Button>
                    );
                  }
                )}
              </>
            </Row>
          )}
        </Row>

        {/* PROFILE BLURB */}
        {siteSettings.aboutSection && (
          <P
            css={{
              textStyle: "text-2",
              color: "$gray11",
              lineClamp: profileExpanded ? 10000 : 3,
              whiteSpace: "pre-line",
            }}
            onClick={() => {
              toggleProfileExpanded();
              trackChefProfileExpanded(chefId);
            }}
          >
            {siteSettings.aboutSection}
          </P>
        )}

        {/* LINKTREE FUNCTIONALITY */}
        {siteSettings.storefrontLinks && profileExpanded && (
          <Column css={{ gap: "$sm", mt: "$lg" }}>
            <P css={{ textStyle: "text-2", color: "$gray12" }}>Links</P>
            {siteSettings.storefrontLinks.map((btn) => {
              const rawUrl = btn.url;
              const href = rawUrl.startsWith("http") ? rawUrl : `http://${rawUrl}`;
              return (
                <Button
                  color="accent"
                  variant="tinted"
                  as="a"
                  href={href}
                  target="_blank"
                  key={btn.name}
                  css={{
                    textDecoration: "none",
                    whiteSpace: "break-spaces",
                    wordBreak: "break-word",
                    height: "auto",
                    pady: "$sm",
                    lineHeight: "$text",
                    textAlign: "center",
                  }}
                  onClick={() => trackStorefrontLinkClicked(chefId, href)}
                >
                  {btn.name}
                </Button>
              );
            })}
          </Column>
        )}

        {/* REMINDER SINGUP BUTTON */}
        <ReminderDialog
          title={`Don't miss out`}
          description="Give us your phone number and we will send you a text a few minutes before a new sale starts."
          trackSource="chef_profile"
          triggerCSS={{ mt: "$lg", w: "$full" }}
          trigger={
            <Button
              color="info"
              variant="tinted"
              as="div"
              css={{ w: "$full" }}
              customButtonColorStyle={getCustomButtonColorsFromChef(chefStyles)}
            >
              <BellIcon />
              Never miss a sale
            </Button>
          }
        />
      </Column>
      {(siteSettings.storefrontLinks || siteSettings.aboutSection) && (
        <Button
          color="gray"
          variant="tinted"
          customButtonColorStyle={getCustomButtonColorsFromChef(chefStyles, "light")}
          shape={"rounded"}
          size="xsmall"
          css={{
            pos: "absolute",
            bottom: -12,
            zIndex: "$above",
            left: 0,
            right: 0,
            textStyle: "text-1",
            mx: "auto",
            padx: "$xs",
            width: "fit-content",
            boxShadow: "$elevation2",
          }}
          onClick={() => {
            toggleProfileExpanded();
            trackChefProfileExpanded(chefId);
          }}
        >
          {profileExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
          {profileExpanded ? "Collapse" : "Expand"}
        </Button>
      )}
    </Column>
  );
});
ChefProfile.displayName = "ChefProfile";

const DropSection = ({
  title,
  hideWhatsADrop,
  children,
}: {
  title: string;
  hideWhatsADrop?: boolean;
  children: React.ReactNode;
}) => {
  const { siteSettings } = useSelector((state: ShopReduxState) => state.storefront);
  const chefId = siteSettings.chefId;
  return (
    <Column css={{ padx: "$md" }}>
      <Row css={{ jc: "space-between", mb: "$md", alignContent: "baseline" }}>
        <H2 css={{ textStyle: "text-4" }}>{title}</H2>
        {!hideWhatsADrop && (
          <Dialog
            trigger={
              <Button
                as="div"
                variant="plain"
                color="gray"
                removePadding="true"
                onClick={() => trackDropExplainerClicked(chefId)}
                css={{
                  textStyle: "text-2",
                  color: "$gray10",
                  textDecoration: "underline",
                }}
              >
                What&apos;s a drop?
              </Button>
            }
            title="What is a drop?"
            description="A drop is a pre-order sale that is only available for short time and has limited inventory. Be there when the orders open and get what you can before someone else does!"
            triggerCss={{}}
            contentCss={{}}
            defaultOpen={undefined}
            isOpen={undefined}
            onOpenChange={undefined}
          >
            {""}
          </Dialog>
        )}
      </Row>

      <Column css={{ gap: "$md" }}>{children}</Column>
    </Column>
  );
};

export const ChefHomepage = ({ chefId }: { chefId: string }) => {
  const firebaseContext = useContext<any>(FirebaseContext);
  const { siteSettings } = useSelector((state: ShopReduxState) => state.storefront);

  const [numPastDropsToShow, setNumPastDropsToShow] = useState(5);
  const [numNextDropsToShow, setNumNextDropsToShow] = useState(5);

  const getChefEvents = useQuery(
    ["getChefEventsCloud", chefId],
    async () => {
      const result = await firebaseContext.cloudFunctions.getChefEvents(chefId);
      if (result.error) {
        throw new Error(result.error);
      }
      return result;
    },
    {
      cacheTime: Number.POSITIVE_INFINITY,
      staleTime: Number.POSITIVE_INFINITY,
    }
  );

  const events = getChefEvents.data?.events;
  const pastEvents = getChefEvents.data?.pastEvents;

  const chefProfileRef = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(chefProfileRef, { rootMargin: "-100px 0px 0px 0px" });
  const profileIsVisible = !!entry?.isIntersecting;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const eventSearchParam = searchParams.get("event");

  if (eventSearchParam) {
    if (events) {
      const eventId = Object.keys(events).find(
        (eventId) => eventId.substring(0, 5) === eventSearchParam
      );
      if (eventId) {
        navigate(eventId);
      }
    }
    if (pastEvents && !pastEvents.error) {
      const eventId = (pastEvents as Array<any>)
        .map((event) => event.id)
        .find((eventId) => eventId.substring(0, 5) === eventSearchParam);
      if (eventId) {
        navigate(eventId);
      }
    }
  }

  const viewableEventIds = getSortedViewableEventIds();

  function getSortedViewableEventIds() {
    if (!events || events.constructor != Object) return [];
    const viewableEventIds = Object.keys(events)
      .filter((eventId) => {
        const event = events[eventId];
        const eventStatus = getEventStatus(events[eventId]);
        if (eventSearchParam) {
          if (event.private && eventSearchParam !== eventId.substring(0, 5)) return false;
        } else {
          if (event.private) return false;
        }
        return eventStatus === "live" || eventStatus === "scheduled";
      })
      .sort((eventIdA, eventIdB) => {
        const eventA = events[eventIdA];
        const eventB = events[eventIdB];
        const eventStatusA = getEventStatus(eventA);
        const eventStatusB = getEventStatus(eventB);
        if (eventStatusA === "live" && eventStatusB === "live") {
          const timeWindowsA = getSortedTimeWindows(eventA.timeWindows);
          let firstTimeA = 0;
          if (timeWindowsA.length > 0) {
            firstTimeA = timeWindowsA.sort((a, b) => {
              return a.startTime - b.startTime;
            })[timeWindowsA.length - 1].startTime;
          }
          const timeWindowsB = getSortedTimeWindows(eventB.timeWindows);
          let firstTimeB = 0;
          if (timeWindowsB.length > 0) {
            firstTimeB = timeWindowsB.sort((a, b) => {
              return a.startTime - b.startTime;
            })[timeWindowsB.length - 1].startTime;
          }
          return firstTimeA - firstTimeB;
        } else if (eventStatusA === "live") {
          return -1;
        } else if (eventStatusB === "live") {
          return 1;
        } else if (eventStatusA === "scheduled" && eventStatusB === "scheduled") {
          return eventA.goLiveTime - eventB.goLiveTime;
        } else if (eventStatusA === "scheduled") {
          return -1;
        } else if (eventStatusB === "scheduled") {
          return 1;
        } else {
          return eventB.goLiveTime - eventA.goLiveTime;
        }
      });

    return viewableEventIds;
  }

  function getLiveEventsSectionTitle(events: any, viewableEventIds: string[], dropName: string) {
    if (!events || events.constructor != Object || !viewableEventIds)
      return `Next ${capitalizeFirstLetter(dropName)}`;
    // loop through each event and check the status of all viewableEventIds
    let numLiveEvents = 0;
    let numScheduledEvents = 0;
    for (let i = 0; i < viewableEventIds.length; i++) {
      const event = events[viewableEventIds[i]];
      const eventStatus = getEventStatus(event);
      if (eventStatus === "live") {
        numLiveEvents++;
      } else if (eventStatus === "scheduled") {
        numScheduledEvents++;
      }
    }
    if (numLiveEvents > 0 && numScheduledEvents > 0) {
      return `Current & Next ${capitalizeFirstLetter(dropName)}s`;
    }
    if (numLiveEvents > 0) {
      return `Current ${capitalizeFirstLetter(dropName)}${numLiveEvents > 1 ? "s" : ""}`;
    }
    return `Next ${capitalizeFirstLetter(dropName)}${numScheduledEvents > 1 ? "s" : ""}`;
  }

  const dropName = siteSettings?.customDropName || "Drop";

  if (getChefEvents.isError) {
    return <p>An error has occurred. Please refresh the page.</p>;
  }

  return (
    <Box
      css={{
        ff: "$inter",
        padb: "$xxl",
        backgroundColor: "$accent2",
      }}
    >
      <StorefrontHeader
        siteSettings={siteSettings}
        type="storefront"
        css={{
          position: "sticky",
          top: 0,
          zIndex: "$sticky",
          // pointerEvents: profileIsVisible ? "none" : "all",
          // opacity: profileIsVisible || !entry ? 0 : 1,
          transition: "opacity 0.3s ease-in-out",
        }}
      />
      <Column
        css={{
          w: "$full",
          maxW: "$maxContentWidthXs",
          ac: "center",
          mx: "auto",
          gap: "$xxl",
        }}
      >
        {/*  */}
        {/*  */}
        {/* CHEF PROFILE */}
        {/*  */}
        {/*  */}
        <ChefProfile ref={chefProfileRef} />
        {/*  */}
        {/*  */}
        {/* NEXT EVENT */}
        {/*  */}
        {/*  */}
        {/*  */}

        <DropSection
          title={getLiveEventsSectionTitle(events, viewableEventIds, dropName)}
          hideWhatsADrop={!!siteSettings.customDropName}
        >
          {getChefEvents.isLoading ? (
            <>
              <DropCardLoader />
              <DropCardLoader />
              <DropCardLoader />
            </>
          ) : (
            <>
              {/* Map of upcoming drops */}
              {viewableEventIds.slice(0, numNextDropsToShow).map((eventId) => (
                <DropCard event={events[eventId]} key={eventId} />
              ))}
              {viewableEventIds && viewableEventIds.length === 0 && (
                <NoEventsPlaceholder siteSettings={siteSettings} />
              )}
              {viewableEventIds.length > numNextDropsToShow && (
                <Button
                  variant="plain"
                  color="gray"
                  onClick={() => {
                    setNumNextDropsToShow(viewableEventIds.length);
                  }}
                >
                  <ChevronDownIcon />
                  {`View ${viewableEventIds.length - numNextDropsToShow} more`}
                </Button>
              )}
            </>
          )}
        </DropSection>
        {/*  */}
        {/*  */}
        {/* FAQ */}
        {/*  */}
        {/*  */}
        {siteSettings.faqs && siteSettings.faqs.length > 0 && (
          <Column
            css={{
              gap: "$xs",
              br: "$lg",
              backgroundColor: "$white",
              boxShadow: "$elevation5",
              pady: "$md",
            }}
          >
            <H2 css={{ textStyle: "text-4", ff: "$inter", color: "$gray12", ml: 20 }}>
              Frequently Asked Questions
            </H2>
            <Accordion type="single" collapsible>
              {siteSettings.faqs.map((faq) => (
                <Accordion.Item
                  value={faq.question}
                  title={faq.question}
                  content={faq.answer}
                  key={faq.question}
                  onClick={() => {
                    trackFaqExpanded(chefId, faq.question);
                  }}
                />
              ))}
            </Accordion>
          </Column>
        )}

        {/*  */}
        {/*  */}
        {/* PAST EVENTS */}
        {/*  */}
        {/*  */}
        <DropSection title={`Past ${capitalizeFirstLetter(dropName)}s`} hideWhatsADrop={true}>
          {getChefEvents.isLoading ? (
            <>
              <DropCardLoader />
              <DropCardLoader />
              <DropCardLoader />
            </>
          ) : (
            <>
              {pastEvents.length === 0 ? (
                <P
                  css={{
                    ff: "$inter",
                    textStyle: "text-5",
                    color: "$gray10",
                    textAlign: "center",
                    pady: "$xl",
                  }}
                >
                  Nothing here yet.
                </P>
              ) : (
                pastEvents
                  .slice(0, numPastDropsToShow)
                  .map((item: any) => <DropCard event={item} key={item.id} />)
              )}
            </>
          )}
          {getChefEvents.isSuccess && pastEvents.length > numPastDropsToShow && (
            <Button
              variant="plain"
              color="gray"
              onClick={() => {
                setNumPastDropsToShow(numPastDropsToShow + 5);
              }}
            >
              <ChevronDownIcon />
              View more
            </Button>
          )}
        </DropSection>
      </Column>
      <StorefrontFooter restaurantName={siteSettings.restaurantName} />
    </Box>
  );
};

export const NewStorefront = ({
  cart,
  cartEvent,
  isCartStuffLoading,
}: {
  cart: any;
  cartEvent: any;
  isCartStuffLoading: boolean;
}) => {
  const firebaseContext = useContext<any>(FirebaseContext);
  const { siteSettings, getSiteSettingsLoading, getSiteSettingsError } = useSelector(
    (state: ShopReduxState) => state.storefront
  );

  const pathParams = useParams();
  const [searchParams] = useSearchParams();
  const chefId =
    searchParams.get("chef") || // For Reflect.run tests
    pathParams.chefId?.toLowerCase();

  // New host/chef visited
  useEffect(() => {
    if (chefId) {
      // TODO turn into react-query useQuery
      firebaseContext.api.getSiteSettings(chefId);
    }
  }, [firebaseContext, chefId]);

  if (getSiteSettingsLoading) {
    return <StorefrontLoader />;
  }

  if (!chefId) {
    return <NotFound />;
  }

  if (!siteSettings && !getSiteSettingsLoading && !getSiteSettingsError) {
    return <NotFound />;
  }

  if (getSiteSettingsError) {
    return <p>An error has occurred. Please refresh the page.</p>;
  }

  return (
    <SentryRoutes>
      <Route
        path=":eventId"
        element={<Drop cart={cart} cartEvent={cartEvent} isCartStuffLoading={isCartStuffLoading} />}
      />
      <Route index element={<ChefHomepage chefId={chefId} />} />
    </SentryRoutes>
  );
};
