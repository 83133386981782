// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./DropdownButton.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { TextPrimitive } from "../_internal_primitives";
import {
  SelectRoot,
  SelectOptionContainer,
  SelectButton,
} from "../_internal_select";

const styles = [
  {
    type: "default",
    layers: {
      "select-button-text": {
        none: {
          text: "Create",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const DropdownButtonContext = createContext(null);

function DropdownButton(_props) {
  const defaults = useContext(DropdownButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <SelectRoot
      {...props}
      key="select-root"
      internal={{
        styles,
        projectId: "7X7HTLRqyD",
        layerId: "select-root",
        scope: "Ds26Qy1JiD",
        activeVariants,
      }}
      vislyChildren={(getStyle) => (
        <>
          <SelectButton
            key="select-button"
            id={"Button_select-button"}
            className="__visly_reset_7X7HTLRqyD __visly_scope_Ds26Qy1JiD_select-button"
          >
            {
              <TextPrimitive
                id={"text_select-button-text"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_Ds26Qy1JiD_select-button-text"
                }
                key={"select-button-text"}
                text={
                  exists(props.label)
                    ? props.label
                    : getStyle("select-button-text", "text")
                }
                element={getStyle("select-button-text", "htmlElement")}
              />
            }
          </SelectButton>
          <SelectOptionContainer
            key="select-menu"
            id={"Menu_select-menu"}
            className="__visly_reset_7X7HTLRqyD __visly_scope_Ds26Qy1JiD_select-menu"
            innerKey="select-menu"
            addSpacing={true}
            useButtonWidth={true}
          >
            {props.children}
          </SelectOptionContainer>
        </>
      )}
    >
      {props.children}
    </SelectRoot>
  );
}

DropdownButton.__variants = [];

export default DropdownButton;
