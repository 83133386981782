import React from "react";
import { EphemeralChat } from "./EphemeralChat";
import { Countdown } from "./EventCardCountdown";
import { getCountdownTimeArray } from "@hotplate/utils-ts/helperFunctions";
import { EventType } from "../shop/types";
import { Column } from "../../hotplate-common/primitives/Containers";

export const DropChat = ({
  channelId,
  event,
  timeUntilGoLive,
}: {
  event: EventType;
  channelId: string;
  timeUntilGoLive: number;
}) => {
  const countdownTimeArray = getCountdownTimeArray(timeUntilGoLive);

  return (
    <Column css={{ pady: "$sm", gap: "$sm" }}>
      <Countdown
        type="large"
        goLiveTime={event.goLiveTime}
        hideCountdown={event.hideOrdersOpenTime}
        days={countdownTimeArray[0]}
        hours={countdownTimeArray[1]}
        minutes={countdownTimeArray[2]}
        seconds={countdownTimeArray[3]}
        labeled={true}
        chefStyles={{}} //!add these
        css={{ jc: "center" }}
      />
      <EphemeralChat channelId={channelId} />
    </Column>
  );
};
