import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import _ from "lodash";
import React, { useState, useRef } from "react";
import { styled } from "../../../stitches.config";

const SearchbarContainer = styled("div", {
  all: "unset",
  display: "flex",
  position: "relative",
  alignItems: "center",
  flexGrow: 1,
  minWidth: 0,
  padx: "10px",
  ff: "$avenir",
  fw: "$semi_bold",
  fs: 14,
  height: 30,
  width: 50,
  borderRadius: "$pill",
  cursor: "text",
  border: `1px solid $gray6`,
  outline: "0px solid transparent",
  outlineOffset: "-1px",
  lh: "$btn",
  transition: "background-color 125ms ease-in-out, outline 125ms ease-in-out",
  "&:focus-within": { outline: `2px solid $accent9` },
  "&::placeholder": {
    color: "$gray9",
  },
  "@desktop_sm": {
    width: "auto",
  },
});

const SearchbarPrimitive = styled("input", {
  all: "unset",
  display: "flex",
  position: "relative",
  minWidth: 0,
  overflow: "hidden",
  textOverflow: "ellipsis",
  flex: 1,
  marginLeft: 8,
  cursor: "text",

  transition: "width 150ms ease-in-out, margin 150ms ease-in-out",
  "&::-webkit-search-cancel-button": {
    appearance: "none",
  },
});

const IconFlex = styled("div", {
  display: "flex",
  height: "100%",
  jc: "center",
  ai: "center",
  flexShrink: 0,
});

export const ManagementSearchbar = ({ setSearch, ...props }) => {
  const inputRef = useRef(null);
  const [text, setText] = useState("");
  const [debounce] = useState(() => {
    return _.debounce(
      (func) => {
        func();
      },
      400,
      { leading: false, trailing: true }
    );
  });

  return (
    <SearchbarContainer onClick={() => inputRef.current.focus()} {...props}>
      <IconFlex>
        <MagnifyingGlassIcon />
      </IconFlex>

      <SearchbarPrimitive
        ref={inputRef}
        type="search"
        placeholder="Search..."
        value={text}
        onFocus={() => {
          inputRef.current.select();
        }}
        onChange={(e) => {
          setText(e.target.value);
          debounce(() => {
            setSearch(e.target.value);
          });
        }}
      />
    </SearchbarContainer>
  );
};
