// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./InputSelect.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { TextPrimitive, ContainerPrimitive } from "../_internal_primitives";
import { Root, TextFieldPrimitive } from "../_internal_text_field";
import { default as Options } from "./InputSelect.Options";

const styles = [
  {
    type: "default",
    layers: {
      input: {
        none: {
          "placeholder-text": "Item name",
        },
      },
      "5g7TB5hmXU": {
        none: {
          text: "Label",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "required",
    layers: {},
  },
  {
    type: "boolean",
    propName: "visible",
    layers: {},
  },
  {
    type: "boolean",
    propName: "variations",
    layers: {
      input: {
        none: {
          "placeholder-text": "Add Variations",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "mods",
    layers: {
      input: {
        none: {
          "placeholder-text": "Add Modifications",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "quantity",
    layers: {
      input: {
        none: {
          "placeholder-text": "#",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "required",
    layers: {},
  },
  {
    type: "boolean",
    propName: "visible",
    layers: {},
  },
  {
    type: "boolean",
    propName: "variations",
    layers: {},
  },
  {
    type: "boolean",
    propName: "mods",
    layers: {},
  },
  {
    type: "boolean",
    propName: "quantity",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "required",
  },
  {
    type: "boolean",
    propName: "visible",
  },
  {
    type: "boolean",
    propName: "variations",
  },
  {
    type: "boolean",
    propName: "mods",
  },
  {
    type: "boolean",
    propName: "quantity",
  },
];

export const InputSelectContext = createContext(null);

function InputSelect(_props) {
  const defaults = useContext(InputSelectContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <Root
      {...props}
      key="root"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "root",
        scope: "Gd2jozKsNz",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Container_N2g1KqYu1r"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Gd2jozKsNz_N2g1KqYu1r"
            }
            key={"N2g1KqYu1r"}
            addSpacing={false}
            element={getStyle("N2g1KqYu1r", "htmlElement")}
          >
            <ContainerPrimitive
              id={"Container_Rxh7LBquzR"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Gd2jozKsNz_Rxh7LBquzR"
              }
              key={"Rxh7LBquzR"}
              addSpacing={false}
              element={getStyle("Rxh7LBquzR", "htmlElement")}
            >
              {
                <TextPrimitive
                  id={"Label_5g7TB5hmXU"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_Gd2jozKsNz_5g7TB5hmXU"
                  }
                  key={"5g7TB5hmXU"}
                  text={
                    exists(props.label)
                      ? props.label
                      : getStyle("5g7TB5hmXU", "text")
                  }
                  element={getStyle("5g7TB5hmXU", "htmlElement")}
                />
              }
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"Container_5kzWNAcw2H"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Gd2jozKsNz_5kzWNAcw2H"
              }
              key={"5kzWNAcw2H"}
              addSpacing={false}
              element={getStyle("5kzWNAcw2H", "htmlElement")}
            >
              {
                <TextFieldPrimitive
                  id={"input_input"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_Gd2jozKsNz_input"
                  }
                  key={"input"}
                  placeholder={
                    exists(props.placeholder)
                      ? props.placeholder
                      : getStyle("input", "placeholder-text")
                  }
                />
              }
            </ContainerPrimitive>
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"Select_9S8KocXsKi"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Gd2jozKsNz_9S8KocXsKi"
            }
            key={"9S8KocXsKi"}
            addSpacing={false}
            element={getStyle("9S8KocXsKi", "htmlElement")}
          >
            {props.Options === undefined ? (
              <Options key={"DZXN8ceQ9N"} />
            ) : (
              props.Options
            )}
          </ContainerPrimitive>
        </>
      )}
    </Root>
  );
}

InputSelect.__variants = [
  {
    name: "required",
    type: "variant",
  },
  {
    name: "visible",
    type: "variant",
  },
  {
    name: "variations",
    type: "variant",
  },
  {
    name: "mods",
    type: "variant",
  },
  {
    name: "quantity",
    type: "variant",
  },
];

export default InputSelect;
