// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./EventPickupWindows.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import {
  default as TimeCadence,
  LabeledInputContext as TimeCadenceContext,
} from "../Events/LabeledInput";
import {
  default as SaveButton,
  ButtonContext as SaveButtonContext,
  default as AddPickupWindowButton,
  ButtonContext as AddPickupWindowButtonContext,
} from "../Primitives/Button";
import { default as PickupWindowContainer } from "./EventPickupWindows.PickupWindowContainer";

const styles = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      "8nak386BQP": {
        none: {
          label: "Pickup Times Occur",
        },
      },
      "8ozbzkrS7S": {
        none: {
          text: "Save & Continue",
          large: true,
          kind: "primary",
        },
      },
      "2vwhYW2meb": {
        none: {
          text: "Add Pickup Window",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {
          "8nak386BQP": {},
          "8ozbzkrS7S": {},
          "2vwhYW2meb": {},
        },
      },
      {
        propValue: "small",
        layers: {
          "8nak386BQP": {},
          "8ozbzkrS7S": {},
          "2vwhYW2meb": {},
        },
      },
      {
        propValue: "med",
        layers: {
          "8nak386BQP": {},
          "8ozbzkrS7S": {},
          "2vwhYW2meb": {},
        },
      },
      {
        propValue: "large",
        layers: {
          "8nak386BQP": {},
          "8ozbzkrS7S": {},
          "2vwhYW2meb": {},
        },
      },
      {
        propValue: "xlarge",
        layers: {
          "8nak386BQP": {},
          "8ozbzkrS7S": {},
          "2vwhYW2meb": {},
        },
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export const EventPickupWindowsContext = createContext(null);

function EventPickupWindows(_props) {
  const defaults = useContext(EventPickupWindowsContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="XDhDr9NTxz"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "XDhDr9NTxz",
        scope: "BbEeiw2yzE",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Container_6rdXxRi2hn"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_BbEeiw2yzE_6rdXxRi2hn"
            }
            key={"6rdXxRi2hn"}
            addSpacing={false}
            element={getStyle("6rdXxRi2hn", "htmlElement")}
          >
            {[
              props.PickupWindowContainer === undefined ? (
                <PickupWindowContainer key={"2VjmCbEN1R"} />
              ) : (
                props.PickupWindowContainer
              ),
              <SpacerPrimitive
                id={"Spacer_686Y7VrCsZ"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_BbEeiw2yzE_686Y7VrCsZ"
                }
                key={"686Y7VrCsZ"}
              />,
              props.AddPickupWindowButton === undefined ? (
                <AddPickupWindowButton
                  key={"2vwhYW2meb"}
                  {...getCompositeDefaultProps("2vwhYW2meb")}
                  id="AddPickupWindowButton_2vwhYW2meb"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_BbEeiw2yzE_2vwhYW2meb"
                />
              ) : (
                <AddPickupWindowButtonContext.Provider
                  key="2vwhYW2meb-provider"
                  value={{
                    key: "2vwhYW2meb",
                    id: "AddPickupWindowButton_2vwhYW2meb",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_BbEeiw2yzE_2vwhYW2meb",
                    ...getCompositeDefaultProps("2vwhYW2meb"),
                  }}
                >
                  {props.AddPickupWindowButton}
                </AddPickupWindowButtonContext.Provider>
              ),
            ]}
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_AJLB4F8zao"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_BbEeiw2yzE_AJLB4F8zao"
            }
            key={"AJLB4F8zao"}
          />
          <ContainerPrimitive
            id={"Container_85fiRSJmYp"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_BbEeiw2yzE_85fiRSJmYp"
            }
            key={"85fiRSJmYp"}
            addSpacing={false}
            element={getStyle("85fiRSJmYp", "htmlElement")}
          >
            {props.TimeCadence === undefined ? (
              <TimeCadence
                key={"8nak386BQP"}
                {...getCompositeDefaultProps("8nak386BQP")}
                id="TimeCadence_8nak386BQP"
                className="__visly_reset_7X7HTLRqyD __visly_scope_BbEeiw2yzE_8nak386BQP"
              />
            ) : (
              <TimeCadenceContext.Provider
                key="8nak386BQP-provider"
                value={{
                  key: "8nak386BQP",
                  id: "TimeCadence_8nak386BQP",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_BbEeiw2yzE_8nak386BQP",
                  ...getCompositeDefaultProps("8nak386BQP"),
                }}
              >
                {props.TimeCadence}
              </TimeCadenceContext.Provider>
            )}
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_pddKvAKA3t"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_BbEeiw2yzE_pddKvAKA3t"
            }
            key={"pddKvAKA3t"}
          />
          <ContainerPrimitive
            id={"Container_VxAvwe8dHZ"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_BbEeiw2yzE_VxAvwe8dHZ"
            }
            key={"VxAvwe8dHZ"}
            addSpacing={false}
            element={getStyle("VxAvwe8dHZ", "htmlElement")}
          >
            {props.SaveButton === undefined ? (
              <SaveButton
                key={"8ozbzkrS7S"}
                {...getCompositeDefaultProps("8ozbzkrS7S")}
                id="SaveButton_8ozbzkrS7S"
                className="__visly_reset_7X7HTLRqyD __visly_scope_BbEeiw2yzE_8ozbzkrS7S"
              />
            ) : (
              <SaveButtonContext.Provider
                key="8ozbzkrS7S-provider"
                value={{
                  key: "8ozbzkrS7S",
                  id: "SaveButton_8ozbzkrS7S",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_BbEeiw2yzE_8ozbzkrS7S",
                  ...getCompositeDefaultProps("8ozbzkrS7S"),
                }}
              >
                {props.SaveButton}
              </SaveButtonContext.Provider>
            )}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

EventPickupWindows.TimeCadence = TimeCadence;
EventPickupWindows.SaveButton = SaveButton;
EventPickupWindows.AddPickupWindowButton = AddPickupWindowButton;
EventPickupWindows.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export default EventPickupWindows;
