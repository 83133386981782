import React from "react";
import { styled } from "../../../stitches.config";
import { PackingCard } from "./PackingCard";
import { VirtuosoGrid } from "react-virtuoso";
import { PackingCardPrint } from "./PackingCardPrint";
import { useSelector } from "react-redux";
import { PackingCardLoader } from "../../../hotplate-common/loaders/PackingLoaders";
import { NothingHere } from "./NothingHere";

const GridContainer = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr",
  hideOnPrint: true,
  gap: "$lg",
  padx: "$md",
  paddingTop: "$md",
  paddingBottom: "calc($sizes$navHeight + $md)",
  "@tablet": {
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
  },
  "@desktop_sm": {
    paddingBottom: "$md",
  },
});
const CardWrapper = styled("div", {
  display: "flex",
  height: 375, // items must have consistent height, not flexible, would be nice to setup a better VL which supports variable height rows
  width: "100%",
});

export const PackingCardGrid = ({ orders, functions, isPrinting }) => {
  const connectToOrdersLoading = useSelector(
    (state) => state.orderManagement.connectToOrdersLoading
  );

  return (
    <>
      {/* Print Box */}
      {isPrinting && <PackingCardPrint orders={orders} functions={functions} />}
      {orders.length === 0 ? (
        connectToOrdersLoading ? (
          <GridContainer>
            {Array(10)
              .fill(0)
              .map((_, i) => (
                <PackingCardLoader css={{ width: "100%", height: 375 }} view="grid" key={i} />
              ))}
          </GridContainer>
        ) : (
          <NothingHere />
        )
      ) : (
        // Virtualized List
        <VirtuosoGrid
          totalCount={orders.length}
          components={{
            Item: CardWrapper,
            List: GridContainer,
          }}
          itemContent={(idx) => (
            <PackingCard order={orders[idx]} functions={functions} view="grid" />
          )}
        />
      )}
    </>
  );
};
