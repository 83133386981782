import React from "react";
import { styled } from "../../stitches.config";

import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";

const SCROLLBAR_SIZE = 10;

const StyledScrollbar = styled(ScrollAreaPrimitive.Scrollbar, {
  display: "flex",
  // ensures no selection
  userSelect: "none",
  // disable browser handling of all panning and zooming gestures on touch devices
  touchAction: "none",
  padding: 2,
  //   background: "$overlay6",
  transition: "background 160ms ease-out",
  "&:hover": { background: "$overlay6" },
  '&[data-orientation="vertical"]': { width: SCROLLBAR_SIZE },
  '&[data-orientation="horizontal"]': {
    flexDirection: "column",
    height: SCROLLBAR_SIZE,
  },
});

const StyledViewport = styled(ScrollAreaPrimitive.Viewport, {
  width: "100%",
  height: "100%",
  borderRadius: "inherit",
});

const StyledCorner = styled(ScrollAreaPrimitive.Corner, {
  background: "$overlay8",
});

const StyledThumb = styled(ScrollAreaPrimitive.Thumb, {
  flex: 1,
  background: "$gray10",
  borderRadius: SCROLLBAR_SIZE,
  // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    minWidth: 44,
    minHeight: 44,
  },
});

const Root = styled(ScrollAreaPrimitive.Root, {});
const Viewport = StyledViewport;
const Thumb = StyledThumb;
const Corner = StyledCorner;
const Scrollbar = StyledScrollbar;

interface ScrollAreaProps extends React.ComponentPropsWithRef<typeof Root> {
  children?: React.ReactNode;
  css?: React.ComponentProps<typeof Root>["css"];
  id?: React.ComponentProps<typeof Viewport>["id"];
  viewportCss?: React.ComponentProps<typeof Viewport>["css"];
  vertical?: boolean;
  horizontal?: boolean;
  onScroll?: React.ComponentProps<typeof Viewport>["onScroll"];
}

export const ScrollArea = React.forwardRef<HTMLDivElement, ScrollAreaProps>(
  (
    {
      children,
      css,
      id,
      viewportCss,
      vertical = true,
      horizontal,
      onScroll,
      ...props
    }: ScrollAreaProps,
    ref
  ) => (
    <Root css={{ ...css, overflow: "hidden" }} {...props}>
      <Viewport css={viewportCss} id={id} onScroll={onScroll} ref={ref}>
        {children}
      </Viewport>
      {vertical && (
        <Scrollbar orientation="vertical">
          <Thumb />
        </Scrollbar>
      )}
      {horizontal && (
        <Scrollbar orientation="horizontal">
          <Thumb />
        </Scrollbar>
      )}
      {vertical && horizontal && <Corner />}
    </Root>
  )
);

ScrollArea.displayName = "ScrollArea";
