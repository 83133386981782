/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { usePrevious } from "../../hooks";
import { ModalSubpage } from "../../visly/Modals";

const ModalSubpageMaster = ({
  forceClose,
  closeModal,
  children,
  navText,
  isFirstPage,
  zIndex,
  gray,
  style,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [id] = useState(uuid());

  useEffect(() => {
    setTimeout(() => setIsOpen(true), 300);
  }, []);

  const prevProps = usePrevious({ forceClose });
  useEffect(() => {
    if (!prevProps) {
      return;
    }
    if (!prevProps.forceClose && forceClose) {
      unmount();
    }
  });

  function unmount() {
    setIsOpen(false);
    setTimeout(closeModal, 600);
  }

  if (isFirstPage) {
    return (
      <div
        id={"modalSubpageContainer" + id}
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        {children}
      </div>
    );
  }
  return (
    <div
      id={"modalSubpageContainer" + id}
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: Number.isInteger(zIndex) ? zIndex : 0,
      }}
    >
      <ModalSubpage
        isOpen={isOpen}
        NavButton={<ModalSubpage.NavButton onClick={unmount} text={navText} />}
        gray={gray}
        style={style}
      >
        {children}
      </ModalSubpage>
    </div>
  );
};

export default ModalSubpageMaster;
