import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { FilterButton } from "../../../hotplate-common/primitives/FilterButton";
import { Popover } from "../../../hotplate-common/primitives/Popover";
import { Label } from "../../../hotplate-common/primitives/Label";
import { Select } from "../../../hotplate-common/primitives/Select";
import { Flex } from "../../../hotplate-common/primitives/Containers";
import {
  setPrimaryOrdersSort,
  setCompletedOrdersDisplay,
} from "../../../hotplate-storefront/actions";
import { trackCompletedOrdersDisplayChanged, trackPackingSortChanged } from "../analytics";

export const SortPopover = () => {
  const primarySort = useSelector((state) => state.orderManagement.sort.primary);
  const completedOrdersDisplay = useSelector(
    (state) => state.orderManagement.completedOrdersDisplay
  );
  const dispatch = useDispatch();

  const primarySortLabels = {
    timeSlot_asc: "Fulfillment Time (Early → Late)",
    timeSlot_desc: "Fulfillment Time (Late → Early)",
    orderNumber_asc: "Order Number (Low → High)",
    orderNumber_desc: "Order Number (High → Low)",
    customerName_asc: "Customer Name (A → Z)",
    customerName_desc: "Customer Name (Z → A)",
  };

  const completedOrdersSortLabels = {
    hidden: "Hidden",
    end: "Sorted to end",
    none: "Left where they are",
  };

  function getStateObj(value) {
    const type = value.split("_")[0];
    const direction = value.split("_")[1];
    return {
      type: type,
      direction: direction,
    };
  }

  return (
    <Popover
      css={{ display: "flex" }}
      trigger={
        <FilterButton
          text="Sort"
          alert={
            primarySort.type !== "timeSlot" ||
            primarySort.direction !== "asc" ||
            completedOrdersDisplay !== "hidden"
          }
        >
          <CaretSortIcon />
        </FilterButton>
      }
    >
      <Flex css={{ flexDirection: "column", padding: 20 }}>
        <Label text="Sort orders by" css={{ marginBottom: "$lg" }}>
          <Select
            onValueChange={(value) => {
              setPrimaryOrdersSort(getStateObj(value))(dispatch);
              trackPackingSortChanged({ packingSort: getStateObj(value) });
            }}
            defaultValue={`${primarySort.type}_${primarySort.direction}`}
            placeholder="Sort orders by..."
            triggerCss={{ minWidth: 250 }}
          >
            {Object.keys(primarySortLabels).map((labelKey, index) => (
              <Select.Option key={index} value={labelKey}>
                {primarySortLabels[labelKey]}
              </Select.Option>
            ))}
          </Select>
        </Label>
        <Label text="When completed, orders should be">
          <Select
            onValueChange={(value) => {
              setCompletedOrdersDisplay(value)(dispatch);
              trackCompletedOrdersDisplayChanged({ completedOrdersDisplay: value });
            }}
            defaultValue={completedOrdersDisplay}
            placeholder="When completed, orders should be..."
            triggerCss={{ minWidth: 250 }}
          >
            {Object.keys(completedOrdersSortLabels).map((labelKey, index) => (
              <Select.Option key={index} value={labelKey}>
                {completedOrdersSortLabels[labelKey]}
              </Select.Option>
            ))}
          </Select>
        </Label>
      </Flex>
    </Popover>
  );
};
