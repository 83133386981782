// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./DatePicker.DateDropdown.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, ContainerPrimitive } from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "expanded",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "expanded",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "expanded",
  },
];

export const DateDropdownContext = createContext(null);

function DateDropdown(_props) {
  const defaults = useContext(DateDropdownContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="452p3dMqAw"
      addSpacing={true}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "452p3dMqAw",
        scope: "RGH4ysakne",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"daypicker-container_gdvTW3y8xV"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_RGH4ysakne_gdvTW3y8xV"
          }
          key={"gdvTW3y8xV"}
          addSpacing={false}
          element={getStyle("gdvTW3y8xV", "htmlElement")}
        >
          {
            <ContainerPrimitive
              key={"C2HYE2gg7Y"}
              id={"Slot_C2HYE2gg7Y"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_RGH4ysakne_C2HYE2gg7Y"
              }
              addSpacing={false}
            >
              {props.children}
            </ContainerPrimitive>
          }
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

DateDropdown.__variants = [
  {
    name: "expanded",
    type: "variant",
  },
];

export default DateDropdown;
