// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./StatisticsBlock.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      "7gjTC9wnZ6": {
        none: {
          text: "123.45",
        },
      },
      YFJ3tWWZeR: {
        none: {
          text: "Block Subtitle",
        },
      },
      "6VWp9rTmBb": {
        none: {
          text: "$",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "showDollarSign",
    layers: {},
  },
  {
    type: "enum",
    propName: "type",
    values: [
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "normal",
        layers: {},
      },
    ],
  },
  {
    type: "enum",
    propName: "color",
    values: [
      {
        propValue: "lightBlue",
        layers: {},
      },
      {
        propValue: "darkBlue",
        layers: {},
      },
      {
        propValue: "lightGreen",
        layers: {},
      },
      {
        propValue: "darkGreen",
        layers: {},
      },
      {
        propValue: "lightPurple",
        layers: {},
      },
      {
        propValue: "darkPurple",
        layers: {},
      },
      {
        propValue: "lightOrange",
        layers: {},
      },
      {
        propValue: "darkOrange",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "showDollarSign",
    layers: {},
  },
  {
    type: "enum",
    propName: "type",
    values: [
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "normal",
        layers: {},
      },
    ],
  },
  {
    type: "enum",
    propName: "color",
    values: [
      {
        propValue: "lightBlue",
        layers: {},
      },
      {
        propValue: "darkBlue",
        layers: {},
      },
      {
        propValue: "lightGreen",
        layers: {},
      },
      {
        propValue: "darkGreen",
        layers: {},
      },
      {
        propValue: "lightPurple",
        layers: {},
      },
      {
        propValue: "darkPurple",
        layers: {},
      },
      {
        propValue: "lightOrange",
        layers: {},
      },
      {
        propValue: "darkOrange",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "showDollarSign",
  },
  {
    type: "enum",
    propName: "type",
    propValues: ["small", "normal"],
  },
  {
    type: "enum",
    propName: "color",
    propValues: [
      "lightBlue",
      "darkBlue",
      "lightGreen",
      "darkGreen",
      "lightPurple",
      "darkPurple",
      "lightOrange",
      "darkOrange",
    ],
  },
];

export const StatisticsBlockContext = createContext(null);

function StatisticsBlock(_props) {
  const defaults = useContext(StatisticsBlockContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="WBY9NiNPdS"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "WBY9NiNPdS",
        scope: "VkzsMC79hE",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"title-container_8EfC8YVGmb"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_VkzsMC79hE_8EfC8YVGmb"
            }
            key={"8EfC8YVGmb"}
            addSpacing={false}
            element={getStyle("8EfC8YVGmb", "htmlElement")}
          >
            <TextPrimitive
              id={"dollarSign_6VWp9rTmBb"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_VkzsMC79hE_6VWp9rTmBb"
              }
              key={"6VWp9rTmBb"}
              text={getStyle("6VWp9rTmBb", "text")}
              element={getStyle("6VWp9rTmBb", "htmlElement")}
            />
            <TextPrimitive
              id={"title_7gjTC9wnZ6"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_VkzsMC79hE_7gjTC9wnZ6"
              }
              key={"7gjTC9wnZ6"}
              text={
                exists(props.title)
                  ? props.title
                  : getStyle("7gjTC9wnZ6", "text")
              }
              element={getStyle("7gjTC9wnZ6", "htmlElement")}
            />
            <SpacerPrimitive
              id={"Spacer_HvgUh16xzU"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_VkzsMC79hE_HvgUh16xzU"
              }
              key={"HvgUh16xzU"}
            />
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_G2RniF5TGy"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_VkzsMC79hE_G2RniF5TGy"
            }
            key={"G2RniF5TGy"}
          />
          <ContainerPrimitive
            id={"subtitle-container_FEENkwam8s"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_VkzsMC79hE_FEENkwam8s"
            }
            key={"FEENkwam8s"}
            addSpacing={false}
            element={getStyle("FEENkwam8s", "htmlElement")}
          >
            <SpacerPrimitive
              id={"dollarSign-spacer_CxAJM8Da49"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_VkzsMC79hE_CxAJM8Da49"
              }
              key={"CxAJM8Da49"}
            />
            <ContainerPrimitive
              id={"subtitle-tooltip-container_EwGvTJskQv"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_VkzsMC79hE_EwGvTJskQv"
              }
              key={"EwGvTJskQv"}
              addSpacing={true}
              element={getStyle("EwGvTJskQv", "htmlElement")}
            >
              <TextPrimitive
                id={"subtitle_YFJ3tWWZeR"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_VkzsMC79hE_YFJ3tWWZeR"
                }
                key={"YFJ3tWWZeR"}
                text={
                  exists(props.subtitle)
                    ? props.subtitle
                    : getStyle("YFJ3tWWZeR", "text")
                }
                element={getStyle("YFJ3tWWZeR", "htmlElement")}
              />
              <ContainerPrimitive
                key={"8ZhZ4hmQBv"}
                id={"Slot_8ZhZ4hmQBv"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_VkzsMC79hE_8ZhZ4hmQBv"
                }
                addSpacing={false}
              >
                {props.tooltip}
              </ContainerPrimitive>
            </ContainerPrimitive>
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

StatisticsBlock.__variants = [
  {
    name: "showDollarSign",
    type: "variant",
  },
  {
    name: "type",
    type: "group",
    variants: ["small", "normal"],
  },
  {
    name: "color",
    type: "group",
    variants: [
      "lightBlue",
      "darkBlue",
      "lightGreen",
      "darkGreen",
      "lightPurple",
      "darkPurple",
      "lightOrange",
      "darkOrange",
    ],
  },
];

export default StatisticsBlock;
