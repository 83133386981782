import logdna from "@logdna/browser";
import pino from "pino";
import _ from "lodash";
import { IS_PROD, IS_STAGING } from "./hotplate-storefront/actions/types";

// Found in Settings > Organization > API Keys
const LOGDNA_INGESTION_KEY = "bb4d242347fcb5afb943f44cd5891fae";
const environment = IS_PROD ? "production" : IS_STAGING ? "staging" : "development";

export const init = () => {
  logdna.init(LOGDNA_INGESTION_KEY, {
    app: "front-end",
    console: false,
    globalErrorHandlers: false,
    sampleRate: 100,
  });
};

const loggerStack = [];

export let logger = pino({
  browser: {
    asObject: true,
    transmit: {
      send: (level, logEvent) => {
        const { ts, messages, bindings } = logEvent;
        const mergedBindings = _.merge({}, ...bindings);
        logdna.log(messages.join(" "), {
          level: level,
          timestamp: ts,
          deployEnv: environment,
          ...mergedBindings,
        });
      },
    },
  },
  level: "trace",
});

export function pushLoggerContext(context) {
  loggerStack.push(logger);
  logger = logger.child(context);
}

export function popLoggerContext() {
  logger = loggerStack.pop() || logger;
}
