/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Modal from "../../../../hotplate-common/Modal";
import AddEditModal from "../../../components/AddEditModal";

import { Input } from "../../../../visly/Primitives";
import { LabeledInput } from "../../../../visly/Events";

import { mergeDiff, removeNulls } from "../../../../hooks";

import "./css/EditMenuSectionModal.css";

export default function EditMenuSectionModal({ onRequestSave, onRequestClose, menuSection }) {
  const [optionSelectModalState, setOptionSelectModalState] = useState(null);
  const [menuSectionDiff, setMenuSectionDiff] = useState({});

  const mergedMenuSection = removeNulls(mergeDiff(menuSection, menuSectionDiff));

  return (
    <Modal closeModal={onRequestClose} optionSelectModalState={optionSelectModalState}>
      <AddEditModal
        setOptionSelectModalState={(message, optionSelectButtons) => {
          setOptionSelectModalState({
            message: message,
            optionSelectButtons: optionSelectButtons,
            closeOptionSelectModal: () => {
              setOptionSelectModalState(null);
            },
          });
        }}
        cancelButtonOnClick={onRequestClose}
        saveButtonOnClick={() => {
          onRequestSave(menuSectionDiff);
        }}
        saveButtonLoading={false}
        deleteButtonText="Delete Section"
        deleteButtonOnClick={() => {
          onRequestSave(null);
        }}
        deleteMessage="This will remove the section & the menu items within it, are you sure?"
        deleteButtonLoading={false}
        saveDisabled={false}
        title="Section Settings"
        navText="Menu"
        editing={true}
      >
        <div className="editSection-container">
          <LabeledInput
            label="Section Name"
            Input={
              <Input
                style={{ width: "100%" }}
                value={mergedMenuSection.title}
                onChange={(val) => {
                  setMenuSectionDiff({
                    title: val,
                  });
                }}
                placeholder="Untitled Section"
              />
            }
          />
        </div>
      </AddEditModal>
    </Modal>
  );
}
