import React, { useState } from "react";
import { IconButton } from "../../../hotplate-common/primitives/IconButton";
import ReactLoading from "react-loading";
import { setToastPopupInfo } from "../../../hotplate-storefront/actions";
import {
  DotsVerticalIcon,
  DrawingPinFilledIcon,
  DrawingPinIcon,
  GearIcon,
  PersonIcon,
  ReaderIcon,
} from "@radix-ui/react-icons";
import { DropdownMenu } from "../../../hotplate-common/primitives/DropdownMenu";
import { useDispatch, useSelector } from "react-redux";
import { trackOrderEditorOpened, trackOrderPinned, trackReceiptViewed } from "../analytics";

export const OrderInteraction = ({ order, view, functions, ...props }) => {
  const {
    toggleShowCustomerModal,
    setEditOrderData,
    setSubscriptionModalData,
    toggleShowIrpReceipt,
    toggleOrderPinned,
  } = functions;
  const [pinLoading, setPinLoading] = useState(false);
  const packingView = useSelector((state) => state.orderManagement.packingView);
  const completedOrdersDisplay = useSelector(
    (state) => state.orderManagement.completedOrdersDisplay
  );
  const primarySort = useSelector((state) => state.orderManagement.sort.primary);
  const trackMetaData = {
    packingView,
    primarySort,
    completedOrdersDisplay,
  };
  const dispatch = useDispatch();

  const buttonObj = {
    pin: {
      visible: true,
      label: `${order.pinned ? "Unpin" : "Pin"} order`,
      icon: pinLoading ? (
        <ReactLoading type="spin" height="20px" width="20px" color="inherit" />
      ) : order.pinned ? (
        <DrawingPinFilledIcon />
      ) : (
        <DrawingPinIcon />
      ),
      onClick: async () => {
        setPinLoading(true);
        try {
          await toggleOrderPinned({
            chefId: order.chefId,
            paymentIntentId: order.paymentIntentId,
            pinned: !order.pinned,
          });
          trackOrderPinned({
            ...trackMetaData,
            orderId: order.paymentIntentId,
            enabled: !order.pinned,
          });
          setToastPopupInfo({
            type: "info",
            text: !order.pinned ? `Order pinned to start` : `Order unpinned`,
          })(dispatch);
        } catch (e) {
          setToastPopupInfo({
            type: "error",
            text: `Failed to pin order: ${e}`,
          })(dispatch);
          throw e;
        } finally {
          setPinLoading(false);
        }
      },
      css: order.pinned
        ? {
            backgroundColor: "$warning9",
            color: "$white",
            "&:hover": { backgroundColor: "$warning10" },
          }
        : {},
    },
    edit: {
      visible: true,
      label: "Edit order",
      icon: <GearIcon />,
      onClick: () => {
        setEditOrderData(order);
        trackOrderEditorOpened({
          ...trackMetaData,
          orderId: order.paymentIntentId,
        });
      },
    },
    subscriber: {
      visible: order.isSubscription,
      label: "Subscriber profile",
      icon: <PersonIcon />,
      onClick: () => {
        setSubscriptionModalData(order);
      },
    },
    receipt: {
      visible: true,
      label: "Receipt & refund",
      icon: <ReaderIcon />,
      onClick: () => {
        toggleShowIrpReceipt(order);
        trackReceiptViewed({
          ...trackMetaData,
          orderId: order.paymentIntentId,
        });
      },
    },
    customer: {
      visible: true,
      label: "Customer profile",
      icon: <PersonIcon />,
      onClick: () => {
        toggleShowCustomerModal(view, order);
      },
    },
  };

  return (
    <DropdownMenu
      {...props}
      trigger={
        <IconButton
          icon={<DotsVerticalIcon />}
          css={
            order.pinned
              ? {
                  backgroundColor: "$warning9",
                  color: "$white",
                  "&:hover": { backgroundColor: "$warning10" },
                }
              : {}
          }
          onClick={(e) => e.stopPropagation()}
        /> // prevent click from propagating to row & marking the orde as done
      }
      onClick={(e) => e.stopPropagation()} // prevent click from propagating to row & marking the orde as done
    >
      {Object.keys(buttonObj).map((button) => {
        const { visible, label, icon, onClick, css } = buttonObj[button];
        if (!visible) return null;
        return (
          <DropdownMenu.Item text={label} hint={icon} key={button} onClick={onClick} css={css} />
        );
      })}
    </DropdownMenu>
  );
};
