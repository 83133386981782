import React from "react";
import { styled } from "../../stitches.config";
import {
  RatioBoxLoader,
  TitleLoader,
  DescriptionLoader,
  VariableWidthLoader,
} from "./CommonLoaders";
import { Flex } from "../primitives/Containers";

const EventCardLoaderContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "400px",
  gap: "$md",
  padding: "$md",
  borderRadius: "$md",
  boxShadow: "$elevation5",
  backgroundColor: "$white",
});

const EventCardFieldLoader = () => {
  return (
    <Flex css={{ width: "100%", alignItems: "flex-start" }}>
      <RatioBoxLoader width={28} ratio={1} css={{ marginRight: "$md", borderRadius: "$sm" }} />
      <Flex css={{ flexDirection: "column", width: "100%" }}>
        <DescriptionLoader css={{ width: "50%", marginTop: 2 }} />
        <DescriptionLoader css={{ width: "35%", height: 12 }} />
      </Flex>
    </Flex>
  );
};

export const EventCardLoader = () => {
  return (
    <EventCardLoaderContainer>
      <TitleLoader css={{ width: "65%" }} />
      <EventCardFieldLoader />
      <EventCardFieldLoader />
      <TitleLoader css={{ height: "$space$xl", marginLeft: "auto", marginBottom: 0, width: 105 }} />
    </EventCardLoaderContainer>
  );
};

const StatBlockLoaderContainer = styled("div", {
  display: "flex",
  width: "100%",
  flexDirection: "column",
  br: "$md",
  pad: "$md",
  border: `1px solid $gray6`,
  backgroundColor: "$gray1",
});

export const StatBlockLoader = () => {
  return (
    <StatBlockLoaderContainer>
      <VariableWidthLoader
        min={50}
        max={80}
        unit="%"
        css={{ height: "$fontSizes$xxl", marginBottom: "$sm" }}
      />
      <VariableWidthLoader min={25} max={60} unit="%" css={{ height: 16 }} />
    </StatBlockLoaderContainer>
  );
};
