import {
  formatToCurrency,
  getDateTimeString,
  getFormattedPhone,
  numberWithCommas,
} from "@hotplate/utils-ts/helperFunctions";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useContext, useState } from "react";

import { CSVLink } from "react-csv";
import { TableVirtuoso } from "react-virtuoso";
import { Area, AreaChart, Bar, BarChart, Tooltip, XAxis, YAxis } from "recharts";
import { FirebaseContext } from "../../firebaseSocket";
import { Label } from "../../hotplate-common/primitives/Label";
import {
  Flex,
  Column,
  Row,
  H1,
  P,
  Grid,
  Center,
} from "../../hotplate-common/primitives/Containers";
import { Select } from "../../hotplate-common/primitives/Select";
import { colors, styled } from "../../stitches.config";
import CustomerModal from "../components/CustomerModal";
import { InsightMetric } from "./InsightMetric";
import { ChartWrapper } from "./ChartWrapper";
import { Badge } from "../../hotplate-common/primitives/Badge";
import { PuffLoader } from "../../hotplate-common/loaders/PuffLoader";
import { Error } from "../../hotplate-common/primitives/Error";
import { NothingHere } from "../orderManagement/components/NothingHere";
import { FeedbackChart } from "./FeedbackChart";
import { usePortalUser } from "../../auth";

type AggregationPeriod = "day" | "week" | "month" | "event";

const rangeChoices = {
  day: { choices: ["7", "14", "30", "60", "90", "all"], default: "14" },
  week: { choices: ["4", "8", "12", "24", "all"], default: "12" },
  month: { choices: ["3", "6", "9", "12", "all"], default: "12" },
  event: { choices: ["5", "10", "15", "25", "all"], default: "10" },
};

const RefreshDataButton = styled("button", {
  all: "unset",
  cursor: "pointer",
  color: "$gray9",
  fontFamily: "$avenir",
  fontSize: 12,
  fontWeight: "$semi_bold",
  padding: "$sm",
  paddingBottom: 0,
  "&:hover": {
    textDecoration: "underline",
  },
  "&:disabled": {
    pointerEvents: "none",
  },
});

const tooltipStyleProps: {
  contentStyle: React.CSSProperties;
  labelStyle: React.CSSProperties;
  separator: string;
  wrapperStyle: React.CSSProperties;
  itemStyle: React.CSSProperties;
  animationDuration: number;
  cursor: boolean | React.SVGProps<SVGElement>;
} = {
  contentStyle: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.violet1,
    borderRadius: 4,
    border: `1px solid ${colors.violet4}`,
    boxShadow: "0px 6px 16px 2px rgba(35, 52, 52, 0.1)",
    padding: 12,
    outline: "none",
  },
  wrapperStyle: {
    border: "none",
    outline: "none",
  },
  itemStyle: {
    margin: 0,
    padding: 0,
    fontFamily: "Avenir",
    fontSize: 13,
    marginTop: 8,
  },
  labelStyle: {
    margin: 0,
    padding: 0,
    color: colors.violet12,
    fontWeight: 600,
    fontFamily: "Avenir",
    fontSize: 14,
    marginBottom: 4,
  },
  separator: ": ",
  animationDuration: 300,
  cursor: {
    stroke: colors.mauve6,
    strokeWidth: 1,
    strokeDasharray: "5 5",
  },
};

function EventTooltipContent(props: any) {
  return (
    <>
      {props.payload && props.payload[0] != null ? (
        <div style={tooltipStyleProps.contentStyle}>
          <p style={tooltipStyleProps.labelStyle}>{props.payload[0].payload.title}</p>

          {/* <p style={tooltipStyleProps.itemStyle}>Orders opened: {props.payload[0].payload.date}</p> */}
          {props.revenue && (
            <p style={{ color: props.revenue || colors.gray11, ...tooltipStyleProps.itemStyle }}>
              Revenue: {formatToCurrency(props.payload[0].payload.revenue)}
            </p>
          )}
          {props.numNewCustomers && (
            <p
              style={{
                color: props.numNewCustomers || colors.gray11,
                ...tooltipStyleProps.itemStyle,
              }}
            >
              New customers: {numberWithCommas(props.payload[0].payload.numNewCustomers)}
            </p>
          )}
          {props.numReturningCustomers && (
            <p
              style={{
                color: props.numReturningCustomers || colors.gray11,
                ...tooltipStyleProps.itemStyle,
              }}
            >
              Returning customers:{" "}
              {numberWithCommas(props.payload[0].payload.numReturningCustomers)}
            </p>
          )}
          {props.numOrdersFromNewCustomers && (
            <p
              style={{
                color: props.numOrdersFromNewCustomers || colors.gray11,
                ...tooltipStyleProps.itemStyle,
              }}
            >
              Orders from new customers:{" "}
              {numberWithCommas(props.payload[0].payload.numOrdersFromNewCustomers)}
            </p>
          )}
          {props.numOrdersFromReturningCustomers && (
            <p
              style={{
                color: props.numOrdersFromReturningCustomers || colors.gray11,
                ...tooltipStyleProps.itemStyle,
              }}
            >
              Orders from returning customers:{" "}
              {numberWithCommas(props.payload[0].payload.numOrdersFromReturningCustomers)}
            </p>
          )}
          {props.uniqueVisitors && (
            <p
              style={{
                color: props.uniqueVisitors || colors.gray11,
                ...tooltipStyleProps.itemStyle,
              }}
            >
              Visitors: {numberWithCommas(props.payload[0].payload.uniqueVisitors)}
            </p>
          )}
          <Row css={{ marginTop: 12, alignItems: "baseline", gap: "$lg" }}>
            <p
              style={{ color: colors.gray11, ...tooltipStyleProps.itemStyle }}
            >{`${props.payload[0].payload.date}`}</p>
            {props.payload[0].payload.private ? (
              <Badge color="light_accent" size="small" css={{ marginLeft: "auto" }}>
                Private Event
              </Badge>
            ) : null}
          </Row>
        </div>
      ) : null}
    </>
  );
}

function StyledTable(props: any) {
  return <table {...props} style={{ borderSpacing: "1em", borderCollapse: "separate" }} />;
}

export default function Insights() {
  const firebaseContext = useContext<any>(FirebaseContext);
  const { chefId } = usePortalUser();

  const queryClient = useQueryClient();
  const getInsights = useQuery(
    ["getInsights", chefId],
    async () => {
      return (await firebaseContext.database.ref(`/hosts/${chefId}/insightsCache`).get()).val();
    },
    { staleTime: Number.POSITIVE_INFINITY }
  );
  const refreshInsights = useMutation(
    () => {
      return firebaseContext.cloudFunctions.updateInsightsCache();
    },
    {
      onSuccess: (json: any) => {
        queryClient.setQueryData(["getInsights", chefId], json.data);
      },
    }
  );

  const [aggregationPeriod, setAggregationPeriod] = useState<AggregationPeriod>("event"); // bucket stats by day/week/month/event
  const [range, setRange] = useState<string>(rangeChoices[aggregationPeriod].default); // view the past [range] days/weeks/months/events
  const [selectedCustomerPhoneNumber, setSelectedCustomerPhoneNumber] = useState<string>();
  const [customerSort, setCustomerSort] = useState({
    field: "totalSpend",
    ascending: false,
  });
  const [customerSearch, setCustomerSearch] = useState("");

  let perDay = undefined;
  if (getInsights.data?.perDay) {
    const orderDays = Object.keys(getInsights.data.perDay);
    orderDays.sort();
    if (orderDays.length) {
      const allDays = [];

      // find the local time date object that will render a matching date
      const maybeStart = new Date(orderDays[0]);
      const maybeStartBefore = new Date(maybeStart.getTime() - 8.64e7);
      const maybeStartAfter = new Date(maybeStart.getTime() + 8.64e7);
      const start =
        maybeStartBefore.toISOString().split("T")[0] === orderDays[0]
          ? maybeStartBefore
          : maybeStart.toISOString().split("T")[0] === orderDays[0]
          ? maybeStart
          : maybeStartAfter;
      const end = new Date(orderDays[orderDays.length - 1]);

      let cur = start;
      while (cur.getTime() <= end.getTime()) {
        allDays.push(cur.toISOString().split("T")[0]);
        cur = new Date(cur.getTime() + 8.64e7);
      }

      perDay = [];
      for (const day of allDays) {
        if (getInsights.data.perDay[day]) {
          perDay.push(getInsights.data.perDay[day]);
        } else {
          perDay.push({
            date: day,
            revenue: 0,
            numNewCustomers: 0,
            numReturningCustomers: 0,
            numOrdersFromNewCustomers: 0,
            numOrdersFromReturningCustomers: 0,
            uniqueVisitors: 0,
          });
        }
      }
    }
  }

  let perWeek: any[] | undefined = undefined;
  if (perDay) {
    perWeek = [];
    for (const entry of perDay) {
      const entryDate = new Date(entry.date);
      entryDate.setUTCDate(entryDate.getUTCDate() - entryDate.getUTCDay());

      let shouldAdd = false;
      if (perWeek.length === 0) {
        shouldAdd = true;
      } else {
        const cur = new Date(perWeek[perWeek.length - 1].date);
        cur.setUTCDate(cur.getUTCDate() - cur.getUTCDay());
        if (cur.toISOString() !== entryDate.toISOString()) {
          shouldAdd = true;
        }
      }

      if (shouldAdd) {
        perWeek.push({
          date: entryDate.toISOString().split("T")[0],
          revenue: 0,
          numNewCustomers: 0,
          numReturningCustomers: 0,
          numOrdersFromNewCustomers: 0,
          numOrdersFromReturningCustomers: 0,
          uniqueVisitors: 0,
        });
      }

      perWeek[perWeek.length - 1].revenue += entry.revenue;
      perWeek[perWeek.length - 1].numNewCustomers += entry.numNewCustomers;
      perWeek[perWeek.length - 1].numReturningCustomers += entry.numReturningCustomers;
      perWeek[perWeek.length - 1].numOrdersFromNewCustomers += entry.numOrdersFromNewCustomers;
      perWeek[perWeek.length - 1].numOrdersFromReturningCustomers +=
        entry.numOrdersFromReturningCustomers;
      perWeek[perWeek.length - 1].uniqueVisitors += entry.uniqueVisitors;
    }
  }

  let perMonth: any[] | undefined = undefined;
  if (perDay) {
    perMonth = [];
    for (const entry of perDay) {
      const entryDate = new Date(entry.date);
      entryDate.setUTCDate(1);

      let shouldAdd = false;
      if (perMonth.length === 0) {
        shouldAdd = true;
      } else {
        const cur = new Date(perMonth[perMonth.length - 1].date);
        cur.setUTCDate(1);
        if (cur.toISOString() !== entryDate.toISOString()) {
          shouldAdd = true;
        }
      }

      if (shouldAdd) {
        const dateString = entryDate.toISOString().split("T")[0];
        perMonth.push({
          date: dateString.slice(0, dateString.lastIndexOf("-")),
          revenue: 0,
          numNewCustomers: 0,
          numReturningCustomers: 0,
          numOrdersFromNewCustomers: 0,
          numOrdersFromReturningCustomers: 0,
          uniqueVisitors: 0,
        });
      }

      perMonth[perMonth.length - 1].revenue += entry.revenue;
      perMonth[perMonth.length - 1].numNewCustomers += entry.numNewCustomers;
      perMonth[perMonth.length - 1].numReturningCustomers += entry.numReturningCustomers;
      perMonth[perMonth.length - 1].numOrdersFromNewCustomers += entry.numOrdersFromNewCustomers;
      perMonth[perMonth.length - 1].numOrdersFromReturningCustomers +=
        entry.numOrdersFromReturningCustomers;
      perMonth[perMonth.length - 1].uniqueVisitors += entry.uniqueVisitors;
    }
  }

  const stats: {
    [key in AggregationPeriod]: any[] | undefined;
  } = {
    day: range === "all" ? perDay : perDay?.slice(-parseInt(range)),
    week: range === "all" ? perWeek : perWeek?.slice(-parseInt(range)),
    month: range === "all" ? perMonth : perMonth?.slice(-parseInt(range)),
    event: getInsights.data?.perEvent
      ? range === "all"
        ? Object.values(getInsights.data.perEvent)
        : Object.values(getInsights.data.perEvent).slice(-parseInt(range))
      : undefined,
  };

  function getTrProps(customer: any) {
    return {
      onClick: () => {
        setSelectedCustomerPhoneNumber(customer.phone);
      },
      style: { cursor: "pointer" },
    };
  }

  const loadedSuccessfully = !getInsights.isLoading && !getInsights.isError && getInsights.data;

  return (
    <Flex
      css={{
        padding: "$md",
        width: "100%",
        justifyContent: "center",
        "@tablet": {
          padding: "$lg",
        },
        fontFamily: "$avenir",
      }}
    >
      <Column css={{ width: "100%", maxWidth: "$maxContentWidth" }}>
        <Row css={{ flexWrap: "wrap", gap: "$sm", alignItems: "baseline" }}>
          <H1
            css={{
              fontFamily: "$arboria",
              fontSize: "$heading",
              fontWeight: "$semi_bold",
              lineHeight: "$text",
              color: "$violet12",
            }}
          >
            Insights
          </H1>
          <Row css={{ alignItems: "flex-end" }}>
            <P
              css={{
                fontFamily: "$avenir",
                fontSize: 12,
                fontWeight: "$normal",
                color: "$gray9",
              }}
            >
              {loadedSuccessfully
                ? `Last updated ${new Date(getInsights.data.lastUpdatedAt).toLocaleString(
                    undefined,
                    {
                      dateStyle: "short",
                      timeStyle: "short",
                    }
                  )}`
                : ""}
            </P>
            {!getInsights.isLoading && (
              <RefreshDataButton
                disabled={refreshInsights.isLoading}
                onClick={() => {
                  refreshInsights.mutateAsync();
                }}
              >
                {refreshInsights.isLoading
                  ? "Refreshing... (this may take several minutes)"
                  : "Refresh"}
              </RefreshDataButton>
            )}
          </Row>
        </Row>
        <Error show={refreshInsights.isError}>{"" + refreshInsights.error}</Error>
        {getInsights.isLoading ? (
          <Center css={{ height: "100%", width: "100%", paddingTop: 256 }}>
            <PuffLoader />
          </Center>
        ) : getInsights.isError ? (
          <Error>{"" + getInsights.error}</Error>
        ) : !getInsights.data ? (
          <NothingHere />
        ) : (
          <>
            {!stats[aggregationPeriod] ? (
              <p>Not enough data collected to show graphs yet.</p>
            ) : (
              <>
                {(() => {
                  const data = stats[aggregationPeriod];
                  if (data?.length) {
                    return (
                      <Column
                        css={{
                          width: "100%",
                          paddingBottom: "$sizes$navHeight",
                          marginBottom: "$lg",
                        }}
                      >
                        <Row css={{ gap: "$md", padding: "$lg 0px", flexWrap: "wrap" }}>
                          <Label text="Breakdown by" side="left" tooltipText={null} css={null}>
                            <Select
                              onValueChange={(value: AggregationPeriod) => {
                                setAggregationPeriod(value);
                                setRange(rangeChoices[value].default);
                              }}
                              value={aggregationPeriod}
                              placeholder="Breakdown by..."
                            >
                              <Select.Option value={"day"}>Day</Select.Option>
                              <Select.Option value={"week"}>Week</Select.Option>
                              <Select.Option value={"month"}>Month</Select.Option>
                              <Select.Separator />
                              <Select.Option value={"event"}>Event</Select.Option>
                            </Select>
                          </Label>
                          <Label text="Range" side="left" tooltipText={null} css={null}>
                            <Select
                              onValueChange={(value: string) => {
                                setRange(value);
                              }}
                              value={range}
                              placeholder="Show the last..."
                            >
                              {rangeChoices[aggregationPeriod].choices.map((choice) => (
                                <Select.Option key={choice} value={choice}>
                                  {choice} {aggregationPeriod}s
                                </Select.Option>
                              ))}
                            </Select>
                          </Label>
                        </Row>
                        <Grid
                          css={{
                            gridTemplateColumns: "repeat(auto-fit, minmax(255px, 1fr))",
                            gap: "$md",
                            width: "100%",
                            "@tablet": {
                              gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
                            },
                            "@desktop_sm": {
                              gap: "$lg",
                            },
                          }}
                        >
                          <ChartWrapper
                            title="Revenue"
                            labels={[data[0].date, data[data.length - 1].date]}
                            tooltipText="The sum of all food sales, tips, and service fees you charge your customers. Does not include Hotplate's fee."
                          >
                            {aggregationPeriod === "event" ? (
                              <BarChart
                                data={data}
                                margin={{ top: 4, right: 0, bottom: 0, left: 0 }}
                              >
                                <XAxis dataKey="date" hide={true} />
                                <Tooltip
                                  content={<EventTooltipContent revenue={colors.grass9} />}
                                  {...tooltipStyleProps}
                                />
                                <Bar
                                  dataKey="revenue"
                                  fill={colors.grass6}
                                  stroke={colors.grass9}
                                  strokeWidth={2}
                                  minPointSize={5}
                                />
                              </BarChart>
                            ) : (
                              <AreaChart
                                data={data}
                                margin={{ top: 4, right: 0, bottom: 0, left: 0 }}
                              >
                                <YAxis
                                  hide={true}
                                  domain={[0, "dataMax"]}
                                  // provide domain to prevent issues with negative floating point evaluations causing the domain to start with a large negative number
                                />
                                <XAxis dataKey="date" hide={true} />
                                <Tooltip
                                  formatter={(value) => formatToCurrency(value)}
                                  {...tooltipStyleProps}
                                />
                                <Area
                                  type="monotone"
                                  dataKey="revenue"
                                  fill={colors.grass6}
                                  stroke={colors.grass9}
                                  strokeWidth={2}
                                  dot={false}
                                  name="Revenue"
                                />
                              </AreaChart>
                            )}
                          </ChartWrapper>
                          <ChartWrapper
                            title="Orders"
                            labels={[data[0].date, data[data.length - 1].date]}
                          >
                            {aggregationPeriod === "event" ? (
                              <BarChart
                                data={data}
                                margin={{ top: 4, right: 0, bottom: 0, left: 0 }}
                              >
                                <XAxis dataKey="date" hide={true} />
                                <Tooltip
                                  {...tooltipStyleProps}
                                  content={
                                    <EventTooltipContent
                                      numOrdersFromNewCustomers={colors.mauve9}
                                      numOrdersFromReturningCustomers={colors.tomato9}
                                    />
                                  }
                                />
                                <Bar
                                  dataKey="numOrdersFromNewCustomers"
                                  fill={colors.mauve6}
                                  stroke={colors.mauve9}
                                  strokeWidth={2}
                                  minPointSize={5}
                                  stackId={1}
                                />
                                <Bar
                                  dataKey="numOrdersFromReturningCustomers"
                                  fill={colors.tomato6}
                                  stroke={colors.tomato9}
                                  strokeWidth={2}
                                  minPointSize={5}
                                  stackId={1}
                                />
                              </BarChart>
                            ) : (
                              <AreaChart
                                data={data}
                                margin={{ top: 4, right: 0, bottom: 0, left: 0 }}
                              >
                                <YAxis
                                  hide={true}
                                  domain={[0, "dataMax"]}
                                  // provide domain to prevent issues with negative floating point evaluations causing the domain to start with a large negative number
                                />
                                <XAxis dataKey="date" hide={true} />
                                <Tooltip
                                  {...tooltipStyleProps}
                                  formatter={(value) => numberWithCommas(value)}
                                />
                                <Area
                                  type="monotone"
                                  dataKey="numOrdersFromNewCustomers"
                                  name="Orders from new customers"
                                  stroke={colors.mauve9}
                                  strokeWidth={2}
                                  fill={colors.mauve6}
                                  stackId={1}
                                />
                                <Area
                                  type="monotone"
                                  dataKey="numOrdersFromReturningCustomers"
                                  name="Orders from repeat customers"
                                  stroke={colors.tomato9}
                                  strokeWidth={2}
                                  fill={colors.tomato6}
                                  stackId={1}
                                />
                              </AreaChart>
                            )}
                          </ChartWrapper>
                          <ChartWrapper
                            title="Customers"
                            labels={[data[0].date, data[data.length - 1].date]}
                          >
                            {aggregationPeriod === "event" ? (
                              <BarChart
                                data={data}
                                margin={{ top: 4, right: 0, bottom: 0, left: 0 }}
                              >
                                <XAxis dataKey="date" hide={true} />
                                <Tooltip
                                  {...tooltipStyleProps}
                                  content={
                                    <EventTooltipContent
                                      numNewCustomers={colors.violet9}
                                      numReturningCustomers={colors.cyan9}
                                    />
                                  }
                                />
                                <Bar
                                  dataKey="numNewCustomers"
                                  fill={colors.violet6}
                                  stroke={colors.violet9}
                                  strokeWidth={2}
                                  minPointSize={5}
                                  stackId={1}
                                />
                                <Bar
                                  dataKey="numReturningCustomers"
                                  fill={colors.cyan6}
                                  stroke={colors.cyan9}
                                  strokeWidth={2}
                                  minPointSize={5}
                                  stackId={1}
                                />
                              </BarChart>
                            ) : (
                              <AreaChart
                                data={data}
                                margin={{ top: 4, right: 0, bottom: 0, left: 0 }}
                              >
                                <YAxis
                                  hide={true}
                                  domain={[0, "dataMax"]}
                                  // provide domain to prevent issues with negative floating point evaluations causing the domain to start with a large negative number
                                />
                                <XAxis dataKey="date" hide={true} />
                                <Tooltip
                                  {...tooltipStyleProps}
                                  formatter={(value) => numberWithCommas(value)}
                                />
                                <Area
                                  type="monotone"
                                  dataKey="numNewCustomers"
                                  name="New customers"
                                  stroke={colors.violet9}
                                  strokeWidth={2}
                                  fill={colors.violet6}
                                  stackId={1}
                                />
                                <Area
                                  type="monotone"
                                  dataKey="numReturningCustomers"
                                  name="Repeat customers"
                                  stroke={colors.cyan9}
                                  strokeWidth={2}
                                  fill={colors.cyan6}
                                  stackId={1}
                                />
                              </AreaChart>
                            )}
                          </ChartWrapper>
                          <ChartWrapper
                            title="Unique Visitors"
                            labels={[data[0].date, data[data.length - 1].date]}
                            tooltipText="Tracking of page visits only began in July."
                          >
                            {aggregationPeriod === "event" ? (
                              <BarChart
                                data={data}
                                margin={{ top: 4, right: 0, bottom: 0, left: 0 }}
                              >
                                <XAxis dataKey="date" hide={true} />
                                <Tooltip
                                  {...tooltipStyleProps}
                                  content={<EventTooltipContent uniqueVisitors={colors.amber9} />}
                                />
                                <Bar
                                  type="monotone"
                                  minPointSize={5}
                                  dataKey="uniqueVisitors"
                                  fill={colors.amber6}
                                  stroke={colors.amber9}
                                  strokeWidth={2}
                                />
                              </BarChart>
                            ) : (
                              <AreaChart
                                data={data}
                                margin={{ top: 4, right: 0, bottom: 0, left: 0 }}
                              >
                                <YAxis
                                  hide={true}
                                  domain={[0, "dataMax"]}
                                  // provide domain to prevent issues with negative floating point evaluations causing the domain to start with a large negative number
                                />
                                <XAxis dataKey="date" hide={true} />
                                <Tooltip
                                  {...tooltipStyleProps}
                                  formatter={(value) => numberWithCommas(value)}
                                />
                                <Area
                                  type="monotone"
                                  dataKey="uniqueVisitors"
                                  fill={colors.amber6}
                                  stroke={colors.amber9}
                                  strokeWidth={2}
                                  name="Visitors"
                                  dot={false}
                                />
                              </AreaChart>
                            )}
                          </ChartWrapper>
                          <FeedbackChart />
                        </Grid>
                      </Column>
                    );
                  }
                })()}
              </>
            )}

            <Row css={{ flexWrap: "wrap", gap: "$md", paddingBlock: "$md" }}>
              <InsightMetric
                value={`${Math.round((getInsights.data.retention + Number.EPSILON) * 1000) / 10}%`}
                label={`Retention for the past 30 days`}
              />
              <InsightMetric
                value={getInsights.data.memberCount}
                label={`Signed up for SMS reminders`}
              />
            </Row>

            <br />
            <p>⚠️ Under Construction ⚠️</p>
            <br />
            <h1 style={{ fontSize: "40px", fontWeight: "bold" }}>Customers</h1>
            <input
              type="text"
              size={20}
              placeholder="Filter"
              value={customerSearch}
              onChange={(e) => {
                setCustomerSearch(e.target.value);
              }}
            />
            {getInsights.data.customers ? (
              (() => {
                const allCustomers = Object.values(getInsights.data.customers);
                const data = allCustomers
                  .filter(
                    (customer: any) =>
                      !customerSearch ||
                      [customer.fullName, customer.phone]
                        .map((s) => s.toLowerCase().replace(/\s/g, ""))
                        .join("\n")
                        .includes(customerSearch.toLowerCase().replace(/\s/g, ""))
                  )
                  .sort((a: any, b: any) => {
                    let ret;
                    switch (customerSort.field) {
                      case "fullName":
                      case "phone": {
                        ret = a[customerSort.field].localeCompare(b[customerSort.field], "en", {
                          sensitivity: "accent",
                        });
                        break;
                      }
                      default:
                        ret = a[customerSort.field] - b[customerSort.field];
                    }
                    return customerSort.ascending ? ret : ret * -1;
                  });

                return (
                  <>
                    <TableVirtuoso
                      style={{ height: "500px" }}
                      components={{
                        Table: StyledTable,
                      }}
                      data={data}
                      fixedHeaderContent={() => (
                        <tr>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setCustomerSort({
                                field: "fullName",
                                ascending:
                                  customerSort.field !== "fullName"
                                    ? true
                                    : !customerSort.ascending,
                              });
                            }}
                          >
                            Name
                            {customerSort.field !== "fullName"
                              ? ""
                              : customerSort.ascending
                              ? "▲"
                              : "▼"}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setCustomerSort({
                                field: "phone",
                                ascending:
                                  customerSort.field !== "phone" ? true : !customerSort.ascending,
                              });
                            }}
                          >
                            Phone
                            {customerSort.field !== "phone"
                              ? ""
                              : customerSort.ascending
                              ? "▲"
                              : "▼"}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setCustomerSort({
                                field: "totalSpend",
                                ascending:
                                  customerSort.field !== "totalSpend"
                                    ? false
                                    : !customerSort.ascending,
                              });
                            }}
                          >
                            Total Spend
                            {customerSort.field !== "totalSpend"
                              ? ""
                              : customerSort.ascending
                              ? "▲"
                              : "▼"}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setCustomerSort({
                                field: "ordersPlaced",
                                ascending:
                                  customerSort.field !== "ordersPlaced"
                                    ? false
                                    : !customerSort.ascending,
                              });
                            }}
                          >
                            # of Orders
                            {customerSort.field !== "ordersPlaced"
                              ? ""
                              : customerSort.ascending
                              ? "▲"
                              : "▼"}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setCustomerSort({
                                field: "isSignedUpForReminders",
                                ascending:
                                  customerSort.field !== "isSignedUpForReminders"
                                    ? true
                                    : !customerSort.ascending,
                              });
                            }}
                          >
                            Reminders?
                            {customerSort.field !== "isSignedUpForReminders"
                              ? ""
                              : customerSort.ascending
                              ? "▲"
                              : "▼"}
                          </th>
                        </tr>
                      )}
                      itemContent={(index, customer: any) => (
                        <>
                          <td {...getTrProps(customer)}>{customer.fullName}</td>
                          <td {...getTrProps(customer)}>{getFormattedPhone(customer.phone)}</td>
                          <td {...getTrProps(customer)}>${customer.totalSpend}</td>
                          <td {...getTrProps(customer)}>{customer.ordersPlaced}</td>
                          <td {...getTrProps(customer)}>
                            {customer.isSignedUpForReminders ? "Yes" : "No"}
                          </td>
                        </>
                      )}
                      computeItemKey={(index, customer) => customer.phone}
                    />
                    <p>
                      Viewing {data.length} / {allCustomers.length} customers.
                    </p>
                    <CSVLink
                      data={data}
                      filename={"hotplate-customers-" + getDateTimeString() + ".csv"}
                      target="_blank"
                    >
                      Export
                    </CSVLink>
                  </>
                );
              })()
            ) : (
              <p>No customers yet.</p>
            )}
          </>
        )}
        <CustomerModal
          isOpen={!!selectedCustomerPhoneNumber}
          phone={selectedCustomerPhoneNumber}
          onRequestClose={() => {
            setSelectedCustomerPhoneNumber(undefined);
          }}
        />
      </Column>
    </Flex>
  );
}
