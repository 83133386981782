/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import { FirebaseContext } from "../../firebaseSocket";
import "./css/index.css";

import { textstyles } from "../../visly";

import { SearchBar, Button } from "../../visly/Primitives";

export default function GodMode({ godModeSwitchAccounts }) {
  const firebaseContext = useContext(FirebaseContext);
  const siteSettings = useSelector((state) => state.hostPortal.hostInfo.siteSettings);
  const { godModeOptions, godModeOptionsLoading } = useSelector((state) => state.hostPortal);

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    firebaseContext.api.getGodModeOptions();
  }, [firebaseContext]);

  return (
    <div className="godModeMain">
      <div className="godModeHeader">
        <p style={{ ...textstyles.body, marginBottom: 4, color: "#828989" }}>CURRENT ACCOUNT</p>
        <p style={{ ...textstyles.h2, marginBottom: 32, color: "#233434" }}>
          {!siteSettings ? "No Site Settings" : siteSettings.restaurantName}
        </p>
        <SearchBar
          value={searchText}
          onChange={(value) => setSearchText(value)}
          placeholder="Search Chefs"
        />
      </div>
      {godModeOptionsLoading && <ReactLoading height={40} width={40} color="grey" />}
      {!godModeOptionsLoading && (
        <div className="godModeOptionsScrollbox">
          <div className="godModeOptionsContainer">
            {godModeOptions
              .filter((hostId) => {
                if (searchText == "") return true;
                return JSON.stringify(hostId).toLowerCase().includes(searchText.toLowerCase());
              })
              .map((hostId, index) => (
                <Button
                  key={index}
                  size="large"
                  onClick={() => {
                    godModeSwitchAccounts(hostId);
                  }}
                  text={hostId}
                  className="godModeOption"
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
