import fetchRetry from "./FetchRetry";
import {
  CREATE_PARTNERSHIP_START,
  CREATE_PARTNERSHIP_FAILURE,
  CREATE_PARTNERSHIP_SUCCESS,
  ADD_FUNDING_ACCOUNT_START,
  ADD_FUNDING_ACCOUNT_FAILURE,
  ADD_FUNDING_ACCOUNT_SUCCESS,
  INITIATE_PAYMENT_START,
  INITIATE_PAYMENT_FAILURE,
  INITIATE_PAYMENT_SUCCESS,
  backendUrl,
} from "./types";

export const createPartnership = (
  email,
  firstName,
  lastName,
  companyType,
  companyName,
  hostId,
  firebaseToken
) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_PARTNERSHIP_START });
    fetchRetry(backendUrl + "createPartnership", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        firstName,
        lastName,
        companyType,
        companyName,
        hostId,
        firebaseToken,
      }),
    })
      .then((response) =>
        response.json().then(() => {
          if (response.status >= 400 && response.status < 600) {
            dispatch({
              type: CREATE_PARTNERSHIP_FAILURE,
              payload: "SERVER_ERROR",
            });
            return;
          }
          dispatch({ type: CREATE_PARTNERSHIP_SUCCESS });
        })
      )
      .catch((exception) => {
        console.log(exception);
        dispatch({ type: CREATE_PARTNERSHIP_FAILURE, payload: "SERVER_ERROR" });
      });
  };
};

export const addFundingAccount = (
  accountNumber,
  routingNumber,
  accountType,
  hostId,
  firebaseToken
) => {
  return async (dispatch) => {
    dispatch({ type: ADD_FUNDING_ACCOUNT_START });
    fetchRetry(backendUrl + "addFundingAccount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        accountNumber,
        routingNumber,
        accountType,
        hostId,
        firebaseToken,
      }),
    })
      .then((response) =>
        response.json().then((json) => {
          if (response.status >= 400 && response.status < 600) {
            const errorFields = json.errors.fields;
            let errorMsg = "SERVER_ERROR";
            Object.keys(errorFields).forEach((key) => {
              const error = errorFields[key][0];
              if (key === "bank_routing_number" && error === "Is not found.")
                errorMsg = "Your routing number is incorrect, double check & try again.";
            });
            dispatch({ type: ADD_FUNDING_ACCOUNT_FAILURE, payload: errorMsg });
            return;
          }
          dispatch({ type: ADD_FUNDING_ACCOUNT_SUCCESS });
        })
      )
      .catch((exception) => {
        console.log(exception);
        dispatch({
          type: ADD_FUNDING_ACCOUNT_FAILURE,
          payload: "SERVER_ERROR",
        });
      });
  };
};

export const initiatePayment = (paymentDeliveryOption, hostId, firebaseToken) => {
  return async (dispatch) => {
    dispatch({ type: INITIATE_PAYMENT_START });
    return fetchRetry(backendUrl + "initiatePayment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        paymentDeliveryOption,
        hostId,
        firebaseToken,
      }),
    })
      .then((response) =>
        response.json().then((json) => {
          if (json.error === "NO_FUNDS") {
            dispatch({ type: INITIATE_PAYMENT_FAILURE, payload: "NO_FUNDS" });
            return;
          }
          if (response.status >= 400 && response.status < 600) {
            dispatch({
              type: INITIATE_PAYMENT_FAILURE,
              payload: "SERVER_ERROR",
            });
            return;
          }
          dispatch({ type: INITIATE_PAYMENT_SUCCESS });
        })
      )
      .catch((exception) => {
        console.log(exception);
        dispatch({
          type: INITIATE_PAYMENT_FAILURE,
          payload: "SERVER_ERROR",
        });
      });
  };
};
