import {
  CREATE_PARTNERSHIP_START,
  CREATE_PARTNERSHIP_FAILURE,
  CREATE_PARTNERSHIP_SUCCESS,
  ADD_FUNDING_ACCOUNT_START,
  ADD_FUNDING_ACCOUNT_FAILURE,
  ADD_FUNDING_ACCOUNT_SUCCESS,
  INITIATE_PAYMENT_START,
  INITIATE_PAYMENT_FAILURE,
  INITIATE_PAYMENT_SUCCESS,
} from "../actions/types";

const INITIAL_STATE = {
  createPartnershipLoading: false,
  createPartnershipError: "",

  addFundingAccountLoading: false,
  addFundingAccountError: "",

  initiatePaymentLoading: false,
  initiatePaymentError: "",

  payoutStats: {
    nextWithdrawalAmount: null,
    grossAllTimeIncome: null,
  },
  getPayoutStatsLoading: false,
  getPayoutStatsError: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_PARTNERSHIP_START:
      return {
        ...state,
        createPartnershipLoading: true,
        createPartnershipError: "",
      };
    case CREATE_PARTNERSHIP_FAILURE:
      return {
        ...state,
        createPartnershipLoading: false,
        createPartnershipError: action.payload,
      };
    case CREATE_PARTNERSHIP_SUCCESS:
      return { ...state, createPartnershipLoading: false };
    case ADD_FUNDING_ACCOUNT_START:
      return {
        ...state,
        addFundingAccountLoading: true,
        addFundingAccountError: "",
      };
    case ADD_FUNDING_ACCOUNT_FAILURE:
      return {
        ...state,
        addFundingAccountLoading: false,
        addFundingAccountError: action.payload,
      };
    case ADD_FUNDING_ACCOUNT_SUCCESS:
      return { ...state, addFundingAccountLoading: false };
    case INITIATE_PAYMENT_START:
      return {
        ...state,
        initiatePaymentLoading: true,
        initiatePaymentError: "",
        addFundingAccountError: "",
        createPartnershipError: "",
      };
    case INITIATE_PAYMENT_FAILURE:
      return {
        ...state,
        initiatePaymentLoading: false,
        initiatePaymentError: action.payload,
      };
    case INITIATE_PAYMENT_SUCCESS:
      return { ...state, initiatePaymentLoading: false };
    default:
      return state;
  }
};
