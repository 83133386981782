// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./ModificationCategoriesWrapper.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      YXeiTSmKeB: {
        none: {
          text: "Text",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const ModificationCategoriesWrapperContext = createContext(null);

function ModificationCategoriesWrapper(_props) {
  const defaults = useContext(ModificationCategoriesWrapperContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="UbiYsTkUx8"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "UbiYsTkUx8",
        scope: "5pfzhD2V7T",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_GFND28WRyj"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_5pfzhD2V7T_GFND28WRyj"
          }
          key={"GFND28WRyj"}
          addSpacing={false}
          element={getStyle("GFND28WRyj", "htmlElement")}
        >
          <TextPrimitive
            id={"Text_YXeiTSmKeB"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_5pfzhD2V7T_YXeiTSmKeB"
            }
            key={"YXeiTSmKeB"}
            text={
              exists(props.title) ? props.title : getStyle("YXeiTSmKeB", "text")
            }
            element={getStyle("YXeiTSmKeB", "htmlElement")}
          />
          <SpacerPrimitive
            id={"Spacer_KTVgyJfkLi"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_5pfzhD2V7T_KTVgyJfkLi"
            }
            key={"KTVgyJfkLi"}
          />
          <ContainerPrimitive
            key={"6i2jmtoZYK"}
            id={"Slot_6i2jmtoZYK"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_5pfzhD2V7T_6i2jmtoZYK"
            }
            addSpacing={false}
          >
            {props.children}
          </ContainerPrimitive>
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

ModificationCategoriesWrapper.__variants = [];

export default ModificationCategoriesWrapper;
