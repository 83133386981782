/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { OptionSelectModal as VislyOptionSelectModal } from "../visly/Events";
import { Column } from "./primitives/Containers";

type OptionSelectButton = {
  text: string;
  onClick: () => void;
  type: "warning" | "confirm";
  disabled?: boolean;
};

const OptionSelectModal = ({
  optionSelectButtons,
  message,
  absolute,
  closeModal,
  style,
  ...props
}: {
  message: string;
  optionSelectButtons: OptionSelectButton[];
  absolute?: boolean;
  closeModal: () => void;
  style?: React.CSSProperties;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const scrollLockRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const myRef = scrollLockRef.current;
    if (myRef) setTimeout(() => disableBodyScroll(myRef), 150);
    setTimeout(() => setIsOpen(true), 300);
    return () => {
      if (myRef) enableBodyScroll(myRef);
    };
  }, []); // createRef was needed to avoid ref ts error on <Column>, but using createRef makes it a squiggle in dependency array... idk bro I will figure it out later its fine

  function unmount() {
    if (scrollLockRef.current) enableBodyScroll(scrollLockRef.current);
    setIsOpen(false);
    setTimeout(closeModal, 200);
  }

  return (
    <Column
      ref={scrollLockRef}
      css={{
        position: absolute ? "absolute" : "fixed",
        inset: 0,
        zIndex: "$modal",
        "& title": { whiteSpace: "pre-line" },
      }}
    >
      <VislyOptionSelectModal
        {...props}
        style={style}
        isOpen={isOpen} // reveals modal
        CloseButton={<VislyOptionSelectModal.CloseButton onClick={() => unmount()} key="close" />}
        message={message} // prompt text
      >
        {optionSelectButtons.map((buttonObj, buttonIndex) => (
          <VislyOptionSelectModal.Button
            key={buttonIndex}
            type={buttonObj.type} // warning, confirm
            onClick={() => {
              buttonObj.onClick();
              unmount();
            }}
            text={buttonObj.text} // button text
          />
        ))}
      </VislyOptionSelectModal>
    </Column>
  );
};

export default OptionSelectModal;
