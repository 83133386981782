// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./FeeLineItem.MaxRefund.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      EhaN3Qfsoq: {
        none: {
          text: "Refund all",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const MaxRefundContext = createContext(null);

function MaxRefund(_props) {
  const defaults = useContext(MaxRefundContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="3y3z6EujCt"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "3y3z6EujCt",
        scope: "8zmio71sry",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_8tMLgceixS"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_8zmio71sry_8tMLgceixS"
          }
          key={"8tMLgceixS"}
          addSpacing={false}
          element={getStyle("8tMLgceixS", "htmlElement")}
        >
          {
            <TextPrimitive
              id={"Refund All_EhaN3Qfsoq"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_8zmio71sry_EhaN3Qfsoq"
              }
              key={"EhaN3Qfsoq"}
              text={getStyle("EhaN3Qfsoq", "text")}
              element={getStyle("EhaN3Qfsoq", "htmlElement")}
            />
          }
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

MaxRefund.__variants = [];

export default MaxRefund;
