// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./OrderSummary.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import {
  default as ReceiptLineItem,
  default as Subtotal,
  ReceiptLineItemContext as SubtotalContext,
  default as Tax,
  ReceiptLineItemContext as TaxContext,
  default as Fee,
  default as Total,
  ReceiptLineItemContext as TotalContext,
  default as Refund,
  ReceiptLineItemContext as RefundContext,
} from "../Confirmation/ReceiptLineItem";

const styles = [
  {
    type: "default",
    layers: {
      Moqnr9pMAh: {
        none: {
          text: "YOUR ORDER",
          htmlElement: "h1",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      LpskU6sKJR: {},
      NJsTKT7TAw: {
        none: {
          item: "Subtotal",
          fee: true,
        },
      },
      VTUjSKwdu5: {
        none: {
          item: "Tax",
          fee: true,
        },
      },
      H9mYmjUG4d: {
        none: {
          item: "Tip",
          fee: true,
        },
      },
      RoaNMaZmZ2: {
        none: {
          bold: true,
        },
      },
      "8ZPywAoAzP": {
        none: {
          item: "Refund Amount",
          bold: true,
          color: "secondary",
        },
      },
    },
  },
];

const variantPropTypes = [];

export const OrderSummaryContext = createContext(null);

function OrderSummary(_props) {
  const defaults = useContext(OrderSummaryContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="7HdTXYTxxv"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "7HdTXYTxxv",
        scope: "3Cx9Dxj6ct",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => [
        <TextPrimitive
          id={"Your Order_Moqnr9pMAh"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_3Cx9Dxj6ct_Moqnr9pMAh"
          }
          key={"Moqnr9pMAh"}
          text={getStyle("Moqnr9pMAh", "text")}
          element={getStyle("Moqnr9pMAh", "htmlElement")}
        />,
        <ContainerPrimitive
          key={"QBWWAJx3gd"}
          id={"Slot_QBWWAJx3gd"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_3Cx9Dxj6ct_QBWWAJx3gd"
          }
          addSpacing={false}
        >
          {props.DeliverySummary}
        </ContainerPrimitive>,
        <ContainerPrimitive
          key={"TcME5noo8F"}
          id={"Slot_TcME5noo8F"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_3Cx9Dxj6ct_TcME5noo8F"
          }
          addSpacing={false}
        >
          {props.children}
        </ContainerPrimitive>,
        <SpacerPrimitive
          id={"Spacer_K1SbGVGi1w"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_3Cx9Dxj6ct_K1SbGVGi1w"
          }
          key={"K1SbGVGi1w"}
        />,
        props.Subtotal === undefined ? (
          <Subtotal
            key={"NJsTKT7TAw"}
            {...getCompositeDefaultProps("NJsTKT7TAw")}
            id="Subtotal_NJsTKT7TAw"
            className="__visly_reset_7X7HTLRqyD __visly_scope_3Cx9Dxj6ct_NJsTKT7TAw"
          />
        ) : (
          <SubtotalContext.Provider
            key="NJsTKT7TAw-provider"
            value={{
              key: "NJsTKT7TAw",
              id: "Subtotal_NJsTKT7TAw",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_3Cx9Dxj6ct_NJsTKT7TAw",
              ...getCompositeDefaultProps("NJsTKT7TAw"),
            }}
          >
            {props.Subtotal}
          </SubtotalContext.Provider>
        ),
        <ContainerPrimitive
          key={"EUYUpSyfbu"}
          id={"Slot_EUYUpSyfbu"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_3Cx9Dxj6ct_EUYUpSyfbu"
          }
          addSpacing={false}
        >
          {props.fees}
        </ContainerPrimitive>,
        props.Tax === undefined ? (
          <Tax
            key={"VTUjSKwdu5"}
            {...getCompositeDefaultProps("VTUjSKwdu5")}
            id="Tax_VTUjSKwdu5"
            className="__visly_reset_7X7HTLRqyD __visly_scope_3Cx9Dxj6ct_VTUjSKwdu5"
          />
        ) : (
          <TaxContext.Provider
            key="VTUjSKwdu5-provider"
            value={{
              key: "VTUjSKwdu5",
              id: "Tax_VTUjSKwdu5",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_3Cx9Dxj6ct_VTUjSKwdu5",
              ...getCompositeDefaultProps("VTUjSKwdu5"),
            }}
          >
            {props.Tax}
          </TaxContext.Provider>
        ),
        props.Refund === undefined ? (
          <Refund
            key={"8ZPywAoAzP"}
            {...getCompositeDefaultProps("8ZPywAoAzP")}
            id="Refund_8ZPywAoAzP"
            className="__visly_reset_7X7HTLRqyD __visly_scope_3Cx9Dxj6ct_8ZPywAoAzP"
          />
        ) : (
          <RefundContext.Provider
            key="8ZPywAoAzP-provider"
            value={{
              key: "8ZPywAoAzP",
              id: "Refund_8ZPywAoAzP",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_3Cx9Dxj6ct_8ZPywAoAzP",
              ...getCompositeDefaultProps("8ZPywAoAzP"),
            }}
          >
            {props.Refund}
          </RefundContext.Provider>
        ),
        props.Total === undefined ? (
          <Total
            key={"RoaNMaZmZ2"}
            {...getCompositeDefaultProps("RoaNMaZmZ2")}
            id="Total_RoaNMaZmZ2"
            className="__visly_reset_7X7HTLRqyD __visly_scope_3Cx9Dxj6ct_RoaNMaZmZ2"
          />
        ) : (
          <TotalContext.Provider
            key="RoaNMaZmZ2-provider"
            value={{
              key: "RoaNMaZmZ2",
              id: "Total_RoaNMaZmZ2",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_3Cx9Dxj6ct_RoaNMaZmZ2",
              ...getCompositeDefaultProps("RoaNMaZmZ2"),
            }}
          >
            {props.Total}
          </TotalContext.Provider>
        ),
      ]}
    </RootPrimitive>
  );
}

OrderSummary.ReceiptLineItem = ReceiptLineItem;
OrderSummary.Subtotal = Subtotal;
OrderSummary.Tax = Tax;
OrderSummary.Fee = Fee;
OrderSummary.Total = Total;
OrderSummary.Refund = Refund;
OrderSummary.__variants = [];

export default OrderSummary;
