/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { textstyles } from "../../visly";
import "./css/ProcessingPayment.css";

const headers = [
  "Confirming your order",
  "Firing up the burners",
  "Warming up your plate",
  "Telling the line cooks to come back from their smoke break",
];

const ProcessingPayment = () => {
  const [header, setHeader] = useState("Processing your payment");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIndex((index + 1) % headers.length);
    }, 3500);
    return () => {
      clearTimeout(timeout);
    };
  }, [index]);

  useEffect(() => {
    setHeader(headers[index]);
  }, [index]);

  return (
    <div className="processingPaymentWrapper">
      <div className="paymentStatusWrapper">
        <span
          style={{
            ...textstyles.h3,
            lineHeight: "28px",
            textAlign: "center",
          }}
        >
          {header}
        </span>
      </div>
      <div className="animationWrapper">
        <div className="boxes">
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <span
        style={{
          ...textstyles.body,
          textAlign: "center",
          color: "#727272",
          marginTop: "32px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <p style={{ marginLeft: 8 }}>Do not refresh the page, confirming your payment</p>
      </span>
    </div>
  );
};

export default ProcessingPayment;
