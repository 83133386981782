// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./SettingsHeader.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";
import { default as EventLink, EventLinkContext } from "../Events/EventLink";
import {
  default as CopyLinkButton,
  ButtonContext as CopyLinkButtonContext,
  default as QrCodeButton,
  ButtonContext as QrCodeButtonContext,
} from "../Primitives/Button";

const styles = [
  {
    type: "default",
    layers: {
      HuLr2TMPm2: {
        none: {
          text: "Restaurant Name",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      "6qPc7ziRQf": {},
      J98cGBkE8C: {
        none: {
          text: "Copy Event Link",
          icon: icons.copy,
          rounded: true,
          withIcon: "left",
        },
      },
      HsWrMT8Jbg: {
        none: {
          text: "Event QR Code",
          icon: icons.qr,
          rounded: true,
          withIcon: "left",
        },
      },
    },
  },
];

const variantPropTypes = [];

export const SettingsHeaderContext = createContext(null);

function SettingsHeader(_props) {
  const defaults = useContext(SettingsHeaderContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="Nrn6TT4hfG"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "Nrn6TT4hfG",
        scope: "KieMVm9XFc",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => [
        <TextPrimitive
          id={"restaurantName_HuLr2TMPm2"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_KieMVm9XFc_HuLr2TMPm2"
          }
          key={"HuLr2TMPm2"}
          text={
            exists(props.restaurantName)
              ? props.restaurantName
              : getStyle("HuLr2TMPm2", "text")
          }
          element={getStyle("HuLr2TMPm2", "htmlElement")}
        />,
        <SpacerPrimitive
          id={"Spacer_KrmW6e3jGL"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_KieMVm9XFc_KrmW6e3jGL"
          }
          key={"KrmW6e3jGL"}
        />,
        props.EventLink === undefined ? (
          <EventLink
            key={"6qPc7ziRQf"}
            {...getCompositeDefaultProps("6qPc7ziRQf")}
            id="EventLink_6qPc7ziRQf"
            className="__visly_reset_7X7HTLRqyD __visly_scope_KieMVm9XFc_6qPc7ziRQf"
          />
        ) : (
          <EventLinkContext.Provider
            key="6qPc7ziRQf-provider"
            value={{
              key: "6qPc7ziRQf",
              id: "EventLink_6qPc7ziRQf",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_KieMVm9XFc_6qPc7ziRQf",
              ...getCompositeDefaultProps("6qPc7ziRQf"),
            }}
          >
            {props.EventLink}
          </EventLinkContext.Provider>
        ),
        <SpacerPrimitive
          id={"Spacer_HMMmKLjJCb"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_KieMVm9XFc_HMMmKLjJCb"
          }
          key={"HMMmKLjJCb"}
        />,
        <ContainerPrimitive
          id={"Container_LrDgDZT8R5"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_KieMVm9XFc_LrDgDZT8R5"
          }
          key={"LrDgDZT8R5"}
          addSpacing={false}
          element={getStyle("LrDgDZT8R5", "htmlElement")}
        >
          {[
            props.CopyLinkButton === undefined ? (
              <CopyLinkButton
                key={"J98cGBkE8C"}
                {...getCompositeDefaultProps("J98cGBkE8C")}
                id="CopyLinkButton_J98cGBkE8C"
                className="__visly_reset_7X7HTLRqyD __visly_scope_KieMVm9XFc_J98cGBkE8C"
              />
            ) : (
              <CopyLinkButtonContext.Provider
                key="J98cGBkE8C-provider"
                value={{
                  key: "J98cGBkE8C",
                  id: "CopyLinkButton_J98cGBkE8C",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_KieMVm9XFc_J98cGBkE8C",
                  ...getCompositeDefaultProps("J98cGBkE8C"),
                }}
              >
                {props.CopyLinkButton}
              </CopyLinkButtonContext.Provider>
            ),
            <SpacerPrimitive
              id={"Spacer_EQWPbJYifd"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_KieMVm9XFc_EQWPbJYifd"
              }
              key={"EQWPbJYifd"}
            />,
            props.QrCodeButton === undefined ? (
              <QrCodeButton
                key={"HsWrMT8Jbg"}
                {...getCompositeDefaultProps("HsWrMT8Jbg")}
                id="QrCodeButton_HsWrMT8Jbg"
                className="__visly_reset_7X7HTLRqyD __visly_scope_KieMVm9XFc_HsWrMT8Jbg"
              />
            ) : (
              <QrCodeButtonContext.Provider
                key="HsWrMT8Jbg-provider"
                value={{
                  key: "HsWrMT8Jbg",
                  id: "QrCodeButton_HsWrMT8Jbg",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_KieMVm9XFc_HsWrMT8Jbg",
                  ...getCompositeDefaultProps("HsWrMT8Jbg"),
                }}
              >
                {props.QrCodeButton}
              </QrCodeButtonContext.Provider>
            ),
          ]}
        </ContainerPrimitive>,
      ]}
    </RootPrimitive>
  );
}

SettingsHeader.EventLink = EventLink;
SettingsHeader.CopyLinkButton = CopyLinkButton;
SettingsHeader.QrCodeButton = QrCodeButton;
SettingsHeader.__variants = [];

export default SettingsHeader;
