// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./CustomerMenuItem.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../../_internal_primitives";
import {
  default as ViewAvailableTimesButton,
  ButtonContext as ViewAvailableTimesButtonContext,
} from "../../Primitives/Button";
import { default as NextAvailable } from "./CustomerMenuItem.NextAvailable";
import { default as ChangeQuantity } from "./CustomerMenuItem.ChangeQuantity";
import { default as AvailableTimesContainer } from "./CustomerMenuItem.AvailableTimesContainer";
import { default as Description } from "./CustomerMenuItem.Description";

const styles = [
  {
    type: "default",
    layers: {
      "6bYghBZiEq": {
        none: {
          text: "Menu Item Title",
        },
      },
      T4D6p7fsqM: {
        none: {
          text: "2 Left in Stock",
        },
      },
      MLjPjbp3DL: {
        none: {
          htmlElement: "main",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "showNextAvailable",
    layers: {},
  },
  {
    type: "boolean",
    propName: "hideQuantityRemaining",
    layers: {},
  },
  {
    type: "boolean",
    propName: "noPhoto",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      "9JzdT3BAMJ": {
        none: {
          text: "View all available times",
          rounded: true,
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "showNextAvailable",
    layers: {
      "9JzdT3BAMJ": {},
    },
  },
  {
    type: "boolean",
    propName: "hideQuantityRemaining",
    layers: {
      "9JzdT3BAMJ": {},
    },
  },
  {
    type: "boolean",
    propName: "noPhoto",
    layers: {
      "9JzdT3BAMJ": {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "showNextAvailable",
  },
  {
    type: "boolean",
    propName: "hideQuantityRemaining",
  },
  {
    type: "boolean",
    propName: "noPhoto",
  },
];

export const CustomerMenuItemContext = createContext(null);

function CustomerMenuItem(_props) {
  const defaults = useContext(CustomerMenuItemContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="krYZXKVbHW"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "krYZXKVbHW",
        scope: "27vMJWiDv8",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"image-container_MLjPjbp3DL"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_27vMJWiDv8_MLjPjbp3DL"
            }
            key={"MLjPjbp3DL"}
            addSpacing={false}
            element={getStyle("MLjPjbp3DL", "htmlElement")}
          >
            {
              <ContainerPrimitive
                key={"FG86toi9Va"}
                id={"Slot_FG86toi9Va"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_27vMJWiDv8_FG86toi9Va"
                }
                addSpacing={false}
              >
                {props.image}
              </ContainerPrimitive>
            }
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"item-content-container_C9oFKUH6Dk"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_27vMJWiDv8_C9oFKUH6Dk"
            }
            key={"C9oFKUH6Dk"}
            addSpacing={false}
            element={getStyle("C9oFKUH6Dk", "htmlElement")}
          >
            {[
              <SpacerPrimitive
                id={"Spacer_M4NUUxVMb9"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_27vMJWiDv8_M4NUUxVMb9"
                }
                key={"M4NUUxVMb9"}
              />,
              <TextPrimitive
                id={"title_6bYghBZiEq"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_27vMJWiDv8_6bYghBZiEq"
                }
                key={"6bYghBZiEq"}
                text={
                  exists(props.title)
                    ? props.title
                    : getStyle("6bYghBZiEq", "text")
                }
                element={getStyle("6bYghBZiEq", "htmlElement")}
              />,
              <SpacerPrimitive
                id={"Spacer_Hr9ifDgNh6"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_27vMJWiDv8_Hr9ifDgNh6"
                }
                key={"Hr9ifDgNh6"}
              />,
              <TextPrimitive
                id={"quantityAvailable_T4D6p7fsqM"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_27vMJWiDv8_T4D6p7fsqM"
                }
                key={"T4D6p7fsqM"}
                text={
                  exists(props.quantityAvailable)
                    ? props.quantityAvailable
                    : getStyle("T4D6p7fsqM", "text")
                }
                element={getStyle("T4D6p7fsqM", "htmlElement")}
              />,
              <SpacerPrimitive
                id={"Spacer_5RaaSXYD3Y"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_27vMJWiDv8_5RaaSXYD3Y"
                }
                key={"5RaaSXYD3Y"}
              />,
              props.Description === undefined ? (
                <Description key={"YEd8Ngtm7F"} />
              ) : (
                props.Description
              ),
              <ContainerPrimitive
                id={"nextavailable-container_QQFzPp7bhr"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_27vMJWiDv8_QQFzPp7bhr"
                }
                key={"QQFzPp7bhr"}
                addSpacing={false}
                element={getStyle("QQFzPp7bhr", "htmlElement")}
              >
                {[
                  props.NextAvailable === undefined ? (
                    <NextAvailable key={"Dc7vYAmXEC"} />
                  ) : (
                    props.NextAvailable
                  ),
                  <SpacerPrimitive
                    id={"nextAvailableSpacerTop_CXVBgN4YFb"}
                    className={
                      "__visly_reset_7X7HTLRqyD __visly_scope_27vMJWiDv8_CXVBgN4YFb"
                    }
                    key={"CXVBgN4YFb"}
                  />,
                  props.ViewAvailableTimesButton === undefined ? (
                    <ViewAvailableTimesButton
                      key={"9JzdT3BAMJ"}
                      {...getCompositeDefaultProps("9JzdT3BAMJ")}
                      id="ViewAvailableTimesButton_9JzdT3BAMJ"
                      className="__visly_reset_7X7HTLRqyD __visly_scope_27vMJWiDv8_9JzdT3BAMJ"
                    />
                  ) : (
                    <ViewAvailableTimesButtonContext.Provider
                      key="9JzdT3BAMJ-provider"
                      value={{
                        key: "9JzdT3BAMJ",
                        id: "ViewAvailableTimesButton_9JzdT3BAMJ",
                        className:
                          "__visly_reset_7X7HTLRqyD __visly_scope_27vMJWiDv8_9JzdT3BAMJ",
                        ...getCompositeDefaultProps("9JzdT3BAMJ"),
                      }}
                    >
                      {props.ViewAvailableTimesButton}
                    </ViewAvailableTimesButtonContext.Provider>
                  ),
                  <SpacerPrimitive
                    id={"nextAvailableSpacerBot_GNaGQBnvK2"}
                    className={
                      "__visly_reset_7X7HTLRqyD __visly_scope_27vMJWiDv8_GNaGQBnvK2"
                    }
                    key={"GNaGQBnvK2"}
                  />,
                ]}
              </ContainerPrimitive>,
              <ContainerPrimitive
                id={"var-mod-container_4XYjhdqvxv"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_27vMJWiDv8_4XYjhdqvxv"
                }
                key={"4XYjhdqvxv"}
                addSpacing={false}
                element={getStyle("4XYjhdqvxv", "htmlElement")}
              >
                {
                  <ContainerPrimitive
                    key={"TQ3L1ea3r8"}
                    id={"Slot_TQ3L1ea3r8"}
                    className={
                      "__visly_reset_7X7HTLRqyD __visly_scope_27vMJWiDv8_TQ3L1ea3r8"
                    }
                    addSpacing={false}
                  >
                    {props.children}
                  </ContainerPrimitive>
                }
              </ContainerPrimitive>,
              props.ChangeQuantity === undefined ? (
                <ChangeQuantity key={"6VDnpiUo2J"} />
              ) : (
                props.ChangeQuantity
              ),
            ]}
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"availability-container_Vsi3BZmGi3"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_27vMJWiDv8_Vsi3BZmGi3"
            }
            key={"Vsi3BZmGi3"}
            addSpacing={false}
            element={getStyle("Vsi3BZmGi3", "htmlElement")}
          >
            {props.AvailableTimesContainer === undefined ? (
              <AvailableTimesContainer key={"PpLUQ7whSV"} />
            ) : (
              props.AvailableTimesContainer
            )}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

CustomerMenuItem.ViewAvailableTimesButton = ViewAvailableTimesButton;
CustomerMenuItem.__variants = [
  {
    name: "showNextAvailable",
    type: "variant",
  },
  {
    name: "hideQuantityRemaining",
    type: "variant",
  },
  {
    name: "noPhoto",
    type: "variant",
  },
];

export default CustomerMenuItem;
