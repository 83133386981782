// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Field.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      FhWruCpBbo: {
        none: {
          text: "No Date",
          htmlElement: "span",
        },
      },
      "7UXYUfX7AM": {
        none: {
          text: " ",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "containsSubtitle",
    layers: {},
  },
  {
    type: "boolean",
    propName: "small",
    layers: {},
  },
  {
    type: "boolean",
    propName: "dark",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "containsSubtitle",
    layers: {},
  },
  {
    type: "boolean",
    propName: "small",
    layers: {},
  },
  {
    type: "boolean",
    propName: "dark",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "containsSubtitle",
  },
  {
    type: "boolean",
    propName: "small",
  },
  {
    type: "boolean",
    propName: "dark",
  },
];

export const FieldContext = createContext(null);

function Field(_props) {
  const defaults = useContext(FieldContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="Xz3YmxExp2"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "Xz3YmxExp2",
        scope: "GnQfuCEPqK",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            key={"SWKKH8SWGd"}
            id={"Slot_SWKKH8SWGd"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_GnQfuCEPqK_SWKKH8SWGd"
            }
            addSpacing={false}
          >
            {props.FieldIcon}
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_Lo8xbomWL6"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_GnQfuCEPqK_Lo8xbomWL6"
            }
            key={"Lo8xbomWL6"}
          />
          <ContainerPrimitive
            id={"Container_81EEvUpjss"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_GnQfuCEPqK_81EEvUpjss"
            }
            key={"81EEvUpjss"}
            addSpacing={false}
            element={getStyle("81EEvUpjss", "htmlElement")}
          >
            <SpacerPrimitive
              id={"Spacer_BxND6o5f1C"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_GnQfuCEPqK_BxND6o5f1C"
              }
              key={"BxND6o5f1C"}
            />
            <TextPrimitive
              id={"title_FhWruCpBbo"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_GnQfuCEPqK_FhWruCpBbo"
              }
              key={"FhWruCpBbo"}
              text={
                exists(props.title)
                  ? props.title
                  : getStyle("FhWruCpBbo", "text")
              }
              element={getStyle("FhWruCpBbo", "htmlElement")}
            />
            <SpacerPrimitive
              id={"Spacer_8FRwu8t7cj"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_GnQfuCEPqK_8FRwu8t7cj"
              }
              key={"8FRwu8t7cj"}
            />
            <TextPrimitive
              id={"subtitle_7UXYUfX7AM"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_GnQfuCEPqK_7UXYUfX7AM"
              }
              key={"7UXYUfX7AM"}
              text={
                exists(props.subtitle)
                  ? props.subtitle
                  : getStyle("7UXYUfX7AM", "text")
              }
              element={getStyle("7UXYUfX7AM", "htmlElement")}
            />
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

Field.__variants = [
  {
    name: "containsSubtitle",
    type: "variant",
  },
  {
    name: "small",
    type: "variant",
  },
  {
    name: "dark",
    type: "variant",
  },
];

export default Field;
