/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderTaxes,
  toFixed,
  getMenuItemOptionsString,
  getOrderTotal,
  getOrderRefundTotal,
  getOrderHotplateFees,
  getCustomerPaymentProcessingFee,
  getCartItemUnitPrice,
  priceInputIsValid,
} from "@hotplate/utils-ts/helperFunctions";
import ReactLoading from "react-loading";
import { initiateCustomerRefund } from "../../hotplate-storefront/actions";
import {
  Refund,
  Summary,
  SummaryLineItem,
  RefundLineItem,
  FeeLineItem,
  RestockItems,
  // KillReminders,
  RefundQuantityInput,
} from "../../visly/Refund";
import NewModal from "../../hotplate-common/NewModal";
import { trackRefundIssued } from "../orderManagement/analytics";
import { usePortalUser } from "../../auth";
import { usePrevious } from "../../hooks";
import { useBreakpoint } from "../../visly";

export default function RefundMaster({ order: orderProp, onRequestClose, isOpen }) {
  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);
  const { initiateCustomerRefundLoading, initiateCustomerRefundError } = useSelector(
    (state) => state.orderManagement
  );
  const dispatch = useDispatch();

  const { chefId: hostId } = usePortalUser();

  const [order, setOrder] = useState(orderProp);
  const [feeRefundInputs, setFeeRefundInputs] = useState({});
  const [restockItems, setRestockItems] = useState(false);
  const [shouldKillFutureReminders, setShouldKillFutureReminders] = useState(false);
  const [totalRefundInput, setTotalRefundInput] = useState("");

  const originalOrder = orderProp;
  const maxPossibleRefund = toFixed(
    parseFloat(getOrderTotal(originalOrder)) - parseFloat(getOrderRefundTotal(originalOrder)),
    2
  );

  const totalRefund = getTotalRefund(order);

  // Close the modal when the refund is successful
  const prevInitiateCustomerRefundLoading = usePrevious(initiateCustomerRefundLoading);
  useEffect(() => {
    if (
      prevInitiateCustomerRefundLoading &&
      !initiateCustomerRefundLoading &&
      initiateCustomerRefundError === ""
    ) {
      onRequestClose();
    }
  });

  function getTotalRefund(order) {
    const originalRefundTotal = !originalOrder.refundTotal
      ? 0
      : parseFloat(originalOrder.refundTotal);
    const currRefundTotal = !order.refundTotal ? 0 : parseFloat(order.refundTotal);
    return toFixed(currRefundTotal - originalRefundTotal, 2);
  }

  function setTotalRefundInputWrapped(val) {
    if (!priceInputIsValid(val)) return;
    setTotalRefundInput(val);
  }

  function setRefundTotal(order, val) {
    if (!priceInputIsValid(val)) return;
    const originalOrder = orderProp;
    const newOrder = _.cloneDeep(order);

    let hotplateFeeRefund = 0;
    if (val === "") {
      if (originalOrder.refundTotal) {
        newOrder.refundTotal = originalOrder.refundTotal;
      } else {
        delete newOrder.refundTotal;
      }
    } else {
      const originalRefundTotal = !originalOrder.refundTotal
        ? 0
        : parseFloat(originalOrder.refundTotal);
      const currRefundTotal = parseFloat(val);
      newOrder.refundTotal = toFixed(
        Math.min(currRefundTotal, parseFloat(maxPossibleRefund)) + originalRefundTotal,
        2
      );
      const hotplateFee = getOrderHotplateFees(order);
      hotplateFeeRefund = Math.max(
        0,
        parseFloat(newOrder.refundTotal) - (originalOrder.amount / 100 - parseFloat(hotplateFee))
      );
    }
    setOrder(newOrder);
    setTotalRefundInput(getTotalRefund(newOrder));

    setFeeRefund(newOrder, "hotplateFee", hotplateFeeRefund, false);
  }

  function getCartItemQuantityAvaialbleToRefund(itemIndex) {
    const originalCartItem = orderProp.cartItems[itemIndex];
    const originalRefundQuantity = Number.isInteger(originalCartItem.refundQuantity)
      ? originalCartItem.refundQuantity
      : 0;
    return originalCartItem.quantity - originalRefundQuantity;
  }

  function getCartItemRefundSubtotal(order) {
    let sum = 0;
    for (let i = 0; i < order.cartItems.length; i++) {
      sum +=
        getCurrentCartItemRefundQuantity(order, i) *
        parseFloat(getCartItemUnitPrice(order.cartItems[i]));
    }
    return toFixed(sum, 2);
  }

  function updateTaxFeeRefund(order) {
    let taxableAmount = parseFloat(getCartItemRefundSubtotal(order));
    if ("deliveryFeeRefund" in order) {
      taxableAmount += parseFloat(order.deliveryFeeRefund);
    }
    setFeeRefund(order, "tax", taxableAmount * parseFloat(order.siteSettings.taxRate));
  }

  function setCartItemRefundQuantity(itemIndex, refundQuantity) {
    if (!/^[0-9]+$/.test(refundQuantity) && refundQuantity !== "") return;
    if (parseInt(refundQuantity) < 0) return;
    const originalCartItem = orderProp.cartItems[itemIndex];
    const newOrder = _.cloneDeep(order);
    if (refundQuantity === "") {
      if (originalCartItem.refundQuantity) {
        newOrder.cartItems[itemIndex].refundQuantity = originalCartItem.refundQuantity;
      } else {
        delete newOrder.cartItems[itemIndex].refundQuantity;
      }
    } else {
      let originalRefundQuantity = 0;
      if (Number.isInteger(originalCartItem.refundQuantity))
        originalRefundQuantity = originalCartItem.refundQuantity;
      refundQuantity = Math.min(
        originalCartItem.quantity - originalRefundQuantity,
        parseInt(refundQuantity)
      );
      newOrder.cartItems[itemIndex].refundQuantity = originalRefundQuantity + refundQuantity;
    }
    setOrder(newOrder);
    updateTaxFeeRefund(newOrder);
    // TODO: ADD THIS setFeeRefund(newOrder, 'customerPaymentProcessingFee', );
  }

  function getCurrentCartItemRefundQuantity(order, itemIndex) {
    const originalCartItem = orderProp.cartItems[itemIndex];
    const currentCartItem = order.cartItems[itemIndex];
    const originalRefundQuantity = Number.isInteger(originalCartItem.refundQuantity)
      ? originalCartItem.refundQuantity
      : 0;
    const currentRefundQuantity = Number.isInteger(currentCartItem.refundQuantity)
      ? currentCartItem.refundQuantity
      : 0;
    return currentRefundQuantity - originalRefundQuantity;
  }

  function setFeeInput(feeKey, amount) {
    if (!priceInputIsValid(amount)) return;
    setFeeRefundInputs((feeRefundInputs) => {
      return {
        ...feeRefundInputs,
        [feeKey]: amount,
      };
    });
  }

  function setFeeRefund(order, feeKey, amount, recalculateRefundTotal = true) {
    if (!(feeKey in order) && feeKey !== "tax" && feeKey !== "hotplateFee") return;
    const newOrder = _.cloneDeep(order);
    const feeRefundKey = feeKey + "Refund";

    const originalFeeRefund =
      feeRefundKey in originalOrder ? parseFloat(originalOrder[feeRefundKey]) : 0;

    if (amount === "") {
      if (feeRefundKey in originalOrder) {
        newOrder[feeRefundKey] = originalOrder[feeRefundKey];
      } else {
        delete newOrder[feeRefundKey];
      }
    } else {
      let originalFee = "0.00";
      if (feeKey === "tax") originalFee = getOrderTaxes(order);
      else if (feeKey === "hotplateFee") originalFee = getOrderHotplateFees(order);
      else originalFee = originalOrder[feeKey];

      amount = Math.min(parseFloat(originalFee) - originalFeeRefund, parseFloat(amount));
      newOrder[feeRefundKey] = toFixed(originalFeeRefund + amount, 2);
    }
    setOrder(newOrder);
    setFeeInput(feeKey, toFixed(amount, 2));
    if (recalculateRefundTotal) setRefundTotal(newOrder, getSummaryRefundTotal(newOrder));
    if (feeKey === "deliveryFee") updateTaxFeeRefund(newOrder);
  }

  function getRefundableFees() {
    const fees = [];
    if (orderProp.cartType === "delivery") {
      let availableRefund = parseFloat(orderProp.deliveryFee);
      if (orderProp.deliveryFeeRefund) availableRefund -= parseFloat(orderProp.deliveryFeeRefund);
      fees.push({
        key: "deliveryFee",
        title: "Delivery Fee",
        availableRefund: toFixed(availableRefund, 2),
        editable: true,
      });
    }
    if (orderProp.siteSettings.chargeCustomerPaymentProcessing) {
      let availableRefund = parseFloat(getCustomerPaymentProcessingFee(orderProp));
      if (orderProp.customerPaymentProcessingFeeRefund)
        availableRefund -= parseFloat(orderProp.customerPaymentProcessingFeeRefund);
      fees.push({
        key: "customerPaymentProcessingFee",
        title: "Customer Payment Processing Fee",
        availableRefund: toFixed(availableRefund, 2),
        editable: true,
      });
    }

    let availableRefund = parseFloat(orderProp.tip);
    if (orderProp.tipRefund) availableRefund -= parseFloat(orderProp.tipRefund);
    fees.push({
      key: "tip",
      title: "Tip",
      availableRefund: toFixed(availableRefund, 2),
      editable: true,
    });

    availableRefund = parseFloat(getOrderTaxes(orderProp));
    if (orderProp.taxRefund) availableRefund -= parseFloat(orderProp.taxRefund);
    fees.push({
      key: "tax",
      title: "Tax",
      availableRefund: toFixed(availableRefund, 2),
      editable: false,
    });

    if (parseFloat(getOrderHotplateFees(orderProp)) > 0) {
      let availableRefund = parseFloat(getOrderHotplateFees(orderProp));
      if (orderProp.hotplateFeeRefund) availableRefund -= parseFloat(orderProp.hotplateFeeRefund);
      fees.push({
        key: "hotplateFee",
        title: "Hotplate Fee",
        availableRefund: toFixed(availableRefund, 2),
        editable: false,
      });
    }
    return fees;
  }

  function getSummaryRefundTotal(order) {
    let refundTotal = 0;
    for (let i = 0; i < order.cartItems.length; i++) {
      refundTotal +=
        getCurrentCartItemRefundQuantity(order, i) *
        parseFloat(getCartItemUnitPrice(order.cartItems[i]));
    }
    getRefundableFees().map((fee) => {
      const refundKey = fee.key + "Refund";
      if (refundKey in order) refundTotal += parseFloat(order[refundKey]);
      if (refundKey in originalOrder) refundTotal -= parseFloat(originalOrder[refundKey]);
    });

    return toFixed(refundTotal, 2);
  }

  const numItemsToRefund = originalOrder.cartItems
    .map((_, index) => {
      return getCurrentCartItemRefundQuantity(order, index);
    })
    .reduce((sum, quantity) => {
      return sum + quantity;
    }, 0);

  return (
    <NewModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      fullscreen
      headerless
      title="Issue a Refund"
    >
      <Refund
        size={size}
        style={{
          marginTop: "75px",
          backgroundColor: "transparent",
          paddingInline: "0px",
          maxWidth: "616px",
        }}
        orderNum={"#" + originalOrder.orderId}
        customerName={originalOrder.fullName}
        RestockItems={
          <>
            <RestockItems
              text={"Restock " + numItemsToRefund + " item" + (numItemsToRefund !== 1 ? "s" : "")}
              disabled={numItemsToRefund === 0}
              onClick={() => setRestockItems(!restockItems)}
              Checkbox={
                <RestockItems.Checkbox checked={restockItems} disabled={numItemsToRefund === 0} />
              }
            />
            <RestockItems
              text={"Hide this order from the prep and packing views"}
              onClick={() => setOrder({ ...order, isHidden: !order.isHidden })}
              Checkbox={<RestockItems.Checkbox checked={order.isHidden} />}
            />
          </>
        }
        summary={
          <Summary
            maxPossibleRefund={"$" + maxPossibleRefund}
            warningBubble={
              parseFloat(getSummaryRefundTotal(order)) !== parseFloat(totalRefund) &&
              parseFloat(getSummaryRefundTotal(order)) -
                parseFloat(order.hotplateFeeRefund ? order.hotplateFeeRefund : 0) >
                0 && <Summary.WarningBubble />
            }
            RefundTotal={<Summary.RefundTotal total={"$" + getSummaryRefundTotal(order)} />}
            RefundInput={
              <Summary.RefundInput
                onChange={(val) => setTotalRefundInputWrapped(val)}
                inputProps={{ autoComplete: "new-password" }}
                onBlur={() => setRefundTotal(order, totalRefundInput)}
                value={totalRefundInput}
              />
            }
            ConfirmRefundButton={
              <Summary.ConfirmRefundButton
                onClick={() => {
                  initiateCustomerRefund(
                    hostId,
                    order,
                    restockItems,
                    shouldKillFutureReminders
                  )(dispatch);
                  trackRefundIssued({
                    orderId: order.paymentIntentId,
                    refundAmount: totalRefund,
                    areItemsRestocked: restockItems,
                    areRemindersKilled: shouldKillFutureReminders,
                  });
                }}
                loading={initiateCustomerRefundLoading}
                loadingAnimation={<ReactLoading type={"bubbles"} height={50} width={50} />}
                text={`Refund $${totalRefund}`}
                style={{ height: 50 }}
              />
            }
            noItemsSelected={
              numItemsToRefund === 0 &&
              getRefundableFees().filter((fee) => {
                return fee.key + "Refund" in order;
              }).length === 0
            }
            KillReminders={
              <Summary.KillReminders
                onClick={() => setShouldKillFutureReminders(!shouldKillFutureReminders)}
                Checkbox={<Summary.KillReminders.Checkbox checked={shouldKillFutureReminders} />}
              />
            }
          >
            <>
              {numItemsToRefund > 0 && (
                <SummaryLineItem
                  title="Items subtotal"
                  subtitle={"(" + numItemsToRefund + ` item${numItemsToRefund > 1 ? "s" : ""})`}
                  total={"$" + getCartItemRefundSubtotal(order)}
                />
              )}
              {getSummaryRefundTotal(order) &&
                getRefundableFees().map((fee) => {
                  if (fee.key in feeRefundInputs && feeRefundInputs[fee.key]) {
                    return (
                      <SummaryLineItem
                        key={fee.key}
                        title={fee.title}
                        total={"$" + feeRefundInputs[fee.key]}
                      />
                    );
                  }
                })}
            </>
          </Summary>
        }
        fees={getRefundableFees()
          .filter((fee) => {
            return fee.editable;
          })
          .map((fee) => (
            <FeeLineItem
              key={fee.key}
              title={fee.title}
              total={"($" + fee.availableRefund + ")"}
              RefundAmountInput={
                <FeeLineItem.RefundAmountInput
                  disabled={!fee.editable}
                  onChange={(val) => setFeeInput(fee.key, val)}
                  inputProps={{ autoComplete: "new-password" }}
                  onBlur={() =>
                    setFeeRefund(
                      order,
                      fee.key,
                      fee.key in feeRefundInputs ? feeRefundInputs[fee.key] : ""
                    )
                  }
                  value={fee.key in feeRefundInputs ? feeRefundInputs[fee.key] : ""}
                />
              }
              MaxRefund={
                fee.editable && (
                  <FeeLineItem.MaxRefund
                    disabled={
                      (fee.key in feeRefundInputs ? feeRefundInputs[fee.key] : "") ===
                      fee.availableRefund
                    }
                    onClick={() => setFeeRefund(order, fee.key, fee.availableRefund)}
                  />
                )
              }
            />
          ))}
      >
        {order.cartItems
          .filter((cartItem) => !cartItem.isSynthetic)
          .map((cartItem, index) => (
            <RefundLineItem
              size={size}
              key={index}
              title={
                cartItem.title +
                (Number.isInteger(originalOrder.cartItems[index].refundQuantity) &&
                originalOrder.cartItems[index].refundQuantity > 0
                  ? ` (${originalOrder.cartItems[index].refundQuantity} refunded)`
                  : "")
              }
              options={getMenuItemOptionsString(cartItem)}
              price={"$" + getCartItemUnitPrice(cartItem)}
              refundAmount={
                "$" +
                toFixed(
                  parseFloat(getCartItemUnitPrice(cartItem)) *
                    getCurrentCartItemRefundQuantity(order, index),
                  2
                )
              }
              RefundInput={
                <RefundQuantityInput
                  onChange={(val) => setCartItemRefundQuantity(index, val)}
                  inputProps={{ autoComplete: "new-password" }}
                  value={
                    getCurrentCartItemRefundQuantity(order, index) === 0
                      ? ""
                      : getCurrentCartItemRefundQuantity(order, index).toString()
                  }
                  totalQuantity={getCartItemQuantityAvaialbleToRefund(index).toString()}
                  IncreaseQuantityButton={
                    <RefundQuantityInput.IncreaseQuantityButton
                      disabled={
                        getCurrentCartItemRefundQuantity(order, index) >=
                        getCartItemQuantityAvaialbleToRefund(index)
                      }
                      onClick={() =>
                        setCartItemRefundQuantity(
                          index,
                          getCurrentCartItemRefundQuantity(order, index) + 1
                        )
                      }
                    />
                  }
                  DecreaseQuantityButton={
                    <RefundQuantityInput.DecreaseQuantityButton
                      disabled={cartItem.refundQuantity <= 0}
                      onClick={() =>
                        setCartItemRefundQuantity(
                          index,
                          getCurrentCartItemRefundQuantity(order, index) - 1
                        )
                      }
                    />
                  }
                />
              }
            />
          ))}
      </Refund>
    </NewModal>
  );
}
