import React from "react";
import { styled, keyframes } from "../../stitches.config";
import { DotFilledIcon, CheckIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import AnimationContainer from "../AnimationContainer";
import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";

// #############################################################################
// Animations for the dropdown menu
// #############################################################################

const openUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(4px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const openRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-4px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const openDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-4px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const openLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(4px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const closeUpAndFade = keyframes({
  "0%": { opacity: 1, transform: "translateY(0)" },
  "100%": { opacity: 0, transform: "translateY(-4px)" },
});

const closeRightAndFade = keyframes({
  "0%": { opacity: 1, transform: "translateX(0)" },
  "100%": { opacity: 0, transform: "translateX(4px)" },
});

const closeDownAndFade = keyframes({
  "0%": { opacity: 1, transform: "translateY(0)" },
  "100%": { opacity: 0, transform: "translateY(4px)" },
});

const closeLeftAndFade = keyframes({
  "0%": { opacity: 1, transform: "translateX(0)" },
  "100%": { opacity: 0, transform: "translateX(-4px)" },
});

// #############################################################################
// Styled primitives for the dropdown menu
// #############################################################################

const contentStyles = {
  minWidth: 220,
  backgroundColor: "white",
  borderRadius: "$sm",
  padding: 5,
  zIndex: "$fixed",
  boxShadow:
    "0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)",
  hideOnPrint: true,
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "300ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    animationFillMode: "forwards",
    willChange: "transform, opacity",
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: openDownAndFade },
      '&[data-side="right"]': { animationName: openLeftAndFade },
      '&[data-side="bottom"]': { animationName: openUpAndFade },
      '&[data-side="left"]': { animationName: openRightAndFade },
    },
    '&[data-state="closed"]': {
      '&[data-side="top"]': { animationName: closeUpAndFade },
      '&[data-side="right"]': { animationName: closeRightAndFade },
      '&[data-side="bottom"]': { animationName: closeDownAndFade },
      '&[data-side="left"]': { animationName: closeLeftAndFade },
    },
  },
};

const StyledContent = styled(RadixDropdownMenu.Content, {
  ...contentStyles,
});

const StyledSubContent = styled(RadixDropdownMenu.SubContent, {
  ...contentStyles,
});

const itemStyles = {
  all: "unset",
  fontFamily: "$avenir",
  fontSize: 14,
  lh: "$reset",

  borderRadius: "$xs",
  display: "flex",
  alignItems: "center",
  height: 32,
  paddingInline: 6,
  position: "relative",
  paddingLeft: 25,
  userSelect: "none",
  cursor: "pointer",

  "&[data-disabled]": {
    color: "$gray8",
    cursor: "default",
    pointerEvents: "none",
  },

  variants: {
    variant: {
      warning: {
        color: "$warning10",
        fw: "$semi_bold",
        "&:hover, &[data-highlighted]": {
          backgroundColor: "$warning3",
          color: "$warning11",
        },
      },
      danger: {
        color: "$danger10",
        fw: "$semi_bold",
        "&:hover, &[data-highlighted]": {
          backgroundColor: "$danger3",
          color: "$danger11",
        },
      },
      default: {
        color: "$accent11",
        fw: "$normal",
        "&:hover, &[data-highlighted]": {
          backgroundColor: "$accent3",
          color: "$accent12",
        },
      },
    },
    removeIndent: {
      true: {
        paddingLeft: 15,
      },
    },
  },

  defaultVariants: {
    variant: "default",
  },
};

const StyledItem = styled(RadixDropdownMenu.Item, {
  ...itemStyles,
});
const StyledCheckboxItem = styled(RadixDropdownMenu.CheckboxItem, { ...itemStyles });
const StyledRadioItem = styled(RadixDropdownMenu.RadioItem, { ...itemStyles });
const StyledSubTrigger = styled(RadixDropdownMenu.SubTrigger, {
  '&[data-state="open"]': {
    backgroundColor: "$accent4",
    color: "$accent12",
  },
  ...itemStyles,
});

const StyledLabel = styled(RadixDropdownMenu.Label, {
  fontFamily: "$avenir",
  fs: "$xs",
  lh: "$btn",
  paddingLeft: 15,
  pady: "$xs",
  color: "$gray11",
});

const StyledSeparator = styled(RadixDropdownMenu.Separator, {
  height: 1,
  backgroundColor: "$accent6",
  margin: "$xxs",
});

const StyledItemIndicator = styled(RadixDropdownMenu.ItemIndicator, {
  position: "absolute",
  left: 0,
  width: 25,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledArrow = styled(RadixDropdownMenu.Arrow, {
  fill: "white",
});

// Hint prop for dropdown items
const HintSlot = styled("div", {
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
  paddingLeft: 20,
  paddingRight: 6,
  color: "$gray11",
  ":focus > &": { color: "inherit" },
  "[data-disabled] &": { color: "$gray8" },
});

const UnreadSlot = styled("div", {
  position: "absolute",
  left: 0,
  width: 25,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
});

const DropdownRoot = RadixDropdownMenu.Root;
const DropdownTrigger = styled(RadixDropdownMenu.Trigger, {
  height: "min-content",
  borderRadius: "$xs",
  "&:focus": {
    outline: "none",
  },
  "&:focus-within": {
    outline: "2px solid $info9",
    outlineOffset: 2,
  },
});

// #############################################################################
// Components for the dropdown menu
// #############################################################################

const DropdownLabel = ({ text, ...props }: any) => {
  return <StyledLabel {...props}>{text}</StyledLabel>;
};

// #### Dropdown Item types ####

// Standard item
const DropdownItem = React.forwardRef(
  ({ text, hint, unread, onClick, as: AsComponent, ...props }: any, ref) => {
    const inside = (
      <>
        {unread && (
          <UnreadSlot>
            <AnimationContainer animation="blob" height="20px" width="20px" />
          </UnreadSlot>
        )}
        {text}
        {hint && <HintSlot>{hint}</HintSlot>}
      </>
    );

    return (
      <StyledItem
        textValue={text}
        onSelect={onClick}
        aria-label={text}
        ref={ref}
        asChild={AsComponent !== undefined}
        {...props}
      >
        {AsComponent !== undefined ? <AsComponent>{inside}</AsComponent> : inside}
      </StyledItem>
    );
  }
);

DropdownItem.displayName = "DropdownItem";

// Checkable item
const DropdownCheckItem = ({ checked, onCheckedChange, onClick, text, hint, ...props }: any) => {
  return (
    <StyledCheckboxItem
      checked={checked}
      onCheckedChange={onCheckedChange}
      onSelect={onClick}
      textValue={text}
      aria-label={`Toggle ${text} ${checked ? "off" : "on"}`}
      {...props}
    >
      <StyledItemIndicator>
        <CheckIcon />
      </StyledItemIndicator>
      {text}
      {hint && <HintSlot>{hint}</HintSlot>}
    </StyledCheckboxItem>
  );
};

// Radio item, used with RadioGroup
const DropdownRadioItem = ({ value, text, hint, onClick, ...props }: any) => {
  return (
    <StyledRadioItem
      value={value}
      textValue={text}
      onSelect={onClick}
      aria-label={`Set group to ${text}`}
      {...props}
    >
      <StyledItemIndicator>
        <DotFilledIcon />
      </StyledItemIndicator>
      {text}
      {hint && <HintSlot>{hint}</HintSlot>}
    </StyledRadioItem>
  );
};

// #### Dropdown Item Group types ####

// Radio Group, used with RadioItem, allows for optional label and separators
const DropdownRadioGroup = ({
  value,
  onValueChange,
  label,
  separator,
  children,
  ...props
}: any) => {
  return (
    <>
      {separator.includes("top") && <StyledSeparator />}
      {label && <DropdownLabel text={label} />}
      <RadixDropdownMenu.RadioGroup
        value={value}
        onValueChange={onValueChange}
        aria-label={`Set value of ${label ? label : "radio"} option`}
        {...props}
      >
        {children}
      </RadixDropdownMenu.RadioGroup>
      {separator.includes("bottom") && <StyledSeparator />}
    </>
  );
};

// General Group, allows for optional label and separators

const DropdownGroup = ({ label, separator, children }: any) => {
  return (
    <>
      {separator.includes("top") && <StyledSeparator />}
      <RadixDropdownMenu.Group aria-label={`Grouping of ${label ? label : "menu"} items`}>
        {label && <DropdownLabel text={label} />}
        {children}
      </RadixDropdownMenu.Group>
      {separator.includes("bottom") && <StyledSeparator />}
    </>
  );
};

// #### Dropdown Menu types ####

// Item inside a dropdown that opens a submenu to the right or left, depending on collision
const DropdownSubMenu = ({
  triggerText,
  triggerHintText,
  open,
  onOpenChange,
  children,
  ...props
}: any) => {
  return (
    <DropdownRoot open={open} onOpenChange={onOpenChange} {...props}>
      <StyledSubTrigger textValue={triggerText}>
        <HintSlot>
          {triggerHintText}
          <ChevronRightIcon />
        </HintSlot>
      </StyledSubTrigger>
      <RadixDropdownMenu.Portal>
        <StyledSubContent sideOffset={2} alignOffset={-5}>
          {children}
        </StyledSubContent>
      </RadixDropdownMenu.Portal>
    </DropdownRoot>
  );
};

/**
 * The core Dropdown component. Contains Items, Groups, and SubMenus.
 * @param {node} trigger required. element that renders the menu when clicked
 * @param {bool} open optional. open status of the submenu, must be used with onOpenChange
 * @param {function} onOpenChange optional. function called when the open state of the submenu changes
 * @param {children} children required. items inside the menu, possible items are listed below.
 * @param {DropdownMenu.Item} DropdownMenu.Item optional. standard dropdown item
 * @param {DropdownMenu.CheckItem} DropdownMenu.CheckItem optional. checkable dropdown item
 * @param {DropdownMenu.RadioItem} DropdownMenu.RadioItem optional. radio item, used within radio groups
 * @param {DropdownMenu.Group} DropdownMenu.Group optional. groups items inside the menu
 * @param {DropdownMenu.RadioGroup} DropdownMenu.RadioGroup optional. used to wrap radio items
 * @param {DropdownMenu.SubMenu} DropdownMenu.SubMenu optional. used to render submenus within the menu
 * @param {DropdownMenu.Separator} DropdownMenu.Separator optional. used to separate groups
 * @param {DropdownMenu.Label} DropdownMenu.Label optional. used to label groups
 *
 * @info This item can also be passed any other common props (e.g. disabled, className)
 * @returns DropdownItem
 */
export const DropdownMenu = ({
  trigger,
  open,
  onOpenChange,
  children,
  asChild,
  modal = false,
  ...props
}: any) => {
  return (
    <DropdownRoot open={open} onOpenChange={onOpenChange} modal={modal} {...props}>
      <DropdownTrigger asChild={asChild}>{trigger}</DropdownTrigger>
      <RadixDropdownMenu.Portal>
        <StyledContent sideOffset={5} collisionPadding={8} {...props}>
          {children}
          <StyledArrow offset={12} />
        </StyledContent>
      </RadixDropdownMenu.Portal>
    </DropdownRoot>
  );
};

/**
 * A non-focusable separator for a group of items
 *
 *
 * @returns StytledSeparator
 */
DropdownMenu.Separator = StyledSeparator;

/**
 * A non-focusable label for a group of items
 *
 * @param {string} text required, text to display
 * @info This item can also be passed any other common props (e.g. disabled, className)
 * @returns DropdownLabel
 */
DropdownMenu.Label = DropdownLabel;

/**
 * Component for grouping dropdown items
 *
 * @param {string} label optional, adds a label to thr group
 * @param {string} separator optional, "top" || "bottom" || "top-bottom", add separator(s) to the group
 * @param {children} children items inside the group
 * @info This item can also be passed any other common props (e.g. disabled, className)
 * @returns DropdownGroup
 */
DropdownMenu.Group = DropdownGroup;

/**
 * Component required to have radio options in a dropdown, contains RadioGroup.Item
 * @param {string} value value of the radio group
 * @param {function} onValueChange function to call when value changes
 * @param {string} label optional, adds a label to thr group
 * @param {string} separator optional, "top" || "bottom" || "top-bottom", add separator(s) to the group
 * @param {children} children items inside the group, must be RadioGroup.Item
 * @info This item can also be passed any other common props (e.g. disabled, className)
 * @returns DropdownRadioGroup
 */
DropdownMenu.RadioGroup = DropdownRadioGroup;

/**
 * Dropdown radio item, used with RadioGroup
 * @param {string} value required. value of the radio item
 * @param {string} text required. text of the radio item
 * @param {any} hint optional, adds content to the right of the radio item
 * @param {function} onClick optional, function to call when item is clicked
 * @info This item can also be passed any other common props (e.g. disabled, className)
 * @returns DropdownRadioItem
 */
DropdownRadioGroup.Item = DropdownRadioItem;

/**
 * Checkable dropdown item
 * @param {bool} checked required. checked status of the item, must be used with onCheckedChange
 * @param {function} onCheckedChange required. function called when the checked state of the item changes
 * @param {string} text required. text of the item
 * @param {any} hint optional, adds content to the right of the radio item
 * @param {function} onClick optional, function to call when item is clicked
 * @info This item can also be passed any other common props (e.g. disabled, className)
 * @returns DropdownCheckItem
 */
DropdownMenu.CheckItem = DropdownCheckItem;

/**
 * Standard dropdown item
 * @param {string} text required. text of the item
 * @param {any} hint optional, adds content to the right of the radio item
 * @param {bool} unread optional. adds a unread indicator to the left of the item
 * @param {function} onClick optional, function to call when item is clicked
 * @info This item can also be passed any other common props (e.g. disabled, className)
 * @returns DropdownItem
 */
DropdownMenu.Item = DropdownItem;

/**
 * Submenu with a trigger
 * @param {string} triggerText required. text of the trigger item
 * @param {bool} open optional. open status of the submenu, must be used with onOpenChange
 * @param {function} onOpenChange optional. function called when the open state of the submenu changes
 * @param {children} children required. items inside the submenu, uses the same pattern as a normal DropdownMenu
 * @info This item can also be passed any other common props (e.g. disabled, className)
 * @returns DropdownItem
 */
DropdownMenu.SubMenu = DropdownSubMenu;
