// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./WithdrawInfo.AccountInfo.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";
import {
  default as AccountType,
  SelectContext as AccountTypeContext,
} from "../Primitives/Select";
import {
  default as EditAccount,
  ButtonContext as EditAccountContext,
  default as Cancel,
  ButtonContext as CancelContext,
  default as SaveAccount,
  ButtonContext as SaveAccountContext,
} from "../Primitives/Button";
import {
  default as First,
  InputContext as FirstContext,
  default as Last,
  InputContext as LastContext,
  default as Business,
  InputContext as BusinessContext,
  default as Email,
  InputContext as EmailContext,
  default as AccountNumber,
  InputContext as AccountNumberContext,
  default as Routing,
  InputContext as RoutingContext,
} from "../Primitives/Input";

const styles = [
  {
    type: "default",
    layers: {
      U9mTsDgPJx: {
        none: {
          text: "No Account",
        },
      },
      LPhGaj72cj: {
        none: {
          text: " ",
        },
      },
      "64uTrQFfsK": {
        none: {
          text: "Current Account",
        },
      },
      SqZ7MJTzbU: {
        none: {
          text: "Account Holder",
        },
      },
      VjFdn9gBbq: {
        none: {
          text: "Account Info",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "editing",
    layers: {
      "64uTrQFfsK": {
        none: {
          text: "Edit Account",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      HP985ccVit: {
        none: {
          label: "Account type",
        },
      },
      GvhejKuqWj: {
        none: {
          text: "Edit Account",
        },
      },
      W4H79iQees: {
        none: {
          placeholder: "First Name",
          label: "First Name",
        },
      },
      "59n34DTFdr": {
        none: {
          placeholder: "Last Name",
          label: "Last Name",
        },
      },
      C3muRSBZrg: {
        none: {
          placeholder: "Company Name (if applicable)",
          label: "Company Name",
        },
      },
      RcT5dkxJXM: {
        none: {
          placeholder: "Email",
          label: "Email",
        },
      },
      VwDpujaQN6: {
        none: {
          placeholder: "Account Number",
          label: "Account Number",
        },
      },
      HuXmnhbahy: {
        none: {
          placeholder: "Routing Number",
          label: "Routing Number",
        },
      },
      "5XYxLqWVP2": {
        none: {
          text: "Cancel",
          kind: "quiet",
        },
      },
      NpL2LmjSUK: {
        none: {
          text: "Save Account",
          icon: icons.padlock,
          kind: "primary",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "editing",
    layers: {
      HP985ccVit: {},
      GvhejKuqWj: {},
      W4H79iQees: {},
      "59n34DTFdr": {},
      C3muRSBZrg: {},
      RcT5dkxJXM: {},
      VwDpujaQN6: {},
      HuXmnhbahy: {},
      "5XYxLqWVP2": {},
      NpL2LmjSUK: {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "editing",
  },
];

export const AccountInfoContext = createContext(null);

function AccountInfo(_props) {
  const defaults = useContext(AccountInfoContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="F1H6f5t1RB"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "F1H6f5t1RB",
        scope: "Faqcwba7g6",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <TextPrimitive
            id={"Text_64uTrQFfsK"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_64uTrQFfsK"
            }
            key={"64uTrQFfsK"}
            text={getStyle("64uTrQFfsK", "text")}
            element={getStyle("64uTrQFfsK", "htmlElement")}
          />
          <ContainerPrimitive
            id={"Edit_SBTJSQf6p4"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_SBTJSQf6p4"
            }
            key={"SBTJSQf6p4"}
            addSpacing={false}
            element={getStyle("SBTJSQf6p4", "htmlElement")}
          >
            <ContainerPrimitive
              id={"Holder_HtwmBMKNm5"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_HtwmBMKNm5"
              }
              key={"HtwmBMKNm5"}
              addSpacing={false}
              element={getStyle("HtwmBMKNm5", "htmlElement")}
            >
              {[
                <TextPrimitive
                  id={"Text_SqZ7MJTzbU"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_SqZ7MJTzbU"
                  }
                  key={"SqZ7MJTzbU"}
                  text={getStyle("SqZ7MJTzbU", "text")}
                  element={getStyle("SqZ7MJTzbU", "htmlElement")}
                />,
                <SpacerPrimitive
                  id={"Spacer_HfVffqmiAM"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_HfVffqmiAM"
                  }
                  key={"HfVffqmiAM"}
                />,
                <ContainerPrimitive
                  id={"Container_WX3nFTodxn"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_WX3nFTodxn"
                  }
                  key={"WX3nFTodxn"}
                  addSpacing={false}
                  element={getStyle("WX3nFTodxn", "htmlElement")}
                >
                  {[
                    props.First === undefined ? (
                      <First
                        key={"W4H79iQees"}
                        {...getCompositeDefaultProps("W4H79iQees")}
                        id="First_W4H79iQees"
                        className="__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_W4H79iQees"
                      />
                    ) : (
                      <FirstContext.Provider
                        key="W4H79iQees-provider"
                        value={{
                          key: "W4H79iQees",
                          id: "First_W4H79iQees",
                          className:
                            "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_W4H79iQees",
                          ...getCompositeDefaultProps("W4H79iQees"),
                        }}
                      >
                        {props.First}
                      </FirstContext.Provider>
                    ),
                    <SpacerPrimitive
                      id={"Spacer_H7BsvCirtZ"}
                      className={
                        "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_H7BsvCirtZ"
                      }
                      key={"H7BsvCirtZ"}
                    />,
                    props.Last === undefined ? (
                      <Last
                        key={"59n34DTFdr"}
                        {...getCompositeDefaultProps("59n34DTFdr")}
                        id="Last_59n34DTFdr"
                        className="__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_59n34DTFdr"
                      />
                    ) : (
                      <LastContext.Provider
                        key="59n34DTFdr-provider"
                        value={{
                          key: "59n34DTFdr",
                          id: "Last_59n34DTFdr",
                          className:
                            "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_59n34DTFdr",
                          ...getCompositeDefaultProps("59n34DTFdr"),
                        }}
                      >
                        {props.Last}
                      </LastContext.Provider>
                    ),
                  ]}
                </ContainerPrimitive>,
                <SpacerPrimitive
                  id={"Spacer_D8JanQcbT3"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_D8JanQcbT3"
                  }
                  key={"D8JanQcbT3"}
                />,
                props.Business === undefined ? (
                  <Business
                    key={"C3muRSBZrg"}
                    {...getCompositeDefaultProps("C3muRSBZrg")}
                    id="Business_C3muRSBZrg"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_C3muRSBZrg"
                  />
                ) : (
                  <BusinessContext.Provider
                    key="C3muRSBZrg-provider"
                    value={{
                      key: "C3muRSBZrg",
                      id: "Business_C3muRSBZrg",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_C3muRSBZrg",
                      ...getCompositeDefaultProps("C3muRSBZrg"),
                    }}
                  >
                    {props.Business}
                  </BusinessContext.Provider>
                ),
                <SpacerPrimitive
                  id={"Spacer_V4d7tbqgzT"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_V4d7tbqgzT"
                  }
                  key={"V4d7tbqgzT"}
                />,
                props.Email === undefined ? (
                  <Email
                    key={"RcT5dkxJXM"}
                    {...getCompositeDefaultProps("RcT5dkxJXM")}
                    id="Email_RcT5dkxJXM"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_RcT5dkxJXM"
                  />
                ) : (
                  <EmailContext.Provider
                    key="RcT5dkxJXM-provider"
                    value={{
                      key: "RcT5dkxJXM",
                      id: "Email_RcT5dkxJXM",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_RcT5dkxJXM",
                      ...getCompositeDefaultProps("RcT5dkxJXM"),
                    }}
                  >
                    {props.Email}
                  </EmailContext.Provider>
                ),
              ]}
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"Account_8dANyrmuKC"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_8dANyrmuKC"
              }
              key={"8dANyrmuKC"}
              addSpacing={false}
              element={getStyle("8dANyrmuKC", "htmlElement")}
            >
              {[
                <TextPrimitive
                  id={"Text_VjFdn9gBbq"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_VjFdn9gBbq"
                  }
                  key={"VjFdn9gBbq"}
                  text={getStyle("VjFdn9gBbq", "text")}
                  element={getStyle("VjFdn9gBbq", "htmlElement")}
                />,
                <SpacerPrimitive
                  id={"Spacer_Ake31HaqaE"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_Ake31HaqaE"
                  }
                  key={"Ake31HaqaE"}
                />,
                props.AccountNumber === undefined ? (
                  <AccountNumber
                    key={"VwDpujaQN6"}
                    {...getCompositeDefaultProps("VwDpujaQN6")}
                    id="AccountNumber_VwDpujaQN6"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_VwDpujaQN6"
                  />
                ) : (
                  <AccountNumberContext.Provider
                    key="VwDpujaQN6-provider"
                    value={{
                      key: "VwDpujaQN6",
                      id: "AccountNumber_VwDpujaQN6",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_VwDpujaQN6",
                      ...getCompositeDefaultProps("VwDpujaQN6"),
                    }}
                  >
                    {props.AccountNumber}
                  </AccountNumberContext.Provider>
                ),
                <SpacerPrimitive
                  id={"Spacer_Wr5DBztKwo"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_Wr5DBztKwo"
                  }
                  key={"Wr5DBztKwo"}
                />,
                props.Routing === undefined ? (
                  <Routing
                    key={"HuXmnhbahy"}
                    {...getCompositeDefaultProps("HuXmnhbahy")}
                    id="Routing_HuXmnhbahy"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_HuXmnhbahy"
                  />
                ) : (
                  <RoutingContext.Provider
                    key="HuXmnhbahy-provider"
                    value={{
                      key: "HuXmnhbahy",
                      id: "Routing_HuXmnhbahy",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_HuXmnhbahy",
                      ...getCompositeDefaultProps("HuXmnhbahy"),
                    }}
                  >
                    {props.Routing}
                  </RoutingContext.Provider>
                ),
                <SpacerPrimitive
                  id={"Spacer_Mp4ietY2nT"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_Mp4ietY2nT"
                  }
                  key={"Mp4ietY2nT"}
                />,
                props.AccountType === undefined ? (
                  <AccountType
                    key={"HP985ccVit"}
                    {...getCompositeDefaultProps("HP985ccVit")}
                    id="AccountType_HP985ccVit"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_HP985ccVit"
                  />
                ) : (
                  <AccountTypeContext.Provider
                    key="HP985ccVit-provider"
                    value={{
                      key: "HP985ccVit",
                      id: "AccountType_HP985ccVit",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_HP985ccVit",
                      ...getCompositeDefaultProps("HP985ccVit"),
                    }}
                  >
                    {props.AccountType}
                  </AccountTypeContext.Provider>
                ),
              ]}
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"Buttons_PLb9vXdd2h"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_PLb9vXdd2h"
              }
              key={"PLb9vXdd2h"}
              addSpacing={false}
              element={getStyle("PLb9vXdd2h", "htmlElement")}
            >
              {[
                props.Cancel === undefined ? (
                  <Cancel
                    key={"5XYxLqWVP2"}
                    {...getCompositeDefaultProps("5XYxLqWVP2")}
                    id="Cancel_5XYxLqWVP2"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_5XYxLqWVP2"
                  />
                ) : (
                  <CancelContext.Provider
                    key="5XYxLqWVP2-provider"
                    value={{
                      key: "5XYxLqWVP2",
                      id: "Cancel_5XYxLqWVP2",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_5XYxLqWVP2",
                      ...getCompositeDefaultProps("5XYxLqWVP2"),
                    }}
                  >
                    {props.Cancel}
                  </CancelContext.Provider>
                ),
                props.SaveAccount === undefined ? (
                  <SaveAccount
                    key={"NpL2LmjSUK"}
                    {...getCompositeDefaultProps("NpL2LmjSUK")}
                    id="SaveAccount_NpL2LmjSUK"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_NpL2LmjSUK"
                  />
                ) : (
                  <SaveAccountContext.Provider
                    key="NpL2LmjSUK-provider"
                    value={{
                      key: "NpL2LmjSUK",
                      id: "SaveAccount_NpL2LmjSUK",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_NpL2LmjSUK",
                      ...getCompositeDefaultProps("NpL2LmjSUK"),
                    }}
                  >
                    {props.SaveAccount}
                  </SaveAccountContext.Provider>
                ),
              ]}
            </ContainerPrimitive>
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"Current_6vKxc3iK4w"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_6vKxc3iK4w"
            }
            key={"6vKxc3iK4w"}
            addSpacing={false}
            element={getStyle("6vKxc3iK4w", "htmlElement")}
          >
            {[
              <ContainerPrimitive
                id={"Container_SwuWBWfnWb"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_SwuWBWfnWb"
                }
                key={"SwuWBWfnWb"}
                addSpacing={false}
                element={getStyle("SwuWBWfnWb", "htmlElement")}
              >
                <TextPrimitive
                  id={"bankName_U9mTsDgPJx"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_U9mTsDgPJx"
                  }
                  key={"U9mTsDgPJx"}
                  text={
                    exists(props.bankName)
                      ? props.bankName
                      : getStyle("U9mTsDgPJx", "text")
                  }
                  element={getStyle("U9mTsDgPJx", "htmlElement")}
                />
                <TextPrimitive
                  id={"lastFour_LPhGaj72cj"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_LPhGaj72cj"
                  }
                  key={"LPhGaj72cj"}
                  text={
                    exists(props.lastFour)
                      ? props.lastFour
                      : getStyle("LPhGaj72cj", "text")
                  }
                  element={getStyle("LPhGaj72cj", "htmlElement")}
                />
              </ContainerPrimitive>,
              <SpacerPrimitive
                id={"Spacer_XSeyjfxYHD"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_XSeyjfxYHD"
                }
                key={"XSeyjfxYHD"}
              />,
              props.EditAccount === undefined ? (
                <EditAccount
                  key={"GvhejKuqWj"}
                  {...getCompositeDefaultProps("GvhejKuqWj")}
                  id="EditAccount_GvhejKuqWj"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_GvhejKuqWj"
                />
              ) : (
                <EditAccountContext.Provider
                  key="GvhejKuqWj-provider"
                  value={{
                    key: "GvhejKuqWj",
                    id: "EditAccount_GvhejKuqWj",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_Faqcwba7g6_GvhejKuqWj",
                    ...getCompositeDefaultProps("GvhejKuqWj"),
                  }}
                >
                  {props.EditAccount}
                </EditAccountContext.Provider>
              ),
            ]}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

AccountInfo.AccountType = AccountType;
AccountInfo.EditAccount = EditAccount;
AccountInfo.First = First;
AccountInfo.Last = Last;
AccountInfo.Business = Business;
AccountInfo.Email = Email;
AccountInfo.AccountNumber = AccountNumber;
AccountInfo.Routing = Routing;
AccountInfo.Cancel = Cancel;
AccountInfo.SaveAccount = SaveAccount;
AccountInfo.__variants = [
  {
    name: "editing",
    type: "variant",
  },
];

export default AccountInfo;
