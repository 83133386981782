import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import firebase from "firebase/app";
import { logger } from "./logger";
import { trackLogout } from "./hotplate-common/analytics";
import { persistor } from "./Store";
import { getUserInfo, loadUserInfoSuccess, logoutUser } from "./hotplate-storefront/actions";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "./hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { loginCheckoutMutation, loginPortalMutation } from "./mutations";

interface Auth {
  isLoading: boolean;
  login: (
    isPortal: boolean,
    loginAttempt?: { otp: string; methodId: string },
    unverifiedPhone?: string
  ) => Promise<void>;
  logout: () => Promise<void>;
}

interface User {
  chefId?: string;
  phoneNumber: string;
  isAdmin: string;
  [key: string]: unknown;
}

interface PortalUser extends User {
  chefId: string;
}

const AuthContext = createContext<{
  auth: Auth;
  user?: User;
}>({
  auth: {
    isLoading: false,
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
  },
  user: undefined,
});

export function AuthUserProviderGate({
  children,
  loading,
}: {
  children: React.ReactNode;
  loading?: React.ReactNode;
}) {
  const { userInfo, userInfoLoading, userInfoError } = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const [isAuthReady, setIsAuthReady] = useState(!!firebase.auth().currentUser);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);
  const [idTokenResult, setIdTokenResult] = useState<firebase.auth.IdTokenResult>();

  const queryClient = useQueryClient();
  const loginPortal = useMutation(loginPortalMutation, { retry: 0 });
  const loginCheckout = useMutation(loginCheckoutMutation, { retry: 0 });

  const login = useCallback<Auth["login"]>(
    async (isPortal, loginAttempt, unverifiedPhone) => {
      setIsLoginLoading(true);
      try {
        if (isPortal) {
          const { json } = await loginPortal.mutateAsync.call(undefined, {
            loginAttempt,
            bypassPhone: unverifiedPhone,
          });
          const { userInfo, firebaseToken } = json;

          const userCredential = await firebase.auth().signInWithCustomToken(firebaseToken);
          if (userCredential.user) {
            loadUserInfoSuccess(dispatch, userInfo);
            setIdTokenResult(await userCredential.user.getIdTokenResult());
            // We setIdTokenResult in case the onAuthStateChanged below doesn't get triggered
            // by the signInWithCustomToken we just did.
            // (It doesn't trigger if the user is already logged in, even if with a different
            // token. Additionally, it sometimes just fails to trigger inexplicably.)
          } else {
            throw new Error("Firebase sign in failed");
          }
        } else {
          const { json } = await loginCheckout.mutateAsync.call(undefined, {
            loginAttempt,
            guestPhone: unverifiedPhone,
          });
          const { userInfo, firebaseToken } = json;

          // Guest checkouts do not provide a firebaseToken
          if (firebaseToken) {
            const userCredential = await firebase.auth().signInWithCustomToken(firebaseToken);
            if (userCredential.user) {
              loadUserInfoSuccess(dispatch, userInfo);
              setIdTokenResult(await userCredential.user.getIdTokenResult());
              // We setIdTokenResult in case the onAuthStateChanged below doesn't get triggered
              // by the signInWithCustomToken we just did.
              // (It doesn't trigger if the user is already logged in, even if with a different
              // token. Additionally, it sometimes just fails to trigger inexplicably.)
            } else {
              throw new Error("Firebase sign in failed");
            }
          } else {
            loadUserInfoSuccess(dispatch, userInfo);
          }
        }
      } finally {
        setIsLoginLoading(false);
      }
    },
    [dispatch, loginCheckout.mutateAsync, loginPortal.mutateAsync] // WARNING: Everything in this array must be stable!
  );

  const logout = useCallback(async () => {
    setIsLogoutLoading(true);
    try {
      trackLogout();
      await firebase.auth().signOut();
      logoutUser(true)(dispatch); // Clear Redux store
      queryClient.clear(); // Delete all data from the React Query cache
      return persistor.purge();
    } finally {
      setIsLogoutLoading(false);
    }
  }, [dispatch, queryClient]); // WARNING: Everything in this array must be stable!

  useEffect(() => {
    let canceled = false;
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setIsAuthReady(true);
      if (user) {
        logger.debug("Firebase auth state is logged in.");
        canceled = false;
        user.getIdTokenResult().then((result) => {
          if (!canceled) {
            setIdTokenResult(result);
            getUserInfo()(dispatch); // Refresh the local userInfo object from the backend in the background.
          }
        });
      } else {
        logger.debug("Firebase auth state is logged out.");
        canceled = true;
        setIdTokenResult(undefined);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  const prevUserInfoLoading = usePrevious(userInfoLoading);
  useEffect(() => {
    if (prevUserInfoLoading && !userInfoLoading && userInfoError) {
      logger.debug("Logging out; error loading userInfo.");
      logout();
    }
  }, [logout, prevUserInfoLoading, userInfoLoading, userInfoError]);

  const isLoggedIn =
    idTokenResult?.claims?.phone_number &&
    userInfo?.phone &&
    idTokenResult.claims.phone_number === userInfo.phone;

  const isLoading =
    !isAuthReady || (!isLoggedIn && userInfoLoading) || isLoginLoading || isLogoutLoading;

  return isAuthReady ? (
    <AuthContext.Provider
      value={{
        auth: { isLoading, login, logout },
        user: isLoggedIn
          ? {
              ...userInfo,
              chefId: idTokenResult.claims.hostId,
              phoneNumber: idTokenResult.claims.phone_number,
              isAdmin: !!idTokenResult.claims.isAdmin,
            }
          : undefined,
      }}
    >
      {children}
    </AuthContext.Provider>
  ) : (
    loading || null
  );
}

export function useAuth() {
  return useContext(AuthContext).auth;
}

export function useMaybeUser() {
  return useContext(AuthContext).user;
}

export function useUser() {
  const user = useMaybeUser();
  if (!user) {
    throw new Error("User is not logged in");
  }
  return user;
}

export function usePortalUser() {
  const user = useUser();
  if (!user.chefId) {
    throw new Error("User is missing chefId claim");
  }
  return user as PortalUser;
}
