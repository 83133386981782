import { colors } from "../stitches.config";

// utility functions

function hexToHSLA(H) {
  // Convert hex to RGBA first
  let r = 0,
    g = 0,
    b = 0,
    a = 1;
  if (H.length === 4) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
  } else if (H.length === 7) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
  } else if (H.length === 5) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
    a = "0x" + H[4] + H[4];
    a = (a / 255).toFixed(3);
  } else if (H.length === 9) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
    a = "0x" + H[7] + H[8];
    a = (a / 255).toFixed(3);
  }

  // yeet into HSL
  r /= 255;
  g /= 255;
  b /= 255;

  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return "hsla(" + h + "," + s + "%," + l + "%," + a + ")";
}

function modifyHsl(hsl, sNum, lNum) {
  // modify saturation and lightness
  const hslArr = hsl.replace("hsl(", "").replace("hsla(", "").replace(")", "").split(",");
  const l = parseFloat(hslArr[2]);
  const s = parseFloat(hslArr[1]);

  let sMod = s + sNum;
  let lMod = l + lNum;
  if (sMod > 100) sMod = 100;
  if (sMod < 0) sMod = 0;
  if (lMod > 100) lMod = 100;
  if (lMod < 0) lMod = 0;

  if (hslArr.length === 3) {
    // for hsl
    return `hsl(${hslArr[0]}, ${sMod}%, ${lMod}%)`;
  } else if (hslArr.length === 4) {
    // for hsla
    return `hsla(${hslArr[0]}, ${sMod}%, ${lMod}%, ${hslArr[3]})`;
  }
  console.log("your function broke, nice.");
  return null;
}

function createNewHslFromHsl(hsl, sNum, lNum) {
  const hslArr = hsl.replace("hsl(", "").replace("hsla(", "").replace(")", "").split(",");

  if (hslArr.length === 3) {
    // for hsl
    return `hsl(${hslArr[0]}, ${sNum}%, ${lNum}%)`;
  } else if (hslArr.length === 4) {
    // for hsla
    return `hsla(${hslArr[0]}, ${sNum}%, ${lNum}%, ${hslArr[3]})`;
  }
  console.log("your function broke, nice.");
  return null;
}

function getLightnessFromHSL(HSL) {
  // get lightness from hsla or hsl by parsing the int from the l in the hsl(a) string
  const hslArr = HSL.replace("hsl(", "")
    .replace("hsla(", "")
    .replace(")", "")
    .replace("", "")
    .split(",");
  return parseFloat(hslArr[2]);
}

// getter functions

function getChefPrimaryColor(siteSettings) {
  if (!siteSettings || siteSettings.constructor != Object) return null;
  const accountStyles = siteSettings.styles;
  if (!accountStyles || accountStyles.constructor != Object) return null;
  const primaryColor = accountStyles.primaryColor;
  if (typeof primaryColor !== "string" || primaryColor === "") return null;
  return hexToHSLA(primaryColor);
}

function getContrastingTextColor(hslColor) {
  // hsl or hsla
  const colorLightness = getLightnessFromHSL(hslColor);
  return colorLightness > 70 ? colors.mauve12 : "hsl(0, 0%, 100%)";
}

function getChefHeaderColor(siteSettings) {
  if (!siteSettings || siteSettings.constructor != Object) return null;
  const accountStyles = siteSettings.styles;
  if (
    !accountStyles ||
    accountStyles.constructor != Object ||
    typeof accountStyles.bannerColor !== "string" ||
    accountStyles.bannerColor === ""
  )
    return null;
  return hexToHSLA(accountStyles.bannerColor);
}

function getChefHeaderTextColor(siteSettings) {
  if (!siteSettings || siteSettings.constructor != Object) return null;
  const accountStyles = siteSettings.styles;
  if (
    !accountStyles ||
    accountStyles.constructor != Object ||
    typeof accountStyles.bannerTextColor !== "string" ||
    accountStyles.bannerTextColor === ""
  )
    return null;
  return hexToHSLA(accountStyles.bannerTextColor);
}

function getChefHeaderImage(siteSettings) {
  if (!siteSettings || siteSettings.constructor != Object) return false;
  if (
    !siteSettings.styles ||
    siteSettings.styles.constructor != Object ||
    typeof siteSettings.styles.bannerImage !== "string" ||
    siteSettings.styles.bannerImage === ""
  )
    return false;
  return siteSettings.styles.bannerImage;
}

function getChefBigLogo(siteSettings) {
  if (!siteSettings || siteSettings.constructor != Object) return false;
  if (
    !siteSettings.styles ||
    siteSettings.styles.constructor != Object ||
    typeof siteSettings.styles.bigLogo !== "string" ||
    siteSettings.styles.bigLogo === ""
  )
    return false;
  return siteSettings.styles.bigLogo;
}

// export / main logic

export function getChefStyles(siteSettings) {
  // defaults
  const defaultPrimaryColor = colors.violet9;
  const defaultHeaderColor = colors.violet12;

  // check if chef has defined a primaryColor, with fallback to a default
  const primaryColor = getChefPrimaryColor(siteSettings) || defaultPrimaryColor; // returns hsl(a) string
  // from primaryColor, get Text Color
  const textColor = getContrastingTextColor(primaryColor); // returns hsl string
  // check if chef has defined a headerColor, with a fallback to a default
  const headerColor = getChefHeaderColor(siteSettings) || defaultHeaderColor;
  // get the text color for the header
  const headerTextColor =
    getChefHeaderTextColor(siteSettings) || getContrastingTextColor(headerColor);
  // get cartButton theme based on headerColor lightness
  const cartButtonTheme = getLightnessFromHSL(headerColor) > 70 ? "dark" : "light";
  // get header image or return false
  const headerImage = getChefHeaderImage(siteSettings) || false; // returns url string or false
  // get big logo or return false
  const bigLogo = getChefBigLogo(siteSettings) || false; // returns url string or false

  // Define interaction colors
  const primaryColorLight = createNewHslFromHsl(primaryColor, 100, 95);
  const primaryColorDark = createNewHslFromHsl(primaryColor, 65, 50);
  const primaryColorActive = modifyHsl(primaryColor, -8, -8);
  const primaryColorHover = modifyHsl(primaryColor, -4, -4);

  const styles = {
    primaryColor: primaryColor,
    primaryColorLight: primaryColorLight,
    primaryColorDark: primaryColorDark,
    primaryColorHover: primaryColorHover,
    primaryColorActive: primaryColorActive,
    textColor: textColor,
    headerBackgroundColor: headerColor,
    headerTextColor: headerTextColor,
    cartButtonTheme: cartButtonTheme,
    headerImage: headerImage,
    bigLogo: bigLogo,
  };

  return styles;
}
