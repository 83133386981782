// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./SummaryLineItem.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      "7kuddeoWzF": {
        none: {
          text: "No Title",
        },
      },
      E7Xn4z1HtP: {
        none: {
          text: "$10.00",
        },
      },
      Y7SsV8Xaht: {
        none: {
          text: "",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "bold",
    layers: {
      "7kuddeoWzF": {
        none: {
          text: "Refund Total",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "bold",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "bold",
  },
];

export const SummaryLineItemContext = createContext(null);

function SummaryLineItem(_props) {
  const defaults = useContext(SummaryLineItemContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="MiByoQ6Z9a"
      addSpacing={true}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "MiByoQ6Z9a",
        scope: "XML4b3EU3D",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"quantity-name-container_NftG6smzH8"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_XML4b3EU3D_NftG6smzH8"
            }
            key={"NftG6smzH8"}
            addSpacing={false}
            element={getStyle("NftG6smzH8", "htmlElement")}
          >
            <TextPrimitive
              id={"item_7kuddeoWzF"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_XML4b3EU3D_7kuddeoWzF"
              }
              key={"7kuddeoWzF"}
              text={
                exists(props.title)
                  ? props.title
                  : getStyle("7kuddeoWzF", "text")
              }
              element={getStyle("7kuddeoWzF", "htmlElement")}
            />
            <TextPrimitive
              id={"subtitle_Y7SsV8Xaht"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_XML4b3EU3D_Y7SsV8Xaht"
              }
              key={"Y7SsV8Xaht"}
              text={
                exists(props.subtitle)
                  ? props.subtitle
                  : getStyle("Y7SsV8Xaht", "text")
              }
              element={getStyle("Y7SsV8Xaht", "htmlElement")}
            />
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"price-container_TKdregjEPm"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_XML4b3EU3D_TKdregjEPm"
            }
            key={"TKdregjEPm"}
            addSpacing={false}
            element={getStyle("TKdregjEPm", "htmlElement")}
          >
            {
              <TextPrimitive
                id={"total_E7Xn4z1HtP"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_XML4b3EU3D_E7Xn4z1HtP"
                }
                key={"E7Xn4z1HtP"}
                text={
                  exists(props.total)
                    ? props.total
                    : getStyle("E7Xn4z1HtP", "text")
                }
                element={getStyle("E7Xn4z1HtP", "htmlElement")}
              />
            }
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

SummaryLineItem.__variants = [
  {
    name: "bold",
    type: "variant",
  },
];

export default SummaryLineItem;
