/* eslint-disable react/prop-types */
import React, { PureComponent, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { useBreakpoint } from "../../visly";
import { ItemsCard } from "../../visly/Management/InProgress";
import {
  getMenuItemOptionsString,
  getTimestampDayOfWeekMonthDate,
  getTimestampHoursMinutesAMPM,
  getTimestampMonthDate,
} from "@hotplate/utils-ts/helperFunctions";
import "./index.css";
import { PuffLoader } from "../../hotplate-common/loaders/PuffLoader";
import { NothingHere } from "./components/NothingHere";
import { Center } from "../../hotplate-common/primitives/Containers";
import { trackPrepSectionExpandToggled } from "./analytics";
import { usePortalUser } from "../../auth";

const ItemsCardComponent = ({ data, getSingleItems, getOrdersByTimeFrame }) => {
  const getPreppedData = (singleItems) => {
    const preppedData = [[]];
    const preppedDataObj = {};
    for (let i = 0; i < singleItems.length; i++) {
      const itemId = singleItems[i].title.split("984fhdufs83oijfs")[0];
      if (!(itemId in preppedDataObj)) preppedDataObj[itemId] = [];
      preppedDataObj[itemId].push(singleItems[i]);
    }
    Object.keys(preppedDataObj).forEach((key) => {
      if (preppedDataObj[key].length > 1) preppedData.push(preppedDataObj[key]);
      else preppedData[0].push(preppedDataObj[key][0]);
    });
    for (let i = 0; i < preppedData.length; i++) {
      preppedData[i].sort((a, b) => {
        if (
          a.title.split("984fhdufs83oijfs").length >= 2 &&
          b.title.split("984fhdufs83oijfs").length < 2
        )
          return 1;
        if (
          a.title.split("984fhdufs83oijfs").length < 2 &&
          b.title.split("984fhdufs83oijfs").length >= 2
        )
          return -1;
        return 0;
      });
    }
    return preppedData;
  };

  const getTotalOrders = (data) => {
    const orderIdSet = new Set();
    Object.keys(data.itemObjs).forEach((itemTitle) => {
      const itemsArray = data.itemObjs[itemTitle];
      for (let i = 0; i < itemsArray.length; i++) {
        orderIdSet.add(itemsArray[i].orderId);
      }
    });
    return orderIdSet.size;
  };

  const getTotalOrdersDone = (data) => {
    const orders = {};
    Object.keys(data.itemObjs).forEach((itemTitle) => {
      const itemsArray = data.itemObjs[itemTitle];
      for (let i = 0; i < itemsArray.length; i++) {
        const orderId = itemsArray[i].orderId;
        const itemsLeft = itemsArray[i].quantity - itemsArray[i].quantityDone;
        if (!(orderId in orders)) orders[orderId] = 0;
        orders[orderId] += itemsLeft;
      }
    });
    return Object.keys(orders).filter((oKey) => {
      return orders[oKey] === 0;
    }).length;
  };

  const getTotalItems = (data) => {
    let sum = 0;
    Object.keys(data.itemCounts).forEach((itemTitle) => {
      sum += data.itemCounts[itemTitle];
    });
    return sum;
  };

  const getTotalItemsDone = (data) => {
    let sum = 0;
    Object.keys(data.itemCountsDone).forEach((itemTitle) => {
      sum += data.itemCountsDone[itemTitle];
    });
    return sum;
  };

  const fullDayNames = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
    Sun: "Sunday",
  };
  const [expanded, toggleExpanded] = useState(false);
  const date =
    data.sectionHeader.split(", ").length >= 2
      ? data.sectionHeader.split(", ")[1]
      : data.sectionHeader;
  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);

  const getSectionHeader = (data) => {
    if (data.sectionHeader.includes("-")) return data.sectionHeader;
    const timeBlock = data.sectionHeader.split(", ")[0];
    if (Object.keys(fullDayNames).includes(data.sectionHeader.split(", ")[0])) {
      return fullDayNames[timeBlock];
    }
    return timeBlock;
  };

  return (
    <ItemsCard
      className="itemsCard"
      size={size}
      timeBlock={getSectionHeader(data)}
      date={getSectionHeader(data).includes("-") ? "" : date}
      numOrders={getTotalOrders(data)}
      numItems={getTotalItems(data)}
      ordersDone={getTotalOrdersDone(data)}
      ordersLeft={getTotalOrders(data) - getTotalOrdersDone(data)}
      itemsDone={getTotalItemsDone(data)}
      itemsLeft={getTotalItems(data) - getTotalItemsDone(data)}
      subCards={
        <div className={expanded ? "subItems expanded" : "subItems"}>
          {expanded
            ? getOrdersByTimeFrame(data.sectionHeader).map((subData, index) => (
                <ItemsCard
                  sub
                  orderType={subData.type}
                  animation={
                    subData.type === "asap" && (
                      <div className="ring-container">
                        <div className="ringring"></div>
                        <div className="circle"></div>
                      </div>
                    )
                  }
                  className="item"
                  key={index}
                  size={size}
                  timeBlock={
                    subData.sectionHeader.split(":breakout:")[0] +
                    (subData.sectionHeader.split(":breakout:").length > 1
                      ? " / " + subData.sectionHeader.split(":breakout:")[1]
                      : "")
                  }
                  date={subData.time}
                  numOrders={getTotalOrders(subData)}
                  numItems={getTotalItems(subData)}
                  ordersDone={getTotalOrdersDone(subData)}
                  ordersLeft={getTotalOrders(subData) - getTotalOrdersDone(subData)}
                  itemsDone={getTotalItemsDone(subData)}
                  itemsLeft={getTotalItems(subData) - getTotalItemsDone(subData)}
                >
                  {getPreppedData(getSingleItems(subData)).map((itemCardLines, subIndex) => (
                    <div key={subIndex}>
                      <div>
                        {subIndex !== 0 && (
                          <ItemsCard.Total
                            count={itemCardLines.reduce(function (prev, curr) {
                              return prev + curr.quantity;
                            }, 0)}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexGrow: "1",
                          flexWrap: "wrap",
                          flexShrink: "0",
                          width: "100%",
                        }}
                      >
                        {itemCardLines
                          .sort((a, b) => {
                            const aTitle = a.title.split("984fhdufs83oijfs")[0];
                            const bTitle = b.title.split("984fhdufs83oijfs")[0];
                            return aTitle.localeCompare(bTitle);
                          })
                          .map((singleItem, i) => (
                            <ItemsCard.Item
                              size={size}
                              quantity={singleItem.quantity}
                              name={singleItem.title.split("984fhdufs83oijfs")[0]}
                              hasVariations={singleItem.title.split("984fhdufs83oijfs").length >= 2}
                              variation={
                                singleItem.title.split("984fhdufs83oijfs").length >= 2
                                  ? singleItem.title.split("984fhdufs83oijfs")[1]
                                  : ""
                              }
                              key={i}
                            >
                              {singleItem.quantityDone > 0 && (
                                <ItemsCard.Item.Badge
                                  kind={
                                    singleItem.quantityDone === singleItem.quantity
                                      ? "allDone"
                                      : "someDone"
                                  }
                                  quantity={singleItem.quantityDone}
                                />
                              )}
                            </ItemsCard.Item>
                          ))}
                      </div>
                    </div>
                  ))}
                </ItemsCard>
              ))
            : null}
        </div>
      }
      Expand={
        <ItemsCard.Expand
          size={size}
          pressed={expanded}
          onClick={() => {
            trackPrepSectionExpandToggled({ expanded: !expanded });
            toggleExpanded(!expanded);
          }}
        />
      }
    >
      {getPreppedData(getSingleItems(data)).map((itemCardLines, index) => {
        return (
          <div key={index} className={expanded ? "items expanded" : "items"}>
            <div>
              {index !== 0 && (
                <ItemsCard.Total
                  count={itemCardLines.reduce(function (prev, curr) {
                    return prev + curr.quantity;
                  }, 0)}
                />
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexGrow: "1",
                flexWrap: "wrap",
                flexShrink: "0",
                width: "100%",
              }}
            >
              {itemCardLines
                .sort((a, b) => {
                  const aTitle = a.title.split("984fhdufs83oijfs")[0];
                  const bTitle = b.title.split("984fhdufs83oijfs")[0];
                  return aTitle.localeCompare(bTitle);
                })
                .map((singleItem, i) => (
                  <ItemsCard.Item
                    size={size}
                    className="item"
                    quantity={singleItem.quantity}
                    name={singleItem.title.split("984fhdufs83oijfs")[0]}
                    hasVariations={singleItem.title.split("984fhdufs83oijfs").length >= 2}
                    variation={
                      singleItem.title.split("984fhdufs83oijfs").length >= 2
                        ? singleItem.title.split("984fhdufs83oijfs")[1]
                        : ""
                    }
                    key={i}
                  >
                    {singleItem.quantityDone > 0 && (
                      <ItemsCard.Item.Badge
                        kind={
                          singleItem.quantityDone === singleItem.quantity ? "allDone" : "someDone"
                        }
                        quantity={singleItem.quantityDone}
                      />
                    )}
                  </ItemsCard.Item>
                ))}
            </div>
          </div>
        );
      })}
    </ItemsCard>
  );
};

class PrepSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formattedOrders: {},
    };
  }

  componentDidMount() {
    this.setOrdersFormattedByTimeFrame();
  }

  componentDidUpdate(prevProps) {
    const { orders } = this.props;
    if (!_.isEqual(prevProps.orders, orders)) {
      this.setOrdersFormattedByTimeFrame();
    }
  }

  setOrdersFormattedByTimeFrame() {
    const { orders, hostId } = this.props;
    const allItems = [];

    Object.keys(orders).forEach((key) => {
      const order = orders[key];
      const cartItems = order.cartItems;
      if (Array.isArray(cartItems) && order.siteSettings && order.siteSettings.chefId === hostId) {
        for (let j = 0; j < cartItems.length; j++) {
          const cartItem = _.cloneDeep(cartItems[j]);
          cartItem.timeSlot = order.timeSlot;
          cartItem.paymentIntentId = order.paymentIntentId;
          cartItem.cartType = order.cartType;
          cartItem.orderId = order.paymentIntentId;
          cartItem.isSubscription = order.isSubscription;
          cartItem.itemIndex = j;
          cartItem.location = order.location;
          if (!("quantityDone" in cartItem)) cartItem.quantityDone = 0;
          allItems.push(cartItem);
        }
      }
    });

    const formattedOrders = {};

    allItems.sort((a, b) => {
      return a.timeSlot.startTime - b.timeSlot.startTime;
    });

    const weekIndex = 0;
    for (let i = 0; i < allItems.length; i++) {
      const item = allItems[i];
      const itemTimestamp = item.timeSlot.startTime;
      const dayKey = getTimestampDayOfWeekMonthDate(itemTimestamp);
      const pickupTimeKey = getTimestampHoursMinutesAMPM(itemTimestamp);
      if (!(dayKey in formattedOrders)) {
        formattedOrders[dayKey] = { index: weekIndex, children: {} };
      }

      if (!(pickupTimeKey in formattedOrders[dayKey].children)) {
        formattedOrders[dayKey].children[pickupTimeKey] = {
          index: weekIndex,
          children: [],
        };
      }
      formattedOrders[dayKey].children[pickupTimeKey].children.push(allItems[i]);
    }

    this.setState({ formattedOrders: formattedOrders });
  }

  getSingleItems(section) {
    const itemCards = [];
    Object.keys(section.itemCounts).map((itemTitle) =>
      itemCards.push({
        title: itemTitle,
        quantity: section.itemCounts[itemTitle],
        quantityDone: section.itemCountsDone[itemTitle],
      })
    );
    return itemCards;
  }

  getOrdersByTimeFrame(keyOverride = null) {
    const { activeFilters } = this.props;
    const { formattedOrders } = this.state;
    const timeFrame = keyOverride === null ? "DAY" : "PICKUP_TIME";
    const selectedDay = keyOverride === null ? "" : keyOverride;
    const orders = {};
    Object.keys(formattedOrders).forEach((dayKey) => {
      Object.keys(formattedOrders[dayKey].children).forEach((pickupTimeKey) => {
        for (let i = 0; i < formattedOrders[dayKey].children[pickupTimeKey].children.length; i++) {
          const item = formattedOrders[dayKey].children[pickupTimeKey].children[i];
          if (item.deleted || item.restockQuantity === item.quantity) continue;

          let shouldSkipItem = false;
          if (Array.isArray(activeFilters)) {
            for (const filter of activeFilters) {
              // This breaks the "is not" conjunction (and maybe other conjunctions)
              // for Item and Category filters on prep
              if (filter.title === "Item") {
                if (!filter.selectedValueOptions.includes(item.title)) {
                  shouldSkipItem = true;
                }
              } else if (filter.title === "Category") {
                let shouldKeepItem = false;
                for (const tag of item.prepTags || []) {
                  if (filter.selectedValueOptions.includes(tag)) {
                    shouldKeepItem = true;
                    break;
                  }
                }
                if (!shouldKeepItem) {
                  shouldSkipItem = true;
                }
              }
            }
          }
          if (shouldSkipItem) {
            continue;
          }

          const variationString = getMenuItemOptionsString(item, "variation");
          const itemTitle =
            item.title + "984fhdufs83oijfs" + (variationString !== "" ? " " : "") + variationString;

          const itemIndex = 0;
          let key = "";
          if (timeFrame === "DAY") {
            key = dayKey;
            if (Array.isArray(activeFilters)) {
              for (let j = 0; j < activeFilters.length; j++) {
                if (
                  activeFilters[j].isDateFilter === "fulfillment" &&
                  activeFilters[j].selectedConjuction === "is between"
                ) {
                  const filterStartTime = activeFilters[j].selectedDays[0];
                  const filterEndTime = activeFilters[j].selectedDays[1];
                  if (
                    item.timeSlot.startTime >= filterStartTime &&
                    item.timeSlot.startTime <= filterEndTime
                  ) {
                    key =
                      getTimestampMonthDate(filterStartTime) +
                      " - " +
                      getTimestampMonthDate(filterEndTime);
                  }
                }
              }
            }
          } else if (timeFrame === "PICKUP_TIME") {
            if (selectedDay !== "" && dayKey !== selectedDay) {
              if (keyOverride.includes(" - ")) key = dayKey + ", " + pickupTimeKey;
              else continue;
            } else {
              key = pickupTimeKey;
            }
          }

          let type = null;
          if (!(key in orders)) {
            orders[key] = {
              index: itemIndex,
              type: type,
              sectionHeader: key,
              itemObjs: {},
              itemCounts: {},
              itemCountsDone: {},
              timeFrame: timeFrame,
            };
          }

          if (!(itemTitle in orders[key].itemCounts)) {
            orders[key].itemCounts[itemTitle] = 0;
            orders[key].itemCountsDone[itemTitle] = 0;
            orders[key].itemObjs[itemTitle] = [];
          }

          orders[key].itemObjs[itemTitle].push(item);
          orders[key].itemCounts[itemTitle] +=
            item.quantity - (Number.isInteger(item.restockQuantity) ? item.restockQuantity : 0);
          orders[key].itemCountsDone[itemTitle] += item.quantityDone;
        }
      });
    });
    const ordersArr = [];
    Object.keys(orders).forEach((orderKey) => {
      ordersArr.push(orders[orderKey]);
    });
    ordersArr.sort((a, b) => {
      return a.itemIndex - b.itemIndex;
    });
    ordersArr.sort((a, b) => {
      const aType = a.type ? a.type : "ZZZZ";
      const bType = b.type ? b.type : "ZZZZ";
      return aType.localeCompare(bType);
    });
    return ordersArr;
  }

  daysWithOrders() {
    const { formattedOrders } = this.state;
    const daysWithOrders = new Set();
    Object.keys(formattedOrders).forEach((dayKey) => {
      Object.keys(formattedOrders[dayKey].children).forEach((pickupTimeKey) => {
        for (let i = 0; i < formattedOrders[dayKey].children[pickupTimeKey].children.length; i++) {
          const pt = formattedOrders[dayKey].children[pickupTimeKey].children[i].timeSlot.startTime;
          const filteredDate = new Date(pt);
          filteredDate.setHours(0, 0, 0, 0);
          daysWithOrders.add(filteredDate.getTime());
        }
      });
    });
    return Array.from(daysWithOrders).map((timestamp) => new Date(timestamp));
  }

  render() {
    const { connectToOrdersLoading } = this.props;
    return (
      <div className="prepContainer">
        {Object.keys(this.props.orders).length === 0 ? (
          connectToOrdersLoading ? (
            <Center css={{ width: "100%", height: "100%" }}>
              <PuffLoader />
            </Center>
          ) : (
            <NothingHere />
          )
        ) : (
          this.getOrdersByTimeFrame().map((itemCardData, index) => (
            <ItemsCardComponent
              data={itemCardData}
              key={index}
              getSingleItems={this.getSingleItems.bind(this)}
              getOrdersByTimeFrame={this.getOrdersByTimeFrame.bind(this)}
            />
          ))
        )}
        {}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    connectToOrdersLoading: state.orderManagement.connectToOrdersLoading,
    activeFilters: state.orderManagement.activeFilters,
  };
};

export default connect(
  mapStateToProps,
  {}
)(function (props) {
  const { chefId } = usePortalUser();
  return <PrepSection {...props} hostId={chefId} />;
});
