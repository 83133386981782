// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Modal.CloseButton.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, IconPrimitive } from "../_internal_primitives";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      "3F69Tb8W5b": {
        none: {
          icon: icons.close,
          useMask: true,
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const CloseButtonContext = createContext(null);

function CloseButton(_props) {
  const defaults = useContext(CloseButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="XbwDDBDU6q"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "XbwDDBDU6q",
        scope: "B5T7DxHLw7",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <IconPrimitive
          id={"Icon_3F69Tb8W5b"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_B5T7DxHLw7_3F69Tb8W5b"
          }
          key={"3F69Tb8W5b"}
          useMask={getStyle("3F69Tb8W5b", "useMask")}
          src={getStyle("3F69Tb8W5b", "icon")}
        />
      )}
    </RootPrimitive>
  );
}

CloseButton.__variants = [];

export default CloseButton;
