// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./Container.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../../_internal_primitives";
import { default as ItemsCard } from "../../Management/InProgress/ItemsCard";

const styles = [
  {
    type: "default",
    layers: {
      JLZ9NYouhg: {
        none: {
          text: "Dec. 1 - Dec. 8",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "time",
    values: [
      {
        propValue: "weeks",
        layers: {},
      },
      {
        propValue: "days",
        layers: {},
      },
      {
        propValue: "slots",
        layers: {},
      },
      {
        propValue: "times",
        layers: {},
      },
    ],
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large850",
        layers: {},
      },
      {
        propValue: "med450",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
    ],
  },
  {
    type: "boolean",
    propName: "fade",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      ArnseQ1kkt: {},
    },
  },
  {
    type: "enum",
    propName: "time",
    values: [
      {
        propValue: "weeks",
        layers: {
          ArnseQ1kkt: {},
        },
      },
      {
        propValue: "days",
        layers: {
          ArnseQ1kkt: {},
        },
      },
      {
        propValue: "slots",
        layers: {
          ArnseQ1kkt: {},
        },
      },
      {
        propValue: "times",
        layers: {
          ArnseQ1kkt: {},
        },
      },
    ],
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large850",
        layers: {
          ArnseQ1kkt: {},
        },
      },
      {
        propValue: "med450",
        layers: {
          ArnseQ1kkt: {},
        },
      },
      {
        propValue: "small",
        layers: {
          ArnseQ1kkt: {},
        },
      },
    ],
  },
  {
    type: "boolean",
    propName: "fade",
    layers: {
      ArnseQ1kkt: {},
    },
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "time",
    propValues: ["weeks", "days", "slots", "times"],
  },
  {
    type: "enum",
    propName: "size",
    propValues: ["large850", "med450", "small"],
  },
  {
    type: "boolean",
    propName: "fade",
  },
];

export const ContainerContext = createContext(null);

function Container(_props) {
  const defaults = useContext(ContainerContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="54UjWDhrsJ"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "54UjWDhrsJ",
        scope: "sTxvmb1wL2",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Wrapper_NiqZPVVwT1"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_sTxvmb1wL2_NiqZPVVwT1"
          }
          key={"NiqZPVVwT1"}
          addSpacing={false}
          element={getStyle("NiqZPVVwT1", "htmlElement")}
        >
          {
            <ContainerPrimitive
              id={"Content_135CZKDsf3"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_sTxvmb1wL2_135CZKDsf3"
              }
              key={"135CZKDsf3"}
              addSpacing={false}
              element={getStyle("135CZKDsf3", "htmlElement")}
            >
              <ContainerPrimitive
                id={"Header_MZcxTjZu1u"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_sTxvmb1wL2_MZcxTjZu1u"
                }
                key={"MZcxTjZu1u"}
                addSpacing={false}
                element={getStyle("MZcxTjZu1u", "htmlElement")}
              >
                {
                  <TextPrimitive
                    id={"timeBlock_JLZ9NYouhg"}
                    className={
                      "__visly_reset_7X7HTLRqyD __visly_scope_sTxvmb1wL2_JLZ9NYouhg"
                    }
                    key={"JLZ9NYouhg"}
                    text={
                      exists(props.timeBlock)
                        ? props.timeBlock
                        : getStyle("JLZ9NYouhg", "text")
                    }
                    element={getStyle("JLZ9NYouhg", "htmlElement")}
                  />
                }
              </ContainerPrimitive>
              <ContainerPrimitive
                key={"XBcemEKtxR"}
                id={"Slot_XBcemEKtxR"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_sTxvmb1wL2_XBcemEKtxR"
                }
                addSpacing={false}
              >
                {props.children}
              </ContainerPrimitive>
            </ContainerPrimitive>
          }
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

Container.ItemsCard = ItemsCard;
Container.__variants = [
  {
    name: "time",
    type: "group",
    variants: ["weeks", "days", "slots", "times"],
  },
  {
    name: "size",
    type: "group",
    variants: ["large850", "med450", "small"],
  },
  {
    name: "fade",
    type: "variant",
  },
];

export default Container;
