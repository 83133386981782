/* eslint-disable react/prop-types */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import NewModal from "../../hotplate-common/NewModal";

import SubscriptionManagement from "../../hotplate-common/subscriptions";
import {
  setPackingFilter,
  notifyCustomer,
  setToastPopupInfo,
} from "../../hotplate-storefront/actions";
import ItemizedReceipt from "../payment/ItemizedReceipt";
import CustomerModal from "../components/CustomerModal";
import AddEditOrder from "./AddEditOrder";
import { trackCustomerProfileClicked } from "./analytics";
import { PackingTable } from "./components/PackingTable";
import { PackingCardList } from "./components/PackingCardList";
import { PackingCardGrid } from "./components/PackingCardGrid";
import { shouldRetry, trpc } from "../../trpc";
import { v4 as uuid } from "uuid";
import { createOrder } from "@hotplate/utils-ts/helperFunctions";
import { trackOrderManuallyCreated } from "../analytics";
import { usePortalUser } from "../../auth";

class PackingSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editOrderData: null,
      showSubscriptionModal: false,
      irpPaymentIntentId: null,
      customerModalPhone: null,
    };
    this.setEditOrderData = this.setEditOrderData.bind(this);
    this.setOrderPacked = this.setOrderPacked.bind(this);
    this.setOrderNotPacked = this.setOrderNotPacked.bind(this);
    this.markLineItemDone = this.markLineItemDone.bind(this);
    this.toggleShowIrpReceipt = this.toggleShowIrpReceipt.bind(this);
    this.toggleShowCustomerModal = this.toggleShowCustomerModal.bind(this);
    this.getItemQuantity = this.getItemQuantity.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { isChefCreatingNewOrder, setIsChefCreatingNewOrder, siteSettings, hostId } = this.props;
    const { editOrderData } = this.state;
    if (!prevProps.isChefCreatingNewOrder && isChefCreatingNewOrder) {
      this.setState({
        editOrderData:
          // DEFAULT ORDER DATA TO THEN MODIFY
          createOrder(
            {
              // CART
              id: uuid(),
              paymentIntentId: uuid(),
              fulfillmentType: "pickup",
              cartItems: [],
              chefId: hostId,
              eventId: "",
              timeSlot: {
                startTime: parseInt(Math.floor(new Date().getTime() / 1000) * 1000), // TODO: should pull from same timestamp for start and end time
                endTime: parseInt(Math.floor(new Date().getTime() / 1000) * 1000),
                range: false,
                disabled: false,
                hidden: false,
                // TODO: add timezone
              },
              location: {
                addressDict: {
                  city: "",
                  state: "",
                  streetNumber: "",
                  streetRoute: "",
                  zip: "",
                },
                arrivalInstructions: "",
                displayFullAddress: true,
                id: "",
                lat: 0.0,
                lng: 0.0,
                title: "",
              },
              discountCode: null,
            },
            {
              // CART EVENT
              customFields: [],
              ticketed: false,
            },
            siteSettings,
            {
              // CUSTOMER
              firstName: "",
              lastName: "",
              fullName: "",
              orderId: "Ma" + Math.floor(Math.random() * (10000 - 99999) + 10000).toString(),
              phone: "",
              email: "",
              tip: "0.00",
              customerAddressDict: undefined,
              customerDeliveryInstructions: "",
              deliveryFee: "0.00",
              orderPlaced: new Date().getTime(),
              customFieldValues: [],
              eventRemindersEnabled: false,
              source: "manually_by_chef",
            }
          ),
      });
      trackOrderManuallyCreated();
    } else if (prevState.editOrderData && editOrderData === null) {
      setIsChefCreatingNewOrder(false);
    }
  }

  setEditOrderData(order) {
    this.setState({ editOrderData: order });
  }

  getItemQuantity(cartItem) {
    return (
      cartItem.quantity -
      (Number.isInteger(cartItem.restockQuantity) ? cartItem.restockQuantity : 0)
    );
  }

  async setOrderPacked(order) {
    const { setToastPopupInfo, siteSettings, hostId, notifyCustomer, toggleOrderPacked } =
      this.props;

    try {
      await toggleOrderPacked.mutateAsync({
        paymentIntentId: order.paymentIntentId,
        packed: true,
      });
    } catch (e) {
      setToastPopupInfo({
        type: "error",
        text: `Failed to mark order complete: ${e}`,
      });
      throw e;
    }

    if (
      order.phone &&
      siteSettings &&
      siteSettings.notifications &&
      siteSettings.notifications.onCompleteNotification &&
      typeof siteSettings.notifications.onCompleteNotification.text === "string" &&
      siteSettings.notifications.onCompleteNotification.text !== "" &&
      siteSettings.notifications.onCompleteNotification.enabled
    ) {
      notifyCustomer(
        order.phone,
        siteSettings.notifications.onCompleteNotification.text,
        hostId,
        order
      );
    }
  }

  async setOrderNotPacked(order) {
    const { setToastPopupInfo, toggleOrderPacked } = this.props;
    try {
      await toggleOrderPacked.mutateAsync({
        paymentIntentId: order.paymentIntentId,
        packed: false,
      });
    } catch (e) {
      setToastPopupInfo({
        type: "error",
        text: `Failed to mark order as incomplete: ${e}`,
      });
      throw e;
    }
  }

  async markLineItemDone(order, itemIndex, mark) {
    const { setToastPopupInfo, siteSettings, notifyCustomer, hostId, updateDoneQuantity } =
      this.props;
    try {
      await updateDoneQuantity.mutateAsync({
        paymentIntentId: order.paymentIntentId,
        itemIndex: itemIndex,
        quantityDone: !mark ? 0 : this.getItemQuantity(order.cartItems[itemIndex]),
      });
    } catch (e) {
      setToastPopupInfo({
        type: "error",
        text: `Failed to toggle item: ${e}`,
      });
      throw e;
    }
    if (
      mark &&
      order.phone &&
      siteSettings &&
      typeof siteSettings.onItemCompleteNotification === "string" &&
      siteSettings.onItemCompleteNotification !== ""
    ) {
      notifyCustomer(
        order.phone,
        siteSettings.onItemCompleteNotification.replace(
          "{itemName}",
          order.cartItems[itemIndex].title
        ),
        hostId
      );
    }
  }

  toggleShowIrpReceipt(order) {
    this.setState({ irpPaymentIntentId: order.paymentIntentId });
  }
  toggleShowCustomerModal(source, order) {
    this.setState({ customerModalPhone: order.phone });
    trackCustomerProfileClicked(source, {
      customerId: order.phone,
      orderId: order.paymentIntentId,
    });
  }

  getAllItemNames() {
    const { menuItems } = this.props;
    const itemNames = [];
    if (menuItems && menuItems.constructor == Object) {
      Object.keys(menuItems).forEach((itemKey) => {
        itemNames.push(menuItems[itemKey].title);
      });
    }
    itemNames.sort();
    return itemNames;
  }

  render() {
    const { editOrderData, irpPaymentIntentId, customerModalPhone } = this.state;
    const {
      packingView,
      orders,
      isPrinting,
      toggleOrderPinned,
      markAllItemsDone,
      isChefCreatingNewOrder,
    } = this.props;
    return (
      <>
        {packingView === "CARD" && (
          <PackingCardList
            orders={orders}
            functions={{
              toggleShowCustomerModal: this.toggleShowCustomerModal,
              setEditOrderData: this.setEditOrderData,
              toggleShowIrpReceipt: this.toggleShowIrpReceipt,
              setOrderNotPacked: this.setOrderNotPacked,
              setOrderPacked: this.setOrderPacked,
              getItemQuantity: this.getItemQuantity,
              markLineItemDone: this.markLineItemDone,
              markAllItemsDone: markAllItemsDone.mutateAsync,
              toggleOrderPinned: toggleOrderPinned.mutateAsync,
            }}
            isPrinting={isPrinting}
          />
        )}
        {packingView === "LIST" && (
          <PackingTable
            orders={orders}
            columns={this.props.packingTableColumns}
            functions={{
              toggleShowCustomerModal: this.toggleShowCustomerModal,
              setEditOrderData: this.setEditOrderData,
              toggleShowIrpReceipt: this.toggleShowIrpReceipt,
              setOrderNotPacked: this.setOrderNotPacked,
              setOrderPacked: this.setOrderPacked,
              getItemQuantity: this.getItemQuantity,
              markLineItemDone: this.markLineItemDone,
              toggleOrderPinned: toggleOrderPinned.mutateAsync,
            }}
            isPrinting={isPrinting}
          />
        )}
        {packingView === "GRID" && (
          <PackingCardGrid
            orders={orders}
            functions={{
              toggleShowCustomerModal: this.toggleShowCustomerModal,
              setEditOrderData: this.setEditOrderData,
              toggleShowIrpReceipt: this.toggleShowIrpReceipt,
              setOrderNotPacked: this.setOrderNotPacked,
              setOrderPacked: this.setOrderPacked,
              getItemQuantity: this.getItemQuantity,
              markLineItemDone: this.markLineItemDone,
              markAllItemsDone: markAllItemsDone.mutateAsync,
              toggleOrderPinned: toggleOrderPinned.mutateAsync,
            }}
            isPrinting={isPrinting}
          />
        )}
        <ItemizedReceipt
          isOpen={!!irpPaymentIntentId}
          paymentIntentId={irpPaymentIntentId}
          onRequestClose={() => this.setState({ irpPaymentIntentId: null })}
        />
        <CustomerModal
          isOpen={!!customerModalPhone}
          phone={customerModalPhone}
          onRequestClose={() => this.setState({ customerModalPhone: null })}
        />
        {editOrderData !== null && (
          <AddEditOrder
            order={editOrderData}
            isChefCreatingNewOrder={isChefCreatingNewOrder}
            getItemQuantity={this.getItemQuantity}
            setEditOrderData={this.setEditOrderData}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menuItems: state.hostPortal.hostInfo.menuItems,
    siteSettings: state.hostPortal.hostInfo.siteSettings,
    packingFilter: state.orderManagement.packingFilter,
    prevPackingFilter: state.orderManagement.prevPackingFilter,
    notifyCustomerLoading: state.orderManagement.notifyCustomerLoading,
    packingView: state.orderManagement.packingView,
  };
};

export default connect(mapStateToProps, {
  setPackingFilter,
  notifyCustomer,
  setToastPopupInfo,
})(function PackingSectionQueryWrapper(props) {
  const toggleOrderPacked = trpc.portal.toggleOrderPacked.useMutation({ retry: shouldRetry(1) });
  const toggleOrderPinned = trpc.portal.toggleOrderPinned.useMutation({ retry: shouldRetry(1) });
  const markAllItemsDone = trpc.portal.markAllItemsDone.useMutation({ retry: shouldRetry(1) });
  const updateDoneQuantity = trpc.portal.updateDoneQuantity.useMutation({ retry: shouldRetry(1) });
  const { chefId } = usePortalUser();
  return (
    <PackingSection
      toggleOrderPacked={toggleOrderPacked}
      toggleOrderPinned={toggleOrderPinned}
      markAllItemsDone={markAllItemsDone}
      updateDoneQuantity={updateDoneQuantity}
      {...props}
      hostId={chefId}
    />
  );
});
