import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "../stitches.config";
import { P, A } from "../hotplate-common/primitives/Containers";
import { Button } from "../hotplate-common/primitives/Button";
import { EnvelopeClosedIcon, PaperPlaneIcon } from "@radix-ui/react-icons";
import {
  trackReferralLinkCopied,
  trackReferralLinkSent,
  trackReferralModalOpened,
  trackShareReferralButtonClicked,
} from "./analytics";

const ReferralContentBox = styled("div", {
  display: "grid",
  gridAutoFlow: "row",
  gap: "$md",
  width: "100%",
  marginTop: "$md",
  marginBottom: "50%",
  "@desktop_sm": {
    marginBottom: 0,
  },
});

const CopyReferralButton = styled("button", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "12px 16px",
  backgroundColor: "$accent3",
  borderRadius: "$sm",
  color: "$accent11",
  ff: "$avenir",
  fw: "normal",
  fs: 14,
  minWidth: 0,
  transition: "background-color 200ms ease-in-out",
  "&:hover": {
    backgroundColor: "$accent4",
  },
  "&:active": {
    backgroundColor: "$accent5",
  },
});

const StandardShareButtonBox = styled("div", {
  display: "grid",
  gridAutoFlow: "column",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  gap: "$md",
  width: "100%",
  variants: {
    hide: {
      true: {
        display: "none",
      },
    },
  },
});

const ApiShareButtonBox = styled("div", {
  display: "flex",
  alignItems: "center",
  width: "100%",
  variants: {
    hide: {
      true: {
        display: "none",
      },
    },
  },
});

export const Referral = ({ ...props }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [copyButtonText, setCopyButtonText] = useState("copy");
  const { hostId, firstName } = useSelector((state) => state.login.userInfo);

  useEffect(() => {
    trackReferralModalOpened();
  }, []);

  function getReferralLink() {
    return `${window.location.origin}?utm_source=referral&referral=${hostId}`;
  }

  function copyReferralLink() {
    navigator.clipboard.writeText(getReferralLink()).then(
      () => {
        setCopyButtonText("copied!");
        setTimeout(() => {
          setCopyButtonText("copy");
        }, 3000);
      },
      () => {
        setCopyButtonText("copy failed, try again");
        setTimeout(() => {
          setCopyButtonText("copy");
        }, 1000);
      }
    );
  }

  function getShareDialog() {
    setShowDialog(true);
    if (navigator.share) {
      navigator
        .share({
          title: `${firstName} invited you to join Hotplate`,
          text: "Check out Hotplate, I think you will love it. Plus, when you signup we will both get $30!",
          url: getReferralLink(),
        })
        .then(() => {
          trackReferralLinkSent("share-api");
        })
        .catch(console.error);
    }
  }

  return (
    <ReferralContentBox {...props}>
      <CopyReferralButton
        onClick={() => {
          copyReferralLink();
          trackReferralLinkCopied();
        }}
      >
        <P css={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
          {getReferralLink()}
        </P>
        <P css={{ fw: 600, marginLeft: 6 }}>{copyButtonText}</P>
      </CopyReferralButton>
      {/* hide if the share api is not supported, so standard share buttosn can replace it*/}
      <ApiShareButtonBox hide={!navigator.share && showDialog}>
        <Button
          css={{ width: "100%" }}
          color="info"
          variant="filled"
          onClick={() => {
            getShareDialog();
            trackShareReferralButtonClicked();
          }}
        >
          Share with a Chef
          <PaperPlaneIcon />
        </Button>
      </ApiShareButtonBox>
      {/* hidden if share api is supported or if show dialog is false, replaces CTA button */}
      <StandardShareButtonBox hide={!!navigator.share || !showDialog}>
        <A
          onClick={() => trackReferralLinkSent("email")}
          href={`mailto:?subject=${firstName}%20invited%20you%20to%20join%20Hotplate&body=Hey!%0d%0a%0d%0aCheck out Hotplate, I think you'll love it:%0d%0a%0d%0a${encodeURIComponent(
            getReferralLink()
          )}%0d%0a%0d%0aUse my referral link to signup and we both get $30!%0d%0a%0d%0a- ${firstName}`}
          css={{ textDecoration: "none" }}
        >
          <Button css={{ width: "100%" }} color="info" variant="tinted">
            <EnvelopeClosedIcon />
            Invite by email
          </Button>
        </A>
        <A
          onClick={() => trackReferralLinkSent("sms")}
          href={`sms:&body=Hey%21%20Check%20out%20Hotplate%3A%20${encodeURIComponent(
            getReferralLink()
          )}%0A%0AIf%20you%20sign%20up%20with%20my%20link%20we%20both%20get%20%2430%21`}
          css={{ textDecoration: "none" }}
        >
          <Button css={{ width: "100%" }} color="info" variant="tinted">
            <EnvelopeClosedIcon />
            Invite by text
          </Button>
        </A>
      </StandardShareButtonBox>
    </ReferralContentBox>
  );
};
