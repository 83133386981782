// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./MenuConfigItemCard.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";
import {
  default as DuplicateButton,
  ButtonContext as DuplicateButtonContext,
} from "../Primitives/Button";
import { default as Checkbox, CheckboxContext } from "../Primitives/Checkbox";

const styles = [
  {
    type: "default",
    layers: {
      VAtz94zmds: {
        none: {
          text: "Menu Item Title",
        },
      },
      LV5jaV3tij: {
        none: {
          icon: icons.draggable,
          useMask: true,
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
  {
    type: "enum",
    propName: "type",
    values: [
      {
        propValue: "addEditModal",
        layers: {},
      },
      {
        propValue: "menuConfig",
        layers: {},
      },
    ],
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      DN4ajsPpWs: {
        none: {
          text: "Duplicate",
          icon: icons.copy,
          rounded: true,
          withIcon: "left",
        },
      },
      "4rMWvzgz69": {},
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {
          DN4ajsPpWs: {},
          "4rMWvzgz69": {},
        },
      },
      {
        propValue: "small",
        layers: {
          DN4ajsPpWs: {},
          "4rMWvzgz69": {},
        },
      },
      {
        propValue: "med",
        layers: {
          DN4ajsPpWs: {},
          "4rMWvzgz69": {},
        },
      },
      {
        propValue: "large",
        layers: {
          DN4ajsPpWs: {},
          "4rMWvzgz69": {},
        },
      },
      {
        propValue: "xlarge",
        layers: {
          DN4ajsPpWs: {},
          "4rMWvzgz69": {},
        },
      },
    ],
  },
  {
    type: "enum",
    propName: "type",
    values: [
      {
        propValue: "addEditModal",
        layers: {
          DN4ajsPpWs: {
            none: {
              text: "Duplicate",
              icon: icons.copy,
              rounded: true,
              withIcon: "left",
            },
          },
          "4rMWvzgz69": {},
        },
      },
      {
        propValue: "menuConfig",
        layers: {
          DN4ajsPpWs: {},
          "4rMWvzgz69": {},
        },
      },
    ],
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {
      DN4ajsPpWs: {
        none: {
          text: "Duplicate",
        },
      },
      "4rMWvzgz69": {
        none: {
          checked: true,
        },
      },
    },
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
  {
    type: "enum",
    propName: "type",
    propValues: ["addEditModal", "menuConfig"],
  },
  {
    type: "boolean",
    propName: "selected",
  },
];

export const MenuConfigItemCardContext = createContext(null);

function MenuConfigItemCard(_props) {
  const defaults = useContext(MenuConfigItemCardContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="TqLszWJqeg"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "TqLszWJqeg",
        scope: "LM9QEwy68C",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            key={"EtSm6Fs6pg"}
            id={"Slot_EtSm6Fs6pg"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_LM9QEwy68C_EtSm6Fs6pg"
            }
            addSpacing={false}
          >
            {props.image}
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"menuitem-content-container_XrykGY7Hxh"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_LM9QEwy68C_XrykGY7Hxh"
            }
            key={"XrykGY7Hxh"}
            addSpacing={false}
            element={getStyle("XrykGY7Hxh", "htmlElement")}
          >
            <ContainerPrimitive
              id={"menuitem-details-continer_DmGqURqVP8"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_LM9QEwy68C_DmGqURqVP8"
              }
              key={"DmGqURqVP8"}
              addSpacing={false}
              element={getStyle("DmGqURqVP8", "htmlElement")}
            >
              <TextPrimitive
                id={"title_VAtz94zmds"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_LM9QEwy68C_VAtz94zmds"
                }
                key={"VAtz94zmds"}
                text={
                  exists(props.title)
                    ? props.title
                    : getStyle("VAtz94zmds", "text")
                }
                element={getStyle("VAtz94zmds", "htmlElement")}
              />
              <ContainerPrimitive
                id={"options-container_HBSY8Cxqwt"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_LM9QEwy68C_HBSY8Cxqwt"
                }
                key={"HBSY8Cxqwt"}
                addSpacing={false}
                element={getStyle("HBSY8Cxqwt", "htmlElement")}
              >
                {[
                  <ContainerPrimitive
                    id={"inventory-container_YFCHGDxgbF"}
                    className={
                      "__visly_reset_7X7HTLRqyD __visly_scope_LM9QEwy68C_YFCHGDxgbF"
                    }
                    key={"YFCHGDxgbF"}
                    addSpacing={false}
                    element={getStyle("YFCHGDxgbF", "htmlElement")}
                  >
                    {
                      <ContainerPrimitive
                        key={"FABuocoPd7"}
                        id={"Slot_FABuocoPd7"}
                        className={
                          "__visly_reset_7X7HTLRqyD __visly_scope_LM9QEwy68C_FABuocoPd7"
                        }
                        addSpacing={false}
                      >
                        {props.badges}
                      </ContainerPrimitive>
                    }
                  </ContainerPrimitive>,
                  props.DuplicateButton === undefined ? (
                    <DuplicateButton
                      key={"DN4ajsPpWs"}
                      {...getCompositeDefaultProps("DN4ajsPpWs")}
                      id="DuplicateButton_DN4ajsPpWs"
                      className="__visly_reset_7X7HTLRqyD __visly_scope_LM9QEwy68C_DN4ajsPpWs"
                    />
                  ) : (
                    <DuplicateButtonContext.Provider
                      key="DN4ajsPpWs-provider"
                      value={{
                        key: "DN4ajsPpWs",
                        id: "DuplicateButton_DN4ajsPpWs",
                        className:
                          "__visly_reset_7X7HTLRqyD __visly_scope_LM9QEwy68C_DN4ajsPpWs",
                        ...getCompositeDefaultProps("DN4ajsPpWs"),
                      }}
                    >
                      {props.DuplicateButton}
                    </DuplicateButtonContext.Provider>
                  ),
                ]}
              </ContainerPrimitive>
            </ContainerPrimitive>
            <SpacerPrimitive
              id={"Spacer_XmRNtUv5Sq"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_LM9QEwy68C_XmRNtUv5Sq"
              }
              key={"XmRNtUv5Sq"}
            />
            <ContainerPrimitive
              id={"Container_G4A4seC9cG"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_LM9QEwy68C_G4A4seC9cG"
              }
              key={"G4A4seC9cG"}
              addSpacing={false}
              element={getStyle("G4A4seC9cG", "htmlElement")}
            >
              {[
                props.Checkbox === undefined ? (
                  <Checkbox
                    key={"4rMWvzgz69"}
                    {...getCompositeDefaultProps("4rMWvzgz69")}
                    id="Checkbox_4rMWvzgz69"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_LM9QEwy68C_4rMWvzgz69"
                  />
                ) : (
                  <CheckboxContext.Provider
                    key="4rMWvzgz69-provider"
                    value={{
                      key: "4rMWvzgz69",
                      id: "Checkbox_4rMWvzgz69",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_LM9QEwy68C_4rMWvzgz69",
                      ...getCompositeDefaultProps("4rMWvzgz69"),
                    }}
                  >
                    {props.Checkbox}
                  </CheckboxContext.Provider>
                ),
                <IconPrimitive
                  id={"Icon_LV5jaV3tij"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_LM9QEwy68C_LV5jaV3tij"
                  }
                  key={"LV5jaV3tij"}
                  useMask={getStyle("LV5jaV3tij", "useMask")}
                  src={getStyle("LV5jaV3tij", "icon")}
                />,
              ]}
            </ContainerPrimitive>
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

MenuConfigItemCard.DuplicateButton = DuplicateButton;
MenuConfigItemCard.Checkbox = Checkbox;
MenuConfigItemCard.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
  {
    name: "type",
    type: "group",
    variants: ["addEditModal", "menuConfig"],
  },
  {
    name: "selected",
    type: "variant",
  },
];

export default MenuConfigItemCard;
