// https://www.radix-ui.com/docs/primitives/components/toggle-group
import React from "react";
import { styled } from "../../stitches.config";
import * as RadixToggleGroup from "@radix-ui/react-toggle-group";

const ItemPrimitive = styled(RadixToggleGroup.Item, {
  all: "unset",
  $$borderColor: "$colors$gray6",
  backgroundColor: "gray4",
  color: "$gray11",
  display: "flex",
  ff: "$avenir",
  fw: "$semi_bold",
  borderSpacing: "medium none",
  borderTop: "1px solid $$borderColor",
  borderBottom: "1px solid $$borderColor",
  cursor: "pointer",
  lh: "$reset",
  alignItems: "center",
  justifyContent: "center",
  transition: "background-color 150ms ease-in-out, color 150ms ease-in-out",
  "&:first-child": {
    borderTopLeftRadius: "inherit",
    borderLeft: "1px solid $$borderColor",
    borderBottomLeftRadius: "inherit",
  },
  "&:last-child": {
    borderTopRightRadius: "inherit",
    borderRight: "1px solid $$borderColor",
    borderBottomRightRadius: "inherit",
  },
  "&:hover": { backgroundColor: "$accent3" },
  "&:focus-visible": { position: "relative", outline: "2px solid $accent9", outlineOffset: -2 },
  "&[data-state=on]": { backgroundColor: "$accent9", color: "$accent1" },
  "&[data-disabled]": { pointerEvents: "none", cursor: "default", opacity: 0.5 },
});

const ToggleItem = ({ value, disabled, children, ...props }) => {
  return (
    <ItemPrimitive value={value} disabled={disabled} {...props}>
      {children}
    </ItemPrimitive>
  );
};

const ToggleGroupPrimitive = styled(RadixToggleGroup.Root, {
  display: "flex",
  backgroundColor: "$accent2",
  boxShadow: `0 2px 3px $colors$overlay7`,
  "&[data-orientation='vertical']": {
    flexDirection: "column",
  },
  variants: {
    size: {
      small: {
        borderRadius: "$md",
        height: "$sm_btn",
        [`& ${ItemPrimitive}`]: { padx: "$xs", fs: 13 },
      },
      medium: {
        borderRadius: "$md",
        height: "$md_btn",
        [`& ${ItemPrimitive}`]: { padx: "$sm", fs: 14 },
      },
      large: {
        borderRadius: "$lg",
        height: "$lg_btn",
        [`& ${ItemPrimitive}`]: { padx: "$md", fs: 16 },
      },
    },
  },
  defaultVariants: {
    size: "medium",
  },
});

export const ToggleGroup = ({
  type = "single",
  size,
  value,
  defaultValue,
  onValueChange,
  disabled,
  orientation = "horizontal",
  loop = false,
  children,
  ...props
}) => {
  return (
    <ToggleGroupPrimitive
      type={type}
      size={size}
      value={value}
      defaultValue={defaultValue}
      onValueChange={onValueChange}
      disabled={disabled}
      orientation={orientation}
      loop={loop}
      {...props}
    >
      {children}
    </ToggleGroupPrimitive>
  );
};

ToggleGroup.Item = ToggleItem;
