// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Modal.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as NewMenuItem } from "../Menu/Customer/CustomerMenuItem";
import { default as CloseButton } from "./Modal.CloseButton";

const styles = [
  {
    type: "default",
    layers: {
      KyQob6W7qH: {
        none: {
          htmlElement: "section",
        },
      },
      Gnjn7qSUEb: {
        none: {
          htmlElement: "header",
        },
      },
      GbVAUBX36y: {
        none: {
          htmlElement: "header",
        },
      },
      GTVQq66LoT: {
        none: {
          htmlElement: "article",
        },
      },
      Ft3m3tJCHg: {
        none: {
          htmlElement: "footer",
        },
      },
      "21RyVfgi5w": {
        none: {
          htmlElement: "article",
        },
      },
      WtF2ESE4Ue: {
        none: {
          text: "",
          htmlElement: "span",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
  {
    type: "boolean",
    propName: "compact",
    layers: {},
  },
  {
    type: "boolean",
    propName: "noHeader",
    layers: {},
  },
  {
    type: "boolean",
    propName: "isOpen",
    layers: {},
  },
  {
    type: "boolean",
    propName: "showConfirmation",
    layers: {},
  },
  {
    type: "boolean",
    propName: "hideClose",
    layers: {},
  },
  {
    type: "boolean",
    propName: "gray",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      DKC3KWMjxm: {},
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {
          DKC3KWMjxm: {},
        },
      },
      {
        propValue: "small",
        layers: {
          DKC3KWMjxm: {},
        },
      },
      {
        propValue: "med",
        layers: {
          DKC3KWMjxm: {},
        },
      },
      {
        propValue: "large",
        layers: {
          DKC3KWMjxm: {},
        },
      },
      {
        propValue: "xlarge",
        layers: {
          DKC3KWMjxm: {},
        },
      },
    ],
  },
  {
    type: "boolean",
    propName: "compact",
    layers: {
      DKC3KWMjxm: {},
    },
  },
  {
    type: "boolean",
    propName: "noHeader",
    layers: {
      DKC3KWMjxm: {},
    },
  },
  {
    type: "boolean",
    propName: "isOpen",
    layers: {
      DKC3KWMjxm: {},
    },
  },
  {
    type: "boolean",
    propName: "showConfirmation",
    layers: {
      DKC3KWMjxm: {},
    },
  },
  {
    type: "boolean",
    propName: "hideClose",
    layers: {
      DKC3KWMjxm: {},
    },
  },
  {
    type: "boolean",
    propName: "gray",
    layers: {
      DKC3KWMjxm: {},
    },
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
  {
    type: "boolean",
    propName: "compact",
  },
  {
    type: "boolean",
    propName: "noHeader",
  },
  {
    type: "boolean",
    propName: "isOpen",
  },
  {
    type: "boolean",
    propName: "showConfirmation",
  },
  {
    type: "boolean",
    propName: "hideClose",
  },
  {
    type: "boolean",
    propName: "gray",
  },
];

export const ModalContext = createContext(null);

function Modal(_props) {
  const defaults = useContext(ModalContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="L8reW1qiP6"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "L8reW1qiP6",
        scope: "RsxRVRKH1T",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"content-container_KyQob6W7qH"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_RsxRVRKH1T_KyQob6W7qH"
          }
          key={"KyQob6W7qH"}
          addSpacing={false}
          element={getStyle("KyQob6W7qH", "htmlElement")}
        >
          <ContainerPrimitive
            id={"optionSelect-container_21RyVfgi5w"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_RsxRVRKH1T_21RyVfgi5w"
            }
            key={"21RyVfgi5w"}
            addSpacing={false}
            element={getStyle("21RyVfgi5w", "htmlElement")}
          >
            {
              <ContainerPrimitive
                key={"Lz8avNMrr6"}
                id={"Slot_Lz8avNMrr6"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_RsxRVRKH1T_Lz8avNMrr6"
                }
                addSpacing={false}
              >
                {props.optionSelect}
              </ContainerPrimitive>
            }
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"header-container_Gnjn7qSUEb"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_RsxRVRKH1T_Gnjn7qSUEb"
            }
            key={"Gnjn7qSUEb"}
            addSpacing={false}
            element={getStyle("Gnjn7qSUEb", "htmlElement")}
          >
            <ContainerPrimitive
              id={"button-container_GbVAUBX36y"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_RsxRVRKH1T_GbVAUBX36y"
              }
              key={"GbVAUBX36y"}
              addSpacing={false}
              element={getStyle("GbVAUBX36y", "htmlElement")}
            >
              {props.CloseButton === undefined ? (
                <CloseButton key={"7yY4Fky8hj"} />
              ) : (
                props.CloseButton
              )}
            </ContainerPrimitive>
            <TextPrimitive
              id={"title_WtF2ESE4Ue"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_RsxRVRKH1T_WtF2ESE4Ue"
              }
              key={"WtF2ESE4Ue"}
              text={
                exists(props.title)
                  ? props.title
                  : getStyle("WtF2ESE4Ue", "text")
              }
              element={getStyle("WtF2ESE4Ue", "htmlElement")}
            />
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"scrollbox_EF1LGCxBuK"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_RsxRVRKH1T_EF1LGCxBuK"
            }
            key={"EF1LGCxBuK"}
            addSpacing={false}
            element={getStyle("EF1LGCxBuK", "htmlElement")}
          >
            {
              <ContainerPrimitive
                key={"miTRterJRJ"}
                id={"Slot_miTRterJRJ"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_RsxRVRKH1T_miTRterJRJ"
                }
                addSpacing={false}
              >
                {props.children}
              </ContainerPrimitive>
            }
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"absolute-container_Ft3m3tJCHg"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_RsxRVRKH1T_Ft3m3tJCHg"
            }
            key={"Ft3m3tJCHg"}
            addSpacing={false}
            element={getStyle("Ft3m3tJCHg", "htmlElement")}
          >
            {
              <ContainerPrimitive
                key={"85ab5aH2Px"}
                id={"Slot_85ab5aH2Px"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_RsxRVRKH1T_85ab5aH2Px"
                }
                addSpacing={false}
              >
                {props.fixedContent}
              </ContainerPrimitive>
            }
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"modal-show-container_GTVQq66LoT"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_RsxRVRKH1T_GTVQq66LoT"
            }
            key={"GTVQq66LoT"}
            addSpacing={false}
            element={getStyle("GTVQq66LoT", "htmlElement")}
          >
            {
              <ContainerPrimitive
                key={"Lk9coTS9dn"}
                id={"Slot_Lk9coTS9dn"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_RsxRVRKH1T_Lk9coTS9dn"
                }
                addSpacing={false}
              >
                {props.confirmation}
              </ContainerPrimitive>
            }
          </ContainerPrimitive>
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

Modal.NewMenuItem = NewMenuItem;
Modal.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
  {
    name: "compact",
    type: "variant",
  },
  {
    name: "noHeader",
    type: "variant",
  },
  {
    name: "isOpen",
    type: "variant",
  },
  {
    name: "showConfirmation",
    type: "variant",
  },
  {
    name: "hideClose",
    type: "variant",
  },
  {
    name: "gray",
    type: "variant",
  },
];

export default Modal;
