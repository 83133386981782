import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { FirebaseContext } from "../../firebaseSocket";
import { styled } from "../../stitches.config";
import { Box, Flex, Img } from "../../hotplate-common/primitives/Containers";
import NewModal from "../../hotplate-common/NewModal";
import { DropdownMenu } from "../../hotplate-common/primitives/DropdownMenu";
import Changelog from "../Changelog";
import {
  ArchiveIcon,
  BarChartIcon,
  CalendarIcon,
  ChatBubbleIcon,
  ChevronDownIcon,
  CubeIcon,
  GearIcon,
  PersonIcon,
  QuestionMarkIcon,
  ReaderIcon,
  RocketIcon,
} from "@radix-ui/react-icons";
import { ConfirmLink, ConfirmNavLink } from "../Confirm";
import { Tooltip } from "../../hotplate-common/primitives/Tooltip";
import { Dialog } from "../../hotplate-common/primitives/Dialog";
import { Support } from "./Support";
import { useFirebaseConnection } from "../../hooks";
import { useAuth, usePortalUser } from "../../auth";

// #############################################################################
// NAV BUTTON
// #############################################################################

const ButtonContainer = styled("button", {
  all: "unset",
  position: "relative",
  display: "flex",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",

  "&:hover > div": {
    color: "$hotplate9",
    transition: "color 150ms ease-in-out",
  },
  "&::after": {
    content: "",
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: 2,
    backgroundColor: "transparent",
    transition: "background-color 150ms ease-in-out",
  },
  "&.active": {
    "& > div": {
      color: "$hotplate9",
    },
    "@desktop_sm": {
      "&::after": {
        backgroundColor: "$hotplate9",
      },
    },
  },
  variants: {
    disabled: {
      true: {
        pointerEvents: "none",
        opacity: 0.75,
        "& > div": {
          color: "$gray9",
        },
        "&:hover > div": {
          color: "$gray9",
        },
      },
    },
  },
});

const ButtonContent = styled("div", {
  all: "unset",
  display: "flex",
  flexDirection: "column",
  gap: 8,
  alignItems: "center",
  padx: 8,
  color: "$gray11",
  ff: "$avenir",
  fs: 13,
  "@desktop_sm": {
    padx: 16,
    flexDirection: "row",
    ff: "$arboria",
    fs: 16,
    fw: 500,
    textAlign: "center",
    "& > svg": {
      size: 18,
    },
  },
});

// #############################################################################
// ACCOUNT MENU
// #############################################################################

const Avatar = styled("div", {
  position: "relative",
  display: "flex",
  justifyContent: "center",
  borderRadius: "$pill",
  size: 32,
  backgroundColor: "$violet9",
  "& > div": {
    color: "$accent1",
    ff: "$arboria",
    fw: "$bold",
    fs: 16,
    lineHeight: "28px",
  },
  variants: {
    alert: {
      true: {
        "&::after": {
          content: "",
          position: "absolute",
          top: -1,
          right: -1,
          size: 10,
          borderRadius: "$pill",
          backgroundColor: "$hotplate9",
        },
      },
    },
  },
});

const MenuButton = styled("div", {
  color: "$accent11",
  display: "flex",
  alignItems: "center",
  padding: "0",
  borderRadius: "$pill",
  border: "1px solid transparent",
  transition: "all 150ms ease-in-out",
  "& > svg:last-child": {
    display: "none",
  },
  "&:hover": {
    backgroundColor: "$accent3",
    color: "$accent12",
  },
  "&:focus-visible": {
    outline: "2px solid $accent7",
    outlineOffset: "4px",
  },
  "@desktop_sm": {
    padding: "$xxs",
    "& > svg:last-child": {
      display: "flex",
      marginLeft: "8px",
    },
  },
});

const AccountMenu = ({ getPermission, showReferral, setShowChangelog, setShowSupport, alert }) => {
  const auth = useAuth();
  const user = usePortalUser();
  return (
    <DropdownMenu
      trigger={
        <MenuButton>
          <Avatar alert={alert}>
            <Box>{user.chefId ? user.chefId.charAt(0) : "_"}</Box>
          </Avatar>
          <ChevronDownIcon />
        </MenuButton>
      }
    >
      {getPermission("admin") && (
        <>
          <DropdownMenu.Item as={ConfirmLink} to="admin" text="Hotplate Admin" />
          <DropdownMenu.Separator />
        </>
      )}
      {(getPermission("accountSettings") ||
        getPermission("billing") ||
        getPermission("transactions")) && (
        <DropdownMenu.Group separator="bottom" label="Account">
          <Tooltip
            condition={!getPermission("transactions")}
            css={{ width: "100%" }}
            content="You do not have permission to view this page"
          >
            <DropdownMenu.Item
              data-disabled={getPermission("transactions") && null}
              as={ConfirmLink}
              to="transactions"
              text="Transactions"
              hint={<ArchiveIcon />}
            />
          </Tooltip>
          <Tooltip
            condition={!getPermission("accountSettings")}
            css={{ width: "100%" }}
            content="You do not have permission to view this page"
          >
            <DropdownMenu.Item
              data-disabled={getPermission("accountSettings") && null}
              as={ConfirmLink}
              to="settings"
              text="Settings"
              hint={<GearIcon />}
            />
          </Tooltip>
          <Tooltip
            condition={!getPermission("billing")}
            css={{ width: "100%" }}
            content="You do not have permission to view this page"
          >
            <DropdownMenu.Item
              data-disabled={getPermission("billing") && null}
              as={ConfirmLink}
              to="billing"
              text="Your Plan"
              hint={<CubeIcon />}
            />
          </Tooltip>
        </DropdownMenu.Group>
      )}
      <DropdownMenu.Group separator="bottom">
        <DropdownMenu.Item
          as={ConfirmLink}
          to="feedback"
          text="Feedback"
          hint={<ChatBubbleIcon />}
        />
        <DropdownMenu.Item
          text="Changelog"
          hint={<ReaderIcon />}
          unread={alert}
          onClick={() => {
            setShowChangelog(true);
          }}
        />
        <DropdownMenu.Item
          text="Support"
          hint={<QuestionMarkIcon />}
          onClick={() => {
            setShowSupport(true);
          }}
        />
        <DropdownMenu.Item
          text="Refer a Chef!"
          hint={<RocketIcon />}
          onClick={() => {
            showReferral();
          }}
        />
      </DropdownMenu.Group>
      <DropdownMenu.Item
        removeIndent
        text="Logout"
        onClick={() => {
          auth.logout();
        }}
      />
    </DropdownMenu>
  );
};

// #############################################################################
// NAVIGATION
// #############################################################################

const NavWrapper = styled("div", {
  position: "fixed",
  bottom: 0,
  zIndex: "$fixed",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "center",
  width: "100%",
  height: "calc($navHeight + env(safe-area-inset-bottom))",
  backgroundColor: "$white",
  borderTop: `1px solid $gray6`,
  hideOnPrint: true,
  "@desktop_sm": {
    top: 0,
    height: "$navHeight",
    borderBottom: `1px solid $gray6`,
  },
});

const NavContent = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: "$navHeight",
  width: "100%",
  maxWidth: 1440,
  padx: 0,
  "@desktop_sm": { padx: 32 },
});

const Section = styled("div", {
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  height: "100%",
  flex: "1 1 auto",
  "&:first-child": {
    display: "none",
  },
  "@desktop_sm": {
    justifyContent: "center",
    flexBasis: 0,
    flex: "1 1 0px",
    "&:first-child": {
      display: "flex",
      justifyContent: "flex-start",
    },
    "&:last-child": {
      justifyContent: "flex-end",
    },
  },
});

const NavButton = ({ to, disabled, icon, text, ...props }) => {
  return (
    <Tooltip
      css={{ height: "100%" }}
      condition={disabled}
      content="You do not have permission to access this page"
    >
      <ButtonContainer as={ConfirmNavLink} to={to} disabled={disabled} {...props}>
        <ButtonContent>
          {icon}
          <Flex>{text}</Flex>
        </ButtonContent>
      </ButtonContainer>
    </Tooltip>
  );
};

export const PortalNav = ({ showReferral, getPermission }) => {
  const user = usePortalUser();

  const [showChangelog, setShowChangelog] = useState(false);
  const [showSupport, setShowSupport] = useState(false);

  const { data: lastUpdatedTimestampDb, isLoading: lastUpdatedTimestampLoading } =
    useFirebaseConnection({
      ref: `/changelogs/lastUpdatedTimestamp`,
    });
  const lastUpdatedTimestamp = lastUpdatedTimestampLoading ? null : lastUpdatedTimestampDb || 1;

  const { data: lastSeenChangelogTimestampDb, isLoading: lastSeenChangelogTimestampLoading } =
    useFirebaseConnection(
      {
        ref: `/customersByPhone/${user.phoneNumber}/interactions/lastSeenChangelogTimestamp`,
      },
      { isEnabled: !!user.phoneNumber }
    );
  const lastSeenChangelogTimestamp = lastSeenChangelogTimestampLoading
    ? null
    : lastSeenChangelogTimestampDb || 1;

  const changelogUnread =
    lastSeenChangelogTimestamp &&
    lastUpdatedTimestamp &&
    lastSeenChangelogTimestamp < lastUpdatedTimestamp;

  return (
    <NavWrapper>
      <NavContent>
        {/* LOGO */}
        <Section>
          <Flex as={ConfirmLink} to="." css={{ width: 130, height: 24, alignSelf: "center" }}>
            <Img src="https://ucarecdn.com/1b5b5695-7926-45d6-bad9-a00055ed13fe/outline_logo.svg" />
          </Flex>
        </Section>
        {/* BUTTONS */}
        <Section css={{ flexBasis: "80%", "@desktop_sm": { gap: 48, flexBasis: 0 } }}>
          <NavButton
            disabled={!getPermission("orders")}
            to="orders"
            icon={<ReaderIcon />}
            text="Orders"
          />
          <NavButton
            disabled={!getPermission("events")}
            to="events"
            icon={<CalendarIcon />}
            text="Events"
          />
          <NavButton disabled={false} to="community" icon={<PersonIcon />} text="Community" />
          <NavButton
            disabled={!getPermission("transactions")}
            to="insights"
            icon={<BarChartIcon />}
            text="Insights"
          />
        </Section>
        {/* ACCOUNT */}
        <Section
          css={{
            justifyContent: "center",
            flexBasis: "20%",
            "@desktop_sm": { flexBasis: 0 },
          }}
        >
          <AccountMenu
            alert={changelogUnread}
            setShowChangelog={setShowChangelog}
            getPermission={getPermission}
            showReferral={showReferral}
            setShowSupport={setShowSupport}
          />
        </Section>
      </NavContent>
      <NewModal
        isOpen={showChangelog}
        headerless
        onRequestClose={() => {
          setShowChangelog(false);
        }}
      >
        <Changelog />
      </NewModal>
      <Dialog
        isOpen={showSupport}
        onOpenChange={() => setShowSupport(!showSupport)}
        title="Support"
        description="Here is how you can reach us"
      >
        <Support />
      </Dialog>
    </NavWrapper>
  );
};
