// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./FilterDropdown.Tag.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      RFDBbmymn4: {
        none: {
          text: "Selected Value",
        },
      },
      VrbiMDF5Cr: {
        none: {
          icon: icons.close,
          useMask: true,
        },
      },
    },
  },
  {
    type: "enum",
    propName: "orderType",
    values: [
      {
        propValue: "delivery",
        layers: {
          RFDBbmymn4: {
            none: {
              text: "Delivery",
            },
          },
          VrbiMDF5Cr: {
            none: {
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "shipping",
        layers: {
          RFDBbmymn4: {
            none: {
              text: "Shipping",
            },
          },
          VrbiMDF5Cr: {
            none: {
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "wholesale",
        layers: {
          RFDBbmymn4: {
            none: {
              text: "Wholesale",
            },
          },
          VrbiMDF5Cr: {
            none: {
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "pickup",
        layers: {
          RFDBbmymn4: {
            none: {
              text: "Pickup",
            },
          },
          VrbiMDF5Cr: {
            none: {
              useMask: true,
            },
            hover: {
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "catering",
        layers: {
          RFDBbmymn4: {
            none: {
              text: "Catering",
            },
          },
          VrbiMDF5Cr: {
            none: {
              useMask: true,
            },
          },
        },
      },
      {
        propValue: "subscription",
        layers: {
          RFDBbmymn4: {
            none: {
              text: "Subscription",
            },
          },
        },
      },
    ],
  },
  {
    type: "boolean",
    propName: "date",
    layers: {
      VrbiMDF5Cr: {
        none: {
          useMask: true,
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "orderType",
    values: [
      {
        propValue: "delivery",
        layers: {},
      },
      {
        propValue: "shipping",
        layers: {},
      },
      {
        propValue: "wholesale",
        layers: {},
      },
      {
        propValue: "pickup",
        layers: {},
      },
      {
        propValue: "catering",
        layers: {},
      },
      {
        propValue: "subscription",
        layers: {},
      },
    ],
  },
  {
    type: "boolean",
    propName: "date",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "orderType",
    propValues: [
      "delivery",
      "shipping",
      "wholesale",
      "pickup",
      "catering",
      "subscription",
    ],
  },
  {
    type: "boolean",
    propName: "date",
  },
];

export const TagContext = createContext(null);

function Tag(_props) {
  const defaults = useContext(TagContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="5tL5gPJcYh"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "5tL5gPJcYh",
        scope: "LCNP92tq5Q",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_GaueG2oYzm"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_LCNP92tq5Q_GaueG2oYzm"
          }
          key={"GaueG2oYzm"}
          addSpacing={false}
          element={getStyle("GaueG2oYzm", "htmlElement")}
        >
          <TextPrimitive
            id={"Text_RFDBbmymn4"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_LCNP92tq5Q_RFDBbmymn4"
            }
            key={"RFDBbmymn4"}
            text={
              exists(props.tagText)
                ? props.tagText
                : getStyle("RFDBbmymn4", "text")
            }
            element={getStyle("RFDBbmymn4", "htmlElement")}
          />
          <SpacerPrimitive
            id={"Spacer_Ljigwb1Awd"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_LCNP92tq5Q_Ljigwb1Awd"
            }
            key={"Ljigwb1Awd"}
          />
          <IconPrimitive
            id={"Icon_VrbiMDF5Cr"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_LCNP92tq5Q_VrbiMDF5Cr"
            }
            key={"VrbiMDF5Cr"}
            useMask={getStyle("VrbiMDF5Cr", "useMask")}
            src={getStyle("VrbiMDF5Cr", "icon")}
          />
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

Tag.__variants = [
  {
    name: "orderType",
    type: "group",
    variants: [
      "delivery",
      "shipping",
      "wholesale",
      "pickup",
      "catering",
      "subscription",
    ],
  },
  {
    name: "date",
    type: "variant",
  },
];

export default Tag;
