// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./AddPhone.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import {
  default as AddPhoneButton,
  ButtonContext as AddPhoneButtonContext,
  default as CancelAddPhoneButton,
  ButtonContext as CancelAddPhoneButtonContext,
} from "../Primitives/Button";
import {
  default as PhoneInput,
  InputContext as PhoneInputContext,
  default as NameInput,
  InputContext as NameInputContext,
} from "../Primitives/Input";
import {
  default as PermissionsSelect,
  SelectContext as PermissionsSelectContext,
} from "../Primitives/Select";

const styles = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "open",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      "2DTddgpvgd": {
        none: {
          text: "Add User",
        },
      },
      UoNDJnrtnn: {
        none: {
          placeholder: "(800) 588-2300",
          label: "+1  🇺🇸",
        },
      },
      EG8LmqeG7U: {
        none: {
          text: "Cancel",
          kind: "quiet",
        },
      },
      TcnRNecZjo: {
        none: {
          placeholder: "Name",
          label: "Name",
        },
      },
      LLDMKYeawe: {},
    },
  },
  {
    type: "boolean",
    propName: "open",
    layers: {
      "2DTddgpvgd": {
        none: {
          text: "Save",
          kind: "secondary",
        },
      },
      UoNDJnrtnn: {},
      EG8LmqeG7U: {},
      TcnRNecZjo: {},
      LLDMKYeawe: {},
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {
          "2DTddgpvgd": {},
          UoNDJnrtnn: {},
          EG8LmqeG7U: {},
          TcnRNecZjo: {},
          LLDMKYeawe: {},
        },
      },
      {
        propValue: "small",
        layers: {
          "2DTddgpvgd": {},
          UoNDJnrtnn: {},
          EG8LmqeG7U: {},
          TcnRNecZjo: {},
          LLDMKYeawe: {},
        },
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "open",
  },
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small"],
  },
];

export const AddPhoneContext = createContext(null);

function AddPhone(_props) {
  const defaults = useContext(AddPhoneContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="M2f6kyJfur"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "M2f6kyJfur",
        scope: "A3mt8cMoXN",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"dropdown-container_8HDqCTmYrW"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_A3mt8cMoXN_8HDqCTmYrW"
            }
            key={"8HDqCTmYrW"}
            addSpacing={false}
            element={getStyle("8HDqCTmYrW", "htmlElement")}
          >
            {[
              props.NameInput === undefined ? (
                <NameInput
                  key={"TcnRNecZjo"}
                  {...getCompositeDefaultProps("TcnRNecZjo")}
                  id="NameInput_TcnRNecZjo"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_A3mt8cMoXN_TcnRNecZjo"
                />
              ) : (
                <NameInputContext.Provider
                  key="TcnRNecZjo-provider"
                  value={{
                    key: "TcnRNecZjo",
                    id: "NameInput_TcnRNecZjo",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_A3mt8cMoXN_TcnRNecZjo",
                    ...getCompositeDefaultProps("TcnRNecZjo"),
                  }}
                >
                  {props.NameInput}
                </NameInputContext.Provider>
              ),
              <SpacerPrimitive
                id={"Spacer_AvSL6hDht7"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_A3mt8cMoXN_AvSL6hDht7"
                }
                key={"AvSL6hDht7"}
              />,
              props.PhoneInput === undefined ? (
                <PhoneInput
                  key={"UoNDJnrtnn"}
                  {...getCompositeDefaultProps("UoNDJnrtnn")}
                  id="PhoneInput_UoNDJnrtnn"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_A3mt8cMoXN_UoNDJnrtnn"
                />
              ) : (
                <PhoneInputContext.Provider
                  key="UoNDJnrtnn-provider"
                  value={{
                    key: "UoNDJnrtnn",
                    id: "PhoneInput_UoNDJnrtnn",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_A3mt8cMoXN_UoNDJnrtnn",
                    ...getCompositeDefaultProps("UoNDJnrtnn"),
                  }}
                >
                  {props.PhoneInput}
                </PhoneInputContext.Provider>
              ),
              <SpacerPrimitive
                id={"Spacer_Kf5wqFWcLM"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_A3mt8cMoXN_Kf5wqFWcLM"
                }
                key={"Kf5wqFWcLM"}
              />,
              props.PermissionsSelect === undefined ? (
                <PermissionsSelect
                  key={"LLDMKYeawe"}
                  {...getCompositeDefaultProps("LLDMKYeawe")}
                  id="PermissionsSelect_LLDMKYeawe"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_A3mt8cMoXN_LLDMKYeawe"
                />
              ) : (
                <PermissionsSelectContext.Provider
                  key="LLDMKYeawe-provider"
                  value={{
                    key: "LLDMKYeawe",
                    id: "PermissionsSelect_LLDMKYeawe",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_A3mt8cMoXN_LLDMKYeawe",
                    ...getCompositeDefaultProps("LLDMKYeawe"),
                  }}
                >
                  {props.PermissionsSelect}
                </PermissionsSelectContext.Provider>
              ),
            ]}
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_LeWGeB2KhX"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_A3mt8cMoXN_LeWGeB2KhX"
            }
            key={"LeWGeB2KhX"}
          />
          <ContainerPrimitive
            id={"cancel-container_2jbvJNM5NU"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_A3mt8cMoXN_2jbvJNM5NU"
            }
            key={"2jbvJNM5NU"}
            addSpacing={false}
            element={getStyle("2jbvJNM5NU", "htmlElement")}
          >
            {[
              props.AddPhoneButton === undefined ? (
                <AddPhoneButton
                  key={"2DTddgpvgd"}
                  {...getCompositeDefaultProps("2DTddgpvgd")}
                  id="AddPhoneButton_2DTddgpvgd"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_A3mt8cMoXN_2DTddgpvgd"
                />
              ) : (
                <AddPhoneButtonContext.Provider
                  key="2DTddgpvgd-provider"
                  value={{
                    key: "2DTddgpvgd",
                    id: "AddPhoneButton_2DTddgpvgd",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_A3mt8cMoXN_2DTddgpvgd",
                    ...getCompositeDefaultProps("2DTddgpvgd"),
                  }}
                >
                  {props.AddPhoneButton}
                </AddPhoneButtonContext.Provider>
              ),
              <SpacerPrimitive
                id={"Spacer_XvAFtWUeLA"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_A3mt8cMoXN_XvAFtWUeLA"
                }
                key={"XvAFtWUeLA"}
              />,
              props.CancelAddPhoneButton === undefined ? (
                <CancelAddPhoneButton
                  key={"EG8LmqeG7U"}
                  {...getCompositeDefaultProps("EG8LmqeG7U")}
                  id="CancelAddPhoneButton_EG8LmqeG7U"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_A3mt8cMoXN_EG8LmqeG7U"
                />
              ) : (
                <CancelAddPhoneButtonContext.Provider
                  key="EG8LmqeG7U-provider"
                  value={{
                    key: "EG8LmqeG7U",
                    id: "CancelAddPhoneButton_EG8LmqeG7U",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_A3mt8cMoXN_EG8LmqeG7U",
                    ...getCompositeDefaultProps("EG8LmqeG7U"),
                  }}
                >
                  {props.CancelAddPhoneButton}
                </CancelAddPhoneButtonContext.Provider>
              ),
            ]}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

AddPhone.AddPhoneButton = AddPhoneButton;
AddPhone.PhoneInput = PhoneInput;
AddPhone.CancelAddPhoneButton = CancelAddPhoneButton;
AddPhone.NameInput = NameInput;
AddPhone.PermissionsSelect = PermissionsSelect;
AddPhone.__variants = [
  {
    name: "open",
    type: "variant",
  },
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small"],
  },
];

export default AddPhone;
