/* eslint-disable react/prop-types */
import React from "react";
import { colors, sizes } from "../visly";

function Divider({ spacing }) {
  const padding = sizes.spacing["padding" + spacing];
  return (
    <div
      style={{
        display: "flex",
        paddingTop: padding,
        paddingBottom: padding,
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "1px",
          backgroundColor: colors.gray100,
        }}
      ></div>
    </div>
  );
}

export default Divider;
