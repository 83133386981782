// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Error.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, TextPrimitive } from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      DHv755ELPN: {
        none: {
          text:
            "This is a long error message that is very verbose and goes way too much info to the user about what went wrong.",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const ErrorContext = createContext(null);

function Error(_props) {
  const defaults = useContext(ErrorContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="E9ahycnXBo"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "E9ahycnXBo",
        scope: "PLAdbCbiXy",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <TextPrimitive
          id={"Text_DHv755ELPN"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_PLAdbCbiXy_DHv755ELPN"
          }
          key={"DHv755ELPN"}
          text={
            exists(props.text) ? props.text : getStyle("DHv755ELPN", "text")
          }
          element={getStyle("DHv755ELPN", "htmlElement")}
        />
      )}
    </RootPrimitive>
  );
}

Error.__variants = [];

export default Error;
