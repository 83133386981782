// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./RefundQuantityInput.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { TextPrimitive, ContainerPrimitive } from "../_internal_primitives";
import { Root, TextFieldPrimitive } from "../_internal_text_field";
import * as icons from "../icons";
import {
  default as IncreaseQuantityButton,
  IconButtonContext as IncreaseQuantityButtonContext,
  default as DecreaseQuantityButton,
  IconButtonContext as DecreaseQuantityButtonContext,
} from "../Primitives/IconButton";

const styles = [
  {
    type: "default",
    layers: {
      input: {
        none: {
          "placeholder-text": "0",
        },
      },
      "27Jh7WhE1A": {
        none: {
          text: "/",
        },
      },
      QPe71Si4ZU: {
        none: {
          text: "?",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "invalid",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      "8HVkJSFBty": {
        none: {
          icon: icons.plus,
        },
      },
      HEwsxCTonT: {
        none: {
          icon: icons.minus,
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "invalid",
    layers: {
      "8HVkJSFBty": {},
      HEwsxCTonT: {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "invalid",
  },
];

export const RefundQuantityInputContext = createContext(null);

function RefundQuantityInput(_props) {
  const defaults = useContext(RefundQuantityInputContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <Root
      {...props}
      key="root"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "root",
        scope: "28PtJL3G9n",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => [
        props.DecreaseQuantityButton === undefined ? (
          <DecreaseQuantityButton
            key={"HEwsxCTonT"}
            {...getCompositeDefaultProps("HEwsxCTonT")}
            id="DecreaseQuantityButton_HEwsxCTonT"
            className="__visly_reset_7X7HTLRqyD __visly_scope_28PtJL3G9n_HEwsxCTonT"
          />
        ) : (
          <DecreaseQuantityButtonContext.Provider
            key="HEwsxCTonT-provider"
            value={{
              key: "HEwsxCTonT",
              id: "DecreaseQuantityButton_HEwsxCTonT",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_28PtJL3G9n_HEwsxCTonT",
              ...getCompositeDefaultProps("HEwsxCTonT"),
            }}
          >
            {props.DecreaseQuantityButton}
          </DecreaseQuantityButtonContext.Provider>
        ),
        <ContainerPrimitive
          id={"input-container_Umcqjj2LZT"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_28PtJL3G9n_Umcqjj2LZT"
          }
          key={"Umcqjj2LZT"}
          addSpacing={false}
          element={getStyle("Umcqjj2LZT", "htmlElement")}
        >
          {
            <TextFieldPrimitive
              id={"input_input"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_28PtJL3G9n_input"
              }
              key={"input"}
              placeholder={
                exists(props.placeholder)
                  ? props.placeholder
                  : getStyle("input", "placeholder-text")
              }
            />
          }
        </ContainerPrimitive>,
        <TextPrimitive
          id={"/_27Jh7WhE1A"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_28PtJL3G9n_27Jh7WhE1A"
          }
          key={"27Jh7WhE1A"}
          text={getStyle("27Jh7WhE1A", "text")}
          element={getStyle("27Jh7WhE1A", "htmlElement")}
        />,
        <TextPrimitive
          id={"totalQuantity_QPe71Si4ZU"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_28PtJL3G9n_QPe71Si4ZU"
          }
          key={"QPe71Si4ZU"}
          text={
            exists(props.totalQuantity)
              ? props.totalQuantity
              : getStyle("QPe71Si4ZU", "text")
          }
          element={getStyle("QPe71Si4ZU", "htmlElement")}
        />,
        props.IncreaseQuantityButton === undefined ? (
          <IncreaseQuantityButton
            key={"8HVkJSFBty"}
            {...getCompositeDefaultProps("8HVkJSFBty")}
            id="IncreaseQuantityButton_8HVkJSFBty"
            className="__visly_reset_7X7HTLRqyD __visly_scope_28PtJL3G9n_8HVkJSFBty"
          />
        ) : (
          <IncreaseQuantityButtonContext.Provider
            key="8HVkJSFBty-provider"
            value={{
              key: "8HVkJSFBty",
              id: "IncreaseQuantityButton_8HVkJSFBty",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_28PtJL3G9n_8HVkJSFBty",
              ...getCompositeDefaultProps("8HVkJSFBty"),
            }}
          >
            {props.IncreaseQuantityButton}
          </IncreaseQuantityButtonContext.Provider>
        ),
      ]}
    </Root>
  );
}

RefundQuantityInput.IncreaseQuantityButton = IncreaseQuantityButton;
RefundQuantityInput.DecreaseQuantityButton = DecreaseQuantityButton;
RefundQuantityInput.__variants = [
  {
    name: "invalid",
    type: "variant",
  },
];

export default RefundQuantityInput;
