// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./CustomerMenuItem.ChangeQuantity.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      Ki9vGeMEkG: {
        none: {
          text: "Limit 1 per customer",
        },
      },
      LEgurH9NxH: {
        none: {
          text: "1",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "showMaxPerCustomer",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "showMaxPerCustomer",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "showMaxPerCustomer",
  },
];

export const ChangeQuantityContext = createContext(null);

function ChangeQuantity(_props) {
  const defaults = useContext(ChangeQuantityContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="MJa5FknFyJ"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "MJa5FknFyJ",
        scope: "o56G7tysFN",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"change-quantity-container_3bwzgNkFnh"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_o56G7tysFN_3bwzgNkFnh"
            }
            key={"3bwzgNkFnh"}
            addSpacing={false}
            element={getStyle("3bwzgNkFnh", "htmlElement")}
          >
            {
              <ContainerPrimitive
                id={"button-container_CvYjydPKLn"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_o56G7tysFN_CvYjydPKLn"
                }
                key={"CvYjydPKLn"}
                addSpacing={false}
                element={getStyle("CvYjydPKLn", "htmlElement")}
              >
                <ContainerPrimitive
                  key={"2kWL7KyaW2"}
                  id={"Slot_2kWL7KyaW2"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_o56G7tysFN_2kWL7KyaW2"
                  }
                  addSpacing={false}
                >
                  {props.decrement}
                </ContainerPrimitive>
                <ContainerPrimitive
                  id={"Container_4GofH8ioL2"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_o56G7tysFN_4GofH8ioL2"
                  }
                  key={"4GofH8ioL2"}
                  addSpacing={false}
                  element={getStyle("4GofH8ioL2", "htmlElement")}
                >
                  {
                    <TextPrimitive
                      id={"quantity_LEgurH9NxH"}
                      className={
                        "__visly_reset_7X7HTLRqyD __visly_scope_o56G7tysFN_LEgurH9NxH"
                      }
                      key={"LEgurH9NxH"}
                      text={
                        exists(props.quantity)
                          ? props.quantity
                          : getStyle("LEgurH9NxH", "text")
                      }
                      element={getStyle("LEgurH9NxH", "htmlElement")}
                    />
                  }
                </ContainerPrimitive>
                <ContainerPrimitive
                  key={"VgFGKuS8ic"}
                  id={"Slot_VgFGKuS8ic"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_o56G7tysFN_VgFGKuS8ic"
                  }
                  addSpacing={false}
                >
                  {props.increment}
                </ContainerPrimitive>
              </ContainerPrimitive>
            }
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_JANuUB6VYz"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_o56G7tysFN_JANuUB6VYz"
            }
            key={"JANuUB6VYz"}
          />
          <TextPrimitive
            id={"maxPerCustomer_Ki9vGeMEkG"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_o56G7tysFN_Ki9vGeMEkG"
            }
            key={"Ki9vGeMEkG"}
            text={
              exists(props.maxPerCustomer)
                ? props.maxPerCustomer
                : getStyle("Ki9vGeMEkG", "text")
            }
            element={getStyle("Ki9vGeMEkG", "htmlElement")}
          />
        </>
      )}
    </RootPrimitive>
  );
}

ChangeQuantity.__variants = [
  {
    name: "showMaxPerCustomer",
    type: "variant",
  },
];

export default ChangeQuantity;
