// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "./fonts.css";
export const avenir16 = {
  fontFamily: "Avenir, sans-serif",
  fontSize: "16px",
  fontWeight: 500,
  fontStyle: "normal",
  lineHeight: "20px",
};
export const body = {
  fontFamily: "Avenir, sans-serif",
  fontSize: "14px",
  fontWeight: 400,
  fontStyle: "normal",
  lineHeight: "19px",
  letterSpacing: "0px",
};
export const bodyLarge = {
  fontFamily: "Avenir, sans-serif",
  fontSize: "16px",
  fontWeight: 400,
  fontStyle: "normal",
  lineHeight: "1",
};
export const button = {
  fontFamily: "Avenir, sans-serif",
  fontSize: "14px",
  fontWeight: 500,
  fontStyle: "normal",
  lineHeight: "19px",
};
export const buttonLarge = {
  fontFamily: "Avenir, sans-serif",
  fontSize: "16px",
  fontWeight: 500,
  fontStyle: "normal",
  lineHeight: "1",
};
export const footnote = {
  fontFamily: "Avenir, sans-serif",
  fontSize: "12px",
  fontWeight: 300,
  fontStyle: "normal",
  lineHeight: "16px",
  letterSpacing: "0px",
};
export const formLabel = {
  fontFamily: "Avenir, sans-serif",
  fontSize: "12px",
  fontWeight: 400,
  fontStyle: "normal",
  lineHeight: "12px",
};
export const h1 = {
  fontFamily: "Arboria, sans-serif",
  fontSize: "48px",
  fontWeight: 700,
  fontStyle: "normal",
  lineHeight: "1",
  letterSpacing: "-0.32px",
};
export const h2 = {
  fontFamily: "Arboria, sans-serif",
  fontSize: "32px",
  fontWeight: 500,
  fontStyle: "normal",
  lineHeight: "1",
  letterSpacing: "0px",
};
export const h3 = {
  fontFamily: "Arboria, sans-serif",
  fontSize: "24px",
  fontWeight: 500,
  fontStyle: "normal",
  lineHeight: "1",
};
export const h4 = {
  fontFamily: "Arboria, sans-serif",
  fontSize: "18px",
  fontWeight: 500,
  fontStyle: "normal",
  lineHeight: "1",
};
export const h5 = {
  fontFamily: "Arboria, sans-serif",
  fontSize: "16px",
  fontWeight: 500,
  fontStyle: "normal",
  lineHeight: "1",
  letterSpacing: "0px",
  textTransform: "none",
};
export const h6 = {
  fontFamily: "Avenir, sans-serif",
  fontSize: "16px",
  fontWeight: 500,
  fontStyle: "normal",
  lineHeight: "22px",
};
export const legal = {
  fontFamily: "Avenir, sans-serif",
  fontSize: "12px",
  fontWeight: 400,
  fontStyle: "normal",
  lineHeight: "1",
  letterSpacing: "0px",
};
export const noEvents = {
  fontFamily: "Avenir, sans-serif",
  fontSize: "16px",
  fontWeight: 300,
  fontStyle: "normal",
  lineHeight: "22px",
};
export const placeholder = {
  fontFamily: "Avenir, sans-serif",
  fontSize: "14px",
  fontWeight: 300,
  fontStyle: "normal",
  lineHeight: "19px",
};
export const quote = {
  fontFamily: "Avenir Next LT Pro, sans-serif",
  fontSize: "14px",
  fontWeight: 400,
  fontStyle: "italic",
  lineHeight: "19px",
};
export const tableMono = {
  fontFamily: "Anonymous Pro, sans-serif",
  fontSize: "14px",
  fontWeight: 700,
  fontStyle: "normal",
  lineHeight: "20px",
  letterSpacing: "0px",
};
export const tableText = {
  fontFamily: "Avenir, sans-serif",
  fontSize: "14px",
  fontWeight: 500,
  fontStyle: "normal",
  lineHeight: "20px",
};
export const variations = {
  fontFamily: "Avenir, sans-serif",
  fontSize: "16px",
  fontWeight: 500,
  fontStyle: "normal",
  lineHeight: "22px",
  textTransform: "uppercase",
};
