// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./DropdownMenu.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { IconPrimitive } from "../_internal_primitives";
import {
  SelectRoot,
  SelectOptionContainer,
  SelectButton,
} from "../_internal_select";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      "3uT4n4m8ST": {
        none: {
          icon: icons.moreVertical,
          useMask: true,
        },
        disabled: {
          useMask: true,
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "small",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "small",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "small",
  },
];

export const DropdownMenuContext = createContext(null);

function DropdownMenu(_props) {
  const defaults = useContext(DropdownMenuContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <SelectRoot
      {...props}
      key="select-root"
      internal={{
        styles,
        projectId: "7X7HTLRqyD",
        layerId: "select-root",
        scope: "JyYM3MsfU9",
        activeVariants,
      }}
      vislyChildren={(getStyle) => (
        <>
          <SelectButton
            key="select-button"
            id={"Button_select-button"}
            className="__visly_reset_7X7HTLRqyD __visly_scope_JyYM3MsfU9_select-button"
          >
            {
              <IconPrimitive
                id={"icon_3uT4n4m8ST"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_JyYM3MsfU9_3uT4n4m8ST"
                }
                key={"3uT4n4m8ST"}
                useMask={getStyle("3uT4n4m8ST", "useMask")}
                src={getStyle("3uT4n4m8ST", "icon")}
              />
            }
          </SelectButton>
          <SelectOptionContainer
            key="select-menu"
            id={"Menu_select-menu"}
            className="__visly_reset_7X7HTLRqyD __visly_scope_JyYM3MsfU9_select-menu"
            innerKey="select-menu"
            addSpacing={false}
            useButtonWidth={true}
          >
            {props.children}
          </SelectOptionContainer>
        </>
      )}
    >
      {props.children}
    </SelectRoot>
  );
}

DropdownMenu.__variants = [
  {
    name: "small",
    type: "variant",
  },
];

export default DropdownMenu;
