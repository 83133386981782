// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./UserPhoneCard.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";
import {
  default as RemoveUserButton,
  default as EditUserButton,
} from "../Primitives/IconButton";

const styles = [
  {
    type: "default",
    layers: {
      PNpRUawiiP: {
        none: {
          text: "+12345678900",
        },
      },
      uDhR2uy2Wj: {
        none: {
          text: "No Name",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "owner",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      PC5kZPBTgA: {
        none: {
          icon: icons.close,
        },
      },
      "78MrTnY6d3": {
        none: {
          icon: icons.edit,
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "owner",
    layers: {
      PC5kZPBTgA: {},
      "78MrTnY6d3": {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "owner",
  },
];

export const UserPhoneCardContext = createContext(null);

function UserPhoneCard(_props) {
  const defaults = useContext(UserPhoneCardContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="DfW6L2A1bq"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "DfW6L2A1bq",
        scope: "Pf5NqgvWtJ",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_UtZGLVdm2L"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_Pf5NqgvWtJ_UtZGLVdm2L"
          }
          key={"UtZGLVdm2L"}
          addSpacing={false}
          element={getStyle("UtZGLVdm2L", "htmlElement")}
        >
          <ContainerPrimitive
            id={"toprow_6ecJTXWtbV"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Pf5NqgvWtJ_6ecJTXWtbV"
            }
            key={"6ecJTXWtbV"}
            addSpacing={false}
            element={getStyle("6ecJTXWtbV", "htmlElement")}
          >
            <ContainerPrimitive
              id={"Container_TDqE2YuV3a"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Pf5NqgvWtJ_TDqE2YuV3a"
              }
              key={"TDqE2YuV3a"}
              addSpacing={false}
              element={getStyle("TDqE2YuV3a", "htmlElement")}
            >
              <TextPrimitive
                id={"name_uDhR2uy2Wj"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_Pf5NqgvWtJ_uDhR2uy2Wj"
                }
                key={"uDhR2uy2Wj"}
                text={
                  exists(props.name)
                    ? props.name
                    : getStyle("uDhR2uy2Wj", "text")
                }
                element={getStyle("uDhR2uy2Wj", "htmlElement")}
              />
              <SpacerPrimitive
                id={"Spacer_5JihHWnPCg"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_Pf5NqgvWtJ_5JihHWnPCg"
                }
                key={"5JihHWnPCg"}
              />
              <TextPrimitive
                id={"phone_PNpRUawiiP"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_Pf5NqgvWtJ_PNpRUawiiP"
                }
                key={"PNpRUawiiP"}
                text={
                  exists(props.phone)
                    ? props.phone
                    : getStyle("PNpRUawiiP", "text")
                }
                element={getStyle("PNpRUawiiP", "htmlElement")}
              />
            </ContainerPrimitive>
            <ContainerPrimitive
              key={"8jfCeKM1wm"}
              id={"Slot_8jfCeKM1wm"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_Pf5NqgvWtJ_8jfCeKM1wm"
              }
              addSpacing={false}
            >
              {props.buttons}
            </ContainerPrimitive>
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_BnV95rSoLz"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Pf5NqgvWtJ_BnV95rSoLz"
            }
            key={"BnV95rSoLz"}
          />
          <ContainerPrimitive
            key={"7CT1RSsfWB"}
            id={"Slot_7CT1RSsfWB"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Pf5NqgvWtJ_7CT1RSsfWB"
            }
            addSpacing={false}
          >
            {props.userRole}
          </ContainerPrimitive>
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

UserPhoneCard.RemoveUserButton = RemoveUserButton;
UserPhoneCard.EditUserButton = EditUserButton;
UserPhoneCard.__variants = [
  {
    name: "owner",
    type: "variant",
  },
];

export default UserPhoneCard;
