// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./ConfigSection.EmptyContainer.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      "4CSzkcizp2": {
        none: {
          text: "This section is empty, add (or drag) some items to it!",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export const EmptyContainerContext = createContext(null);

function EmptyContainer(_props) {
  const defaults = useContext(EmptyContainerContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="RGnNQydvJm"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "RGnNQydvJm",
        scope: "A63n7pW5bA",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"dotted-container_D69smoDdAk"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_A63n7pW5bA_D69smoDdAk"
          }
          key={"D69smoDdAk"}
          addSpacing={false}
          element={getStyle("D69smoDdAk", "htmlElement")}
        >
          {
            <TextPrimitive
              id={"Text_4CSzkcizp2"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_A63n7pW5bA_4CSzkcizp2"
              }
              key={"4CSzkcizp2"}
              text={getStyle("4CSzkcizp2", "text")}
              element={getStyle("4CSzkcizp2", "htmlElement")}
            />
          }
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

EmptyContainer.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export default EmptyContainer;
