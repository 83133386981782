// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./MoreEventsCarousel.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as CompactEvent } from "../Events/EventCard";

const styles = [
  {
    type: "default",
    layers: {
      "7GaMygwnLm": {
        none: {
          htmlElement: "header",
        },
      },
      R5KFBRMwbw: {
        none: {
          text: "More from Name",
        },
      },
      "4bxfK8YGnZ": {
        none: {
          htmlElement: "section",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
  {
    type: "boolean",
    propName: "dark",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      WLazvTFMm7: {
        none: {
          type: "compact",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {
          WLazvTFMm7: {},
        },
      },
      {
        propValue: "small",
        layers: {
          WLazvTFMm7: {},
        },
      },
      {
        propValue: "med",
        layers: {
          WLazvTFMm7: {},
        },
      },
      {
        propValue: "large",
        layers: {
          WLazvTFMm7: {},
        },
      },
      {
        propValue: "xlarge",
        layers: {
          WLazvTFMm7: {},
        },
      },
    ],
  },
  {
    type: "boolean",
    propName: "dark",
    layers: {
      WLazvTFMm7: {},
    },
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
  {
    type: "boolean",
    propName: "dark",
  },
];

export const MoreEventsCarouselContext = createContext(null);

function MoreEventsCarousel(_props) {
  const defaults = useContext(MoreEventsCarouselContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="JuzQ5p3es8"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "JuzQ5p3es8",
        scope: "CLtXReiEM8",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"title-button-container_7GaMygwnLm"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_CLtXReiEM8_7GaMygwnLm"
            }
            key={"7GaMygwnLm"}
            addSpacing={false}
            element={getStyle("7GaMygwnLm", "htmlElement")}
          >
            <TextPrimitive
              id={"Text_R5KFBRMwbw"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_CLtXReiEM8_R5KFBRMwbw"
              }
              key={"R5KFBRMwbw"}
              text={
                exists(props.text) ? props.text : getStyle("R5KFBRMwbw", "text")
              }
              element={getStyle("R5KFBRMwbw", "htmlElement")}
            />
            <SpacerPrimitive
              id={"Spacer_FmM75JuhUh"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_CLtXReiEM8_FmM75JuhUh"
              }
              key={"FmM75JuhUh"}
            />
            <ContainerPrimitive
              key={"3uSZ4nMJsy"}
              id={"Slot_3uSZ4nMJsy"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_CLtXReiEM8_3uSZ4nMJsy"
              }
              addSpacing={false}
            >
              {props.ViewAllButton}
            </ContainerPrimitive>
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"scrollbox_4bxfK8YGnZ"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_CLtXReiEM8_4bxfK8YGnZ"
            }
            key={"4bxfK8YGnZ"}
            addSpacing={false}
            element={getStyle("4bxfK8YGnZ", "htmlElement")}
          >
            {
              <ContainerPrimitive
                key={"8qwQ22QZjn"}
                id={"Slot_8qwQ22QZjn"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_CLtXReiEM8_8qwQ22QZjn"
                }
                addSpacing={true}
              >
                {props.children}
              </ContainerPrimitive>
            }
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

MoreEventsCarousel.CompactEvent = CompactEvent;
MoreEventsCarousel.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
  {
    name: "dark",
    type: "variant",
  },
];

export default MoreEventsCarousel;
