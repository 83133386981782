// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./SetLocationTime.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, ContainerPrimitive } from "../_internal_primitives";
import { default as LocationContainer } from "./SetLocationTime.LocationContainer";
import { default as DateContainer } from "./SetLocationTime.DateContainer";
import { default as TimeContainer } from "./SetLocationTime.TimeContainer";

const styles = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "step",
    values: [
      {
        propValue: "location",
        layers: {},
      },
      {
        propValue: "date",
        layers: {},
      },
      {
        propValue: "time",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "step",
    values: [
      {
        propValue: "location",
        layers: {},
      },
      {
        propValue: "date",
        layers: {},
      },
      {
        propValue: "time",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "step",
    propValues: ["location", "date", "time"],
  },
];

export const SetLocationTimeContext = createContext(null);

function SetLocationTime(_props) {
  const defaults = useContext(SetLocationTimeContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="APuzQmo1VP"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "APuzQmo1VP",
        scope: "MCo24eN5zY",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => [
        props.LocationContainer === undefined ? (
          <LocationContainer key={"RJTdFp61nF"} />
        ) : (
          props.LocationContainer
        ),
        <ContainerPrimitive
          id={"date-container_YYzJwTMKsT"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_MCo24eN5zY_YYzJwTMKsT"
          }
          key={"YYzJwTMKsT"}
          addSpacing={false}
          element={getStyle("YYzJwTMKsT", "htmlElement")}
        >
          {props.DateContainer === undefined ? (
            <DateContainer key={"4j8xvgeSwR"} />
          ) : (
            props.DateContainer
          )}
        </ContainerPrimitive>,
        <ContainerPrimitive
          id={"time-container_9o3CmhF4qf"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_MCo24eN5zY_9o3CmhF4qf"
          }
          key={"9o3CmhF4qf"}
          addSpacing={false}
          element={getStyle("9o3CmhF4qf", "htmlElement")}
        >
          {props.TimeContainer === undefined ? (
            <TimeContainer key={"AnfKWH7WSX"} />
          ) : (
            props.TimeContainer
          )}
        </ContainerPrimitive>,
      ]}
    </RootPrimitive>
  );
}

SetLocationTime.__variants = [
  {
    name: "step",
    type: "group",
    variants: ["location", "date", "time"],
  },
];

export default SetLocationTime;
