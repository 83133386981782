// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./ProgressBar.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, ProgressFillPrimitive } from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      fill: {
        none: {
          progress: 0.5,
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "secondary",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "secondary",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "secondary",
  },
];

export const ProgressBarContext = createContext(null);

function ProgressBar(_props) {
  const defaults = useContext(ProgressBarContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="root"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "root",
        scope: "Qq6ek3MuQE",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ProgressFillPrimitive
          id={"Fill_fill"}
          className={"__visly_reset_7X7HTLRqyD __visly_scope_Qq6ek3MuQE_fill"}
          key={"fill"}
          value={
            exists(props.value) ? props.value : getStyle("fill", "progress")
          }
        />
      )}
    </RootPrimitive>
  );
}

ProgressBar.__variants = [
  {
    name: "secondary",
    type: "variant",
  },
];

export default ProgressBar;
