import { chef_id } from "../analytics";

/**
 * things tracked in both Prep & Packing
 */

export function trackOrdersFiltered({ filter }, ...obj) {
  window.analytics.track("Orders Filtered", {
    chef_id,
    title: filter.title,
    conjunction: filter.selectedConjuction,
    isDateFilter: !!filter.isDateFilter,
    ...obj,
  });
}

export function trackOrdersSearched({ searchQuery }) {
  window.analytics.track("Orders Searched", {
    chef_id,
    query: searchQuery,
  });
}
/**
 * PREP SECTION ANALYTICS
 */

export function trackPrepSectionExpandToggled({ enabled }, ...obj) {
  window.analytics.track("Prep Section Expand Toggled", {
    chef_id,
    enabled: enabled,
    ...obj,
  });
}

/*
PACKING SECTION ANALYTICS
**/

/**
 * General
 */

export function trackPackingViewChanged({ packingView }, ...obj) {
  window.analytics.track("Packing View Changed", {
    chef_id,
    packing_view: packingView,
    ...obj,
  });
}

export function trackPackingSortChanged({ packingSort }, ...obj) {
  window.analytics.track("Packing Sort Changed", {
    chef_id,
    type: packingSort.type,
    direction: packingSort.direction,
    ...obj,
  });
}

export function trackCompletedOrdersDisplayChanged({ completedOrdersDisplay }, ...obj) {
  window.analytics.track("Completed Orders Display Changed", {
    chef_id,
    completed_orders_display: completedOrdersDisplay,
    ...obj,
  });
}

/*
Order Interaction
**/
export function trackReceiptViewed(
  { orderId, packingView, primarySort, completedOrdersDisplay },
  ...obj
) {
  window.analytics.track("Receipt Viewed", {
    chef_id,
    order_id: orderId,
    packing_view: packingView,
    sort: {
      primary_type: primarySort.type,
      primary_direction: primarySort.direction,
      completed_orders_display: completedOrdersDisplay,
    },
    ...obj,
  });
}

export function trackCustomerProfileClicked(source, obj) {
  window.analytics.track("Customer Profile Clicked", {
    chef_id,
    customer_id: obj.customerId,
    order_id: obj.orderId,
    source: source,
  });
}

export function trackOrderEditorOpened(
  { orderId, packingView, primarySort, completedOrdersDisplay },
  ...obj
) {
  window.analytics.track("Order Editor Opened", {
    chef_id,
    order_id: orderId,
    packing_view: packingView,
    sort: {
      primary_type: primarySort.type,
      primary_direction: primarySort.direction,
      completed_orders_display: completedOrdersDisplay,
    },
    ...obj,
  });
}

export function trackOrderPinned(
  { orderId, packingView, primarySort, completedOrdersDisplay, enabled },
  ...obj
) {
  window.analytics.track("Pin Order Toggled", {
    chef_id,
    order_id: orderId,
    enabled,
    packing_view: packingView,
    sort: {
      primary_type: primarySort.type,
      primary_direction: primarySort.direction,
      completed_orders_display: completedOrdersDisplay,
    },
    ...obj,
  });
}

/*
    Packing Card
**/
export function trackOrderMarkedComplete(
  { orderId, packingView, primarySort, completedOrdersDisplay },
  ...obj
) {
  window.analytics.track("Order Marked Complete", {
    chef_id,
    order_id: orderId,
    packing_view: packingView,
    sort: {
      primary_type: primarySort.type,
      primary_direction: primarySort.direction,
      completed_orders_display: completedOrdersDisplay,
    },
    ...obj,
  });
}

export function trackOrderMarkedIncomplete(
  { orderId, packingView, primarySort, completedOrdersDisplay },
  ...obj
) {
  window.analytics.track("Order Marked Incomplete", {
    chef_id,
    order_id: orderId,
    packing_view: packingView,
    sort: {
      primary_type: primarySort.type,
      primary_direction: primarySort.direction,
      completed_orders_display: completedOrdersDisplay,
    },
    ...obj,
  });
}

export function trackOrderMarkedPacked(
  { orderId, packingView, primarySort, completedOrdersDisplay },
  ...obj
) {
  window.analytics.track("Order Marked Packed", {
    chef_id,
    order_id: orderId,
    packing_view: packingView,
    sort: {
      primary_type: primarySort.type,
      primary_direction: primarySort.direction,
      completed_orders_display: completedOrdersDisplay,
    },
    ...obj,
  });
}

/**
 *
 * LineItem
 */

export function trackItemPacked(
  { orderId, packingView, primarySort, completedOrdersDisplay, packed },
  ...obj
) {
  if (packed) {
    window.analytics.track("Item Packed", {
      chef_id,
      order_id: orderId,
      packing_view: packingView,
      sort: {
        primary_type: primarySort.type,
        primary_direction: primarySort.direction,
        completed_orders_display: completedOrdersDisplay,
      },
      ...obj,
    });
  } else {
    window.analytics.track("Item Unpacked", {
      chef_id,
      order_id: orderId,
      packing_view: packingView,
      sort: {
        primary_type: primarySort.type,
        primary_direction: primarySort.direction,
        completed_orders_display: completedOrdersDisplay,
      },
      ...obj,
    });
  }
}

/**
 * Refund
 */

export function trackRefundIssued(
  { orderId, refundAmount, areItemsRestocked, areRemindersKilled },
  ...obj
) {
  window.analytics.track("Refund Issued", {
    chef_id,
    order_id: orderId,
    refund_amount: refundAmount,
    are_items_restocked: areItemsRestocked,
    are_reminders_killed: areRemindersKilled,
    ...obj,
  });
}

/**
 * Order Editing
 */

export function trackOrderEdited({ orderId, isCustomerNotified }, ...obj) {
  window.analytics.track("Order Edited", {
    chef_id,
    order_id: orderId,
    is_customer_notified: isCustomerNotified,
    ...obj,
  });
}
