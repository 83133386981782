import React from "react";
import { styled } from "../../stitches.config";
import { RatioBoxLoader, TitleLoader, DescriptionLoader } from "./CommonLoaders";
import { Box, CenterX, Column, Flex } from "../primitives/Containers";
import { EventCardLoader } from "./EventLoaders";
import {
  MenuContainer,
  MenuItemGrid,
  MenuSection,
} from "../../hotplate-storefront/components/StorefrontContainers";

const MenuItemLoaderContainer = styled("div", {
  display: "flex",
  flexFlow: "column nowrap",
  overflow: "hidden",
  backgroundColor: "$gray1",
  boxShadow: "$elevation5",
  minWidth: "100%",
  minHeight: "fit-content",
  borderRadius: "$lg",
});

const StorefrontTabsLoaderContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "$xl",
  height: 48,
  padx: "$lg",
  width: "100%",
  maxWidth: "$maxContentWidth",
  borderBottom: "2px solid $gray6",
});

export const StorefrontHeaderLoader = () => {
  return (
    <CenterX css={{ boxShadow: "$elevation5", alignItems: "center" }}>
      <Flex
        css={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: 72,
          padx: "$lg",
          maxWidth: "$maxContentWidth",
          "@desktop_sm": {
            height: 64,
          },
        }}
      >
        <TitleLoader css={{ width: 275, height: 40, marginBottom: 0 }} />
        <TitleLoader
          css={{
            borderRadius: "$pill",
            height: 32,
            width: 100,
            marginBottom: 0,
            display: "none",
            "@desktop_sm": { display: "flex" },
          }}
        />
      </Flex>
    </CenterX>
  );
};

export const SectionTitleLoader = () => {
  return <TitleLoader css={{ width: 100, marginTop: "$xl" }} />;
};

export const StorefrontTabsLoader = () => {
  return (
    <CenterX>
      <StorefrontTabsLoaderContainer>
        <DescriptionLoader css={{ width: 75 }} />
        <DescriptionLoader css={{ width: 150 }} />
        <DescriptionLoader css={{ width: 100 }} />
      </StorefrontTabsLoaderContainer>
    </CenterX>
  );
};

export const MenuItemLoader = () => {
  return (
    <MenuItemLoaderContainer>
      <RatioBoxLoader />
      <Box css={{ width: "100%", padding: "$md", flexDirection: "column" }}>
        <TitleLoader css={{ width: "50%" }} />
        <DescriptionLoader css={{ width: "100%" }} />
        <DescriptionLoader css={{ width: "60%" }} />
      </Box>
    </MenuItemLoaderContainer>
  );
};

const DropCardLoaderContainer = styled("div", {
  display: "flex",
  overflow: "hidden",
  backgroundColor: "$gray1",
  boxShadow: "$elevation5",
  minWidth: "100%",
  minHeight: "fit-content",
  borderRadius: "$lg",
});

export const DropCardLoader = () => {
  return (
    <DropCardLoaderContainer>
      <RatioBoxLoader width="40%" css={{ br: "$lg", m: "$sm", flexShrink: 0 }} />
      <Column css={{ padl: "$xs", width: "100%", pady: "$sm" }}>
        <TitleLoader css={{ width: "40%" }} />
        <DescriptionLoader css={{ width: "60%" }} />
        <DescriptionLoader css={{ width: "30%" }} />
      </Column>
    </DropCardLoaderContainer>
  );
};

export const StorefrontLoader = () => {
  return (
    <Flex css={{ flexDirection: "column", width: "100%", height: "100%" }}>
      <StorefrontHeaderLoader />
      <CenterX css={{ paddingTop: "$xl", paddingBottom: "$lg", padx: "$sm" }}>
        <EventCardLoader />
      </CenterX>
      <StorefrontTabsLoader />
      <CenterX>
        <MenuContainer>
          <MenuSection>
            <SectionTitleLoader />
            <MenuItemGrid>
              <MenuItemLoader />
              <MenuItemLoader />
              <MenuItemLoader />
              <MenuItemLoader />
            </MenuItemGrid>
          </MenuSection>
          <MenuSection>
            <SectionTitleLoader />
            <MenuItemGrid>
              <MenuItemLoader />
              <MenuItemLoader />
            </MenuItemGrid>
          </MenuSection>
          <MenuSection>
            <SectionTitleLoader />
            <MenuItemGrid>
              <MenuItemLoader />
              <MenuItemLoader />
              <MenuItemLoader />
              <MenuItemLoader />
              <MenuItemLoader />
            </MenuItemGrid>
          </MenuSection>
        </MenuContainer>
      </CenterX>
    </Flex>
  );
};
