import { getMenuItemOptionsString } from "@hotplate/utils-ts/helperFunctions";
import React, { useState } from "react";
import { Checkbox } from "../../../hotplate-common/primitives/Checkbox";
import { Flex, H2, H3 } from "../../../hotplate-common/primitives/Containers";
import { styled } from "../../../stitches.config";
import { usePrevious } from "../../../hooks";
import { trackItemPacked } from "../analytics";
import { useSelector } from "react-redux";

// creating ONE component for list and card view so code isnt duplicated...
// TODO add "card" variant

const LineItemContent = styled("div", {
  all: "unset",
  display: "flex",
  alignItems: "flex-start",
  minWidth: 0,
  backgroundColor: "transparent",
  color: "$accent12",
  transition: "background-color 125ms ease-in-out",
  ff: "$avenir",
  fw: "$semi_bold",
  lineHeight: 1.5,
  textAlign: "left",
  cursor: "pointer",
  borderRadius: "$sm",
  "& h2": {
    fw: "$bold",
    mx: 8,
    whiteSpace: "nowrap",
    "@media print": {
      ff: "Anonymous Pro, monospace",
    },
  },
  "& h3": {
    whiteSpace: "pre-line",
    wordBreak: "break-word",
    minWidth: 100,
    "@media print": {
      ff: "Anonymous Pro, monospace",
      lineHeight: 1.25,
    },
  },
  "&:hover": {
    backgroundColor: "$overlay3",
  },
  "&:active": {
    backgroundColor: "$overlay5",
  },
  "&:disabled": {
    cursor: "default",
    pointerEvents: "none",
    color: "$gray11",
  },
  variants: {
    view: {
      list: {
        padding: 4,
        fs: 14,
        "@media print": {
          fs: 12,
        },
      },
      card: {
        padding: "8px 4px",
        fs: 16,
        "& h3": {
          display: "flex",
        },
      },
    },
  },
});

const LineItemVariation = styled("p", {
  ff: "$avenir",
  fw: "$semi_bold",
  fs: 14,
  lineHeight: 1.5,
  color: "$hotplate10",
  marginTop: 4,
  textTransform: "uppercase",
});

const LineItemMod = styled("p", {
  ff: "$avenir",
  fw: "$semi_bold",
  fs: 14,
  lineHeight: 1.5,
  color: "$gray11",
  marginTop: 4,
});

// TODO make this a sharable component across both list and card views
// TODO right now in packing items are sorted, with a prop "indexMapping" and utilizing a state "selectedColumns"
// TODO I am weary to try to use that in both List and Card view right now without fullly understanding it, and making this reusable was not part of the spec, so I am not going to spend time on it for now.
// TODO if the indexMapping is resolved, this component can be shared between both views easily
export const LineItem = ({ item, itemIndex, order, functions, type, ...props }) => {
  const { markLineItemDone, getItemQuantity } = functions;
  let [lineItemDoneLocal, setLineItemDoneLocal] = useState();

  const prevItemQuantityDone = usePrevious(item.quantityDone);
  if (lineItemDoneLocal !== undefined && prevItemQuantityDone !== item.quantityDone) {
    setLineItemDoneLocal(undefined);
  }

  const packingView = useSelector((state) => state.orderManagement.packingView);
  const completedOrdersDisplay = useSelector(
    (state) => state.orderManagement.completedOrdersDisplay
  );
  const primarySort = useSelector((state) => state.orderManagement.sort.primary);
  const trackMetaData = {
    packingView,
    primarySort,
    completedOrdersDisplay,
  };

  const checked =
    lineItemDoneLocal !== undefined
      ? lineItemDoneLocal
      : item.quantityDone >= getItemQuantity(item) || !!order.packed; // coerce to boolean because if checked is undefined, the Radix checkbox will start using its own internal state

  return (
    <LineItemContent
      disabled={order.packed}
      type={type}
      onClick={async (e) => {
        e.stopPropagation();
        if (order.packed) return;
        setLineItemDoneLocal(!checked);
        try {
          await markLineItemDone(order, itemIndex, !checked);
        } catch (e) {
          setLineItemDoneLocal(undefined);
          throw e;
        } finally {
          trackItemPacked({
            ...trackMetaData,
            orderId: order.paymentIntentId,
            packed: !checked,
          });
        }
      }}
      {...props}
    >
      <Checkbox
        css={{ "@media print": { display: "none" } }}
        size={type === "list" ? "small" : "medium"}
        checked={checked}
        disabled={order.packed}
      />
      <H2>{getItemQuantity(item)}</H2>
      <Flex css={{ flexDirection: "column" }}>
        <H3>{item.title}</H3>
        {getMenuItemOptionsString(item, "variation") &&
          (type === "list" ? (
            <LineItemVariation>{getMenuItemOptionsString(item, "variation")}</LineItemVariation>
          ) : (
            getMenuItemOptionsString(item, "variation")
              .split(", ")
              .map((variation, i) => <LineItemVariation key={i}>{variation}</LineItemVariation>)
          ))}
        {getMenuItemOptionsString(item, "modification") && (
          <LineItemMod>{getMenuItemOptionsString(item, "modification")}</LineItemMod>
        )}
      </Flex>
    </LineItemContent>
  );
};
