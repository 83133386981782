// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./AvailablePickupTimes.TimesContainer.PickupTime.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      "6x6sa6WuDM": {
        none: {
          text: "1:00pm",
          htmlElement: "title",
        },
      },
      YM7EtHLYEY: {
        none: {
          text: "2 available",
          htmlElement: "title",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "rangeEnabled",
    layers: {
      "6x6sa6WuDM": {
        none: {
          text: "1:00pm - 4:00pm",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "unavailable",
    layers: {
      YM7EtHLYEY: {
        none: {
          text: "0 available",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "rangeEnabled",
    layers: {},
  },
  {
    type: "boolean",
    propName: "unavailable",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "rangeEnabled",
  },
  {
    type: "boolean",
    propName: "unavailable",
  },
];

export const PickupTimeContext = createContext(null);

function PickupTime(_props) {
  const defaults = useContext(PickupTimeContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="C32CSPLQLB"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "C32CSPLQLB",
        scope: "D4wz74LsjQ",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"content-container_CU3aqssjLN"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_D4wz74LsjQ_CU3aqssjLN"
          }
          key={"CU3aqssjLN"}
          addSpacing={false}
          element={getStyle("CU3aqssjLN", "htmlElement")}
        >
          <TextPrimitive
            id={"time_6x6sa6WuDM"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_D4wz74LsjQ_6x6sa6WuDM"
            }
            key={"6x6sa6WuDM"}
            text={
              exists(props.time) ? props.time : getStyle("6x6sa6WuDM", "text")
            }
            element={getStyle("6x6sa6WuDM", "htmlElement")}
          />
          <TextPrimitive
            id={"availability_YM7EtHLYEY"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_D4wz74LsjQ_YM7EtHLYEY"
            }
            key={"YM7EtHLYEY"}
            text={
              exists(props.availability)
                ? props.availability
                : getStyle("YM7EtHLYEY", "text")
            }
            element={getStyle("YM7EtHLYEY", "htmlElement")}
          />
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

PickupTime.__variants = [
  {
    name: "rangeEnabled",
    type: "variant",
  },
  {
    name: "unavailable",
    type: "variant",
  },
];

export default PickupTime;
