// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./OptionCategoryCard.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";
import { default as OutOfStockBadge } from "../Events/Status";

const styles = [
  {
    type: "default",
    layers: {
      Mc6duCztxW: {
        none: {
          text: "Option 1, Option 2, Option 3, Option 4, Option 5",
        },
      },
      GtSkQ1FHFy: {
        none: {
          text: "Modification Name",
        },
      },
      "3G4dBo7YgX": {
        none: {
          icon: icons.chevronRight,
          useMask: true,
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "outOfStock",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      H83tqjcqHa: {
        none: {
          type: "outOfStock",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "outOfStock",
    layers: {
      H83tqjcqHa: {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "outOfStock",
  },
];

export const OptionCategoryCardContext = createContext(null);

function OptionCategoryCard(_props) {
  const defaults = useContext(OptionCategoryCardContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="MKdCrxU2iC"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "MKdCrxU2iC",
        scope: "VMu4gB9TD3",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_CGReLGbwQn"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_VMu4gB9TD3_CGReLGbwQn"
          }
          key={"CGReLGbwQn"}
          addSpacing={false}
          element={getStyle("CGReLGbwQn", "htmlElement")}
        >
          <ContainerPrimitive
            id={"title-options-container_9By8ebACnz"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_VMu4gB9TD3_9By8ebACnz"
            }
            key={"9By8ebACnz"}
            addSpacing={false}
            element={getStyle("9By8ebACnz", "htmlElement")}
          >
            <TextPrimitive
              id={"title_GtSkQ1FHFy"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_VMu4gB9TD3_GtSkQ1FHFy"
              }
              key={"GtSkQ1FHFy"}
              text={
                exists(props.title)
                  ? props.title
                  : getStyle("GtSkQ1FHFy", "text")
              }
              element={getStyle("GtSkQ1FHFy", "htmlElement")}
            />
            <SpacerPrimitive
              id={"Spacer_EUEX4QvANP"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_VMu4gB9TD3_EUEX4QvANP"
              }
              key={"EUEX4QvANP"}
            />
            <ContainerPrimitive
              id={"title-outofstock-container_9zHw91kx1d"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_VMu4gB9TD3_9zHw91kx1d"
              }
              key={"9zHw91kx1d"}
              addSpacing={false}
              element={getStyle("9zHw91kx1d", "htmlElement")}
            >
              <TextPrimitive
                id={"options_Mc6duCztxW"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_VMu4gB9TD3_Mc6duCztxW"
                }
                key={"Mc6duCztxW"}
                text={
                  exists(props.options)
                    ? props.options
                    : getStyle("Mc6duCztxW", "text")
                }
                element={getStyle("Mc6duCztxW", "htmlElement")}
              />
              <SpacerPrimitive
                id={"Spacer_Nd9WvZJqoR"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_VMu4gB9TD3_Nd9WvZJqoR"
                }
                key={"Nd9WvZJqoR"}
              />
              <ContainerPrimitive
                key={"4QQ9zJ4LXv"}
                id={"Slot_4QQ9zJ4LXv"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_VMu4gB9TD3_4QQ9zJ4LXv"
                }
                addSpacing={false}
              >
                {props.outOfStockBadge}
              </ContainerPrimitive>
            </ContainerPrimitive>
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"icon-wrapper_R1zfAXD4jX"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_VMu4gB9TD3_R1zfAXD4jX"
            }
            key={"R1zfAXD4jX"}
            addSpacing={false}
            element={getStyle("R1zfAXD4jX", "htmlElement")}
          >
            <SpacerPrimitive
              id={"Spacer_R448xHY8Tz"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_VMu4gB9TD3_R448xHY8Tz"
              }
              key={"R448xHY8Tz"}
            />
            <IconPrimitive
              id={"Icon_3G4dBo7YgX"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_VMu4gB9TD3_3G4dBo7YgX"
              }
              key={"3G4dBo7YgX"}
              useMask={getStyle("3G4dBo7YgX", "useMask")}
              src={getStyle("3G4dBo7YgX", "icon")}
            />
          </ContainerPrimitive>
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

OptionCategoryCard.OutOfStockBadge = OutOfStockBadge;
OptionCategoryCard.__variants = [
  {
    name: "outOfStock",
    type: "variant",
  },
];

export default OptionCategoryCard;
