// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./TextArea.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { ContainerPrimitive } from "../_internal_primitives";
import { TextAreaRoot } from "../_internal_textarea";
import { default as CharCount } from "./TextArea.CharCount";

const styles = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "small",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "small",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "small",
  },
];

export const TextAreaContext = createContext(null);

function TextArea(_props) {
  const defaults = useContext(TextAreaContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <TextAreaRoot
      {...props}
      key="textarea-root"
      internal={{
        styles,
        projectId: "7X7HTLRqyD",
        layerId: "textarea-root",
        scope: "E4LCBrJ6g5",
        activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_WknUpwHyTR"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_E4LCBrJ6g5_WknUpwHyTR"
          }
          key={"WknUpwHyTR"}
          addSpacing={false}
          element={getStyle("WknUpwHyTR", "htmlElement")}
        >
          {props.CharCount === undefined ? (
            <CharCount key={"PREnQnmoQR"} />
          ) : (
            props.CharCount
          )}
        </ContainerPrimitive>
      )}
    </TextAreaRoot>
  );
}

TextArea.__variants = [
  {
    name: "small",
    type: "variant",
  },
];

export default TextArea;
