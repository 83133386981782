export let chef_id;
export function setPortalAnalyticsChefId(chefId) {
  chef_id = chefId;
}

// Packing Section
export function trackOrderManuallyCreated() {
  window.analytics.track("Order Manually Created", {
    chef_id: chef_id,
  });
}

export function trackOrdersExported({ trackSource, isItemsAsColumns }) {
  window.analytics.track("Orders Exported", {
    chef_id: chef_id,
    source: trackSource,
    is_items_as_columns: isItemsAsColumns,
  });
}

// Referrals

export function trackReferralModalOpened() {
  window.analytics.track("Referral Modal Opened", {
    chef_id: chef_id,
  });
}

export function trackReferralLinkCopied() {
  window.analytics.track("Referral Link Copied", {
    chef_id: chef_id,
  });
}

export function trackShareReferralButtonClicked() {
  window.analytics.track("Share Referral Button Clicked", {
    chef_id: chef_id,
  });
}

export function trackReferralLinkSent(channel) {
  window.analytics.track("Referral Link Sent", {
    chef_id: chef_id,
    channel: channel,
  });
}

// Changelog

export function trackChangelogViewed(changelog_id) {
  window.analytics.track("Changelog Viewed", {
    chef_id: chef_id,
    changelog_id: changelog_id,
  });
}

export function trackChangelogNavigationClicked(type) {
  window.analytics.track("Changelog Navigation Clicked", {
    chef_id: chef_id,
    type: type,
  });
}

// TODO needs ref for this to work  on scroll
// export function trackChangelogRead(chef_id) {
//   window.analytics.track("Changelog Read", {
//     chef_id: chef_id,
//   });
// }

// Events
export function trackQuantityOnOptionsChanged() {
  window.analytics.track("Quantity On Options Changed", {
    chef_id: chef_id,
  });
}

export function trackOpenSendEmailRemindersModalClicked(event_id) {
  window.analytics.track("Open Send Email Reminders Modal Clicked", {
    chef_id: chef_id,
    event_id: event_id,
  });
}

export function trackSendEmailRemindersClicked(event_id) {
  window.analytics.track("Send Email Reminders Clicked", {
    chef_id: chef_id,
    event_id: event_id,
  });
}

// Account Settings

export function trackConnectToSquareClicked() {
  window.analytics.track("Connect To Square Clicked", {
    chef_id,
  });
}

// Misc

export function trackSurveyResponse(obj) {
  // { OBJECT KEYS:
  //   TRACK
  //   chef_id, survey_id, response, prompt
  // *  I think there should also be a group call here so we can easily reference their answers, but don't want to deal with understanding how groups are updated right now
  // }
  window.analytics.track("Filled Out Survey", {
    chef_id,
    survey_id: obj.survey_id,
    response: obj.response,
    prompt: obj.prompt,
  });
}

export function trackFeatureFeedbackOpened(feature) {
  window.analytics.track("Feature Feedback Opened", {
    chef_id,
    feature: feature,
  });
}

export function trackFeatureFeedbackSubmitted(feature) {
  window.analytics.track("Feature Feedback Submitted", {
    chef_id,
    feature: feature,
  });
}
