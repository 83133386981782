/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { getTimestampHoursMinutesAMPM } from "@hotplate/utils-ts/helperFunctions";
import { Input } from "../../visly/Primitives";
import * as chrono from "chrono-node";

export function TimeInput({ timestamp, onBlur, style, validationType }) {
  const [value, setValue] = useState(
    Number.isInteger(timestamp) ? getTimestampHoursMinutesAMPM(timestamp) : ""
  );

  useEffect(() => {
    setValue(getTimestampHoursMinutesAMPM(timestamp));
  }, [timestamp]);

  return (
    <Input
      style={style}
      validationType={validationType}
      value={value}
      onBlur={() => {
        let timeAMPM = chrono.parseDate(value, new Date());
        if (timeAMPM === null) setValue(getTimestampHoursMinutesAMPM(timestamp));
        else {
          timeAMPM = getTimestampHoursMinutesAMPM(timeAMPM.getTime());
          setValue(timeAMPM);
          onBlur(timeAMPM);
        }
      }}
      onChange={(val) => setValue(val)}
      placeholder="12:00pm"
    />
  );
}
