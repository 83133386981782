// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./EditOptionCategory.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import {
  default as Title,
  LabeledInputContext as TitleContext,
  default as RequiredOptions,
  LabeledInputContext as RequiredOptionsContext,
  default as MaximumOptions,
  LabeledInputContext as MaximumOptionsContext,
} from "../Events/LabeledInput";
import {
  default as ConfigHeader,
  ConfigHeaderContext,
} from "../Events/ConfigHeader";
import {
  default as SetVariationCheckbox,
  CheckboxContext as SetVariationCheckboxContext,
  default as SetInventoryCheckbox,
  CheckboxContext as SetInventoryCheckboxContext,
} from "../Primitives/Checkbox";
import { default as Status, StatusContext } from "../Events/Status";
import {
  default as AddOptionButton,
  ButtonContext as AddOptionButtonContext,
} from "../Primitives/Button";
import { default as OptionsContainer } from "./EditOptionCategory.OptionsContainer";

const styles = [
  {
    type: "default",
    layers: {
      AFTrBWf4jB: {
        none: {
          text: "Show {ModName} on Prep screen",
        },
      },
      WL5xcd4kpx: {
        none: {
          text: "Set Inventory for each option",
        },
      },
      "95BKDwvwr7": {
        none: {
          text: "Options",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      GhJQEFwbtB: {
        none: {
          label: "Option Group Name",
        },
      },
      Y51Rzn3bXD: {
        none: {
          title: "Option Group Settings",
          subtitle: " ",
        },
      },
      EyBcuZPKS6: {
        none: {
          label: "Required Options",
        },
      },
      U9nPSnSWqr: {
        none: {
          label: "Maximum Options",
        },
      },
      "433DSFqauU": {},
      WB2oNoqYxK: {},
      EphLEqL3Jw: {
        none: {
          text: "BETA",
          type: "complete",
        },
      },
      A3tHCmFcLh: {
        none: {
          text: "Add Option",
          rounded: true,
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {
          GhJQEFwbtB: {},
          Y51Rzn3bXD: {},
          EyBcuZPKS6: {},
          U9nPSnSWqr: {},
          "433DSFqauU": {},
          WB2oNoqYxK: {},
          EphLEqL3Jw: {},
          A3tHCmFcLh: {},
        },
      },
      {
        propValue: "small",
        layers: {
          GhJQEFwbtB: {},
          Y51Rzn3bXD: {},
          EyBcuZPKS6: {},
          U9nPSnSWqr: {},
          "433DSFqauU": {},
          WB2oNoqYxK: {},
          EphLEqL3Jw: {},
          A3tHCmFcLh: {},
        },
      },
      {
        propValue: "med",
        layers: {
          GhJQEFwbtB: {},
          Y51Rzn3bXD: {},
          EyBcuZPKS6: {},
          U9nPSnSWqr: {},
          "433DSFqauU": {},
          WB2oNoqYxK: {},
          EphLEqL3Jw: {},
          A3tHCmFcLh: {},
        },
      },
      {
        propValue: "large",
        layers: {
          GhJQEFwbtB: {},
          Y51Rzn3bXD: {},
          EyBcuZPKS6: {},
          U9nPSnSWqr: {},
          "433DSFqauU": {},
          WB2oNoqYxK: {},
          EphLEqL3Jw: {},
          A3tHCmFcLh: {},
        },
      },
      {
        propValue: "xlarge",
        layers: {
          GhJQEFwbtB: {},
          Y51Rzn3bXD: {},
          EyBcuZPKS6: {},
          U9nPSnSWqr: {},
          "433DSFqauU": {},
          WB2oNoqYxK: {},
          EphLEqL3Jw: {},
          A3tHCmFcLh: {},
        },
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export const EditOptionCategoryContext = createContext(null);

function EditOptionCategory(_props) {
  const defaults = useContext(EditOptionCategoryContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="ACTrDicoaC"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "ACTrDicoaC",
        scope: "MCvoGExqTp",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => [
        props.Title === undefined ? (
          <Title
            key={"GhJQEFwbtB"}
            {...getCompositeDefaultProps("GhJQEFwbtB")}
            id="Title_GhJQEFwbtB"
            className="__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_GhJQEFwbtB"
          />
        ) : (
          <TitleContext.Provider
            key="GhJQEFwbtB-provider"
            value={{
              key: "GhJQEFwbtB",
              id: "Title_GhJQEFwbtB",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_GhJQEFwbtB",
              ...getCompositeDefaultProps("GhJQEFwbtB"),
            }}
          >
            {props.Title}
          </TitleContext.Provider>
        ),
        <SpacerPrimitive
          id={"Spacer_BRRLx9EMB2"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_BRRLx9EMB2"
          }
          key={"BRRLx9EMB2"}
        />,
        <ContainerPrimitive
          id={"options-container_CGUpSfjUtF"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_CGUpSfjUtF"
          }
          key={"CGUpSfjUtF"}
          addSpacing={true}
          element={getStyle("CGUpSfjUtF", "htmlElement")}
        >
          {[
            <TextPrimitive
              id={"Text_95BKDwvwr7"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_95BKDwvwr7"
              }
              key={"95BKDwvwr7"}
              text={getStyle("95BKDwvwr7", "text")}
              element={getStyle("95BKDwvwr7", "htmlElement")}
            />,
            props.OptionsContainer === undefined ? (
              <OptionsContainer key={"RA58aAmNjR"} />
            ) : (
              props.OptionsContainer
            ),
            <ContainerPrimitive
              id={"setinventory-container_NeyLNHysvY"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_NeyLNHysvY"
              }
              key={"NeyLNHysvY"}
              addSpacing={false}
              element={getStyle("NeyLNHysvY", "htmlElement")}
            >
              {[
                props.SetInventoryCheckbox === undefined ? (
                  <SetInventoryCheckbox
                    key={"WB2oNoqYxK"}
                    {...getCompositeDefaultProps("WB2oNoqYxK")}
                    id="SetInventoryCheckbox_WB2oNoqYxK"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_WB2oNoqYxK"
                  />
                ) : (
                  <SetInventoryCheckboxContext.Provider
                    key="WB2oNoqYxK-provider"
                    value={{
                      key: "WB2oNoqYxK",
                      id: "SetInventoryCheckbox_WB2oNoqYxK",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_WB2oNoqYxK",
                      ...getCompositeDefaultProps("WB2oNoqYxK"),
                    }}
                  >
                    {props.SetInventoryCheckbox}
                  </SetInventoryCheckboxContext.Provider>
                ),
                <SpacerPrimitive
                  id={"Spacer_2TS7EVUxG4"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_2TS7EVUxG4"
                  }
                  key={"2TS7EVUxG4"}
                />,
                <TextPrimitive
                  id={"setInventoryText_WL5xcd4kpx"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_WL5xcd4kpx"
                  }
                  key={"WL5xcd4kpx"}
                  text={getStyle("WL5xcd4kpx", "text")}
                  element={getStyle("WL5xcd4kpx", "htmlElement")}
                />,
                <SpacerPrimitive
                  id={"Spacer_7PuU23YH6B"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_7PuU23YH6B"
                  }
                  key={"7PuU23YH6B"}
                />,
                <ContainerPrimitive
                  key={"HCETA385d2"}
                  id={"Slot_HCETA385d2"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_HCETA385d2"
                  }
                  addSpacing={false}
                >
                  {props.setInventoryTooltip}
                </ContainerPrimitive>,
                <SpacerPrimitive
                  id={"Spacer_M8d8fzJnwm"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_M8d8fzJnwm"
                  }
                  key={"M8d8fzJnwm"}
                />,
                props.Status === undefined ? (
                  <Status
                    key={"EphLEqL3Jw"}
                    {...getCompositeDefaultProps("EphLEqL3Jw")}
                    id="Status_EphLEqL3Jw"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_EphLEqL3Jw"
                  />
                ) : (
                  <StatusContext.Provider
                    key="EphLEqL3Jw-provider"
                    value={{
                      key: "EphLEqL3Jw",
                      id: "Status_EphLEqL3Jw",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_EphLEqL3Jw",
                      ...getCompositeDefaultProps("EphLEqL3Jw"),
                    }}
                  >
                    {props.Status}
                  </StatusContext.Provider>
                ),
              ]}
            </ContainerPrimitive>,
            props.AddOptionButton === undefined ? (
              <AddOptionButton
                key={"A3tHCmFcLh"}
                {...getCompositeDefaultProps("A3tHCmFcLh")}
                id="AddOptionButton_A3tHCmFcLh"
                className="__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_A3tHCmFcLh"
              />
            ) : (
              <AddOptionButtonContext.Provider
                key="A3tHCmFcLh-provider"
                value={{
                  key: "A3tHCmFcLh",
                  id: "AddOptionButton_A3tHCmFcLh",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_A3tHCmFcLh",
                  ...getCompositeDefaultProps("A3tHCmFcLh"),
                }}
              >
                {props.AddOptionButton}
              </AddOptionButtonContext.Provider>
            ),
          ]}
        </ContainerPrimitive>,
        <SpacerPrimitive
          id={"Spacer_DXymmx7Qjg"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_DXymmx7Qjg"
          }
          key={"DXymmx7Qjg"}
        />,
        <ContainerPrimitive
          id={"settings-container_CPc1dUxYat"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_CPc1dUxYat"
          }
          key={"CPc1dUxYat"}
          addSpacing={true}
          element={getStyle("CPc1dUxYat", "htmlElement")}
        >
          {[
            props.ConfigHeader === undefined ? (
              <ConfigHeader
                key={"Y51Rzn3bXD"}
                {...getCompositeDefaultProps("Y51Rzn3bXD")}
                id="ConfigHeader_Y51Rzn3bXD"
                className="__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_Y51Rzn3bXD"
              />
            ) : (
              <ConfigHeaderContext.Provider
                key="Y51Rzn3bXD-provider"
                value={{
                  key: "Y51Rzn3bXD",
                  id: "ConfigHeader_Y51Rzn3bXD",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_Y51Rzn3bXD",
                  ...getCompositeDefaultProps("Y51Rzn3bXD"),
                }}
              >
                {props.ConfigHeader}
              </ConfigHeaderContext.Provider>
            ),
            <ContainerPrimitive
              id={"requiredmaxoptions-container_48ya6krcSH"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_48ya6krcSH"
              }
              key={"48ya6krcSH"}
              addSpacing={false}
              element={getStyle("48ya6krcSH", "htmlElement")}
            >
              {[
                props.RequiredOptions === undefined ? (
                  <RequiredOptions
                    key={"EyBcuZPKS6"}
                    {...getCompositeDefaultProps("EyBcuZPKS6")}
                    id="RequiredOptions_EyBcuZPKS6"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_EyBcuZPKS6"
                  />
                ) : (
                  <RequiredOptionsContext.Provider
                    key="EyBcuZPKS6-provider"
                    value={{
                      key: "EyBcuZPKS6",
                      id: "RequiredOptions_EyBcuZPKS6",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_EyBcuZPKS6",
                      ...getCompositeDefaultProps("EyBcuZPKS6"),
                    }}
                  >
                    {props.RequiredOptions}
                  </RequiredOptionsContext.Provider>
                ),
                <SpacerPrimitive
                  id={"Spacer_WwvT6pbe9K"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_WwvT6pbe9K"
                  }
                  key={"WwvT6pbe9K"}
                />,
                props.MaximumOptions === undefined ? (
                  <MaximumOptions
                    key={"U9nPSnSWqr"}
                    {...getCompositeDefaultProps("U9nPSnSWqr")}
                    id="MaximumOptions_U9nPSnSWqr"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_U9nPSnSWqr"
                  />
                ) : (
                  <MaximumOptionsContext.Provider
                    key="U9nPSnSWqr-provider"
                    value={{
                      key: "U9nPSnSWqr",
                      id: "MaximumOptions_U9nPSnSWqr",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_U9nPSnSWqr",
                      ...getCompositeDefaultProps("U9nPSnSWqr"),
                    }}
                  >
                    {props.MaximumOptions}
                  </MaximumOptionsContext.Provider>
                ),
              ]}
            </ContainerPrimitive>,
            <ContainerPrimitive
              id={"setvar-container_GSSYBU5TX7"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_GSSYBU5TX7"
              }
              key={"GSSYBU5TX7"}
              addSpacing={false}
              element={getStyle("GSSYBU5TX7", "htmlElement")}
            >
              {[
                props.SetVariationCheckbox === undefined ? (
                  <SetVariationCheckbox
                    key={"433DSFqauU"}
                    {...getCompositeDefaultProps("433DSFqauU")}
                    id="SetVariationCheckbox_433DSFqauU"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_433DSFqauU"
                  />
                ) : (
                  <SetVariationCheckboxContext.Provider
                    key="433DSFqauU-provider"
                    value={{
                      key: "433DSFqauU",
                      id: "SetVariationCheckbox_433DSFqauU",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_433DSFqauU",
                      ...getCompositeDefaultProps("433DSFqauU"),
                    }}
                  >
                    {props.SetVariationCheckbox}
                  </SetVariationCheckboxContext.Provider>
                ),
                <SpacerPrimitive
                  id={"Spacer_Q6DhQRgh2W"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_Q6DhQRgh2W"
                  }
                  key={"Q6DhQRgh2W"}
                />,
                <TextPrimitive
                  id={"setVariationText_AFTrBWf4jB"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_AFTrBWf4jB"
                  }
                  key={"AFTrBWf4jB"}
                  text={
                    exists(props.setVariationText)
                      ? props.setVariationText
                      : getStyle("AFTrBWf4jB", "text")
                  }
                  element={getStyle("AFTrBWf4jB", "htmlElement")}
                />,
                <SpacerPrimitive
                  id={"Spacer_9UYckvdoYE"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_9UYckvdoYE"
                  }
                  key={"9UYckvdoYE"}
                />,
                <ContainerPrimitive
                  key={"Afd3bUY483"}
                  id={"Slot_Afd3bUY483"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_MCvoGExqTp_Afd3bUY483"
                  }
                  addSpacing={false}
                >
                  {props.setVariationTooltip}
                </ContainerPrimitive>,
              ]}
            </ContainerPrimitive>,
          ]}
        </ContainerPrimitive>,
      ]}
    </RootPrimitive>
  );
}

EditOptionCategory.Title = Title;
EditOptionCategory.ConfigHeader = ConfigHeader;
EditOptionCategory.RequiredOptions = RequiredOptions;
EditOptionCategory.MaximumOptions = MaximumOptions;
EditOptionCategory.SetVariationCheckbox = SetVariationCheckbox;
EditOptionCategory.SetInventoryCheckbox = SetInventoryCheckbox;
EditOptionCategory.Status = Status;
EditOptionCategory.AddOptionButton = AddOptionButton;
EditOptionCategory.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export default EditOptionCategory;
