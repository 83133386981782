import firebase from "firebase/app";
import { backendTsUrl } from "./hotplate-storefront/actions/types";

export class StatusCodeError extends Error {
  name;
  status;

  constructor(message, status) {
    super(message);
    this.name = "StatusCodeError";
    this.status = status;
  }
}

const headers = { "Content-Type": "application/json" };

export async function getAuthHeaders() {
  const currentUser = firebase.auth().currentUser;
  if (currentUser) {
    const token = await currentUser.getIdToken();
    return {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  } else {
    throw new Error("Cannot get authenticated headers when not logged in.");
  }
}

async function checkStatusAndGetResult(response) {
  if (response.status >= 500) {
    throw new StatusCodeError(`Unexpected status code ${response.status}`, response.status);
  }
  return { response, json: await response.json() };
}

/**
 * Throws if the response in the result has a status code other than 2XX.
 * @param {*} result
 * @returns result
 */
export function ensureOk(result) {
  const { response, json } = result;
  if (response.status < 200 || 300 <= response.status) {
    throw new StatusCodeError(
      json?.error || `Unexpected status code ${response.status}`,
      response.status
    );
  }
  return result;
}

/* BEGIN UNAUTHENTICATED REQUESTS */

export async function subscribeToChefRemindersMutation({ chefId, phone }) {
  if (!chefId || !phone) {
    throw new Error("Bad arguments to subscribeToChefRemindersMutation");
  }
  return fetch(backendTsUrl + "shop/subscribeToChefReminders", {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      chefId,
      phone,
    }),
  }).then(checkStatusAndGetResult);
}

export async function applyGiftOrDiscountCodeMutation({ cartId, chefId, code }) {
  if (
    typeof cartId !== "string" ||
    cartId === "" ||
    typeof chefId !== "string" ||
    chefId === "" ||
    typeof code !== "string" ||
    code === ""
  ) {
    throw new Error("Bad arguments to applyDiscountCodeMutation");
  }
  return fetch(backendTsUrl + "shop/applyGiftOrDiscountCode", {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      cartId,
      chefId,
      code,
    }),
  }).then(checkStatusAndGetResult);
}

export async function prepareToConfirmGeneralShopPaymentMutation({
  chefId,
  paymentIntentId,
  items,
  firstName,
  lastName,
  email,
  phone,
}) {
  return fetch(backendTsUrl + "shop/prepareToConfirmGeneralShopPayment", {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      paymentIntentId,
      items,
      chefId,
      firstName,
      lastName,
      email,
      phone,
    }),
  }).then(checkStatusAndGetResult);
}

export async function initializeGeneralShopPaymentIntentMutation({ items, chefId, phone }) {
  return fetch(backendTsUrl + "shop/initializeGeneralShopPaymentIntent", {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      items,
      chefId,
      phone,
    }),
  }).then(checkStatusAndGetResult);
}

export async function initializeCartPaymentIntentMutation({ cartId, isOnDemand, phone }) {
  if (!cartId) {
    throw new Error("Bad arguments to initializeCartPaymentIntentMutation");
  }
  return fetch(backendTsUrl + "shop/initializeCartPaymentIntent", {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      cartId,
      isOnDemand,
      phone,
    }),
  }).then(checkStatusAndGetResult);
}

export async function reserveTimeSlotMutation({ cartId, chefId, eventId, timeSlot, location }) {
  if (!cartId || !chefId || !eventId || !timeSlot || !location) {
    throw new Error("Bad arguments to reserveTimeSlotMutation");
  }

  return fetch(backendTsUrl + "shop/reserveTimeSlot", {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      cartId,
      chefId,
      eventId,
      timeSlot,
      location,
    }),
  }).then(checkStatusAndGetResult);
}

export async function prepareToConfirmPaymentMutation({
  cartId,
  checkoutInfo,
  totalApprovedByCustomer,
}) {
  if (!cartId || !checkoutInfo || !totalApprovedByCustomer) {
    throw new Error("Bad arguments to prepareToConfirmPaymentMutation");
  }

  return fetch(backendTsUrl + "shop/prepareToConfirmPayment", {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      cartId,
      checkoutInfo,
      totalApprovedByCustomer,
    }),
  }).then(checkStatusAndGetResult);
}

export async function saveUserInfoMutation({ userInfo }) {
  if (!userInfo) {
    throw new Error("Bad arguments to saveUserInfoMutation");
  }

  return fetch(backendTsUrl + "shop/saveUserInfo", {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      userInfo,
    }),
  }).then(checkStatusAndGetResult);
}

export async function addItemToCartMutation({
  cartId,
  chefId,
  eventId,
  menuItemId,
  fulfillmentType,
  quantity,
  notes,
  optionCategories,
}) {
  return fetch(backendTsUrl + "shop/addItemToCart", {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      cartId,
      chefId,
      eventId,
      menuItemId,
      fulfillmentType,
      quantity,
      notes,
      optionCategories,
    }),
  }).then(checkStatusAndGetResult);
}

export async function removeItemFromCartMutation({ cartId, cartItem }) {
  return fetch(backendTsUrl + "shop/removeItemFromCart", {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ cartId, cartItem }),
  }).then(checkStatusAndGetResult);
}

export async function expireCartMutation({ cartId, creationId }) {
  return fetch(backendTsUrl + "shop/expireCart", {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ cartId, creationId }),
  }).then(checkStatusAndGetResult);
}

export async function setMenuItemNotificationPhoneMutation({ chefId, menuItemId, phone, eventId }) {
  return fetch(backendTsUrl + "shop/setMenuItemNotificationPhone", {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ chefId, menuItemId, phone, eventId }),
  }).then(checkStatusAndGetResult);
}

export async function sendOTPMutation({ phoneNumber }) {
  return fetch(backendTsUrl + "sendOTP", {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ phoneNumber }),
  })
    .then(checkStatusAndGetResult)
    .then(ensureOk);
}

export async function loginPortalMutation({ loginAttempt, bypassPhone }) {
  return fetch(backendTsUrl + (bypassPhone ? "portal/bypassLogin" : "portal/login"), {
    method: "POST",
    headers: headers,
    body: JSON.stringify(
      bypassPhone
        ? {
            phone: bypassPhone,
          }
        : loginAttempt
    ),
  })
    .then(checkStatusAndGetResult)
    .then(ensureOk);
}

export async function loginCheckoutMutation({ loginAttempt, guestPhone }) {
  return fetch(backendTsUrl + "shop/login", {
    method: "POST",
    headers: headers,
    body: JSON.stringify(guestPhone ? { guestPhone: guestPhone } : { loginAttempt }),
  })
    .then(checkStatusAndGetResult)
    .then(ensureOk);
}

export async function postOnWallOfLoveMutation({
  chefId,
  message,
  phone,
  paymentIntentId,
  firstName,
  lastName,
}) {
  return fetch(backendTsUrl + "shop/postOnWallOfLove", {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      chefId: chefId,
      message: message,
      phone: phone,
      paymentIntentId: paymentIntentId,
      firstName: firstName,
      lastName: lastName,
    }),
  }).then(checkStatusAndGetResult);
}

/* BEGIN AUTHENTICATED REQUESTS */
