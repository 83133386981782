// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./AddEditMenuItem.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, ContainerPrimitive } from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const AddEditMenuItemContext = createContext(null);

function AddEditMenuItem(_props) {
  const defaults = useContext(AddEditMenuItemContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="RUo2Bxrgmc"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "RUo2Bxrgmc",
        scope: "F95HfSAgay",
        activeVariants: activeVariants,
      }}
    >
      <ContainerPrimitive
        key={"FkvEC8gkfh"}
        id={"Slot_FkvEC8gkfh"}
        className={
          "__visly_reset_7X7HTLRqyD __visly_scope_F95HfSAgay_FkvEC8gkfh"
        }
        addSpacing={false}
      >
        {props.eventDetails}
      </ContainerPrimitive>
      <ContainerPrimitive
        key={"W87wqMMieX"}
        id={"Slot_W87wqMMieX"}
        className={
          "__visly_reset_7X7HTLRqyD __visly_scope_F95HfSAgay_W87wqMMieX"
        }
        addSpacing={false}
      >
        {props.globalDetails}
      </ContainerPrimitive>
    </RootPrimitive>
  );
}

AddEditMenuItem.__variants = [];

export default AddEditMenuItem;
