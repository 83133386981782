// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Alert.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import {
  default as No,
  ButtonContext as NoContext,
  default as Yes,
  ButtonContext as YesContext,
} from "../Primitives/Button";

const styles = [
  {
    type: "default",
    layers: {
      HbuXsCVKVA: {
        none: {
          text: "Confirm?",
        },
      },
      SLAVjKWa7H: {
        none: {
          text: "This action cannot be undone.",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "kind",
    values: [
      {
        propValue: "primary",
        layers: {},
      },
      {
        propValue: "bad",
        layers: {},
      },
      {
        propValue: "good",
        layers: {},
      },
    ],
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large850",
        layers: {},
      },
      {
        propValue: "med450",
        layers: {},
      },
      {
        propValue: "customVariant",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      "6LXXPGbr5i": {
        none: {
          text: "Cancel",
          kind: "secondary",
        },
      },
      "42X9DaYUQZ": {
        none: {
          text: "OK",
          kind: "primary",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "kind",
    values: [
      {
        propValue: "primary",
        layers: {
          "6LXXPGbr5i": {},
          "42X9DaYUQZ": {},
        },
      },
      {
        propValue: "bad",
        layers: {
          "6LXXPGbr5i": {},
          "42X9DaYUQZ": {
            none: {
              text: "Confirm",
              kind: "warning",
            },
          },
        },
      },
      {
        propValue: "good",
        layers: {
          "6LXXPGbr5i": {},
          "42X9DaYUQZ": {
            none: {
              text: "Confirm",
              kind: "good",
            },
          },
        },
      },
    ],
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large850",
        layers: {
          "6LXXPGbr5i": {},
          "42X9DaYUQZ": {},
        },
      },
      {
        propValue: "med450",
        layers: {
          "6LXXPGbr5i": {},
          "42X9DaYUQZ": {},
        },
      },
      {
        propValue: "customVariant",
        layers: {
          "6LXXPGbr5i": {},
          "42X9DaYUQZ": {},
        },
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "kind",
    propValues: ["primary", "bad", "good"],
  },
  {
    type: "enum",
    propName: "size",
    propValues: ["large850", "med450", "customVariant"],
  },
];

export const AlertContext = createContext(null);

function Alert(_props) {
  const defaults = useContext(AlertContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="JzFdEzyiEv"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "JzFdEzyiEv",
        scope: "WyXVuTPm8o",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_SgMmdPRioy"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_WyXVuTPm8o_SgMmdPRioy"
          }
          key={"SgMmdPRioy"}
          addSpacing={false}
          element={getStyle("SgMmdPRioy", "htmlElement")}
        >
          <ContainerPrimitive
            id={"Container_JaApZatNwx"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_WyXVuTPm8o_JaApZatNwx"
            }
            key={"JaApZatNwx"}
            addSpacing={false}
            element={getStyle("JaApZatNwx", "htmlElement")}
          >
            <TextPrimitive
              id={"alertPrompt_HbuXsCVKVA"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_WyXVuTPm8o_HbuXsCVKVA"
              }
              key={"HbuXsCVKVA"}
              text={
                exists(props.alertPrompt)
                  ? props.alertPrompt
                  : getStyle("HbuXsCVKVA", "text")
              }
              element={getStyle("HbuXsCVKVA", "htmlElement")}
            />
            <SpacerPrimitive
              id={"Spacer_DExCrPy3K9"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_WyXVuTPm8o_DExCrPy3K9"
              }
              key={"DExCrPy3K9"}
            />
            <TextPrimitive
              id={"Text_SLAVjKWa7H"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_WyXVuTPm8o_SLAVjKWa7H"
              }
              key={"SLAVjKWa7H"}
              text={
                exists(props.alertText)
                  ? props.alertText
                  : getStyle("SLAVjKWa7H", "text")
              }
              element={getStyle("SLAVjKWa7H", "htmlElement")}
            />
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"Container_AeMDF6nCyA"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_WyXVuTPm8o_AeMDF6nCyA"
            }
            key={"AeMDF6nCyA"}
            addSpacing={false}
            element={getStyle("AeMDF6nCyA", "htmlElement")}
          >
            <ContainerPrimitive
              id={"Container_KrLpE5kdWv"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_WyXVuTPm8o_KrLpE5kdWv"
              }
              key={"KrLpE5kdWv"}
              addSpacing={false}
              element={getStyle("KrLpE5kdWv", "htmlElement")}
            >
              {props.No === undefined ? (
                <No
                  key={"6LXXPGbr5i"}
                  {...getCompositeDefaultProps("6LXXPGbr5i")}
                  id="No_6LXXPGbr5i"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_WyXVuTPm8o_6LXXPGbr5i"
                />
              ) : (
                <NoContext.Provider
                  key="6LXXPGbr5i-provider"
                  value={{
                    key: "6LXXPGbr5i",
                    id: "No_6LXXPGbr5i",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_WyXVuTPm8o_6LXXPGbr5i",
                    ...getCompositeDefaultProps("6LXXPGbr5i"),
                  }}
                >
                  {props.No}
                </NoContext.Provider>
              )}
            </ContainerPrimitive>
            <ContainerPrimitive
              id={"Container_SYcq9SiQT5"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_WyXVuTPm8o_SYcq9SiQT5"
              }
              key={"SYcq9SiQT5"}
              addSpacing={false}
              element={getStyle("SYcq9SiQT5", "htmlElement")}
            >
              {props.Yes === undefined ? (
                <Yes
                  key={"42X9DaYUQZ"}
                  {...getCompositeDefaultProps("42X9DaYUQZ")}
                  id="Yes_42X9DaYUQZ"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_WyXVuTPm8o_42X9DaYUQZ"
                />
              ) : (
                <YesContext.Provider
                  key="42X9DaYUQZ-provider"
                  value={{
                    key: "42X9DaYUQZ",
                    id: "Yes_42X9DaYUQZ",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_WyXVuTPm8o_42X9DaYUQZ",
                    ...getCompositeDefaultProps("42X9DaYUQZ"),
                  }}
                >
                  {props.Yes}
                </YesContext.Provider>
              )}
            </ContainerPrimitive>
          </ContainerPrimitive>
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

Alert.No = No;
Alert.Yes = Yes;
Alert.__variants = [
  {
    name: "kind",
    type: "group",
    variants: ["primary", "bad", "good"],
  },
  {
    name: "size",
    type: "group",
    variants: ["large850", "med450", "customVariant"],
  },
];

export default Alert;
