// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Tabs.Button.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { TextPrimitive, ContainerPrimitive } from "../_internal_primitives";
import { RadioGroupButtonRoot } from "../_internal_radio_group";

const styles = [
  {
    type: "default",
    layers: {
      "segmented-control-button-text": {
        none: {
          text: "Tab name",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large850",
        layers: {},
      },
      {
        propValue: "med450",
        layers: {},
      },
      {
        propValue: "small",
        layers: {
          "segmented-control-button-text": {
            none: {
              htmlElement: "span",
            },
          },
        },
      },
    ],
  },
  {
    type: "enum",
    propName: "chef",
    values: [
      {
        propValue: "maneatingplantla",
        layers: {},
      },
      {
        propValue: "littlebluehousesj",
        layers: {},
      },
      {
        propValue: "sandoitchi",
        layers: {},
      },
      {
        propValue: "ubeaddict",
        layers: {},
      },
      {
        propValue: "misa",
        layers: {},
      },
      {
        propValue: "frankgrizzly",
        layers: {},
      },
      {
        propValue: "badlarryburgerclub",
        layers: {},
      },
      {
        propValue: "comfypupla",
        layers: {},
      },
      {
        propValue: "twojewishgirls",
        layers: {},
      },
      {
        propValue: "calabama",
        layers: {},
      },
      {
        propValue: "dabaosingapore",
        layers: {},
      },
      {
        propValue: "happymediumsdeli",
        layers: {},
      },
      {
        propValue: "bakesumpastries",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "large850",
        layers: {},
      },
      {
        propValue: "med450",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
    ],
  },
  {
    type: "enum",
    propName: "chef",
    values: [
      {
        propValue: "maneatingplantla",
        layers: {},
      },
      {
        propValue: "littlebluehousesj",
        layers: {},
      },
      {
        propValue: "sandoitchi",
        layers: {},
      },
      {
        propValue: "ubeaddict",
        layers: {},
      },
      {
        propValue: "misa",
        layers: {},
      },
      {
        propValue: "frankgrizzly",
        layers: {},
      },
      {
        propValue: "badlarryburgerclub",
        layers: {},
      },
      {
        propValue: "comfypupla",
        layers: {},
      },
      {
        propValue: "twojewishgirls",
        layers: {},
      },
      {
        propValue: "calabama",
        layers: {},
      },
      {
        propValue: "dabaosingapore",
        layers: {},
      },
      {
        propValue: "happymediumsdeli",
        layers: {},
      },
      {
        propValue: "bakesumpastries",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "selected",
  },
  {
    type: "enum",
    propName: "size",
    propValues: ["large850", "med450", "small"],
  },
  {
    type: "enum",
    propName: "chef",
    propValues: [
      "maneatingplantla",
      "littlebluehousesj",
      "sandoitchi",
      "ubeaddict",
      "misa",
      "frankgrizzly",
      "badlarryburgerclub",
      "comfypupla",
      "twojewishgirls",
      "calabama",
      "dabaosingapore",
      "happymediumsdeli",
      "bakesumpastries",
    ],
  },
];

export const ButtonContext = createContext(null);

function Button(_props) {
  const defaults = useContext(ButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RadioGroupButtonRoot
      {...props}
      key="segmented-control-button-root"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "segmented-control-button-root",
        scope: "8qApQon9Xp",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_TKiZ3536PG"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_8qApQon9Xp_TKiZ3536PG"
          }
          key={"TKiZ3536PG"}
          addSpacing={false}
          element={getStyle("TKiZ3536PG", "htmlElement")}
        >
          {
            <ContainerPrimitive
              id={"Container_5v1gebAWUN"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_8qApQon9Xp_5v1gebAWUN"
              }
              key={"5v1gebAWUN"}
              addSpacing={false}
              element={getStyle("5v1gebAWUN", "htmlElement")}
            >
              <TextPrimitive
                id={"text_segmented-control-button-text"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_8qApQon9Xp_segmented-control-button-text"
                }
                key={"segmented-control-button-text"}
                text={
                  exists(props.text)
                    ? props.text
                    : getStyle("segmented-control-button-text", "text")
                }
                element={getStyle(
                  "segmented-control-button-text",
                  "htmlElement"
                )}
              />
              <ContainerPrimitive
                id={"Container_Hz4wammPBk"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_8qApQon9Xp_Hz4wammPBk"
                }
                key={"Hz4wammPBk"}
                addSpacing={false}
                element={getStyle("Hz4wammPBk", "htmlElement")}
              >
                {null}
              </ContainerPrimitive>
            </ContainerPrimitive>
          }
        </ContainerPrimitive>
      )}
    </RadioGroupButtonRoot>
  );
}

Button.__variants = [
  {
    name: "selected",
    type: "variant",
  },
  {
    name: "size",
    type: "group",
    variants: ["large850", "med450", "small"],
  },
  {
    name: "chef",
    type: "group",
    variants: [
      "maneatingplantla",
      "littlebluehousesj",
      "sandoitchi",
      "ubeaddict",
      "misa",
      "frankgrizzly",
      "badlarryburgerclub",
      "comfypupla",
      "twojewishgirls",
      "calabama",
      "dabaosingapore",
      "happymediumsdeli",
      "bakesumpastries",
    ],
  },
];

export default Button;
