// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./SetLocationTime.TimeContainer.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as TimeCard } from "../Checkout/CheckoutTimeCard";

const styles = [
  {
    type: "default",
    layers: {
      "8tiQzoxCDo": {
        none: {
          text: "Choose Time",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      "8MYvFboyGt": {},
    },
  },
];

const variantPropTypes = [];

export const TimeContainerContext = createContext(null);

function TimeContainer(_props) {
  const defaults = useContext(TimeContainerContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="RWaPCdv6Vd"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "RWaPCdv6Vd",
        scope: "5dgVNbTUtb",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <TextPrimitive
            id={"Text_8tiQzoxCDo"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_5dgVNbTUtb_8tiQzoxCDo"
            }
            key={"8tiQzoxCDo"}
            text={
              exists(props.text) ? props.text : getStyle("8tiQzoxCDo", "text")
            }
            element={getStyle("8tiQzoxCDo", "htmlElement")}
          />
          <SpacerPrimitive
            id={"Spacer_CZ5uEg82z7"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_5dgVNbTUtb_CZ5uEg82z7"
            }
            key={"CZ5uEg82z7"}
          />
          <ContainerPrimitive
            key={"DckSNMMH8m"}
            id={"Slot_DckSNMMH8m"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_5dgVNbTUtb_DckSNMMH8m"
            }
            addSpacing={true}
          >
            {props.children}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

TimeContainer.TimeCard = TimeCard;
TimeContainer.__variants = [];

export default TimeContainer;
