// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./AddEditMenuItem.EventDetails.ExpandGlobalDetails.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as HeaderButton } from "./AddEditMenuItem.EventDetails.ExpandGlobalDetails.HeaderButton";

const styles = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "expanded",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "expanded",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "expanded",
  },
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export const ExpandGlobalDetailsContext = createContext(null);

function ExpandGlobalDetails(_props) {
  const defaults = useContext(ExpandGlobalDetailsContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="T78reuzdRG"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "T78reuzdRG",
        scope: "WxgAwd4fqF",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => [
        props.HeaderButton === undefined ? (
          <HeaderButton key={"M5JnmBUQDa"} />
        ) : (
          props.HeaderButton
        ),
        <ContainerPrimitive
          id={"Container_FkV32EMsa6"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_WxgAwd4fqF_FkV32EMsa6"
          }
          key={"FkV32EMsa6"}
          addSpacing={false}
          element={getStyle("FkV32EMsa6", "htmlElement")}
        >
          <SpacerPrimitive
            id={"Spacer_6YsouKN8H1"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_WxgAwd4fqF_6YsouKN8H1"
            }
            key={"6YsouKN8H1"}
          />
          <ContainerPrimitive
            key={"HfhmetLt47"}
            id={"Slot_HfhmetLt47"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_WxgAwd4fqF_HfhmetLt47"
            }
            addSpacing={false}
          >
            {props.children}
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_5PaEwEBSSA"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_WxgAwd4fqF_5PaEwEBSSA"
            }
            key={"5PaEwEBSSA"}
          />
        </ContainerPrimitive>,
      ]}
    </RootPrimitive>
  );
}

ExpandGlobalDetails.__variants = [
  {
    name: "expanded",
    type: "variant",
  },
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export default ExpandGlobalDetails;
