import {
  getCustomButtonColorsFromChef,
  getFormattedPhone,
  getUnFormattedPhone,
} from "@hotplate/utils-ts/helperFunctions";
import { CheckIcon } from "@radix-ui/react-icons";
import { CSS } from "@stitches/react";
import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getChefStyles } from "../../hotplate-common/chefStyleFunctions";
import { Button } from "../../hotplate-common/primitives/Button";
import { Dialog } from "../../hotplate-common/primitives/Dialog";
import { Error } from "../../hotplate-common/primitives/Error";
import { Input } from "../../hotplate-common/primitives/Input";
import { ensureOk, subscribeToChefRemindersMutation } from "../../mutations";
import { trackReminderSignupCompleted } from "../analytics";
import { ChefStyles } from "./types";

export const ReminderDialog = ({
  title,
  description,
  trigger,
  triggerCSS,
  trackSource,
}: {
  title: string;
  description: string;
  trigger: JSX.Element;
  triggerCSS?: CSS;
  trackSource:
    | "header"
    | "checkout"
    | "drop_info_scheduled"
    | "drop_info_complete"
    | "chef_profile"
    | "no_events_placeholder"
    | "drop_card";
}) => {
  const { siteSettings } = useSelector(
    (state: { storefront: { siteSettings: any } }) => state.storefront
  ); // TODO: type state fully
  const chefStyles: ChefStyles = getChefStyles(siteSettings);

  const [phoneNumber, setPhoneNumber] = useState("");

  const subscribeToChefReminders = useMutation(subscribeToChefRemindersMutation, {
    retry: 2,
  });

  function onPhoneChanged(phone: string) {
    setPhoneNumber(getFormattedPhone(phone, false));
  }

  return (
    <Dialog
      trigger={trigger}
      triggerCss={triggerCSS}
      title={title}
      description={description}
      isOpen={undefined}
      onOpenChange={undefined}
      contentCss={undefined}
      defaultOpen={undefined}
    >
      <Input
        css={{ minW: 0 }}
        contentBefore={"+1 🇺🇸"}
        disabled={subscribeToChefReminders.isLoading}
        placeholder="Phone number"
        type={"tel"}
        inputMode={"tel"}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onPhoneChanged(e.target.value)}
        value={phoneNumber}
        showClear={undefined}
        contentAfter={undefined}
        maxLength={undefined}
        showCount={undefined}
        defaultValue={undefined}
        loading={undefined}
        status={undefined}
        size={undefined}
      />
      {subscribeToChefReminders.isError && (
        <Error css={{ mt: "$sm" }}>
          There was an error when we tried to sign you up, try again.
        </Error>
      )}
      <Button
        css={{ width: "100%", marginTop: "1rem" }}
        customButtonColorStyle={getCustomButtonColorsFromChef(chefStyles)}
        color="success"
        variant="tinted"
        disabled={phoneNumber.length < 14}
        onClick={async () => {
          ensureOk(
            await subscribeToChefReminders.mutateAsync({
              chefId: siteSettings.chefId,
              phone: "+1" + getUnFormattedPhone(phoneNumber),
            })
          );
          trackReminderSignupCompleted(trackSource, {
            chef_id: siteSettings.chefId,
            customer_id: "+1" + getUnFormattedPhone(phoneNumber),
          });
        }}
        size="large"
        loading={subscribeToChefReminders.isLoading}
      >
        {subscribeToChefReminders.isSuccess && <CheckIcon />}
        {subscribeToChefReminders.isSuccess ? "You're signed up!" : "Notify me!"}
      </Button>
    </Dialog>
  );
};
