// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./CreatePickupWindow.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, ContainerPrimitive } from "../_internal_primitives";
import {
  default as AddLocationButton,
  ButtonContext as AddLocationButtonContext,
} from "../Primitives/Button";
import { default as LocationsContainer } from "./CreatePickupWindow.LocationsContainer";

const styles = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      Tj6bRPoiiR: {
        none: {
          text: "Add Pickup Location",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {
          Tj6bRPoiiR: {},
        },
      },
      {
        propValue: "small",
        layers: {
          Tj6bRPoiiR: {},
        },
      },
      {
        propValue: "med",
        layers: {
          Tj6bRPoiiR: {},
        },
      },
      {
        propValue: "large",
        layers: {
          Tj6bRPoiiR: {},
        },
      },
      {
        propValue: "xlarge",
        layers: {
          Tj6bRPoiiR: {},
        },
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export const CreatePickupWindowContext = createContext(null);

function CreatePickupWindow(_props) {
  const defaults = useContext(CreatePickupWindowContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="VhPEmqSYDM"
      addSpacing={true}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "VhPEmqSYDM",
        scope: "8thaTUb4en",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => [
        <ContainerPrimitive
          key={"VtrM8p3f5W"}
          id={"Slot_VtrM8p3f5W"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_8thaTUb4en_VtrM8p3f5W"
          }
          addSpacing={false}
        >
          {props.datePicker}
        </ContainerPrimitive>,
        props.LocationsContainer === undefined ? (
          <LocationsContainer key={"M4qHBLUBZS"} />
        ) : (
          props.LocationsContainer
        ),
        props.AddLocationButton === undefined ? (
          <AddLocationButton
            key={"Tj6bRPoiiR"}
            {...getCompositeDefaultProps("Tj6bRPoiiR")}
            id="AddLocationButton_Tj6bRPoiiR"
            className="__visly_reset_7X7HTLRqyD __visly_scope_8thaTUb4en_Tj6bRPoiiR"
          />
        ) : (
          <AddLocationButtonContext.Provider
            key="Tj6bRPoiiR-provider"
            value={{
              key: "Tj6bRPoiiR",
              id: "AddLocationButton_Tj6bRPoiiR",
              className:
                "__visly_reset_7X7HTLRqyD __visly_scope_8thaTUb4en_Tj6bRPoiiR",
              ...getCompositeDefaultProps("Tj6bRPoiiR"),
            }}
          >
            {props.AddLocationButton}
          </AddLocationButtonContext.Provider>
        ),
      ]}
    </RootPrimitive>
  );
}

CreatePickupWindow.AddLocationButton = AddLocationButton;
CreatePickupWindow.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export default CreatePickupWindow;
