// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./AddEditMenuItem.EventDetails.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import {
  default as Inventory,
  LabeledInputContext as InventoryContext,
  default as MaxPerCustomer,
  LabeledInputContext as MaxPerCustomerContext,
  default as MaxPerSlotDay,
  LabeledInputContext as MaxPerSlotDayContext,
} from "../Events/LabeledInput";
import {
  default as EventSettingsHeader,
  ConfigHeaderContext as EventSettingsHeaderContext,
} from "../Events/ConfigHeader";
import { default as ExpandGlobalDetails } from "./AddEditMenuItem.EventDetails.ExpandGlobalDetails";

const styles = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      PuhdFPPihv: {
        none: {
          label: "Inventory",
        },
      },
      G3dhAYPStm: {
        none: {
          label: "Max per order",
        },
      },
      CSVM9raF5X: {
        none: {
          label: "Max per Pickup Time/Day",
        },
      },
      "8vjBay86vo": {
        none: {
          title: "Inventory & Limits",
          subtitle: "Control how this item is sold in this event",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {
          PuhdFPPihv: {},
          G3dhAYPStm: {},
          CSVM9raF5X: {},
          "8vjBay86vo": {},
        },
      },
      {
        propValue: "small",
        layers: {
          PuhdFPPihv: {},
          G3dhAYPStm: {},
          CSVM9raF5X: {},
          "8vjBay86vo": {},
        },
      },
      {
        propValue: "med",
        layers: {
          PuhdFPPihv: {},
          G3dhAYPStm: {},
          CSVM9raF5X: {},
          "8vjBay86vo": {},
        },
      },
      {
        propValue: "large",
        layers: {
          PuhdFPPihv: {},
          G3dhAYPStm: {},
          CSVM9raF5X: {},
          "8vjBay86vo": {},
        },
      },
      {
        propValue: "xlarge",
        layers: {
          PuhdFPPihv: {},
          G3dhAYPStm: {},
          CSVM9raF5X: {},
          "8vjBay86vo": {},
        },
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export const EventDetailsContext = createContext(null);

function EventDetails(_props) {
  const defaults = useContext(EventDetailsContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="SEXffWDjzn"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "SEXffWDjzn",
        scope: "3RF5zfETKr",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => [
        <ContainerPrimitive
          id={"main-content_EsxweKmuje"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_3RF5zfETKr_EsxweKmuje"
          }
          key={"EsxweKmuje"}
          addSpacing={false}
          element={getStyle("EsxweKmuje", "htmlElement")}
        >
          {[
            props.EventSettingsHeader === undefined ? (
              <EventSettingsHeader
                key={"8vjBay86vo"}
                {...getCompositeDefaultProps("8vjBay86vo")}
                id="EventSettingsHeader_8vjBay86vo"
                className="__visly_reset_7X7HTLRqyD __visly_scope_3RF5zfETKr_8vjBay86vo"
              />
            ) : (
              <EventSettingsHeaderContext.Provider
                key="8vjBay86vo-provider"
                value={{
                  key: "8vjBay86vo",
                  id: "EventSettingsHeader_8vjBay86vo",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_3RF5zfETKr_8vjBay86vo",
                  ...getCompositeDefaultProps("8vjBay86vo"),
                }}
              >
                {props.EventSettingsHeader}
              </EventSettingsHeaderContext.Provider>
            ),
            <SpacerPrimitive
              id={"24px_8efCTD92cd"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_3RF5zfETKr_8efCTD92cd"
              }
              key={"8efCTD92cd"}
            />,
            props.Inventory === undefined ? (
              <Inventory
                key={"PuhdFPPihv"}
                {...getCompositeDefaultProps("PuhdFPPihv")}
                id="Inventory_PuhdFPPihv"
                className="__visly_reset_7X7HTLRqyD __visly_scope_3RF5zfETKr_PuhdFPPihv"
              />
            ) : (
              <InventoryContext.Provider
                key="PuhdFPPihv-provider"
                value={{
                  key: "PuhdFPPihv",
                  id: "Inventory_PuhdFPPihv",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_3RF5zfETKr_PuhdFPPihv",
                  ...getCompositeDefaultProps("PuhdFPPihv"),
                }}
              >
                {props.Inventory}
              </InventoryContext.Provider>
            ),
            <SpacerPrimitive
              id={"32px_X1iRurN9UL"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_3RF5zfETKr_X1iRurN9UL"
              }
              key={"X1iRurN9UL"}
            />,
            props.MaxPerCustomer === undefined ? (
              <MaxPerCustomer
                key={"G3dhAYPStm"}
                {...getCompositeDefaultProps("G3dhAYPStm")}
                id="MaxPerCustomer_G3dhAYPStm"
                className="__visly_reset_7X7HTLRqyD __visly_scope_3RF5zfETKr_G3dhAYPStm"
              />
            ) : (
              <MaxPerCustomerContext.Provider
                key="G3dhAYPStm-provider"
                value={{
                  key: "G3dhAYPStm",
                  id: "MaxPerCustomer_G3dhAYPStm",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_3RF5zfETKr_G3dhAYPStm",
                  ...getCompositeDefaultProps("G3dhAYPStm"),
                }}
              >
                {props.MaxPerCustomer}
              </MaxPerCustomerContext.Provider>
            ),
            <SpacerPrimitive
              id={"32px_Mdy6VHNFnV"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_3RF5zfETKr_Mdy6VHNFnV"
              }
              key={"Mdy6VHNFnV"}
            />,
            props.MaxPerSlotDay === undefined ? (
              <MaxPerSlotDay
                key={"CSVM9raF5X"}
                {...getCompositeDefaultProps("CSVM9raF5X")}
                id="MaxPerSlotDay_CSVM9raF5X"
                className="__visly_reset_7X7HTLRqyD __visly_scope_3RF5zfETKr_CSVM9raF5X"
              />
            ) : (
              <MaxPerSlotDayContext.Provider
                key="CSVM9raF5X-provider"
                value={{
                  key: "CSVM9raF5X",
                  id: "MaxPerSlotDay_CSVM9raF5X",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_3RF5zfETKr_CSVM9raF5X",
                  ...getCompositeDefaultProps("CSVM9raF5X"),
                }}
              >
                {props.MaxPerSlotDay}
              </MaxPerSlotDayContext.Provider>
            ),
            <SpacerPrimitive
              id={"48px_2NYFSbfR9e"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_3RF5zfETKr_2NYFSbfR9e"
              }
              key={"2NYFSbfR9e"}
            />,
          ]}
        </ContainerPrimitive>,
        props.ExpandGlobalDetails === undefined ? (
          <ExpandGlobalDetails key={"GZP1KHLsQE"} />
        ) : (
          props.ExpandGlobalDetails
        ),
      ]}
    </RootPrimitive>
  );
}

EventDetails.Inventory = Inventory;
EventDetails.MaxPerCustomer = MaxPerCustomer;
EventDetails.MaxPerSlotDay = MaxPerSlotDay;
EventDetails.EventSettingsHeader = EventSettingsHeader;
EventDetails.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export default EventDetails;
