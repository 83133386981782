/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useBreakpoint } from "../visly";
import { ToolTipContainer, Tooltip } from "../visly/Primitives";
import "./css/ToolTip.css";

function ToolTipMaster({ text, placement, white }) {
  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);
  const [showToolTip, setShowToolTip] = useState(false);
  return (
    <Tooltip
      className="tooltip-master"
      delayMs="50"
      text={text}
      placement={placement === "center" ? "bottom" : placement}
    >
      {(ref) => (
        <ToolTipContainer
          white={white}
          placement={placement}
          className={showToolTip ? "tooltip visible" : "tooltip"} // adding the visible class so we can make it fade in/out at a later date
          text={text}
          innerRef={size === "xsmall" || size === "small" ? null : ref} // onHover only on desktop
          showToolTip={size !== "xsmall" && size !== "small" ? false : showToolTip} // preventing tooltip from being permanently visible on window resize from mobile -> desktop
          onClick={
            size !== "med" && size !== "large" && size !== "xlarge"
              ? () => {
                  // onclick only on mobile
                  setShowToolTip(!showToolTip);
                }
              : null
          }
        />
      )}
    </Tooltip>
  );
}

export default ToolTipMaster;
