import React from "react";
import { styled } from "../../stitches.config";
import * as RadixTabs from "@radix-ui/react-tabs";

const TabsPrimitive = styled(RadixTabs.Root, {
  display: "flex",
  width: "100%",
  br: "$sm",
  border: "1px solid $accent4",
  flexDirection: "column",
  boxShadow: `$elevation3`,
});

const ListPrimitive = styled(RadixTabs.List, {
  flexShrink: 0,
  maxWidth: "100%",
  overflow: "auto",
  display: "flex",

  borderBottom: `1px solid $gray6`,
});

const TabPrimitive = styled(RadixTabs.Trigger, {
  all: "unset",
  display: "flex",
  jfc: true,
  flex: 1,
  height: 48,
  alignItems: "center",
  justifyContent: "center",
  padx: "$lg",
  backgroundColor: "$white",
  color: "$gray11",
  cursor: "pointer",
  ff: "$avenir",
  fs: 14,
  fw: "$normal",
  whiteSpace: "nowrap",
  lineHeight: 1,
  userSelect: "none",
  "&:first-child": { brtl: "$sm" },
  "&:last-child": { brtr: "$sm" },
  "&:hover": { color: "$accent11" },
  '&[data-state="active"]': {
    color: "$accent11",
    fw: "$semi_bold",
    boxShadow: "inset 0 -1px 0 0 currentColor, 0 1px 0 0 currentColor",
  },
  "&:focus": { outline: "none" },
});

const PagePrimitive = styled(RadixTabs.Content, {
  flexGrow: 1,
  backgroundColor: "$white",
  padding: "$lg",
  borderBottomLeftRadius: "$sm",
  borderBottomRightRadius: "$sm",
  outline: "none",
  "&:focus": { outline: "none" },
});

// Exports

export const Tabs = ({
  asChild,
  defaultValue,
  value,
  onValueChange,
  orientation,
  activationMode,
  children,
  ...props
}) => {
  return (
    <TabsPrimitive
      asChild={asChild}
      defaultValue={defaultValue}
      value={value}
      onValueChange={onValueChange}
      orientation={orientation || "horizontal"}
      activationMode={activationMode || "automatic"}
      {...props}
    >
      {children}
    </TabsPrimitive>
  );
};

Tabs.List = ListPrimitive;
Tabs.Tab = TabPrimitive;
Tabs.Page = PagePrimitive;
