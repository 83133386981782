import {
  SET_TOAST_POPUP_INFO,
  PREPARE_QUEUE_EVENT_REMINDERS_START,
  PREPARE_QUEUE_EVENT_REMINDERS_SUCCESS,
  PREPARE_QUEUE_EVENT_REMINDERS_FAILURE,
  backendUrl,
} from "./types";
import fetchRetry from "./FetchRetry";

export const setToastPopupInfo = (toastPopupInfo) => {
  return (dispatch) => {
    dispatch({
      type: SET_TOAST_POPUP_INFO,
      payload: toastPopupInfo,
    });
  };
};

export const prepareQueueEventReminders = (
  chefId,
  eventId,
  eventDropTime,
  restaurantName,
  bypassCall
) => {
  return (dispatch) => {
    dispatch({ type: PREPARE_QUEUE_EVENT_REMINDERS_START });
    if (bypassCall) {
      dispatch({ type: PREPARE_QUEUE_EVENT_REMINDERS_SUCCESS });
      return () => {
        // do nothing
      };
    }
    return fetchRetry(backendUrl + "prepareQueueEventReminders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chefId,
        eventId,
        eventDropTime,
        restaurantName,
      }),
    })
      .then(() => {
        dispatch({ type: PREPARE_QUEUE_EVENT_REMINDERS_SUCCESS });
      })
      .catch((exception) => {
        console.log(exception);
        dispatch({
          type: PREPARE_QUEUE_EVENT_REMINDERS_FAILURE,
          payload: "SERVER_ERROR",
        });
      });
  };
};
