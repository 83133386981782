/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";
import AddEditModal from "../../../components/AddEditModal";
import DayPicker from "../../../components/DayPicker";
import ImageUploader from "../../../components/ImageUploader";
import Modal from "../../../../hotplate-common/Modal";
import AddressInput from "../../../../hotplate-common/AddressInput";
import ToolTip from "../../../../hotplate-common/ToolTip";
import SmartTextArea from "../../../../hotplate-common/SmartTextArea";
import {
  getSortedLocations,
  getTimestampDayOfWeek,
  getAddressString,
} from "@hotplate/utils-ts/helperFunctions";
import { useBreakpoint } from "../../../../visly";
import {
  LocationBadge,
  ObjectSelectionModal,
  CreatePickupWindow,
  LocationCard,
  AddEditLocation,
} from "../../../../visly/Events";
import { Input } from "../../../../visly/Primitives";

import { trackLocationCreated, trackLocationUpdated } from "../analytics";
import { OverwritingObject, mergeDiff, removeNulls, useDetectDeletion } from "../../../../hooks";
import { setToastPopupInfo } from "../../../../hotplate-storefront/actions";
import { Flex, P } from "../../../../hotplate-common/primitives/Containers";
import { styled } from "../../../../stitches.config";
import { shouldRetry, trpc } from "../../../../trpc";

function createNewLocation() {
  return {
    id: uuid(),
    title: "",
    arrivalInstructions: "",
    addressDict: null,
    lat: null,
    lng: null,
    displayFullAddress: true,
    image:
      "https://ucarecdn.com/b4c76d91-9145-4b7c-8258-3b6dc641a0e0/linesquarepngpngimage466144.png",
  };
}

function AddEditLocationModal({
  saveLocationLoading,
  deleteLocationLoading,
  isNewLocation,
  initialLocationDiff,
  location,
  onRequestSave,
  onRequestDelete,
  onRequestClose,
}) {
  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);
  const [optionSelectModalState, setOptionSelectModalState] = useState(null);
  const [locationDiff, setLocationDiff] = useState(initialLocationDiff || {});
  const mergedLocation = removeNulls(mergeDiff(location, locationDiff));

  function toggleLocationDisplayFullAddress() {
    setLocationDiff((prevLocationDiff) => {
      return {
        ...prevLocationDiff,
        displayFullAddress: !mergedLocation.displayFullAddress,
      };
    });
  }

  function setLocationArrivalInstructions(value) {
    setLocationDiff((prevLocationDiff) => {
      return {
        ...prevLocationDiff,
        arrivalInstructions: value,
      };
    });
  }

  function setLocationTitle(value) {
    setLocationDiff((prevLocationDiff) => {
      return {
        ...prevLocationDiff,
        title: value,
      };
    });
  }

  function setLocationLatLng(coords) {
    setLocationDiff((prevLocationDiff) => {
      return {
        ...prevLocationDiff,
        lat: coords.lat,
        lng: coords.lng,
      };
    });
  }

  function setLocationTimeZone(timeZoneId) {
    setLocationDiff((prevLocationDiff) => {
      return {
        ...prevLocationDiff,
        timeZone: timeZoneId,
      };
    });
  }

  function setLocationAddressDict(addressDict) {
    const unit =
      mergedLocation.addressDict &&
      mergedLocation.addressDict.constructor === Object &&
      typeof mergedLocation.addressDict.unit === "string" &&
      mergedLocation.addressDict.unit !== ""
        ? mergedLocation.addressDict.unit
        : addressDict.unit;
    setLocationDiff((prevLocationDiff) => {
      return {
        ...prevLocationDiff,
        addressDict: {
          ...prevLocationDiff.addressDict,
          ...addressDict,
          unit: unit,
        },
      };
    });
  }

  function setLocationAddressUnit(unit) {
    setLocationDiff((prevLocationDiff) => {
      return {
        ...prevLocationDiff,
        addressDict: {
          ...prevLocationDiff.addressDict,
          unit: unit,
        },
      };
    });
  }

  function setLocationImage(image) {
    setLocationDiff((prevLocationDiff) => {
      return {
        ...prevLocationDiff,
        image: image,
      };
    });
  }

  function onFileLoaded(fileInfo) {
    if (!fileInfo) {
      console.error("IMG UPLOAD ERR");
      return;
    }
    setLocationImage(fileInfo.cdnUrl);
  }

  return (
    <Modal
      closeModal={onRequestClose}
      navText="Locations"
      optionSelectModalState={optionSelectModalState}
    >
      <AddEditModal
        cancelButtonOnClick={onRequestClose}
        saveButtonOnClick={() => {
          onRequestSave(locationDiff);
          trackLocationUpdated({ locationDiff });
        }}
        saveButtonLoading={saveLocationLoading}
        deleteButtonOnClick={onRequestDelete}
        deleteMessage="This will delete this location from your locations list."
        deleteButtonLoading={deleteLocationLoading}
        saveDisabled={mergedLocation.title === "" || !mergedLocation.addressDict}
        title={isNewLocation ? "Create Location" : "Edit Location"}
        navText="Locations"
        editing={!isNewLocation}
        setOptionSelectModalState={(message, optionSelectButtons) => {
          setOptionSelectModalState({
            message: message,
            optionSelectButtons: optionSelectButtons,
            closeOptionSelectModal: () => {
              setOptionSelectModalState(null);
            },
          });
        }}
      >
        <AddEditLocation
          size={size}
          LocationTitle={
            <AddEditLocation.LocationTitle
              style={{ maxWidth: "100%" }}
              Input={
                <Input
                  style={{ width: "100%" }}
                  value={mergedLocation.title}
                  placeholder={"Sally's Bar, Farmers Market, etc."}
                  onChange={(val) => setLocationTitle(val)}
                />
              }
              tooltip={
                <ToolTip
                  placement="center"
                  text="This is the location name displayed to customers on your storefront."
                />
              }
            />
          }
          AddressContainer={
            <AddEditLocation.AddressContainer>
              <AddressInput
                style={{ width: "100%" }}
                setAddressDict={setLocationAddressDict}
                setAddressUnit={setLocationAddressUnit}
                setLatLng={setLocationLatLng}
                setTimeZone={setLocationTimeZone}
                addressDict={mergedLocation.addressDict}
                initialAddress={getAddressString({
                  ...mergedLocation,
                  displayFullAddress: true,
                })}
              />
            </AddEditLocation.AddressContainer>
          }
          Instructions={
            <AddEditLocation.Instructions
              style={{ maxWidth: "100%" }}
              Input={
                <SmartTextArea
                  style={{ height: "328px", width: "100%" }}
                  maxLength={1000}
                  placeholder="No instructions provided"
                  onChange={(value) => setLocationArrivalInstructions(value)}
                  text={mergedLocation.arrivalInstructions}
                />
              }
              tooltip={
                <ToolTip
                  placement="center"
                  text="Customers will be provided these instructions in their order confirmation. This can include info like parking instructions, how to pickup the food, and any other info you want to provide customers after checkout."
                />
              }
            />
          }
          Photo={
            <AddEditLocation.Photo
              style={{ maxWidth: "100%" }}
              Input={
                <ImageUploader
                  image={mergedLocation.image}
                  onFileLoaded={onFileLoaded}
                  defaultImage="https://ucarecdn.com/b4c76d91-9145-4b7c-8258-3b6dc641a0e0/linesquarepngpngimage466144.png"
                  crop="750x750 minimum"
                />
              }
              tooltip={
                <ToolTip
                  placement="center"
                  text="Optional. Add a photo to help customers find this location."
                />
              }
            />
          }
          HideAddress={
            <AddEditLocation.HideAddress
              Switch={
                <AddEditLocation.HideAddress.Switch
                  checked={!mergedLocation.displayFullAddress}
                  onChange={toggleLocationDisplayFullAddress}
                />
              }
              tooltip={
                <ToolTip text="Only provides customers with the exact address after they have completed checkout." />
              }
            />
          }
        />
      </AddEditModal>
    </Modal>
  );
}

const SelectLocationModal = connect(
  (state) => {
    return {
      locations: state.hostPortal.hostInfo.locations,
    };
  },
  { setToastPopupInfo }
)(
  ({
    locations: hostLocations,
    setToastPopupInfo,

    eventId,
    onRequestSave,
    onRequestClose,
    onLocationSave,
    onLocationDelete,
  }) => {
    // Note that this sortedLocations is made of locations from hostInfo
    const sortedLocations = getSortedLocations(hostLocations || {});
    const [idOfLocationBeingEdited, setIdOfLocationBeingEdited] = useState(null);
    const [idOfSelectedLocation, setIdOfSelectedLocation] = useState(null);
    const [newLocation, setNewLocation] = useState(null);
    const saveLocationDiff = trpc.portal.saveLocationDiff.useMutation({
      retry: shouldRetry(1),
    });
    const deleteLocation = trpc.portal.deleteLocation.useMutation({
      retry: shouldRetry(1),
    });

    const locationBeingEditedWasDeleted = useDetectDeletion(hostLocations, idOfLocationBeingEdited);
    if (locationBeingEditedWasDeleted) {
      setIdOfLocationBeingEdited(null);
    }

    function toggleSelectedLocation(locationId) {
      setIdOfSelectedLocation(locationId === idOfLocationBeingEdited ? null : locationId);
    }

    function createAndSwitchToNewLocation() {
      const newLocation = createNewLocation();
      setNewLocation(newLocation);
      trackLocationCreated({
        id: newLocation.id,
      });
      setIdOfLocationBeingEdited(newLocation.id);
    }

    return (
      <>
        <Modal closeModal={onRequestClose} navText="Pickup Window">
          <ObjectSelectionModal
            className="objectSelection"
            header="Select Location"
            isEmpty={sortedLocations.length === 0}
            CreateButton={
              <ObjectSelectionModal.CreateButton onClick={createAndSwitchToNewLocation} />
            }
            CancelButton={
              <ObjectSelectionModal.CancelButton text="Cancel" onClick={onRequestClose} />
            }
            AddButton={
              <ObjectSelectionModal.AddButton
                disabled={idOfSelectedLocation === null}
                onClick={() => onRequestSave(hostLocations[idOfSelectedLocation])}
                text={"Add Location"}
              />
            }
          >
            {sortedLocations.map((location, locationIndex) => (
              <LocationCard
                key={locationIndex}
                SelectLocationButton={
                  <LocationCard.SelectLocationButton
                    title={location.title}
                    subtitle={getAddressString(location)}
                    selected={idOfSelectedLocation === location.id}
                    onClick={() => toggleSelectedLocation(location.id)}
                  />
                }
                selected={idOfSelectedLocation === location.id}
                EditButton={
                  <LocationCard.EditButton
                    onClick={() => setIdOfLocationBeingEdited(location.id)}
                  />
                }
              />
            ))}

            {sortedLocations.length === 0 && (
              <ObjectSelectionModal.EmptyContainerCta text="You haven't saved any locations, create your first by pressing the button above" />
            )}
          </ObjectSelectionModal>
        </Modal>
        {(newLocation || idOfLocationBeingEdited) && (
          <AddEditLocationModal
            saveLocationLoading={saveLocationDiff.isLoading}
            deleteLocationLoading={deleteLocation.isLoading}
            isNewLocation={hostLocations[idOfLocationBeingEdited] === undefined}
            initialLocationDiff={newLocation}
            location={newLocation ? {} : hostLocations[idOfLocationBeingEdited]}
            onRequestSave={async (locationDiffIncoming) => {
              // Note: locationDiffIncoming should not be null; there is no remove button
              try {
                await saveLocationDiff.mutateAsync({
                  eventId,
                  locationId: idOfLocationBeingEdited,
                  locationDiff: locationDiffIncoming,
                });
              } catch (e) {
                setToastPopupInfo({
                  type: "error",
                  text: `Location failed to save: ${e}`,
                });
                throw e;
              }
              setIdOfLocationBeingEdited(null);
              setNewLocation(null);
              const location = newLocation ? {} : hostLocations[idOfLocationBeingEdited];
              const mergedLocation = mergeDiff(location, locationDiffIncoming);
              onLocationSave(mergedLocation); // give to AddEditTimeWindowModal to update the diff of the event being edited
            }}
            onRequestDelete={async () => {
              setIdOfLocationBeingEdited(null);
              setNewLocation(null);
              try {
                await deleteLocation.mutateAsync({ locationId: idOfLocationBeingEdited });
              } catch (e) {
                setToastPopupInfo({
                  type: "error",
                  text: `Location failed to delete: ${e}`,
                });
                throw e;
              }
              onLocationDelete(idOfLocationBeingEdited);
            }}
            onRequestClose={() => {
              setIdOfLocationBeingEdited(null);
              setNewLocation(null);
            }}
          />
        )}
      </>
    );
  }
);

const Error = styled("p", {
  ff: "$avenir",
  fs: 14,
  fw: "$normal",
  color: "$error10",
  lineHeight: 1.5,
  pady: "$md",
});

export default function AddEditTimeWindowModal({
  isEventRecurring,
  eventId,
  isNewTimeWindow,
  initialTimeWindowDiff,
  timeWindow,
  onRequestSave,
  onRequestClose,
  goLiveTime,
}) {
  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);

  const [optionSelectModalState, setOptionSelectModalState] = useState(null);
  const [isSelectLocationModalVisible, setIsSelectLocationModalVisible] = useState(null);
  const [timeWindowDiff, setTimeWindowDiff] = useState(initialTimeWindowDiff || {});

  const mergedTimeWindow = removeNulls(mergeDiff(timeWindow, timeWindowDiff));

  // Note that this sortedLocations is made of locations in the mergedTimeWindow
  const sortedLocations = getSortedLocations(mergedTimeWindow.locations);

  function setTimeWindowDayOfWeek(dayOfWeek) {
    const dayOfWeekToTime = {};
    for (const timestamp of Array.from(
      { length: 7 },
      (_, i) => new Date().getTime() + i * 1000 * 60 * 60 * 24
    )) {
      dayOfWeekToTime[getTimestampDayOfWeek(timestamp)] = timestamp;
    }
    setTimeWindowDate(dayOfWeekToTime[dayOfWeek]);
  }

  function setTimeWindowDate(timestamp) {
    const nextD = new Date(timestamp);
    const prevDStartEndTime = new Date(mergedTimeWindow.startTime);
    prevDStartEndTime.setYear(nextD.getFullYear());
    prevDStartEndTime.setMonth(nextD.getMonth(), nextD.getDate());
    const newStartTime = prevDStartEndTime.getTime();

    const prevDEndTime = new Date(mergedTimeWindow.endTime);
    prevDEndTime.setYear(nextD.getFullYear());
    prevDEndTime.setMonth(nextD.getMonth(), nextD.getDate());
    const newEndTime = prevDEndTime.getTime();

    setTimeWindowDiff((prevTimeWindowDiff) => {
      return {
        ...prevTimeWindowDiff,
        startTime: newStartTime,
        endTime: newEndTime,
      };
    });
  }

  function setTimeWindowStartTime(timeAMPM) {
    const prevD = new Date(mergedTimeWindow.startTime);
    const hours = parseInt(timeAMPM.split(":")[0]);
    const minutes = parseInt(timeAMPM.split(":")[1].substr(0, 2));
    const ampm = timeAMPM.split(":")[1].substr(2, 2);
    prevD.setHours((hours % 12) + (ampm === "PM" ? 12 : 0), minutes, 0, 0);
    const newStartTime = prevD.getTime();
    setTimeWindowDiff((prevTimeWindowDiff) => {
      return {
        ...prevTimeWindowDiff,
        startTime: newStartTime,
      };
    });
  }

  function setTimeWindowEndTime(timeAMPM) {
    const prevD = new Date(mergedTimeWindow.endTime);
    const hours = parseInt(timeAMPM.split(":")[0]);
    const minutes = parseInt(timeAMPM.split(":")[1].substr(0, 2));
    const ampm = timeAMPM.split(":")[1].substr(2, 2);
    prevD.setHours((hours % 12) + (ampm === "PM" ? 12 : 0), minutes, 0, 0);
    const newEndTime = prevD.getTime();
    setTimeWindowDiff((prevTimeWindowDiff) => {
      return {
        ...prevTimeWindowDiff,
        endTime: newEndTime,
      };
    });
  }

  return (
    <>
      <Modal closeModal={() => onRequestClose()} optionSelectModalState={optionSelectModalState}>
        <AddEditModal
          cancelButtonOnClick={() => onRequestClose()}
          saveButtonOnClick={() => {
            onRequestSave(timeWindowDiff);
          }}
          deleteButtonOnClick={() => {
            onRequestSave(null);
          }}
          deleteMessage="Are you sure you want to delete this Pickup Window?"
          saveDisabled={
            sortedLocations.length === 0 ||
            !Number.isInteger(mergedTimeWindow.startTime) ||
            !Number.isInteger(mergedTimeWindow.endTime) ||
            mergedTimeWindow.startTime > mergedTimeWindow.endTime
          }
          title={isNewTimeWindow ? "Create Pickup Window" : "Edit Pickup Window"}
          navText="Pickup Windows"
          editing={!isNewTimeWindow}
          setOptionSelectModalState={(message, optionSelectButtons) => {
            setOptionSelectModalState({
              message: message,
              optionSelectButtons: optionSelectButtons,
              closeOptionSelectModal: () => {
                setOptionSelectModalState(null);
              },
            });
          }}
        >
          <CreatePickupWindow
            size={size}
            datePicker={
              <Flex css={{ flexDirection: "column" }}>
                <DayPicker
                  startTimestamp={mergedTimeWindow.startTime}
                  setDate={setTimeWindowDate}
                  recurring={isEventRecurring}
                  dateLabel="Pickup Date"
                  setStartTime={setTimeWindowStartTime}
                  startTimeLabel="Pickups Begin"
                  endTimestamp={mergedTimeWindow.endTime}
                  setEndTime={setTimeWindowEndTime}
                  endTimeLabel="Pickups End"
                  setTimeWindowDayOfWeek={setTimeWindowDayOfWeek}
                  invalid={mergedTimeWindow.startTime < goLiveTime}
                />
                {mergedTimeWindow.startTime < goLiveTime && (
                  <Error>
                    Pickups will start before this event goes live, are you sure this is correct?
                  </Error>
                )}
                <P
                  css={{
                    ff: "$avenir",
                    fs: 14,
                    fw: "$normal",
                    color: "$gray11",
                    marginTop: 8,
                    marginLeft: 2,
                  }}
                >
                  Setting times in{" "}
                  {new Date().toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2]}
                </P>
              </Flex>
            }
            AddLocationButton={
              <CreatePickupWindow.AddLocationButton
                text={sortedLocations.length === 0 ? "Add Location" : "Change Location"}
                onClick={() => {
                  setIsSelectLocationModalVisible(true);
                }}
              />
            }
            LocationsContainer={
              <CreatePickupWindow.LocationsContainer isEmpty={sortedLocations.length === 0}>
                {sortedLocations.map((location, locationIndex) => (
                  <LocationBadge
                    key={locationIndex}
                    title={location.title}
                    showRemoveButton={true}
                    RemoveButton={
                      <LocationBadge.RemoveButton
                        onClick={() => {
                          setTimeWindowDiff((prevTimeWindowDiff) => {
                            // remove all other locations; a pickup window can only have one
                            // location
                            return {
                              ...prevTimeWindowDiff,
                              locations: new OverwritingObject({}),
                            };
                          });
                        }}
                      />
                    }
                  />
                ))}
              </CreatePickupWindow.LocationsContainer>
            }
          />
        </AddEditModal>
      </Modal>
      {isSelectLocationModalVisible && (
        <SelectLocationModal
          eventId={eventId}
          onRequestSave={(selectedLocation) => {
            setTimeWindowDiff((prevTimeWindowDiff) => {
              // remove all other locations; a pickup window can only have one
              // location
              return {
                ...prevTimeWindowDiff,
                locations: new OverwritingObject({
                  [selectedLocation.id]: selectedLocation,
                }),
              };
            });
            setIsSelectLocationModalVisible(false);
          }}
          onRequestClose={() => {
            setIsSelectLocationModalVisible(false);
          }}
          onLocationSave={(location) => {
            if (mergedTimeWindow.locations && mergedTimeWindow.locations[location.id]) {
              setTimeWindowDiff((prevTimeWindowDiff) => {
                // update this event's location when the corresponding host location is edited
                return {
                  ...prevTimeWindowDiff,
                  locations: new OverwritingObject({
                    [location.id]: location,
                  }),
                };
              });
            }
          }}
          onLocationDelete={(locationId) => {
            if (mergedTimeWindow.locations && mergedTimeWindow.locations[locationId]) {
              setTimeWindowDiff((prevTimeWindowDiff) => {
                // delete this event's location when the corresponding host location is deleted
                return {
                  ...prevTimeWindowDiff,
                  locations: new OverwritingObject({}),
                };
              });
            }
          }}
        />
      )}
    </>
  );
}
