import React from "react";
import * as RadixAspectRatio from "@radix-ui/react-aspect-ratio";
import { styled } from "../../stitches.config";

const Box = styled("div", {
  width: "100%",
  overflow: "hidden",
});

const Img = styled("img", {
  objectFit: "cover",
  width: "100%",
  height: "100%",
});

export const AspectRatioImg = ({ ratio, src, alt, css, ...props }) => {
  return (
    <Box css={css} {...props}>
      <RadixAspectRatio.AspectRatio ratio={ratio}>
        <Img src={src} alt={alt} />
      </RadixAspectRatio.AspectRatio>
    </Box>
  );
};
