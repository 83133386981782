/* eslint-disable react/prop-types */
import React, { useState, useMemo } from "react";
import {
  getOrderTaxes,
  getSubtotal,
  getPaymentProcessing,
  getDateTimeString,
  getOrderDiscount,
  getTimestampHoursMinutesAMPM,
  getAbbreviatedTimezone,
  getTimestampDayOfWeekMonthDate,
  formatToCurrency,
  getOrderRefundTotal,
  getEventStatus,
} from "@hotplate/utils-ts/helperFunctions";

import { styled } from "../../../../stitches.config";
import { DotFilledIcon, DownloadIcon, QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import { Column, Flex, Grid, H2, Row } from "../../../../hotplate-common/primitives/Containers";
import { Tooltip } from "../../../../hotplate-common/primitives/Tooltip";
import { IconButton } from "../../../../hotplate-common/primitives/IconButton";
import { FilterButton } from "../../../../hotplate-common/primitives/FilterButton";
import { Tabs } from "../../../../hotplate-common/primitives/Tabs";
import { Select } from "../../../../hotplate-common/primitives/Select";
import { useSelector } from "react-redux";
import { StatBlockLoader } from "../../../../hotplate-common/loaders/EventLoaders";
import { trackEventStatsBreakdownUpdated } from "../analytics";
import { OrderExportDialog } from "../../../components/OrderExportDialog";

const Avenir = styled("div", {
  ff: "$avenir",
});

const StatisticsEvent = ({ text, timestamp, type, ...props }) => {
  const iconColor = (() => {
    switch (type) {
      case "success":
        return "$success10";
      case "warning":
        return "$warning10";
      case "error":
        return "$error10";
      default:
        return "$success10";
    }
  })();

  return (
    <>
      {timestamp && text && (
        <Flex
          css={{
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            height: 32,
            padx: 16,
          }}
          {...props}
        >
          <Flex
            css={{
              alignItems: "center",
              color: iconColor,
              "& svg": {
                marginRight: 4,
                size: 20,
              },
            }}
          >
            <DotFilledIcon />
            <Avenir css={{ fs: 14, fw: "$semi_bold", color: "$gray11" }}>{text}</Avenir>
          </Flex>
          <Avenir css={{ fs: 13, color: "$gray10" }}>
            {getDateTimeString(timestamp).replaceAll("-", " ")}
          </Avenir>
        </Flex>
      )}
    </>
  );
};

const Stat = styled("div", {
  display: "flex",
  flexDirection: "column",
  br: "$md",
  pad: "$md",
  border: `1px solid $gray6`,
  backgroundColor: "$gray3",
  color: "$gray11",
});

const StatBlock = ({ title, value, color = "gray", tooltipContent }) => {
  const connectToEventOrdersLoading = useSelector(
    (state) => state.orderManagement.connectToEventOrdersLoading
  );

  return (
    <>
      {connectToEventOrdersLoading ? (
        <StatBlockLoader />
      ) : (
        <Stat
          css={{
            border: `1px solid $${color + "6"}`,
            color: `$${color + "11"}`,
            backgroundColor: `$${color + "3"}`,
          }}
        >
          <Flex
            as="span"
            css={{ ff: "$arboria", fs: "$heading", fw: "$bold", lh: "$reset", marginBottom: "$xs" }}
          >
            {value}
          </Flex>
          <Row
            css={{
              ff: "$avenir",
              fs: 16,
              fw: "$semi_bold",
              lh: "$text",
              color: "inherit",
              gap: "$xxs",
              alignItems: "center",
            }}
          >
            <Flex as="h3">{title}</Flex>
            {tooltipContent && (
              <Tooltip content={tooltipContent} clickable>
                <IconButton as="div" size="small" icon={<QuestionMarkCircledIcon />} />
              </Tooltip>
            )}
          </Row>
        </Stat>
      )}
    </>
  );
};

const StatGrid = ({ orders, title, event, ...props }) => {
  function getOrdersPlaced() {
    return orders.length;
  }

  function getItemsSold() {
    let sum = 0;
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      const cartItems = order.cartItems;
      if (Array.isArray(cartItems)) {
        for (let j = 0; j < cartItems.length; j++) {
          sum += cartItems[j].quantity;
        }
      }
    }
    return sum;
  }

  function getNetSales() {
    let sum = 0;
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      sum += parseFloat(getSubtotal(order.cartItems));
    }
    return formatToCurrency(sum);
  }

  function getTotalTips() {
    let sum = 0;
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      if (order.tip !== "") {
        sum += parseFloat(order.tip);
      }
    }
    return formatToCurrency(sum);
  }

  function getTaxCollected() {
    let sum = 0;
    for (let i = 0; i < orders.length; i++) {
      sum += parseFloat(getOrderTaxes(orders[i]));
    }
    return formatToCurrency(sum);
  }

  function getDiscounts() {
    let sum = 0;
    for (let i = 0; i < orders.length; i++) {
      sum += parseFloat(getOrderDiscount(orders[i]));
    }
    return formatToCurrency(sum);
  }

  function getRefunds() {
    let sum = 0;
    for (let i = 0; i < orders.length; i++) {
      sum += parseFloat(getOrderRefundTotal(orders[i]));
    }
    return formatToCurrency(sum);
  }

  function getPaymentProcessingFees() {
    let sum = 0;
    for (let i = 0; i < orders.length; i++) {
      sum += parseFloat(getPaymentProcessing(orders[i]));
    }
    return formatToCurrency(sum);
  }

  return (
    <Column {...props}>
      <Row css={{ justifyContent: "space-between", alignItems: "center", mb: "$sm" }}>
        <H2 css={{ ff: "$avenir", fs: "$md", fw: "$semi_bold", lh: "$text", color: "$gray12" }}>
          {title}
        </H2>
        <OrderExportDialog
          orders={orders}
          trackSource="event"
          trigger={
            <FilterButton>
              <DownloadIcon />
              <span>Export</span>
            </FilterButton>
          }
        />
      </Row>

      <Grid css={{ gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))", gap: "$md" }}>
        <StatBlock title="Orders" value={getOrdersPlaced()} color="info" />
        <StatBlock title="Items Sold" value={getItemsSold()} color="info" />
        <StatBlock
          title="Food Sales"
          value={getNetSales()}
          color="success"
          tooltipContent="The summed subtotal of orders."
        />
        <StatBlock title="Tips" value={getTotalTips()} color="success" />
        <StatBlock title="Discounts" value={getDiscounts()} color="warning" />
        <StatBlock title="Refunds" value={getRefunds()} color="warning" />
        <StatBlock title="Tax Collected" value={getTaxCollected()} />
        <StatBlock
          title="Processing Fees"
          value={getPaymentProcessingFees()}
          tooltipContent={`Payment Processing Fees charged by our payment processing partner, Stripe. \n\n 2.9% + 30¢ per order`}
        />
      </Grid>
    </Column>
  );
};

export const Statistics = ({ event, orders }) => {
  const [breakdownValue, setBreakdownValue] = useState(undefined);
  const timeWindows = event.timeWindows;

  const ordersBreakdown = useMemo(() => {
    const filteredOrders = {};
    // ** Get orders per fulfillment Day
    const ordersByFulfillmentDay = {};
    // add orders to days
    Object.keys(orders).forEach((pI) => {
      const order = orders[pI];
      const fulfillmentTime = new Date(order.timeSlot.startTime).setHours(0, 0, 0, 0).toString();
      if (!Object.keys(ordersByFulfillmentDay).includes(fulfillmentTime))
        ordersByFulfillmentDay[fulfillmentTime] = [];
      ordersByFulfillmentDay[fulfillmentTime].push(order);
    });
    filteredOrders["ordersByFulfillmentDay"] = ordersByFulfillmentDay;

    const ordersByDayPlaced = {};
    // add orders to days
    Object.keys(orders).forEach((pI) => {
      const order = orders[pI];
      const dayPlaced = new Date(order.orderPlaced).setHours(0, 0, 0, 0).toString();
      if (!Object.keys(ordersByDayPlaced).includes(dayPlaced)) ordersByDayPlaced[dayPlaced] = [];
      ordersByDayPlaced[dayPlaced].push(order);
    });
    filteredOrders["ordersByDayPlaced"] = ordersByDayPlaced;

    // ** Get orders per Time Window
    const ordersByTimeWindow = {};
    // define time windows
    Object.keys(timeWindows).forEach((key) => {
      ordersByTimeWindow[key] = [];
    });
    // add orders to time windows
    Object.keys(ordersByTimeWindow).forEach((timeWindow) => {
      Object.keys(orders).forEach((pI) => {
        const order = orders[pI];
        const fulfillmentTime = order.timeSlot.startTime;
        if (
          fulfillmentTime >= timeWindows[timeWindow].startTime &&
          fulfillmentTime <= timeWindows[timeWindow].endTime
        )
          ordersByTimeWindow[timeWindow].push(order);
      });
    });
    filteredOrders["ordersByTimeWindow"] = ordersByTimeWindow;
    return filteredOrders;
  }, [timeWindows, orders]);

  return (
    <Column>
      <Row css={{ alignItems: "center", justifyContent: "space-between", marginBottom: "$sm" }}>
        <Flex
          as="h1"
          css={{ ff: "$avenir", fs: "$heading", fw: "$bold", lh: "$text", color: "$gray12" }}
        >
          Sales Statistics
        </Flex>
        <Select
          placeholder="Breakdown by..."
          value={breakdownValue}
          onValueChange={(val) => {
            setBreakdownValue(val);
            trackEventStatsBreakdownUpdated({
              eventId: event.id,
              eventStatus: getEventStatus(event),
              breakdown: val,
            });
          }}
        >
          <Select.Option value="day_placed">Day placed</Select.Option>
          <Select.Option value="fulfillment_day">Fulfillment day</Select.Option>
          <Select.Option value="time_window">Pickup window</Select.Option>
        </Select>
      </Row>
      <Tabs css={{ boxShadow: "none", borderRadius: 0, border: "none" }} defaultValue="overall">
        <Tabs.List>
          <Tabs.Tab value="overall">Overall</Tabs.Tab>
          {breakdownValue === "day_placed" &&
            Object.keys(ordersBreakdown.ordersByDayPlaced).map((day) => (
              <Tabs.Tab key={day} value={`${day}-placed`}>
                {getTimestampDayOfWeekMonthDate(parseInt(day), true)}
              </Tabs.Tab>
            ))}
          {breakdownValue === "fulfillment_day" &&
            Object.keys(ordersBreakdown.ordersByFulfillmentDay).map((day) => (
              <Tabs.Tab key={day} value={`${day}-fulfilled`}>
                {getTimestampDayOfWeekMonthDate(parseInt(day), true)}
              </Tabs.Tab>
            ))}
          {breakdownValue === "time_window" &&
            Object.keys(ordersBreakdown.ordersByTimeWindow).map((windowId) => (
              <Tabs.Tab key={windowId} value={windowId}>
                {`${getTimestampDayOfWeekMonthDate(
                  timeWindows[windowId].startTime,
                  true
                )}, ${getTimestampHoursMinutesAMPM(
                  timeWindows[windowId].startTime
                )} - ${getTimestampHoursMinutesAMPM(
                  timeWindows[windowId].endTime
                )} ${getAbbreviatedTimezone()}`}
              </Tabs.Tab>
            ))}
        </Tabs.List>
        <Tabs.Page value="overall" css={{ padx: 0 }}>
          <StatGrid title="Overall Statistics" orders={Object.values(orders)} />
        </Tabs.Page>
        {/* Day Placed Page Data */}
        {Object.keys(ordersBreakdown.ordersByDayPlaced).map((day) => (
          <Tabs.Page key={day} value={`${day}-placed`} css={{ padx: 0 }}>
            <StatGrid
              title={`${getTimestampDayOfWeekMonthDate(parseInt(day), true)}`}
              orders={ordersBreakdown.ordersByDayPlaced[day]}
            />
          </Tabs.Page>
        ))}
        {/* Fulfillment Day Page Data */}
        {Object.keys(ordersBreakdown.ordersByFulfillmentDay).map((day) => (
          <Tabs.Page key={day} value={`${day}-fulfilled`} css={{ padx: 0 }}>
            <StatGrid
              title={`${getTimestampDayOfWeekMonthDate(parseInt(day), true)}`}
              orders={ordersBreakdown.ordersByFulfillmentDay[day]}
              event={event} // only passed for logging purposes
            />
          </Tabs.Page>
        ))}
        {/* Time Window Page Data */}
        {Object.keys(ordersBreakdown.ordersByTimeWindow).map((windowId) => (
          <Tabs.Page key={windowId} value={windowId} css={{ padx: 0 }}>
            <StatGrid
              title={`${getTimestampDayOfWeekMonthDate(
                timeWindows[windowId].startTime
              )}, ${getTimestampHoursMinutesAMPM(
                timeWindows[windowId].startTime
              )} - ${getTimestampHoursMinutesAMPM(
                timeWindows[windowId].endTime
              )} ${getAbbreviatedTimezone()}`}
              orders={ordersBreakdown.ordersByTimeWindow[windowId]}
            />
          </Tabs.Page>
        ))}
      </Tabs>
      {!!event.remindersSent && (
        <StatisticsEvent
          type="success"
          text={`SMS notifications sent to ${event.numRemindersSent} customers`}
          timestamp={event.timeRemindersSent}
        />
      )}
      {!!event.numEmailRemindersSent && (
        <StatisticsEvent
          type="success"
          text={`Email notifications sent to ${event.numEmailRemindersSent} customers`}
          timestamp={event.timeEmailRemindersSent}
        />
      )}
    </Column>
  );
};
