import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import type firebase from "firebase";
import _ from "lodash";
import type { Dispatch, RefObject, SetStateAction } from "react";
import { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { FirebaseContext } from "./firebaseSocket";
import { backendTsUrl } from "./hotplate-storefront/actions/types";

export class OverwritingObject extends Object {
  _overwrite: boolean;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
    this._overwrite = true; // for JSON serializability
  }
}

export class OverwritingArray extends Array {
  _overwrite: boolean;

  constructor(arr: Iterable<any>) {
    super(...arr);
    this._overwrite = true; // for JSON serializability
  }
}

export function mergeDiff(obj: any, diff: any) {
  return _.mergeWith({ key: _.cloneDeep(obj) }, { key: diff }, (objValue, srcValue) => {
    // Arrays always completely overwrite
    // this will prevent merging of event.menu.sections or event.menuItems.categories
    if (Array.isArray(srcValue) || (srcValue instanceof Object && srcValue._overwrite)) {
      return srcValue;
    }
  }).key;
}

/**
 * Copy all subobjects recursively, ignoring any keys that map to null. Arrays
 * are not recursed into and are taken as-is.
 */
export function removeNulls(obj: any) {
  let ret = obj;
  if (typeof obj === "object" && !Array.isArray(obj)) {
    ret = {};
    for (const key in obj) {
      if (obj[key] !== null && key !== "_overwrite") {
        ret[key] = removeNulls(obj[key]);
      }
    }
  }
  return ret;
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useDetectDeletion(obj: any, key: string) {
  // https://reactjs.org/docs/hooks-faq.html#how-do-i-implement-getderivedstatefromprops
  obj = obj || {};
  // if previously, key was not null, and obj[key] was defined,
  // and now, key is STILL not null, BUT obj[key] is UNDEFINED,
  const prevObj = usePrevious(obj);
  const prevKey = usePrevious(key);
  return prevKey && prevObj[prevKey] && key && obj[key] === undefined;
}

export function useToggle(
  defaultValue?: boolean
): [boolean, () => void, Dispatch<SetStateAction<boolean>>] {
  const [value, setValue] = useState(!!defaultValue);

  const toggle = useCallback(() => setValue((x) => !x), []);

  return [value, toggle, setValue];
}

export function useTimeout(callback: any, delay: any) {
  const timeoutRef = useRef<number | undefined>(undefined);
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === "number") {
      timeoutRef.current = window.setTimeout(tick, delay);
      return () => window.clearTimeout(timeoutRef.current);
    }
  }, [delay]);
  return timeoutRef;
}

export function useInterval(callback: any, delay: any) {
  const intervalRef = useRef<number | undefined>(undefined);
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === "number") {
      intervalRef.current = window.setInterval(tick, delay);
      return () => window.clearInterval(intervalRef.current);
    }
  }, [delay]);
  return intervalRef;
}

export function usePresenceCounter(presenceKey: string) {
  const url = "wss://hotplate-ephemeral-chat-node-redis.fly.dev/socket";
  // const url = "ws://localhost:9001/socket";

  const [count, setCount] = useState<number | undefined>();

  useEffect(() => {
    if (presenceKey) {
      try {
        const socket = new WebSocket(url);

        socket.addEventListener("open", (event) => {
          socket.send(
            JSON.stringify({
              command: "subscribe",
              topic: presenceKey,
            })
          );
        });

        socket.addEventListener("message", (event) => {
          const data = JSON.parse(event.data);
          if (data.command === "presence") {
            setCount(data.count);
          }
        });

        return () => {
          socket.close();
          setCount(0);
        };
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
      }
    }
  }, [presenceKey]);

  return { count };
}

type Message = { content: string; username: string };

export function useEphemeralChat(channelId: string) {
  const topic = "chat/" + channelId;
  const url = "wss://hotplate-ephemeral-chat-node-redis.fly.dev/socket";
  // const url = "ws://localhost:9001/socket";

  const [socket, setSocket] = useState<WebSocket>();
  const [userCount, setUserCount] = useState<number | undefined>();
  const messages = useRef<Message[]>([]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const addMessage = useCallback((message: Message) => {
    if (messages.current.length === 50) {
      messages.current.shift();
    }
    messages.current.push(message);
    forceUpdate();
  }, []);

  useEffect(() => {
    if (channelId) {
      try {
        const socket = new WebSocket(url);

        socket.addEventListener("open", (event) => {
          socket.send(
            JSON.stringify({
              command: "subscribe",
              topic: topic,
            })
          );
          setSocket(socket);
        });

        socket.addEventListener("message", (event) => {
          const data = JSON.parse(event.data);
          if (data.command === "presence") {
            setUserCount(data.count);
          } else if (data.command === "message" && typeof data.content === "string") {
            addMessage(data);
          }
        });

        return () => {
          socket.close();
          setUserCount(undefined);
          setSocket(undefined);
        };
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
      }
    }
  }, [channelId, topic, addMessage]);

  const sendMessage = useCallback(
    (content: string) => {
      addMessage({ content: content, username: "(me)" });
      socket?.send(
        JSON.stringify({
          command: "message",
          topic: topic,
          content: content,
        })
      );
    },
    [addMessage, topic, socket]
  );

  return { isConnected: !!socket, userCount, messages: messages.current, sendMessage };
}

interface Args extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
}

export function useIntersectionObserver(
  elementRef: RefObject<Element>,
  { threshold = 0, root = null, rootMargin = "0%", freezeOnceVisible = false }: Args
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef?.current, JSON.stringify(threshold), root, rootMargin, frozen]);

  return entry;
}

export function useFirebaseConnection(
  {
    ref,
    orderByChild,
    equalTo,
    equalToKey,
  }: {
    ref: string;
    orderByChild?: string;
    equalTo?: Parameters<firebase.database.Query["equalTo"]>[0];
    equalToKey?: Parameters<firebase.database.Query["equalTo"]>[1];
  },
  {
    isEnabled = true,
    throttleArgs,
  }:
    | {
        isEnabled?: boolean;
        throttleArgs?: {
          wait: Parameters<typeof _.throttle>[1];
          options?: {
            leading: boolean;
            trailing: boolean;
          };
        };
      }
    | undefined = {}
) {
  if (isEnabled === undefined) {
    isEnabled = true;
  }
  const firebaseContext = useContext(FirebaseContext);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>();

  const shouldThrottle = throttleArgs !== undefined;
  const wrappedHandler = useMemo(() => {
    const handler = (snapshot: firebase.database.DataSnapshot, signal: AbortSignal) => {
      if (!signal.aborted) {
        setData(snapshot.val());
        setIsLoading(false);
      }
    };

    if (shouldThrottle) {
      return _.throttle(handler, throttleArgs.wait, {
        leading: throttleArgs.options?.leading,
        trailing: throttleArgs.options?.trailing,
      });
    }
    return handler;
  }, [
    shouldThrottle,
    throttleArgs?.wait,
    throttleArgs?.options?.leading,
    throttleArgs?.options?.trailing,
  ]);

  useEffect(() => {
    setIsLoading(true);

    if (isEnabled) {
      try {
        const db = firebaseContext.database as firebase.database.Database;

        let query: firebase.database.Query = db.ref(ref); // may throw if the Firebase app was deleted during reset
        if (orderByChild) {
          query = query.orderByChild(orderByChild);
        }
        if (equalTo) {
          query = query.equalTo(equalTo, equalToKey);
        }

        const abortController = new AbortController();

        const trueHandler = (val: firebase.database.DataSnapshot) => {
          return wrappedHandler(val, abortController.signal);
        };

        query.on("value", trueHandler);

        return () => {
          query.off("value", trueHandler);
          abortController.abort();
        };
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  }, [firebaseContext.database, wrappedHandler, ref, orderByChild, equalTo, equalToKey, isEnabled]);

  return { data, isLoading };
}

export function useClientTime({ renderIntervalMs = 1000, isEnabled = true } = {}) {
  const [time, setTime] = useState(isEnabled ? Date.now() : undefined);

  useEffect(() => {
    if (isEnabled) {
      const interval = setInterval(() => {
        setTime(Date.now());
      }, renderIntervalMs);

      return () => {
        clearInterval(interval);
        setTime(undefined);
      };
    }
  }, [isEnabled, renderIntervalMs]);

  return time;
}

export function useServerTime({ renderIntervalMs = 1000, isEnabled = true } = {}) {
  const getSkew = useQuery(
    ["time"],
    async () => {
      const startTime = Date.now();
      const response = await fetch(backendTsUrl + "time");
      const elapsed = Date.now() - startTime;
      const result = await response.json();
      const clientTime = startTime + elapsed / 2;
      const serverTime = result.ms as number;
      const skew = serverTime - clientTime;
      return skew;
    },
    { enabled: isEnabled, staleTime: 0 }
  );

  const clientTime = useClientTime({ isEnabled: getSkew.isSuccess && isEnabled, renderIntervalMs });

  return getSkew.data && clientTime ? clientTime + getSkew.data : undefined;
}
