// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./SetLocationTime.LocationContainer.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as LocationCard } from "../Checkout/CheckoutLocationCard";

const styles = [
  {
    type: "default",
    layers: {
      WhBfSaCibd: {
        none: {
          text: "Choose Location",
        },
      },
      "2d3hswjkjQ": {
        none: {
          text: "Text",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "delivery",
    layers: {
      WhBfSaCibd: {
        none: {
          text: "Delivery Address",
        },
      },
      "2d3hswjkjQ": {
        none: {
          text: "1337 Gamer Ave, Austin, TX 78702",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      "8gv14Qacch": {},
    },
  },
  {
    type: "boolean",
    propName: "delivery",
    layers: {
      "8gv14Qacch": {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "delivery",
  },
];

export const LocationContainerContext = createContext(null);

function LocationContainer(_props) {
  const defaults = useContext(LocationContainerContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="EF1VE9ZRuW"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "EF1VE9ZRuW",
        scope: "Ek2HeLBcPW",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <TextPrimitive
            id={"Text_WhBfSaCibd"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Ek2HeLBcPW_WhBfSaCibd"
            }
            key={"WhBfSaCibd"}
            text={getStyle("WhBfSaCibd", "text")}
            element={getStyle("WhBfSaCibd", "htmlElement")}
          />
          <SpacerPrimitive
            id={"Spacer_J3z24LTF4L"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Ek2HeLBcPW_J3z24LTF4L"
            }
            key={"J3z24LTF4L"}
          />
          <TextPrimitive
            id={"deliveryAddress_2d3hswjkjQ"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Ek2HeLBcPW_2d3hswjkjQ"
            }
            key={"2d3hswjkjQ"}
            text={
              exists(props.deliveryAddress)
                ? props.deliveryAddress
                : getStyle("2d3hswjkjQ", "text")
            }
            element={getStyle("2d3hswjkjQ", "htmlElement")}
          />
          <ContainerPrimitive
            key={"V3VZcLyFwa"}
            id={"Slot_V3VZcLyFwa"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_Ek2HeLBcPW_V3VZcLyFwa"
            }
            addSpacing={true}
          >
            {props.children}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

LocationContainer.LocationCard = LocationCard;
LocationContainer.__variants = [
  {
    name: "delivery",
    type: "variant",
  },
];

export default LocationContainer;
