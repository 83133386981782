import { chef_id } from "../analytics";

// Account Settings
export function trackStorefrontUrlCopied() {
  window.analytics.track("Storefront URL Copied", {
    chef_id,
  });
}

export function trackStorefrontQrCodeDownloaded() {
  window.analytics.track("Storefront QR Code Downloaded", {
    chef_id,
  });
}

export function trackBusinessNameUpdated({ name }) {
  window.analytics.track("Business Name Updated", {
    chef_id,
    name: name,
  });
}

export function trackTaxRateUpdated({ taxRate }) {
  window.analytics.track("Tax Rate Updated", {
    chef_id,
    tax_rate: taxRate,
  });
}

export function trackUserAddedToAccount({ userName, phone, permissions }) {
  window.analytics.track("User Added to Account", {
    chef_id,
    user_name: userName,
    phone: phone,
    permissions: permissions,
  });
}

export function trackUserRemovedFromAccount({ phone }) {
  window.analytics.track("User Removed from Account", {
    chef_id,
    phone: phone,
  });
}

export function trackAccountPrimaryColorUpdated({ color }) {
  window.analytics.track("Account Primary Color Updated", {
    chef_id,
    color: color,
  });
}

export function trackAccountStorefrontHeaderColorUpdated({ color }) {
  window.analytics.track("Account Storefront Header Color Updated", {
    chef_id,
    color: color,
  });
}

export function trackAccountStorefrontHeaderTextColorUpdated({ color }) {
  window.analytics.track("Account Storefront Header Text Color Updated", {
    chef_id,
    color: color,
  });
}

export function trackAccountLogoUpdated({ logo }) {
  window.analytics.track("Account Logo Updated", {
    chef_id,
    logo: logo,
  });
}

export function trackAccountLogoRemoved() {
  window.analytics.track("Account Logo Removed", {
    chef_id,
  });
}

export function trackAccountTipSuggestionsUpdated({ tipSuggestions }) {
  window.analytics.track("Account Tip Suggestions Updated", {
    chef_id,
    tip_suggestions: tipSuggestions,
  });
}

export function trackAccountZeroTipDefaultToggled({ enabled }) {
  window.analytics.track("Account Zero Tip Default Toggled", {
    chef_id,
    enabled: enabled,
  });
}

export function trackAccountSmsNotificationToggled({ notificationType, enabled }) {
  window.analytics.track("Account SMS Notification Toggled", {
    chef_id,
    notification_type: notificationType,
    enabled: enabled,
  });
}

export function trackAccountSmsNotificationUpdated({ notificationType, message }) {
  window.analytics.track("Account SMS Notification Updated", {
    chef_id,
    notification_type: notificationType,
    message: message,
  });
}

export function trackConnectToSquareClicked() {
  window.analytics.track("Connect To Square Clicked", {
    chef_id,
  });
}

// Discount Codes
export function trackCreateDiscountCodeClicked() {
  window.analytics.track("Create Discount Code Clicked", {
    chef_id,
  });
}

export function trackDiscountCodeToggled({ discountCodeId, enabled }) {
  window.analytics.track("Discount Code Toggled", {
    chef_id,
    discount_id: discountCodeId,
    enabled: enabled,
  });
}

export function trackDiscountCodeArchivedUnarchived(discountCodeId, archived) {
  if (archived) {
    window.analytics.track("Discount Code Archived", {
      chef_id,
      discount_id: discountCodeId,
    });
  } else {
    window.analytics.track("Discount Code Unarchived", {
      chef_id,
      discount_id: discountCodeId,
    });
  }
}

export function trackDiscountCodeCreated(discountCode) {
  window.analytics.track("Discount Code Created", {
    chef_id,
    discount_id: discountCode.id,
    type: discountCode.type,
    amount: discountCode.amount,
    code: discountCode.code,
  });
}

export function trackAboutMeUpdated({ aboutMe }) {
  window.analytics.track("About Me Updated", {
    chef_id,
    about_me: aboutMe,
  });
}

export function trackCustomDropNameUpdated({ customName }) {
  window.analytics.track("Custom Drop Name Updated", {
    chef_id,
    custom_name: customName,
  });
}

export function trackSocialMediaLinkUpdated({ socialMediaLink }) {
  window.analytics.track("Social Media Link Updated", {
    chef_id,
    social_media_link: socialMediaLink,
  });
}

export function trackStorefrontLinkAdded() {
  window.analytics.track("Storefront Link Added", {
    chef_id,
  });
}

export function trackStorefrontLinkRemoved() {
  window.analytics.track("Storefront Link Removed", {
    chef_id,
  });
}

export function trackFAQAdded() {
  window.analytics.track("FAQ Added", {
    chef_id,
  });
}

export function trackFAQRemoved() {
  window.analytics.track("FAQ Removed", {
    chef_id,
  });
}

export function trackIsBusinessNameHiddenToggled({ enabled }) {
  window.analytics.track("Hide Business Name in Profile Toggled", {
    chef_id,
    enabled: enabled,
  });
}

export function trackIsGiftCardsEnabledOnStorefrontToggled({ enabled }) {
  window.analytics.track("Hide Business Name in Profile Toggled", {
    chef_id,
    enabled: enabled,
  });
}

export function trackHideDropChatToggled({ enabled }) {
  window.analytics.track("Hide Drop Chat Toggled", {
    chef_id,
    enabled: enabled,
  });
}

export function trackVisibleItemsInCartsCapToggled({ enabled }) {
  window.analytics.track("Cap Visible Items in Carts Toggled", {
    chef_id,
    enabled: enabled,
  });
}

export function trackHidePresenceToggled({ enabled }) {
  window.analytics.track("Hide Presence Count Toggled", {
    chef_id,
    enabled: enabled,
  });
}
