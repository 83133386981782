import React from "react";
import AnimationContainer from "../../hotplate-common/AnimationContainer";
import { Input } from "../../visly/Primitives";
import "./css/UserInfoDetail.css";
import ErrorLine from "./ErrorLine";

const UserInfoDetail = ({
  value,
  onChange,
  placeholder,
  label,
  validationType,
  type,
  errorText = "",
  style = {},
  showError = true,
  ...restProps
}: {
  value: string,
  onChange: (value: string) => void,
  placeholder: string,
  label: string,
  validationType: string,
  type: string,
  restProps: any,
  showError?: boolean,
  errorText?: string,
  style?: any,
}) => {
  return (
    <div className="userInfoDetailContainer" style={style}>
      <Input
        style={{ marginBottom: 16 }}
        value={value}
        type={type}
        onChange={(value) => onChange(value)}
        placeholder={placeholder}
        aria-label={typeof label === "string" ? label : placeholder}
        label={typeof label === "string" ? label : placeholder}
        validationType={validationType}
        validAnimation={
          validationType === "valid" && (
            <AnimationContainer animation="check" width="24px" height="24px" />
          )
        }
        handleOnKeyDown={value !== ""}
        {...restProps}
      />
      <ErrorLine showError={showError} errorText={errorText} />
    </div>
  );
};

export default UserInfoDetail;
