// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./FeeLineItem.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import {
  default as RefundAmountInput,
  InputContext as RefundAmountInputContext,
} from "../Primitives/Input";
import { default as MaxRefund } from "./FeeLineItem.MaxRefund";

const styles = [
  {
    type: "default",
    layers: {
      NFbmL73ZbG: {
        none: {
          text: "Fee Title",
        },
      },
      JwsxAXPi67: {
        none: {
          text: "($0.00)",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      RUtnNWtLKE: {
        none: {
          placeholder: "0.00",
          label: "Fee Refund",
          unit: "$",
          handleOnKeyDown: false,
          withUnit: "left",
        },
      },
    },
  },
];

const variantPropTypes = [];

export const FeeLineItemContext = createContext(null);

function FeeLineItem(_props) {
  const defaults = useContext(FeeLineItemContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="CRgDZEtZpA"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "CRgDZEtZpA",
        scope: "GUMdhXec65",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"title-total-container_Y5s88kSBtC"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_GUMdhXec65_Y5s88kSBtC"
            }
            key={"Y5s88kSBtC"}
            addSpacing={false}
            element={getStyle("Y5s88kSBtC", "htmlElement")}
          >
            <TextPrimitive
              id={"feeTitle_NFbmL73ZbG"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_GUMdhXec65_NFbmL73ZbG"
              }
              key={"NFbmL73ZbG"}
              text={
                exists(props.title)
                  ? props.title
                  : getStyle("NFbmL73ZbG", "text")
              }
              element={getStyle("NFbmL73ZbG", "htmlElement")}
            />
            <SpacerPrimitive
              id={"Spacer_JDPrxfoNxy"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_GUMdhXec65_JDPrxfoNxy"
              }
              key={"JDPrxfoNxy"}
            />
            <TextPrimitive
              id={"feeTotal_JwsxAXPi67"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_GUMdhXec65_JwsxAXPi67"
              }
              key={"JwsxAXPi67"}
              text={
                exists(props.total)
                  ? props.total
                  : getStyle("JwsxAXPi67", "text")
              }
              element={getStyle("JwsxAXPi67", "htmlElement")}
            />
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"refund-input-container_SCFTKhTwf1"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_GUMdhXec65_SCFTKhTwf1"
            }
            key={"SCFTKhTwf1"}
            addSpacing={false}
            element={getStyle("SCFTKhTwf1", "htmlElement")}
          >
            {[
              props.RefundAmountInput === undefined ? (
                <RefundAmountInput
                  key={"RUtnNWtLKE"}
                  {...getCompositeDefaultProps("RUtnNWtLKE")}
                  id="RefundAmountInput_RUtnNWtLKE"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_GUMdhXec65_RUtnNWtLKE"
                />
              ) : (
                <RefundAmountInputContext.Provider
                  key="RUtnNWtLKE-provider"
                  value={{
                    key: "RUtnNWtLKE",
                    id: "RefundAmountInput_RUtnNWtLKE",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_GUMdhXec65_RUtnNWtLKE",
                    ...getCompositeDefaultProps("RUtnNWtLKE"),
                  }}
                >
                  {props.RefundAmountInput}
                </RefundAmountInputContext.Provider>
              ),
              <SpacerPrimitive
                id={"Spacer_QUPeLrgngB"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_GUMdhXec65_QUPeLrgngB"
                }
                key={"QUPeLrgngB"}
              />,
              props.MaxRefund === undefined ? (
                <MaxRefund key={"EyEKxggTCz"} />
              ) : (
                props.MaxRefund
              ),
            ]}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

FeeLineItem.RefundAmountInput = RefundAmountInput;
FeeLineItem.__variants = [];

export default FeeLineItem;
