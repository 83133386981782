// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./EditMenuSettings.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import {
  default as MaxOrdersPerPickupTime,
  LabeledInputContext as MaxOrdersPerPickupTimeContext,
} from "../Events/LabeledInput";
import {
  default as ShowInventoryOnStorefront,
  LabeledSwitchContext as ShowInventoryOnStorefrontContext,
  default as HideMenuUntilLive,
  LabeledSwitchContext as HideMenuUntilLiveContext,
} from "../Events/LabeledSwitch";

const styles = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "noSections",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      KyGa2SP5ph: {
        none: {
          label: "Max customers per Pickup Time",
        },
      },
      A83bkzZ1Xo: {
        none: {
          title: "Show Inventory on Storefront",
        },
      },
      PVGTG92rDm: {
        none: {
          title: "Hide menu until orders open",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "noSections",
    layers: {
      KyGa2SP5ph: {},
      A83bkzZ1Xo: {},
      PVGTG92rDm: {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "noSections",
  },
];

export const EditMenuSettingsContext = createContext(null);

function EditMenuSettings(_props) {
  const defaults = useContext(EditMenuSettingsContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="L84kPS3N7m"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "L84kPS3N7m",
        scope: "NL6aTk7sXb",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            key={"Tt3HQ1wJ2h"}
            id={"Slot_Tt3HQ1wJ2h"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_NL6aTk7sXb_Tt3HQ1wJ2h"
            }
            addSpacing={false}
          >
            {props.rearrangeSections}
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_LhYFZeD4Wj"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_NL6aTk7sXb_LhYFZeD4Wj"
            }
            key={"LhYFZeD4Wj"}
          />
          <ContainerPrimitive
            id={"settings-container_3R8G5jSjxT"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_NL6aTk7sXb_3R8G5jSjxT"
            }
            key={"3R8G5jSjxT"}
            addSpacing={true}
            element={getStyle("3R8G5jSjxT", "htmlElement")}
          >
            {[
              props.MaxOrdersPerPickupTime === undefined ? (
                <MaxOrdersPerPickupTime
                  key={"KyGa2SP5ph"}
                  {...getCompositeDefaultProps("KyGa2SP5ph")}
                  id="MaxOrdersPerPickupTime_KyGa2SP5ph"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_NL6aTk7sXb_KyGa2SP5ph"
                />
              ) : (
                <MaxOrdersPerPickupTimeContext.Provider
                  key="KyGa2SP5ph-provider"
                  value={{
                    key: "KyGa2SP5ph",
                    id: "MaxOrdersPerPickupTime_KyGa2SP5ph",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_NL6aTk7sXb_KyGa2SP5ph",
                    ...getCompositeDefaultProps("KyGa2SP5ph"),
                  }}
                >
                  {props.MaxOrdersPerPickupTime}
                </MaxOrdersPerPickupTimeContext.Provider>
              ),
              props.ShowInventoryOnStorefront === undefined ? (
                <ShowInventoryOnStorefront
                  key={"A83bkzZ1Xo"}
                  {...getCompositeDefaultProps("A83bkzZ1Xo")}
                  id="ShowInventoryOnStorefront_A83bkzZ1Xo"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_NL6aTk7sXb_A83bkzZ1Xo"
                />
              ) : (
                <ShowInventoryOnStorefrontContext.Provider
                  key="A83bkzZ1Xo-provider"
                  value={{
                    key: "A83bkzZ1Xo",
                    id: "ShowInventoryOnStorefront_A83bkzZ1Xo",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_NL6aTk7sXb_A83bkzZ1Xo",
                    ...getCompositeDefaultProps("A83bkzZ1Xo"),
                  }}
                >
                  {props.ShowInventoryOnStorefront}
                </ShowInventoryOnStorefrontContext.Provider>
              ),
              props.HideMenuUntilLive === undefined ? (
                <HideMenuUntilLive
                  key={"PVGTG92rDm"}
                  {...getCompositeDefaultProps("PVGTG92rDm")}
                  id="HideMenuUntilLive_PVGTG92rDm"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_NL6aTk7sXb_PVGTG92rDm"
                />
              ) : (
                <HideMenuUntilLiveContext.Provider
                  key="PVGTG92rDm-provider"
                  value={{
                    key: "PVGTG92rDm",
                    id: "HideMenuUntilLive_PVGTG92rDm",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_NL6aTk7sXb_PVGTG92rDm",
                    ...getCompositeDefaultProps("PVGTG92rDm"),
                  }}
                >
                  {props.HideMenuUntilLive}
                </HideMenuUntilLiveContext.Provider>
              ),
            ]}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

EditMenuSettings.MaxOrdersPerPickupTime = MaxOrdersPerPickupTime;
EditMenuSettings.ShowInventoryOnStorefront = ShowInventoryOnStorefront;
EditMenuSettings.HideMenuUntilLive = HideMenuUntilLive;
EditMenuSettings.__variants = [
  {
    name: "noSections",
    type: "variant",
  },
];

export default EditMenuSettings;
