// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./EventPublish.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";
import {
  default as Link,
  LabeledInputContext as LinkContext,
} from "../Events/LabeledInput";
import {
  default as PublishButton,
  ButtonContext as PublishButtonContext,
  default as QrCodeButton,
  ButtonContext as QrCodeButtonContext,
  default as CopyLinkButton,
  ButtonContext as CopyLinkButtonContext,
} from "../Primitives/Button";

const styles = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      GyRrWzRDiM: {
        none: {
          label: "Event Link",
        },
      },
      "8ozbzkrS7S": {
        none: {
          text: "Publish Event",
          large: true,
          kind: "good",
        },
      },
      KXoQKWH1yA: {
        none: {
          text: "QR Code",
          icon: icons.qr,
          rounded: true,
          withIcon: "large",
        },
      },
      JEJBnekQTR: {
        none: {
          text: "Copy Link",
          icon: icons.copy,
          rounded: true,
          withIcon: "large",
        },
      },
    },
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {
          GyRrWzRDiM: {},
          "8ozbzkrS7S": {},
          KXoQKWH1yA: {},
          JEJBnekQTR: {},
        },
      },
      {
        propValue: "small",
        layers: {
          GyRrWzRDiM: {},
          "8ozbzkrS7S": {},
          KXoQKWH1yA: {},
          JEJBnekQTR: {},
        },
      },
      {
        propValue: "med",
        layers: {
          GyRrWzRDiM: {},
          "8ozbzkrS7S": {},
          KXoQKWH1yA: {},
          JEJBnekQTR: {},
        },
      },
      {
        propValue: "large",
        layers: {
          GyRrWzRDiM: {},
          "8ozbzkrS7S": {},
          KXoQKWH1yA: {},
          JEJBnekQTR: {},
        },
      },
      {
        propValue: "xlarge",
        layers: {
          GyRrWzRDiM: {},
          "8ozbzkrS7S": {},
          KXoQKWH1yA: {},
          JEJBnekQTR: {},
        },
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export const EventPublishContext = createContext(null);

function EventPublish(_props) {
  const defaults = useContext(EventPublishContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="XDhDr9NTxz"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "XDhDr9NTxz",
        scope: "MUjAohTeoa",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <SpacerPrimitive
            id={"Spacer_XNYMAopjLs"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_MUjAohTeoa_XNYMAopjLs"
            }
            key={"XNYMAopjLs"}
          />
          <ContainerPrimitive
            id={"Container_6rdXxRi2hn"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_MUjAohTeoa_6rdXxRi2hn"
            }
            key={"6rdXxRi2hn"}
            addSpacing={false}
            element={getStyle("6rdXxRi2hn", "htmlElement")}
          >
            {[
              props.Link === undefined ? (
                <Link
                  key={"GyRrWzRDiM"}
                  {...getCompositeDefaultProps("GyRrWzRDiM")}
                  id="Link_GyRrWzRDiM"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_MUjAohTeoa_GyRrWzRDiM"
                />
              ) : (
                <LinkContext.Provider
                  key="GyRrWzRDiM-provider"
                  value={{
                    key: "GyRrWzRDiM",
                    id: "Link_GyRrWzRDiM",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_MUjAohTeoa_GyRrWzRDiM",
                    ...getCompositeDefaultProps("GyRrWzRDiM"),
                  }}
                >
                  {props.Link}
                </LinkContext.Provider>
              ),
              <SpacerPrimitive
                id={"Spacer_3nb2Zd4PJ5"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_MUjAohTeoa_3nb2Zd4PJ5"
                }
                key={"3nb2Zd4PJ5"}
              />,
              <ContainerPrimitive
                id={"Container_VdRitTM41P"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_MUjAohTeoa_VdRitTM41P"
                }
                key={"VdRitTM41P"}
                addSpacing={false}
                element={getStyle("VdRitTM41P", "htmlElement")}
              >
                {[
                  props.QrCodeButton === undefined ? (
                    <QrCodeButton
                      key={"KXoQKWH1yA"}
                      {...getCompositeDefaultProps("KXoQKWH1yA")}
                      id="QrCodeButton_KXoQKWH1yA"
                      className="__visly_reset_7X7HTLRqyD __visly_scope_MUjAohTeoa_KXoQKWH1yA"
                    />
                  ) : (
                    <QrCodeButtonContext.Provider
                      key="KXoQKWH1yA-provider"
                      value={{
                        key: "KXoQKWH1yA",
                        id: "QrCodeButton_KXoQKWH1yA",
                        className:
                          "__visly_reset_7X7HTLRqyD __visly_scope_MUjAohTeoa_KXoQKWH1yA",
                        ...getCompositeDefaultProps("KXoQKWH1yA"),
                      }}
                    >
                      {props.QrCodeButton}
                    </QrCodeButtonContext.Provider>
                  ),
                  <SpacerPrimitive
                    id={"Spacer_BkxPEUVwWP"}
                    className={
                      "__visly_reset_7X7HTLRqyD __visly_scope_MUjAohTeoa_BkxPEUVwWP"
                    }
                    key={"BkxPEUVwWP"}
                  />,
                  props.CopyLinkButton === undefined ? (
                    <CopyLinkButton
                      key={"JEJBnekQTR"}
                      {...getCompositeDefaultProps("JEJBnekQTR")}
                      id="CopyLinkButton_JEJBnekQTR"
                      className="__visly_reset_7X7HTLRqyD __visly_scope_MUjAohTeoa_JEJBnekQTR"
                    />
                  ) : (
                    <CopyLinkButtonContext.Provider
                      key="JEJBnekQTR-provider"
                      value={{
                        key: "JEJBnekQTR",
                        id: "CopyLinkButton_JEJBnekQTR",
                        className:
                          "__visly_reset_7X7HTLRqyD __visly_scope_MUjAohTeoa_JEJBnekQTR",
                        ...getCompositeDefaultProps("JEJBnekQTR"),
                      }}
                    >
                      {props.CopyLinkButton}
                    </CopyLinkButtonContext.Provider>
                  ),
                ]}
              </ContainerPrimitive>,
            ]}
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_pddKvAKA3t"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_MUjAohTeoa_pddKvAKA3t"
            }
            key={"pddKvAKA3t"}
          />
          <ContainerPrimitive
            id={"Container_VxAvwe8dHZ"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_MUjAohTeoa_VxAvwe8dHZ"
            }
            key={"VxAvwe8dHZ"}
            addSpacing={false}
            element={getStyle("VxAvwe8dHZ", "htmlElement")}
          >
            {props.PublishButton === undefined ? (
              <PublishButton
                key={"8ozbzkrS7S"}
                {...getCompositeDefaultProps("8ozbzkrS7S")}
                id="PublishButton_8ozbzkrS7S"
                className="__visly_reset_7X7HTLRqyD __visly_scope_MUjAohTeoa_8ozbzkrS7S"
              />
            ) : (
              <PublishButtonContext.Provider
                key="8ozbzkrS7S-provider"
                value={{
                  key: "8ozbzkrS7S",
                  id: "PublishButton_8ozbzkrS7S",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_MUjAohTeoa_8ozbzkrS7S",
                  ...getCompositeDefaultProps("8ozbzkrS7S"),
                }}
              >
                {props.PublishButton}
              </PublishButtonContext.Provider>
            )}
          </ContainerPrimitive>
          <ContainerPrimitive
            key={"JcPAwpbepB"}
            id={"Slot_JcPAwpbepB"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_MUjAohTeoa_JcPAwpbepB"
            }
            addSpacing={false}
          >
            {props.children}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

EventPublish.Link = Link;
EventPublish.PublishButton = PublishButton;
EventPublish.QrCodeButton = QrCodeButton;
EventPublish.CopyLinkButton = CopyLinkButton;
EventPublish.__variants = [
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
];

export default EventPublish;
