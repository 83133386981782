import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import Home from "./hotplate-storefront/Home";
import TermsOfService from "./hotplate-storefront/TermsOfService";
import PrivacyPolicy from "./hotplate-storefront/PrivacyPolicy";
import ReturnPolicy from "./hotplate-storefront/ReturnPolicy";
import Confirmation from "./hotplate-storefront/shop/Confirmation";
import HostPortal from "./hotplate-portal/HostPortal";
import Shop from "./hotplate-storefront/shop/Shop";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Inner = () => {
  const location = useLocation();
  useEffect(() => {
    window?.analytics?.page(
      location.pathname === "/"
        ? undefined
        : location.pathname.startsWith("/portal")
        ? "Portal"
        : "Ecommerce"
    );
  }, [location]);

  return (
    <SentryRoutes>
      <Route path="/">
        <Route index element={<Home />} />
        <Route path="post-signup" element={<Home />} />
        <Route path="_ah/warmup" element={<Home />} />
        <Route path="tos" element={<TermsOfService />} />
        <Route path="privacypolicy" element={<PrivacyPolicy />} />
        <Route path="returnpolicy" element={<ReturnPolicy />} />
        <Route path="confirmation" element={<Confirmation />} />
        <Route path="portal/*" element={<HostPortal />} />
        <Route path="*" element={<Shop />} />
      </Route>
    </SentryRoutes>
  );
};

const RouterComponent = () => {
  return (
    <BrowserRouter>
      <Inner />
    </BrowserRouter>
  );
};

export default RouterComponent;
