import * as PopoverPrimitive from "@radix-ui/react-popover";
import React from "react";
import { styled, keyframes } from "../../stitches.config";
import { Checkbox } from "./Checkbox";
import { Flex } from "./Containers";

const slideUpAndFadeIn = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFadeIn = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFadeIn = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFadeIn = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFadeOut = keyframes({
  "0%": { opacity: 1, transform: "translateY(0)" },
  "100%": { opacity: 0, transform: "translateY(2px)" },
});

const slideLeftAndFadeOut = keyframes({
  "0%": { opacity: 1, transform: "translateX(0)" },
  "100%": { opacity: 0, transform: "translateX(-2px)" },
});

const slideUpAndFadeOut = keyframes({
  "0%": { opacity: 1, transform: "translateY(0)" },
  "100%": { opacity: 0, transform: "translateY(-2px)" },
});

const slideRightAndFadeOut = keyframes({
  "0%": { opacity: 1, transform: "translateX(0)" },
  "100%": { opacity: 0, transform: "translateX(2px)" },
});

const StyledContent = styled(PopoverPrimitive.Content, {
  width: "calc(100vw - 32px)",
  borderRadius: 8,
  position: "relative",
  maxHeight: "min(600px, 60vh)",
  overflowY: "auto",
  overflowX: "hidden",
  backgroundColor: "white",
  boxShadow: "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  fontFamily: "var(--avenir)",
  fontSize: "14px",
  fontWeight: "500",
  color: "var(--gray700)",
  zIndex: "$popover",
  hideOnPrint: true,
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "200ms",
    animationTimingFunction: "ease-in-out",
    willChange: "transform, opacity",
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFadeIn },
      '&[data-side="right"]': { animationName: slideLeftAndFadeIn },
      '&[data-side="bottom"]': { animationName: slideUpAndFadeIn },
      '&[data-side="left"]': { animationName: slideRightAndFadeIn },
    },
    '&[data-state="closed"]': {
      '&[data-side="top"]': { animationName: slideUpAndFadeOut },
      '&[data-side="right"]': { animationName: slideRightAndFadeOut },
      '&[data-side="bottom"]': { animationName: slideDownAndFadeOut },
      '&[data-side="left"]': { animationName: slideLeftAndFadeOut },
    },
  },
  "& ::webkit-scrollbar": {
    display: "none",
  },
  "@tablet": {
    minWidth: 275,
    width: "fit-content",
    maxWidth: "90vw",
    borderRadius: 4,
  },
});

const StyledArrow = styled(PopoverPrimitive.Arrow, {
  fill: "white",
});

const StyledTrigger = styled(PopoverPrimitive.Trigger, {
  "&:focus": {
    outline: "none",
  },
});

const Box = styled("div", {});

const LineItemPrimitive = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderBottom: "1px solid var(--gray100)",
  flexShrink: 0,
  padding: "10px 14px 8px",
  height: 46,
  width: "100%",
  lh: "$heading",
  backgroundColor: "transparent",
  fontFamily: "var(--avenir)",
  fontWeight: "400",
  "& h3": {
    fontSize: "14px",
    color: "var(--gray800)",
    textAlign: "left",
    whiteSpace: "nowrap",
    maxWidth: "40ch",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& p": {
    fontSize: "12px",
    color: "var(--gray600)",
    whiteSpace: "nowrap",
    maxWidth: "24ch",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cursor: "pointer",
  userSelect: "none",
  transition: "background-color 100ms ease-in-out",
  "&:hover": {
    backgroundColor: "var(--gray050)",
  },
  "&:focus": {
    outline: "none",
  },
  variants: {
    selected: {
      true: {
        backgroundColor: "var(--gray100)",
        "&:hover": {
          backgroundColor: "var(--gray100)",
        },
      },
    },
  },
  "@tablet": {
    height: 36,
    border: "none",
    "& p": {
      maxWidth: "15ch",
    },
    "& h3": {
      maxWidth: "30ch",
    },
  },
});

const PopoverLineItem = ({ title, subtitle, showCheckbox, onClick, selected }) => {
  return (
    <LineItemPrimitive onClick={onClick} selected={selected}>
      {showCheckbox && <Checkbox css={{ marginRight: 8 }} size="small" checked={selected} />}
      <Flex
        css={{
          justifyContent: "space-between",
          alignItems: "baseline",
          flex: 1,
          minWidth: "0",
        }}
      >
        <h3>{title}</h3>
        {subtitle && <p>{subtitle}</p>}
      </Flex>
    </LineItemPrimitive>
  );
};

const PopoverSaveButton = styled("button", {
  display: "flex",
  justifyContent: "center",
  position: "sticky",
  bottom: "14px",
  margin: "14px auto 8px",
  padding: "8px 14px 6px",
  width: "calc(100% - 28px)",
  zIndex: "500",
  backgroundColor: "var(--secondary500)",
  border: "1px solid var(--secondary600)",
  boxShadow: "var(--elevation4-shadow)",
  borderRadius: "6px",
  fontFamily: "var(--avenir)",
  fontSize: "13px",
  fontWeight: "500",
  color: "var(--white)",
  "&:disabled": {
    backgroundColor: "var(--secondary200)",
    border: "1px solid var(--secondary200)",
    cursor: "default",
  },
});

const PopoverTrigger = StyledTrigger;
const PopoverArrow = StyledArrow;
const PopoverClose = PopoverPrimitive.Close;
const ContentPortal = PopoverPrimitive.Portal;

const PopoverContent = ({ closeButton, id, children }) => {
  return (
    <ContentPortal>
      <StyledContent
        align={"center"}
        avoidCollisions={true}
        collisionPadding={8}
        arrowPadding={8}
        hideWhenDetached={true}
        id={id}
      >
        {children}
        <PopoverClose aria-label="close" asChild>
          {closeButton}
        </PopoverClose>

        {/* // TODO: Arrow currently does not render because <Content/> has overflowY: auto && the arrow falls outside */}
        <PopoverArrow />
      </StyledContent>
    </ContentPortal>
  );
};

export const Popover = ({ trigger, children, open, close, closeButton, id, ...props }) => {
  return (
    <PopoverPrimitive.Root
      {...props}
      open={open}
      onOpenChange={(open) => {
        !open && close && close();
      }}
    >
      {/* Provides custom anchor on mobile devices */}
      {window.innerWidth < 576 && (
        <PopoverPrimitive.Anchor asChild>
          <Box
            css={{
              position: "fixed",
              top: "5vh",
              left: "50%",
              right: "50%",
            }}
          />
        </PopoverPrimitive.Anchor>
      )}

      <PopoverTrigger aria-label="open" css={{ display: "flex" }}>
        {trigger}
      </PopoverTrigger>
      <PopoverContent id={id} closeButton={closeButton}>
        {children}
      </PopoverContent>
    </PopoverPrimitive.Root>
  );
};

Popover.LineItem = PopoverLineItem;
Popover.SaveButton = PopoverSaveButton;
