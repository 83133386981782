// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./LocationCard.SelectLocationButton.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      MmLZQvm6EX: {
        none: {
          text: "Location title",
        },
      },
      Bx6pZ3544W: {
        none: {
          text: "Address subtitle",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "selected",
  },
];

export const SelectLocationButtonContext = createContext(null);

function SelectLocationButton(_props) {
  const defaults = useContext(SelectLocationButtonContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="UMDAfVvdeu"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "UMDAfVvdeu",
        scope: "EMKS37etWS",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"time-location-container_VvstoJZzmr"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_EMKS37etWS_VvstoJZzmr"
            }
            key={"VvstoJZzmr"}
            addSpacing={false}
            element={getStyle("VvstoJZzmr", "htmlElement")}
          >
            <ContainerPrimitive
              id={"Container_Jr9rYuYs67"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_EMKS37etWS_Jr9rYuYs67"
              }
              key={"Jr9rYuYs67"}
              addSpacing={false}
              element={getStyle("Jr9rYuYs67", "htmlElement")}
            >
              {
                <TextPrimitive
                  id={"title_MmLZQvm6EX"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_EMKS37etWS_MmLZQvm6EX"
                  }
                  key={"MmLZQvm6EX"}
                  text={
                    exists(props.title)
                      ? props.title
                      : getStyle("MmLZQvm6EX", "text")
                  }
                  element={getStyle("MmLZQvm6EX", "htmlElement")}
                />
              }
            </ContainerPrimitive>
            <SpacerPrimitive
              id={"Spacer_KBhU8JA74W"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_EMKS37etWS_KBhU8JA74W"
              }
              key={"KBhU8JA74W"}
            />
            <TextPrimitive
              id={"subtitle_Bx6pZ3544W"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_EMKS37etWS_Bx6pZ3544W"
              }
              key={"Bx6pZ3544W"}
              text={
                exists(props.subtitle)
                  ? props.subtitle
                  : getStyle("Bx6pZ3544W", "text")
              }
              element={getStyle("Bx6pZ3544W", "htmlElement")}
            />
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_8SuFhngMm9"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_EMKS37etWS_8SuFhngMm9"
            }
            key={"8SuFhngMm9"}
          />
        </>
      )}
    </RootPrimitive>
  );
}

SelectLocationButton.__variants = [
  {
    name: "selected",
    type: "variant",
  },
];

export default SelectLocationButton;
