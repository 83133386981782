// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./PlanCard.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      "3vcPhaenWL": {
        none: {
          text: "$30",
        },
      },
      "6oFHKH8zdY": {
        none: {
          text: "/mo",
        },
      },
      "883Enim1mk": {
        none: {
          text: "5%",
        },
      },
      "5EnGo5oA7Z": {
        none: {
          text: "transaction fee paid by customers at checkout",
        },
      },
      MEvXePVq3C: {
        none: {
          text: "Plan 1",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "selected",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "selected",
  },
];

export const PlanCardContext = createContext(null);

function PlanCard(_props) {
  const defaults = useContext(PlanCardContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="5oNA4ftokb"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "5oNA4ftokb",
        scope: "RMhaubrYcK",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <TextPrimitive
            id={"title_MEvXePVq3C"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_RMhaubrYcK_MEvXePVq3C"
            }
            key={"MEvXePVq3C"}
            text={
              exists(props.title) ? props.title : getStyle("MEvXePVq3C", "text")
            }
            element={getStyle("MEvXePVq3C", "htmlElement")}
          />
          <SpacerPrimitive
            id={"Spacer_GXKXy8SiWu"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_RMhaubrYcK_GXKXy8SiWu"
            }
            key={"GXKXy8SiWu"}
          />
          <ContainerPrimitive
            id={"chef-price-container_8u3QmgK8kH"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_RMhaubrYcK_8u3QmgK8kH"
            }
            key={"8u3QmgK8kH"}
            addSpacing={false}
            element={getStyle("8u3QmgK8kH", "htmlElement")}
          >
            <TextPrimitive
              id={"priceToChef_3vcPhaenWL"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_RMhaubrYcK_3vcPhaenWL"
              }
              key={"3vcPhaenWL"}
              text={
                exists(props.priceToChef)
                  ? props.priceToChef
                  : getStyle("3vcPhaenWL", "text")
              }
              element={getStyle("3vcPhaenWL", "htmlElement")}
            />
            <TextPrimitive
              id={"/mo_6oFHKH8zdY"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_RMhaubrYcK_6oFHKH8zdY"
              }
              key={"6oFHKH8zdY"}
              text={getStyle("6oFHKH8zdY", "text")}
              element={getStyle("6oFHKH8zdY", "htmlElement")}
            />
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_DJSz8dzahi"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_RMhaubrYcK_DJSz8dzahi"
            }
            key={"DJSz8dzahi"}
          />
          <ContainerPrimitive
            id={"hotplate-fee-container_93sj48y9Z6"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_RMhaubrYcK_93sj48y9Z6"
            }
            key={"93sj48y9Z6"}
            addSpacing={false}
            element={getStyle("93sj48y9Z6", "htmlElement")}
          >
            <TextPrimitive
              id={"hotplatePercentFee_883Enim1mk"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_RMhaubrYcK_883Enim1mk"
              }
              key={"883Enim1mk"}
              text={
                exists(props.hotplatePercentFee)
                  ? props.hotplatePercentFee
                  : getStyle("883Enim1mk", "text")
              }
              element={getStyle("883Enim1mk", "htmlElement")}
            />
            <SpacerPrimitive
              id={"Spacer_KSHoCZZW6Z"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_RMhaubrYcK_KSHoCZZW6Z"
              }
              key={"KSHoCZZW6Z"}
            />
            <TextPrimitive
              id={"customers_5EnGo5oA7Z"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_RMhaubrYcK_5EnGo5oA7Z"
              }
              key={"5EnGo5oA7Z"}
              text={getStyle("5EnGo5oA7Z", "text")}
              element={getStyle("5EnGo5oA7Z", "htmlElement")}
            />
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

PlanCard.__variants = [
  {
    name: "selected",
    type: "variant",
  },
];

export default PlanCard;
