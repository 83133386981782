import { ChatBubbleIcon, ClipboardCopyIcon, EnvelopeClosedIcon } from "@radix-ui/react-icons";
import React, { useState } from "react";
import { usePortalUser } from "../../auth";
import { Flex, Row } from "../../hotplate-common/primitives/Containers";
import { IconButton } from "../../hotplate-common/primitives/IconButton";

export const Support = () => {
  const [phoneCopied, setPhoneCopied] = useState(false);
  const [emailCopied, setEmailCopied] = useState(false);
  const { chefId } = usePortalUser();
  function getSupportPhone() {
    return "+12148339248";
  }

  function getSupportEmail() {
    return "rishi@hotplate.com";
  }
  return (
    <>
      <Row css={{ alignItems: "center", marginBottom: "$md", gap: "$sm" }}>
        <Flex css={{ ff: "$avenir", fs: 14, fw: "$normal", lh: "$text", color: "$gray12" }}>
          Text or call us: (214) 833-9248{" "}
        </Flex>
        <IconButton size="medium" as="a" href="sms:2148339248" icon={<ChatBubbleIcon />} />
        <IconButton
          size="medium"
          color={phoneCopied ? "filled_success" : "default"}
          icon={<ClipboardCopyIcon />}
          onClick={() =>
            navigator.clipboard.writeText(getSupportPhone()).then(
              () => {
                setPhoneCopied(true);
                setTimeout(() => {
                  setPhoneCopied(false);
                }, 3000);
              },
              () => {
                console.log("error copying support phone");
              }
            )
          }
        />
      </Row>
      <Row css={{ alignItems: "center", gap: "$sm" }}>
        <Flex css={{ ff: "$avenir", fs: 14, fw: "$normal", lh: "$text", color: "$gray12" }}>
          Email Rishi, one of Hotplate&apos;s founders: rishi@hotplate.com
        </Flex>
        <IconButton
          size="medium"
          as="a"
          href={`mailto:rishi@hotplate.com?subject=Support%20Request%20-%20${chefId}`}
          icon={<EnvelopeClosedIcon />}
        />
        <IconButton
          size="medium"
          color={emailCopied ? "filled_success" : "default"}
          icon={<ClipboardCopyIcon />}
          onClick={() =>
            navigator.clipboard.writeText(getSupportEmail()).then(
              () => {
                setEmailCopied(true);
                setTimeout(() => {
                  setEmailCopied(false);
                }, 3000);
              },
              () => {
                console.log("error copying support email");
              }
            )
          }
        />
      </Row>
    </>
  );
};
