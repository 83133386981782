/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";
import EventCard from "../../hotplate-common/EventCard";
import DuplicateEventModal from "./eventOverview/DuplicateEventModal";
import EventConfigurationMaster from "./eventConfiguration";
import {
  startPortalLoad,
  endPortalLoad,
  clearPortalLoad,
} from "../../hotplate-common/HelperFunctions";
import { getEventStatus, getSortedTimeWindows } from "@hotplate/utils-ts/helperFunctions";
import _ from "lodash";
import { styled } from "../../stitches.config";

import { EventOverview } from "../../visly/Events";
import { useBreakpoint } from "../../visly";

import { FirebaseContext } from "../../firebaseSocket";

import { useDetectDeletion, useToggle } from "../../hooks";

import "./css/index.css";
import { Center, Flex, H1, H2 } from "../../hotplate-common/primitives/Containers";
import { Button } from "../../hotplate-common/primitives/Button";
import { PuffLoader } from "../../hotplate-common/loaders/PuffLoader";
import { trackEventCreated, trackEventGroupExpanded } from "./eventOverview/analytics";
import { usePortalUser } from "../../auth";

/**
 * Create a new event, optionally providing an existing event object to
 * copy from.
 * @param {object} [eventToDuplicate]
 */
function createNewEvent(eventToDuplicate) {
  const newEventId = uuid();

  if (eventToDuplicate) {
    const cloned = _.cloneDeep(eventToDuplicate); // cloneDeep shouldn't be necessary but is for good measure
    const menuItems = cloned.menuItems || {};
    for (const menuItemId in menuItems) {
      cloned.menuItems[menuItemId].inventory =
        cloned.menuItems[menuItemId].inventoryOriginal === undefined
          ? ""
          : cloned.menuItems[menuItemId].inventoryOriginal;
      cloned.menuItems[menuItemId].inventoryModified = 0;
      cloned.menuItems[menuItemId].inventoryReserved = 0;
      cloned.menuItems[menuItemId].inventorySold = 0;
    }
    delete cloned.reservations;
    return {
      ...cloned,
      id: newEventId,
      title: eventToDuplicate.title + " (COPY)",
      draft: true,
      remindersSent: 0,
      numRemindersSent: 0,
      timeRemindersSent: undefined,
    };
  }

  const beginningOfToday = new Date();
  beginningOfToday.setHours(0, 0, 0, 0);

  const orderCutoffTime = new Date(beginningOfToday.getTime() + 1000 * 60 * 60 * 24 * 3).getTime();

  return {
    id: newEventId,
    draft: true,
    title: "",
    onDemandEnabled: false,
    pickupEnabled: true,
    orderCutoffType: "Never",
    orderCutoffHoursBefore: "",
    orderCutoffTime: orderCutoffTime, // unix time in milliseconds,
    orderCutoffDayOfWeek: null,
    recurring: false,
    recurringCount: 1, // 1 week out
    checkoutTime: 5 * 60 * 1000, // 5 min
    private: false,
    goLiveTime: new Date().getTime(), // unix time in milliseconds
    timeSlotCadence: "Anytime between",
    timeWindows: null,
    locations: null,
  };
}

function getSortedEvents(events) {
  if (!events || events.constructor != Object) return [];
  const sortedEvents = [];
  Object.keys(events).forEach((key) => {
    sortedEvents.push(events[key]);
  });
  const statusPriority = { live: 0, scheduled: 1, draft: 2, complete: 3 };
  return sortedEvents.sort((eventA, eventB) => {
    const aStatus = getEventStatus(eventA);
    const bStatus = getEventStatus(eventB);
    if (aStatus === bStatus) {
      const timeWindowsA = getSortedTimeWindows(eventA.timeWindows);
      let firstTimeA = 0;
      if (timeWindowsA.length > 0) {
        firstTimeA = timeWindowsA.sort((a, b) => {
          return a.startTime - b.startTime;
        })[timeWindowsA.length - 1].startTime;
      }
      const timeWindowsB = getSortedTimeWindows(eventB.timeWindows);
      let firstTimeB = 0;
      if (timeWindowsB.length > 0) {
        firstTimeB = timeWindowsB.sort((a, b) => {
          return a.startTime - b.startTime;
        })[timeWindowsB.length - 1].startTime;
      }
      if (aStatus === "complete") return firstTimeB - firstTimeA;
      return firstTimeA - firstTimeB;
    }
    return statusPriority[aStatus] - statusPriority[bStatus];
  });
}

const Title = styled("h1", {
  display: "flex",
  ff: "$arboria",
  fw: 500,
  fs: "$lg",
  marginTop: "0.75rem",
  color: "$gray12",
  lh: "$article",
});

const Subtitle = styled("h2", {
  ff: "$avenir",
  fw: "$normal",
  fs: "$sm",
  lh: "$heading",
  color: "$gray11",
});

const OverviewHeader = ({ title, desc, numEvents, open, toggleOpen }) => {
  return (
    <Flex
      css={{ justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}
      onClick={() => toggleOpen()}
    >
      <Flex css={{ flexDirection: "column" }}>
        <Title>
          <H1>{title}</H1>
          <H2 css={{ color: "$gray10", marginLeft: 8 }}>{`• ${numEvents}`}</H2>
        </Title>
        <Subtitle>{desc}</Subtitle>
      </Flex>
      <Button variant="gray" color="gray">
        {open ? "Hide" : "Show"}
      </Button>
    </Flex>
  );
};

export default connect((state) => {
  return {
    events: state.events.events,
    connectToPortalEventsLoading: state.events.connectToPortalEventsLoading,
  };
}, {})(function EventMaster({
  events,
  connectToPortalEventsLoading,

  setConfirmModuleUnmount,
}) {
  const { chefId } = usePortalUser();
  const [showDuplicateEventModal, setShowDuplicateEventModal] = useState(false);
  const [idOfEventBeingEdited, setIdOfEventBeingEdited] = useState(null);
  const [newEvent, setNewEvent] = useState(null);
  const firebaseContext = useContext(FirebaseContext);
  const [showLive, toggleShowLive] = useToggle(true);
  const [showDraft, toggleShowDraft] = useToggle(true);
  const [showComplete, toggleShowComplete] = useToggle(false);

  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);

  const eventBeingEditedWasDeleted = useDetectDeletion(events, idOfEventBeingEdited);
  if (eventBeingEditedWasDeleted) {
    // we don't even allow deletion of events via UI, but just in case...
    setIdOfEventBeingEdited(null);
  }

  const sortedEvents = getSortedEvents(events);

  // * filter sorted events by status (live, scheduled, draft, complete)
  const sortedLiveScheduledEvents = sortedEvents.filter(
    (event) => getEventStatus(event) === "live" || getEventStatus(event) === "scheduled"
  );
  const sortedDraftEvents = sortedEvents.filter(
    (event) => getEventStatus(event) === "draft" && !event.archived
  );
  const sortedCompleteEvents = sortedEvents.filter((event) => getEventStatus(event) === "complete");

  useEffect(() => {
    firebaseContext.api.connectToPortalEvents(chefId);
    return () => {
      firebaseContext.api.disconnectFromPortalEvents(chefId);
      clearPortalLoad();
    };
  }, [chefId, firebaseContext.api]);

  useEffect(() => {
    if (connectToPortalEventsLoading) {
      startPortalLoad();
    } else {
      endPortalLoad();
    }
  }, [connectToPortalEventsLoading]);

  useEffect(() => {
    if (newEvent && events?.[idOfEventBeingEdited]) {
      setNewEvent(null);
    }
  }, [events, newEvent, idOfEventBeingEdited]);

  function createAndSwitchToNewEvent(eventToDuplicate) {
    const newEvent = createNewEvent(eventToDuplicate);
    setNewEvent(newEvent);
    setIdOfEventBeingEdited(newEvent.id);
  }

  return (
    <>
      {newEvent || idOfEventBeingEdited ? (
        <EventConfigurationMaster
          initialEventDiff={newEvent}
          event={events?.[idOfEventBeingEdited] || {}}
          isNewEvent={newEvent !== null}
          setConfirmModuleUnmount={setConfirmModuleUnmount}
          onRequestClose={() => {
            setNewEvent(null); // throw away newly generated event, if any
            setIdOfEventBeingEdited(null);
          }}
        />
      ) : (
        <EventOverview
          className={
            sortedEvents && sortedEvents.length > 0 ? "eventOverview" : "no-events-container"
          }
          size={size}
          title="Your Events"
          isEmpty={sortedEvents.length === 0}
          createEventButton={
            sortedEvents.length !== 0 && (
              <EventOverview.CreateEventButton
                label="Create"
                selectedKey={-1}
                onSelectionChange={(key) => {
                  if (key === "Create New") {
                    createAndSwitchToNewEvent();
                    trackEventCreated();
                  } else if (key === "Duplicate") {
                    setShowDuplicateEventModal(true);
                  }
                }}
              >
                <EventOverview.CreateEventButton.Option key={"Create New"} label={"Create New"} />
                <EventOverview.CreateEventButton.Option key={"Duplicate"} label={"Duplicate"} />
              </EventOverview.CreateEventButton>
            )
          }
        >
          {connectToPortalEventsLoading ? (
            <Center css={{ width: "100%", height: "100%" }}>
              <PuffLoader />
            </Center>
          ) : sortedEvents.length === 0 ? (
            <EventOverview.EmptyEventsCta
              CreateFirstEventButton={
                <EventOverview.EmptyEventsCta.CreateFirstEventButton
                  onClick={() => createAndSwitchToNewEvent()}
                />
              }
            />
          ) : (
            <div className="event-overview-grid">
              {/* Live && scheduled events */}
              {sortedLiveScheduledEvents.length > 0 && (
                <>
                  <OverviewHeader
                    title="Live"
                    desc="Events visible on your storefront"
                    numEvents={sortedLiveScheduledEvents.length}
                    open={showLive}
                    toggleOpen={() => {
                      toggleShowLive();
                      trackEventGroupExpanded({
                        group: "live",
                        expanded: !showLive,
                      });
                    }}
                  />
                  {showLive && (
                    <div className="event-overview-subgrid">
                      {sortedLiveScheduledEvents.map((event, eventIndex) => (
                        <EventCard
                          type="overview"
                          event={event}
                          key={eventIndex}
                          onClick={() => {
                            setIdOfEventBeingEdited(event.id);
                          }}
                        />
                      ))}
                    </div>
                  )}
                </>
              )}
              {/* Draft events */}
              {sortedDraftEvents.length > 0 && (
                <>
                  <OverviewHeader
                    title="Drafts"
                    desc="Unfinished events that you have not yet published"
                    numEvents={sortedDraftEvents.length}
                    open={showDraft}
                    toggleOpen={() => {
                      toggleShowDraft();
                      trackEventGroupExpanded({
                        group: "draft",
                        expanded: !showDraft,
                      });
                    }}
                  />
                  {showDraft && (
                    <div className="event-overview-subgrid">
                      {sortedDraftEvents.map((event, eventIndex) => (
                        <EventCard
                          type="overview"
                          event={event}
                          key={eventIndex}
                          onClick={() => {
                            setIdOfEventBeingEdited(event.id);
                          }}
                        />
                      ))}
                    </div>
                  )}
                </>
              )}
              {/* Complete events */}
              {sortedCompleteEvents.length > 0 && (
                <>
                  <OverviewHeader
                    title="Complete"
                    desc="Orders closed, click on any event to see the stats on your
                  sale"
                    numEvents={sortedCompleteEvents.length}
                    open={showComplete}
                    toggleOpen={() => {
                      toggleShowComplete();
                      trackEventGroupExpanded({
                        group: "complete",
                        expanded: !showComplete,
                      });
                    }}
                  />
                  {showComplete && (
                    <div className="event-overview-subgrid">
                      {sortedCompleteEvents.map((event, eventIndex) => (
                        <EventCard
                          type="overview"
                          event={event}
                          key={eventIndex}
                          onClick={() => {
                            setIdOfEventBeingEdited(event.id);
                          }}
                        />
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </EventOverview>
      )}
      {showDuplicateEventModal && (
        <DuplicateEventModal
          events={sortedEvents}
          onClose={() => {
            setShowDuplicateEventModal(false);
          }}
          onSelect={(selectedEventIdx) => {
            createAndSwitchToNewEvent(sortedEvents[selectedEventIdx]);
            setShowDuplicateEventModal(false);
          }}
        />
      )}
    </>
  );
});
