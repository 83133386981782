import { ArrowUpIcon } from "@radix-ui/react-icons";
import React, { useLayoutEffect, useRef, useState } from "react";
import { useEphemeralChat } from "../../hooks";
import { Button } from "../../hotplate-common/primitives/Button";
import { Column, P, Row, Span } from "../../hotplate-common/primitives/Containers";
import { styled } from "../../stitches.config";
import { ScrollArea } from "../../hotplate-common/primitives/ScrollArea";
import _ from "lodash";
import { Badge } from "../../hotplate-common/primitives/Badge";
import AnimationContainer from "../../hotplate-common/AnimationContainer";
import { trackChatMessageRejected, trackChatMessageSent } from "../analytics";
import { useParams, useSearchParams } from "react-router-dom";

const InputWrapper = styled("div", {
  position: "relative",
  display: "flex",
  ai: "center",
  padl: "$sm",
  padr: "$xxs",
  br: "$md",
  ff: "$inter",
  textStyle: "text-2",
  color: "$gray12",
  outlineOffset: "-1px",
  outline: "1px solid transparent",
  border: "1px solid $gray8",
  transition: "outline 100ms ease-in-out, border 100ms ease-in-out",
  "&::placeholder": {
    color: "$gray9",
  },
  "&:focus-within": { outline: `2px solid $accent9` },
});

const ChatInput = styled("input", {
  all: "unset",
  display: "flex",
  position: "relative",
  outline: "1px solid transparent",
  minWidth: 0,
  overflow: "hidden",
  textOverflow: "ellipsis",
  flex: 1,
  cursor: "text",
  height: "$sm_btn",
});

function usernameToColor(username: string) {
  if (!username) return "#000000"; // an example of types letting us down because username was not guaranteed despite the type system thinking it was
  let hash = 0;
  for (let i = 0; i < username.length; i++) {
    hash = username.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
}

export function EphemeralChat({ channelId }: { channelId: string }) {
  const [messageText, setMessageText] = useState("");
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);
  const [throttle] = useState(() => {
    return _.throttle(
      (func) => {
        return func();
      },
      100,
      { leading: true, trailing: true }
    );
  });
  const { isConnected, userCount, messages, sendMessage } = useEphemeralChat(channelId);
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const pathParams = useParams();
  const [searchParams] = useSearchParams();
  const chefId =
    searchParams.get("chef") || // For Reflect.run tests
    pathParams.chefId?.toLowerCase();

  function checkAndSendMessage(message: string) {
    // ! add profanity filter here
    if (message.length > 0) {
      sendMessage(message);
      trackChatMessageSent(chefId);
      setMessageText("");
    }
    // ! track when message rejected
    // if (didFailProfanityCheck) {
    //     trackChatMessageRejected(chefId, "profanity");
    // }
  }

  useLayoutEffect(() => {
    if (isAutoScrollEnabled && scrollAreaRef.current) {
      // Scroll to bottom
      scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight;
    }
  });

  return (
    <Column css={{ flexGrow: 1, ff: "$inter" }}>
      <Row
        css={{
          w: "$full",
          jc: "space-between",
          ai: "baseline",
          mt: "$xs",
          padx: "$sm",
          position: "relative",
          backgrouncColor: "$white",
          "&::after": {
            content: "",
            position: "absolute",
            bottom: -24,
            left: 0,
            right: 8,
            h: "$xs_btn",
            w: "calc(100% - 8px)",
            zIndex: "$fixed",
            background:
              "linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 29%, rgba(255,255,255,1) 100%)",
          },
        }}
      >
        <Badge
          color="light_gray"
          css={{ textStyle: "text-1", color: "$gray10", ai: "center", backgroundColor: "$gray2" }}
        >
          <AnimationContainer
            animation={isConnected ? "blob-green" : "blob"}
            height="10px"
            width="10px"
          />
          <Span css={{ ml: "$xs", nudgeY: 0.5 }}>{isConnected ? "Connected" : "Disconnected"}</Span>
        </Badge>
        <Badge color="light_danger" size="medium">
          <Span css={{ fw: "$semi_bold", mr: ".75ch" }}>🔥 {userCount}</Span> here
        </Badge>
      </Row>
      <ScrollArea
        css={{
          height: "min(400px, 25vh)", // ! THIS IS ABSOLUTE TRASH STYLING, FIX THIS LATER, works for now but is not super responsive (check it in 1280x720 & then shrink the height )
          "& > div": {
            overscrollBehavior: "contain",
          },
        }}
        id="chat"
        onScroll={(e) => {
          e.persist();
          const element = e.currentTarget;
          throttle(() => {
            if (element.scrollTop >= element.scrollHeight - element.clientHeight - 1) {
              // The user scrolled to the bottom of the container.
              setIsAutoScrollEnabled(true);
            } else {
              setIsAutoScrollEnabled(false);
            }
          });
        }}
        ref={scrollAreaRef}
      >
        <P
          css={{
            color: "$gray10",
            textStyle: "text-1",
            textAlign: "center",
            maxW: "45ch",
            padx: "$md",
            mt: "$lg",
            mx: "auto",
          }}
        >
          Welcome to the chat! <br />
          Type a message to talk to everyone waiting for the drop 🔥
        </P>
        {messages.map((message, i) => (
          <Row
            key={i}
            css={{
              pady: "$xxs",
              padl: "$xs",
              mx: "$xxs",
              padr: "$md",
              textStyle: "text-2",
              lh: "1.5",
              gap: "0.5ch",
              br: "$xs",
              flexWrap: "wrap",
              backgroundColor: "transparent",
              "&:hover": { backgroundColor: "$gray3" },
            }}
          >
            <P css={{ fw: "$bold", color: usernameToColor(message.username) }}>
              {message.username}:
            </P>
            <Span>{message.content}</Span>
          </Row>
        ))}
      </ScrollArea>
      <Column css={{ padx: "$sm" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            checkAndSendMessage(messageText);
          }}
        >
          <InputWrapper>
            <ChatInput
              type="text"
              placeholder="Type here..."
              maxLength={500}
              value={messageText}
              onChange={(e) => {
                setMessageText(e.target.value);
              }}
            />
            {messageText.length > 0 && (
              <Button
                variant="tinted"
                color="accent"
                shape="rounded"
                size="xsmall"
                css={{ "@desktop_md": { display: "none" } }}
              >
                <ArrowUpIcon />
                Send
              </Button>
            )}
          </InputWrapper>
        </form>
      </Column>
      {/* Autoscroll: {"" + !!isAutoScrollEnabled} */}
    </Column>
  );
}
