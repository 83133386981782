import React from "react";
import { Center, P } from "../../hotplate-common/primitives/Containers";
import { TypeformButton } from "../components/TypeformButton";

export const FeedbackChart = () => {
  return (
    <Center
      css={{
        width: "100%",
        height: "100%",
        flexDirection: "column",
        gap: "$lg",
        backgroundColor: "$gray2",
        borderRadius: "$md",
        padding: "$lg",
        boxShadow: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
      }}
    >
      <P
        css={{
          fontFamily: "$avenir",
          fontSize: "$md",
          fontWeight: "$semi_bold",
          color: "$gray12",
          lineHeight: "$text",
          textAlign: "center",
          maxWidth: "32ch",
        }}
      >
        Craving more Insights? Tell us what you want to see next!
      </P>
      <TypeformButton
        formId="lRbA3rzF"
        testFormId="PNZ4SE3i"
        featureName="Insights"
        text="Suggest an Insight"
        css={{ minWidth: 200, width: "50%" }}
      />
    </Center>
  );
};
