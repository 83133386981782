// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./ObjectSelectionModal.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import {
  default as CancelButton,
  ButtonContext as CancelButtonContext,
  default as AddButton,
  ButtonContext as AddButtonContext,
  default as CreateButton,
  ButtonContext as CreateButtonContext,
} from "../Primitives/Button";

const styles = [
  {
    type: "default",
    layers: {
      MyEvVfni3A: {
        none: {
          text: "Modal Header",
        },
      },
      "8RjnzMBHiJ": {
        none: {
          htmlElement: "footer",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "isEmpty",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      "6u34hk6UYB": {
        none: {
          text: "Cancel",
          shadow: true,
        },
      },
      UDzEi9gpJS: {
        none: {
          text: "Add",
          kind: "primary",
          shadow: true,
        },
      },
      JdiK2b8SPr: {
        none: {
          text: "Create",
          rounded: true,
          kind: "secondary",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "isEmpty",
    layers: {
      "6u34hk6UYB": {},
      UDzEi9gpJS: {},
      JdiK2b8SPr: {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "isEmpty",
  },
];

export const ObjectSelectionModalContext = createContext(null);

function ObjectSelectionModal(_props) {
  const defaults = useContext(ObjectSelectionModalContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="GYgJqdxa65"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "GYgJqdxa65",
        scope: "5SS74a2WH7",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"header-container_3fbyrM22Ki"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_5SS74a2WH7_3fbyrM22Ki"
            }
            key={"3fbyrM22Ki"}
            addSpacing={false}
            element={getStyle("3fbyrM22Ki", "htmlElement")}
          >
            {
              <ContainerPrimitive
                id={"title-subtitle-create-container_FttkqZVKNF"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_5SS74a2WH7_FttkqZVKNF"
                }
                key={"FttkqZVKNF"}
                addSpacing={false}
                element={getStyle("FttkqZVKNF", "htmlElement")}
              >
                <ContainerPrimitive
                  id={"title-create-container_AvnHypcGih"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_5SS74a2WH7_AvnHypcGih"
                  }
                  key={"AvnHypcGih"}
                  addSpacing={false}
                  element={getStyle("AvnHypcGih", "htmlElement")}
                >
                  {[
                    <TextPrimitive
                      id={"header_MyEvVfni3A"}
                      className={
                        "__visly_reset_7X7HTLRqyD __visly_scope_5SS74a2WH7_MyEvVfni3A"
                      }
                      key={"MyEvVfni3A"}
                      text={
                        exists(props.header)
                          ? props.header
                          : getStyle("MyEvVfni3A", "text")
                      }
                      element={getStyle("MyEvVfni3A", "htmlElement")}
                    />,
                    <SpacerPrimitive
                      id={"Spacer_ChRutepooA"}
                      className={
                        "__visly_reset_7X7HTLRqyD __visly_scope_5SS74a2WH7_ChRutepooA"
                      }
                      key={"ChRutepooA"}
                    />,
                    props.CreateButton === undefined ? (
                      <CreateButton
                        key={"JdiK2b8SPr"}
                        {...getCompositeDefaultProps("JdiK2b8SPr")}
                        id="CreateButton_JdiK2b8SPr"
                        className="__visly_reset_7X7HTLRqyD __visly_scope_5SS74a2WH7_JdiK2b8SPr"
                      />
                    ) : (
                      <CreateButtonContext.Provider
                        key="JdiK2b8SPr-provider"
                        value={{
                          key: "JdiK2b8SPr",
                          id: "CreateButton_JdiK2b8SPr",
                          className:
                            "__visly_reset_7X7HTLRqyD __visly_scope_5SS74a2WH7_JdiK2b8SPr",
                          ...getCompositeDefaultProps("JdiK2b8SPr"),
                        }}
                      >
                        {props.CreateButton}
                      </CreateButtonContext.Provider>
                    ),
                  ]}
                </ContainerPrimitive>
                <ContainerPrimitive
                  key={"RqbYcUu4X9"}
                  id={"Slot_RqbYcUu4X9"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_5SS74a2WH7_RqbYcUu4X9"
                  }
                  addSpacing={false}
                >
                  {props.subHeader}
                </ContainerPrimitive>
              </ContainerPrimitive>
            }
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"scrollbox_Fjo2d1cERs"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_5SS74a2WH7_Fjo2d1cERs"
            }
            key={"Fjo2d1cERs"}
            addSpacing={false}
            element={getStyle("Fjo2d1cERs", "htmlElement")}
          >
            <ContainerPrimitive
              key={"GYKLM2Sawj"}
              id={"Slot_GYKLM2Sawj"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_5SS74a2WH7_GYKLM2Sawj"
              }
              addSpacing={true}
            >
              {props.children}
            </ContainerPrimitive>
            <SpacerPrimitive
              id={"Spacer_QFfgd43AZU"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_5SS74a2WH7_QFfgd43AZU"
              }
              key={"QFfgd43AZU"}
            />
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"button-container_8RjnzMBHiJ"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_5SS74a2WH7_8RjnzMBHiJ"
            }
            key={"8RjnzMBHiJ"}
            addSpacing={true}
            element={getStyle("8RjnzMBHiJ", "htmlElement")}
          >
            {[
              props.CancelButton === undefined ? (
                <CancelButton
                  key={"6u34hk6UYB"}
                  {...getCompositeDefaultProps("6u34hk6UYB")}
                  id="CancelButton_6u34hk6UYB"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_5SS74a2WH7_6u34hk6UYB"
                />
              ) : (
                <CancelButtonContext.Provider
                  key="6u34hk6UYB-provider"
                  value={{
                    key: "6u34hk6UYB",
                    id: "CancelButton_6u34hk6UYB",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_5SS74a2WH7_6u34hk6UYB",
                    ...getCompositeDefaultProps("6u34hk6UYB"),
                  }}
                >
                  {props.CancelButton}
                </CancelButtonContext.Provider>
              ),
              props.AddButton === undefined ? (
                <AddButton
                  key={"UDzEi9gpJS"}
                  {...getCompositeDefaultProps("UDzEi9gpJS")}
                  id="AddButton_UDzEi9gpJS"
                  className="__visly_reset_7X7HTLRqyD __visly_scope_5SS74a2WH7_UDzEi9gpJS"
                />
              ) : (
                <AddButtonContext.Provider
                  key="UDzEi9gpJS-provider"
                  value={{
                    key: "UDzEi9gpJS",
                    id: "AddButton_UDzEi9gpJS",
                    className:
                      "__visly_reset_7X7HTLRqyD __visly_scope_5SS74a2WH7_UDzEi9gpJS",
                    ...getCompositeDefaultProps("UDzEi9gpJS"),
                  }}
                >
                  {props.AddButton}
                </AddButtonContext.Provider>
              ),
            ]}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

ObjectSelectionModal.CancelButton = CancelButton;
ObjectSelectionModal.AddButton = AddButton;
ObjectSelectionModal.CreateButton = CreateButton;
ObjectSelectionModal.__variants = [
  {
    name: "isEmpty",
    type: "variant",
  },
];

export default ObjectSelectionModal;
