// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Switch.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { SpacerPrimitive, ContainerPrimitive } from "../_internal_primitives";
import { CheckboxRoot } from "../_internal_checkbox";

const styles = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "checked",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "checked",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "checked",
  },
];

export const SwitchContext = createContext(null);

function Switch(_props) {
  const defaults = useContext(SwitchContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <CheckboxRoot
      {...props}
      key="root"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "root",
        scope: "RQNciNfRqs",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"container_toggle"}
          className={"__visly_reset_7X7HTLRqyD __visly_scope_RQNciNfRqs_toggle"}
          key={"toggle"}
          addSpacing={false}
          element={getStyle("toggle", "htmlElement")}
        >
          {
            <SpacerPrimitive
              id={"Spacer_FVjLL2gw1j"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_RQNciNfRqs_FVjLL2gw1j"
              }
              key={"FVjLL2gw1j"}
            />
          }
        </ContainerPrimitive>
      )}
    </CheckboxRoot>
  );
}

Switch.__variants = [
  {
    name: "checked",
    type: "variant",
  },
];

export default Switch;
