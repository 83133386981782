// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./PayoutModal.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as PayoutHist } from "../Payout/PayoutHist";
import { default as WithdrawInfo } from "../Payout/WithdrawInfo";
import {
  default as ConfigHeader,
  ConfigHeaderContext,
} from "../Events/ConfigHeader";

const styles = [
  {
    type: "default",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      EEqMKnVpEN: {},
      HmwhFszoHA: {},
      T6wjjyKi6f: {
        none: {
          title: "Initiate Payout",
          subtitle: " ",
        },
      },
    },
  },
];

const variantPropTypes = [];

export const PayoutModalContext = createContext(null);

function PayoutModal(_props) {
  const defaults = useContext(PayoutModalContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="U1MjmUWugp"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "U1MjmUWugp",
        scope: "TQ1ZEys2i6",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Container_Tx9XXrLuC3"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_TQ1ZEys2i6_Tx9XXrLuC3"
            }
            key={"Tx9XXrLuC3"}
            addSpacing={false}
            element={getStyle("Tx9XXrLuC3", "htmlElement")}
          >
            {props.ConfigHeader === undefined ? (
              <ConfigHeader
                key={"T6wjjyKi6f"}
                {...getCompositeDefaultProps("T6wjjyKi6f")}
                id="ConfigHeader_T6wjjyKi6f"
                className="__visly_reset_7X7HTLRqyD __visly_scope_TQ1ZEys2i6_T6wjjyKi6f"
              />
            ) : (
              <ConfigHeaderContext.Provider
                key="T6wjjyKi6f-provider"
                value={{
                  key: "T6wjjyKi6f",
                  id: "ConfigHeader_T6wjjyKi6f",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_TQ1ZEys2i6_T6wjjyKi6f",
                  ...getCompositeDefaultProps("T6wjjyKi6f"),
                }}
              >
                {props.ConfigHeader}
              </ConfigHeaderContext.Provider>
            )}
          </ContainerPrimitive>
          <ContainerPrimitive
            key={"2zFaWr4eZU"}
            id={"Slot_2zFaWr4eZU"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_TQ1ZEys2i6_2zFaWr4eZU"
            }
            addSpacing={false}
          >
            {props.withdrawlInfo}
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_NnrU7yVzM4"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_TQ1ZEys2i6_NnrU7yVzM4"
            }
            key={"NnrU7yVzM4"}
          />
          <ContainerPrimitive
            key={"3G7X7SjxXD"}
            id={"Slot_3G7X7SjxXD"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_TQ1ZEys2i6_3G7X7SjxXD"
            }
            addSpacing={false}
          >
            {props.payoutHistory}
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_75VF4y4tEE"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_TQ1ZEys2i6_75VF4y4tEE"
            }
            key={"75VF4y4tEE"}
          />
        </>
      )}
    </RootPrimitive>
  );
}

PayoutModal.PayoutHist = PayoutHist;
PayoutModal.WithdrawInfo = WithdrawInfo;
PayoutModal.ConfigHeader = ConfigHeader;
PayoutModal.__variants = [];

export default PayoutModal;
