// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Menu.Submenu.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import { SubMenuRoot } from "../_internal_menu";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      "3WTxDmsmjK": {
        none: {
          text: "Submenu item",
        },
      },
      "8t8uc6NWMW": {
        none: {
          icon: icons.chevronRight,
          useMask: true,
        },
        disabled: {
          useMask: true,
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const SubmenuContext = createContext(null);

function Submenu(_props) {
  const defaults = useContext(SubmenuContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <SubMenuRoot
      {...props}
      key="menu-submenu"
      internal={{
        styles,
        projectId: "7X7HTLRqyD",
        layerId: "menu-submenu",
        scope: "64uf2sS2B2",
        activeVariants,
      }}
      vislyChildren={(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Row_CLRzNGJT3r"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_64uf2sS2B2_CLRzNGJT3r"
            }
            key={"CLRzNGJT3r"}
            addSpacing={false}
            element={getStyle("CLRzNGJT3r", "htmlElement")}
          >
            <SpacerPrimitive
              id={"Spacer_HV8zBq3YwH"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_64uf2sS2B2_HV8zBq3YwH"
              }
              key={"HV8zBq3YwH"}
            />
            <TextPrimitive
              id={"text_3WTxDmsmjK"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_64uf2sS2B2_3WTxDmsmjK"
              }
              key={"3WTxDmsmjK"}
              text={
                exists(props.title)
                  ? props.title
                  : getStyle("3WTxDmsmjK", "text")
              }
              element={getStyle("3WTxDmsmjK", "htmlElement")}
            />
          </ContainerPrimitive>
          <IconPrimitive
            id={"Icon_8t8uc6NWMW"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_64uf2sS2B2_8t8uc6NWMW"
            }
            key={"8t8uc6NWMW"}
            useMask={getStyle("8t8uc6NWMW", "useMask")}
            src={getStyle("8t8uc6NWMW", "icon")}
          />
        </>
      )}
    >
      {props.children}
    </SubMenuRoot>
  );
}

Submenu.__variants = [];

export default Submenu;
