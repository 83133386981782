import React from "react";
import { styled } from "../../stitches.config";
import { CheckIcon } from "@radix-ui/react-icons";
import ReactLoading from "react-loading";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";

const StyledCheckbox = styled(CheckboxPrimitive.Root, {
  all: "unset",
  backgroundColor: "$white",
  borderRadius: 4,
  display: "flex",
  flexShrink: 0,
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid $gray7",
  transition: "all 100ms ease-in-out",
  "&:hover": {
    backgroundColor: "$gray2",
    borderColor: "$info7",
  },
  '&[data-state="checked"]': {
    backgroundColor: "$info9",
    border: "1px solid $info10",
  },
  "&:focus-visible": { outline: `2px solid $info9`, outlineOffset: 2 },
  "&:disabled": {
    opacity: 0.5,
    border: "1px solid $gray4",
    pointerEvents: "none",
    cursor: "default",
  },
  variants: {
    size: {
      large: {
        width: 24,
        height: 24,
        "& svg": {
          width: 16,
          height: 16,
        },
      },
      medium: {
        width: 18,
        height: 18,
        "& svg": {
          width: 14,
          height: 14,
        },
      },
      small: {
        width: 15,
        height: 15,
        "& svg": {
          width: 13,
          height: 13,
        },
      },
    },
    loading: {
      true: {
        pointerEvents: "none",
        cursor: "default",
        backgroundColor: "transparent",
        border: "1px solid transparent",
        "& svg": {
          height: "100%",
          width: "100%",
        },
        "&:hover": {
          backgroundColor: "transparent",
          border: "1px solid transparent",
        },
      },
    },
    defaultVariants: {
      size: "normal",
      loading: false,
    },
  },
});

const StyledIndicator = styled(CheckboxPrimitive.Indicator, {
  display: "flex",
  alignItems: "center",
  color: "$white",
  "@media print": {
    color: "$gray12",
  },
});

export const Checkbox = ({
  checked,
  onChange,
  defaultChecked,
  disabled,
  required,
  name,
  value,
  size,
  loading,
  ...props
}) => {
  return (
    <StyledCheckbox
      size={size || "medium"}
      checked={checked}
      onCheckedChange={onChange}
      disabled={disabled || loading}
      required={required}
      loading={loading}
      name={name}
      value={value}
      defaultChecked={defaultChecked}
      {...props}
    >
      {loading ? (
        <ReactLoading type={"spin"} color={"#180091"} height={16} width={16} />
      ) : (
        <StyledIndicator>{checked && <CheckIcon />}</StyledIndicator>
      )}
    </StyledCheckbox>
  );
};
