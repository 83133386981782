import React from "react";
import { Column, P, Row } from "../../hotplate-common/primitives/Containers";
import { styled } from "../../stitches.config";

const sharedStyles = {
  display: "flex",
  alignItems: "center",
  jc: "center",
  height: "$md_btn",
  borderRadius: 6,
  ff: "$inter",
  textStyle: "text-3",
  fw: "$bold",
  backgroundColor: "$danger4",
  color: "$danger11",
  '[data-type="large"] &': {
    fontSize: 30,
    lineHeight: "1.25",
    fontWeight: "$bold",
    h: 56,
    br: "$lg",
    w: "95%",
  },
};

const TimeFlex = styled("div", {
  ...sharedStyles,
  padx: "$xxs",
  minW: "3.25ch",
});

const HiddenDropTime = styled("div", {
  ...sharedStyles,
  padx: "$xs",
  w: "auto",
});

const Time = ({ chefStyles, children }) => {
  return (
    <TimeFlex
      css={
        chefStyles && {
          backgroundColor: chefStyles.primaryColorLight,
          color: chefStyles.primaryColorDark,
        }
      }
    >
      {children}
    </TimeFlex>
  );
};

// pass it number of days, hours, minutes, seconds, etc, and it will map them to the Time component
const TimeGroup = ({ timeString, label, chefStyles }) => {
  // trim all whitespace and split into array
  // const stringArray = timeString.toString().replace(/\s+/g, "").split("");
  // remove all whitespace
  const formattedTimeString = timeString.toString().replace(/\s+/g, "");
  return (
    <Column css={{ jc: "center" }}>
      {/* map to box up a number at a time
      <Row css={{ gap: "$xxs" }}>
        {stringArray.map((num, index) => (
          <Time key={index} chefStyles={chefStyles}>
            {num}
          </Time>
        ))}
      </Row> */}
      <Row css={{ gap: "$xxs" }}>
        <Time chefStyles={chefStyles}>{formattedTimeString}</Time>
      </Row>
      {label && (
        <P
          css={{
            textStyle: "text-1",
            ff: "$inter",
            color: "$gray11",
            textAlign: "center",
            '[data-type="large"] &': {
              mt: "$xxs",
            },
          }}
        >
          {label}
        </P>
      )}
    </Column>
  );
};

const CountdownGrid = styled("div", {
  display: "flex",
  gap: 12,
});

export const Countdown = ({
  goLiveTime,
  hideCountdown,
  days,
  hours,
  minutes,
  seconds,
  labeled,
  chefStyles,
  type,
  ...props
}) => {
  const moreThan5MinsAway = days > 0 || hours > 0 || minutes >= 5;

  function getHiddenDropTimeText() {
    // format goLiveTime to be like 12/1/21 using intl
    const intl = new Intl.DateTimeFormat("en", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    });
    const formattedGoLiveTime = intl.format(new Date(goLiveTime));

    if (days > 0) {
      // 1 > days >= 0
      return `Drops on ${formattedGoLiveTime}`;
    } else if (hours > 6) {
      // 24 > hours >= 6
      return `Drops today`;
    } else if (hours >= 1) {
      // 6 > hours > 0
      return `Drops soon`;
    } // if its less than 1 hour away
    else return `Drops any minute`;
  }

  return (
    <CountdownGrid {...props} data-type={type}>
      {hideCountdown && moreThan5MinsAway ? (
        <HiddenDropTime>{getHiddenDropTimeText()}</HiddenDropTime>
      ) : (
        <>
          {parseInt(days) > 0 && (
            <TimeGroup label={labeled ? "Days" : ""} chefStyles={chefStyles} timeString={days} />
          )}
          {(parseInt(hours) > 0 || type === "large") && (
            <TimeGroup label={labeled ? "Hours" : ""} chefStyles={chefStyles} timeString={hours} />
          )}
          <TimeGroup label={labeled ? "Mins" : ""} chefStyles={chefStyles} timeString={minutes} />
          <TimeGroup label={labeled ? "Secs" : ""} chefStyles={chefStyles} timeString={seconds} />
        </>
      )}
    </CountdownGrid>
  );
};

// so if its hideen, the countdown should say something like
// "Drops 12/1"
// "Drops soon (within 6 hours)"
// "Drops any minute" when its within 30 mins
// shows countdown timer when its 5 mins before
