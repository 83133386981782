/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { connect } from "react-redux";
import { toFixed, priceInputIsValid } from "@hotplate/utils-ts/helperFunctions";
import AddEditOptionCategoryModal from "./AddEditOptionCategoryModal";
import SmartTextArea from "../../../../hotplate-common/SmartTextArea";
import ToolTip from "../../../../hotplate-common/ToolTip";
import Modal from "../../../../hotplate-common/Modal";
import { useBreakpoint } from "../../../../visly";
import ImageUploader from "../../../components/ImageUploader";
import Spacer from "../../../../hotplate-common/Spacer";
import { showItemWaitlistPortalFunctionality } from "../../../../hotplate-common/HelperFunctions";
import { Input, Button, Select, Badge } from "../../../../visly/Primitives";
import { AddEditMenuItem, LabeledSwitch } from "../../../../visly/Events";
import { mergeDiff, removeNulls } from "../../../../hooks";

import { TrashIcon } from "@radix-ui/react-icons";
import AddEditModal from "../../../components/AddEditModal";
import ChangeLiveEventMenuItemInventoryModal from "./ChangeLiveEventMenuItemInventoryModal";
import {
  trackItemDeleted,
  trackItemInventoryUpdated,
  trackItemMaxPerOrderUpdated,
  trackItemMaxPerPickupUpdated,
  trackItemNotesRequiredToggled,
  trackItemNotesToggled,
  trackItemRemovedFromEvent,
  trackAdditionalMenuItemImageAdded,
} from "../analytics";
import _ from "lodash";
import { P } from "../../../../hotplate-common/primitives/Containers";
import { usePortalUser } from "../../../../auth";

function createNewOptionCategory() {
  return {
    title: "",
    minimumSelections: 0,
    maximumSelections: 1,
    categoryType: "modification",
    options: [
      {
        title: "",
        price: "",
        inventory: "",
      },
    ],
    showOptionInventory: false,
  };
}

export default connect((state) => {
  return {};
}, {})(function AddEditMenuItemModal({
  showLiveCounts,
  saveMenuItemLoading,
  deleteMenuItemLoading,
  isNewMenuItem,
  initialMenuItemDiff,
  menuItem,
  menuItemSoldCount,
  itemWaitlistCount,
  onRequestSave,
  onRequestDelete,
  onRequestClose,
  eventId,
  eventStatus,
}) {
  const { chefId } = usePortalUser();
  const [menuItemDiff, setMenuItemDiff] = useState(initialMenuItemDiff || {});
  const [optionSelectModalState, setOptionSelectModalState] = useState(null);
  const [isMenuItemInventoryChangeModalVisible, setIsMenuItemInventoryChangeModalVisible] =
    useState(false);
  const [isGlobalDetailsExpanded, setIsGlobalDetailsExpanded] = useState(false);
  const [idxOfOptionCategoryBeingEdited, setIdxOfOptionCategoryBeingEdited] = useState(null);
  const [newOptionCategory, setNewOptionCategory] = useState(null);

  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);
  const [debounce] = useState(() => {
    return _.debounce(
      (func) => {
        func();
      },
      2000,
      { leading: false, trailing: true }
    );
  });
  // Merge props with diff state
  const mergedMenuItem = removeNulls(mergeDiff(menuItem, menuItemDiff));

  function createAndSwitchToNewOptionCategory() {
    const newOptionCategory = createNewOptionCategory();
    setNewOptionCategory(newOptionCategory);
  }

  function appendNewOptionCategory(newOptionCategory) {
    setMenuItemDiff((prevMenuItemDiff) => {
      const oldCategories = prevMenuItemDiff.optionCategories
        ? prevMenuItemDiff.optionCategories
        : menuItem.optionCategories || []; // snapshot, is reading the closure value ok?
      return {
        ...prevMenuItemDiff,
        optionCategories: [...oldCategories, newOptionCategory],
      };
    });
  }

  function mergeOptionCategoryDiff(optionCategoryDiff) {
    setMenuItemDiff((prevMenuItemDiff) => {
      // menuItem.optionCategories should have been snapshotted already
      const newCategories = [...prevMenuItemDiff.optionCategories];
      if (optionCategoryDiff === null) {
        newCategories.splice(idxOfOptionCategoryBeingEdited, 1);
      } else {
        newCategories[idxOfOptionCategoryBeingEdited] = mergeDiff(
          newCategories[idxOfOptionCategoryBeingEdited],
          optionCategoryDiff
        );
      }

      return {
        ...prevMenuItemDiff,
        optionCategories: newCategories,
      };
    });
  }

  function setMenuItemImage(value, index) {
    if (Number.isInteger(index)) {
      trackAdditionalMenuItemImageAdded();
    }
    setMenuItemDiff((prevMenuItemDiff) => {
      if (!Number.isInteger(index)) return { ...prevMenuItemDiff, image: value };
      else {
        const newAdditionalImages = mergedMenuItem.additionalImages || [];
        if (index >= newAdditionalImages.length) {
          newAdditionalImages.push(value);
        } else {
          newAdditionalImages[index] = value;
        }
        return { ...prevMenuItemDiff, additionalImages: newAdditionalImages };
      }
    });
  }

  function deleteLastAdditionalMenuItemImage() {
    setMenuItemDiff((prevMenuItemDiff) => {
      return {
        ...prevMenuItemDiff,
        additionalImages: (mergedMenuItem.additionalImages || []).slice(0, -1),
      };
    });
  }

  function setMenuItemTitle(value) {
    setMenuItemDiff((prevMenuItemDiff) => {
      return { ...prevMenuItemDiff, title: value };
    });
  }

  function setMenuItemDescription(value) {
    setMenuItemDiff((prevMenuItemDiff) => {
      return { ...prevMenuItemDiff, description: value };
    });
  }

  function setMenuItemInventory(value) {
    if (!/^[0-9]+$/.test(value) && value !== "") return;
    setMenuItemDiff((prevMenuItemDiff) => {
      return {
        ...prevMenuItemDiff,
        inventory: value !== "" ? parseInt(value) : "",
      };
    });
    debounce(() => {
      trackItemInventoryUpdated({
        eventId: eventId,
        eventStatus: eventStatus,
        itemId: menuItem.id,
        inventory: value !== "" ? parseInt(value) : "unlimited",
      });
    });
  }

  function setMenuItemMaxPerCustomer(value) {
    if (!/^[0-9]+$/.test(value) && value !== "") return;
    setMenuItemDiff((prevMenuItemDiff) => {
      return {
        ...prevMenuItemDiff,
        maxPerCustomer: value !== "" ? parseInt(value) : "",
      };
    });
    debounce(() => {
      trackItemMaxPerOrderUpdated({
        eventId: eventId,
        eventStatus: eventStatus,
        itemId: menuItem.id,
        maxPerOrder: value !== "" ? parseInt(value) : "unlimited",
      });
    });
  }

  function setMenuItemMaxPerRangeType(value) {
    if (value === "timeSlot") {
      setMenuItemDiff((prevMenuItemDiff) => {
        return {
          ...prevMenuItemDiff,
          maxPerRangeType: value,
          maxPerTimeSlot: mergedMenuItem.maxPerDay, // is reading the closure value ok?
        };
      });
    } else if (value === "date") {
      setMenuItemDiff((prevMenuItemDiff) => {
        return {
          ...prevMenuItemDiff,
          maxPerRangeType: value,
          maxPerDay: mergedMenuItem.maxPerTimeSlot,
        };
      });
    }
  }

  function setMenuItemMaxPerTimeSlot(value) {
    if (!/^[0-9]+$/.test(value) && value !== "") return;
    setMenuItemDiff((prevMenuItemDiff) => {
      return {
        ...prevMenuItemDiff,
        maxPerTimeSlot: value !== "" ? parseInt(value) : "",
      };
    });
    debounce(() => {
      trackItemMaxPerPickupUpdated({
        eventId: eventId,
        eventStatus: eventStatus,
        itemId: menuItem.id,
        type: "timeSlot",
        maxPerPickup: value !== "" ? parseInt(value) : "unlimited",
      });
    });
  }

  function setMenuItemMaxPerDay(value) {
    if (!/^[0-9]+$/.test(value) && value !== "") return;
    setMenuItemDiff((prevMenuItemDiff) => {
      return {
        ...prevMenuItemDiff,
        maxPerDay: value !== "" ? parseInt(value) : "",
      };
    });
    debounce(() => {
      trackItemMaxPerPickupUpdated({
        eventId: eventId,
        eventStatus: eventStatus,
        itemId: menuItem.id,
        type: "date",
        maxPerPickup: value !== "" ? parseInt(value) : "unlimited",
      });
    });
  }

  function setMenuItemPrice(value) {
    if (!priceInputIsValid(value)) return;
    setMenuItemDiff((prevMenuItemDiff) => {
      return { ...prevMenuItemDiff, price: value };
    });
  }

  function toggleMenuItemNotesEnabled() {
    setMenuItemDiff((prevMenuItemDiff) => {
      return {
        ...prevMenuItemDiff,
        notesEnabled: !prevMenuItemDiff.notesEnabled,
      };
    });
    trackItemNotesToggled({
      eventId: eventId,
      eventStatus: eventStatus,
      itemId: menuItem.id,
      enabled: !menuItemDiff.notesEnabled, // TODO see if this works
    });
  }

  function toggleMenuItemNotesRequired() {
    setMenuItemDiff((prevMenuItemDiff) => {
      return {
        ...prevMenuItemDiff,
        notesRequired: !prevMenuItemDiff.notesRequired,
      };
    });
    trackItemNotesRequiredToggled({
      eventId: eventId,
      eventStatus: eventStatus,
      itemId: menuItem.id,
      enabled: !menuItemDiff.notesRequired, // TODO see if this works
    });
  }

  function onFileLoaded(fileInfo, index) {
    if (!fileInfo) {
      console.error("IMG UPLOAD ERR");
      return;
    }
    setMenuItemImage(fileInfo.cdnUrl, index);
  }

  return (
    <>
      <Modal
        closeModal={onRequestClose}
        isFirstPage={!isNewMenuItem}
        optionSelectModalState={optionSelectModalState}
      >
        <AddEditModal
          cancelButtonOnClick={() => {
            onRequestClose();
          }}
          setOptionSelectModalState={(message, optionSelectButtons, modal) => {
            setOptionSelectModalState({
              message: message,
              optionSelectButtons: optionSelectButtons,
              closeOptionSelectModal: () => {
                setOptionSelectModalState(null);
              },
              modal: modal,
            });
          }}
          saveButtonOnClick={() => onRequestSave(menuItemDiff)}
          saveButtonLoading={saveMenuItemLoading}
          deleteButtonText={"Delete Item"}
          deleteButtonOnClick={() => {
            onRequestDelete();
            trackItemDeleted({
              eventId: eventId,
              eventStatus: eventStatus,
              itemId: menuItem.id,
            });
          }}
          deleteMessage="This will permanently delete this menu item, are you sure?"
          deleteButtonLoading={deleteMenuItemLoading}
          saveDisabled={mergedMenuItem.title === "" || mergedMenuItem.price === ""}
          secondaryDeleteButton={
            <>
              <Button
                onClick={() => {
                  onRequestSave(null);
                  trackItemRemovedFromEvent({
                    eventId: eventId,
                    eventStatus: eventStatus,
                    itemId: menuItem.id,
                  });
                }}
                kind="warning"
                text="Remove from Event"
              />
              <Spacer size="Large" />
            </>
          }
          title={isNewMenuItem ? "Create Menu Item" : mergedMenuItem.title}
          navText={!isNewMenuItem ? "Menu" : "Menu Items"}
          editing={!isNewMenuItem}
        >
          <AddEditMenuItem
            eventDetails={
              !isNewMenuItem && (
                <AddEditMenuItem.EventDetails
                  size={size}
                  Inventory={
                    <AddEditMenuItem.EventDetails.Inventory
                      Input={
                        showLiveCounts ? (
                          <div>
                            <div
                              style={{
                                display: "flex",
                                marginBottom: "1em",
                              }}
                            >
                              <Badge
                                text={`${
                                  Number.isInteger(mergedMenuItem.inventory)
                                    ? mergedMenuItem.inventory
                                    : "∞"
                                } available`}
                                light="black"
                                style={{ display: "inline-block" }}
                              />
                              {Number.isInteger(mergedMenuItem.inventoryReserved) && (
                                <Badge
                                  text={`${Math.max(
                                    menuItem.inventoryReserved - menuItemSoldCount,
                                    0
                                  )} in carts`}
                                  light="warning"
                                />
                              )}
                              <Badge text={`${menuItemSoldCount} sold`} light="good" />
                              {showItemWaitlistPortalFunctionality(
                                menuItem,
                                menuItemSoldCount,
                                itemWaitlistCount
                              ) && <Badge text={itemWaitlistCount + " want more"} solid="black" />}
                            </div>
                            <Button
                              text="Change inventory"
                              onClick={() => {
                                setIsMenuItemInventoryChangeModalVisible(true);
                              }}
                            />
                            {showItemWaitlistPortalFunctionality(
                              menuItem,
                              menuItemSoldCount,
                              itemWaitlistCount
                            ) && (
                              <P css={{ textStyle: "text-2", ff: "$avenir", color: "$accent11" }}>
                                {itemWaitlistCount - (itemWaitlistCount === 1 ? 0 : 1)}+ customers
                                signed up to be notified if this item comes back in stock
                              </P>
                            )}
                          </div>
                        ) : (
                          <Input
                            darkPlaceholder
                            style={{ width: "100%" }}
                            value={mergedMenuItem.inventory}
                            onChange={(val) => setMenuItemInventory(val)}
                            placeholder="Unlimited"
                          />
                        )
                      }
                    />
                  }
                  MaxPerCustomer={
                    <AddEditMenuItem.EventDetails.MaxPerCustomer
                      Input={
                        <Input
                          darkPlaceholder
                          style={{ width: "100%" }}
                          value={mergedMenuItem.maxPerCustomer}
                          onChange={(val) => setMenuItemMaxPerCustomer(val)}
                          placeholder="Unlimited"
                        />
                      }
                      tooltip={
                        <ToolTip
                          placement="center"
                          text="Limit how much one customer can buy of this item"
                        />
                      }
                    />
                  }
                  MaxPerSlotDay={
                    <AddEditMenuItem.EventDetails.MaxPerSlotDay
                      Input={
                        <div style={{ display: "flex" }}>
                          <Input
                            style={{
                              flex: "150px 0 0",
                              marginRight: size === "xsmall" ? "8px" : "16px",
                            }}
                            darkPlaceholder
                            value={
                              mergedMenuItem.maxPerRangeType === "timeSlot"
                                ? mergedMenuItem.maxPerTimeSlot
                                : mergedMenuItem.maxPerDay
                            }
                            disabled={chefId === "atozcreamery"}
                            onChange={(val) => {
                              if (mergedMenuItem.maxPerRangeType === "timeSlot")
                                setMenuItemMaxPerTimeSlot(val);
                              else if (mergedMenuItem.maxPerRangeType === "date")
                                setMenuItemMaxPerDay(val);
                            }}
                            placeholder="Unlimited"
                          />
                          <Select
                            style={{ flex: "175px 0 0" }}
                            label={
                              mergedMenuItem.maxPerRangeType === "timeSlot"
                                ? "per Pickup Time"
                                : "per Day"
                            }
                            selectedKey={mergedMenuItem.maxPerRangeType}
                            onSelectionChange={(val) => setMenuItemMaxPerRangeType(val)}
                          >
                            <Select.Option key="timeSlot" label="per Pickup Time" />
                            <Select.Option key="date" label="per Day" />
                          </Select>
                        </div>
                      }
                      tooltip={
                        <ToolTip
                          placement="center"
                          text="Limit how much of this item can be ordered for each Pickup Time or for each day"
                        />
                      }
                    />
                  }
                  ExpandGlobalDetails={
                    <AddEditMenuItem.EventDetails.ExpandGlobalDetails
                      size={size}
                      expanded={isGlobalDetailsExpanded}
                      HeaderButton={
                        <AddEditMenuItem.EventDetails.ExpandGlobalDetails.HeaderButton
                          size={size}
                          expanded={isGlobalDetailsExpanded}
                          onClick={() => {
                            setIsGlobalDetailsExpanded((val) => !val);
                          }}
                        />
                      }
                    >
                      <AddEditMenuItem.GlobalDetails
                        editing={true}
                        Title={
                          <AddEditMenuItem.GlobalDetails.Title
                            Input={
                              <Input
                                style={{ width: "100%" }}
                                value={mergedMenuItem.title}
                                onChange={(val) => setMenuItemTitle(val)}
                                placeholder="Untitled Item"
                              />
                            }
                          />
                        }
                        Description={
                          <AddEditMenuItem.GlobalDetails.Description
                            Input={
                              <SmartTextArea
                                maxLength={1000}
                                placeholder="Enter the description for your menu item"
                                onChange={(value) => setMenuItemDescription(value)}
                                text={mergedMenuItem.description}
                              />
                            }
                          />
                        }
                        Price={
                          <AddEditMenuItem.GlobalDetails.Price
                            label="Base Price"
                            Input={
                              <Input
                                style={{ width: "100%" }}
                                withUnit="left"
                                unit="$"
                                value={mergedMenuItem.price}
                                onChange={(value) => setMenuItemPrice(value)}
                                onBlur={() =>
                                  setMenuItemPrice(toFixed(parseFloat(mergedMenuItem.price), 2))
                                }
                              />
                            }
                          />
                        }
                        ImageWidget={
                          <AddEditMenuItem.GlobalDetails.ImageWidget
                            Input={
                              <div style={{ flexDirection: "column", display: "flex" }}>
                                <ImageUploader
                                  image={mergedMenuItem.image}
                                  onFileLoaded={onFileLoaded}
                                  defaultImage="https://ucarecdn.com/c7478fd7-b935-44e7-b406-7889e94f9b3d/MenuPlaceholder.png"
                                  crop="750x750 minimum"
                                />
                                <div
                                  style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    marginTop: "8px",
                                  }}
                                >
                                  {Array.isArray(mergedMenuItem.additionalImages) &&
                                    mergedMenuItem.additionalImages.length > 0 && (
                                      <div
                                        style={{
                                          backgroundColor: "#e8e8e8",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          maxHeight: "100px",
                                          width: "100px",
                                          marginRight: "4px",
                                          borderRadius: "4px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => deleteLastAdditionalMenuItemImage()}
                                      >
                                        <TrashIcon style={{ width: "30px", height: "30px" }} />
                                      </div>
                                    )}
                                  {mergedMenuItem.image &&
                                    mergedMenuItem.image !==
                                      "https://ucarecdn.com/c7478fd7-b935-44e7-b406-7889e94f9b3d/MenuPlaceholder.png" &&
                                    (Array.isArray(mergedMenuItem.additionalImages)
                                      ? mergedMenuItem.additionalImages
                                      : []
                                    )
                                      .concat([
                                        "https://ucarecdn.com/c7478fd7-b935-44e7-b406-7889e94f9b3d/MenuPlaceholder.png",
                                      ])
                                      .map((image, index) => {
                                        return (
                                          <ImageUploader
                                            key={index}
                                            image={image}
                                            onFileLoaded={(fileInfo) =>
                                              onFileLoaded(fileInfo, index)
                                            }
                                            containerStyle={{ marginRight: "4px" }}
                                            maxHeight="100px"
                                            defaultImage="https://ucarecdn.com/c7478fd7-b935-44e7-b406-7889e94f9b3d/MenuPlaceholder.png"
                                            crop="750x750 minimum"
                                          />
                                        );
                                      })}
                                </div>
                              </div>
                            }
                          />
                        }
                        Modifications={
                          <AddEditMenuItem.GlobalDetails.Modifications
                            empty={
                              !Array.isArray(mergedMenuItem.optionCategories) ||
                              mergedMenuItem.optionCategories.length === 0
                            }
                          >
                            {Array.isArray(mergedMenuItem.optionCategories) &&
                              mergedMenuItem.optionCategories.map(
                                (optionCategory, optionCategoryIndex) => (
                                  <AddEditMenuItem.GlobalDetails.Modifications.OptionCategoryCard
                                    title={optionCategory.title}
                                    key={optionCategoryIndex}
                                    options={
                                      Array.isArray(optionCategory.options) &&
                                      optionCategory.options.length !== 0
                                        ? optionCategory.options
                                            .map((option) => {
                                              return option.title;
                                            })
                                            .join(", ")
                                        : "No Options Set"
                                    }
                                    onClick={() => {
                                      // Snapshot the categories list into the diff
                                      // if it hasn't been done already to ensure
                                      // the indices of the list won't change up
                                      // from underneath us while a particular
                                      // category is being edited.
                                      if (!Array.isArray(menuItemDiff.optionCategories)) {
                                        setMenuItemDiff((prevMenuItemDiff) => {
                                          return {
                                            ...prevMenuItemDiff,
                                            optionCategories: [
                                              ...(menuItem.optionCategories || []),
                                            ],
                                          };
                                        });
                                      }
                                      setIdxOfOptionCategoryBeingEdited(optionCategoryIndex);
                                    }}
                                    outOfStock={
                                      optionCategory.showOptionInventory &&
                                      Array.isArray(optionCategory.options) &&
                                      optionCategory.options.length !== 0
                                        ? optionCategory.options
                                            .map((option) => {
                                              if (
                                                Number.isInteger(option.inventory) &&
                                                option.inventory <= 0
                                              )
                                                return 0;
                                              return option.inventory;
                                            })
                                            .indexOf(0) !== -1
                                        : false
                                    }
                                  />
                                )
                              )}
                          </AddEditMenuItem.GlobalDetails.Modifications>
                        }
                        AddModificationButton={
                          <AddEditMenuItem.GlobalDetails.AddModificationButton
                            onClick={() => {
                              createAndSwitchToNewOptionCategory();
                            }}
                          />
                        }
                        NotesEnabledSwitch={
                          <>
                            <AddEditMenuItem.GlobalDetails.NotesEnabledSwitch
                              Switch={
                                <AddEditMenuItem.GlobalDetails.NotesEnabledSwitch.Switch
                                  checked={mergedMenuItem.notesEnabled}
                                  onChange={() => toggleMenuItemNotesEnabled()}
                                />
                              }
                              tooltip={
                                <ToolTip text="Allow customers to write in their own notes & requests for this item" />
                              }
                            />
                            {mergedMenuItem.notesEnabled && (
                              <LabeledSwitch
                                style={{ marginTop: "16px" }}
                                Switch={
                                  <LabeledSwitch.Switch
                                    checked={mergedMenuItem.notesRequired}
                                    onChange={() => toggleMenuItemNotesRequired()}
                                  />
                                }
                                title="Make note required"
                                tooltip={
                                  <ToolTip text="Require customers to add a note before they can add the item to their cart" />
                                }
                              />
                            )}
                          </>
                        }
                      />
                    </AddEditMenuItem.EventDetails.ExpandGlobalDetails>
                  }
                />
              )
            }
            globalDetails={
              isNewMenuItem && (
                <AddEditMenuItem.GlobalDetails
                  style={
                    size === "small" || size === "xsmall"
                      ? { padding: "8px 16px 48px 16px" }
                      : { padding: "8px 24px 48px 24px" }
                  }
                  editing={false}
                  Title={
                    <AddEditMenuItem.GlobalDetails.Title
                      Input={
                        <Input
                          style={{ width: "100%" }}
                          value={mergedMenuItem.title}
                          onChange={(val) => setMenuItemTitle(val)}
                          placeholder="Untitled Item"
                        />
                      }
                    />
                  }
                  Description={
                    <AddEditMenuItem.GlobalDetails.Description
                      Input={
                        <SmartTextArea
                          style={{ width: "100%" }}
                          maxLength={1000}
                          placeholder="Enter the description for your menu item"
                          onChange={(value) => setMenuItemDescription(value)}
                          text={mergedMenuItem.description}
                        />
                      }
                    />
                  }
                  Price={
                    <AddEditMenuItem.GlobalDetails.Price
                      label="Base Price"
                      Input={
                        <Input
                          style={{ width: "100%" }}
                          withUnit="left"
                          unit="$"
                          value={mergedMenuItem.price}
                          onChange={(value) => setMenuItemPrice(value)}
                          onBlur={() =>
                            setMenuItemPrice(toFixed(parseFloat(mergedMenuItem.price), 2))
                          }
                        />
                      }
                    />
                  }
                  ImageWidget={
                    <AddEditMenuItem.GlobalDetails.ImageWidget
                      Input={
                        <ImageUploader
                          image={mergedMenuItem.image}
                          onFileLoaded={onFileLoaded}
                          defaultImage="https://ucarecdn.com/c7478fd7-b935-44e7-b406-7889e94f9b3d/MenuPlaceholder.png"
                          crop="750x750 minimum"
                        />
                      }
                    />
                  }
                  Modifications={
                    <AddEditMenuItem.GlobalDetails.Modifications
                      empty={
                        !Array.isArray(mergedMenuItem.optionCategories) ||
                        mergedMenuItem.optionCategories.length === 0
                      }
                    >
                      {Array.isArray(mergedMenuItem.optionCategories) &&
                        mergedMenuItem.optionCategories.map(
                          (optionCategory, optionCategoryIndex) => (
                            <AddEditMenuItem.GlobalDetails.Modifications.OptionCategoryCard
                              title={optionCategory.title}
                              key={optionCategoryIndex}
                              options={
                                Array.isArray(optionCategory.options) &&
                                optionCategory.options.length !== 0
                                  ? optionCategory.options
                                      .map((option) => {
                                        return option.title;
                                      })
                                      .join(", ")
                                  : "No Options Set"
                              }
                              onClick={() => {
                                // Snapshot the categories list into the diff
                                // if it hasn't been done already to ensure
                                // the indices of the list won't change up
                                // from underneath us while a particular
                                // category is being edited.
                                if (!Array.isArray(menuItemDiff.optionCategories)) {
                                  setMenuItemDiff((prevMenuItemDiff) => {
                                    return {
                                      ...prevMenuItemDiff,
                                      optionCategories: [...(menuItem.optionCategories || [])],
                                    };
                                  });
                                }
                                setIdxOfOptionCategoryBeingEdited(optionCategoryIndex);
                              }}
                              outOfStock={
                                optionCategory.showOptionInventory &&
                                Array.isArray(optionCategory.options) &&
                                optionCategory.options.length !== 0
                                  ? optionCategory.options
                                      .map((option) => {
                                        if (
                                          Number.isInteger(option.inventory) &&
                                          option.inventory <= 0
                                        )
                                          return 0;
                                        return option.inventory;
                                      })
                                      .indexOf(0) !== -1
                                  : false
                              }
                            />
                          )
                        )}
                    </AddEditMenuItem.GlobalDetails.Modifications>
                  }
                  AddModificationButton={
                    <AddEditMenuItem.GlobalDetails.AddModificationButton
                      onClick={() => createAndSwitchToNewOptionCategory()}
                    />
                  }
                  NotesEnabledSwitch={
                    <>
                      <AddEditMenuItem.GlobalDetails.NotesEnabledSwitch
                        Switch={
                          <AddEditMenuItem.GlobalDetails.NotesEnabledSwitch.Switch
                            checked={mergedMenuItem.notesEnabled}
                            onChange={() => toggleMenuItemNotesEnabled()}
                          />
                        }
                        tooltip={
                          <ToolTip text="Allow customers to write in their own notes & requests for this item" />
                        }
                      />
                      {mergedMenuItem.notesEnabled && (
                        <LabeledSwitch
                          style={{ marginTop: "16px" }}
                          Switch={
                            <LabeledSwitch.Switch
                              checked={mergedMenuItem.notesRequired}
                              onChange={() => toggleMenuItemNotesRequired()}
                            />
                          }
                          title="Require a note"
                          tooltip={
                            <ToolTip text="Require customers to add a note before they can add the item to their cart" />
                          }
                        />
                      )}
                    </>
                  }
                />
              )
            }
          />
        </AddEditModal>
      </Modal>
      {isMenuItemInventoryChangeModalVisible && (
        <ChangeLiveEventMenuItemInventoryModal
          menuItem={mergedMenuItem}
          eventId={eventId}
          menuItemSoldCount={menuItemSoldCount}
          itemWaitlistCount={itemWaitlistCount}
          onRequestClose={() => {
            setIsMenuItemInventoryChangeModalVisible(false);
          }}
        />
      )}
      {(newOptionCategory || idxOfOptionCategoryBeingEdited !== null) && (
        <AddEditOptionCategoryModal
          menuItemTitle={mergedMenuItem.title}
          isNewOptionCategory={newOptionCategory !== null}
          eventId={eventId}
          eventStatus={eventStatus}
          initialOptionCategoryDiff={newOptionCategory}
          optionCategory={
            newOptionCategory ? {} : mergedMenuItem.optionCategories[idxOfOptionCategoryBeingEdited]
          }
          onRequestSave={(optionCategoryDiffIncoming) => {
            setIdxOfOptionCategoryBeingEdited(null);
            setNewOptionCategory(null);
            if (newOptionCategory) {
              appendNewOptionCategory(optionCategoryDiffIncoming);
            } else {
              mergeOptionCategoryDiff(optionCategoryDiffIncoming);
            }
          }}
          onRequestClose={() => {
            setIdxOfOptionCategoryBeingEdited(null);
            setNewOptionCategory(null);
          }}
        />
      )}
    </>
  );
});
