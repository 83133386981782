// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./InputSelect.Options.Option.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";

const styles = [
  {
    type: "default",
    layers: {
      "5qM7pXL7gv": {
        none: {
          text: "search result hello babay honey ragtime",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const OptionContext = createContext(null);

function Option(_props) {
  const defaults = useContext(OptionContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="RpLatnPx8X"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "RpLatnPx8X",
        scope: "jzHqzGcGAR",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Container_CsLzcBjpLe"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_jzHqzGcGAR_CsLzcBjpLe"
          }
          key={"CsLzcBjpLe"}
          addSpacing={false}
          element={getStyle("CsLzcBjpLe", "htmlElement")}
        >
          {
            <TextPrimitive
              id={"optionName_5qM7pXL7gv"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_jzHqzGcGAR_5qM7pXL7gv"
              }
              key={"5qM7pXL7gv"}
              text={
                exists(props.optionName)
                  ? props.optionName
                  : getStyle("5qM7pXL7gv", "text")
              }
              element={getStyle("5qM7pXL7gv", "htmlElement")}
            />
          }
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

Option.__variants = [];

export default Option;
