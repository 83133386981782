import React from "react";
import ReactLoading from "react-loading";
import PropTypes from "prop-types";
import ErrorLine from "./ErrorLine";
import "./css/CheckoutCard.css";
import { icons } from "../../visly";
import { Button, IconButton } from "../../visly/Primitives";

export default function CheckoutCard({
  setAction,
  currentAction,
  focusedAction,
  headerText,
  buttonText,
  savedMiddleTexts,
  buttonLoading,
  buttonDisabled,
  buttonStyle,
  confirmSection,
  confirmationError,
  children,
}) {
  return (
    <div className="checkoutInputContainer">
      {currentAction === focusedAction ? (
        <div className="checkoutInputContainerFocused">
          <p
            className="checkoutInputSelectedHeader"
            style={{ marginBottom: 8, overflowWrap: "break-word" }}
          >
            {headerText}
          </p>
          {children}
          {buttonText !== "" && (
            <Button
              style={{
                ...buttonStyle,
                marginTop: "24px",
                height: "52px",
              }}
              onClick={confirmSection}
              disabled={buttonLoading || buttonDisabled}
              loading={buttonLoading}
              loadingAnimation={
                <ReactLoading type={"bubbles"} color={"#FFFFFF"} height={"40px"} width={"40px"} />
              }
              text={buttonText}
              kind="primary"
              large
            />
          )}
          {confirmationError !== "" && typeof confirmationError === "string" && (
            <ErrorLine
              showError={true}
              style={{ marginTop: "24px" }}
              errorText={confirmationError}
            />
          )}
        </div>
      ) : (
        <div className="checkoutInputContainerSaved">
          <p className="checkoutInputContainerLeft">{headerText}</p>
          <div className="checkoutInputMiddle">
            {savedMiddleTexts.map((text, index) => {
              if (typeof text !== "string" || text === "") return null;
              return (
                <p className="checkoutInputContainerText" key={index}>
                  {text}
                </p>
              );
            })}
          </div>
          <div
            style={{
              width: "25%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          >
            <IconButton
              icon={icons.edit}
              style={{
                filter:
                  "invert(73%) sepia(0%) saturate(271%) hue-rotate(211deg) brightness(94%) contrast(88%)",
                height: 25,
              }}
              onClick={() => setAction(focusedAction)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

CheckoutCard.propTypes = {
  currentAction: PropTypes.string.isRequired,
  setAction: PropTypes.func.isRequired,
  focusedAction: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  savedMiddleTexts: PropTypes.array.isRequired,
  confirmSection: PropTypes.func,
  children: PropTypes.node.isRequired,
  buttonLoading: PropTypes.bool,
  confirmationError: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonStyle: PropTypes.object,
  displayColor: PropTypes.string,
};

CheckoutCard.defaultProps = {
  buttonLoading: false,
  buttonText: "",
  buttonStyle: {},
  buttonDisabled: false,
  confirmSection: () => {
    // do nothing
  },
  confirmationError: "",
};
