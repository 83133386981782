// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./Toast.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../_internal_primitives";
import { default as CloseButton } from "./Toast.CloseButton";

const styles = [
  {
    type: "default",
    layers: {
      CVMPCzqNX1: {
        none: {
          text: "Here is a notification",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "dark",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
  {
    type: "enum",
    propName: "type",
    values: [
      {
        propValue: "success",
        layers: {},
      },
      {
        propValue: "warning",
        layers: {},
      },
      {
        propValue: "error",
        layers: {},
      },
      {
        propValue: "info",
        layers: {},
      },
    ],
  },
  {
    type: "boolean",
    propName: "isOpen",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "dark",
    layers: {},
  },
  {
    type: "enum",
    propName: "size",
    values: [
      {
        propValue: "xsmall",
        layers: {},
      },
      {
        propValue: "small",
        layers: {},
      },
      {
        propValue: "med",
        layers: {},
      },
      {
        propValue: "large",
        layers: {},
      },
      {
        propValue: "xlarge",
        layers: {},
      },
    ],
  },
  {
    type: "enum",
    propName: "type",
    values: [
      {
        propValue: "success",
        layers: {},
      },
      {
        propValue: "warning",
        layers: {},
      },
      {
        propValue: "error",
        layers: {},
      },
      {
        propValue: "info",
        layers: {},
      },
    ],
  },
  {
    type: "boolean",
    propName: "isOpen",
    layers: {},
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "dark",
  },
  {
    type: "enum",
    propName: "size",
    propValues: ["xsmall", "small", "med", "large", "xlarge"],
  },
  {
    type: "enum",
    propName: "type",
    propValues: ["success", "warning", "error", "info"],
  },
  {
    type: "boolean",
    propName: "isOpen",
  },
];

export const ToastContext = createContext(null);

function Toast(_props) {
  const defaults = useContext(ToastContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="2kuU7TGfT9"
      addSpacing={true}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "2kuU7TGfT9",
        scope: "YGrGLuD2iE",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <TextPrimitive
            id={"Text_CVMPCzqNX1"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_YGrGLuD2iE_CVMPCzqNX1"
            }
            key={"CVMPCzqNX1"}
            text={
              exists(props.text) ? props.text : getStyle("CVMPCzqNX1", "text")
            }
            element={getStyle("CVMPCzqNX1", "htmlElement")}
          />
          <ContainerPrimitive
            id={"Container_G415wFAdgd"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_YGrGLuD2iE_G415wFAdgd"
            }
            key={"G415wFAdgd"}
            addSpacing={false}
            element={getStyle("G415wFAdgd", "htmlElement")}
          >
            {props.CloseButton === undefined ? (
              <CloseButton key={"JmhWFbVdoM"} />
            ) : (
              props.CloseButton
            )}
          </ContainerPrimitive>
        </>
      )}
    </RootPrimitive>
  );
}

Toast.__variants = [
  {
    name: "dark",
    type: "variant",
  },
  {
    name: "size",
    type: "group",
    variants: ["xsmall", "small", "med", "large", "xlarge"],
  },
  {
    name: "type",
    type: "group",
    variants: ["success", "warning", "error", "info"],
  },
  {
    name: "isOpen",
    type: "variant",
  },
];

export default Toast;
