// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./ModalSubpage.css";
import React, { createContext, useContext } from "react";
import {
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { RootPrimitive, ContainerPrimitive } from "../_internal_primitives";
import { default as NavButton, NavButtonContext } from "../Events/NavButton";

const styles = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "isOpen",
    layers: {},
  },
  {
    type: "boolean",
    propName: "gray",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      QAa8whC8tp: {},
    },
  },
  {
    type: "boolean",
    propName: "isOpen",
    layers: {
      QAa8whC8tp: {},
    },
  },
  {
    type: "boolean",
    propName: "gray",
    layers: {
      QAa8whC8tp: {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "isOpen",
  },
  {
    type: "boolean",
    propName: "gray",
  },
];

export const ModalSubpageContext = createContext(null);

function ModalSubpage(_props) {
  const defaults = useContext(ModalSubpageContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="6aiQAojtgj"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "6aiQAojtgj",
        scope: "9aDCE2DAZu",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"subpage-content-container_59bXRqMn7Q"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_9aDCE2DAZu_59bXRqMn7Q"
          }
          key={"59bXRqMn7Q"}
          addSpacing={false}
          element={getStyle("59bXRqMn7Q", "htmlElement")}
        >
          <ContainerPrimitive
            id={"nav-button-container_BWwAbTMvXX"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_9aDCE2DAZu_BWwAbTMvXX"
            }
            key={"BWwAbTMvXX"}
            addSpacing={false}
            element={getStyle("BWwAbTMvXX", "htmlElement")}
          >
            {props.NavButton === undefined ? (
              <NavButton
                key={"QAa8whC8tp"}
                {...getCompositeDefaultProps("QAa8whC8tp")}
                id="NavButton_QAa8whC8tp"
                className="__visly_reset_7X7HTLRqyD __visly_scope_9aDCE2DAZu_QAa8whC8tp"
              />
            ) : (
              <NavButtonContext.Provider
                key="QAa8whC8tp-provider"
                value={{
                  key: "QAa8whC8tp",
                  id: "NavButton_QAa8whC8tp",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_9aDCE2DAZu_QAa8whC8tp",
                  ...getCompositeDefaultProps("QAa8whC8tp"),
                }}
              >
                {props.NavButton}
              </NavButtonContext.Provider>
            )}
          </ContainerPrimitive>
          <ContainerPrimitive
            key={"9ZzZ4VMmYu"}
            id={"Slot_9ZzZ4VMmYu"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_9aDCE2DAZu_9ZzZ4VMmYu"
            }
            addSpacing={false}
          >
            {props.children}
          </ContainerPrimitive>
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

ModalSubpage.NavButton = NavButton;
ModalSubpage.__variants = [
  {
    name: "isOpen",
    type: "variant",
  },
  {
    name: "gray",
    type: "variant",
  },
];

export default ModalSubpage;
