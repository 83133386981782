/* eslint-disable react/prop-types */
import {
  capitalizeFirstLetter,
  numberWithCommas,
  toFixed,
} from "@hotplate/utils-ts/helperFunctions";
import firebase from "firebase/app";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";
import { usePortalUser } from "../../auth";
import { FirebaseContext } from "../../firebaseSocket";
import { usePrevious } from "../../hooks";
import AnimationContainer from "../../hotplate-common/AnimationContainer";
import Modal from "../../hotplate-common/Modal";
import {
  addFundingAccount,
  createPartnership,
  initiatePayment,
} from "../../hotplate-storefront/actions";
import { IS_PROD } from "../../hotplate-storefront/actions/types";
import { colors, textstyles, useBreakpoint } from "../../visly";
import { PayoutModal, WithdrawInfo, WithdrawPreview } from "../../visly/Payout";
import Reauthenticate from "../components/Reauthenticate";
import Survey from "../components/Survey";
import {
  trackBankInfoUpdated,
  trackPayoutButtonClicked,
  trackTransactionClicked,
} from "./analytics";
import "./index.css";
import ItemizedReceipt from "./ItemizedReceipt";
import PayoutHistory from "./PayoutHistory";
import TransactionHistory from "./TransactionHistory";

const Payment = () => {
  const size = useBreakpoint("xsmall", ["small", "med", "large", "xlarge"]);

  const dispatch = useDispatch();
  const { chefId: hostId } = usePortalUser();
  const firebaseContext = useContext(FirebaseContext);

  const {
    routableInfo,
    routableInfoLoading,
    createPartnershipLoading,
    createPartnershipError,
    addFundingAccountLoading,
    addFundingAccountError,
    initiatePaymentLoading,
    initiatePaymentError,
    initiateCustomerRefundLoading,
    initiateCustomerRefundError,
  } = useSelector((state) => ({
    routableInfo: state.hostPortal.hostInfo.routableInfo,
    routableInfoLoading: state.hostPortal.connectToChefInfoRoutableInfoLoading,
    createPartnershipLoading: state.payout.createPartnershipLoading,
    createPartnershipError: state.payout.createPartnershipError,
    addFundingAccountLoading: state.payout.addFundingAccountLoading,
    addFundingAccountError: state.payout.addFundingAccountError,
    initiatePaymentLoading: state.payout.initiatePaymentLoading,
    initiatePaymentError: state.payout.initiatePaymentError,
    initiateCustomerRefundLoading: state.orderManagement.initiateCustomerRefundLoading,
    initiateCustomerRefundError: state.orderManagement.initiateCustomerRefundError,
  }));

  const [showReauthModal, setShowReauthModal] = useState(false);
  const [showPayoutModal, setShowPayoutModal] = useState(false);
  const [irpPaymentIntentId, setIrpPaymentIntentId] = useState(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [email, setEmail] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountType, setAccountType] = useState("");

  const [editBankInfoState, setEditBankInfoState] = useState(null);
  const [tosAccepted, setTosAccepted] = useState(false);

  const [previousBankSelected, setPreviousBankSelected] = useState(!!routableInfo.payoutInfo);
  const [showSurvey, setShowSurvey] = useState(false);

  const [nextWithdrawalAmount, setNextWithdrawalAmount] = useState(undefined);
  const [getNextWithdrawalAmountLoading, setGetNextWithdrawalAmountLoading] = useState(true);

  const [grossAllTimeIncome, setGrossAllTimeIncome] = useState(undefined);
  const [getGrossAllTimeIncomeLoading, setGetGrossAllTimeIncomeLoading] = useState(true);

  async function getNextWithdrawalAmount() {
    const payoutInfo = routableInfo.payoutInfo;
    const lastPayoutTimestamp =
      Array.isArray(payoutInfo) && payoutInfo.length
        ? payoutInfo[payoutInfo.length - 1]["timestamp"]
        : 0;

    setGetNextWithdrawalAmountLoading(true);
    try {
      const {
        data: { withdrawalAmount: nextWithdrawalAmount },
      } = await firebaseContext.cloudFunctions.getWithdrawalAmount({
        chefId: hostId,
        startTimestamp: lastPayoutTimestamp,
        endTimestamp: 9999999999999,
      });

      setNextWithdrawalAmount(nextWithdrawalAmount);
    } finally {
      setGetNextWithdrawalAmountLoading(false);
    }
  }

  async function getGrossAllTimeIncome() {
    setGetGrossAllTimeIncomeLoading(true);
    try {
      const {
        data: { withdrawalAmount: grossAllTimeIncome },
      } = await firebaseContext.cloudFunctions.getWithdrawalAmount({
        chefId: hostId,
        startTimestamp: 0,
        endTimestamp: 9999999999999,
      });
      setGrossAllTimeIncome(grossAllTimeIncome);
    } finally {
      setGetGrossAllTimeIncomeLoading(false);
    }
  }

  function addBankInfo() {
    setEditBankInfoState({
      firstName: "",
      lastName: "",
      businessName: "",
      email: "",
      accountNumber: "",
      routingNumber: "",
      accountType: "",
    });
  }

  function cancelAddBankInfo() {
    setEditBankInfoState(null);
  }

  function saveBankInfo() {
    setFirstName(editBankInfoState.firstName);
    setLastName(editBankInfoState.lastName);
    setBusinessName(editBankInfoState.businessName);
    setEmail(editBankInfoState.email);
    setAccountNumber(editBankInfoState.accountNumber);
    setRoutingNumber(editBankInfoState.routingNumber);
    setAccountType(editBankInfoState.accountType);
    setEditBankInfoState(null);
    setPreviousBankSelected(false);
    trackBankInfoUpdated();
  }

  function getBusinessNameError(acc, biz) {
    return biz.length === 0;
  }

  function bankInfoInvalid(stateOverride = undefined) {
    const firstNameLocal = stateOverride?.firstName ?? firstName;
    const lastNameLocal = stateOverride?.lastName ?? lastName;
    const businessNameLocal = stateOverride?.businessName ?? businessName;
    const emailLocal = stateOverride?.email ?? email;
    const accountNumberLocal = stateOverride?.accountNumber ?? accountNumber;
    const routingNumberLocal = stateOverride?.routingNumber ?? routingNumber;
    const accountTypeLocal = stateOverride?.accountType ?? accountType;
    return (
      routingNumberLocal.length !== 9 ||
      accountNumberLocal.length < 6 ||
      accountNumberLocal.length > 17 ||
      firstNameLocal.length < 2 ||
      lastNameLocal.length < 2 ||
      emailLocal.length < 5 ||
      getBusinessNameError(accountTypeLocal, businessNameLocal) ||
      accountTypeLocal === ""
    );
  }

  function toggleShowPayoutModal() {
    if (!previousBankSelected && bankInfoInvalid() && !showPayoutModal) {
      addBankInfo();
    }
    if (!showPayoutModal) getNextWithdrawalAmount();
    setShowPayoutModal(!showPayoutModal);
    trackPayoutButtonClicked();
  }

  function getActiveBank() {
    const payoutInfo = routableInfo.payoutInfo;
    if (!previousBankSelected || !Array.isArray(payoutInfo) || payoutInfo.length === 0) {
      return {
        bankName: "",
        lastFour: "••••" + accountNumber.slice(-4),
      };
    }
    const bankName = payoutInfo[payoutInfo.length - 1].bankName;
    return {
      bankName: bankName
        .split(" ")
        .slice(0, bankName.split(" ").length - 1)
        .join(" "),
      lastFour: "••••" + bankName.slice(-4),
    };
  }

  function getPayoutHistory() {
    let payoutInfo = routableInfo.payoutInfo;
    if (!payoutInfo) payoutInfo = [];
    const payoutHistory = [];
    for (let i = payoutInfo.length - 1; i >= 0; i--) {
      let payoutAmount = "N/A";
      if (payoutInfo[i].amount) {
        payoutAmount = "$" + numberWithCommas(payoutInfo[i].amount);
      }
      const bankName = payoutInfo[i].bankName.slice(0, -5);
      const lastFour = "••••" + payoutInfo[i].bankName.slice(-4);
      const date = new Date(payoutInfo[i].timestamp);
      const dateString = new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
      }).format(date);
      const timeString = new Intl.DateTimeFormat("en-US", {
        timeStyle: "short",
      }).format(date);

      payoutHistory.push({
        dateString,
        timeString,
        payoutAmount,
        bankName,
        lastFour,
      });
    }
    return payoutHistory;
  }

  function initiateLoading() {
    return (
      createPartnershipLoading ||
      addFundingAccountLoading ||
      initiatePaymentLoading ||
      getNextWithdrawalAmountLoading
    );
  }

  function withdrawalDisabled() {
    if (
      previousBankSelected &&
      tosAccepted &&
      nextWithdrawalAmount &&
      parseFloat(nextWithdrawalAmount) > 0
    )
      return false; // TODO: CHANGE
    if (
      firstName.length === 0 ||
      lastName.length === 0 ||
      email.length === 0 ||
      getBusinessNameError(accountType, businessName) ||
      accountType.length === 0 ||
      isNaN(accountNumber) ||
      isNaN(routingNumber) ||
      accountNumber.length < 6 ||
      accountNumber.length > 17 ||
      routingNumber.length !== 9 ||
      !tosAccepted ||
      !nextWithdrawalAmount ||
      parseFloat(nextWithdrawalAmount) === 0
    )
      return true;
    return false;
  }

  // Initiates withdrawal
  async function initiateStart() {
    const firebaseToken = await firebase.auth().currentUser.getIdToken();
    if (previousBankSelected && tosAccepted)
      initiatePayment("ach_same_day", hostId, firebaseToken)(dispatch);
    else {
      createPartnership(
        email,
        firstName,
        lastName,
        accountType.split(":")[0],
        businessName,
        hostId,
        firebaseToken
      )(dispatch);
    }
  }

  function setBankInfoFirstName(val) {
    const newBankInfoState = _.cloneDeep(editBankInfoState);
    newBankInfoState.firstName = val;
    setEditBankInfoState(newBankInfoState);
  }

  function setBankInfoLastName(val) {
    const newBankInfoState = _.cloneDeep(editBankInfoState);
    newBankInfoState.lastName = val;
    setEditBankInfoState(newBankInfoState);
  }

  function setBankInfoBusinessName(val) {
    const newBankInfoState = _.cloneDeep(editBankInfoState);
    newBankInfoState.businessName = val;
    setEditBankInfoState(newBankInfoState);
  }

  function setBankInfoEmail(val) {
    const newBankInfoState = _.cloneDeep(editBankInfoState);
    newBankInfoState.email = val;
    setEditBankInfoState(newBankInfoState);
  }

  function setBankInfoAccountNumber(val) {
    if (!/^[0-9]+$/.test(val) && val !== "") return;
    const newBankInfoState = _.cloneDeep(editBankInfoState);
    newBankInfoState.accountNumber = val;
    setEditBankInfoState(newBankInfoState);
  }

  function setBankInfoRoutingNumber(val) {
    if (!/^[0-9]+$/.test(val) && val !== "") return;
    const newBankInfoState = _.cloneDeep(editBankInfoState);
    newBankInfoState.routingNumber = val;
    setEditBankInfoState(newBankInfoState);
  }

  function emailValid(email) {
    if (typeof email !== "string") return false;
    const emailPattern =
      /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    return emailPattern.test(email);
  }

  function setBankInfoAccountType(val) {
    if (
      val !== "business:checking" &&
      val !== "business:savings" &&
      val !== "personal:checking" &&
      val !== "personal:savings"
    )
      return;
    const newBankInfoState = _.cloneDeep(editBankInfoState);
    newBankInfoState.accountType = val;
    setEditBankInfoState(newBankInfoState);
  }

  function getWithdrawlError() {
    const defaultErrorMsg = "Something went wrong. Double check your info & try again.";
    if (createPartnershipError === "SERVER_ERROR") return defaultErrorMsg;
    if (addFundingAccountError === "SERVER_ERROR") return defaultErrorMsg;
    if (initiatePaymentError === "SERVER_ERROR") return defaultErrorMsg;
    if (createPartnershipError !== "") return createPartnershipError;
    if (addFundingAccountError !== "") return addFundingAccountError;
    if (initiatePaymentError !== "" && initiatePaymentError !== "NO_FUNDS")
      return initiatePaymentError;
    return "";
  }

  function getNextAvailableWithdrawalText() {
    let res = nextWithdrawalAmount;
    if (nextWithdrawalAmount === undefined) return "";
    let prefix = "$";
    if (nextWithdrawalAmount < 0) {
      prefix = "-$";
      res *= -1;
    }
    return prefix + numberWithCommas(toFixed(res, 2));
  }

  function getGrossAllTimeText() {
    let res = grossAllTimeIncome;
    if (grossAllTimeIncome === undefined) return "";
    let prefix = "$";
    if (grossAllTimeIncome < 0) {
      prefix = "-$";
      res *= -1;
    }
    return prefix + numberWithCommas(toFixed(res, 2));
  }

  function onTransactionRowClick(order) {
    setIrpPaymentIntentId(order.paymentIntentId);
    trackTransactionClicked();
  }

  useEffect(() => {
    if (!routableInfoLoading) {
      getNextWithdrawalAmount();
    }
    getGrossAllTimeIncome();
  }, []);

  const prevProps = usePrevious({
    createPartnershipLoading,
    addFundingAccountLoading,
    initiatePaymentLoading,
    routableInfoLoading,
    initiateCustomerRefundLoading,
  });

  useEffect(() => {
    if (!prevProps) return;
    if (
      prevProps.createPartnershipLoading &&
      !createPartnershipLoading &&
      createPartnershipError === ""
    ) {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((firebaseToken) => {
          addFundingAccount(
            accountNumber,
            routingNumber,
            accountType.split(":")[1],
            hostId,
            firebaseToken
          )(dispatch);
        });
    } else if (
      prevProps.addFundingAccountLoading &&
      !addFundingAccountLoading &&
      addFundingAccountError === ""
    ) {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((firebaseToken) => {
          initiatePayment("ach_same_day", hostId, firebaseToken)(dispatch);
        });
    } else if (
      prevProps.initiatePaymentLoading &&
      !initiatePaymentLoading &&
      initiatePaymentError === "NO_FUNDS"
    ) {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((firebaseToken) => {
          initiatePayment("ach_standard", hostId, firebaseToken)(dispatch);
        });
    } else if (
      prevProps.initiatePaymentLoading &&
      !initiatePaymentLoading &&
      initiatePaymentError === ""
    ) {
      setShowSurvey(true);
      getNextWithdrawalAmount();
      getGrossAllTimeIncome();
    }

    if (
      prevProps.initiateCustomerRefundLoading &&
      !initiateCustomerRefundLoading &&
      initiateCustomerRefundError === ""
    ) {
      getNextWithdrawalAmount();
      getGrossAllTimeIncome();
    }

    if (prevProps.routableInfoLoading && !routableInfoLoading) {
      setPreviousBankSelected(!!routableInfo.payoutInfo);
      getNextWithdrawalAmount();
    }
  });

  return (
    <div className="paymentMain">
      <Survey isOpen={showSurvey} close={() => setShowSurvey(false)} />
      {showPayoutModal && (
        <Modal closeModal={toggleShowPayoutModal} gray>
          {showReauthModal && (
            <Reauthenticate
              onReauthenticate={initiateStart}
              close={() => setShowReauthModal(false)}
            />
          )}
          <PayoutModal
            id="payoutModal"
            withdrawlInfo={
              <WithdrawInfo
                error={getWithdrawlError()}
                link={
                  <p
                    style={{
                      color: colors.gray600,
                      flexShrink: "1",
                      ...textstyles.formLabel,
                    }}
                  >
                    I agree to the{" "}
                    <a
                      href="https://routable.com/legal/terms"
                      target="_blank"
                      style={{
                        color: colors.secondary400,
                        textDecoration: "none",
                        ...textstyles.formLabel,
                      }}
                      rel="noreferrer"
                    >
                      Terms of Service
                    </a>{" "}
                    of Routable.com, Inc., {"Hotplate's"} Payment Partner
                  </p>
                }
                balance={getNextAvailableWithdrawalText()}
                editing={editBankInfoState !== null}
                Checkbox={
                  <WithdrawInfo.Checkbox
                    onChange={() => setTosAccepted(!tosAccepted)}
                    checked={tosAccepted}
                  />
                }
                WithdrawButton={
                  <WithdrawInfo.WithdrawButton
                    loading={initiateLoading()}
                    loadingAnimation={
                      <ReactLoading type={"bubbles"} color={"#FFFFFF"} height={50} width={50} />
                    }
                    disabled={initiateLoading() || withdrawalDisabled()}
                    onClick={() => setShowReauthModal(true)}
                  />
                }
                AccountInfo={
                  <WithdrawInfo.AccountInfo
                    bankName={getActiveBank().bankName}
                    lastFour={getActiveBank().lastFour}
                    editing={editBankInfoState !== null}
                    EditAccount={
                      <WithdrawInfo.AccountInfo.EditAccount onClick={() => addBankInfo()} />
                    }
                    First={
                      editBankInfoState && (
                        <WithdrawInfo.AccountInfo.First
                          onChange={(val) => setBankInfoFirstName(val)}
                          value={editBankInfoState.firstName}
                          handleOnKeyDown={editBankInfoState.firstName.length > 0}
                        />
                      )
                    }
                    Last={
                      editBankInfoState && (
                        <WithdrawInfo.AccountInfo.Last
                          onChange={(val) => setBankInfoLastName(val)}
                          value={editBankInfoState.lastName}
                          handleOnKeyDown={editBankInfoState.lastName.length > 0}
                        />
                      )
                    }
                    Business={
                      editBankInfoState && (
                        <WithdrawInfo.AccountInfo.Business
                          placeholder="Business Name"
                          label="Business Name"
                          onChange={(val) => setBankInfoBusinessName(val)}
                          value={editBankInfoState.businessName}
                          handleOnKeyDown={editBankInfoState.businessName.length > 0}
                        />
                      )
                    }
                    Email={
                      editBankInfoState && (
                        <WithdrawInfo.AccountInfo.Email
                          onChange={(val) => setBankInfoEmail(val)}
                          value={editBankInfoState.email}
                          validationType={
                            (emailValid(editBankInfoState.email) && "valid") ||
                            (editBankInfoState.email.length !== 0 &&
                              !emailValid(editBankInfoState.email) &&
                              "invalid")
                          }
                          validAnimation={
                            emailValid() && (
                              <AnimationContainer animation="check" width="24px" height="24px" />
                            )
                          }
                          type="email"
                          inputMode="email"
                          handleOnKeyDown={editBankInfoState.email.length > 0}
                        />
                      )
                    }
                    AccountNumber={
                      editBankInfoState && (
                        <WithdrawInfo.AccountInfo.AccountNumber
                          onChange={(val) => setBankInfoAccountNumber(val)}
                          value={editBankInfoState.accountNumber}
                          placeholder={IS_PROD ? "Account Number" : "TEST # 856667"}
                          validationType={
                            (editBankInfoState.accountNumber.length >= 6 &&
                              editBankInfoState.accountNumber.length <= 17 &&
                              "valid") ||
                            (editBankInfoState.accountNumber.length !== 0 &&
                              (editBankInfoState.accountNumber.length < 6 ||
                                editBankInfoState.accountNumber.length > 17) &&
                              "invalid")
                          }
                          validAnimation={
                            editBankInfoState.accountNumber.length >= 6 &&
                            editBankInfoState.accountNumber.length <= 17 && (
                              <AnimationContainer animation="check" width="24px" height="24px" />
                            )
                          }
                          handleOnKeyDown={editBankInfoState.accountNumber.length > 0}
                        />
                      )
                    }
                    Routing={
                      editBankInfoState && (
                        <WithdrawInfo.AccountInfo.Routing
                          onChange={(val) => setBankInfoRoutingNumber(val)}
                          value={editBankInfoState.routingNumber}
                          placeholder={
                            IS_PROD ? "Routing Number" : "TEST # Routing Number: 072403004"
                          }
                          validationType={
                            (editBankInfoState.routingNumber.length === 9 && "valid") ||
                            (editBankInfoState.routingNumber.length !== 9 &&
                              editBankInfoState.routingNumber.length !== 0 &&
                              "invalid")
                          }
                          validAnimation={
                            editBankInfoState.routingNumber.length === 9 && (
                              <AnimationContainer animation="check" width="24px" height="24px" />
                            )
                          }
                          handleOnKeyDown={editBankInfoState.routingNumber.length > 0}
                        />
                      )
                    }
                    AccountType={
                      editBankInfoState && (
                        <WithdrawInfo.AccountInfo.AccountType
                          label={
                            editBankInfoState.accountType
                              ? capitalizeFirstLetter(editBankInfoState.accountType.split(":")[0]) +
                                " " +
                                capitalizeFirstLetter(editBankInfoState.accountType.split(":")[1])
                              : "Account Type"
                          }
                          onSelectionChange={(key) => setBankInfoAccountType(key)}
                        >
                          <WithdrawInfo.AccountInfo.AccountType.Option
                            key="business:checking"
                            label="Business Checking"
                          />
                          <WithdrawInfo.AccountInfo.AccountType.Option
                            key="business:savings"
                            label="Business Savings"
                          />
                          <WithdrawInfo.AccountInfo.AccountType.Option
                            key="personal:checking"
                            label="Personal Checking"
                          />
                          <WithdrawInfo.AccountInfo.AccountType.Option
                            key="personal:savings"
                            label="Personal Savings"
                          />
                        </WithdrawInfo.AccountInfo.AccountType>
                      )
                    }
                    SaveAccount={
                      <WithdrawInfo.AccountInfo.SaveAccount
                        disabled={bankInfoInvalid(editBankInfoState)}
                        onClick={saveBankInfo}
                      />
                    }
                    Cancel={
                      (previousBankSelected || !bankInfoInvalid()) && (
                        <WithdrawInfo.AccountInfo.Cancel
                          style={{ marginRight: "16px" }}
                          onClick={cancelAddBankInfo}
                        />
                      )
                    }
                  />
                }
              />
            }
            payoutHistory={<PayoutHistory payouts={getPayoutHistory()} />}
          />
        </Modal>
      )}
      <ItemizedReceipt
        paymentIntentId={irpPaymentIntentId}
        isOpen={!!irpPaymentIntentId}
        onRequestClose={() => setIrpPaymentIntentId(null)}
      />
      <WithdrawPreview
        size={size}
        grossIncome={
          !getGrossAllTimeIncomeLoading ? (
            getGrossAllTimeText()
          ) : (
            <ReactLoading type="spin" height="40px" width="25px" color="#000000" />
          )
        }
        toWithdraw={
          !getNextWithdrawalAmountLoading ? (
            getNextAvailableWithdrawalText()
          ) : (
            <ReactLoading type="spin" height="40px" width="25px" color="#000000" />
          )
        }
        Button={
          <WithdrawPreview.Button
            onClick={toggleShowPayoutModal}
            disabled={hostId === "ryzeupsourdough" || routableInfoLoading}
            loading={routableInfoLoading}
            loadingAnimation={
              <ReactLoading type={"spin"} color={"#FFFFFF"} height={"20px"} width={"20px"} />
            }
          />
        }
      />
      <TransactionHistory rowOnClick={onTransactionRowClick} />
    </div>
  );
};

export default Payment;
