// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import React, { createContext } from "react";
import { Item, Section } from "@visly/core";
import { exists } from "./_internal_utils";
import { isFragment } from "./_internal_component_utils";
export const ItemContext = createContext(null);
export let CollectionType;

(function (CollectionType) {
  CollectionType["Item"] = "Item";
  CollectionType["Section"] = "Section";
})(CollectionType || (CollectionType = {}));

function flattenChildren(children) {
  const childArray = [];
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child) && isFragment(child)) {
      childArray.push(...flattenChildren(child.props.children));
    } else {
      childArray.push(child);
    }
  });
  return childArray;
}

function renderElement(Element) {
  const key = Element.key;
  const type =
    typeof Element.type === "function"
      ? Element.type.__collectionType
      : Element.type;

  if (type === CollectionType.Section) {
    const childArray = [];
    const children = Element.props.children;

    if (exists(children)) {
      React.Children.forEach(flattenChildren(children), (child) => {
        if (React.isValidElement(child)) {
          const key = child.key;
          childArray.push(
            <Item key={key} textValue={`${key}`}>
              {React.cloneElement(child, child.props)}
            </Item>
          );
        }
      });
    }

    return (
      <Section title={React.cloneElement(Element, Element.props)}>
        {childArray}
      </Section>
    );
  }

  return (
    <Item key={key} textValue={`${key}`}>
      {React.cloneElement(Element, Element.props)}
    </Item>
  );
}

export function renderCollection(children) {
  if (!exists(children)) {
    return null;
  }

  const childArray = [];
  React.Children.forEach(flattenChildren(children), (child) => {
    if (React.isValidElement(child)) {
      childArray.push(renderElement(child));
    }
  });

  if (childArray.length === 1) {
    return childArray[0];
  }

  return childArray;
}
