// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { mergeProps } from "@visly/core";
import {
  InteractionState,
  useIsInside,
  noop,
  exists,
  getRootClasses,
  stylesForState,
} from "./_internal_utils";
import { checkVersion } from "./_internal_version";
checkVersion();
export const useMouseHandler = (args) => {
  const { isInside, setPressed, setHovered } = args;
  const [isHandlingEvents, setIsHandlingEvents] = useState(false);
  const handler = createMouseHandler({
    isInside,
    setHovered,
    setIsHandlingEvents,
    setPressed,
  });
  useEffect(() => {
    const resetPressedState = () => {
      if (isHandlingEvents) {
        setIsHandlingEvents(false);
        setPressed(false);
      }
    };

    document.addEventListener("mouseup", resetPressedState);
    return () => {
      document.removeEventListener("mouseup", resetPressedState);
    };
  }, [isHandlingEvents, setIsHandlingEvents, setPressed]);
  return handler;
};
export const createMouseHandler = ({
  isInside,
  setIsHandlingEvents,
  setPressed,
  setHovered,
}) => {
  return {
    onMouseEnter: (event) => setHovered(isInside(event.target)),
    onMouseLeave: (_) => setHovered(false),
    onMouseMove: (event) => setHovered(isInside(event.target)),
    onMouseDown: (event) => {
      setIsHandlingEvents(isInside(event.target));
      setPressed(isInside(event.target));
      event.stopPropagation();
    },
    onMouseUp: (_) => setPressed(false),
  };
};

function useInteractionState(props) {
  const {
    disabled = false,
    _hovered = false,
    _focused = false,
    _pressed = false,
  } = props;
  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);
  const [pressed, setPressed] = useState(false);
  const pairs = [
    [InteractionState.None, true],
    [InteractionState.Hover, _hovered || hovered],
    [InteractionState.Focus, _focused || focused],
    [InteractionState.Pressed, _pressed || pressed],
  ];
  const states = disabled
    ? [InteractionState.None, InteractionState.Disabled]
    : pairs.filter((s) => s[1]).map((s) => s[0]);
  return {
    states,
    setFocused,
    setPressed,
    setHovered,
  };
}

function hasDisabledState(states) {
  return states.includes(InteractionState.Disabled);
}

function useInteractionStateHandlers(
  ref,
  props,
  isInterative,
  ignoreFocusHandling
) {
  const { onClick } = props;
  const { states, setFocused, setHovered, setPressed } = useInteractionState(
    props
  );
  const isInside = useIsInside(ref);
  const mouseHandler = useMouseHandler({
    isInside,
    setHovered,
    setPressed,
  });
  const interactionStateHandlers = {
    onFocus: ignoreFocusHandling
      ? noop
      : (e) => {
          setFocused(true);
          e.stopPropagation();
        },
    onBlur: ignoreFocusHandling
      ? noop
      : () => {
          setFocused(false);
        },
    onClick: (e) => {
      if (exists(onClick)) {
        e.stopPropagation();
      }
    },
    ...mouseHandler,
  };

  if (hasDisabledState(states) || !isInterative) {
    return {
      states,
      setFocused: noop,
      handlers: {},
    };
  }

  return {
    states,
    setFocused,
    handlers: interactionStateHandlers,
  };
}

function filterReactProps(props, disabled) {
  const reactPropKeys = [
    "tabIndex",
    "role",
    "onAuxClick",
    "onAuxClickCapture",
    "onClick",
    "onClickCapture",
    "onContextMenu",
    "onContextMenuCapture",
    "onDoubleClick",
    "onDoubleClickCapture",
    "onDrag",
    "onDragCapture",
    "onDragEnd",
    "onDragEndCapture",
    "onDragEnter",
    "onDragEnterCapture",
    "onDragExit",
    "onDragExitCapture",
    "onDragLeave",
    "onDragLeaveCapture",
    "onDragOver",
    "onDragOverCapture",
    "onDragStart",
    "onDragStartCapture",
    "onDrop",
    "onDropCapture",
    "onMouseDown",
    "onMouseDownCapture",
    "onMouseEnter",
    "onMouseLeave",
    "onMouseMove",
    "onMouseMoveCapture",
    "onMouseOut",
    "onMouseOutCapture",
    "onMouseOver",
    "onMouseOverCapture",
    "onMouseUp",
    "onMouseUpCapture",
    "onTouchCancel",
    "onTouchCancelCapture",
    "onTouchEnd",
    "onTouchEndCapture",
    "onTouchMove",
    "onTouchMoveCapture",
    "onTouchStart",
    "onTouchStartCapture",
    "onPointerDown",
    "onPointerDownCapture",
    "onPointerMove",
    "onPointerMoveCapture",
    "onPointerUp",
    "onPointerUpCapture",
    "onPointerCancel",
    "onPointerCancelCapture",
    "onPointerEnter",
    "onPointerLeave",
    "onPointerOver",
    "onPointerOverCapture",
    "onPointerOut",
    "onPointerOutCapture",
    "onGotPointerCapture",
    "onGotPointerCaptureCapture",
    "onLostPointerCapture",
    "onLostPointerCaptureCapture",
    "onScroll",
    "onScrollCapture",
    "onWheel",
    "onWheelCapture",
    "onKeyDown",
    "onKeyDownCapture",
    "onKeyPress",
    "onKeyPressCapture",
    "onKeyUp",
    "onKeyUpCapture",
    "onFocus",
    "onFocusCapture",
    "onBlur",
    "onBlurCapture",
  ];

  if (disabled) {
    return {
      tabIndex: props.tabIndex,
      role: props.role,
    };
  }

  const reactProps = {};

  for (const key of reactPropKeys) {
    if (exists(props[key])) {
      reactProps[key] = props[key];
    }
  }

  return reactProps;
}

export function usePrimitive({
  ref,
  props,
  ignoreFocusHandling = false,
  isFocusVisible = false,
  isPressed,
  variants = [],
  isInteractive = true,
  noUserSelect = false,
}) {
  const { internal, style: _style = {}, className, innerRef, testId } = props;
  const { states: _states, handlers, setFocused } = useInteractionStateHandlers(
    ref,
    props,
    isInteractive,
    ignoreFocusHandling
  );
  const states = useMemo(
    () => (exists(internal.state) ? [internal.state] : _states),
    [_states, internal.state]
  );

  if (isFocusVisible) {
    states.push(InteractionState.Focus);
  }

  if (isPressed) {
    states.push(InteractionState.Pressed);
  }

  const activeVariants = useMemo(
    () => [...variants, ...internal.activeVariants],
    [internal.activeVariants, variants]
  );
  const vislyClasses = getRootClasses({
    projectId: internal.projectId,
    scope: internal.scope,
    layerId: internal.layerId,
    setVariantProps: activeVariants,
    states,
  });
  const isDisabled = hasDisabledState(states);
  const reactProps = useMemo(() => filterReactProps(props, isDisabled), [
    props,
    isDisabled,
  ]);
  const noSelectStyles =
    exists(props.onClick) || noUserSelect
      ? {
          userSelect: "none",
          WebkitUserSelect: "none",
          KhtmlUserSelect: "none",
          MozUserSelect: "none",
        }
      : {};
  const style = { ..._style, ...noSelectStyles };
  const vislyProps = {
    ...mergeProps(reactProps, handlers),
    className: [className, vislyClasses].filter(exists).join(" "),
  };
  const values = stylesForState({
    styles: internal.styles,
    states,
    variants: activeVariants,
  });
  return {
    states,
    style,
    variants: activeVariants,
    innerRef,
    testId,
    vislyProps,
    values,
    isDisabled,
    setFocused,
    resetProps: {
      className: `__visly_reset_${internal.projectId}`,
    },
    handlers,
  };
}
