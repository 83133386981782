import React from "react";
import { styled, keyframes } from "../../stitches.config";

import * as TooltipPrimitive from "@radix-ui/react-tooltip";

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const StyledContent = styled(TooltipPrimitive.Content, {
  borderRadius: "$sm",
  fontFamily: "$avenir",
  paddingBlock: "$sm",
  paddingInline: "$md",
  fontSize: "14px",
  lh: "$text",
  whiteSpace: "pre-line",
  maxWidth: "min(300px, 90vw)",
  color: "$accent11",
  backgroundColor: "white",
  boxShadow: "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  zIndex: "$tooltip",
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    animationFillMode: "forwards",
    willChange: "transform, opacity",
    transformOrigin: "var(--radix-tooltip-content-transform-origin)",
    '&[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

const StyledArrow = styled(TooltipPrimitive.Arrow, {
  fill: "white",
});

const TooltipRoot = TooltipPrimitive.Root;
const TooltipTrigger = styled(TooltipPrimitive.Trigger, { cursor: "default" });
const TooltipContent = StyledContent;
export const TooltipProvider = TooltipPrimitive.Provider;

export const Tooltip = ({
  children,
  condition = true,
  css,
  content,
  clickable = false,
  ...props
}) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const clickableRootProps = {
    open: tooltipOpen,
    onOpenChange: (tooltipOpen) => setTooltipOpen(!tooltipOpen),
  };

  const clickableTriggerProps = {
    onClick: () => setTooltipOpen(!tooltipOpen),
    onMouseEnter: () => setTooltipOpen(true),
    onMouseLeave: () => setTooltipOpen(false),
  };
  return (
    <>
      {condition ? (
        <TooltipRoot delayDuration={500} {...(clickable ? clickableRootProps : {})}>
          <TooltipTrigger css={css} {...(clickable ? clickableTriggerProps : {})}>
            {children}
          </TooltipTrigger>
          <TooltipPrimitive.Portal>
            <TooltipContent sideOffset={5} avoidCollisions collisionPadding={12} {...props}>
              {content}
              <StyledArrow />
            </TooltipContent>
          </TooltipPrimitive.Portal>
        </TooltipRoot>
      ) : (
        children
      )}
    </>
  );
};
