/* eslint-disable react/prop-types */
import { PopupButton } from "@typeform/embed-react";
import React, { useEffect, useState } from "react";
import { CalendlyEventListener, openPopupWidget } from "react-calendly";
import { useNavigate } from "react-router-dom";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import Icon from "../hotplate-common/Icon";
import { Button } from "../hotplate-common/primitives/Button";
import { Flex, P } from "../hotplate-common/primitives/Containers";
import { ReactComponent as ArrowRight } from "../static/images/arrow-right.svg";
import { ReactComponent as BackedByYC } from "../static/images/logos/backed-by-yc.svg";
import { ReactComponent as HotplateLogo } from "../static/images/logos/outline_logo.svg";
import { ReactComponent as InstaLogo } from "../static/images/shareSvgs/insta-logo.svg";
import { styled } from "../stitches.config";
import { icons } from "../visly";
import { Badge, BubbleTabs, Button as VislyButton, IconButton } from "../visly/Primitives";
import { IS_PROD } from "./actions/types";
import {
  trackDemoScheduled,
  trackDemoTimeSelected,
  trackDemoTimesViewed,
  trackGetStartedClicked,
  trackNavClicked,
  trackSignupCompleted,
} from "./analytics";
import "./css/Home.css";

const features = [
  {
    title: "Get started in seconds",
    subtitle: "Simple Setup",
    description:
      "Setting up a sale on Hotplate takes an average of 3 minutes.\n\nSwitching from a different platform? Let us know and we can migrate your items over for you.",
    icon: icons.cal,
    src: "https://ucarecdn.com/1342dac7-8c16-442f-a2d2-b5b2d5c185f6/eventspic.png",
  },
  {
    title: "Sell food like rockstars sell tickets",
    subtitle: "Built for high-volume",
    description:
      "Customers reserve items when they add them to their cart, and must checkout in time to secure their purchase. This process ensures you never oversell, and gives customers a much less frustrating buying experience.",
    icon: icons.cart,
    src: "https://ucarecdn.com/699dd125-cf13-43b6-851a-2b8ff571e336/",
  },
  {
    title: "Remind customers automatically",
    subtitle: "No more no-shows",
    description:
      "No more hours spent communicating basic info to customers. Hotplate gives your customers a clear checkout, automatic order confirmations, and reminders.",
    icon: icons.billing,
    src: "https://ucarecdn.com/8d5b1941-a728-46f6-bb08-4efd8cc33c53/",
  },

  {
    title: "Move through orders like butter",
    subtitle: "Fire Orders",
    description: "All the information you need, and nothing more. Headache-free service.",
    icon: icons.orders,
    src: "https://ucarecdn.com/9c07cf08-7f3d-4f55-a89d-06b96b026122/packing.png",
  },
  {
    title: "Customer profiles",
    subtitle: "build loyalty",
    description:
      "Clear customer insights to help you make your customer's experience even better. See your first time buyers and your regulars at a glance.",
    icon: icons.person,
    src: "https://ucarecdn.com/32b5952a-479b-4cd6-a3af-73454586440f/",
  },
];

const moreFeatures = [
  {
    name: "Optimize your output at every event",
    title: "Sale Limits",
    description:
      "Hotplate goes beyond inventory: set how much of an item can be bought for every pickup window, day, or per customer.\n\nAutomatically ensure that you orders are cleanly spread across the entire event to maximize your sales without losing your sanity.",
  },
  {
    name: "Everything feels more hype when you add a countdown timer",
    title: "Drop Countdown",
    description:
      "As your event's orders are about to open, customers get a timer that opens the orders for everyone at the same time.\n\nNothing beats a little excitment.",
  },
  {
    name: "Never have your inventory go negative",
    title: "Oversell Protection",
    description:
      "It is impossible to oversell your inventory on Hotplate. Our checkout works like TicketMaster: when customers add items to their cart, they have them reserved until they buy or their time runs out.\n\n95,000+ orders, and not one oversold item.",
  },
  {
    name: "Insights into your sales",
    title: "Event Stats",
    description:
      "Know how may orders were placed, how many items were sold, your total tips, and how much you made at every event.\n\nWant more stats? Let us know for our upcoming Insights update.",
  },
  {
    name: "Take walkups while you cook",
    title: "Hardwareless Walkups",
    description:
      "Print a QR code for every event. Customers can scan, browse, and buy in less than a minute, and place orders without forcing you to constantly jump between the line and your POS",
  },
  {
    name: "Automatic texts to make no-shows a thing of the past",
    title: "Customer Notifications",
    description:
      "Customers are automatically sent a confirmation when they order, and are reminded about their order 24, 8, and 2 hours before their pickup.\n\nCustomize the texts to say exactly what you need them to.",
  },

  {
    name: "Sometimes, nothing beats real paper",
    title: "Ticket Printing",
    description:
      "Print your order tickets so you can label each customer's bag, or to send them down the line while in service.",
  },

  {
    name: "Set & Forget Delivery",
    title: "Delivery",
    description:
      "Set what zip codes you deliver to, the price of delivery, and the minimum cart size to unlock it. All customers have to do is type in their address.\n\nCollect delivery orders & fees easily, not by DMing 20 customers to coordinate.",
  },
  {
    name: "Make your storefront reflect your business",
    title: "Branding",
    description:
      "Set up your color pallete, upload your logo, add images to your events.\n\nWe make it easy for your storefront to reflect you while not getting overwhelmed with the infinite customizations of website builders.",
  },
  {
    name: "Because it should be easy",
    title: "Order Editing & Refunds",
    description:
      "Move customers between pickup times, refund them partially or fully.\n\nYour customers will get notfied automatically when you make changes, so you don't need to worry about it.",
  },
];

// Not being used currently.
const TestimonialSwiper = () => {
  const colors = ["#FF9AA2", "#FFB7B2", "#FFDAC1", "#E2F0CB", "#B5EAD7", "#C7CEEA", "#E5C7EB"];
  const testimonials = [
    {
      name: "Zach Vraa",
      handle: "@atozcreamery",
      quote: (
        <p>
          <em>The simplicity of the entire platform is unmatched.</em> Set your menu, pick up times,
          and manage inventory with ease. The customer service is also direct and immediate when
          questions or issues arise.
        </p>
      ),
      src: "https://ucarecdn.com/0e080b2b-0e32-4706-8213-841877ec2059/",
      link: "https://www.instagram.com/atozcreamery/",
      followerCount: "22.4k followers",
      hotplateLink: "https://hotplate.com/atozcreamery",
    },
    {
      name: "Cathay Bi",
      handle: "@dumplingclubsf",
      quote: (
        <p>
          Hotplate is <em>1000x better than Square</em>
        </p>
      ),
      src: "https://ucarecdn.com/c59e2cc4-c258-4d96-a997-2bd92a85aa17/dumplingclub.jpg",
      link: "https://www.instagram.com/dumplingclubsf/",
      followerCount: "9.2k followers",
      hotplateLink: "https://hotplate.com/dumplingclub",
    },
    {
      name: "Jessie Gil",
      handle: "@elcompavegano",
      quote: (
        <p>
          I&apos;d spend hours on my phone going through DM orders, when I could&apos;ve been
          prepping and cooking. Using Hotplate, I feel like{" "}
          <em>a ton of weight has been lifted off my shoulders</em> 😩🙏
        </p>
      ),
      src: "https://ucarecdn.com/7e5d7fa1-b9d0-42ec-a60b-353f5f0dd077/169363212_295171505503113_1518142852441641594_n.jpg",
      link: "https://www.instagram.com/elcompavegano/",
      followerCount: "16.8k followers",
      hotplateLink: "https://hotplate.com/elcompavegano",
    },
    {
      name: "Mikkijo Bayawa",
      handle: "@riceculturex",
      quote: (
        <p>
          I love the simplicity of it. And the look and feel of it. And how easy it is to just
          create something on the fly.
        </p>
      ),
      src: "https://ucarecdn.com/5976c532-0381-4d38-b0a0-d6152cec9010/SCR20230208qke.png",
      link: "https://www.instagram.com/riceculturex",
      followerCount: "9.9k followers",
      hotplateLink: "https://hotplate.com/riceculture",
    },
    {
      name: "Daniel Koester",
      handle: "@danthebakerchicago",
      quote: (
        <p>
          It's very simple to use, and has eliminated all the errors and mistakes that are so easy
          to make with other products. Plus sms reminders are really awesome!
        </p>
      ),
      src: "https://ucarecdn.com/c5eb2ccb-aaef-45a2-b532-fdc83eed04e8/SCR20230208qpi.png",
      link: "https://www.instagram.com/danthebakerchicago",
      followerCount: "14.8k followers",
      hotplateLink: "https://hotplate.com/danthebakerchicago",
    },
    {
      name: "Secret Pizza",
      handle: "@secretpizzala",
      quote: (
        <p>
          Hotplate has improved the customer experience and made it{" "}
          <em>much simpler and more streamlined.</em> I don&apos;t have the headaches I used to have
          with my old system.
        </p>
      ),
      src: "https://ucarecdn.com/cac4ab13-3bf7-4d1f-b373-b207b32d1106/185393513_1145171252576750_5649444532344645055_n.jpg",
      link: "https://www.instagram.com/secretpizzala/",
      followerCount: "14.4k followers",
      hotplateLink: "https://hotplate.com/secretpizzala",
    },

    {
      name: "Josh Delton",
      handle: "@undergroundcreamery",
      quote: (
        <p>
          I tried using platforms such as Shopify and Squarespace E-Commerce until it became
          apparent that they were not meant for a food and beverage pop up concepts. With small
          business and limited supplies, features such as cart holding and purchase limits make it{" "}
          <em>much more accessible to consumers</em> and it&apos;s{" "}
          <em>so much easier for us vendors to manage.</em> There&apos;s also a peace of mind
          Hotplate gives me knowing they&apos;re open to feedback giving out a hotline for consumers
          and vendors.
        </p>
      ),
      src: "https://ucarecdn.com/49fc8051-fad6-4903-b362-316a2536e2d5/",
      link: "https://www.instagram.com/undergroundcreamery/?hl=en",
      followerCount: "16.5k followers",
      hotplateLink: "https://hotplate.com/undergroundcreamery",
    },
  ];
  return (
    <Swiper
      modules={[Navigation, Autoplay]}
      navigation={{
        nextEl: ".testimonial-button-next",
        prevEl: ".testimonial-button-prev",
      }}
      autoplay={{
        delay: 10000,
        disableOnInteraction: true,
      }}
      grabCursor={true}
      breakpoints={{
        480: {
          slidesPerView: 1.5,
        },
        768: {
          slidesPerView: 2.5,
          spaceBetween: 24,
        },
        1024: {
          slidesPerView: 3.25,
          spaceBetween: 24,
        },
        1280: {
          slidesPerView: 3.5,
          spaceBetween: 32,
        },
      }}
      centeredSlides
      spaceBetween={16}
      loop={true}
      effect="slide"
      slidesPerView={1.25}
    >
      {testimonials.map((testimonial, index) => (
        <SwiperSlide key={index}>
          <div
            className="testimonial-card"
            style={{
              "--color": colors[index % colors.length],
            }}
            onClick={() => {
              window.open(testimonial.hotplateLink, "_blank");
            }}
          >
            <div className="__quote avenir">{testimonial.quote}</div>
            <div className="__user-info">
              <div
                className="__photo-container"
                style={{ "--delay": Math.floor(Math.random() * 10) }}
              >
                <img className="__photo" src={testimonial.src} alt={testimonial.name} />
              </div>
              <div className="__name-wrap">
                <h1
                  className="__name arboria"
                  onClick={() => {
                    window.open(testimonial.hotplateLink, "_blank");
                  }}
                >
                  {testimonial.name}
                </h1>
                <a
                  className="__handle avenir"
                  href={testimonial.link}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <h2>{testimonial.handle}</h2>
                  <h2>{testimonial.followerCount}</h2>
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const FeatureContentItem = ({ title, subtitle, description, icon, order, src }) => {
  return (
    <div className="features-content__item" data-order={order}>
      <header className="subtitle-wrap avenir">
        <div className="icon-wrap">
          <Icon icon={icon} color="#FFFFFF" size="24px" />
        </div>
        <h2 className="subtitle arboria">{subtitle}</h2>
      </header>
      <h1 className="title arboria">{title}</h1>
      <p className="description avenir">{description}</p>
      <div className="features-content__img-wrap--mobile">
        <img src={src} className="features-content__img--mobile"></img>
      </div>
    </div>
  );
};

const Plancard = ({ title, price, fee, button, delay }) => {
  return (
    <div className="pricing-plan--card drop-in" style={{ "--delay": delay }}>
      <h1 className="__plan-title avenir" aria-label="Plan Name">
        {title}
      </h1>
      <h2 className="__plan-price arboria" aria-label="Plan Price per Month">
        {price}
        <span>/mo</span>
      </h2>
      <h3 className="__plan-fee arboria">{fee}</h3>
      <p className="__plan-description avenir">Transaction fee paid by your customers</p>
      {/* // TODO: add tooltip providing average cost */}
      {button}
    </div>
  );
};

const TypeformButton = ({ className, onClick, text }) => {
  const innerText = text || "Get Started";
  const searchParams = new URL(window.location).searchParams;
  const referral = searchParams.get("referral") || "none";
  return (
    <div className="typeform-btn" onClick={onClick}>
      {IS_PROD ? (
        // Prod form
        <PopupButton
          id="VGoZa7YC"
          hidden={{
            referral: referral,
          }}
          className={className}
        >
          {innerText}
        </PopupButton>
      ) : (
        // Test form
        <PopupButton
          id="mDWCQZwW"
          hidden={{
            referral: referral,
          }}
          className={className}
        >
          {innerText}
        </PopupButton>
      )}
    </div>
  );
};

const NavPrimitive = styled("nav", {
  display: "flex",
  alignItems: "center",
  variants: {
    kind: {
      desktop: {
        display: "none",
        "@desktop_md": {
          display: "flex",
          padx: 8,
          borderLeft: "1px solid $gray6",
          justifyContent: "flex-start",
        },
      },
      mobile: {
        justifyContent: "space-between",
        width: "100%",
        maxWidth: "100%",
        overflow: "auto",
        "@desktop_sm": {
          justifyContent: "center",
          gap: 32,
          overflow: "visible",
        },
        "@desktop_md": {
          display: "none",
        },
      },
    },
  },
});

const NavButton = styled("button", {
  all: "unset",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 40,
  padx: 12,
  borderRadius: "$sm",
  backgroundColor: "transparent",
  color: "$gray11",
  ff: "$avenir",
  fs: 14,
  fw: "$semi_bold",
  whiteSpace: "nowrap",
  cursor: "pointer",
  transition: "background-color 100ms ease-in-out, color 100ms ease-in-out",
  "&:hover": {
    backgroundColor: "$overlay3",
    color: "$gray12",
  },
  "@desktop_sm": {
    padx: 16,
  },
});

const HomeNav = ({ kind, ...props }) => {
  const sections = [
    {
      text: "Testimonials",
      sectionId: "#testimonials-section",
      eventProp: "testimonials",
    },
    {
      text: "Why Hotplate?",
      sectionId: "#why-hotplate-section",
      eventProp: "why-hotplate",
    },
    {
      text: "Features",
      sectionId: "#features-section",
      eventProp: "features",
    },
    {
      text: "Pricing",
      sectionId: "#pricing-section",
      eventProp: "pricing",
    },
  ];

  return (
    <NavPrimitive kind={kind} {...props}>
      {sections.map(({ text, sectionId, eventProp }) => (
        <NavButton
          key={text}
          onClick={() => {
            window.scrollTo({
              top: document.querySelector(sectionId).offsetTop - 120,
              behavior: "smooth",
            });
            trackNavClicked({
              section: eventProp,
            });
          }}
        >
          {text}
        </NavButton>
      ))}
    </NavPrimitive>
  );
};

const Homepage = () => {
  const navigate = useNavigate();
  const [selectedFeature, setSelectedFeature] = useState({
    name: "Optimize your output at every event",
    title: "Sale Limits",
    description:
      "Hotplate goes beyond inventory: set how much of an item can be bought for every pickup window, day, or per customer.\n\nAutomatically ensure that you orders are cleanly spread across the entire event to maximize your sales without losing your sanity.",
  });

  useEffect(() => {
    const header = document.querySelector(".header__inner");
    // pseudo div @ top of page
    const subject = document.querySelector(".subject-container");
    // adding a top margin of 16px for the obersver
    // adding this margin effectively shifts the subjetc "down" in the eyes of the observer
    const subjectOptions = {
      rootMargin: "16px 0px 0px 0px",
    };
    const subjectObserver = new IntersectionObserver(function (entries) {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          header.classList.add("--scrolled");
        } else {
          header.classList.remove("--scrolled");
        }
      });
    }, subjectOptions);

    subjectObserver.observe(subject);

    const crossedTextEls = document.querySelectorAll(".hp-feature-cross__item");
    const crossedTextOptions = {
      rootMargin: "100px 0px 0px 0px",
    };

    const crossedTextObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add("cross-out");
        } else {
          entry.target.classList.remove("cross-out");
        }
      });
    }, crossedTextOptions);

    crossedTextEls.forEach((el) => {
      crossedTextObserver.observe(el);
    });

    // select photos within the sticy container
    const featurePhotoEls = document.querySelectorAll(".features-images--sticky-items__img ");

    // select the feature elements that will be used to toggle photo visibility
    const featureTextEls = document.querySelectorAll(".features-content__item");

    const featureTextOptions = {
      threshold: 0.4,
    };

    const featureOberserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          Array.from(featurePhotoEls)
            .filter((el) => el.dataset.order === entry.target.dataset.order)
            .forEach((el) => {
              el.classList.add("--show-img");
            });
        } else {
          Array.from(featurePhotoEls)
            .filter((el) => el.dataset.order === entry.target.dataset.order)
            .forEach((el) => {
              el.classList.remove("--show-img");
            });
        }
      });
    }, featureTextOptions);

    featureTextEls.forEach((el) => {
      featureOberserver.observe(el);
    });

    const dropInEls = document.querySelectorAll(".drop-in");
    const dropInOptions = {
      threshold: 1,
      rootMargin: "200px 0px 0px 0px",
    };
    const dropInObserver = new IntersectionObserver(handleDropIn, dropInOptions);

    dropInEls.forEach((el) => {
      dropInObserver.observe(el);
    });

    function handleDropIn(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("--appear");
          dropInObserver.unobserve(entry.target);
        } else {
          return;
        }
      });
    }

    // if url is /post-signup, open demo scheduler and pass in search params

    if (window.location.pathname === "/post-signup") {
      openDemoScheduler(getDemoSchedulerPrefill());
    }

    return () => {
      // subjectObserver.unobserve(subject);
      // crossedTextObserver.disconnect();
      // featureOberserver.disconnect();
      // dropInObserver.disconnect();
    };
  }, []);

  function getDemoSchedulerPrefill() {
    const searchParams = new URL(window.location).searchParams;
    const referral = searchParams.get("referral") === "none" ? "" : searchParams.get("referral");
    const prefillValuesForDemoScheduler = {
      name: searchParams.get("name"),
      email: searchParams.get("email"),
      customAnswers: {
        a1: searchParams.get("phone"),
        a2: searchParams.get("instaURL") || "",
        a3: referral,
      },
    };
    return prefillValuesForDemoScheduler;
  }

  function openDemoScheduler(prefill) {
    trackSignupCompleted({
      name: prefill.name,
      email: prefill.email,
      phone: prefill.customAnswers.a1,
      instagram: prefill.customAnswers.a2,
      referral: prefill.customAnswers.a3,
    });
    const url = "https://calendly.com/hotplate/waitlist-demo-schedule";
    const pageSettings = { hideGdprBanner: true };
    const utm = "";
    openPopupWidget({ url, prefill, pageSettings, utm });
  }

  const postSignup = window.location.pathname === "/post-signup";
  const searchParams = new URL(window.location).searchParams;
  const prefillValuesForDemoScheduler = {
    name: searchParams.get("name"),
    email: searchParams.get("email"),
    customAnswers: {
      a1: searchParams.get("phone"),
      a2: searchParams.get("instaURL"),
      a3: searchParams.get("referral"),
    },
  };

  return (
    <main className="home" aria-label="Hotplate Homepage">
      <CalendlyEventListener
        onDateAndTimeSelected={(e) => trackDemoTimeSelected(e)}
        onEventScheduled={(e) => trackDemoScheduled(e)}
        onEventTypeViewed={(e) => trackDemoTimesViewed(e)}
      />
      <header className="header-main" aria-label="Login or Schedule a Demo">
        <div className="header__inner">
          <Flex
            css={{
              width: "100%",
              alignItems: "center",
              marginBottom: "16px !important",
              "@desktop_md": { marginBottom: "0px !important" },
            }}
          >
            <a className="__logo" href="#">
              <HotplateLogo height="100%" />
            </a>
            <HomeNav kind="desktop" />
            <div className="header__inner__buttons">
              <VislyButton
                kind="quiet"
                text="Login"
                id="get-started-button"
                aria-label="Login"
                className="header__button --secondary"
                onClick={() => {
                  navigate("/portal");
                }}
              />
              {postSignup ? (
                <VislyButton
                  text="Get Started"
                  kind="primary"
                  className="header__button --primary"
                  onClick={() => openDemoScheduler(prefillValuesForDemoScheduler)}
                />
              ) : (
                <TypeformButton
                  className="header__button --primary"
                  onClick={() => trackGetStartedClicked("nav")}
                />
              )}
            </div>
          </Flex>
          <HomeNav kind="mobile" />
        </div>
      </header>
      <div className="subject-container"></div>
      <section
        className="hero-section"
        aria-label="Hotplate is really cool and makes starting your own online food business really easy, smiley face"
      >
        <div className="hero-section__cta-content">
          <h1
            className="cta__title arboria wgt-bold drop-in"
            style={{ "--delay": 0 }}
            aria-label="The simple way to setup sales and manage orders for your online food business"
          >
            The <strong>storefront</strong> built for <strong>food drops</strong>
            <div style={{ display: "none" }}>
              for&nbsp;your&nbsp;
              <span
                className="typing-text"
                data-wait="4000"
                data-words="Dumpling Drops, Bake Sales, Breakfast Sandos, Festive Pies, Luxury Bento"
              ></span>
            </div>
          </h1>

          <h2 className="cta__subtitle avenir wgt-medium drop-in" style={{ "--delay": 200 }}>
            Hotplate is the simple, streamlined way to create your food sales without worrying about
            over-selling, confused customers, or hacky setup.
          </h2>
          <div
            className="btn-wrap drop-in"
            style={{ "--delay": 400, gap: 32, flexWrap: "wrap-reverse" }}
          >
            <Button
              onClick={() =>
                window.scrollTo({
                  top: document.querySelector("#testimonials-section").offsetTop - 120,
                  behavior: "smooth",
                })
              }
              color="accent"
              variant="tinted"
              size="large"
              css={{
                height: 56,
                width: 300,
                borderRadius: 8,
                "@media (min-width: 576px)": { width: 200 },
              }}
            >
              Testimonials
            </Button>
            {postSignup ? (
              <VislyButton
                kind="primary"
                className="cta-btn"
                text="Get Started"
                onClick={() => openDemoScheduler(prefillValuesForDemoScheduler)}
              />
            ) : (
              <TypeformButton
                className="cta-btn"
                aria-label="Click here to get started on Hotplate"
                onClick={() => trackGetStartedClicked("hero-section")}
              />
            )}
          </div>
          <h3 className="cta__stats avenir wgt-medium drop-in" style={{ "--delay": 200 }}>
            150,000+ orders placed • $5,000,000+ in sales so far
          </h3>
        </div>
        {/* <div className="hero-section__image">
            <div className="hero-section__image__inner">
              <img
                className="hero-photo"
                src="https://ucarecdn.com/4264da5a-738b-4d93-96d3-497e48125875/bakesum_storefront.png"
                data-words="Bake Sales"
              />
              <img
                className="hero-photo"
                src="https://ucarecdn.com/6befe33c-6853-4ff9-b9a7-2f3a28224e51/calabama.png"
                data-words="Breakfast Sandos"
              />
              <img
                className="hero-photo"
                src="https://ucarecdn.com/4e8070db-9cf9-42f8-89e9-77b9bab4c7c9/frozen_treats.png"
                data-words="Festive Pies"
              />
              <img
                className="hero-photo"
                src="https://ucarecdn.com/41372435-9d49-4a57-b6e0-6d5afaf1409d/Suki_Yuki.png"
                data-words="Luxury Bento"
              />
              <img
                className="hero-photo"
                src="https://ucarecdn.com/f47cf898-9aa4-4e38-8dcd-d9dbf8905dd1/dumplingclub_storefront.png"
                data-words="Dumpling Drops"
              />
            </div>
          </div> */}
      </section>

      <section
        className="testimonial-section"
        aria-label="Customer Testimonials"
        id="testimonials-section"
      >
        {/* <h2 className="__subtitle avenir wgt-medium drop-in" style={{ "--delay": 0 }}>
            Testimonials
          </h2>
          <h1 className="__title arboria drop-in" style={{ "--delay": 200 }}>
            Trusted by the best popups around the country.
          </h1> */}
        <div className="testimonial-section__inner">
          <TestimonialSwiper />
          <div className="testimonial-nav flex jcc drop-in" style={{ "--delay": 400 }}>
            <IconButton
              rounded
              icon={icons.arrowLeft}
              aria-label="Previous Testimonial"
              className="testimonial-button-prev"
              style={{ marginRight: "1.25rem" }}
            />
            <IconButton
              rounded
              aria-label="Next Testimonial"
              icon={icons.arrowRight}
              className="testimonial-button-next"
            />
          </div>
        </div>
      </section>
      <section
        className="section organized-section"
        aria-label="Stay organized, without any extra work"
      >
        <h2
          className="organized-section__subtitle avenir wgt-medium drop-in"
          style={{ "--delay": 0 }}
        >
          Your entire business at a glance
        </h2>
        <h1
          className="organized-section__title arboria wgt-medium drop-in"
          style={{ "--delay": 200 }}
        >
          Stay organized, without any extra work
        </h1>
        <div className="hp-feature-cross">
          <div className="hp-feature-cross__item arboria" style={{ "--order": 1 }}>
            Spreadsheets
          </div>
          <div className="hp-feature-cross__item arboria" style={{ "--order": 2 }}>
            Front of house headaches
          </div>
          <div className="hp-feature-cross__item arboria" style={{ "--order": 3 }}>
            Hours of DMs
          </div>
        </div>
      </section>
      <section
        className="feature-preview-section"
        aria-label="The Core Features of Hotplate"
        id="why-hotplate-section"
      >
        <div className="feature-preview-section__inner">
          <div className="features-content">
            {features.map((feature, index) => (
              <FeatureContentItem
                key={index}
                title={feature.title}
                subtitle={feature.subtitle}
                description={feature.description}
                icon={feature.icon}
                src={feature.src}
                order={index + 1}
              />
            ))}
          </div>
          <div className="features-images--sticky">
            <div className="features-images--sticky-items">
              <img src={features[0].src} className="features-images--placeholder"></img>
              {features.map((feature, index) => (
                <img
                  key={index}
                  src={feature.src}
                  className="features-images--sticky-items__img"
                  data-order={index + 1}
                ></img>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section
        className="more-features-section"
        aria-label="More Features of Hotplate"
        id="features-section"
      >
        <div className="more-features-section__inner">
          <h2 className="__subtitle avenir wgt-medium drop-in" style={{ "--delay": 0 }}>
            Features
          </h2>
          <h1 className="__title arboria drop-in" style={{ "--delay": 200 }}>
            Building the tools chefs need, every day.
          </h1>
          <div className="__selected-feature">
            <BubbleTabs className="__nav-tabs" value={selectedFeature.title}>
              {moreFeatures.map((feature, index) => (
                <BubbleTabs.Button
                  key={index}
                  onClick={() => setSelectedFeature(feature)}
                  value={feature.title}
                  text={feature.title}
                />
              ))}
            </BubbleTabs>
            <h2 className="__feature-title arboria">{selectedFeature.name}</h2>
            <p className="__feature-description avenir">{selectedFeature.description}</p>
            <a className="feature-inline-link arboria">
              {postSignup ? (
                <h3
                  className="__label"
                  aria-label="Click here to get started on Hotplate"
                  onClick={() => openDemoScheduler(prefillValuesForDemoScheduler)}
                >
                  Get Started on Hotplate
                </h3>
              ) : (
                <TypeformButton
                  className="__label"
                  text="Get Started on Hotplate"
                  onClick={() => trackGetStartedClicked("features-section")}
                />
              )}
              <div className="__icon-wrap">
                <ArrowRight height="24px" width="24px" fill="#e85b5b" />
              </div>
            </a>
          </div>
          <div className="features-coming-soon-grid">
            <div className="flex fdc features-coming-soon--left">
              <h2 className="__hero-title arboria">And we{"'"}re just getting started.</h2>
              {/* <a
                  className="roadmap-inline-link arboria drop-in"
                  style={{ "--delay": 100 }}
                >
                  <h3
                    className="__label"
                    aria-label="Click here to checkout our roadmap"
                  >
                    Checkout our roadmap
                  </h3>
                  <div className="__icon-wrap">
                    <ArrowRight height="24px" width="24px" fill="#e85b5b" />
                  </div>
                </a> */}
              <h4 className="__hero-footer avenir drop-in" style={{ "--delay": 250 }}>
                We’re building the tools you need, every day.
                {/* Missing something you need? Let us know and we{"'"}ll add it. */}
              </h4>
            </div>
            <div className="features-coming-soon--right avenir">
              <h3 className="coming-soon avenir" aria-label="Features Coming Soon">
                Coming soon
              </h3>
              <p className="drop-in" style={{ "--delay": 0 }}>
                Memberships & Subscriptions
              </p>
              <div className="feature-complete drop-in" style={{ "--delay": 0 }}>
                <p>SMS Drop Reminders</p>
                <Badge text="LIVE" light="good" />
              </div>
              <p className="drop-in" style={{ "--delay": 0 }}>
                Hotplate POS Integrations
              </p>
              <div className="feature-complete drop-in" style={{ "--delay": 0 }}>
                <p>Customer Insights & Analytics</p>
                <Badge text="LIVE" light="good" />
              </div>

              <p className="drop-in" style={{ "--delay": 0 }}>
                Invoicing
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="layered-waves"></div>

      <section
        className="pricing-section"
        aria-label="How much Hotplate costs to use"
        id="pricing-section"
      >
        <div className="pricing-section__inner">
          <h1 className="__title arboria drop-in" style={{ "--delay": 0 }}>
            By the way, it&apos;s practically free
          </h1>
          {/* <h2 className="__subtitle avenir drop-in" style={{ "--delay": 250 }}>
            Two ways to pay, get all of the features of Hotplate either way.
          </h2> */}
          <div className="pricing-plans-wrap">
            <Plancard
              delay={250}
              title="Free"
              price="$0"
              fee="5%"
              button={
                postSignup ? (
                  <VislyButton
                    className="__plan-btn"
                    text="Get Started"
                    onClick={() => openDemoScheduler(prefillValuesForDemoScheduler)}
                  />
                ) : (
                  <TypeformButton
                    className="__plan-btn"
                    onClick={() => trackGetStartedClicked("plans-section_free")}
                  />
                )
              }
            />
            {/* <Plancard
              delay={500}
              title="Paid"
              price="$29"
              fee="2%"
              button={
                postSignup ? (
                  <VislyButton
                    className="__plan-btn"
                    text="Get Started"
                    onClick={() => openDemoScheduler(prefillValuesForDemoScheduler)}
                  />
                ) : (
                  <TypeformButton
                    className="__plan-btn"
                    onClick={() => trackGetStartedClicked("plans-section_paid")}
                  />
                )
              }
            /> */}
          </div>
          <p className="__stripe-fees avenir drop-in" style={{ "--delay": 0 }}>
            You pay a 2.9% + 30¢ credit card fee for each transaction, which we forward to our
            payment processing partner, Stripe.
          </p>
          <P
            className="drop-in"
            css={{ "--delay": 0, ff: "$avenir", color: "$gray5", textStyle: "text-1", mt: "$xl" }}
          >
            {`Selling more than $10,000 per month and want to pay some of the fee yourself? Mention it to us when you sign up and we can figure out a plan that works for you.`}
          </P>
        </div>
      </section>
      <footer className="footer" aria-label="Sign up to use Hotplate!">
        <div className="footer__inner">
          <div className="footer_row --top">
            <div className="__logo-col">
              <h1 className="__blurb arboria">
                Here{"'"}s to a world
                <br />
                with more freedom.
              </h1>
              <h2 className="with-love avenir">Made with 🤍 by Andy & Ben & Rishi</h2>
            </div>
            <div className="__logo-wrap">
              <BackedByYC height="100%" />
            </div>
          </div>
          <div className="footer_row --bot">
            <div className="__col socials">
              <a
                className="footer-inline-link arboria"
                href="https://www.instagram.com/hotplate/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="__icon-wrap">
                  <InstaLogo height="100%" width="100%" />
                </div>
                <label className="__label" aria-label="Hotplate's Instagram">
                  Instagram
                </label>
              </a>
            </div>
            <div className="__col">
              <a className="footer-inline-link arboria" href="mailto:support@hotplate.com">
                <label className="__label" aria-label="Support Email">
                  Support
                </label>
              </a>
              <a
                className="footer-inline-link arboria"
                href="https://www.iubenda.com/privacy-policy/45202832"
                target="_blank"
                rel="noopener noreferrer"
              >
                <label className="__label" aria-label="Privacy Policy">
                  Privacy
                </label>
              </a>
              <a
                className="footer-inline-link arboria"
                href="https://www.iubenda.com/terms-and-conditions/45202832"
                target="_blank"
                rel="noopener noreferrer"
              >
                <label className="__label" aria-label="Terms of Use">
                  Terms
                </label>
              </a>
              <label className="footer-copyright arboria">
                © Hotplate {new Date().getFullYear()}
              </label>
            </div>
          </div>
        </div>
      </footer>
    </main>
  );
};

export default Homepage;
