// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./DraggableSection.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
  IconPrimitive,
} from "../_internal_primitives";
import * as icons from "../icons";

const styles = [
  {
    type: "default",
    layers: {
      TxMFx3AhhP: {
        none: {
          icon: icons.draggable,
          useMask: true,
        },
      },
      GQZ9nAkPaW: {
        none: {
          text: "Section Name",
        },
      },
      RrJaVKrFZj: {
        none: {
          text: "4 items",
        },
      },
    },
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
];

const variantPropTypes = [];

export const DraggableSectionContext = createContext(null);

function DraggableSection(_props) {
  const defaults = useContext(DraggableSectionContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="43Q4pZtbdj"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "43Q4pZtbdj",
        scope: "TEwkbHKtpQ",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"text-container_PkedpenQCh"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_TEwkbHKtpQ_PkedpenQCh"
            }
            key={"PkedpenQCh"}
            addSpacing={false}
            element={getStyle("PkedpenQCh", "htmlElement")}
          >
            <TextPrimitive
              id={"title_GQZ9nAkPaW"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_TEwkbHKtpQ_GQZ9nAkPaW"
              }
              key={"GQZ9nAkPaW"}
              text={
                exists(props.title)
                  ? props.title
                  : getStyle("GQZ9nAkPaW", "text")
              }
              element={getStyle("GQZ9nAkPaW", "htmlElement")}
            />
            <SpacerPrimitive
              id={"Spacer_BiapJUDhto"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_TEwkbHKtpQ_BiapJUDhto"
              }
              key={"BiapJUDhto"}
            />
            <TextPrimitive
              id={"itemCountString_RrJaVKrFZj"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_TEwkbHKtpQ_RrJaVKrFZj"
              }
              key={"RrJaVKrFZj"}
              text={
                exists(props.itemCountString)
                  ? props.itemCountString
                  : getStyle("RrJaVKrFZj", "text")
              }
              element={getStyle("RrJaVKrFZj", "htmlElement")}
            />
          </ContainerPrimitive>
          <SpacerPrimitive
            id={"Spacer_DJxrRpQgck"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_TEwkbHKtpQ_DJxrRpQgck"
            }
            key={"DJxrRpQgck"}
          />
          <IconPrimitive
            id={"Icon_TxMFx3AhhP"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_TEwkbHKtpQ_TxMFx3AhhP"
            }
            key={"TxMFx3AhhP"}
            useMask={getStyle("TxMFx3AhhP", "useMask")}
            src={getStyle("TxMFx3AhhP", "icon")}
          />
        </>
      )}
    </RootPrimitive>
  );
}

DraggableSection.__variants = [];

export default DraggableSection;
