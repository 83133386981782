// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../textstyles/fonts.css";
import "../reset.css";
import "./InputPhone.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../_internal_utils";
import { TextPrimitive, ContainerPrimitive } from "../_internal_primitives";
import { Root, TextFieldPrimitive } from "../_internal_text_field";

const styles = [
  {
    type: "default",
    layers: {
      input: {
        none: {
          "placeholder-text": "Mobile phone number",
        },
      },
      "5g7TB5hmXU": {
        none: {
          text: "+1 🇺🇸",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "handleOnKeyDown",
    layers: {},
  },
  {
    type: "boolean",
    propName: "loading",
    layers: {},
  },
  {
    type: "enum",
    propName: "status",
    values: [
      {
        propValue: "enterCode",
        layers: {
          input: {
            none: {
              "placeholder-text": "Enter SMS Code",
            },
          },
          "5g7TB5hmXU": {
            none: {
              text: "💬 code",
            },
          },
        },
      },
      {
        propValue: "success",
        layers: {
          input: {
            none: {
              "placeholder-text": "Success",
            },
          },
        },
      },
      {
        propValue: "failure",
        layers: {
          input: {
            none: {
              "placeholder-text": "Incorrect, try again",
            },
          },
        },
      },
    ],
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {},
  },
  {
    type: "boolean",
    propName: "handleOnKeyDown",
    layers: {},
  },
  {
    type: "boolean",
    propName: "loading",
    layers: {},
  },
  {
    type: "enum",
    propName: "status",
    values: [
      {
        propValue: "enterCode",
        layers: {},
      },
      {
        propValue: "success",
        layers: {},
      },
      {
        propValue: "failure",
        layers: {},
      },
    ],
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "handleOnKeyDown",
  },
  {
    type: "boolean",
    propName: "loading",
  },
  {
    type: "enum",
    propName: "status",
    propValues: ["enterCode", "success", "failure"],
  },
];

export const InputPhoneContext = createContext(null);

function InputPhone(_props) {
  const defaults = useContext(InputPhoneContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <Root
      {...props}
      key="root"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "root",
        scope: "XnUxvwPVUA",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <>
          <ContainerPrimitive
            id={"Container_Rxh7LBquzR"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_XnUxvwPVUA_Rxh7LBquzR"
            }
            key={"Rxh7LBquzR"}
            addSpacing={false}
            element={getStyle("Rxh7LBquzR", "htmlElement")}
          >
            {
              <TextPrimitive
                id={"Label_5g7TB5hmXU"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_XnUxvwPVUA_5g7TB5hmXU"
                }
                key={"5g7TB5hmXU"}
                text={
                  exists(props.label)
                    ? props.label
                    : getStyle("5g7TB5hmXU", "text")
                }
                element={getStyle("5g7TB5hmXU", "htmlElement")}
              />
            }
          </ContainerPrimitive>
          <ContainerPrimitive
            id={"Container_5kzWNAcw2H"}
            className={
              "__visly_reset_7X7HTLRqyD __visly_scope_XnUxvwPVUA_5kzWNAcw2H"
            }
            key={"5kzWNAcw2H"}
            addSpacing={false}
            element={getStyle("5kzWNAcw2H", "htmlElement")}
          >
            <TextFieldPrimitive
              id={"sign_in_input"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_XnUxvwPVUA_input"
              }
              key={"input"}
              placeholder={
                exists(props.placeholder)
                  ? props.placeholder
                  : getStyle("input", "placeholder-text")
              }
            />
            <ContainerPrimitive
              id={"Container_6R8U22uuRV"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_XnUxvwPVUA_6R8U22uuRV"
              }
              key={"6R8U22uuRV"}
              addSpacing={false}
              element={getStyle("6R8U22uuRV", "htmlElement")}
            >
              {
                <ContainerPrimitive
                  key={"WRLyiRDJ29"}
                  id={"Slot_WRLyiRDJ29"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_XnUxvwPVUA_WRLyiRDJ29"
                  }
                  addSpacing={false}
                >
                  {props.loadingAnimation}
                </ContainerPrimitive>
              }
            </ContainerPrimitive>
          </ContainerPrimitive>
        </>
      )}
    </Root>
  );
}

InputPhone.__variants = [
  {
    name: "handleOnKeyDown",
    type: "variant",
  },
  {
    name: "loading",
    type: "variant",
  },
  {
    name: "status",
    type: "group",
    variants: ["enterCode", "success", "failure"],
  },
];

export default InputPhone;
