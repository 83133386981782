// write boiler plate for functional component
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { ShopReduxState, ChefStyles } from "./types";
import { Dialog } from "../../hotplate-common/primitives/Dialog";
import { Button } from "../../hotplate-common/primitives/Button";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { stripeFrontendApiKey } from "../actions/types";
import { Input } from "../../hotplate-common/primitives/Input";
import {
  toFixed,
  priceInputIsValid,
  getUnFormattedPhone,
  getFormattedPhone,
} from "@hotplate/utils-ts/helperFunctions";
import "../components/css/CheckoutForm.css";
import { useMutation } from "@tanstack/react-query";
import {
  ensureOk,
  initializeGeneralShopPaymentIntentMutation,
  prepareToConfirmGeneralShopPaymentMutation,
} from "../../mutations";
import { setToastPopupInfo as setToastPopupInfoUnconnected } from "../actions";
import { useConnectedFn } from "../../hotplate-common/HelperFunctions";
import { getChefStyles } from "../../hotplate-common/chefStyleFunctions";
import { HeartFilledIcon } from "@radix-ui/react-icons";
import { Error as ErrorMessage } from "../../hotplate-common/primitives/Error";
import { Label } from "../../hotplate-common/primitives/Label";
import { Column, H1, P, Row } from "../../hotplate-common/primitives/Containers";

const stripePromise = loadStripe(stripeFrontendApiKey);

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#E85B5B",
      color: "#333333",
      fontWeight: 400,
      fontFamily: "Inter, sans-serif",
      fontSize: "14px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#333333",
      },
      "::placeholder": {
        color: "#C6CACC",
      },
    },
    invalid: {
      iconColor: "red",
      color: "red",
    },
  },
};

const giftCardPriceOptions = ["10.00", "25.00", "50.00", "100.00", "Other"];

function UninjectedBuyGiftCard() {
  const dispatch = useDispatch();
  const setToastPopupInfo = useConnectedFn(setToastPopupInfoUnconnected, dispatch);
  const [isConfirmPaymentLoading, setIsConfirmPaymentLoading] = useState(false);
  const { siteSettings } = useSelector((state: ShopReduxState) => state.storefront);
  const chefId = siteSettings?.chefId;
  const chefStyles: ChefStyles = getChefStyles(siteSettings);

  const userInfo = useSelector((state) => state.login.userInfo);
  const [firstName, setFirstName] = useState(userInfo?.firstName || "");
  const [lastName, setLastName] = useState(userInfo?.lastName || "");
  const [email, setEmail] = useState(userInfo?.email || "");
  const [phone, setPhone] = useState(
    getFormattedPhone(userInfo?.phone?.replace("+1", "") || "", false)
  );
  const [cardInputError, setCardInputError] = useState();
  const [confirmPaymentError, setConfirmPaymentError] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [giftCardPriceOption, setGiftCardPriceOption] = useState("25.00"); // 10.00, 25.00, 50.00, 100.00, Other
  const [giftCardOtherInput, setGiftCardOtherInput] = useState("25.00");
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const checkoutRef = useRef(confirmPayment);
  checkoutRef.current = confirmPayment;

  const stripe = useStripe();
  const elements = useElements();

  function getGiftCardPrice() {
    if (giftCardPriceOption === "Other") {
      if (!priceInputIsValid(giftCardOtherInput)) return "0.00";
      return giftCardOtherInput;
    } else {
      return giftCardPriceOption;
    }
  }

  const items = [
    {
      title: "Gift Card",
      isGiftCard: true,
      amount: Math.round(parseFloat(getGiftCardPrice()) * 100),
    },
  ];

  const initializeGeneralShopPaymentIntent = useMutation(
    initializeGeneralShopPaymentIntentMutation,
    { retry: 2 }
  );

  const prepareToConfirmGeneralShopPayment = useMutation(
    prepareToConfirmGeneralShopPaymentMutation,
    { retry: 2 }
  );

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Total",
          amount: 50, // will be updated in the onClick for opening the payment request menu
        },
        requestPayerName: true, // TODO possibly remove, though apparently it's recommended to keep at least one of these
        requestPayerEmail: true, // TODO possibly remove
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          pr.on("paymentmethod", (ev) => {
            checkoutRef.current(ev); // TODO come up with a better pattern for this, this is probably not safe for concurrent mode
          });
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  const isInitializationLoading =
    !stripe ||
    !elements ||
    initializeGeneralShopPaymentIntent.isLoading ||
    !initializeGeneralShopPaymentIntent.data?.json.client_secret ||
    !initializeGeneralShopPaymentIntent.data?.json.payment_intent_id;

  const isPaymentGateClosed =
    isInitializationLoading ||
    isConfirmPaymentLoading ||
    firstName === "" ||
    lastName === "" ||
    email === "" ||
    phone.length < 14 ||
    parseFloat(getGiftCardPrice()) < 0.5;

  async function confirmPayment(ev) {
    if (isPaymentGateClosed) return;
    setIsConfirmPaymentLoading(true);

    const paymentIntentClientSecret = initializeGeneralShopPaymentIntent.data?.json.client_secret;
    const paymentIntentId = initializeGeneralShopPaymentIntent.data?.json.payment_intent_id;
    try {
      ensureOk(
        await prepareToConfirmGeneralShopPayment.mutateAsync({
          chefId,
          paymentIntentId,
          items,
          firstName,
          lastName,
          email,
          phone: "+1" + getUnFormattedPhone(phone),
        })
      );

      if (ev) {
        // Payment Request
        const { paymentIntent, error } = await stripe.confirmCardPayment(
          paymentIntentClientSecret,
          {
            payment_method: ev.paymentMethod.id,
          },
          {
            handleActions: false,
          }
        );

        if (error) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete("fail");
          throw error;
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete("success");
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent.status === "requires_action") {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(paymentIntentClientSecret);
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
              throw error;
            } else {
              // The payment has succeeded.
            }
          } else {
            // The payment has succeeded.
          }
        }
      } else {
        // Card
        const { error } = await stripe.confirmCardPayment(paymentIntentClientSecret, {
          setup_future_usage: "on_session",
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              phone: "+1" + getUnFormattedPhone(phone),
              name: firstName + " " + lastName,
              email: email,
            },
          },
        });
        if (error) {
          throw error;
        }
      }

      setShowConfirmation(true);
    } catch (err) {
      setConfirmPaymentError(err);
      throw err;
    } finally {
      setIsConfirmPaymentLoading(false);
    }
  }

  return (
    <Dialog
      trigger={
        <Button color={"accent"} variant={"tinted"} as="div">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-gift"
          >
            <polyline points="20 12 20 22 4 22 4 12"></polyline>
            <rect x="2" y="7" width="20" height="5"></rect>
            <line x1="12" y1="22" x2="12" y2="7"></line>
            <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path>
            <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path>
          </svg>
          Gift Card
        </Button>
      }
      contentCss={{ overflow: "auto" }}
      open={isDialogOpen}
      onOpenChange={async () => {
        if (!isConfirmPaymentLoading) setIsDialogOpen(!isDialogOpen);
        if (!isDialogOpen) {
          try {
            ensureOk(
              await initializeGeneralShopPaymentIntent.mutateAsync({
                items: items,
                chefId: chefId,
                phone: "+1" + getUnFormattedPhone(phone),
              })
            );
          } catch (err) {
            setToastPopupInfo({
              text: `An error occured. Please refresh and try again later.`,
              type: "error",
            });
          }
        } else {
          setGiftCardPriceOption("25.00");
        }
      }}
      title={showConfirmation ? "Success!" : "Gift Cards"}
      description={
        showConfirmation
          ? "This is your gift card code. You will also receive an email & text with the code and redemption instructions."
          : `Grab a gift card that can be used on any ${siteSettings.restaurantName} sale.`
      }
    >
      {showConfirmation && (
        <>
          <Row
            css={{
              padx: "$xl",
              pady: "$sm",
              br: "$sm",
              backgroundColor: "$success3",
              jc: "center",
            }}
          >
            <H1
              css={{
                ff: "$inter",
                textStyle: "text-7",
                color: "$success11",
                letterSpacing: "0.25em",
              }}
            >
              {prepareToConfirmGeneralShopPayment.data?.json.items[0].code}
            </H1>
          </Row>

          <P
            css={{
              ff: "$inter",
              textStyle: "text-1",
              color: "$gray10",
              mt: "$xxs",
            }}
          >
            {`Starting balance: $${toFixed(
              prepareToConfirmGeneralShopPayment.data?.json.items[0].amount / 100,
              2
            )}`}
          </P>
        </>
      )}
      {!showConfirmation && (
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            if (isPaymentGateClosed) return;
            return confirmPayment();
          }}
        >
          <Label text="1. Select amount" css={{ mb: "$lg" }}>
            <Row css={{ width: "100%" }}>
              {giftCardPriceOptions.map((priceOption, priceOptionIndex) => {
                return (
                  <Button
                    key={priceOption}
                    color={"info"}
                    variant={giftCardPriceOption === priceOption ? "filled" : "outlined"}
                    type={"button"}
                    onClick={() => {
                      if (isConfirmPaymentLoading) return;
                      setGiftCardPriceOption(priceOption);
                    }}
                    css={{
                      borderRadius:
                        priceOptionIndex === 0
                          ? "4px 0px 0px 4px"
                          : priceOptionIndex === giftCardPriceOptions.length - 1
                          ? "0px 4px 4px 0px"
                          : "0",
                      width: "20%",
                    }}
                  >
                    {priceOption !== "Other" ? `$${priceOption.split(".")[0]}` : "Other"}
                  </Button>
                );
              })}
            </Row>
            {giftCardPriceOption === "Other" && (
              <Input
                placeholder={"0.00"}
                contentBefore={"$"}
                value={giftCardOtherInput}
                onBlur={() => {
                  if (giftCardOtherInput === "") setGiftCardOtherInput("0.00");
                  else setGiftCardOtherInput(toFixed(giftCardOtherInput, 2));
                }}
                onChange={(e) => {
                  const val = e.target.value;
                  if (!priceInputIsValid(val)) return;
                  setGiftCardOtherInput(val);
                }}
                css={{ mt: "8px", ml: "auto", w: "10ch", minW: 0 }}
              />
            )}
          </Label>
          <Label
            text="2. Contact Info"
            tooltipText={
              "After you purchase this gift card, we will send a text and email with the card as well as redemption instructions."
            }
            css={{ mb: "$lg" }}
          >
            <Column css={{ gap: "$md", width: "100%" }}>
              <Input
                placeholder={"First Name"}
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
              <Input
                placeholder={"Last Name"}
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
              <Input
                placeholder={"Email"}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <Input
                contentBefore={"+1 🇺🇸"}
                placeholder={"Phone Number"}
                value={phone}
                onChange={(e) => {
                  setPhone(getFormattedPhone(e.target.value, false));
                }}
              />
            </Column>
          </Label>

          <Label text="3. Payment info" css={{}}>
            <CardElement
              options={CARD_OPTIONS}
              onChange={(e) => {
                if (e.error) {
                  setCardInputError(e.error);
                } else if (e.complete) {
                  setCardInputError(undefined);
                }
              }}
            />
          </Label>

          {cardInputError && <ErrorMessage>{cardInputError.message}</ErrorMessage>}
          {confirmPaymentError && <ErrorMessage>{confirmPaymentError.message}</ErrorMessage>}

          {paymentRequest && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "52px",
                width: "100%",
                borderRadius: "8px",
                overflow: "hidden",
                marginTop: 24,
                opacity: isPaymentGateClosed ? 0.5 : 1,
                pointerEvents: isPaymentGateClosed ? "none" : "auto",
              }}
            >
              <PaymentRequestButtonElement
                onClick={() => {
                  const amount = Math.round(parseFloat(getGiftCardPrice()) * 100);
                  paymentRequest.update({
                    total: {
                      label: "Total",
                      amount: amount,
                    },
                  });
                }}
                options={{
                  paymentRequest,
                  style: {
                    paymentRequestButton: {
                      type: "default",
                      // One of 'default', 'book', 'buy', or 'donate'
                      // Defaults to 'default'

                      theme: "dark",
                      // One of 'dark', 'light', or 'light-outline'
                      // Defaults to 'dark'

                      height: "52px",
                      // Defaults to '40px'. The width is always '100%'.
                    },
                  },
                }}
                // style={{ borderRadius: 8, height: 52 }}
              />
            </div>
          )}

          <Button
            color={"accent"}
            variant={"filled"}
            loading={isInitializationLoading || isConfirmPaymentLoading}
            disabled={isPaymentGateClosed || cardInputError}
            css={{
              marginTop: "12px",
              width: "100%",
            }}
          >
            Buy ${getGiftCardPrice()} Gift Card
          </Button>
        </form>
      )}
    </Dialog>
  );
}

export function BuyGiftCard() {
  return (
    <Elements stripe={stripePromise}>
      <UninjectedBuyGiftCard />
    </Elements>
  );
}
