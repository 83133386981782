/* eslint-disable react/prop-types */
import React, { PureComponent } from "react";
import {
  getCartItemPrice,
  getCartItemUnitPrice,
  getSubtotal,
  getOrderTaxes,
  getPaymentProcessing,
  getOrderRevenue,
  toFixed,
  capitalizeFirstLetter,
  getAddressString,
  getMenuItemOptionsString,
  getTimestampDayOfWeek,
  getTimestampMonthDate,
  getTimestampHoursMinutesAMPM,
  getTransactionOrderStatus,
  getOrderTotal,
  chargeHotplateFee,
  getOrderHotplateFees,
  isCollabOrder,
  getCollabOrder,
  getOrderDiscount,
} from "@hotplate/utils-ts/helperFunctions";
import { connect } from "react-redux";
import { FirebaseContext } from "../../firebaseSocket";
import NewModal from "../../hotplate-common/NewModal";
import Refund from "./Refund";
import {
  ItemizedReceipt,
  ReceiptCard,
  CollabOrderReceipt,
  ChefOrderSummary,
} from "../../visly/Management";
import { Button } from "../../visly/Primitives";
import { ReceiptLineItem } from "../../visly/Confirmation";
import { usePortalUser } from "../../auth";

class ItemizedReceiptMaster extends PureComponent {
  static contextType = FirebaseContext;
  constructor(props) {
    super(props);
    this.state = {
      showRefundModal: false,
    };
  }

  componentDidMount() {
    // Used for checking if the Firebase app was reset
    this.prevDb = this.context.database;
  }

  componentDidUpdate(prevProps) {
    const { hostId, paymentIntentId, isOpen } = this.props;
    if (
      paymentIntentId &&
      hostId &&
      isOpen &&
      (!prevProps.isOpen || this.prevDb !== this.context.database)
    ) {
      this.prevDb = this.context.database;
      this.context.api.connectToItemizedReceiptOrder(hostId, paymentIntentId);
    }

    if (prevProps.isOpen && !isOpen) {
      this.unmount();
    }
  }

  componentWillUnmount() {
    this.unmount();
  }

  unmount() {
    const { hostId, paymentIntentId } = this.props;
    if (hostId && paymentIntentId) {
      this.context.api.disconnectFromItemizedReceiptOrder(hostId, paymentIntentId);
    }
  }

  getCartItemTitle(cartItem) {
    let title = cartItem.title;
    if (cartItem.deleted) title += " (removed by you)";
    else if (cartItem.manuallyAddedItem) title += " (manually added)";
    else if (Number.isInteger(cartItem.refundQuantity) && cartItem.refundQuantity > 0)
      title += ` (${cartItem.refundQuantity} refunded)`;
    return title;
  }

  getLineItemAmount(lineItemTitle, collabOrder = null) {
    const order = collabOrder ? collabOrder : this.props.order;
    let total = "0.00";
    if (lineItemTitle === "subtotal") {
      total = getSubtotal(order.cartItems);
    } else if (lineItemTitle === "deliveryFee") {
      total = order.deliveryFee;
    } else if (lineItemTitle === "customerPaymentProcessingFee") {
      total = order.customerPaymentProcessingFee;
    } else if (lineItemTitle === "tax") {
      total = getOrderTaxes(order);
    } else if (lineItemTitle === "hotplateFee") {
      total = getOrderHotplateFees(order, true);
    } else if (lineItemTitle === "total") {
      total = getOrderTotal(order);
    } else if (lineItemTitle === "tip") {
      total = order.tip;
    } else if (lineItemTitle === "discount") {
      total = getOrderDiscount(order);
    }

    const refundTotal = this.getLineItemRefund(lineItemTitle);

    return toFixed(parseFloat(total) - parseFloat(refundTotal), 2);
  }

  getLineItemRefund(lineItemTitle, collabOrder = null) {
    const order = collabOrder ? collabOrder : this.props.order;
    if (lineItemTitle === "total") {
      if ("refundTotal" in order) return order.refundTotal;
      return "0.00";
    }
    if (lineItemTitle === "subtotal") {
      let refundTotal = 0;
      for (let i = 0; i < order.cartItems.length; i++) {
        if (order.cartItems[i].refundQuantity && order.cartItems[i].refundQuantity > 0) {
          refundTotal +=
            getCartItemUnitPrice(order.cartItems[i]) * order.cartItems[i].refundQuantity;
        }
      }
      return toFixed(refundTotal, 2);
    }
    const lineItemRefundTitle = lineItemTitle + "Refund";
    if (lineItemRefundTitle in order) {
      const refundTotal = order[lineItemRefundTitle];
      if (parseFloat(refundTotal) <= 0) return "0.00";
      return refundTotal;
    }
    return "0.00";
  }

  getLineItemRefundString(lineItemTitle) {
    const refundTotal = this.getLineItemRefund(lineItemTitle);
    if (refundTotal === "0.00") return null;
    return "($" + refundTotal + " refunded)";
  }

  render() {
    const { onRequestClose, hostId, order, paymentIntentId, isOpen } = this.props;
    const { showRefundModal } = this.state;

    const irp = order;
    let showIrp = true;
    if (!irp || Object.keys(irp).length === 0 || irp.paymentIntentId !== paymentIntentId)
      showIrp = false;
    const isCollab = isCollabOrder(irp);
    const collabOrder = getCollabOrder(irp, hostId);
    return (
      <NewModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        hideClose={showRefundModal}
        fullscreen
        title={`Order #${irp.orderId}`}
        desc={irp.fullName}
      >
        {showIrp && (
          <ItemizedReceipt
            size={this.size}
            style={{ backgroundColor: "transparent" }}
            paymentStatus={<ItemizedReceipt.Status type={getTransactionOrderStatus(irp)} />}
            name={irp.fullName}
            email={irp.email}
            phone={irp.phone}
            DatePlaced={
              <ItemizedReceipt.DatePlaced
                size={this.size}
                text={
                  getTimestampMonthDate(irp.orderPlaced) +
                  ", " +
                  getTimestampHoursMinutesAMPM(irp.orderPlaced)
                }
              />
            }
            PaymentId={
              <ItemizedReceipt.PaymentId size={this.size} text={irp.paymentIntentId.slice(-6)} />
            }
            ChefOrderSummary={
              <ReceiptCard size={this.size}>
                <ChefOrderSummary
                  FulfillmentDate={
                    <ChefOrderSummary.FulfillmentDate
                      size={this.size}
                      label={capitalizeFirstLetter(irp.cartType) + " Date"}
                      text={
                        irp.timeSlot.range
                          ? getTimestampDayOfWeek(irp.timeSlot.startTime, true) +
                            ", " +
                            getTimestampMonthDate(irp.timeSlot.startTime) +
                            ", " +
                            getTimestampHoursMinutesAMPM(irp.timeSlot.startTime) +
                            " - " +
                            getTimestampHoursMinutesAMPM(irp.timeSlot.endTime)
                          : getTimestampDayOfWeek(irp.timeSlot.startTime, true) +
                            ", " +
                            getTimestampMonthDate(irp.timeSlot.startTime) +
                            ", " +
                            getTimestampHoursMinutesAMPM(irp.timeSlot.startTime)
                      }
                    />
                  }
                  FulfillmentAddress={
                    <ChefOrderSummary.FulfillmentDate
                      size={this.size}
                      label={capitalizeFirstLetter(irp.cartType) + " Address"}
                      text={
                        irp.cartType === "delivery" || irp.cartType === "wholesale"
                          ? getAddressString({
                              addressDict: irp.customerAddressDict,
                              displayFullAddress: true,
                            }) +
                            " " +
                            (irp.customerAddressDict ? irp.customerAddressDict.zip : "No Zip")
                          : getAddressString({
                              addressDict: irp.location.addressDict,
                              displayFullAddress: true,
                            }) +
                            " " +
                            (irp.location && irp.location.addressDict
                              ? irp.location.addressDict.zip
                              : "No Zip")
                      }
                    />
                  }
                  orderType={
                    isCollab ? (
                      <ChefOrderSummary.Status text="Collab" type="complete" />
                    ) : (
                      <ChefOrderSummary.Status type={irp.cartType} />
                    )
                  }
                  isCollab={isCollab}
                  orderNum={"#" + irp.orderId}
                  fees={
                    <>
                      {((collabOrder || order).discountCode || (collabOrder || order).giftCard) && (
                        <ChefOrderSummary.Fee
                          fee
                          item={
                            (order.discountCode
                              ? "Discount Code: " + order.discountCode.name
                              : "") + (order.giftCard ? " Gift Card: " : "")
                          }
                          total={`-$${this.getLineItemAmount("discount")}`}
                          refund={this.getLineItemRefundString("discount")}
                        />
                      )}
                      <ChefOrderSummary.Fee
                        fee
                        item="Chef Tip"
                        total={`$${this.getLineItemAmount("tip")}`}
                        refund={this.getLineItemRefundString("tip")}
                      />
                      {typeof irp.deliveryFee === "string" &&
                        !isNaN(irp.deliveryFee) &&
                        parseFloat(irp.deliveryFee) > 0 && (
                          <ChefOrderSummary.Fee
                            fee
                            item="Delivery Fee"
                            total={`$${this.getLineItemAmount("deliveryFee")}`}
                            refund={this.getLineItemRefundString("deliveryFee")}
                          />
                        )}
                      {irp.siteSettings.chargeCustomerPaymentProcessing && (
                        <ChefOrderSummary.Fee
                          fee
                          item="Payment Processing Fee"
                          total={`$${this.getLineItemAmount("customerPaymentProcessingFee")}`}
                          refund={this.getLineItemRefundString("customerPaymentProcessingFee")}
                        />
                      )}
                      {irp.siteSettings.taxRate && irp.siteSettings.taxRate !== "0.00" && (
                        <ChefOrderSummary.Fee
                          fee
                          item="Tax"
                          total={`$${this.getLineItemAmount("tax")}`}
                          refund={this.getLineItemRefundString("tax")}
                        />
                      )}
                    </>
                  }
                  Subtotal={
                    <ChefOrderSummary.Subtotal
                      total={`$${this.getLineItemAmount("subtotal")}`}
                      refund={this.getLineItemRefundString("subtotal")}
                    />
                  }
                  HotplateFee={
                    chargeHotplateFee(irp) && (
                      <ChefOrderSummary.HotplateFee
                        total={`$${this.getLineItemAmount("hotplateFee")}`}
                        refund={this.getLineItemRefundString("hotplateFee")}
                      />
                    )
                  }
                  CustomerTotal={
                    <ChefOrderSummary.CustomerTotal total={`$${this.getLineItemAmount("total")}`} />
                  }
                  PpFee={<ChefOrderSummary.PpFee total={`-$${getPaymentProcessing(irp)}`} />}
                  ChefHotplateFee={
                    chargeHotplateFee(irp, true) && (
                      <ChefOrderSummary.HotplateFee
                        item={`Hotplate Fee ${
                          !irp.siteSettings.chargeHotplateFeeToChef
                            ? "(paid by customer)"
                            : "(paid by you)"
                        }`}
                        total={`-$${this.getLineItemAmount("hotplateFee")}`}
                        refund={this.getLineItemRefundString("hotplateFee")}
                      />
                    )
                  }
                  Refund={
                    typeof irp.refundTotal === "string" && (
                      <ChefOrderSummary.HotplateFee total={`-$${irp.refundTotal}`} />
                    )
                  }
                  Revenue={<ChefOrderSummary.Revenue total={`$${getOrderRevenue(irp)}`} />}
                >
                  {irp.cartItems
                    .filter((cartItem) => !cartItem.isSynthetic)
                    .map((cartItem, index) => (
                      <ReceiptLineItem
                        style={{
                          opacity: cartItem.deleted ? 0.5 : 1,
                          color: cartItem.manuallyAddedItem ? "#1EC18C" : "",
                        }}
                        key={index}
                        item={this.getCartItemTitle(cartItem)}
                        total={`$${getCartItemPrice(cartItem)}`}
                        quantity={cartItem.quantity}
                        options={getMenuItemOptionsString(cartItem)}
                      />
                    ))}
                </ChefOrderSummary>
              </ReceiptCard>
            }
            InitiateRefundButton={
              null
              // <ItemizedReceipt.InitiateRefundButton onClick={() => startRefund()}/>
            }
            DropdownMenu={
              isCollab ? null : (
                <Button
                  text="Refund"
                  kind="secondary"
                  disabled={order.cartItems[0].isGiftCard}
                  onClick={() => this.setState({ showRefundModal: true })}
                />
              )
              //   <ItemizedReceipt.DropdownMenu
              //     onSelectionChange={(key) => {
              //       if (key === "REFUND") {
              //         this.setState({ showRefundModal: true });
              //       }
              //     }}
              //   >
              //     <ItemizedReceipt.DropdownMenu.Item
              //       label="Initiate refund..."
              //       key="REFUND"
              //     />
              //   </ItemizedReceipt.DropdownMenu>
              // )
            }
          >
            {isCollab && (
              <ReceiptCard size={this.size}>
                <CollabOrderReceipt
                  orderNum={"#" + collabOrder.orderId}
                  fees={
                    <>
                      <CollabOrderReceipt.Fee
                        fee
                        item="Chef Tip"
                        total={`$${this.getLineItemAmount("tip", collabOrder)}`}
                        refund={this.getLineItemRefundString("tip", collabOrder)}
                      />
                      {typeof collabOrder.deliveryFee === "string" &&
                        !isNaN(collabOrder.deliveryFee) &&
                        parseFloat(collabOrder.deliveryFee) > 0 && (
                          <CollabOrderReceipt.Fee
                            fee
                            item="Delivery Fee"
                            total={`$${this.getLineItemAmount("deliveryFee", collabOrder)}`}
                            refund={this.getLineItemRefundString("deliveryFee", collabOrder)}
                          />
                        )}
                      {collabOrder.siteSettings.chargeCustomerPaymentProcessing && (
                        <CollabOrderReceipt.Fee
                          fee
                          item="Payment Processing Fee"
                          total={`$${this.getLineItemAmount(
                            "customerPaymentProcessingFee",
                            collabOrder
                          )}`}
                          refund={this.getLineItemRefundString(
                            "customerPaymentProcessingFee",
                            collabOrder
                          )}
                        />
                      )}
                      {collabOrder.siteSettings.taxRate &&
                        collabOrder.siteSettings.taxRate !== "0.00" && (
                          <CollabOrderReceipt.Fee
                            fee
                            item="Tax"
                            total={`$${this.getLineItemAmount("tax", collabOrder)}`}
                            refund={this.getLineItemRefundString("tax", collabOrder)}
                          />
                        )}
                    </>
                  }
                  Subtotal={
                    <CollabOrderReceipt.Subtotal
                      total={`$${this.getLineItemAmount("subtotal", collabOrder)}`}
                      refund={this.getLineItemRefundString("subtotal", collabOrder)}
                    />
                  }
                  HotplateFee={
                    chargeHotplateFee(collabOrder) && (
                      <CollabOrderReceipt.HotplateFee
                        total={`$${this.getLineItemAmount("hotplateFee", collabOrder)}`}
                        refund={this.getLineItemRefundString("hotplateFee", collabOrder)}
                      />
                    )
                  }
                  CustomerTotal={
                    <CollabOrderReceipt.CustomerTotal
                      total={`$${this.getLineItemAmount("total", collabOrder)}`}
                    />
                  }
                  PpFee={
                    <CollabOrderReceipt.PpFee total={`$${getPaymentProcessing(collabOrder)}`} />
                  }
                  ChefHotplateFee={
                    chargeHotplateFee(collabOrder) && (
                      <CollabOrderReceipt.HotplateFee
                        total={`$${this.getLineItemAmount("hotplateFee", collabOrder)}`}
                        refund={this.getLineItemRefundString("hotplateFee", collabOrder)}
                      />
                    )
                  }
                  Refund={
                    typeof collabOrder.refundTotal === "string" && (
                      <CollabOrderReceipt.HotplateFee total={`-$${collabOrder.refundTotal}`} />
                    )
                  }
                  Revenue={
                    <CollabOrderReceipt.Revenue total={`$${getOrderRevenue(collabOrder)}`} />
                  }
                >
                  {collabOrder.cartItems
                    .filter((cartItem) => !cartItem.isSynthetic)
                    .map((cartItem, index) => (
                      <ReceiptLineItem
                        style={{
                          opacity: cartItem.deleted ? 0.5 : 1,
                          color: cartItem.manuallyAddedItem ? "#1EC18C" : "",
                        }}
                        key={index}
                        item={this.getCartItemTitle(cartItem)}
                        total={`$${getCartItemPrice(cartItem)}`}
                        quantity={cartItem.quantity}
                        options={getMenuItemOptionsString(cartItem)}
                      />
                    ))}
                </CollabOrderReceipt>
              </ReceiptCard>
            )}
          </ItemizedReceipt>
        )}
        {showRefundModal && showIrp && (
          <Refund
            isOpen={showRefundModal && showIrp}
            order={irp}
            onRequestClose={() => this.setState({ showRefundModal: false })}
          />
        )}
      </NewModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    order: state.orderManagement.itemizedReceiptOrder,
    connectToItemizedReceiptOrderLoading:
      state.orderManagement.connectToItemizedReceiptOrderLoading,
    connectToItemizedReceiptOrderError: state.orderManagement.connectToItemizedReceiptOrderError,
    disconnectFromItemizedReceiptOrderLoading:
      state.orderManagement.disconnectFromItemizedReceiptOrderLoading,
    disconnectFromItemizedReceiptOrderError:
      state.orderManagement.disconnectFromItemizedReceiptOrderError,
  };
};

export default connect(
  mapStateToProps,
  {}
)(function (props) {
  const { chefId } = usePortalUser();
  return <ItemizedReceiptMaster {...props} hostId={chefId} />;
});
