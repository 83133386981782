import React from "react";
import { AlertDialog } from "../../hotplate-common/primitives/AlertDialog";
import { Button } from "../../hotplate-common/primitives/Button";
import { trackSurveyResponse } from "../../hotplate-portal/analytics";
import { v4 as uuid } from "uuid";
import { shouldRetry, trpc } from "../../trpc";
import { usePortalUser } from "../../auth";
import { useFirebaseConnection } from "../../hooks";
import { PuffLoader } from "../../hotplate-common/loaders/PuffLoader";
import { Center } from "../../hotplate-common/primitives/Containers";

const surveyTypes = {
  PMF: {
    prompt:
      "How disappointed would you be if you could never use Hotplate again? (Don't worry, this is a hypothetical)",
    surveyId: "pmf_survey",
    buttonColor: "accent",
    buttonVariant: "tinted",
    buttonOptions: ["Not Disappointed", "Somewhat Disappointed", "Very Disappointed"],
  },
  SUPPORT: {
    prompt: "Do you feel like your support issues/questions are solved in a timely manner?",
    surveyId: "support_survey",
    buttonColor: "warning",
    buttonVariant: "tinted",
    buttonOptions: [
      "Support is never timely",
      "Support is sometimes timely",
      "Support is always timely",
    ],
  },
  FEEDBACK_VALUED: {
    prompt:
      "Do you feel like the feedback you have given the Hotplate team is heard/implemented in the product?",
    surveyId: "feedback_valued_survey",
    buttonColor: "danger",
    buttonVariant: "tinted",
    buttonOptions: [
      "Feedback is never heard/implemented",
      "Feedback is sometimes heard/implemented",
      "Feedback is always heard/implemented",
    ],
  },
};

export default function Survey({ showClose, isOpen, close }) {
  const { chefId } = usePortalUser();
  const saveSurveyResponse = trpc.portal.saveSurveyResponse.useMutation({ retry: shouldRetry(2) });

  const { data: surveyResponses, isLoading: isSurveyResponsesLoading } = useFirebaseConnection({
    ref: `/hosts/${chefId}/surveyResponses`,
  });

  function getSurveyType() {
    const surveyTypesFinished = {};
    Object.keys(surveyTypes).forEach((surveyType) => {
      surveyTypesFinished[surveyType] = 0;
    });

    if (surveyResponses) {
      Object.keys(surveyResponses).forEach((surveyId) => {
        const surveyResponse = surveyResponses[surveyId];
        Object.keys(surveyTypes).forEach((surveyType) => {
          if (surveyTypes[surveyType].prompt === surveyResponse.prompt) {
            surveyTypesFinished[surveyType] = Math.max(
              surveyResponse.timestamp,
              surveyTypesFinished[surveyType]
            );
          }
        });
      });
    }

    let res = undefined;
    Object.keys(surveyTypes).forEach((surveyType) => {
      if (new Date().getTime() - surveyTypesFinished[surveyType] > 1000 * 60 * 60 * 24 * 28) {
        res = surveyType;
        return;
      }
    });

    return res; // all surveys have been completed within the last 28 days
  }

  const surveyType = getSurveyType();

  function saveSurveyResponseWrapped(answer) {
    trackSurveyResponse({
      survey_id: surveyTypes[surveyType].surveyId,
      prompt: surveyTypes[surveyType].prompt,
      response: answer,
    });
    saveSurveyResponse.mutate({
      id: uuid(), // this is NOT supposed to be surveyId
      prompt: surveyTypes[surveyType].prompt,
      answer: answer,
    });
    close();
  }

  const surveyOptions = surveyTypes[surveyType];

  return (
    <AlertDialog
      unclosable={!showClose}
      spicyDescription={surveyOptions?.prompt}
      isOpen={isOpen && surveyType} // more surveys left
      actionButtonsArr={
        !isSurveyResponsesLoading &&
        surveyOptions?.buttonOptions.map((option) => {
          return (
            <Button
              color={surveyOptions.buttonColor}
              variant={surveyOptions.buttonVariant}
              key={option}
              onClick={() => {
                saveSurveyResponseWrapped(option);
              }}
            >
              {option}
            </Button>
          );
        })
      }
    >
      {isSurveyResponsesLoading && (
        <Center css={{ width: "100%", height: "100%" }}>
          <PuffLoader />
        </Center>
      )}
    </AlertDialog>
  );
}
