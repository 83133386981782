// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
/* tslint:disable */
/* eslint-disable */
import "../../textstyles/fonts.css";
import "../../reset.css";
import "./OrderEditing.css";
import React, { createContext, useContext } from "react";
import {
  exists,
  findSetVariantProps,
  makeCompositeDefaultProps,
} from "../../_internal_utils";
import {
  RootPrimitive,
  SpacerPrimitive,
  TextPrimitive,
  ContainerPrimitive,
} from "../../_internal_primitives";
import {
  default as EditTimeslot,
  LabeledInputContext as EditTimeslotContext,
  default as EditLocation,
  LabeledInputContext as EditLocationContext,
  default as EditCartType,
  LabeledInputContext as EditCartTypeContext,
} from "../../Events/LabeledInput";
import { default as OrderEditingLineItem } from "../../Management/Packing/OrderEditingLineItem";
import {
  default as AddItemToOrder,
  AddItemToOrderContext,
} from "../../Management/Packing/AddItemToOrder";
import {
  default as SaveButton,
  ButtonContext as SaveButtonContext,
  default as CancelButton,
  ButtonContext as CancelButtonContext,
} from "../../Primitives/Button";
import { default as Error } from "../../Primitives/Error";
import { default as NotifyCustomer } from "./OrderEditing.NotifyCustomer";

const styles = [
  {
    type: "default",
    layers: {
      KTA5mX6obZ: {
        none: {
          text: "Items",
        },
      },
      LexS2fvdn1: {
        none: {
          text: "Edit Order",
        },
      },
      UFFPnSh3EF: {
        none: {
          text: "Customer Name",
        },
      },
      RdiKMGiTZ8: {
        none: {
          text: "#0000",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "noItems",
    layers: {},
  },
];

const defaultPropValues = [
  {
    type: "default",
    layers: {
      "9QBEsxYURu": {
        none: {
          label: "cartType Time",
        },
      },
      QdwLjn1kLE: {},
      MDNaysgNVw: {},
      JtSzg8LZA6: {
        none: {
          text: "Save Changes",
          kind: "primary",
        },
      },
      "9moLYCLi2u": {
        none: {
          text: "Cancel",
        },
      },
      PxKtK5kRN2: {},
      PFPdW9Fvo8: {
        none: {
          label: "cartType Location",
        },
      },
      ToMgup7rv2: {
        none: {
          label: "Order Type",
        },
      },
    },
  },
  {
    type: "boolean",
    propName: "noItems",
    layers: {
      "9QBEsxYURu": {},
      QdwLjn1kLE: {},
      MDNaysgNVw: {},
      JtSzg8LZA6: {},
      "9moLYCLi2u": {},
      PxKtK5kRN2: {},
      PFPdW9Fvo8: {},
      ToMgup7rv2: {},
    },
  },
];

const variantPropTypes = [
  {
    type: "boolean",
    propName: "noItems",
  },
];

export const OrderEditingContext = createContext(null);

function OrderEditing(_props) {
  const defaults = useContext(OrderEditingContext);
  const props = { ...defaults, ..._props };
  const activeVariants = findSetVariantProps(variantPropTypes, props);
  const getCompositeDefaultProps = makeCompositeDefaultProps(
    defaultPropValues,
    activeVariants
  );
  return (
    <RootPrimitive
      {...props}
      key="MQmthYYoTm"
      addSpacing={false}
      internal={{
        projectId: "7X7HTLRqyD",
        styles: styles,
        layerId: "MQmthYYoTm",
        scope: "QieY5DiCwN",
        activeVariants: activeVariants,
      }}
    >
      {(getStyle) => (
        <ContainerPrimitive
          id={"Wrapper_ArQNTi8CEm"}
          className={
            "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_ArQNTi8CEm"
          }
          key={"ArQNTi8CEm"}
          addSpacing={false}
          element={getStyle("ArQNTi8CEm", "htmlElement")}
        >
          {[
            <TextPrimitive
              id={"Title_LexS2fvdn1"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_LexS2fvdn1"
              }
              key={"LexS2fvdn1"}
              text={getStyle("LexS2fvdn1", "text")}
              element={getStyle("LexS2fvdn1", "htmlElement")}
            />,
            <SpacerPrimitive
              id={"Spacer_AtVEjWxXiS"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_AtVEjWxXiS"
              }
              key={"AtVEjWxXiS"}
            />,
            <ContainerPrimitive
              id={"name-orderNum-container_Lk6Fnm2Jsq"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_Lk6Fnm2Jsq"
              }
              key={"Lk6Fnm2Jsq"}
              addSpacing={false}
              element={getStyle("Lk6Fnm2Jsq", "htmlElement")}
            >
              <TextPrimitive
                id={"name_UFFPnSh3EF"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_UFFPnSh3EF"
                }
                key={"UFFPnSh3EF"}
                text={
                  exists(props.name)
                    ? props.name
                    : getStyle("UFFPnSh3EF", "text")
                }
                element={getStyle("UFFPnSh3EF", "htmlElement")}
              />
              <SpacerPrimitive
                id={"Spacer_DPXr1pL4aV"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_DPXr1pL4aV"
                }
                key={"DPXr1pL4aV"}
              />
              <TextPrimitive
                id={"orderNum_RdiKMGiTZ8"}
                className={
                  "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_RdiKMGiTZ8"
                }
                key={"RdiKMGiTZ8"}
                text={
                  exists(props.orderNum)
                    ? props.orderNum
                    : getStyle("RdiKMGiTZ8", "text")
                }
                element={getStyle("RdiKMGiTZ8", "htmlElement")}
              />
            </ContainerPrimitive>,
            <SpacerPrimitive
              id={"Spacer_7puf6bzzAJ"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_7puf6bzzAJ"
              }
              key={"7puf6bzzAJ"}
            />,
            props.EditCartType === undefined ? (
              <EditCartType
                key={"ToMgup7rv2"}
                {...getCompositeDefaultProps("ToMgup7rv2")}
                id="EditCartType_ToMgup7rv2"
                className="__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_ToMgup7rv2"
              />
            ) : (
              <EditCartTypeContext.Provider
                key="ToMgup7rv2-provider"
                value={{
                  key: "ToMgup7rv2",
                  id: "EditCartType_ToMgup7rv2",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_ToMgup7rv2",
                  ...getCompositeDefaultProps("ToMgup7rv2"),
                }}
              >
                {props.EditCartType}
              </EditCartTypeContext.Provider>
            ),
            props.EditLocation === undefined ? (
              <EditLocation
                key={"PFPdW9Fvo8"}
                {...getCompositeDefaultProps("PFPdW9Fvo8")}
                id="EditLocation_PFPdW9Fvo8"
                className="__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_PFPdW9Fvo8"
              />
            ) : (
              <EditLocationContext.Provider
                key="PFPdW9Fvo8-provider"
                value={{
                  key: "PFPdW9Fvo8",
                  id: "EditLocation_PFPdW9Fvo8",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_PFPdW9Fvo8",
                  ...getCompositeDefaultProps("PFPdW9Fvo8"),
                }}
              >
                {props.EditLocation}
              </EditLocationContext.Provider>
            ),
            props.EditTimeslot === undefined ? (
              <EditTimeslot
                key={"9QBEsxYURu"}
                {...getCompositeDefaultProps("9QBEsxYURu")}
                id="EditTimeslot_9QBEsxYURu"
                className="__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_9QBEsxYURu"
              />
            ) : (
              <EditTimeslotContext.Provider
                key="9QBEsxYURu-provider"
                value={{
                  key: "9QBEsxYURu",
                  id: "EditTimeslot_9QBEsxYURu",
                  className:
                    "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_9QBEsxYURu",
                  ...getCompositeDefaultProps("9QBEsxYURu"),
                }}
              >
                {props.EditTimeslot}
              </EditTimeslotContext.Provider>
            ),
            <SpacerPrimitive
              id={"Spacer_JVXriwEDDm"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_JVXriwEDDm"
              }
              key={"JVXriwEDDm"}
            />,
            <ContainerPrimitive
              id={"Items_7igmFPDJ6z"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_7igmFPDJ6z"
              }
              key={"7igmFPDJ6z"}
              addSpacing={false}
              element={getStyle("7igmFPDJ6z", "htmlElement")}
            >
              {[
                <TextPrimitive
                  id={"Items_KTA5mX6obZ"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_KTA5mX6obZ"
                  }
                  key={"KTA5mX6obZ"}
                  text={getStyle("KTA5mX6obZ", "text")}
                  element={getStyle("KTA5mX6obZ", "htmlElement")}
                />,
                <ContainerPrimitive
                  key={"98Q4vNg5LC"}
                  id={"Slot_98Q4vNg5LC"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_98Q4vNg5LC"
                  }
                  addSpacing={false}
                >
                  {props.children}
                </ContainerPrimitive>,
                <SpacerPrimitive
                  id={"Spacer_UZD1hveNux"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_UZD1hveNux"
                  }
                  key={"UZD1hveNux"}
                />,
                props.AddItemToOrder === undefined ? (
                  <AddItemToOrder
                    key={"MDNaysgNVw"}
                    {...getCompositeDefaultProps("MDNaysgNVw")}
                    id="AddItemToOrder_MDNaysgNVw"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_MDNaysgNVw"
                  />
                ) : (
                  <AddItemToOrderContext.Provider
                    key="MDNaysgNVw-provider"
                    value={{
                      key: "MDNaysgNVw",
                      id: "AddItemToOrder_MDNaysgNVw",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_MDNaysgNVw",
                      ...getCompositeDefaultProps("MDNaysgNVw"),
                    }}
                  >
                    {props.AddItemToOrder}
                  </AddItemToOrderContext.Provider>
                ),
              ]}
            </ContainerPrimitive>,
            <SpacerPrimitive
              id={"Spacer_MH4yZ532nn"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_MH4yZ532nn"
              }
              key={"MH4yZ532nn"}
            />,
            props.NotifyCustomer === undefined ? (
              <NotifyCustomer key={"2LS8QdNi1T"} />
            ) : (
              props.NotifyCustomer
            ),
            <ContainerPrimitive
              key={"6KTZvguMfg"}
              id={"Slot_6KTZvguMfg"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_6KTZvguMfg"
              }
              addSpacing={false}
            >
              {props.warningBubble}
            </ContainerPrimitive>,
            <SpacerPrimitive
              id={"Spacer_9Ku3gaoR5t"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_9Ku3gaoR5t"
              }
              key={"9Ku3gaoR5t"}
            />,
            <ContainerPrimitive
              key={"9QBAskPN8j"}
              id={"Slot_9QBAskPN8j"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_9QBAskPN8j"
              }
              addSpacing={false}
            >
              {props.notes}
            </ContainerPrimitive>,
            <ContainerPrimitive
              key={"5YnkeP3zr6"}
              id={"Slot_5YnkeP3zr6"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_5YnkeP3zr6"
              }
              addSpacing={false}
            >
              {props.error}
            </ContainerPrimitive>,
            <SpacerPrimitive
              id={"Spacer_XSpdvu4QmZ"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_XSpdvu4QmZ"
              }
              key={"XSpdvu4QmZ"}
            />,
            <ContainerPrimitive
              id={"button-container_DWHXesM3T2"}
              className={
                "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_DWHXesM3T2"
              }
              key={"DWHXesM3T2"}
              addSpacing={false}
              element={getStyle("DWHXesM3T2", "htmlElement")}
            >
              {[
                props.CancelButton === undefined ? (
                  <CancelButton
                    key={"9moLYCLi2u"}
                    {...getCompositeDefaultProps("9moLYCLi2u")}
                    id="CancelButton_9moLYCLi2u"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_9moLYCLi2u"
                  />
                ) : (
                  <CancelButtonContext.Provider
                    key="9moLYCLi2u-provider"
                    value={{
                      key: "9moLYCLi2u",
                      id: "CancelButton_9moLYCLi2u",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_9moLYCLi2u",
                      ...getCompositeDefaultProps("9moLYCLi2u"),
                    }}
                  >
                    {props.CancelButton}
                  </CancelButtonContext.Provider>
                ),
                <SpacerPrimitive
                  id={"Spacer_XQVo8PgKtu"}
                  className={
                    "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_XQVo8PgKtu"
                  }
                  key={"XQVo8PgKtu"}
                />,
                props.SaveButton === undefined ? (
                  <SaveButton
                    key={"JtSzg8LZA6"}
                    {...getCompositeDefaultProps("JtSzg8LZA6")}
                    id="SaveButton_JtSzg8LZA6"
                    className="__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_JtSzg8LZA6"
                  />
                ) : (
                  <SaveButtonContext.Provider
                    key="JtSzg8LZA6-provider"
                    value={{
                      key: "JtSzg8LZA6",
                      id: "SaveButton_JtSzg8LZA6",
                      className:
                        "__visly_reset_7X7HTLRqyD __visly_scope_QieY5DiCwN_JtSzg8LZA6",
                      ...getCompositeDefaultProps("JtSzg8LZA6"),
                    }}
                  >
                    {props.SaveButton}
                  </SaveButtonContext.Provider>
                ),
              ]}
            </ContainerPrimitive>,
          ]}
        </ContainerPrimitive>
      )}
    </RootPrimitive>
  );
}

OrderEditing.EditTimeslot = EditTimeslot;
OrderEditing.OrderEditingLineItem = OrderEditingLineItem;
OrderEditing.AddItemToOrder = AddItemToOrder;
OrderEditing.SaveButton = SaveButton;
OrderEditing.CancelButton = CancelButton;
OrderEditing.Error = Error;
OrderEditing.EditLocation = EditLocation;
OrderEditing.EditCartType = EditCartType;
OrderEditing.__variants = [
  {
    name: "noItems",
    type: "variant",
  },
];

export default OrderEditing;
